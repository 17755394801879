import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { ButtonPrimaryInverse, ButtonDangerInverseWithBorder } from "../buttons/buttons";
import { RequiredFieldsMessage } from "../requiredMessage/requiredMessage";

interface IRegisteredAndUnregisteredActionsProps {
    onRegisteredUser: () => any;
    onUnregisteredUser: () => any;
    visibleRequiredMessage?: boolean;
    hiddenAction?: boolean;
    disabled:boolean;
}

export const RegisteredAndUnregisteredActions: React.FC<IRegisteredAndUnregisteredActionsProps> = ({
    onRegisteredUser,
    onUnregisteredUser,
    visibleRequiredMessage,
    hiddenAction,
    disabled
}) => {
    const { unregistered } = useAppSelector((store) => store.managementUserForm.generalUserData);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.USER.BUTTON" });

    const requiredMessage = visibleRequiredMessage ? <RequiredFieldsMessage /> : null;

    if (unregistered === undefined) {
        return null;
    } else if (unregistered) {
        return (
            <>
                {!hiddenAction && (
                    <ButtonPrimaryInverse
                        title={t("CHARGED")}
                        disabled={disabled}
                        handleClick={onRegisteredUser}
                    />
                )}
                {requiredMessage}
            </>
        );
    } else if (!unregistered) {
        return (
            <>
                {!hiddenAction && (
                    <ButtonDangerInverseWithBorder
                    disabled={disabled}
                        title={t("DISCHARGED")}
                        handleClick={onUnregisteredUser}
                    />
                )}

                {requiredMessage}
            </>
        );
    } else {
        return null;
    }
};
