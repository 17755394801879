import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { generatePath } from "react-router";
import { NameIcons } from "../../../../../../assets/icons";
import { routeUrls } from "../../../../../../common/constants";
import { useAppSelector } from "../../../../../../redux/hooks";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { ResumeCardsStyle } from "./resumeCards.jss";

export const ResumeCards = () => {
    const theme = useTheme();
    const styles = ResumeCardsStyle({ theme });

    const { resumeRequest } = useAppSelector((store) => store.createRequest);
    const { data } = useAppSelector((store) => store.catalog.merchandiseTypes);
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });

    const onRequestClick = (requestId: number) => {
        const id = String(requestId);
        const path = generatePath(routeUrls.EDIT_REQUEST, { id });
        window.open(path, "_blank");
    };

    return (
        <div className={styles.resumeCardsContainer}>
            {resumeRequest.map((request) => (
                <div className={styles.resumeCard}>
                    <div className={styles.resumeCardData}>
                        <div className={styles.resumeCardColumn}>
                            <div className={styles.resumeCardLabel}>{t("FIELDS.TRANSPORT_UNIT")}</div>
                            <div className={styles.resumeCardRequestData}>{request.transportUnitNumber}</div>
                        </div>
                        <div className={styles.resumeCardColumn}>
                            <div className={styles.resumeCardLabel}>{t("FIELDS.MERCHANDISE_TYPE")}</div>
                            <div className={styles.resumeCardRequestData}>
                                {data.find((item) => item.id === request.merchandiseTypeId)?.description}
                            </div>
                        </div>
                    </div>
                    <div className={styles.resumeCardLink}>
                        <CustomIcons
                            size={20}
                            margin={"0px"}
                            iconName={NameIcons.EDIT}
                            onClick={() => onRequestClick(request.id)}
                            title={t("BUTTONS.EDIT_INSPECTION_REQUEST")}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
