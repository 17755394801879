export const margins = {
    default: 10,
    sizes: {
        xs: 5,
        sm: 10,
        base: 15,
        md: 20,
        lg: 25,
        xl: 30,
        xxl: 40,
        xxxl: 50,
    },
    icons: {
        default: 10,
    },
};
