import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Batch } from "./batch/batch";
import { BatchesFormStyle } from "./formStyle.jss";
import { IBatch } from "../../../../../../models/requests/IEditRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { addBatchItem, resetBatchProperty, updateBatchProperty } from "../../../../../../redux/reducers/request/edit/editRequest";
import { getGenericPayloadFromEvent } from "../../../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { FormField } from "../../../../../common/fields/fields";

export const BatchesForm = () => {
    const style = BatchesFormStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const list = useAppSelector((store) => store.editRequest.batches);
    const form = useAppSelector((store) => store.editRequest.batch); 

    const {
        register,
        formState: { isValid },
    } = useForm<IBatch>({ mode: "onChange" });
    
    const onAddItem = () => {
        if (form.code.length > 0) {
            dispatch(addBatchItem(form));
            dispatch(resetBatchProperty());
        }
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateBatchProperty(payload));
        },
        [dispatch],
    );

    return (
        <>
            <div className={style.container}>
                <div className={style.addFormContainer}>
                    <FormField
                        type="text"
                        value={form.code}
                        label={t("FIELDS.BATCH")}
                        isRequired={true}
                        {...register("code", {
                            onChange: onInputChange,
                            validate: () => !!form.code,
                        })}
                    />
                    <div className={style.addButtonContainer}>
                        <ButtonPrimaryInverseWithBorder
                            disabled={!isValid || !form.code || form.code.length == 0}
                            handleClick={onAddItem}
                            title={t("BUTTONS.ADD")}
                        />
                    </div>
                </div>

                <div className={style.dataContainer}>
                    {list.length > 0 ? (
                        list.map((item, index) => (
                            <div className={style.column} key={`${BatchesForm.name}-${index}`}>
                                <Batch
                                    batch={item}
                                    key={`${BatchesForm.name}-${index}`}
                                    index={index}
                                    isNew={true}
                                    allowRemove={true}
                                />
                            </div>
                        ))
                    ) : (
                        <div className={style.column}>{t("FIELDS.NOT_DATA")}</div>
                    )}
                </div>
            </div>
        </>
    );
};
