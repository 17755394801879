import { UserApi } from "../../../../api/user";
import { throwErrorToastrAsync } from "../../../../utils/apiUtils";
import { loadingModules, setFeature, setModules } from "../../../reducers/user/module/module";
import { AppThunk, store } from "../../../store";

const userApi = new UserApi();

export const fetchUserModules = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingModules(true));
        const response = await userApi.getUserModules();
        dispatch(setModules(response));
        let featuresUsers: any = [];

        for (let module of response) {
            for (let feature of module.features) {
                featuresUsers.push(feature);
            }
        }

        dispatch(setFeature(featuresUsers));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingModules(false));
    }
};
