import { createUseStyles } from "react-jss";

export const UploadFileDropzoneStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        gap: 10,
    },

    contentError: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        gap: 10,
    },

    fileSection: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: 15,
        gap: 15,
    },
    fileItem: {
        width: "100%",
        height: 35,
        padding: "15px",
        backgroundColor: theme.colors.palette.greyLighter,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
    },
    buttonRed: {
        "& .st0-red": {
            fill: `${theme.colors.palette.red} !important`,
        },
    },
    fileIcon: {
        color: theme.colors.palette.purple,
        fontSize: 30,
    },
    fileInfo: {
        flexGrow: 1,
    },
    fileInfoReject: {
        textAlign: "center",
        fontWeight: "600",
        padding: "5px 0px",
        color: theme.colors.palette.red,
    },
    fileActions: {},
    dropzone: (props: any) => ({
        minHeight: 120,
        padding: "15px 0px",
        border: props.disabled ? `1px dashed ${theme.colors.palette.grey}` : `1px dashed ${theme.colors.palette.purple}`,
        background: theme.colors.palette.greyLighter,
    }),
    icon: (props: any) => ({
        color: props.disabled ? theme.colors.palette.grey : theme.colors.palette.purple,
        fontSize: "5vh",
    }),

    iconError: {
        color: theme.colors.palette.red,
        fontSize: "5vh",
    },
    iconAccept: {
        color: theme.colors.palette.purple,
        fontSize: "5vh",
    },
}));
