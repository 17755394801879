import { useEffect } from "react";
import { mapUserAdminDataToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../customSelector/customSelector";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { fetchCustomerOptions } from "../../../../../redux/actions/user/users/users";

interface ICustomerFilterSelector extends ISelectorProps {
    filteredRegisteredCustomer: boolean;
}

export const CustomerFilterSelector: React.FC<ICustomerFilterSelector> = ({
    name,
    onChange,
    selectedKey,
    title,
    disabled,
    filteredRegisteredCustomer,
}) => {
    const { data, loading } = useAppSelector((store) => store.usersByRole.customerAdmin);
    const dispatch = useAppDispatch();
    useEffect(() => {
        data === undefined && dispatch(fetchCustomerOptions());
    }, [dispatch, data]);

    return (
        <CustomSelector
            title={title}
            options={mapUserAdminDataToDropDownOptions(data ?? [], filteredRegisteredCustomer)}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabled || loading}
        ></CustomSelector>
    );
};
