import { IComboBoxOption } from "@fluentui/react";
import { Method } from "../../common/enum";
import { UserType } from "../../common/enum/UserType";
import {
    IAdminData,
    IAdminDataAPI,
    IDocument,
    IEntityData,
    IEntityDataAPI,
    IInvoiceEntity,
    IPaymentData,
    IPaymentDataAPI,
    IRequestedDocument,
    IUserAdminData,
    IUserManaged,
    IUserProfile,
    IUserSelfSignup,
    IUserSignup,
    IUserSignupAPI,
} from "../../models";
import { IManagementUserSignup } from "../../models/management/userSignup/IManagementUserSignup";
import { IManagementUserSignupFilter } from "../../models/management/userSignup/IManagementUserSignupFilter";
import { IUserRoleView } from "../../models/requests/IUserRoleView";
import { IUserImportResponse } from "../../models/resources/userImport/IUserImport";
import { IDocumentsRequiredApi } from "../../models/user/IDocumentRequired";
import { IUserAuthData } from "../../models/user/IUserAuthData";
import { IUserClaims } from "../../models/user/IUserClaims";
import { IUserModuleData } from "../../models/user/IUserModuleData";
import { IUserSignupConfirmation } from "../../models/user/IUserSignupConfirmation";
import IUserSignupState from "../../models/user/IUserSignupState";
import IUserSignupType from "../../models/user/IUserSignupType";
import { IUserNotifications } from "../../models/user/notifications/IUserNotifications";
import { IRepresentative } from "../../models/user/representatives/IRepresentative";
import { apiFetch, apiFetchOAuth, apiFetchOAuthWithClaims } from "../../services/apiClient";
import { compileNameOfProperty, formatHour, getFileFromBlobUrl, mapInvoiceEntitiesToDropDownOptions, mapUserToDropDownOptions } from "../../utils";

const baseURL = `${process.env.REACT_APP_API_URL}/users`;

type MetadataItem = {
    DocumentId: string;
    FileName: string;
};
export class UserApi {
    async getDocumentRequested(userType: UserType): Promise<IDocumentsRequiredApi> {
        const url = `${baseURL}/signupdocumenttypes/${userType}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IDocumentsRequiredApi>();
    }

    async getUserSignupTypesAsync(): Promise<IUserSignupType[]> {
        const url = `${baseURL}/signuptypes`;
        return apiFetch(Method.GET, url).execute<IUserSignupType[]>();
    }

    async getUserSignupStatesAsync(): Promise<IUserSignupState[]> {
        const url = `${baseURL}/signupstates`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserSignupState[]>();
    }

    async getUserSignupAsync(user: IUserSignup): Promise<boolean> {
        const formData = await mapUserSignupToFormData(user);
        const url = `${baseURL}/signup`;
        return apiFetch(Method.POST, url).withBody(formData).withMultiPartHeaders().execute<boolean>();
    }

    async getUserSignupListAsync(filter: IManagementUserSignupFilter): Promise<IManagementUserSignup[]> {
        const url = `${baseURL}/signup?email=${filter.email}&name=${filter.name}&userTypeId=${filter.userTypeId}&stateCode=${filter.stateCode}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({ filter }).execute<IManagementUserSignup[]>();
    }

    async getUserSignupByIdAsync(id: string): Promise<IUserSignupConfirmation> {
        const url = `${baseURL}/signup/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserSignupConfirmation>();
    }

    async approveUserSignupAsync(id: string, crmCode: string, externalCode: string, hasRepresentativeData: boolean): Promise<any> {
        const url = `${baseURL}/signup/approved`;
        return apiFetchOAuthWithClaims(Method.PATCH, url)
            .withBody({ id, crmCode, externalCode, hasRepresentativeData })
            .execute<IUserSignupConfirmation>();
    }

    async rejectUserSignupAsync(id: string, reason: string): Promise<any> {
        const url = `${baseURL}/signup/rejected`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody({ id, reason }).execute<IUserSignupConfirmation>();
    }

    async getUserSignupDocumentTemplatesAsync(userType: UserType): Promise<IDocument[]> {
        const url = `${baseURL}/signupdocumenttemplates/${userType}`;
        return apiFetch(Method.GET, url).execute<IDocument[]>();
    }

    async getUserData(): Promise<IUserAuthData> {
        const url = `${baseURL}/me`;
        return apiFetchOAuth(Method.GET, url)
            .execute<IUserAuthData>()
            .then((response) => mapToUserAuthData(response));
    }

    async getClaims(organizationId: string, roleId: string): Promise<IUserClaims> {
        const url = `${baseURL}/me/claims?organizationId=${organizationId}&roleId=${roleId}`;
        return apiFetchOAuth(Method.GET, url).execute<IUserClaims>();
    }

    async getUserProfile(): Promise<IUserProfile> {
        const url = `${baseURL}/me/details`;
        return apiFetchOAuthWithClaims(Method.GET, url)
            .execute<IUserProfile>()
            .then((response) => mapToUserProfile(response));
    }

    async getUserProfileEntityListUsers(): Promise<IUserManaged[]> {
        const url = `${baseURL}/me/users`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserManaged[]>();
    }

    async updateUserProfileData(user: IUserProfile): Promise<boolean> {
        const formData = await mapUserProfileToFormData(user);
        const url = `${baseURL}/me/details`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody(formData).withMultiPartHeaders().execute<boolean>();
    }

    async updateUserProfileImage(blobUrl: string): Promise<boolean> {
        const url = `${baseURL}/me/image`;

        const formData = new FormData();
        formData.append("image", await getFileFromBlobUrl(blobUrl));

        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).withMultiPartHeaders().execute<boolean>();
    }

    async getUserViews(): Promise<IUserRoleView[]> {
        const url = `${baseURL}/me/views`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserRoleView[]>();
    }

    async sendUserSignupRequests(emails: string[]): Promise<boolean> {
        const url = `${baseURL}/signup/user`;
        const body = { emails };
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(body).execute<boolean>();
    }

    async getUserModules(): Promise<IUserModuleData[]> {
        const url = `${baseURL}/me/modules`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserModuleData[]>();
    }
    async selfUserSignup(user: IUserSelfSignup): Promise<boolean> {
        const formData = await mapUserSelfSignupToFormData(user);
        const url = `${baseURL}/signup/user`;
        return apiFetch(Method.PATCH, url).withBody(formData).withMultiPartHeaders().execute<any>();
    }

    async userSignupExists(body: any): Promise<boolean> {
        const url = `${baseURL}/signup/userexists`;
        return apiFetch(Method.POST, url).withBody(body).execute<boolean>();
    }

    async getAdminCustomers(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/customers`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getActiveCustomers(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/customers/active`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getInvoiceCustomers(customerId: string): Promise<IInvoiceEntity[]> {
        const url = `${baseURL}/customers/${customerId}/invoiceentities`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IInvoiceEntity[]>();
    }

    async getImportTemplate(): Promise<any> {
        const url = `${baseURL}/customers/import/template`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<any>().then();
    }

    async postImportCustomerFile(excelFile: any): Promise<IUserImportResponse> {
        const url = `${baseURL}/customers/import`;
        const formData = new FormData();
        formData.append("excelFile", excelFile);
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).withMultiPartHeaders().execute<IUserImportResponse>().then();
    }

    async getImportCarrierTemplate(): Promise<any> {
        const url = `${baseURL}/carriers/import/template`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<any>().then();
    }

    async postImportCarrierFile(excelFile: any): Promise<IUserImportResponse> {
        const url = `${baseURL}/carriers/import`;
        const formData = new FormData();
        formData.append("excelFile", excelFile);
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).withMultiPartHeaders().execute<IUserImportResponse>().then();
    }

    async getAdminCarriers(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/carriers`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getActiveCarriers(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/carriers/active`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getActiveAgents(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/agents/active`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getActiveOrganisms(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/organisms/active`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getActivePortLectors(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/portlectors/active`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getActiveTerminals(): Promise<IUserAdminData[]> {
        const url = `${baseURL}/terminals/active`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>();
    }

    async getRoleInspector(organismId: number): Promise<IComboBoxOption[]> {
        const url = `${baseURL}/inspectors?organismId=${organismId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>().then(mapUserToDropDownOptions);
    }

    async getAllRoleInspector(organismId?: string): Promise<IComboBoxOption[]> {
        const filter = organismId ? `?organismId=${organismId}` : "";
        const url = `${baseURL}/inspectors${filter}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserAdminData[]>().then(mapUserToDropDownOptions);
    }

    async getUserRepresentatives(): Promise<IRepresentative[]> {
        const url = `${baseURL}/me/representatives`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IRepresentative[]>();
    }

    async sendUserRepresentatives(body: IRepresentative): Promise<boolean> {
        const url = `${baseURL}/me/representatives`;
        const formData = new FormData();
        formData.append(compileNameOfProperty<IRepresentative>("representativeDocumentNumber"), body.representativeDocumentNumber);
        formData.append(compileNameOfProperty<IRepresentative>("document"), body.document || "");
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).execute<boolean>();
    }

    async getUserNotifications(): Promise<IUserNotifications[]> {
        const url = `${baseURL}/me/notifications`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IUserNotifications[]>();
    }

    async putActiveUserNotification(list: IUserNotifications[]): Promise<boolean> {
        const url = `${baseURL}/me/notifications`;
        return apiFetchOAuthWithClaims(Method.PUT, url)
            .withBody({ userNotifications: mapListUserNotifications(list) })
            .execute<boolean>();
    }
}
const mapListUserNotifications = (list: IUserNotifications[]): any => {
    return list.map((item: IUserNotifications) => {
        const isEmailList = item.emailList[0] === "" ? false : true;

        return {
            notificationId: item.notificationId,
            scheduleTimeOn: item.scheduleTimeOn ? formatHour(item.scheduleTimeOn) : null,
            email: isEmailList ? item.emailList.join(";") : null,
            active: item.active,
        };
    });
};

const mapUserSelfSignupToFormData = async (user: IUserSelfSignup): Promise<FormData> => {
    const formData = new FormData();

    formData.append(compileNameOfProperty<IUserSelfSignup>("id"), user.id);
    formData.append(compileNameOfProperty<IUserSelfSignup>("name"), user.name);
    formData.append(compileNameOfProperty<IUserSelfSignup>("surName"), user.surName);
    !!user.lastName && formData.append(compileNameOfProperty<IUserSelfSignup>("lastName"), user.lastName);
    formData.append(compileNameOfProperty<IUserSelfSignup>("phoneNumber"), user.phoneNumber);
    user?.image && formData.append(compileNameOfProperty<IUserSelfSignup>("image"), await getFileFromBlobUrl(user.image));

    return formData;
};

const mapUserSignupToFormData = async (user: IUserSignup): Promise<FormData> => {
    const formData = new FormData();

    formData.append(compileNameOfProperty<IUserSignupAPI>("userType"), user.userType);
    formData.append(compileNameOfProperty<IUserSignupAPI>("userPositionType"), user.userPositionType);
    formData.append(compileNameOfProperty<IUserSignupAPI>("name"), user.name);
    formData.append(compileNameOfProperty<IUserSignupAPI>("surName"), user.surName);
    !!user.lastName && formData.append(compileNameOfProperty<IUserSignupAPI>("lastName"), user.lastName);
    formData.append(compileNameOfProperty<IUserSignupAPI>("email"), user.email);
    formData.append(compileNameOfProperty<IUserSignupAPI>("phoneNumber"), user.phoneNumber);
    formData.append(compileNameOfProperty<IUserSignupAPI>("organizationId"), user.organizationId);
    user?.avatar?.file && formData.append(compileNameOfProperty<IUserSignupAPI>("image"), await getFileFromBlobUrl(user.avatar.file));

    !!user.entityData && formData.append(compileNameOfProperty<IUserSignupAPI>("entityData"), JSON.stringify(mapToEntityDataAPI(user.entityData)));
    !!user.paymentData &&
        user.userType !== UserType.TRUCKER &&
        formData.append(compileNameOfProperty<IUserSignupAPI>("paymentData"), JSON.stringify(mapToPaymentDataAPI(user.paymentData)));
    !!user.adminData &&
        user.userType !== UserType.TRUCKER &&
        formData.append(compileNameOfProperty<IUserSignupAPI>("adminData"), JSON.stringify(mapToAdminDataAPI(user.adminData)));
    !!user.adminData &&
        user.userType !== UserType.TRUCKER &&
        formData.append(compileNameOfProperty<IUserSignupAPI>("operatorData"), JSON.stringify(mapToAdminDataAPI(user.operatorData)));

    setDocumentTypes(formData, user.documents);
    return appendRegisterUserFiles(formData, user.documents);
};

const mapUserProfileToFormData = async (user: IUserProfile): Promise<FormData> => {
    const formData = new FormData();

    formData.append(compileNameOfProperty<IUserProfile>("name"), user.name);
    formData.append(compileNameOfProperty<IUserProfile>("surName"), user.surName);
    !!user.lastName && formData.append(compileNameOfProperty<IUserProfile>("lastName"), user.lastName);
    !!user.phoneNumber && formData.append(compileNameOfProperty<IUserProfile>("phoneNumber"), user.phoneNumber);
    !!user.adminData && formData.append(compileNameOfProperty<IUserProfile>("adminData"), JSON.stringify(mapToAdminDataAPI(user.adminData)));
    !!user.operatorData &&
        formData.append(compileNameOfProperty<IUserSignupAPI>("operatorData"), JSON.stringify(mapToAdminDataAPI(user.operatorData)));
    return formData;
};
const appendRegisterUserFiles = async (formData: FormData, documents: IRequestedDocument[]) => {
    if (documents.some((item) => item.file)) {
        await Promise.all(
            documents.map(async (document: IRequestedDocument) => {
                const blob = await getFileFromBlobUrl(document.file);
                blob &&
                    formData.append(compileNameOfProperty<IUserSignupAPI>("documents"), blob, `${String(document.documentId)}-${document.fileName}`);
            }),
        );
    }

    return formData;
};

const setDocumentTypes = (formData: FormData, documents: IRequestedDocument[]) => {
    const metadataList: MetadataItem[] = [];
    documents.forEach((doc) => {
        const metadata: MetadataItem = {
            DocumentId: String(doc.documentId),
            FileName: `${String(doc.documentId)}-${doc.fileName}` || "",
        };
        metadataList.push(metadata);
    });
    formData.append(compileNameOfProperty<IUserSignupAPI>("documentTypes"), JSON.stringify(metadataList));
};

const mapToEntityDataAPI = (entityData: IEntityData): IEntityDataAPI => {
    return {
        City: entityData.city,
        DocumentNumber: entityData.documentNumber,
        Name: entityData.name,
        PostalCode: entityData.postalCode,
        Province: entityData.province,
        SocialAddress: entityData.socialAddress,
    };
};

const mapToPaymentDataAPI = (paymentData: IPaymentData): IPaymentDataAPI => {
    return {
        PaymentMethod: paymentData.paymentMethod,
        AccountNumber: paymentData.accountNumber,
        BankEntity: paymentData.bankEntity,
    };
};

const mapToAdminDataAPI = (admindata: IAdminData): IAdminDataAPI => {
    return {
        Phone: admindata.phone,
        MobilePhone: admindata.mobilePhone,
        Emails: admindata.emails.map((x) => x.email),
    };
};

const mapToUserAuthData = (response: any): IUserAuthData => {
    return {
        email: response.id,
        name: response.name,
        lastName: response.lastName,
        surName: response.surName,
        phoneNumber: response.phoneNumber,
        organismId: response.organismId,
        image: response.imageBlob?.uri,
        organizations: response.organizations,
        roles: response.roles,
        parentId: response.parentId,
        principalRole: ""
    };
};

const mapToUserProfile = (response: any): IUserProfile => {
    return {
        image: response.imageBlob?.uri,
        email: response.id,
        hasImageChanged: false,
        lastName: response.lastName ? response.lastName : "",
        surName: response.surName ? response.surName : "",
        name: response.name,
        phoneNumber: response.phoneNumber,
        roles: response?.roles,
        adminData: mapToAdminData(response.adminData),
        entityData: response.entityData,
        operatorData: mapToAdminData(response.operatorData),
        paymentData: response.paymentData,
        blobs: response.blobs,
        representatives: response.representatives,
        hasRepresentativeData: response.hasRepresentativeData,
        hasNotificationData: response.hasNotificationData,
    };
};

export const mapToManagedUsers = (users: []): IUserManaged[] => {
    if (users?.length === 0) {
        return [];
    }
    return users.map(mapToManagedUser);
};

const mapToAdminData = (adminData: any): IAdminData => {
    if (adminData === null) {
        return { emails: [{ email: "", isValid: false }], mobilePhone: "", phone: "" };
    }
    if (adminData.emails === null || adminData.Emails?.length === 0) {
        return {
            mobilePhone: adminData.mobilePhone ? adminData.mobilePhone : "",
            phone: adminData.phone ? adminData.phone : "",
            emails: [{ email: "", isValid: false }],
        };
    } else {
        return {
            mobilePhone: adminData.mobilePhone ? adminData.mobilePhone : "",
            phone: adminData.phone ? adminData.phone : "",
            emails: adminData.emails.map((email: any) => {
                return {
                    email: String(email),
                    isValid: true,
                };
            }),
        };
    }
};

const mapToManagedUser = (user: any): IUserManaged => {
    return {
        name: user.name,
        surName: user.surName,
        imageUrl: user?.imageBlobUri,
        roleName: user?.roles ? user?.roles[0]?.name : "",
        lastName: user.lastName,
        id: user.id,
    };
};
