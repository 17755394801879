import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserEntitySignupDataContainerStyles = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        backgroundColor: theme.colors.site.background,
        height: "calc(100vh - 11.3rem)",
        display: "flex",
        flexDirection: "column",
        padding: theme.paddings.default,
        [mediaQueries.MOBILE]: {
            height: "100%",
        },
    },
    section: {
        paddingBottom: theme.paddings.sizes.lg,
        paddingRight: theme.paddings.sizes.base,
        display: "flex",
        flexDirection: "column",
        rowGap: 35,
    },
    divisor: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
    },
    buttonsRow: {
        flexFlow: "row",
        display: "flex",
        justifyContent: "end",
        columnGap: theme.paddings.default,
        paddingTop:15
      
    },
}));
