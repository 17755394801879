import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ColorType } from "../../../../../common/enum/ColorType";
import { IDockQueueRuleFormData } from "../../../../../models/management/dockQueueRule/IDockQueueRule";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setNameManagementDockQueueRuleForm, updateManagementDockQueueRuleSort } from "../../../../../redux/reducers/management/dockQueueRule/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { FormField } from "../../../../common/fields/fields";
import { FormNumericField } from "../../../../common/numberField/numericField";
import { Separator } from "../../../../common/separator/separator";
import { TitleH6 } from "../../../../common/titles/titles";
import { DockQueueRuleComponentContainer } from "./components/dockQueueRuleComponents/container";
import { DockQueueRulePrioritiesContainer } from "./components/dockQueueRulePriorities/container";
import { ManagementParkingValidationFormStyle } from "./formStyle.jss";

export const ManagementParkingValidationForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.DOCK_DISPLAY.FORM" });

    const theme = useTheme();
    const style = ManagementParkingValidationFormStyle({ theme });
    const { register } = useForm<IDockQueueRuleFormData>();
    const { description, minimumSort } = useAppSelector((state) => state.managementDockQueueRuleForm.form);
    const dispatch = useAppDispatch();

    const onInputChange = (event: any, _newValue?: any) => {
        const payload = getGenericPayloadFromEvent(event);
        dispatch(setNameManagementDockQueueRuleForm(payload.value));
    };

    const onNumberChange = useCallback(
        (event: any) => {
            dispatch(updateManagementDockQueueRuleSort(event.target.value ?? 0));
        },
        [dispatch],
    );

    return (
        <div className={style.parkingValidationContainer}>
            <div className={style.parkingValidationName}>
                <FormField
                    type="text"
                    value={description}
                    label={t("NAME")}
                    isRequired={true}
                    {...register(compileNameOfProperty<IDockQueueRuleFormData>("description"), {
                        onChange: onInputChange,
                        value: description,
                        required: true,
                    })}
                />
            </div>

            <div className={style.parkingValidationFormContainer}>
                <div className={style.parkingValidationRestrictionBox}>
                    <div className={style.utiContainer}>
                        <div>{t("SORT")}</div>
                        <div>
                            <FormNumericField
                                minNumber="1"
                                value={minimumSort ? minimumSort.toString() : ""}
                                {...register(compileNameOfProperty<IDockQueueRuleFormData>("minimumSort"), {
                                    onChange: onNumberChange,
                                    value: minimumSort,
                                })}
                            />
                        </div>
                    </div>

                    <TitleH6
                        color={ColorType.black}
                        bold={true} 
                        title={t("COMPONENT_BOX")}
                    />
                    <div className={style.box}>
                        <DockQueueRuleComponentContainer />
                    </div>
                </div>

                <Separator size="100%" />
                <div className={style.parkingValidationExceptionBox}>
                    <TitleH6
                        color={ColorType.black}
                        bold={true}
                        title={t("EXCEPTION_BOX")}
                    />

                    <div className={style.box}> 
                        <DockQueueRulePrioritiesContainer />
                    </div>
                </div>
            </div>
        </div>
    );
};
