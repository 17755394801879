import { FormatDate } from "../../common/enum/dateTime/FormatDate";
import { Method } from "../../common/enum";
import { ICreateDraft, ICreateRequest, IRequestDetail, IResumeCreateRequest, ITransportUnitData } from "../../models";
import { apiFetchOAuthWithClaims } from "../../services/apiClient";
import { convertToLocal } from "../../utils/dateUtils";
import { getCustomerReference } from "../../services/user/customerService";
import { store } from "../../redux/store";

const baseURL = `${process.env.REACT_APP_API_URL}/requests/drafts`;

export class DraftApi {
    async getDraftAsync(): Promise<IRequestDetail[]> {
        const url = `${baseURL}/search`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({}).execute<IRequestDetail[]>();
    }
    async getRequestDraftAsync(groupId: string): Promise<ICreateRequest> {
        const url = `${baseURL}/${groupId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ICreateRequest>().then(mapToEditRequest);
    }
    async deleteRequestDraftAsync(groupId: string): Promise<IRequestDetail[]> {
        const url = `${baseURL}/${groupId}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<IRequestDetail[]>();
    }
    async createRequestDraftAsync(requestBody: ICreateDraft): Promise<IResumeCreateRequest[]> {
        const url = `${baseURL}/create`;
        const options = store.getState().usersByRole.invoiceCustomers.options;
        const representative = getCustomerReference(+requestBody.invoiceCustomerId, options);
        const requestCopy: ICreateDraft = {
            ...requestBody,
            invoiceCustomerId: requestBody.invoiceCustomerId,
            transportUnits: mapToAPITransportUnits(requestBody.transportUnits),
            representativeId: representative ?? null,
            date: new Date(convertToLocal(requestBody.date, FormatDate.ISO)),
        };
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(requestCopy).execute<IResumeCreateRequest[]>();
    }
    async submitRequestDraftAsync(groupId: string): Promise<IRequestDetail[]> {
        const url = `${baseURL}/${groupId}/submit`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).execute<IRequestDetail[]>();
    }
}

const mapToEditRequest = (body: any): ICreateRequest => {
    return {
        ...body,
        date: new Date(body.date),
    };
};

const mapToAPITransportUnits = (utis: ITransportUnitData[]): any => {
    if (utis.length === 0) {
        return null;
    }

    return utis.map((uti) => {
        return {
            ...uti,
            seals: uti.seals.map((seal) => seal),
            grossWeight: uti.grossWeight ? +uti.grossWeight : "0",
            packages: uti.packages ? +uti.packages : "0",
        };
    });
};
