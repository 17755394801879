import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterCertificateTypesMasterController } from "../controller/controller";
import { MasterCertificateTypesColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/constants";
import { fetchMasterTypesCertificateList } from "../../../../../../redux/actions/resources/master/certificateType/certificateType";
interface IMasterTypeCertificateListContainer {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterTypeCertificateListContainer: React.FC<IMasterTypeCertificateListContainer> = ({ heigthPopUp, widthPopUp }) => {
    const { list, loading } = useAppSelector((state) => state.masterCertificateTypeList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return MasterCertificateTypesColumns();
    };
    useEffect(() => {
        !list.length && !loading && dispatch(fetchMasterTypesCertificateList());
    }, []);
    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={list}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 10rem)"}
                />
            )}
            <MasterCertificateTypesMasterController
                heigthPopUp={heigthPopUp}
                widthPopUp={widthPopUp}
            />
        </div>
    );
};
