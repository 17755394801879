import React,{ useEffect } from "react";
import { fetchdockBlocks } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../../../common/selectors/customSelector/customSelector";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const CustomDockBlockSelector: React.FC<ISelectorProps> = ({ onChange, title, name, selectedKey, disabled }) => {
    const { data, loading } = useAppSelector((store) => store.catalog.dockBlock);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchdockBlocks());
    }, [dispatch, data]);

    return (
        <CustomSelector
            title={title}
            options={mapIDescriptionToDropDownOptions(data.map((item)=> {
                return {
                    id:item.id, 
                     description: `${item.code} - ${item.description??DefaultValues.EMPTY_LIST}`
                }
            }))}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabled !== undefined ? disabled : loading}
        />
    );
};
