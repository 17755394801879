import { DetailsRow, IDetailsFooterProps } from "@fluentui/react/lib/DetailsList";
import { t } from "i18next";
import { useCallback, useEffect } from "react";
import { useTheme } from "react-jss";
import { IPagination } from "../../../../models/common/IPagination";
import { fetchInvoicingUninvoicedAction, setPageAction } from "../../../../redux/actions/invoicing/uninvoiced/uninvoiced";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { InvoicingUninvoicedColumn } from "./column/column";
import { InvoicingUninvoicedColumnFooter } from "./column/columnFooter";
import { InvoicingUninvoicedListStyle } from "./listStyle.jss";
import { SkeletonSizes } from "../../../../common/enum/sizes";
import { sortArray } from "../../../../utils";

export const InvoicingUninvoicedList = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = InvoicingUninvoicedListStyle({ theme });
    const { list, pagination, totalAmount, loading, sort } = useAppSelector((state) => state.invoicingUninvoicedConceptList);

    useEffect(() => {
        list === undefined && dispatch(fetchInvoicingUninvoicedAction());
    }, [dispatch, list]);

    const onPageUp = useCallback(
        (skip: number) => {
            const currentPage: number = skip + 1;
            if (currentPage <= pagination.totalPages) {
                dispatch(setPageAction(currentPage));
            }
        },
        [pagination.totalPages],
    );

    const onFirstPage = useCallback((skip: number) => {
        if (skip !== 1) {
            dispatch(setPageAction(1));
        }
    }, []);

    const onPageDown = useCallback((skip: number) => {
        const currentPage: number = skip - 1;
        if (currentPage >= 1) {
            dispatch(setPageAction(currentPage));
        }
    }, []);

    const onLastPage = useCallback(
        (skip: number) => {
            if (skip !== pagination.totalPages) {
                dispatch(setPageAction(pagination.totalPages));
            }
        },
        [pagination.totalPages],
    );

    const paginationProps: IPagination = {
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
        firstItemNumber: pagination.firstItemNumber,
        lastItemNumber: pagination.lastItemNumber,
        footerTitle: t("INVOICED.FOOTERTABLE.PAGINATION_FOOTER", {
            firstItemNumber: pagination.firstItemNumber,
            lastItemNumber: pagination.lastItemNumber,
            totalItems: pagination.totalItems,
        }),
        onFirstPage,
        onLastPage,
        onPageDown,
        onPageUp,
    };

    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
            totalAmount: totalAmount,
        };
        const footerColumns = InvoicingUninvoicedColumnFooter(dataFooter);

        return (
            <>
                <DetailsRow
                    {...detailsFooterProps}
                    columns={footerColumns}
                    item={{}}
                    itemIndex={5}
                    groupNestingDepth={detailsFooterProps.groupNestingDepth}
                    selection={detailsFooterProps.selection}
                    styles={style}
                />
            </>
        );
    };

    return (
        <>
            {!loading ? (
                <>
                    <CustomDetailsList
                        data={sortArray(list, sort) ?? []}
                        renderColumns={InvoicingUninvoicedColumn}
                        height={heights.invoiceListHeigt}
                        pagination={paginationProps}
                        customFooter={_onRenderDetailsFooter}
                    />
                </>
            ) : (
                <>
                    <Skeleton rows={SkeletonSizes.DEFAULT} />
                </>
            )}
        </>
    );
};
