import { useMemo } from "react";
import { IField, IUserRoleView, IView } from "../../../../../models/requests/IUserRoleView";
import { IColumn } from "@fluentui/react";
import { useAppSelector } from "../../../../../redux/hooks";
import { ViewType } from "../../../../../models";

export const UpcomingAppointmentsSortColumnsSchema = (columns: IColumn[], featureId: string): IColumn[] => {
    const viewColumns: IColumn[] = [];
    let columnSchema: IField[] = [];
    const { viewPermission } = useAppSelector((state) => state.userRoleViews);
    const viewRequestPermision: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === featureId);

    const viewConfig = useMemo(() => {
        return viewRequestPermision ? (viewRequestPermision?.views.find((view) => view.type === ViewType.LIST) as IView) : null;
    }, [viewRequestPermision?.views]);

    columnSchema = viewConfig ? [...viewConfig.fields] : [];

    columnSchema.forEach((value) => {
        const columnFilter = columns.filter((column) => column.key.toLocaleLowerCase() === value.name.toLocaleLowerCase()).find(() => true);
        if (columnFilter) {
            viewColumns.push(columnFilter);
        }
    });
    return viewColumns;
};
