import { useSelector } from "react-redux";
import { RoleType } from "../../common/enum";
import { getUserRole } from "../../redux/selectors/user/role/role";

export const useRoleType = (roles: RoleType[]): boolean => {
    const { id: userRoleId } = useSelector(getUserRole);

    const isRoleTypeSelected = roles.some((rol) => rol === (userRoleId as RoleType));

    return isRoleTypeSelected;
};
