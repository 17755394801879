import { useTheme } from "react-jss";
import { generateRandomKeys } from "../../../../utils/keys";
import { ColumnType } from "../tableCustom";
import { IsShowCustomTableListCell } from "../utils/utils";
import { TableCustomHeaderStyle } from "./tableHeaderStyle.jss";

type TableCustomHeader<T, K extends keyof T> = {
    columns: Array<ColumnType<T, K>>;
};

export const TableHeaderCustom = <T, K extends keyof T>({ columns }: TableCustomHeader<T, K>): JSX.Element => {
    const theme = useTheme();
    const style = TableCustomHeaderStyle({ theme });

    const header = columns.map((column, index) => {
        return (
            IsShowCustomTableListCell(column.showColumn) && (
                <div
                    className={`th ${column.className ? column.className : ""}`}
                    style={{
                        width: column.width ? column.width : "20%",
                        textAlign: "left",
                        paddingRight: column.padding ? column.padding : "15px",
                    }}
                    key={`th-${generateRandomKeys()}`}
                >
                    {column.actions ? column.actions : column.title}
                </div>
            )
        );
    });

    return <div className={style.header}>{header}</div>;
};
