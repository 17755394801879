export enum SortTableName {
    RequestDatePermission = "RequestDatePermission",
    Organism = "Organism",
    MerchandiseOrigin = "MerchandiseOrigin",
    CertificateTypes = "CertificateType",
    Turns = "Turns",
    Block = "DockBlock",
    MerchandiseCategory = "MerchandiseCategory",
    Dock = "Dock",
    DocumentTypes = "DocumentType",
    FinalDestinationTypes = "FinalDestinationType",
    PresenceRepresentative = "RepresentativePresenceType",
    SealTypes = "SealType",
    ActuationGroup = "ActuationTypeGroup",
    ActuationType = "ActuationType",
    CustomState = "CustomsState",
    NotificationType = "NotificationType",
    ValidationParkingRule = "ValidationParkingRule",
    DockQueueRule = "DockQueueRule",
}
