import { createUseStyles } from "react-jss";

export const UserSignupContainerStyle = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        paddingTop: "2vh",
        height: "calc(100vh - 12rem)",
        display: "flex",
        flexFlow: "row nowrap",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: theme.gaps.sizes.md,
    },
}));
