import { IDropdownOption } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";

import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { MasterMerchandiseCategoryFormStyle } from "./formStyle.jss";
import { useCallback, useEffect, useMemo } from "react";
import { Selector } from "../../../../../../common/selectors/selector";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";



import { FormField } from "../../../../../../common/fields/fields";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { fetchMerchandiseCategoryGroup } from "../../../../../../../redux/actions/catalog/catalog";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterMerchandiseCategoryRemaining, IMasterMerchandiseCategory } from "../../../../../../../models/resources/master/IMerchandiseCategory";
import { updateMasterMerchandiseCategoryProperty, setMasterMerchandiseCategoryForm } from "../../../../../../../redux/reducers/resources/master/merchandiseCategory/form";





interface IMasterMerchandiseCategoryFormProps {
    selectorData: IMasterMerchandiseCategoryRemaining[];
    showNotData: boolean;
}

export const MasterMerchandiseCategoryForm: React.FC<IMasterMerchandiseCategoryFormProps> = ({ selectorData, showNotData }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = MasterMerchandiseCategoryFormStyle({ theme });
    const { form } = useAppSelector((state) => state.masterMerchandiseCategoryForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);

    const { register, control } = useForm<IMasterMerchandiseCategory>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateMasterMerchandiseCategoryProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };

    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateFormProperty(name, option.key);
            const merchandiseCategory = selectorData.find((item) => {
                return item.id === option.key;
            });
            if (merchandiseCategory) {
                let newForm: IMasterMerchandiseCategory = {
                    active: true,
                    code: merchandiseCategory.code,
                    description: merchandiseCategory.description,
                    referenceId: String(option.key ?? merchandiseCategory.id),
                };
                dispatch(setMasterMerchandiseCategoryForm(newForm));
            }
        },
        [dispatch, selectorData],
    );

    const { data } = useAppSelector((store) => store.catalog.merchandiseCategoryGroup);

    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    useEffect(() => {
        !data.length && dispatch(fetchMerchandiseCategoryGroup());
    }, []);

    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {showPopUp === PopUpCodes.CREATE && (
                <Selector
                    name={compileNameOfProperty<IMasterMerchandiseCategory>("referenceId")}
                    title={t("FORM.MERCHANDISE_CATEGORY_SELECTOR")}
                    options={mapIDescriptionToDropDownOptions(selectorData)}
                    onChange={onSelectorChange}
                    selectedKey={Number(form.referenceId)}
                    required={true}
                />
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterMerchandiseCategory>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterMerchandiseCategory>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    disabled={disabledInput}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <Selector
                    name={compileNameOfProperty<IMasterMerchandiseCategory>("merchandiseCategoryGroupId")}
                    title={t("FORM.MERCHANDISE_CATEGORY_GROUP")}
                    options={mapIDescriptionToDropDownOptions(data)}
                    onChange={onSelectorChange}
                    selectedKey={Number(form.merchandiseCategoryGroupId)}
                    disabled={disabledInput}
                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterMerchandiseCategory>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
