import { useEffect } from "react";
import { fetchRequestDatePermisionListAction } from "../../../../redux/actions/management/requestdatepermision/requestdatepermision";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementRequestDatePermisionController } from "../controller/controller";
import { ManagementRequestDatePermisionListColumn } from "./column/column";
import { SkeletonSizes } from "../../../../common/enum/sizes";

export const ManagementRequestDatePermisionList = () => {
    const { list, loading } = useAppSelector((state) => state.managementRequestDatePermisionList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return ManagementRequestDatePermisionListColumn();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchRequestDatePermisionListAction());
    }, [list]);

    return (
        <>
            {!loading ? (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                    height={heights.tariffListHeigt}
                />
            ) : (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            )}

            <ManagementRequestDatePermisionController />
        </>
    );
};
