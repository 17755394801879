import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    GenericOptionsWithLoading,
    IGenericUsersData,
    IInvoiceEntity,
    IUserAdminData,
    emptyDropdownOptionsWithLoading,
    emptyUsersDataWithLoading,
} from "../../../../models";

interface DropDownOptionsCatalogState {
    invoiceCustomers: GenericOptionsWithLoading<IInvoiceEntity>;
    customerAdmin: IGenericUsersData;
    carrierAdmin: GenericOptionsWithLoading<IComboBoxOption>;
    inspector: GenericOptionsWithLoading<IComboBoxOption>;
    allInspectors: GenericOptionsWithLoading<IComboBoxOption>;
    activeCustomers: IGenericUsersData;
    activeCarriers: IGenericUsersData;
    activeAgents: IGenericUsersData;
    activeOrganisms: IGenericUsersData;
    activePortLectors: IGenericUsersData;
    activeTerminals: IGenericUsersData;
}

const initialState: DropDownOptionsCatalogState = {
    customerAdmin: emptyUsersDataWithLoading(),
    invoiceCustomers: emptyDropdownOptionsWithLoading(),
    carrierAdmin: emptyDropdownOptionsWithLoading(),
    inspector: emptyDropdownOptionsWithLoading(),
    allInspectors: emptyDropdownOptionsWithLoading(),
    activeCustomers: emptyUsersDataWithLoading(),
    activeCarriers: emptyUsersDataWithLoading(),
    activeAgents: emptyUsersDataWithLoading(),
    activeOrganisms: emptyUsersDataWithLoading(),
    activePortLectors: emptyUsersDataWithLoading(),
    activeTerminals: emptyUsersDataWithLoading(),
};

export const catalogSlice = createSlice({
    name: "usersByRoleState",
    initialState,
    reducers: {
        resetUsersByRole: () => initialState,
        setCustomerAdminOptions: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.customerAdmin.data = action.payload;
        },
        loadingCustomerAdminOptions: (state, action: PayloadAction<boolean>) => {
            state.customerAdmin.loading = action.payload;
        },
        setInvoiceCustomersOptions: (state, action: PayloadAction<IInvoiceEntity[]>) => {
            state.invoiceCustomers.options = action.payload;
        },
        loadingInvoiceCustomersOptions: (state, action: PayloadAction<boolean>) => {
            state.invoiceCustomers.loading = action.payload;
        },
        setCarrierAdminOptions: (state, action: PayloadAction<IDropdownOption<IComboBoxOption>[]>) => {
            state.carrierAdmin.options = action.payload;
        },
        loadingCarrierAdminOptions: (state, action: PayloadAction<boolean>) => {
            state.carrierAdmin.loading = action.payload;
        },
        setInspectorOptions: (state, action: PayloadAction<IComboBoxOption[]>) => {
            state.inspector.options = action.payload;
        },
        loadingInspectorOptions: (state, action: PayloadAction<boolean>) => {
            state.inspector.loading = action.payload;
        },
        setAllInspectorOptions: (state, action: PayloadAction<IComboBoxOption[]>) => {
            state.allInspectors.options = action.payload;
        },
        loadingAllInspectorOptions: (state, action: PayloadAction<boolean>) => {
            state.allInspectors.loading = action.payload;
        },
        setActiveCustomers: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.activeCustomers.data = action.payload;
        },
        loadingActiveCustomers: (state, action: PayloadAction<boolean>) => {
            state.activeCustomers.loading = action.payload;
        },
        setActiveCarriers: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.activeCarriers.data = action.payload;
        },
        loadingActiveCarriers: (state, action: PayloadAction<boolean>) => {
            state.activeCarriers.loading = action.payload;
        },
        setActiveAgents: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.activeAgents.data = action.payload;
        },
        loadingActiveAgents: (state, action: PayloadAction<boolean>) => {
            state.activeAgents.loading = action.payload;
        },
        setActiveOrganisms: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.activeOrganisms.data = action.payload;
        },
        loadingActiveOrganisms: (state, action: PayloadAction<boolean>) => {
            state.activeOrganisms.loading = action.payload;
        },
        setActivePortLectors: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.activePortLectors.data = action.payload;
        },
        loadingActivePortLectors: (state, action: PayloadAction<boolean>) => {
            state.activePortLectors.loading = action.payload;
        },
        setActiveTerminals: (state, action: PayloadAction<IUserAdminData[] | undefined>) => {
            state.activeTerminals.data = action.payload;
        },
        loadingActiveTerminals: (state, action: PayloadAction<boolean>) => {
            state.activeTerminals.loading = action.payload;
        },
    },
});

export const {
    resetUsersByRole,
    setCarrierAdminOptions,
    setCustomerAdminOptions,
    setInvoiceCustomersOptions,
    loadingCarrierAdminOptions,
    loadingCustomerAdminOptions,
    loadingInvoiceCustomersOptions,
    setInspectorOptions,
    loadingInspectorOptions,
    setAllInspectorOptions,
    loadingAllInspectorOptions,
    setActiveCustomers,
    loadingActiveCustomers,
    setActiveCarriers,
    loadingActiveCarriers,
    setActiveAgents,
    loadingActiveAgents,
    setActiveOrganisms,
    loadingActiveOrganisms,
    setActivePortLectors,
    loadingActivePortLectors,
    setActiveTerminals,
    loadingActiveTerminals,
} = catalogSlice.actions;

export default catalogSlice.reducer;
