import { IDropdownOption } from "@fluentui/react";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { fetchFinalDestinations } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions, mapIDescriptionToDropdownOption } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface IFinalDestinationSelectorProps extends IBaseSelectorProps {
    setValue: UseFormSetValue<any>;
}

export const FinalDestinationSelector: React.FC<IFinalDestinationSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    disabled,
    isRequired,
    control,
    rules,
    setValue,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.finalDestinations);
    const dispatch = useAppDispatch();
    const [selectorkey, setSelectorKey] = useState<number>(0);

    useEffect(() => {
        data.length === 0 && dispatch(fetchFinalDestinations());
        data.length !== 0 && setDefaultValueIfApply();
    }, [dispatch, data]);

    const onSelectorChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };

    const setDefaultValueIfApply = () => {
        if (!selectedKey) {
            const item = data.find((item) => item.defaultValue);
            if (item) {
                onChange(mapIDescriptionToDropdownOption(item), propertyName);
                setValue(propertyName, item.id);
                setSelectorKey(item.id);
            }
        }
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={mapIDescriptionToDropDownOptions(data)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
            key={`${FinalDestinationSelector.name}-${selectorkey}`}
        />
    );
};
