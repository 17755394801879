import dayjs from "dayjs";
import { AppointmentsAPI } from "../../../api/appointments";
import { IQueryZoneAvaiable } from "../../../models/appointment/IDashboardZonesAvaiable";
import { convertToLocal, throwErrorToastrAsync } from "../../../utils";
import { setAppointmentBoard, setLoadingAppointmentBoard } from "../../reducers/appointment/board";
import { setLoadingRequestUpcomingAppointmentsList, setRequestUpcomingAppointmentsList } from "../../reducers/upcomingAppointments/list";
import { AppThunk, store } from "../../store";
import { setAppointmentAvailableZone, setForceAppointmentType } from "../../reducers/appointment/zoneAvailable";
import { IAppointmentAssign } from "../../../models/appointment/IAppointmentAssign";
import { fetchEditRequestData } from "../request/edit/action/requestEdit";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { setGlobalLoading } from "../../reducers/common/globalLoading";
import { resetAppoitmentType, setCanAssignAppointment } from "../../reducers/request/edit/editRequest";
import { IEditRequest } from "../../../models/requests/IEditRequest";
import { setGlobalLoadingAction } from "../common";
import { ICanAssignAppointment, ICheckAssignAppointmentRequest } from "../../../models/requests/editRequest/IAppointment";
import { resetAppointmentTypeForm, setAppointmentFormLoading } from "../../reducers/request/edit/form/appointment";
import { AppointmentTypes } from "../../../common/enum/request/AppointmentTypes";
import { UserApi } from "../../../api";

const appointmentsApi = new AppointmentsAPI();

export const fetchRequestAppointmentsListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().requestUpcomingAppointmentsFilter;
        dispatch(setLoadingRequestUpcomingAppointmentsList(true));
        const response = await appointmentsApi.getAppointmentsList(filter);
        dispatch(setRequestUpcomingAppointmentsList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingRequestUpcomingAppointmentsList(false));
    }
};
export const fetchExportRequestAppointmentsListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().requestUpcomingAppointmentsFilter;

        dispatch(setGlobalLoading(true));
        const response = await appointmentsApi.getExportAppointmentsList(filter);
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchAppointmentsBoardAction =
    (date: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingAppointmentBoard(true));
            const response = await appointmentsApi.appointmentBoard(dayjs(date).format("YYYY-MM-DD"));
            dispatch(setAppointmentBoard(response));
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingAppointmentBoard(false));
        }
    };

export const postAppointmentsBoardTimeZoneLockAction =
    (date: string, appointmentDockId: number, appointmentTimeZoneId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingAppointmentBoard(true));
            await appointmentsApi.appointmentBoardTimeZoneLock(date, appointmentDockId, appointmentTimeZoneId);
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingAppointmentBoard(false));
        } finally {
            dispatch(fetchAppointmentsBoardAction(date));
        }
    };

export const deleteAppointmentsBoardTimeZoneLockAction =
    (id: number, date: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingAppointmentBoard(true));
            await appointmentsApi.deleteAppointmentBoardTimeZoneLock(id);
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingAppointmentBoard(false));
        } finally {
            dispatch(fetchAppointmentsBoardAction(date));
        }
    };

export const postAppointmentAssignAction = (data: IAppointmentAssign, request: IEditRequest): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingAppointmentBoard(true));
            dispatch(setAppointmentFormLoading(true));
            const appointmentAssign: any = {
                ...request,
                appointmentTypeId: data.appointmentTypeId,
                timeZoneId: data.timeZoneId,
                timeZoneSort: data.timeZoneSort,
                dashboardId: data.dashboardId,

                requestId: request.id,
                date: new Date(convertToLocal(request.date, FormatDate.ISO)),
            };
            await appointmentsApi.postAppointmentAssign(appointmentAssign);
            dispatch(fetchEditRequestData(data.requestId.toString()));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingAppointmentBoard(false));
            dispatch(setAppointmentFormLoading(false));
        }
    };
};

export const fetchAppointmentsZonesAvaiablesAction =
    (data: IQueryZoneAvaiable): AppThunk =>
    async (dispatch) => {
        try {
            const { editRequest, editRequestAppointment, appointmentAvailableZone } = store.getState();
            const appointmentTypeId = editRequest.headerRequest.appointmentTypeId;
            const formAppointmentTypeId = editRequestAppointment.form.appointmentTypeId;
            const isForceAppointmentType = appointmentAvailableZone.forceAppointmentType;
            dispatch(setLoadingAppointmentBoard(true));

            const response = await appointmentsApi.getZonesAvailables(data);

            let forceAppointmentType: boolean = false;

            if (!isForceAppointmentType && !editRequest.headerRequest.appointmentAssigned) {
                forceAppointmentType = formAppointmentTypeId === AppointmentTypes.SIMPLE && response.appointmentTypeId === AppointmentTypes.DOUBLE;
                dispatch(setForceAppointmentType(forceAppointmentType));
            }

            if (!isForceAppointmentType && editRequest.headerRequest.appointmentAssigned) {
                forceAppointmentType = appointmentTypeId === AppointmentTypes.SIMPLE && response.appointmentTypeId === AppointmentTypes.DOUBLE;
                dispatch(setForceAppointmentType(forceAppointmentType));
            }

            dispatch(setAppointmentAvailableZone(response));

            if (forceAppointmentType) {
                dispatch(resetAppoitmentType(response.appointmentTypeId));
                dispatch(resetAppointmentTypeForm(response.appointmentTypeId));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingAppointmentBoard(false));
        }
    };

export const fetchGetAppointmentPermission = (form: ICanAssignAppointment): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoading(true));
            const response = await appointmentsApi.getAppointmentPermission(form);
            dispatch(setCanAssignAppointment(response));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };
};

export const removeAppointmentRequest = (requestId: string, cancelLoader?: boolean): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await appointmentsApi.removeAppointmentRequest(requestId);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setGlobalLoadingAction(false));
        } finally {
            cancelLoader && dispatch(setGlobalLoadingAction(false));
        }
    };
};

export const checkAssignAppointmentRequest = (checkAssignAppointment: ICheckAssignAppointmentRequest): any => {
    return async (dispatch: any) => {
        try {
            const response = await appointmentsApi.checkAssignAppointmentRequest(checkAssignAppointment);
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };
};
