import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../models/common/IGenericPayload";
import { IUpcomingAppointmentFilter } from "../../../models/upcomingAppointment/IUpcomingAppointments";
import { DefaultValues } from "../../../common/enum/DefaultValues";

interface IUpcomingAppointmentsFilterState {
    filter: IUpcomingAppointmentFilter;
}

const initialState: IUpcomingAppointmentsFilterState = {
    filter: {
        customerId: DefaultValues.DEFAULT_ALL_SELECTOR as string,
        inspectorId: DefaultValues.DEFAULT_ALL_SELECTOR as string,
        merchandiseCategoryId: DefaultValues.DEFAULT_ALL_SELECTOR as string,
        organismId: DefaultValues.DEFAULT_ALL_SELECTOR as string,
        merchandiseTypeId:DefaultValues.DEFAULT_ALL_SELECTOR as string,
        endDate:new Date().toDateString(),
        startDate:new Date().toDateString(),
    },
};

export const upcomingAppointmentsFilterSlice = createSlice({
    name: "upcomingAppointmentsFilterSlice",
    initialState,
    reducers: {
        resetUpcomingAppointmentsFilter: () => initialState,
        updateUpcomingAppointmentsFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetUpcomingAppointmentsFilter, updateUpcomingAppointmentsFilterProperty } = upcomingAppointmentsFilterSlice.actions;

export default upcomingAppointmentsFilterSlice.reducer;
