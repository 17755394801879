import { createUseStyles } from "react-jss";

export const ForbiddenStyled = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        backgroundColor: theme.colors.site.background,
        paddingTop: "2vh",
        height: "calc(100vh - 12rem)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.paddings.default,
    },
    container: {
        marginTop: "-27px",
        display: "flex",
        flexDirection: "column",
        width: "600px",
        height: "300px",
        border: "1px solid #ddd",
        justifyContent: "center",
        backgroundColor: "#f2f4f7",
        boxShadow: "7px 10px 10px 0 rgb(0 0 0 / 20%)",
    },
    mainRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    iconColumn: {
        display: "flex",
        flexDirection: "column",
        fontSize: "130px",
        paddingLeft: "30px",
        color: theme.colors.titles.primary.color,
    },
    panelColumn: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",paddingRight:10
    },
    titleRow: {
        display: "flex",
        flexDirection: "row",
        fontWeight: "700",
        paddingBottom: "20px",
    },
    messageRow: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: "20px",
        
    },
    buttonRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "stretch",
    },
    button: {
        width: "100%",
    },
}));
