import React from "react";
import { InvoicingNonSynchronizedContainer } from "./container";
import { store } from "../../../redux/store";
import { resetInvoiceNonSynchronized } from "../../../redux/reducers/invoicing/nonSynchronized/list";

export class InvoicingNonSynchronizedController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetInvoiceNonSynchronized());
    }

    render(): React.ReactNode {
        return <InvoicingNonSynchronizedContainer />;
    }
}
