import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { emptyManagementUserFilter, IManagementUserFilter } from "../../../../models/management/user/IManagementUserFilter";

interface IManagementUserFilterState {
    filter: IManagementUserFilter;
}

const initialState: IManagementUserFilterState = {
    filter: emptyManagementUserFilter(),
};

export const managementUserFilterSlice = createSlice({
    name: "managementUserFilter",
    initialState,
    reducers: {
        managementUserFilterReset: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { managementUserFilterReset, updateFilterProperty } = managementUserFilterSlice.actions;

export default managementUserFilterSlice.reducer;
