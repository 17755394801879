import { routeUrls } from "../../common/constants";
import { OrganismTypes } from "../../common/enum";

/**
 * Get route url for organism edit request
 * @param organism Organism type enum value
 * @returns route url for organism edit request
 */
export const OrganismEditRequestRoute = (organism: OrganismTypes): string => {
    const routeMap = {
        [OrganismTypes.FOREIGN_HEALTH]: routeUrls.ORGANISM_FOREIGN_HEALTH_EDIT_REQUEST,
        [OrganismTypes.PLANT_HEALTH]: routeUrls.ORGANISM_VEGETABLE_HEALTH_EDIT_REQUEST,
        [OrganismTypes.SOIVRE]: routeUrls.ORGANISM_SOIVRE_EDIT_REQUEST,
        [OrganismTypes.ANIMAL_HEALTH]: routeUrls.ORGANISM_ANIMAL_HEALTH_EDIT_REQUEST,
        [OrganismTypes.CUSTOMS]: routeUrls.ORGANISM_CUSTOMS_EDIT_REQUEST,
        [OrganismTypes.PHARMACY]: routeUrls.ORGANISM_PHARMACY_EDIT_REQUEST,
        [OrganismTypes.FISHING]: routeUrls.ORGANISM_FISHING_EDIT_REQUEST,
        [OrganismTypes.OTHERS]: routeUrls.ORGANISM_OTHERS_EDIT_REQUEST,
        [OrganismTypes.TAX_CANARY]: routeUrls.ORGANISM_CANARIAN_EDIT_REQUEST,
        [OrganismTypes.BORDER_INSPECTION_BODY]: routeUrls.ORGANISM_BORDER_INSPECTION_EDIT_REQUEST,
    };

    return routeMap[organism];
};

/**
 * Get route url for organism request list
 * @param organism Organism type enum value
 * @returns route url for organism request list
 */
export const OrganismRequestRoute = (organism: OrganismTypes): string => {
    const routeMap = {
        [OrganismTypes.FOREIGN_HEALTH]: routeUrls.ORGANISM_FOREIGN_HEALTH_REQUEST,
        [OrganismTypes.PLANT_HEALTH]: routeUrls.ORGANISM_VEGETABLE_HEALTH_REQUEST,
        [OrganismTypes.SOIVRE]: routeUrls.ORGANISM_SOIVRE_REQUEST,
        [OrganismTypes.ANIMAL_HEALTH]: routeUrls.ORGANISM_ANIMAL_HEALTH_REQUEST,
        [OrganismTypes.CUSTOMS]: routeUrls.ORGANISM_CUSTOMS_REQUEST,
        [OrganismTypes.PHARMACY]: routeUrls.ORGANISM_PHARMACY_REQUEST,
        [OrganismTypes.FISHING]: routeUrls.ORGANISM_FISHING_REQUEST,
        [OrganismTypes.OTHERS]: routeUrls.ORGANISM_OTHERS_REQUEST,
        [OrganismTypes.TAX_CANARY]: routeUrls.ORGANISM_CANARIAN_REQUEST,
        [OrganismTypes.BORDER_INSPECTION_BODY]: routeUrls.ORGANISM_BORDER_INSPECTION_REQUEST,
    };

    return routeMap[organism];
}