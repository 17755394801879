import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const TableInspectionStyles = createUseStyles((theme: any) => ({
    generalcontainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.md,
        width: theme.widths.sectionContainer.complete,
        padding: theme.paddings.default,
        overflowY: "scroll",
        height: theme.heights.requestEditContent,
        [mediaQueries.TABLET]: {
            height: theme.heights.requestEditMobileContent,
        },
        background:"white"
    },
    inspectionHeader: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.gaps.sizes.md,
    },
}));
