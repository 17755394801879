import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { convertToLocal } from "../../../../utils";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IRequestIntegrationFilter } from "../../../../models";

interface IRequestIntegrationFilterState {
    filter: IRequestIntegrationFilter;
}

const initialState: IRequestIntegrationFilterState = {
    filter: {
        endDate: convertToLocal(new Date(), FormatDate.ISO),
        startDate: convertToLocal(new Date(), FormatDate.ISO),
        integrationReference: "",
        withErrors: undefined,
        transportUnitNumber: "",
    },
};

export const requestIntegrationFilterReducer = createSlice({
    name: "requestIntegrationFilterReducer",
    initialState,
    reducers: {
        resetRequestIntegrationFilter: () => initialState,
        updateRequestIntegrationFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetRequestIntegrationFilter, updateRequestIntegrationFilterProperty } = requestIntegrationFilterReducer.actions;

export default requestIntegrationFilterReducer.reducer;
