import { DefaultValues } from "../../common/enum/DefaultValues";

export interface IRequestFilter {
    transportUnitId: number[];
    transportUnitSizeId: number[];
    transportUnitNumber?: string;
    stateId: number[];
    dockId: number[];
    turnId: number[];
    customerId: string[];
    terminalId: number[];
    merchandiseTypeId: number[];
    merchandiseOriginId: number[];
    customsStateId: number[];
    finalDestinationTypeId: number[];
    organismId: number[];
    previusOrganismId?: number[];
    actuationTypeGroupId: number[];
    actuationTypeId: number[];
    certificateTypeId: number[];
    certificateNumber?: string;
    summaryNumber?: string;
    documentTypeId: number[];
    invoiced?: boolean;
    valued: string[];
    vua?: boolean;
    app?: boolean;
    parkingAuthorization?: boolean;
    halalCertified?: boolean;
    dryCharge?: boolean;
    fumigated?: boolean;
    organicProduct?: boolean;
    inspectorAssigned?: boolean;
    inspectorId: string[];
    summaryActive?: boolean;
    sort?: number;
    appointmentTimeZoneId: number[];
    appointmentAssigned?: boolean;
    description?: string;
}

export interface IInspectionFilter {
    organismId?: number[];
    actuationTypeGroupId?: number[];
    actuationTypeId?: number[];
    certificateTypeId?: number[];
    inspectorAssigned?: boolean;
    inspectorId?: string[];
}

export interface FullRequestFilter {
    transportUnitId?: number[];
    transportUnitSizeId?: number[];
    transportUnitNumber?: string;
    stateId?: number[];
    dockId?: number[];
    turnId?: number[];
    customerId?: string[];
    terminalId?: number[];
    merchandiseTypeId?: number[];
    merchandiseOriginId?: number[];
    customsStateId?: number[];
    finalDestinationTypeId?: number[];
    certificateNumber?: string;
    summaryNumber?: string;
    documentTypeId?: number[];
    invoiced?: boolean;
    valued?: string[];
    vua?: boolean;
    app?: boolean;
    parkingAuthorization?: boolean;
    halalCertified?: boolean;
    dryCharge?: boolean;
    fumigated?: boolean;
    organicProduct?: boolean;
    summaryActive?: boolean;
    sort?: number;
    startDate?: string;
    endDate?: string;
    appointmentTimeZoneId?: number[];
    appointmentAssigned?: boolean;
    inspectionFilter: IInspectionFilter;
    description?: string;
}

export const emptyRequestFilter = (): IRequestFilter => {
    return {
        actuationTypeGroupId: [-1],
        actuationTypeId: [-1],
        certificateTypeId: [-1],
        customsStateId: [-1],
        dockId: [-1],
        documentTypeId: [-1],
        finalDestinationTypeId: [-1],
        inspectorId: [DefaultValues.DEFAULT_ALL_SELECTOR as string],
        merchandiseTypeId: [-1],
        merchandiseOriginId: [-1],
        organismId: [-1],
        stateId: [-1],
        transportUnitId: [-1],
        transportUnitSizeId: [-1],
        turnId: [-1],
        customerId: [DefaultValues.DEFAULT_ALL_SELECTOR as string],
        terminalId: [-1],
        valued: [DefaultValues.DEFAULT_ALL_SELECTOR as string],
        appointmentTimeZoneId: [-1],
    };
};
