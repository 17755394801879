import { UserApi } from "../../../../api";
import { throwErrorToastrAsync } from "../../../../utils";
import { setData, setLoading } from "../../../reducers/user/signup/states";
import { AppThunk } from "../../../store";

const userSignupApi = new UserApi();

export const fetchUserSignupStatesAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await userSignupApi.getUserSignupStatesAsync();
        dispatch(setData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoading(false));
    }
};
