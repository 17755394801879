import { ManagementWorkCalendarApi } from "../../../../api/management/workCalendar";
import { throwErrorToastrAsync } from "../../../../utils";
import { fetchManagementWorkCalendarList } from "../../../reducers/management/workCalendar/list";

const managementWorkCalendarApi = new ManagementWorkCalendarApi();

export const fetchManagementWorkCalendarAction =
    (year: number, month: number): any =>
    async (dispatch: any) => {
        try {
            const response = await managementWorkCalendarApi.getNonWorkDaysAsync(year, month);
            dispatch(fetchManagementWorkCalendarList(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };

export const setManagementWorkCalendarAction =
    (date: string): any =>
    async () => {
        try {
            await managementWorkCalendarApi.setNonWorkDaysAsync(date);
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };

export const deleteManagementWorkCalendarAction =
    (id: number): any =>
    async () => {
        try {
            await managementWorkCalendarApi.deleteNonWorkDaysAsync(id);
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };
