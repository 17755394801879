import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterTurnsList } from "./list/list";
import { setTurns } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterTurnsList } from "../../../../../redux/reducers/resources/master/turns/list";

export class MasterTurnsContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterTurnsList());
        store.dispatch(setTurns([]));
    }

    render(): React.ReactNode {
        return (
            <MasterTurnsList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
