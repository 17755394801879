import { palette } from "./colors";

export const borders = {
    sizes: {
        xs: 5,
        sm: 10,
        base: 15,
        md: 20,
        lg: 25,
        xl: 30,
        xxl: 40,
        xxxl: 50,
    },
    default: {
        radius: "0.25rem",
        border: `1px solid ${palette.grey}`,
        borderDotted: `1px dotted ${palette.grey}`,
        borderDashed: `1px dashed ${palette.grey}`,
        boxShadow: `0px 3px 7px 2px rgba(240,240,240,1)`,
        whiteBorder:`3px solid ${palette.white}`, 
        purpleBorder:`1px solid ${palette.purple}`
    },
    siteNav: {
        item: {
            radius: "0",
        },
    },
    button: {
        radius: "0.25rem",
    },
    navButton: {
        radius: "0.25rem",
    },
    table: {
        header: {
            border: `1px solid ${palette.grey}`,
        },
        body: {
            border: `1px dotted ${palette.grey}`,
        },
    },
    actionMenu: {
        boxShadow: `0px 7px 7px 2px rgba(240,240,240,1)`,
    },
    selector: {
        borderBottom: `1px solid ${palette.grey}`,
        disabledBorder :"1.3px solid #dfdddd"
    },
};
