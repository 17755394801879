import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ProfileDocumentTypes } from "../../../../../../common/enum/ProfileDocumentTypes";
import {
    IManagementUserForm,
    removeDocument,
    removeDocumentBlobs,
    setDocumentsDeleteUser,
    setDocumentsUser,
} from "../../../../../../redux/reducers/management/user/form";
import { LabelText } from "../../../../../common/labels/labelText/labelText";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { UploadFileCustom } from "../../../../../common/uploadFile/uploadFile";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { ContentTypeExtensions } from "../../../../../../common/enum/files/ContentType";
import { IRequestedDocument } from "../../../../../../models";
import { FileSize } from "../../../../../../common/enum/files/FileSize";
import { DefaultValues } from "../../../../../../common/enum/DefaultValues";
interface IManagementUserFormCarrierAdminEntityDocumentsProps {
    generalUserData: IManagementUserForm;
}
export const ManagementUserFormCarrierAdminEntityDocuments: React.FC<IManagementUserFormCarrierAdminEntityDocumentsProps> = ({ generalUserData }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });

    const updateDocument = (file?: File, name?: string) => {
        if (file) {
            const item: IRequestedDocument = {
                documentId: Number(name),
                documentName: file?.name,
                file: file ? URL.createObjectURL(file) : "",
                index: Number(name),
                fileName: file?.name,
            };
            dispatch(setDocumentsUser(item));
        }
    };
    const fiscalCardDocument = useMemo(() => {
        if (!generalUserData.documents) {
            return null;
        }
        return generalUserData.documents.find((item) => item.documentId === Number(ProfileDocumentTypes.FISCAL_CARD));
    }, [generalUserData.documents]);

    const fiscalCardBlob = useMemo(() => {
        if (!generalUserData.blobs) {
            return null;
        }

        return generalUserData.blobs.find((item) => item.type === ProfileDocumentTypes.FISCAL_CARD);
    }, [generalUserData]);

    const banckAuthorizationDocument = useMemo(() => {
        return generalUserData.documents.find((item) => item.documentId === Number(ProfileDocumentTypes.BANK_AUTHORIZATION));
    }, [generalUserData.documents]);

    const banckAuthorizationBlob = useMemo(() => {
        if (!generalUserData.blobs) {
            return null;
        }
        return generalUserData.blobs.find((item) => item.type === ProfileDocumentTypes.BANK_AUTHORIZATION);
    }, [generalUserData]);

    return (
        <div className={GridClass.ROW_GRID}>
            <div className={` ${GridClass.LONG_DOBLE_CELL}`}>
                <div className={`${style.carrierDocument} `}>
                    <LabelText
                        name={t("SECURITY_INFO")}
                        hiddenValue={true}
                    />
                    <UploadFileCustom
                        removeDocument={() => {
                            dispatch(removeDocument(Number(ProfileDocumentTypes.BANK_AUTHORIZATION)));
                            dispatch(removeDocumentBlobs(ProfileDocumentTypes.BANK_AUTHORIZATION));
                            dispatch(
                                setDocumentsDeleteUser({
                                    DocumentId: ProfileDocumentTypes.BANK_AUTHORIZATION,
                                    FileName: banckAuthorizationDocument?.fileName || banckAuthorizationBlob?.fileName || DefaultValues.NOT_TEXT,
                                }),
                            );
                        }}
                        name={ProfileDocumentTypes.BANK_AUTHORIZATION}
                        allowedExtensions={ContentTypeExtensions.USER_DOCUMENT}
                        onChangeFile={updateDocument}
                        title={t("ADD_DOCUMENTS")}
                        buttonStyle={ColorType.primary}
                        maxSize={FileSize._5MB}
                        file={banckAuthorizationDocument ?? banckAuthorizationBlob}
                    />
                </div>
            </div>

            <div className={GridClass.LONG_DOBLE_CELL}>
                <div className={` ${style.carrierDocument}`}>
                    <LabelText
                        name={t("FISCAL_CARD")}
                        hiddenValue={true}
                    />
                    <UploadFileCustom
                        removeDocument={() => {
                            dispatch(removeDocument(Number(ProfileDocumentTypes.FISCAL_CARD)));
                            dispatch(removeDocumentBlobs(ProfileDocumentTypes.FISCAL_CARD));
                            dispatch(
                                setDocumentsDeleteUser({
                                    DocumentId: ProfileDocumentTypes.FISCAL_CARD,
                                    FileName: fiscalCardDocument?.fileName || fiscalCardBlob?.fileName || DefaultValues.NOT_TEXT,
                                }),
                            );
                        }}
                        name={ProfileDocumentTypes.FISCAL_CARD}
                        allowedExtensions={ContentTypeExtensions.USER_DOCUMENT}
                        onChangeFile={updateDocument}
                        title={t("ADD_DOCUMENTS")}
                        buttonStyle={ColorType.primary}
                        maxSize={FileSize._5MB}
                        file={fiscalCardDocument ?? fiscalCardBlob}
                    />
                </div>
            </div>
        </div>
    );
};
