import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalLoadingState {
  loading: boolean;
}

const initialState: GlobalLoadingState = {
  loading: false,
};

export const globalLoadingSlice = createSlice({
  name: "globalLoading",
  initialState,
  reducers: {
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setGlobalLoading } = globalLoadingSlice.actions;

export default globalLoadingSlice.reducer;
