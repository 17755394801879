import { useEffect, useMemo } from "react";
import { SkeletonSizes } from "../../../../common/constants";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { VlcPortFinishedRequestColumn } from "./column/column";
import { fetchFinishedREquestList } from "../../../../redux/actions/vlcPort/finishedRequest/finishedRequest";
import { sortArray } from "../../../../utils";
export const VlcPortFinishedRequestList = () => {
    const dispatch = useAppDispatch();
    const { loading, list, sort } = useAppSelector((state) => state.vlcPortFinishedRequestList);

    const renderColumns = () => {
        return VlcPortFinishedRequestColumn();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchFinishedREquestList());
    }, [list]);

    const newList = useMemo(() => {
        return list ? sortArray(list, sort) : [];
    }, [list, sort]);

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={newList}
            renderColumns={renderColumns}
            height={"calc(100vh - 9rem)"}
        />
    );
};
