import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const CustomDetailsListStyle = createUseStyles((theme: any) => ({
    listContainer: (props: any) => ({
        width: "100%",
        "& .ms-DetailsList": {
            paddingTop: 0,
            fontSize: "11px !important",
        },
        "& .ms-DetailsList-headerWrapper": {
            flex: "0 0 auto",
        },
        "& .ms-DetailsList-contentWrapper": {
            flex: "1 1 auto",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
        },
        "&:hover .ms-DetailsHeader-cell": {
            backgroundColor: theme.colors.palette.purpleLight,
        },
        "&:hover": {
            backgroundColor: theme.colors.palette.purpleLight,
        },
        "& .ms-DetailsHeader-cellName": {
            fontSize: "10px",
            color: theme.colors.palette.grey,
            width: "100%",
        },
        "& .ms-DetailsHeader": {
            backgroundColor: theme.colors.palette.purpleLight,

            boxSizing: "content-box",
            textTransform: "uppercase",
            padding: 0,
            borderBottom: 0,
        },
        "& .ms-DetailsRow": {
            borderBottom: theme.borders.default.borderDotted,
        },
        "& [role=grid]": {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            height: props.height ?? "calc(100vh - 300px)",
            [mediaQueries.TABLET]: {
                height: "calc(100vh - 481px)",
            },
        },
        "& .ms-DetailsHeader-cellTitle": {
            height: "-webkit-fill-available",
        },
    }),
    footer: {
        flex: "0 0 auto",
        width: "100%",
        backgroundColor: theme.colors.palette.purpleLight,
    },
    customFooter: {
        "& > .ms-DetailsRow ": {
            flex: "0 0 auto",
            width: "100%",
            border: "none !important",
            color: theme.colors.palette.black,
        },
    },
    iconTitle: {
        fontSize: "0.90rem",
    },
}));
