export enum GridClass {
    ROW_GRID = `ms-Grid-row ms-Grid-row--gap`,
    THREE_CELL = "ms-Grid-col ms-sm12 ms-lg4",
    SIMPLE_CELL = "ms-Grid-col ms-sm12",
    LONG_DOBLE_CELL="ms-Grid-col ms-sm12 ms-lg6", 
    UNIT_CELL="ms-Grid-col ms-sm1 ms-lg1", 
    DOBLE_CELL="ms-Grid-col ms-sm12 ms-lg3", 
    SEVEN_CELL="ms-Grid-col ms-sm12 ms-lg7",
    EIGTH_CELL="ms-Grid-col ms-sm12 ms-lg8",
    FULL_CELL="ms-Grid-col ms-sm12 ms-lg12", 
}
