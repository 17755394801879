import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IControlExpedientItem } from "../../../../models/control/expedient/IExpedient";

interface IControlExpedientListState {
    list: IControlExpedientItem[];
    sort: ISortState;
    loading?: boolean;
}

const initialState: IControlExpedientListState = {
    list: [],
    sort: emptySortState(),
    loading: false,
};

export const controlExpedientListSlice = createSlice({
    name: "controlExpedientListSlice",
    initialState,
    reducers: {
        resetcontrolExpedientList: () => initialState,
        setList: (state, action: PayloadAction<IControlExpedientItem[]>) => {
            state.list = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetcontrolExpedientList, setList, updateSortColumn, setLoading } = controlExpedientListSlice.actions;

export default controlExpedientListSlice.reducer;
