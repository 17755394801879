import { Resolver } from "react-hook-form";
import { isValidEmail, isValidPhoneNumber } from "../../../../../common/validations/validationsForm";
import { IUserProfile } from "../../../../../models";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils";
import { RoleType } from "../../../../../common/enum";

export const myProfileResolver: Resolver<IUserProfile> = async (values: IUserProfile) => {
    let errors = {};
    const role = store.getState().role.roleData;
    Object.entries(values).forEach(([name, value]) => {
        switch (name) {
            case compileNameOfProperty<IUserProfile>("email"):
                if (!isValidEmail(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.EMAIL",
                        },
                    };
                }
                break;
            case compileNameOfProperty<IUserProfile>("phoneNumber"):
                if (
                    !isValidPhoneNumber(value) &&
                    (role.id === RoleType.CUSTOMER_USER ||
                        role.id === RoleType.CUSTOMER_ADMIN ||
                        role.id === RoleType.CARRIER_ADMIN ||
                        role.id === RoleType.CARRIER_USER)
                ) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.PHONE",
                        },
                    };
                }
                break;
            case compileNameOfProperty<IUserProfile>("surName"):
                if (
                    !value &&
                    (role.id === RoleType.CUSTOMER_USER ||
                        role.id === RoleType.CUSTOMER_ADMIN ||
                        role.id === RoleType.CARRIER_ADMIN ||
                        role.id === RoleType.CARRIER_USER ||
                        role.id === RoleType.ORGANISM_TECHNICIAN ||
                        role.id === RoleType.ORGANISM_INSPECTOR ||
                        role.id === RoleType.ORGANISM_ADMIN ||
                        role.id === RoleType.SUPER_ADMIN)
                ) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.REQUIRE",
                        },
                    };
                }
                break;
            case compileNameOfProperty<IUserProfile>("lastName"):
            case compileNameOfProperty<IUserProfile>("image"):
                break;
            default:
                if (!value) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.REQUIRE",
                        },
                    };
                }
                break;
        }
    });
    return { values, errors };
};
