import { createUseStyles } from "react-jss";

export const ResumeButtonsStyle = createUseStyles((theme: any) => ({
    resumeButtonsContainer: {
        display: "flex",
        justifyContent: "space-between",

        backgroundColor: theme.colors.site.background,
        padding: 32,
    },

    disclaimer: {
        display: "flex",
        columnGap: 4,
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.sm,
    },
    resumeButtons: {
        display: "flex",
        columnGap: 16,
        "& button": {
            minWidth: 110,
        },
    },
}));
