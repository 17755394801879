import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterCustomsState } from "../../../../../models/resources/master/ICustomsState";

export interface IMasterCustomsStatesFormState {
    form: IMasterCustomsState;
    loading: boolean;
}

const initialState: IMasterCustomsStatesFormState = {
    form: {
        active: true,
        code: "",
        description: "",
        temporaryRegime: false,
    },
    loading: false,
};

export const masterCustomsStatesForm = createSlice({
    name: "masterCustomsStatesForm",
    initialState,
    reducers: {
        resetMasterCustomsStatesForm: () => initialState,
        setLoadingMasterCustomsStatesForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterCustomsStatesForm: (state, action: PayloadAction<IMasterCustomsState>) => {
            state.form = action.payload;
        },
        updateMasterCustomsStatesProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterCustomsStatesForm, setLoadingMasterCustomsStatesForm, setMasterCustomsStatesForm, updateMasterCustomsStatesProperty } =
    masterCustomsStatesForm.actions;

export default masterCustomsStatesForm.reducer;
