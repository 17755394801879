import { Icon } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../assets/icons";
import { ColorType } from "../../../common/enum/ColorType";
import { ContentType } from "../../../common/enum/files/ContentType";
import { FileSize } from "../../../common/enum/files/FileSize";
import filesUtil from "../../../common/utils/filesUtil";
import { Button } from "../buttons/buttons";
import { CustomIcons } from "../customIcons/customIcons";
import { UploadFileDropzoneStyle } from "./uploadFileDropzone.jss";

interface Props {
    allowedExtensions: ContentType[];
    maxSize: FileSize;
    disabled: boolean;
    multiple?: boolean;
    onFilesChange?: (files: File) => void;
    onFilesChangeMultiple?: (files: File[]) => void;
}

export const UploadFileDropzone: React.FC<Props> = ({ allowedExtensions, maxSize, disabled, multiple, onFilesChange, onFilesChangeMultiple }) => {
    const { t } = useTranslation("messages");
    const theme = useTheme();
    const styles = UploadFileDropzoneStyle({ theme, disabled });
    const [customAcceptedFiles, setCustomAcceptedFiles] = useState<File[]>([]);
    const [isErrorFile, setErrorFile] = useState<boolean>(false);

    const { acceptedFiles, fileRejections, getRootProps, getInputProps, open, isDragAccept, isDragActive, isDragReject } = useDropzone({
        accept: allowedExtensions.reduce<Record<string, any[]>>((acc, currentValue) => {
            acc[currentValue] = [];
            return acc;
        }, {}),
        noClick: true,
        noKeyboard: true,
        maxSize: maxSize,
        multiple: multiple,
        disabled: disabled,
    });

    useEffect(() => {
        if (!multiple && acceptedFiles.length > 0) {
            setCustomAcceptedFiles(acceptedFiles);
        }

        if (multiple && acceptedFiles.length > 0) {
            setCustomAcceptedFiles((prevFiles) => {
                const newFiles = acceptedFiles.filter((file) => !prevFiles.some((prevFile) => prevFile.name === file.name));
                return [...prevFiles, ...newFiles];
            });
        }
    }, [acceptedFiles]);

    useEffect(() => {
        fileRejections.length > 0 ? setErrorFile(true) : setErrorFile(false);
    }, [fileRejections]);

    useEffect(() => {
        if (!multiple && onFilesChange) {
            onFilesChange(customAcceptedFiles[0]);
        }

        if (multiple && onFilesChangeMultiple) {
            onFilesChangeMultiple(customAcceptedFiles);
        }
    }, [customAcceptedFiles, onFilesChange, onFilesChangeMultiple]);

    const removeFile = useCallback(
        (index: number) => {
            setCustomAcceptedFiles((currentFiles) => currentFiles.filter((_, fileIndex) => index !== fileIndex));
        },
        [customAcceptedFiles],
    );

    const getEnumKeysByValue = (value: ContentType): string =>
        Object.keys(ContentType).find((key) => ContentType[key as keyof typeof ContentType] === value) ?? "";

    const files = customAcceptedFiles.map((file: any, index: number) => (
        <div
            key={file.path}
            className={styles.fileItem}
        >
            <div className={styles.fileIcon}>
                <CustomIcons
                    isButton={false}
                    iconName={filesUtil.getIconForMimeType(file.type)}
                    size={25}
                    tooltip={file.type}
                    margin={"0px"}
                />
            </div>
            <div className={styles.fileInfo}>
                <strong>
                    {file.path} <small>({filesUtil.bytesToMegabytes(file.size)} mb)</small>
                </strong>
            </div>
            <div className={styles.fileActions}>
                <CustomIcons
                    className={styles.buttonRed}
                    isButton={true}
                    iconName={NameIcons.REMOVE}
                    onClick={() => {
                        removeFile(index);
                    }}
                    size={20}
                    tooltip={t("INPUT_FILE.REMOVE_FILE")}
                    margin={"0px"}
                />
            </div>
        </div>
    ));

    const onDragActiveContent = (
        <div className={styles.content}>
            <Icon
                iconName="Upload"
                className={styles.icon}
            />
            <div>{multiple ? t("INPUT_FILE.DROPZONE_MULTIPLE") : t("INPUT_FILE.DROPZONE")}</div>
            <div>
                <Button
                    color={ColorType.primary}
                    title={t("INPUT_FILE.SELECT_FILE")}
                    handleClick={open}
                    disabled={disabled}
                />
            </div>
        </div>
    );

    const onDragAcceptContent = (
        <div className={styles.content}>
            <Icon
                iconName="BulkUpload"
                className={styles.iconAccept}
            />
            <div>{t("INPUT_FILE.DROPZONE_ACCEPT")}</div>
        </div>
    );

    return (
        <div className={styles.container}>
            <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />
                {isDragAccept && onDragAcceptContent}
                {isDragReject && onDragAcceptContent}
                {!isDragActive && onDragActiveContent}
            </div>
            {isErrorFile && (
                <div className={styles.fileInfoReject}>
                    <Trans
                        t={t}
                        i18nKey={"INPUT_FILE_ERRORS.GENERIC_ERROR_FILE"}
                        values={{
                            allowedExtensions: allowedExtensions.map((ext) => getEnumKeysByValue(ext)).join(", "),
                            maxSize: filesUtil.bytesToMegabytes(maxSize),
                        }}
                    />
                </div>
            )}
            <div className={styles.fileSection}>{files}</div>
        </div>
    );
};
