import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptyUserRoleData, IRole } from "../../../../models/common/IRole";
import { store } from "../../../store";
import { act } from "react-dom/test-utils";

interface RoleState {
    roleData: IRole;
}

const initialState: RoleState = {
    roleData: emptyUserRoleData(),
};

export const roleSlice = createSlice({
    name: "role",
    initialState,
    reducers: {
        setRole: (state, action: PayloadAction<IRole>) => {
            state.roleData = action.payload;
        },
        restoreOriginalRole: (state, action: PayloadAction<string>) => {
            state.roleData.id = action.payload;
        },
        resetRole: () => initialState,
    },
});

export const { setRole, restoreOriginalRole, resetRole } = roleSlice.actions;

export default roleSlice.reducer;
