import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const EditRequestTabsInvoiceConceptContainerStyle = createUseStyles((theme: any) => ({
    invoiceConceptRequestGeneralContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.md,
        padding: theme.paddings.default,
        paddingLeft: 0,
        height: theme.heights.requestEditContent,
        [mediaQueries.TABLET]: {
            height: theme.heights.requestEditMobileContent,
        },
        overflowY: "scroll",
    },
    buttonActionsContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: theme.gaps.buttons.default,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.form.default,
        flex: "1 1 auto",
        alignItems: "center",
    },
    invoiceContainerData: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.md,
        paddingRight:5
    },
}));
