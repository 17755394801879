import { useEffect, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { IUserSignupConfirmation } from "../../../models/user/IUserSignupConfirmation";
import { fetchOrganizations } from "../../../redux/actions/catalog/catalog";
import {
    acceptUserSignupConfirmationAction,
    fetchUserSignupConfirmationAction,
    rejectUserSignupConfirmationAction,
    resetUserSignupConfirmationAction,
} from "../../../redux/actions/user/confirmation/confirmation";
import { fetchUserSignupTypesAction } from "../../../redux/actions/user/signup/types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
    userSignupConfirmationFormLoadingSelector,
    userSignupConfirmationSuccessSelector,
} from "../../../redux/selectors/user/confirmation/confirmation";
import { getAllUserTypesSelector } from "../../../redux/selectors/user/signup/types";
import { compileNameOfProperty } from "../../../utils";
import { ButtonPrimary, ButtonSecondary } from "../../common/buttons/buttons";
import { Checkboxs } from "../../common/checkbox/checkbox";
import { ILabelTextStyleProps, LabelText } from "../../common/labels/labelText/labelText";
import { PopUpForm } from "../../common/popup/popUpForm/popUpForm";
import { Skeleton } from "../../common/skeleton/skeleton";
import { TitleH5 } from "../../common/titles/titles";
import { UserDocumentList } from "../../common/user/documentList/documentList";
import { UserSignupResumeData } from "../signup/resume/data/resumeData";
import { UserConfirmationStyle } from "./confimation.jss";
import { ConfirmationForm } from "./confirmationForm/confirmationForm";
import { RejectionReason } from "./rejectionReason/rejectionReason";
import { UserType } from "../../../common/enum/UserType";
import { UserSignupState } from "../../../common/enum/UserSignupState";
import { ColorType } from "../../../common/enum/ColorType";

interface IUserConfirmationProps {
    height?: any;
}

export const UserConfirmation: React.FC<IUserConfirmationProps> = ({ height }) => {
    const theme = useTheme() as any;
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const styles = UserConfirmationStyle({ theme, height });
    const { id } = useParams();
    const idParam = id ? id : "";
    const userSignupTypes = useSelector(getAllUserTypesSelector.selectAll);
    const { form, loadingSingupConfirmation } = useAppSelector((state) => state.userSignupConfirmation);
    const success = useSelector(userSignupConfirmationSuccessSelector);
    const loading = useSelector(userSignupConfirmationFormLoadingSelector);
    const { isProfileLoaded } = useAppSelector((state) => state.userData);
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });

    useEffect(() => {
        isProfileLoaded && dispatch(fetchUserSignupConfirmationAction(idParam));
        dispatch(fetchOrganizations());
        return () => {
            dispatch(resetUserSignupConfirmationAction());
        };
    }, [isProfileLoaded]);

    useEffect(() => {
        !userSignupTypes.length && dispatch(fetchUserSignupTypesAction());
    }, [dispatch, userSignupTypes]);

    useEffect(() => {
        if (form.state === UserSignupState.REJECTED) {
            setShowRejectReasonForm(false);
        }
    }, [form.state]);

    const resolver: Resolver<IUserSignupConfirmation> = async (values: IUserSignupConfirmation) => {
        const errors = {};
        return { values, errors };
    };

    const {
        register,
        formState: { errors: confirmationErrors },
    } = useForm<IUserSignupConfirmation>({ mode: "onChange", resolver: resolver });

    const [showRejectReasonForm, setShowRejectReasonForm] = useState(false);
    const [value, onChange] = useState("");

    const propsStyle: ILabelTextStyleProps = {
        dataColor: theme.colors.titles.secondary,
        dataSize: "",
        labelColor: theme.colors.titles.secondary,
        labelSize: "",
        dataWidth: "auto",
    };

    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    const onSaveRejectionReasonForm = () => {
        dispatch(rejectUserSignupConfirmationAction(idParam, value)).then((response: any) => {
            response && onCancelRejectionReasonForm();
        });
    };

    const onCancelRejectionReasonForm = () => {
        setShowRejectReasonForm(false);
    };
    const handleAcceptation = () => {
        dispatch(acceptUserSignupConfirmationAction(idParam, form.crmCode, form.externalCode, form.hasRepresentativeData));
    };

    const handleRejection = () => {
        setShowRejectReasonForm(true);
    };

    return (
        <>
            <PopUpForm
                content={
                    <RejectionReason
                        handleChange={handleChange}
                        reason={value}
                    />
                }
                title={t("USER.CONFIRMATION.REASONREJECTED")}
                isVisible={showRejectReasonForm}
                isDisabledButton={!value}
                onCancel={onCancelRejectionReasonForm}
                onSave={onSaveRejectionReasonForm}
                width={isMobile ? "60vw" : "20vw"}
                height={loadingSingupConfirmation ? "15vh" : "auto"}
                isLoading={loadingSingupConfirmation}
            />

            <div className={styles.content}>
                <div className={styles.mainColumn}>
                    <div className={styles.userData}>
                        <div className={styles.userDataColumn}>
                            {loading || !isProfileLoaded ? (
                                <Skeleton
                                    rows={15}
                                    marginRow={20}
                                />
                            ) : (
                                <UserSignupResumeData data={form} />
                            )}
                        </div>
                        <div className={styles.userDataColumn}>
                            {loading || !isProfileLoaded ? (
                                <Skeleton
                                    rows={7}
                                    marginRow={20}
                                />
                            ) : (
                                <>
                                    <div className={styles.userDataColumn}>
                                        <div className={styles.userDataColumnTitle}>
                                            <TitleH5
                                                title={t("USER.SIGNUP.DOCUMENTATION")}
                                                color={ColorType.primary}
                                                bold={true}
                                            />
                                        </div>
                                        <UserDocumentList documents={form.blobs} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.actionButtons}>
                {!loading &&
                    (() => {
                        switch (form.confirmation.state) {
                            case UserSignupState.APPROVED:
                                return (
                                    <>
                                        {form.userType === UserType.CUSTOMER && (
                                            <div className={styles.codesRow}>
                                                <Checkboxs
                                                    label={t("USER.CONFIRMATION.ENABLE_REPRESENTATIVE")}
                                                    name={compileNameOfProperty<IUserSignupConfirmation>("hasRepresentativeData")}
                                                    isChecked={form.hasRepresentativeData}
                                                    disabled={true}
                                                    defaultWidth={true}
                                                />
                                                <LabelText
                                                    name={t("USER.CONFIRMATION.CRMCODE")}
                                                    value={form.crmCode}
                                                    styleProps={propsStyle}
                                                />
                                                <LabelText
                                                    name={t("USER.CONFIRMATION.EXTERNALCODE")}
                                                    value={form.externalCode}
                                                    styleProps={propsStyle}
                                                />
                                            </div>
                                        )}
                                        <TitleH5
                                            title={t("USER.CONFIRMATION.ACCEPTED")}
                                            color={ColorType.success}
                                            bold={true}
                                        />
                                    </>
                                );

                            case UserSignupState.REJECTED:
                                return (
                                    <TitleH5
                                        title={t("USER.CONFIRMATION.REJECTED")}
                                        color={ColorType.danger}
                                        bold={true}
                                    />
                                );
                            default:
                                return (
                                    <>
                                        <div className={styles.codesRow}>
                                            {form.userType === UserType.CUSTOMER && (
                                                <ConfirmationForm
                                                    data={form}
                                                    errors={confirmationErrors}
                                                    register={register}
                                                />
                                            )}
                                        </div>
                                        <ButtonSecondary
                                            disabled={!success}
                                            handleClick={() => handleRejection()}
                                            title={t("USER.CONFIRMATION.REJECTBUTTON")}
                                        />
                                        <ButtonPrimary
                                            disabled={!success || (!form.crmCode && form.userType === UserType.CUSTOMER)}
                                            handleClick={handleAcceptation}
                                            title={t("USER.CONFIRMATION.ACCEPTBUTTON")}
                                        />
                                    </>
                                );
                        }
                    })()}
            </div>
        </>
    );
};
