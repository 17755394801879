import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestIntegrations } from "../../../../../models";

interface IRequestIntegrationListState {
    list?: IRequestIntegrations[];
    loading: boolean;
}

const initialState: IRequestIntegrationListState = {
    loading: false,
};

export const requestIntegrationListReducer = createSlice({
    name: "requestIntegrationListReducer",
    initialState,
    reducers: {
        requestIntegrationList: () => initialState,
        setRequestIntegrationList: (state, action: PayloadAction<IRequestIntegrations[]>) => {
            state.list = action.payload;
        },
        setRequestIntegrationLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { requestIntegrationList, setRequestIntegrationList, setRequestIntegrationLoading } = requestIntegrationListReducer.actions;

export default requestIntegrationListReducer.reducer;
