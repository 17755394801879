import axios, { AxiosBasicCredentials, AxiosRequestConfig, Method } from "axios";
import { setApiErrorResponse } from "../redux/reducers/webConfig/apiException";
import { store } from "../redux/store";
import { HttpStatus } from "../common/enum/api/HttpStatus";

export type HTTPMethod = Method;

const getAccessToken = (): string => {
    let token = "";
    const valueStorage = Object.keys(localStorage)
        .filter((key) => key.startsWith("oidc.user"))
        .map((key) => localStorage[key]);
    if (valueStorage.length !== 0) {
        const tokenInfo = JSON.parse(valueStorage[0]);
        token = tokenInfo.access_token;
    }

    return token;
};

const getClaimsToken = (): string => {
    return store.getState().userClaims.token;
};

/**
 * Request base object
 */
export class Request {
    private config: AxiosRequestConfig;
    private mock: any;

    constructor(method: HTTPMethod, url: string) {
        this.config = {
            method: method,
            url: url,
        };
    }

    /**
     * Add a parameter to the request base URI
     *
     * @param {string} key name of the parameter
     * @param {string} value of the parameter
     * @returns {Request}
     */
    withQueryParameter(key: string, value: string): Request {
        this.config.params = { ...this.config.params, [key]: value };
        return this;
    }

    /**
     * Add a header key-value to the request
     *
     * @param {string} key name of the header
     * @param {string} value of the header
     * @returns
     */
    withHeader(key: string, value: any): Request {
        this.config.headers = { ...this.config.headers, [key]: value };
        return this;
    }

    withMultiPartHeaders(): Request {
        this.config.headers = { ...this.config.headers, Accept: "*/*" };
        this.config.headers = { ...this.config.headers, "Content-Type": "multipart/form-data" };
        return this;
    }

    /**
     * Configure basic authentication
     *
     * @param {AxiosBasicCredentials} basic crecdentials
     * @returns
     */
    withAuthentication(auth: AxiosBasicCredentials): Request {
        this.config.auth = auth;
        return this;
    }

    /**
     * Add a Authentication header with a Bearer token
     *
     * @returns
     */
    withOAuth(): Request {
        const token = getAccessToken();
        if (token) {
            this.config.headers = { ...this.config.headers, Authorization: `Bearer ${token}` };
        }

        return this;
    }

    withClaimsToken(): Request {
        const token = getClaimsToken();
        if (token) {
            this.config.headers = { ...this.config.headers, "User-Authorization": token };
        }
        return this;
    }

    withLanguage(): Request {
        this.config.headers = { ...this.config.headers, "Accept-Language": localStorage.i18nextLng };
        return this;
    }

    /**
     * Add a body to the request
     *
     * @param {any} body data of the request
     * @returns
     */
    withBody(body: any): Request {
        this.config.data = body;
        return this;
    }

    /**
     * Add a timeout value in milliseconds
     *
     * @param {number} milliseconds for waiting
     * @returns
     */
    withTimeout(millis: number): Request {
        this.config.timeout = millis;
        return this;
    }

    /**
     * Add a mock object to the request
     *
     * @param {any} mock object
     * @returns
     */
    withMock(mock: any) {
        this.mock = mock;
        return this;
    }

    /**
     * Execute the type request
     *
     * @returns {Promise<T>} return a Promise with the type of the method
     */
    async execute<T>(): Promise<T> {
        try {
            const response = await axios.request<T>(this.config);
            return response.data;
        } catch (error: Error | any) {
            if (axios.isAxiosError(error) && error.response) {
                const { status, request } = error.response;
                if (status === HttpStatus.SERVICE_UNAVAILABLE) {
                    store.dispatch(setApiErrorResponse({ htmlStatus: status, iconName: "FlameSolid", request: request?.responseURL }));
                }

                if (status === HttpStatus.INTERNAL_SERVER_ERROR) {
                    store.dispatch(setApiErrorResponse({ htmlStatus: status, iconName: "UnknownCall", request: request?.responseURL }));
                }
            }
            throw error;
        }
    }
}

/**
 * Initialize the request
 * {@linkcode Request}
 * [[Request]]
 *
 * @param {HTTPMethod} method of our request
 * @param {string} url of the request
 * @returns {Request} an instance of Request
 */
export const apiFetch = (method: HTTPMethod, url: string): Request => {
    return new Request(method, url).withLanguage();
};

export const apiFetchOAuth = (method: HTTPMethod, url: string): Request => {
    return new Request(method, url).withOAuth().withLanguage();
};

export const apiFetchOAuthWithClaims = (method: HTTPMethod, url: string): Request => {
    return new Request(method, url).withOAuth().withLanguage().withClaimsToken();
};
