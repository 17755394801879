import { createUseStyles } from "react-jss";

export const TableCustomStyle = createUseStyles((theme: any) => ({
    notData: {
        fontSize: theme.fonts.sizes.sm,
    },

    container : {
        display:"flex", 
        flexDirection:"column",
        width:"100%"
    },
    dataContainer : {
        display:"flex",
        flexDirection:"column"
    }
}));



