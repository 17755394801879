import { CookieSectionFormat, IItemTranslate } from "../controller/controller";

export const CookiePolicyContainer = () => {
    const translate: IItemTranslate[] = [
        {
            header: "COOKIES_POLICY.TITLE",
            description: "COOKIES_POLICY.TITLE_DESCRIPTION",
        },
        {
            header: "COOKIES_POLICY.WHAT_IS_COOKIE_HEADER",
            description: "COOKIES_POLICY.WHAT_IS_COOKIE_DESCRIPTION",
        },
        {
            header: "COOKIES_POLICY.COOKIE_USE_HEADER",
            description: "COOKIES_POLICY.COOKIE_USE_DESCRIPTION",
        },

        {
            header: "COOKIES_POLICY.COOKIE_CONFIG_HEADER",
            description: "COOKIES_POLICY.COOKIE_CONFIG_DESCRIPTION",
        },

        {
            header: "COOKIES_POLICY.TECHNICAL_COOKIES_HEADER",
            description: "COOKIES_POLICY.TECHNICAL_COOKIES_DESCRIPTION",
        },
        {
            header: "COOKIES_POLICY.CONTROLL_COOKIE_HEADER",
            description: "COOKIES_POLICY.CONTROLL_COOKIE_DESCRIPTION",
        },

        {
            description: "LAST_UPDATE",
        },
    ];

    return (
        <>
            <CookieSectionFormat translate={translate} />
        </>
    );
};
