import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { DirectionType } from "../../../../../../common/enum/DirectionType";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { useContainer } from "../../../../../../hooks/request/useContainer";
import { IGenericPayload } from "../../../../../../models";
import { ICreateRequest } from "../../../../../../models/requests/ICreateRequest";
import { IRequestStepProps } from "../../../../../../models/requests/draft/IRequestStepProps";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { setInformationStepIsValid, updateRequestProperty } from "../../../../../../redux/reducers/request/create/createRequest";
import { compileNameOfProperty } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { ControlledRadioButtonGroup } from "../../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { BooleanSelector, MerchandiseOriginSelector, MerchandiseTypeSelector, TerminalSelector } from "../../../../common/selectors";
import { MerchandiseCategoryId } from "../../../../common/selectors/merchandiseCategoryId/merchandiseCategoryId";
import { CreateRequestContainerStyle } from "../../containerStyle.jss";
import { requestMaxLength } from "../../validation/requestConfig";
import { booleanValidation, renderRequiredData } from "../../validation/requestValidationUtils";

export const GoodInfoData: React.FC<IRequestStepProps> = ({
    schema,
    onInputChange,
    onSelectorChange,
    onYesNoOptionsChange,
    resetPropertyWhenParentChange,
    organization,
}) => {
    const theme = useTheme();
    const style = CreateRequestContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST.FIELDS" });

    const { request } = useAppSelector((store) => store.createRequest);
    const { informationStepIsValid } = useAppSelector((store) => store.createRequest.sectionValidation);
    const isContainer = useContainer(request.transportUnitId);

    const dispatch = useAppDispatch();

    const {
        register,
        control,
        formState: { errors, isValid },
    } = useForm<ICreateRequest>({ mode: "onChange" });

    const onRadioButtonChange = useCallback(
        (optionKey: string, propertyName: string) => {
            const payload: IGenericPayload = {
                name: propertyName,
                value: !!+optionKey,
            };
            dispatch(updateRequestProperty(payload));
        },
        [dispatch],
    );

    useEffect(() => {
        informationStepIsValid !== isValid && dispatch(setInformationStepIsValid(isValid));
    }, [isValid, informationStepIsValid]);

    return (
        <>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={GridClass.ROW_GRID}>
                    {schema.merchandiseOriginId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <MerchandiseOriginSelector
                                onChange={onSelectorChange}
                                selectedKey={request.merchandiseOriginId}
                                propertyName={compileNameOfProperty<ICreateRequest>("merchandiseOriginId")}
                                title={t("MERCHANDISE_ORIGIN")}
                                isRequired={schema.merchandiseOriginId.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.merchandiseOriginId.required) }}
                            />
                        </div>
                    )}
                    {schema.humanConsumption.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <BooleanSelector
                                onChange={onYesNoOptionsChange}
                                selectedKey={+request?.humanConsumption}
                                propertyName={compileNameOfProperty<ICreateRequest>("humanConsumption")}
                                title={t("HUMAN_CONSUMPTION")}
                                isRequired={schema.humanConsumption.required}
                                control={control}
                                rules={{
                                    required: renderRequiredData(schema.humanConsumption.required),
                                    validate: () => booleanValidation(schema.humanConsumption.required, request.humanConsumption),
                                }}
                            />
                        </div>
                    )}
                    {schema.merchandiseTypeId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <MerchandiseTypeSelector
                                onChange={onSelectorChange}
                                selectedKey={request.merchandiseTypeId}
                                propertyName={compileNameOfProperty<ICreateRequest>("merchandiseTypeId")}
                                title={t("MERCHANDISE_TYPE")}
                                filterOptions={true}
                                merchandiseOriginId={request.merchandiseOriginId}
                                humanConsumption={request.humanConsumption}
                                resetSelectorAtRedux={resetPropertyWhenParentChange}
                                isRequired={schema.merchandiseTypeId.required}
                                control={control}
                                rules={{
                                    required: renderRequiredData(schema.merchandiseTypeId.required),
                                    validate: () => (schema.merchandiseTypeId.required ? !!request.merchandiseTypeId : true),
                                }}
                            />
                        </div>
                    )}
                </div>

                <div className={GridClass.ROW_GRID}>
                    {schema.merchandiseCategoryId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <MerchandiseCategoryId
                                onChange={onSelectorChange}
                                selectedKey={request.merchandiseCategoryId}
                                propertyName={compileNameOfProperty<ICreateRequest>("merchandiseCategoryId")}
                                title={t("NATURAL_MERCHANDISE")}
                                isRequired={schema.merchandiseCategoryId.required}
                                control={control}
                                rules={{ required: renderRequiredData(isContainer || schema.terminalId.required) }}
                                disabled={schema.merchandiseCategoryId.readonly}
                                merchandiseTypeId={request.merchandiseTypeId}
                                humanConsumption={request.humanConsumption}
                                merchandiseOriginId={request.merchandiseOriginId}
                            />
                        </div>
                    )}

                    {schema.description.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <FormField
                                type="text"
                                value={request.description}
                                label={t("DESCRIPTION")}
                                maxLength={requestMaxLength.DESCRIPTION}
                                isRequired={schema.description.required}
                                error={errors.description}
                                {...register(compileNameOfProperty<ICreateRequest>("description"), {
                                    onChange: onInputChange,
                                    validate: () => (schema.description.required ? !!request.description : true),
                                    required: renderRequiredData(schema.description.required),
                                })}
                            />
                        </div>
                    )}
                </div>

                <div className={GridClass.ROW_GRID}>
                    {schema.tariffHeading.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <FormField
                                type="text"
                                value={request.tariffHeading}
                                maxLength={requestMaxLength.TARIFF_HEADING}
                                label={t("TARIFF_ITEM")}
                                isRequired={schema.tariffHeading.required}
                                error={errors.tariffHeading}
                                {...register(compileNameOfProperty<ICreateRequest>("tariffHeading"), {
                                    onChange: onInputChange,
                                    value: request.tariffHeading,
                                    validate: (value: any) => (schema.tariffHeading.required ? !!value : true),
                                    required: renderRequiredData(schema.tariffHeading.required),
                                })}
                            />
                        </div>
                    )}
                    {schema.merchandiseArrivalShip.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <FormField
                                type="text"
                                value={request.merchandiseArrivalShip}
                                label={t("MERCHANDISEARRIVALSHIP")}
                                maxLength={requestMaxLength.SHIP_ARRIVAL}
                                isRequired={schema.merchandiseArrivalShip.required}
                                error={errors.merchandiseArrivalShip}
                                {...register(compileNameOfProperty<ICreateRequest>("merchandiseArrivalShip"), {
                                    onChange: onInputChange,
                                    validate: () => (schema.merchandiseArrivalShip.required ? !!request.merchandiseArrivalShip : true),
                                    required: renderRequiredData(schema.merchandiseArrivalShip.required),
                                })}
                            />
                        </div>
                    )}
                </div>

                <div className={GridClass.ROW_GRID}>
                    {schema.summaryNumber.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <FormField
                                type="text"
                                value={request.summaryNumber}
                                maxLength={requestMaxLength.SUMMARY}
                                label={t("SUMMARY")}
                                isRequired={schema.summaryNumber.required}
                                error={errors.summaryNumber}
                                {...register(compileNameOfProperty<ICreateRequest>("summaryNumber"), {
                                    onChange: onInputChange,
                                    validate: () => (schema.summaryNumber.required ? !!request.summaryNumber : true),
                                    required: renderRequiredData(schema.summaryNumber.required),
                                })}
                            />
                        </div>
                    )}
                    {schema.terminalId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <TerminalSelector
                                onChange={onSelectorChange}
                                selectedKey={request.terminalId}
                                propertyName={compileNameOfProperty<ICreateRequest>("terminalId")}
                                title={t("TERMINAL")}
                                isRequired={isContainer}
                                disabled={!isContainer}
                                control={control}
                                rules={{
                                    required: isContainer ? renderRequiredData(schema.terminalId.required) : false,
                                    validate: () => (isContainer ? !!request.terminalId : true),
                                }}
                            />
                        </div>
                    )}
                </div>

                {organization?.parkingAuthorization && schema.parkingAuthorization.visible && (
                    <div className={`${GridClass.ROW_GRID}`}>
                        <div className={`${GridClass.FULL_CELL}`}>
                            <ControlledRadioButtonGroup
                                selectedKey={String(+request.automaticParkingAuthorization)}
                                onRadioButtonChange={onRadioButtonChange}
                                control={control}
                                direction={DirectionType.horizontal}
                                name={compileNameOfProperty<ICreateRequest>("automaticParkingAuthorization")}
                                label={t("PARKINGAUTHORIZATION")}
                                disabled={schema.parkingAuthorization.readonly}
                                required={schema.parkingAuthorization.required}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
