import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { OrganizationContainerStyle } from "./containerStyle.jss";
import { fetchUserOrganization, putUserOrganization } from "../../../redux/actions/user/organization/organization";
import { Skeleton } from "../../common/skeleton/skeleton";
import { SkeletonSizes } from "../../../common/constants";
import { OrganizationFormContainer } from "./form/form";
import { RequiredFieldsMessage } from "../../common/requiredMessage/requiredMessage";

export const OrganizationContainer = () => {
    const theme = useTheme() as any;
    const styles = OrganizationContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "ORGANIZATION" });
    const { isLoading } = useAppSelector((state) => state.organization);
    const [validForm, setValidForm] = useState(true);
    const dispatch = useAppDispatch();

    const onSave = () => {
        dispatch(putUserOrganization());
    };

    useEffect(() => {
        dispatch(fetchUserOrganization());
    }, [dispatch]);
    return (
        <>
            <NavHeaderComponent title={t("TITLES.ORGANIZATION")} />

            <div className={styles.container}>
                {isLoading ? (
                    <Skeleton rows={SkeletonSizes.MANAGEMENT_USER} />
                ) : (
                    <OrganizationFormContainer setValidForm={setValidForm}></OrganizationFormContainer>
                )}
            </div>
            <div className={styles.actionButtons}>
                <div className={styles.disclaimer}>
                    <RequiredFieldsMessage />
                </div>

                <ButtonPrimary
                    disabled={!validForm || isLoading}
                    title={t("BUTTONS.SAVE")}
                    handleClick={() => onSave()}
                />
            </div>
        </>
    );
};
