import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IManagementUserSignup } from "../../../../../models/management/userSignup/IManagementUserSignup";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/management/userSignup/list";
import { store } from "../../../../../redux/store";
import { widths } from "../../../../../styles/romeu/widths";
import { compileNameOfProperty } from "../../../../../utils";
import { formatDate } from "../../../../../utils/dateUtils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { ManagementUserSignupListColumnStyle } from "./columnStyle.jss";
import { UserSignupState } from "../../../../../common/enum/UserSignupState";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ManagementUserSignupListColumn = (onEditItem: any): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.USER_SIGNUP.LIST" });
    const theme = useTheme();
    const styles = ManagementUserSignupListColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.managementUserSignupList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().managementUserSignupList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const getStateClassName = (stateCode: string): string => {
        switch (stateCode) {
            case UserSignupState.PENDING:
                return styles.statePending;
            case UserSignupState.APPROVED:
                return styles.stateApproved;
            case UserSignupState.REJECTED:
                return styles.stateRejected;
            default:
                return styles.statePending;
        }
    };

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IManagementUserSignup>("name"),
            minWidth: 150,
            maxWidth: 300,
            name: t("NAME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("name")}
                    title={t("NAME")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("name"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.name}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("email"),
            minWidth: 150,
            maxWidth: 300,
            name: t("EMAIL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("email")}
                    title={t("EMAIL")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("email"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.email}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("entityName"),
            minWidth: 150,
            maxWidth: 300,
            name: t("ENTITY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("entityName")}
                    title={t("ENTITY")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("entityName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.entityName}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("userTypeName"),
            minWidth: 100,
            maxWidth: 150,
            name: t("TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("userTypeName")}
                    title={t("TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("userTypeName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.userTypeName}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("date"),
            minWidth: 100,
            maxWidth: 150,
            name: t("REQUEST_DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("date")}
                    title={t("REQUEST_DATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("date"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.date ? formatDate(item.date, FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("rejectedDate"),
            minWidth: 100,
            maxWidth: 150,
            name: t("REJECTED_DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("rejectedDate")}
                    title={t("REJECTED_DATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("rejectedDate"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.rejectedDate ? formatDate(item.rejectedDate, FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("approvedDate"),
            minWidth: 100,
            maxWidth: 150,
            name: t("APPROVED_DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("approvedDate")}
                    title={t("APPROVED_DATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("approvedDate"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span>{item.approvedDate ? formatDate(item.approvedDate, FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUserSignup>("stateDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUserSignup>("stateDescription")}
                    title={t("STATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUserSignup>("stateDescription"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUserSignup) => <span className={getStateClassName(item.stateCode ?? "")}>{item.stateDescription}</span>,
            isResizable: true,
        },
        {
            key: "",
            minWidth: 100,
            maxWidth: 150,
            name: "",
            onRender: (item: IManagementUserSignup) => (
                <CustomIcons
                    iconName={item.stateCode === UserSignupState.PENDING ? NameIcons.EDIT : NameIcons.EYEICON}
                    onClick={() => onEditItem(item)}
                    size={widths.management.icons}
                />
            ),
            isResizable: true,
        },
    ];

    return columns;
};
