import { IDropdownOption } from "@fluentui/react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { MasterCertificateTypeFormStyle } from "./formStyle.jss";
import { updateMasterBlockProperty } from "../../../../../../../redux/reducers/resources/master/block/form";
import { IMasterCertificateType } from "../../../../../../../models/resources/master/ICertificateType";

export const MasterBlockForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const theme = useTheme();
    const style = MasterCertificateTypeFormStyle({ theme });
    const dispatch = useAppDispatch();
    const updatePropertyForm = (name: string, value: any) => {
        dispatch(updateMasterBlockProperty({ name, value }));
    };

    const { form } = useAppSelector((state) => state.masterBlockForm);
    const { register, control } = useForm<IMasterCertificateType>({ mode: "onChange" });

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        updatePropertyForm(propertyName, !!+option.key);
    };

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code}
                    {...register(compileNameOfProperty<IMasterCertificateType>("code"), {
                        onChange: onInputChange,
                        value: form.code,
                    })}
                    isRequired={true}
                />
                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? ""}
                    disabled={false}
                    {...register(compileNameOfProperty<IMasterCertificateType>("description"), {
                        onChange: onInputChange,
                        value: form.description,
                    })}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterCertificateType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                />
            </div>
        </div>
    );
};
