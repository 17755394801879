import { useMemo } from "react";

import { MasterAppointmentsTypes } from "../../../../common/enum/masters/masterAppointmentsType";



import { useAppSelector } from "../../../../redux/hooks";
import { MasterAppointmentBoardListContainer } from "./appointmentBoard/list/list";
import { MasterAppointmentTypeActuationListContainer } from "./appointmentTypeActuation/list/list";
import { ManagementAppointmentPermissionContainer } from "./appointmentPermission/container";
import { IMasterAppointmentTypeContainer } from "./appointmentType/container";
import { ManagementAppointmentDatePermissionContainer } from "./appointmentDatePermission/container";

export const MasterAppointmentListComponent = () => {
    const { codeComponent } = useAppSelector((state) => state.masterAppointment);

    const managementListComponent = useMemo(() => {
        switch (codeComponent) {
            case MasterAppointmentsTypes.APPOINTMENT_TYPES:
                return <IMasterAppointmentTypeContainer />;
            case MasterAppointmentsTypes.APPOINTMENT_TYPE_ACTUATION:
                return <MasterAppointmentTypeActuationListContainer />;
            case MasterAppointmentsTypes.RESTRICTION_DATE:
                return <ManagementAppointmentDatePermissionContainer />;
            case MasterAppointmentsTypes.BOARD:
                return <MasterAppointmentBoardListContainer />;
            case MasterAppointmentsTypes.APPOINTMENT_PERMISSION:
                return <ManagementAppointmentPermissionContainer />;
            default:
                return <></>;
        }
    }, [codeComponent]);
    return managementListComponent;
};
