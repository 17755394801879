import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../../../models";
import { IActuation } from "../../../../../../../../../models/requests/IEditRequest";
import { fetchActuationTypes } from "../../../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../redux/hooks";
import { updateActuationFormProperty } from "../../../../../../../../../redux/reducers/request/edit/form/actuation";
import {
    compileNameOfProperty,
    convertToLocal,
    getGenericPayloadFromEvent,
    mapIDescriptionToDropDownOptions,
} from "../../../../../../../../../utils";
import { FormField } from "../../../../../../../../common/fields/fields";
import { Selector } from "../../../../../../../../common/selectors/selector";
import { requestMaxLength } from "../../../../../../create/validation/requestConfig";
import { EditRequestActuationFormStyle } from "./formStyle.jss";
import { FormatDate } from "../../../../../../../../../common/enum/dateTime/FormatDate";
import { OrganismSelector } from "../../../../../../../common/selectors";
interface IPropsActuationForm {
    selectedOrganism?: any;
    showInspectorArrive?: boolean;
    disabledOrganismSelector?: boolean;
}
export const ActuationForm: React.FC<IPropsActuationForm> = ({ selectedOrganism, showInspectorArrive, disabledOrganismSelector }) => {

    const theme = useTheme();
    const styles = EditRequestActuationFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.POPUPINSPECTION" });
    const dispatch = useAppDispatch();

    const { form } = useAppSelector((store) => store.actuationForm);
    const actuationsOptions = useAppSelector((store) => store.catalog.actuationTypes.data);
    const isEditActuation = form.id ? true : false;

    const {
        register,
        control,
        formState: { errors },
    } = useForm<any>({ mode: "onChange" });

    const updateActuationFormPropierty = (value: any, name: string) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateActuationFormProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateActuationFormProperty(payload));
        },
        [dispatch],
    );

    useEffect(() => {
        !actuationsOptions.length && dispatch(fetchActuationTypes());

        if (selectedOrganism && !form.organismId) {
            updateActuationFormPropierty(Number(selectedOrganism), compileNameOfProperty<IActuation>("organismId"));
        }
    }, [actuationsOptions, selectedOrganism]);

    return (
        <div className={styles.generalContainer}>
            <div className={styles.actuationFormContainer}>
                <div className={styles.actuationFormRow}>
                    <Selector
                        title={t("ACTUATION")}
                        options={useMemo(() => mapIDescriptionToDropDownOptions(actuationsOptions), [actuationsOptions])}
                        onChange={(_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => {
                            updateActuationFormPropierty(Number(option.key), name);
                        }}
                        selectedKey={form.typeId}
                        name={compileNameOfProperty<IActuation>("typeId")}
                        fontSize={"13px"}
                        required={true}
                    />
                </div>
                <div className={styles.actuationFormRow}>
                    <FormField
                        type="text"
                        value={form.instructions}
                        label={t("INSTRUCTION")}
                        maxLength={requestMaxLength.DESCRIPTION}
                        error={errors.remarks}
                        {...register(compileNameOfProperty<IActuation>("instructions"), {
                            onChange: onInputChange,
                            value: form.instructions,
                            required: true,
                        })}
                    />
                </div>

                <div className={styles.actuationFormRow}>
                    <OrganismSelector
                        onChange={(option: any, name: string) => {
                            updateActuationFormPropierty(Number(option.key), name);
                        }}
                        isRequired={true}
                        disabled={disabledOrganismSelector}
                        selectedKey={form.organismId}
                        propertyName={compileNameOfProperty<IActuation>("organismId")}
                        title={t("ORGANISM")}
                        control={control}
                    />

                    {isEditActuation && showInspectorArrive && (
                        <FormField
                            type="text"
                            value={form.inspectorArrivedOn ? convertToLocal(form.inspectorArrivedOn, FormatDate.HOUR_MINUTE) : ""}
                            label={t("INSPECTORTIME")}
                            maxLength={requestMaxLength.DESCRIPTION}
                            error={errors.remarks}
                            {...register("inspectorArrivedOn", {
                                value: form.inspectorArrivedOn ? convertToLocal(form.inspectorArrivedOn, FormatDate.HOUR_MINUTE) : "",
                            })}
                            disabled={true}
                            readonly={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
