import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

interface IMasterAppointmentPopUpState {
    showPopUp: PopUpCodes;
    codeComponent: string;
}

const initialState: IMasterAppointmentPopUpState = {
    showPopUp: PopUpCodes.NONE,
    codeComponent: "",
};

export const masterAppointmentPopUp = createSlice({
    name: "masterAppointmentPopUp",
    initialState,
    reducers: {
        resetMasterAppointmentPopUp: () => initialState,
        setShowPopUpMasterAppointment: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setComponetMasterAppointment: (state, action: PayloadAction<string>) => {
            state.codeComponent = action.payload;
        },
        clearMasterAppointmentPopUp: (state) => {
            state.showPopUp = PopUpCodes.NONE;
        },
    },
});

export const { resetMasterAppointmentPopUp, setShowPopUpMasterAppointment, setComponetMasterAppointment, clearMasterAppointmentPopUp } =
    masterAppointmentPopUp.actions;

export default masterAppointmentPopUp.reducer;
