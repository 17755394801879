import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const EnvironmentDescriptionStyle = createUseStyles((theme: any) => ({
    container:(props:any) =>({
        padding: theme.paddings.sizes.sm,
        fontWeight: theme.fonts.weight.bolder,
        borderRadius: theme.borders.button.radius,
        color: theme.colors.palette.white,
        fontSize: theme.fonts.sizes.base,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        background:props.background,
        [mediaQueries.MOBILE]: {
            fontSize: theme.fonts.sizes.sm,
        },
    }),
}));
