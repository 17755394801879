import { useCallback } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { updateEntitySignupProperty } from "../../../../../../redux/reducers/user/signup/signup";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { TitleH5 } from "../../../../../common/titles/titles";
import { UserSignupEntityInfoStyle } from "./info.jss";
import { IEntityData } from "../../../../../../models";
import { ColorType } from "../../../../../../common/enum/ColorType";

interface IEntityFormProps {
    data: IEntityData;
    register: UseFormRegister<IEntityData>;
    errors: FieldErrors<IEntityData>;
}

export const UserEntityInfoSignupData = (props: IEntityFormProps) => {
    const style = UserSignupEntityInfoStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            if (payload.name === "documentNumber" && payload.value) {
                payload.value = payload.value.toUpperCase();
            }
            dispatch(updateEntitySignupProperty(payload));
        },
        [dispatch],
    );

    return (
        <>
            <div className={style.row}>
                <div className={style.title}>
                    <TitleH5
                        title={t("USER.TITLES.ENTITY_DATA")}
                        color={ColorType.primary}
                        bold={true}
                    />
                </div>
            </div>
           
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.ENTITY_NAME")}
                        value={props.data.name}
                        error={props.errors.name}
                        isRequired={true}
                        {...props.register(compileNameOfProperty<IEntityData>("name"), { onChange: onInputChange, value: props.data.name })}
                    />
                </div>
                <div className={style["column-70"]}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.CIF")}
                        value={props.data.documentNumber ? props.data.documentNumber.toUpperCase() : ""}
                        error={props.errors.documentNumber}
                        {...props.register(compileNameOfProperty<IEntityData>("documentNumber"), {
                            onChange: onInputChange,
                            value: props.data.documentNumber ? props.data.documentNumber.toUpperCase() : "",
                        })}
                        isRequired={true}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.FISCAL_ADDRESS")}
                        value={props.data.socialAddress}
                        error={props.errors.socialAddress}
                        {...props.register(compileNameOfProperty<IEntityData>("socialAddress"), {
                            onChange: onInputChange,
                            value: props.data.socialAddress,
                        })}
                        isRequired={true}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.POSTAL_CODE")}
                        value={props.data.postalCode}
                        error={props.errors.postalCode}
                        {...props.register(compileNameOfProperty<IEntityData>("postalCode"), {
                            onChange: onInputChange,
                            value: props.data.postalCode,
                        })}
                        isRequired={true}
                    />
                </div>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.MUNICIPALITY")}
                        value={props.data.city}
                        error={props.errors.city}
                        {...props.register(compileNameOfProperty<IEntityData>("city"), { onChange: onInputChange, value: props.data.city })}
                        isRequired={true}
                    />
                </div>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.PROVINCE")}
                        value={props.data.province}
                        error={props.errors.province}
                        {...props.register(compileNameOfProperty<IEntityData>("province"), { onChange: onInputChange, value: props.data.province })}
                        isRequired={true}
                    />
                </div>
            </div>
        </>
    );
};
