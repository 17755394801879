import { SelectorStyled } from "./selectorStyle.jss";
import { Dropdown, IComboBoxOption, IDropdown, IDropdownStyles, IRefObject } from "@fluentui/react";
import { useTheme } from "react-jss";

interface ISelectorProps {
    id?: string;
    name: string;
    title: string;
    options: IComboBoxOption[];
    onChange: (_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => void;
    comboBoxRef?: IRefObject<IDropdown>;
    selectedKey?: string | number;
    disabled?: boolean;
    className?: string;
    required?: boolean;
    isBooleanSelector?: boolean;
    fontSize?: string;
    isMultiSelect?: boolean;
    errorMessage?:string;
}

export const Selector: React.FC<ISelectorProps> = ({
    id,
    name,
    title,
    options,
    onChange,
    comboBoxRef,
    selectedKey,
    disabled,
    className,
    required,
    isBooleanSelector,
    isMultiSelect,
    errorMessage
}) => {
    const theme = useTheme();
    const selectorStyled = SelectorStyled({ theme });
    const customStyles: Partial<IDropdownStyles> = {
        root: selectorStyled.rootStyles,
        callout: selectorStyled.callout,
        dropdown: selectorStyled.dropdown,
        dropdownItem: selectorStyled.optionText,
        dropdownItemSelected: selectorStyled.optionText,
        title: selectorStyled.inputText,
        label: selectedKey || (isBooleanSelector && selectedKey === 0) ? selectorStyled.labelSelected : selectorStyled.label,
        errorMessage:selectorStyled.errorMessage
    };
    const handleChange = (_event: React.FormEvent<HTMLDivElement>, option: any) => {
        onChange(_event, option, name);
    };
    const containerClass = disabled ? selectorStyled.selectorContainerDisabled : selectorStyled.selectorContainer;
    return (
        <div className={className ? className : containerClass}>
            <Dropdown
                id={id}
                onChange={handleChange}
                componentRef={comboBoxRef}
                selectedKey={selectedKey}
                label={title}
                disabled={disabled}
                options={options}
                className={className}
                styles={customStyles}
                required={required}
                multiSelect={isMultiSelect}
                errorMessage={errorMessage}
            />
        </div>
    );
};
