import { createUseStyles } from "react-jss";

export const UserProfilePaymentInfoFormStyle = createUseStyles((theme: any) => ({
    row: {
        boxSizing: "border-box",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
    },
    column: {
        display: "flex",
        alignItems: "center",
    },

    columnBox: {
        paddingBottom: "10px",
        flexBasis: "100%",
    },
    title: {
        flexBasis: "100%",
    },

    columnTitle: {
        display: "flex",
        alignItems: "center",
        width: "55%",
    },
    columnDocument: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: 10,
        width: "60%",
    },
    documentTitle: {
        fontSize: "0.75rem",
    },
    selectorRequired: {
        color: theme.colors.palette.red,
    },
}));
