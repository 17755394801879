import { createUseStyles } from "react-jss";

export const RequestListPopUpFilterStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 15,
        marginTop: 10,
        marginBottom: 10,
        minWidth: 320,
    },
    date: (props: any) => ({
        paddingLeft: 15,
        paddingRight: 15,
        display: "flex",
        paddingTop: !props.width ? 15 : 0,
        paddingBottom: !props.width ? 15 : 0,
    justifyContent: "center",
        gap: 15  ,
        alignItems: "center",
        backgroundColor: theme.colors.requestFilter.backgroundColor,
        borderRadius: "0.25rem",
        marginLeft: 10,
        marginRight: 10,
    }),

    actions: (props: any) => ({
        display: "flex",
        backgroundColor: theme.colors.requestFilter.backgroundColor,
        fontSize: theme.fonts.sizes.sm,
        color: theme.colors.palette.purple,
        fontWeight: theme.fonts.weight.bolder,
        marginLeft: props.width ? 0 : 10,
        marginRight: props.width ? 0 : 10,
        borderRadius: "0.25rem",
        gap:  0,
        marginTop: props.width ? 10 : 0,
    }),

    action: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: 10,
    },

    filterIconContainer: {
        minWidth: "50px ",
    },
    textIconContainer: {
        minWidth: "100px",
    },
    applyFilterIcon: {
        width: 10,
        height: 10,
        background: "orange",
        borderRadius: 50,
        position: "relative",
        top: -8,
        left: -7,
    },
    verticalWhiteSeparator:(props:any)=>( {
        height: 70,
        borderLeft: theme.borders.default.whiteBorder,
    }),
}));
