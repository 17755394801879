import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const BatchesFormStyle = createUseStyles((theme: any) => ({
    container:(props:any)=> ({
        display: "flex",
        width: "100%",
        maxWidth: "30vw",
        flexDirection: "column",
        [mediaQueries.MOBILE]: {
            maxWidth: "100vw",
        },
    }),
    addFormContainer: {
        rowGap: 12,
        paddingTop: 16,
        paddingBottom: 16,
        flexWrap: "no wrap",
        display: "flex",
        flexDirection: "row",
        boxSizing: "content-box",
        columnGap: 24,
    },
    addButtonContainer: {
        minWidth: 100,
        display: "flex",
        justifyContent: "end",
    },
    titleContainer: {
        width: "100%",
    },
    dataContainer: {
        overflowY: "auto",
        rowGap: 12,
        paddingTop: 8,
        paddingBottom: 8,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
        boxSizing: "content-box",
        columnGap: 24,
        [mediaQueries.MOBILE]: {
            maxHeight: "50vh",
        },
        maxHeight: "25vh",
    },
    column: {
        display: "flex",
        flexDirection: "row",
        height: 30,
    }
}));
