import { createUseStyles } from "react-jss";

export const SpinnerStyle = createUseStyles((theme: any) => ({
    spinner: (props: any) => ({
        display: "block",
        position: "relative",
        left: "50%",
        top: "50%",
        width: props.size ?? 50,
        height: props.size ?? 50,
        margin: "-50px 0 0 -25px",
        border: "5px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "50%",
        borderLeftColor: theme.colors.palette.purple,
        animation: "$rotation 1s linear infinite",
        WebkitAnimation: "$rotation 1s linear infinite",
    }),
    "@keyframes rotation": {
        from: { WebkitTransform: "rotate(0deg)", msTransform: "rotate(0deg)", animation: "rotate(0deg)" },
        to: { WebkitTransform: "rotate(360deg)", msTransform: "rotate(360deg)", animation: "rotate(360deg)" },
    },
}));
