import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { VlcportImportRequestContainerStyle } from "./containerStyle.jss";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { IContextualMenuItem } from "@fluentui/react";
import { ButtonContextualMenu } from "../../common/contextualMenu/contextualMenu";
import { VlcPortImportRequestFormController } from "./controller/controller";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setVlcPortImportRequestPopUp } from "../../../redux/reducers/vlcPort/importRequest/form";
import { useState } from "react";
import { VlcPortImportRequestList } from "./list/list";
import { fetchVlcImportRequestTemplate } from "../../../redux/actions/vlcPort/importRequest/importRequest";
import { DownloadDataIcon } from "../../common/downloadData/downloadData";
import { Alert } from "../../common/alert/alert";
import { fonts } from "../../../styles/romeu/fonts";
import { ColorType } from "../../../common/enum/ColorType";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

export enum ImportRequest {
    DOCUMENT = "importDocument",
    CODE = "importText",
}

export const VlcportImportRequestContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.IMPORT_REQUEST" });
    const theme = useTheme();
    const style = VlcportImportRequestContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const [codeComponent, setCodeComponent] = useState<string>("");
    const { data } = useAppSelector((state) => state.VlcPortImportRequestList);

    const menuItems: IContextualMenuItem[] = [
        {
            key: ImportRequest.DOCUMENT,
            onClick: () => {
                dispatch(setVlcPortImportRequestPopUp(PopUpCodes.CREATE));
                setCodeComponent(ImportRequest.DOCUMENT);
            },
            text: t("IMPORT_DOCUMENT"),
        },
        {
            key: ImportRequest.CODE,
            onClick: () => {
                dispatch(setVlcPortImportRequestPopUp(PopUpCodes.CREATE));
                setCodeComponent(ImportRequest.CODE);
            },
            text: t("IMPORT_CODE"),
        },
    ];
    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonContextualMenu
                        items={menuItems}
                        buttonTitle={t("IMPORT")}
                    />
                }
                endAction={
                    <DownloadDataIcon
                        title={t("DOWNLOAD_TEMPLATE")}
                        onDownloadAction={() => {
                            dispatch(fetchVlcImportRequestTemplate());
                        }}
                    />
                }
            />
            <VlcPortImportRequestFormController
                codeComponent={codeComponent}
                setCodeComponent={setCodeComponent}
            />

            {data && data?.resultsError > 0 && <VlcPortImportRequestList />}

            {data && data?.resultsError === 0 && (
                <Alert
                    colorType={ColorType.success}
                    fontSize={fonts.sizes.xl}
                    data={t("MESSAGE")}
                />
            )}
        </div>
    );
};
