import { createUseStyles } from "react-jss";

export const InputStyle = createUseStyles((theme: any) => ({
    input: {
        border: "none",
        backgroundColor: "transparent",
        borderBottom: `1.3px solid ${theme.colors.form.input.default}`,
        color: theme.colors.form.input.filled,
        fontSize: theme.fonts.sizes.base,
        padding: `7px 0`,
        display: "block",
        width: "100%",
        "&:focus": {
            outline: "none",
            borderBottomColor: theme.colors.form.input.focus,
        },
        "&:valid, &:focus-visible": {
            outline: "none",
            backgroundColor: "transparent",
        },
        "&:disabled": {
            borderBottom: theme.borders.selector.disabledBorder,            
            fontWeight: "200",
        },
    },
}));
