import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const ManagementMenuSettingsByRolContainerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",

        gap: theme.gaps.sizes.base,
    },

    containerList: {
        color: theme.colors.palette.black,
        height: "calc(100vh - 14rem)",
        backgroundColor: "white",
        paddingTop: 15,
        paddingBottom: 15,
        overflow: "auto",
    },
    list: {
        display: "flex",
        gap: 15,

        flexWrap: "wrap",
    },

    item: {
        width: "23%",
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },

    actions: {
        padding: "17px 24px 0px 24px",
        display: "flex",
        gap: theme.gaps.buttons.default,
        justifyContent: "end",
        alignItems: "center",
        "& button": {
            minWidth: 110,
        },
    },
}));
