import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterDocumentTypesList } from "./list/list";
import { setDocumentTypes } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterDocumentTypesList } from "../../../../../redux/reducers/resources/master/documentTypes/list";

export class MasterDocumentTypesContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterDocumentTypesList());
        store.dispatch(setDocumentTypes([]));
    }

    render(): React.ReactNode {
        return (
            <MasterDocumentTypesList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
