import { createUseStyles } from "react-jss";

export const LabelTextStyle = createUseStyles((theme: any) => ({
    labelContainer: (props: any) => ({
        display: "flex",
        flexFlow: "column",
        rowGap: 2,
        width: props.styleProps?.dataWidth ?? "auto",
    }),
    label: (props: any) => ({
        color: props.styleProps?.labelColor ?? theme.colors.font.labels.title,
        fontSize: props.styleProps?.labelSize ?? theme.fonts.sizes.xs,
    }),
    data: (props: any) => ({
        color: props.styleProps?.dataColor ?? theme.colors.font.labels.value,
        fontSize: props.styleProps?.dataSize ?? theme.fonts.sizes.md,
        fontWeight: 600,
        width: "100%",
        cursor: "pointer",
    }),
}));
