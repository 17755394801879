import { Method } from "../../../common/enum";
import { IConfigDockMerchandiseForm, IConfigDockMerchandiseListItem } from "../../../models/config/dockMerchandise";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/catalog/dockzones`;

export class ConfigDockMerchandiseAPI {
    async getConfigDockMerchandiseList(filter: any): Promise<IConfigDockMerchandiseListItem[]> {
        const url = `${baseURL}/all`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IConfigDockMerchandiseListItem[]>();
    }

    async getConfigDockMerchandise(idConfig: number): Promise<IConfigDockMerchandiseForm> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IConfigDockMerchandiseForm>();
    }
    async deleteConfigDockMerchandise(idConfig: number): Promise<boolean> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
    async postConfigDockMerchandise(config: any): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(config).execute<boolean>();
    }
    async putConfigDockMerchandise(config: any): Promise<boolean> {
        const url = `${baseURL}/${config.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(config).execute<boolean>();
    }
}
