import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMerchandiseCategoryGroup } from "../../../../../models/resources/master/IGroupMerchandiseCategory";

export interface IMasterGroupMerchandiseCategoryState {
    form: IMerchandiseCategoryGroup;
    loading: boolean;
}

const initialState: IMasterGroupMerchandiseCategoryState = {
    form: {
        active: true,
        code: "",
        description: "",
        id:0
    },
    loading: false,
};

export const masterTypesWareForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterGroupMerchandiseCategoryForm: () => initialState,
        setLoadingMasterGroupMerchandiseCategoryForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterGroupMerchandiseCategoryForm: (state, action: PayloadAction<IMerchandiseCategoryGroup>) => {
            state.form = action.payload;
        },
        updateMasterGroupMerchandiseCategoryProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterGroupMerchandiseCategoryForm,
    setLoadingMasterGroupMerchandiseCategoryForm,
    setMasterGroupMerchandiseCategoryForm,
    updateMasterGroupMerchandiseCategoryProperty,
} = masterTypesWareForm.actions;

export default masterTypesWareForm.reducer;
