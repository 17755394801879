import { createUseStyles } from "react-jss";

export const TableBodyStyle = createUseStyles((theme: any) => ({
    row: {
        display: "flex",
        flexDirection: "column",
        fontSize: theme.fonts.sizes.sm,
        paddingTop: 8,
        paddingBottom: 9,
        borderBottom: theme.borders.table.body.border,
        color: theme.colors.table.body.color,
        "& div ": {
            display: "flex",
        },
    },

    movileRow: {
        display: "flex",
        width: "100%",
        flex: "1 1 auto",
        borderBottom: theme.borders.table.body.border,
        paddingTop: 8,
        paddingBottom: 9,
        paddingLeft: theme.paddings.sizes.xs,
        paddingRight: theme.paddings.sizes.xs,
    },

    movileActions: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    movileHeader: {
        width: "40%",
        alignItems: "center",
        display: "flex",
    },
    movileData: {
        width: "60%",
        color: theme.colors.table.body.color,
        paddingLeft: theme.paddings.sizes.xs,
        paddingRight: theme.paddings.sizes.xs,
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    mobileTable: {
        border: theme.borders.default.border,
        padding: theme.paddings.sizes.sm,
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.sm,
        alignContent: "center",
        alignItems: "center",
        marginBottom: theme.margins.sizes.base,
        "& > :last-child": {
            border: "none",
        },
    },
}));
