import { createUseStyles } from "react-jss";

export const TableListStyles = createUseStyles((theme: any) => ({
    tableContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    tableHeader: {
        display: "flex",
        color: theme.colors.table.header.color,
        alignItems: "center",
        backgroundColor: theme.colors.palette.purpleLight,
        height: 41,
        "& > div ": {
            width: "19.9%",
            textTransform: "uppercase",
            alignItems: "center",
            display: "flex",
            fontSize: "10px !important",
            fontWeight: theme.fonts.weight.bold,
            fontFamily: `${theme.fonts.families.customTable} !important`,
        },
    },
    tableBody: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        fontSize: 11,
        gap: theme.gaps.sizes.sm,
        paddingTop: theme.paddings.sizes.xs,
        paddingBottom: theme.paddings.sizes.xs,

        fontFamily: `${theme.fonts.families.customTable} !important`,
        "& > .row": {
            width: theme.widths.sectionContainer.complete,
            display: "flex",
            color: theme.colors.table.body.color,

            "& .separator": {
                flex: "1 1 auto",
                display: "flex",
                borderBottom: theme.borders.table.body.border,
            },

            "& .separator > .cell": {
                width: "25%",
                fontWeight: 400,
                fontSize:12,
                display: "flex",
                paddingLeft: 1,
                paddingBottom: theme.paddings.sizes.xs,
                alignItems: "center",
                fontFamily: `${theme.fonts.families.customTable} !important`,
            },
        },
        "& .row:last-child > .separator": {
            border: "0px !important",
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
        },
    },
}));
