import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { RequestStateSort } from "../../../../../../models";
import { IEditRequest } from "../../../../../../models/requests/IEditRequest";
import { IEditRequestStepProps } from "../../../../../../models/requests/request/IEditRequestStepProps";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
    pushBatchToList,
    pushSealToList,
    resetBatchProperty,
    resetBatches,
    resetSealProperty,
    resetSeals,
} from "../../../../../../redux/reducers/request/edit/editRequest";
import { getUserOrganization } from "../../../../../../redux/selectors/user/organization/organization";
import { getUserRole } from "../../../../../../redux/selectors/user/role/role";
import { widths } from "../../../../../../styles/romeu/widths";
import { compileNameOfProperty } from "../../../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { FormField } from "../../../../../common/fields/fields";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { TitleH5 } from "../../../../../common/titles/titles";
import { BooleanSelector, MerchandiseOriginSelector, MerchandiseTypeSelector, TerminalSelector } from "../../../../common/selectors";
import { MerchandiseCategoryId } from "../../../../common/selectors/merchandiseCategoryId/merchandiseCategoryId";
import { booleanValidation, renderRequiredData } from "../../../create/validation/requestValidationUtils";
import { GeneralInformationEditContainerStyle } from "../containerStyle.jss";
import { useContainer } from "../../../../../../hooks/request/useContainer";
import { OrganizationTypes, RoleType } from "../../../../../../common/enum";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { SealTypes } from "../../../../../../common/enum/SealType";
import { Seal } from "../../components/seals/seal/seal";
import { OriginSeals } from "../../components/seals/form";
import { BatchesForm } from "../../components/batches/form";
import { Batch } from "../../components/batches/batch/batch";

export const GoodInfoData: React.FC<IEditRequestStepProps> = ({
    schema,
    onInputChange,
    onSelectorChange,
    onYesNoOptionsChange,
    resetPropertyWhenParentChange,
    register,
    control,
    errors,
    disabledAppointmentData,
}) => {
    const dispatch = useAppDispatch();
    const { headerRequest, seals, batches } = useAppSelector((store) => store.editRequest);
    const [showSealFormPopUp, setShowSealFormPopUp] = useState<boolean>(false);
    const [showBatchesFormPopUp, setShowBatchesFormPopUp] = useState<boolean>(false);

    const { id: userOrganizationId } = useSelector(getUserOrganization);
    const { id: userRoleId } = useSelector(getUserRole);

    const theme = useTheme();
    const style = GeneralInformationEditContainerStyle({ theme });
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });

    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });

    const isContainer = useContainer(headerRequest.transportUnitId);
    const merchandaiseDataRow = schema.merchandiseOriginId.visible || schema.humanConsumption.visible || schema.merchandiseTypeId.visible;
    const merchandiseCategoryAndDescription = schema.merchandiseCategoryId.visible || schema.description.visible || schema.originCountryCode.visible;
    const tariffHeadingAndArrivalShip = schema.tariffHeading.visible || schema.merchandiseArrivalShip.visible;

    const terminalRequired = useMemo(() => {
        if (!isContainer) return false;

        if (userOrganizationId === OrganizationTypes.ALG) return true;

        if (userOrganizationId === OrganizationTypes.VLC) {
            const isAdminRole = [
                RoleType.SUPER_ADMIN,
                RoleType.TEAM_LEADER,
                RoleType.AREA_DIRECTOR,
                RoleType.DIRECTOR,
                RoleType.ADMINISTRATIVE,
            ].includes(userRoleId as RoleType);

            return !(isAdminRole && headerRequest.stateSort === RequestStateSort.SUBMITTED);
        }

        return schema.terminalId.required;
    }, [userRoleId, headerRequest.stateSort, isContainer, userOrganizationId, schema.terminalId]);

    const onCancelSealFormPopUp = () => {
        dispatch(resetSeals());
        setShowSealFormPopUp(false);
        dispatch(resetSealProperty());
    };

    const onSaveSealFormPopUp = () => {
        dispatch(pushSealToList(seals));
        onCancelSealFormPopUp();
    };

    const sealsRequest = useMemo(() => {
        if (headerRequest.seals.filter((seal) => seal.typeId !== SealTypes.ORIGIN).length === 0) {
            return <div>{t("FIELDS.NOT_DATA")}</div>;
        }

        return headerRequest.seals.map((seal, index) => {
            if (seal.typeId !== SealTypes.ORIGIN) {
                return (
                    <div key={`${OriginSeals.name}-${index}`}>
                        <Seal
                            seal={seal}
                            index={index}
                            newSeal={false}
                            isRemoveSeal={schema.newSeals.readonly}
                        />
                    </div>
                );
            }
        });
    }, [headerRequest.seals, schema.newSeals.readonly]);

    const onCancelBatchesFormPopUp = () => {
        dispatch(resetBatches());
        setShowBatchesFormPopUp(false);
        dispatch(resetBatchProperty());
    };

    const onSaveBatchesFormPopUp = () => {
        dispatch(pushBatchToList(batches));
        onCancelBatchesFormPopUp();
    };

    const batchesRequest = useMemo(() => {
        if (headerRequest.batches.length === 0) {
            return <div>{t("FIELDS.NOT_DATA")}</div>;
        }

        return headerRequest.batches.map((item, index) => {
            return (
                <div key={`${BatchesForm.name}-${index}`}>
                    <Batch
                        batch={item}
                        index={index}
                        isNew={false}
                        allowRemove={!schema.batches.readonly}
                    />
                </div>
            );
        });
    }, [headerRequest.batches, schema.batches.readonly]);

    return (
        <div className={style.container}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                {merchandaiseDataRow && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.merchandiseOriginId.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <MerchandiseOriginSelector
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.merchandiseOriginId}
                                    propertyName={compileNameOfProperty<IEditRequest>("merchandiseOriginId")}
                                    title={t("FIELDS.MERCHANDISEORIGINDESCRIPTION")}
                                    isRequired={schema.merchandiseOriginId.required}
                                    disabled={schema.merchandiseOriginId.readonly || disabledAppointmentData}
                                    control={control}
                                    rules={{ required: renderRequiredData(schema.merchandiseOriginId.required) }}
                                />
                            </div>
                        )}
                        {schema.humanConsumption.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <BooleanSelector
                                    onChange={onYesNoOptionsChange}
                                    selectedKey={headerRequest?.humanConsumption !== null ? +headerRequest?.humanConsumption : undefined}
                                    propertyName={compileNameOfProperty<IEditRequest>("humanConsumption")}
                                    title={t("FIELDS.HUMANCONSUMPTION")}
                                    isRequired={schema.humanConsumption.required}
                                    control={control}
                                    disabled={schema.humanConsumption.readonly || disabledAppointmentData}
                                    rules={{
                                        required: renderRequiredData(schema.humanConsumption.required),
                                        validate: () => booleanValidation(schema.humanConsumption.required, headerRequest.humanConsumption),
                                    }}
                                />
                            </div>
                        )}
                        {schema.merchandiseTypeId.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <MerchandiseTypeSelector
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.merchandiseTypeId}
                                    propertyName={compileNameOfProperty<IEditRequest>("merchandiseTypeId")}
                                    title={t("FIELDS.MERCHANDISETYPEDESCRIPTION")}
                                    filterOptions={true}
                                    merchandiseOriginId={headerRequest.merchandiseOriginId}
                                    humanConsumption={headerRequest.humanConsumption}
                                    resetSelectorAtRedux={resetPropertyWhenParentChange}
                                    isRequired={schema.merchandiseTypeId.required}
                                    disabled={schema.merchandiseTypeId.readonly || disabledAppointmentData}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.merchandiseTypeId.required),
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {merchandiseCategoryAndDescription && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.originCountryCode.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.originCountryCode || ""}
                                    label={t("FIELDS.ORIGINCOUNTRYCODE")}
                                    isRequired={schema.originCountryCode.required}
                                    disabled={schema.originCountryCode.readonly}
                                    error={errors.originCountryCode}
                                    {...register(compileNameOfProperty<IEditRequest>("originCountryCode"), {
                                        onChange: onInputChange,
                                        required: renderRequiredData(schema.originCountryCode.required),
                                        value: headerRequest.originCountryCode,
                                    })}
                                />
                            </div>
                        )}
                        {schema.merchandiseCategoryId.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <MerchandiseCategoryId
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.merchandiseCategoryId}
                                    propertyName={compileNameOfProperty<IEditRequest>("merchandiseCategoryId")}
                                    title={t("FIELDS.MERCHANDISECATEGORYDESCRIPTION")}
                                    isRequired={schema.merchandiseCategoryId.required}
                                    control={control}
                                    rules={{ required: renderRequiredData(isContainer || schema.terminalId.required) }}
                                    disabled={schema.merchandiseCategoryId.readonly}
                                    merchandiseTypeId={headerRequest.merchandiseTypeId}
                                    humanConsumption={headerRequest.humanConsumption}
                                    merchandiseOriginId={headerRequest.merchandiseOriginId}
                                />
                            </div>
                        )}
                    </div>
                )}
                {schema.description.visible && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <FormField
                                type="text"
                                value={headerRequest.description || ""}
                                label={t("FIELDS.DESCRIPTION")}
                                isRequired={schema.description.required}
                                disabled={schema.description.readonly}
                                error={errors.description}
                                {...register(compileNameOfProperty<IEditRequest>("description"), {
                                    onChange: onInputChange,
                                    required: renderRequiredData(schema.description.required),
                                    value: headerRequest.description,
                                })}
                            />
                        </div>
                    </div>
                )}

                {tariffHeadingAndArrivalShip && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.tariffHeading.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.tariffHeading}
                                    maxLength={50}
                                    label={t("FIELDS.TARIFF_ITEM")}
                                    isRequired={schema.tariffHeading.required}
                                    disabled={schema.tariffHeading.readonly}
                                    {...register(compileNameOfProperty<IEditRequest>("tariffHeading"), {
                                        onChange: onInputChange,
                                        value: headerRequest.tariffHeading,
                                        validate: (value: any) => (schema.tariffHeading.required ? !!value : true),
                                        required: renderRequiredData(schema.tariffHeading.required),
                                    })}
                                />
                            </div>
                        )}
                        {schema.merchandiseArrivalShip.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.merchandiseArrivalShip}
                                    label={t("FIELDS.MERCHANDISEARRIVALSHIP")}
                                    maxLength={255}
                                    error={errors.merchandiseArrivalShip}
                                    isRequired={schema.merchandiseArrivalShip.required}
                                    disabled={schema.merchandiseArrivalShip.readonly}
                                    {...register(compileNameOfProperty<IEditRequest>("merchandiseArrivalShip"), {
                                        onChange: onInputChange,
                                        validate: () => (schema.merchandiseArrivalShip.required ? !!headerRequest.merchandiseArrivalShip : true),
                                        required: renderRequiredData(schema.merchandiseArrivalShip.required && !headerRequest.merchandiseArrivalShip),
                                    })}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                    {schema.summaryNumber.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <FormField
                                type="text"
                                value={headerRequest.summaryNumber}
                                maxLength={11}
                                label={t("FIELDS.SUMMARY")}
                                isRequired={schema.summaryNumber.required}
                                disabled={schema.summaryNumber.readonly}
                                {...register(compileNameOfProperty<IEditRequest>("summaryNumber"), {
                                    onChange: onInputChange,
                                    validate: () => (schema.summaryNumber.required ? !!headerRequest.summaryNumber : true),
                                    required: renderRequiredData(schema.summaryNumber.required),
                                })}
                            />
                        </div>
                    )}
                    {schema.terminalId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <TerminalSelector
                                onChange={onSelectorChange}
                                selectedKey={headerRequest.terminalId}
                                propertyName={compileNameOfProperty<IEditRequest>("terminalId")}
                                title={t("FIELDS.TERMINALDESCRIPTION")}
                                isRequired={terminalRequired}
                                disabled={schema.terminalId.readonly || !isContainer}
                                control={control}
                                rules={{
                                    required: schema.terminalId.readonly ? false : renderRequiredData(terminalRequired),
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={style.titleSeal}>
                    <TitleH5
                        title={t("TITLES.SEALS_SECTION")}
                        color={ColorType.primary}
                        bold={true}
                    />

                    {!schema.newSeals.readonly && (
                        <ButtonPrimaryInverseWithBorder
                            handleClick={() => setShowSealFormPopUp(true)}
                            title={`+ ${t("BUTTONS.ADD_SEAL")}`}
                        />
                    )}
                </div>
                <div className={style.sealsSectionContainer}>{sealsRequest}</div>

                {schema.batches.visible && (
                    <>
                        <div className={style.titleBatch}>
                            <TitleH5
                                title={t("TITLES.BATCHES_SECTION")}
                                color={ColorType.primary}
                                bold={true}
                            />

                            {!schema.batches.readonly && (
                                <ButtonPrimaryInverseWithBorder
                                    handleClick={() => setShowBatchesFormPopUp(true)}
                                    title={`+ ${t("BUTTONS.ADD_BATCH")}`}
                                />
                            )}
                        </div>
                        <div className={style.batchesSectionContainer}>{batchesRequest}</div>
                    </>
                )}
            </div>

            <PopUpForm
                content={<OriginSeals />}
                title={t("BUTTONS.ADD_SEAL")}
                height={"auto"}
                width={isMobile ? widths.editRequest.originalSeal.mobileWidth : widths.editRequest.originalSeal.width}
                isVisible={showSealFormPopUp}
                onCancel={onCancelSealFormPopUp}
                onSave={onSaveSealFormPopUp}
            />

            <PopUpForm
                content={<BatchesForm />}
                title={t("BUTTONS.ADD_BATCH")}
                height={"auto"}
                width={isMobile ? widths.editRequest.batch.mobileWidth : widths.editRequest.batch.width}
                isVisible={showBatchesFormPopUp}
                onCancel={onCancelBatchesFormPopUp}
                onSave={onSaveBatchesFormPopUp}
            />
        </div>
    );
};
