import { createUseStyles } from "react-jss";

export const incidencePopUpStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.gaps.sizes.xxl,
        paddingTop: theme.paddings.sizes.base,
    },
    warningMenssage: {
        color: theme.colors.titles.primary.color,
        fontWeight: theme.fonts.weight.bolder,
    },
}));
