import { createUseStyles } from "react-jss";

export const ActionsFilterHeaderStyle = createUseStyles((theme: any) => ({

    container:(props:any)=>({
        display: "flex",
        flexDirection:"row",
        alignItems: "end",
        justifyContent: "flex-start",
        "& > div": {
            display: "flex",
            flexDirection: props.column?"column": "row",
            gap:props.column? theme.gaps.sizes.xs: theme.gaps.sizes.base,
            alignItems:"end",
            "& button": {
                width: 50,
                "& i": {
                    fontSize: "15px",
                },
            },
        },
    }) 
}));
