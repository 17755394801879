import { IChoiceGroupOption, IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { IGenericPayload } from "../models";
import { IField } from "../models/requests/IUserRoleView";
import i18n from "../services/i18n";
import { DefaultValues } from "../common/enum/DefaultValues";

export const compileNameOfProperty = <T>(name: keyof T) => name;

export type KeysOfUnion<T> = T extends T ? keyof T : never;

export const getGenericPayloadFromEvent = (event: any): IGenericPayload => {
    return {
        name: event.target.name,
        value: typeof event.target.value === "string" && event.target.value.length === 0 ? null : event.target.value,
    };
};

const commonOptions = {
    YES: "common:COMMON.YES",
    NO: "common:COMMON.NO",
    ALL: "common:COMMON.ALL",
};

export const yesNoOptions: IComboBoxOption[] = [
    {
        key: 0,
        text: i18n.t(commonOptions.NO),
        title: i18n.t(commonOptions.NO),
    },
    {
        key: 1,
        text: i18n.t(commonOptions.YES),
        title: i18n.t(commonOptions.YES),
    },
];

export const yesNoOptionsWithDefault: IComboBoxOption[] = [
    {
        key: -1,
        text: i18n.t(commonOptions.ALL),
        title: i18n.t(commonOptions.ALL),
    },
    {
        key: 0,
        text: i18n.t(commonOptions.NO),
        title: i18n.t(commonOptions.NO),
    },
    {
        key: 1,
        text: i18n.t(commonOptions.YES),
        title: i18n.t(commonOptions.YES),
    },
];

export const isFieldVisible = (fields: IField[], name: string): boolean => {
    return fields.some((field) => field.name.toLowerCase() === name.toLowerCase());
};

export const isFieldRequired = (fields: IField[], name: string): boolean => {
    return fields.find((field) => field.name.toLowerCase() === name.toLowerCase())?.required === true;
};

export const isFieldReadOnly = (fields: IField[], name: string): boolean => {
    return fields.find((field) => field.name.toLowerCase() === name.toLowerCase())?.readOnly === true;
};

export const requiredMessage: string = i18n.t("common:FORM.VALIDATION:REQUIRE");

export const removeDefaultOptionsFromStringArray = (array: string[]): string[] | undefined => {
    const filteredArray = array.filter((item) => item !== stringDefaultValue.key);
    return filteredArray.length === 0 ? undefined : filteredArray;
};

export const removeDefaultOptionsFromNumberArray = (array: number[]): number[] | undefined => {
    const filteredArray = array.filter((item) => item !== numericDefaultValue.key);
    return filteredArray.length === 0 ? undefined : filteredArray;
};

export const numericDefaultValue: IDropdownOption = {
    key: Number(DefaultValues.DEFAULT_ALL_SELECTOR),
    text: i18n.t(commonOptions.ALL),
};

export const stringDefaultValue: IDropdownOption = {
    key: DefaultValues.DEFAULT_ALL_SELECTOR,
    text: i18n.t(commonOptions.ALL),
};

export const radioButtonDefaultOptions: IChoiceGroupOption[] = [
    { key: "0", text: i18n.t(commonOptions.NO) },
    { key: "1", text: i18n.t(commonOptions.YES) },
];
