import { createUseStyles } from "react-jss";

export const ManagementSummaryFormFormStyle = createUseStyles((theme: any) => ({
    containerForm: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.xl,
        width: "100%",
        overflowY: "auto",
        paddingTop: theme.paddings.sizes.base,
    },
    rowForm: {
        display: "flex",
        gap: theme.gaps.sizes.xl,
        alignItems: "end",
    },
    discharged: {
        color: theme.colors.messageAlert.warning.color,
        fontSize: theme.fonts.sizes.base,
    },
    timePicker: {
        marginBottom: 5,
    },
}));
