import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrganization, IUserSignup } from "../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateUserSignupProperty } from "../../../../../redux/reducers/user/signup/signup";
import { compileNameOfProperty } from "../../../../../utils";
import { Checkboxs } from "../../../../common/checkbox/checkbox";
import { LinkPrimary } from "../../../../common/links/links";
import { PopUp } from "../../../../common/popup/popUp";
import { FormSection } from "../../../../common/section/formSection";
import { TermsAndConditions } from "../../../../termsAndConditions/termsAndConditions";
import { UserSignupResumeTermsAndConditionsStyle } from "./containerStyle.jss";

interface UserSignupResumeTermsAndConditionsProps {
    data: IUserSignup;
}

export const UserSignupResumeTermsAndConditions: React.FC<UserSignupResumeTermsAndConditionsProps> = ({ data }) => {
    const styles = UserSignupResumeTermsAndConditionsStyle();
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const organiations: IOrganization[] = useAppSelector((store) => store.catalog.organizations.data);

    const organization = organiations.filter((org: IOrganization) => org.id === data.organizationId)[0];

    const [isPopUpVisible, setPopUpVisible] = useState<boolean>(false);

    const onCheckboxChange = useCallback(
        () => (event: any) => {
            const payload = {
                name: event.target.name,
                value: event.target.checked,
            };
            dispatch(updateUserSignupProperty(payload));
        },
        [dispatch],
    );

    const onTermsAndConditionsClick = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setPopUpVisible(true);
    }, []);

    const popUpChildren = (
        <div className={styles.popUpContainer}>
            <TermsAndConditions
                fiscalName={organization?.fiscalName}
                fiscalAddress={organization?.fiscalAddress}
            />
        </div>
    );

    const formSectionChildren = (
        <div className={styles.container}>
            <Checkboxs
                name={compileNameOfProperty<IUserSignup>("acceptedTermsAndConditions")}
                isChecked={data.acceptedTermsAndConditions}
                handleChange={onCheckboxChange()}
                required={true}
                defaultWidth={true}
            />
            <div>
                {t("USER.SIGNUP.I_ACCEPT_THE")}{" "}
                <LinkPrimary
                    to=""
                    title={t("USER.TITLES.TERMS_AND_CONDITIONS").toLocaleLowerCase()}
                    bold={true}
                    onClick={onTermsAndConditionsClick}
                />
            </div>
        </div>
    );

    return (
        <>
            <PopUp
                title={t("USER.TITLES.TERMS_AND_CONDITIONS")}
                children={popUpChildren}
                state={isPopUpVisible}
                hideModal={() => {
                    setPopUpVisible(false);
                }}
            />

            <FormSection
                title={t("USER.TITLES.TERMS_AND_CONDITIONS")}
                children={formSectionChildren}
                lastSection={true}
            />
        </>
    );
};
