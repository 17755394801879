import { IDropdownOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { fetchCustomerOptions } from "../../../../../redux/actions/user/users/users";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";

import { mapUserAdminDataToDropDownOptions } from "../../../../../utils";
import { IBaseSelectorProps } from "../../../../request/common/selectors/IBaseSelectorProps";

interface ICustomerSelectorProps extends IBaseSelectorProps {
    setValue: UseFormSetValue<any>;
    filteredRegisteredCustomer?: boolean;
}

export const CustomerSelector: React.FC<ICustomerSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    isRequired,
    control,
    rules,
    setValue,
    disabled,
    filteredRegisteredCustomer,
}) => {
    const { data, loading } = useAppSelector((store) => store.usersByRole.customerAdmin);
    const dispatch = useAppDispatch();
    const [selectorkey, setSelectorKey] = useState<number>(0);

    useEffect(() => {
        data === undefined && dispatch(fetchCustomerOptions());
        data && data.length === 1 && setOptionIfNotYetSelected();
    }, [dispatch, data]);

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _ind?: number) => {
        onChange(option, propertyName);
    };

    const setOptionIfNotYetSelected = async () => {
        if (!selectedKey && data) {
            let option: IDropdownOption = {
                key: data[0].id,
                text: data[0].entityData.name,
            };
            onChange(option, propertyName);
            setSelectorKey((value) => value + 1);
            setValue(propertyName, String(data[0].id));
        }
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={mapUserAdminDataToDropDownOptions(data ?? [], filteredRegisteredCustomer ?? true)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
            key={`${CustomerSelector.name}-${selectorkey}`}
        />
    );
};
