import { createUseStyles } from "react-jss";

export const AppointmentBoardContainerStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        padding: 15,
        height: "calc(100vh - 15rem)",
        overflowY: "auto",
    },

    appointment: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#f2f2f2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    appointmentLock: {
        backgroundColor: "#dddddd",
    },
    appointmentContent: {
        color: "#333",
        fontWeight: theme.fonts.weight.bold,
        fontSize: theme.fonts.sizes.xs,
    },
    appointmentAvailable: {
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#e5e5e5",
        },
    },

    tableContainer: {
        margin: "25px 0",
    },
    table: {
        borderCollapse: "collapse",
        width: "100%",
        "& > thead > tr > th , & > tbody > tr > th, & > tbody > tr > td": {
            borderTop: "1px solid #ccc",
            borderLeft: "1px solid #FCFCFC",
            borderRight: "1px solid #FCFCFC",
            padding: 8,
            textAlign: "center",
            position: "relative",
            height: 25,
            width: 200,
        },
        "& > thead > tr > th , & > tbody > tr > th": {
            backgroundColor: "#f2f2f2",
            fontWeight: theme.fonts.weight.bold,
        },
        "& > thead > tr > th:first-child , & > tbody > tr > th:first-child, & > tbody > tr > td:first-child": {
            borderLeft: "none",
            width: 200,
        },
    },
    "state-0": {},
    "state-10": {
        backgroundColor: theme.colors.appointmentBoardStates.greyStatusContainer,
    },
    "state-20": {
        backgroundColor: theme.colors.appointmentBoardStates.yellowStatusContainer,
    },
    "state-30": {
        backgroundColor: theme.colors.appointmentBoardStates.yellowStatusContainer,
    },
    "state-40": {
        backgroundColor: theme.colors.appointmentBoardStates.orangeStatusContainer,
    },
    "state-50": {
        backgroundColor: theme.colors.appointmentBoardStates.redStatusContainer,
    },
    "state-60": {
        backgroundColor: theme.colors.appointmentBoardStates.greenLigthStatusCode,
    },
    "state-70": {
        backgroundColor: theme.colors.appointmentBoardStates.redStatusContainer,
    },
    "state-80": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-90": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-100": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-110": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-120": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
}));
