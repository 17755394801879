import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const DatePickerStyled = createUseStyles((theme: any) => ({
    datePickerContainer: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "end",
       
        background: "transparent !important",
    },
    customStyles: (props: any) => ({
        flexFlow: "column",
        width: "100%",
        alignItems:"end",

        [mediaQueries.MOBILE]: {
            width: "100%",
        },
        " & input:focus + label": {
            color: theme.colors.form.input.focus,
            fontSize: theme.fonts.sizes.xs,
            transform: "translateY(0)",
            background: "transparent",
        },
        "& .ms-Label": {
            color: theme.colors.form.input.default,
            fontSize: props.hasValue ? theme.fonts.sizes.xs : theme.fonts.sizes.base,
            fontWeight: 100,
            position: "absolute",
            pointerEvents: "none",
            top: props.hasValue ? -14 : 0,
            transform: "translateY(0px)",
            transition: "all 0.2s ease-in-out",
            left: 5,
            zIndex: 5555,
            fontFamily: theme.fonts.families.site,
        },
        "& .ms-Label::after": {
            color: theme.colors.palette.red,
        },
        "& .ms-TextField-fieldGroup": {
            minWidth: "120px",
            fontSize: "13px",
            color: theme.colors.form.input.default,
            fontFamily: theme.fonts.families.site,
            border: "none",
            background: "transparent",
            "&:after": {
                border: "none",
                background: "transparent",
                borderBottom: "1px solid " + theme.colors.navLinks.secondary.color,
            },
            borderBottom: !props.disabled ? "1px solid " + theme.colors.navLinks.secondary.color : theme.borders.selector.disabledBorder,
        },
        "& .ms-TextField-field": {
            fontFamily: theme.fonts.families.site,
            color: theme.colors.form.input.filled,
        },
        "& input:disabled": {
            background: "transparent",

        },
        "& .ms-DatePicker-event--with-label": {
            display: props.disabled ? "none" : "",
        },
        "& .ms-TextField-errorMessage": {
            position: "absolute",
            fontSize: theme.fonts.sizes.xs,
            color: theme.colors.form.input.invalid,
            marginTop: "-5px",
            fontFamily: theme.fonts.families.site,
        },
    }),
}));
