import { IConfigRoleProperty } from "../../../../models";
import { IField } from "../../../../models/requests/IUserRoleView";
import { isFieldRequired, compileNameOfProperty, isFieldVisible, isFieldReadOnly } from "../../../../utils";


export interface IConfigUpcominAppointmentListSchema {
    transportUnitNumber: IConfigRoleProperty;
    appointmentDockDescription: IConfigRoleProperty;
    appointmentDate: IConfigRoleProperty;
    appointmentTimeZoneTimeRange: IConfigRoleProperty;
    terminalDescription: IConfigRoleProperty;
    expedientInspector: IConfigRoleProperty;
    customerName: IConfigRoleProperty;
    requestStateDescription: IConfigRoleProperty;
    transportUnitSizeCode: IConfigRoleProperty;
}

export const generateUpcomingAppointmentListSchema = (fields: IField[]): IConfigUpcominAppointmentListSchema => {
    return {
        appointmentDate: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentDate")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentDate")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentDate")),
        },
        appointmentDockDescription: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentDockDescription")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentDockDescription")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentDockDescription")),
        },
        transportUnitNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("transportUnitNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("transportUnitNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("transportUnitNumber")),
        },
        appointmentTimeZoneTimeRange: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentTimeZoneTimeRange")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentTimeZoneTimeRange")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("appointmentTimeZoneTimeRange")),
        },
        terminalDescription: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("terminalDescription")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("terminalDescription")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("terminalDescription")),
        },
        expedientInspector: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("expedientInspector")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("expedientInspector")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("expedientInspector")),
        },
        customerName: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("customerName")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("customerName")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("customerName")),
        },
        requestStateDescription: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("requestStateDescription")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("requestStateDescription")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("requestStateDescription")),
        },
        transportUnitSizeCode: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("transportUnitSizeCode")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("transportUnitSizeCode")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentListSchema>("transportUnitSizeCode")),
        },
    };
};
