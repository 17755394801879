import { createUseStyles } from "react-jss";

export const OriginSealItemStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        height:25
    },
    content: {
        backgroundColor: theme.colors.palette.greyBlue,
        display: "flex",
        justifyContent: "center",
        gap: 30,
        borderRadius: 20,
        paddingLeft: 5,
        paddingRight: 5,
    },
    text: {
        display: "flex",
        alignContent: "center",
        padding: 5,
        width: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontWeight: "600",
        color: theme.colors.palette.shadowBlack,
        opacity: 1,
    },

    button: {
        fontWeight: 600,
        color: theme.colors.palette.red,
    },
}));
