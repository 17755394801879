import { useEffect } from "react";
import { SkeletonSizes } from "../../../../common/constants";
import { fetchManagementDockQueueRuleListAction } from "../../../../redux/actions/management/dockQueueRule/dockQueueRule";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementDockQueueRuleListColumn } from "./column/column";

export const ManagementParkingValidationRuleList = () => {
    const { loading, list } = useAppSelector((state) => state.managementDockQueueRuleList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return ManagementDockQueueRuleListColumn();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchManagementDockQueueRuleListAction());
    }, [list]);

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={renderColumns}
        />
    );
};
