import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import {
    emptyManagementRepresentationFilter,
    IManagementRepresentationFilter,
} from "../../../../models/management/representation/IManagementRepresentationFilter";

interface IManagementRepresentationFilterState {
    filter: IManagementRepresentationFilter;
}

const initialState: IManagementRepresentationFilterState = {
    filter: emptyManagementRepresentationFilter(),
};

export const managementRepresentationFilterSlice = createSlice({
    name: "managementRepresentationFilter",
    initialState,
    reducers: {
        resetFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetFilter, updateFilterProperty } = managementRepresentationFilterSlice.actions;

export default managementRepresentationFilterSlice.reducer;
