import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserSignupTypeContainerStyle = createUseStyles((theme: any) => ({
    container: {
        flexFlow: "row",
        display: "flex",
        flex: "100%",
        padding: theme.paddings.default,
        [mediaQueries.MOBILE]: {
            padding: 0,
        },
    },
    column: {
        flexFlow: "column",
        display: "flex",
        flex: "100",
        justifyContent: "space-between",
    },
   
    buttonsRow: {
        flexFlow: "row",
        display: "flex",
        justifyContent: "end",
        columnGap: theme.paddings.default,
    },
    rowForm : {
        display: "flex",
       
        width:"25%",
        gap:theme.gaps.form.row,
        [mediaQueries.MOBILE]: {
            flexFlow:"column", 
            width:"100%"
        },
    }
}));
