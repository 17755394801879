import { createUseStyles } from "react-jss";

export const CustomerDatePickerStyle = createUseStyles((theme: any) => ({
    datePicker: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: theme.colors.palette.black,
    },
    dateTitle: {
        fontWeight: "lighter",
        fontSize: theme.fonts.sizes.xs,
    },
    dateContainer: (props: any) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: 10,
        cursor: "pointer",
        paddingTop: props.showLeftArrowController ? 0 : theme.paddings.sizes.xs,
        " & i": {
            width: 25,
            fontSize: theme.fonts.sizes.lg,
            color: theme.colors.palette.purple,
        },
    }),
    date: (props: any) => ({
        fontSize: theme.fonts.defaults.datePicker,
        fontWeight: theme.fonts.weight.bold,
        display: "flex",
        gap: 10,
        width: props.showLeftArrowController ? 200 : 207,
    }),
    currentDate: {
        fontWeight: theme.fonts.weight.lighter,
    },
    calendarIcon: {
        fill: theme.colors.daypicker.todayColor,
    },
    controller: {
        display: "flex",
        gap: 15,
        marginTop: 10,
    },
    reverse: {
        transform: "scaleX(-1)",
    },
    iconRange: {
        display: "flex",
        alignItems: "center",
        " & i": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.palette.purple,
        },
        " & .arrow": {
            transform: "none",
        },
        " & .arrowUp": {
            transform: "rotate(180deg)",
        },
    },
}));
