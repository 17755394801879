import { createUseStyles } from "react-jss";

export const confirmMessageStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        gap: theme.gaps.sizes.xl,
        alignItems: "center",
        width: "100%",
    },

    text:(props:any)=>({
        color: theme.colors.titles.black.color,
        wordBreak: "break-word",
        textAlign: "start",
        textJustify: "inter-word",
        fontSize: props.fontSize ?props.fontSize: theme.fonts.sizes.xxl,
    }) 
        
    ,
    icon: {
        width: theme.widths.confirmPopUp.icon,
    },
    infoIcon: {
        width: theme.widths.confirmPopUp.icon,
        stroke: theme.colors.buttons.info.background,
    },
    removeIcon: {
        width: theme.widths.confirmPopUp.icon,
        fill: theme.colors.buttons.danger.background,
    },
    iconWarning: {
        fontSize: theme.widths.confirmPopUp.icon,
        color: theme.colors.buttons.warning.background,
    },
}));
