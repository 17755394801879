import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { IInvoicingSummaryByConcept } from "../../../../../models/invoicing/summaryByConcept/IInvoicingSummaryByConcept";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { store } from "../../../../../redux/store";
import { updateSortColumn } from "../../../../../redux/reducers/invoicing/summaryByConcept/list";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { InvoicingSummaryByConceptColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const InvoicingSummaryByConceptColumn = (ref?: any): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "INVOICED.SUMMARYLIST" });
    const theme = useTheme();
    const styles = InvoicingSummaryByConceptColumnStyle({ theme });
    const dispatch = useAppDispatch();

    const sort = useAppSelector((state) => state.invoicingSummaryConceptList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().invoicingSummaryConceptList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IInvoicingSummaryByConcept>("tariffDescription"),
            minWidth: 120,
            maxWidth: 1000,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingSummaryByConcept>("tariffDescription")}
                    title={t("DESCRIPTION")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingSummaryByConcept>("tariffDescription"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingSummaryByConcept) => <span>{item.tariffDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingSummaryByConcept>("quantity"),
            minWidth: 100,
            maxWidth: 200,
            name: t("UNITS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingSummaryByConcept>("quantity")}
                    title={t("UNITS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingSummaryByConcept>("quantity"))}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingSummaryByConcept) => <span>{item.quantity ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingSummaryByConcept>("amount"),
            minWidth: 100,
            maxWidth: 200,
            name: t("TOTAL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingSummaryByConcept>("amount")}
                    title={t("TOTAL")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingSummaryByConcept>("amount"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingSummaryByConcept) => (
                <span>
                    {item.amount ?? DefaultValues.EMPTY_LIST} {DefaultValues.EURO}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingSummaryByConcept>("percentage"),
            minWidth: 100,
            maxWidth: 200,
            name: t("PERCENT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingSummaryByConcept>("percentage")}
                    title={t("PERCENT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingSummaryByConcept>("percentage"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingSummaryByConcept) => <span>{item.percentage ? `${item.percentage}%` : DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
