import { Icon, IconButton, IContextualMenuProps, IIconProps, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useTheme } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { NameIcons } from "../../../assets/icons";
import { componentsMediaQueries } from "../../../common/constants";
import { CustomIconsStyle } from "./customIconsStyle.jss";
import { useMemo } from "react";

interface ICustomIconsProps {
    iconName?: NameIcons | string;
    className?: string;
    onClick?: () => any;
    isButton?: boolean;
    tooltip?: string;
    size?: any;
    iconButtonStyles?: any;
    margin?: any;
    isDisabled?: boolean;
    breackResponse?: string;
    disabledSelect?: boolean;
    containerClass?: string;
    title?: string;
    id?: string;
    position?: string;
    columnPosition?: string;
    menuProps?: IContextualMenuProps;
    itemRef?: any;
}

export const CustomIcons: React.FC<ICustomIconsProps> = ({
    iconName: icon,
    onClick,
    isButton,
    className,
    tooltip,
    size,
    iconButtonStyles,
    margin,
    isDisabled,
    breackResponse,
    disabledSelect,
    containerClass,
    position,
    title,
    id,
    columnPosition,
    menuProps,
    itemRef,
}) => {
    const isMobile = useMediaQuery({ query: breackResponse ? breackResponse : componentsMediaQueries.TABLET });
    const theme = useTheme();
    let newPosition = position ? position : "end";
    const style = CustomIconsStyle({ theme, size, margin, isDisabled, isMobile, disabledSelect, position: newPosition, isButton, columnPosition });
    const iconProps: IIconProps = { iconName: icon, className: `${style.icon} ${className} ${isDisabled ? style.isDisabled : ""}` };
    const clickIfISEnabled = () => {
        !isDisabled && onClick && onClick();
    };
    const IconItem = useMemo(() => {
        if (isButton) {
            return (
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={iconProps}
                    onClick={clickIfISEnabled}
                    title={title}
                    id={id}
                    disabled={isDisabled}
                    menuProps={menuProps}
                />
            );
        }

        return (
            <Icon
                className={`${style.icon} ${className} ${isDisabled ? style.isDisabled : ""}`}
                iconName={icon}
                onClick={clickIfISEnabled}
                title={title}
                id={id}
            />
        );
    }, [, iconButtonStyles, iconProps, clickIfISEnabled, title, id, isDisabled, menuProps, isButton]);

    const buttonTooltip = useMemo(() => {
        return (
            <TooltipHost
                content={tooltip}
                delay={TooltipDelay.zero}
            >
                {IconItem}
            </TooltipHost>
        );
    }, [tooltip, IconItem]);

    return <div className={`${style.container} ${containerClass}`}>{tooltip && !isDisabled ? buttonTooltip : IconItem}</div>;
};
