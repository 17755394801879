import { createUseStyles } from "react-jss";

export const SignupResumeDataTypeStyle = createUseStyles((theme: any) => ({
  container: {
    paddingLeft: theme.paddings.default,
    "& label": {
      color: theme.colors.titles.secondary,
      fontWeight: 600,
    },
  },
}));
