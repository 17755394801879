import { ICalloutProps, IStyleFunctionOrObject, ITeachingBubbleStyleProps, ITeachingBubbleStyles } from "@fluentui/react";

const calloutStyles: ICalloutProps["styles"] = {
    beak: {
        background: "white",
    },
};

export const stylesBuble: IStyleFunctionOrObject<ITeachingBubbleStyleProps, ITeachingBubbleStyles> | undefined = {
    root: {
        background: "white",
        color: "black",
    },
    content: {
        background: "white",
        animation: "none",
    },
    subText: {
        color: "black",
    },
    subComponentStyles: {
        callout: calloutStyles,
    },
};
