import { Icon, IPivotItemProps, Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../../models";
import { useAppSelector } from "../../../../../redux/hooks";
import { setManagementUserSelectedPivotKey, updatePaymentDataProperty } from "../../../../../redux/reducers/management/user/form";
import { ManagementUserCustomerFormBankPay } from "./bankpay/bankpay";
import { ManagementCustomerFormEntityData } from "./entity/entity";
import { ManagementCustomerFormUserData } from "./user/user";
import { ManagementUserDefaultFormStyle } from "../baseFormStyle.jss";
import { ManagementUserFormOrganizationsTab } from "../common/organizationsTab/organizationsTab";

interface IManagementUserCustomerAdminFormProps {
    onInputChange: (event: any) => any;
    setIsAvailableSave: (value: any) => any;
    userMigration: boolean;
}

export const ManagementUserCustomerAdminForm: React.FC<IManagementUserCustomerAdminFormProps> = ({
    onInputChange,
    setIsAvailableSave,
    userMigration,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER.TABS" });
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const { selectedPivotKey, generalUserData } = useAppSelector((store) => store.managementUserForm);
    const [validUserDataTab, setValidUserDataTab] = useState<boolean>(false);
    const [validUserEntityDataBubble, setValidUserEntityDataBubble] = useState<boolean>(false);
    const [validUserPaymentDataBubble, setValidUserPaymentDataBubble] = useState<boolean>(false);

    const setSelectedPivotKey = (key: string) => {
        dispatch(setManagementUserSelectedPivotKey(key));
    };

    const bubbleRender = (link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        let showBubble: boolean = false;
        switch (link.itemKey) {
            case "0":
                showBubble = !validUserDataTab;
                break;
            case "1":
                showBubble = !validUserEntityDataBubble;

                break;
            case "2":
                showBubble = !validUserPaymentDataBubble;
                break;
        }

        return (
            <span style={{ flex: "0 1 100%" }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                {showBubble && (
                    <Icon
                        iconName={link.itemIcon}
                        style={{ color: "orange" }}
                    />
                )}
            </span>
        );
    };

    useEffect(() => {
        setIsAvailableSave(validUserDataTab && validUserEntityDataBubble && validUserPaymentDataBubble ? true : false);
    }, [validUserDataTab, validUserEntityDataBubble, validUserPaymentDataBubble]);

    return (
        <Pivot
            selectedKey={String(selectedPivotKey)}
            className={style.pivot}
            onLinkClick={(event) => {
                setSelectedPivotKey(event?.props.itemKey ? event?.props.itemKey : "0");
            }}
        >
            <PivotItem
                headerText={t("USER")}
                itemKey="0"
                itemIcon="LocationDot"
                onRenderItemLink={bubbleRender}
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementCustomerFormUserData
                        onInputChange={onInputChange}
                        generalUserData={generalUserData}
                        setIsValidTab={setValidUserDataTab}
                        userMigration={userMigration}
                    />
                </div>
            </PivotItem>

            <PivotItem
                headerText={t("ENTITY")}
                itemKey="1"
                itemIcon="LocationDot"
                onRenderItemLink={bubbleRender}
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementCustomerFormEntityData
                        generalUserData={generalUserData}
                        setIsValidTab={setValidUserEntityDataBubble}
                    />
                </div>
            </PivotItem>

            <PivotItem
                headerText={t("BANK_INFO")}
                itemKey="2"
                itemIcon="LocationDot"
                onRenderItemLink={bubbleRender}
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementUserCustomerFormBankPay
                        generalUserData={generalUserData}
                        updateDataProperty={(value: IGenericPayload) => {
                            dispatch(updatePaymentDataProperty(value));
                        }}
                        setIsValidTab={setValidUserPaymentDataBubble}
                    />
                </div>
            </PivotItem>
            
            {generalUserData.availableOrganizations.length > 1 && (
                <PivotItem
                headerText={t("ORGANIZATIONS")}
                itemKey="3"
                alwaysRender={true}
                >
                    <div className={style.pivotItem}>
                        <ManagementUserFormOrganizationsTab
                            generalUserData={generalUserData}
                        />
                    </div>
                </PivotItem>
            )}

        </Pivot>
    );
};
