import { IPivotItemProps, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { routeFeatures } from "../../../../../../common/constants/routeFeatures";
import { DefaultValues } from "../../../../../../common/enum/DefaultValues";
import { IGenericPayload, ViewType } from "../../../../../../models";
import { IUserRoleView, IView } from "../../../../../../models/requests/IUserRoleView";
import { onApplyFilterAction } from "../../../../../../redux/actions/request/filter/filterRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { resetFilter, updateRequestFilterProperty } from "../../../../../../redux/reducers/request/list/filter";
import { LocalStorageKey, LocalStorageService } from "../../../../../../services/localStorageService";
import { Bubble } from "../../../../../common/bubble/bubble";
import { ButtonPrimary, ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { Separator } from "../../../../../common/separator/separator";
import { generateFilterRequestSchema } from "../../../../common/schema/generateFilterRequestSchema";
import { RequestExpedientInfoPivotFilter } from "./expedient/expedient";
import { RequestFilterPivotStyle } from "./filterStyle.jss";
import { RequestInspectionPivotFilter } from "./inspection/inspection";
import { RequestMerchandisePivotFilter } from "./merchandise/merchandise";
import { RequestUtiPivotFilter } from "./uti/uti";


export const RequestFilterPivot = () => { 
    const theme = useTheme();
    const styles = RequestFilterPivotStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.TITLES" });
    const dispatch = useAppDispatch();
    const [selectedKey, setSelectedKey] = React.useState("0");
    const localStorageService = new LocalStorageService();
    const {
        requestListFilter: { filter },
        userData: { profile },
        userRoleViews: { viewPermission },
        organization: {
            organization: { id: currentOrganization },
        },
    } = useAppSelector((state) => state);

    const viewRequestPermission: IUserRoleView | undefined = useMemo(() => viewPermission?.find((feature) => feature.id === routeFeatures.REQUEST), [viewPermission]);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view) => view.type === ViewType.SEARCH) as IView;
    }, [viewRequestPermission?.views]);

    const schema = useMemo(() => {
        return viewConfig && viewConfig.fields && generateFilterRequestSchema(viewConfig.fields);
    }, [viewConfig]);

    const organization = useMemo(() => {
        return profile.organizations.find((item) => item.id === currentOrganization);
    }, [profile.organizations, currentOrganization]);



    const showUtiPivot: boolean =
        schema &&
        (schema.sortVisible ||
            schema.transportUnitNumberVisible ||
            schema.transportUnitIdVisible ||
            schema.transportUnitSizeIdVisible ||
            schema.customerIdVisible ||
            schema.valuedVisible ||
            schema.invoicedVisible);

    const showMerchandisePivot: boolean =
        schema &&
        (schema.merchandiseTypeIdVisible ||
            schema.merchandiseOriginIdVisible ||
            schema.customsStateIdVisible ||
            schema.halalCertifiedVisible ||
            schema.dryChargeVisible ||
            schema.organicProductVisible ||
            schema.fumigatedVisible);

    const showExpedientPivot: boolean =
        schema &&
        (schema.certificateNumberVisible ||
            schema.certificateTypeIdVisible ||
            schema.actuationTypeIdVisible ||
            schema.organismIdVisible ||
            schema.inspectorAssignedVisible ||
            schema.inspectorIdVisible ||
            schema.vuaVisible ||
            schema.summaryNumberVisible ||
            schema.terminalIdVisible ||
            schema.finalDestinationTypeIdVisible);

    const showInspectionPivot: boolean =
        schema &&
        (schema.parkingAuthorizationVisible ||
            schema.summaryActiveVisible ||
            schema.turnIdVisible ||
            schema.stateIdVisible ||
            schema.dockIdVisible ||
            schema.documentTypeIdVisible ||
            schema.appVisible ||
            schema.appointmentTimeZoneVisible);

    const onMultipleNumberKeysChange = useCallback(
        (name: string, numberOptions: number[]) => {
            const payload: IGenericPayload = {
                name,
                value: numberOptions,
            };
            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );

    const onMultipleStringKeysChange = useCallback(
        (name: string, stringOptions: string[]) => {
            const payload: IGenericPayload = {
                name,
                value: stringOptions,
            };

            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );

    const onNullableBooleanChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );

    const onInputChange = useCallback(
        (event: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: event.target.value,
            };
            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );

    const onInputNumberChange = useCallback(
        (value: number | undefined, name: string) => {
            const payload: IGenericPayload = {
                name,
                value,
            };
            dispatch(updateRequestFilterProperty(payload));
        },
        [dispatch],
    );
    const onApply = useCallback(() => {
        dispatch(onApplyFilterAction());
        localStorageService.setItem(LocalStorageKey.DETAILS_LIST_SCROLL_TOP, "0");
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(resetFilter());
        if(profile.organismId !== undefined) {
            dispatch(updateRequestFilterProperty({ name: "organismId", value: [profile.organismId] }));
        }
        dispatch(onApplyFilterAction());
        setSelectedKey("0");
        localStorageService.setItem(LocalStorageKey.DETAILS_LIST_SCROLL_TOP, "0");
    }, [dispatch, profile]);

    const showUtiBubble: boolean = useMemo(() => {
        return (
            filter.sort !== undefined ||
            (filter.transportUnitNumber !== undefined && filter.transportUnitNumber !== "") ||
            !(filter.transportUnitId.length === 1 && filter.transportUnitId[0] === -1) ||
            !(filter.transportUnitSizeId.length === 1 && filter.transportUnitSizeId[0] === -1) ||
            !(filter.customerId.length === 1 && filter.customerId[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            !(filter.valued.length === 1 && filter.valued[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            filter.invoiced !== undefined
        );
    }, [filter]);

    const showMerchandiseBubble: boolean = useMemo(() => {
        return (
            !(filter.merchandiseTypeId.length === 1 && filter.merchandiseTypeId[0] === -1) ||
            !(filter.merchandiseOriginId.length === 1 && filter.merchandiseOriginId[0] === -1) ||
            !(filter.customsStateId.length === 1 && filter.customsStateId[0] === -1) ||
            filter.halalCertified !== undefined ||
            filter.dryCharge !== undefined ||
            filter.organicProduct !== undefined ||
            filter.fumigated !== undefined || 
            (filter.description !== undefined && filter.description !== "")
        );
    }, [filter]);

    const showExpedientBubble: boolean = useMemo(() => {
        return (
            (filter.certificateNumber !== undefined && filter.certificateNumber !== "") ||
            !(filter.certificateTypeId.length === 1 && filter.certificateTypeId[0] === -1) ||
            !(filter.actuationTypeId.length === 1 && filter.actuationTypeId[0] === -1) ||
            (!(filter.organismId.length === 1 && filter.organismId[0] === -1) && !profile.organismId) ||
            filter.inspectorAssigned !== undefined ||
            !(filter.inspectorId.length === 1 && filter.inspectorId[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            filter.vua !== undefined ||
            (filter.summaryNumber !== undefined && filter.summaryNumber !== "") ||
            !(filter.terminalId.length === 1 && filter.terminalId[0] === -1) ||
            !(filter.finalDestinationTypeId.length === 1 && filter.finalDestinationTypeId[0] === -1)
        );
    }, [filter]);

    const showInspectionBubble: boolean = useMemo(() => {
        let show =
            filter.parkingAuthorization !== undefined ||
            filter.summaryActive !== undefined ||
            !(filter.turnId.length === 1 && filter.turnId[0] === -1) ||
            !(filter.stateId.length === 1 && filter.stateId[0] === -1) ||
            !(filter.dockId.length === 1 && filter.dockId[0] === -1) ||
            !(filter.documentTypeId.length === 1 && filter.documentTypeId[0] === -1) ||
            !(filter.appointmentTimeZoneId && filter.appointmentTimeZoneId.length === 1 && filter.appointmentTimeZoneId[0] === -1) ||
            filter.app !== undefined;

        return show;
    }, [filter]);

    const bubbleRender = (link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        let showBubble: boolean = false;

        switch (link.itemKey) {
            case "0":
                showBubble = showUtiBubble;
                break;
            case "1":
                showBubble = showMerchandiseBubble;
                break;
            case "2":
                showBubble = showExpedientBubble;
                break;
            case "3":
                showBubble = showInspectionBubble;
                break;
        }

        return (
            <span style={{ flex: "0 1 100%" }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                <Bubble
                    showBubble={showBubble}
                    iconName={link.itemIcon ?? ""}
                />
            </span>
        );
    };

    return (
        <>
            {schema && (showUtiPivot || showMerchandisePivot || showExpedientPivot || showInspectionPivot) && (
                <div className={styles.container}>
                    <Pivot
                        className={styles.pivot}
                        selectedKey={String(selectedKey)}
                        onLinkClick={(event) => {
                            setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                        }}
                    >
                        {showUtiPivot && (
                            <PivotItem
                                headerText={t("REQUEST_DATA")}
                                itemKey="0"
                                itemIcon="LocationDot"
                                onRenderItemLink={bubbleRender}
                                alwaysRender
                            >
                                <RequestUtiPivotFilter
                                    onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                                    onNullableBooleanChange={onNullableBooleanChange}
                                    onInputChange={onInputChange}
                                    onMultipleStringKeysChange={onMultipleStringKeysChange}
                                    onInputNumberChange={onInputNumberChange}
                                    schema={schema}
                                    onEnterKey={onApply}
                                />
                            </PivotItem>
                        )}
                        {showMerchandisePivot && (
                            <PivotItem
                                headerText={t("MERCHANDISE")}
                                itemKey="1"
                                itemIcon="LocationDot"
                                onRenderItemLink={bubbleRender}
                                alwaysRender
                            >
                                <RequestMerchandisePivotFilter
                                    onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                                    onNullableBooleanChange={onNullableBooleanChange}
                                    schema={schema}
                                    organization={organization}
                                    onInputChange={onInputChange}
                                    onEnterKey={onApply}
                                />
                            </PivotItem>
                        )}
                        {showExpedientPivot && (
                            <PivotItem
                                headerText={t("EXPEDIENT_INFO")}
                                itemKey="2"
                                itemIcon="LocationDot"
                                onRenderItemLink={bubbleRender}
                                alwaysRender
                            >
                                <RequestExpedientInfoPivotFilter
                                    onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                                    onNullableBooleanChange={onNullableBooleanChange}
                                    onInputChange={onInputChange}
                                    onMultipleStringKeysChange={onMultipleStringKeysChange}
                                    schema={schema}
                                    onEnterKey={onApply}
                                />
                            </PivotItem>
                        )}
                        {showInspectionPivot && (
                            <PivotItem
                                headerText={t("INSPECTION")}
                                itemKey="3"
                                itemIcon="LocationDot"
                                onRenderItemLink={bubbleRender}
                                alwaysRender
                            >
                                <RequestInspectionPivotFilter
                                    onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                                    onNullableBooleanChange={onNullableBooleanChange}
                                    schema={schema}
                                />
                            </PivotItem>
                        )}
                    </Pivot>

                    <Separator size="85%" />

                    <div className={styles.buttons}>
                        <div>
                            <ButtonPrimaryInverseWithBorder
                                handleClick={onReset}
                                icon="ClearFilter"
                            />

                            <ButtonPrimary
                                handleClick={onApply}
                                icon="Search"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
