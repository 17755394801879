import { Method } from "../../../common/enum";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/dockdisplayscreen`;

export class ManagementDockDisplayApi {
    async getManagementDocksDisplayAsync(organization?: string): Promise<any> {
        const url = organization ? `${baseURL}/${organization}` : baseURL;
        return organization
            ? apiFetchOAuthWithClaims(Method.GET, url).withHeader("x-organization-identifier", organization).execute<any>()
            : apiFetchOAuthWithClaims(Method.GET, url).execute<any>();
    }
}
