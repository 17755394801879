import { createUseStyles } from "react-jss";

export const ManagementIncidenceFilterStyle = createUseStyles((theme: any) => ({
    filterContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.filter.default,
    },

    item: {
        minWidth: 250,
    },
}));
