import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IActuationsTypesWithLoading,
    IActuationType,
    IActuationTypesGroup,
    IActuationTypesGroupsWithLoading,
    ICertificatesTypesGroupWithLoading,
    ICertificateType,
    ICustomsState,
    ICustomsStatesWithLoading,
    IDock,
    IDocksWithLoading,
    IDocumentsTypesWithLoading,
    IDocumentType,
    IFinalDestinationsTypesWithLoading,
    IFinalDestinationType,
    IIncidencesTypesWithLoading,
    IIncidenceType,
    IInvoiceConceptGroupWithLoading,
    IMerchandiseOrigin,
    IMerchandiseOriginsWithLoading,
    IMerchandiseType,
    IMerchandiseTypesWithLoading,
    IOrganism,
    IOrganismsWithLoading,
    IOrganization,
    IOrganizationsWithLoading,
    IRepresentativePresenceType,
    IRepresentativePresenceTypesWithLoading,
    IRequestState,
    IRequestStatesWithLoading,
    IRole,
    IRolesWithLoading,
    ISealType,
    ISealTypesWithLoading,
    ITransportUnit,
    ITransportUnitSize,
    ITransportUnitSizesWithLoading,
    ITransportUnitsWithLoading,
    ITurn,
    ITurnsWithLoading,
    IUserPositionType,
    IUserPositionTypesWithLoading,
    IValuedType,
    IValuedTypesWithLoading,
} from "../../../models/catalog";
import { IDockBlock, IDockBlockWithLoading } from "../../../models/catalog/IDockBlock";
import {
    IMerchandiseCategoryOption,
    IMerchandiseCategoryRealtionShipOption,
    IMerchandiseCategoryRealtionShipWithLoading,
    IMerchandiseCategoryWithLoading,
} from "../../../models/catalog/IMercandiseCategory";
import { INotificationMode, INotificationModesWithLoading } from "../../../models/catalog/INotificationMode";
import { INotificationType, INotificationTypesWithLoading } from "../../../models/catalog/INotificationType";
import { ITerminal, ITerminalsWithLoading } from "../../../models/catalog/ITerminal";
import { IRequestDayPermission, IRequestDayPermissionWithLoading } from "../../../models/catalog/requestDayPermission";

import { IInvoiceConcept } from "../../../models/management/tariff/ITariffData";
import { IMasterAppointmentType, IMasterAppointmentTypeWithLoading } from "../../../models/masterAppointment/IAppointmentType";
import { IActuationGroupsWithLoading, IMasterActuationGroupRemaining } from "../../../models/resources/master/IActuationGroup";

import { IAppointmentTimeZone, IAppointmentTimeZoneWithLoading } from "../../../models/catalog/IAppointmentTimeZone";
import { IMasterTransportUnitRemaining, IMasterTransportUnitRemainingWithLoading } from "../../../models/resources/master/ITransportUnit";
import { IActuationTypeWithLoading, IMasterActuationTypeRemaining } from "../../../models/resources/master/IActuationType";
import { IMasterCertificateTypesRemaining } from "../../../models/resources/master/ICertificateType";
import { ICustomStatesRemainingWithLoading, IMasterCustomsStateRemaining } from "../../../models/resources/master/ICustomsState";
import {
    IFinalDestinationTypeRemainingWithLoading,
    IMasterFinalDestinationTypeRemaining,
} from "../../../models/resources/master/IFinalDestinationType";
import { IMerchandiseCategoryGroupWithLoading, IMerchandiseCategoryGroup } from "../../../models/resources/master/IGroupMerchandiseCategory";
import { IMerchandiseCategoryRemainingWithLoading, IMasterMerchandiseCategoryRemaining } from "../../../models/resources/master/IMerchandiseCategory";
import { IMasterMerchandiseTypeRemaining } from "../../../models/resources/master/IMerchandiseTypes";
import { IMasterNotificationTypeWhithLoading } from "../../../models/resources/master/INotificationType";
import { IRepresentativePresenceWithLoading } from "../../../models/resources/master/IPresenceRepresentative";
import { IRequestStateRemainingWithLoading, IMasterRequestStateRemaining } from "../../../models/resources/master/IRequestState";
import { IMasterTurnRemainingWithLoading, IMasterTurnRemaining } from "../../../models/resources/master/ITurn";
import { IMasterTypeSealRemaining } from "../../../models/resources/master/ITypeSeal";

interface CatalogState {
    organizations: IOrganizationsWithLoading;
    finalDestinations: IFinalDestinationsTypesWithLoading;
    customsStates: ICustomsStatesWithLoading;
    merchandiseOrigins: IMerchandiseOriginsWithLoading;
    merchandiseTypes: IMerchandiseTypesWithLoading;
    merchandiseTypesWithGroup: IMerchandiseTypesWithLoading;
    transportUnits: ITransportUnitsWithLoading;
    representativePresenceTypes: IRepresentativePresenceTypesWithLoading;
    terminals: ITerminalsWithLoading;
    sealTypes: ISealTypesWithLoading;
    turns: ITurnsWithLoading;
    organisms: IOrganismsWithLoading;
    roles: IRolesWithLoading;
    certificates: ICertificatesTypesGroupWithLoading;
    certificatesByOrganism: ICertificatesTypesGroupWithLoading;
    transportUnitSizes: ITransportUnitSizesWithLoading;
    incidenceTypes: IIncidencesTypesWithLoading;
    actuationTypes: IActuationsTypesWithLoading;
    actuationTypesForFilter: IActuationsTypesWithLoading;
    docks: IDocksWithLoading;
    docksQueued: IDock[];
    valuedTypes: IValuedTypesWithLoading;
    documentTypes: IDocumentsTypesWithLoading;
    requestStates: IRequestStatesWithLoading;
    actuationsGroup: IActuationTypesGroupsWithLoading;
    invoiceConcepts: IInvoiceConceptGroupWithLoading;
    userPositionTypes: IUserPositionTypesWithLoading;
    merchandiseCategories: IMerchandiseCategoryWithLoading;
    merchandiseCategoriesSorted: IMerchandiseCategoryWithLoading;
    dockBlock: IDockBlockWithLoading;
    merchandiseCategoriesRelationShip: IMerchandiseCategoryRealtionShipWithLoading;
    notificationTypes: INotificationTypesWithLoading;
    notificationModes: INotificationModesWithLoading;
    requestDayPermission: IRequestDayPermissionWithLoading;
    appointmentDayPermissionTypes: IRequestDayPermissionWithLoading;
    organismsRemaining: IOrganismsWithLoading;
    certificateTypesRemaining: IActuationGroupsWithLoading;
    merchandiseOriginsRemaining: IMerchandiseOriginsWithLoading;
    merchandiseCategoryRemaining: IMerchandiseCategoryRemainingWithLoading;
    documentTypeRemaining: IMerchandiseCategoryRemainingWithLoading;
    sealTypeRemaining: IMerchandiseTypesWithLoading;
    finalDestinationTypeRemaining: IFinalDestinationTypeRemainingWithLoading;
    representativePresenceTypesRemaining: IRepresentativePresenceWithLoading;
    turnRemaining: IMasterTurnRemainingWithLoading;
    requestStatesRemaining: IRequestStateRemainingWithLoading;
    actuationGroupReamining: IActuationGroupsWithLoading;
    actuationTypesRemaining: IActuationTypeWithLoading;
    appointmentTypesRemaining: IMasterAppointmentTypeWithLoading;
    appointmentTypes: IMasterAppointmentTypeWithLoading;
    groupMerchandiseCategoryRemaining: IMerchandiseCategoryGroupWithLoading;
    merchandiseCategoryGroup: IMerchandiseCategoryGroupWithLoading;
    customsStatesRemaining: ICustomStatesRemainingWithLoading;
    notificationTypesRemaining: IMasterNotificationTypeWhithLoading;
    transportUnitRemaining: IMasterTransportUnitRemainingWithLoading;
    appointmentTimeZone: IAppointmentTimeZoneWithLoading;
}

const defaultCatalogData = {
    data: [],
    loading: false,
};
const customCatalogData = {
    loading: false,
};
const initialState: CatalogState = {
    organizations: defaultCatalogData,
    finalDestinations: defaultCatalogData,
    customsStates: defaultCatalogData,
    merchandiseOrigins: defaultCatalogData,
    merchandiseTypes: defaultCatalogData,
    transportUnits: defaultCatalogData,
    representativePresenceTypes: defaultCatalogData,
    terminals: defaultCatalogData,
    sealTypes: defaultCatalogData,
    turns: defaultCatalogData,
    organisms: defaultCatalogData,
    certificates: defaultCatalogData,
    certificatesByOrganism: defaultCatalogData,
    actuationTypes: defaultCatalogData,
    actuationTypesForFilter: defaultCatalogData,
    requestStates: customCatalogData,
    documentTypes: defaultCatalogData,
    transportUnitSizes: defaultCatalogData,
    docks: defaultCatalogData,
    docksQueued: [],
    valuedTypes: customCatalogData,
    roles: customCatalogData,
    actuationsGroup: defaultCatalogData,
    incidenceTypes: defaultCatalogData,
    invoiceConcepts: defaultCatalogData,
    userPositionTypes: defaultCatalogData,
    merchandiseCategories: defaultCatalogData,
    merchandiseCategoriesRelationShip: defaultCatalogData,
    notificationTypes: defaultCatalogData,
    notificationModes: defaultCatalogData,
    requestDayPermission: defaultCatalogData,
    appointmentDayPermissionTypes: defaultCatalogData,
    organismsRemaining: defaultCatalogData,
    certificateTypesRemaining: defaultCatalogData,
    merchandiseOriginsRemaining: defaultCatalogData,
    merchandiseCategoryRemaining: defaultCatalogData,
    documentTypeRemaining: defaultCatalogData,
    sealTypeRemaining: defaultCatalogData,
    finalDestinationTypeRemaining: defaultCatalogData,
    representativePresenceTypesRemaining: defaultCatalogData,
    turnRemaining: defaultCatalogData,
    requestStatesRemaining: defaultCatalogData,
    merchandiseCategoriesSorted: defaultCatalogData,
    dockBlock: defaultCatalogData,
    actuationGroupReamining: defaultCatalogData,
    actuationTypesRemaining: defaultCatalogData,
    appointmentTypesRemaining: customCatalogData,
    groupMerchandiseCategoryRemaining: defaultCatalogData,
    merchandiseCategoryGroup: defaultCatalogData,
    customsStatesRemaining: defaultCatalogData,
    notificationTypesRemaining: defaultCatalogData,
    merchandiseTypesWithGroup: defaultCatalogData,
    appointmentTypes: customCatalogData,
    transportUnitRemaining: defaultCatalogData,
    appointmentTimeZone: customCatalogData,
};

export const catalogSlice = createSlice({
    name: "catalogState",
    initialState,
    reducers: {
        resetCatalog: () => initialState,
        setOrganizations: (state, action: PayloadAction<IOrganization[]>) => {
            state.organizations.data = action.payload;
        },
        loadingOrganizations: (state, action: PayloadAction<boolean>) => {
            state.organizations.loading = action.payload;
        },
        setFinalDestinations: (state, action: PayloadAction<IFinalDestinationType[]>) => {
            state.finalDestinations.data = action.payload;
        },
        loadingFinalDestinations: (state, action: PayloadAction<boolean>) => {
            state.finalDestinations.loading = action.payload;
        },
        setCustomsStates: (state, action: PayloadAction<ICustomsState[]>) => {
            state.customsStates.data = action.payload;
        },
        loadingCustomStates: (state, action: PayloadAction<boolean>) => {
            state.customsStates.loading = action.payload;
        },
        setMerchandiseOrigins: (state, action: PayloadAction<IMerchandiseOrigin[]>) => {
            state.merchandiseOrigins.data = action.payload;
        },
        loadingMerchandiseOrigins: (state, action: PayloadAction<boolean>) => {
            state.merchandiseOrigins.loading = action.payload;
        },
        loadingMerchandiseTypes: (state, action: PayloadAction<boolean>) => {
            state.merchandiseTypes.loading = action.payload;
        },
        setMerchandiseTypes: (state, action: PayloadAction<IMerchandiseType[]>) => {
            state.merchandiseTypes.data = action.payload;
        },
        loadingMerchandiseTypesWithGroup: (state, action: PayloadAction<boolean>) => {
            state.merchandiseTypesWithGroup.loading = action.payload;
        },
        setMerchandiseTypesWithGroup: (state, action: PayloadAction<IMerchandiseType[]>) => {
            state.merchandiseTypesWithGroup.data = action.payload;
        },

        setTransportUnits: (state, action: PayloadAction<ITransportUnit[]>) => {
            state.transportUnits.data = action.payload;
        },
        loadingTransportUnits: (state, action: PayloadAction<boolean>) => {
            state.transportUnits.loading = action.payload;
        },
        setRepresentativePresenceTypes: (state, action: PayloadAction<IRepresentativePresenceType[]>) => {
            state.representativePresenceTypes.data = action.payload;
        },
        loadingRepresentativePresenceTypes: (state, action: PayloadAction<boolean>) => {
            state.representativePresenceTypes.loading = action.payload;
        },
        setTerminals: (state, action: PayloadAction<ITerminal[]>) => {
            state.terminals.data = action.payload;
        },
        loadingTerminals: (state, action: PayloadAction<boolean>) => {
            state.terminals.loading = action.payload;
        },
        setSealTypes: (state, action: PayloadAction<ISealType[]>) => {
            state.sealTypes.data = action.payload.filter((x) => x.code !== "1");
        },
        loadingSealTypes: (state, action: PayloadAction<boolean>) => {
            state.sealTypes.loading = action.payload;
        },
        setTurns: (state, action: PayloadAction<ITurn[]>) => {
            state.turns.data = action.payload;
        },
        loadingTurns: (state, action: PayloadAction<boolean>) => {
            state.turns.loading = action.payload;
        },
        setOrganisms: (state, action: PayloadAction<IOrganism[]>) => {
            state.organisms.data = action.payload;
        },
        loadingOrganisms: (state, action: PayloadAction<boolean>) => {
            state.organisms.loading = action.payload;
        },
        setCertificates: (state, action: PayloadAction<ICertificateType[]>) => {
            state.certificates.data = action.payload;
        },
        setCertificatesByOrganism: (state, action: PayloadAction<ICertificateType[]>) => {
            state.certificatesByOrganism.data = action.payload;
        },
        setLoadingCertificatesByOrganism: (state, action: PayloadAction<boolean>) => {
            state.certificatesByOrganism.loading = action.payload;
        },
        loadingCertificates: (state, action: PayloadAction<boolean>) => {
            state.certificates.loading = action.payload;
        },
        setTransportUnitSizes: (state, action: PayloadAction<ITransportUnitSize[]>) => {
            state.transportUnitSizes.data = action.payload;
        },
        loadingTransportUnitSizes: (state, action: PayloadAction<boolean>) => {
            state.transportUnitSizes.loading = action.payload;
        },
        setActuationTypes: (state, action: PayloadAction<IActuationType[]>) => {
            state.actuationTypes.data = action.payload;
        },
        loadingActuationTypes: (state, action: PayloadAction<boolean>) => {
            state.actuationTypes.loading = action.payload;
        },
        setActuationTypesForFilter: (state, action: PayloadAction<IActuationType[]>) => {
            state.actuationTypesForFilter.data = action.payload;
        },
        loadingActuationTypesForFilter: (state, action: PayloadAction<boolean>) => {
            state.actuationTypesForFilter.loading = action.payload;
        },
        setDocks: (state, action: PayloadAction<IDock[]>) => {
            state.docks.data = action.payload;
        },
        loadingDocks: (state, action: PayloadAction<boolean>) => {
            state.docks.loading = action.payload;
        },
        setDocksQueued: (state, action: PayloadAction<IDock[]>) => {
            state.docksQueued = action.payload;
        },
        setValuedTypes: (state, action: PayloadAction<IValuedType[]>) => {
            state.valuedTypes.data = action.payload;
        },
        loadingValuedTypes: (state, action: PayloadAction<boolean>) => {
            state.valuedTypes.loading = action.payload;
        },
        setRequestStates: (state, action: PayloadAction<IRequestState[]>) => {
            state.requestStates.data = action.payload;
        },
        loadingRequestStates: (state, action: PayloadAction<boolean>) => {
            state.requestStates.loading = action.payload;
        },
        setDocumentTypes: (state, action: PayloadAction<IDocumentType[]>) => {
            state.documentTypes.data = action.payload;
        },
        setIncidenceType: (state, action: PayloadAction<IIncidenceType[] | undefined>) => {
            state.incidenceTypes.data = action.payload;
        },
        loadingDocumentTypes: (state, action: PayloadAction<boolean>) => {
            state.documentTypes.loading = action.payload;
        },
        setActuationsGroup: (state, action: PayloadAction<IActuationTypesGroup[]>) => {
            state.actuationsGroup.data = action.payload;
        },
        loadingActuationsGroup: (state, action: PayloadAction<boolean>) => {
            state.actuationsGroup.loading = action.payload;
        },
        setRoles: (state, action: PayloadAction<IRole[]>) => {
            state.roles.data = action.payload;
        },
        loadingRoles: (state, action: PayloadAction<boolean>) => {
            state.roles.loading = action.payload;
        },
        setInvoiceConcepts: (state, action: PayloadAction<IInvoiceConcept[]>) => {
            state.invoiceConcepts.data = action.payload;
        },
        loadingInvoiceConcepts: (state, action: PayloadAction<boolean>) => {
            state.invoiceConcepts.loading = action.payload;
        },
        setUserPositionTypes: (state, action: PayloadAction<IUserPositionType[]>) => {
            state.userPositionTypes.data = action.payload;
        },
        loadingUserPositionTypes: (state, action: PayloadAction<boolean>) => {
            state.userPositionTypes.loading = action.payload;
        },
        setMerchandiseTypesOptions: (state, action: PayloadAction<IMerchandiseCategoryOption[]>) => {
            state.merchandiseCategories.data = action.payload;
        },
        setMerchandiseTypesOptionsLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseCategories.loading = action.payload;
        },
        setMerchandiseCategoryOptions: (state, action: PayloadAction<IMerchandiseCategoryOption[]>) => {
            state.merchandiseCategories.data = action.payload;
        },
        setMerchandiseCategoryOptionsLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseCategories.loading = action.payload;
        },
        setMerchandiseCategoryRelationShipOptions: (state, action: PayloadAction<IMerchandiseCategoryRealtionShipOption[]>) => {
            state.merchandiseCategoriesRelationShip.data = action.payload;
        },
        setMerchandiseCategoryRelationShipLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseCategories.loading = action.payload;
        },
        setNotificationTypes: (state, action: PayloadAction<INotificationType[]>) => {
            state.notificationTypes.data = action.payload;
        },
        loadingNotificationTypes: (state, action: PayloadAction<boolean>) => {
            state.notificationTypes.loading = action.payload;
        },
        setNotificationModes: (state, action: PayloadAction<INotificationMode[]>) => {
            state.notificationModes.data = action.payload;
        },
        loadingNotificationModes: (state, action: PayloadAction<boolean>) => {
            state.notificationModes.loading = action.payload;
        },
        setRequestDayPermissionModes: (state, action: PayloadAction<IRequestDayPermission[]>) => {
            state.requestDayPermission.data = action.payload;
        },
        loadingRequestDayPermissionModes: (state, action: PayloadAction<boolean>) => {
            state.requestDayPermission.loading = action.payload;
        },
        setAppointmentDayPermissionTypes: (state, action: PayloadAction<IRequestDayPermission[]>) => {
            state.appointmentDayPermissionTypes.data = action.payload;
        },
        loadingAppointmentDayPermissionTypes: (state, action: PayloadAction<boolean>) => {
            state.appointmentDayPermissionTypes.loading = action.payload;
        },
        setOrganismRemainingData: (state, action: PayloadAction<IOrganism[]>) => {
            state.organismsRemaining.data = action.payload;
        },
        setOrganismRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.organismsRemaining.loading = action.payload;
        },
        setCertificateTypesRemainingData: (state, action: PayloadAction<IMasterCertificateTypesRemaining[]>) => {
            state.certificateTypesRemaining.data = action.payload;
        },
        setCertificateTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.certificateTypesRemaining.loading = action.payload;
        },
        setMerchandiseOriginsRemainingData: (state, action: PayloadAction<IMerchandiseOrigin[]>) => {
            state.merchandiseOriginsRemaining.data = action.payload;
        },
        setMerchandiseOriginsRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseOriginsRemaining.loading = action.payload;
        },

        setMerchandiseCategoryRemainingData: (state, action: PayloadAction<IMasterMerchandiseTypeRemaining[]>) => {
            state.merchandiseCategoryRemaining.data = action.payload;
        },
        setMerchandiseCategoryRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseCategoryRemaining.loading = action.payload;
        },

        setDocumentsTypesRemainingData: (state, action: PayloadAction<IMasterMerchandiseCategoryRemaining[]>) => {
            state.documentTypeRemaining.data = action.payload;
        },
        setDocumentsTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.documentTypeRemaining.loading = action.payload;
        },
        setTypesSealsRemainingData: (state, action: PayloadAction<IMasterTypeSealRemaining[]>) => {
            state.sealTypeRemaining.data = action.payload;
        },
        setTypesSealsRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.sealTypeRemaining.loading = action.payload;
        },
        setFinalDestinationTypesRemainingData: (state, action: PayloadAction<IMasterFinalDestinationTypeRemaining[]>) => {
            state.finalDestinationTypeRemaining.data = action.payload;
        },
        setFinalDestinationTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.finalDestinationTypeRemaining.loading = action.payload;
        },
        setRepresentativePresenceTypesRemainingData: (state, action: PayloadAction<IRepresentativePresenceType[]>) => {
            state.representativePresenceTypesRemaining.data = action.payload;
        },
        setRepresentativePresenceTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.representativePresenceTypesRemaining.loading = action.payload;
        },
        setMasterTurnsRemainingData: (state, action: PayloadAction<IMasterTurnRemaining[]>) => {
            state.turnRemaining.data = action.payload;
        },
        setMasterTurnsRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.turnRemaining.loading = action.payload;
        },
        setMasterRequestStatesRemainingData: (state, action: PayloadAction<IMasterRequestStateRemaining[]>) => {
            state.requestStatesRemaining.data = action.payload;
        },
        setMasterRequestStatesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.requestStatesRemaining.loading = action.payload;
        },
        setMerchandiseCategorySortedData: (state, action: PayloadAction<IMerchandiseCategoryOption[]>) => {
            state.merchandiseCategoriesSorted.data = action.payload;
        },
        setMerchandiseCategorySortedLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseCategoriesSorted.loading = action.payload;
        },
        setDockBlockData: (state, action: PayloadAction<IDockBlock[]>) => {
            state.dockBlock.data = action.payload;
        },
        setDockBlockLoading: (state, action: PayloadAction<boolean>) => {
            state.dockBlock.loading = action.payload;
        },
        setActuationGroupRemainingData: (state, action: PayloadAction<IMasterActuationGroupRemaining[]>) => {
            state.actuationGroupReamining.data = action.payload;
        },
        setActuationGroupRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.actuationGroupReamining.loading = action.payload;
        },
        setActuationTypesRemainingData: (state, action: PayloadAction<IMasterActuationTypeRemaining[]>) => {
            state.actuationTypesRemaining.data = action.payload;
        },
        setActuationTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.actuationTypesRemaining.loading = action.payload;
        },
        setAppointmentTypesRemainingData: (state, action: PayloadAction<IMasterAppointmentType[]>) => {
            state.appointmentTypesRemaining.data = action.payload;
        },
        setAppointmentTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.appointmentTypesRemaining.loading = action.payload;
        },
        setGroupManagementCategoryRemainingData: (state, action: PayloadAction<IMerchandiseCategoryGroup[]>) => {
            state.groupMerchandiseCategoryRemaining.data = action.payload;
        },
        setGroupManagementCategoryRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.groupMerchandiseCategoryRemaining.loading = action.payload;
        },
        setCustomsStatesRemainingData: (state, action: PayloadAction<IMasterCustomsStateRemaining[]>) => {
            state.customsStatesRemaining.data = action.payload;
        },
        setCustomsStatesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.customsStatesRemaining.loading = action.payload;
        },
        setMerchandiseCategoryGroupData: (state, action: PayloadAction<IMerchandiseCategoryGroup[]>) => {
            state.merchandiseCategoryGroup.data = action.payload;
        },
        setMerchandiseCategoryGroupLoading: (state, action: PayloadAction<boolean>) => {
            state.merchandiseCategoryGroup.loading = action.payload;
        },
        setNotificationTypesRemainingData: (state, action: PayloadAction<INotificationType[]>) => {
            state.notificationTypesRemaining.data = action.payload;
        },
        setNotificationTypesRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.notificationTypesRemaining.loading = action.payload;
        },
        setAppointmentTypesData: (state, action: PayloadAction<IMasterAppointmentType[]>) => {
            state.appointmentTypes.data = action.payload;
        },
        setAppointmentTypesLoading: (state, action: PayloadAction<boolean>) => {
            state.appointmentTypes.loading = action.payload;
        },
        setTransportUnitRemainingData: (state, action: PayloadAction<IMasterTransportUnitRemaining[]>) => {
            state.transportUnitRemaining.data = action.payload;
        },
        setTransportUnitRemainingLoading: (state, action: PayloadAction<boolean>) => {
            state.transportUnitRemaining.loading = action.payload;
        },
        setAppointmentTimeZoneData: (state, action: PayloadAction<IAppointmentTimeZone[] | undefined>) => {
            state.appointmentTimeZone.data = action.payload;
        },
        setAppointmentTimeZoneLoading: (state, action: PayloadAction<boolean>) => {
            state.appointmentTimeZone.loading = action.payload;
        },
    },
});
export const {
    resetCatalog,
    setOrganizations,
    loadingOrganizations,
    setFinalDestinations,
    loadingFinalDestinations,
    setCustomsStates,
    loadingCustomStates,
    setMerchandiseOrigins,
    loadingMerchandiseOrigins,
    setMerchandiseTypes,
    loadingMerchandiseTypes,
    setTransportUnits,
    loadingTransportUnits,
    setRepresentativePresenceTypes,
    loadingRepresentativePresenceTypes,
    setTerminals,
    loadingTerminals,
    setSealTypes,
    loadingSealTypes,
    setTurns,
    loadingTurns,
    loadingOrganisms,
    setCertificates,
    loadingCertificates,
    setCertificatesByOrganism,
    setLoadingCertificatesByOrganism,
    setTransportUnitSizes,
    loadingTransportUnitSizes,
    setActuationTypes,
    loadingActuationTypes,
    setActuationTypesForFilter,
    loadingActuationTypesForFilter,
    setDocks,
    loadingDocks,
    setDocksQueued,
    setValuedTypes,
    loadingValuedTypes,
    setRequestStates,
    loadingRequestStates,
    setIncidenceType,
    setDocumentTypes,
    loadingDocumentTypes,
    setOrganisms,
    setActuationsGroup,
    loadingActuationsGroup,
    setRoles,
    loadingRoles,
    setInvoiceConcepts,
    loadingInvoiceConcepts,
    setUserPositionTypes,
    loadingUserPositionTypes,
    setMerchandiseTypesOptions,
    setMerchandiseTypesOptionsLoading,
    setMerchandiseCategoryOptions,
    setMerchandiseCategoryOptionsLoading,
    setMerchandiseCategoryRelationShipLoading,
    setMerchandiseCategoryRelationShipOptions,
    setNotificationTypes,
    loadingNotificationTypes,
    setNotificationModes,
    loadingNotificationModes,
    loadingRequestDayPermissionModes,
    setRequestDayPermissionModes,
    setOrganismRemainingData,
    setOrganismRemainingLoading,
    setCertificateTypesRemainingData,
    setCertificateTypesRemainingLoading,
    setMerchandiseOriginsRemainingData,
    setMerchandiseOriginsRemainingLoading,
    setMerchandiseCategoryRemainingData,
    setMerchandiseCategoryRemainingLoading,
    setDocumentsTypesRemainingData,
    setDocumentsTypesRemainingLoading,
    setTypesSealsRemainingData,
    setTypesSealsRemainingLoading,
    setFinalDestinationTypesRemainingData,
    setFinalDestinationTypesRemainingLoading,
    setRepresentativePresenceTypesRemainingData,
    setRepresentativePresenceTypesRemainingLoading,
    setMasterTurnsRemainingData,
    setMasterTurnsRemainingLoading,
    setMasterRequestStatesRemainingData,
    setMasterRequestStatesRemainingLoading,
    setMerchandiseCategorySortedData,
    setMerchandiseCategorySortedLoading,
    setDockBlockData,
    setDockBlockLoading,
    setActuationGroupRemainingData,
    setActuationGroupRemainingLoading,
    setActuationTypesRemainingData,
    setActuationTypesRemainingLoading,
    setAppointmentTypesRemainingData,
    setAppointmentTypesRemainingLoading,
    setGroupManagementCategoryRemainingData,
    setGroupManagementCategoryRemainingLoading,
    setCustomsStatesRemainingData,
    setCustomsStatesRemainingLoading,
    setMerchandiseCategoryGroupData,
    setMerchandiseCategoryGroupLoading,
    setNotificationTypesRemainingData,
    setNotificationTypesRemainingLoading,
    setAppointmentTypesData,
    setAppointmentTypesLoading,
    loadingMerchandiseTypesWithGroup,
    setMerchandiseTypesWithGroup,
    setTransportUnitRemainingData,
    setTransportUnitRemainingLoading,
    setAppointmentTimeZoneData,
    setAppointmentTimeZoneLoading,
    setAppointmentDayPermissionTypes,
    loadingAppointmentDayPermissionTypes,
} = catalogSlice.actions;

export default catalogSlice.reducer;
