import { IContextualMenuItem } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ColorType } from "../../../common/enum/ColorType";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppointmentImportPopUp } from "../../../redux/reducers/appointment/appoitmentImport/form";
import { fonts } from "../../../styles/romeu/fonts";
import { Alert } from "../../common/alert/alert";
import { ButtonContextualMenu } from "../../common/contextualMenu/contextualMenu";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { QueuAssignmentImportContainerStyle } from "./containerStyle.jss";
import { QueuAssignmentImportFormController } from "./controller/controller";
import { QueuAssignmentImportList } from "./list/list";
import { setAssignmentQueueImportListData } from "../../../redux/reducers/request/assignmentQueue/list";
import { setAssignmentQueueImportPopUp } from "../../../redux/reducers/request/assignmentQueue/form";

export enum ImportRequest {
    DOCUMENT = "Inspector",
    CODE = "importText",
}

export const QueuAssignmentImportContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT.IMPORT" });
    const theme = useTheme();
    const style = QueuAssignmentImportContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const [codeComponent, setCodeComponent] = useState<string>("");
    const { data } = useAppSelector((state) => state.assignmentQueueImportList);

    const menuItems: IContextualMenuItem[] = [
        {
            key: ImportRequest.DOCUMENT,
            onClick: () => {
                dispatch(setAssignmentQueueImportPopUp(PopUpCodes.CREATE));
                setCodeComponent(ImportRequest.DOCUMENT);
            },
            text: t("IMPORT_FILE"),
        },
        {
            key: ImportRequest.CODE,
            onClick: () => {
                dispatch(setAssignmentQueueImportPopUp(PopUpCodes.CREATE));
                setCodeComponent(ImportRequest.CODE);
            },
            text: t("IMPORT_TXT"),
        },
    ];
    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={t("TITLE_QUEUE")}
                action={
                    <ButtonContextualMenu
                        items={menuItems}
                        buttonTitle={t("IMPORT_QUEUE")}
                    />
                }
            />
            <QueuAssignmentImportFormController
                codeComponent={codeComponent}
                setCodeComponent={setCodeComponent}
            />

            {data && data?.resultsError > 0 && <QueuAssignmentImportList />}

            {data && data?.resultsError === 0 && (
                <Alert
                    colorType={ColorType.success}
                    fontSize={fonts.sizes.xl}
                    data={t("MESSAGE")}
                />
            )}
        </div>
    );
};
