import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterPresenceRepresentativeFormControler } from "../controller/controller";
import { MasterPresenceRepresentativeColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/enum/sizes";
import { fetchMasterPresenceRepresentativeList } from "../../../../../../redux/actions/resources/master/presenceRepresentative/presenceRepresentative";
interface IMasterRepresentativePresenceList{
    widthPopUp:any;
    heigthPopUp:any;
}
export const MasterRepresentativePresenceList:React.FC<IMasterRepresentativePresenceList> = ({heigthPopUp, widthPopUp}) => {
    const dispatch = useAppDispatch();
    const { list, loading } = useAppSelector((state) => state.masterPresenceRepresentativeList);
    const onRenderColumn = () => {
        return MasterPresenceRepresentativeColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterPresenceRepresentativeList());
    }, [list]);

    return loading ? (
                        <Skeleton rows={SkeletonSizes.DEFAULT} />

    ) : (
        <>
            <CustomDetailsList
                data={list ?? []}
                renderColumns={onRenderColumn}
            ></CustomDetailsList>
            <MasterPresenceRepresentativeFormControler widthPopUp={widthPopUp} heigthPopUp={heigthPopUp} />
        </>
    );
};
