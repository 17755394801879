import { IDropdownOption } from "@fluentui/react";
import { useEffect, useState } from "react";
import { UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { fetchInvoiceCustomerOptions } from "../../../../../redux/actions/user/users/users";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";
import { mapInvoiceEntitiesToDropDownOptions } from "../../../../../utils";
import { getCustomerReference } from "../../../../../services/user/customerService";

interface IInvoiceCustomerProps extends IBaseSelectorProps {
    customerId: string;
    resetSelectorAtRedux?: (propertyName: string) => void;
    setValue: UseFormSetValue<any>;
    trigger?: UseFormTrigger<any>;
    onLoadPromise?: boolean;
}

export const InvoiceCustomerSelector: React.FC<IInvoiceCustomerProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    customerId,
    disabled,
    isRequired,
    control,
    rules,
    setValue,
    trigger,
    onLoadPromise,
}) => {
    const { options, loading } = useAppSelector((store) => store.usersByRole.invoiceCustomers);
    const [invoiceCustomerOptions, setInvoiceCustomerOptions] = useState<IDropdownOption[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (options.length === 1 && customerId && invoiceCustomerOptions.length > 0) {
            onChange(invoiceCustomerOptions[0], propertyName);
            setValue(propertyName, Number(invoiceCustomerOptions[0].key));

            trigger && trigger(["invoiceCustomerId"]);
        }
    }, [dispatch, options, invoiceCustomerOptions, customerId]);

    useEffect(() => {
        if (options.length > 0) {
            setInvoiceCustomerOptions(mapInvoiceEntitiesToDropDownOptions(options));
        }
    }, [options]);

    useEffect(() => {
        if (!onLoadPromise) {
            customerId && dispatch(fetchInvoiceCustomerOptions(customerId));
        }
    }, [customerId]);

    const onSelectorChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        const customerReference = option ? getCustomerReference(+option.key, options) : null;
        onChange(option, propertyName, customerReference);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={!loading ? selectedKey : null}
            options={invoiceCustomerOptions}
            disabled={loading || disabled || !customerId}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
