import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { useContainer } from "../../../../../../hooks/request/useContainer";
import { useSize } from "../../../../../../hooks/resizeHook";
import { IGenericPayload } from "../../../../../../models";
import { ICreateRequest, ITransportUnitData, ITransportUnitDataWithIndex } from "../../../../../../models/requests/ICreateRequest";
import { IRequestStepProps } from "../../../../../../models/requests/draft/IRequestStepProps";
import { getAvailableDatesRequestAction } from "../../../../../../redux/actions/request/create/createRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
    addBatchDraftItem,
    addSealDraftItem,
    deleteUtiDraftFromList,
    editUtiDraftFromList,
    pushUtiDraftToList,
    removeBatchDraftItem,
    removeSealDraftItem,
    resetTerminal,
    resetUtiDraft,
    setUtiDraft,
    setUtiDraftShowPopUp,
    setUtiDraftStepIsValid,
    updateUtiDraftProperty,
} from "../../../../../../redux/reducers/request/draft/draft";
import { addDayToDate, compileNameOfProperty } from "../../../../../../utils";
import { Alert } from "../../../../../common/alert/alert";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { ControlledDatePicker } from "../../../../../common/datePicker/controlledDatePicker";
import { FormField } from "../../../../../common/fields/fields";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { RequiredSpan } from "../../../../../common/required/requiredSpan";
import { TitleH5 } from "../../../../../common/titles/titles";
import { RegCheckTemp } from "../../../../common/checkBox/regTempCheck";
import { RequestUtiForm } from "../../../../common/form/uti/form";
import { UtiCardSection } from "../../../../common/list/uti/utiCardSection";
import { CustomStatesSelector, InvoiceCustomerSelector, TurnSelector } from "../../../../common/selectors";
import { TransportUnitSelector } from "../../../../common/selectors/transportUnit/transportUnit";
import { renderRequiredData } from "../../../../form/create/validation/requestValidationUtils";
import { FormDraftContainerStyle } from "../../containerStyle.jss";
import { requestMaxLength } from "../../validation/requestConfig";
import { CustomerSelector } from "../../../../../common/selectors/controllerSelectors/customerSelector/customer";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";

export const RequestUtiDataForm: React.FC<IRequestStepProps> = ({
    schema,
    onInputChange,
    onSelectorChange,
    onDateChange,
    resetPropertyWhenParentChange,
    onCheckBoxChange,
    editMode,
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = FormDraftContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });
    const [indexOrder, setIndexOrder] = useState<number>(-1);
    const [showTemporyReg, setShowTemporyReg] = useState<boolean>(false);
    const [requireUti, setRequireUti] = useState<boolean>(false);
    const [validForm, setValidForm] = useState<boolean>(false);
    const {
        draftRequest: { request, toPresentDraft, uti },
        catalog: { transportUnits: transportUnitsData },
        createRequest: { availableDates },
    } = useAppSelector((state) => ({
        draftRequest: state.draftRequest,
        catalog: state.catalog,
        createRequest: state.createRequest,
    }));
    const transportUnits = transportUnitsData?.data;
    const { height } = useSize();
    const minDate = availableDates && availableDates.minDate ? new Date(availableDates.minDate) : undefined;
    const isContainer = useContainer(request.transportUnitId);
    const [isLoadedAvailableDates, setIsLoadedAvailableDates] = useState<boolean>(false);

    const {
        register,
        control,
        formState: { errors, isValid },
        trigger,
        setValue,
    } = useForm<ICreateRequest>({ mode: "onChange" });

    const titlePopUp = useMemo(() => {
        switch (uti.showPopUp) {
            case PopUpCodes.CREATE:
                return t("BUTTONS.ADD_UTI");
            case PopUpCodes.UPDATE:
                return t("BUTTONS.EDIT_UTI");
            default:
                return "";
        }
    }, [uti.showPopUp]);

    const restrictedDates = useMemo(() => availableDates?.restrictedDates?.map((item: any) => new Date(item)) ?? [], [availableDates]);

    const onCancel = () => {
        dispatch(resetUtiDraft());
        setIndexOrder(-1);
    };

    const onSave = () => {
        if (uti.showPopUp === PopUpCodes.CREATE) {
            dispatch(pushUtiDraftToList(uti.form));
        } else if (uti.showPopUp === PopUpCodes.UPDATE) {
            dispatch(editUtiDraftFromList({ index: indexOrder, transportUnit: uti.form } as ITransportUnitDataWithIndex));
        }
        onCancel();
    };

    const requireUtiList = useMemo(() => {
        if (!toPresentDraft) {
            setRequireUti(false);
            dispatch(setUtiDraftStepIsValid(true));
            return false;
        }

        if (!request.transportUnits?.length) {
            setRequireUti(true);
            dispatch(setUtiDraftStepIsValid(false));
            return true;
        }

        const fieldsToCheck = ["transportUnitSizeId", "grossWeight", "packages", "palletized", "transportUnitNumber"];

        const requireUti = request.transportUnits.some((transportUnit) =>
            fieldsToCheck.some(
                (field) =>
                    field in transportUnit &&
                    schema[field as keyof typeof schema]?.required &&
                    (transportUnit[field as keyof typeof transportUnit] === null ||
                        transportUnit[field as keyof typeof transportUnit] === "" ||
                        transportUnit[field as keyof typeof transportUnit] === 0),
            ),
        );

        setRequireUti(requireUti);
        dispatch(setUtiDraftStepIsValid(!requireUti));

        return requireUti;
    }, [toPresentDraft, request.transportUnits, schema]);

    useEffect(() => {
        trigger(["turnId", "customsStateId"]);
    }, [toPresentDraft, trigger]);

    useEffect(() => {
        const isValidStep = !(requireUtiList || Object.keys(errors).length || ((toPresentDraft || isContainer) && !request.transportUnits.length));
        dispatch(setUtiDraftStepIsValid(isValidStep));
    }, [toPresentDraft, errors, requireUtiList, request, isValid, isContainer]);

    useEffect(() => {
        if (!isContainer) {
            dispatch(resetTerminal());
        }
    }, [transportUnits, request.transportUnitId]);

    useEffect(() => {
        const getAvailableDates = async () => {
            try {
                if (!availableDates) {
                    await dispatch(getAvailableDatesRequestAction());
                }
            } finally {
                setIsLoadedAvailableDates(true);
            }
        };
        getAvailableDates();
    }, []);

    useEffect(() => {
        trigger();
    }, [onInputChange, onSelectorChange, onDateChange, resetPropertyWhenParentChange, onCheckBoxChange]);

    return (
        <>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={GridClass.ROW_GRID}>
                    {schema.transportUnitId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <TransportUnitSelector
                                onChange={onSelectorChange}
                                selectedKey={request.transportUnitId!}
                                propertyName={compileNameOfProperty<ICreateRequest>("transportUnitId")}
                                title={t("FIELDS.TRANSPORT_UNIT_TYPE")}
                                disabled={request.transportUnits.length > 0}
                                isRequired={schema.transportUnitId.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.transportUnitId.required) }}
                            />
                        </div>
                    )}

                    {schema.date.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <ControlledDatePicker
                                name={compileNameOfProperty<ICreateRequest>("date")}
                                label={t("FIELDS.DATE")}
                                onDateChange={onDateChange}
                                initValue={request.date}
                                required={schema.date.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.date.required) }}
                                minDate={minDate}
                                hasError={true}
                                disabled={!isLoadedAvailableDates}
                                calendarProps={{
                                    restrictedDates: restrictedDates,
                                }}
                            />
                        </div>
                    )}

                    {schema.turnId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <TurnSelector
                                onChange={onSelectorChange}
                                selectedKey={request.turnId!}
                                propertyName={compileNameOfProperty<ICreateRequest>("turnId")}
                                title={t("FIELDS.TURN")}
                                isRequired={schema.turnId.required}
                                control={control}
                                setValue={setValue}
                                rules={{
                                    required: renderRequiredData(schema.turnId.required),
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={`${GridClass.ROW_GRID}`}>
                    <div className={GridClass.SIMPLE_CELL}>
                        <TitleH5
                            title={t("TITLES.CUSTOMER_INFO")}
                            color={ColorType.primary}
                            bold={true}
                        />
                    </div>
                </div>
                <div className={`${GridClass.ROW_GRID}`}>
                    {schema.customerId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <CustomerSelector
                                onChange={onSelectorChange}
                                selectedKey={request.customerId}
                                title={t("FIELDS.CUSTOMER")}
                                propertyName={compileNameOfProperty<ICreateRequest>("customerId")}
                                isRequired={schema.customerId.required}
                                control={control}
                                rules={{
                                    required: renderRequiredData(schema.customerId.required),
                                    validate: () => (schema.invoiceCustomerId.required ? !!request.customerId : true),
                                }}
                                setValue={setValue}
                                filteredRegisteredCustomer={true}
                            />
                        </div>
                    )}

                    {schema.invoiceCustomerId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <InvoiceCustomerSelector
                                onChange={onSelectorChange}
                                selectedKey={request.invoiceCustomerId}
                                title={t("FIELDS.INVOICE_CUSTOMER")}
                                propertyName={compileNameOfProperty<ICreateRequest>("invoiceCustomerId")}
                                customerId={request.customerId}
                                resetSelectorAtRedux={resetPropertyWhenParentChange}
                                isRequired={schema.invoiceCustomerId.required}
                                setValue={setValue}
                                control={control}
                                onLoadPromise={editMode}
                                rules={{
                                    required: renderRequiredData(schema.invoiceCustomerId.required),
                                    validate: () => (schema.invoiceCustomerId.required ? !!request.invoiceCustomerId : true),
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.SIMPLE_CELL}>
                        <TitleH5
                            title={t("TITLES.EXPEDITION_INFO")}
                            color={ColorType.primary}
                            bold={true}
                        />
                    </div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    {schema.customerReference.visible && (
                        <div className={showTemporyReg ? GridClass.DOBLE_CELL : GridClass.THREE_CELL}>
                            <FormField
                                type="text"
                                value={request.customerReference!}
                                maxLength={requestMaxLength.CUSTOMER_REFERENCE}
                                label={t("FIELDS.REFERENCE")}
                                isRequired={schema.customerReference.required}
                                error={errors.customerReference}
                                {...register(compileNameOfProperty<ICreateRequest>("customerReference"), {
                                    onChange: (event: any, newValue?: any) => {
                                        setValue("customerReference", event.target.value);
                                        onInputChange(event);
                                        trigger("customerReference");
                                    },
                                    required: renderRequiredData(schema.customerReference.required && !request.customerReference),
                                })}
                            />
                        </div>
                    )}

                    {schema.booking.visible && (
                        <div className={showTemporyReg ? GridClass.DOBLE_CELL : GridClass.THREE_CELL}>
                            <FormField
                                type="text"
                                value={request.booking}
                                maxLength={requestMaxLength.BOOKING}
                                label={t("FIELDS.BOOKING")}
                                isRequired={schema.booking.required}
                                error={errors.booking}
                                {...register(compileNameOfProperty<ICreateRequest>("booking"), {
                                    onChange: onInputChange,
                                    validate: () => (schema.booking.required ? !!request.booking : true),
                                    required: renderRequiredData(schema.booking.required),
                                })}
                            />
                        </div>
                    )}

                    {schema.customsStateId.visible && (
                        <div className={showTemporyReg ? GridClass.DOBLE_CELL : GridClass.THREE_CELL}>
                            <CustomStatesSelector
                                onChange={onSelectorChange}
                                selectedKey={request.customsStateId}
                                propertyName={compileNameOfProperty<ICreateRequest>("customsStateId")}
                                title={t("FIELDS.CUSTOMS_STATE")}
                                isRequired={schema.customsStateId.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.customsStateId.required) }}
                            />
                        </div>
                    )}

                    {schema.temporaryRegime.visible && (
                        <div className={GridClass.DOBLE_CELL}>
                            <RegCheckTemp
                                name={compileNameOfProperty<ICreateRequest>("temporaryRegime")}
                                label={t("FIELDS.TEMPORARY_REGIME")}
                                isChecked={request.temporaryRegime}
                                handleChange={onCheckBoxChange}
                                selectedCustomState={request.customsStateId}
                                isCreateRequest={true}
                                setShowTemporyReg={setShowTemporyReg}
                            />
                        </div>
                    )}
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={`${GridClass.SIMPLE_CELL} ${style.titleWhithAction}`}>
                        <TitleH5
                            title={t("TITLES.UTI_SECTION")}
                            color={ColorType.primary}
                            bold={true}
                        />
                        {schema.uti_create.visible && (
                            <>
                                <ButtonPrimaryInverseWithBorder
                                    handleClick={() => {
                                        dispatch(setUtiDraftShowPopUp(PopUpCodes.CREATE));
                                    }}
                                    title={`+ ${t("BUTTONS.ADD_UTI")}`}
                                    disabled={!request.transportUnitId}
                                />
                                {(schema.uti_create.required || isContainer) && <RequiredSpan />}
                            </>
                        )}
                    </div>
                    <div className={`${GridClass.SIMPLE_CELL} ${style.messageSpace}`}>
                        {requireUti && (
                            <Alert
                                title={t("INFO.DRAFT_MESSAGE_UTI")}
                                icon={"Error"}
                                height={"20px"}
                                colorType={ColorType.danger}
                                data={<></>}
                            />
                        )}
                    </div>

                    <div className={GridClass.SIMPLE_CELL}>
                        <UtiCardSection
                            isContainer={isContainer}
                            schema={schema}
                            setIndexOrder={setIndexOrder}
                            onDeleteUti={(index: number) => {
                                dispatch(deleteUtiDraftFromList(index));
                            }}
                            setUtiForm={(uti: ITransportUnitData) => {
                                dispatch(
                                    setUtiDraft({
                                        ...uti,
                                        palletized: uti.palletized === undefined || uti.palletized === null ? false : uti.palletized,
                                    }),
                                );
                            }}
                            setShowPopUp={(code: PopUpCodes) => {
                                dispatch(setUtiDraftShowPopUp(code));
                            }}
                            transportUnits={request.transportUnits}
                        />
                    </div>
                </div>
            </div>

            <PopUpForm
                content={
                    <RequestUtiForm
                        isContainer={isContainer}
                        schema={schema}
                        setIsValidForm={(value: boolean) => {
                            setValidForm(value);
                        }}
                        updateProperty={(payload: IGenericPayload) => {
                            dispatch(updateUtiDraftProperty(payload));
                        }}
                        form={uti.form}
                        addSeal={(seal: string) => {
                            dispatch(addSealDraftItem(seal));
                        }}
                        removeSeal={(index: number) => {
                            dispatch(removeSealDraftItem(index));
                        }}
                        addBatch={(batch: string) => {
                            dispatch(addBatchDraftItem(batch));
                        }}
                        removeBatch={(index: number) => {
                            dispatch(removeBatchDraftItem(index));
                        }}
                    />
                }
                title={titlePopUp}
                isVisible={uti.showPopUp === PopUpCodes.CREATE || uti.showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                height={height <= 746 ? "60vh" : "45vh"}
                width={"auto"}
                isDisabledButton={validForm}
            />
        </>
    );
};
