import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { VlcportHistoricListColumnStyle } from "./columnStyle.jss";
import { IVlcportHistoricList } from "../../../../../models/vlcPort/IHistoricRequest";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { formatDate } from "../../../../../utils";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../assets/icons";
import { reprocessVlcPortHistoricPositioningRequest } from "../../../../../redux/actions/vlcPort/historic/historic";
import { useAppDispatch } from "../../../../../redux/hooks";
import { VlcportHistoricListPivotId } from "../../container";
import { setVlcportHistoricForm, setVlcportHistoricFormPopUpShow } from "../../../../../redux/reducers/vlcPort/historic/form";
import { Separator } from "../../../../common/separator/separator";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";

export const VlcportHistoricListColumn = (selectedKey: string): IColumn[] => {
    const theme = useTheme();
    const styles = VlcportHistoricListColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.HISTORIC" });
    const dispatch = useAppDispatch();
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IVlcportHistoricList>("date"),
            minWidth: 100,
            maxWidth: 100,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("date")}
                    title={t("DATE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IVlcportHistoricList) => <span>{formatDate(item.date, FormatDate.DATE_HOUR_MINUTE)}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IVlcportHistoricList>("fileName"),
            minWidth: 450,
            maxWidth: 500,
            name: t("FILE_NAME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("fileName")}
                    title={t("FILE_NAME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IVlcportHistoricList) => <span>{item.fileName}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IVlcportHistoricList>("integrationReference"),
            minWidth: 200,
            maxWidth: 250,
            name: t("REFERENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("integrationReference")}
                    title={t("REFERENCE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IVlcportHistoricList) => <span>{item.integrationReference}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IVlcportHistoricList>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 150,
            name: t("UTI_TRANSPORT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("transportUnitNumber")}
                    title={t("UTI_TRANSPORT_NUMBER")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IVlcportHistoricList) => <span>{item.transportUnitNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IVlcportHistoricList>("processed"),
            minWidth: 100,
            maxWidth: 100,
            name: t("PROCESSED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("processed")}
                    title={t("PROCESSED")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IVlcportHistoricList) => (
                <span>
                    {" "}
                    <BooleanValue value={item.processed} />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IVlcportHistoricList>("withErrors"),
            minWidth: 100,
            maxWidth: 100,
            name: t("WITH_ERROR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("withErrors")}
                    title={t("WITH_ERROR")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IVlcportHistoricList) => (
                <span>
                    {" "}
                    <BooleanValue value={item.withErrors} />{" "}
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IVlcportHistoricList>("canReprocessMessage"),
            minWidth: 100,
            maxWidth: 100,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IVlcportHistoricList>("canReprocessMessage")}
                    title={""}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),

            onRender: (item: IVlcportHistoricList) => (
                <span className={styles.action}>
                    <CustomIcons
                        iconName={NameIcons.EYEICON}
                        size={20}
                        tooltip={t("VIEW_ERRORS")}
                        onClick={() => {
                            dispatch(setVlcportHistoricFormPopUpShow(PopUpCodes.CREATE));
                            dispatch(
                                setVlcportHistoricForm({
                                    messaageData: item.messageData,
                                    response: item.response,
                                }),
                            );
                        }}
                    ></CustomIcons>

                    {item.canReprocessMessage && selectedKey === VlcportHistoricListPivotId.POSITIONINGREQUEST && (
                        <>
                            <Separator
                                size="1.5rem"
                                horizontal={false}
                            ></Separator>
                            <CustomIcons
                                size={20}
                                iconName={"SyncOccurence"}
                                className={styles.reloadIcon}
                                tooltip={t("REPROCESS")}
                                onClick={() => {
                                    dispatch(reprocessVlcPortHistoricPositioningRequest(item.fileName));
                                }}
                            ></CustomIcons>
                        </>
                    )}
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
