import {
    IActuationType,
    IActuationTypesGroup,
    ICertificateType,
    ICustomsState,
    IDock,
    IDocksQueued,
    IDocumentType,
    IFinalDestinationType,
    IIncidenceType,
    IMerchandiseOrigin,
    IMerchandiseType,
    IModule,
    IOrganism,
    IOrganization,
    IRepresentativePresenceType,
    IRequestState,
    IRole,
    ISealType,
    ITransportUnit,
    ITransportUnitSize,
    ITurn,
    IValuedType,
} from "../../models/catalog";
import { IMerchandiseCategoryOption, IMerchandiseCategoryRealtionShipOption } from "../../models/catalog/IMercandiseCategory";
import { INotificationMode } from "../../models/catalog/INotificationMode";
import { INotificationType } from "../../models/catalog/INotificationType";
import { IUserPositionType } from "../../models/catalog/IUserPositionType";
import { IRequestDayPermission } from "../../models/catalog/requestDayPermission";
import { IMasterCertificateType, IMasterCertificateTypesRemaining } from "../../models/resources/master/ICertificateType";
import { IMasterOrganismItem } from "../../models/resources/master/IOrganism";
import { IMasterMerchandiseOrigin, IMasterMerchandiseOriginRemaining } from "../../models/resources/master/IMerchandiseOrigin";
import { IUserRoleView } from "../../models/requests/IUserRoleView";
import { apiFetch, apiFetchOAuthWithClaims } from "../../services/apiClient";
import { IMasterMerchandiseType, IMasterMerchandiseTypeRemaining } from "../../models/resources/master/IMerchandiseTypes";
import { IMasterMerchandiseCategory, IMasterMerchandiseCategoryRemaining } from "../../models/resources/master/IMerchandiseCategory";
import { IMasterDisplayDoock } from "../../models/resources/master/IDisplayDock";
import { IMasterDocumentType } from "../../models/resources/master/IDocumentType";
import { IMasterSealType, IMasterTypeSealRemaining } from "../../models/resources/master/ITypeSeal";
import { IMasterFinalDestinationType } from "../../models/resources/master/IFinalDestinationType";
import { IMasterPresenceRepresentative, IMasterRepresentativePresenceRemaining } from "../../models/resources/master/IPresenceRepresentative";
import { IMasterTurn, IMasterTurnRemaining } from "../../models/resources/master/ITurn";
import { IMasterRequestState, IMasterRequestStateRemaining } from "../../models/resources/master/IRequestState";
import { IOrganizationData } from "../../models/organization/IOrganizationData";
import { IMasterBlock } from "../../models/resources/master/IBlock";
import { IDockBlock } from "../../models/catalog/IDockBlock";
import { IMasterActuationGroup, IMasterActuationGroupRemaining } from "../../models/resources/master/IActuationGroup";
import { IMasterActuationType, IMasterActuationTypeRemaining } from "../../models/resources/master/IActuationType";
import { IMasterAppointmentType } from "../../models/masterAppointment/IAppointmentType";
import { IMerchandiseCategoryGroup } from "../../models/resources/master/IGroupMerchandiseCategory";
import { IMasterCustomsState, IMasterCustomsStateRemaining } from "../../models/resources/master/ICustomsState";
import { IMasterNotificationType } from "../../models/resources/master/INotificationType";
import { ITerminal } from "../../models/catalog/ITerminal";
import { IMasterTransportUnit, IMasterTransportUnitRemaining } from "../../models/resources/master/ITransportUnit";
import { getLocalDate } from "../../utils";
import { FormatDate } from "../../common/enum/dateTime/FormatDate";
import { IAppointmentTimeZone } from "../../models/catalog/IAppointmentTimeZone";
import { Method } from "../../common/enum";
import { IMasterTerminal } from "../../models/resources/master/ITerminal";

const baseURL = `${process.env.REACT_APP_API_URL}/catalog`;
const baseMasterURL = `${baseURL}/master`;

export class CatalogAPI {
    async getOrganizations(): Promise<IOrganization[]> {
        const url = `${baseMasterURL}/organizations`;
        return apiFetch(Method.GET, url).execute<IOrganization[]>();
    }

    async getModules(): Promise<IModule> {
        const url = `${baseMasterURL}/modules`;
        return apiFetch(Method.GET, url).execute<IModule>();
    }

    async getFinalDestinationTypes(): Promise<IFinalDestinationType[]> {
        const url = `${baseURL}/finaldestinationtypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IFinalDestinationType[]>();
    }

    async getCustomsStateTypes(): Promise<ICustomsState[]> {
        const url = `${baseURL}/customsStates`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ICustomsState[]>();
    }

    async getMerchandiseOrigins(): Promise<IMerchandiseOrigin[]> {
        const url = `${baseURL}/merchandiseOrigins`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseOrigin[]>();
    }

    async getMerchandiseTypes(): Promise<IMerchandiseType[]> {
        const url = `${baseURL}/merchandiseTypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseType[]>();
    }

    async getMerchandiseCategoryTypesWithGroups(id: string): Promise<IMerchandiseType[]> {
        const url = `${baseURL}/merchandisecategorygroups/${id}/merchandisetype`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseType[]>();
    }

    async getMerchandiseCategory(): Promise<IMerchandiseCategoryOption[]> {
        const url = `${baseURL}/merchandisecategories`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseCategoryOption[]>();
    }
    async getMerchandiseCategorySorted(): Promise<IMerchandiseCategoryOption[]> {
        const url = `${baseURL}/merchandisecategories/sorted`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseCategoryOption[]>();
    }
    async getMerchandiseCategoryRelationship(): Promise<IMerchandiseCategoryRealtionShipOption[]> {
        const url = `${baseURL}/merchandisecategoryrelationship/all`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({}).execute<IMerchandiseCategoryRealtionShipOption[]>();
    }

    async getTransportUnits(): Promise<ITransportUnit[]> {
        const url = `${baseURL}/transportUnits`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ITransportUnit[]>();
    }

    async getRepresentativePresenceTypes(): Promise<IRepresentativePresenceType[]> {
        const url = `${baseURL}/representativepresencetypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRepresentativePresenceType[]>();
    }

    async getTerminals(): Promise<ITerminal[]> {
        const url = `${baseURL}/terminals`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ITerminal[]>();
    }

    async getSealTypes(): Promise<ISealType[]> {
        const url = `${baseURL}/sealtypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ISealType[]>();
    }

    async getTurns(): Promise<ITurn[]> {
        const url = `${baseURL}/turns`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ITurn[]>();
    }

    async getOrganisms(): Promise<IOrganism[]> {
        const url = `${baseURL}/organisms`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IOrganism[]>();
    }

    async getCertificateTypesByOrganismId(organismId: number): Promise<ICertificateType[]> {
        const url = `${baseURL}/certificateTypes?organismId=${organismId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ICertificateType[]>();
    }

    async getAllCertificateTypes(): Promise<ICertificateType[]> {
        const url = `${baseURL}/certificateTypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ICertificateType[]>();
    }

    async getContainerSizes(): Promise<ITransportUnitSize[]> {
        const url = `${baseURL}/transportunitsizes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<ITransportUnitSize[]>();
    }

    async getActuationTypes(filter?: boolean): Promise<IActuationType[]> {
        const url = `${baseURL}/actuationtypes?filter=${filter}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IActuationType[]>();
    }

    async getDocks(): Promise<IDock[]> {
        const url = `${baseURL}/docks`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IDock[]>();
    }

    async getDocksQueued(params: IDocksQueued): Promise<IDock[]> {
        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== null && value !== undefined)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");
        const url = `${baseURL}/docks/queued?${queryString}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IDock[]>();
    }

    async getValuedTypes(): Promise<IValuedType[]> {
        const url = `${baseURL}/valuedtypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IValuedType[]>();
    }

    async getRequestStates(): Promise<IRequestState[]> {
        const url = `${baseURL}/requeststates`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRequestState[]>();
    }

    async getDocumentsType(): Promise<IDocumentType[]> {
        const url = `${baseURL}/documenttypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<any>();
    }

    async getIncidenceType(idOrganism?: number): Promise<IIncidenceType[]> {
        const url = `${baseURL}/incidencetypes` + (idOrganism ? `?organismId=${idOrganism}` : "");
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IIncidenceType[]>();
    }

    async getInspectionActuationsGroup(): Promise<IActuationTypesGroup[]> {
        const url = `${baseURL}/actuationtypegroups`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IActuationTypesGroup[]>();
    }

    async getRoles(): Promise<IRole[]> {
        const url = `${baseMasterURL}/roles`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRole[]>();
    }

    async getUserPositionTypes(): Promise<IUserPositionType[]> {
        const url = `${baseMasterURL}/userpositiontypes`;
        return apiFetch(Method.GET, url).execute<IUserPositionType[]>();
    }

    async getNotificationTypes(): Promise<INotificationType[]> {
        const url = `${baseURL}/notificationtypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<INotificationType[]>();
    }

    async getNotificationModes(): Promise<INotificationMode[]> {
        const url = `${baseURL}/notificationmodes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<INotificationMode[]>();
    }

    async getRequestDayPermissionTypes(): Promise<IRequestDayPermission[]> {
        const url = `${baseURL}/master/requestdaypermissiontypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRequestDayPermission[]>();
    }

    async getAppointmentDayPermissionTypes(): Promise<IRequestDayPermission[]> {
        const url = `${baseURL}/master/appointmentdaypermissiontypes`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IRequestDayPermission[]>();
    }

    async getCatalogViews(featureId: string, roleId: string): Promise<IUserRoleView> {
        const url = `${baseURL}/views?featureId=${featureId}&roleId=${roleId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IUserRoleView>();
    }

    async getMasterOrganismList(): Promise<IMasterOrganismItem[]> {
        const url = `${baseURL}/organisms/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterOrganismItem[]>();
    }
    async getMasterOrganism(id: number): Promise<IMasterOrganismItem> {
        const url = `${baseURL}/organisms/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterOrganismItem>();
    }
    async postMasterOrganism(form: IMasterOrganismItem): Promise<boolean> {
        const url = `${baseURL}/organisms`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }
    async putMasterOrganism(form: IMasterOrganismItem): Promise<IMasterOrganismItem> {
        const url = `${baseURL}/organisms/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<IMasterOrganismItem>();
    }
    async getOrganismsNotConfigure(): Promise<IOrganism[]> {
        const url = `${baseMasterURL}/organisms/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IOrganism[]>();
    }

    async getMasterCertificateTypesList(): Promise<IMasterCertificateType[]> {
        const url = `${baseURL}/certificatetypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCertificateType[]>();
    }
    async getMasterCertificateType(id: number): Promise<IMasterCertificateType> {
        const url = `${baseURL}/certificatetypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCertificateType>();
    }
    async getMasterCertificateTypesRemaining(): Promise<IMasterCertificateTypesRemaining[]> {
        const url = `${baseMasterURL}/certificatetypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCertificateTypesRemaining[]>();
    }
    async putMasterCertificateType(form: IMasterCertificateType): Promise<IMasterCertificateType> {
        const url = `${baseURL}/certificatetypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<IMasterCertificateType>();
    }
    async postMasterCertificateType(form: IMasterCertificateType): Promise<IMasterCertificateType> {
        const url = `${baseURL}/certificatetypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<IMasterCertificateType>();
    }

    async getMasterMerchandiseOriginsList(): Promise<IMasterMerchandiseOrigin[]> {
        const url = `${baseURL}/merchandiseorigins/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseOrigin[]>();
    }
    async getMasterMerchandiseOrigin(id: number): Promise<IMasterMerchandiseOrigin> {
        const url = `${baseURL}/merchandiseorigins/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseOrigin>();
    }
    async getMasterMerchandiseOriginRemaining(): Promise<IMasterMerchandiseOriginRemaining[]> {
        const url = `${baseMasterURL}/merchandiseorigins/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseOriginRemaining[]>();
    }
    async putMasterMerchandiseOrigins(form: IMasterMerchandiseOrigin): Promise<boolean> {
        const url = `${baseURL}/merchandiseorigins/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async postMasterMerchandiseOrigins(form: IMasterMerchandiseOrigin): Promise<boolean> {
        const url = `${baseURL}/merchandiseorigins`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async getMasterMerchandiseTypesList(): Promise<IMasterMerchandiseTypeRemaining[]> {
        const url = `${baseURL}/merchandisetypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseTypeRemaining[]>();
    }
    async getMasterMerchandiseType(id: number): Promise<IMasterMerchandiseType> {
        const url = `${baseURL}/merchandisetypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseType>();
    }
    async getMasterMerchandiseTypeRemaining(): Promise<IMasterCertificateTypesRemaining[]> {
        const url = `${baseMasterURL}/merchandisetypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCertificateTypesRemaining[]>();
    }
    async putMasterMerchandiseTypes(form: IMasterMerchandiseType): Promise<boolean> {
        const url = `${baseURL}/merchandisetypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async postMasterMerchandiseTyes(form: IMasterMerchandiseType): Promise<boolean> {
        const url = `${baseURL}/merchandisetypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async getMasterMerchandiseCategoryList(): Promise<IMasterMerchandiseCategory[]> {
        const url = `${baseURL}/merchandisecategories/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseCategory[]>();
    }
    async getMasterMerchandiseCategoryRemaining(): Promise<IMasterMerchandiseCategoryRemaining[]> {
        const url = `${baseMasterURL}/merchandisecategories/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseCategoryRemaining[]>();
    }

    async getMasterMerchandiseCategory(id: number): Promise<IMasterMerchandiseCategory> {
        const url = `${baseURL}/merchandisecategories/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseCategory>();
    }
    async postMasterMerchandiseCategory(form: IMasterMerchandiseCategory): Promise<boolean> {
        const url = `${baseURL}/merchandisecategories`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterMerchandiseCategory(form: IMasterMerchandiseCategory): Promise<boolean> {
        const url = `${baseURL}/merchandisecategories/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterDocksList(): Promise<IMasterDisplayDoock[]> {
        const url = `${baseURL}/docks/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterDisplayDoock[]>();
    }

    async getMasterDock(id: number): Promise<IMasterDisplayDoock> {
        const url = `${baseURL}/docks/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterDisplayDoock>();
    }
    async postMasterDocks(form: IMasterDisplayDoock): Promise<boolean> {
        const url = `${baseURL}/docks`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterDocks(form: IMasterDisplayDoock): Promise<boolean> {
        const url = `${baseURL}/docks/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterDocumentTypesList(): Promise<IMasterDocumentType[]> {
        const url = `${baseURL}/documenttypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterDocumentType[]>();
    }
    async getMasterDocumentTypesRemaining(): Promise<IMasterMerchandiseCategoryRemaining[]> {
        const url = `${baseMasterURL}/documenttypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterMerchandiseCategoryRemaining[]>();
    }

    async getMasterDocumentType(id: number): Promise<IMasterDocumentType> {
        const url = `${baseURL}/documenttypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterDocumentType>();
    }
    async postMasterDocumentType(form: IMasterDocumentType): Promise<boolean> {
        const url = `${baseURL}/documenttypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterDocumentType(form: IMasterDocumentType): Promise<boolean> {
        const url = `${baseURL}/documenttypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterSealTypesList(): Promise<IMasterSealType[]> {
        const url = `${baseURL}/sealtypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterSealType[]>();
    }
    async getMasterSealTypesRemaining(): Promise<IMasterTypeSealRemaining[]> {
        const url = `${baseMasterURL}/sealtypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterTypeSealRemaining[]>();
    }

    async getMasterSealType(id: number): Promise<IMasterSealType> {
        const url = `${baseURL}/sealtypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterSealType>();
    }
    async postMasterSealType(form: IMasterSealType): Promise<boolean> {
        const url = `${baseURL}/sealtypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterSealType(form: IMasterSealType): Promise<boolean> {
        const url = `${baseURL}/sealtypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getFinalDestinationTypesList(): Promise<IMasterFinalDestinationType[]> {
        const url = `${baseURL}/finaldestinationtypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterFinalDestinationType[]>();
    }
    async getMasterFinalDestinationTypesRemaining(): Promise<IMasterTypeSealRemaining[]> {
        const url = `${baseMasterURL}/finaldestinationtypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterTypeSealRemaining[]>();
    }

    async getMasterFinalDestinationTypes(id: number): Promise<IMasterFinalDestinationType> {
        const url = `${baseURL}/finaldestinationtypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterFinalDestinationType>();
    }
    async postMasterFinalDestinationTypes(form: IMasterFinalDestinationType): Promise<boolean> {
        const url = `${baseURL}/finaldestinationtypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterFinalDestinationTypes(form: IMasterFinalDestinationType): Promise<boolean> {
        const url = `${baseURL}/finaldestinationtypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterRepresentativePresenceTypesList(): Promise<IMasterPresenceRepresentative[]> {
        const url = `${baseURL}/representativepresencetypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterPresenceRepresentative[]>();
    }
    async getMasterPresenceRepresentativeTypesRemaining(): Promise<IMasterRepresentativePresenceRemaining[]> {
        const url = `${baseMasterURL}/representativepresencetypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterRepresentativePresenceRemaining[]>();
    }

    async getMasterPresenceRepresentativeType(id: number): Promise<IMasterPresenceRepresentative> {
        const url = `${baseURL}/representativepresencetypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterPresenceRepresentative>();
    }
    async postMasterPresenceRepresentativeTypes(form: IMasterPresenceRepresentative): Promise<boolean> {
        const url = `${baseURL}/representativepresencetypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterPresenceRepresentativeTypes(form: IMasterPresenceRepresentative): Promise<boolean> {
        const url = `${baseURL}/representativepresencetypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterTurnsList(): Promise<IMasterTurn[]> {
        const url = `${baseURL}/turns/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterTurn[]>();
    }
    async getMasterTurnsRemaining(): Promise<IMasterTurnRemaining[]> {
        const url = `${baseMasterURL}/turns/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterTurnRemaining[]>();
    }

    async getMasterTurn(id: number): Promise<IMasterTurn> {
        const url = `${baseURL}/turns/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterTurn>();
    }
    async postMasterTurns(form: IMasterTurn): Promise<boolean> {
        const url = `${baseURL}/turns`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterTurns(form: IMasterTurn): Promise<boolean> {
        const url = `${baseURL}/turns/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterRequestStatesList(): Promise<IMasterRequestState[]> {
        const url = `${baseURL}/requeststates/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterRequestState[]>();
    }
    async getMasterRequestStatesRemaining(): Promise<IMasterRequestStateRemaining[]> {
        const url = `${baseMasterURL}/requeststates/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterRequestStateRemaining[]>();
    }

    async getMasterRequestState(id: number): Promise<IMasterRequestState> {
        const url = `${baseURL}/requeststates/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterRequestState>();
    }
    async postMasterRequestState(form: IMasterRequestState): Promise<boolean> {
        const url = `${baseURL}/requeststates`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterRequestState(form: IMasterRequestState): Promise<boolean> {
        const url = `${baseURL}/requeststates/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async managementSort(tableName: string, id: number, sortType: string): Promise<boolean> {
        const url = `${process.env.REACT_APP_API_URL}/management/sort/${tableName}/${id}/${sortType}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).execute<boolean>();
    }

    async getUserOrganization(): Promise<IOrganizationData> {
        const url = `${baseURL}/organizations`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IOrganizationData>();
    }

    async putUserOrganization(form: IOrganizationData): Promise<boolean> {
        const url = `${baseURL}/organizations`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterBlockList(): Promise<IMasterBlock[]> {
        const url = `${baseURL}/dockblocks/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterBlock[]>();
    }

    async getMasterBlock(id: number): Promise<IMasterBlock> {
        const url = `${baseURL}/dockblocks/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterBlock>();
    }

    async postMasterBlock(form: IMasterBlock): Promise<boolean> {
        const url = `${baseURL}/dockblocks`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }
    async putMasterBlock(form: IMasterBlock): Promise<boolean> {
        const url = `${baseURL}/dockblocks/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async getDockBlocks(): Promise<IDockBlock[]> {
        const url = `${baseURL}/dockblocks`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IDockBlock[]>();
    }
    async getActuationGroupRemaining(): Promise<IMasterActuationGroupRemaining[]> {
        const url = `${baseMasterURL}/actuationtypegroups/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterActuationGroupRemaining[]>();
    }
    async getActuationGroupList(): Promise<IMasterActuationGroupRemaining[]> {
        const url = `${baseURL}/actuationtypegroups/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterActuationGroupRemaining[]>();
    }

    async getActuationGroup(id: number): Promise<IMasterActuationGroup> {
        const url = `${baseURL}/actuationtypegroups/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterActuationGroup>();
    }
    async postActuationGroup(form: any): Promise<boolean> {
        const url = `${baseURL}/actuationtypegroups`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putActuationGroup(form: any): Promise<boolean> {
        const url = `${baseURL}/actuationtypegroups/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getActuationTypeRemaining(): Promise<IMasterActuationTypeRemaining[]> {
        const url = `${baseMasterURL}/actuationTypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterActuationTypeRemaining[]>();
    }
    async getActuationTypeList(): Promise<IMasterActuationType[]> {
        const url = `${baseURL}/actuationtypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterActuationType[]>();
    }

    async getActuationType(id: number): Promise<IMasterActuationType> {
        const url = `${baseURL}/actuationtypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterActuationType>();
    }
    async postActuationType(form: any): Promise<boolean> {
        const url = `${baseURL}/actuationtypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putActuationType(form: any): Promise<boolean> {
        const url = `${baseURL}/actuationtypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterAppointmentTypeList(): Promise<IMasterAppointmentType[]> {
        const url = `${baseURL}/appointment/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentType[]>();
    }

    async getMasterAppointmentTypeForm(id: number): Promise<IMasterAppointmentType> {
        const url = `${baseURL}/appointment/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterAppointmentType>();
    }

    async postMasterAppointmentTypeForm(form: IMasterAppointmentType): Promise<boolean> {
        const url = `${baseURL}/appointment`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterAppointmentTypeForm(form: IMasterAppointmentType): Promise<boolean> {
        const url = `${baseURL}/appointment/${form.id}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async getMasterGroupMerchandiseCategoryList(): Promise<IMerchandiseCategoryGroup[]> {
        const url = `${baseURL}/merchandisecategorygroups`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseCategoryGroup[]>();
    }

    async getMasterGroupMerchandiseCategoryRemaining(): Promise<IMerchandiseCategoryGroup[]> {
        const url = `${baseMasterURL}/merchandisecategorygroups/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseCategoryGroup[]>();
    }

    async getMasterGroupMerchandiseCategory(id: number): Promise<IMerchandiseCategoryGroup> {
        const url = `${baseURL}/merchandisecategorygroups/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMerchandiseCategoryGroup>();
    }
    async postMasterGroupMerchandiseCategory(form: any): Promise<boolean> {
        const url = `${baseURL}/merchandisecategorygroups`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterGroupMerchandiseCategory(form: any): Promise<boolean> {
        const url = `${baseURL}/merchandisecategorygroups/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterCustomsStatesList(): Promise<IMasterCustomsState[]> {
        const url = `${baseURL}/customsstates/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCustomsState[]>();
    }
    async getMasterCustomsState(id: number): Promise<IMasterCustomsState> {
        const url = `${baseURL}/customsstates/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCustomsState>();
    }
    async getMasterCustomsStatesRemaining(): Promise<IMasterCustomsStateRemaining[]> {
        const url = `${baseMasterURL}/customsstates/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IMasterCustomsStateRemaining[]>();
    }
    async putMasterCustomsStates(form: IMasterCustomsState): Promise<boolean> {
        const url = `${baseURL}/customsstates/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async postMasterCustomsStates(form: IMasterCustomsState): Promise<boolean> {
        const url = `${baseURL}/customsstates`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async getMasterNotificationTypesList(): Promise<IMasterNotificationType[]> {
        const url = `${baseURL}/notificationtypes/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterNotificationType[]>();
    }

    async getMasterNotificationTypesRemaining(): Promise<IMasterNotificationType[]> {
        const url = `${baseMasterURL}/notificationtypes/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterNotificationType[]>();
    }

    async getMasterNotificationType(id: number): Promise<IMasterNotificationType> {
        const url = `${baseURL}/notificationtypes/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterNotificationType>();
    }

    async postMasterNotificationTypes(form: IMasterNotificationType): Promise<boolean> {
        const url = `${baseURL}/notificationtypes`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async putMasterNotificationTypes(form: IMasterNotificationType): Promise<boolean> {
        const url = `${baseURL}/notificationtypes/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async getMasterTransportUnitRemaining(): Promise<IMasterTransportUnitRemaining[]> {
        const url = `${baseMasterURL}/transportunits/remaining`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterTransportUnitRemaining[]>();
    }

    async getMasterTransportUnitList(): Promise<IMasterTransportUnit[]> {
        const url = `${baseURL}/transportunits/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterTransportUnit[]>();
    }

    async getMasterTransportUnit(form: IMasterTransportUnit): Promise<IMasterTransportUnit> {
        const url = `${baseURL}/transportunits/${form.id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterTransportUnit>();
    }
    async putMasterTransportUnit(form: IMasterTransportUnit): Promise<boolean> {
        const url = `${baseURL}/transportunits/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async postMasterTransportUnit(form: IMasterTransportUnit): Promise<boolean> {
        const url = `${baseURL}/transportunits`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async getMasterTerminalList(): Promise<IMasterTerminal[]> {
        const url = `${baseURL}/terminals/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterTerminal[]>();
    }
    async getMasterTerminal(form: IMasterTerminal): Promise<IMasterTerminal> {
        const url = `${baseURL}/terminals/${form.id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IMasterTerminal>();
    }
    async putMasterTerminal(form: IMasterTerminal): Promise<boolean> {
        const url = `${baseURL}/terminals/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }
    async postMasterTerminal(form: IMasterTerminal): Promise<boolean> {
        const url = `${baseURL}/terminals`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }

    async getAppointmentTimeZone(): Promise<IAppointmentTimeZone[]> {
        const url = `${baseURL}/appointmenttimezones?date=${getLocalDate(FormatDate.ISO)}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IAppointmentTimeZone[]>();
    }
}
