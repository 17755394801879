import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { IManagementUserForm } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, convertToLocal } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { myProfileCarrierResolver, myProfileResolver } from "./resolver";
import { useEffect } from "react";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { LabelText } from "../../../../../common/labels/labelText/labelText";
import { renderRequiredData } from "../../../../../request/draft/form/validation/requestValidationUtils";
import { requestMaxLength } from "../../../../../request/form/create/validation/requestConfig";
import { isFieldRequiredWithSchema } from "../../validators/schema";
import { schemaRole, schemaView } from "../../../../../../common/enum/management/Schema";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { useAppSelector } from "../../../../../../redux/hooks";

interface IManagementCustomerFormUserDataProps {
    onInputChange: (event: any) => any;
    generalUserData?: IManagementUserForm;
    setIsValidTab: (value: boolean) => any;
    role?: schemaRole;
    userMigration: boolean;
}
export const ManagementCustomerFormUserData: React.FC<IManagementCustomerFormUserDataProps> = ({
    onInputChange,
    generalUserData,
    setIsValidTab,
    role,
    userMigration,
}) => {
    const { showPopUp } = useAppSelector((store) => store.managementUserForm);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const schema = isFieldRequiredWithSchema(role ?? schemaRole.DEFAULT, schemaView.USER_DATA);
    const resolver = role === schemaRole.CARRIER ? myProfileCarrierResolver : myProfileResolver;
    const {
        register,
        formState: { errors: error, isValid },
        trigger,
        setValue,
    } = useForm<IManagementUserForm>({ mode: "onChange", resolver: resolver });

    useEffect(() => {
        setIsValidTab(isValid);
    }, [isValid]);

    const isRequiredField = (field: string) => {
        return schema.includes(field);
    };

    useEffect(() => {
        if (generalUserData) {
            setValue("email", generalUserData?.email);
            setValue("surName", generalUserData?.surName);
            setValue("lastName", generalUserData?.lastName);
            setValue("name", generalUserData?.name);

            trigger();
        }
    }, [generalUserData]);

    return (
        <div className={`  ${style.containerForm}`}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={`${GridClass.ROW_GRID}`}>
                    <div className={GridClass.THREE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData?.name ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("NAME")}
                            {...register(compileNameOfProperty<IManagementUserForm>("name"), {
                                onChange: onInputChange,
                                value: generalUserData?.name ?? "",
                                required: renderRequiredData(isRequiredField(compileNameOfProperty<IManagementUserForm>("name"))),
                                validate: {
                                    isRequired: (value: any) => isRequiredField(compileNameOfProperty<IManagementUserForm>("name")),
                                },
                            })}
                            error={error.name}
                            isRequired={isRequiredField(compileNameOfProperty<IManagementUserForm>("name"))}
                        />
                    </div>

                    <div className={GridClass.THREE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData?.surName ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("SURNAME")}
                            error={error.surName}
                            {...register(compileNameOfProperty<IManagementUserForm>("surName"), {
                                onChange: onInputChange,
                                value: generalUserData?.surName ?? "",
                                required: renderRequiredData(isRequiredField(compileNameOfProperty<IManagementUserForm>("surName"))),
                                validate: {
                                    isRequired: (value: any) => isRequiredField(compileNameOfProperty<IManagementUserForm>("surName")),
                                },
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IManagementUserForm>("surName"))}
                        />
                    </div>
                    <div className={GridClass.THREE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData?.lastName ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("LASTNAME")}
                            {...register(compileNameOfProperty<IManagementUserForm>("lastName"), {
                                onChange: onInputChange,
                                value: generalUserData?.lastName ?? "",
                                required: isRequiredField(compileNameOfProperty<IManagementUserForm>("lastName")),
                            })}
                        />
                    </div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData?.email ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("EMAIL")}
                            {...register(compileNameOfProperty<IManagementUserForm>("email"), {
                                onChange: onInputChange,
                                value: generalUserData?.email ?? "",
                            })}
                            error={error.email}
                            isRequired={isRequiredField(compileNameOfProperty<IManagementUserForm>("email"))}
                            disabled={userMigration ? false : PopUpCodes.UPDATE === showPopUp}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData?.phoneNumber ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("PHONE_NUMBER")}
                            error={error.phoneNumber}
                            {...register(compileNameOfProperty<IManagementUserForm>("phoneNumber"), {
                                onChange: onInputChange,
                                value: generalUserData?.phoneNumber ?? "",
                            })}
                        />
                    </div>
                </div>
                {generalUserData && generalUserData?.unregistered && (
                    <div className={GridClass.ROW_GRID}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <LabelText
                                key={t("STATE") + generalUserData.unregisteredOn}
                                name={t("STATE")}
                                value={`${t("DISCHARGED")} - ${convertToLocal(generalUserData.unregisteredOn ?? "", FormatDate.DATE_HOUR)}`}
                                className={style.discharged}
                            ></LabelText>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
