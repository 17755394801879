import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const OrganizationFormStyle = createUseStyles((theme: any) => ({
    container: {
        height: "calc(100vh - 14rem)",
        overflow: "auto",
    },

    containerForm: {
        padding: theme.paddings.sizes.sm,
        paddingLeft: 0,
        display: "flex",
        gap: theme.gaps.sizes.xxl,
        flexDirection: "column",
        overflowY: "scroll",
    },
    rowForm: {
        display: "flex",
        gap: "60px",
        "&  > div": {
            width: "49%",
            padding: 0,
        },
    },
    dataRow: {
        flexFlow: "row wrap",
        display: "flex",
        flexDirection: "row",
        columnGap: 60,
        rowGap: theme.gaps.sizes.xl,
        paddingBottom: 32,
    },
    radioButtonItem: {
        flex: "1 1 150px",
        display: "flex",
        [mediaQueries.MOBILE]: {
            width: "50%",
        },
    },
    sectionForm: {
        padding: theme.paddings.sizes.md,
        paddingLeft: 0,
        display: "flex",
        gap: theme.gaps.sizes.xxl,
        flexDirection: "column",
        overflowY: "scroll",
        marginBottom: theme.margins.sizes.base,
    },
    eventItem: {
        flexDirection: "column",
        gap: 10,
    },
}));
