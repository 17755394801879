import { createUseStyles } from "react-jss";

export const ConfigOrganismCertificateTypeFilterStyle = createUseStyles((theme: any) => ({
    filter: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.filter.default,
    },
    item: {
        minWidth: 300,
    },
}));
