import { Resolver } from "react-hook-form";
import { isValidPhoneNumber } from "../../../common/validations/validationsForm";
import { IUserSelfSignup } from "../../../models";
import { compileNameOfProperty } from "../../../utils";

export const selfSignUpResolver: Resolver<IUserSelfSignup> = async (values: IUserSelfSignup) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        switch (name) {
            case compileNameOfProperty<IUserSelfSignup>("phoneNumber"):
                if (!isValidPhoneNumber(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.PHONE",
                        },
                    };
                }
                break;
            case compileNameOfProperty<IUserSelfSignup>("lastName"):
                break;
            default:
                if (!value) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.REQUIRE",
                        },
                    };
                }
                break;
        }
    });
    return { values, errors };
};
