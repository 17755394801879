import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IIncidenceTypesItem } from "../../../../../models/management/incidenceType/IIncidenceType";
import { fetchManagementIncidenceTypeItemAction } from "../../../../../redux/actions/management/incidenceTypes/incidenceTypes";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
    setShowPopUpIncidenceTypeForm,
    updateManagementIncidenceTypeFormProperty,
} from "../../../../../redux/reducers/management/incidenceType/form";
import { updateSortColumn } from "../../../../../redux/reducers/management/incidenceType/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { ManagementIncidenceTypesListColumnStyle } from "./columnStyle.jss";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ManagementIncidenceTypesListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.INCIDENCE_TYPE.TABLE" });
    const theme = useTheme();
    const style = ManagementIncidenceTypesListColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.managementIncidenceTypesList.sort);
    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().managementIncidenceTypesList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IIncidenceTypesItem>("description"),
            minWidth: 250,
            maxWidth: 450,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IIncidenceTypesItem>("description")}
                    title={t("DESCRIPTION")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IIncidenceTypesItem>("description"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IIncidenceTypesItem) => <span>{item.description??DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IIncidenceTypesItem>("organismDescription"),
            minWidth: 250,
            maxWidth: 450,
            name: t("ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IIncidenceTypesItem>("organismDescription")}
                    title={t("ORGANISM")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IIncidenceTypesItem>("organismDescription"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IIncidenceTypesItem) => <span>{item.organismDescription ??DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IIncidenceTypesItem>("incidenceType"),
            minWidth: 250,
            maxWidth: 450,
            name: t("INCIDENCE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IIncidenceTypesItem>("incidenceType")}
                    title={t("INCIDENCE_TYPE")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IIncidenceTypesItem>("incidenceType"))}
                    sort={sort}
                    isSortable={false}
                />
            ),
            onRender: (item: IIncidenceTypesItem) => <span>{item.organismId ? t("OPERATIONAL_INCIDENCE") : t("CHARGE_INCIDENCE")}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IIncidenceTypesItem>("customerNotice"),
            minWidth: 300,
            maxWidth: 500,
            name: t("WARNING"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IIncidenceTypesItem>("customerNotice")}
                    title={t("WARNING")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IIncidenceTypesItem>("customerNotice"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IIncidenceTypesItem) => (
                <span>
                    <BooleanValue value={item.customerNotice}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: "actions",
            minWidth: 150,
            maxWidth: 200,
            name: "actions",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={"actions"}
                    title={""}
                    styles={style}
                    sort={sort}
                    isSortable={false}
                />
            ),
            onRender: (item: IIncidenceTypesItem) => (
                <span className={style.buttonsContainer}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            if (item.id) {
                                dispatch(fetchManagementIncidenceTypeItemAction(item.id));
                                dispatch(setShowPopUpIncidenceTypeForm(PopUpCodes.UPDATE));
                            }
                        }}
                    ></CustomIcons>

                    {item.allowDelete && (
                        <>
                            <Separator size="none" />
                            <CustomIcons
                                iconName={NameIcons.REMOVE}
                                onClick={() => {
                                    if (item.id) {
                                        dispatch(setShowPopUpIncidenceTypeForm(PopUpCodes.DELETE));
                                        dispatch(updateManagementIncidenceTypeFormProperty({ name: "id", value: item.id }));
                                    }
                                }}
                            ></CustomIcons>
                        </>
                    )}
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
