import { createUseStyles } from "react-jss";

export const UserTruckerFileProfileDataStyle = createUseStyles((theme: any) => ({
    row: {
        boxSizing: "border-box",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
    },
    column: {
        flexBasis: "100%",
    },
    title: {
        flexBasis: "100%",
        paddingBottom: "20px",
    },
  
    columnDocument: {
        display: "flex",
        minWidth: "5rem",
        flexDirection:"column", 
        gap:15
    },
    columnDocumentFull: {
        display: "flex",
        alignItems: "center",
        minWidth: "20rem",
    },
    documentTitle: {
        fontSize: "0.75rem",
    },
    document: {
        //width:"70%"
    }
}));
