import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ImageSliderStyle } from "./imageSliderStyle.jss";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import React from "react";

interface IImageSliderProps {
    images?: string[];
    imageIndex: number;
    setShowImageSlider: (value: any) => void;
}

export const ImageSlider: React.FC<IImageSliderProps> = ({ images, imageIndex, setShowImageSlider }) => {
    const { t } = useTranslation("common");
    const theme = useTheme();
    const style = ImageSliderStyle({ theme });

    let list = images?.map((img) => {
        return {
            original: img,
            thumbnail: img,
        };
    });

    return (
        <div className={style.container}>
            <ImageGallery
                items={list}
                infinite={true}
                thumbnailPosition={"left"}
                showPlayButton={false}
                startIndex={imageIndex}
                showIndex={true}
                indexSeparator={` ${t("COMMON.OF")} `}
                useBrowserFullscreen={true}
                showBullets={true}
                showNav={true}
                showFullscreenButton={false}
                disableThumbnailSwipe={false}
            />
        </div>
    );
};
