import { Shimmer, ShimmerElementType } from "@fluentui/react/lib/Shimmer";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import React from "react";

interface IProps {
    rows: number;
    marginRow?: number;
    size?: string;
    columns?: number;
}

export const Skeleton: React.FC<IProps> = ({ rows, marginRow, size, columns }) => {
    const margin = marginRow || 20;

    const wrapperClass = mergeStyles({
        padding: 2,
        selectors: {
            "& > .ms-Shimmer-container": {
                marginBottom: margin,
                marginTop: margin,
            },
        },
        display: "block",
        width: size ? size : "100%",
    });

    const shimmers: JSX.Element[] = [];
    for (let i = 0; i < rows; i++) {
        let shimmerElements = Array(columns || 1).fill({ type: ShimmerElementType.line });
        shimmers.push(
            <Shimmer
                key={`shimmer${i}`}
                shimmerElements={shimmerElements}
            />,
        );
    }
    return <div className={`skeleton ${wrapperClass}`}>{shimmers}</div>;
};
