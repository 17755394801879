import { RequestApi } from "../../../../api";
import { throwErrorToastrAsync } from "../../../../utils";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import {
    setVlcportHistoricInspectionAppoimentLoading,
    setVlcportHistoricInspectionAppoimentList,
} from "../../../reducers/vlcPort/historic/list/inspectionAppoiment";
import {
    setVlcportHistoricInspectionResultLoading,
    setVlcportHistoricInspectionResultList,
} from "../../../reducers/vlcPort/historic/list/inspectionResult";
import {
    setVlcportHistoricPositioningRequestList,
    setVlcportHistoricPositioningRequestLoading,
} from "../../../reducers/vlcPort/historic/list/positioningRequest";

import { store } from "../../../store";

const requestApi = new RequestApi();

export const fetchVlcportHistoricInspectionAppoiment = (): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoading(true));
            const { filter } = store.getState().vlcportHistoricFilter;
            let newFilter = {
                ...filter,
                fileName: filter.fileName !== "" ? filter.fileName : null,
                integrationReference: filter.integrationReference !== "" ? filter.integrationReference : null,
                transportUnitNumber: filter.transportUnitNumber !== "" ? filter.transportUnitNumber : null,
            };

            dispatch(setVlcportHistoricInspectionAppoimentLoading(true));
            const response = await requestApi.getVlcPortHistoricInspectionsAppointmentsList(newFilter);
            dispatch(setVlcportHistoricInspectionAppoimentList(response));

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setVlcportHistoricInspectionAppoimentLoading(false));
            dispatch(setGlobalLoading(false));
        }
    };
};

export const fetchVlcportHistoricInspectionResult = (): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoading(true));
            const { filter } = store.getState().vlcportHistoricFilter;
            let newFilter = {
                ...filter,
                fileName: filter.fileName !== "" ? filter.fileName : null,
                integrationReference: filter.integrationReference !== "" ? filter.integrationReference : null,
                transportUnitNumber: filter.transportUnitNumber !== "" ? filter.transportUnitNumber : null,
            };
            dispatch(setVlcportHistoricInspectionResultLoading(true));
            const response = await requestApi.getVlcPortHistoricInspectionsResultsList(newFilter);
            dispatch(setVlcportHistoricInspectionResultList(response));

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setVlcportHistoricInspectionResultLoading(false));
            dispatch(setGlobalLoading(false));
        }
    };
};

export const fetchVlcPortHistoricPositioningRequest = (): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoading(true));
            const { filter } = store.getState().vlcportHistoricFilter;
            let newFilter = {
                ...filter,
                fileName: filter.fileName !== "" ? filter.fileName : null,
                integrationReference: filter.integrationReference !== "" ? filter.integrationReference : null,
                transportUnitNumber: filter.transportUnitNumber !== "" ? filter.transportUnitNumber : null,
            };
            dispatch(setVlcportHistoricPositioningRequestLoading(true));
            const response = await requestApi.getVlcPortHistoricPositioningRequestList(newFilter);
            dispatch(setVlcportHistoricPositioningRequestList(response));

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setVlcportHistoricPositioningRequestLoading(false));
            dispatch(setGlobalLoading(false));
        }
    };
};

export const reprocessVlcPortHistoricPositioningRequest = (fileName: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoading(true));
            dispatch(setVlcportHistoricPositioningRequestLoading(true));
            await requestApi.postReprocessVlcPortHistoricPositioningRequestList(fileName);
            dispatch(fetchVlcPortHistoricPositioningRequest());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setVlcportHistoricPositioningRequestLoading(false));
            dispatch(setGlobalLoading(false));
        }
    };
};
