import { ErrorType } from "../../../common/enum/ErrorType";
import { resetErrorHandler, setErrorHandler } from "../../reducers/common/errorHandler";
import { AppThunk } from "../../store";


export const resetErrorHandlerAction = (): AppThunk => (dispatch) => {
    dispatch(resetErrorHandler());
};

export const setErrorHandlerAction =
    (error: boolean, type: ErrorType): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorHandler({
            error: error,
            type: type
        }));
    };
