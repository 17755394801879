import { createUseStyles } from "react-jss";

export const UserSignupOperationEmailsStyle = createUseStyles((theme: any) => ({
    row: {
        boxSizing: "border-box",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
        width: "90%",
    },
    rowEmails: {
        rowGap: "20px",
        display: "flex",
        flex: "0 1 auto",
        flexFlow: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
        marginTop: "1rem",
    },
    column: {
        flexBasis: "100%",
    },
    columnEmail: {
        flexBasis: "90%",
    },
    title: {
        flexBasis: "100%",
        padding: "20px 20px 20px 0px",
    },
}));
