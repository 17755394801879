
import React,{ useCallback } from "react";
import { FieldErrors, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "../../../../../../../common/validations/validationsForm";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { FormField } from "../../../../../../common/fields/fields";
import { INotificationEmail } from "../list";

import { UserProfileAdminEmailItemStyle } from "./itemStyle.jss";

interface IProps {
    email: string;
    isLastIndex: boolean;
    isUniqueIndex: boolean;
    index: number;
    updateEmailItem: (value: INotificationEmail) => any;
    removeEmailItem: (value: INotificationEmail) => any;
    addEmailItem: () => any;
    notificationIndex: number;
}

interface IEmailItem {
    email: string;
}

export const NotificationEmailItem: React.FC<IProps> = ({
    email,
    isLastIndex,
    isUniqueIndex,
    index,
    addEmailItem,
    removeEmailItem,
    updateEmailItem,
    notificationIndex,
}) => {
    const style = UserProfileAdminEmailItemStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });

    const emailResolver: Resolver<IEmailItem> = async (values: IEmailItem) => {
        let errors = {};
        Object.entries(values).forEach(([name, value]) => {
            if (name === "email") {
                if (!isValidEmail(value)) {
                    errors = {
                        ...errors,
                        ["email"]: {
                            type: "required",
                            message: "FORM.VALIDATION.EMAIL",
                        },
                    };
                }
            }
        });
        return { values, errors };
    };

    const {
        register,
        formState: { errors: validationErrors, isValid },
    } = useForm<any>({ mode: "onChange", resolver: emailResolver });

    const onInputChange = useCallback(
        (event) => {
            const payload: INotificationEmail = {
                notificationIndex: notificationIndex,
                email: event?.target?.value,
                emailIndex: index,
            };
            updateEmailItem(payload);
        },
        [dispatch],
    );

    const onRemoveItem = () => {
        const payload: INotificationEmail = {
            notificationIndex: notificationIndex,
            email: "",
            emailIndex: index,
        };

        removeEmailItem(payload);
    };

    const onAddItem = () => {
        isValid && addEmailItem();
    };

    const emailErrors: FieldErrors<IEmailItem> = validationErrors;

    return (
        
            <div className={style.emailContainer}>
                <div className={style.emailContent}>
                    <FormField
                        type="text"
                        label={t("EMAIL")}
                        value={email}
                        error={emailErrors.email}
                        {...register(compileNameOfProperty<IEmailItem>("email"), {
                            onChange: onInputChange,
                            value: email,
                        })}
                        isRequired={email !==""}
                    />
                    {!isUniqueIndex && (
                        <CustomIcons
                            iconName="Cancel"
                            onClick={onRemoveItem}
                        />
                    )}
                </div>
                {isLastIndex && (
                    <div className={style.addEmail}>
                        <CustomIcons
                            iconName="Add"
                            onClick={onAddItem}
                            isDisabled={!isValid}
                        />
                    </div>
                )}
            </div>
    );
};
