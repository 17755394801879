import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ManagementUserSignupFormStyle } from "./formStyle.jss";
import { UserConfirmation } from "../../../user/confirmation/confirmation";
import { NavHeaderComponent } from "../../../common/navHeader/navHeader";
import { useNavigate } from "react-router";
import { routeUrls } from "../../../../common/constants";

export const ManagementUserSignupForm = () => {
    const theme = useTheme();
    const styles = ManagementUserSignupFormStyle({ theme });
    const { t } = useTranslation("common");
    const navigate = useNavigate();

    const backHandleClick = () => {
        navigate(routeUrls.MANAGEMENT_USER_SIGNUP);
    };

    return (
        <div className={styles.container}>
            <NavHeaderComponent 
                title={t("MANAGEMENT.USER_SIGNUP.TITLE")} 
                backHandleClick={backHandleClick} 
            />

            <UserConfirmation height="calc(100vh - 224px)"/>
        </div>
    );
};
