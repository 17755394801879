import { StylesConfig } from "react-select";
import { SelectOptions } from "./autocomplete";

export const initAutocompleteStyles = (theme: any): StylesConfig<SelectOptions> => {
    return {
        control: (provided, { isDisabled}) => ({
            ...provided,
            backgroundColor: isDisabled ? "transparent" : provided.backgroundColor,
            border: "none",
            borderBottom: isDisabled ? theme.borders.selector.disabledBorder : `1px solid ${theme.colors.autocomplete.border}`,
            borderRadius: 0,
            height: 33,
            minHeight: 33,
            boxShadow: "none",

            "&:hover": {
                borderBottom: isDisabled ? `0px` : `1px solid ${theme.colors.autocomplete.border}`,
            },
            "&:active": {
                borderBottom: isDisabled ? `0px` : `1px solid ${theme.colors.autocomplete.border}`,
            }
        }),
        option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            fontSize: theme.fonts.sizes.sm,
            backgroundColor: isSelected
                ? theme.colors.autocomplete.optionSelected
                : isFocused
                ? theme.colors.autocomplete.optionFocused
                : provided.backgroundColor,
            "&:hover": {
                color: theme.colors.palette.black,
                backgroundColor: theme.colors.autocomplete.optionHover,
            },
        }),
        container: (provided) => ({
            ...provided,
            width: "100%",
            height: 32,
            "& div": {
                fontFamily: theme.fonts.families.siteImportant,
                color: theme.colors.autocomplete.fontColor,
            },
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        valueContainer: (provided) => ({
            ...provided,
            paddingLeft: 0,
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            opacity: "0.4",
        }),
        dropdownIndicator: (provided, { isDisabled}) => ({
            ...provided,
            display: isDisabled ? "none" : provided.display
          }),
    } as StylesConfig<SelectOptions>;
};
