import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatisticsInspectionGroupedState } from "../../../../models/statistics/inspection/IGrouped";

interface IInitialState {
    data: IStatisticsInspectionGroupedState;
}
const initialSateData = (): IStatisticsInspectionGroupedState => {
    return {
        totalRequestsPositionedWithInvoiceConcepts: 0,
        totalRequests: 0,
        totalStayTime: "",
        totalIncidencesTime: "",
        totalInspectionTime: "",
        totalIncidences: 0,
        totalIncidencesPercentage: 0,
        totalStayAverageTime: "",
        totalInspectionAverageTime: "",
        totalIncidencesAverageTime: "",
        totalNetTime: "",
        inspectionGroupedStatistics: [],
        loading: false,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsInspectionGroupedListSlice = createSlice({
    name: "statiticsInspectionGroupedList",
    initialState,
    reducers: {
        resetStatisticsInspectionGroupedData: () => initialState,
        setStatisticsInspectionGroupedData: (state, action: PayloadAction<IStatisticsInspectionGroupedState>) => {
            state.data = action.payload;
        },
        setStatisticsInspectionGroupedLoading: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
    },
});

export const { resetStatisticsInspectionGroupedData, setStatisticsInspectionGroupedData, setStatisticsInspectionGroupedLoading } =
    statiticsInspectionGroupedListSlice.actions;

export default statiticsInspectionGroupedListSlice.reducer;
