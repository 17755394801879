import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchInspectorOptions } from "../../../../../redux/actions/user/users/users";
import { useAppSelector } from "../../../../../redux/hooks";
import { CustomSelector } from "../../../../common/selectors/customSelector/customSelector";
import { Selector } from "../../../../common/selectors/selector";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

interface ICustomInspectorSelectorProps {
    name: string;
    selectedKey: any;
    onChange: (name: string, keysSelected: any) => any;
    organismId: number;
}

export const CustomInspectorSelector: React.FC<ICustomInspectorSelectorProps> = ({ name, onChange, selectedKey, organismId }) => {
    const { options, loading } = useAppSelector((store) => store.usersByRole.allInspectors);
    const dispatch = useDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.FILTER" });

    useEffect(() => {
        !options.length && !loading && dispatch(fetchInspectorOptions(organismId));
    }, [options]);

    return (
        <CustomSelector
            title={t("INSPECTOR")}
            options={options}
            onChange={onChange}
            selectedKey={selectedKey ? selectedKey : DefaultValues.DEFAULT_ALL_SELECTOR}
            name={name}
        ></CustomSelector>
    );
};
interface IInspectorSelectorProps {
    name: string;
    selectedKey: any;
    onChange: (event: any, name: string, keysSelected: any) => any;
    organismId: number;
}

export const InspectorSelector: React.FC<IInspectorSelectorProps> = ({ name, onChange, selectedKey, organismId }) => {
    const { options, loading } = useAppSelector((store) => store.usersByRole.allInspectors);
    const dispatch = useDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.FILTER" });


    useEffect(() => {
        !options.length && !loading && dispatch(fetchInspectorOptions(organismId));
    }, [options, organismId]);

    return (
        <Selector
            selectedKey={selectedKey}
            name={name}
            title={t("INSPECTOR")}
            options={options}
            onChange={onChange}
        />
    );
};
