import { useTheme } from "react-jss";
import { ManagementParkingValidationFormStyle } from "./formStyle.jss";
import { Separator } from "../../../../common/separator/separator";
import { ManagementParkingValidationFormRestriction } from "./components/restriction/container";
import { ManagementParkingValidationFormException } from "./components/exception/exceptionSection";
import { ManagementInspectorSectionForm } from "./components/exception/inspection/inspection";
import { FormField } from "../../../../common/fields/fields";
import { useForm } from "react-hook-form";
import { IManagementParkingValidationRuleForm } from "../../../../../models/management/parkingValidationRule/IParkingValidationRule";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setNameManagementParkingValidationRuleForm } from "../../../../../redux/reducers/management/parkingValidation/form";
import { useTranslation } from "react-i18next";
import { TitleH6 } from "../../../../common/titles/titles";
import { ColorType } from "../../../../../common/enum/ColorType";

export const ManagementParkingValidationForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PARKING_VALIDATION_RULE.FORM" });

    const theme = useTheme();
    const style = ManagementParkingValidationFormStyle({ theme });
    const { register } = useForm<IManagementParkingValidationRuleForm>();
    const { description } = useAppSelector((state) => state.managementParkingValidationRuleForm.form);
    const dispatch = useAppDispatch();

    const onInputChange = (event: any, _newValue?: any) => {
        const payload = getGenericPayloadFromEvent(event);
        dispatch(setNameManagementParkingValidationRuleForm(payload.value));
    };

    return (
        <div className={style.parkingValidationContainer}>
            <div className={style.parkingValidationName}>
                <FormField
                    type="text"
                    value={description}
                    label={t("NAME")}
                    isRequired={true}
                    {...register(compileNameOfProperty<IManagementParkingValidationRuleForm>("name"), {
                        onChange: onInputChange,
                        value: description,
                        required: true,
                    })}
                />
            </div>

            <div className={style.parkingValidationFormContainer}>
                <div className={style.parkingValidationRestrictionBox}>
                    <TitleH6
                        color={ColorType.black}
                        bold={true}
                        title={t("RESTRICTIONS").toUpperCase()}
                    />
                    <div className={style.box}>
                        <ManagementParkingValidationFormRestriction />
                    </div>
                </div>

                <Separator size="100%" />
                <div className={style.parkingValidationExceptionBox}>
                    <TitleH6
                        color={ColorType.black}
                        bold={true}
                        title={t("EXCEPTIONS").toUpperCase()}
                    />

                    <div className={style.box}>
                        <ManagementParkingValidationFormException />
                        <ManagementInspectorSectionForm />
                    </div>
                </div>
            </div>
        </div>
    );
};
