import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISortState } from "../../../../models/common/ISortState";
import { IInspectionsDetailedStatisticsItem, IInspectionsStatisticsState } from "../../../../models/statistics/IInspection";
import { sortArray } from "../../../../utils";

interface IInitialState {
    data: IInspectionsStatisticsState;
}
const initialSateData = () => {
    return {
        inspectionDetailedStatistics: [] as IInspectionsDetailedStatisticsItem[],
        sort: {
            column: "",
            orderColumn: "",
        },
        loading: false,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsInspectionListSlice = createSlice({
    name: "statiticsInspectionList",
    initialState,
    reducers: {
        reset: () => initialState,
        setDetailedList: (state, action: PayloadAction<IInspectionsDetailedStatisticsItem[]>) => {
            state.data.inspectionDetailedStatistics = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.data.sort = action.payload;
            state.data.inspectionDetailedStatistics = sortArray(state.data.inspectionDetailedStatistics, state.data.sort);
        },
        setLoadingInspectionList: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
        setDataInspectionList: (state, action: PayloadAction<IInspectionsStatisticsState>) => {
            state.data = action.payload;
            state.data.sort = {
                column: "",
                orderColumn: "",
            };
        },
    },
});

export const { reset, setDetailedList, updateSortColumn, setLoadingInspectionList, setDataInspectionList } = statiticsInspectionListSlice.actions;

export default statiticsInspectionListSlice.reducer;
