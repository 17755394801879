import { Method } from "../../../../common/enum";
import { IBoardHistoryAppointmentDock } from "../../../../models/masterAppointment/IAppointmentBoard";
import { apiFetchOAuthWithClaims } from "../../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment/dashboards`;

export class MasterAppointmentDockBoardAPI {
    async getMasterAppointmentDockBoard(dashboardId: string, id: string): Promise<IBoardHistoryAppointmentDock> {
        const url = `${baseURL}/${dashboardId}/docks/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IBoardHistoryAppointmentDock>();
    }

    async postMasterAppointmentDockBoard(dashboardId: string, form: IBoardHistoryAppointmentDock): Promise<number> {
        const url = `${baseURL}/${dashboardId}/docks`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<number>();
    }

    async updateMasterAppointmentDockBoard(dashboardId: string, id: string, form: IBoardHistoryAppointmentDock): Promise<number> {
        const url = `${baseURL}/${dashboardId}/docks/${id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<number>();
    }

    async deleteMasterAppointmentDockBoard(dashboardId: string, id: string): Promise<boolean> {
        const url = `${baseURL}/${dashboardId}/docks/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
