import { IColumn } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";

import { useAppDispatch } from "../../../../../../../redux/hooks";

import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";

import { MasterMerchandiseCategoryColumnsStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterMerchandiseCategory } from "../../../../../../../models/resources/master/IMerchandiseCategory";
import { fetchMasterGroupMerchandiseCategory } from "../../../../../../../redux/actions/resources/master/groupMerchandiseCategory/groupMerchandiseCategory";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";

export const MasterMerchandiseCategoryColumns = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = MasterMerchandiseCategoryColumnsStyle({ theme });
    const columns: IColumn[] = [
       
        {
            key: compileNameOfProperty<IMasterMerchandiseCategory>("code"),
            minWidth: 120,
            maxWidth: 200,
            name: t("CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseCategory>("code")}
                    title={t("CODE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseCategory) => <span>{item.code?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterMerchandiseCategory>("description"),
            minWidth: 100,
            maxWidth: 200,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseCategory>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseCategory) => <span>{item.description?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseCategory) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterGroupMerchandiseCategory(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
