import { DraftApi } from "../../../../api/draft";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";
import messages from "../../../../translations/es/messages.json";
import { showMessageSuccessFromAssets, throwErrorToastrAsync } from "../../../../utils";
import { nextStepDraft, resetDraftState, setEditDraft, setDraftCreated, setGroupId, setLoadingDraft } from "../../../reducers/request/draft/draft";
import { AppThunk, store } from "../../../store";
import { setGlobalLoadingAction } from "../../common";
import { fetchPromiseInvoiceCustomerOptions } from "../../user/users/users";
import { fetchDraftListAction } from "./draftList";

const draftApi = new DraftApi();

export const createDraftRequestAction = (presentDraft: boolean = false): any => {
    return async (dispatch: any) => {
        try {
            const { request } = store.getState().draftRequest;
            dispatch(setGlobalLoadingAction(true));
            const response = await draftApi.createRequestDraftAsync(request);
            if (presentDraft && request.groupId) {
                dispatch(patchDraftSubmit(request.groupId));
            }
            if (!request.groupId) {
                showMessageSuccessFromAssets(messages.SUCCESS.CREATE_REQUEST);
                dispatch(setDraftCreated(response));
                dispatch(nextStepDraft());
            }
            return true;
        } catch (error) {
            dispatch(HandleAxiosError(error));
        } finally {
            dispatch(fetchDraftListAction());
            dispatch(setGlobalLoadingAction(false));
        }
    };
};

export const patchDraftSubmit =
    (groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await draftApi.submitRequestDraftAsync(groupId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
            dispatch(fetchDraftListAction());
        }
    };

export const deleteDraft =
    (groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await draftApi.deleteRequestDraftAsync(groupId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchDraftListAction());
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const setEditDraftRequestData =
    (groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await draftApi.getRequestDraftAsync(groupId);
            dispatch(setGroupId(groupId));
            dispatch(setEditDraft(response));
            await dispatch(fetchPromiseInvoiceCustomerOptions(response.customerId));
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingDraft(true));
        } finally {
            dispatch(setLoadingDraft(true));
        }
    };

export const onExitDraftRequestAction = (): AppThunk => async (dispatch) => {
    dispatch(resetDraftState());
};
