import { ActionButton, IIconProps, TooltipHost } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../assets/icons";
import { ColorType } from "../../../common/enum/ColorType";
import {
    fetchInvoicingNonSynchronizedAction,
    putInvoicingNonSynchronizedAction,
} from "../../../redux/actions/invoicing/nonSynchronized/nonSynchronized";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Button } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { InvoicingNonSynchronizedList } from "./list/list";
import i18n from "../../../services/i18n";

export const InvoicingNonSynchronizedContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "INVOICED" });
    const dispatch = useAppDispatch();

    const reloadIcon: IIconProps = { iconName: NameIcons.RELOAD, style: { width: 25 } };
    const { list } = useAppSelector((state) => state.invoicingNonSynchronizedList);

    const onReload = useCallback(() => dispatch(fetchInvoicingNonSynchronizedAction()), [dispatch]);
    const onSyncAllInvoicing = useCallback(() => {
        const invoicingNonSyncIds = list?.map((item) => item.id) || [];
        dispatch(putInvoicingNonSynchronizedAction(invoicingNonSyncIds));
    }, [dispatch]);

    return (
        <>
            <NavHeaderComponent
                title={t("TITLES.NON_SYNCHRONIZED")}
                endAction={
                    <>
                        <Button
                            color={ColorType.primary}
                            title={t("NON_SYNCHRONIZED.SYNC_UP_ALL")}
                            handleClick={onSyncAllInvoicing}
                        />
                        <TooltipHost content={i18n.t("common:COMMON.RELOAD")}>
                            <ActionButton
                                iconProps={reloadIcon}
                                onClick={onReload}
                                allowDisabledFocus
                            />
                        </TooltipHost>
                    </>
                }
            />
            <InvoicingNonSynchronizedList />
        </>
    );
};
