import { UserProfileEntityUsersSendSignupContainerStyle } from "./containerStyle.jss";
import React, { useEffect, useMemo } from "react";
import { UserProfileEntityUsersSendSignupEmailsList } from "./list/list";
import { useAppSelector } from "../../../../../../../redux/hooks";

interface IUserProfileEntityUsersSendSignupContainerProps {
    setIsValidForm: (value: any) => any;
}

export const UserProfileEntityUsersSendSignupContainer: React.FC<IUserProfileEntityUsersSendSignupContainerProps> = ({ setIsValidForm }) => {
    const style = UserProfileEntityUsersSendSignupContainerStyle();
    const emails = useAppSelector((state) => state.registeredEmails.registeredMails);

    const isThereInvalidEmails = useMemo(() => {
        return emails.some((email) => !email.isValid);
    }, [emails]);

    useEffect(() => {
        setIsValidForm(isThereInvalidEmails);
    }, [isThereInvalidEmails]);

    return (
        <div className={style.sendSignUpContainer}>
            <div className={style.emailContainer}>
                <UserProfileEntityUsersSendSignupEmailsList emails={emails} />
            </div>
        </div>
    );
};
