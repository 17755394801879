import { createUseStyles } from "react-jss";

export const InfoCalloutStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        fontSize: theme.fonts.sizes.xs,
        alignItems: "start",
        columnGap: 8,

        "& label": {
            color: theme.colors.form.input.default,
            fontSize: theme.fonts.sizes.xs,
            fontWeight: 100,
            fontFamily: theme.fonts.families.siteImportant,
        },
    },
    icon: {
        display: "flex",
        "& i": {
            color: theme.colors.buttons.primary.background,
            cursor: "help",
        },
    },
    message: {
        padding: 16,
        lineHeight: "1.6",
        whiteSpace: "pre-wrap",
        fontSize: theme.fonts.sizes.xs,
        fontWeight: 100,
        fontFamily: theme.fonts.families.siteImportant,
    },
}));
