import { useTranslation } from "react-i18next";
import { IGenericPayload } from "../../../models";
import { exportStatisticsMonthlySummaryAction, fetchStatiticsMonthlySummaryFilterAction } from "../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetMonthlySummaryFilter, updateFilterProperty } from "../../../redux/reducers/statistics/monthlySummary/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsMonthlySummaryList } from "./list/list";

export const StatisticsMonthlySummaryContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.MONTHLY_SUMMARY" });
    const { filter } = useAppSelector((state) => state.statisticsMonthlySummaryFilter);

    const dispatch = useAppDispatch();
    const onExportStatisticsMonthlySummaryAction = () => {
        return dispatch(exportStatisticsMonthlySummaryAction());
    };

    const updateHeaderPropierty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };
    const onApplyFilters = () => {
        return dispatch(fetchStatiticsMonthlySummaryFilterAction());
    };
    const onResetFilters = () => {
        dispatch(resetMonthlySummaryFilter());
        onApplyFilters();
    };

    return (
        <div>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={<ExportDataIcon onExportCSV={onExportStatisticsMonthlySummaryAction} />}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderPropierty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <StatisticsMonthlySummaryList />
        </div>
    );
};
