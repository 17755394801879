import { TermsAndConditionsStyle } from "./termsAndConditionsStyle.jss";

interface ITermsAndConditionsProps {
    fiscalName: string;
    fiscalAddress: string;
}

export const TermsAndConditions = (props: ITermsAndConditionsProps) => {
    const styles = TermsAndConditionsStyle();

    return (
        <>
            <div className={styles.container}>
                <p>
                    Sus datos serán tratados por <b>{props.fiscalName}</b>, con el fin de gestionar su solicitud de registro. 
                    Sus datos son tratados en base al consentimiento prestado mediante el envío de dicho formulario. Sus datos personales no serán cedidos a terceros, 
                    salvo los supuestos previstos en la legislación vigente. Los usuarios cuyos datos sean objeto de tratamiento podrán ejercitar gratuitamente los derechos 
                    de acceso e información, rectificación, cancelación, portabilidad, supresión o, en su caso, oposición de sus datos, en los términos especificados en el 
                    Reglamento General de Protección de Datos de Carácter Personal, conforme al procedimiento legalmente establecido.
                </p>

                <p>
                    Estos derechos podrán ser ejercidos dirigiendo comunicación por escrito, debidamente firmada, acompañada de fotocopia del DNI, a:
                </p>

                <ul>
                    <li>{props.fiscalName}</li>
                    <li>{props.fiscalAddress}</li>
                </ul>

                <p>
                    Los datos de los usuarios cuyos datos son tratados, podrán ser conservados mientras que estos no ejerzan los derechos de cancelación u oposición. 
                    Asimismo, en caso de considerar vulnerado su derecho a la protección de datos personales, podrá interponer una reclamación ante la 
                    Agencia Española de Protección de Datos (www.agpd.es).
                </p>
            </div>
        </>
    );
};
