import { useTheme } from "react-jss";
import { fetchStatiticsCustomerFilterAction } from "../../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsCustomersListStyle } from "./listStyle.jss";
import { useEffect } from "react";
import { StatisticsCustomersListColumn } from "./column/column";
import { DetailsRow, IDetailsFooterProps } from "@fluentui/react";
import { StatisticsCustomersListColumnFooter } from "./column/footer";
import { SkeletonSizes } from "../../../../common/constants";

export const StatisticsCustomersList = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = StatisticsCustomersListStyle({ theme });
    const { customerStatistics, loading } = useAppSelector((state) => state.statiticsCustomerList.data);

    const onRenderColumns = () => {
        return StatisticsCustomersListColumn();
    };
    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };
        const footerColumns = StatisticsCustomersListColumnFooter(dataFooter);

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={footerColumns}
                item={{}}
                itemIndex={5}
                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                selection={detailsFooterProps.selection}
                styles={style}
            />
        );
    };
    useEffect(() => {
        !loading && dispatch(fetchStatiticsCustomerFilterAction());
    }, []);

    return !loading ? (
        <CustomDetailsList
            data={customerStatistics}
            renderColumns={onRenderColumns}
            height={heights.statistics.customers}
            customFooter={_onRenderDetailsFooter}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
