import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IStatisticsOperatorItem } from "../../../../../models/statistics/IOperator";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/statistics/incidenceOperator/list";
import { store } from "../../../../../redux/store";
import { onUpdateSortedColumnUtil, compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { StatisticsIncidenceOrganismColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsIncidenceOrganismListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = StatisticsIncidenceOrganismColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INCIDENCE.OPERATOR.TABLE" });
    const dispatch = useAppDispatch();

    const { sort } = useAppSelector((store) => store.statisticsOperatorIncidenceList.data);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().statisticsOperatorIncidenceList.data.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IStatisticsOperatorItem>("incidenceType"),
            minWidth: 200,
            maxWidth: 240,
            name: t("TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOperatorItem>("incidenceType")}
                    title={t("TYPE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOperatorItem>("incidenceType"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOperatorItem) => <span className={styles.dataCell}>{item.incidenceType ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOperatorItem>("organism"),
            minWidth: 200,
            maxWidth: 300,
            name: t("ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOperatorItem>("organism")}
                    title={t("ORGANISM")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOperatorItem>("organism"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOperatorItem) => <span className={styles.dataCell}>{item.organism ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOperatorItem>("incidences"),
            minWidth: 110,
            maxWidth: 110,
            name: t("NUMBER_INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOperatorItem>("incidences")}
                    title={t("NUMBER_INCIDENCE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOperatorItem>("incidences"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOperatorItem) => <span className={styles.dataCell}>{item.incidences ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
