import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserImportResponse } from "../../../../models/resources/userImport/IUserImport";

interface ICustomerImportState {
    loading: boolean;
    importResponse?: IUserImportResponse;
    templateCustomerImport?: string;
}

const initialState: ICustomerImportState = {
    loading: false,
    importResponse: undefined,
    templateCustomerImport: undefined,
};

export const customerImportSlice = createSlice({
    name: "customerImportSlice",
    initialState,
    reducers: {
        resetCustomerImport: () => initialState,
        setLoadingCustomerImport: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDetailsResponse: (state, action: PayloadAction<IUserImportResponse>) => {
            state.importResponse = action.payload;
        },
        setUriTemplateCustomerImport: (state, action: PayloadAction<string>) => {
            state.templateCustomerImport = action.payload;
        },
    },
});

export const { resetCustomerImport, setLoadingCustomerImport, setDetailsResponse, setUriTemplateCustomerImport } = customerImportSlice.actions;

export default customerImportSlice.reducer;
