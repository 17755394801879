import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterCustomsStatesFormController } from "../controller/controller";
import { MasterCustomsStatesColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/constants";
import { fetchMasterCustomsStatesList } from "../../../../../../redux/actions/resources/master/customsStates/customsStates";
interface IMasterCustomsStatesList {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterCustomsStatesList: React.FC<IMasterCustomsStatesList> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { loading, list } = useAppSelector((state) => state.masterCustomsStatesList);

    const renderColumns = () => {
        return MasterCustomsStatesColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterCustomsStatesList());
    }, [list]);

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 9rem)"}
                ></CustomDetailsList>
            )}
            <MasterCustomsStatesFormController
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </div>
    );
};
