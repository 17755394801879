import { LocalStorageKey, LocalStorageService } from "../../../../services/localStorageService";
import { AppThunk, store } from "../../../store";
import { fetchRequestListAction } from "../list/requestList";

export const onApplyFilterAction = (): AppThunk => async (dispatch) => {
    const localStorageService = new LocalStorageService();

    localStorageService.setItem(LocalStorageKey.REQUEST_FILTER, store.getState().requestListFilter.filter);
    localStorageService.setItem(LocalStorageKey.REQUEST_FILTER_START_DATE, store.getState().requestListFilter.startDate);
    localStorageService.setItem(LocalStorageKey.REQUEST_FILTER_END_DATE, store.getState().requestListFilter.endDate);
    dispatch(fetchRequestListAction());
};
