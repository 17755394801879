import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { IGenericPayload, IKeyDescription } from "../../../../models";
import { IIncidenceTypesFilter } from "../../../../models/management/incidenceType/IIncidenceType";
import { fetchOrganisms } from "../../../../redux/actions/catalog/catalog";
import { fetchManagementIncidencesTypesAction } from "../../../../redux/actions/management/incidenceTypes/incidenceTypes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    resetManagementIncidenceTypeFilter,
    updateManagementIncidenceTypeFilterProperty,
} from "../../../../redux/reducers/management/incidenceType/filter";
import { compileNameOfProperty, mapIDescriptionToDropDownOptions } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { BooleaMultiSelector } from "../../../common/selectors";
import { CustomSelector } from "../../../common/selectors/customSelector/customSelector";
import { MultipleOrganismSelector } from "../../../request/common/selectors/organism/multipleOrganism";
import { ManagementIncidenceFilterStyle } from "./filterStyle.jss";
import { IncidenceType } from "../../../../common/enum/IncidenceTypes";
interface IManagementIncidenceTypesFilterProps {
    isOrganismUser: boolean;
}
export const ManagementIncidenceTypesFilter: React.FC<IManagementIncidenceTypesFilterProps> = ({ isOrganismUser }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ManagementIncidenceFilterStyle({ theme });

    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.INCIDENCE_TYPE" });
    const { filter } = useAppSelector((state) => state.managementIncidenceTypesFilter);
    const { data, loading } = useAppSelector((store) => store.catalog.organisms);
    const { profile } = useAppSelector((state) => state.userData);
    const incidencesTypes: IKeyDescription[] = [
        { id: 1, description: t("CONTEXTUAL_MENU.OPERATIONAL_INCIDENCE") },
        { id: 2, description: t("CONTEXTUAL_MENU.CHARGE_INCIDENCE") },
    ];
    const onApply = () => {
        dispatch(fetchManagementIncidencesTypesAction());
    };
    const onReset = () => {
        dispatch(resetManagementIncidenceTypeFilter());
        updateFilterOrganism();
        onApply();
    };

    const updateFilterOrganism = () => {
        if (profile.organismId) {
            dispatch(
                updateManagementIncidenceTypeFilterProperty({
                    name: compileNameOfProperty<IIncidenceTypesFilter>("organismIds"),
                    value: [profile.organismId],
                }),
            );
        }
        if (isOrganismUser) {
            dispatch(
                updateManagementIncidenceTypeFilterProperty({
                    name: compileNameOfProperty<IIncidenceTypesFilter>("incidenceType"),
                    value: 1,
                }),
            );
        }
    };

    const onMultipleNumberKeysChange = useCallback(
        (name: string, numberOptions: number[]) => {
            const payload: IGenericPayload = {
                name,
                value: numberOptions,
            };

            dispatch(updateManagementIncidenceTypeFilterProperty(payload));
        },
        [dispatch],
    );

    const onBooleanSelectorChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateManagementIncidenceTypeFilterProperty(payload));
        },
        [dispatch],
    );
    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            const payload: IGenericPayload = {
                name,
                value: event.key,
            };
            dispatch(updateManagementIncidenceTypeFilterProperty(payload));
        },
        [dispatch],
    );

    useEffect(() => {
        updateFilterOrganism();
    }, []);

    const FiltersItems = (
        <div className={style.filterContainer}>
            <div className={style.item}>
                <CustomSelector
                    onChange={onSelectorChange}
                    selectedKey={filter.incidenceType ? filter.incidenceType : DefaultValues.DEFAULT_ALL_SELECTOR}
                    options={mapIDescriptionToDropDownOptions(incidencesTypes)}
                    title={t("TABLE.INCIDENCE_TYPE")}
                    name={compileNameOfProperty<IIncidenceTypesFilter>("incidenceType")}
                    disabled={isOrganismUser}
                />
            </div>

            <div className={style.item}>
                <MultipleOrganismSelector
                    selectedKeys={filter.organismIds}
                    propertyName={compileNameOfProperty<IIncidenceTypesFilter>("organismIds")}
                    title={t("TABLE.ORGANISM")}
                    onChange={onMultipleNumberKeysChange}
                    disabled={filter.incidenceType === Number(IncidenceType.CHARGE) || profile.organismId ? true : false}
                    autocomplete={false}
                />
            </div>

            <div className={style.item}>
                <BooleaMultiSelector
                    selectedKey={filter.customerNotice}
                    propertyName={compileNameOfProperty<IIncidenceTypesFilter>("customerNotice")}
                    title={t("TABLE.WARNING")}
                    onChange={onBooleanSelectorChange}
                />
            </div>
        </div>
    );

    useEffect(() => {
        data.length === 0 && !loading && dispatch(fetchOrganisms());
    }, [dispatch, data]);

    useEffect(() => {
        if (filter.incidenceType === Number(IncidenceType.CHARGE)) {
            onMultipleNumberKeysChange(compileNameOfProperty<IIncidenceTypesFilter>("organismIds"), [-1]);
        }
    }, [filter.incidenceType]);

    return (
        <FilterHeader
            onApply={onApply}
            content={FiltersItems}
            onReset={onReset}
        />
    );
};
