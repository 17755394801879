import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RoleType } from "../../../../../../../common/enum";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../../common/enum/PopUpConfirmationType";
import { ModuleTypes } from "../../../../../../../common/enum/me/ModuleTypes";
import { useAppointmentPermission } from "../../../../../../../hooks/request/useAppointmentPermission";
import { useEditRequestSelector } from "../../../../../../../hooks/request/useEditRequestLogic";
import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { IEditRequest } from "../../../../../../../models/requests/IEditRequest";
import { ICheckAssignAppointmentRequest } from "../../../../../../../models/requests/editRequest/IAppointment";
import { checkAssignAppointmentRequest, postAppointmentAssignAction } from "../../../../../../../redux/actions/appointments/appointments";
import { getAvailableDatesRequestAction } from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import {
    resetAppointmentAvailable,
    setAppointmentSelectionToAssign,
    setAppointmentSelectionZone,
    setForceAppointmentType,
} from "../../../../../../../redux/reducers/appointment/zoneAvailable";
import { setGlobalLoading } from "../../../../../../../redux/reducers/common/globalLoading";
import { updateAppoitmentsZones, updateRequestHeaderProperty } from "../../../../../../../redux/reducers/request/edit/editRequest";
import { resetAppointmentFormDataState, setEditRequestAppointmentShowForm } from "../../../../../../../redux/reducers/request/edit/form/appointment";
import { store } from "../../../../../../../redux/store";
import MeModuleChecker from "../../../../../../../utils/me/modules";
import { PopUpConfirmation } from "../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { Toastr } from "../../../../../../common/toast/toast";
import { EditRequestAppointmentContainer } from "./container/container";
import { EditRequestAppointmentForm } from "./container/form/form";
import { mapCompareRequestValues } from "./utils";

interface IEditReuestAppointmentControllerProps {
    schema: IConfigEditRequest;
    disabledAppointmentData: boolean;
    invalidRequestForm: boolean;
}

export const EditRequestAppointmentController: React.FC<IEditReuestAppointmentControllerProps> = ({
    schema,
    disabledAppointmentData,
    invalidRequestForm,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const {
        editRequest: { headerRequest, copyHeaderRequest, isLoadedRequest },
        editRequestAppointment: { showPopUp, loading, form },
        appointmentAvailableZone: {
            selectedToAssign,
            available: { timeZones },
        },
    } = useAppSelector((state) => state);

    useAppointmentPermission();

    const { currentUserRole } = useEditRequestSelector();

    const timeZone = useMemo(() => {
        if (!timeZones) {
            return null;
        }
        return timeZones?.find((t) => t.id === selectedToAssign?.timeZoneId);
    }, [selectedToAssign, timeZones]);

    const disabledCreateAppointmentButtonForm = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return !form.timeZoneId || !form.timeZoneId || loading ? true : false;
        }

        return loading;
    }, [form, loading, showPopUp]);

    const isChangedRequest: boolean = useMemo(() => {
        return isEqual(mapCompareRequestValues(headerRequest), mapCompareRequestValues(copyHeaderRequest));
    }, [
        headerRequest.date,
        headerRequest.fumigated,
        headerRequest.halalCertified,
        headerRequest.humanConsumption,
        headerRequest.merchandiseCategoryId,
        headerRequest.merchandiseTypeId,
        headerRequest.organicProduct,
        headerRequest.transportUnitId,
        headerRequest.appointmentTypeId,
        headerRequest.turnId,
        headerRequest.transportUnitSizeId,
    ]);

    const onAcceptPopUp = () => {
        if (selectedToAssign) {
            if (showPopUp === PopUpCodes.WARNING) {
                dispatch(setGlobalLoading(true));
            }

            if (
                MeModuleChecker.hasActiveModule(store, ModuleTypes.APPOINTMENT) &&
                headerRequest.appointmentAssigned &&
                (currentUserRole === RoleType.CUSTOMER_ADMIN || currentUserRole === RoleType.CUSTOMER_USER)
            ) {
                if (headerRequest.date && headerRequest.transportUnitId && headerRequest.transportUnitSizeId && headerRequest.appointmentAssigned) {
                    const data: ICheckAssignAppointmentRequest = {
                        date: dayjs(headerRequest.date?.toISOString()).format("YYYY-MM-DD"),
                        hasAppointment: headerRequest.appointmentAssigned,
                        merchandiseCategoryId: headerRequest.merchandiseCategoryId,
                        transportUnitId: headerRequest.transportUnitId,
                        transportUnitSizeId: headerRequest.transportUnitSizeId,
                    };
                    dispatch(checkAssignAppointmentRequest(data)).then((response: any) => {
                        if (response.message === null) {
                            dispatch(postAppointmentAssignAction(selectedToAssign, headerRequest)).then((response: any) => {
                                response && onCancelPopUp();
                                if (showPopUp === PopUpCodes.WARNING) {
                                    dispatch(setGlobalLoading(false));
                                }
                                dispatch(setForceAppointmentType(false));
                            });
                        } else {
                            Toastr(response.message).error();
                            let newRequest: IEditRequest = {
                                ...headerRequest,
                                date: new Date(response.date),
                            };

                            dispatch(updateRequestHeaderProperty(newRequest));
                            dispatch(resetAppointmentAvailable());
                            dispatch(
                                getAvailableDatesRequestAction({
                                    hasAppointment: headerRequest.appointmentAssigned ?? false,
                                    merchandiseCategoryId: headerRequest.merchandiseCategoryId?.toString() ?? "",
                                    transportUnitId: headerRequest.transportUnitId?.toString() ?? "",
                                    transportUnitSizeId: headerRequest.transportUnitSizeId?.toString() ?? "",
                                }),
                            );
                            onCancelPopUp();
                        }
                    });
                }
                dispatch(setGlobalLoading(false));
                return;
            }

            dispatch(postAppointmentAssignAction(selectedToAssign, headerRequest)).then((response: any) => {
                response && onCancelPopUp();
                if (showPopUp === PopUpCodes.WARNING) {
                    dispatch(setGlobalLoading(false));
                }
                dispatch(setForceAppointmentType(false));
            });
        }
    };

    const onCancelPopUp = () => {
        dispatch(setEditRequestAppointmentShowForm(PopUpCodes.NONE));
        dispatch(resetAppointmentFormDataState());
        dispatch(setAppointmentSelectionToAssign(null));
        dispatch(setAppointmentSelectionZone(undefined));
        dispatch(setForceAppointmentType(false));
    };

    useEffect(() => {
        if (isLoadedRequest && !isChangedRequest) {
            dispatch(updateAppoitmentsZones(null));
        }
    }, [headerRequest, isChangedRequest]);

    return (
        <>
            <EditRequestAppointmentContainer
                schema={schema}
                disabledAppointmentData={disabledAppointmentData}
                isEqualsRequest={isChangedRequest}
                invalidRequestForm={invalidRequestForm}
            />
            {!headerRequest.appointmentAssigned && (
                <PopUpForm
                    content={
                        <EditRequestAppointmentForm
                            schema={schema}
                            disabledAppointmentData={false}
                            hiddenActionsAppointment={true}
                            invalidRequestForm={false}
                        />
                    }
                    title={t("EDIT_REQUEST.APPOINTMENT.CREATE")}
                    isVisible={showPopUp === PopUpCodes.CREATE}
                    isDisabledButton={disabledCreateAppointmentButtonForm}
                    onCancel={onCancelPopUp}
                    onSave={onAcceptPopUp}
                    isLoading={loading}
                    height={"calc(30vh - 1rem)"}
                    width={"calc(50vw - 5rem)"}
                />
            )}
            {invalidRequestForm ? (
                <PopUpConfirmation
                    onAccept={onAcceptPopUp}
                    onCancel={onCancelPopUp}
                    showPopUp={showPopUp === PopUpCodes.WARNING}
                    type={PopUpConfirmationType.WARNING}
                    height={"12vh"}
                    loading={disabledCreateAppointmentButtonForm}
                    message={t("EDIT_REQUEST.APPOINTMENT.INVALID_REQUEST")}
                    disabledActions={disabledCreateAppointmentButtonForm}
                />
            ) : (
                <PopUpConfirmation
                    onAccept={onAcceptPopUp}
                    onCancel={onCancelPopUp}
                    showPopUp={showPopUp === PopUpCodes.WARNING}
                    buttonAcceptTitle={t("EDIT_REQUEST.APPOINTMENT.ACCEPT")}
                    buttonCancelTitle={t("EDIT_REQUEST.APPOINTMENT.CANCEL_APPOINTMENT")}
                    type={PopUpConfirmationType.INFO}
                    height={"12vh"}
                    loading={disabledCreateAppointmentButtonForm}
                    message={t("EDIT_REQUEST.APPOINTMENT.INFO_MESSAGE", {
                        start: timeZone?.startTime,
                        end: timeZone?.endTime,
                        description: timeZone?.description,
                    })}
                    disabledActions={disabledCreateAppointmentButtonForm}
                />
            )}
        </>
    );
};
