import React from "react";
import { store } from "../../../redux/store";
import { ManagementUserContainer } from "./container";
import { resetManagementUser } from "../../../redux/reducers/management/user/form";
import { managementUserFilterReset } from "../../../redux/reducers/management/user/filter";
import { resetManagementUserList } from "../../../redux/reducers/management/user/list";

export class ManagementUserController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetManagementUser());
        store.dispatch(managementUserFilterReset());
        store.dispatch(resetManagementUserList());
    }

    render(): React.ReactNode {
        return <ManagementUserContainer />;
    }
}
