import { FormFieldTextarea } from "../../../common/fields/fields";
import { RejectionReasonStyle } from "./rejectionReason.jss";
import { useTranslation } from "react-i18next";

interface IRejectionReasonProps {
    reason: string;
    handleChange: (e: any) => any;
}

export const RejectionReason = (props: IRejectionReasonProps) => {
    const style = RejectionReasonStyle();
    const { t } = useTranslation("common");

    return (
        <div className={style.rejectionContainer}>
            <div className={style.reasonContainer}>
                <FormFieldTextarea
                    rows={4}
                    name={"Reason"}
                    label={t("USER.CONFIRMATION.REASON")}
                    value={props.reason}
                    onChange={props.handleChange}
                />
            </div>
        </div>
    );
};
