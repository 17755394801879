import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { regex } from "../../../../../../common/constants";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { DefaultValues } from "../../../../../../common/enum/DefaultValues";
import { DirectionType } from "../../../../../../common/enum/DirectionType";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { SealTypes } from "../../../../../../common/enum/SealType";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { RequestState } from "../../../../../../common/enum/request/RequestState";
import { useContainer } from "../../../../../../hooks/request/useContainer";
import { IGenericPayload } from "../../../../../../models";
import { Appointment, IEditRequest, ISeal } from "../../../../../../models/requests/IEditRequest";
import { IEditRequestStepProps } from "../../../../../../models/requests/request/IEditRequestStepProps";
import { getAvailableDatesRequestAction, updateRequestFormData } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { pushSealToList, resetSealProperty, resetSeals } from "../../../../../../redux/reducers/request/edit/editRequest";
import { widths } from "../../../../../../styles/romeu/widths";
import { compileNameOfProperty, requiredMessage } from "../../../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { ControlledDatePicker } from "../../../../../common/datePicker/controlledDatePicker";
import { DateTimePickerComponent } from "../../../../../common/dateTimePicker/dateTimePicker";
import { FormField } from "../../../../../common/fields/fields";
import { FormFieldCustom } from "../../../../../common/fields/fieldsCustom";
import { LabelTime } from "../../../../../common/labels/labelTime/labelTime";
import { FormNumberField } from "../../../../../common/numberField/numberField";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { ControlledRadioButtonGroup } from "../../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { CustomerSelector } from "../../../../../common/selectors/controllerSelectors/customerSelector/customer";
import { Separator } from "../../../../../common/separator/separator";
import { TitleH5 } from "../../../../../common/titles/titles";
import { RegCheckTemp } from "../../../../common/checkBox/regTempCheck";
import { ContainerSizeSelector, InvoiceCustomerSelector } from "../../../../common/selectors";
import { CustomStatesSelector } from "../../../../common/selectors/customStates/customState";
import { TransportUnitSelector } from "../../../../common/selectors/transportUnit/transportUnit";
import { TurnSelector } from "../../../../common/selectors/turn/turn";
import { requestMaxLength } from "../../../create/validation/requestConfig";
import { booleanValidation, renderRequiredData } from "../../../create/validation/requestValidationUtils";
import { OriginSeals } from "../../components/seals/form";
import { Seal } from "../../components/seals/seal/seal";
import { GeneralInformationEditContainerStyle } from "../containerStyle.jss";
import { EditRequestAppointmentController } from "./customAppointment/controller";
import { CustomToogle } from "../../../../../common/toogle/toogle";

interface IUtiDataProps extends IEditRequestStepProps {
    validcustomsAuthorityArrivalOn: string;
    validrepresentativeArrivalOn: string;
    validreadyForInstructionOn: string;
}

export const UtiData: React.FC<IUtiDataProps> = ({
    schema,
    onInputChange,
    onSelectorChange,
    resetPropertyWhenParentChange,
    onCheckBoxChange,
    onRadioButtonChange,
    onNumberChange,
    control,
    errors,
    register,
    resetField,
    setValue,
    trigger,
    organization,
    disabledAppointmentData,
    validcustomsAuthorityArrivalOn,
    validreadyForInstructionOn,
    validrepresentativeArrivalOn,
    invalidRequestForm,
}) => {
    const theme = useTheme();
    const style = GeneralInformationEditContainerStyle({ theme });
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const { headerRequest, seals, availableDates } = useAppSelector((store) => store.editRequest);
    const isContainerRequest = useContainer(headerRequest.transportUnitId);
    const { register: registerAppointment } = useForm<Appointment>();
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });
    const [showSealFormPopUp, setShowSealFormPopUp] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [resetCustomsAuthorityArrivalOn, setResetCustomsAuthorityArrivalOn] = useState<boolean>(false);
    const [resetRepresentativeArrivalOn, setResetRepresentativeArrivalOn] = useState<boolean>(false);
    const [resetReadyForInstructionOn, setResetReadyForInstructionOn] = useState<boolean>(false);

    const { minDate, maxDate, restrictedDates } = availableDates
        ? {
              minDate: availableDates.minDate ? new Date(availableDates.minDate) : undefined,
              maxDate: availableDates.maxDate ? new Date(availableDates.maxDate) : undefined,
              restrictedDates: availableDates.restrictedDates?.map((item) => new Date(item)) || [],
          }
        : {
              minDate: undefined,
              maxDate: undefined,
              restrictedDates: [],
          };

    useEffect(() => {
        if (!availableDates) {
            dispatch(
                getAvailableDatesRequestAction({
                    hasAppointment: headerRequest.appointmentAssigned ?? false,
                    merchandiseCategoryId: headerRequest.merchandiseCategoryId?.toString() ?? "",
                    transportUnitId: headerRequest.transportUnitId?.toString() ?? "",
                    transportUnitSizeId: headerRequest.transportUnitSizeId?.toString() ?? "",
                }),
            );
        }
    }, [
        availableDates,
        headerRequest.appointmentAssigned,
        headerRequest.merchandiseCategoryId,
        headerRequest.transportUnitId,
        headerRequest.transportUnitSizeId,
    ]);

    const onCancelSealFormPopUp = () => {
        dispatch(resetSeals());
        setShowSealFormPopUp(false);
        dispatch(resetSealProperty());
    };
    const onSaveSealFormPopUp = () => {
        dispatch(pushSealToList(seals));
        onCancelSealFormPopUp();
    };

    const isContainer = useMemo(() => {
        if (isLoaded && resetField) {
            resetField("transportUnitNumber");
            setValue("transportUnitNumber", headerRequest.transportUnitNumber);
        }
        setIsLoaded(true);
        return isContainerRequest;
    }, [isContainerRequest]);

    const showAppointmentContent = useMemo(() => {
        let value = true;

        if (!schema.appointmentCreate.visible && !headerRequest.appointmentAssigned) {
            value = false;
        }
        return value;
    }, [headerRequest.appointment, schema]);

    const pattern = useMemo(() => {
        return isContainer
            ? {
                  value: regex.EQUALS_CONTAINER_PLATE,
                  message: t("FORM.VALIDATION.CONTAINER"),
              }
            : {
                  value: regex.NOT_EQUALS_CONTAINER_PLATE,
                  message: t("FORM.VALIDATION.NOT_CONTAINER"),
              };
    }, [isContainer]);

    const titleSection = (title: string) => {
        return (
            <TitleH5
                title={title}
                color={ColorType.primary}
                bold={true}
            />
        );
    };

    const removeDate = (propertyName: string) => {
        dispatch(updateRequestFormData({ name: propertyName, value: null }));
    };

    const sealsRequest = useMemo(() => {
        if (headerRequest.seals.filter((seal) => seal.typeId === SealTypes.ORIGIN).length === 0) {
            return <div>{t("EDIT_REQUEST.FIELDS.NOT_DATA")}</div>;
        }

        return headerRequest.seals.map((seal: ISeal, index) => {
            if (seal.typeId === SealTypes.ORIGIN) {
                return (
                    <Seal
                        seal={seal}
                        key={`${OriginSeals.name}-${index}`}
                        index={index}
                        newSeal={false}
                        isOriginalSeal={true}
                        isRemoveSeal={schema.originSeal.readonly}
                    />
                );
            }
        });
    }, [headerRequest.seals]);

    const updateRequestHeader = (propertyName: string, value: any) => {
        const payload: IGenericPayload = {
            name: propertyName,
            value,
        };

        dispatch(updateRequestFormData(payload));
    };

    const onDateChange = (date: Date | undefined | null, propertyName: string) => {
        if (date) {
            date.setSeconds(0);
            date.setMilliseconds(0);
            updateRequestHeader(propertyName, date);
        }
    };
    return (
        <div className={style.container}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={`${GridClass.ROW_GRID}  ${!schema.dockPriority.visible ? style.row : ""}`}>
                    {schema.transportUnitId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <TransportUnitSelector
                                onChange={onSelectorChange}
                                selectedKey={headerRequest.transportUnitId!}
                                propertyName={compileNameOfProperty<IEditRequest>("transportUnitId")}
                                title={t("EDIT_REQUEST.FIELDS.TRANSPORTUNITDESCRIPTION")}
                                disabled={schema.transportUnitId.readonly || disabledAppointmentData}
                                control={control}
                                rules={{ required: renderRequiredData(schema.transportUnitId.required) }}
                                isRequired={schema.transportUnitId.required}
                            />
                        </div>
                    )}
                    {schema.transportUnitId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <ControlledDatePicker
                                name={compileNameOfProperty<IEditRequest>("date")}
                                label={t("EDIT_REQUEST.FIELDS.DATE")}
                                onDateChange={onDateChange}
                                initValue={headerRequest.date}
                                minDate={headerRequest.appointmentAssigned ? new Date() : minDate}
                                required={schema.date.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.date.required) }}
                                disabled={schema.date.readonly}
                                maxDate={maxDate}
                                calendarProps={{
                                    restrictedDates: restrictedDates,
                                }}
                            />
                        </div>
                    )}

                    {schema.turnId.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <TurnSelector
                                onChange={onSelectorChange}
                                selectedKey={headerRequest.turnId}
                                propertyName={compileNameOfProperty<IEditRequest>("turnId")}
                                title={t("EDIT_REQUEST.FIELDS.TURNDESCRIPTION")}
                                control={control}
                                rules={{ required: renderRequiredData(schema.turnId.required) }}
                                setValue={setValue}
                                isRequired={schema.turnId.required}
                                disabled={schema.turnId.readonly || disabledAppointmentData}
                            />
                        </div>
                    )}
                </div>
                {schema.dockPriority.visible && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        <div className={GridClass.FULL_CELL}>
                            <CustomToogle
                                checked={headerRequest.dockPriority}
                                onChage={() => updateRequestHeader("dockPriority", !headerRequest.dockPriority)}
                                inlineLabel={true}
                                label={t("EDIT_REQUEST.FIELDS.PRIORITY")}
                                disabled={schema.dockPriority.readonly}
                                customContainerStyle={style.toogleContainer}
                            />
                        </div>
                    </div>
                )}

                {schema.appointmentTimeZoneId.visible && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        <div className={GridClass.THREE_CELL}>
                            <FormField
                                type="text"
                                value={headerRequest.appointment?.timeZoneDescription || DefaultValues.EMPTY}
                                label={t("EDIT_REQUEST.FIELDS.APPOINTMENTTIMEZONEDESCRIPTION")}
                                isRequired={schema.appointmentTimeZoneId.required}
                                disabled={schema.appointmentTimeZoneId.readonly}
                                {...registerAppointment("timeZoneDescription", {
                                    onChange: onInputChange,
                                    value: headerRequest.appointment?.timeZoneDescription,
                                })}
                                autocomplete="off"
                            />
                        </div>
                    </div>
                )}
                {schema.appointmentTypeId.visible && schema.appointmentTimeZones.visible && showAppointmentContent && (
                    <EditRequestAppointmentController
                        schema={schema}
                        disabledAppointmentData={disabledAppointmentData}
                        invalidRequestForm={invalidRequestForm}
                    />
                )}

                {titleSection(t("EDIT_REQUEST.TITLES.CUSTOMER_INFO"))}
                <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                    {schema.customerId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            {schema.customerId.readonly ? (
                                <FormFieldCustom
                                    type="text"
                                    value={headerRequest.customerEntityName || ""}
                                    label={t("EDIT_REQUEST.FIELDS.CUSTOMERID")}
                                    disabled={true}
                                    readonly={true}
                                    name={compileNameOfProperty<IEditRequest>("customerId")}
                                    onChange={() => null}
                                />
                            ) : (
                                <CustomerSelector
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.customerId}
                                    title={t("EDIT_REQUEST.FIELDS.CUSTOMERID")}
                                    propertyName={compileNameOfProperty<IEditRequest>("customerId")}
                                    isRequired={schema.customerId.required}
                                    control={control}
                                    rules={{ required: renderRequiredData(true) }}
                                    setValue={setValue}
                                    disabled={schema.customerId.readonly}
                                    filteredRegisteredCustomer={!schema.customerId.readonly}
                                />
                            )}
                        </div>
                    )}

                    {schema.invoiceCustomerId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <InvoiceCustomerSelector
                                onChange={onSelectorChange}
                                selectedKey={headerRequest.invoiceCustomerId ? headerRequest.invoiceCustomerId : undefined}
                                title={t("EDIT_REQUEST.FIELDS.INVOICECUSTOMERID")}
                                propertyName={compileNameOfProperty<IEditRequest>("invoiceCustomerId")}
                                customerId={headerRequest.customerId}
                                resetSelectorAtRedux={resetPropertyWhenParentChange}
                                isRequired={schema.invoiceCustomerId.required}
                                disabled={schema.invoiceCustomerId.readonly}
                                control={control}
                                rules={{
                                    required: renderRequiredData(schema.invoiceCustomerId.required),
                                    validate: () => (schema.invoiceCustomerId.required ? !!headerRequest.invoiceCustomerId : true),
                                }}
                                setValue={setValue}
                                trigger={trigger}
                                onLoadPromise={true}
                            />
                        </div>
                    )}
                </div>

                {(schema.customerReference.visible || schema.booking.visible || schema.customsStateId.visible || schema.temporaryRegime.visible) && (
                    <>
                        {titleSection(t("EDIT_REQUEST.TITLES.EXPEDITION_INFO"))}
                        <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                            {schema.customerReference.visible && (
                                <div className={GridClass.SIMPLE_CELL}>
                                    <FormField
                                        type="text"
                                        value={headerRequest.customerReference || ""}
                                        maxLength={requestMaxLength.CUSTOMER_REFERENCE}
                                        label={t("EDIT_REQUEST.FIELDS.CUSTOMERREFERENCE")}
                                        isRequired={schema.customerReference.required}
                                        disabled={schema.customerReference.readonly}
                                        error={errors.customerReference}
                                        {...register(compileNameOfProperty<IEditRequest>("customerReference"), {
                                            onChange: onInputChange,
                                            value: headerRequest.customerReference,
                                            required: schema.customerReference.required ? requiredMessage : false,
                                        })}
                                        autocomplete="off"
                                    />
                                </div>
                            )}
                        </div>
                        <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                            {schema.booking.visible && (
                                <div className={GridClass.THREE_CELL}>
                                    <FormField
                                        type="text"
                                        value={headerRequest.booking || ""}
                                        maxLength={requestMaxLength.BOOKING}
                                        label={t("EDIT_REQUEST.FIELDS.BOOKING")}
                                        isRequired={schema.booking.required}
                                        disabled={schema.booking.readonly}
                                        error={errors.booking}
                                        {...register(compileNameOfProperty<IEditRequest>("booking"), {
                                            onChange: onInputChange,
                                            required: renderRequiredData(schema.booking.required),
                                        })}
                                    />
                                </div>
                            )}

                            {schema.customsStateId.visible && (
                                <div className={GridClass.THREE_CELL}>
                                    <CustomStatesSelector
                                        onChange={onSelectorChange}
                                        selectedKey={headerRequest.customsStateId}
                                        propertyName={compileNameOfProperty<IEditRequest>("customsStateId")}
                                        title={t("EDIT_REQUEST.FIELDS.CUSTOMSSTATEDESCRIPTION")}
                                        isRequired={schema.customsStateId.required}
                                        disabled={schema.customsStateId.readonly}
                                        control={control}
                                        rules={{ required: renderRequiredData(schema.customsStateId.required) }}
                                    />
                                </div>
                            )}

                            {schema.temporaryRegime.visible && (
                                <div className={GridClass.THREE_CELL}>
                                    <RegCheckTemp
                                        name={compileNameOfProperty<IEditRequest>("temporaryRegime")}
                                        label={t("EDIT_REQUEST.FIELDS.TEMPORARYREGIME")}
                                        isChecked={headerRequest.temporaryRegime}
                                        handleChange={onCheckBoxChange}
                                        selectedCustomState={headerRequest.customsStateId}
                                        isCreateRequest={false}
                                        disabled={schema.temporaryRegime.readonly}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}

                {titleSection(t("EDIT_REQUEST.TITLES.UTI_SECTION"))}
                <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                    {schema.transportUnitSizeId.visible && isContainer && (
                        <div className={GridClass.THREE_CELL}>
                            <ContainerSizeSelector
                                onChange={onSelectorChange}
                                selectedKey={headerRequest.transportUnitSizeId!}
                                propertyName={compileNameOfProperty<IEditRequest>("transportUnitSizeId")}
                                disabled={schema.transportUnitSizeId.readonly || disabledAppointmentData}
                                title={t("EDIT_REQUEST.FIELDS.TRANSPORTUNITSIZEDESCRIPTION")}
                                isRequired={schema.transportUnitSizeId.required}
                                control={control}
                                rules={{ required: isContainer ? requiredMessage : false }}
                            />
                        </div>
                    )}

                    {schema.transportUnitNumber.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <FormField
                                autocomplete="off"
                                type="text"
                                value={headerRequest.transportUnitNumber || ""}
                                maxLength={requestMaxLength.TRANSPORT_UNIT_NUMBER}
                                label={t("EDIT_REQUEST.FIELDS.TRANSPORTUNITNUMBER")}
                                {...register(compileNameOfProperty<IEditRequest>("transportUnitNumber"), {
                                    onChange: onInputChange,
                                    value: headerRequest.transportUnitNumber,

                                    required: renderRequiredData(schema.transportUnitNumber.required),
                                    validate: {
                                        isRequired: (value: any) => schema.transportUnitNumber.required,
                                        hasInvalidChars: (value: any) => (regex.ONLY_NUMBERS_AND_LETTERS.test(value) ? true : false),
                                    },

                                    pattern: pattern,
                                })}
                                error={errors.transportUnitNumber}
                                isRequired={schema.transportUnitNumber.required}
                                disabled={schema.transportUnitNumber.readonly}
                            />
                        </div>
                    )}
                    {schema.tractorNumber.visible && (
                        <div className={GridClass.THREE_CELL}>
                            <FormField
                                type="text"
                                value={headerRequest.tractorNumber}
                                maxLength={requestMaxLength.TRACTOR_NUMBER}
                                label={t("EDIT_REQUEST.FIELDS.TRACTORNUMBER")}
                                {...register(compileNameOfProperty<IEditRequest>("tractorNumber"), {
                                    onChange: onInputChange,
                                    value: headerRequest.tractorNumber,
                                    required: schema.tractorNumber.required,
                                })}
                                isRequired={schema.tractorNumber.required}
                                disabled={schema.tractorNumber.readonly}
                            />
                        </div>
                    )}
                </div>
                {(schema.packages.visible || schema.grossWeight.visible || schema.palletized.visible) && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.packages.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FormNumberField
                                    value={headerRequest.packages ? String(headerRequest.packages) : ""}
                                    maxLength={requestMaxLength.PACKAGES}
                                    label={t("EDIT_REQUEST.FIELDS.PACKAGES")}
                                    isRequired={headerRequest?.stateId === RequestState.CANCELED ? false : schema.packages.required}
                                    disabled={schema.packages.readonly}
                                    {...register(compileNameOfProperty<IEditRequest>("packages"), {
                                        onChange: onNumberChange,
                                        value: headerRequest.packages,
                                        validate: {
                                            isRequired: (value: any) => (schema.packages.required ? value && Number(value) > 0 : false),
                                        },
                                        required: schema.packages.required ? requiredMessage : false,
                                    })}
                                    nonZeroValue={true}
                                />
                            </div>
                        )}
                        {schema.grossWeight.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FormNumberField
                                    value={headerRequest.grossWeight ? String(headerRequest.grossWeight) : ""}
                                    maxLength={requestMaxLength.GROSS_WEIGHT}
                                    label={t("EDIT_REQUEST.FIELDS.GROSSWEIGHT")}
                                    isRequired={headerRequest?.stateId === RequestState.CANCELED ? false : schema.grossWeight.required}
                                    disabled={schema.grossWeight.readonly}
                                    decimalNumber={3}
                                    {...register(compileNameOfProperty<IEditRequest>("grossWeight"), {
                                        onChange: onNumberChange,
                                        value: headerRequest.grossWeight,
                                        required: schema.grossWeight.required,
                                        validate: {
                                            isRequired: (value: any) => (schema.grossWeight.required ? value && Number(value) > 0 : false),
                                        },
                                    })}
                                    nonZeroValue={true}
                                />
                            </div>
                        )}
                        {schema.palletized.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <ControlledRadioButtonGroup
                                    label={t("EDIT_REQUEST.FIELDS.PALLETIZED")}
                                    selectedKey={headerRequest?.palletized !== null ? String(+headerRequest?.palletized) : undefined}
                                    onRadioButtonChange={onRadioButtonChange}
                                    required={schema.palletized.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.palletized.required),
                                        validate: () => booleanValidation(schema.palletized.required, headerRequest.palletized),
                                    }}
                                    direction={DirectionType.horizontal}
                                    name={compileNameOfProperty<IEditRequest>("palletized")}
                                    disabled={schema.palletized.readonly}
                                />
                            </div>
                        )}
                    </div>
                )}
                {schema.originSeal.visible && (
                    <>
                        <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                            <div className={GridClass.SIMPLE_CELL}>
                                <div className={style.titleSeal}>
                                    <TitleH5
                                        title={t("EDIT_REQUEST.TITLES.SEALS_SECTION_ORIGIN")}
                                        color={ColorType.primary}
                                        bold={true}
                                        isRequired={schema.originSeal.required}
                                    />
                                    {!schema.originSeal.readonly && (
                                        <ButtonPrimaryInverseWithBorder
                                            handleClick={() => setShowSealFormPopUp(true)}
                                            title={`+ ${t("EDIT_REQUEST.BUTTONS.ADD_SEAL")}`}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={GridClass.ROW_GRID}>
                            <div className={`${GridClass.SIMPLE_CELL} ${style.sealsSectionContainer}`}>{sealsRequest}</div>
                        </div>
                    </>
                )}
                <Separator
                    horizontal={true}
                    size="100%"
                />

                <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                    {(schema.entryOn.visible || schema.dockAssignmentOn.visible || schema.dockStartOn.visible) && (
                        <div className={`${style.borderRight}  ${GridClass.THREE_CELL}`}>
                            {schema.entryOn.visible && (
                                <LabelTime
                                    name={t("EDIT_REQUEST.FIELDS.ENTRYSTATEON")}
                                    value={headerRequest?.entryOn}
                                    format={FormatDate.DATE_HOUR_MINUTE}
                                />
                            )}
                            {schema.dockAssignmentOn.visible && (
                                <>
                                    {schema.dockAssignmentOn.readonly ? (
                                        <LabelTime
                                            name={t("EDIT_REQUEST.FIELDS.DOCK_ASSIGNMENT_ON")}
                                            value={headerRequest?.dockAssignmentOn}
                                            format={FormatDate.DATE_HOUR_MINUTE}
                                        />
                                    ) : (
                                        <DateTimePickerComponent
                                            name={compileNameOfProperty<IEditRequest>("dockAssignmentOn")}
                                            label={t("EDIT_REQUEST.FIELDS.DOCK_ASSIGNMENT_ON")}
                                            onChange={onDateChange}
                                            initValue={headerRequest?.dockAssignmentOn}
                                            removeDate={() => removeDate("dockAssignmentOn")}
                                        />
                                    )}
                                </>
                            )}
                            {schema.dockStartOn.visible && (
                                <LabelTime
                                    name={t("EDIT_REQUEST.FIELDS.DOCKSTARTSTATEON")}
                                    value={headerRequest?.dockStartOn}
                                    format={FormatDate.DATE_HOUR_MINUTE}
                                />
                            )}
                        </div>
                    )}
                    {((schema.customsAuthorityArrivalOn.visible && organization?.events?.customsAuthorityArrival.active) ||
                        (schema.representativeArrivalOn.visible && organization?.events?.representativeArrival.active) ||
                        (schema.readyForInstructionOn.visible && organization?.events?.readyForInstruction.active)) && (
                        <div className={`${style.borderRight} ${style.dateContainer} ${GridClass.THREE_CELL}`}>
                            {schema.customsAuthorityArrivalOn.visible && organization?.events?.customsAuthorityArrival.active && (
                                <>
                                    {schema.customsAuthorityArrivalOn.readonly ? (
                                        <LabelTime
                                            name={t("EDIT_REQUEST.FIELDS.CUSTOMSAUTHORITYARRIVALON")}
                                            value={headerRequest?.customsAuthorityArrivalOn}
                                            format={FormatDate.DATE_HOUR_MINUTE}
                                        />
                                    ) : (
                                        <DateTimePickerComponent
                                            name={compileNameOfProperty<IEditRequest>("customsAuthorityArrivalOn")}
                                            label={t("EDIT_REQUEST.FIELDS.CUSTOMSAUTHORITYARRIVALON")}
                                            onChange={onDateChange}
                                            initValue={headerRequest?.customsAuthorityArrivalOn!}
                                            minDate={headerRequest.dockStartOn}
                                            error={validcustomsAuthorityArrivalOn}
                                            resetDate={resetCustomsAuthorityArrivalOn}
                                            setResetDate={setResetCustomsAuthorityArrivalOn}
                                            removeDate={() => removeDate("customsAuthorityArrivalOn")}
                                        />
                                    )}
                                </>
                            )}

                            {schema.representativeArrivalOn.visible && organization?.events?.representativeArrival.active && (
                                <>
                                    {schema.representativeArrivalOn.readonly ? (
                                        <LabelTime
                                            name={t("EDIT_REQUEST.FIELDS.REPRESENTATIVEARRIVALON")}
                                            value={headerRequest?.representativeArrivalOn}
                                            format={FormatDate.DATE_HOUR_MINUTE}
                                        />
                                    ) : (
                                        <DateTimePickerComponent
                                            name={compileNameOfProperty<IEditRequest>("representativeArrivalOn")}
                                            label={t("EDIT_REQUEST.FIELDS.REPRESENTATIVEARRIVALON")}
                                            onChange={onDateChange}
                                            initValue={headerRequest?.representativeArrivalOn!}
                                            minDate={headerRequest.dockStartOn}
                                            error={validrepresentativeArrivalOn}
                                            resetDate={resetRepresentativeArrivalOn}
                                            setResetDate={setResetRepresentativeArrivalOn}
                                            removeDate={() => removeDate("representativeArrivalOn")}
                                        />
                                    )}
                                </>
                            )}

                            {schema.readyForInstructionOn.visible && organization?.events?.readyForInstruction.active && (
                                <>
                                    {schema.readyForInstructionOn.readonly ? (
                                        <LabelTime
                                            name={t("EDIT_REQUEST.FIELDS.READYFORINSTRUCTIONON")}
                                            value={headerRequest?.readyForInstructionOn}
                                            format={FormatDate.DATE_HOUR_MINUTE}
                                        />
                                    ) : (
                                        <DateTimePickerComponent
                                            name={compileNameOfProperty<IEditRequest>("readyForInstructionOn")}
                                            label={t("EDIT_REQUEST.FIELDS.READYFORINSTRUCTIONON")}
                                            onChange={onDateChange}
                                            initValue={headerRequest?.readyForInstructionOn!}
                                            minDate={headerRequest.dockStartOn}
                                            error={validreadyForInstructionOn}
                                            resetDate={resetReadyForInstructionOn}
                                            setResetDate={setResetReadyForInstructionOn}
                                            removeDate={() => removeDate("readyForInstructionOn")}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    {(schema.exitOn.visible || schema.dockEndOn.visible) && (
                        <div className={`${style.dateContainer} ${GridClass.THREE_CELL}`}>
                            {schema.dockEndOn.visible && (
                                <LabelTime
                                    name={t("EDIT_REQUEST.FIELDS.DOCKENDSTATEON")}
                                    value={headerRequest?.dockEndOn}
                                    format={FormatDate.DATE_HOUR_MINUTE}
                                />
                            )}
                            {schema.exitOn.visible && (
                                <LabelTime
                                    name={t("EDIT_REQUEST.FIELDS.EXITSTATEON")}
                                    value={headerRequest?.exitOn}
                                    format={FormatDate.DATE_HOUR_MINUTE}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            <PopUpForm
                content={<OriginSeals isOriginalSeal={true} />}
                title={t("EDIT_REQUEST.BUTTONS.ADD_SEAL")}
                height={"auto"}
                width={isMobile ? widths.editRequest.seal.mobileWidth : widths.editRequest.seal.width}
                isVisible={showSealFormPopUp}
                onCancel={onCancelSealFormPopUp}
                onSave={onSaveSealFormPopUp}
            />
        </div>
    );
};
