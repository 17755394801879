import { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { Label } from "../labels/label";
import { TextInputStyled } from "./textInput.jss";

interface ITextInputProps {
    propertyName: string;
    type?: string;
    label: string;
    value: string | undefined;
    onChange: (event: any, name: string) => void;
    disabled?: boolean;
    className?: string;
    onEnter?: () => void;
}

export const TextInput: React.FC<ITextInputProps> = ({ propertyName, type, label, value, onChange, disabled, className, onEnter }) => {
    const theme = useTheme() as any;
    const styles = TextInputStyled({ theme });
    const [textFieldKey, setTextFieldKey] = useState<number>(0);
    const filled = value ? "filled" : "";

    useEffect(() => {
        value === undefined && setTextFieldKey((key) => key + 1);
    }, [value]);

    const handleChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, _newValue?: string) => {
        onChange(_event, propertyName);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            onEnter && onEnter();
        }
    };

    return (
        <div className={styles.input}>
            <input
                autoComplete={"none"}
                key={`${propertyName}-${textFieldKey}`}
                name={propertyName}
                type={type}
                value={value}
                onChange={handleChange}
                disabled={disabled}
                className={className}
                onKeyDown={handleKeyDown}
            />
            {label && (
                <Label
                    title={label}
                    className={filled}
                />
            )}
        </div>
    );
};
