import { useTheme } from "react-jss";
import { GeneralInformationEditContainerStyle } from "../../../containerStyle.jss";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { setEditRequestAppointmentShowForm } from "../../../../../../../../redux/reducers/request/edit/form/appointment";
import { ColorType } from "../../../../../../../../common/enum/ColorType";
import { PopUpCodes } from "../../../../../../../../common/enum/PopUpCodes";
import { ButtonPrimaryInverseWithBorder } from "../../../../../../../common/buttons/buttons";
import { TitleH5 } from "../../../../../../../common/titles/titles";
import React, { useEffect, useMemo, useState } from "react";
import { IConfigEditRequest } from "../../../../../../../../models/requests/IConfigEditRequest";
import { EditRequestAppointmentForm } from "./form/form";
import { getBeforeOfToday } from "../../../../../../../../utils";

interface IEditRequestAppointmentContainerProps {
    schema: IConfigEditRequest;
    disabledAppointmentData: boolean;
    isEqualsRequest: boolean;
    invalidRequestForm: boolean;
}

export const EditRequestAppointmentContainer: React.FC<IEditRequestAppointmentContainerProps> = ({
    schema,
    disabledAppointmentData,
    isEqualsRequest,
    invalidRequestForm,
}) => {
    const theme = useTheme();
    const style = GeneralInformationEditContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const [isChangedRequest, setIsChangedRequest] = useState<boolean>(false);
    const { headerRequest, canAssignAppointment } = useAppSelector((state) => state.editRequest);

    const showButtonCreate = useMemo(() => {
        if (!schema.appointmentCreate.visible) {
            return false;
        }
        if (getBeforeOfToday(headerRequest.date)) {
            if (isChangedRequest && !canAssignAppointment) {
                return true;
            }

            if (!headerRequest.appointmentAssigned) {
                return true;
            }

            return false;
        }
        return !headerRequest.appointmentAssigned || !canAssignAppointment;
    }, [schema, headerRequest, canAssignAppointment, isChangedRequest]);

    const showEditRequestAssingAppointment = useMemo(() => {
        if (getBeforeOfToday(headerRequest.date)) {
            if (isChangedRequest) {
                return canAssignAppointment;
            }

            if (headerRequest.appointmentAssigned) {
                return true;
            }

            return false;
        }

        return headerRequest.appointmentAssigned && canAssignAppointment;
    }, [headerRequest, canAssignAppointment, isChangedRequest]);

    useEffect(() => {
        if (!isEqualsRequest) {
            setIsChangedRequest(true);
        }
    }, [isEqualsRequest]);

    return (
        <>
            <div className={style.title}>
                <TitleH5
                    title={t("EDIT_REQUEST.APPOINTMENT.TITLE")}
                    color={ColorType.primary}
                    bold={true}
                />
                {showButtonCreate && (
                    <ButtonPrimaryInverseWithBorder
                        handleClick={() => {
                            dispatch(setEditRequestAppointmentShowForm(PopUpCodes.CREATE));
                        }}
                        disabled={!canAssignAppointment || invalidRequestForm}
                        title={`+ ${t("EDIT_REQUEST.APPOINTMENT.CREATE")}`}
                    />
                )}
            </div>

            {showEditRequestAssingAppointment && (
                <EditRequestAppointmentForm
                    schema={schema}
                    disabledAppointmentData={disabledAppointmentData}
                    hiddenActionsAppointment={false}
                    invalidRequestForm={invalidRequestForm}
                />
            )}
        </>
    );
};
