import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const UserConfirmationStyle = createUseStyles((theme: any) => ({
    content: (props: any) => ({
        minWidth: "100%",
        paddingTop: "2vh",
        paddingLeft: theme.paddings.default,
        height: props.height ? props.height : "calc(100vh - 9rem)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        background: theme.colors.site.white,
    }),
    mainColumn: {
        overflow: "auto",
        width: "100%",
        paddingLeft: theme.paddings.sizes.base,
        paddingRight: theme.paddings.sizes.base,
        rowGap: theme.gaps.sizes.md,
    },
    codesRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
        gap: theme.gaps.sizes.lg,
        paddingRight: theme.gaps.sizes.sm,
        width: "50%",
       
    },
    actionButtons: {
        padding: "24px 24px 17px 24px",
        display: "flex",
        gap: theme.gaps.buttons.default,
        justifyContent: "end",
        alignItems: "center",
        "& button": {
            minWidth: 110,
        },
        "& div:nth-child(2)": {
            display: "flex",
        },
        "& h5": {
            paddingTop: theme.paddings.sizes.sm,
        },
        backgroundColor: "#F7F7F7",
    },
    userData: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        rowGap: theme.gaps.sizes.md,
        columnGap: theme.gaps.sizes.xxxl,
        [mediaQueries.TABLETPORTRAIT]: {
            flexWrap: "wrap",
        },
    },
    userDataColumn: {
        display: "flex",
        flex: "50%",
        flexDirection: "column",
    },
    userDataColumnTitle: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: theme.paddings.default,
    },
    buttonsRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
        height: "62px",
        padding: theme.paddings.default,
        columnGap: theme.gaps.sizes.md,
        boxShadow: "0px -2px 3px rgb(70 70 70 / 35%)",
    },
}));
