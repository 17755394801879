import { Icon } from "@fluentui/react";
import { BubbleStyle } from "./bubbleStyle.jss";
import { useTheme } from "react-jss";
interface IBubbleProps {
    iconName: string;
    showBubble?: boolean;
}
export const Bubble: React.FC<IBubbleProps> = ({ iconName, showBubble }) => {
   const theme = useTheme();
   const style = BubbleStyle({theme});
   
    return showBubble ? (
        <Icon
            iconName={iconName}
            className={style.bubble}
        />
    ) : (
        <></>
    );
};
