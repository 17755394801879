export enum ColorType {
    primary = "primary",
    primaryInverse = "primaryInverse",
    primaryInverseWithBorder = "primaryInverseWithBorder",
    secondary = "secondary",
    success = "success",
    danger = "danger",
    warning = "warning",
    info = "info",
    black = "black",
    dangerInverseWithBorder = "dangerInverseWithBorder"
}
