import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const PopUpFormStyle = createUseStyles((_theme: any) => ({
    popUpContainer: (props: any) => ({
        display: "flex",
        flexDirection: "column",
        gap: props.theme.gaps.sizes.md,
        minHeight: props.height ? props.height : props.theme.heights.customPopUp.maxHeigth,
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    }),

    content: (props: any) => ({
        maxHeight: props.height ? props.height : props.theme.heights.customPopUp.maxHeigth,
        minHeight: props.height ? props.height : props.theme.heights.customPopUp.maxHeigth,
        height: props.height ? props.height : props.theme.heights.customPopUp.heigth,
        minWidth: props.width ? props.width : "50vw",
        maxWidth: 1000,
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "initial",
    }),
}));
