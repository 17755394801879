import { createUseStyles } from "react-jss";

export const TableListStyles = createUseStyles((theme: any) => ({
    tableContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    tableHeader: {
        display: "flex",
        paddingBottom: theme.paddings.forms.inputs.defaultPadding,
        borderBottom: theme.borders.table.header.border,
        color: theme.colors.table.header.color,
        alignContent: "center",
        alignItems:"center",
        marginBottom: 5,
        "& > div ": {
            width: "20%",
        },
    },
    tableBody: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        fontSize: theme.fonts.sizes.sm,
        "& > div:not([class])": {
            width: theme.widths.sectionContainer.complete,
            display: "flex",
            paddingTop: 8,
            paddingBottom: 9,
            borderBottom: theme.borders.table.body.border,
            color: theme.colors.table.body.color,
            "& > div ": {
                width: "20%",
                display: "flex",
                gap: theme.gaps.sizes.sm,
                alignItems: "center",
                wordBreak: "break-all",
            },
        },
    },
    notData: {
        fontSize: theme.fonts.sizes.sm,
    },
}));
