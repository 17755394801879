import React,{ useEffect } from "react";
import { fetchMerchandiseOrigins } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../../../common/selectors/customSelector/customSelector";

export const CustomMerchandiseOriginSelector: React.FC<ISelectorProps> = ({ onChange, title, name, selectedKey, disabled }) => {
    const { data, loading } = useAppSelector((store) => store.catalog.merchandiseOrigins);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchMerchandiseOrigins());
    }, [dispatch, data]);

    return (
        <CustomSelector
            title={title}
            options={mapIDescriptionToDropDownOptions(data)}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabled !== undefined ? disabled : loading}
        />
    );
};
