import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const UserProfileContainerStyle = createUseStyles((theme: any) => ({
    profileContainer: {
        padding: 32,
    },
    profileContent: {
        minWidth: "100%",
        background: theme.colors.siteContent.background,
        minHeight: "calc(100vh - 14rem)",
        height: "auto",
        maxHeight: "calc(100vh - 14rem)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "hidden",
        [mediaQueries.TABLET]: {
            height: "100%",
        },
        paddingLeft: "24px",
        marginTop: "15px",
        marginBottom: "10px",
    },
    actionButtons: {
        padding: "17px 24px 17px 24px",
        display: "flex",
        gap: theme.gaps.buttons.default,
        justifyContent: "end",
        alignItems: "center",
        "& button": {
            minWidth: 110,
        },
        "& div:nth-child(2)": {
            display: "flex",
            gap: theme.gaps.buttons.default,
        },
    },
   
}));
