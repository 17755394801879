import { useEffect, useMemo } from "react";
import { fetchOrganisms } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../../../common/selectors/customSelector/customSelector";
import { RoleType } from "../../../../../common/enum";



export const CustomOrganismSelector: React.FC<ISelectorProps> = ({ onChange, title, name, selectedKey, disabled }) => {
    const { data, loading } = useAppSelector((store) => store.catalog.organisms);
    const dispatch = useAppDispatch();

    const { organismId, roles } = useAppSelector((state) => state.userData.profile);
    const pcfAdmin = roles.find((item) => item.id === RoleType.LEADERSHIP) ? true : false;

    useEffect(() => {
        organismId && !pcfAdmin && onChange(name, { key: organismId });
    }, [pcfAdmin, organismId, selectedKey]);

    useEffect(() => {
        data.length === 0 && dispatch(fetchOrganisms());
    }, [dispatch, data]);

    const disabledSelector = useMemo(() => {
        if (organismId && !pcfAdmin) {
            return true;
        }

        return disabled !== undefined ? disabled : loading;
    }, [disabled, organismId]);

    return (
        <CustomSelector
            title={title}
            options={mapIDescriptionToDropDownOptions(data)}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabledSelector}
        />
    );
};
