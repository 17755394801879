import { RootState } from "../../../store";

export const organizationSelector = (state: RootState) => {
    return state.organization;
};

export const getUserOrganization = (state: RootState) => {
    const auth = organizationSelector(state);
    return auth.organization;
};
