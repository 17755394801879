import { useCallback, useState } from "react";
import { NameIcons } from "../../../../../assets/icons";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { PopUp } from "../../../../common/popup/popUp";
import { DraftContainer } from "../../../draft/form/container";

interface IEditDraft {
    groupId: string;
}

export const EditDraft: React.FC<IEditDraft> = ({ groupId }): JSX.Element => {
    const [isEditRequestVisible, setEditRequestVisible] = useState<boolean>(false);

    const onClosePopUp = useCallback(() => {
        setEditRequestVisible(false);
    }, []);

    const onCreateRequestClick = useCallback(() => {
        setEditRequestVisible(true);
    }, []);

    return (
        <>
            <div>
                <CustomIcons
                    size={"20px"}
                    iconName={NameIcons.EDIT}
                    onClick={() => onCreateRequestClick()}
                />

                <PopUp
                    children={
                        <DraftContainer
                            onAbandon={onClosePopUp}
                            groupId={groupId}
                        />
                    }
                    state={isEditRequestVisible}
                    isBlocking={true}
                    skipHeader={true}
                    skipPadding={true}
                />
            </div>
        </>
    );
};
