import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const StatisticsFiltersContainerStyle = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        display: "flex",
        flexDirection: "row",
        height: props.reducedHeight ? 105 : 145,
        backgroundColor: "#E7E5FF",
        margin: "15px 0",
        alignItems: "flex-start",
        [mediaQueries.TABLET]: {
            flexDirection: "column",
            backgroundColor: "transparent",
            minHeight: "30vh",
        },
    }),

    verticalWhiteSeparator: {
        height: "100%",
        borderLeft: theme.borders.default.whiteBorder,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    },
    horizontalWhiteSeparator: {
        width: "100%",
        borderBottom: `3px solid ${theme.colors.palette.white}`,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    },
    pivotContainer: {
        display: "flex",
        gap: 15,
        width: "100%",
        height: "100%",
        alignItems: "center",
        paddingLeft:18,
    },
    dateContainer: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        paddingLeft: theme.paddings.header.paddingLeft,
        paddingRight: theme.paddings.header.paddingRight,
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        gap: theme.gaps.sizes.xs,
        height: "100%",
        paddingBottom: 12,
        paddingRight: 12,
        "& button": {
            width: 50,
            "& i": {
                fontSize: "15px",
            },
        },
    },
   
}));
