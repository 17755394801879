import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserStepperStyled = createUseStyles((theme: any) => ({
    container: {
        padding: theme.paddings.default,
        flexFlow: "row",
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        height: "100%",
        [mediaQueries.MOBILE]: {
            flexFlow: "column",
            alignItems: "center",
            gap:35
        },
    },
    leftColumn: {
        flexFlow: "column",
        display: "flex",
        flex: 25,
        paddingRight: theme.paddings.default,
        rowGap: theme.gaps.sizes.base,
        [mediaQueries.MOBILE]: {
            minWidth: 300,
            flex: 0,
            rowGap: 10,
        },
    },
    rightColumn: {
        flexFlow: "column",
        display: "flex",
        flex: 75,
        paddingLeft: theme.paddings.default,
        [mediaQueries.MOBILE]: {
            minWidth: 300,
            width:"100%"
        },
    },
    stepContainer: {
        cursor: "pointer",
    },
}));
