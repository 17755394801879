import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IParkingValidationConfiguration } from "../../../../models/management/parkingValidationRule/IParkingValidationRule";
export interface IManagementParkingValidationConfigurationState {
    view?: IParkingValidationConfiguration;
    loading: boolean;
}

const initialState: IManagementParkingValidationConfigurationState = {
    view: undefined,
    loading: false,
};

export const managementParkingValidationConfigurationSlice = createSlice({
    name: "ManagementParkingValidationConfiguration",
    initialState,
    reducers: {
        resetParkingValidationRuleView: () => initialState,
        setLoadingParkingValidationRuleConfiguration: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setParkingValidationRuleConfigurationView: (state, action: PayloadAction<IParkingValidationConfiguration>) => {
            state.view = action.payload;
        },
    },
});

export const { resetParkingValidationRuleView, setLoadingParkingValidationRuleConfiguration, setParkingValidationRuleConfigurationView } =
    managementParkingValidationConfigurationSlice.actions;

export default managementParkingValidationConfigurationSlice.reducer;
