import React from "react";
import appInsights from "../../services/telemetryService";

class ErrorBoundary extends React.Component {
    componentDidCatch(error: any, errorInfo: any) {
        appInsights.trackException({
            exception: error,
            severityLevel: 3,
            properties: {
                componentStack: errorInfo.componentStack,
            },
        });
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
