import React from "react";
import { store } from "../../../redux/store";
import { UpcomingAppointmentSortContainer } from "./container";
import { resetRequestUpcomingAppointmentsSortList } from "../../../redux/reducers/upcomingAppointmentsSort/list";
import { resetUpcomingAppointmentsSortFilter } from "../../../redux/reducers/upcomingAppointmentsSort/filter";
import { setInspectorOptions } from "../../../redux/reducers/user/users/usersByRole";

export class UpcomingAppointmentSortController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetRequestUpcomingAppointmentsSortList());
        store.dispatch(resetUpcomingAppointmentsSortFilter());
        store.dispatch(setInspectorOptions([]));
    }

    render(): React.ReactNode {
        return <UpcomingAppointmentSortContainer />;
    }
}
