import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../../redux/hooks";
import { IManagementRepresentationForm } from "../../../../../redux/reducers/management/representation/form";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { ControlledDatePicker } from "../../../../common/datePicker/controlledDatePicker";
import { ControlledRadioButtonGroup } from "../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { ManagementRepresentationDefaultFormStyle } from "./formStyle.jss";

interface IManagementRepresentationDefaultFormProps {
    onDateChange: (date: Date | undefined | null, propertyName: string) => any;
    onRadioButtonChange: (optionKey: string, propertyName: string) => any;
    setIsAvailableSave: (value: any) => any;
}

export const ManagementRepresentationDefaultForm: React.FC<IManagementRepresentationDefaultFormProps> = ({
    onDateChange,
    onRadioButtonChange,
    setIsAvailableSave,
}) => {
    const { control } = useForm<IManagementRepresentationForm>({ mode: "onChange" });
    const { startDate, endDate, validated } = useAppSelector((store) => store.managementRepresentationForm.generalRepresentationData);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.REPRESENTATION.FORM" });
    const theme = useTheme();
    const style = ManagementRepresentationDefaultFormStyle({ theme });

    useEffect(() => {
        const startDateCompare = startDate ? new Date(startDate) : new Date();
        const endDateCompare = endDate ? new Date(endDate) : new Date();
        setIsAvailableSave(startDate && endDate && endDateCompare > startDateCompare);
    }, [startDate, endDate]);

    return (
        <div className={style.containerForm}>
            <div className={style.rowForm}>
                <ControlledDatePicker
                    name={compileNameOfProperty<IManagementRepresentationForm>("startDate")}
                    label={t("STARTDATE")}
                    onDateChange={onDateChange}
                    initValue={startDate && new Date(startDate)}
                    required={true}
                    control={control}
                    disabled={false}
                />
                <ControlledDatePicker
                    name={compileNameOfProperty<IManagementRepresentationForm>("endDate")}
                    label={t("ENDDATE")}
                    onDateChange={onDateChange}
                    initValue={endDate && new Date(endDate)}
                    required={true}
                    control={control}
                    disabled={false}
                />
            </div>
            <div className={style.rowForm}>
                <ControlledRadioButtonGroup
                    label={t("VALID")}
                    selectedKey={validated ? "1" : "0"}
                    onRadioButtonChange={onRadioButtonChange}
                    control={control}
                    name={compileNameOfProperty<IManagementRepresentationForm>("validated")}
                    required={true}
                />
            </div>
        </div>
    );
};
