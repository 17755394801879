import React, { FormEvent, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { LocalStorageKey, LocalStorageService } from "../../../services/localStorageService";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { MasterContainerStyle } from "./containerStyle.jss";
import { MasterListComponent } from "./list/list";
import { MasterTypes } from "../../../common/enum/masters/marterTypes";
import { MasterSelector } from "../../common/selectors/controllerSelectors/masterSelector/masterSelector";
import { IComboBoxOption } from "@fluentui/react";
import { setComponetMaster, setShowPopUpMaster } from "../../../redux/reducers/resources/master/master";

export const MastersContainer = () => {
    const dispatch = useAppDispatch();
    const localStorageService = new LocalStorageService();

    const { codeComponent } = useAppSelector((state) => state.masterPopUp);

    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const theme = useTheme();
    const style = MasterContainerStyle({ theme });

    const title = useMemo(() => {
        switch (codeComponent) {
            case MasterTypes.ORGANISM:
                return `${t("CREATE")} ${t("BUTTON.ORGANISM").toLowerCase()}`;
            case MasterTypes.CERTIFICATE_TYPE:
                return `${t("CREATE")} ${t("BUTTON.CERTIFICATE_TYPE").toLowerCase()}`;
            case MasterTypes.MERCHANDISE_ORIGIN:
                return `${t("CREATE")} ${t("BUTTON.MERCHANDISE_ORIGIN").toLowerCase()}`;
            case MasterTypes.MERCHANDISE_TYPES:
                return `${t("CREATE")} ${t("BUTTON.MERCHANDISE_TYPE").toLowerCase()}`;
            case MasterTypes.GROUPS_MERCHANDISE_CATEGORY:
                return `${t("CREATE")} ${t("BUTTON.MERCHANDISE_CATEGORY_GROUP").toLowerCase()}`;
            case MasterTypes.MERCHANDISE_CATEGORY:
                return `${t("CREATE")} ${t("BUTTON.MERCHANDISE_CATEGORY").toLowerCase()}`;
            case MasterTypes.DOCKS:
                return `${t("CREATE")} ${t("BUTTON.DOCKS").toLowerCase()}`;
            case MasterTypes.REQUEST_STATES:
                return `${t("CREATE")} ${t("BUTTON.REQUEST_STATE").toLowerCase()}`;
            case MasterTypes.ACTUATION_TYPE:
                return `${t("CREATE")} ${t("BUTTON.ACTUATION_TYPE").toLowerCase()}`;
            case MasterTypes.DOCUMENT_TYPE:
                return `${t("CREATE")} ${t("BUTTON.DOCUMENT_TYPE").toLowerCase()}`;
            case MasterTypes.FINAL_TYPE_DESTINY:
                return `${t("CREATE")} ${t("BUTTON.TYPE_FINAL_DESTINY").toLowerCase()}`;
            case MasterTypes.REPRESENTATIVE_PRESENCE_TYPES:
                return `${t("CREATE")} ${t("BUTTON.REPRESENTATIVE_PRESENCE_TYPES").toLowerCase()}`;
            case MasterTypes.SEAL_TYPES:
                return `${t("CREATE")} ${t("BUTTON.SEAL_TYPES").toLowerCase()}`;
            case MasterTypes.TURNS:
                return `${t("CREATE")} ${t("BUTTON.TURNS").toLowerCase()}`;
            case MasterTypes.BLOCK:
                return `${t("CREATE")} ${t("BUTTON.BLOCK").toLowerCase()}`;
            case MasterTypes.ACTUATION_GROUP:
                return `${t("CREATE")} ${t("BUTTON.ACTUATION_GROUP").toLowerCase()}`;
            case MasterTypes.CUSTOMS_STATES:
                return `${t("CREATE")} ${t("BUTTON.CUSTOMS_STATES").toLowerCase()}`;
            case MasterTypes.NOTIFICATION_TYPES:
                return `${t("CREATE")} ${t("BUTTON.NOTIFICATION_TYPES").toLowerCase()}`;
            case MasterTypes.TRANSPORT_UNITS:
                return `${t("CREATE")} ${t("BUTTON.TRANSPORT_UNIT").toLowerCase()}`;
            case MasterTypes.TERMINAL:
                return `${t("CREATE")} ${t("BUTTON.TERMINAL").toLowerCase()}`;
        }

        return "";
    }, [codeComponent]);

    const onSelectorChange = (_event: FormEvent<HTMLDivElement>, option: any, name: string) => {
        dispatch(setComponetMaster(option.key));
        localStorageService.setItem(LocalStorageKey.MASTER_MANAGEMENT, option.key);
    };

    const data: IComboBoxOption[] = [
        {
            key: MasterTypes.ORGANISM,
            text: t("SELECTOR.ORGANISM"),
        },
        {
            key: MasterTypes.CERTIFICATE_TYPE,
            text: t("SELECTOR.CERTIFICATE_TYPE"),
        },
        {
            key: MasterTypes.REQUEST_STATES,
            text: t("SELECTOR.REQUEST_STATE"),
        },
        {
            key: MasterTypes.CUSTOMS_STATES,
            text: t("SELECTOR.CUSTOMS_STATES"),
        },
        {
            key: MasterTypes.BLOCK,
            text: t("SELECTOR.BLOCK"),
        },
        {
            key: MasterTypes.DOCKS,
            text: t("SELECTOR.DOCKS"),
        },
        {
            key: MasterTypes.GROUPS_MERCHANDISE_CATEGORY,
            text: t("SELECTOR.MERCHANDISE_CATEGORY_GROUP"),
        },
        {
            key: MasterTypes.MERCHANDISE_CATEGORY,
            text: t("SELECTOR.MERCHANDISE_CATEGORY"),
        },

        {
            key: MasterTypes.MERCHANDISE_ORIGIN,
            text: t("SELECTOR.MERCHANDISE_ORIGIN"),
        },
        {
            key: MasterTypes.MERCHANDISE_TYPES,
            text: t("SELECTOR.MERCHANDISE_TYPE"),
        },
        {
            key: MasterTypes.TURNS,
            text: t("SELECTOR.TURNS"),
        },
        {
            key: MasterTypes.SEAL_TYPES,
            text: t("SELECTOR.SEAL_TYPES"),
        },
        {
            key: MasterTypes.FINAL_TYPE_DESTINY,
            text: t("SELECTOR.TYPE_FINAL_DESTINY"),
        },
        {
            key: MasterTypes.REPRESENTATIVE_PRESENCE_TYPES,
            text: t("SELECTOR.REPRESENTATIVE_PRESENCE_TYPES"),
        },
        {
            key: MasterTypes.DOCUMENT_TYPE,
            text: t("SELECTOR.DOCUMENT_TYPE"),
        },
        {
            key: MasterTypes.ACTUATION_GROUP,
            text: t("SELECTOR.ACTUATION_GROUP"),
        },
        {
            key: MasterTypes.ACTUATION_TYPE,
            text: t("SELECTOR.ACTUATION_TYPE"),
        },
        {
            key: MasterTypes.NOTIFICATION_TYPES,
            text: t("SELECTOR.NOTIFICATION_TYPES"),
        },
        {
            key: MasterTypes.TRANSPORT_UNITS,
            text: t("SELECTOR.TRANSPORT_UNIT"),
        },
        {
            key: MasterTypes.TERMINAL,
            text: t("SELECTOR.TERMINAL"),
        },
    ];
    useLayoutEffect(() => {
        const localStorageService = new LocalStorageService();
        let code = localStorageService.getItem(LocalStorageKey.MASTER_MANAGEMENT);
        code && dispatch(setComponetMaster(code));
    }, []);
    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={""}
                action={
                    <>
                        <MasterSelector
                            onChange={onSelectorChange}
                            options={data}
                            selectedKey={codeComponent}
                        />

                        <ButtonPrimary
                            title={title}
                            handleClick={() => {
                                dispatch(setShowPopUpMaster(PopUpCodes.CREATE));
                            }}
                        />
                    </>
                }
            />
            <MasterListComponent />
        </div>
    );
};
