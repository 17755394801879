import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IMasterAppointmentType } from "../../../../models/masterAppointment/IAppointmentType";

export interface IMasterAppointmentTypeFormState {
    form: IMasterAppointmentType;
    loading: boolean;
}

const initialState: IMasterAppointmentTypeFormState = {
    form: {
        active: true,
        code: "",
        description: "",
        id:0
    },
    loading: false,
};

export const masterAppointmentTypeForm = createSlice({
    name: "masterAppointmentTypeForm",
    initialState,
    reducers: {
        resetMasterAppointmentTypeForm: () => initialState,
        setLoadingMasterAppointmentTypeForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentTypeForm: (state, action: PayloadAction<IMasterAppointmentType>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentTypeProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterAppointmentTypeForm,
    setLoadingMasterAppointmentTypeForm,
    setMasterAppointmentTypeForm,
    updateMasterAppointmentTypeProperty,
} = masterAppointmentTypeForm.actions;

export default masterAppointmentTypeForm.reducer;
