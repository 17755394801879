import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { fetchMerchandiseOriginRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterMerchandiseOriginsForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { postToMasterMerchandiseOriginsForm } from "../../../../../../redux/actions/resources/master/merchandiseOrigins/merchandiseOrigin";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { resetMasterMerchandiseOriginsForm } from "../../../../../../redux/reducers/resources/master/merchandiseOrigins/form";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterMerchandiseOriginControllerForm {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterMerchandiseOriginControllerForm: React.FC<IMasterMerchandiseOriginControllerForm> = ({ widthPopUp, heigthPopUp }) => {
    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { loading, form } = useAppSelector((state) => state.masterMerchandiseOriginsForm);
    const { data } = useAppSelector((state) => state.catalog.merchandiseOriginsRemaining);

    const onAcceptPopUp = () => {
        dispatch(postToMasterMerchandiseOriginsForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };
    const onDimissed = () => {
        dispatch(resetMasterMerchandiseOriginsForm());
    };
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.MERCHANDISE_ORIGIN").toLowerCase();
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.MERCHANDISE_ORIGIN").toLowerCase();
        }

        return "";
    }, [showPopUp]);

    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchMerchandiseOriginRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterMerchandiseOriginsForm
                    showNotData={showNotData}
                    data={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onlyHidden={showNotData}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
