import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IManagementNonWorkDays } from "../../../../models/management/workCalendar/IManagementNonWorkDays";

interface ManagementWorkCalendarListState {
    loading: boolean;
    list: IManagementNonWorkDays[];
}

const initialState: ManagementWorkCalendarListState = {
    loading: false,
    list: [],
};

export const managementWorkCalendarListSlice = createSlice({
    name: "managementWorkCalendarList",
    initialState,
    reducers: {
        resetManagementWorkCalendarList: () => initialState,

        setLoadingManagementWorkCalendarList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementWorkCalendarList: (state, action: PayloadAction<IManagementNonWorkDays[]>) => {
            state.list = action.payload;
        },
        pushManagementWorkCalendar: (state, action: PayloadAction<IManagementNonWorkDays>) => {
            state.list.push({ ...action.payload });
        },
    },
});

export const { resetManagementWorkCalendarList, setLoadingManagementWorkCalendarList, fetchManagementWorkCalendarList, pushManagementWorkCalendar } =
    managementWorkCalendarListSlice.actions;

export default managementWorkCalendarListSlice.reducer;
