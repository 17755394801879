export const checkUndefinedOrNullValues = <T>(obj: T): boolean => {
    for (const key in obj) {
        if (obj[key as keyof T] === undefined || obj[key as keyof T] === null) {
            return true;
        }
    }
    return false;
};

export const objectToQueryString = (obj: Record<string, any>): string => {
    const queryParams = new URLSearchParams();

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            queryParams.append(key, obj[key].toString());
        }
    }
    return queryParams.toString();
};
