import { RoleType } from "../../common/enum";
import { IRole } from "../../models";

const getCustomerRoles = (): RoleType[] => [RoleType.CUSTOMER_USER, RoleType.CUSTOMER_ADMIN];
const getPCFUsersRoles = (): RoleType[] => [
    RoleType.SUPER_ADMIN,
    RoleType.ADMINISTRATIVE,
    RoleType.AREA_DIRECTOR,
    RoleType.DIRECTOR,
    RoleType.TEAM_LEADER,
    RoleType.DOCK,
    RoleType.STAND,
];
const getOrganimsRoles = (): RoleType[] => [RoleType.ORGANISM_ADMIN, RoleType.ORGANISM_INSPECTOR, RoleType.ORGANISM_TECHNICIAN];
const findRoleById = (roles: IRole[], roleId: string): IRole | undefined => roles.find((role) => role.id === roleId);
export { getCustomerRoles, getPCFUsersRoles, getOrganimsRoles, findRoleById };
