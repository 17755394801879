import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../models";
import { IRequestPendingAssignmentFilter } from "../../../models/assignment/IAssignment";

interface IAssignmentFilterState {
    filter: IRequestPendingAssignmentFilter;
}

const initialState: IAssignmentFilterState = {
    filter: {
        requestDescription: "",
        certificateNumber: "",
        transportUnitNumber: "",
    },
};

export const assignementFilterSlice = createSlice({
    name: "assignementFilterSlice",
    initialState,
    reducers: {
        resetFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetFilter, updateFilterProperty } = assignementFilterSlice.actions;

export default assignementFilterSlice.reducer;
