import { createUseStyles } from "react-jss";

export const StatisticsDailyChargeListDateItemtStyle = createUseStyles((theme: any) => ({
    date: {
        width: "99%",
        color: theme.colors.dailyCharge.color,
        backgroundColor: theme.colors.dailyCharge.background,
        padding: theme.paddings.sizes.sm,
        fontWeight: theme.fonts.weight.bolder,
        
    },
}));
