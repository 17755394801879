import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterCertificateTypeForm, setMasterCertificateTypeForm } from "../../../../reducers/resources/master/certificateType/form";
import { setLoadingMasterCertificateTypeList, setMasterCertificateTypeList } from "../../../../reducers/resources/master/certificateType/list";
import { AppThunk, store } from "../../../../store";


const catalogApi = new CatalogAPI();

export const fetchMasterTypesCertificateList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterCertificateTypeList(true));
        const response = await catalogApi.getMasterCertificateTypesList();
        dispatch(setMasterCertificateTypeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterCertificateTypeList(false));
    }
};

export const fetchMasterTypeCertificate =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterCertificateTypeForm(true));
            const response = await catalogApi.getMasterCertificateType(id);
            dispatch(setMasterCertificateTypeForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterCertificateTypeForm(false));
        }
    };

export const postAndPutMasterTypesCertificate = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterCertificateTypeForm;
            dispatch(setLoadingMasterCertificateTypeForm(true));
            if (form.id) {
                await catalogApi.putMasterCertificateType(form);
            } else {
                await catalogApi.postMasterCertificateType(form);
            }
            dispatch(fetchMasterTypesCertificateList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterCertificateTypeForm(false));
            return false;
        } 
    };
};

export const patchSortCertificateTypesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterCertificateTypeList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterTypesCertificateList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterCertificateTypeList(false));
        }
    };
