import { IColumn } from "@fluentui/react";
import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { ITariffCondition } from "../../../../../models/management/tariff/ITariffData";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { getTariffItemDataForm } from "../../../../../redux/actions/management/tariff/tariff";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setShowPopUpTariff, updateTariffConditionForm } from "../../../../../redux/reducers/management/tariff/form";
import { updateSortColumn } from "../../../../../redux/reducers/management/tariff/list";
import { store } from "../../../../../redux/store";
import { widths } from "../../../../../styles/romeu/widths";
import { compileNameOfProperty } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { ManagementTariffListColumnStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";



export const ManagementTariffListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.TARIFF.LIST" });
    const theme = useTheme();
    const styles = ManagementTariffListColumnStyle({ theme });
    const dispatch = useAppDispatch();

    const sort = useAppSelector((state) => state.managementTariffList.sort);

    const { requestStates } = useAppSelector((state) => state.catalog);

    useEffect(() => {
        requestStates.data === undefined  && dispatch(fetchRequestStates());
    }, [requestStates.data]);

    const getStateDescription = (id?: number) => {
        const state = requestStates.data && requestStates.data.find((item) => item.id === id);
        return state?.description;
    };

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().managementTariffList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<ITariffCondition>("conceptDescription"),
            minWidth: 120,
            maxWidth: 700,
            name: t("CONCEPT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ITariffCondition>("conceptDescription")}
                    title={t("CONCEPT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<ITariffCondition>("conceptDescription"))}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: ITariffCondition) => <span>{item.conceptDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ITariffCondition>("unitPrice"),
            minWidth: 100,
            maxWidth: 200,
            name: t("IMPORT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ITariffCondition>("unitPrice")}
                    title={t("IMPORT")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<ITariffCondition>("unitPrice"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: ITariffCondition) => <span>{item.unitPrice ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ITariffCondition>("automatic"),
            minWidth: 150,
            maxWidth: 200,
            name: t("CREATEAUTO"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ITariffCondition>("automatic")}
                    title={t("CREATEAUTO")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<ITariffCondition>("automatic"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: ITariffCondition) => <span> <BooleanValue value={item.automatic}></BooleanValue></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ITariffCondition>("requestStateId"),
            minWidth: 100,
            maxWidth: 200,
            name: t("CHANGE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ITariffCondition>("requestStateId")}
                    title={t("CHANGE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<ITariffCondition>("requestStateId"))}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: ITariffCondition) => <span>{getStateDescription(item.requestStateId) ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: "",
            minWidth: 100,
            maxWidth: 200,
            name: "",
            onRender: (item: ITariffCondition) => (
                <div className={styles.buttonsContainer}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() =>{
                            dispatch(getTariffItemDataForm(item.id ?? 0));
                            dispatch(setShowPopUpTariff(PopUpCodes.UPDATE));
                        } }
                        size={widths.management.icons}
                    />

                    <Separator size="none"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(updateTariffConditionForm({name:"id", value:item.id}));
                            dispatch(setShowPopUpTariff(PopUpCodes.DELETE));
                        }}
                        size={widths.management.icons}
                    />
                </div>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
