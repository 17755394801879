import {
    ContextualMenu,
    ContextualMenuItemType,
    IContextualMenuItem,
    IPersonaSharedProps,
    Persona,
    PersonaPresence,
    PersonaSize,
} from "@fluentui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { UserData } from "react-oidc";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routeUrls } from "../../../common/constants";
import { IRole } from "../../../models/common/IRole";
import { IUserAuthData } from "../../../models/user/IUserAuthData";
import { resetUserAuthAction } from "../../../redux/actions/userData/userData";
import { useAppDispatch } from "../../../redux/hooks";
import { getUserRole } from "../../../redux/selectors/user/role/role";
import { getUserData } from "../../../redux/selectors/userData/userData";
import { LocalStorageService } from "../../../services/localStorageService";
import { ContextMenuStyles } from "./contextMenu.jss";

export const UserContextMenu = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const theme = useTheme();
    const styles = ContextMenuStyles({ theme });
    const { userManager } = useContext(UserData);
    const localStorageService = new LocalStorageService();
    const userData: IUserAuthData = useSelector(getUserData);
    const roleData: IRole = useSelector(getUserRole);

    const logout = async () => {
        dispatch(resetUserAuthAction());
        await userManager?.signoutRedirect();
        localStorageService.clear();
    };

    const linkRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

    const menuItems: IContextualMenuItem[] = [
        {
            key: "divider_0",
            itemType: ContextualMenuItemType.Divider,
        },
        {
            key: "profile",
            text: t("SITE.SITEHEADER.PROFILE"),
            iconProps: { iconName: "Contact", className: styles.iconMenuColor },
            onClick: () => {
                navigate(routeUrls.PROFILE);
            },
        },
        {
            key: "divider_1",
            itemType: ContextualMenuItemType.Divider,
        },
        {
            key: "logout",
            iconProps: { iconName: "PowerButton", className: styles.iconMenuColor },
            text: t("SITE.SITEHEADER.LOGOUT"),
            onClick: () => logout(),
        },
    ];

    const personaProps: IPersonaSharedProps = {
        imageUrl: userData.image,
        text: `${userData.name} ${userData.surName ?? ""}`.trim(),
        secondaryText: roleData?.name,
    };

    const personaStyles = {
        root: {
            "flex-direction": "row-reverse",
        },
        primaryText: {
            "font-weight": "bold",
        },
        secondaryText: {
            "text-align": "right",
            color: "#7367f0",
        },
    };

    return (
        <>
            <div className={styles.userProfile}>
                <a
                    className={styles.link}
                    ref={linkRef}
                    onClick={onShowContextualMenu}
                    href="#"
                >
                    <Persona
                        {...personaProps}
                        styles={personaStyles}
                        size={PersonaSize.size40}
                        presence={PersonaPresence.none}
                    />
                </a>

                <ContextualMenu
                    shouldFocusOnMount={true}
                    items={menuItems}
                    hidden={!showContextualMenu}
                    target={linkRef}
                    onItemClick={onHideContextualMenu}
                    onDismiss={onHideContextualMenu}
                />
            </div>
        </>
    );
};
