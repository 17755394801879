import { createUseStyles } from "react-jss";

export const StatisticsExpedientInfoPivotFilterStyle = createUseStyles((theme: any) => ({
    requestExpedientInfoContainer: {
        display: "flex",
        flexFlow: "row wrap",
        columnGap: theme.gaps.sizes.sm,
        paddingTop: theme.paddings.sizes.xs,
        width: "100%",
    },
    filterItem: (props: any) => ({
        paddingTop: 5,
        width: props.hasFiveColumns ? 161 : 205,
    }),
    filterItem2: (props: any) => ({
        paddingTop: 5,
        width: props.hasFiveColumns ? 332 : 420,
    }),
}));
