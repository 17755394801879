import { IDocument } from "../../../../models";
import { LinkPrimary } from "../../links/links";
import { UserDocumentListStyled } from "./documentList.jss";

interface UserDocumentListProps {
    documents: IDocument[];
}

export const UserDocumentList = (props: UserDocumentListProps) => {
    const styles = UserDocumentListStyled();

    return (
        <>
            <div className={styles.container}>
                {props.documents.map((item, index) => (
                    <LinkPrimary
                        key={index}
                        className={styles.item}
                        icon="download"
                        title={item.type}
                        to={item.uri}
                        target="_blank"
                    />
                ))}
            </div>
        </>
    );
};
