import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterMerchandiseOrigin } from "../../../../../models/resources/master/IMerchandiseOrigin";

export interface IMasterOriginWareFormState {
    form: IMasterMerchandiseOrigin;
    loading: boolean;
}

const initialState: IMasterOriginWareFormState = {
    form: {
        active: false,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterOriginWareForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterMerchandiseOriginsForm: () => initialState,
        setLoadingMasterMerchandiseOriginsForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterMerchandiseOriginsForm: (state, action: PayloadAction<IMasterMerchandiseOrigin>) => {
            state.form = action.payload;
        },
        updateMasterMerchandiseOriginsProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterMerchandiseOriginsForm, setLoadingMasterMerchandiseOriginsForm, setMasterMerchandiseOriginsForm, updateMasterMerchandiseOriginsProperty } =
    masterOriginWareForm.actions;

export default masterOriginWareForm.reducer;
