import { DraftApi } from "../../../../api/draft";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";
import { fetchDraftList, loadingDraftList } from "../../../reducers/request/draft/draftlist";
import { AppThunk } from "../../../store";

const draftApi = new DraftApi();

export const fetchDraftListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingDraftList(true));
        const response = await draftApi.getDraftAsync();
        dispatch(fetchDraftList(response));
    } catch (error) {
        dispatch(HandleAxiosError(error));
    } finally {
        dispatch(loadingDraftList(false));
    }
};
