import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const SignupResumeDataUserStyle = createUseStyles((theme: any) => ({
  container: {
    padding: theme.paddings.default,
    display: "flex",
    width: "100%",
    [mediaQueries.TABLET]: {
      flexDirection: "column",
      alignItems: "center",
      rowGap: theme.gaps.sizes.base,
    },
  },
  userPhoto: {
    width: 200,
  },
  userData: {
    width: "calc(100% - 200px)",
    [mediaQueries.TABLET]: {
      width: "100%",
    },
  },
  userDataRow: {
    display: "flex",
    paddingBottom: theme.paddings.default,
    [mediaQueries.MOBILE]: {
      flexDirection: "column",
      rowGap: theme.gaps.sizes.base,
    },
    "& > * ": {
      width: "50%",
      [mediaQueries.MOBILE]: {
        width: "100%",
      },
    },
  },
  userDataFullRow: {
    "& > * ": {
      width: "100%",
    },
  },
}));
