import { apiFetchOAuthWithClaims } from "../../../services/apiClient";
import { Method } from "../../../common/enum";
import { IInvoicingFilter } from "../../../models/invoicing/filter/IInvoiceFilter";
import { IInvoicingSummaryByConceptData } from "../../../models/invoicing/summaryByConcept/IInvoicingSummaryByConcept";

const baseURL = `${process.env.REACT_APP_API_URL}/invoicing/summarybyconcept`;

export class InvoicingSummaryByConceptApi {

    async getSummaryByConcept(filters?: IInvoicingFilter): Promise<IInvoicingSummaryByConceptData> {
        const filtersUrl = `?startDate=${filters?.startDate}&endDate=${filters?.endDate}${
            filters?.customerId ? `&customerId=${filters?.customerId}` : ""
        }`;

        const url = `${baseURL}${filters ? filtersUrl : ""}`;

        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IInvoicingSummaryByConceptData>();
    }

    async exportSummaryByConcept(filters: IInvoicingFilter): Promise<string> {
        const url = `${baseURL}/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
}
