export const requestMaxLength = {
    CUSTOMER_REFERENCE: 255,
    BOOKING: 50,
    DESCRIPTION: 255,
    TARIFF_HEADING: 50,
    TRANSPORT_UNIT_NUMBER: 11,
    TRACTOR_NUMBER: 64,
    SHIP_ARRIVAL: 255,
    SUMMARY: 11,
    EXPORTER: 255,
    IMPORTER: 255,
    SEAL: 50,
    PACKAGES: 9,
    GROSS_WEIGHT: 9
};
