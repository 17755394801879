export const EditRequestValidation = {
    UTI: [
        "transportUnitNumber",
        "date",
        "turnId",
        "customerId",
        "invoiceCustomerId",
        "customerReference",
        "booking",
        "customsStateId",
        "transportUnitSizeId",
        "transportUnitNumber",
        "tractorNumber",
        "packages",
        "grossWeight",
        "palletized",
    ],
    GOOD_INFO:[
        "merchandiseOriginId",
        "humanConsumption",
        "merchandiseTypeId",
        "originCountryCode",
        "merchandiseCategoryId",
        "description",
        "tariffHeading",
        "merchandiseArrivalShip",
        "summaryNumber",
        "terminalId",
    ], 
    CERTIFICATES:[
        "fumigated",
        "dryCharge",
        "organicProduct",
        "halalCertified",
        "exporter",
        "importer",
        "representativePresenceTypeId",
        "finalDestinationTypeId",
        "c5Number",
        "carrierId",
        "carrierPhone",
        "remarks",
    ]

};
