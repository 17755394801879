import { Trans, useTranslation } from "react-i18next";
import { Title } from "../../common/titles/titles";
import { LegalNoticeContainerStyle } from "./controllerStyle.jss";
import { useTheme } from "react-jss";
import { LEGALNOTICEDATA, TechnicalCookiesData, URL_DIRECTIONS } from "../../../common/constants/legalNoticeData";
import { routeUrls } from "../../../common/constants";
import { ControlledRadioButtonGroup } from "../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { useForm } from "react-hook-form";
import { ColorType } from "../../../common/enum/ColorType";
import { DirectionType } from "../../../common/enum/DirectionType";
interface ICookieSectionFormatProps {
    translate: IItemTranslate[];
}
export interface IItemTranslate {
    header?: any;
    description?: any;
    listHeader?: any;
}

export const CookieSectionFormat: React.FC<ICookieSectionFormatProps> = ({ translate }) => {
    const { t } = useTranslation("cookiesection");
    const theme = useTheme();
    const style = LegalNoticeContainerStyle({ theme });
    const location = window.location.protocol + "//" + window.location.host;
    const { control } = useForm<any>({ mode: "onChange" });

    const printUrlItem = (label: any, href: string, newTab?: boolean) => {
        return (
            <a
                target={newTab ? "_blank" : ""}
                rel="noreferrer"
                href={href}
            >
                {label}
            </a>
        );
    };

    const printListItem = (listHeader: any, numberRow: number, ordenList?: boolean) => {
        const rows = [];
        for (let i = 0; i < numberRow; i++) {
            rows.push(listHeader + ".ROW" + (i + 1));
        }

        return !ordenList ? (
            <ul className={style.list}>
                {rows.map((item: any) => {
                    return <li key={item}>{t(item)}</li>;
                })}
            </ul>
        ) : (
            <ol
                type="i"
                className={style.list}
            >
                {rows.map((item: any) => {
                    return <li key={item}>{t(item)}</li>;
                })}
            </ol>
        );
    };

    const TechnicalCookiesTableData = (
        <table
            className={style.table}
            cellSpacing={0}
            cellPadding={10}
        >
            <thead>
                <tr>
                    <th>{t("TECHNICAL_COOKIES")}</th>
                    <th>{t("PURPOSE")}</th>
                    <th>{t("EXPIRATION")}</th>
                </tr>
            </thead>
            <tbody>
                {TechnicalCookiesData.map((item: any) => {
                    return (
                        <tr key={item.name + item.type}>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.type}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );

    const TechnicalCookiesTableConfig = (
        <table
            className={style.table}
            cellSpacing={0}
            cellPadding={10}
        >
            <tr>
                <td>{t("TECHNICAL_COOKIES")}</td>
                <td>
                    <ControlledRadioButtonGroup
                        selectedKey={String(+true)}
                        onRadioButtonChange={() => {}}
                        control={control}
                        direction={DirectionType.horizontal}
                        name={""}
                        disabled={true}
                    />
                </td>
                <td>{t("REQUIRED")}</td>
            </tr>
            <tr style={{ display: "none" }}></tr>
        </table>
    );

    return (
        <div>
            <div className={style.container}>
                {translate.map((item: IItemTranslate, index) => {
                    return (
                        <>
                            {item.header && (
                                <Title
                                    type={index === 0 ? "h1" : "h4"}
                                    color={ColorType.primary}
                                    className={index === 0 ? style.centerText : ""}
                                    title={t(item.header)}
                                />
                            )}

                            {item.description && (
                                <div className={style.section}>
                                    <Trans
                                        t={t}
                                        values={{
                                            location,
                                            company: LEGALNOTICEDATA.COMPANY,
                                            registeredOffice: LEGALNOTICEDATA.REGISTERED_OFFICE,
                                            cif: LEGALNOTICEDATA.CIF,
                                            publicRegistry: LEGALNOTICEDATA.PUBLIC_REGISTRY,
                                            phone: LEGALNOTICEDATA.PHONE,
                                            email: LEGALNOTICEDATA.EMAIL,
                                            TechnicalCookiesTable: <></>,
                                            chromeHelpUrl: URL_DIRECTIONS.CHROME,
                                            exploreHelpUrl: URL_DIRECTIONS.EXPLORE,
                                            firefoxHelpUrl: URL_DIRECTIONS.FIREFOX,
                                            safariHelpUrl: URL_DIRECTIONS.SAFARY,
                                            operaHelpUrl: URL_DIRECTIONS.OPERA,
                                        }}
                                        components={{
                                            Table: TechnicalCookiesTableConfig,
                                            Email: printUrlItem(LEGALNOTICEDATA.EMAIL, `mailto:${LEGALNOTICEDATA.EMAIL}`),
                                            PolicySecurityUrl: printUrlItem(t("POLICYSECURITY"), routeUrls.PRIVACY_POLICY),
                                            CookiesUrl: printUrlItem(t("COOKIE"), routeUrls.COOKIES_POLICE),
                                            LegalNoticeUrl: printUrlItem(t("LEGAL_NOTICE_URL"), routeUrls.LEGAL_NOTICE),
                                            List1: printListItem(item.listHeader, 2, true),
                                            List2: printListItem(item.listHeader, 3),
                                            List3: printListItem(item.listHeader, 6),
                                            TechnicalCookiesTable: TechnicalCookiesTableData,
                                            ChromeHelpUrl: printUrlItem(URL_DIRECTIONS.CHROME, URL_DIRECTIONS.CHROME, true),
                                            ExploreHelpUrl: printUrlItem(URL_DIRECTIONS.EXPLORE, URL_DIRECTIONS.EXPLORE, true),
                                            FirefoxHelpUrl: printUrlItem(URL_DIRECTIONS.FIREFOX, URL_DIRECTIONS.FIREFOX, true),
                                            SafariHelpUrl: printUrlItem(URL_DIRECTIONS.SAFARY, URL_DIRECTIONS.SAFARY, true),
                                            OperaHelpUrl: printUrlItem(URL_DIRECTIONS.OPERA, URL_DIRECTIONS.OPERA, true),
                                        }}
                                        i18nKey={item.description}
                                        tOptions={{ interpolation: { escapeValue: false } }}
                                    />
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
};
