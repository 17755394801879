import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { IGenericPayload } from "../../../../../models";
import { IBoardHistoryAppointmentTimeZone } from "../../../../../models/masterAppointment/IAppointmentBoard";

export interface IAppointmentTimeZoneFormState {
    form: IBoardHistoryAppointmentTimeZone;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmentTimeZoneFormState = {
    form: {
        id: 0,
        code: "",
        description: "",
        startTime: "",
        endTime: "",
        active: true,
        turnId: 0,
        turnDescription: "",
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const masterAppointmentTimeZoneForm = createSlice({
    name: "masterAppointmentTimeZoneForm",
    initialState,
    reducers: {
        resetMasterAppointmentTimeZoneForm: () => initialState,
        setLoadingMasterAppointmentTimeZoneForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentTimeZoneForm: (state, action: PayloadAction<IBoardHistoryAppointmentTimeZone>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentTimeZoneProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setShowPopUpMasterAppointmentTimeZone: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetMasterAppointmentTimeZoneForm,
    setLoadingMasterAppointmentTimeZoneForm,
    setMasterAppointmentTimeZoneForm,
    updateMasterAppointmentTimeZoneProperty,
    setShowPopUpMasterAppointmentTimeZone,
} = masterAppointmentTimeZoneForm.actions;

export default masterAppointmentTimeZoneForm.reducer;
