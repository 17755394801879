import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { removeControlExpedientItemAction, updateControlExpedientItemAction } from "../../../redux/actions/control/expedient/expedient";
import { useAppSelector } from "../../../redux/hooks";
import {  resetControlExpedientState, setShowPopUp } from "../../../redux/reducers/control/expedients/form";
import { widths } from "../../../styles/romeu/widths";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { PopUpConfirmation } from "../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../common/popup/popUpForm/popUpForm";
import { ControlExpedientsFilter } from "./filters/filters";
import { ControlerExpedientsForm } from "./form/form";
import { ControllExpedientsList } from "./list/list";
import { PopUpConfirmationType } from "../../../common/enum/PopUpConfirmationType";

export const ControlExpedientsContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS" });

    const dispatch = useDispatch();
    const { form, loading, showPopUp } = useAppSelector((state) => state.controlExpedientsForm);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        } else if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        }

        return "";
    }, [showPopUp]);

    const disabledButtonForm = useMemo(() => {
        let item: any = form;
        let keys = ["certificateTypeId", "certificateNumber", "year"];

        for (let key of keys) {
            let value = item[key];
            if (!value) {
                return true;
            }
        }
        return false;
    }, [form]);
    const onDimissed = () => {
        dispatch(resetControlExpedientState());
    };
    const onCloseForm = () => {
        dispatch(setShowPopUp(PopUpCodes.NONE));
    };

    const onAccept = () => {
        if ((!disabledButtonForm && showPopUp === PopUpCodes.CREATE) || showPopUp === PopUpCodes.UPDATE) {
            dispatch(updateControlExpedientItemAction()).then((response: any) => {
                response && onCloseForm();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(removeControlExpedientItemAction()).then((response: any) => {
                response && onCloseForm();
            });
        }
    };

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setShowPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />
            <ControlExpedientsFilter />
            <ControllExpedientsList />
            <PopUpForm
                content={<ControlerExpedientsForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false}
                onCancel={onCloseForm}
                onSave={onAccept}
                onDimissed={onDimissed}
                height={"30vh"}
                isDisabledButton={disabledButtonForm}
                isLoading={loading}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCloseForm}
                onAccept={onAccept}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                loading={loading}
            />
        </>
    );
};
