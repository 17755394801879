import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IGlobalConfig {
    siteNavFixed: boolean;
    showSiteNav: boolean;
    persistScrollOnDetailsList: boolean;
}
const initialState: IGlobalConfig = {
    siteNavFixed: false,
    showSiteNav: false,
    persistScrollOnDetailsList: false,
};

export const globalConfigSlice = createSlice({
    name: "globalConfig",
    initialState,
    reducers: {
        setFixedSiteNav: (state, action: PayloadAction<boolean>) => {
            state.siteNavFixed = action.payload;
        },
        setPersistScrollOnDetailsList: (state, action: PayloadAction<boolean>) => {
            state.persistScrollOnDetailsList = action.payload;
        },
        setShowSiteNav: (state, action: PayloadAction<boolean>) => {
            state.showSiteNav = action.payload;
        },
        resetShowSiteNav: (state) => {
            state.showSiteNav = false;
        },
        reset: () => initialState,
    },
});

export const { setFixedSiteNav, setPersistScrollOnDetailsList, setShowSiteNav, resetShowSiteNav } = globalConfigSlice.actions;

export default globalConfigSlice.reducer;
