import { createUseStyles } from "react-jss";

export const DocumentContainerStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.lg,
        padding: theme.paddings.small,
    },
    documentHeader: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        alignItems: "center",
        flexWrap: "wrap",
    },
    createdByEmail: {
        cursor: "pointer",
    },
}));
