import { useTheme } from "react-jss";
import { UpcomingAppointmentsSortListStyle } from "./columnStyle.jss";
import { useTranslation } from "react-i18next";
import { IColumn } from "@fluentui/react";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { IUpcomingAppointmentSortItem } from "../../../../../models/upcomingAppointmentSort/IUpcomingAppointmentSort";
import { compileNameOfProperty } from "../../../../../utils";
import { UpcomingAppointmentsSortColumnsSchema } from "./filterColumn";

export const UpcomingAppointmentsSortColumns = (featureId: string): IColumn[] => {
    const theme = useTheme();
    const styles = UpcomingAppointmentsSortListStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT" });
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 200,
            name: t("LIST.TRANSPORT_UNIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("transportUnitNumber")}
                    title={t("LIST.TRANSPORT_UNIT")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.transportUnitNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("appointmentSort"),
            minWidth: 100,
            maxWidth: 200,
            name: t("LIST.APPOINMENT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("appointmentSort")}
                    title={t("LIST.APPOINMENT_NUMBER")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.appointmentSort}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("date"),
            minWidth: 100,
            maxWidth: 200,
            name: t("LIST.DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("date")}
                    title={t("LIST.DATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.date}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("terminalDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("LIST.TERMINAL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("terminalDescription")}
                    title={t("LIST.TERMINAL")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.terminalDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("inspectors"),
            minWidth: 100,
            maxWidth: 200,
            name: t("FILTER.INSPECTOR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("inspectors")}
                    title={t("FILTER.INSPECTOR")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => (
                <span className={styles.inspectorExpedient}>
                    {item.inspectors &&
                        item.inspectors.map((item) => {
                            return <span key={item}>{item}</span>;
                        })}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("customerName"),
            minWidth: 100,
            maxWidth: 200,
            name: t("LIST.CUSTOMER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("customerName")}
                    title={t("LIST.CUSTOMER")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.customerName}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("transportUnitSizeCode"),
            minWidth: 100,
            maxWidth: 200,
            name: t("LIST.SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("transportUnitSizeCode")}
                    title={t("LIST.SIZE")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.transportUnitSizeCode}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUpcomingAppointmentSortItem>("requestStateDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("LIST.STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUpcomingAppointmentSortItem>("requestStateDescription")}
                    title={t("LIST.STATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IUpcomingAppointmentSortItem) => <span>{item.requestStateDescription}</span>,
            isResizable: true,
        },
    ];

    return UpcomingAppointmentsSortColumnsSchema(columns, featureId);
};
