import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IManagementRepresentation } from "../../../../../models/management/representation/IManagementRepresentation";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/management/representation/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils";
import { convertToLocal } from "../../../../../utils/dateUtils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Link } from "../../../../common/links/links";
import { ManagementRepresentationListColumnStyle } from "./columnStyle.jss";
import { widths } from "../../../../../styles/romeu/widths";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { setManagementRepresentationPopUp } from "../../../../../redux/reducers/management/representation/form";
import { getMananagementRepresentationAction } from "../../../../../redux/actions/management/representation/representation";

export const ManagementRepresentationListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.REPRESENTATION.LIST" });
    const theme = useTheme();
    const styles = ManagementRepresentationListColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.managementRepresentationList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().managementRepresentationList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IManagementRepresentation>("userName"),
            minWidth: 175,
            maxWidth: 240,
            name: t("USERNAME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("userName")}
                    title={t("USERNAME")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("userName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => <span>{item.userName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("userDocumentNumber"),
            minWidth: 105,
            maxWidth: 150,
            name: t("USERDOCUMENTNUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("userDocumentNumber")}
                    title={t("USERDOCUMENTNUMBER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("userDocumentNumber"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => <span>{item.userDocumentNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("representativeName"),
            minWidth: 195,
            maxWidth: 240,
            name: t("REPRESENTATIVENAME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("representativeName")}
                    title={t("REPRESENTATIVENAME")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("representativeName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => <span>{item.representativeName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("representativeDocumentNumber"),
            minWidth: 120,
            maxWidth: 200,
            name: t("REPRESENTATIVEDOCUMENTNUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("representativeDocumentNumber")}
                    title={t("REPRESENTATIVEDOCUMENTNUMBER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("representativeDocumentNumber"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => <span>{item.representativeDocumentNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("startDate"),
            minWidth: 90,
            maxWidth: 130,
            name: t("STARTDATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("startDate")}
                    title={t("STARTDATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("startDate"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => (
                <span>{item.startDate ? convertToLocal(item.startDate.toString(), FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("endDate"),
            minWidth: 80,
            maxWidth: 130,
            name: t("ENDDATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("endDate")}
                    title={t("ENDDATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("endDate"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => (
                <span>{item.endDate ? convertToLocal(item.endDate.toString(), FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("documentName"),
            minWidth: 100,
            maxWidth: 200,
            name: t("AUTHORIZATION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("documentName")}
                    title={t("AUTHORIZATION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("documentName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => (
                <Link
                    title={item.documentName!}
                    to={item.documentUri ? item.documentUri : ""}
                    target={"_blank"}
                />
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRepresentation>("validated"),
            minWidth: 80,
            maxWidth: 100,
            name: t("VALID"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRepresentation>("validated")}
                    title={t("VALID")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementRepresentation>("validated"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementRepresentation) => (
                <span>
                    {" "}
                    <BooleanValue value={item.validated}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: "",
            minWidth: 50,
            maxWidth: 60,
            name: "",
            onRender: (item: IManagementRepresentation) => (
                <CustomIcons
                    iconName={NameIcons.EDIT}
                    onClick={() => {
                        dispatch(setManagementRepresentationPopUp(PopUpCodes.CREATE));
                        dispatch(getMananagementRepresentationAction(String(item.id)));
                    }}
                    size={widths.management.icons}
                    margin={"0px"}
                />
            ),
            isResizable: true,
        },
    ];

    return columns;
};
