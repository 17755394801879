import Cookies from "js-cookie";

interface CookieOptions {
    expires?: number | Date;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: "Strict" | "Lax" | "None";
}

class CookieService {
    static get(key: string) {
        return Cookies.get(key);
    }

    static set(key: string, value: string, options?: CookieOptions) {
        return Cookies.set(key, value, options);
    }

    static remove(key: string, options?: CookieOptions) {
        return Cookies.remove(key, options);
    }
}

export default CookieService;
