import { useTranslation } from "react-i18next";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { TextInput } from "../../../common/inputs/textInput";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { IManagementAuthorizedTransportUnitNumber } from "../../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";
import { compileNameOfProperty } from "../../../../utils";
import { resetManagementAuthorizedTransportUnitNumberFilter, updateFilterProperty } from "../../../../redux/reducers/management/authorizedTransportUnitNumber/filter";
import { useCallback } from "react";
import { IGenericPayload } from "../../../../models";
import { BooleaMultiSelector } from "../../../common/selectors";
import { useTheme } from "react-jss";
import { ManagementAuthorizedTransportUnitNumberFilterStyle } from "./filterStyle.jss";
import { fetchManagementAuthorizedTransportUnitNumberListAction } from "../../../../redux/actions/management/authorizedTransportUnitNumber/authorizedTransportUnitNumber";

export const ManagementAuthorizedTransportUnitNumberFilter = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.AUTHORIZED_TRANSPORT_UNIT_NUMBER" });
    const { filter } = useAppSelector((state) => state.managementAuthorizedTransportUnitNumberFilter);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ManagementAuthorizedTransportUnitNumberFilterStyle({ theme });
    const onApply = () => {
        dispatch(fetchManagementAuthorizedTransportUnitNumberListAction());
    };

    const onReset = () => {
        dispatch(resetManagementAuthorizedTransportUnitNumberFilter());
        onApply();
    };

    const onUpdateHeaderProperty = (name: string, value: any) => {
        dispatch(updateFilterProperty({ name, value }));
    };

    const onInputChange = (event: any, name: string) => {
        onUpdateHeaderProperty(name, event.target.value);
    };
    const onBooleanSelectorChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const FilterItems = (
        <div className={style.filterContainer}>
                <div className={style.description}>
                    <TextInput
                        value={filter.transportUnitNumber}
                        propertyName={compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("transportUnitNumber")}
                        label={t("TRANSPORT_UNIT_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onApply}
                        
                    />
                </div>

                <div className={style.selector}>
                    <BooleaMultiSelector
                        selectedKey={filter.authorized}
                        propertyName={compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("authorized")}
                        title={t("AUTHORIZEDS")}
                        onChange={onBooleanSelectorChange}
                    />
                </div>
            </div>
    ) ;
            
    

    return (
        <FilterHeader
            content={FilterItems}
            onApply={onApply}
            onReset={onReset}
        />
    );
};
