import { createUseStyles } from "react-jss";

export const PaginationStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        padding: "15px",
        alignItems: "center"
    },
    child: {
        display: "flex",
        marginLeft: "auto"
    },
    requests: {
        color: theme.colors.palette.purple,
        fontSize: theme.fonts.sizes.base
    },
    icon: {
        fontSize: theme.fonts.sizes.base,
        cursor: "pointer",
        color: theme.colors.palette.purple,
        padding: "5px"
    },
    iconDisabled: {
        color: theme.colors.palette.purpleLighter,
        cursor: "default",

    },
    pages: {
        display: "flex",
        color: theme.colors.palette.purple,
        fontSize: theme.fonts.sizes.md,
        textAlign: "center",
        letterSpacing: "3px",
        marginLeft: "auto"
    }
}));
