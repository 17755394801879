import { createUseStyles } from "react-jss";

export const headerTitleStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        fontSize: theme.fonts.sizes.xl,
    },
    loading: {
        width: 120,
    },
}));
