import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { generatePath } from "react-router";
import { routeUrls } from "../../../../common/constants/routeUrls";
import { IRequestPendingAssignmentListItem } from "../../../../models/assignment/IAssignment";
import { compileNameOfProperty } from "../../../../utils";
import { CustomHeaderColumn } from "../../../common/customDetailsList/customHeader";
import { Link, LinkPrimary } from "../../../common/links/links";
import { AssignmentsColumnsStyle } from "./columnsStyle.jss";
import { DefaultValues } from "../../../../common/enum/DefaultValues";

export const RequestPendingAssignmentsColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = AssignmentsColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "ASSIGNMENT.LIST" });

    let path = (id: string) => {
        return generatePath(routeUrls.EDIT_REQUEST, { id });
    };

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IRequestPendingAssignmentListItem>("sortCode"),
            minWidth: 100,
            maxWidth: 100,
            name: t("POSITION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestPendingAssignmentListItem>("sortCode")}
                    title={t("POSITION")}
                    styles={styles}
                />
            ),
            onRender: (item: IRequestPendingAssignmentListItem) => <span>{item.sortCode ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestPendingAssignmentListItem>("certificateNumber"),
            minWidth: 250,
            maxWidth: 300,
            name: t("DOCUMENT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestPendingAssignmentListItem>("certificateNumber")}
                    title={t("DOCUMENT_NUMBER")}
                    styles={styles}
                />
            ),
            onRender: (item: IRequestPendingAssignmentListItem) => (
                <span>
                    {item.requestId ? (
                        <LinkPrimary
                        border
                            to={""}
                            title={item.certificateNumber}
                            onClick={() => {
                                window.open(path(String(item.requestId ?? 0)), "_blank");
                            }}
                        />
                    ) : (
                        item.certificateNumber ?? DefaultValues.EMPTY_LIST 
                    )}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestPendingAssignmentListItem>("transportUnitNumber"),
            minWidth: 130,
            maxWidth: 150,
            name: t("UTI"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestPendingAssignmentListItem>("transportUnitNumber")}
                    title={t("UTI")}
                    styles={styles}
                />
            ),
            onRender: (item: IRequestPendingAssignmentListItem) => <span>{item.transportUnitNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestPendingAssignmentListItem>("requestDescription"),
            minWidth: 100,
            maxWidth: 300,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestPendingAssignmentListItem>("requestDescription")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IRequestPendingAssignmentListItem) => <span>{item.requestDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
