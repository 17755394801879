import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { IManagementUser } from "../../../../../models/management/user/IManagementUser";
import { getMananagementUserAction } from "../../../../../redux/actions/management/user/user";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setShowPopUpUser, updateManagementUserProperty } from "../../../../../redux/reducers/management/user/form";
import { updateSortColumn } from "../../../../../redux/reducers/management/user/list";
import { store } from "../../../../../redux/store";
import { widths } from "../../../../../styles/romeu/widths";
import { compileNameOfProperty, convertToLocal } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { ManagementUserListColumnStyle } from "./columnStyle.jss";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ManagementUserListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.USER.LIST" });
    const theme = useTheme();
    const styles = ManagementUserListColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.managementUserList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().managementUserList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IManagementUser>("name"),
            minWidth: 260,
            maxWidth: 400,
            name: t("NAME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUser>("name")}
                    title={t("NAME")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUser>("name"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUser) => <span>{item.name ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUser>("entityName"),
            minWidth: 260,
            maxWidth: 350,
            name: t("ENTITY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUser>("entityName")}
                    title={t("ENTITY")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUser>("entityName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUser) => <span>{item.entityName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUser>("id"),
            minWidth: 250,
            maxWidth: 370,
            name: t("EMAIL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUser>("id")}
                    title={t("EMAIL")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUser>("id"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUser) => <span>{item.id ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUser>("roleDescription"),
            minWidth: 180,
            maxWidth: 240,
            name: t("TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUser>("roleDescription")}
                    title={t("TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUser>("roleDescription"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUser) => <span>{item.roleDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUser>("lastLoginOn"),
            minWidth: 120,
            maxWidth: 200,
            name: t("LASTLOGINON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUser>("lastLoginOn")}
                    title={t("LASTLOGINON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUser>("lastLoginOn"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUser) => (
                <span>{item.lastLoginOn ? convertToLocal(item.lastLoginOn.toString(), FormatDate.DATE_HOUR) : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementUser>("unregistered"),
            minWidth: 50,
            maxWidth: 90,
            name: t("UNREGISTERED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementUser>("unregistered")}
                    title={t("UNREGISTERED")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementUser>("unregistered"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementUser) => (
                <span>
                    <BooleanValue value={item.unregistered}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: "",
            minWidth: 40,
            maxWidth: 70,
            name: "",
            onRender: (item: IManagementUser) => (
                <CustomIcons
                    iconName={NameIcons.EDIT}
                    onClick={() => {
                        dispatch(setShowPopUpUser(PopUpCodes.UPDATE));
                        dispatch(updateManagementUserProperty({ name: "roleId", value: item.roleId }));
                        dispatch(getMananagementUserAction(String(item.id)));
                    }}
                    size={widths.management.icons}
                />
            ),
            isResizable: true,
        },
    ];

    return columns;
};
