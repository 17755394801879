import { createUseStyles } from "react-jss";

export const RequestListActuationContainerStyle= createUseStyles((theme: any) => ({
    manageActuationContainer : {
        display:"flex", 
        flexDirection:"column", 
        width:"99%", 
        gap: theme.gaps.sizes.base
    }, 
    list : {
        maxHeight:400, 
        overflow:"auto"
    }
}));
