import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterSealTypesList } from "./list/list";
import { setSealTypes } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterSealTypesList } from "../../../../../redux/reducers/resources/master/sealTypes/list";

export class MasterSealTypesContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterSealTypesList());
        store.dispatch(setSealTypes([]));
    }

    render(): React.ReactNode {
        return (
            <MasterSealTypesList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
