import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { routeUrls } from "../../common/constants";
import { NavButtonPrimary, NavButtonSecondary } from "../common/navButtons/navButtons";
import { TitleH1 } from "../common/titles/titles";
import { LoginStyle } from "./login.jss";
import { useNavigate } from "react-router-dom";

interface ICallbackProps {
    userManager: any;
}

export const Login = (props: ICallbackProps) => {
    const { t } = useTranslation();
    const loginStyle = LoginStyle();

    const navigate = useNavigate();

    useEffect(() => {
        props.userManager.getUser().then((result: any) => {
            if (result) {
                return navigate(routeUrls.REQUEST);
            }
            return null;
        });
    }, []);

    return (
        <>
            <div className={loginStyle.content}>
                <TitleH1 title={t("LOGIN.WELCOME")} />
                <div className={loginStyle.card}>
                    <NavButtonPrimary
                        title={t("LOGIN.SIGN-IN")}
                        to={routeUrls.REQUEST}
                    />
                    <NavButtonSecondary
                        title={t("LOGIN.SIGN-UP")}
                        to="signup"
                    />
                </div>
            </div>
        </>
    );
};
