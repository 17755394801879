import React from "react";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { RequiredSpan } from "../../required/requiredSpan";
import { LabelTextStyle } from "./labelTextStyle.jss";

interface ILabelTextProps {
    name: string;
    value?: any;
    className?: string;
    styleProps?: ILabelTextStyleProps;
    hiddenValue?: boolean;
    isRequired?: boolean;
}

export interface ILabelTextStyleProps {
    labelColor?: string;
    labelSize?: string;
    dataColor?: string;
    dataSize?: string;
    dataWidth?: string;
}

export const LabelText: React.FC<ILabelTextProps> = ({ name, value, className, styleProps, hiddenValue, isRequired }) => {
    const theme = useTheme();
    const style = LabelTextStyle({ theme, styleProps });
    const valueRevised = value ? value : DefaultValues.EMPTY;
    return (
        <div className={style.labelContainer}>
            <label className={className ? className : style.label}>
                {name} {isRequired && <RequiredSpan />}
            </label>
            {!hiddenValue && <label className={className ? className : style.data}>{valueRevised}</label>}
        </div>
    );
};
