import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDockQueueRule } from "../../../../models/management/dockQueueRule/IDockQueueRule";

interface ManagementDockQueueRuleListState {
    list?: IDockQueueRule[];
    loading: boolean;
}

const initialState: ManagementDockQueueRuleListState = {
    list: undefined,
    loading: false,
};

export const managementDockQueueRuleListSlice = createSlice({
    name: "managementDockQueueRuleList",
    initialState,
    reducers: {
        resetManagementDockQueueRuleList: () => initialState,

        setLoadingManagementDockQueueRuleList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementDockQueueRuleList: (state, action: PayloadAction<IDockQueueRule[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetManagementDockQueueRuleList, setLoadingManagementDockQueueRuleList, fetchManagementDockQueueRuleList } =
    managementDockQueueRuleListSlice.actions;

export default managementDockQueueRuleListSlice.reducer;
