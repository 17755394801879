import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterTerminal } from "../../../../../models/resources/master/ITerminal";

export interface IMasterTerminalState {
    list?: IMasterTerminal[];
    loading: boolean;
}

const initialState: IMasterTerminalState = {
    loading: false,
};

export const masterTerminalList = createSlice({
    name: "masterOrganismList",
    initialState,
    reducers: {
        resetMasterTerminalList: () => initialState,
        setLoadingTerminalList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTerminalList: (state, action: PayloadAction<IMasterTerminal[]>) => {
            state.list = action.payload;
        },
    },
});

export const {resetMasterTerminalList, setLoadingTerminalList, setMasterTerminalList } = masterTerminalList.actions;

export default masterTerminalList.reducer;
