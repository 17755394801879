import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterFinalDestinationTypesFormController } from "../controller/controller";
import { MasterFinalDestinationTypesColumns } from "./column/column";
import { fetchMasterFinalDestinationTypesList } from "../../../../../../redux/actions/resources/master/finalDestinationType/finalDestinationType";

interface IMasterDestinationFinalTypes {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterDestinationFinalTypes: React.FC<IMasterDestinationFinalTypes> = ({ heigthPopUp, widthPopUp }) => {
    const { list, loading } = useAppSelector((state) => state.masterFinalDestinationTypesList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return MasterFinalDestinationTypesColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterFinalDestinationTypesList());
    }, [list]);

    return loading ? (
        <Skeleton rows={5}></Skeleton>
    ) : (
        <>
            <CustomDetailsList
                data={list ?? []}
                renderColumns={renderColumns}
            ></CustomDetailsList>
            <MasterFinalDestinationTypesFormController
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </>
    );
};
