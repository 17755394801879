import { Method } from "../../common/enum";
import { apiFetchOAuthWithClaims } from "../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/inspectioncards`;

export class InspectionCardApi {
    async getRequestInspectionCardAsync(requestId:number): Promise<string> {
        const url = `${baseURL}/${requestId}/report`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<string>();
    }
}