import { IConfigEditRequest } from "../../../../../models/requests/IConfigEditRequest";
import { IField } from "../../../../../models/requests/IUserRoleView";
import { compileNameOfProperty, isFieldReadOnly, isFieldRequired, isFieldVisible } from "../../../../../utils";

export const generateEditRequestPropertySchema = (fields: IField[]): IConfigEditRequest => {
    return {
        transportUnitId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitId")),
        },
        date: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("date")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("date")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("date")),
        },
        turnId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("turnId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("turnId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("turnId")),
        },
        customerId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("customerId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("customerId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("customerId")),
        },
        invoiceCustomerId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceCustomerId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceCustomerId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceCustomerId")),
        },
        customerReference: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("customerReference")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("customerReference")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("customerReference")),
        },
        booking: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("booking")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("booking")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("booking")),
        },
        customsStateId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("customsStateId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("customsStateId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("customsStateId")),
        },
        temporaryRegime: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("temporaryRegime")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("temporaryRegime")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("temporaryRegime")),
        },
        certificate: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("certificate")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("certificate")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("certificate")),
        },
        goodInformation: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("goodInformation")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("goodInformation")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("goodInformation")),
        },

        //UTI
        uti: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("uti")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("uti")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("uti")),
        },
        uti_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("uti_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("uti_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("uti_create")),
        },
        transportUnitSizeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitSizeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitSizeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitSizeId")),
        },
        transportUnitNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("transportUnitNumber")),
        },
        tractorNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("tractorNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("tractorNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("tractorNumber")),
        },

        packages: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("packages")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("packages")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("packages")),
        },
        grossWeight: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("grossWeight")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("grossWeight")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("grossWeight")),
        },
        palletized: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("palletized")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("palletized")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("palletized")),
        },
        originSeal: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("originSeal")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("originSeal")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("originSeal")),
        },
        dockPriority: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("dockPriority")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("dockPriority")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("dockPriority")),
        },
        merchandiseOriginId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseOriginId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseOriginId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseOriginId")),
        },
        humanConsumption: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("humanConsumption")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("humanConsumption")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("humanConsumption")),
        },
        merchandiseTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseTypeId")),
        },
        description: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("description")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("description")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("description")),
        },
        tariffHeading: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("tariffHeading")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("tariffHeading")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("tariffHeading")),
        },
        merchandiseArrivalShip: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseArrivalShip")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseArrivalShip")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseArrivalShip")),
        },
        summaryNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("summaryNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("summaryNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("summaryNumber")),
        },
        terminalId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("terminalId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("terminalId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("terminalId")),
        },
        parkingAuthorization: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("parkingAuthorization")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("parkingAuthorization")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("parkingAuthorization")),
        },
        fumigated: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("fumigated")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("fumigated")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("fumigated")),
        },
        dryCharge: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("dryCharge")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("dryCharge")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("dryCharge")),
        },
        organicProduct: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("organicProduct")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("organicProduct")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("organicProduct")),
        },
        halalCertified: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("halalCertified")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("halalCertified")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("halalCertified")),
        },
        exporter: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("exporter")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("exporter")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("exporter")),
        },
        importer: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("importer")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("importer")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("importer")),
        },
        carrierId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("carrierId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("carrierId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("carrierId")),
        },
        remarks: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("remarks")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("remarks")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("remarks")),
        },
        inspectionPresence: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspectionPresence")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspectionPresence")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspectionPresence")),
        },
        representativePresenceId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("representativePresenceId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("representativePresenceId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("representativePresenceId")),
        },
        finalDestinationTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("finalDestinationTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("finalDestinationTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("finalDestinationTypeId")),
        },
        carrierPhone: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("carrierPhone")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("carrierPhone")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("carrierPhone")),
        },
        c5Number: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("c5Number")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("c5Number")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("c5Number")),
        },
        carrierDockAssignmentNotifiedOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("carrierDockAssignmentNotifiedOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("carrierDockAssignmentNotifiedOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("carrierDockAssignmentNotifiedOn")),
        },
        entryOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("entryOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("entryOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("entryOn")),
        },
        dockAssignmentOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("dockAssignmentOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("dockAssignmentOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("dockAssignmentOn")),
        },
        dockStartOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("dockStartOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("dockStartOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("dockStartOn")),
        },
        dockEndOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("dockEndOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("dockEndOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("dockEndOn")),
        },
        exitOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("exitOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("exitOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("exitOn")),
        },
        // INSPECTIONS
        inspection: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection")),
        },
        inspection_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_create")),
        },
        inspection_delete: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_delete")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_delete")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_delete")),
        },
        inspection_update: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_update")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_update")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_update")),
        },
        inspection_organismId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_organismId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_organismId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_organismId")),
        },
        inspection_certificateTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_certificateTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_certificateTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_certificateTypeId")),
        },
        inspection_certificateNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_certificateNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_certificateNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_certificateNumber")),
        },
        inspection_exporter: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_exporter")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_exporter")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_exporter")),
        },
        inspection_importer: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_importer")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_importer")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_importer")),
        },
        inspection_year: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_year")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_year")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_year")),
        },
        inspection_inspectorArrivedOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_inspectorArrivedOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_inspectorArrivedOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_inspectorArrivedOn")),
        },
        inspection_inspectorId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_inspectorId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_inspectorId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_inspectorId")),
        },
        inspection_vuaOrganismCode: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("inspection_vuaOrganismCode")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("inspection_vuaOrganismCode")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("inspection_vuaOrganismCode")),
        },

        actuation_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_create")),
        },
        actuation_update: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_update")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_update")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_update")),
        },
        actuation_delete: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_delete")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_delete")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_delete")),
        },
        actuation_date: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_date")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_date")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_date")),
        },
        actuation_description: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_description")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_description")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_description")),
        },
        actuation_instructions: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_instructions")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_instructions")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_instructions")),
        },
        actuation_organismId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("actuation_organismId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("actuation_organismId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("actuation_organismId")),
        },

        // INVOICE CONCEPTS
        invoiceConceptAdmin: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_create")),
        },
        invoiceConcept: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept")),
        },
        invoiceConcept_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_create")),
        },
        invoiceConcept_invoiced: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_invoiced")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_invoiced")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_invoiced")),
        },
        invoiceConcept_validationRemarks: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_validationRemarks")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_validationRemarks")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_validationRemarks")),
        },
        invoiceConcept_invoicingRemarks: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_invoicingRemarks")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_invoicingRemarks")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_invoicingRemarks")),
        },

        // DOCUMENTS
        document: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("document")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("document")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("document")),
        },
        document_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("document_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("document_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("document_create")),
        },
        document_delete: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("document_delete")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("document_delete")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("document_delete")),
        },

        // IMAGES

        image_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("image_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("image_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("image_create")),
        },
        image_delete: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("image_delete")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("image_delete")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("image_delete")),
        },

        // INCIDENCES
        incidence: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence")),
        },
        incidence_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_create")),
        },
        incidence_update: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_update")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_update")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_update")),
        },
        incidence_delete: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_delete")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_delete")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_delete")),
        },
        incidence_organism: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_organism")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_organism")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_organism")),
        },
        incidence_operational: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_operational")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_operational")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_operational")),
        },

        // VUA
        vuaNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("vuaNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("vuaNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("vuaNumber")),
        },

        // STATE
        stateId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("stateId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("stateId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("stateId")),
        },

        // STATE HISTORY
        requestStateHistory: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory")),
        },
        requestStateHistory_stateDescription: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_stateDescription")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_stateDescription")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_stateDescription")),
        },
        requestStateHistory_createdOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_createdOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_createdOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_createdOn")),
        },
        requestStateHistory_date: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_date")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_date")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_date")),
        },
        // STATE
        dockId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("dockId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("dockId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("dockId")),
        },
        newSeals: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("newSeals")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("newSeals")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("newSeals")),
        },
        batches: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("batches")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("batches")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("batches")),
        },
        merchandiseCategoryId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseCategoryId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseCategoryId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("merchandiseCategoryId")),
        },
        originCountryCode: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("originCountryCode")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("originCountryCode")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("originCountryCode")),
        },
        appointmentTimeZoneId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTimeZoneId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTimeZoneId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTimeZoneId")),
        },
        appointmentTimeZones: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTimeZones")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTimeZones")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTimeZones")),
        },
        appointmentTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("appointmentTypeId")),
        },
        appointmentCreate: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("appointmentCreate")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("appointmentCreate")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("appointmentCreate")),
        },
        history: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("history")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("history")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("history")),
        },
        customsAuthorityArrivalOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("customsAuthorityArrivalOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("customsAuthorityArrivalOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("customsAuthorityArrivalOn")),
        },
        readyForInstructionOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("readyForInstructionOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("readyForInstructionOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("readyForInstructionOn")),
        },
        representativeArrivalOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("representativeArrivalOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("representativeArrivalOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("representativeArrivalOn")),
        },
        incidence_openingOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_openingOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_openingOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_openingOn")),
        },
        incidence_closingOn: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("incidence_closingOn")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("incidence_closingOn")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("incidence_closingOn")),
        },
        invoiceConcept_synchronize: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_synchronize")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_synchronize")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("invoiceConcept_synchronize")),
        },
        requestStateHistory_viewAll: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_viewAll")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_viewAll")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigEditRequest>("requestStateHistory_viewAll")),
        },
    };
};
