import { useEffect } from "react";
import { fetchMasterAppointmentPermissionList } from "../../../../../../redux/actions/masterAppointment/appointmentPermission/appointmentPermission";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterAppointmentPermissionColumns } from "./column/column";
export const ManagementAppointmentPermissionList = () => {
    const dispatch = useAppDispatch();
    const { loading, list } = useAppSelector((state) => state.masterAppointmentPermissionList);
    const onRenderColumns = () => {
        return MasterAppointmentPermissionColumns();
    };
    useEffect(() => {
        list === undefined && dispatch(fetchMasterAppointmentPermissionList());
    }, [list]);

    return loading ? (
        <Skeleton rows={3}></Skeleton>
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={onRenderColumns}
            height={"calc(100vh - 8.73rem)"}
        ></CustomDetailsList>
    );
};
