import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { RequestIncidenceType } from "../../../../../../../common/enum/categoryIncidence";
import { IGenericPayload } from "../../../../../../../models";
import { ICreateIncidence } from "../../../../../../../models/requests/IRequestIncidence";
import { postIncidencesRequest } from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import {  resetIncidenceFormDataState, updateIncidenceFormProperty } from "../../../../../../../redux/reducers/request/edit/form/incidence";
import { heights } from "../../../../../../../styles/romeu/heights";
import { widths } from "../../../../../../../styles/romeu/widths";
import { compileNameOfProperty } from "../../../../../../../utils";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../../../../common/requiredMessage/requiredMessage";
import { EditRequestIncidenceForm } from "./form/form";
interface IEditRequestIncidenceFormControllProps {
    showIncidenceForm: boolean;
    setShowIncidenceForm: (value: any) => any;
    category: string;
    requestId: number;
}

export const EditRequestIncidenceFormControll: React.FC<IEditRequestIncidenceFormControllProps> = ({
    setShowIncidenceForm,
    showIncidenceForm,
    category,
    requestId,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });
    const dispatch = useAppDispatch();
    const { form, loadingForm } = useAppSelector((store) => store.editRequestIncidenceForm);
    const titlePopUp = form.id ? t("UPDATEINCIDENCE") : t("REGISTINCIDENCE");

    const onSaveIncidenceForm = () => {
        dispatch(postIncidencesRequest(requestId, form)).then((response: any) => {
            response && onCloseIncidenceForm();
        });
    };

    const onDimissed = () => {
        const payload: IGenericPayload = {
            name: compileNameOfProperty<ICreateIncidence>("category"),
            value: category,
        };
        dispatch(resetIncidenceFormDataState());
        dispatch(updateIncidenceFormProperty(payload));
    };

    const onCloseIncidenceForm = () => {
        setShowIncidenceForm(false);
    };
    const disabledButton = useMemo(() => {
        let item: any = form;
        let keys = ["typeId"];

        form.category === RequestIncidenceType.ORGANISM && keys.push("organismId");

        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }

        return false;
    }, [form]);

    return (
        <PopUpForm
            isVisible={showIncidenceForm}
            onSave={onSaveIncidenceForm}
            onCancel={onCloseIncidenceForm}
            content={<EditRequestIncidenceForm showPopUp={showIncidenceForm} />}
            height={isMobile ? heights.editRequest.incidence.mobileHeight : heights.editRequest.incidence.height}
            width={isMobile ? widths.editRequest.incidence.mobileWidth : widths.editRequest.incidence.width}
            title={titlePopUp}
            isDisabledButton={loadingForm ? true : disabledButton}
            leftButtonContent={<RequiredFieldsMessage></RequiredFieldsMessage>}
            isLoading={loadingForm}
            onDimissed={onDimissed}
        />
    );
};
