import { useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";

export const useAppointment = () => {
    const {
        editRequest: { headerRequest, copyHeaderRequest },
        editRequestAppointment: { form: createRequestAppointment },
        appointmentAvailableZone: {
            available: { timeZones, appointmentTypeId },
            forceAppointmentType: isForceAppointmentType,
        },
    } = useAppSelector((state) => state);

    const isChangeDate = useMemo(() => headerRequest.date !== copyHeaderRequest.date, [headerRequest.date]);

    const isNotAvailableTimeZones = useMemo(() => {
        if (createRequestAppointment.appointmentTypeId) {
            return createRequestAppointment.appointmentTypeId && timeZones?.length === 0;
        }
        return headerRequest.appointmentTypeId !== null && headerRequest.appointment === null && timeZones?.length === 0;
    }, [timeZones, headerRequest.appointmentTypeId, headerRequest.appointment, appointmentTypeId, createRequestAppointment]);

    const forceAppointmentType = useMemo(() => isForceAppointmentType, [isForceAppointmentType]);

    return { isNotAvailableTimeZones, forceAppointmentType, isChangeDate };
};
