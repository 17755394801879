import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptyErrorHandler, IErrorHandler } from "../../../models/common/IErrorHandler";

interface ErrorHandlerState {
    errorHandlerData: IErrorHandler;
}

const initialState: ErrorHandlerState = {
    errorHandlerData: emptyErrorHandler(),
};

export const errorHandlerSlice = createSlice({
    name: "errorHandler",
    initialState,
    reducers: {
        setErrorHandler: (state, action: PayloadAction<IErrorHandler>) => {
            state.errorHandlerData = action.payload;
        },
        resetErrorHandler: () => initialState,
    },
});

export const { setErrorHandler, resetErrorHandler } = errorHandlerSlice.actions;

export default errorHandlerSlice.reducer;
