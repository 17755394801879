import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterMerchandiseCategoryForm, setMasterMerchandiseCategoryForm } from "../../../../reducers/resources/master/merchandiseCategory/form";
import { setLoadingMasterMerchandiseCategoryList, setMasterMerchandiseCategoryList } from "../../../../reducers/resources/master/merchandiseCategory/list";
import { AppThunk, store } from "../../../../store";


const catalogApi = new CatalogAPI();

export const fetchMasterMerchandiseCategoryList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterMerchandiseCategoryList(true));
        const response = await catalogApi.getMasterMerchandiseCategoryList();
        dispatch(setMasterMerchandiseCategoryList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterMerchandiseCategoryList(false));
    }
};

export const fetchMasterMerchandiseCategory =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterMerchandiseCategoryForm(true));
            const response = await catalogApi.getMasterMerchandiseCategory(id);
            dispatch(setMasterMerchandiseCategoryForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterMerchandiseCategoryForm(false));
        }
    };


    export const postMasterMerchandiseCategory = (): any => {
        return async (dispatch: any) => {
            try {
                const { form } = store.getState().masterMerchandiseCategoryForm;
                dispatch(setLoadingMasterMerchandiseCategoryForm(true));
                if (form.id) {
                    await catalogApi.putMasterMerchandiseCategory(form);
                } else {
                    await catalogApi.postMasterMerchandiseCategory(form);
                }
                dispatch(fetchMasterMerchandiseCategoryList());
                return true;
            } catch (error) {
                throwErrorToastrAsync(error);
                dispatch(setLoadingMasterMerchandiseCategoryForm(false));
                return false;
            } 
        };
    };


    export const patchSortMerchandiseCategoryList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterMerchandiseCategoryList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterMerchandiseCategoryList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterMerchandiseCategoryList(false));
        }
    };
