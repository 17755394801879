import { Method } from "../../../common/enum";
import { IConfigDockBlockItem } from "../../../models/config/blockDock";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/catalog/dockBlockrelationship`;

export class ConfigDockBlockAPI {
    async getConfigDockBlockList(filter: any): Promise<IConfigDockBlockItem[]> {
        const url = `${baseURL}/all`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IConfigDockBlockItem[]>();
    }

    async getConfigDockBlock(idConfig: number): Promise<IConfigDockBlockItem> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IConfigDockBlockItem>();
    }
    async deleteConfigDockBlock(idConfig: number): Promise<boolean> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
    async postConfigDockBlock(config: any): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(config).execute<boolean>();
    }
    async putConfigDockBlock(config: any): Promise<boolean> {
        const url = `${baseURL}/${config.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(config).execute<boolean>();
    }
}
