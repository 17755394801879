import { toast } from "react-toastify";
import { IToastifyConfig, IToastifyWithConf } from "../../../models/common/IToastify";
import i18n from "../../../services/i18n";
import { ToastPositionType, ToastThemeType } from "../../../common/enum";

export const Toastr = (titleNode?: string, config?: IToastifyConfig, error?: Error | any) => {
    if (error) {
        const errorData = error?.response?.data;
        titleNode =
            (errorData && `Error ${errorData?.status} - ${errorData?.detail || errorData?.title} `) || error?.message || i18n.t(titleNode ?? "");
    }

    const props: IToastifyWithConf = {
        title: !error ? i18n.t(titleNode ?? "") : titleNode,
        ...config,
    };

    return {
        show: (id?: string) => {
            return toast(props.title, {
                position: props.position ? props.position : ToastPositionType.TOP_LEFT,
                theme: props.theme ? props.theme : ToastThemeType.LIGHT,
                toastId: id,
                ...config,
            });
        },
        loading: (id?: string) => {
            return toast.loading(props.title, { toastId: id, ...config });
        },
        update: (id?: string) => {
            return toast.update(id || "", { render: props.title, ...config });
        },
        error: () => {
            return toast.error(props.title, { ...config });
        },
        clear: (id?: string) => {
            return toast.dismiss(id ?? undefined);
        },
    };
};
