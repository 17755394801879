import React from "react";
import { store } from "../../../../../redux/store";
import { ManagementAppointmentdatePermissionList } from "./list/list";
import { MasterAppointmenDateRestrictionFormController } from "./controller/controller";
import { resetMasterAppointmentDatePermissionList } from "../../../../../redux/reducers/masterAppointment/appointmentDatePermission/list";
import { resetMasterAppointmentDatePermissionForm } from "../../../../../redux/reducers/masterAppointment/appointmentDatePermission/form";

export class ManagementAppointmentDatePermissionContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterAppointmentDatePermissionList());
        store.dispatch(resetMasterAppointmentDatePermissionForm());
    }

    render(): React.ReactNode {
        return (
            <>
                <ManagementAppointmentdatePermissionList />
                <MasterAppointmenDateRestrictionFormController />
            </>
        );
    }
}
