export interface ISortState {
    column: string | string[];
    orderColumn: string;
}

export const emptySortState = (): ISortState => {
    return {
        column: "",
        orderColumn: "",
    };
};
