import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { fetchAppointmentsBoardAction } from "../../../redux/actions/appointments/appointments";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { generateRandomKeys } from "../../../utils/keys";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { AppointmentBoardContainerStyle } from "./containerStyle.jss";
import { GridBoardComponent } from "./controller/grid";
import { AppointmentBoardFilter } from "./filter/filter";

export const AppointmentBoardContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT_BOARD" });
    const { list, loading, date } = useAppSelector((store) => store.appointmentBoardGrid);
    const theme = useTheme();
    const styles = AppointmentBoardContainerStyle({ theme });

    const dispatch = useAppDispatch();

    useEffect(() => {
        !loading && dispatch(fetchAppointmentsBoardAction(date));
    }, [dispatch]);

    return (
        <>
            <NavHeaderComponent title={t("BOARD")} />
            <AppointmentBoardFilter />
            <div className={styles.container}>
                {list.map((item, key) => (
                    <GridBoardComponent
                        key={`${item.merchandiseCategoryGroupDescription}-${generateRandomKeys()}`}
                        data={item}
                    />
                ))}
            </div>
        </>
    );
};
