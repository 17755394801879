import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { Disclaimer } from "../../../common/disclaimer/disclaimer";
import { NotMasterDataWarningStyle } from "./notDataWarningStyle.jss";

export const NotDataWarningMessage = () => {
    const theme = useTheme();
    const style = NotMasterDataWarningStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    return (
        <div className={style.container}>
            <Disclaimer
                body={t("NOT_DATA")}
                color="#7367f0"
                backgroundColor="#e0deff"
            />
        </div>
    );
};
