import { createUseStyles } from "react-jss";

export const VlcportHistoricFilterStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        gap: theme.gaps.filter.container,
    },
    filter: {
        display: "flex",
        flexDirection: "column", 
        gap: theme.gaps.filter.default,
       
    },
    row: {
        display: "flex",
        gap: theme.gaps.filter.default,
    },
    item:{
        width:230
    }, 
    verticalWhiteSeparator: {
        marginTop:-15,
        height:"135%",
        borderLeft: theme.borders.default.whiteBorder,
    },
}));
