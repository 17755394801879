import { createUseStyles } from "react-jss";

export const TimePickerComponentStyle = createUseStyles((theme: any) => ({
    ".MuiFormControl-root": {
        "& .MuiInput-underline:before": {
            borderBottom: "5px solid red",
        },
    },
    containerTimePicker: {
        display: "flex",
        alignContent: "center",
        fontFamily: theme.fonts.families.site,
        "& .MuiInput-root": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.table.body.color,
        },
        "& .MuiInput-root:hover:before": {
            cursor: "pointer !important",
            pointerEvents: "all",
        },
        "& .MuiFormLabel-root": {
            fontSize: theme.fonts.sizes.base,
            "& > .MuiInput-underline:before": {
                borderBottom: "5px solid #82868B",
            },
        },
        "& .MuiInput-underline:before": {
            borderBottom: "5px solid #82868B",
        },
        "& .MuiInput-root:after": {
            borderBottom: "1px solid #7367f0 !important",
        },
        "& .MuiInput-root > input": {
            color: theme.colors.table.body.color,
            "& .MuiInput-underline:before": {
                borderBottom: "2px solid #82868B",
            },
        },
    },
    label: (props: any) => ({
        "& div > input": {
            color: props.hasError ? theme.colors.font.error : theme.colors.form.input.default,
            fontSize: theme.fonts.sizes.base,
        },
        "& .MuiFormLabel-asterisk": {
            color: theme.colors.palette.red,
        },
        "& .MuiInputLabel-shrink": {
            fontSize: `${theme.colors.palette.grey} !important`,
            color: theme.colors.palette.grey,
            transform: "translate(0, 5px)",
        },
        "& .MuiInputLabel-root": {
            fontSize: theme.fonts.sizes.base,
            "& .MuiInput-underline:before": {
                borderBottom: "5px solid #82868B",
            },
        },
    }),
    timePicker: {
        zIndex: "2000000 !important",
    },
    inputLabel: {
        fontFamily: theme.fonts.families.site,
        color: theme.colors.form.input.default,
        paddingBottom: -5,
    },
    inputTime: {
        padding: "9px 0px 3.5px 0px",
        fontSize: theme.fonts.sizes.base,
        fontFamily: theme.fonts.families.site,
        borderBottom: `1.3px solid ${theme.colors.form.input.default}`,
        "&::-webkit-calendar-picker-indicator": {
            display: "none",
        },
        "&::-moz-focus-inner": {
            border: 0,
        },
        "&::-ms-clear": {
            display: "none",
        },
    },
}));
