import { useCallback, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routeUrls, SkeletonSizes } from "../../../../common/constants";
import { IPagination } from "../../../../models/common/IPagination";
import { IManagementUserSignup } from "../../../../models/management/userSignup/IManagementUserSignup";
import { fetchManagementUserSignupListAction, setPageAction } from "../../../../redux/actions/management/userSignup/userSignup";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getUserClaimsLoaded } from "../../../../redux/selectors/user/claims/claims";
import { heights } from "../../../../styles/romeu/heights";
import { sortAndPaginateArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementUserSignupListColumn } from "./column/column";

export const ManagementUserSignupList = () => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);
    const { list, sort, pagination, loading } = useAppSelector((state) => state.managementUserSignupList);

    useEffect(() => {
        isUserClaimsLoaded && !loading && dispatch(fetchManagementUserSignupListAction());
    }, [dispatch, isUserClaimsLoaded]);

    const currentList = sortAndPaginateArray(list, sort, pagination);

    const onEditItem = (userSignup: IManagementUserSignup) => {
        navigate(routeUrls.MANAGEMENT_USER_SIGNUP_FORM.replace(":id", userSignup.id!.toString()));
    };

    const onPageUp = useCallback(
        (skip: number) => {
            const currentPage: number = skip + 1;
            if (currentPage <= pagination.totalPages) {
                dispatch(setPageAction(currentPage));
            }
        },
        [pagination.totalPages],
    );

    const onFirstPage = useCallback((skip: number) => {
        if (skip !== 1) {
            dispatch(setPageAction(1));
        }
    }, []);

    const onPageDown = useCallback((skip: number) => {
        const currentPage: number = skip - 1;
        if (currentPage >= 1) {
            dispatch(setPageAction(currentPage));
        }
    }, []);

    const onLastPage = useCallback(
        (skip: number) => {
            if (skip !== pagination.totalPages) {
                dispatch(setPageAction(pagination.totalPages));
            }
        },
        [pagination.totalPages],
    );

    const renderColumns = () => {
        return ManagementUserSignupListColumn(onEditItem);
    };

    const paginationProps: IPagination = {
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
        firstItemNumber: pagination.firstItemNumber,
        lastItemNumber: pagination.lastItemNumber,
        footerTitle: t("MANAGEMENT.USER.PAGINATION_FOOTER", { firstItemNumber: pagination.firstItemNumber, lastItemNumber: pagination.lastItemNumber, totalItems: pagination.totalItems }),
        onFirstPage,
        onLastPage,
        onPageDown,
        onPageUp
    };

    return (
        <>            
            {loading || !isUserClaimsLoaded ? (
                <Skeleton rows={SkeletonSizes.MANAGEMENT_USER_SIGNUP} />
            ) : (
                <CustomDetailsList
                    data={currentList}
                    pagination={paginationProps}
                    renderColumns={renderColumns}
                    height={heights.management.tables}
                />
            )}
        </>
    );
};

