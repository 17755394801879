import { createUseStyles } from "react-jss";

export const NavButtonsStyled = createUseStyles((theme: any) => ({
    navButton: {
        display: "inline-block",
        border: "0px",
        borderRadius: theme.borders.navButton.radius,
        padding: theme.paddings.navButton.container,
        cursor: "pointer",
        fontSize: theme.fonts.sizes.base,
        fontWeight: theme.fonts.weight.normal,
        textDecoration: "none",
        textAlign: "center",
        "&:hover": {
            backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.2) 0 0)",
        },
        "& i": {
            verticalAlign: "middle",
            paddingRight: theme.paddings.navButton.icon,
        },
    },
    primary: {
        background: theme.colors.buttons.primary.background,
        color: theme.colors.buttons.primary.color,
    },
    secondary: {
        background: theme.colors.buttons.secondary.background,
        color: theme.colors.buttons.secondary.color,
    },
    success: {
        background: theme.colors.buttons.success.background,
        color: theme.colors.buttons.success.color,
    },
    danger: {
        background: theme.colors.buttons.danger.background,
        color: theme.colors.buttons.danger.color,
    },
    warning: {
        background: theme.colors.buttons.warning.background,
        color: theme.colors.buttons.warning.color,
    },
    info: {
        background: theme.colors.buttons.info.background,
        color: theme.colors.buttons.info.color,
    },
}));
