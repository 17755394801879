import { IDocument, IUserSignup } from ".";
import { UserSignupState } from "../../common/enum/UserSignupState";
import { emptyUserRegisterModel } from "./IUserSignup";

export interface IUserSignupConfirmation extends IUserSignup {
    blobs: IDocument[];
    state: UserSignupState;
    organizationId: string;
    crmCode: string;
    externalCode: string;
    imageBlob: IDocument | undefined;
    confirmation: Confirmation;
    hasRepresentativeData: boolean;
}
export interface Confirmation {
    date?: Date;
    reason: string;
    state: UserSignupState;
    user: string;
}

export const emptyUserSignupConfirmationModel = (): IUserSignupConfirmation => {
    return {
        ...emptyUserRegisterModel(),
        blobs: [],
        state: UserSignupState.PENDING,
        organizationId: "",
        crmCode: "",
        externalCode: "",
        imageBlob: undefined,
        confirmation: {
            reason: "",
            state: UserSignupState.PENDING,
            user: "",
            date: undefined,
        },
        hasRepresentativeData: false,
    };
};
