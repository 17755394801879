import { ManagementParkingValidationRuleApi } from "../../../../api/management/parkingValidation";
import { ManagementSortTableApi } from "../../../../api/management/sort/sort";
import { throwErrorToastrAsync } from "../../../../utils";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import {
    setLoadingManagementParkingValidationRuleForm,
    setManagementParkingValidationRuleForm,
} from "../../../reducers/management/parkingValidation/form";
import {
    fetchManagementParkingValidationRuleList,
    setLoadingManagementParkingValidationRuleList,
} from "../../../reducers/management/parkingValidation/list";
import { setParkingValidationRuleConfigurationView } from "../../../reducers/management/parkingValidation/view";
import { AppThunk, store } from "../../../store";

const requestValidationRuleAPI = new ManagementParkingValidationRuleApi();
const managementSort = new ManagementSortTableApi();

export const fetchManagementParkingValidationRuleListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingManagementParkingValidationRuleList(true));
        const response = await requestValidationRuleAPI.getAllRules();
        dispatch(fetchManagementParkingValidationRuleList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingManagementParkingValidationRuleList(false));
    }
};

export const postAndPutManagementParkingValidationRuleAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().managementParkingValidationRuleForm;

            dispatch(setLoadingManagementParkingValidationRuleForm(true));
            if (form.id) {
                await requestValidationRuleAPI.updateParkingValidationRuleAsync(form);
            } else {
                await requestValidationRuleAPI.createParkingValidationRuleAsync(form);
            }
            dispatch(fetchManagementParkingValidationRuleListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingManagementParkingValidationRuleForm(false));
            return false;
        }
    };
};

export const getParkingValidationConfiguration = (): any => async (dispatch: any) => {
    return new Promise<boolean>(async (resolve) => {
        try {
            const response = await requestValidationRuleAPI.getConfiguration();
            dispatch(setParkingValidationRuleConfigurationView(response));
            resolve(true);
        } catch (error) {
            throwErrorToastrAsync(error);
            resolve(false);
        }
    });
};

export const getParkingValidationRuleAsync =
    (id: number): any =>
    async (dispatch: any) => {
        return new Promise<boolean>(async (resolve) => {
            try {
                dispatch(setGlobalLoading(true));
                const response = await requestValidationRuleAPI.getParkingValidationRuleAsync(id);
                dispatch(setManagementParkingValidationRuleForm(response));
                resolve(true);
            } catch (error) {
                throwErrorToastrAsync(error);
                resolve(false);
            } finally {
                dispatch(setGlobalLoading(false));
            }
        });
    };

export const deleteParkingValidationRuleAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { id } = store.getState().managementParkingValidationRuleForm.form;

            dispatch(setLoadingManagementParkingValidationRuleForm(true));
            await requestValidationRuleAPI.deleteParkingValidationRule(id);
            dispatch(fetchManagementParkingValidationRuleListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingManagementParkingValidationRuleForm(false));
        }
    };
};

export const patchParkingValidationRulePriorityAsync =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementParkingValidationRuleForm(true));
            await managementSort.managementSort(tableName, id, sortType);
            dispatch(fetchManagementParkingValidationRuleListAction());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementParkingValidationRuleForm(false));
        }
    };
