import { createUseStyles } from "react-jss";

export const StatisticsIncidenceOperatorListStyle = createUseStyles((theme: any) => ({
    root: {
        width: "100%",
        backgroundColor: theme.colors.footerTable.background,
        display: "flex",
    },
    cell: {
        display: "flex",
        width: "100rem",
        overflow: "hidden",
    },
    footerCell: {
        fontWeight: theme.fonts.weight.bold,
    },
    fields: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
}));
