import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterRequestState } from "../../../../../models/resources/master/IRequestState";


export interface IMasterRequestStatesFormState {
    form: IMasterRequestState;
    loading: boolean;
}

const initialState: IMasterRequestStatesFormState = {
    form: {
        active: true,
        description: "",
        dockAssignment: false,
        internal: false,
        last: false,
        main: false,
    },
    loading: false,
};

export const masterRequestStatesForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterRequestStatesForm: () => initialState,
        setLoadingMasterRequestStatesForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterRequestStatesForm: (state, action: PayloadAction<IMasterRequestState>) => {
            state.form = action.payload;
        },
        updateMasterRequestStatesProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterRequestStatesForm, setLoadingMasterRequestStatesForm, setMasterRequestStatesForm, updateMasterRequestStatesProperty } =
    masterRequestStatesForm.actions;

export default masterRequestStatesForm.reducer;
