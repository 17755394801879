import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const ProfileUserNotificationContainerStyle = createUseStyles((theme: any) => ({
    notificationContainer: {
        padding: 32,
        display: "flex",
        [mediaQueries.TABLET]: {
            flexDirection: "column",
            padding: 32,
        },
        maxHeight: "calc(100vh - 15rem)",
        overflowY: "auto",
        width: "100%",
    },
    notificationContent: {
        display: "flex",
        flexDirection: "column",
        gap: 14,
        [mediaQueries.TABLET]: {
            width: "100%",
        },
        height: "calc(100vh - 19rem)",
        width: "100%",
    },

    row: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        flexDirection: "column",
        width: "100%",
    },
   
}));
