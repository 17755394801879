import { MasterAppointmentBoardAPI } from "../../../../api/masterAppointment/appointmentBoard";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IBoardHistoryAppointmentCreate, IBoardHistoryAppointmentTimeZone } from "../../../../models/masterAppointment/IAppointmentBoard";
import { convertToLocal, throwErrorToastrAsync } from "../../../../utils";
import { setAppointmentBoardEditForm, setLoadingAppointmentBoardEditForm } from "../../../reducers/masterAppointment/appointmentBoard/edit";
import { setLoadingAppointmentBoardList, setMasterAppointmentBoardList } from "../../../reducers/masterAppointment/appointmentBoard/list";

import { AppThunk } from "../../../store";

const appointmentApi = new MasterAppointmentBoardAPI();

export const fetchMasterAppointmentBoardList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingAppointmentBoardList(true));
        const response = await appointmentApi.getMasterAppointmentBoardAll();
        dispatch(setMasterAppointmentBoardList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingAppointmentBoardList(false));
    }
};

export const fetchMasterAppointmentBoard =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingAppointmentBoardEditForm(true));
            const response = await appointmentApi.getMasterAppointmentBoard(id);
            response.appointmentTimeZones = transformStringToDateTime(response.appointmentTimeZones);
            dispatch(setAppointmentBoardEditForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingAppointmentBoardEditForm(false));
        }
    };

export const postMasterAppointmentBoardForm = (form: IBoardHistoryAppointmentCreate): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingAppointmentBoardList(true));
            let customForm: IBoardHistoryAppointmentCreate = {
                ...form,
                date: convertToLocal(form.date, FormatDate.ISO),
                merchandiseCategoryGroupId:
                    String(form.merchandiseCategoryGroupId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseCategoryGroupId : undefined,
            };

            const response = await appointmentApi.postMasterAppointmentBoard(customForm);
            dispatch(fetchMasterAppointmentBoardList());
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingAppointmentBoardList(false));
            return false;
        }
    };
};

const transformStringToDateTime = (form: IBoardHistoryAppointmentTimeZone[]): IBoardHistoryAppointmentTimeZone[] => {
    form.forEach((item) => {
        item.startTime = new Date(`2000-01-01 ${item.startTime}`);
        item.endTime = new Date(`2000-01-01 ${item.endTime}`);
    });
    return form;
};
