import React, { useEffect } from "react";
import { OriginSealItemStyle } from "./sealStyle.jss";
import { ISeal } from "../../../../../../../models/requests/IEditRequest";
import { fetchSealTypes } from "../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { removeNewSealForm, removeSealEditRequest } from "../../../../../../../redux/reducers/request/edit/editRequest";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";

interface ISealProps {
    seal: ISeal;
    index: number;
    newSeal: boolean;
    isOriginalSeal?: boolean;
    isRemoveSeal?: boolean;
}

export const Seal: React.FC<ISealProps> = ({ seal, index, newSeal, isOriginalSeal, isRemoveSeal }) => {
    const style = OriginSealItemStyle();
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((store) => store.catalog.sealTypes);

    const removeSeal = () => {
        if (newSeal) {
            dispatch(removeNewSealForm(index));
        } else {
            if (seal.id) {
                dispatch(removeSealEditRequest({ id: seal.id }));
            } else {
                dispatch(removeSealEditRequest({ index }));
            }
        }
    };

    const getNewSealsDescriptionWhithCode = () => {
        let description;
        if (!isOriginalSeal) {
            if (seal.typeDescription) {
                description = `${seal.typeDescription} ${seal.code}`;
            } else {
                let type = data.find((option) => option.id === seal.typeId);
                description = `${type?.description} ${seal.code}`;
            }
        } else {
            description = seal.code;
        }
        return description;
    };

    useEffect(() => {
        data.length === 0 && dispatch(fetchSealTypes());
    }, [dispatch, data]);

    return (
        <>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.text}>{getNewSealsDescriptionWhithCode()}</div>
                    {!isRemoveSeal && (
                        <CustomIcons
                            iconName="Cancel"
                            onClick={removeSeal}
                            className={style.button}
                        ></CustomIcons>
                    )}
                </div>
            </div>
        </>
    );
};
