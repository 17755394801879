import { createUseStyles } from "react-jss";


export const ManagementAuthorizedTransportUnitNumberFilterStyle = createUseStyles((theme: any) => ({
    description: {
        width: "240px",
        maxWidth: 250,
    },
    selector: {
        width: "240px",
    },
    filterContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.filter.default,
        
    },
}));
