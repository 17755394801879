import { UserManager } from "oidc-client";

interface ICallbackProps {
    userManager: UserManager;
}

export const SilentRenewAuth = (props: ICallbackProps) => {
    props.userManager.signinCallback();

    return <></>;
};
