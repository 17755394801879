import { createUseStyles } from "react-jss";

export const TitleStyled = createUseStyles((theme: any) => ({
    title: {
        margin: "0",
        fonts: theme.fonts.families.site,
        fontWeight: theme.fonts.weight.normal,
        display:"flex", 
        gap:5, 
        width:"auto"
    },
    primary: {
        color: theme.colors.titles.primary.color,
    },
    secondary: {
        color: theme.colors.titles.secondary.color,
    },
    success: {
        color: theme.colors.titles.success.color,
    },
    danger: {
        color: theme.colors.titles.danger.color,
    },
    warning: {
        color: theme.colors.titles.warning.color,
    },
    info: {
        color: theme.colors.titles.info.color,
    },
    black: {
        color: theme.colors.titles.black.color,
    },
    bold: {
        fontWeight: theme.fonts.weight.bold,
    },
    titleH1: {
        fontSize: theme.fonts.sizes.xxxl,
        lineHeight: theme.fonts.sizes.xxxl,
    },
    titleH2: {
        fontSize: theme.fonts.sizes.xxl,
        lineHeight: theme.fonts.sizes.xxl,
    },
    titleH3: {
        fontSize: theme.fonts.sizes.xl,
        lineHeight: theme.fonts.sizes.xl,
    },
    titleH4: {
        fontSize: theme.fonts.sizes.lg,
        lineHeight: theme.fonts.sizes.lg,
    },
    titleH5: {
        fontSize: theme.fonts.sizes.md,
        lineHeight: theme.fonts.sizes.md,
    },
    titleH6: {
        fontSize: theme.fonts.sizes.base,
        lineHeight: theme.fonts.sizes.base,
    },
}));
