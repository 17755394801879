import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IGenericPayload } from "../../../../models";
import {  IControlExpedientFilter } from "../../../../models/control/expedient/IExpedient";
import { convertToLocal } from "../../../../utils";

interface IControlExpedientFilterState {
    filter: IControlExpedientFilter;
}

const initialState: IControlExpedientFilterState = {
    filter: {

        startDate: convertToLocal(new Date(), FormatDate.ISO),
        endDate: convertToLocal(new Date(), FormatDate.ISO),
    },
};

export const controlExpedientFilterSlice = createSlice({
    name: "controlExpedientFilterSlice",
    initialState,
    reducers: {
        resetcontrolExpedientFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetcontrolExpedientFilter, updateFilterProperty } = controlExpedientFilterSlice.actions;

export default controlExpedientFilterSlice.reducer;
