import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { uploadDocumentRequest } from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { heights } from "../../../../../../../styles/romeu/heights";
import { widths } from "../../../../../../../styles/romeu/widths";
import { ButtonPrimaryInverseWithBorder } from "../../../../../../common/buttons/buttons";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../../../../common/requiredMessage/requiredMessage";
import { TitleH5 } from "../../../../../../common/titles/titles";
import { DocumentContainerStyle } from "./containerStyle.jss";
import { EditRequestDocumentForm } from "./form/form";
import { EditRequestDocumentList } from "./list/list";
import { ColorType } from "../../../../../../../common/enum/ColorType";
import { DocumentTypesWithId } from "../../../../../../../common/enum";
interface IEditRequestDocumentContainerProps {
    schema: IConfigEditRequest;
    requestId: number;
}
export const EditRequestDocumentContainer: React.FC<IEditRequestDocumentContainerProps> = ({ schema, requestId }) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const style = DocumentContainerStyle({ theme });

    const { loadingDocuments } = useAppSelector((store) => store.editRequest.requestDocuments);

    const [showAttachDocumentForm, setShowAttachDocumentForm] = useState<boolean>(false);
    const [selectedTypeDocument, setDocumentType] = useState<any>();
    const [documentRequest, setDocumentRequest] = useState<any>();

    const onDimissed = () => {
        setDocumentType(0);
        setDocumentRequest(null);
    };

    const onCancel = () => {
        setShowAttachDocumentForm(false);
    };

    const onSave = () => {
        dispatch(uploadDocumentRequest(selectedTypeDocument, documentRequest, requestId)).then((response: any) => {
            response && onCancel();
        });
    };

    const onAddjuntNewDocument = () => {
        setShowAttachDocumentForm(true);
    };
    return (
        <div className={style.container}>
            <div className={style.documentHeader}>
                <TitleH5
                    title={t("ADD_DOCUMENTS")}
                    bold={true}
                    color={ColorType.primary}
                />
                {schema.document_create.visible && (
                    <ButtonPrimaryInverseWithBorder
                        handleClick={onAddjuntNewDocument}
                        title={`+ ${t("ADD_DOCUMENTS")}`}
                    />
                )}
            </div>

            <EditRequestDocumentList
                schema={schema}
                requestId={requestId}
            />

            <PopUpForm
                isVisible={showAttachDocumentForm}
                onCancel={onCancel}
                onDimissed={onDimissed}
                content={
                    <EditRequestDocumentForm
                        setDocumentType={setDocumentType}
                        selectedTypeDocument={selectedTypeDocument}
                        setDocumentRequest={setDocumentRequest}
                    />
                }
                height={selectedTypeDocument === DocumentTypesWithId.FUMIGATION_REPORT ? "calc(85vh - 15rem)" : heights.editRequest.document.heigth}
                width={widths.editRequest.document.width}
                title={t("ADD_DOCUMENTS")}
                onSave={onSave}
                isDisabledButton={!documentRequest}
                isLoading={loadingDocuments}
                leftButtonContent={<RequiredFieldsMessage />}
            />
        </div>
    );
};
