import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";

export interface IVlcPortImportRequestForm {
    xml: string;
}

interface VlcPortImportRequestForm {
    loading: boolean;
    form: IVlcPortImportRequestForm;
    isVisible: string;
}

const initialState: VlcPortImportRequestForm = {
    loading: false,
    isVisible: "",
    form: {
        xml: "",
    },
};

export const VlcportImportRequestFormReducer = createSlice({
    name: "VlcportImportRequestFormReducer",
    initialState,
    reducers: {
        setVlcportImportRequestFormData: (state, action: PayloadAction<any>) => {
            state.form = action.payload;
        },
        setVlcportImportRequestFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setVlcPortImportRequestPopUp: (state, action: PayloadAction<string>) => {
            state.isVisible = action.payload;
        },

        resetVlcportImportRequestForm: () => initialState,
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetVlcportImportRequestForm,
    setVlcPortImportRequestPopUp,
    setVlcportImportRequestFormData,
    setVlcportImportRequestFormLoading,
    updateFormProperty
} = VlcportImportRequestFormReducer.actions;

export default VlcportImportRequestFormReducer.reducer;
