import { createUseStyles } from "react-jss";

export const ManagementUserSignupFormStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        "& > :first-child": {
            marginBottom: theme.margins.sizes.base
        },
    },
}));
