import React from "react";
import { store } from "../../../redux/store";
import { StatisticsReaderRecordRatioContainer } from "./container";
import { resetReaderRecordRatioListState } from "../../../redux/reducers/statistics/readerRecordRatio/list";

export class StatisticsReaderRecordRatioController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetReaderRecordRatioListState());
    }

    render(): React.ReactNode {
        return <StatisticsReaderRecordRatioContainer />;
    }
}
