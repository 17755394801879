import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IUserPositionType } from "../../../../../../models/catalog";
import { IUserSignup } from "../../../../../../models/user/IUserSignup";
import { fetchUserPositionTypes } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { Avatar } from "../../../../../common/avatar/avatar";
import { ILabelTextStyleProps, LabelText } from "../../../../../common/labels/labelText/labelText";
import { SignupResumeDataUserStyle } from "./resumeDataUser.jss";
import { UserType } from "../../../../../../common/enum/UserType";

interface Props {
    data: IUserSignup;
}
export const SignupResumeDataUser: React.FC<Props> = ({ data }) => {
    const styles = SignupResumeDataUserStyle();
    const { t } = useTranslation("common");
    const theme = useTheme() as any;
    const dispatch = useAppDispatch();

    const propsStyle: ILabelTextStyleProps = {
        dataColor: theme.colors.titles.secondary.color,
        dataSize: "",
        labelColor: theme.colors.titles.secondary.color,
        labelSize: "",
    };

    const userPositionTypes: IUserPositionType[] = useAppSelector((store) => store.catalog.userPositionTypes.data);

    useEffect(() => {
        !userPositionTypes.length && dispatch(fetchUserPositionTypes());
    }, [dispatch, userPositionTypes]);

    return (
        <div className={styles.container}>
            <div className={styles.userPhoto}>
                <Avatar
                    size="145px"
                    src={data.avatar?.file}
                />
            </div>
            <div className={styles.userData}>
                <div className={styles.userDataRow}>
                    {data.userType === UserType.CUSTOMER && (
                        <LabelText
                            name={t("USER.SIGNUP.USER_POSITION")}
                            value={userPositionTypes?.find((item) => item.code === data.userPositionType)?.description}
                            styleProps={propsStyle}
                        />
                    )}
                    <LabelText
                        name={t("USER.SIGNUP.NAME")}
                        value={data.name}
                        styleProps={propsStyle}
                    />
                </div>

                <div className={styles.userDataRow}>
                    <LabelText
                        name={t("USER.SIGNUP.SURNAME")}
                        value={data.surName}
                        styleProps={propsStyle}
                    />
                    <LabelText
                        name={t("USER.SIGNUP.LASTNAME")}
                        value={data.lastName}
                        styleProps={propsStyle}
                    />
                </div>

                <div className={styles.userDataRow}>
                    <LabelText
                        name={t("USER.SIGNUP.EMAIL")}
                        value={data.email}
                        styleProps={propsStyle}
                    />
                    <LabelText
                        name={t("USER.SIGNUP.PHONE_NUMBER")}
                        value={data.phoneNumber}
                        styleProps={propsStyle}
                    />
                </div>
            </div>
        </div>
    );
};
