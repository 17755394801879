import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { IManagementUserForm } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, convertToLocal } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { LabelText } from "../../../../../common/labels/labelText/labelText";
import { requestMaxLength } from "../../../../../request/form/create/validation/requestConfig";
import { myProfileResolver } from "../resolver";
import { useEffect } from "react";
import { IUserAuthData } from "../../../../../../models/user/IUserAuthData";
import { useSelector } from "react-redux";
import { getUserData } from "../../../../../../redux/selectors/userData/userData";
import { AddMultipleActuation } from "../../common/addMultipleActuation/addMultipleActuation";

interface IManagementUserDefaultFormDataProps {
    generalUserData: IManagementUserForm;
    setIsValidTab: (value: boolean) => any;
    onInputChange: (event: any) => any;
    onCheckBoxChange?: (ev?: any, checked?: boolean) => void;
    editMode: boolean;
}

export const ManagementUserDefaultFormData: React.FC<IManagementUserDefaultFormDataProps> = ({
    generalUserData,
    setIsValidTab,
    onInputChange,
    onCheckBoxChange,
    editMode
}) => {

    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const dischargedDate = generalUserData.unregistered ? convertToLocal(generalUserData.unregisteredOn, FormatDate.DATE) : "";
    const userData: IUserAuthData = useSelector(getUserData);

    const {
        register,
        formState: { errors: error, isValid },
    } = useForm<IManagementUserForm>({ mode: "onChange", resolver: myProfileResolver });

    useEffect(() => {
        setIsValidTab(isValid);
    }, [isValid]);

    return (
        <div className={`  ${style.containerForm}`}>
            <div className={`ms-Grid ${style.gridContainer}`} dir="ltr">
                {generalUserData.parentId && generalUserData.parentId !== userData?.email && (
                    <div className={`${GridClass.ROW_GRID}`}>
                        <div className={GridClass.THREE_CELL}>
                            <FormField
                                type="text"
                                value={generalUserData.parentId ?? ""}
                                maxLength={requestMaxLength.DESCRIPTION}
                                label={t("EMAIL_ADMIN")}
                                {...register(compileNameOfProperty<IManagementUserForm>("parentId"), {
                                    onChange: onInputChange,
                                    value: generalUserData.parentId ?? "",
                                    required: true,
                                })}
                                disabled={true}
                            />
                        </div>
                    </div>
                )}
                <div className={`${GridClass.ROW_GRID}`}>
                    <div className={GridClass.THREE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.name ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("NAME")}
                            {...register(compileNameOfProperty<IManagementUserForm>("name"), {
                                onChange: onInputChange,
                                value: generalUserData.name ?? "",
                                required: true,
                            })}
                            error={error.name}
                            isRequired={true}
                        />
                    </div>

                    <div className={GridClass.THREE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.surName ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("SURNAME")}
                            error={error.surName}
                            {...register(compileNameOfProperty<IManagementUserForm>("surName"), {
                                onChange: onInputChange,
                                value: generalUserData.surName ?? "",
                                required: true,
                            })}
                            isRequired={true}
                        />
                    </div>
                    <div className={GridClass.THREE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.lastName ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("LASTNAME")}
                            {...register(compileNameOfProperty<IManagementUserForm>("lastName"), {
                                onChange: onInputChange,
                                value: generalUserData.lastName ?? "",
                            })}
                        />
                    </div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.email ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("EMAIL")}
                            {...register(compileNameOfProperty<IManagementUserForm>("email"), {
                                onChange: onInputChange,
                                value: generalUserData.email ?? "",
                                required:true
                            })}
                            error={error.email}
                            disabled={editMode}
                            isRequired={true}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            value={generalUserData.phoneNumber ?? ""}
                            maxLength={requestMaxLength.DESCRIPTION}
                            label={t("PHONE_NUMBER")}
                            error={error.phoneNumber}
                            {...register(compileNameOfProperty<IManagementUserForm>("phoneNumber"), {
                                onChange: onInputChange,
                                value: generalUserData.phoneNumber ?? "",
                            })}
                        />
                    </div>
                </div>
                <AddMultipleActuation generalUserData={generalUserData} onCheckBoxChange={onCheckBoxChange} />

                {generalUserData.unregistered && (
                    <div className={GridClass.ROW_GRID}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <LabelText
                                key={t("STATE") + generalUserData.unregisteredOn}
                                name={t("STATE")}
                                value={`${t("DISCHARGED")} - ${dischargedDate}`}
                                className={style.discharged}
                            ></LabelText>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
