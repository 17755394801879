export enum ContentType {
    PDF = "application/pdf",
    EXCEL = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    WORD = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    JPEG = "image/jpeg",
    JPG = "image/jpg",
    PNG = "image/png",
    JSON = "application/json",
    TXT = "text/plain",
}

export const ContentTypeExtensions = {
    USER_DOCUMENT: [ContentType.PDF, ContentType.WORD, ContentType.JPEG, ContentType.JPG, ContentType.PNG],
};
