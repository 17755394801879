import { Method } from "../../../common/enum";
import { IConfigMerchandiseCategoryForm, IConfigMerchandiseCategoryListItem } from "../../../models/config/merchandiseCategory";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/catalog/merchandisecategoryrelationship`;

export class ConfigMerchandiseCategoryAPI {
    async getConfigMerchandiseCategoryList(filter: any): Promise<IConfigMerchandiseCategoryListItem[]> {
        const url = `${baseURL}/all`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(  filter ).execute<IConfigMerchandiseCategoryListItem[]>();
    }

    async getConfigMerchandiseCategory(idConfig: number): Promise<IConfigMerchandiseCategoryForm> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IConfigMerchandiseCategoryForm>();
    }
    async deleteConfigMerchandiseCategory(idConfig: number): Promise<boolean> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
    async postConfigMerchandiseCategory(config: IConfigMerchandiseCategoryForm): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(config).execute<boolean>();
    }
    async putConfigMerchandiseCategory(config: IConfigMerchandiseCategoryForm): Promise<boolean> {
        const url = `${baseURL}/${config.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(config).execute<boolean>();
    }
}
