import { createUseStyles } from "react-jss";

export const EnvironmentStyles = createUseStyles((theme: any) => ({
    "@global": {
        body: {
            margin: 0,
            fontFamily: theme.fonts.families.site,
            fontSize: theme.fonts.sizes.base,
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            color: theme.colors.site.color,
        },
        p: {
            margin: "0",
        },
    },
    siteContainer: {
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "stretch",
        backgroundColor: theme.colors.site.background,
        "& div": {
            boxSizing: "border-box",
        },
    },
    siteBody: {
        flex: "1 1 auto",
        overflow: "hidden",
        transition: "0.1s",
    },
    siteContent: {
        height: "100%",
    },
    siteFooter: {
        background: theme.colors.siteFooter.background,
        minHeight: "50px",
    },
}));
