import { createUseStyles } from "react-jss";

export const ManagementMessageDayTypeFormStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFEBD8",
        borderRadius: theme.borders.default.radius,
        padding: theme.paddings.sizes.sm,
        gap: theme.gaps.sizes.sm,
        height: "22vh",
        marginBottom: theme.margins.sizes.sm,
    },

    header: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.sizes.sm,
        color: "#C96200",
        textTransform: "uppercase",
    },
    icon: {
        stroke: "#C96200",
        color: "#76471B",
    },
    data: {
        height: "20vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.xs,
        paddingRight: theme.paddings.sizes.sm,
    },
    title: {
        color: "#76471B",
    },
    description: {
        color: "#C96200",
    },
}));
