import { useBoolean, useId } from "@fluentui/react-hooks";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../assets/icons";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { addDayToDate, convertToLocal } from "../../../utils/dateUtils";
import { CustomIcons } from "../customIcons/customIcons";
import { DayPickerComponent } from "../dayPicker/dayPicker";
import { TeachingBubbleComponent } from "../teachingBubble/teachingBubble";
import { CustomerDatePickerStyle } from "./customerDatePickerStyle.jss";
require("dayjs/locale/es");

interface ICustomDatePickerProps {
    setFilterProperty?: (name: any, value: any) => any;
    onAppliFilters?: () => any;
    startDate: string;
    endDate?: string;
    title?: string;
    maxDaySelected: number;
    minDaySelected: number;
    showLeftArrowController?: boolean;
    disabledDays?: {
        from: Date;
        to: Date;
    }[];
}

export const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
    setFilterProperty,
    maxDaySelected,
    minDaySelected,
    startDate,
    endDate,
    title,
    showLeftArrowController,
    onAppliFilters,
    disabledDays,
}) => {
    const defaultSelected: DateRange = {
        from: new Date(startDate),
        to: undefined,
    };

    const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
    const [rangeSelected, setRangeSelected] = useState<boolean>(false);

    const theme = useTheme();
    const styles = CustomerDatePickerStyle({ theme, rangeSelected, showLeftArrowController });
    const { t } = useTranslation("common");
    const [calendarVisible, { toggle: toggleCalendarVisible }] = useBoolean(false);
    const buttonId = useId("targetButton");

    const onSelectBeforeDate = () => {
        let newDate = addDayToDate(startDate, "day", -1, FormatDate.ISO);
        setDate(newDate);
    };

    const onSelectNextDate = () => {
        let newDate = addDayToDate(startDate, "day", 1, FormatDate.ISO);
        setDate(newDate);
    };

    const setDate = (newDate: string) => {

        if (startDate === endDate && setFilterProperty) {
            setFilterProperty("startDate", convertToLocal(newDate, FormatDate.ISO));
            setFilterProperty("endDate", convertToLocal(newDate, FormatDate.ISO));
            setRange({
                from: new Date(newDate),
                to: new Date(newDate),
            });
            onAppliFilters && onAppliFilters();
        }
    };

    const onSelectedRange = useCallback((date: DateRange | undefined) => {

        if (date && setFilterProperty) {
            setFilterProperty("startDate", convertToLocal(date.from, FormatDate.ISO));
            setFilterProperty("endDate", convertToLocal(date.from, FormatDate.ISO));

            if (date.to) {
                setFilterProperty("endDate", convertToLocal(date.to, FormatDate.ISO));
            }
            setRange(date);
            onAppliFilters && onAppliFilters();
        } else {
            setRange({ from: undefined, to: undefined });
        }
    }, []);

    useEffect(() => {
        const selectedRange = startDate !== endDate ? true : false;
        setRangeSelected(selectedRange);

        const from = range?.from ? convertToLocal(new Date(range.from), FormatDate.DATE) : "";
        const to = range?.to ? convertToLocal(new Date(range.to), FormatDate.DATE) : "";

        if (to !== from) {
            !selectedRange && setRange({ from: undefined, to: undefined });
        }
    }, [startDate, endDate]);

    return (
        <>
            <div className={styles.datePicker}>
                {title && <div className={styles.dateTitle}>{title}</div>}

                <div className={styles.controller}>
                    {showLeftArrowController && (
                        <CustomIcons
                            iconName={NameIcons.FLAGRIGHT}
                            className={styles.reverse}
                            onClick={onSelectBeforeDate}
                            isDisabled={rangeSelected}
                            margin={"0px"}
                        ></CustomIcons>
                    )}

                    <div
                        className={styles.dateContainer}
                        onClick={toggleCalendarVisible}
                        id={showLeftArrowController ? buttonId : undefined}
                    >
                        <CustomIcons
                            id={buttonId}
                            iconName={NameIcons.CALENDAR}
                            className={styles.calendarIcon}
                            margin={"0px"}
                        ></CustomIcons>

                        <div className={styles.date}>
                            {dayjs(startDate).startOf("day").locale("es").format(FormatDate.DATE_MONTH)}
                            {endDate === startDate && startDate === convertToLocal(new Date(), FormatDate.ISO) ? (
                                <div className={styles.currentDate}>({t("REQUEST_FILTER.TITLES.TODAY")})</div>
                            ) : null}
                            {endDate !== startDate ? ` - ${dayjs(endDate).startOf("day").locale("es").format(FormatDate.DATE_MONTH)}` : null}
                        </div>
                        {!showLeftArrowController && (
                            <div className={styles.iconRange}>
                                <CustomIcons
                                    id={buttonId}
                                    iconName={NameIcons.CHEVRONDOWNICON}
                                    className={calendarVisible ? "arrowUp" : "arrow"}
                                ></CustomIcons>
                            </div>
                        )}
                    </div>

                    {showLeftArrowController && (
                        <CustomIcons
                            iconName={NameIcons.FLAGRIGHT}
                            onClick={onSelectNextDate}
                            isDisabled={rangeSelected}
                            margin={"0px"}
                        ></CustomIcons>
                    )}
                </div>
            </div>
            <div hidden>
                {calendarVisible && (
                    <TeachingBubbleComponent
                        id={buttonId}
                        onToggle={toggleCalendarVisible}
                    >
                        <DayPickerComponent
                            mode="range"
                            defaultMonth={new Date(startDate)}
                            selected={range}
                            onSelect={onSelectedRange}
                            minDaySelect={minDaySelected}
                            maxDaySelect={maxDaySelected}
                            disabledDays={disabledDays ? disabledDays : undefined}
                        />
                    </TeachingBubbleComponent>
                )}
            </div>
        </>
    );
};
