import React, { useMemo } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { mapIDescriptionAppoitmentZoneToDropDownOptions } from "../../../../utils";
import { Selector } from "../selector";

interface IAppointmentTimeZoneSelector {
    title: string;
    onChange: (keysSelected: any, name: string) => void;
    selectedKey?: number;
    disabled?: boolean;
    className?: string;
    name: string;
    isRequired?: boolean;
    autoSelect: boolean;
}

export const AppointmentTimeZoneSelector: React.FC<IAppointmentTimeZoneSelector> = ({ name, onChange, selectedKey, title, disabled, autoSelect }) => {
    const {
        appointmentAvailableZone: { available, selectedToAssign },
        editRequest: { headerRequest, canAssignAppointment },
        editRequestAppointment: { form },
    } = useAppSelector((store) => store);

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => {
        onChange(option, name);
    };

    const options = useMemo(() => {
        return mapIDescriptionAppoitmentZoneToDropDownOptions(available.timeZones ?? []);
    }, [available, headerRequest.appointmentTypeId, selectedToAssign, headerRequest.appointment]);

    return (
        <>
            <Selector
                selectedKey={selectedKey ?? 0}
                name={name}
                title={title}
                options={options}
                onChange={onSelectorChange}
                required={true}
                disabled={options.length <= 0 || disabled}
            />
        </>
    );
};
