import { AppointmentsAPI } from "../../../../api/appointments";
import { throwErrorToastrAsync } from "../../../../utils";
import {
    setAppointmentTypesData,
    setAppointmentTypesLoading,
    setAppointmentTypesRemainingData,
    setAppointmentTypesRemainingLoading,
} from "../../../reducers/catalog/catalog";
import { setLoadingMasterAppointmentTypeForm, setMasterAppointmentTypeForm } from "../../../reducers/masterAppointment/appointmentType/form";
import { setLoadingMasterAppointmentTypeList, setMasterAppointmentTypeList } from "../../../reducers/masterAppointment/appointmentType/list";

import { AppThunk, store } from "../../../store";

const appointmentApi = new AppointmentsAPI();

export const fetchMasterAppointmentTypeList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterAppointmentTypeList(true));
        const response = await appointmentApi.getMasterAppointmentTypeList();
        dispatch(setMasterAppointmentTypeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterAppointmentTypeList(false));
    }
};
export const patchSortAppointmentTypesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterAppointmentTypeList(true));
            await appointmentApi.managementSort(tableName, id, sortType);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchMasterAppointmentTypeList());
        }
    };

export const fetchMasterAppointmentType =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterAppointmentTypeForm(true));
            const response = await appointmentApi.getMasterAppointmentTypeForm(id);
            dispatch(setMasterAppointmentTypeForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterAppointmentTypeForm(false));
        }
    };

export const postAndPutMasterAppointmentType = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterAppointmentTypesForm;
            dispatch(setLoadingMasterAppointmentTypeForm(true));

            if (form.id) {
                await appointmentApi.putMasterAppointmentTypeForm(form);
            } else {
                await appointmentApi.postMasterAppointmentTypeForm(form);
            }
            dispatch(fetchMasterAppointmentTypeList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentTypeForm(false));
            return false;
        } 
    };
};

export const fetchAppointmentTypesRemaining = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setAppointmentTypesRemainingLoading(true));
        dispatch(setLoadingMasterAppointmentTypeForm(true));

        const response = await appointmentApi.getMasterAppointmentTypeRemaining();
        dispatch(setAppointmentTypesRemainingData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterAppointmentTypeForm(false));
        dispatch(setAppointmentTypesRemainingLoading(false));
    }
};

export const fetchCatalogAppointmentTypes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setAppointmentTypesLoading(true));

        const response = await appointmentApi.getCatalogAppointmentType();
        dispatch(setAppointmentTypesData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setAppointmentTypesLoading(true));
    }
};
