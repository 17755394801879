import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../../assets/icons";
import { FormatDate } from "../../../../../../../../common/enum/dateTime/FormatDate";
import { IConfigEditRequest } from "../../../../../../../../models/requests/IConfigEditRequest";
import { IActuation } from "../../../../../../../../models/requests/IEditRequest";
import { getInspectionActuations } from "../../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch } from "../../../../../../../../redux/hooks";
import { compileNameOfProperty, convertToLocal } from "../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../common/tableCustom/tableCustom";
import { isShowActionsOfActuation } from "../../../../../../common/validation/inspections";
import { ActuationListStyle } from "./listStyle.jss";
import { setEditRequestActuationPopUp, updateActuationFormProperty } from "../../../../../../../../redux/reducers/request/edit/form/actuation";
import { PopUpCodes } from "../../../../../../../../common/enum/PopUpCodes";

interface IEditRequestInspectionActuationItemProps {
    actuation: IActuation[];
    schema: IConfigEditRequest;
    requestId: number;
    requestInspectionColumnsShow: number;
    invalidRequest: boolean;
    showFormActuationModal: (value: any) => any;
    showDeleteActuationModal: (value: any) => any;
}

export const EditRequestGeneralInformationEditRequestInspectionsActuationList: React.FC<IEditRequestInspectionActuationItemProps> = ({
    schema,
    actuation,
    requestId,
    requestInspectionColumnsShow,
    invalidRequest,
    showFormActuationModal,
    showDeleteActuationModal
}) => {
    const [isDropDownTable, setDropDownTable] = useState<boolean>(true);

    const iconDrop = isDropDownTable ? "ChevronUpSmall" : "ChevronDownSmall";
    const theme = useTheme();
    const style = ActuationListStyle({ theme, isDropDownTable, requestInspectionColumnsShow });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();

    const columns: ColumnType<IActuation, any>[] = [
        { key: "date", title: t("POPUPINSPECTION.DATA"), showColumn: schema.actuation_date.visible, width: "15%" },
        { key: "description", title: t("POPUPINSPECTION.ACTUATION"), showColumn: schema.actuation_description.visible, width: "15%" },
        { key: "instructions", title: t("POPUPINSPECTION.INSTRUCTION"), showColumn: schema.actuation_instructions.visible, width: "45%" },
        { key: "organismDescription", title: t("POPUPINSPECTION.ORGANISM"), showColumn: schema.actuation_organismId.visible, width: "20%" },
        { key: "actions", title: "", padding: "0px", showColumn: schema.actuation_update.visible || schema.actuation_delete.visible ? true : false },
    ];

    const onEditActuationItem = (actuation: IActuation) => {
        actuation.id && actuation.inspectionId && dispatch(getInspectionActuations(requestId, actuation.inspectionId, actuation.id));
        dispatch(setEditRequestActuationPopUp(PopUpCodes.UPDATE));
        showFormActuationModal(true);
    };

    const onDeleteActuationItem = (actuation: IActuation) => {
        dispatch(setEditRequestActuationPopUp(PopUpCodes.DELETE));
        dispatch(updateActuationFormProperty({ name: compileNameOfProperty<IActuation>("id"), value: actuation.id }));
        dispatch(
            updateActuationFormProperty({
                name: compileNameOfProperty<IActuation>("inspectionId"),
                value: actuation.inspectionId,
            }),
        );
        showDeleteActuationModal(true);
    };

    const actions = (item: IActuation) => {
        const showButtonUpdateActuation = schema.actuation_update.visible && isShowActionsOfActuation(item.organismId);
        const showButtonRemoveActuation = schema.actuation_delete.visible && isShowActionsOfActuation(item.organismId);

        return (
            <CustomTableActions
                position="end"
                children={
                    <div>
                        {showButtonUpdateActuation && (
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.EDIT}
                                onClick={() => onEditActuationItem(item)}
                                tooltip={t("TOOLTIP.UPDATE_ACTUATION")}
                                isDisabled={invalidRequest}
                            ></CustomIcons>
                        )}

                        {showButtonRemoveActuation && showButtonUpdateActuation && <Separator size="auto"></Separator>}
                        {showButtonRemoveActuation && (
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.REMOVE}
                                onClick={() => onDeleteActuationItem(item)}
                                tooltip={t("TOOLTIP.REMOVE_ACTUATION")}
                                isDisabled={invalidRequest}
                            ></CustomIcons>
                        )}
                    </div>
                }
            />
        );
    };

    const tableRows = actuation.map((item) => {
        return {
            date: convertToLocal(item.date, FormatDate.DATE_HOUR_MINUTE),
            description: item.description,
            instructions: item.instructions,
            organismDescription: item.organismDescription,
            actions: actions(item),
        };
    });

    return (
        <div className={style.actuationContainer}>
            <div className={style.dropIconContainer}>
                <CustomIcons
                    containerClass={style.dropIconContainer}
                    iconName={iconDrop}
                    size={"1.2rem"}
                    margin={"10px"}
                    onClick={() => setDropDownTable(!isDropDownTable)}
                    className={style.icon}
                ></CustomIcons>
            </div>
            {isDropDownTable && (
                <CustomTableList
                    data={tableRows}
                    columns={columns}
                    loading={false}
                />
            )}
        </div>
    );
};
