import { IDropdownOption } from "@fluentui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../../models";
import { IBoardHistoryAppointmentCreate } from "../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { updateMasterAppointmentBoardProperty } from "../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/form";
import { compileNameOfProperty } from "../../../../../../../../utils";
import { ControlledDatePicker } from "../../../../../../../common/datePicker/controlledDatePicker";
import { MerchandiseCategoryGroupSelector } from "../../../../../../../request/common/selectors/merchandiseCategoryGrop/merchandiseCategoryGroupSelector";
import { MasterAppointmentBoardStyle } from "./formStyle.jss";
import { DefaultValues } from "../../../../../../../../common/enum/DefaultValues";

export const AppointmentCreateBoardForm = () => {
    const theme = useTheme();
    const style = MasterAppointmentBoardStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD" });
    const { control } = useForm<IBoardHistoryAppointmentCreate>({ mode: "onChange" });
    const { form } = useAppSelector((state) => state.masterAppointmentBoardCreateForm);
    const minDate = useMemo(() => {
        return dayjs(new Date()).startOf("day").toDate();
    }, []);

    const onDateChange = (date: any, name: any) => {
        let payload = {
            name: name,
            value: date,
        };

        dispatch(updateMasterAppointmentBoardProperty(payload));
    };

    const onSelectorChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        dispatch(updateMasterAppointmentBoardProperty(payload));
    };

    return (
        <>
            <div className={style.form}>
                <div className={style.row}>
                    <ControlledDatePicker
                        name={compileNameOfProperty<IBoardHistoryAppointmentCreate>("date")}
                        label={t("DATE")}
                        onDateChange={onDateChange}
                        initValue={new Date()}
                        required={true}
                        control={control}
                        minDate={minDate}
                    />
                </div>
                <div className={style.row}>
                    <MerchandiseCategoryGroupSelector
                        onChange={onSelectorChange}
                        selectedKey={form.merchandiseCategoryGroupId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                        propertyName={compileNameOfProperty<IBoardHistoryAppointmentCreate>("merchandiseCategoryGroupId")}
                        title={t("MERCHANDISE_CATEGORY")}
                        control={control}
                        isRequired={false}
                        allOption={true}
                        allOptionText={t("NOT_MERCHANDISE_CATEGORY")}
                    />
                </div>
            </div>
        </>
    );
};
