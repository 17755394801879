import { useCallback, useMemo, useState } from "react";
import { IFormOption } from "../../../../../../../models/common/IFormOptions";
import {
    IValidationParkingRestriction,
    IValidationParkingRuleRestriction,
} from "../../../../../../../models/management/parkingValidationRule/IParkingValidationRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { deleteRestrictionRule, updateParkingValidationRestrictionForm } from "../../../../../../../redux/reducers/management/parkingValidation/form";
import { CheckboxSelector } from "../../../../../../common/checkboxSelector/checkboxSelector";
import { RadioButtonGroup } from "../../../../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { Section } from "../section/section";

export const ManagementParkingValidationFormRestriction = () => {
    const { view } = useAppSelector((state) => state.managementParkingValidationConfiguration);
    const {
        form: { validationParkingRuleRestrictions },
    } = useAppSelector((state) => state.managementParkingValidationRuleForm);

    const [active, setActive] = useState<{ [key: number]: boolean }>({});

    const dispatch = useAppDispatch();

    const isActive = (validationParkingRestrictionId: number, value: number, restrictions: IValidationParkingRuleRestriction[]): boolean =>
        restrictions.some(
            (restriction) =>
                restriction.validationParkingRestrictionId === validationParkingRestrictionId &&
                restriction.value === value &&
                (restriction.deleted === false || restriction.deleted === null),
        );

    const onRadioButtonChange = (option: any, validationParkingRestrictionId: number) => {
        let payload = {
            validationParkingRestrictionId: validationParkingRestrictionId,
            value: +option,
            isBoolValue: true,
        };
        dispatch(updateParkingValidationRestrictionForm(payload));
    };

    const onCheckboxChange = (validationParkingRestrictionId: number, value: number) => {
        let payload = {
            validationParkingRestrictionId: validationParkingRestrictionId,
            value: +value,
            isBoolValue: false,
        };
        dispatch(updateParkingValidationRestrictionForm(payload));
    };

    const getValueFromActive = useCallback((key: number): boolean | undefined => active[key], [active]);

    const onCheckboxChangeSection = useCallback(
        (restriction: IValidationParkingRestriction, existContent: boolean, event?: any, checked = false) => {
            setActive((prevState) => ({
                ...prevState,
                [restriction.id]: checked ?? existContent,
            }));

            if (checked && restriction.boolValue) {
                onRadioButtonChange(false, restriction.id);
            }

            if (!checked) {
                dispatch(deleteRestrictionRule(restriction.id));
            }
        },
        [active, validationParkingRuleRestrictions],
    );

    const printComponent = (item: IValidationParkingRestriction, index: number, options: IFormOption[]) => {
        let itemSelected = false;

        if (item.boolValue) {
            itemSelected = validationParkingRuleRestrictions.find(
                (r) => r.validationParkingRestrictionId === item.id && (r.deleted === false || r.deleted === null),
            )?.value;
            if (itemSelected === null) {
                onRadioButtonChange(false, item.id);
            }
        }

        return item.boolValue ? (
            <RadioButtonGroup
                name={item.description}
                onChange={(option: any, name?: any) => {
                    onRadioButtonChange(!!+option, item.id);
                }}
                selectedKey={itemSelected ? itemSelected.toString() : "0"}
            />
        ) : (
            <CheckboxSelector
                options={options}
                name={item.description}
                onChange={(name: string, value: any, indexOption, id) => {
                    if (id) {
                        onCheckboxChange(item.id, +id);
                    }
                }}
            />
        );
    };

    const form = useMemo(() => {
        return view?.validationParkingRestrictions.map((restriction: IValidationParkingRestriction, index) => {
            const existContent = () => {
                return validationParkingRuleRestrictions.some(
                    (restrictionRule) =>
                        restrictionRule.validationParkingRestrictionId === restriction.id &&
                        (restrictionRule.deleted === false || restrictionRule.deleted === null),
                );
            };

            const options = restriction.validationParkingRestrictionValues?.map((value): IFormOption => {
                return {
                    key: value.id,
                    text: value.description,
                    active: isActive(restriction.id, value.id, validationParkingRuleRestrictions),
                    disabled: false,
                };
            });

            return (
                <Section
                    title={restriction.description}
                    value={printComponent(restriction, index, options)}
                    showBubble={existContent()}
                    onChangeDisabledController={(e: any, checked?: boolean) => {
                        onCheckboxChangeSection(restriction, existContent(), e, checked);
                    }}
                    sectionActive={getValueFromActive(restriction.id) ?? existContent()}
                    showDisabledController={true}
                />
            );
        });
    }, [validationParkingRuleRestrictions, view, active]);

    return <>{form}</>;
};
