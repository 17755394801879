import React from "react";
import { ThemeProvider, useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { getUserClaimsLoaded } from "../../../redux/selectors/user/claims/claims";
import { romeuTheme } from "../../../styles/romeu";
import { ClaimsToken } from "../../auth/claimsToken";
import { ErrorHandler } from "../../common/errorHandler/errorHandler";
import { SpinnerFull } from "../../common/spinner/spinnerFull/spinnerFull";
import { RoleViewPermissions } from "../../role/views";
import { InitDataFromLocalStorage } from "../../session/InitDataFromLocalStorage";
import { EnvironmentStyles } from "./customTemplate.jss";
import { Cookies } from "../../common/cookies/cookiesContent";

interface TemplateProps {
    title?: string;
    featureId?: string;
    component: React.ReactNode | React.Component | React.FunctionComponent;
}

export const CustomTemplate = (props: TemplateProps & any) => {
    const [currentTheme, setCurrentTheme] = React.useState(romeuTheme);
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);

    function changeTheme(themeValue: string) {
        if (themeValue === romeuTheme.id) {
            setCurrentTheme(romeuTheme);
        }
    }

    return (
        <>
            <ErrorHandler />
            <ClaimsToken />
            <RoleViewPermissions />
            <InitDataFromLocalStorage />
            <ThemeProvider theme={currentTheme}>
                {isUserClaimsLoaded ? (
                    <BodyContainer
                        component={props.component}
                        title={props.title}
                        featureId={props.featureId}
                        changeTheme={changeTheme}
                        props={props}
                    />
                ) : (
                    <></>
                )}
                <SpinnerFull />
                <Cookies />
            </ThemeProvider>
        </>
    );
};

const BodyContainer = (props: any) => {
    const theme = useTheme();
    const styles = EnvironmentStyles({ theme });
    const body = props.component ? <props.component {...props.props} /> : <p>Template referenced without body component</p>;

    return (
        <div className={styles.siteContainer}>
            <div className={styles.siteBody}>
                <div className={styles.siteContent}>{body}</div>
            </div>
        </div>
    );
};
