import { createUseStyles } from "react-jss";

export const CustomToogleStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    toogleContainer: (props: any) => ({
        transform: props.customMode ? "rotate(-90deg)" : "",
        "& button": {
            backgroundColor: props.newCheckState ? theme.colors.toogle.background : theme.colors.site.background,
        },
        "& .pill-213": {
            backgroundColor: props.newCheckState ? theme.colors.toogle.background : theme.colors.site.background,
        },
        "& button:hover": {
            backgroundColor: props.newCheckState ? theme.colors.toogle.background : theme.colors.site.background,
        },
        marginBottom: 0,
    }),
    traficToogleContainer: (props: any) => ({
        transform: "rotate(-270deg)",
        "& button": {
            backgroundColor: props.newCheckState ? theme.colors.toogle.background : theme.colors.site.background,
        },
        "& .pill-213": {
            backgroundColor: props.newCheckState ? theme.colors.toogle.background : theme.colors.site.background,
        },
        "& button:hover": {
            backgroundColor: props.newCheckState ? theme.colors.toogle.background : theme.colors.site.background,
        },
        marginBottom: 0,
    }),

    root: (props: any) => ({
        alignContent: "end",
        flexDirection: props.customMode ? "row-reverse" : "",
        alignItems: "",
        marginBottom: 0,
        cursor: props.disabled ? "" : "pointer",
    }),
    traficToogleroot: (props: any) => ({
        alignContent: "end",
        flexDirection: "row-reverse",
        alignItems: "",
        marginBottom: 0,
        cursor: props.disabled ? "" : "pointer",
        "& > label": {
            color: theme.colors.form.input.default,
            fontSize: theme.fonts.sizes.base,
            fontWeight: 100,
            fontFamily: theme.fonts.families.site,
        },
    }),

    label: (props: any) => ({
        cursor: props.disabled ? "" : "pointer",
        padding: 0,
        marginLeft: 5,
    }),

    traficTooglelabel: (props: any) => ({
        cursor: props.disabled ? "" : "pointer",
        padding: 0,
        marginLeft: 0,
        fontFamily: theme.fonts.families.siteImportant,
        fontWeight: `normal !important`,
    }),
    calloutContainer: {
        marginLeft: 10,
        marginTop: 3,
    },
    thumb: (props: any) => ({
        background: props.newCheckState ? theme.colors.palette.green : theme.colors.palette.red,
    }),
}));
