import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import {
    IParkingValidationFormData,
    IValidationParkingRuleException,
    IValidationParkingRuleRestriction,
} from "../../../../models/management/parkingValidationRule/IParkingValidationRule";
export interface IManagementParkingValidationRuleFormState {
    form: IParkingValidationFormData;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IManagementParkingValidationRuleFormState = {
    form: {
        id: null,
        description: "",
        validationParkingRuleExceptions: [],
        validationParkingRuleRestrictions: [],
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managementParkingValidationRuleFormSlice = createSlice({
    name: "ManagementParkingValidationRuleForm",
    initialState,
    reducers: {
        resetManagementParkingValidationRuleForm: () => initialState,
        setManagementParkingValidationRuleForm: (state, action: PayloadAction<IParkingValidationFormData>) => {
            state.form = action.payload;
        },
        setNameManagementParkingValidationRuleForm: (state, action: PayloadAction<string>) => {
            state.form.description = action.payload;
        },
        setLoadingManagementParkingValidationRuleForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        createValidationParkingRuleException: (state, action: PayloadAction<IValidationParkingRuleException>) => {
            state.form.validationParkingRuleExceptions.push(action.payload);
        },

        updateParkingValidationRestrictionForm: (
            state,
            action: PayloadAction<{ validationParkingRestrictionId: number; value: number; isBoolValue: boolean }>,
        ) => {
            const { validationParkingRestrictionId, value, isBoolValue } = action.payload;

            const restrictionIndex = state.form.validationParkingRuleRestrictions.findIndex(
                (restriction) =>
                    restriction.validationParkingRestrictionId === validationParkingRestrictionId &&
                    (isBoolValue ? true : restriction.value === value),
            );

            if (restrictionIndex !== -1) {
                const restriction = state.form.validationParkingRuleRestrictions[restrictionIndex];
                if (isBoolValue && (restriction.deleted === false || restriction.deleted === null)) {
                    restriction.value = value;
                } else {
                    if (restriction.id !== null && (restriction.deleted === false || restriction.deleted === null)) {
                        restriction.deleted = true;
                    } else if (restriction.id !== null && restriction.deleted === true) {
                        restriction.deleted = false;
                    } else {
                        state.form.validationParkingRuleRestrictions.splice(restrictionIndex, 1);
                    }
                }
            } else {
                const newRestriction: IValidationParkingRuleRestriction = {
                    id: null,
                    validationParkingRuleId: state.form.id,
                    validationParkingRestrictionId,
                    value,
                    deleted: false,
                };
                state.form.validationParkingRuleRestrictions.push(newRestriction);
            }
        },

        deleteRestrictionRule: (state, action: PayloadAction<number>) => {
            const restrictionIndex: number[] = [];

            state.form.validationParkingRuleRestrictions.forEach((restriction, index) => {
                if (restriction.validationParkingRestrictionId === action.payload) {
                    restrictionIndex.push(index);
                }
            });

            restrictionIndex.forEach((index) => {
                if (state.form.validationParkingRuleRestrictions[index].id !== null) {
                    state.form.validationParkingRuleRestrictions[index].deleted = true;
                    state.form.validationParkingRuleRestrictions[index].value = null;
                } else {
                    state.form.validationParkingRuleRestrictions.splice(index, 1);
                }
            });
        },
        deleteAllExceptionRule: (state, action: PayloadAction<number>) => {
            const expeceptionIndex: number[] = [];

            state.form.validationParkingRuleExceptions.forEach((restriction, index) => {
                if (restriction.validationParkingExceptionId === action.payload) {
                    expeceptionIndex.push(index);
                }
            });
            expeceptionIndex.forEach((index) => {
                if (state.form.validationParkingRuleExceptions[index].id !== null) {
                    state.form.validationParkingRuleExceptions[index].validationParkingAppointmentExceptionHour = null;
                    state.form.validationParkingRuleExceptions[index].validationParkingAppointmentExceptionTypeId = null;
                    state.form.validationParkingRuleExceptions[index].validationParkingInspectorExceptionLineId = null;
                    state.form.validationParkingRuleExceptions[index].deleted = true;
                } else {
                    state.form.validationParkingRuleExceptions.splice(index, 1);
                }
            });
        },

        setExceptionAppointmentRule: (state, action: PayloadAction<IValidationParkingRuleException>) => {
            const { validationParkingExceptionId, validationParkingAppointmentExceptionLineId } = action.payload;
            const exceptionIndex = state.form.validationParkingRuleExceptions.findIndex(
                (exception) =>
                    exception.validationParkingExceptionId === validationParkingExceptionId &&
                    exception.validationParkingAppointmentExceptionLineId === validationParkingAppointmentExceptionLineId,
            );

            if (exceptionIndex !== -1) {
                const exception = state.form.validationParkingRuleExceptions[exceptionIndex];
                if (exception.id !== null && (exception.deleted === false || exception.deleted === null)) {
                    state.form.validationParkingRuleExceptions[exceptionIndex].deleted = true;
                    state.form.validationParkingRuleExceptions[exceptionIndex].validationParkingAppointmentExceptionHour = null;
                    state.form.validationParkingRuleExceptions[exceptionIndex].validationParkingAppointmentExceptionLineId = null;
                    state.form.validationParkingRuleExceptions[exceptionIndex].validationParkingInspectorExceptionLineId = null;
                } else if (exception.id !== null && exception.deleted === true) {
                    state.form.validationParkingRuleExceptions[exceptionIndex].deleted = false;
                } else {
                    state.form.validationParkingRuleExceptions.splice(exceptionIndex, 1);
                }
            } else {
                state.form.validationParkingRuleExceptions.push(action.payload);
            }
        },
        setExceptionRuleAppointmentTypeId: (state, action: PayloadAction<{ index: number; typeId: number | null }>) => {
            state.form.validationParkingRuleExceptions[action.payload.index].validationParkingAppointmentExceptionTypeId = action.payload.typeId;
        },
        setExceptionRuleAppointmentExceptionHour: (state, action: PayloadAction<{ index: number; hour: string | null }>) => {
            state.form.validationParkingRuleExceptions[action.payload.index].validationParkingAppointmentExceptionHour = action.payload.hour;
        },
        setManagementParkingValidationRuleFormPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setExceptionRuleInspector: (state, action: PayloadAction<IValidationParkingRuleException>) => {
            const { validationParkingExceptionId, validationParkingInspectorExceptionLineId } = action.payload;

            const exceptionIndex = state.form.validationParkingRuleExceptions.findIndex(
                (restriction) =>
                    restriction.validationParkingExceptionId === validationParkingExceptionId &&
                    restriction.validationParkingInspectorExceptionLineId === validationParkingInspectorExceptionLineId,
            );

            if (exceptionIndex !== -1) {
                const exception = state.form.validationParkingRuleExceptions[exceptionIndex];
                if (exception.id !== null && (exception.deleted === false || exception.deleted === null)) {
                    state.form.validationParkingRuleExceptions[exceptionIndex].deleted = true;
                } else if (exception.id !== null && exception.deleted === true) {
                    state.form.validationParkingRuleExceptions[exceptionIndex].deleted = false;
                } else {
                    state.form.validationParkingRuleExceptions.splice(exceptionIndex, 1);
                }
            } else {
                state.form.validationParkingRuleExceptions.push(action.payload);
            }
        },
    },
});

export const {
    resetManagementParkingValidationRuleForm,
    setLoadingManagementParkingValidationRuleForm,
    createValidationParkingRuleException,
    updateParkingValidationRestrictionForm,
    setExceptionAppointmentRule,
    setExceptionRuleAppointmentTypeId,
    setExceptionRuleAppointmentExceptionHour,
    setManagementParkingValidationRuleFormPopUp,
    setManagementParkingValidationRuleForm,
    setNameManagementParkingValidationRuleForm,
    setExceptionRuleInspector,
    deleteAllExceptionRule,
    deleteRestrictionRule,
} = managementParkingValidationRuleFormSlice.actions;

export default managementParkingValidationRuleFormSlice.reducer;
