import { createUseStyles } from "react-jss";

export const UserSignupOperationEmailItemStyle = createUseStyles({
    emailContainer: {
        display: "flex",
        justifyContent: "space-between",
        "& i": {
            paddingTop: 15,
        },
    },
    emailContent: {
        display: "flex",
        width: "100%",
        "& i": {
            paddingTop: 15,
        },
    },
    addEmail: {
        display: "flex",
        width: 50,
        justifyContent: "flex-end",
        "& i": {
            paddingTop: 15,
        },
    },
    button: {
        cursor: "pointer",
    },
   
});
