import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const ManagementRequestDatePermisionDateFormStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.paddings.sizes.sm,
        gap: theme.gaps.sizes.md,
        width: "100%",
    },
    rowForm: {
        display: "flex",
        gap: theme.gaps.sizes.md,
        alignItems: "end",
        width: "100%",
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
            alignItems:"start"
        },
    },
    rowFormMobile : {
        display: "flex",
        gap: theme.gaps.sizes.xl,
        alignItems: "end",
        justifyContent:"space-between"
    },
    checkWorkDay: {
        width: "50%",
    },
}));
