import { IComboBoxOption } from "@fluentui/react";
import { Selector } from "../../selector";
import { MasterSelectorStyle } from "./masterSelectorStyle.jss";
import { useTheme } from "react-jss";
import { FormEvent } from "react";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

interface IMasterSelectorProps {
    onChange: (_event: FormEvent<HTMLDivElement>, option: any, name: string) => any;
    selectedKey?: string;
    options: IComboBoxOption[];
    label?: string;
    isDisabled?: boolean;
}
export const MasterSelector: React.FC<IMasterSelectorProps> = ({ onChange, selectedKey, options, label, isDisabled }) => {
    const theme = useTheme();
    const style = MasterSelectorStyle({ theme });
    return (
        <Selector
            disabled={isDisabled ?? !options.length}
            name={""}
            title={label ?? DefaultValues.NOT_TEXT}
            className={style.selector}
            options={options}
            selectedKey={selectedKey}
            onChange={onChange}
        />
    );
};
