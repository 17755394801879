import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pageSizes } from "../../../../common/constants";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IRequestDetail, IRequestDetailWithIndex } from "../../../../models/requests/IRequestDetail";
import { IRequestList } from "../../../../models/requests/IRequestList";
import { getFirstItemNumber, getLastItemNumber, getTotalPages } from "../../../../utils";

interface RequestListState {
    list: IRequestDetail[];
    sort: ISortState;
    pagination: IPaginationState;
    selectedChangeDate?: Date;
    autoReload: boolean;
    loading: boolean;
    showAllInspections: boolean;
    showAllRequest: boolean;
}

const initialState: RequestListState = {
    list: [] as IRequestDetail[],
    sort: emptySortState(),
    pagination: emptyPaginationState(pageSizes.REQUEST),
    selectedChangeDate: undefined,
    autoReload: true,
    loading: false,
    showAllInspections: false,
    showAllRequest: false,
};

export const requestListSlice = createSlice({
    name: "requestList",
    initialState,
    reducers: {
        resetRequestList: () => initialState,

        loadingRequestList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchRequestList: (state, action: PayloadAction<IRequestList>) => {
            state.list = action.payload.results;
            state.pagination.totalItems = action.payload.count;
            state.pagination.totalPages = getTotalPages(state.pagination);
        },
        concatRequestList: (state, action: PayloadAction<IRequestDetail[]>) => {
            state.list.push(...action.payload);
        },
        updateSelectedChangeDate: (state, action: PayloadAction<Date | undefined>) => {
            state.selectedChangeDate = action.payload;
        },
        updateChangeDate: (state, action: PayloadAction<IRequestDetailWithIndex>) => {
            state.list[action.payload.index].changeDate = action.payload.changeDate;
        },
        updateAllChangeDate: (state, action: PayloadAction<boolean>) => {
            state.list.forEach((item) => (item.changeDate = action.payload));
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        updateStateId: (state, action: PayloadAction<IRequestDetailWithIndex>) => {
            state.list[action.payload.index].stateId = action.payload.stateId;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },

        setAutoReload: (state, action: PayloadAction<boolean>) => {
            state.autoReload = action.payload;
        },
        setShowAllinspections: (state, action: PayloadAction<boolean>) => {
            state.showAllInspections = action.payload;
        },
        setShowAllRequest: (state, action: PayloadAction<boolean>) => {
            state.showAllRequest = action.payload;
        },
    },
});

export const {
    resetRequestList,
    loadingRequestList,
    fetchRequestList,
    concatRequestList,
    updateSelectedChangeDate,
    updateChangeDate,
    updateAllChangeDate,
    updateSortColumn,
    updateStateId,
    setPage,
    setAutoReload,
    setShowAllinspections,
    setShowAllRequest,
} = requestListSlice.actions;

export default requestListSlice.reducer;
