import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatisticsInspectionAccumulativeMonthState } from "../../../../models/statistics/inspection/IAccumulated";

interface IInitialState {
    data: IStatisticsInspectionAccumulativeMonthState;
}
const initialSateData = (): IStatisticsInspectionAccumulativeMonthState => {
    return {
        inspectionsAccumulativeStatistics: {
            inspectionAccumulativeStatistics: [],
            organismRequests: [],
            totalInspectionsPositionedWithInvoiceConcepts: 0,
            totalRequests: 0,
            totalRequestsPositionedWithInvoiceConcepts: 0,
            totalRequestsPositionedWithInvoiceConceptsWithManyOrganisms: 0,
            totalRequestsWithManyOrganisms: 0,
            
            inspectionsPositionedWithInvoiceConceptsPercentage: 0,
            requestsPositionedWithInvoiceConceptsPercentage: 0,
            requestsPositionedWithInvoiceConceptsWithManyOrganismsPercentage: 0,
            requestsWithManyOrganismsPercentage: 0,
       
       
        },
        loading: false,
        organisms: [],
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsInspectionAccumulativeListSlice = createSlice({
    name: "statiticsInspectionList",
    initialState,
    reducers: {
        reset: () => initialState,
        setAccumulatedInspectionData: (state, action: PayloadAction<IStatisticsInspectionAccumulativeMonthState>) => {
            state.data = action.payload;
        },

        setLoadingInspectionAccumulativeList: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
    },
});

export const { reset, setAccumulatedInspectionData, setLoadingInspectionAccumulativeList } = statiticsInspectionAccumulativeListSlice.actions;

export default statiticsInspectionAccumulativeListSlice.reducer;
