import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserSignupDocumentTemplatesStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        padding: theme.paddings.default,
        height: "100%",
        [mediaQueries.MOBILE]: {
            paddingLeft: 0,
        },
    },
    divisor: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
    },
    buttonsRow: {
        flexFlow: "row",
        display: "flex",
        justifyContent: "end",
        columnGap: theme.paddings.default,
    },
}));
