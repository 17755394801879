import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routeUrls } from "../../../common/constants";
import { IGenericPayload, IUserSelfSignup } from "../../../models";
import { selfSignUpAction } from "../../../redux/actions/user/signup/selfSignUp";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateSelfSignupProperty } from "../../../redux/reducers/user/signup/selfSignup";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../utils";
import { Avatar } from "../../common/avatar/avatar";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { Disclaimer } from "../../common/disclaimer/disclaimer";
import { FormField } from "../../common/fields/fields";
import { UserSelfSignUpcontainerStyle } from "./containerStyle.jss";
import { selfSignUpResolver } from "./selfSignUpResolver";

export const UserSelfSignUpcontainer = () => {
    const styles = UserSelfSignUpcontainerStyle();

    const { t } = useTranslation("common", { keyPrefix: "USER.SELF_SIGNUP" });
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        register,
        formState: { errors, isValid },
    } = useForm<IUserSelfSignup>({ mode: "onChange", resolver: selfSignUpResolver });

    const form = useAppSelector((state) => state.selfSignUp.form);
    const signupSuccess = useAppSelector((state) => state.selfSignUp.signupSuccess);

    useEffect(() => {
        const payload: IGenericPayload = {
            name: compileNameOfProperty<IUserSelfSignup>("id"),
            value: id,
        };
        dispatch(updateSelfSignupProperty(payload));
    }, [id]);

    useEffect(() => {
        if (signupSuccess) {
            navigate(routeUrls.HOME);
        }
    }, [signupSuccess]);

    const handleAvatarClick = (file: File | undefined) => {
        const isFile = file instanceof File;
        const payload: IGenericPayload = {
            name: compileNameOfProperty<IUserSelfSignup>("image"),
            value: isFile ? URL.createObjectURL(file) : "",
        };

        dispatch(updateSelfSignupProperty(payload));
    };

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateSelfSignupProperty(payload));
        },
        [dispatch],
    );

    const onRegisterClick = () => {
        dispatch(selfSignUpAction());
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.dataContainer}>
                    <Disclaimer
                        body={t("DISCLAIMER_MESSAGE")}
                        color="#7367f0"
                        backgroundColor="#e0deff"
                    />
                    <div className={styles.form}>
                        <div className={styles.avatar}>
                            <Avatar
                                src={form.image}
                                size="145px"
                                onChangeAvatar={handleAvatarClick}
                                edit={true}
                            />
                        </div>
                        <div className={styles.data}>
                            <FormField
                                type="text"
                                label={t("NAME")}
                                value={form.name}
                                error={errors.name}
                                isRequired={true}
                                {...register(compileNameOfProperty<IUserSelfSignup>("name"), {
                                    onChange: onInputChange,
                                    value: form.name,
                                })}
                            />
                            <FormField
                                type="text"
                                value={form.surName}
                                error={errors.surName}
                                label={t("SURNAME")}
                                isRequired={true}
                                {...register(compileNameOfProperty<IUserSelfSignup>("surName"), {
                                    onChange: onInputChange,
                                    value: form.surName,
                                })}
                            />
                            <FormField
                                type="text"
                                value={form.lastName}
                                error={errors.lastName}
                                label={t("LASTNAME")}
                                {...register(compileNameOfProperty<IUserSelfSignup>("lastName"), {
                                    onChange: onInputChange,
                                    value: form.lastName,
                                })}
                            />
                            <FormField
                                type="text"
                                value={form.phoneNumber}
                                error={errors.phoneNumber}
                                label={t("PHONE_NUMBER")}
                                isRequired={true}
                                {...register(compileNameOfProperty<IUserSelfSignup>("phoneNumber"), {
                                    required: true,
                                    onChange: onInputChange,
                                    value: form.phoneNumber,
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <ButtonPrimary
                        disabled={!isValid}
                        handleClick={onRegisterClick}
                        title={t("REGISTER_BUTTON")}
                    />
                </div>
            </div>
        </div>
    );
};
