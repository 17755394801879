import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementRepresentationList } from "./list/list";
import { ManagementRepresentationFilter } from "./filter/filter";
import { ManagementRepresentationBaseForm } from "./form/container";

export const ManagementRepresentationContainer = () => {
    const { t } = useTranslation("common");

    return (
        <>
            <NavHeaderComponent title={t("MANAGEMENT.REPRESENTATION.TITLE")} />

            <ManagementRepresentationBaseForm />

            <ManagementRepresentationFilter />

            <ManagementRepresentationList />
        </>
    );
};
