import { IDropdownOption } from "@fluentui/react";

import React,{ useEffect } from "react";
import {  fetchInspectionActuationsGroup } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../../../../request/common/selectors/IBaseSelectorProps";

export const ActuationGroupSelector: React.FC<IBaseSelectorProps> = ({ onChange, selectedKey, propertyName,calloutLabel,calloutMessage, title, disabled, isRequired, control, rules, allOption, allOptionText }) => {
    const { data, loading } = useAppSelector(store => store.catalog.actuationsGroup);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchInspectionActuationsGroup());
    }, [dispatch, data]);

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(data,allOptionText) : mapIDescriptionToDropDownOptions(data)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
            calloutMessage={calloutMessage}
            calloutLabel={calloutLabel}
        />
    );
};
