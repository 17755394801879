import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { patchRequestInspectionsUnassignInspector } from "../../../../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { resetUnassignInspectorFormData } from "../../../../../../../redux/reducers/request/list/form/assignInspector";
import { PopUpConfirmation } from "../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../../../../common/enum/PopUpConfirmationType";

interface IRequestListExpedientsInspectorsUnassignAlertControlerProps {
    showInspectorUnassign: boolean;
    setShowInspectorUnassign: (value: any) => any;
}

export const RequestListExpedientsInspectorsUnassignAlertControler: React.FC<IRequestListExpedientsInspectorsUnassignAlertControlerProps> = ({
    showInspectorUnassign,
    setShowInspectorUnassign,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "LIST.EXPEDIENTS_DESCRIPTIONS" });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const { expedientNumber, requestId, inspectionId, inspector } = useAppSelector((store) => store.assignInspectorForm.unassignInspector);

    const onCancelInspectorsUnassignPopUp = () => {
        setShowInspectorUnassign(false);
        dispatch(resetUnassignInspectorFormData());
        setIsLoading(false);
    };

    const onAcceptInspectorsUnassignAlert = async () => {
        setIsLoading(true);
        dispatch(patchRequestInspectionsUnassignInspector(requestId, inspectionId)).then((response: any) => {
            onCancelInspectorsUnassignPopUp();
        });
    };

    return (
        <PopUpConfirmation
            showPopUp={showInspectorUnassign}
            onCancel={onCancelInspectorsUnassignPopUp}
            onAccept={onAcceptInspectorsUnassignAlert}
            type={PopUpConfirmationType.INFO}
            height={"auto"}
            width={"50vw"}
            message={t("UNASSIGN_ALERT", { inspector, expedientNumber, interpolation: { escapeValue: false } })}
            disabledActions={isLoading}
            loading={isLoading}
        />
    );
};
