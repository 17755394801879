import React from "react";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { CustomerImportListColumn } from "./column/column";
import { useAppSelector } from "../../../../redux/hooks";

export const CustomersImportList: React.FC = () => {
    const { loading, importResponse } = useAppSelector((state) => state.customerImport);
    const onRenderColumn = () => {
        return CustomerImportListColumn();
    };

    return loading ? (
        <Skeleton
            rows={5}
            columns={2}
        />
    ) : (
        <CustomDetailsList
            data={importResponse?.errors ? importResponse.errors : []}
            renderColumns={onRenderColumn}
            height="calc(100v - 7.9rem)"
        />
    );
};
