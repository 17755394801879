import { createUseStyles } from "react-jss";

export const UtiListStyle = createUseStyles((theme: any) => ({
    listContainer: {
        display: "flex",
        width: "100%",
        "& .ms-Viewport": {
            width: "100%",
        },
        "& .ms-DetailsHeader-cellName": {
            color: theme.colors.palette.grey,
            fontFamily: theme.fonts.families.site,
        },
        "& .ms-DetailsRow-cell": {
            color: theme.colors.palette.shadowBlack,
            fontFamily: theme.fonts.families.site,
        },
    },
    icons: {
       display:"flex"
    },
    sealsContainer: {
        display: "flex",
        overflow: "auto",
        rowGap: 4,
        columnGap: 4,
        flexWrap: "wrap",
    },
    seal: {
        backgroundColor: theme.colors.palette.greyBlue,
        padding: 6,
        width: "auto",
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: 600,
        color: theme.colors.palette.purpleDarker,
        borderRadius: 50,
    },
}));
