import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { InspectionCard } from "./inspectionCard/inspectionCard";
import { InspectionSectionStyle } from "./inspectionSection.jss";
import { InspectionList } from "./list/inspectionList";
import { Inspection } from "../../../../../models";
import { useSize } from "../../../../../hooks/resizeHook";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { fetchCertificates } from "../../../../../redux/actions/catalog/catalog";
import { DeviceMinSizes } from "../../../../../common/constants";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { useTranslation } from "react-i18next";

interface IProps {
    setIndexOrder: (index: number) => void;
    setInspection: (inspection: Inspection) => void;
    setInspectionShowPopUp: (code: PopUpCodes) => void;
    deleteInspection: (index: number) => void;
    inspections: Inspection[];
}

export const InspectionSection: React.FC<IProps> = ({ setIndexOrder, deleteInspection, setInspection, setInspectionShowPopUp, inspections }) => {
    const theme = useTheme();
    const styles = InspectionSectionStyle({ theme });
    const { width } = useSize();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const certificateOptions = useAppSelector((store) => store.catalog.certificates.data);
    const organismsOptions = useAppSelector((store) => store.catalog.organisms.data);

    const onEditInspectionRequest = (inspection: Inspection, index: number) => {
        setInspection(inspection);
        setIndexOrder(index);
        setInspectionShowPopUp(PopUpCodes.UPDATE);
    };

    const onDeleteInspection = (index: number) => {
        setIndexOrder(-1);
        deleteInspection(index);
    };

    useEffect(() => {
        !certificateOptions.length && dispatch(fetchCertificates());
    }, [dispatch]);

    return (
        <>
            <div className={styles.inspectionSectionContainer}>
                {inspections.length > 0 ? (
                    width < DeviceMinSizes.DESKTOP ? (
                        inspections?.map((inspection, index) => {
                            return (
                                <InspectionCard
                                    inspection={inspection}
                                    editCard={onEditInspectionRequest}
                                    deleteCard={onDeleteInspection}
                                    key={index}
                                    indexOrder={index}
                                    certificatesOptions={certificateOptions}
                                    organismsOptions={mapIDescriptionToDropDownOptions(organismsOptions)}
                                />
                            );
                        })
                    ) : (
                        <InspectionList
                            inspections={inspections}
                            editCard={onEditInspectionRequest}
                            deleteCard={onDeleteInspection}
                            certificatesOptions={certificateOptions}
                            organismsOptions={mapIDescriptionToDropDownOptions(organismsOptions)}
                        />
                    )
                ) : (
                    <>{t("EDIT_REQUEST.FIELDS.NOT_DATA")}</>
                )}
            </div>
        </>
    );
};
