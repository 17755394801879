import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonPrimary, ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { SummaryMessageStyle } from "./summaryMessage.jss";

interface IProps {
    onAccept: () => void;
    onCancel: () => void;
}

export const SummaryMessage: React.FC<IProps> = ({ onAccept, onCancel }) => {
    const style = SummaryMessageStyle();
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });

    return (
        <div className={style.summaryMessageContainer}>
            <div className={style.textContainer}>{t("INFO.SUMMARY_MESSAGE")}</div>
            <div className={style.buttonsContainer}>
                <ButtonPrimaryInverseWithBorder
                    handleClick={onCancel}
                    title={t("BUTTONS.CANCEL")}
                />
                <ButtonPrimary
                    handleClick={onAccept}
                    title={t("BUTTONS.CONTINUE")}
                />
            </div>
        </div>
    );
};
