import { useTranslation } from "react-i18next";
import { Skeleton } from "../skeleton/skeleton";
import { TableListStyles } from "./tableListStyle.jss";
import { SkeletonSizes } from "../../../common/constants";

interface ITableListProps {
    headerContent: React.ReactNode;
    bodyContent: React.ReactNode;
    hasData: boolean;
    isLoading?: boolean;
    skeletonRows?: number;
}

export const TableList = (props: ITableListProps) => {
    const { t } = useTranslation("common", { keyPrefix: "COMMON" });
    const style = TableListStyles();

    return !props.isLoading ? (
        <>
            {props.hasData ? (
                <div className={`tableContainer ${style.tableContainer}`}>
                   <div className={`tableHeader ${style.tableHeader}`}>{props.headerContent}</div>

                    <div className={`tableBody ${style.tableBody}`}>{props.bodyContent}</div>
                </div>
            ) : (
                <div className={style.notData}>{t("NO_DATA")}</div>
            )}
        </>
    ) : (
        <Skeleton
            rows={props.skeletonRows ?? SkeletonSizes.DEFAULT}
            marginRow={10}
            size={"99.4%"}
        />
    );
};
