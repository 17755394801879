import { ConfigDockMerchandiseAPI } from "../../../api/config/dockMerchandise";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import { throwErrorToastrAsync } from "../../../utils";
import { setConfigDockMerchandiseForm, setConfigDockMerchandiseLoadingForm } from "../../reducers/config/dockMerchandise/form";
import { setConfigDockMerchandiseList, setConfigDockMerchandiseLoadingList } from "../../reducers/config/dockMerchandise/list";
import { AppThunk, store } from "../../store";
const configMerchandiseCategoryApi = new ConfigDockMerchandiseAPI();
export const fetchConfigDockMerchandiseListAction = (): AppThunk => async (dispatch) => {
    const { filter } = store.getState().configDockMerchandiseFilter;
    const newFilter = {
        ...filter,
        dockId: String(filter.dockId) === DefaultValues.DEFAULT_ALL_SELECTOR ? null : filter.dockId,
        merchandiseTypeId: String(filter.merchandiseTypeId) === DefaultValues.DEFAULT_ALL_SELECTOR ? null : filter.merchandiseTypeId,
        merchandiseOriginId: String(filter.merchandiseOriginId) === DefaultValues.DEFAULT_ALL_SELECTOR ? null : filter.merchandiseOriginId,
    };

    try {
        dispatch(setConfigDockMerchandiseLoadingList(true));
        const response = await configMerchandiseCategoryApi.getConfigDockMerchandiseList(newFilter);
        dispatch(setConfigDockMerchandiseList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setConfigDockMerchandiseLoadingList(false));
    }
};

export const fetchConfigDockMerchandiseAction =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setConfigDockMerchandiseLoadingForm(true));
            const response = await configMerchandiseCategoryApi.getConfigDockMerchandise(id);

            let form = {
                ...response,
                merchandiseOriginId: response.merchandiseOriginId ? response.merchandiseOriginId : -1,
            };

            dispatch(setConfigDockMerchandiseForm(form));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setConfigDockMerchandiseLoadingForm(false));
        }
    };

export const postAndPutConfigDockMerchandise = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().configDockMerchandiseForm;
        const newform: any = {
            ...form,
            merchandiseOriginId: String(form.merchandiseOriginId) === DefaultValues.DEFAULT_ALL_SELECTOR ? null : form.merchandiseOriginId,
            merchandiseTypeId: String(form.merchandiseTypeId) === DefaultValues.DEFAULT_ALL_SELECTOR ? null : form.merchandiseTypeId,
        };

        try {
            dispatch(setConfigDockMerchandiseLoadingForm(true));
            if (form.id) {
                await configMerchandiseCategoryApi.putConfigDockMerchandise(newform);
            } else {
                await configMerchandiseCategoryApi.postConfigDockMerchandise(newform);
            }dispatch(fetchConfigDockMerchandiseListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);dispatch(setConfigDockMerchandiseLoadingForm(false));
        } 
    };
};

export const removeConfigDockMerchandise = (): any => {
    return async (dispatch: any) => {
        const { id } = store.getState().configDockMerchandiseForm.form;
        try {
            dispatch(setConfigDockMerchandiseLoadingForm(true));
            await configMerchandiseCategoryApi.deleteConfigDockMerchandise(id ?? 0);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchConfigDockMerchandiseListAction());
            dispatch(setConfigDockMerchandiseLoadingForm(false));
        }
    };
};
