import { createUseStyles } from "react-jss";

export const RequestListExpedientsAddInspectorContainerStyle = createUseStyles((theme: any) => ({
    formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        width:"100%", 
        justifyContent:"center"
    },
}));
