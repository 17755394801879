import { createUseStyles } from "react-jss";

export const ManagementParkingValidationFormStyle = createUseStyles((theme: any) => ({
    parkingValidationContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 30,
    },
    parkingValidationName: {
        width: "30%",
        paddingTop: 20,
    },
    parkingValidationFormContainer: {
        display: "flex",
        gap: 30,
        width: "100%",
        height: "calc(85vh - 10rem)",
    },

    parkingValidationRestrictionBox: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        gap: 15,
    },
    parkingValidationExceptionBox :{
        width: "50%",
        display: "flex",
        flexDirection: "column",
        gap: 15,
    },
    
    box: {
        overflowY: "auto",
        overflowX: "hidden",
        display:"flex", 
        flexDirection:"column", 
        gap:10
    }, 
    
    utiContainer: {
        display: "flex",
        flexDirection: "row",
        gap: 15,
        alignItems: "center",
        marginBottom: 25,
    }

}));
