import { createUseStyles } from "react-jss";

export const UserSignupResumeTermsAndConditionsStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        padding: theme.paddings.default,
        gap:theme.gaps.sizes.sm
    },
    popUpContainer: {
        maxWidth: 600,
        height: 400,
        overflow: "auto"
    },
}));
