import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty, onUpdateSortedColumnUtil } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { OrganismWithCertificateColumnsStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { updateSortColumn } from "../../../../../redux/reducers/resources/organismCertificatedType/list";
import { IConfigOrganismCertificateType } from "../../../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { fetchOrganismCertificateType } from "../../../../../redux/actions/resources/organismCertificateType/organismCertificateType";
import {
    setPopUpConfigOrganismCertificateTypeForm,
    updateConfigOrganismCertificateTypeProperty,
} from "../../../../../redux/reducers/resources/organismCertificatedType/form";
import { Separator } from "../../../../common/separator/separator";

export const OrganismWithCertificateListColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = OrganismWithCertificateColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONFIG.ORGANISMCERTIFICATETYPE" });
    const dispatch = useAppDispatch();
    const { sort } = useAppSelector((state) => state.configOrganismCertificateTypeList);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().configOrganismCertificateTypeList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IConfigOrganismCertificateType>("organismDescription"),
            minWidth: 200,
            maxWidth: 300,
            name: compileNameOfProperty<IConfigOrganismCertificateType>("organismDescription"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigOrganismCertificateType>("organismDescription")}
                    title={t("ORGANISM")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigOrganismCertificateType>("organismDescription"))}
                />
            ),
            onRender: (item: IConfigOrganismCertificateType) => <span>{item.organismDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IConfigOrganismCertificateType>("certificateTypeDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: compileNameOfProperty<IConfigOrganismCertificateType>("certificateTypeDescription"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigOrganismCertificateType>("certificateTypeDescription")}
                    title={t("CERTIFICATE_TYPE")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigOrganismCertificateType>("certificateTypeDescription"))}
                />
            ),
            onRender: (item: IConfigOrganismCertificateType) => <span>{item.certificateTypeDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigOrganismCertificateType>("automaticExpedient"),
            minWidth: 100,
            maxWidth: 200,
            name: compileNameOfProperty<IConfigOrganismCertificateType>("automaticExpedient"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigOrganismCertificateType>("automaticExpedient")}
                    title={t("AUTOMATIC_EXPEDIENT")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigOrganismCertificateType>("automaticExpedient"))}
                />
            ),
            onRender: (item: IConfigOrganismCertificateType) => (
                <span>
                    <BooleanValue value={item.automaticExpedient} />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigOrganismCertificateType>("automaticCertificateNumber"),
            minWidth: 250,
            maxWidth: 300,
            name: compileNameOfProperty<IConfigOrganismCertificateType>("automaticCertificateNumber"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigOrganismCertificateType>("automaticCertificateNumber")}
                    title={t("AUTOMATIC_CERTIFICATE_NUMBER")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigOrganismCertificateType>("automaticCertificateNumber"))}
                />
            ),
            onRender: (item: IConfigOrganismCertificateType) => (
                <span>
                    <BooleanValue value={item.automaticCertificateNumber} />
                </span>
            ),
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IConfigOrganismCertificateType) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setPopUpConfigOrganismCertificateTypeForm(PopUpCodes.UPDATE));
                            dispatch(fetchOrganismCertificateType(item.id ?? 0));
                        }}
                    />
                    <Separator size="auto"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(setPopUpConfigOrganismCertificateTypeForm(PopUpCodes.DELETE));
                            let payload = {
                                name: compileNameOfProperty<IConfigOrganismCertificateType>("id"),
                                value: item.id ?? 0,
                            };

                            dispatch(updateConfigOrganismCertificateTypeProperty(payload));

                             
                        }}
                    ></CustomIcons>
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
