import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../common/constants";

export const SiteNavStyles = createUseStyles((theme: any) => ({
    sidebar: {
        minHeight: 60,
        gap: 10,
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "hidden",
    },

    sidebarFull: {
        flexDirection: "row",
        paddingTop: 5,
        justifyContent: "flex-start",
    },
    siteNavContainer: {
        position: "fixed",
        background: theme.colors.siteNav.background,
        color: theme.colors.siteNav.color,
        borderRight: `1px ${theme.colors.siteNav.background} solid`,
        transition: "0.3s",
        zIndex: "9999",
        width: theme.widths.siteNav.hideMenu,
        height: "100vh",
        "& img": {
            width: 45,
            top: 36,
            transition: "0.3s",
        },
        "& img.full-image": {
            width: 100,
            paddingLeft: 20,
        },
        [mediaQueries.MOBILE]: {
            width: 0,
        },
        [mediaQueries.TABLET]: {
            width: 0,
        },
    },
    siteNavContainerFull: {
        width: theme.widths.siteNav.panel,
    },
    skeletonContainer: {
        opacity: "0.2",
        padding: theme.paddings.default,
    },
    collapsedNavButton: {
        position: "absolute",
        right: 15,
        fontSize: theme.fonts.sizes.md,
        cursor: "pointer",
        color: theme.colors.siteNav.navButton.color,
    },
    collapsedNavButtonSmall: {
        position: "initial",
        fontSize: theme.fonts.sizes.sm,
    },
    collapsedNavMenu: {
        minWidth: theme.widths.siteNav.hideMenu,
        color: theme.colors.siteNav.color,
        background: theme.colors.siteNav.background,
    },
    logo: {
        width: 60,
    },
    navMenuControl: {
        padding: "15px 15px 22px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    navMenu: {
        overflowY: "auto",
        height: "calc(100vh - 72px)",
        display: "none",
        paddingBottom: theme.paddings.sizes.base,
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-track": {
            background: theme.colors.siteNav.background,
        },
        "&::-webkit-scrollbar-thumb": {
            background: theme.colors.palette.greyDark,
            borderRadius: 3,
        },
    },
    navMenuBlock: {
        display: "block",
        overflowX: "hidden",
    },
}));
