import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { RepresentativeStyle } from "./containerStyle.jss";
interface IRepresentativeProps {
    validated?: boolean;
}

export const Representative: React.FC<IRepresentativeProps> = ({ validated }) => {
    const theme = useTheme();
    const style = RepresentativeStyle({ theme });
    const { t } = useTranslation("common");

    return <div className={validated ? style.validate : style.pending}>{validated ? t("VALIDATED") : t("PENDING")}</div>;
};
