import { CatalogAPI } from "../../../../../api";

import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterOrganismForm, setMasterOrganismForm } from "../../../../reducers/resources/master/organism/form";
import { setLoadingMasterOrganismList, setMasterOrganismList } from "../../../../reducers/resources/master/organism/list";
import { AppThunk, store } from "../../../../store";
import { fetchOrganismRemaining } from "../../../catalog/catalog";

const catalogAPI = new CatalogAPI();

export const fetchMasterOrganismList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterOrganismList(true));
        const response = await catalogAPI.getMasterOrganismList();
        dispatch(setMasterOrganismList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterOrganismList(false));
    }
};

export const fetchMasterOrganismForm =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterOrganismForm(true));
            const response = await catalogAPI.getMasterOrganism(id);
            dispatch(setMasterOrganismForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterOrganismForm(false));
        }
    };

export const postToMasterOrganismForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterOrganismForm;

        try {
            dispatch(setLoadingMasterOrganismForm(true));
            if (form.id) {
                await catalogAPI.putMasterOrganism(form);
                dispatch(fetchOrganismRemaining());
            } else {
                await catalogAPI.postMasterOrganism(form);
            }
            dispatch(fetchMasterOrganismList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterOrganismForm(false));
            return false;
        }
    };
};

export const patchSortOrganismList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterOrganismList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterOrganismList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterOrganismList(false));
        }
    };
