import { createUseStyles } from "react-jss";

export const TermsAndConditionsStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        "& p": {
            marginBottom: theme.margins.sizes.md,
        },
        "& ul": {
            marginBottom: theme.margins.sizes.md,
        }
    },
}));
