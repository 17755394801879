import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ExceptionType } from "../../../../../../../common/enum/management/ExceptionType";
import { IValidationParkingRuleException } from "../../../../../../../models/management/parkingValidationRule/IParkingValidationRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { deleteAllExceptionRule, setExceptionAppointmentRule } from "../../../../../../../redux/reducers/management/parkingValidation/form";
import { Section } from "../section/section";
import { ExceptionAppointmentForm } from "./appointment/form/appointment";
import { exceptionSectionStyle } from "./exceptionSectionStyle.jss";

export const ManagementParkingValidationFormException = () => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = exceptionSectionStyle({ theme });
    const { view } = useAppSelector((state) => state.managementParkingValidationConfiguration);
    const {
        form: { validationParkingRuleExceptions },
    } = useAppSelector((state) => state.managementParkingValidationRuleForm);
    const [showExceptionContent, setShowExceptionContent] = useState<boolean>(false);

    const active = useMemo(() => {
        const activeContent = validationParkingRuleExceptions.some(
            (exception) =>
                exception.validationParkingExceptionId === ExceptionType.APPOINTMENT && (exception.deleted === false || exception.deleted === null),
        );
        setShowExceptionContent(activeContent);
        return activeContent;
    }, [validationParkingRuleExceptions]);

    const viewParams = useMemo(
        () => view?.validationParkingExceptions.find((item) => item.id === ExceptionType.APPOINTMENT),
        [validationParkingRuleExceptions],
    );

    const onCheckboxChange = useCallback((event?: any, checked = false) => {
        if (!checked) {
            dispatch(deleteAllExceptionRule(ExceptionType.APPOINTMENT));
        }
        setShowExceptionContent(checked);
    }, []);

    const disabledCheckBox = useMemo(() => {
        const exceptionLines = view?.validationParkingAppointmentExceptionLines ?? [];
        if (exceptionLines?.length > 0) {
            const exceptionLine = exceptionLines[0];
            const isSelectedFirstOption = validationParkingRuleExceptions.some(
                (exception) =>
                    exception.validationParkingExceptionId === ExceptionType.APPOINTMENT &&
                    (exception.deleted === false || exception.deleted === null) &&
                    exception.validationParkingAppointmentExceptionLineId === exceptionLine.id,
            );

            !isSelectedFirstOption && dispatch(deleteAllExceptionRule(ExceptionType.APPOINTMENT));

            return !isSelectedFirstOption;
        }

        return false;
    }, [validationParkingRuleExceptions]);

    const onActiveExceptionLine = useCallback(
        (payload: IValidationParkingRuleException) => {
            dispatch(setExceptionAppointmentRule(payload));
        },
        [dispatch],
    );

    return (
        <div className={style.parkingValidationExceptionActiveContainer}>
            <Section
                showBubble={active || showExceptionContent}
                title={viewParams?.description ?? ""}
                showDisabledController={true}
                disabledControllerLabel={!active ? t("COMMON.DEACTIVATE") : t("COMMON.ACTIVE")}
                onChangeDisabledController={onCheckboxChange}
                sectionActive={active || showExceptionContent}
                value={
                    <div className={style.container}>
                        <div className={style.appointment}>
                            {view?.validationParkingAppointmentExceptionLines.map((exceptionLine, index) => (
                                <ExceptionAppointmentForm
                                    key={exceptionLine.id}
                                    data={exceptionLine}
                                    disabledData={index === 0 ? false : disabledCheckBox}
                                    updateData={(payload: IValidationParkingRuleException) => {
                                        onActiveExceptionLine(payload);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                }
            />
        </div>
    );
};
