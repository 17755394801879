import { useBoolean, useId } from "@fluentui/react-hooks";
import dayjs from "dayjs";
import { useState } from "react";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../assets/icons";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { addDayToDate, convertToLocal } from "../../../utils/dateUtils";
import { CustomIcons } from "../customIcons/customIcons";
import { DayPickerOnlyComponent } from "../dayPicker/dayPickerOnly";
import { TeachingBubbleComponent } from "../teachingBubble/teachingBubble";
import { CustomerDatePickerStyle } from "./customerDatePickerStyle.jss";
require("dayjs/locale/es");

interface ICustoemDatePickerProps {
    setFilterProperty?: (name: any, value: any) => any;
    onAppliFilters?: () => any;
    date: Date;
    title?: string;
    showLeftArrowController?: boolean;
}

export const CustomDatePickerOnlyDate: React.FC<ICustoemDatePickerProps> = ({
    setFilterProperty,
    title,
    date,
    showLeftArrowController,
    onAppliFilters,
}) => {
    const [customDate, setCustomDate] = useState<Date | undefined>(date);
    const [rangeSelected, setRangeSelected] = useState<boolean>(false);

    const theme = useTheme();
    const styles = CustomerDatePickerStyle({ theme, rangeSelected, showLeftArrowController });
    const [calendarVisible, { toggle: toggleCalendarVisible }] = useBoolean(false);
    const buttonId = useId("targetButton");

    const onSelectBeforeDate = () => {
        let newDate = addDayToDate(customDate, "day", -1, FormatDate.ISO);
        setDate(new Date(newDate));
    };

    const onSelectNextDate = () => {
        let newDate = addDayToDate(customDate, "day", 1, FormatDate.ISO);
        setDate(new Date(newDate));
    };

    const setDate = (newDate: Date | undefined) => {
        if (date && setFilterProperty) {
            setFilterProperty("date", convertToLocal(newDate, FormatDate.ISO));
            setCustomDate(newDate);
            onAppliFilters && onAppliFilters();
        }
    };

    return (
        <>
            <div className={styles.datePicker}>
                {title && <div className={styles.dateTitle}>{title}</div>}

                <div className={styles.controller}>
                    {showLeftArrowController && (
                        <CustomIcons
                            iconName={NameIcons.FLAGRIGHT}
                            className={styles.reverse}
                            onClick={onSelectBeforeDate}
                            isDisabled={rangeSelected}
                            margin={"0px"}
                        ></CustomIcons>
                    )}

                    <div
                        className={styles.dateContainer}
                        onClick={toggleCalendarVisible}
                        id={showLeftArrowController ? buttonId : undefined}
                    >
                        <CustomIcons
                            id={buttonId}
                            iconName={NameIcons.CALENDAR}
                            className={styles.calendarIcon}
                            margin={"0px"}
                        ></CustomIcons>

                        <div className={styles.date}>{dayjs(date).startOf("day").locale("es").format(FormatDate.DATE_MONTH)}</div>
                        {!showLeftArrowController && (
                            <div className={styles.iconRange}>
                                <CustomIcons
                                    id={buttonId}
                                    iconName={NameIcons.CHEVRONDOWNICON}
                                    className={calendarVisible ? "arrowUp" : "arrow"}
                                ></CustomIcons>
                            </div>
                        )}
                    </div>

                    {showLeftArrowController && (
                        <CustomIcons
                            iconName={NameIcons.FLAGRIGHT}
                            onClick={onSelectNextDate}
                            isDisabled={rangeSelected}
                            margin={"0px"}
                        ></CustomIcons>
                    )}
                </div>
            </div>
            <div hidden>
                {calendarVisible && (
                    <TeachingBubbleComponent
                        id={buttonId}
                        onToggle={toggleCalendarVisible}
                    >
                        <DayPickerOnlyComponent
                            mode="single"
                            defaultMonth={date}
                            selected={customDate}
                            onSelect={setDate}
                        />
                    </TeachingBubbleComponent>
                )}
            </div>
        </>
    );
};
