import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
    deleteMasterAppointmentTimeZones,
    postMasterAppointmentTimeZones,
    updateMasterAppointmentTimeZones,
} from "../../../../../../../../../../redux/actions/masterAppointment/appointmentBoard/appointmentTimeZones/action";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../redux/hooks";
import {
    resetMasterAppointmentTimeZoneForm,
    setShowPopUpMasterAppointmentTimeZone,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDockTimeZone/form";
import { PopUpConfirmation } from "../../../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../../../../../../common/popup/popUpForm/popUpForm";
import { MasterBoardHistoryAppointmentTimeZoneForm } from "./form/form";
import { PopUpConfirmationType } from "../../../../../../../../../../common/enum/PopUpConfirmationType";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../../../../../../../common/requiredMessage/requiredMessage";

export const MasterBoardHistoryAppointmentTimeZoneControllerForm = () => {
    const dispatch = useAppDispatch();
    const { showPopUp, form, loading } = useAppSelector((store) => store.masterAppointmentTimeZone);
    const { id } = useAppSelector((store) => store.masterAppointmentBoardEdit.form);

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_CONDITIONS" });

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE) {
            dispatch(postMasterAppointmentTimeZones(id.toString(), form)).then((response: any) => {
                response && onClose();
            });
        } else if (showPopUp === PopUpCodes.UPDATE) {
            dispatch(updateMasterAppointmentTimeZones(id.toString(), form.id.toString(), form)).then((response: any) => {
                response && onClose();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(deleteMasterAppointmentTimeZones(id.toString(), form.id.toString()));
            onClose();
        }
    };

    const onDimissed = () => {
        dispatch(resetMasterAppointmentTimeZoneForm());
    };
    const onClose = () => {
        dispatch(setShowPopUpMasterAppointmentTimeZone(PopUpCodes.NONE));
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE_ZONES");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE_ZONES");
        }
        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return !form.code || !form.description || !form.turnId || !form.startTime || !form.endTime;
    }, [form]);

    return (
        <>
            <PopUpForm
                content={<MasterBoardHistoryAppointmentTimeZoneForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onClose}
                onSave={onSave}
                height={"30vh"}
                width={"40vw"}
                onDimissed={onDimissed}
                isDisabledButton={isDisabledButton}
                isLoading={loading}
                leftButtonContent={<RequiredFieldsMessage />}
            />

            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onClose}
                onAccept={onSave}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                disabledActions={loading}
                height={"auto"}
                width={"auto"}
            ></PopUpConfirmation>
        </>
    );
};
