import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { RequestIncidenceType } from "../../../../../../../../common/enum/categoryIncidence";
import { IGenericPayload } from "../../../../../../../../models";
import { ICreateIncidence } from "../../../../../../../../models/requests/IRequestIncidence";
import { fetchIncidenceTypes, fetchOrganisms } from "../../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { updateIncidenceFormProperty } from "../../../../../../../../redux/reducers/request/edit/form/incidence";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../../utils";
import { FormField } from "../../../../../../../common/fields/fields";
import { Selector } from "../../../../../../../common/selectors/selector";
import { requestMaxLength } from "../../../../../create/validation/requestConfig";
import { incidencePopUpStyle } from "./formStyle.jss";
import { setIncidenceType } from "../../../../../../../../redux/reducers/catalog/catalog";
interface IEditRequestIncidenceFormProps {
    showPopUp: boolean;
}

export const EditRequestIncidenceForm: React.FC<IEditRequestIncidenceFormProps> = ({ showPopUp }) => {
    const theme = useTheme();
    const styles = incidencePopUpStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });

    const dispatch = useAppDispatch();
    const { form } = useAppSelector((store) => store.editRequestIncidenceForm);
    const { organismId } = useAppSelector((store) => store.userData.profile);

    const organismType = useAppSelector((store) => store.catalog.organisms.data);
    const incidenceTypes = useAppSelector((store) => store.catalog.incidenceTypes.data);

    const bloquedIncidenceSelector = (!form.organismId && form.category === RequestIncidenceType.ORGANISM) || incidenceTypes?.length === 0 ? true : false;
    const bloquedOrganismSelector = form.id || organismId  ? true : false;

    const {
        register,
        formState: { errors },
    } = useForm<ICreateIncidence>({ mode: "onChange" });

    const updateHeaderProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name: name,
            value: value,
        };
        dispatch(updateIncidenceFormProperty(payload));
    };

    const onSelectorChange = (event: any, option: any, name: string) => {
        if (name === compileNameOfProperty<ICreateIncidence>("organismId") && form.typeId) {
            updateHeaderProperty(compileNameOfProperty<ICreateIncidence>("typeId"), 0);
            dispatch(setIncidenceType(undefined));
        }

        updateHeaderProperty(name, option.key);
    };

    const onInputChange = (event: any) => {
        const { name, value } = getGenericPayloadFromEvent(event);
        updateHeaderProperty(name, value);
    };

    useEffect(() => {
        if (showPopUp) {
            if (form.category === RequestIncidenceType.ORGANISM) {
                dispatch(fetchOrganisms());
                dispatch(fetchIncidenceTypes(form.organismId));
            } else if (form.category === RequestIncidenceType.OPERATIONAL) {
                dispatch(fetchIncidenceTypes());
            }
        }
    }, [form.category, form.organismId, showPopUp]);

    useEffect(() => {
        if (organismId) {
            updateHeaderProperty(compileNameOfProperty<ICreateIncidence>("organismId"), organismId);
        }
        if (incidenceTypes && incidenceTypes.length === 1 && form.organismId) {
            updateHeaderProperty(compileNameOfProperty<ICreateIncidence>("typeId"), incidenceTypes[0].id);
        }
    }, [organismId, form.organismId, incidenceTypes]);

    return (
        <>
            <div className={styles.form}>
                {form.category === RequestIncidenceType.ORGANISM && (
                    <Selector
                        selectedKey={form.organismId}
                        name={compileNameOfProperty<ICreateIncidence>("organismId")}
                        onChange={onSelectorChange}
                        options={mapIDescriptionToDropDownOptions(organismType)}
                        title={t("ORGANISMDESCRIPTION")}
                        required={true}
                        disabled={bloquedOrganismSelector}
                    />
                )}

                <Selector
                    selectedKey={form.typeId}
                    name={compileNameOfProperty<ICreateIncidence>("typeId")}
                    onChange={onSelectorChange}
                    options={mapIDescriptionToDropDownOptions(incidenceTypes ?? [])}
                    title={t("INCIDENCETYPE")}
                    disabled={bloquedIncidenceSelector}
                    required={true}
                    errorMessage={incidenceTypes !== undefined && !incidenceTypes.length ? t("DONTINCIDENCE") : undefined}
                />

                <FormField
                    type="text"
                    value={form.remarks}
                    label={t("REMARKS")}
                    maxLength={requestMaxLength.DESCRIPTION}
                    error={errors.remarks}
                    {...register(compileNameOfProperty<ICreateIncidence>("remarks"), { onChange: onInputChange, value: form.remarks })}
                    disabled={!form.typeId ? true : false}
                />
            </div>
        </>
    );
};
