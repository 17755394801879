import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterActuationTypeListContainer } from "./list/list";
import { setActuationTypes } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterActuationTypeList } from "../../../../../redux/reducers/resources/master/actuationType/list";

export class MasterActuacionTypeContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterActuationTypeList());
        store.dispatch(setActuationTypes([]));
    }

    render(): React.ReactNode {
        return (
            <MasterActuationTypeListContainer
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
