import { createUseStyles } from "react-jss";

export const RequestPendingAssignmentFilterStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        width: "50%",
        gap: theme.gaps.filter.default,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: theme.gaps.filter.default,
    },
    filters: {
        flex: "1 1 auto",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        gap: theme.gaps.filter.default,
        "& .description": {
            width: "100%",
        },
        "& .origin": {
            width: "48%",
        },
    },
}));
