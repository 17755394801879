import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { generateRandomKeys } from "../../../../../utils/keys";
import { dinamicItemData } from "../../utils/utils";

interface IInspectionDetailedColumnFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsInspectionDetailedListColumnFooter = (props: IInspectionDetailedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.DETAILED_TABLE" });
    const { organisms, inspectionsStatistics } = useAppSelector((store) => store.statiticsInspectionDetailedList.data);

    const columns: IColumn[] = [
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTAL_FINAL")}</b>,
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => (
                <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}>
                    {" "}
                    {inspectionsStatistics.totalRequestsPositionedWithInvoiceConcepts}
                </b>
            ),
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> {inspectionsStatistics.totalRequests}</b>,
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(3, 3, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => <b style={generateStyleWhithCustomFooter(3, 3, props.listColumnsFooter)}> {inspectionsStatistics.totalIncidences}</b>,
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(4, 4, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => (
                <b style={generateStyleWhithCustomFooter(4, 4, props.listColumnsFooter)}> {inspectionsStatistics.totalAverageEntryTime}</b>
            ),
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(5, 5, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => (
                <b style={generateStyleWhithCustomFooter(5, 5, props.listColumnsFooter)}> {inspectionsStatistics.totalAverageInspectionTime}</b>
            ),
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(6, 6, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => (
                <b style={generateStyleWhithCustomFooter(6, 6, props.listColumnsFooter)}> {inspectionsStatistics.totalAverageExitTime}</b>
            ),
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(7, 7, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => (
                <b style={generateStyleWhithCustomFooter(7, 7, props.listColumnsFooter)}> {inspectionsStatistics.totalAverageTotalTime}</b>
            ),
        },
    ];

    const dinamicOrganismItem = () => {
        for (let organism of organisms) {
            const index = props.listColumnsFooter.findIndex((item) => item.key === organism.code);
            columns.push({
                key: generateRandomKeys().toString(),
                minWidth: calcWidthColumFooter(index, index, props.listColumnsFooter),
                name: generateRandomKeys().toString(),
                onRender: () => (
                    <b style={generateStyleWhithCustomFooter(index, index, props.listColumnsFooter)}>
                        {dinamicItemData(inspectionsStatistics.organismRequests, organism)}
                    </b>
                ),
            });
        }
        return columns;
    };

    return dinamicOrganismItem();
};
