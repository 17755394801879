import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterTransportUnit } from "../../../../../models/resources/master/ITransportUnit";

export interface IMasterTransportUnitState {
    list?: IMasterTransportUnit[];
    loading: boolean;
}

const initialState: IMasterTransportUnitState = {
    loading: false,
};

export const masterTransportUnitList = createSlice({
    name: "masterOrganismList",
    initialState,
    reducers: {
        resetMasterTransportUnitList: () => initialState,
        setLoadingTransportUnitList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTransportUnitList: (state, action: PayloadAction<IMasterTransportUnit[]>) => {
            state.list = action.payload;
        },
    },
});

export const {resetMasterTransportUnitList, setLoadingTransportUnitList, setMasterTransportUnitList } = masterTransportUnitList.actions;

export default masterTransportUnitList.reducer;
