import { useId } from "@fluentui/react-hooks";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { FieldError } from "react-hook-form/dist/types/errors";
import { FieldsStyle } from "../fields/fields.jss";
import { Input } from "../inputs/input";
import { Label } from "../labels/label";
import { ShowCallOutComponent } from "../radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
interface IFieldsProps {
    name: string;
    label?: string;
    value: string;
    error?: FieldError;
    disabled?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    onChange?: (e: any) => any;
    readonly?: boolean;
    minNumber?: string;
    decimalNumber?: number;
    calloutMessage?: string;
    calloutLabel?: string;
    nonZeroValue?: boolean;
}
export const FormNumberField = React.forwardRef<HTMLInputElement, IFieldsProps & ReturnType<UseFormRegister<any>>>(
    (
        {
            onChange,
            name,
            label,
            value,
            disabled,
            isRequired,
            maxLength,
            readonly,
            minNumber,
            decimalNumber,
            calloutMessage,
            calloutLabel,
            nonZeroValue,
        },
        ref,
    ) => {
        const iconId = useId("callout-iconButton");
        const [isCalloutVisible, setCalloutVisibility] = useState<boolean>(false);
        const showCallOut = calloutLabel && calloutMessage;
        const inputStyle = FieldsStyle();
        const filled: string | number = !!value && value[0]?.length > 0 ? "filled" : "";
        const [error, setErrorMessage] = useState<string>("");
        const errorMessage = decimalNumber
            ? t("FORM.VALIDATION.ONLY_THREE_DECIMALS_ALLOWED", { decimalNumber })
            : t("FORM.VALIDATION.POSITIVE_INTEGERS");

        const decimalsNumber = decimalNumber ? decimalNumber : 0;
        const regex = new RegExp(decimalNumber ? `(^([0-9]*)([,.][0-9]{0,${decimalsNumber}})?$)` : "^[0-9]+$");
        const regexNonZero = new RegExp(`^(?!0+$).+`);

        useEffect(() => {
            if (isRequired && value.length === 0) {
                setErrorMessage(t("FORM.VALIDATION.REQUIRE"));
            }
        }, []);

        const changeData = (event: any, _newValue?: string) => {
            const currentValue = String(event.target.value);

            if (nonZeroValue && currentValue !== "" && !regexNonZero.test(currentValue)) {
                setErrorMessage(t("FORM.VALIDATION.NON_ZERO_VALUE", { label }));
                return;
            }

            if (!regex.test(currentValue)) {
                setErrorMessage(errorMessage);
            }
            if (regex.test(currentValue)) {
                setErrorMessage("");
                onChange(event);
            }

            if (!currentValue) {
                onChange(event);
                if (isRequired) {
                    setErrorMessage(t("FORM.VALIDATION.REQUIRE"));
                }
            }
        };

        return (
            <div className={inputStyle.formGroup}>
                <Input
                    name={name}
                    value={value.replace(".", ",")}
                    type={"text"}
                    maxLength={maxLength}
                    onChange={changeData}
                    ref={ref}
                    disabled={disabled}
                    readonly={readonly}
                    min={minNumber}
                />
                {label && !showCallOut && (
                    <>
                        <Label
                            title={label}
                            className={filled}
                            isRequired={isRequired}
                        />
                    </>
                )}
                {error && <div className={inputStyle.error}>{error ? error : ""}</div>}{" "}
                {showCallOut && (
                    <span className={inputStyle.inputNumberCallout}>
                        <ShowCallOutComponent
                            calloutLabel={calloutLabel}
                            required={isRequired}
                            iconId={iconId}
                            setCalloutVisibility={setCalloutVisibility}
                            isCalloutVisible={isCalloutVisible}
                            calloutMessage={calloutMessage}
                        />
                    </span>
                )}
            </div>
        );
    },
);
