import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { IGenericPayload } from "../../../../../../../models";

import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils/formUtils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterOrganismFormStyle } from "./formStyle.jss";

import { TitleH5 } from "../../../../../../common/titles/titles";
import { CustomToogle } from "../../../../../../common/toogle/toogle";
import { IPayloadUserChecknotification } from "../../../../../../user/profile/notifications/item/item";
import { ColorType } from "../../../../../../../common/enum/ColorType";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterOrganismItem } from "../../../../../../../models/resources/master/IOrganism";
import {
    IMasterTransportUnitRemaining,
    IMasterTransportUnit,
    IMasterTransportUnitSize,
} from "../../../../../../../models/resources/master/ITransportUnit";
import {
    updateMasterTransportUnitProperty,
    setMasterTransportUnitForm,
    updateMasterTransportUnitSizeActive,
} from "../../../../../../../redux/reducers/resources/master/transportUnit/form";
interface IMasterTransportUnitFormProps {
    showNotData: boolean;
    data: IMasterTransportUnitRemaining[];
}

export const MasterSealTypesForm: React.FC<IMasterTransportUnitFormProps> = ({ showNotData, data }) => {
    const theme = useTheme();
    const style = MasterOrganismFormStyle({ theme });
    const dispatch = useAppDispatch();

    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);

    const { form } = useAppSelector((state) => state.masterTransportUnitForm);
    const { register, control } = useForm<IMasterTransportUnit>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateMasterTransportUnitProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateFormProperty(name, option.key);
            let transport = data.find((item) => item.id === option.key);
            if (transport) {
                let newForm: IMasterTransportUnit = {
                    active: true,
                    code: transport.code,
                    container: transport.container,
                    description: transport.description,
                    referenceId: String(option.key),
                    transportUnitSizes: !transport.sizes
                        ? undefined
                        : transport.sizes.map((item) => {
                              return {
                                  code: item.code,
                                  description: item.description,
                                  active: true,
                                  referenceId: String(item.id),
                              };
                          }),
                };
                dispatch(setMasterTransportUnitForm(newForm));
            }
        },
        [dispatch, data],
    );

    const disabledInputs = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [form, showPopUp]);
    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterOrganismItem>("referenceId")}
                        title={t("FORM.TRANSPORT_UNIT")}
                        options={mapIDescriptionToDropDownOptions(data)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterTransportUnit>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterTransportUnit>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                    disabled={disabledInputs}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={form?.container ? 1 : 0}
                    propertyName={compileNameOfProperty<IMasterTransportUnit>("container")}
                    title={t("CONTAINER")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInputs}
                />
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterTransportUnit>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInputs}
                />
            </div>
            {form.transportUnitSizes && form.transportUnitSizes?.length > 0 && (
                <div className={style.rowForm}>
                    <div className={style.transportUnitSizesContainer}>
                        <TitleH5
                            title={t("FORM.UTI_SIZES")}
                            bold
                            color={ColorType.black}
                        />

                        {form.transportUnitSizes.map((item: IMasterTransportUnitSize, notificationIndex: number) => {
                            return (
                                <span key={`${item.description ?? DefaultValues.EMPTY_LIST}-${notificationIndex}`}>
                                    <CustomToogle
                                        checked={item.active}
                                        onChage={(event: any, checked?: boolean | undefined) => {
                                            const payload: IPayloadUserChecknotification = {
                                                active: !item.active,
                                                notificationIndex,
                                            };

                                            dispatch(updateMasterTransportUnitSizeActive(payload));
                                        }}
                                        inlineLabel={true}
                                        label={item.description ?? DefaultValues.EMPTY_LIST}
                                    />
                                </span>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
