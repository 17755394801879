import { createUseStyles } from "react-jss";

export const ManageUsersStyle = createUseStyles((_theme: any) => ({
    manageUsersContainer: {
        padding: 8,
        display: "flex",
        flexDirection: "column",
    },
    manageUsersHeader: {
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    users: {
        display: "flex",
        minHeight: 100,
        paddingTop: 16,
    },
}));
