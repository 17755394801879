import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";

export interface IAppointmentImportForm {
    document: string;
    typeCode: string;
}

interface IAppointmentImport {
    loading: boolean;
    form: IAppointmentImportForm;
    isVisible: string;
}

const initialState: IAppointmentImport = {
    loading: false,
    isVisible: "",
    form: {
        document: "",
        typeCode: "Appointment",
    },
};

export const AppointmentImportFormReducer = createSlice({
    name: "AppointmentImportFormReducer",
    initialState,
    reducers: {
        setAppointmentImportFormData: (state, action: PayloadAction<any>) => {
            state.form = action.payload;
        },
        setAppointmentImportFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAppointmentImportPopUp: (state, action: PayloadAction<string>) => {
            state.isVisible = action.payload;
        },

        resetsetAppointmentImportForm: () => initialState,
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    setAppointmentImportFormData,
    setAppointmentImportFormLoading,
    setAppointmentImportPopUp,
    resetsetAppointmentImportForm,
    updateFormProperty,
} = AppointmentImportFormReducer.actions;

export default AppointmentImportFormReducer.reducer;
