import { ColorType } from "../../../common/enum/ColorType";
import { RequiredSpan } from "../required/requiredSpan";
import { TitleStyled } from "./titles.jss";

interface ITitleProps {
    type?: string;
    title: string;
    color?: ColorType;
    bold?: boolean;
    className?: string;
    isRequired?: boolean;
}

export const Title = (props: ITitleProps) => {
    const titleStyled = TitleStyled();
    const className = props.className ?? "";

    const getColorClassName = (color?: ColorType) => {
        switch (color) {
            case ColorType.primary:
                return titleStyled.primary;
            case ColorType.secondary:
                return titleStyled.secondary;
            case ColorType.success:
                return titleStyled.success;
            case ColorType.danger:
                return titleStyled.danger;
            case ColorType.warning:
                return titleStyled.warning;
            case ColorType.info:
                return titleStyled.info;
            case ColorType.black:
                return titleStyled.black;
            default:
                return "";
        }
    };

    switch (props.type) {
        case "h2":
            return (
                <h2
                    title={props.title}
                    className={`${titleStyled.title} ${titleStyled.titleH2} ${getColorClassName(props.color)} ${
                        props.bold ? titleStyled.bold : ""
                    } ${className}`}
                >
                    {props.title} {props.isRequired && <RequiredSpan></RequiredSpan>}
                </h2>
            );
        case "h3":
            return (
                <h3
                    title={props.title}
                    className={`${titleStyled.title} ${titleStyled.titleH3} ${getColorClassName(props.color)} ${
                        props.bold ? titleStyled.bold : ""
                    } ${className}`}
                >
                    {props.title}
                    {props.isRequired && <RequiredSpan></RequiredSpan>}
                </h3>
            );
        case "h4":
            return (
                <h4
                    title={props.title}
                    className={`${titleStyled.title} ${titleStyled.titleH4} ${getColorClassName(props.color)} ${
                        props.bold ? titleStyled.bold : ""
                    } ${className}`}
                >
                    {props.title}
                    {props.isRequired && <RequiredSpan></RequiredSpan>}
                </h4>
            );
        case "h5":
            return (
                <h5
                    title={props.title}
                    className={`${titleStyled.title} ${titleStyled.titleH5} ${getColorClassName(props.color)} ${
                        props.bold ? titleStyled.bold : ""
                    } ${className}`}
                >
                    {props.title}
                    {props.isRequired && <RequiredSpan></RequiredSpan>}
                </h5>
            );
        case "h6":
            return (
                <h6
                    title={props.title}
                    className={`${titleStyled.title} ${titleStyled.titleH6} ${getColorClassName(props.color)} ${
                        props.bold ? titleStyled.bold : ""
                    } ${className}`}
                >
                    {props.title}
                    {props.isRequired && <RequiredSpan></RequiredSpan>}
                </h6>
            );
        case "h1":
        default:
            return (
                <h1
                    title={props.title}
                    className={`${titleStyled.title} ${titleStyled.titleH1} ${getColorClassName(props.color)} ${
                        props.bold ? titleStyled.bold : ""
                    } ${className}`}
                >
                    {props.title} {props.isRequired && <RequiredSpan></RequiredSpan>}
                </h1>
            );
    }
};

export const TitleH1 = (props: ITitleProps) => {
    return (
        <Title
            type="h1"
            {...props}
        ></Title>
    );
};

export const TitleH2 = (props: ITitleProps) => {
    return (
        <Title
            type="h2"
            {...props}
        ></Title>
    );
};

export const TitleH3 = (props: ITitleProps) => {
    return (
        <Title
            type="h3"
            {...props}
        ></Title>
    );
};

export const TitleH4 = (props: ITitleProps) => {
    return (
        <Title
            type="h4"
            {...props}
        ></Title>
    );
};

export const TitleH5 = (props: ITitleProps) => {
    return (
        <Title
            type="h5"
            {...props}
        ></Title>
    );
};

export const TitleH6 = (props: ITitleProps) => {
    return (
        <Title
            type="h6"
            {...props}
        ></Title>
    );
};
