import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../../redux/hooks";
import { RequestButtons } from "../buttons/requestButtons";
import { CertificatesData } from "./form/form";
import { IRequestStepProps } from "../../../../../models/requests/draft/IRequestStepProps";
import { CreateRequestContainerStyle } from "../containerStyle.jss";

export const CertificatesDataContainer: React.FC<IRequestStepProps> = (props) => {
    const theme = useTheme();
    const styles = CreateRequestContainerStyle({ theme });
    const { certificateStepIsValid } = useAppSelector((store) => store.createRequest.sectionValidation);

    return (
        <>
            <div className={styles.stepperContainer}>
                <CertificatesData {...props} />
            </div>
            <RequestButtons
                isValid={certificateStepIsValid}
                isLastStep={true}
                isFirstStep={false}
                schema={props.schema}
            />
        </>
    );
};
