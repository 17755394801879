import { createUseStyles } from "react-jss";

export const InspectionSectionStyle = createUseStyles((theme: any) => ({
    inspectionSectionContainer: {
        display: "flex",
        width: "100%",
    //    columnGap: 24,
        flexWrap: "wrap",
      //  rowGap: theme.gaps.sizes.xl,
    },
}));
