import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterTransportUnitList } from "./list/list";
import { setTransportUnitSizes, setTransportUnits } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterTransportUnitList } from "../../../../../redux/reducers/resources/master/transportUnit/list";

export class MasterTransportUnitContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterTransportUnitList());
        store.dispatch(setTransportUnits([]));
        store.dispatch(setTransportUnitSizes([]));
    }

    render(): React.ReactNode {
        return (
            <MasterTransportUnitList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
