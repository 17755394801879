import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { mapEditRequestCanAssignAppointment } from "../../components/request/form/edit/generalInformation/uti/customAppointment/utils";
import { IQueryZoneAvaiable } from "../../models/appointment/IDashboardZonesAvaiable";
import { fetchAppointmentsZonesAvaiablesAction, fetchGetAppointmentPermission } from "../../redux/actions/appointments/appointments";
import { fetchMerchandiseCategoriesRealtionShipTypes, fetchMerchandiseCategoriesTypes } from "../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../utils";
import { checkUndefinedOrNullValues } from "../../utils/objectUtils";
import { findMerchandiseCategory } from "../../utils/request/requestUtils";
import { useOrganizationPermission } from "../organization/useOrganizationPermission";

export const useAppointmentPermission = () => {
    const dispatch = useAppDispatch();
    const {
        editRequest: { headerRequest },
        editRequestAppointment: { form: appointmentForm },
        catalog: {
            merchandiseCategories: { data, loading },
            merchandiseCategoriesRelationShip: { data: categoriesRelationShip, loading: categoriesRelationShipLoading },
        },
    } = useAppSelector((state) => state);

    const options = mapIDescriptionToDropDownOptions(data);
    const { preappointment } = useOrganizationPermission();

    useEffect(() => {
        !data.length && !loading && dispatch(fetchMerchandiseCategoriesTypes());
        !categoriesRelationShip.length && !categoriesRelationShipLoading && dispatch(fetchMerchandiseCategoriesRealtionShipTypes());
    }, [data.length, loading, categoriesRelationShip.length, categoriesRelationShipLoading, dispatch]);

    const fetchAppointmentPermission = useCallback(async () => {
        if (!categoriesRelationShip || !options) return;

        const { id } = findMerchandiseCategory(
            headerRequest.merchandiseTypeId,
            headerRequest.humanConsumption,
            headerRequest.merchandiseOriginId,
            categoriesRelationShip,
            options,
        );

        const formCanAssignAppointment = mapEditRequestCanAssignAppointment(headerRequest);
        const response = await dispatch(fetchGetAppointmentPermission(formCanAssignAppointment));

        if (!response) return;

        const queryParams: IQueryZoneAvaiable = {
            date: dayjs(headerRequest.date?.toISOString()).format("YYYY-MM-DD"),
            fumigated: headerRequest.fumigated,
            halalCertified: headerRequest.halalCertified,
            humanConsumption: headerRequest.humanConsumption,
            merchandiseCategoryId: +id || headerRequest.merchandiseCategoryId,
            merchandiseTypeId: headerRequest.merchandiseTypeId,
            organicProduct: headerRequest.organicProduct,
            transportUnitId: headerRequest.transportUnitId,
            turnId: headerRequest.turnId,
            appointmentTypeId: appointmentForm.appointmentTypeId ?? headerRequest.appointmentTypeId,
            transportUnitSizeId: headerRequest.transportUnitSizeId ?? 0,
            requestId: headerRequest.id,
        };

        if (!checkUndefinedOrNullValues(queryParams)) {
            dispatch(fetchAppointmentsZonesAvaiablesAction(queryParams));
        }
    }, [
        headerRequest.date,
        headerRequest.fumigated,
        headerRequest.halalCertified,
        headerRequest.humanConsumption,
        headerRequest.merchandiseTypeId,
        headerRequest.organicProduct,
        headerRequest.transportUnitId,
        headerRequest.appointmentTypeId,
        headerRequest.turnId,
        appointmentForm.appointmentTypeId,
    ]);

    useEffect(() => {
        if (
            headerRequest.transportUnitId !== undefined &&
            headerRequest.transportUnitSizeId !== undefined &&
            headerRequest.merchandiseCategoryId !== undefined &&
            preappointment
        ) {
            fetchAppointmentPermission();
        }
    }, [
        fetchAppointmentPermission,
        headerRequest.transportUnitId,
        headerRequest.transportUnitSizeId,
        headerRequest.merchandiseCategoryId,
        headerRequest.date,
        preappointment
    ]);
};
