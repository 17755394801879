import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IGenericPayload, IPaymentData, IRequestedDocument } from "../../../../../../models";
import { IManagementUserForm, removeDocument, removeDocumentBlobs, setDocumentsUser } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../utils";
import { Checkboxs } from "../../../../../common/checkbox/checkbox";
import { LabelText } from "../../../../../common/labels/labelText/labelText";
import { TitleH6 } from "../../../../../common/titles/titles";
import { GridClass } from "../../../../../../common/enum/GridClass";

import { myProfileBanckPayResolver } from "../user/resolver";
import { UploadFileCustom } from "../../../../../common/uploadFile/uploadFile";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { FormField } from "../../../../../common/fields/fields";
import { ProfileDocumentTypes } from "../../../../../../common/enum/ProfileDocumentTypes";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { ContentTypeExtensions } from "../../../../../../common/enum/files/ContentType";
import { PaymentMethod } from "../../../../../../common/enum/PaymentMethod";
import { FileSize } from "../../../../../../common/enum/files/FileSize";

interface IManagementUserCustomerFormBankPayProps {
    generalUserData: IManagementUserForm;
    updateDataProperty: (value: IGenericPayload) => any;
    setIsValidTab: (value: boolean) => any;
}

export const ManagementUserCustomerFormBankPay: React.FC<IManagementUserCustomerFormBankPayProps> = ({
    generalUserData,
    updateDataProperty,
    setIsValidTab,
}) => {
    const { paymentData } = generalUserData;
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors: error, isValid },
    } = useForm<IPaymentData>({ mode: "onChange", resolver: myProfileBanckPayResolver });

    const onCheckboxChange = useCallback(
        (paymentMethod) => (event: any) => {
            const payload = getGenericPayloadFromEvent(event);
            if (paymentMethod === PaymentMethod.BANK_DRAFT) {
                payload.value = PaymentMethod.BANK_DRAFT;
            } else if (paymentMethod === PaymentMethod.CASH) {
                payload.value = PaymentMethod.CASH;
            }
            updateDataProperty(payload);
        },
        [dispatch],
    );

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            const payload: IGenericPayload = {
                name: name,
                value: value,
            };
            updateDataProperty(payload);
        },
        [dispatch],
    );

    const updateDocument = (file?: File, name?: string) => {
        if (file) {
            const item: IRequestedDocument = {
                documentId: Number(name),
                documentName: file?.name,
                file: file ? URL.createObjectURL(file) : "",
                index: Number(name),
                fileName: file?.name,
            };
            dispatch(setDocumentsUser(item));
        }
    };

    const banckCertificate = useMemo(() => {
        return generalUserData.documents.find((item) => item.documentId === Number(ProfileDocumentTypes.BANK_CERTIFICATE));
    }, [generalUserData.documents]);

    const banckCertificateBlob = useMemo(() => {
        if (!generalUserData.blobs) {
            return null;
        }
        return generalUserData.blobs.find((item) => item.type === ProfileDocumentTypes.BANK_CERTIFICATE);
    }, [generalUserData]);

    const banckAuthorization = useMemo(() => {
        return generalUserData.documents.find((item) => item.documentId === Number(ProfileDocumentTypes.BANK_AUTHORIZATION));
    }, [generalUserData.documents]);

    const banckAuthorizationBlob = useMemo(() => {
        if (!generalUserData.blobs) {
            return null;
        }
        return generalUserData.blobs.find((item) => item.type === ProfileDocumentTypes.BANK_AUTHORIZATION);
    }, [generalUserData]);

    useEffect(() => {
        setIsValidTab(isValid && (banckAuthorization || banckAuthorizationBlob) && (banckCertificate || banckCertificateBlob) ? true : false);
    }, [isValid, banckAuthorization, banckAuthorizationBlob, banckCertificate, banckCertificateBlob]);
    return (
        <div className={`  ${style.containerForm}`}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.SIMPLE_CELL}>
                        <TitleH6
                            title={t("PAYMENT_DATA")}
                            color={ColorType.primary}
                        />
                    </div>
                </div>

                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <Checkboxs
                            name={compileNameOfProperty<IPaymentData>("paymentMethod")}
                            label={t("BANK_DRAFT")}
                            isChecked={paymentData.paymentMethod === PaymentMethod.BANK_DRAFT}
                            handleChange={onCheckboxChange(PaymentMethod.BANK_DRAFT)}
                        />
                    </div>

                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <Checkboxs
                            name={compileNameOfProperty<IPaymentData>("paymentMethod")}
                            label={t("PAYMENT_CASH")}
                            isChecked={paymentData.paymentMethod === PaymentMethod.CASH}
                            handleChange={onCheckboxChange(PaymentMethod.CASH)}
                        />
                    </div>
                </div>

                <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("BANK_ACCOUNT")}
                            value={paymentData.accountNumber}
                            required={true}
                            error={error.accountNumber}
                            {...register(compileNameOfProperty<IPaymentData>("accountNumber"), {
                                onChange: onInputChange,
                                value: paymentData.accountNumber,
                            })}
                            isRequired={true}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <div className={GridClass.ROW_GRID}>
                            <div className={`${GridClass.THREE_CELL} ${style.documentRow}`}>
                                <LabelText
                                    name={t("BANK_CERTIFICATE")}
                                    isRequired
                                    hiddenValue={true}
                                />
                            </div>
                            <div className={GridClass.EIGTH_CELL}>
                                <UploadFileCustom
                                    removeDocument={() => {
                                        dispatch(removeDocument(Number(ProfileDocumentTypes.BANK_CERTIFICATE)));
                                        dispatch(removeDocumentBlobs(ProfileDocumentTypes.BANK_CERTIFICATE));
                                    }}
                                    name={ProfileDocumentTypes.BANK_CERTIFICATE}
                                    allowedExtensions={ContentTypeExtensions.USER_DOCUMENT}
                                    onChangeFile={updateDocument}
                                    title={t("ADD_DOCUMENTS")}
                                    buttonStyle={ColorType.primary}
                                    maxSize={FileSize._5MB}
                                    file={banckCertificate ?? banckCertificateBlob}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("BANK_ENTITY")}
                            value={paymentData.bankEntity}
                            error={error.bankEntity}
                            {...register(compileNameOfProperty<IPaymentData>("bankEntity"), {
                                onChange: onInputChange,
                                value: paymentData.bankEntity,
                            })}
                            isRequired={true}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <div className={`${GridClass.ROW_GRID}`}>
                            <div className={`${GridClass.THREE_CELL} `}>
                                <LabelText
                                    name={t("BANK_AUTHORIZATION")}
                                    isRequired
                                    hiddenValue={true}
                                />
                            </div>
                            <div className={GridClass.EIGTH_CELL}>
                                <UploadFileCustom
                                    removeDocument={() => {
                                        dispatch(removeDocument(Number(ProfileDocumentTypes.BANK_AUTHORIZATION)));
                                        dispatch(removeDocumentBlobs(ProfileDocumentTypes.BANK_AUTHORIZATION));
                                    }}
                                    name={ProfileDocumentTypes.BANK_AUTHORIZATION}
                                    allowedExtensions={ContentTypeExtensions.USER_DOCUMENT}
                                    onChangeFile={updateDocument}
                                    title={t("ADD_DOCUMENTS")}
                                    buttonStyle={ColorType.primary}
                                    maxSize={FileSize._5MB}
                                    file={banckAuthorization ?? banckAuthorizationBlob}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
