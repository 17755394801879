import { RequestApi } from "../../../../api";
import { AppointmentsAPI } from "../../../../api/appointments";
import { throwErrorToastrAsync } from "../../../../utils";
import { IAppointmentImportForm, setAppointmentImportFormLoading } from "../../../reducers/appointment/appoitmentImport/form";
import { setAppointmentImportListData } from "../../../reducers/appointment/appoitmentImport/list";

const requestApi = new RequestApi();

export const ImportAssignmentQueueAction = (form: IAppointmentImportForm, fileExtension?: any): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setAppointmentImportFormLoading(true));
            let response: any = null;

            if (fileExtension === "txt") {
                response = await requestApi.importRequestAssignmentQueueText(form);
            }

            if (fileExtension === "json") {
                response = await requestApi.importRequestAssignmentQueue(form);
            }

            if (fileExtension === "document") {
                response = await requestApi.importRequestAssignmentQueueDocument(form);
            }

            dispatch(setAppointmentImportListData(response));

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setAppointmentImportFormLoading(false));
        }
    };
};
