import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../../redux/hooks";
import { RequestButtons } from "../buttons/requestButtons";
import { RequestUtiDataForm } from "./form/form";
import { IRequestStepProps } from "../../../../../models/requests/draft/IRequestStepProps";
import { FormDraftContainerStyle } from "../containerStyle.jss";

export const RequestUtiFormContainer: React.FC<IRequestStepProps> = (props) => {
    const theme = useTheme();
    const styles = FormDraftContainerStyle({ theme });
    const { utiStepIsValid } = useAppSelector((store) => store.draftRequest.sectionValidation);
    return (
        <>
            <div className={styles.stepperContainer}>
                <RequestUtiDataForm {...props} />
            </div>
            <RequestButtons
                isValid={utiStepIsValid}
                isLastStep={false}
                isFirstStep={true}
                schema={props.schema}
                onCloseModal={props.editMode ? props.onResumeAccept : undefined}
                editDraft={props.editMode ?? false}
            />
        </>
    );
};
