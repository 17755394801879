import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routeUrls } from "../../../common/constants";
import { resetErrorHandlerAction, setErrorHandlerAction } from "../../../redux/actions/common/errorHandler";
import { useAppDispatch } from "../../../redux/hooks";
import { getErrorHandler } from "../../../redux/selectors/common/errorHandler";
import { AppThunk } from "../../../redux/store";
import { throwErrorToastrAsync } from "../../../utils";
import { ErrorType } from "../../../common/enum/ErrorType";

export const ErrorHandler = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const errorHandler = useSelector(getErrorHandler);

    useEffect(() => {
        if (errorHandler.error) {
            let url = "";

            dispatch(resetErrorHandlerAction());

            if (errorHandler.type === ErrorType.FORBIDDEN) {
                url = routeUrls.FORBIDDEN;
            }

            navigate(url, { replace: true });
        }
    }, [dispatch, errorHandler, navigate]);

    return <></>;
};

export const HandleAxiosError =
    (error: any): AppThunk =>
    (dispatch) => {
        if (axios.isAxiosError(error) && error.response?.status === 403) {
            dispatch(setErrorHandlerAction(true, ErrorType.FORBIDDEN));
        } else {
            throwErrorToastrAsync(error);
        }
    };
