import { createUseStyles } from "react-jss";

export const VlcportImportRequestContainerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection:"column",
        width: "100%",
        gap: theme.gaps.sizes.base,
    },
   
}));
