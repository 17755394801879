import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../../../assets/icons";
import {
    IBoardHistoryAppointmentDock,
    IBoardHistoryAppointmentDockTable,
} from "../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch } from "../../../../../../../../../../redux/hooks";
import {
    setMasterAppointmentDockForm,
    setShowPopUpAppointmentDock,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDock/form";
import { CustomIcons } from "../../../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../../../common/tableCustom/tableCustom";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../../../../../../common/enum/DefaultValues";

interface IMasterAppointmentDockControllerProps {
    list: IBoardHistoryAppointmentDock[];
}

export const MasterBoardHistoryAppointmentDockList: React.FC<IMasterAppointmentDockControllerProps> = ({ list }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_DOCK" });
    const dispatch = useAppDispatch();

    let loading = false;

    const columns: ColumnType<IBoardHistoryAppointmentDockTable, keyof IBoardHistoryAppointmentDockTable>[] = [
        { key: "code", title: t("CODE") },
        { key: "description", title: t("DESCRIPTION") },
        { key: "actions", title: "" },
    ];

    const rows = list.map((item) => {
        return {
            ...item,
            code: item.code ?? DefaultValues.EMPTY_LIST,
            description: item.description ?? DefaultValues.EMPTY_LIST,

            actions: (
                <CustomTableActions
                    children={
                        <div>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.EDIT}
                                onClick={() => {
                                    dispatch(setMasterAppointmentDockForm(item));
                                    dispatch(setShowPopUpAppointmentDock(PopUpCodes.UPDATE));
                                }}
                            />

                            <Separator size="auto"></Separator>

                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.REMOVE}
                                onClick={() => {
                                    dispatch(setMasterAppointmentDockForm(item));
                                    dispatch(setShowPopUpAppointmentDock(PopUpCodes.DELETE));
                                }}
                            />
                        </div>
                    }
                />
            ),
        };
    });

    return (
        <CustomTableList
            data={rows}
            columns={columns}
            loading={loading}
        />
    );
};
