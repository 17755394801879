import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterOrganismItem } from "../../../../../models/resources/master/IOrganism";

export interface IMasterOrganismFormState {
    form: IMasterOrganismItem;
    loading: boolean;
}

const initialState: IMasterOrganismFormState = {
    form: {
        active: false,
        code: "",
        description: "",
        viewAll: false,
        internal: false,
        manageInspections: false,
    },
    loading: false,
};

export const masterOrganismForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterOrganismForm: () => initialState,
        setLoadingMasterOrganismForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterOrganismForm: (state, action: PayloadAction<IMasterOrganismItem>) => {
            state.form = action.payload;
        },
        updateMasterOrganismProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setResetmasterOrganismForm: (state) => {
            state.form = {
                active: false,
                code: "",
                description: "",
                viewAll: false,
                internal: false,
                manageInspections: false,
            };
        },
    },
});

export const {
    resetMasterOrganismForm,
    setLoadingMasterOrganismForm,
    setMasterOrganismForm,
    updateMasterOrganismProperty,
    setResetmasterOrganismForm,
} = masterOrganismForm.actions;

export default masterOrganismForm.reducer;
