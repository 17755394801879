import { IConfigCreateRequest } from "../../../../../models/requests/IConfigCreateRequest";
import { IField } from "../../../../../models/requests/IUserRoleView";
import { compileNameOfProperty, isFieldReadOnly, isFieldRequired, isFieldVisible } from "../../../../../utils";

export const generateRequestPropertySchema = (fields: IField[]): IConfigCreateRequest => {
    return {
        transportUnitId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitId")),
        },
        date: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("date")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("date")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("date")),
        },
        turnId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("turnId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("turnId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("turnId")),
        },
        customerId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("customerId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("customerId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("customerId")),
        },
        invoiceCustomerId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("invoiceCustomerId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("invoiceCustomerId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("invoiceCustomerId")),
        },
        customerReference: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("customerReference")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("customerReference")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("customerReference")),
        },
        booking: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("booking")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("booking")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("booking")),
        },
        customsStateId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("customsStateId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("customsStateId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("customsStateId")),
        },
        uti_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("uti_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("uti_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("uti_create")),
        },
        temporaryRegime: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("temporaryRegime")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("temporaryRegime")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("temporaryRegime")),
        },
        //UTI
        transportUnitSizeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitSizeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitSizeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitSizeId")),
        },
        transportUnitNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("transportUnitNumber")),
        },
        packages: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("packages")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("packages")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("packages")),
        },
        grossWeight: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("grossWeight")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("grossWeight")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("grossWeight")),
        },
        palletized: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("palletized")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("palletized")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("palletized")),
        },
        originSeal: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("originSeal")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("originSeal")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("originSeal")),
        },

        merchandiseOriginId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseOriginId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseOriginId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseOriginId")),
        },
        humanConsumption: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("humanConsumption")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("humanConsumption")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("humanConsumption")),
        },
        merchandiseTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseTypeId")),
        },
        merchandiseCategoryId : {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseCategoryId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseCategoryId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseCategoryId")),

        },
        description: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("description")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("description")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("description")),
        },
        tariffHeading: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("tariffHeading")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("tariffHeading")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("tariffHeading")),
        },
        merchandiseArrivalShip: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseArrivalShip")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseArrivalShip")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("merchandiseArrivalShip")),
        },
        summaryNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("summaryNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("summaryNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("summaryNumber")),
        },
        terminalId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("terminalId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("terminalId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("terminalId")),
        },
        batches: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("batches")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("batches")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("batches")),
        },
        parkingAuthorization: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("parkingAuthorization")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("parkingAuthorization")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("parkingAuthorization")),
        },
        fumigated: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("fumigated")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("fumigated")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("fumigated")),
        },
        dryCharge: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("dryCharge")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("dryCharge")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("dryCharge")),
        },
        organicProduct: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("organicProduct")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("organicProduct")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("organicProduct")),
        },
        halalCertified: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("halalCertified")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("halalCertified")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("halalCertified")),
        },
        exporter: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("exporter")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("exporter")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("exporter")),
        },
        importer: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("importer")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("importer")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("importer")),
        },
        carrierId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("carrierId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("carrierId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("carrierId")),
        },
        remarks: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("remarks")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("remarks")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("remarks")),
        },
        inspectionPresence: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspectionPresence")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspectionPresence")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspectionPresence")),
        },
        representativePresenceId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("representativePresenceId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("representativePresenceId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("representativePresenceId")),
        },
        finalDestinationTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("finalDestinationTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("finalDestinationTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("finalDestinationTypeId")),
        },
        inspection_create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_create")),
        },
        // INSPECTION
        inspection_organismId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_organismId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_organismId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_organismId")),
        },
        inspection_certificateTypeId: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_certificateTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_certificateTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_certificateTypeId")),
        },
        inspection_certificateNumber: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_certificateNumber")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_certificateNumber")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_certificateNumber")),
        },
        inspection_exporter: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_exporter")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_exporter")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_exporter")),
        },
        inspection_importer: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_importer")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_importer")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_importer")),
        },
        inspection_year: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_year")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_year")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigCreateRequest>("inspection_year")),
        }
    };
};
