import { createUseStyles } from "react-jss";

export const LabelTimeStyle = createUseStyles((theme: any) => ({
    timeLabelContainer: {
        display: "flex",
        flexFlow: "column",
        rowGap: 2,
    },
    label: {
        color: theme.colors.titles.secondary,
    },
    data: {
        color: theme.colors.titles.secondary,
        fontWeight: 600,
        width:"100%", 
        cursor:"pointer"
    },
}));
