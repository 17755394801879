import { createUseStyles } from "react-jss";

export const AlertStyle = createUseStyles((theme: any) => ({
    header: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.sizes.sm,
        textTransform: "uppercase",
    },
    data: {
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.xs,
        paddingRight: theme.paddings.sizes.sm,
    },
    title: {
        color: "#76471B",
    },
    description: {
        color: "#C96200",
    },
    default: {
        background: "#FFEBD8",
        stroke: "#C96200 !important",
        fill: "#C96200 !important",
        color: "#76471B",
    },

    primary: {
        background: theme.colors.buttons.primary.background,
        color: theme.colors.buttons.primary.color,
        stroke: theme.colors.buttons.primary.color,
    },
    primaryInverse: {
        background: theme.colors.buttons.primary.color,
        color: theme.colors.buttons.primary.background,
        stroke: theme.colors.buttons.primary.background,
    },
    primaryInverseWithBorder: {
        background: theme.colors.buttons.primary.color,
        color: theme.colors.buttons.primary.background,
        border: `1px solid ${theme.colors.buttons.primary.background}`,
        stroke: theme.colors.buttons.primary.background,
    },
    secondary: {
        background: theme.colors.buttons.secondary.background,
        color: theme.colors.buttons.secondary.color,
        stroke: theme.colors.buttons.secondary.color,
    },
    success: {
        background: theme.colors.buttons.success.background,
        color: theme.colors.buttons.success.color,
        stroke: theme.colors.buttons.success.color,
    },
    danger: {
        background: theme.colors.buttons.danger.background,
        color: theme.colors.buttons.danger.color,
        stroke: theme.colors.buttons.danger.color,
    },
    warning: {
        background: theme.colors.buttons.warning.background,
        color: theme.colors.buttons.warning.color,
        stroke: theme.colors.buttons.warning.color,
    },
    info: {
        background: theme.colors.buttons.info.background,
        color: theme.colors.buttons.info.color,
        stroke: theme.colors.buttons.info.color,
    },
    dangerInverseWithBorder: {
        color: theme.colors.buttons.danger.background,
        stroke: theme.colors.buttons.danger.background,
        background: theme.colors.buttons.danger.color,
    },
    container: (props: any) => ({
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.borders.default.radius,
        padding: theme.paddings.sizes.sm,
        gap: theme.gaps.sizes.sm,
        height: props.height ? props.height : "auto",
        marginBottom: theme.margins.sizes.sm,
        fontSize: props.fontSize ? props.fontSize : "",
        margin: props.margin ? props.margin : 0,
        width: props.width ? props.width : "auto",
    }),
}));
