import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../models";
import { IStatisticsFilter } from "../../../models/statistics/IStatisticsFilters";
import { StatisticsDateRangeController } from "./calendar/calendar";
import { StatisticsFiltersContainerStyle } from "./containerStyle.jss";
import { StatisticsFiltersPivot } from "./pivot/pivot";
interface IStatisticsFilterContainerProps {
    updateFilterProperty: (value: IGenericPayload) => any;
    filterData: IStatisticsFilter;
    onApplyFilters: () => any;
    onResetFilters: () => any;
}

export const StatisticsFilterContainer: React.FC<IStatisticsFilterContainerProps> = ({
    filterData: filter,
    updateFilterProperty,
    onApplyFilters,
    onResetFilters,
}) => {
    const theme = useTheme();
    const style = StatisticsFiltersContainerStyle({ theme });

    return (
        <div className={style.container}>
            <div className={style.dateContainer}>
                <StatisticsDateRangeController
                    filter={filter}
                    updateFilterProperty={updateFilterProperty}
                ></StatisticsDateRangeController>
            </div>
            <div className={style.verticalWhiteSeparator}></div>
            <div className={style.pivotContainer}>
                <StatisticsFiltersPivot
                    updateRequestFilterProperty={updateFilterProperty}
                    filter={filter}
                    onApplyFilters={onApplyFilters}
                    onResetFilters={onResetFilters}
                />
            </div>
        </div>
    );
};
