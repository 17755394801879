import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IInvoicingNonSynchronized } from "../../../../models";

interface IInvoicingNonSynchronizedListState {
    list?: IInvoicingNonSynchronized[];
    loading: boolean;
    sort: ISortState;
}

const initialState: IInvoicingNonSynchronizedListState = {
    list: undefined,
    loading: false,
    sort: emptySortState(),
};

export const invoicingNonSynchronizedListSlice = createSlice({
    name: "invoicingNonSynchronizedList",
    initialState,
    reducers: {
        resetInvoiceNonSynchronized: () => initialState,
        setList: (state, action: PayloadAction<IInvoicingNonSynchronized[]>) => {
            state.list = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetInvoiceNonSynchronized, setList, updateSortColumn, setLoading } = invoicingNonSynchronizedListSlice.actions;

export default invoicingNonSynchronizedListSlice.reducer;
