import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { ManagementAppointmentPermisionForm } from "./form/form";
import { IGenericPayload } from "../../../../../../models";
import { IAppointmentPermission } from "../../../../../../models/masterAppointment/IAppointmentPermission";
import {
    deleteMasterAppointmentPermission,
    postAndPutMasterAppointmentPermission,
} from "../../../../../../redux/actions/masterAppointment/appointmentPermission/appointmentPermission";
import {
    resetMasterAppointmentPermissionForm,
    updateMasterAppointmentPermissionProperty,
} from "../../../../../../redux/reducers/masterAppointment/appointmentPermission/form";
import { clearMasterAppointmentPopUp } from "../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { compileNameOfProperty } from "../../../../../../utils";
import { PopUpConfirmation } from "../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { useContainer } from "../../../../../../hooks/request/useContainer";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../common/enum/PopUpConfirmationType";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

export const MasterAppointmenRestrictionFormController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterAppointment);
    const { loading, form } = useAppSelector((store) => store.masterAppointmentPermissionForm);
    const isContainer = useContainer(form.transportUnitId);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return `${t("CREATE")} ${t("BUTTON.APPOINTMENT_PERMISSION")}`;
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return `${t("UPDATE")} ${t("BUTTON.APPOINTMENT_PERMISSION")}`;
        }
        return "";
    }, [showPopUp]);

    const onDimissed = () => {
        dispatch(resetMasterAppointmentPermissionForm());
    };
    const onCancel = () => {
        dispatch(clearMasterAppointmentPopUp());
    };

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postAndPutMasterAppointmentPermission()).then((response: any) => {
                response && onCancel();
            });
        } else if (showPopUp === PopUpCodes.DELETE && form.id) {
            dispatch(deleteMasterAppointmentPermission());
            onCancel();
        }
    };

    const isValidForm = useMemo(() => {
        let keys = ["merchandiseCategoryGroupId", "transportUnitId"];
        isContainer && keys.push("transportUnitSizeId");
        let item: any = form;
        let valid = false;
        for (let key of keys) {
            if (!item[key]) {
                valid = true;
                break;
            }
        }
        return valid;
    }, [form, isContainer]);

    useEffect(() => {
        if (!isContainer && !loading) {
            let payload: IGenericPayload = {
                name: compileNameOfProperty<IAppointmentPermission>("transportUnitSizeId"),
                value: null,
            };
            dispatch(updateMasterAppointmentPermissionProperty(payload));
        }
    }, [form.transportUnitId]);

    return (
        <>
            <PopUpForm
                content={<ManagementAppointmentPermisionForm isContainer={isContainer} />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"50vh"}
                width={"50vw"}
                isLoading={loading}
                isBlocking={false}
                isDisabledButton={isValidForm}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancel}
                onAccept={onSave}
                loading={loading}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
            />
        </>
    );
};
