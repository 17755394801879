import { useEffect } from "react";
import { fetchAllInspectorOptions } from "../../../../../redux/actions/user/users/users";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { CustomSelector } from "../../../../common/selectors/customSelector/customSelector";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

interface ICustomInspectorRoleSelectorProps {
    title: string;
    onChange: (name: string, keysSelected: any) => void;
    selectedKey: any;
    disabled?: boolean;
    organismId: any;
    name: string;
}

export const CustomInspectorRoleSelector: React.FC<ICustomInspectorRoleSelectorProps> = ({
    organismId,
    onChange,
    name,
    title,
    selectedKey,
    disabled,
}) => {
    const { options, loading } = useAppSelector((store) => store.usersByRole.inspector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        options.length === 0 && dispatch(fetchAllInspectorOptions(organismId));
    }, [dispatch, options]);

    useEffect(() => {
        String(organismId) !== DefaultValues.DEFAULT_ALL_SELECTOR && dispatch(fetchAllInspectorOptions(organismId));
    }, [organismId]);

    return (
        <CustomSelector
            title={title}
            options={options}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabled !== undefined ? disabled : loading}
        />
    );
};
