import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../../../assets/icons";
import { FormatDate } from "../../../../../../../../../../common/enum/dateTime/FormatDate";

import {
    IBoardHistoryAppointmentTimeZone,
    IBoardHistoryAppointmentTimeZoneTable,
} from "../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch } from "../../../../../../../../../../redux/hooks";
import {
    setMasterAppointmentTimeZoneForm,
    setShowPopUpMasterAppointmentTimeZone,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDockTimeZone/form";
import { convertToLocal } from "../../../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../../../common/tableCustom/tableCustom";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../../../../../../common/enum/DefaultValues";

interface IMasterBoardHistoryAppointmentTimeZoneListProps {
    list: IBoardHistoryAppointmentTimeZone[];
}

export const MasterBoardHistoryAppointmentTimeZoneList: React.FC<IMasterBoardHistoryAppointmentTimeZoneListProps> = ({ list }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_TIME_ZONE" });
    const dispatch = useAppDispatch();

    let loading = false;

    const columns: ColumnType<IBoardHistoryAppointmentTimeZoneTable, keyof IBoardHistoryAppointmentTimeZoneTable>[] = [
        { key: "code", title: t("CODE") },
        { key: "description", title: t("DESCRIPTION") },
        { key: "turnDescription", title: t("TURN") },
        { key: "startTime", title: t("START_TIME") },
        { key: "endTime", title: t("END_TIME") },
        { key: "externalCode", title: t("EXTERNAL_CODE") },
        { key: "actions", title: "" },
    ];

    const rows = list.map((item) => {
        return {
            ...item,
            code:item.code,
            description:item.description,
            turnDescription:item.turnDescription,
            externalCode:item.externalCode ?? DefaultValues.EMPTY_LIST,
            startTime: convertToLocal(item.startTime, FormatDate.HOUR_MINUTE),
            endTime: convertToLocal(item.endTime, FormatDate.HOUR_MINUTE),
            actions: (
                <CustomTableActions
                    children={
                        <div>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.EDIT}
                                onClick={() => {
                                    dispatch(setMasterAppointmentTimeZoneForm(item));
                                    dispatch(setShowPopUpMasterAppointmentTimeZone(PopUpCodes.UPDATE));
                                }}
                            />

                            <Separator size="auto"></Separator>

                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.REMOVE}
                                onClick={() => {
                                    dispatch(setMasterAppointmentTimeZoneForm(item));
                                    dispatch(setShowPopUpMasterAppointmentTimeZone(PopUpCodes.DELETE));
                                }}
                            />
                        </div>
                    }
                />
            ),
        };
    });

    return (
        <CustomTableList
            data={rows}
            columns={columns}
            loading={loading}
        />
    );
};
