import { IPivotStyles, IStyleSet, Pivot, PivotItem } from "@fluentui/react";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { mediaQueries, pageSizes } from "../../../common/constants";
import { isValidEmail } from "../../../common/validations/validationsForm";
import { useNavigationWithBlocker } from "../../../hooks/useBlockerHook";
import { IUserProfile } from "../../../models";
import {
    fetchProfileUserNotifications,
    fetchUserProfileAction,
    fetchUserProfileUsersList,
    onSaveDataProfileUserNotification,
    updateUserProfileAction,
} from "../../../redux/actions/user/profile/userProfile";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateProfileCopy } from "../../../redux/reducers/user/profile/userProfile";
import { loadingUserProfileSelector, userProfileSelector } from "../../../redux/selectors/user/profile/userProfile";
import { getUserRole } from "../../../redux/selectors/user/role/role";
import { colors } from "../../../styles/romeu/colors";
import { fonts } from "../../../styles/romeu/fonts";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { PopUpBlocker } from "../../common/popup/popUpBlocker/popUpBlocker";
import { Skeleton } from "../../common/skeleton/skeleton";
import { adminResolver, entityResolver, paymentResolver } from "../signup/entity/entityResolvers";
import { UserProfileContainerStyle } from "./containerStyle.jss";
import { UserProfileEntityContainer } from "./entity/container";
import { ProfileUserNotificationsContainer } from "./notifications/container";
import { UserProfileRepresentativeContainer } from "./representatives/container";
import { myProfileResolver } from "./user/form/resolver";
import { UserProfileDataContainer } from "./user/profile";

export const UserProfileContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE" });
    const theme = useTheme();
    const style = UserProfileContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const userProfile = useSelector(userProfileSelector);
    const loading = useSelector(loadingUserProfileSelector);
    const roleData = useSelector(getUserRole);
    const { isProfileLoaded } = useAppSelector((state) => state.userData);
    const isUserProfileLoading = useAppSelector((state) => state.userProfile.loadingUser);

    const [isDirtyForm, setIsDirtyForm] = useState(false);
    const { showWarningBack, cancelNavigation } = useNavigationWithBlocker(isDirtyForm);

    const { list } = useAppSelector((store) => store.userProfile.profile.userNotifications);
    const { profile, profileCopy } = useAppSelector((store) => store.userProfile);

    useEffect(() => {
        if (!isEqual(profile, profileCopy)) {
            setIsDirtyForm(true);
        } else {
            setIsDirtyForm(false);
        }
    }, [profile, profileCopy]);

    const onExitToBlockedPopUp = () => {
        setIsDirtyForm(false);
    };

    const canHasNotification = useMemo(() => {
        return userProfile.hasNotificationData;
    }, [userProfile]);

    const canEditAdminData = useMemo(() => {
        return roleData?.hasEntityData;
    }, [roleData]);

    const canEditRepresentativeData = useMemo(() => {
        return roleData?.hasRepresentativeData && userProfile.hasRepresentativeData;
    }, [roleData, userProfile]);

    const onSave = useCallback(() => {
        dispatch(updateUserProfileAction());
        canHasNotification && dispatch(onSaveDataProfileUserNotification());
        dispatch(updateProfileCopy());
    }, [dispatch, canHasNotification]);

    useEffect(() => {
        isProfileLoaded && dispatch(fetchUserProfileAction());
        isProfileLoaded && dispatch(fetchUserProfileUsersList());
        isProfileLoaded && dispatch(fetchProfileUserNotifications());
    }, [isProfileLoaded, dispatch]);

    const [selectedKey, setSelectedKey] = React.useState("0");

    const pivotStyles: Partial<IStyleSet<IPivotStyles>> = useMemo(() => {
        return {
            linkIsSelected: {
                color: colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: colors.siteNav.section.color,
                fontFamily: fonts.families.site,
                minWidth: 200,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, []);

    const {
        register: userRegister,
        formState: { errors: userErrors, isValid: isUserValid },
    } = useForm<IUserProfile>({ mode: "onChange", resolver: myProfileResolver });

    const {
        register: entityRegister,
        formState: { errors: entityErrors, isValid: isEntityValid },
    } = useForm<any>({ mode: "onChange", resolver: entityResolver });

    const {
        register: adminRegister,
        formState: { errors: adminErrors, isValid: isAdminValid },
    } = useForm<any>({ mode: "onChange", resolver: adminResolver });

    const {
        register: operationRegister,
        formState: { errors: operationErrors, isValid: isOperationValid },
    } = useForm<any>({ mode: "onChange", resolver: adminResolver });

    const {
        register: paymentRegister,
        formState: { errors: paymentErrors, isValid: isPaymentValid },
    } = useForm<any>({ mode: "onChange", resolver: paymentResolver });

    const areInvalidEmails = useMemo(() => {
        return userProfile.operatorData.emails.some((email) => !email.isValid) || userProfile.adminData.emails.some((email) => !email.isValid);
    }, [userProfile.operatorData.emails, userProfile.adminData.emails]);

    const isTruckDataValid = useMemo(() => {
        return !areInvalidEmails && isAdminValid && isOperationValid && isPaymentValid;
    }, [areInvalidEmails, isAdminValid, isOperationValid, isOperationValid, isPaymentValid]);

    const disableNextButton = useMemo(() => {
        return !isEntityValid || !isTruckDataValid || !isUserValid;
    }, [isEntityValid, isTruckDataValid, isUserValid]);

    const invalidNotifications: boolean = useMemo(() => {
        if (!list || list.length === 0) {
            return false;
        }
        return list.some((notification) => notification.emailList.some((email) => email !== "" && !isValidEmail(email)));
    }, [list]);

    return (
        <>
            <NavHeaderComponent title={t("TITLE")} />

            <div className={style.profileContent}>
                <Pivot
                    styles={pivotStyles}
                    selectedKey={String(selectedKey)}
                    onLinkClick={(event) => {
                        setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "2");
                    }}
                >
                    <PivotItem
                        headerText={t("TABS.USER")}
                        itemKey="0"
                    >
                        {loading || !isProfileLoaded || isUserProfileLoading || !userProfile.email ? (
                            <Skeleton rows={pageSizes.PROFILE} />
                        ) : (
                            <UserProfileDataContainer
                                loading={loading ?? false}
                                userErrors={userErrors}
                                userRegister={userRegister}
                                userData={userProfile}
                            />
                        )}
                    </PivotItem>
                    {canEditAdminData && (
                        <PivotItem
                            headerText={t("TABS.ENTITY")}
                            itemKey="1"
                        >
                            {!isProfileLoaded ? (
                                <Skeleton rows={pageSizes.PROFILE} />
                            ) : (
                                <UserProfileEntityContainer
                                    disabled={false}
                                    adminErrors={adminErrors}
                                    adminRegister={adminRegister}
                                    entityErrors={entityErrors}
                                    entityRegister={entityRegister}
                                    operationErrors={operationErrors}
                                    operationRegister={operationRegister}
                                    paymentErrors={paymentErrors}
                                    paymentRegister={paymentRegister}
                                />
                            )}
                        </PivotItem>
                    )}
                    {canEditRepresentativeData && (
                        <PivotItem
                            headerText={t("TABS.REPRESENTATION")}
                            itemKey="2"
                        >
                            {loading || !isProfileLoaded ? <Skeleton rows={pageSizes.PROFILE} /> : <UserProfileRepresentativeContainer />}
                        </PivotItem>
                    )}
                    {canHasNotification && (
                        <PivotItem
                            headerText={t("TABS.NOTIFICATION")}
                            itemKey="3"
                        >
                            {loading || !isProfileLoaded ? (
                                <Skeleton rows={pageSizes.PROFILE} />
                            ) : (
                                <>
                                    <ProfileUserNotificationsContainer />
                                </>
                            )}
                        </PivotItem>
                    )}
                </Pivot>
            </div>

            {!loading && isProfileLoaded && !isUserProfileLoading && (
                <div className={style.actionButtons}>
                    <ButtonPrimary
                        disabled={disableNextButton || invalidNotifications}
                        title={t("SAVE")}
                        handleClick={onSave}
                    />
                </div>
            )}
            <PopUpBlocker
                stayOn={cancelNavigation}
                exitTo={onExitToBlockedPopUp}
                showPopUp={showWarningBack}
            />
        </>
    );
};
