import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { ContentType } from "../../../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../../../common/enum/files/FileSize";
import { IRepresentative } from "../../../../../../models/user/representatives/IRepresentative";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
    setDocumentRepresentativeFile,
    updateRepresentativeProperty,
} from "../../../../../../redux/reducers/user/representatives/userRepresentatives";
import { compileNameOfProperty, getGenericPayloadFromEvent, requiredMessage } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { UploadFileCustom } from "../../../../../common/uploadFile/uploadFile";
import { formResolver } from "./formResolver";
import { UserProfileUserFormStyle } from "./formStyle.jss";

export const UserRepresentativeDataForm = () => {
    const styles = UserProfileUserFormStyle();
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE.REPRESENTATIVE" });
    const dispatch = useAppDispatch();
    const { form } = useAppSelector((store) => store.userRepresentative);

    const {
        register,
        formState: { errors },
    } = useForm<IRepresentative>({ mode: "onChange", resolver: formResolver });

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateRepresentativeProperty(payload));
        },
        [dispatch],
    );

    const onChangeFile = (file: File | undefined) => {
        dispatch(setDocumentRepresentativeFile(file || undefined));
    };

    return (
        <>
            <div className={styles.content}>
                <div className={styles.row}>
                    <FormField
                        type="text"
                        value={form.representativeDocumentNumber ? form.representativeDocumentNumber.toUpperCase() : ""}
                        label={t("TITLES.NIF")}
                        error={errors.representativeDocumentNumber}
                        isRequired={true}
                        {...register(compileNameOfProperty<IRepresentative>("representativeDocumentNumber"), {
                            value: form.representativeDocumentNumber,
                            onChange: onInputChange,
                            required: requiredMessage,
                        })}
                    />
                </div>
                <div className={styles.upload}>
                    <UploadFileCustom
                        allowedExtensions={[ContentType.JPG, ContentType.JPEG, ContentType.PNG, ContentType.PDF]}
                        maxSize={FileSize._5MB}
                        title={t("BUTTONS.ATTACH_REPRESENTATIVE_AUTH")}
                        name={"Documento"}
                        buttonStyle={ColorType.primary}
                        onChangeFile={onChangeFile}
                        file={form.document}
                    />
                </div>
            </div>
        </>
    );
};
