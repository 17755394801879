import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../../../common/constants";

export const EditRequestTabsInvoiceConceptRemarksFormStyle = createUseStyles((theme: any) => ({
    generalContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
    },
    formRow: {
        width: "100%",
        display: "flex",
        gap: 15,
        flexFlow: "wrap",
    },
    remarksFields: {
        width: "50%",
        [mediaQueries.MOBILE]: {
            width: theme.widths.sectionContainer.complete,
        },
    },
}));
