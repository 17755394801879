import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IStatisticsFilter } from "../../../../../models/statistics/IStatisticsFilters";
import { compileNameOfProperty } from "../../../../../utils";
import { BooleaMultiSelector } from "../../../../common/selectors";
import { MultipleMerchandiseTypeSelector } from "../../../../request/common/selectors";
import { MultipleCustomStateSelector } from "../../../../request/common/selectors/customStates/multipleCustomState";
import { MultipleMerchandiseOriginSelector } from "../../../../request/common/selectors/merchandiseOrigin/multipleMerchandiseOrigin";
import { StatisticsMerchandisePivotFilterStyle } from "./merchandiseStyle.jss";

import { useAppSelector } from "../../../../../redux/hooks";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    filter: IStatisticsFilter;
}
export const RequestMerchandisePivotFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, onNullableBooleanChange, filter }) => {
    const theme = useTheme();
    const styles = StatisticsMerchandisePivotFilterStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.FILTER" });
    const { organizations } = useAppSelector((state) => state.userData.profile);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);
    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    return (
        <div className={styles.requestMerchandiseFilterContainer}>
            <div className={styles.filterItem}>
                <MultipleMerchandiseTypeSelector
                    selectedKeys={filter.merchandiseTypeIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("merchandiseTypeIds")}
                    title={t("MERCHANDISE_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>
            <div className={styles.filterItem}>
                <MultipleMerchandiseOriginSelector
                    selectedKeys={filter.merchandiseOriginIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("merchandiseOriginIds")}
                    title={t("MERCHANDISE_ORIGIN")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>
            <div className={styles.filterItem2}>
                <MultipleCustomStateSelector
                    selectedKeys={filter.customsStateIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("customsStateIds")}
                    title={t("CUSTOMS_STATE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>
            {organization && organization.halalCertified && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.halalCertified}
                        propertyName={compileNameOfProperty<IStatisticsFilter>("halalCertified")}
                        title={t("HALAL")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}

            {organization && organization.dryCharge && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.dryCharge}
                        propertyName={compileNameOfProperty<IStatisticsFilter>("dryCharge")}
                        title={t("DRY_CARGO")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {organization && organization.organicProduct && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.organicProduct}
                        propertyName={compileNameOfProperty<IStatisticsFilter>("organicProduct")}
                        title={t("ORGANIC_PRODUCT")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {organization && organization.fumigated && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.fumigated}
                        propertyName={compileNameOfProperty<IStatisticsFilter>("fumigated")}
                        title={t("FUMIGATED_CONTAINER")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
        </div>
    );
};
