import { IComboBoxOption, IDropdown, IDropdownOption } from "@fluentui/react";
import React, { useEffect, useCallback } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IUserSignup } from "../../../../../models";
import { IUserPositionType } from "../../../../../models/catalog";
import { fetchUserPositionTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateUserSignupProperty } from "../../../../../redux/reducers/user/signup/signup";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { FormField } from "../../../../common/fields/fields";
import { Selector } from "../../../../common/selectors/selector";
import { UserType } from "../../../../../common/enum/UserType";

interface IProfileFormProps {
    data: IUserSignup;
    register: UseFormRegister<IUserSignup>;
    errors: FieldErrors<IUserSignup>;
}

export const ProfileForm = (props: IProfileFormProps) => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const userPositionTypes: IUserPositionType[] = useAppSelector((store) => store.catalog.userPositionTypes.data);

    const userPositionTypesOptions: IComboBoxOption[] = userPositionTypes.map((item: IUserPositionType) => {
        return {
            key: item.code,
            text: item.description,
        };
    });

    const userPositionTypesSelectorRef = React.useRef<IDropdown>(null);

    useEffect(() => {
        !userPositionTypes.length && dispatch(fetchUserPositionTypes());
    }, [dispatch, userPositionTypes]);

    const onUserPositionTypeChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            dispatch(updateUserSignupProperty({ name: "userPositionType", value: option.key }));
        }
    };

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateUserSignupProperty(payload));
        },
        [dispatch],
    );

    return (
        <>
            {props.data.userType === UserType.CUSTOMER && (
                <span>
                    <Selector
                        selectedKey={props.data.userPositionType}
                        comboBoxRef={userPositionTypesSelectorRef}
                        title={t("USER.SIGNUP.USER_POSITION")}
                        options={userPositionTypesOptions}
                        onChange={onUserPositionTypeChange}
                        name={compileNameOfProperty<IUserSignup>("organizationId")}
                        required={true}
                    />
                </span>
            )}
            <span>
                <FormField
                    type="text"
                    label={t("USER.SIGNUP.NAME")}
                    value={props.data.name}
                    error={props.errors.name}
                    {...props.register(compileNameOfProperty<IUserSignup>("name"), { onChange: onInputChange, value: props.data.name })}
                    isRequired={true}
                />
            </span>
            <span>
                <FormField
                    type="text"
                    value={props.data.surName}
                    error={props.errors.surName}
                    label={t("USER.SIGNUP.SURNAME")}
                    {...props.register(compileNameOfProperty<IUserSignup>("surName"), { onChange: onInputChange, value: props.data.surName })}
                    isRequired={true}
                />
            </span>
            <span>
                <FormField
                    type="text"
                    value={props.data.lastName}
                    error={props.errors.lastName}
                    label={t("USER.SIGNUP.LASTNAME")}
                    {...props.register(compileNameOfProperty<IUserSignup>("lastName"), { onChange: onInputChange, value: props.data.lastName })}
                />
            </span>
            <span>
                <FormField
                    type="email"
                    value={props.data.email}
                    error={props.errors.email}
                    label={t("USER.SIGNUP.EMAIL")}
                    {...props.register(compileNameOfProperty<IUserSignup>("email"), { onChange: onInputChange, value: props.data.email })}
                    isRequired={true}
                />
            </span>
            <span>
                <FormField
                    type="text"
                    value={props.data.phoneNumber}
                    error={props.errors.phoneNumber}
                    label={t("USER.SIGNUP.PHONE_NUMBER")}
                    {...props.register(compileNameOfProperty<IUserSignup>("phoneNumber"), {
                        onChange: onInputChange,
                        value: props.data.phoneNumber,
                    })}
                    isRequired={true}
                />
            </span>
        </>
    );
};
