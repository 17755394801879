import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { routeFeatures } from "../../../common/constants/routeFeatures";
import { IGenericPayload, ViewType } from "../../../models";
import { IUserRoleView } from "../../../models/requests/IUserRoleView";
import {
    exportRequestAppointmentsUpcomingSortListAction,
    fetchRequestAppointmentsUpcomingSortListAction,
} from "../../../redux/actions/appointments/upcomingAppointmentsSort";
import { fetchViewsAction } from "../../../redux/actions/user/role/view";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
    resetUpcomingAppointmentsSortFilter,
    updateUpcomingAppointmentsSortFilterProperty,
} from "../../../redux/reducers/upcomingAppointmentsSort/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { AppointmentUpcomingFilter } from "../filter/filter";
import { generateUpcomingAppointmentSortFilterSchema } from "../filter/generateSchema";
import { UpcomingAppointmentsSortList } from "./list/list";

export const UpcomingAppointmentSortContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT" });
    const dispatch = useAppDispatch();
    const { viewPermission } = useAppSelector((state) => state.userRoleViews);
    const { filter } = useAppSelector((state) => state.upcomingAppointmentsSortFilter);

    const viewRequestPermision: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === routeFeatures.UPCOMING_APPOINTMENTS_SORT);

    const viewConfig = useMemo(() => {
        return viewRequestPermision ? (viewRequestPermision?.views.find((view) => view.type === ViewType.SEARCH) as any) : null;
    }, [viewRequestPermision?.views]);

    const schema = useMemo(() => {
        return generateUpcomingAppointmentSortFilterSchema(viewConfig?.fields ? viewConfig.fields : []);
    }, [viewConfig]);

    useEffect(() => {
        viewRequestPermision === undefined && dispatch(fetchViewsAction());
    }, [viewRequestPermision]);

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={
                    <ExportDataIcon
                        onExportCSV={() => {
                            dispatch(exportRequestAppointmentsUpcomingSortListAction());
                        }}
                    />
                }
            />

            <AppointmentUpcomingFilter
                schema={schema}
                filter={filter}
                onApplyFilter={() => {
                    dispatch(fetchRequestAppointmentsUpcomingSortListAction());
                }}
                onResetFilter={() => {
                    dispatch(resetUpcomingAppointmentsSortFilter());
                    dispatch(fetchRequestAppointmentsUpcomingSortListAction());
                }}
                updateProperty={(payload: IGenericPayload) => {
                    dispatch(updateUpcomingAppointmentsSortFilterProperty(payload));
                }}
            ></AppointmentUpcomingFilter>
            <UpcomingAppointmentsSortList />
        </>
    );
};
