import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { mediaQueries } from "../../../common/constants";
import { IGenericPayload } from "../../../models";
import {
    exportStatisticsInspectionAcumulativeByMonthAction,
    exportStatisticsInspectionsDetailedAction,
    exportStatisticsInspectionsGroupedAction,
    fetchStatisticsInspectionAcumulativeByMonthAction,
    fetchStatisticsInspectionDetailedFilterAction,
    fetchStatisticsInspectionGroupedFilterAction,
} from "../../../redux/actions/statistics/statistics";
import { useAppSelector } from "../../../redux/hooks";
import { resetStatisticsInspectionFilter, updateFilterProperty } from "../../../redux/reducers/statistics/inspection/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsInspectionGroupedByMonthContainer } from "./accumulated/list";
import { StatisticsInspectionDetailedContainer } from "./detailed/list";
import { StatisticsInspectionGroupedContainer } from "./grouped/list";

export const StatisticsInspectionsContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION" });
    const dispatch = useDispatch();
    const { filter: filter } = useAppSelector((state) => state.statisticsInspectionFilter);
    const [selectedKey, setSelectedKey] = React.useState("0");
    const theme = useTheme() as any;

    const onExportDataStatisticsInspections = () => {
        switch (selectedKey) {
            case "0":
                return dispatch(exportStatisticsInspectionsDetailedAction());
            case "1":
                return dispatch(exportStatisticsInspectionsGroupedAction());
            case "2":
                return dispatch(exportStatisticsInspectionAcumulativeByMonthAction());
            default:
                return;
        }
    };

    const updateHeaderProperty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };

    const onApplyFilters = () => {
        switch (selectedKey) {
            case "0":
                return dispatch(fetchStatisticsInspectionDetailedFilterAction());
            case "1":
                return dispatch(fetchStatisticsInspectionGroupedFilterAction());
            case "2":
                return dispatch(fetchStatisticsInspectionAcumulativeByMonthAction());
            default:
                return;
        }
    };

    const onResetFilters = () => {
        dispatch(resetStatisticsInspectionFilter());
        onApplyFilters();
    };

    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    const statisticsInspectionContainerHeaderActions = () => {
        return <ExportDataIcon onExportCSV={onExportDataStatisticsInspections} />;
    };

    return (
        <div>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={statisticsInspectionContainerHeaderActions()}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderProperty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <Pivot
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("DETAILED")}
                    itemKey="0"
                >
                    <StatisticsInspectionDetailedContainer />
                </PivotItem>

                <PivotItem
                    headerText={t("GROUPED")}
                    itemKey="1"
                >
                    <StatisticsInspectionGroupedContainer />
                </PivotItem>

                <PivotItem
                    headerText={t("ACCUMULATED")}
                    itemKey="2"
                >
                    <StatisticsInspectionGroupedByMonthContainer />
                </PivotItem>
            </Pivot>
        </div>
    );
};
