import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterRequestState } from "../../../../../models/resources/master/IRequestState";
export interface IMasterRequestStatesState {
    list?: IMasterRequestState[];
    loading: boolean;
}

const initialState: IMasterRequestStatesState = {
    loading: false,
};

export const masterRequestStatesList = createSlice({
    name: "masterRequestStatesList",
    initialState,
    reducers: {
        resetMasterRequestStatesList: () => initialState,
        setMasterLoadingRequestStatesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterRequestStatesList: (state, action: PayloadAction<IMasterRequestState[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterRequestStatesList, setMasterLoadingRequestStatesList, setMasterRequestStatesList } = masterRequestStatesList.actions;

export default masterRequestStatesList.reducer;
