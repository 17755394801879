import { useMemo } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { sortArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { SkeletonSizes } from "../../../../common/constants";
import { VlcPortImportRequestColumn } from "./column/column";

export const VlcPortImportRequestList = () => {
    const { loading, data, sort } = useAppSelector((state) => state.VlcPortImportRequestList);

    const renderColumns = () => {
        return VlcPortImportRequestColumn();
    };

    const newList = useMemo(() => {
        return data?.errors ? sortArray(data?.errors, sort) : [];
    }, [data?.errors, sort]);

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={newList}
            renderColumns={renderColumns}
            height={"calc(100vh - 9rem)"}
        />
    );
};
