import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpcomingAppointment } from "../../../models/upcomingAppointment/IUpcomingAppointments";

export interface IRequestUpcomingAppointmentsList {
    list?: IUpcomingAppointment[];
    loading: boolean;
}

const initialState: IRequestUpcomingAppointmentsList = {
    loading: false,
};

export const requestUpcomingAppointmentsList = createSlice({
    name: "masterDisplayDocksList",
    initialState,
    reducers: {
        resetRequestUpcomingAppointmentsList: () => initialState,
        setLoadingRequestUpcomingAppointmentsList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setRequestUpcomingAppointmentsList: (state, action: PayloadAction<IUpcomingAppointment[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetRequestUpcomingAppointmentsList, setLoadingRequestUpcomingAppointmentsList, setRequestUpcomingAppointmentsList } =
    requestUpcomingAppointmentsList.actions;

export default requestUpcomingAppointmentsList.reducer;
