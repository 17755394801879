import * as React from "react";
import { ContextualMenu, IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { ButtonPrimary } from "../buttons/buttons";

export interface IButtonContextualMenuProps {
    items: IContextualMenuItem[];
    buttonTitle: string;
}

export const ButtonContextualMenu:React.FC<IButtonContextualMenuProps> = ({items,buttonTitle}) => {
    const linkRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    
    const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = React.useCallback(() => {
        setShowContextualMenu(false);
    }, []);

    return (
        <div>
            <ButtonPrimary
                title={buttonTitle}
                handleClick={onShowContextualMenu}
                innerRef={linkRef}
            />
            <ContextualMenu
                items={items}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </div>
    );
};
