import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterBlock } from "../../../../../models/resources/master/IBlock";

export interface IMasterBlockListState {
    list?: IMasterBlock[];
    loading: boolean;
}

const initialState: IMasterBlockListState = {
    loading: false,
};

export const masterBlockList = createSlice({
    name: "masterBlockList",
    initialState,
    reducers: {
        resetMasterBlockList: () => initialState,
        setLoadingMasterBlockeList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterBlockList: (state, action: PayloadAction<IMasterBlock[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterBlockList, setLoadingMasterBlockeList, setMasterBlockList } = masterBlockList.actions;

export default masterBlockList.reducer;
