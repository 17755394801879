import { createUseStyles } from "react-jss";

export const UserProfileEntityUsersSendSignupEmailsListStyle = createUseStyles((_theme: any) => ({
    emailContainer: {
        display: "flex",
        width: "100%",
        height: 260,
        overflowY: "auto",
        paddingBottom: 10,
        paddingTop:10,
        flexDirection: "column",
        rowGap: 20,
    },
}));
