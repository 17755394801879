import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/management/authorizedTransportUnitNumber/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { ManagementAuthorizedTransportUnitNumberColumnStyle } from "./columnStyle.jss";
import { IManagementAuthorizedTransportUnitNumber } from "../../../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";
import {
    setShowPopUpAuthorizedTransportUnitNumberForm,
    updateManagementAuthorizedTransportUnitNumberFormProperty,
} from "../../../../../redux/reducers/management/authorizedTransportUnitNumber/form";
import { fetchManagementAuthorizedTransportUnitNumberAction } from "../../../../../redux/actions/management/authorizedTransportUnitNumber/authorizedTransportUnitNumber";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ManagementAuthorizedTransportUnitNumberColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.AUTHORIZED_TRANSPORT_UNIT_NUMBER" });
    const theme = useTheme();
    const style = ManagementAuthorizedTransportUnitNumberColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.managementAuthorizedTransportUnitNumberList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().managementAuthorizedTransportUnitNumberList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("transportUnitNumber"),
            minWidth: 300,
            maxWidth: 500,
            name: t("TRANSPORT_UNIT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("transportUnitNumber")}
                    title={t("TRANSPORT_UNIT_NUMBER")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("transportUnitNumber"))}
                    sort={sort}
                    isSortable={false}
                />
            ),
            onRender: (item: IManagementAuthorizedTransportUnitNumber) => <span>{item.transportUnitNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("description"),
            minWidth: 250,
            maxWidth: 450,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("description")}
                    title={t("DESCRIPTION")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("description"))}
                    sort={sort}
                    isSortable={false}
                />
            ),
            onRender: (item: IManagementAuthorizedTransportUnitNumber) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("authorized"),
            minWidth: 250,
            maxWidth: 450,
            name: t("AUTHORIZED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("authorized")}
                    title={t("AUTHORIZED")}
                    styles={style}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("authorized"))}
                    sort={sort}
                    isSortable={false}
                />
            ),
            onRender: (item: IManagementAuthorizedTransportUnitNumber) => (
                <span>
                    <BooleanValue value={item.authorized}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 150,
            maxWidth: 200,
            name: "actions",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={"actions"}
                    title={""}
                    styles={style}
                    sort={sort}
                    isSortable={false}
                />
            ),
            onRender: (item: IManagementAuthorizedTransportUnitNumber) => (
                <span className={style.buttonsContainer}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            if (item.id) {
                                dispatch(fetchManagementAuthorizedTransportUnitNumberAction(item.id));
                                dispatch(setShowPopUpAuthorizedTransportUnitNumberForm(PopUpCodes.UPDATE));
                            }
                        }}
                    ></CustomIcons>

                    <>
                        <Separator size="none" />
                        <CustomIcons
                            iconName={NameIcons.REMOVE}
                            onClick={() => {
                                if (item.id) {
                                    dispatch(setShowPopUpAuthorizedTransportUnitNumberForm(PopUpCodes.DELETE));
                                    dispatch(updateManagementAuthorizedTransportUnitNumberFormProperty({ name: "id", value: item.id }));
                                }
                            }}
                        ></CustomIcons>
                    </>
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
