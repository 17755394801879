import { CatalogAPI } from "../../../../../api";
import { IMasterTransportUnit } from "../../../../../models/resources/master/ITransportUnit";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterTransportUnitForm, setMasterTransportUnitForm } from "../../../../reducers/resources/master/transportUnit/form";
import { setLoadingTransportUnitList, setMasterTransportUnitList } from "../../../../reducers/resources/master/transportUnit/list";
import { AppThunk, store } from "../../../../store";


const catalogAPI = new CatalogAPI();

export const fetchMasterTransportUnitList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingTransportUnitList(true));
        const response = await catalogAPI.getMasterTransportUnitList();
        dispatch(setMasterTransportUnitList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingTransportUnitList(false));
    }
};

export const fetchMasterUnitTransport = (form:IMasterTransportUnit): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterTransportUnitForm(true));
        const response = await catalogAPI.getMasterTransportUnit(form);
        dispatch(setMasterTransportUnitForm(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterTransportUnitForm(false));
    }
};

export const postToMasterTransportUnitForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterTransportUnitForm;

        try {
            dispatch(setLoadingMasterTransportUnitForm(true));
            if (form.id) {
                await catalogAPI.putMasterTransportUnit(form);
            } else {
                await catalogAPI.postMasterTransportUnit(form);
            }
            dispatch(fetchMasterTransportUnitList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);dispatch(setLoadingMasterTransportUnitForm(false));
            return false;
        } 
    };
};