import { createUseStyles } from "react-jss";
import { cssConstants } from "../../../../../common/constants";

export const RequestUtiFormStyle = createUseStyles((theme: any) => ({
    formCotainer: {
        display: "flex",
        flexDirection: "column",
        gap: 35,
        paddingTop: 15,
        width: "100%",
        overflowY: "auto",
        paddingRight: 15,
    },
    formRow: {
        display: "flex",
        gap: 15,
        alignItems: "end",
        width: "100%",
    },

    upsertUtiContainer: {
        display: "flex",
        width: 500,
        height: 500,
        flexDirection: "column",
        padding: "32px 16px",
        justifyContent: cssConstants.SPACE_BETWEEN,
    },
    upsertUtiData: {
        display: "flex",
        rowGap: theme.gaps.sizes.lg,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
        height: "inherit",
        paddingTop: 14,
    },
    upsertUtiRow: {
        display: "flex",
        flexDirection: "row",
        columnGap: 24,
    },
    upsertUtiSeals: {
        display: "flex",
        flexDirection: "column",
        rowGap: 12,
    },
    upsertUtiBatches: {
        display: "flex",
        flexDirection: "column",
        rowGap: 12,
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: cssConstants.SPACE_BETWEEN,
        alignItems: "start",
        paddingTop: theme.paddings.default,
    },
    buttons: {
        display: "flex",
        columnGap: theme.gaps.sizes.md,
        "& button": {
            minWidth: 120,
        },
    },
    requiredFields: {
        display: "flex",
        columnGap: 4,
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.sm,
        "& span": {
            color: theme.colors.titles.danger.color,
            fontWeight: 600,
        },
    },
    numberField: {
        width: "36.5%",
    },
}));
