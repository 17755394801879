import { IDocument, IPaymentData,  } from ".";
import { PaymentMethod } from "../../common/enum/PaymentMethod";
import { IAdminData } from "./IAdminData";
import { IEntityData } from "./IEntityData";
import { IRepresentative } from "./representatives/IRepresentative";

export interface IUserProfile extends IBaseUserProfile {
    email: string;
    image: string;
    hasImageChanged: boolean;
    roles: IUserDetailRole[];
    entityData: IEntityData;
    operatorData: IAdminData;
    adminData: IAdminData;
    paymentData: IPaymentData;
    blobs: IDocument[];
    representatives?: IRepresentative[];
    hasRepresentativeData?: boolean;
    hasNotificationData: boolean;
}

export interface IBaseUserProfile {
    name: string;
    surName: string;
    lastName: string;
    phoneNumber: string;
}

export interface IUserDetailRole {
    canEditAdministrationData: boolean;
    id: string;
    name: string;
    userManagementCode: string;
}

export const emptyUserProfileModel = (): IUserProfile => {
    return {
        name: "",
        surName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        hasImageChanged: false,
        image: "",
        roles: [],
        entityData: {
            documentNumber: "",
            city: "",
            name: "",
            postalCode: "",
            province: "",
            socialAddress: "",
        },
        paymentData: {
            paymentMethod: PaymentMethod.BANK_DRAFT,
            accountNumber: "",
            bankEntity: "",
        },
        adminData: {
            mobilePhone: "",
            phone: "",
            emails: [
                {
                    email: "",
                    isValid: true,
                },
            ],
        },
        operatorData: {
            mobilePhone: "",
            phone: "",
            emails: [
                {
                    email: "",
                    isValid: true,
                },
            ],
        },
        blobs: [],
        representatives: [],
        hasRepresentativeData: false,
        hasNotificationData: false,
    };
};
