export enum pageSizes {
    REQUEST = 150,
    PROFILE = 7,
    INVOICING_UNINVOICED = 50,
    MANAGEMENT_USER = 50,
    MANAGEMENT_USER_SIGNUP = 50,
    MANAGEMENT_REPRESENTATION = 50,
    MANAGEMENT_NOTIFICATION = 50,
    MANAGEMENT_SUMMARY=30
}

export enum SkeletonSizes {
    REQUEST = 11,
    MANAGEMENT_USER = 12,
    MANAGEMENT_USER_SIGNUP = 12,
    DEFAULT = 5
}

export enum DeviceMinSizes {
    DESKTOP = 1280,
    TABLET = 800,
}

export enum MaxDays {
    REQUEST_SUBMITTED = 10,
}