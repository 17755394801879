import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import Select from "react-select";
import { HookFormProps } from "../../../models";
import { Label } from "../labels/label";
import { IAutoCompleteProps } from "./autocomplete";
import { AutoCompleteStyle } from "./autocomplete.jss";
import { initAutocompleteStyles } from "./customStyles";

export const ControlledAutoComplete: FC<IAutoCompleteProps & HookFormProps> = (props) => {
    const theme = useTheme();
    const { t } = useTranslation("common");

    const customStyles = initAutocompleteStyles(theme);

    const styles = AutoCompleteStyle({ theme });

    const [inputText, setInputText] = useState<string>("");
    const { name, options, selectedKey, onChange, disabled, title, isRequired } = props;

    const selectOptions = options.map((item) => {
        return { value: item.key, label: item.text };
    });

    const renderNoOptions = () => {
        return <> {t("FORM.VALIDATION.NO_RESULTS")}</>;
    };

    const internalSelectedKey = selectOptions.filter((item) => item.value === selectedKey);

    const internalOnChange = (option: any) => {
        const realOption = options.filter((item) => item.key === option?.value && item.text === option?.label)[0];
        onChange(realOption, name);
    };

    const onInputChange = (newValue: string) => {
        setInputText(newValue);
    };

    return (
        <div className={styles.container}>
            <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.selectedKey}
                render={({ field: { onChange: onAutocompleteChanges }, fieldState: { error } }) => (
                    <>
                        <Select
                            onChange={(option) => {
                                onAutocompleteChanges(option);
                                internalOnChange(option);
                            }}
                            styles={customStyles}
                            options={selectOptions}
                            value={internalSelectedKey}
                            isDisabled={disabled}
                            noOptionsMessage={renderNoOptions}
                            placeholder=""
                            onInputChange={onInputChange}
                        />
                        {title && (
                            <Label
                                title={title}
                                isRequired={isRequired}
                                className={selectedKey || inputText ? styles.labelSelected : styles.label}
                            />
                        )}
                        {error && <span className={styles.errorMessage}>{error?.message}</span>}
                    </>
                )}
            />
        </div>
    );
};
