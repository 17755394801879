import { IDropdownOption } from "@fluentui/react";
import React,{ useEffect } from "react";
import { fetchDocks } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const DockSelector: React.FC<IBaseSelectorProps> = ({ onChange, propertyName, title, selectedKey, control, rules, disabled, isRequired  }) => {
    const { data, loading } = useAppSelector((store) => store.catalog.docks);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchDocks());
    }, [dispatch, data]);
    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _ind?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={mapIDescriptionToDropDownOptions(data)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
