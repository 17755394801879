import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { routeUrls } from "../common/constants";
import { HttpStatus } from "../common/enum/api/HttpStatus";

export const ErrorListener = () => {
    const navigate = useNavigate();
    const { error } = useAppSelector((state) => state.apiException);

    useEffect(() => {
        if (error && error.htmlStatus) {
            switch (error.htmlStatus) {
                case HttpStatus.INTERNAL_SERVER_ERROR:
                case HttpStatus.SERVICE_UNAVAILABLE:
                case HttpStatus.NOT_FOUND:
                    navigate(routeUrls.INTERNAL_SERVER_ERROR);
                    break;
            }
        }
    }, [error, navigate]);

    return null;
};
