import { useTranslation } from "react-i18next";
import {
    exportInvoicingSummaryByConceptAction,
    fetchInvoicingSummaryByConceptAction,
} from "../../../redux/actions/invoicing/summaryByConcept/summaryByConcept";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { InvoicingSummaryByConceptList } from "./list/list";
import { InvoicingFilterContainer } from "../filter/filter";
import { IGenericPayload } from "../../../models";
import { resetInvoicingSummaryConceptFilter, updateFilterProperty } from "../../../redux/reducers/invoicing/summaryByConcept/filter";
import { useCallback } from "react";
export const InvoicingSummaryByConceptContainer = () => {
    const { filter } = useAppSelector((state) => state.invoicingSummaryConceptFilter);

    const { t } = useTranslation("common", { keyPrefix: "INVOICED" });
    const dispatch = useAppDispatch();
    const onApply = useCallback(() => {
        dispatch(fetchInvoicingSummaryByConceptAction());
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(resetInvoicingSummaryConceptFilter());
        dispatch(fetchInvoicingSummaryByConceptAction());
    }, [dispatch]);

    return (
        <>
            <NavHeaderComponent
                title={t("TITLES.SUMARYCONCEPT")}
                endAction={<ExportDataIcon onExportCSV={() => dispatch(exportInvoicingSummaryByConceptAction())} />}
            />

            <InvoicingFilterContainer
                filter={filter}
                applyFilter={onApply}
                resetFilter={onReset}
                updateFilterProperty={(payload: IGenericPayload) => {
                    dispatch(updateFilterProperty(payload));
                }}
            />

            <InvoicingSummaryByConceptList />
        </>
    );
};
