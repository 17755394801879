import { useMemo } from "react";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    resetConfigOrganismCertificateTypeForm,
    setPopUpConfigOrganismCertificateTypeForm,
} from "../../../../redux/reducers/resources/organismCertificatedType/form";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ConfigOrganismWithCertificateForm } from "./form/form";
import { useTranslation } from "react-i18next";
import {
    postToOrganismCertificateTypeForm,
    removeOrganismCertificateTypeForm,
} from "../../../../redux/actions/resources/organismCertificateType/organismCertificateType";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ConfigOrganismWithCertificateController = () => {
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();
    const { form, loading, showPopUp } = useAppSelector((state) => state.configOrganismCertificateTypeForm);

    const onCancel = () => {
        dispatch(setPopUpConfigOrganismCertificateTypeForm(PopUpCodes.NONE));
    };
    const onDimissed = () => {
        dispatch(resetConfigOrganismCertificateTypeForm());
    };
    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postToOrganismCertificateTypeForm()).then((response: any) => {
                response && onCancel();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(removeOrganismCertificateTypeForm()).then((response: any) => {
                response && onCancel();
            });
        }
    };
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }

        return "";
    }, [showPopUp]);
    return (
        <>
            <PopUpForm
                content={<ConfigOrganismWithCertificateForm />}
                title={titlePopUp}
                isLoading={loading}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"calc(40vh - 10rem)"}
                width={"calc(40vw - 5rem)"}
                leftButtonContent={<RequiredFieldsMessage />}
                isDisabledButton={!form.organismId || !form.certificateTypeId}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancel}
                onDimissed={onDimissed}
                onAccept={onSave}
                height={"auto"}
                type={PopUpConfirmationType.DELETE}
            ></PopUpConfirmation>
        </>
    );
};
