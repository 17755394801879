import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { IActuation } from "../../../../models/requests/IEditRequest";
import { fetchExpedientActuationList } from "../../../../redux/actions/request/edit/action/actuation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setEditRequestActuationPopUp, updateActuationFormProperty } from "../../../../redux/reducers/request/edit/form/actuation";
import { compileNameOfProperty } from "../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../common/buttons/buttons";
import { ActuationFormController } from "../../common/columns/components/controllers/actuation";
import { RequestListActuationContainerStyle } from "./containerStyle.jss";
import { RequestListActuationList } from "./list/list";

interface IRequestListActuationContainerProps {
    inspectionId: number;
    requestId: number;
}

export const RequestListActuationContainer: React.FC<IRequestListActuationContainerProps> = ({ inspectionId, requestId }) => {
    const { t } = useTranslation("common", { keyPrefix: "LIST.ACTUATION" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = RequestListActuationContainerStyle({ theme });
    const [showFormActuationModal, setShowFormActuationModal] = useState<boolean>(false);
    const [showDeleteActuationModal, setShowDeleteActuationModal] = useState<boolean>(false);

    const { list, loadingList } = useAppSelector((store) => store.actuationForm);

    const { organismId } = useAppSelector((store) => store.userData.profile);


    useEffect(() => {
        if (list === undefined && !loadingList) {
            dispatch(fetchExpedientActuationList(requestId, inspectionId));
        }
    }, [dispatch]);

    return (
        <div className={style.manageActuationContainer}>
            <div>
                <ButtonPrimaryInverseWithBorder
                    handleClick={() => {
                        dispatch(setEditRequestActuationPopUp(PopUpCodes.CREATE));
                        setShowFormActuationModal(true);
                        dispatch(updateActuationFormProperty({ name: compileNameOfProperty<IActuation>("inspectionId"), value: inspectionId }));
                    }}
                    title={`+ ${t("NEW_ACTUATION")}`}
                />
            </div>
            <div className={style.list}>
                <RequestListActuationList
                    list={list ?? []}
                    requestId={requestId}
                    showFormActuationModal={setShowFormActuationModal}
                    showDeleteActuationModal={setShowDeleteActuationModal}
                />
            </div>

            <ActuationFormController
                isEditRequest={false}
                requestId={requestId}
                disabledOrganismSelector={organismId ? true : false}
                delay={true}
                showFormActuationModal={showFormActuationModal}
                onDismissFormActuationModal={setShowFormActuationModal}
                showDeleteActuationModal={showDeleteActuationModal}
                onDismissDeleteActuationModal={setShowDeleteActuationModal}
            />
        </div>
    );
};
