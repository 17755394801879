import { useMemo } from "react";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { ManagementAuthorizedTransportUnitNumberForm } from "./form/form";
import {
    resetManagementAuthorizedTransportUnitNumberState,
    setShowPopUpAuthorizedTransportUnitNumberForm,
} from "../../../../redux/reducers/management/authorizedTransportUnitNumber/form";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import {
    postManagementAuthorizedTransportUnitNumberAction,
    removeManagementAuthorizedTransportUnitNumberAction,
} from "../../../../redux/actions/management/authorizedTransportUnitNumber/authorizedTransportUnitNumber";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ManagementAuthorizedTransportUnitNumberController = () => {
    const { form, loading, showPopUp } = useAppSelector((state) => state.managementAuthorizedTransportUnitNumberForm);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.AUTHORIZED_TRANSPORT_UNIT_NUMBER" });
    const dispatch = useAppDispatch();

    const onDimissed = () => {
        dispatch(resetManagementAuthorizedTransportUnitNumberState());
    };

    const onCloseForm = () => {
        dispatch(setShowPopUpAuthorizedTransportUnitNumberForm(PopUpCodes.NONE));
    };

    const onSaveForm = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postManagementAuthorizedTransportUnitNumberAction()).then((response: any) => {
                if (response) {
                    onCloseForm();
                }
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(removeManagementAuthorizedTransportUnitNumberAction()).then((response: any) => {
                if (response) {
                    onCloseForm();
                }
            });
        }
    };

    const isVisiblePopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE;
    }, [showPopUp]);

    const isValidForm = useMemo(() => {
        return !form.transportUnitNumber || !form.description;
    }, [form]);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }
        return "";
    }, [showPopUp]);

    return (
        <>
            <PopUpForm
                content={<ManagementAuthorizedTransportUnitNumberForm />}
                width={"30vw"}
                height={"30vh"}
                title={titlePopUp}
                isDisabledButton={isValidForm}
                isLoading={loading}
                isVisible={isVisiblePopUp}
                leftButtonContent={<RequiredFieldsMessage />}
                onSave={onSaveForm}
                onCancel={onCloseForm}
                onDimissed={onDimissed}
            />
            <PopUpConfirmation
                onDimissed={onDimissed}
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCloseForm}
                onAccept={onSaveForm}
                loading={loading}
                disabledActions={loading}
                height={"auto"}
                type={PopUpConfirmationType.DELETE}
            ></PopUpConfirmation>
        </>
    );
};
