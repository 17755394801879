import { createUseStyles } from "react-jss";

export const RequestDraftListStyle = createUseStyles((theme: any) => ({
    generalContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
    },
    requestContainer: {
        height: "100%",
    },
    createRequestContainer: {
        padding: "32px 32px 0 32px",
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        columnGap: 24,
    },
    exportBtn: {
        display: "flex",
        gap: 10,
        alignContent: "center",
        justifyContent: "center",
        width: 100,
        cursor: "pointer",
        color: theme.colors.exportBtn.color,
        textTransform: "capitalize",
        fontSize: theme.fonts.sizes.xs,
        "& i": {
            width: 30,
        },
    },
    cardList : {
        height: "calc(100vh - 320px)",
        overflow: "auto",
    }
}));
