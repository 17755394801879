import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISortState } from "../../../../models/common/ISortState";
import { ICustomersStatisticsItem, ICustomersStatisticsState } from "../../../../models/statistics/ICustomer";
import { sortArray } from "../../../../utils";

interface IInitialState {
    data: ICustomersStatisticsState;
}
const initialSateData = () => {
    return {
        customerStatistics: [] as ICustomersStatisticsItem[],
        sort: {
            column: "",
            orderColumn: "",
        },
        loading: false,
        totalContainers20: 0,
        totalContainers40: 0,
        totalTrucks: 0,
        totalVehicles: 0,
        totalIncidences: 0,
        totalIncidencesTime: 0,
        totalAverageIncidencesTime: 0,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsCustomerListSlice = createSlice({
    name: "statiticsCustomerList",
    initialState,
    reducers: {
        resetCustomerList: () => initialState,
        setList: (state, action: PayloadAction<ICustomersStatisticsItem[]>) => {
            state.data.customerStatistics = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.data.sort = action.payload;
            state.data.customerStatistics = sortArray(state.data.customerStatistics, state.data.sort);
        },
        setLoadingCustomerList: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
        setDataCustomerList: (state, action: PayloadAction<ICustomersStatisticsState>) => {
            state.data = action.payload;
            state.data.sort = {
                column: "",
                orderColumn: "",
            };
        },
    },
});

export const { resetCustomerList, setList, updateSortColumn, setLoadingCustomerList, setDataCustomerList } = statiticsCustomerListSlice.actions;

export default statiticsCustomerListSlice.reducer;
