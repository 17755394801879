import { useTheme } from "react-jss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsCustomersListStyle } from "./listStyle.jss";
import { useEffect } from "react";
import { StatisticsMonthlySummaryListColumn } from "./column/column";
import { DetailsRow, IDetailsFooterProps } from "@fluentui/react";
import { StatisticsTotalMonthlySummaryListColumnFooter, StatisticsAverageMonthlySummaryListColumnFooter } from "./column/footer";
import { fetchStatiticsMonthlySummaryFilterAction } from "../../../../redux/actions/statistics/statistics";
import { SkeletonSizes } from "../../../../common/constants";

export const StatisticsMonthlySummaryList = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = StatisticsCustomersListStyle({ theme });
    const { monthlySummaryStatistic, loading } = useAppSelector((state) => state.statisticsMonthlySummaryList.data);

    const onRenderColumns = () => {
        return StatisticsMonthlySummaryListColumn();
    };
    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };
        const totalFooterColumns = StatisticsTotalMonthlySummaryListColumnFooter(dataFooter);
        const totalMonthly = StatisticsAverageMonthlySummaryListColumnFooter(dataFooter);
        return (
            <>
                <DetailsRow
                    {...detailsFooterProps}
                    columns={totalFooterColumns}
                    item={{}}
                    itemIndex={5}
                    groupNestingDepth={detailsFooterProps.groupNestingDepth}
                    selection={detailsFooterProps.selection}
                    styles={style}
                />
                <div className={style.averageMonthly}>
                    <DetailsRow
                        {...detailsFooterProps}
                        columns={totalMonthly}
                        item={{}}
                        itemIndex={5}
                        groupNestingDepth={detailsFooterProps.groupNestingDepth}
                        selection={detailsFooterProps.selection}
                        styles={style}
                    />
                </div>
            </>
        );
    };
    useEffect(() => {
        monthlySummaryStatistic && !loading && dispatch(fetchStatiticsMonthlySummaryFilterAction());
    }, []);

    return !loading ? (
        <CustomDetailsList
            data={monthlySummaryStatistic ? monthlySummaryStatistic : []}
            renderColumns={onRenderColumns}
            height={heights.statistics.customers}
            customFooter={_onRenderDetailsFooter}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
