export interface IUserSelfSignup {
    id: string;
    name: string;
    surName: string;
    lastName: string;
    image: string;
    phoneNumber: string;
}

export const emptyUserSelfSignUpModel = (): IUserSelfSignup => {
    return {
        id: "",
        name: "",
        surName: "",
        lastName: "",
        phoneNumber: "",
        image: "",
    };
};
