import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const TemplateHeaderStyles = createUseStyles((theme: any) => ({
    siteHeader: (props: any) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        background: props.background ?? theme.colors.siteHeader.background,
        height: 60,
        gap: theme.gaps.sizes.xxl,
        rowGap: "row-gap",
    }),
    menu: {
        display: "none",
        [mediaQueries.TABLET]: {
            display: "block",
        },
        [mediaQueries.MOBILE]: {
            display: "block",
        },
    },
    title: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
        marginLeft: theme.paddings.header.paddingLeft,
        borderTop: "5px solid gray",
    },
    selectorContainer: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.sizes.md,
    },
    userInfo: {
        height: "100%",
        display: "flex",
        marginLeft: "auto",
        alignItems: "center",
    },
    row: {
        display: "flex",
        width: "100%",
    },
}));
