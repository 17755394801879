import { Method } from "../../../common/enum";
import { IManagementRepresentation } from "../../../models/management/representation/IManagementRepresentation";
import { IManagementRepresentationFilter } from "../../../models/management/representation/IManagementRepresentationFilter";
import { IManagementRepresentationForm } from "../../../redux/reducers/management/representation/form";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/users/representatives`;

export class ManagementRepresentationApi {
    async getRepresentationListAsync(filter: IManagementRepresentationFilter): Promise<IManagementRepresentation[]> {
        const url = `${baseURL}?userId=${filter.userId}&representativeUserId=${filter.representativeUserId}&validated=${
            filter.validated === undefined ? "" : filter.validated
        }`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({ filter }).execute<IManagementRepresentation[]>();
    }

    async updateRepresentationAsync(representation: any): Promise<boolean> {
        const url = `${baseURL}/${representation?.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(representation).execute<boolean>();
    }

    async getRepresentationAsync(representationId: string): Promise<IManagementRepresentationForm> {
        const url = `${baseURL}/${representationId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IManagementRepresentationForm>();
    }
}
