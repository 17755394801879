import React from "react";
import { fetchSilentRequestListAction } from "../../../redux/actions/request/list/requestList";
import { restoreOriginalRole } from "../../../redux/reducers/user/role/role";
import { setImpersonatedOrganismId } from "../../../redux/reducers/userData/userData";
import { setPersistScrollOnDetailsList } from "../../../redux/reducers/webConfig/globalConfig";
import { store } from "../../../redux/store";
import { RequestList } from "./list/list";
import { RoleType } from "../../../common/enum";

interface IRequestListContainerProps {
    interval: NodeJS.Timer | undefined;
}

export class RequestListContainer extends React.Component {
    state: IRequestListContainerProps = {
        interval: undefined,
    };

    componentDidMount(): void {
        this.state.interval = setInterval(() => {
            store.dispatch(fetchSilentRequestListAction());
        }, 120000);
    }

    componentWillUnmount(): void {
        const { principalRole } = store.getState().userData.profile;

        this.state.interval && clearInterval(this.state.interval);
        this.state.interval = undefined;

        if(principalRole !== RoleType.ORGANISM_ADMIN && principalRole !== RoleType.ORGANISM_TECHNICIAN && principalRole !== RoleType.ORGANISM_INSPECTOR) {
            store.dispatch(setImpersonatedOrganismId(undefined));
            store.dispatch(restoreOriginalRole(principalRole));
        }

        store.dispatch(setPersistScrollOnDetailsList(false));

        
    }

    render(): React.ReactNode {
        return <RequestList />;
    }
}
