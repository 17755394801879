import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatisticsReaderRecordRatio, IStatisticsReaderRecordRatioWithState } from "../../../../models/statistics/IReaderRecordRatio";

interface IInitialState {
    data: IStatisticsReaderRecordRatioWithState;
}
const initialSateData = (): IStatisticsReaderRecordRatioWithState => {
    return {
        loading: false,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsReaderRecordRatioListSlice = createSlice({
    name: "statiticsReaderRecordRatioListSlice",
    initialState,
    reducers: {
        resetReaderRecordRatioListState: () => initialState,
        setList: (state, action: PayloadAction<IStatisticsReaderRecordRatio[]>) => {
            state.data.readerRecordsRatio = action.payload;
        },
        setStatisticsReaderRecordRatioLoading: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
        setStatisticsReaderRecordRatioData: (state, action: PayloadAction<IStatisticsReaderRecordRatio[]>) => {
            state.data.readerRecordsRatio = action.payload;
        },
    },
});

export const { resetReaderRecordRatioListState, setList, setStatisticsReaderRecordRatioLoading, setStatisticsReaderRecordRatioData } =
    statiticsReaderRecordRatioListSlice.actions;

export default statiticsReaderRecordRatioListSlice.reducer;
