import { useTheme } from "react-jss";
import { RequestUtiFormStyle } from "./formStyle.jss";
import { useTranslation } from "react-i18next";
import { IDropdownOption } from "@fluentui/react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../../../redux/hooks";
import { FormatNumberInput } from "../../../../common/numberField/utils";
import { regex } from "../../../../../common/constants";
import { FormField } from "../../../../common/fields/fields";
import { FormNumberField } from "../../../../common/numberField/numberField";
import { ControlledRadioButtonGroup } from "../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { requestMaxLength } from "../../../form/create/validation/requestConfig";
import { ContainerSizeSelector } from "../../selectors";
import { OriginSeals } from "../seals/seals";
import { IConfigCreateRequest, IGenericPayload, ITransportUnitData } from "../../../../../models";
import { compileNameOfProperty, getGenericPayloadFromEvent, requiredMessage } from "../../../../../utils";
import { booleanValidation } from "../../../draft/form/validation/requestValidationUtils";
import { Batches } from "../batches/batches";

interface IRequestUtiFormProps {
    setIsValidForm: (value: boolean) => void;
    isContainer: boolean;
    schema: IConfigCreateRequest;
    form: ITransportUnitData;
    updateProperty: (payload: IGenericPayload) => void;
    addSeal: (seal: string) => void;
    removeSeal: (index: number) => void;
    addBatch: (batch: string) => void;
    removeBatch: (index: number) => void;
}

export const RequestUtiForm: React.FC<IRequestUtiFormProps> = ({
    isContainer,
    schema,
    setIsValidForm,
    form,
    updateProperty,
    addSeal,
    removeSeal,
    addBatch,
    removeBatch,
}) => {
    const theme = useTheme();
    const styles = RequestUtiFormStyle({ theme });
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const {
        register,
        control,
        trigger,
        formState: { errors, isValid },
    } = useForm<ITransportUnitData>({ mode: "onChange" });

    const handleChange = (option?: IDropdownOption, name?: string) => {
        const payload: IGenericPayload = {
            name: name!,
            value: option?.key,
        };
        updateProperty(payload);
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            updateProperty(payload);
        },
        [dispatch],
    );

    const onNumberChange = useCallback(
        (event: any, _newValue?: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);

            updateProperty({ name: name, value: FormatNumberInput(value) });
        },
        [dispatch],
    );
    const onRadioButtonChange = useCallback(
        (optionKey: string, name?: any) => {
            const payload: IGenericPayload = {
                name: name,
                value: !!+optionKey,
            };
            updateProperty(payload);
        },
        [dispatch],
    );

    useEffect(() => {
        trigger();
        setIsValidForm(!isValid);
    }, [form, isValid, errors]);

    return (
        <div className={styles.formCotainer}>
            {isContainer && schema.transportUnitSizeId.visible && (
                <div className={styles.formRow}>
                    <ContainerSizeSelector
                        onChange={handleChange}
                        selectedKey={form.transportUnitSizeId!}
                        propertyName={compileNameOfProperty<ITransportUnitData>("transportUnitSizeId")}
                        title={t("CREATE_REQUEST.FIELDS.CONTAINER_SIZE")}
                        isRequired={isContainer}
                        control={control}
                        rules={{ required: isContainer ? requiredMessage : false }}
                    />
                </div>
            )}
            {schema.transportUnitNumber.visible && (
                <div className={styles.formRow}>
                    <FormField
                        type="text"
                        value={form.transportUnitNumber}
                        maxLength={requestMaxLength.TRANSPORT_UNIT_NUMBER}
                        label={t("CREATE_REQUEST.FIELDS.TRANSPORT_UNIT")}
                        isRequired={schema.transportUnitNumber.required}
                        error={
                            errors.transportUnitNumber?.type === "hasInvalidChars"
                                ? { ...errors.transportUnitNumber, message: t("FORM.VALIDATION.TRANSPORT_UNIT_INVALID_CHARS") }
                                : errors.transportUnitNumber
                        }
                        {...register("transportUnitNumber", {
                            onChange: onInputChange,
                            value: form.transportUnitNumber,
                            validate: {
                                hasInvalidChars: (value: any) => (regex.ONLY_NUMBERS_AND_LETTERS.test(value) ? true : false),
                                isRequired: () => (schema.transportUnitNumber.required ? !!form.transportUnitNumber : true),
                            },

                            required: schema.transportUnitNumber.required ? requiredMessage : false,
                            pattern: isContainer
                                ? {
                                      value: regex.EQUALS_CONTAINER_PLATE,
                                      message: t("FORM.VALIDATION.CONTAINER"),
                                  }
                                : {
                                      value: regex.NOT_EQUALS_CONTAINER_PLATE,
                                      message: t("FORM.VALIDATION.NOT_CONTAINER"),
                                  },
                        })}
                    />
                </div>
            )}
            {(schema.packages.visible || schema.grossWeight.visible || schema.palletized.visible) && (
                <div className={styles.formRow}>
                    {schema.packages.visible && (
                        <div className={styles.numberField}>
                            <FormNumberField
                                value={form.packages ? String(form.packages) : ""}
                                maxLength={requestMaxLength.PACKAGES}
                                label={t("CREATE_REQUEST.FIELDS.PACKAGES")}
                                isRequired={schema.packages.required}
                                {...register(compileNameOfProperty<ITransportUnitData>("packages"), {
                                    onChange: onNumberChange,
                                    value: form.packages,
                                    validate: {
                                        isRequired: (value: any) => {
                                            if (!schema.packages.required) {
                                                return value !== "0";
                                            }
                                            return schema.packages.required ? value && value > 0 : true;
                                        },
                                    },
                                    required: schema.packages.required ? requiredMessage : false,
                                })}
                                nonZeroValue={true}
                            />
                        </div>
                    )}
                    {schema.grossWeight.visible && (
                        <div className={styles.numberField}>
                            <FormNumberField
                                value={form.grossWeight ? String(form.grossWeight) : ""}
                                maxLength={requestMaxLength.GROSS_WEIGHT}
                                label={t("CREATE_REQUEST.FIELDS.GROSS_KG")}
                                isRequired={schema.grossWeight.required}
                                {...register(compileNameOfProperty<ITransportUnitData>("grossWeight"), {
                                    onChange: onNumberChange,
                                    value: form.grossWeight,
                                    validate: {
                                        isRequired: (value: any) => {
                                            if (!schema.grossWeight.required) {
                                                return value !== "0";
                                            }

                                            return schema.grossWeight.required ? value && value > 0 : true;
                                        },
                                    },
                                    required: schema.grossWeight.required ? requiredMessage : false,
                                })}
                                decimalNumber={3}
                                nonZeroValue={true}
                            />
                        </div>
                    )}
                    {schema.palletized.visible && (
                        <div>
                            <ControlledRadioButtonGroup
                                label={t("CREATE_REQUEST.FIELDS.PALLETIZED")}
                                selectedKey={String(+form?.palletized!)}
                                onRadioButtonChange={onRadioButtonChange}
                                required={schema.palletized.required}
                                control={control}
                                rules={{
                                    required: schema.palletized.required ? requiredMessage : false,
                                    validate: () => booleanValidation(schema.palletized.required, form.palletized),
                                }}
                                name={compileNameOfProperty<ITransportUnitData>("palletized")}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.upsertUtiSeals}>
                <OriginSeals
                    seals={form.seals}
                    addItem={addSeal}
                    removeSeal={removeSeal}
                    isContainer={isContainer}
                />
            </div>
            {schema.batches.visible && (
                <div className={styles.upsertUtiBatches}>
                    <Batches
                        batches={form.batches}
                        addBatch={addBatch}
                        removeBatch={removeBatch}
                    />
                </div>
            )}
        </div>
    );
};
