export const palette = {
    white: "#FFFFFF",
    black: "#000000",
    shadowBlack: "#283046",
    green: "#28C76F",
    greenLigth: "#85ff90",
    red: "#EA5455",
    orange: "#FFC107",
    darkOrange: "#FF9F43",
    turquoise: "#00CFE8",
    greyLighter: "#F7F7F7",
    greyLight: "#F0F0F0",
    grey: "#82868B",
    greyDark: "#676D7D",
    greyBlue: "#EEEDF8",
    greyLightText: "#95989A",
    greyPurple: "#a09cc7",
    greyFooter: "#DDDCE8",
    purpleLighter: "#D9D7F6",
    purpleLight: "#F3F3FA",
    purple: "#7367f0",
    lavender: "#E7E5FF",
    purpleDarker: "#283046",
    blueLight: "#009fe3",
    blueDark: "#002e5e",
    yellow: "#ddc509",
    greenWater: "#00B4AD",
    greenWaterLight: "#F2FFFD",
};

export const colors = {
    palette: palette,
    autocomplete: {
        border: palette.grey,
        optionSelected: "#EEEBE9",
        optionFocused: palette.purpleLight,
        optionHover: palette.greyLighter,
        optionHoverColor: palette.white,
        fontColor: palette.grey,
    },
    site: {
        background: palette.greyLighter,
        color: palette.grey,
        white: palette.white,
        warning: palette.darkOrange,
    },
    font: {
        base: palette.grey,
        labels: {
            title: palette.greyLightText,
            value: palette.black,
        },
        success: palette.green,
        warning: palette.darkOrange,
        error: palette.red,
        list: {
            color: palette.black,
            highlight: palette.red,
        },
        highlight: palette.purple,
    },
    sections: {
        background: palette.greyLighter,
    },
    titles: {
        primary: {
            color: palette.purple,
        },
        secondary: {
            color: palette.grey,
        },
        success: {
            color: palette.green,
        },
        danger: {
            color: palette.red,
        },
        warning: {
            color: palette.orange,
        },
        info: {
            color: palette.purple,
        },
        black: {
            color: palette.black,
        },
    },
    buttons: {
        primary: {
            background: palette.purple,
            color: palette.white,
        },
        secondary: {
            background: palette.grey,
            color: palette.white,
        },
        success: {
            background: palette.green,
            color: palette.white,
        },
        danger: {
            background: palette.red,
            color: palette.white,
        },
        warning: {
            background: palette.darkOrange,
            color: palette.white,
        },
        info: {
            background: palette.purple,
            color: palette.white,
        },
    },
    navLinks: {
        primary: {
            color: palette.purple,
            visitedColor: palette.purple,
        },
        secondary: {
            color: palette.grey,
            visitedColor: palette.grey,
        },
        success: {
            color: palette.green,
            visitedColor: palette.green,
        },
        danger: {
            color: palette.red,
            visitedColor: palette.red,
        },
        warning: {
            color: palette.orange,
            visitedColor: palette.orange,
        },
        info: {
            color: palette.turquoise,
            visitedColor: palette.turquoise,
        },
    },
    siteNav: {
        background: palette.purpleDarker,
        color: palette.purpleLighter,
        navButton: {
            background: palette.greyBlue,
            color: palette.greyBlue,
        },
        section: {
            color: palette.greyDark,
        },
        item: {
            background: "transparent",
            color: palette.purpleLighter,
        },
        itemHover: {
            color: palette.white,
        },
        itemActive: {
            background: palette.lavender,
            color: palette.purpleDarker,
        },
    },
    siteHeader: {
        background: "#EEEDF8 0% 0% no-repeat padding-box",
        devBackground:"#ebf3a0", 
        preBackground:"#ff0d005c"
    },
    siteContent: {
        background: palette.white,
    },
    siteFooter: {
        background: palette.greyLighter,
    },
    form: {
        input: {
            default: palette.grey,
            filled: palette.greyDark,
            focus: palette.purple,
            invalid: palette.red,
        },
        checkbox: {
            default: palette.purple,
        },
        section: {
            separator: palette.grey,
        },
    },
    spinner: {
        background: palette.greyDark,
        main: palette.blueDark,
        after: palette.blueLight,
        before: palette.black,
        title: palette.black,
    },
    inputFile: {
        error: palette.red,
    },
    icon: {
        edit: palette.greyDark,
    },
    radioButtons: {
        color: palette.purple,
        borderColor: palette.purple,
    },
    cards: {
        request: palette.greyLight,
    },
    messageAlert: {
        primary: {
            color: palette.purple,
        },
        secondary: {
            color: palette.grey,
        },
        success: {
            color: palette.green,
        },
        danger: {
            color: palette.red,
        },
        warning: {
            color: palette.orange,
        },
        info: {
            color: palette.turquoise,
        },
        black: {
            color: palette.black,
        },
    },
    table: {
        header: {
            color: palette.grey,
        },
        body: {
            color: palette.black,
        },
        sortArrow: {
            color: palette.purple,
        },
    },
    daypicker: {
        todayBackground: palette.purpleLight,
        todayColor: palette.black,
        selectedBackground: palette.purple,
        selectedColor: palette.white,
    },
    headerNav: {
        background: palette.lavender,
        color: palette.black,
    },
    exportBtn: {
        color: palette.purple,
    },
    iconSort: {
        disabled: palette.grey,
        enabled: palette.purple,
    },
    stateChanger: {
        primary: {
            color: palette.purple,
            selectedBackground: palette.white,
        },
        secondary: {
            color: palette.turquoise,
            selectedBackground: palette.white,
        },
        other: {
            color: palette.black,
        },
    },
    footerTable: {
        background: palette.greyFooter,
    },
    toogle: {
        background: palette.purple,
    },
    tooltip: {
        background: palette.lavender,
    },
    statistics: {
        color: palette.purple,
    },
    dailyCharge: {
        color: palette.purple,
        background: palette.greyBlue,
    },
    dockDisplay: {
        asignedVehicle: {
            background: "#F6F5FF",
            border: "#D4D1F2",
        },
        newAsignedVehicle: {
            background: palette.greenWaterLight,
            border: palette.greenWater,
            color: palette.greenWater,
        },
    },
    workCalendar: {
        borderColor: palette.lavender,
        color: palette.greyDark,
        disabledBackground: palette.greyLight,
        weekend: palette.greyLight,
        weekColor: palette.purple,
        headerSelector: palette.white,
        background: palette.white,
        nonWorkDayBackground: "#FFDBDB",
    },
    editRequest: {
        containerBackGround: palette.greyLighter,
    },
    appointmentBoardStates: {
        // #30ef24
        greenStatusContainer: "rgb(40 199 111)",
        redStatusContainer: "#fa857d",
        yellowStatusContainer: "#faed7d",
        greyStatusContainer: "#b0aeae",
        orangeStatusContainer: palette.orange,
        disabledContainer: "#8c8c8c",
        greenLigthStatusCode:palette.greenLigth

    },
    requestFilter : {
        backgroundColor: "#E7E5FF",
    }, 
    allCustomerSelector :{
        unregisteredOptionColor:palette.red, 
        headerOptionColor:palette.purple
    }
};


