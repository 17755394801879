import { useEffect } from "react";
import "react-day-picker/dist/style.css";
import { makeUserManager } from "react-oidc";
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "./App.css";
import { EnvirontmentType } from "./common/constants/environtment";
import { getClientSettings } from "./config/userManagerConfig";
import { DefaultRouter } from "./routes/routes";

const userManagerConfig = getClientSettings();
const userManagerOidc = makeUserManager(userManagerConfig);

function App() {
    const router = createBrowserRouter(createRoutesFromElements(DefaultRouter(userManagerOidc)));
    const environment = process.env.REACT_APP_ENVIRONMENT;
       
    useEffect(() => {
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key?.startsWith("oidc.user") && e.newValue === null) {
                window.location.pathname = "/";
                return;
            }

            if (e.key?.startsWith("oidc.user") && e.newValue !== null) {
                window.location.reload();
                return;
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [userManagerOidc]);

 
    useEffect(() => {
        if (environment === EnvirontmentType.DEV || environment === EnvirontmentType.PRE) {
            const meta = document.createElement("meta");
            meta.name = "robots";
            meta.content = "noindex, nofollow";
            document.head.prepend(meta);
        }
    }, [environment]);

    useEffect(() => {
        const handleSilentRenew = (e: any) => {
            const url = new URL(e.detail.href);
            const error = url.searchParams.get("error");

            if (error) {
                userManagerOidc.revokeAccessToken();
                userManagerOidc.removeUser();
                localStorage.clear();
                window.location.pathname = "/";
            } else {
                userManagerOidc.signinSilentCallback(e.detail.toString()).catch((err) => {
                    console.error("Error during silent renew process", err);
                });
            }
        };

        window.addEventListener("oidc-silent-renew-message", handleSilentRenew);

        return () => {
            window.removeEventListener("oidc-silent-renew-message", handleSilentRenew);
        };
    }, [userManagerOidc]);

    return (
        <>
            <RouterProvider router={router} />
            <ToastContainer
                position="top-left"
                autoClose={5000}
                transition={Slide}
                style={{ zIndex: 9999999 }}
            />
        </>
    );
}

export default App;
