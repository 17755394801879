import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IInvoicingUninvoiced } from "../../../../../models";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";

interface IInvoicingUninvoicedColumnFooterProps {
    listColumnsFooter: IColumn[];
    totalAmount: any;
}

export const InvoicingUninvoicedColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "INVOICED.FOOTERTABLE" });
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("transportUnitNumber"),
            minWidth: calcWidthColumFooter(0, 4, props.listColumnsFooter),
            name: compileNameOfProperty<IInvoicingUninvoiced>("transportUnitNumber"),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 4, props.listColumnsFooter)}>{t("TOTALFINAL")}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("requestSort"),
            minWidth: calcWidthColumFooter(5, 5, props.listColumnsFooter),
            name: compileNameOfProperty<IInvoicingUninvoiced>("requestSort"),
            onRender: () => <b style={generateStyleWhithCustomFooter(5, 5, props.listColumnsFooter)}> {props.totalAmount}</b>,
            isResizable: true,
        },
    ];

    return columns;
};
