import { useCallback, useEffect} from "react";

import { fetchCustomerOptions } from "../../../../../redux/actions/user/users/users";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";

import { mapUserAdminDataToDropdownOption } from "../../../../../utils";
import { IBaseMultipleStringSelectorProps } from "../../../../request/common/selectors/IBaseMultipleSelectorProps";
import { MultipleStringSelectorFilter } from "../../../../request/common/selectors/multipleSelectorFilter/MultipleStringSelectorFilter";
import { IComboBoxOption, SelectableOptionMenuItemType } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { AllCustomerSelectorStyle } from "./allCustomerSelectorStyle.jss";
import { useTheme } from "react-jss";

export const AllCustomerSelector: React.FC<IBaseMultipleStringSelectorProps> = ({ selectedKeys, onChange, title, propertyName, isDisabled }) => {
    const { t } = useTranslation("common");
    const theme = useTheme();

    const style = AllCustomerSelectorStyle({ theme });

    const { data, loading } = useAppSelector((store) => store.usersByRole.customerAdmin);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data === undefined && dispatch(fetchCustomerOptions());
    }, [dispatch, data]);

    const mapCustomersOptions = useCallback(
        (filteredText?: string) => {
            let mapArray: IComboBoxOption[] = [];
            let notData = {
                key: "Header3",
                text: t("COMMON.NO_DATA"),
                disabled: true,
                styles: {
                    optionText: style.disabledHeader,
                },
                itemType: SelectableOptionMenuItemType.Normal,
            };

            if (!data) {
                return mapArray;
            }
            mapArray.push({
                key: "Header1",
                text: t("COMMON.REGUISTERED"),
                itemType: SelectableOptionMenuItemType.Header,
                styles: {
                    optionText: style.headerOption,
                },
            });
            let registeredOption = data?.filter((item) => {
                if (!item.unregistered) {
                    if (filteredText && !item.entityData.name.toLocaleLowerCase().includes(filteredText.toLocaleLowerCase())) {
                        return;
                    }
                    return mapArray.push(mapUserAdminDataToDropdownOption(item));
                }
            });
            if (registeredOption.length === 0) {
                mapArray.push(notData);
            }

            mapArray.push({
                key: "Header2",
                text: t("COMMON.UNREGISTERED"),
                itemType: SelectableOptionMenuItemType.Header,
                styles: {
                    optionText: style.headerOption,
                    optionTextWrapper: {
                        background: "red",
                    },
                },
            });
            let unregisteredOption = data?.filter((item) => {
                if (item.unregistered) {
                    if (filteredText && !item.entityData.name.toLocaleLowerCase().includes(filteredText.toLocaleLowerCase())) {
                        return;
                    }
                    return mapArray.push({
                        ...mapUserAdminDataToDropdownOption(item),
                        styles: {
                            optionText: style.unregisteredOptionText,
                        },
                    });
                }
            });
            if (unregisteredOption.length === 0) {
                mapArray.push(notData);
            }

            return mapArray;
        },
        [data],
    );

    return (
        <MultipleStringSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapCustomersOptions()}
            label={title}
            propertyName={propertyName}
            isDisabled={isDisabled || loading}
            customFilteredOptions={mapCustomersOptions}
        />
    );
};
