import { throwErrorToastrAsync } from "../../../../utils";
import {
    fetchManagementAuthorizedTransportUnitNumberList,
    setLoadingManagementAuthorizedTransportUnitNumberList,
} from "../../../reducers/management/authorizedTransportUnitNumber/list";
import { AppThunk, store } from "../../../store";
import { ManagementAuthorizedTransportUnitNumberApi } from "../../../../api/management/authorizedTransportUnitNumber";
import {
    setManagementAuthorizedTransportUnitNumberFormData,
    setManagementAuthorizedTransportUnitNumberFormLoading,
    setShowPopUpAuthorizedTransportUnitNumberForm,
} from "../../../reducers/management/authorizedTransportUnitNumber/form";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

const api = new ManagementAuthorizedTransportUnitNumberApi();

export const fetchManagementAuthorizedTransportUnitNumberListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().managementAuthorizedTransportUnitNumberFilter;

        let customFilter = {
            ...filter,
            transportUnitNumber: filter.transportUnitNumber !== "" ? filter.transportUnitNumber : null,
        };

        dispatch(setLoadingManagementAuthorizedTransportUnitNumberList(true));
        const response = await api.getManagementAuthorizedTransportUnitNumberAsync(customFilter);
        dispatch(fetchManagementAuthorizedTransportUnitNumberList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingManagementAuthorizedTransportUnitNumberList(false));
    }
};

export const postManagementAuthorizedTransportUnitNumberAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().managementAuthorizedTransportUnitNumberForm;

            dispatch(setManagementAuthorizedTransportUnitNumberFormLoading(true));

            if (form.id) {
                await api.putManagementAuthorizedTransportUnitNumberItemAsync(form);
            } else {
                await api.postManagementAuthorizedTransportUnitNumberItemAsync(form);
            }
            dispatch(setShowPopUpAuthorizedTransportUnitNumberForm(PopUpCodes.NONE));
            dispatch(fetchManagementAuthorizedTransportUnitNumberListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setManagementAuthorizedTransportUnitNumberFormLoading(false));
            return false;
        }
    };
};

export const removeManagementAuthorizedTransportUnitNumberAction = (): any => {
    return async (dispatch: any) => {
        const { id } = store.getState().managementAuthorizedTransportUnitNumberForm.form;

        try {
            dispatch(setManagementAuthorizedTransportUnitNumberFormLoading(true));

            await api.removeManagementAuthorizedTransportUnitNumberItemAsync(id ?? 0);
            dispatch(setShowPopUpAuthorizedTransportUnitNumberForm(PopUpCodes.NONE));

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(fetchManagementAuthorizedTransportUnitNumberListAction());
            dispatch(setManagementAuthorizedTransportUnitNumberFormLoading(false));
        }
    };
};

export const fetchManagementAuthorizedTransportUnitNumberAction =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setManagementAuthorizedTransportUnitNumberFormLoading(true));

            const response = await api.getManagementAuthorizedTransportUnitNumberItemAsync(id);
            dispatch(setManagementAuthorizedTransportUnitNumberFormData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setManagementAuthorizedTransportUnitNumberFormLoading(false));
        }
    };
