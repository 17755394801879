import { IColumn } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { IAppointmentDatePermission } from "../../../../../../../models/masterAppointment/IAppointmentDatePermission";
import { fetchMasterAppointmentDatePermission } from "../../../../../../../redux/actions/masterAppointment/appointmentDatePermission/appointmentDatePermission";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { setShowPopUpMasterAppointment } from "../../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterAppointmentDatePermissionColumnsStyle } from "./columnStyle.jss";
import { Separator } from "../../../../../../common/separator/separator";
import { IGenericPayload } from "../../../../../../../models";
import { updateMasterAppointmentDatePermissionProperty } from "../../../../../../../redux/reducers/masterAppointment/appointmentDatePermission/form";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";

export const MasterAppointmentDatePermissionColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterAppointmentDatePermissionColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useAppDispatch();

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("transportUnitDescription"),
            minWidth: 130,
            maxWidth: 170,
            name: t("APPOINTMENT_DATE_PERMISSION.TRANSPORT_UNIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("transportUnitDescription")}
                    title={t("APPOINTMENT_DATE_PERMISSION.TRANSPORT_UNIT")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.transportUnitDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("transportUnitSizeDescription"),
            minWidth: 100,
            maxWidth: 110,
            name: t("APPOINTMENT_DATE_PERMISSION.TRANSPORT_SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("transportUnitSizeDescription")}
                    title={t("APPOINTMENT_DATE_PERMISSION.TRANSPORT_SIZE")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.transportUnitSizeDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("merchandiseCategoryGroupDescription"),
            minWidth: 160,
            maxWidth: 200,
            name: t("APPOINTMENT_DATE_PERMISSION.MERCHANDISE_CATEGORY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("merchandiseCategoryGroupDescription")}
                    title={t("APPOINTMENT_DATE_PERMISSION.MERCHANDISE_CATEGORY")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.merchandiseCategoryGroupDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("dayDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("APPOINTMENT_DATE_PERMISSION.DAY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("dayDescription")}
                    title={t("APPOINTMENT_DATE_PERMISSION.DAY")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.dayDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("startTime"),
            minWidth: 60,
            maxWidth: 100,
            name: t("APPOINTMENT_DATE_PERMISSION.START_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("startTime")}
                    title={t("APPOINTMENT_DATE_PERMISSION.START_TIME")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.startTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IAppointmentDatePermission>("endTime"),
            minWidth: 60,
            maxWidth: 100,
            name: t("APPOINTMENT_DATE_PERMISSION.END_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("endTime")}
                    title={t("APPOINTMENT_DATE_PERMISSION.END_TIME")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.endTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("manual"),
            minWidth: 60,
            maxWidth: 100,
            name: t("APPOINTMENT_DATE_PERMISSION.MANUAL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("manual")}
                    title={t("APPOINTMENT_DATE_PERMISSION.MANUAL")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => (
                <span>
                    {" "}
                    <BooleanValue value={item.manual} />{" "}
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IAppointmentDatePermission>("workDays"),
            minWidth: 100,
            maxWidth: 200,
            name: t("APPOINTMENT_DATE_PERMISSION.WORK_DAY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("workDays")}
                    title={t("APPOINTMENT_DATE_PERMISSION.WORK_DAY")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => (
                <span>
                    {" "}
                    <BooleanValue value={item.workDays} />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentDatePermission>("applicationDay"),
            minWidth: 100,
            maxWidth: 200,
            name: t("APPOINTMENT_DATE_PERMISSION.APLICATION_DAYS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentDatePermission>("applicationDay")}
                    title={t("APPOINTMENT_DATE_PERMISSION.APLICATION_DAYS")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => <span>{item.applicationDay ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentDatePermission) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            if (item.id) {
                                dispatch(fetchMasterAppointmentDatePermission(item.id)).then(() =>
                                    dispatch(setShowPopUpMasterAppointment(PopUpCodes.UPDATE)),
                                );
                            }
                        }}
                    />

                    <Separator size="auto"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(setShowPopUpMasterAppointment(PopUpCodes.DELETE));
                            const payload: IGenericPayload = {
                                name: compileNameOfProperty<IAppointmentDatePermission>("id"),
                                value: item.id,
                            };
                            dispatch(updateMasterAppointmentDatePermissionProperty(payload));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
