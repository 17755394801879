import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVlcportHistoricList } from "../../../../../models/vlcPort/IHistoricRequest";

interface IVlcportHistoricListState {
    list?: IVlcportHistoricList[];
    loading: boolean;
}

const initialState: IVlcportHistoricListState = {
    loading: false,
};

export const vlcportHistoricPositioningRequestListReducer = createSlice({
    name: "vlcportHistoricPositioningRequestListReducer",
    initialState,
    reducers: {
        resetVlcportHistoricPositioningRequestList: () => initialState,
        setVlcportHistoricPositioningRequestList: (state, action: PayloadAction<IVlcportHistoricList[]>) => {
            state.list = action.payload;
        },
        setVlcportHistoricPositioningRequestLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetVlcportHistoricPositioningRequestList, setVlcportHistoricPositioningRequestList, setVlcportHistoricPositioningRequestLoading } =
    vlcportHistoricPositioningRequestListReducer.actions;

export default vlcportHistoricPositioningRequestListReducer.reducer;
