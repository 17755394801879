import { Method } from "../../../common/constants";
import { IDockQueueConfiguration, IDockQueueRule, IDockQueueRuleComponent, IDockQueueRuleFormData } from "../../../models/management/dockQueueRule/IDockQueueRule";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";





const baseURL = `${process.env.REACT_APP_API_URL}/dockqueue`;


export class ManagementDockQueueRuleApi { 
    async getAllRules(): Promise<IDockQueueRule[]> {
        const url = `${baseURL}/rules/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IDockQueueRule[]>();
    }
    
    async getConfiguration(): Promise<IDockQueueConfiguration> {
        const url = `${baseURL}/configuration`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IDockQueueConfiguration>();
    }    
    async updateDockQueueRuleAsync(dockQueueRuleFormData: IDockQueueRuleFormData): Promise<boolean> {
        const url = `${baseURL}/rule`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody(dockQueueRuleFormData).execute<boolean>();
    }
    async createDockQueueRuleAsync(dockQueueRuleFormData: IDockQueueRuleFormData): Promise<boolean> {
        const url = `${baseURL}/rule`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(dockQueueRuleFormData).execute<boolean>();
    }
    async deleteDockQueueRule(id: number | null): Promise<boolean> {
        const url = `${baseURL}/rule/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute();
    }    
    async getDockQueueRuleRuleAsync(id: number): Promise<IDockQueueRuleFormData> {
        const url = `${baseURL}/rule/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IDockQueueRuleFormData>();
    }
}
