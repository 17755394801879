import { IDropdownOption } from "@fluentui/react";
import { useEffect } from "react";
import { fetchCarrierOptions } from "../../../../../redux/actions/user/users/users";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { ControlledAutoComplete } from "../../../../common/autocomplete/controlledAutocomplete";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const CarrierSelector: React.FC<IBaseSelectorProps> = ({
    onChange, 
    selectedKey, 
    propertyName, 
    title, 
    disabled, 
    isRequired, 
    control, 
    rules
}) => {
    const { options, loading } = useAppSelector((store) => store.usersByRole.carrierAdmin);
    const dispatch = useAppDispatch();

    useEffect(() => {
        options.length === 0 && dispatch(fetchCarrierOptions());
    }, [dispatch, options]);

    const onAutoCompleteChange = (option: IDropdownOption, name: string) => {
        onChange(option, name);
    };
    return (
            <ControlledAutoComplete
                name={propertyName}
                onChange={onAutoCompleteChange}
                options={options}
                disabled={loading || disabled}
                selectedKey={selectedKey}
                title={title}
                isRequired={isRequired}
                control={control}
                rules={rules}
            />
    );
};
