import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ButtonPrimary, ButtonPrimaryInverse } from "../../../buttons/buttons";
import { CustomPopUpButtonActionsStyle } from "./buttonActionsStyle.jss";

interface IPopUpFormButtonActionsProps {
    onSave: (value: any) => any;
    onCancel: (value: any) => any;
    leftButtonContents?: any;
    disabledButton?: boolean;
    buttonSaveTitle?: string;
    buttonCancelTitle?: string;
    isLoading?: boolean;
    hiddenCancel?: boolean;
    hiddenSave?: boolean;
    customActions?: any;
}

export const PopUpFormButtonActions: React.FC<IPopUpFormButtonActionsProps> = ({
    onSave,
    onCancel,
    leftButtonContents,
    disabledButton,
    buttonSaveTitle,
    buttonCancelTitle,
    isLoading,
    hiddenCancel,
    hiddenSave,
    customActions,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "POPUPACTIONS" });
    const cancel = buttonCancelTitle ? buttonCancelTitle : t("CANCEL");
    const save = buttonSaveTitle ? buttonSaveTitle : t("SAVE");
    const theme = useTheme();
    const style = CustomPopUpButtonActionsStyle({ theme, customActions : customActions });

    return (
        <div className={style.generalContainerButtons}>
            {leftButtonContents && <div className={style.sectionContainer}>{leftButtonContents}</div>}
            <div className={style.sectionContainerButtons}>
                {customActions && customActions}
                {!hiddenCancel && (
                    <ButtonPrimaryInverse
                        title={cancel}
                        handleClick={onCancel}
                        disabled={isLoading}
                    />
                )}
                {!hiddenSave && (
                    <ButtonPrimary
                        title={save}
                        handleClick={onSave}
                        disabled={disabledButton || isLoading}
                        loading={isLoading}
                    />
                )}
            </div>
        </div>
    );
};
