import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload, IUserManaged } from "../../../../../../models";
import {
    updateProfileUser,
    setManagementUserRegisteredAction,
    setManagementUserUnregisteredAction,
    getMananagementUserAction,
} from "../../../../../../redux/actions/management/user/user";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { updateManagementUserProperty } from "../../../../../../redux/reducers/management/user/form";
import { heights } from "../../../../../../styles/romeu/heights";
import { widths } from "../../../../../../styles/romeu/widths";
import { getGenericPayloadFromEvent } from "../../../../../../utils";
import { RegisteredAndUnregisteredActions } from "../../../../../common/formUtils/registeredAndUnregistered";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { UserProfileEntityManageUsersListItem } from "./item/managedUserItem";
import { ManageUsersListStyle } from "./listStyle.jss";
import { ManagementUserDefaultForm } from "../../../../../management/user/form/default/defaultForm";

interface IManagementEntityUserListProps {
    users: IUserManaged[];
}

export const ManagementEntityUserList: React.FC<IManagementEntityUserListProps> = ({ users }) => {
    const theme = useTheme();
    const style = ManageUsersListStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE.ENTITY" });
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => state.userData.profile.email);
    const [showEditUserPerfilForm, setShowEditUserPerfilForm] = useState(false);
    const [validEditUserPerfilForm, setValidEditUserPerfilForm] = useState<boolean>(false);
    const { loading, generalUserData } = useAppSelector((store) => store.managementUserForm);

    const updatePropertyForm = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateManagementUserProperty(payload));
    };

    const onSelectorChange = (event: any, option: any, name: string) => {
        updatePropertyForm(name, option.key);
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );

    const onEditUserPerfilForm = (user: IUserManaged) => {
        setShowEditUserPerfilForm(true);
        dispatch(getMananagementUserAction(user.id));
    };
    const onCancelEditUserPerfilForm = () => {
        setShowEditUserPerfilForm(false);
    };

    const onSaveEditUserPerfilForm = () => {
        dispatch(updateProfileUser(generalUserData)).then((response: any) => {
            response && onCancelEditUserPerfilForm();
        });
    };

    const onUnregisterUser = () => {
        if (generalUserData.id) {
            dispatch(setManagementUserUnregisteredAction(generalUserData.id));
        }
    };

    const onRegisterUser = () => {
        if (generalUserData.id) {
            dispatch(setManagementUserRegisteredAction(generalUserData.id));
        }
    };

    return (
        <>
            <PopUpForm
                content={
                    <ManagementUserDefaultForm
                        onSelectorChange={onSelectorChange}
                        onInputChange={onInputChange}
                        editMode={true}
                        setIsAvailableSave={setValidEditUserPerfilForm}
                        onCheckBoxChange={onInputChange}
                    />
                }
                title={t("EDIT_USER")}
                isVisible={showEditUserPerfilForm}
                isDisabledButton={!validEditUserPerfilForm}
                onCancel={onCancelEditUserPerfilForm}
                onSave={onSaveEditUserPerfilForm}
                width={widths.management.user.userForm.width}
                height={heights.management.user.userForm.height}
                isLoading={loading}
                leftButtonContent={
                    <RegisteredAndUnregisteredActions
                        onRegisteredUser={onRegisterUser}
                        onUnregisteredUser={onUnregisterUser}
                        visibleRequiredMessage={true} disabled={loading}                    />
                }
            />

            <div className={style.userListContainer}>
                {users.map((user, index) => {
                    return (
                        <UserProfileEntityManageUsersListItem
                            key={`${UserProfileEntityManageUsersListItem.name}_${index}`}
                            user={user}
                            onClickEditButton={() => onEditUserPerfilForm(user)}
                            showEditButton={user.id !== userId}
                        />
                    );
                })}
            </div>
        </>
    );
};
