export enum RoleType {
    DIRECTOR = "Director",
    CUSTOMER_ADMIN = "CustomerAdmin",
    CUSTOMER_USER = "CustomerUser",
    ADMINISTRATIVE = "Administrative",
    AGENT_ADMIN = "AgentAdmin",
    AGENT_USER = "AgentUser",
    AREA_DIRECTOR = "AreaDirector",
    CARRIER_ADMIN = "CarrierAdmin",
    CARRIER_USER = "CarrierUser",
    DOCK = "Dock",
    LEADERSHIP = "Leadership",
    ORGANISM_ADMIN = "OrganismAdmin",
    ORGANISM_TECHNICIAN = "OrganismTechnician",
    ORGANISM_INSPECTOR = "OrganismInspector",
    PORT_LECTOR_ADMIN = "PortLectorAdmin",
    PORT_LECTOR_USER = "PortLectorUser",
    STAND = "Stand",
    SUPER_ADMIN = "SuperAdmin",
    TEAM_LEADER = "TeamLeader",
    TERMINAL_ADMIN = "TerminalAdmin",
    TERMINAL_USER = "TerminalUser",
    MAPA_DIRECTOR = "MapaDirector",
}
