import React from "react";
import { IChartOption, IChartSeries } from "../../../models/common/IChartOptions";
import Chart from "react-apexcharts";

interface IChartProps {
    options: IChartOption & any;
    series: IChartSeries & any;
    type: "bar";
    height: number | string;
}

export const Charts: React.FC<IChartProps> = ({ type, options, series, height }) => {
    return (
        <Chart
            options={options}
            series={series}
            type={type}
            height={height}
        />
    );
};
