import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SkeletonSizes } from "../../../../common/constants";

import { IPagination } from "../../../../models/common/IPagination";
import { fetchManagementNotificationListAction, setPageAction } from "../../../../redux/actions/management/notification/notication";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getUserClaimsLoaded } from "../../../../redux/selectors/user/claims/claims";
import { heights } from "../../../../styles/romeu/heights";
import { sortAndPaginateArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementNotificationListColumn } from "./column/column";

export const ManagementNotificationList = () => {
    const { t } = useTranslation("common", {keyPrefix:"MANAGEMENT.NOTIFICATION"});
    const dispatch = useAppDispatch();
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);
    const { list, sort, pagination, loading } = useAppSelector((state) => state.managementNotificationList);

    useEffect(() => {
        isUserClaimsLoaded && !loading && dispatch(fetchManagementNotificationListAction());
    }, [dispatch, isUserClaimsLoaded]);

    const currentList = sortAndPaginateArray(list, sort, pagination);

    const onPageUp = useCallback(
        (skip: number) => {
            const currentPage: number = skip + 1;
            if (currentPage <= pagination.totalPages) {
                dispatch(setPageAction(currentPage));
            }
        },
        [pagination.totalPages],
    );

    const onFirstPage = useCallback((skip: number) => {
        if (skip !== 1) {
            dispatch(setPageAction(1));
        }
    }, []);

    const onPageDown = useCallback((skip: number) => {
        const currentPage: number = skip - 1;
        if (currentPage >= 1) {
            dispatch(setPageAction(currentPage));
        }
    }, []);

    const onLastPage = useCallback(
        (skip: number) => {
            if (skip !== pagination.totalPages) {
                dispatch(setPageAction(pagination.totalPages));
            }
        },
        [pagination.totalPages],
    );

    const renderColumns = () => {
        return ManagementNotificationListColumn();
    };

    const paginationProps: IPagination = {
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
        firstItemNumber: pagination.firstItemNumber,
        lastItemNumber: pagination.lastItemNumber,
        footerTitle: t("PAGINATION_FOOTER", {
            firstItemNumber: pagination.firstItemNumber,
            lastItemNumber: pagination.lastItemNumber,
            totalItems: pagination.totalItems,
        }),
        onFirstPage,
        onLastPage,
        onPageDown,
        onPageUp,
    };

    return (
        <>
            {loading || !isUserClaimsLoaded ? (
                <Skeleton rows={SkeletonSizes.MANAGEMENT_USER} />
            ) : (
                <CustomDetailsList
                    data={currentList}
                    pagination={paginationProps}
                    renderColumns={renderColumns}
                    height={heights.management.tables}
                />
            )}
        </>
    );
};
