import { useTheme } from "react-jss";
import { TitleHeader } from "../../../common/headerTitle/headerTitle";
import { OrganizationSelector } from "../../../common/selectors/organizationSelector/organizationSelector";
import { Separator } from "../../../common/separator/separator";
import { UserContextMenu } from "../../../user/contextMenu/contextMenu";
import { TemplateHeaderStyles } from "./header.jss";
import { useMediaQuery } from "react-responsive";
import { EnvironmentDescription } from "../../../common/environment/environment";
import { CustomIcons } from "../../../common/customIcons/customIcons";
import { useDispatch } from "react-redux";
import { setShowSiteNav } from "../../../../redux/reducers/webConfig/globalConfig";
import { useCallback } from "react";
import { useAppSelector } from "../../../../redux/hooks";

interface TemplateHeaderProps {
    title?: string;
    featureId?: string;
    background?: string;
}

export const TemplateHeader = (props: TemplateHeaderProps) => {
    const theme = useTheme() as any;
    const dispatch = useDispatch();
    const styles = TemplateHeaderStyles({ theme, background: props.background });
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });
    const { showSiteNav } = useAppSelector((state) => state.globalConfig);

    const showMobileMenu = useCallback(() => {
        dispatch(setShowSiteNav(!showSiteNav));
    }, [showSiteNav]);
    return (
        <>
            <header className={styles.siteHeader}>
                <div className={styles.menu}>
                    <CustomIcons
                        iconName="GlobalNavButton"
                        size={30}
                        onClick={showMobileMenu}
                    />
                </div>
                <div className={styles.title}>
                    <TitleHeader
                        title={props.title}
                        featureId={props.featureId}
                    />
                </div>
                {!isMobile && (
                    <>
                        <Separator />
                        <div className={styles.selectorContainer}>
                            <OrganizationSelector />
                        </div>
                    </>
                )}
                <div className={styles.userInfo}>
                    <EnvironmentDescription />
                    <UserContextMenu />
                </div>
            </header>
        </>
    );
};
