import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { IGenericPayload } from "../../../../../models";
import { IBoardHistoryAppointmentDock } from "../../../../../models/masterAppointment/IAppointmentBoard";

export interface IAppointmentDockFormState {
    form: IBoardHistoryAppointmentDock;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmentDockFormState = {
    form: {
        id: 0,
        code: "",
        description: "",
        active: true,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const masterAppointmentDockForm = createSlice({
    name: "masterAppointmentDockForm",
    initialState,
    reducers: {
        resetMasterAppointmentDockForm: () => initialState,
        setLoadingMasterAppointmentDockForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentDockForm: (state, action: PayloadAction<IBoardHistoryAppointmentDock>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentDockProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setShowPopUpAppointmentDock: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetMasterAppointmentDockForm,
    setLoadingMasterAppointmentDockForm,
    setMasterAppointmentDockForm,
    updateMasterAppointmentDockProperty,
    setShowPopUpAppointmentDock,
} = masterAppointmentDockForm.actions;

export default masterAppointmentDockForm.reducer;
