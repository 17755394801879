import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterActuationGroup } from "../../../../../models/resources/master/IActuationGroup";

export interface IMasterActuationGroupFormState {
    form: IMasterActuationGroup;
    loading: boolean;
}

const initialState: IMasterActuationGroupFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterActuationGroupeForm = createSlice({
    name: "masterActuationGroupeForm",
    initialState,
    reducers: {
        resetMasterActuationGroupState: () => initialState,
        resetMasterActuationGroupForm: (state) => {
            state.form = initialState.form;
        },
        setLoadingMasterActuationGroupForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterActuationGroupForm: (state, action: PayloadAction<IMasterActuationGroup>) => {
            state.form = action.payload;
        },
        updateMasterActuationGroupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterActuationGroupState, setLoadingMasterActuationGroupForm, setMasterActuationGroupForm, updateMasterActuationGroupProperty,resetMasterActuationGroupForm } =
    masterActuationGroupeForm.actions;

export default masterActuationGroupeForm.reducer;
