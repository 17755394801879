import { PaymentMethod } from "../../common/enum/PaymentMethod";
import { UserType } from "../../common/enum/UserType";
import { IAdminData } from "./IAdminData";
import { IEntityData } from "./IEntityData";
import { IPaymentData } from "./IPaymentData";
import { IRequestedDocument } from "./IRequestedDocument";


export interface IUserSignupAPI {
    userType: UserType;
    userPositionType: string;
    name: string;
    surName: string;
    lastName: string;
    email: string;
    organizationId: string;
    avatar: IRequestedDocument;
    phoneNumber: string;
    documents: string[];
    entityData: string;
    paymentData: string;
    adminData: string;
    operatorData: string;
    image: string;
    documentTypes: string;
}

export interface IUserSignup {
    userType: UserType;
    userPositionType: string;
    name: string;
    surName: string;
    lastName: string;
    email: string;
    organizationId: string;
    avatar: IRequestedDocument;
    phoneNumber: string;
    documents: IRequestedDocument[];
    entityData: IEntityData;
    paymentData: IPaymentData;
    adminData: IAdminData;
    operatorData: IAdminData;
    acceptedTermsAndConditions: boolean
}

export const emptyUserRegisterModel = (): IUserSignup => {
    return {
        userType: UserType.NOT_MAPPED,
        userPositionType: "",
        name: "",
        surName: "",
        lastName: "",
        documents: [],
        email: "",
        phoneNumber: "",
        organizationId: "",
        avatar: {
            documentId: 0,
            file: null,
            index: 0,
            documentName: "",
        },
        entityData: {
            documentNumber: "",
            city: "",
            name: "",
            postalCode: "",
            province: "",
            socialAddress: "",
        },
        paymentData: {
            paymentMethod: PaymentMethod.BANK_DRAFT,
            accountNumber: "",
            bankEntity: "",
        },
        adminData: {
            mobilePhone: "",
            phone: "",
            emails: [
                {
                    email: "",
                    isValid: true,
                },
            ],
        },
        operatorData: {
            mobilePhone: "",
            phone: "",
            emails: [
                {
                    email: "",
                    isValid: true,
                },
            ],
        },
        acceptedTermsAndConditions: false
    };
};
