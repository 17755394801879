import React, { useEffect } from "react";
import { fetchValuedTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapValuedTypesToDropDownOptions } from "../../../../../utils";
import { StringMultiSelector } from "../../../../common/selectors";
import { IBaseMultipleStringSelectorProps } from "../IBaseMultipleSelectorProps";
import { MultipleStringSelectorFilter } from "../multipleSelectorFilter/MultipleStringSelectorFilter";
interface IMultipleValuedTypesSelector extends IBaseMultipleStringSelectorProps {
    autocomplete: boolean;
}

export const MultipleValuedTypesSelector: React.FC<IMultipleValuedTypesSelector> = ({
    autocomplete,
    onChange,
    propertyName,
    title,
    selectedKeys,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.valuedTypes);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data === undefined && dispatch(fetchValuedTypes());
    }, [dispatch, data]);

    return autocomplete ? (
        <MultipleStringSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapValuedTypesToDropDownOptions(data ?? [])}
            label={title}
            propertyName={propertyName}
        ></MultipleStringSelectorFilter>
    ) : (
        <StringMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapValuedTypesToDropDownOptions(data ??[])}
            disabled={loading}
            title={title}
            name={propertyName}
        />
    );
};
