import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { fetchMasterRequestStatesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterRequestStatesForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { resetMasterRequestStatesForm } from "../../../../../../redux/reducers/resources/master/requestStates/form";
import { setShowPopUpMaster } from "../../../../../../redux/reducers/resources/master/master";
import { postToMasterRequestStateForm } from "../../../../../../redux/actions/resources/master/requestStates/requestStates";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterRequestStatesFormController {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterRequestStatesFormController: React.FC<IMasterRequestStatesFormController> = ({ widthPopUp, heigthPopUp }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { form, loading } = useAppSelector((state) => state.masterRequestStatesForm);
    const { data } = useAppSelector((state) => state.catalog.requestStatesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postToMasterRequestStateForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(setShowPopUpMaster(PopUpCodes.NONE));
    };
    const onDimissed = () => {
        dispatch(resetMasterRequestStatesForm());
    };
    const showNotData = useMemo(() => {
        return !data.length ? true : false;
    }, [data]);

    const titlePopUp = useMemo(() => {
        let title = "";
        if (showPopUp === PopUpCodes.CREATE) {
            title = t("CREATE") + " " + t("BUTTON.REQUEST_STATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            title = t("UPDATE") + " " + t("BUTTON.REQUEST_STATE");
        }
        return title;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE, ["description"]);
    }, [form]);
    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchMasterRequestStatesRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterRequestStatesForm
                    showNotData={showNotData}
                    data={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            height={heigthPopUp}
            width={widthPopUp}
            onDimissed={onDimissed}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onlyHidden={showPopUp === PopUpCodes.CREATE ? showNotData : false}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
