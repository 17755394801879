import React from "react";
import { store } from "../../../redux/store";
import { CustomersImportContent } from "./content/container";
import { resetCarrierImport } from "../../../redux/reducers/resources/carrierImport/import";

export class CarrierImportContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetCarrierImport());
    }

    render(): React.ReactNode {
        return <CustomersImportContent />;
    }
}
