import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IUserModuleData } from "../../../../models/user/IUserModuleData";

export const moduleAdapter = createEntityAdapter<IUserModuleData>({});

export const moduleSlice = createSlice({
    name: "modules",
    initialState: moduleAdapter.getInitialState({
        isLoading: false,
        hasErrors: false,
        errorMessage: "",
        modules: [] as IUserModuleData[], 
        feature:[]
    }),
    reducers: {
        setModules: (state, action) => {
            state.modules = action.payload;
        },
        loadingModules: (state, action) => {
            state.isLoading = action.payload;
        }, 
        setFeature:(state, action) => {
            state.feature = action.payload;
        },
    }
});

export const { setModules, loadingModules, setFeature } = moduleSlice.actions;

export default moduleSlice.reducer;
