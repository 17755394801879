import { createUseStyles } from "react-jss";

export const RequestListStyle = createUseStyles((theme: any) => ({
    requestContainer: {
        height: "99%",
    },
    createRequestContainer: {
        padding: "32px 32px 0 32px",
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        columnGap: 24,
    },
    cardList: {
        height: "calc(100vh - 355px)",
        overflow: "auto",
    },
}));
