import { createUseStyles } from "react-jss";

export const ManagementRepresentationDefaultFormStyle = createUseStyles((theme: any) => ({
    containerForm: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.xl,
        width: "100%",
        marginTop: 20,
    },
    rowForm: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.gaps.sizes.xl,
        "&  > div": {
            width: "45%",
            padding: 0,
        },
    },
    discharged: {
        color: theme.colors.messageAlert.warning.color,
        fontSize: theme.fonts.sizes.base,
    },
}));
