import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IConfigOrganismCertificateType } from "../../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

export interface IConfigOrganismCertificateTypeFormState {
    form: IConfigOrganismCertificateType;
    loading: boolean;
    showPopUp?: PopUpCodes;
}

const initialState: IConfigOrganismCertificateTypeFormState = {
    form: {
        automaticExpedient: false,
        automaticCertificateNumber: false,
    },
    showPopUp: undefined,
    loading: false,
};

export const configOrganismCertificateTypeForm = createSlice({
    name: "masterActuationTypeForm",
    initialState,
    reducers: {
        resetConfigOrganismCertificateTypeForm: () => initialState,
        setLoadingConfigOrganismCertificateTypeForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setPopUpConfigOrganismCertificateTypeForm: (state, action: PayloadAction<PopUpCodes | undefined>) => {
            state.showPopUp = action.payload;
        },
        setConfigOrganismCertificateTypeForm: (state, action: PayloadAction<IConfigOrganismCertificateType>) => {
            state.form = action.payload;
        },
        updateConfigOrganismCertificateTypeProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetConfigOrganismCertificateTypeForm,
    setPopUpConfigOrganismCertificateTypeForm,
    setConfigOrganismCertificateTypeForm,
    setLoadingConfigOrganismCertificateTypeForm,
    updateConfigOrganismCertificateTypeProperty,
} = configOrganismCertificateTypeForm.actions;

export default configOrganismCertificateTypeForm.reducer;
