import { createUseStyles } from "react-jss";

export const SiteNavItemStyles = createUseStyles((theme: any) => ({
    item: {
        display: "block",
        padding: theme.paddings.siteNav.item,
        borderRadius: theme.borders.siteNav.item.radius,
        fontSize: theme.fonts.sizes.sm,
        fontWeight: theme.fonts.weight.lighter,
        textDecoration: "none",
        color: theme.colors.siteNav.item.color,

        "&:hover": {
            color: theme.colors.siteNav.itemHover.color + " !important"
        },

        "&:visited": {
            color: theme.colors.siteNav.item.color,
        },
    },
    itemActive: {
        display: "block",
        padding: theme.paddings.siteNav.item,
        borderRadius: theme.borders.siteNav.item.radius,
        fontSize: theme.fonts.sizes.sm,
        fontWeight: theme.fonts.weight.bold,
        textDecoration: "none",
        color: theme.colors.siteNav.itemActive.color,
        background: theme.colors.siteNav.itemActive.background
    }
}));
