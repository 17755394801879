import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import common from "../translations/es/common.json";
import messages from "../translations/es/messages.json";
import timeline from "../translations/es/timeLine.json";
import cookiesection from "../translations/es/cookieSection.json";

export const resources = {
    es: {
        common,
        messages,
        timeline,
        cookiesection
    },
} as const;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "es",
        ns: ["common"],
        defaultNS: "common",
        resources: {
            es: {
                common: common,
                messages: messages,
                timeline: timeline,
                cookiesection:cookiesection
            },
        },
        debug: false,
        detection: {
            order: ["querystring", "cookie", "localStorage", "navigator"],
            lookupQuerystring: "lng",
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",
            caches: ["localStorage", "cookie"],
        },
    });

export default i18n;
