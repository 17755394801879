import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { UpcomingAppointmentsSortColumns } from "./column/column";
import { fetchRequestAppointmentsUpcomingSortListAction } from "../../../../redux/actions/appointments/upcomingAppointmentsSort";
import { routeFeatures } from "../../../../common/constants/routeFeatures";

export const UpcomingAppointmentsSortList = () => {
    const dispatch = useAppDispatch();
    const { loading, list } = useAppSelector((store) => store.upcomingAppointmentsSortList);

    const onRenderColumns = () => {
        return UpcomingAppointmentsSortColumns(routeFeatures.UPCOMING_APPOINTMENTS_SORT);
    };
    useEffect(() => {
        list === undefined && dispatch(fetchRequestAppointmentsUpcomingSortListAction());
    }, [list]);

    return loading ? (
        <Skeleton rows={3}></Skeleton>
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={onRenderColumns}
            height="calc(100vh - 16.5rem)"
        />
    );
};
