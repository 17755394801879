import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IParkingValidationRulesList } from "../../../../models/management/parkingValidationRule/IParkingValidationRule";

interface ManagementParkingValidationRuleListState {
    list?: IParkingValidationRulesList[];
    loading: boolean;
}

const initialState: ManagementParkingValidationRuleListState = {
    list: undefined,
    loading: false,
};

export const managementParkingValidationRuleListSlice = createSlice({
    name: "managementParkingValidationRuleList",
    initialState,
    reducers: {
        resetManagementParkingValidationRuleList: () => initialState,

        setLoadingManagementParkingValidationRuleList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementParkingValidationRuleList: (state, action: PayloadAction<IParkingValidationRulesList[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetManagementParkingValidationRuleList, setLoadingManagementParkingValidationRuleList, fetchManagementParkingValidationRuleList } =
    managementParkingValidationRuleListSlice.actions;

export default managementParkingValidationRuleListSlice.reducer;
