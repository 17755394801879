import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { emptyStatisticsFilter, IStatisticsFilter } from "../../../../models/statistics/IStatisticsFilters";

interface IStatiticsCustomerFilterState {
    filter: IStatisticsFilter;
}

const initialState: IStatiticsCustomerFilterState = {
    filter: emptyStatisticsFilter(),
};

export const statisticsCustomerFilterSlice = createSlice({
    name: "statisticsCustomerFilterSlice",
    initialState,
    reducers: {
        resetStatisticsCustomerFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetStatisticsCustomerFilter,
    updateFilterProperty,
} = statisticsCustomerFilterSlice.actions;

export default statisticsCustomerFilterSlice.reducer;
