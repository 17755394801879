import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterFinalDestinationType } from "../../../../../models/resources/master/IFinalDestinationType";

export interface IMasterFinalDestinationTypesState {
    list?: IMasterFinalDestinationType[];
    loading: boolean;
}

const initialState: IMasterFinalDestinationTypesState = {
    loading: false,
};

export const masterFinalDestinationTypesList = createSlice({
    name: "masterFinalDestinationTypesList",
    initialState,
    reducers: {
        resetMasterFinalDestinationTypesList: () => initialState,
        setLoadingFinalDestinationTypesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterFinalDestinationTypesList: (state, action: PayloadAction<IMasterFinalDestinationType[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterFinalDestinationTypesList, setLoadingFinalDestinationTypesList, setMasterFinalDestinationTypesList } =
    masterFinalDestinationTypesList.actions;

export default masterFinalDestinationTypesList.reducer;
