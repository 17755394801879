import { useBoolean } from "@fluentui/react-hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload, RequestStateSort } from "../../../../../../models";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
import { fetchRequestStates } from "../../../../../../redux/actions/catalog/catalog";
import { updateRequestFormData, updateRequestStateId } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { setInspections } from "../../../../../../redux/reducers/request/edit/editRequest";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { DockAssigner } from "../dockAssigner/dockAssigner";
import { StateChangerStyles } from "./stateChanger.jss";
import { SealTypes } from "../../../../../../common/enum/SealType";
import { OrganizationTypes } from "../../../../../../common/enum";
import { throwErrorToastrAsync } from "../../../../../../utils";

interface IProps {
    schema: IConfigEditRequest;
}

export const StateChanger: React.FC<IProps> = ({ schema }) => {
    const theme = useTheme();
    const styles = StateChangerStyles({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();
    const {
        editRequest: { headerRequest: request },
        catalog: {
            requestStates: { data: requestStates },
        },
        organization: {
            organization: { id: currentOrganization },
        },
    } = useAppSelector((store) => store);

    useEffect(() => {
        requestStates === undefined && dispatch(fetchRequestStates());
    }, [dispatch, requestStates]);

    const currentRequestState = () => {
        if (request.stateId && requestStates) {
            return requestStates.find((obj) => {
                return obj.id === request.stateId;
            });
        }

        return undefined;
    };

    const nextRequestState = () => {
        if (currentRequestState() && requestStates) {
            return requestStates.find((obj) => {
                return obj.sort > currentRequestState()!.sort && obj.main;
            });
        }

        return undefined;
    };

    const [statesVisible, { toggle: onToggleStatesVisible }] = useBoolean(false);
    const [otherStatesVisible, { toggle: onToggleOtherStatesVisible }] = useBoolean(false);

    const onToggleStates = () => {
        if (!schema.stateId.readonly) {
            onToggleStatesVisible();

            if (otherStatesVisible) {
                onToggleOtherStatesVisible();
            }
        }
    };

    const onUpdateRequestState = (id: number, sort?: number, nextSort?: number) => {
        const payload: IGenericPayload = { name: "stateId", value: id };

        if (
            currentOrganization === OrganizationTypes.VLC &&
            schema.originSeal.required &&
            request.seals.filter((seal) => seal.typeId === SealTypes.ORIGIN).length === 0
        ) {
            const error: Error = {
                message: t("INFO.ORIGIN_SEALS_REQUIRED"),
                name: "",
            };
            return throwErrorToastrAsync(error);
        }

        onToggleStates();

        dispatch(setInspections([]));

        dispatch(updateRequestFormData(payload));
        dispatch(updateRequestStateId(request, id));
    };

    return (
        <>
            {schema.stateId.visible && (
                <div className={styles.container}>
                    {currentRequestState() && (
                        <>
                            <div
                                className={styles.selector}
                                onClick={onToggleStates}
                            >
                                <CustomIcons
                                    margin={"0px"}
                                    iconName="FlagIcon"
                                ></CustomIcons>
                                <div className={styles.currentState}>{currentRequestState()?.description}</div>
                                {!schema.stateId.readonly && (
                                    <CustomIcons
                                        margin={"0px"}
                                        className={statesVisible ? "arrowUp" : "arrow"}
                                        iconName="ChevronDownIcon"
                                    ></CustomIcons>
                                )}
                            </div>
                            {statesVisible && (
                                <div className={styles.actionMenu}>
                                    <div className="title">{t("STATECHANGER.CHANGE_TO_STATE")}</div>

                                    {nextRequestState() && (
                                        <>
                                            <div
                                                className="button secondary"
                                                onClick={() =>
                                                    onUpdateRequestState(nextRequestState()!.id, request.stateSort, nextRequestState()!.sort)
                                                }
                                            >
                                                <CustomIcons
                                                    margin={"0px"}
                                                    iconName="FlagIcon"
                                                />
                                                {nextRequestState()?.description}
                                            </div>

                                            <div className="separator"></div>
                                        </>
                                    )}

                                    <div className="otherStates">
                                        <div
                                            className="selector"
                                            onClick={onToggleOtherStatesVisible}
                                        >
                                            <CustomIcons
                                                margin={"0px"}
                                                iconName="FlagIcon"
                                            ></CustomIcons>

                                            <div>Otro</div>
                                            <CustomIcons
                                                className={otherStatesVisible ? "arrowUp" : "arrow"}
                                                iconName="ChevronDownIcon"
                                                margin={"0px"}
                                            ></CustomIcons>
                                        </div>
                                        {otherStatesVisible && requestStates && (
                                            <div className="actionMenu">
                                                {requestStates?.map((item, index) => {
                                                    return !item.internal &&
                                                        item.id !== request.stateId &&
                                                        item.id !== nextRequestState()?.id &&
                                                        (!currentRequestState()?.last ||
                                                            (currentRequestState()?.last && item.sort !== RequestStateSort.SUBMITTED)) ? (
                                                        <>
                                                            <div
                                                                className="button primary"
                                                                onClick={() => onUpdateRequestState(item.id, request.stateSort, item.sort)}
                                                            >
                                                                <CustomIcons
                                                                    iconName="FlagIcon"
                                                                    margin={"0px"}
                                                                ></CustomIcons>
                                                                {item.description}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}

            <DockAssigner
                schema={schema}
                dockAssignment={currentRequestState()?.dockAssignment ?? false}
            />
        </>
    );
};
