import { createUseStyles } from "react-jss";
import { mediaQueries,cssConstants } from "../../../../../common/constants";

export const UpsertInspectionStyle = createUseStyles((theme: any) => ({
    upsertInspectionContainer: {
        display: "flex",
        width: 500,
        height: 400,
        flexDirection: "column",
        justifyContent: cssConstants.SPACE_BETWEEN,
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },
    upsertInspectionData: {
        display: "flex",
        rowGap: theme.gaps.sizes.lg,
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
        height: "inherit",
        paddingTop: 14,
    },
    upsertInspectionRow: {
        display: "flex",
        flexDirection: "row",
        columnGap: 24,
    },
    upsertInspectionSeals: {
        display: "flex",
        flexDirection: "column",
        rowGap: 12,
    },
    buttonsRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: cssConstants.SPACE_BETWEEN,
        alignItems: "start",
        paddingTop: theme.paddings.default,
    },
    disclaimer: {
        display: "flex",
        columnGap: 4,
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.sm,
        "& span": {
            color: theme.colors.titles.danger.color,
            fontWeight: 600,
        },
    },
    buttons: {
        display: "flex",
        columnGap: theme.gaps.sizes.md,
        "& button": {
            minWidth: 120,
        },
    },
}));