import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IBoardHistoryAppointmentTypeConfiguration } from "../../../../../models/masterAppointment/IAppointmentBoard";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";


export interface IAppointmentTypeConfigurationFormState {
    form: IBoardHistoryAppointmentTypeConfiguration;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmentTypeConfigurationFormState = {
    form: {
        id: 0,
        appointmentTypeId: 0,
        active: true,
        merchandiseTypeId: -1,
        timeZones: 0,
        appointmentTypeCode: "",
        appointmentTypeDescription: "",
        merchandiseTypeCode: "",
        merchandiseTypeDescription: "",
        forcedAppointmentTypeCode: "",
        forcedAppointmentTypeDescription: "",
        forcedAppointmentTypeId: 0,
        maxAppointments: "",
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const masterAppointmentTypeConfigurationForm = createSlice({
    name: "masterAppointmentTypeConfigurationForm",
    initialState,
    reducers: {
        resetMasterAppointmentTypeConfigurationForm: () => initialState,
        setLoadingMasterAppointmentTypeConfigurationForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentTypeConfigurationForm: (state, action: PayloadAction<IBoardHistoryAppointmentTypeConfiguration>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentTypeConfigurationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setShowPopUpMasterAppointmentTypeConfiguration: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetMasterAppointmentTypeConfigurationForm,
    setLoadingMasterAppointmentTypeConfigurationForm,
    setMasterAppointmentTypeConfigurationForm,
    updateMasterAppointmentTypeConfigurationProperty,
    setShowPopUpMasterAppointmentTypeConfiguration,
} = masterAppointmentTypeConfigurationForm.actions;

export default masterAppointmentTypeConfigurationForm.reducer;
