import { RequestApi } from "../../../../api/request";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";
import messages from "../../../../translations/es/messages.json";
import { showMessageSuccessFromAssets } from "../../../../utils";
import { resetRequestState, setRequestsCreated, nextStep, setAvailableDates } from "../../../reducers/request/create/createRequest";
import { AppThunk, store } from "../../../store";
import { setGlobalLoadingAction } from "../../common";

const requestApi = new RequestApi();

export const createRequestAction = (): AppThunk => async (dispatch) => {
    try {
        const { request } = store.getState().createRequest;
        dispatch(setGlobalLoadingAction(true));
        const response = await requestApi.createRequestAsync(request);

        showMessageSuccessFromAssets(messages.SUCCESS.CREATE_REQUEST);
        dispatch(setRequestsCreated(response));
        dispatch(nextStep());
    } catch (error) {
        dispatch(HandleAxiosError(error));
    } finally {
        dispatch(setGlobalLoadingAction(false));
    }
};

export const onExitRequestAction = (): AppThunk => async (dispatch) => {
    dispatch(resetRequestState());
};

export const getAvailableDatesRequestAction =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const response = await requestApi.getRequestAvailableDates();
                dispatch(setAvailableDates(response));
                resolve();
            } catch (error) {
                dispatch(HandleAxiosError(error));
                reject(error);
            }
        });
    };
