import { createUseStyles } from "react-jss";

export const EnvironmentStyles = createUseStyles((theme: any) => ({
    "@global": {
        body: {
            margin: 0,
            fontFamily: theme.fonts.families.site,
            fontSize: theme.fonts.sizes.base,
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            color: theme.colors.site.color,
            backgroundColor: theme.colors.site.background,
        },
        p: {
            margin: "0",
        },
    },
    siteContainer: {
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",

        justifyContent: "stretch",
        backgroundColor: theme.colors.site.background,
        "& div": {
            boxSizing: "border-box",
        },
    },
    siteHeader: {
        height: "145px",
        backgroundColor: theme.colors.siteNav.background,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            paddingTop: theme.paddings.default,
            height: "90px",
            flexGrow: "1",
            paddingBottom: theme.paddings.default,
            cursor: "pointer",
        },
    },
    siteHeaderBottom: {
        width: "100%",
        height: "30px",
        borderTopLeftRadius: "30px",
        borderTopRightRadius: "30px",
        backgroundColor: theme.colors.site.background,
    },
    siteBody: {
        flex: "1 1 auto",
        overflowX: "auto",
    },
    footer: {
        position: "static",

        left: 0,
        bottom: 0,
        width: "100%",
        textAlign: "center",
        height: 40,
        fontSize: "1.27rem",
        background: "transparent",
        fontWeigth: "normal",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "40px",
        zIndex: 1,
        "& > p": {
            marginBottom: "0.6rem",
        },
    },
    siteFooterLink: {
        padding: 5,
        margin: 5.3,
        fontSize: "0.94rem",
        color: theme.colors.palette.purple,
        fontWeight: "normal",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));
