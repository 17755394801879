import { Dropdown, IDropdown, IDropdownOption, IDropdownStyles, IRefObject } from "@fluentui/react";
import { useMemo } from "react";
import { yesNoOptionsWithDefault } from "../../../../../utils";
import { MultiSelectorStyled } from "../multiSelector.jss";

interface IBooleanMultiSelectorProps {
    title: string;
    onChange: (name: string, booleanValue: boolean | undefined) => void;
    selectorRef?: IRefObject<IDropdown>;
    selectedKey: boolean | undefined;
    disabled?: boolean;
    className?: string;
    propertyName: string;
}

export const BooleaMultiSelector: React.FC<IBooleanMultiSelectorProps> = ({
    onChange,
    selectedKey,
    title,
    disabled,
    propertyName,
    selectorRef,
    className,
}) => {
    const isDisabled = useMemo(() => {
        return !!disabled;
    }, [disabled]);

    const multiSelectorStyled = MultiSelectorStyled({ isDisabled });

    const styles: Partial<IDropdownStyles> = {
        root: multiSelectorStyled.rootStyles,
        callout: multiSelectorStyled.callout,
        dropdownItem: multiSelectorStyled.optionText,
        dropdownItemSelected: multiSelectorStyled.optionText,
        title: multiSelectorStyled.inputText,
        label: multiSelectorStyled.label,
    };

    const handleChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        if (option) {
            const booleanValue = Number(option.key) === -1 ? undefined : !!Number(option.key);
            onChange(propertyName, booleanValue);
        }
    };

    return (
        <div className={multiSelectorStyled.multiSelectorContainer}>
            <Dropdown
                onChange={handleChange}
                componentRef={selectorRef}
                selectedKey={selectedKey === undefined ? -1 : +selectedKey}
                label={title}
                disabled={disabled}
                options={yesNoOptionsWithDefault}
                className={className}
                styles={styles}
            />
        </div>
    );
};
