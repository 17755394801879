import { createUseStyles } from "react-jss";

export const ManagementDockDisplayWaitingVehiclesStyle = createUseStyles((theme: any) => ({
    containerWaiting: (props: { itemCount: number }) => ({
        display: "grid",
        gridTemplateColumns: props.itemCount > 1 ? "repeat(2, 1fr)" : "repeat(1, 1fr)",
        gap: 15,
        gridAutoFlow: "dense",
        gridAutoRows: "10px", 
    }),
    gridContainer: {
        backgroundColor: theme.colors.palette.purpleLight,
        borderRadius: 10,
        padding: theme.paddings.sizes.lg,
        order: 0,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        WebkitBoxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        moxBoxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    containerTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        width: "100%",
        marginBottom: theme.margins.sizes.base,
    },
    titleWaiting: {
        display: "flex",
        flexDirection: "column",
        fontSize: theme.fonts.sizes.xl,
        gap: 5,
        textTransform: "uppercase",
        width: "100%",
        textAlign: "center",
        color: theme.colors.palette.blueDark,
        fontWeight: 400,
    },
    line: {
        height: 2,
        width: "50%",
        backgroundColor: theme.colors.palette.blueDark,
    },
    itemList: {
        width: "100%",
        textAlign: "center",
        fontSize: theme.fonts.sizes.xxxl,
        fontWeight: 600,
        color: theme.colors.palette.black,
        "& > div": {
            borderBottom: `1px dotted ${theme.colors.palette.black}`,
            padding: theme.paddings.sizes.sm,
            "&:last-child": {
                borderBottom: "none",
            },
        },
    },
}));
