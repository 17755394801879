import { IUserOrganization } from "../../models";
import { useAppSelector } from "../../redux/hooks";

interface IOrganizationPermission {
    fumigated?: boolean;
    halalCertified?: boolean;
    organicProduct?: boolean;
    preappointment?: boolean;
    dryCharge?: boolean;
    parkingAuthorization?: boolean;
}

export const useOrganizationPermission = (): IOrganizationPermission => {
    const {
        userData: {
            profile: { organizations },
        },
        organization: {
            organization: { id: currentOrganization },
        }
    } = useAppSelector((state) => state);

    const organization = organizations.find((item) => item.id === currentOrganization) as IUserOrganization;


    return {
        fumigated: organization?.fumigated,
        halalCertified: organization?.halalCertified,
        organicProduct: organization?.organicProduct,
        preappointment: organization?.preappointment,
        dryCharge: organization?.dryCharge,
        parkingAuthorization: organization?.parkingAuthorization,
    };
};