import { useTheme } from "react-jss";
import { useAppSelector } from "../../../redux/hooks";
import { Skeleton } from "../skeleton/skeleton";
import { TitleH5 } from "../titles/titles";
import { headerTitleStyle } from "./headerTitleStyle.jss";
import { ColorType } from "../../../common/enum/ColorType";

interface TitleHeaderProps {
    title?: string;
    featureId?: string;
}

export const TitleHeader = (props: TitleHeaderProps) => {
    const modules = useAppSelector((store) => store.modules.modules);
    const theme = useTheme();
    const styles = headerTitleStyle({ theme });

    const getTitle = () => {
        if (props.title) {
            return props.title;
        } else if (props.featureId) {
            for (const module of modules) {
                for (const featureItem of module.features) {
                    if (featureItem.id === props.featureId) {
                        return featureItem.name;
                    }
                }
            }
        }
        return "";
    };

    const title = getTitle();

    return (
        <div className={styles.container}>
            {!title ? (
                <div className={styles.loading}>
                    <Skeleton rows={1} />
                </div>
            ) : (
                <TitleH5
                    title={title}
                    color={ColorType.secondary}
                    className={styles.title}
                />
            )}
        </div>
    );
};
