import { createUseStyles } from "react-jss";

export const TableListStyles = createUseStyles((theme: any) => ({
    tableContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    tableHeader: {
        display: "flex",
        color: theme.colors.table.header.color,
        alignItems: "center",
        backgroundColor: theme.colors.palette.purpleLight,
        height: 41,
        "& > div ": {
            width: "12.6%",
            textTransform: "uppercase",
            alignItems: "center",
            display: "flex",
            fontSize: "10px !important",
            fontWeight: theme.fonts.weight.bold,
        },
        "&  .hour": {
            width: "10%",
        },
        "&  .inspector": {
            width: "20%",
        },
    },
    tableBody: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        fontSize: theme.fonts.sizes.sm,
        gap: theme.gaps.sizes.sm,
        paddingTop: theme.paddings.sizes.xs,
        paddingBottom: theme.paddings.sizes.xs,
        "& > .row": {
            width: theme.widths.sectionContainer.complete,
            display: "flex",
            color: theme.colors.table.body.color,
            flexDirection: "column",
            alignContent: "end",
            alignItems: "end",

            "& .data ": {
                display: "flex",
                width: "100%",
            },

            "& .data > .cell": {
                width: "12.6%",
                display: "flex",
                paddingLeft: 1,
                paddingBottom: theme.paddings.sizes.xs,
                alignItems: "center",
                fontFamily: `${theme.fonts.families.siteImportant} !important`,
            },
            "& .data > .hour": {
                width: "10.5%",
            },
            "& .data > .inspector": {
                width: "20%",
                fontFamily: theme.fonts.families.siteImportant,
            },
        },
        "& .row:last-child > .separator": {
            border: "0px !important",
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
        },
    },
}));
