import React, { FormEvent, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setComponetMasterAppointment, setShowPopUpMasterAppointment  } from "../../../redux/reducers/masterAppointment/masterAppointment";
import { LocalStorageKey, LocalStorageService } from "../../../services/localStorageService";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { MasterAppointmentsContainerStyle } from "./containerStyle.jss";
 import { MasterAppointmentListComponent } from "./list/list";
 import { MasterAppointmentSelector } from "./selector/masterSelector";
import { MasterAppointmentsTypes } from "../../../common/enum/masters/masterAppointmentsType";

export const MasterAppointmentContainer = () => {
    const dispatch = useAppDispatch();
    const { codeComponent } = useAppSelector((state) => state.masterAppointment);
    const localStorageService = new LocalStorageService();

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });

    const theme = useTheme();
    const style = MasterAppointmentsContainerStyle({ theme });

    const title = useMemo(() => {
        switch (codeComponent) {
            case MasterAppointmentsTypes.APPOINTMENT_TYPES:
                return `${t("CREATE")} ${t("BUTTON.APPOINTMENT_TYPE").toLowerCase()}`;
            case MasterAppointmentsTypes.APPOINTMENT_TYPE_ACTUATION:
                return `${t("CREATE")} ${t("BUTTON.APPOINTMENT_TYPE_ACTUATION").toLowerCase()}`;
            case MasterAppointmentsTypes.RESTRICTION_DATE:
                return `${t("CREATE")} ${t("BUTTON.RESTRICTED_DATE").toLowerCase()}`;
            case MasterAppointmentsTypes.BOARD:
                return `${t("CREATE")} ${t("BUTTON.BOARD").toLowerCase()}`;
                case MasterAppointmentsTypes.APPOINTMENT_PERMISSION:
                return `${t("CREATE")} ${t("BUTTON.APPOINTMENT_PERMISSION").toLowerCase()}`;
           
        }

        return "";
    }, [codeComponent]);

    const onSelectorChange = (_event: FormEvent<HTMLDivElement>, option: any, name: string) => {
        dispatch(setComponetMasterAppointment(option.key));
        localStorageService.setItem(LocalStorageKey.MASTER_APPOINTMENTS_MANAGEMENT, option.key);
    };
    useLayoutEffect(() => {
        const localStorageService = new LocalStorageService();
        let code = localStorageService.getItem(LocalStorageKey.MASTER_APPOINTMENTS_MANAGEMENT);
         dispatch(setComponetMasterAppointment(code ?? MasterAppointmentsTypes.APPOINTMENT_TYPES));
    }, []);

    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={""}
                action={
                    <>
                        <MasterAppointmentSelector
                            onChange={onSelectorChange}
                            selectedKey={codeComponent}
                        />
                        <ButtonPrimary
                            title={title}
                            handleClick={() => {
                                dispatch(setShowPopUpMasterAppointment(PopUpCodes.CREATE));
                            }}
                        />
                    </>
                }
            />
            <MasterAppointmentListComponent />
        </div>
    );
};
