import { IComboBoxOption } from "@fluentui/react";
import { DefaultValues } from "../../common/enum/DefaultValues";
import { IMerchandiseCategoryRealtionShipOption } from "../../models/catalog/IMercandiseCategory";

export const findMerchandiseCategory = (
    merchandiseTypeId: number,
    humanConsumption: boolean,
    merchandiseOriginId: number,
    categoriesRelationShip: IMerchandiseCategoryRealtionShipOption[],
    options: IComboBoxOption[],
) => {
    if (!merchandiseTypeId || humanConsumption === null || !merchandiseOriginId) {
        return { id: DefaultValues.DEFAULT_ID, option: null };
    }

    for (let categoryRelation of categoriesRelationShip) {
        if (
            categoryRelation.humanConsumption === humanConsumption &&
            categoryRelation.merchandiseOriginId === merchandiseOriginId &&
            categoryRelation.merchandiseTypeId === merchandiseTypeId
        ) {
            const idRelationShipCategory = categoryRelation.merchandiseCategoryId ?? DefaultValues.DEFAULT_ID;
            const option = options.find((item) => item.key === idRelationShipCategory);
            return { id: idRelationShipCategory, option: option };
        }
    }

    return { id: DefaultValues.DEFAULT_ID, option: null };
};
