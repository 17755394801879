import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserSignupProfileStyle = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        backgroundColor: theme.colors.site.background,
        height: "calc(100vh - 11.3rem)",
        display: "flex",
        flexDirection: "column",
        flex: "100",
        padding: theme.paddings.default,
        justifyContent: "space-between",
        [mediaQueries.MOBILE]: {
            height: "100%",
        },
    },
    form: {
        flexFlow: "row",
        display: "flex",
        paddingTop: theme.paddings.sizes.base,
        [mediaQueries.MOBILE]: {
            flexFlow: "column",
        },
    },
    infoContainer: {
        display: "flex",
        flexFlow: "column",

        [mediaQueries.MOBILE]: {
            display: "block",
        },
    },
    item: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [mediaQueries.MOBILE]: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            rowGap: 35,
        },
    },
    itemForm: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        minWidth: "30vw",
        gap: theme.gaps.form.row,
        [mediaQueries.MOBILE]: {
            padding: "0 20px 20px 20px",
            gap: 35,
        },
    },
    avatar: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
    },
    buttonsRow: {
        flexFlow: "row",
        display: "flex",
        justifyContent: "end",
        columnGap: theme.paddings.default,
    },
    typeRow: {
        flexFlow: "row",
        display: "flex",
        position: "relative",
        paddingBottom: 2,
    },
    space: {
        padding: "24px",
        [mediaQueries.MOBILE]: {
            padding: "0",
        },
    },
}));
