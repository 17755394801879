import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { emptyStatisticsFilter, IStatisticsFilter } from "../../../../models/statistics/IStatisticsFilters";

interface IStatiticsMonthlySummaryFilterState {
    filter: IStatisticsFilter;
}

const initialState: IStatiticsMonthlySummaryFilterState = {
    filter: emptyStatisticsFilter(),
};

export const statisticsMonthlySummaryFilterSlice = createSlice({
    name: "statisticsMonthlySummaryFilterSlice",
    initialState,
    reducers: {
        resetMonthlySummaryFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMonthlySummaryFilter,
    updateFilterProperty,
} = statisticsMonthlySummaryFilterSlice.actions;

export default statisticsMonthlySummaryFilterSlice.reducer;
