import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch } from "../../../../../redux/hooks";
import { BatchesStyle } from "./batchesStyle.jss";
import {  OriginSeal } from "../../../../../models";
import { TitleH6 } from "../../../../common/titles/titles";
import { FormField } from "../../../../common/fields/fields";
import { ButtonPrimaryInverseWithBorder } from "../../../../common/buttons/buttons";
import { BatchItem } from "./batchItem/batchItem";
import { useSize } from "../../../../../hooks/resizeHook";
import { ColorType } from "../../../../../common/enum/ColorType";

interface IProps {
    batches: string[];
    addBatch: (batch: string) => void;
    removeBatch: (index: number) => void;
}

export const Batches: React.FC<IProps> = ({ batches, addBatch, removeBatch }) => {
    const { width } = useSize();
    const widthBatchData = useMemo(() => {
        if (width <= 1536) {
            return "31vw";
        }
        return "24.5vw";
    }, [width]);

    const theme = useTheme();
    const style = BatchesStyle({ theme, widthBatchData });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });
    const [batch, setBatch] = useState<string>("");
    const {
        register,
        formState: { isValid },
    } = useForm<OriginSeal>({ mode: "onChange" });

    const onInputChange = (event: any) => {
        setBatch(event?.target?.value);
    };

    const onAddBatch = useCallback(
        (event: any, _newValue?: any) => {
            addBatch(batch);
            setBatch("");
        },
        [dispatch, batch],
    );

    return (
        <>
            <div className={style.titleContainer}>
                <div className={style.title}>
                    <TitleH6
                        title={t("TITLES.BATCHES")}
                        color={ColorType.primary}
                    />
                </div>
            </div>
            <div className={style.sealsContainer}>
                <FormField
                    value={batch}
                    type="text"
                    label={t("FIELDS.BATCH")}
                    {...register("seal", {
                        onChange: onInputChange,
                        validate: () => !!batch,
                    })}
                />
                <div className={style.buttonContainer}>
                    <ButtonPrimaryInverseWithBorder
                        handleClick={onAddBatch}
                        title={t("BUTTONS.ADD_BATCH")}
                        disabled={!isValid || !batch.length}
                    />
                </div>
            </div>
            <div className={style.upsertSealData}>
                {batches.map((batch, index) => (
                    <BatchItem
                        batch={batch}
                        key={`${index}`}
                        index={index}
                        removeBatch={removeBatch}
                    />
                ))}
            </div>
        </>
    );
};
