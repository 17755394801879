export const heights = {
    siteHeader: "100px",
    siteHeaderNav: "65px",
    siteSearch: "190px",
    requestEditContent: "calc(100vh - 257px)",
    requestEditMobileContent: "calc(100vh - 300px)",
    tariffListHeigt: "calc(100vh - 10rem)",
    invoiceListHeigt: "calc(100vh - 14.5rem)",

    statistics: {
        customers: "calc(100vh - 18.76rem)",
        incidence: "calc(100vh - 18.80rem)",
        inspection: "calc(100vh - 21.5rem)",
    },
    customPopUp: {
        maxHeigth: "100vh",
        heigth: "calc(100vh - 10rem)",
        default: "calc(100vh - 40rem)",
    },
    representative: {
        heigth: "20vh",
    },
    editRequest: {
        document: {
            heigth: "35vh",
            selectedDocumentMensage: "40vh",
        },
        incidence: {
            height: "calc(35vh - 1rem)",
            mobileHeight: "calc(35vh - 1rem)",
        },
        invoiceConcept: {
            height: "30vh",
            mobileHeight: "30vh",
        },
    },
    management: {
        user: {
            userForm: {
                height: "39vh",
            },
            defaultTable: "calc(100vh - 14.09rem)",
            superAdminTable: "calc(100vh - 17.21rem)",
        },
        representation: {
            representationForm: {
                height: "25vh",
            },
        },
        notification: {
            notificationForm: {
                height: "65vh",
            },
        },
        tables: "calc(100vh - 14.30rem)",
    },
    confirmPopUp: {
        remove: "auto",
    },
    master: {
        default: "40vh",
        phone: "50vh",
    },
    filter: {
        default: 70,
    },
};
