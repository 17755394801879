import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";

export interface IAssignmentQueueImportForm {
    document: string;
    typeCode: string;
}

interface IAssignmentQueueImport {
    loading: boolean;
    form: IAssignmentQueueImportForm;
    isVisible: string;
}

const initialState: IAssignmentQueueImport = {
    loading: false,
    isVisible: "",
    form: {
        document: "",
        typeCode: "AnimalOrder",
    },
};

export const AssignmentQueueImportFormReducer = createSlice({
    name: "AssignmentQueueImportFormReducer",
    initialState,
    reducers: {
        setAssignmentQueueImportFormData: (state, action: PayloadAction<any>) => {
            state.form = action.payload;
        },
        setAssignmentQueueImportFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAssignmentQueueImportPopUp: (state, action: PayloadAction<string>) => {
            state.isVisible = action.payload;
        },

        resetAssignmentQueueImportForm: () => initialState,
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    setAssignmentQueueImportFormData,
    setAssignmentQueueImportFormLoading,
    setAssignmentQueueImportPopUp,
    resetAssignmentQueueImportForm,
    updateFormProperty,
} = AssignmentQueueImportFormReducer.actions;

export default AssignmentQueueImportFormReducer.reducer;
