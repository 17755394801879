import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IUserSignup } from "../../../../../models/user/IUserSignup";
import { getAllUserTypesSelector } from "../../../../../redux/selectors/user/signup/types";
import { FormSection } from "../../../../common/section/formSection";
import { SignupResumeDataAdmin } from "./admin/resumeDataAdmin";
import { SignupResumeDataEntity } from "./entity/resumeDataEntity";
import { SignupResumeOperationEmails } from "./operationEmails/operationEmails";
import { SignupResumeOrganization } from "./organization/resumeOrganization";
import { SignupResumeDataPayment } from "./payment/resumeDataPayment";
import { SignupResumeDataType } from "./type/resumeDataType";
import { SignupResumeDataUser } from "./user/resumeDataUser";
import { UserType } from "../../../../../common/enum/UserType";

interface Props {
    data: IUserSignup;
}

export const UserSignupResumeData: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation("common");
    const userTypes = useSelector(getAllUserTypesSelector.selectAll);
    const entityTypeName = userTypes.filter((type) => type.id === data.userType)[0]?.name;

    return (
        <>
            <FormSection
                title={t("USER.TITLES.ENTITY_TYPE")}
                children={<SignupResumeDataType type={entityTypeName} />}
                firstSection={true}
            />
            <FormSection
                title={t("USER.TITLES.ORGANIZATION_NAME")}
                children={<SignupResumeOrganization organizationId={data.organizationId} />}
            />
            <FormSection
                title={t("USER.TITLES.USER_DATA")}
                children={<SignupResumeDataUser data={data} />}
            />
            <FormSection
                title={t("USER.TITLES.ENTITY_DATA")}
                children={<SignupResumeDataEntity data={data.entityData} />}
                lastSection={data.userType === UserType.TRUCKER}
            />
            {data.userType === UserType.CUSTOMER && data.paymentData && (
                <FormSection
                    title={t("USER.TITLES.PAYMENT_DATA")}
                    children={<SignupResumeDataPayment data={data.paymentData} />}
                />
            )}
            {data.userType === UserType.CUSTOMER && data.adminData && (
                <FormSection
                    title={t("USER.TITLES.ADMIN_DATA")}
                    children={<SignupResumeDataAdmin data={data.adminData} />}
                />
            )}
            {data.userType === UserType.CUSTOMER && data.operatorData && (
                <FormSection
                    title={t("USER.TITLES.OPERATION_DEPARTMENT")}
                    children={<SignupResumeOperationEmails data={data.operatorData} />}
                    lastSection={true}
                />
            )}
        </>
    );
};
