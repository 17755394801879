import { createUseStyles } from "react-jss";
export const EditRequestDocumentFormStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        gap: theme.gaps.sizes.base,
        width: "100%",
    },
    form: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        paddingTop: 20,
    },

    file: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },

    fumigationDocument: {
        width: "100%",
    },
    message: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        padding: theme.paddings.sizes.sm,
        // wordBreak: "break-word",
        textAlign: "justify",
        textJustify: "inter-word",
    },
}));
