// IDailyChargeByInspector

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDailyChargeStatistics, IDailyChargeStatisticsState } from "../../../../models/statistics/IDailyCharge";

interface IInitialState {
    data: IDailyChargeStatisticsState;
}

const initialSateData = (): IDailyChargeStatisticsState => {
    return {
        loading: false,
        list: [],
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsDailyChargeListSlice = createSlice({
    name: "statiticsDailyChargeListSlice",
    initialState,
    reducers: {
        resetStatiticsDailyChargeList: () => initialState,
        setStatiticsDailyChargeList: (state, action: PayloadAction<IDailyChargeStatistics[]>) => {
            state.data.list = action.payload;
        },

        setLoadingStatisticsDailyChargeList: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
    },
});

export const { resetStatiticsDailyChargeList, setStatiticsDailyChargeList, setLoadingStatisticsDailyChargeList } =
    statiticsDailyChargeListSlice.actions;

export default statiticsDailyChargeListSlice.reducer;
