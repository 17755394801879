export enum RequestStateSort {
    SUBMITTED = 10,
    ENTRY = 20,
    PREPARED = 40,
    DOCK = 50,
    DOCK_END = 60,
    EXIT = 80,
    ANULATED = 100,
    DRAFT = 0
}
