import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { IParkingValidationRulesList } from "../../../../../models/management/parkingValidationRule/IParkingValidationRule";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { ManagementParkingValidationRuleListColumnStyle } from "./columnStyle.jss";

import { useMemo } from "react";
import { MethodsSort } from "../../../../../common/enum/sort/Methods";
import { SortTableName } from "../../../../../common/enum/sort/TableName";
import {
    getParkingValidationRuleAsync,
    patchParkingValidationRulePriorityAsync,
} from "../../../../../redux/actions/management/parkingValidation/parkingValidation";
import { setManagementParkingValidationRuleFormPopUp } from "../../../../../redux/reducers/management/parkingValidation/form";

export const ManagementParkingValidationRuleListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PARKING_VALIDATION_RULE" });

    const theme = useTheme();
    const styles = ManagementParkingValidationRuleListColumnStyle({ theme });

    const dispatch = useAppDispatch();
    const { list } = useAppSelector((state) => state.managementParkingValidationRuleList);
   
    const lastIndex = useMemo(() =>  list ? list.length - 1 : 0, [list]);

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IParkingValidationRulesList>("description"),
            minWidth: 250,
            maxWidth: 450,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IParkingValidationRulesList>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IParkingValidationRulesList) => <span>{item.description}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IParkingValidationRulesList>("sort"),
            minWidth: 100,
            maxWidth: 120,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IParkingValidationRulesList>("id")}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IParkingValidationRulesList, index: any) => (
                <span>
                    <CustomIcons
                        className={styles.arrow}
                        iconName={"ChevronUpSmall"}
                        isDisabled={index === 0}
                        onClick={() => {
                            dispatch(patchParkingValidationRulePriorityAsync(SortTableName.ValidationParkingRule, item.id ?? 0, MethodsSort.UP));
                        }}
                    />
                    <Separator size="auto" />
                    <CustomIcons
                        iconName={"ChevronDownSmall"}
                        className={styles.arrow}
                        onClick={() => {
                            dispatch(patchParkingValidationRulePriorityAsync(SortTableName.ValidationParkingRule, item.id ?? 0, MethodsSort.DOWN));
                        }}
                        isDisabled={index === lastIndex || (index === 0 && list?.length === 1) ? true : false}
                    />
                </span>
            ),
            isResizable: true,
        },

        {
            key: "",
            minWidth: 100,
            maxWidth: 100,
            name: "",
            onRender: (_item: IParkingValidationRulesList) => (
                <div className={styles.buttonsContainer}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(getParkingValidationRuleAsync(_item.id)).then(() =>
                                dispatch(setManagementParkingValidationRuleFormPopUp(PopUpCodes.UPDATE)),
                            );
                        }}
                    />
                    <Separator size="none" />
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(getParkingValidationRuleAsync(_item.id)).then(() =>
                                dispatch(setManagementParkingValidationRuleFormPopUp(PopUpCodes.DELETE)),
                            );
                        }}
                    />
                </div>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
