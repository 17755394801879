import { createUseStyles } from "react-jss";

export const InvoicingUninvoicedColumnStyle = createUseStyles((theme: any) => ({
    header: {
        display: "flex",
    },
    sort: {
        marginTop: "5px",
    },
    iconSort: {
        fill: theme.colors.iconSort.disabled,
        width: "16px",
    },
    iconSorted: {
        fill: theme.colors.iconSort.enabled,
        width: "16px",
    },
    sortColumn: {
        width: 14,
        display: "flex",
        position: "absolute",
    },
    dataCell : {
        textAlign:"center"
    }
}));
