import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../../../../../models";
import { IBoardHistoryAppointmentTimeZone } from "../../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../redux/hooks";
import { updateMasterAppointmentTimeZoneProperty } from "../../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDockTimeZone/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../../../../../utils";
import { FormField } from "../../../../../../../../../../common/fields/fields";
import { TimePickerComponent } from "../../../../../../../../../../common/timePicker/timePicker";
import { TurnSelector } from "../../../../../../../../../../request/common/selectors";
import { MasterBoardHistoryAppointmentTimeZoneStyle } from "./formStyle.jss";
import { DefaultValues } from "../../../../../../../../../../../common/enum/DefaultValues";

export const MasterBoardHistoryAppointmentTimeZoneForm = () => {
    const theme = useTheme();
    const style = MasterBoardHistoryAppointmentTimeZoneStyle({ theme });

    const { form } = useAppSelector((state) => state.masterAppointmentTimeZone);
    const dispatch = useAppDispatch();
    const { control, register, setValue } = useForm<IBoardHistoryAppointmentTimeZone>({ mode: "onChange" });

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD" });

    const onUpdateHeaderPopperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name: name,
            value: value,
        };
        dispatch(updateMasterAppointmentTimeZoneProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateMasterAppointmentTimeZoneProperty(payload));
        },
        [dispatch],
    );

    const onTimeChangeStart = useCallback(
        (date: MaterialUiPickersDate) => {
            date &&
                onUpdateHeaderPopperty(compileNameOfProperty<IBoardHistoryAppointmentTimeZone>("startTime"), new Date(date.toISOString()).toString());
        },
        [dispatch],
    );

    const onTimeChangeEnd = useCallback(
        (date: MaterialUiPickersDate) => {
            date &&
                onUpdateHeaderPopperty(compileNameOfProperty<IBoardHistoryAppointmentTimeZone>("endTime"), new Date(date.toISOString()).toString());
        },
        [dispatch],
    );

    const onSelectorChange = (option: any, name: string) => {
        onUpdateHeaderPopperty(name, option.key);
    };

    return (
        <>
            <div className={style.form}>
                <div className={style.rowForm}>
                    <div className={style.item}>
                        <FormField
                            type="text"
                            label={t("FORM.APPOINTMENT_CODE")}
                            value={form.code ?? DefaultValues.NOT_TEXT}
                            {...register(compileNameOfProperty<IBoardHistoryAppointmentTimeZone>("code"), {
                                onChange: onInputChange,
                                value: form.code ?? DefaultValues.NOT_TEXT,
                            })}
                            isRequired={true}
                        />
                    </div>
                    <div className={style.item}>
                        <FormField
                            type="text"
                            label={t("FORM.DESCRIPTION")}
                            value={form.description ?? DefaultValues.NOT_TEXT}
                            {...register(compileNameOfProperty<IBoardHistoryAppointmentTimeZone>("description"), {
                                onChange: onInputChange,
                                value: form.description ?? DefaultValues.NOT_TEXT,
                            })}
                            isRequired={true}
                        />
                    </div>
                    <div className={style.item}>
                        <TurnSelector
                            onChange={onSelectorChange}
                            selectedKey={form.turnId!}
                            propertyName={compileNameOfProperty<IBoardHistoryAppointmentTimeZone>("turnId").toString()}
                            title={t("FORM.TURN")}
                            isRequired={true}
                            control={control}
                            setValue={setValue}
                        />
                    </div>
                </div>
                <div className={style.rowForm}>
                    <div className={style.item}>
                        <FormField
                            type="text"
                            label={t("FORM.EXTERNAL_CODE")}
                            value={form.externalCode ?? DefaultValues.NOT_TEXT}
                            {...register(compileNameOfProperty<IBoardHistoryAppointmentTimeZone>("externalCode"), {
                                onChange: onInputChange,
                                value: form.externalCode ?? DefaultValues.NOT_TEXT,
                            })}
                            isRequired={false}
                        />
                    </div>
                    <div className={style.item}>
                        <TimePickerComponent
                            label={t("FORM.START_TIME")}
                            onChange={onTimeChangeStart}
                            value={form.startTime}
                            required={true}
                        />
                    </div>
                    <div className={style.item}>
                        <TimePickerComponent
                            label={t("FORM.END_TIME")}
                            onChange={onTimeChangeEnd}
                            value={form.endTime}
                            required={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
