import { createUseStyles } from "react-jss";

export const AutoCompleteStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        width: "100%",
        position: "relative",
    },

    label: {
        top: -16,
        transform: "translateY(20px)",
        fontSize: theme.fonts.sizes.base,
        fontFamily: theme.fonts.families.siteImportant,
    },
    labelSelected: {
        top: -20,
        transform: "translateY(8px)",
        fontSize: theme.fonts.sizes.xs,
        fontFamily: theme.fonts.families.siteImportant,
    },
    errorMessage: {
        fontFamily: theme.fonts.families.site,

        position: "absolute",
        fontSize: theme.fonts.sizes.xxs,
        color: theme.colors.form.input.invalid,
        paddingTop: 5,
        paddingBottom: 5,
        top: 32,
    },
}));
