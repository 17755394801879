import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
    deleteMasterAppointmentTypeConfiguration,
    postMasterAppointmentTypeConfiguration,
    updateMasterAppointmentTypeConfiguration,
} from "../../../../../../../../../../redux/actions/masterAppointment/appointmentBoard/appointmentTypeConfiguration/action";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../redux/hooks";
import {
    resetMasterAppointmentTypeConfigurationForm,
    setShowPopUpMasterAppointmentTypeConfiguration,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentTypeConfiguration/form";
import { PopUpConfirmation } from "../../../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../../../../../../common/popup/popUpForm/popUpForm";
import { MasterBoardHistoryAppointmentDockForm } from "./form/form";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../../../../../common/enum/PopUpConfirmationType";
import { RequiredFieldsMessage } from "../../../../../../../../../common/requiredMessage/requiredMessage";

export const MasterBoardHistoryAppointmentDockControllerForm = () => {
    const dispatch = useAppDispatch();
    const { showPopUp, form, loading } = useAppSelector((store) => store.masterAppointmentTypeConfigurationForm);
    const { id } = useAppSelector((store) => store.masterAppointmentBoardEdit.form);

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_TYPE_CONFIGURATION" });

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE) {
            dispatch(postMasterAppointmentTypeConfiguration(id.toString(), form)).then((response: any) => {
                response && onClose();
            });
        } else if (showPopUp === PopUpCodes.UPDATE) {
            dispatch(updateMasterAppointmentTypeConfiguration(id.toString(), form.id.toString(), form)).then((response: any) => {
                response && onClose();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(deleteMasterAppointmentTypeConfiguration(id.toString(), form.id.toString())).then((response: any) => {
                response && onClose();
            });
        }
    };
    const onDimissed = () => {
        dispatch(resetMasterAppointmentTypeConfigurationForm());
    };
    const onClose = () => {
        dispatch(setShowPopUpMasterAppointmentTypeConfiguration(PopUpCodes.NONE));
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }
        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return !form.timeZones || !form.appointmentTypeId;
    }, [form]);
    return (
        <>
            <PopUpForm
                content={<MasterBoardHistoryAppointmentDockForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onClose}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"30vh"}
                width={"30vw"}
                isDisabledButton={isDisabledButton}
                isLoading={loading}
                leftButtonContent={<RequiredFieldsMessage />}
            />

            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onClose}
                onAccept={onSave}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                disabledActions={loading}
                height={"auto"}
                width={"auto"}
            ></PopUpConfirmation>
        </>
    );
};
