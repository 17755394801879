import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigMerchandiseCategoryForm } from "../../../../../models/config/merchandiseCategory";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateFormProperty } from "../../../../../redux/reducers/config/merchandiseCategory/form";
import { compileNameOfProperty } from "../../../../../utils";

import { BooleanSelector, MerchandiseOriginSelector, MerchandiseTypeSelector } from "../../../../request/common/selectors";
import { MerchandiseCategorySelector } from "../../../../request/common/selectors/merchandiseCategoryId/merchandiseCategorySelector";
import { ConfigMerchandiseCategoryFormStyle } from "./formStyle.jss";

export const ConfigMerchandiseCategoryForm = () => {
    const theme = useTheme();
    const style = ConfigMerchandiseCategoryFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { form } = useAppSelector((state) => state.configMerchandiseCategoryForm);
    const { control } = useForm<IConfigMerchandiseCategoryForm>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateFormProperty({ name, value }));
    };

    const onSelectorChange = (option: any, name: string) => {
        onUpdateFormProperty(name, option.key);
    };

    return (
        <div className={style.form}>
            <div className={style.row}>
                <MerchandiseTypeSelector
                    filterOptions={false}
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseTypeId}
                    propertyName={compileNameOfProperty<IConfigMerchandiseCategoryForm>("merchandiseTypeId")}
                    title={t("MERCHANDISE_TYPE")}
                    control={control}
                    isRequired={true}
                />
                <BooleanSelector
                    onChange={onSelectorChange}
                    selectedKey={+form.humanConsumption }
                    propertyName={compileNameOfProperty<IConfigMerchandiseCategoryForm>("humanConsumption")}
                    title={t("HUMAN_CONSUMPTION")}
                    control={control}
                    isRequired={true}
                />
            </div>
            <div className={style.row}>
                <MerchandiseOriginSelector
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseOriginId}
                    propertyName={compileNameOfProperty<IConfigMerchandiseCategoryForm>("merchandiseOriginId")}
                    title={t("MERCHANDISE_ORIGIN")}
                    control={control}
                    isRequired={true}
                />
                <MerchandiseCategorySelector
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseCategoryId}
                    propertyName={compileNameOfProperty<IConfigMerchandiseCategoryForm>("merchandiseCategoryId")}
                    title={t("MERCHANDISE_NATURE")}
                    control={control}
                    isRequired={true}
                />
            </div>
        </div>
    );
};
