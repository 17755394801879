import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { fetchUserSignupDocumentTemplatesAction } from "../../../../redux/actions/user/signup/documentTemplates";
import { useAppDispatch } from "../../../../redux/hooks";
import { nextStep, previousStep } from "../../../../redux/reducers/user/signup/stepper";
import { getUserSignupDocumentTemplatesSelector } from "../../../../redux/selectors/user/signup/documentTemplates";
import { ButtonPrimary, ButtonSecondary } from "../../../common/buttons/buttons";
import { UserDocumentList } from "../../../common/user/documentList/documentList";
import { UserSignupDocumentTemplatesStyles } from "./contaynerStyle.jss";

export const UserSignupDocumentTemplates = () => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = UserSignupDocumentTemplatesStyles({ theme });

    const documents = useSelector(getUserSignupDocumentTemplatesSelector.selectAll);

    useEffect(() => {
        !documents.length && dispatch(fetchUserSignupDocumentTemplatesAction());
    }, []);

    return (
        <>
            <div className={styles.container}>
                <UserDocumentList documents={documents} />

                <div className={styles.divisor}></div>

                <div className={styles.buttonsRow}>
                    <ButtonSecondary
                        handleClick={() => dispatch(previousStep())}
                        title={t("USER.SIGNUP.BACKBUTTON")}
                    />
                    <ButtonPrimary
                        handleClick={() => dispatch(nextStep())}
                        title={t("USER.SIGNUP.NEXTBUTTON")}
                    />
                </div>
            </div>
        </>
    );
};
