import { UserApi } from "../../../../api";
import { UserType } from "../../../../common/enum/UserType";
import { IGenericPayload, IUploadUserDocument } from "../../../../models";
import messages from "../../../../translations/es/messages.json";
import { showMessageSuccessFromAssets, throwErrorToastrAsync } from "../../../../utils";
import {
    loadingUserDocuments,
    resetUserSignupForm,
    setAvatarFile,
    setDocumentFile,
    setDocumentTypesRequested,
    updateUserSignupProperty,
    userSignUpSuccess,
} from "../../../reducers/user/signup/signup";
import { nextStep } from "../../../reducers/user/signup/stepper";
import { AppThunk, store } from "../../../store";
import { setGlobalLoadingAction } from "../../common";

const userSignupApi = new UserApi();

export const updateUserSignupPropertyAction =
    (payload: IGenericPayload): AppThunk =>
    (dispatch) => {
        dispatch(updateUserSignupProperty(payload));
    };

export const resetUserSignFormAction = (): AppThunk => (dispatch) => {
    const form = store.getState().userSignup.form;

    form?.avatar?.file && URL.revokeObjectURL(form.avatar.file);
    form.documents.forEach((doc) => !!doc.file && URL.revokeObjectURL(doc.file));

    dispatch(resetUserSignupForm());
};

export const fetchRequestedDocumentAction =
    (type: UserType): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(loadingUserDocuments(true));
            const response = await userSignupApi.getDocumentRequested(type);
            dispatch(setDocumentTypesRequested(response.documents));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingUserDocuments(false));
        }
    };

export const setDocumentFileAction =
    (payload: IUploadUserDocument): AppThunk =>
    (dispatch) => {
        dispatch(setDocumentFile(payload));
    };

export const setAvatarFileAction =
    (payload: IGenericPayload): AppThunk =>
    (dispatch) => {
        dispatch(setAvatarFile(payload));
    };

export const uploadSignUpRequestAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoadingAction(true));
        const form = store.getState().userSignup.form;
        await userSignupApi.getUserSignupAsync(form);
        dispatch(userSignUpSuccess());
        showMessageSuccessFromAssets(messages.SUCCESS.USER_REGISTRATION);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoadingAction(false));
    }
};

export const userExitsAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoadingAction(true));
        const { email, organizationId } = store.getState().userSignup.form;
        await userSignupApi.userSignupExists({ email, organizationId });
        dispatch(nextStep());
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoadingAction(false));
    }
};
