import { useTheme } from "react-jss";
import { UtiCards } from "./utiCard/utiCard";
import { UtiCardSectionStyle } from "./utiCardSection.jss";
import { UtiList } from "./list/list";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { DeviceMinSizes } from "../../../../../common/constants";
import { useSize } from "../../../../../hooks/resizeHook";
import { IConfigCreateRequest, ITransportUnitData } from "../../../../../models";
import { useTranslation } from "react-i18next";

interface IProps {
    isContainer: boolean;
    schema: IConfigCreateRequest;
    setIndexOrder: (index: number) => void;
    onDeleteUti: (index: number) => void;
    setUtiForm: (uti: ITransportUnitData) => void;
    setShowPopUp: (code: PopUpCodes) => void;
    transportUnits: ITransportUnitData[];
}
export const UtiCardSection: React.FC<IProps> = ({ isContainer, setIndexOrder, onDeleteUti, setUtiForm, setShowPopUp, transportUnits, schema }) => {
    const theme = useTheme();
    const styles = UtiCardSectionStyle({ theme });
    const { t } = useTranslation("common");

    const onEditUnit = (unit: ITransportUnitData, index: number) => {
        setUtiForm(unit);
        setIndexOrder(index);
        setShowPopUp(PopUpCodes.UPDATE);
    };
    const onDeleteUnit = (index: number) => {
        setIndexOrder(-1);
        onDeleteUti(index);
    };
    const { width } = useSize();

    return (
        <div className={styles.utiCardSectionContainer}>
            {transportUnits.length > 0 ? (
                width < DeviceMinSizes.DESKTOP ? (
                    transportUnits?.map((unit, index) => {
                        return (
                            <UtiCards
                                unit={unit}
                                editCard={onEditUnit}
                                deleteCard={onDeleteUnit}
                                key={index}
                                indexOrder={index}
                                isContainer={isContainer}
                                schema={schema}
                            />
                        );
                    })
                ) : (
                    <UtiList
                        units={transportUnits}
                        deleteCard={onDeleteUnit}
                        editCard={onEditUnit}
                        isContainer={isContainer}
                        schema={schema}
                    />
                )
            ) : (
                <>{t("EDIT_REQUEST.FIELDS.NOT_DATA")}</>
            )}
        </div>
    );
};
