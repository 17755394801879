import { RequestApi } from "../../../../api";
import { throwErrorToastrAsync } from "../../../../utils";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import { setVlcportImportRequestFormLoading } from "../../../reducers/vlcPort/importRequest/form";
import { setVlcportImportRequestListData } from "../../../reducers/vlcPort/importRequest/list";
import { AppThunk } from "../../../store";

const requestApi = new RequestApi();

export const vlcPortImportRequestAction = (excelFile: any, xml: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setVlcportImportRequestFormLoading(true));
            const response = await requestApi.importRequestVlcPort(excelFile, xml);
            dispatch(setVlcportImportRequestListData(response));

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setVlcportImportRequestFormLoading(false));
        }
    };
};

export const fetchVlcImportRequestTemplate = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoading(true));
        const response = await requestApi.importRequestVlcPortTemplate();
        window.open(response.uri);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};
