import React from "react";
import { InputStyle } from "./input.jss";

interface IInputProps {
    type: string;
    name: string;
    onChange: (e: any) => void;
    onBlur?: (e: any) => void;
    className?: string;
    value: string;
    ref: React.ForwardedRef<HTMLInputElement>;
    disabled?: boolean;
    maxLength?: number;
    readonly?: boolean;
    step?: string;
    min?: string;
    max?: string;
    onEnter?: any;
    autocomplete?: string;
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
    ({ onChange, onBlur, name, type, value, disabled, maxLength, readonly, step, min, max, onEnter, autocomplete, className }, ref) => {
        const inputStyle = InputStyle();

        const handleKeyDown = (event: any) => {
            if (event.key === "Enter") {
                onEnter();
            }
        };

        return (
            <>
                <input
                    autoComplete={autocomplete ?? "none"}
                    className={`${inputStyle.input} ${className}`}
                    type={type}
                    name={name}
                    maxLength={maxLength}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    disabled={disabled}
                    readOnly={readonly}
                    step={step}
                    min={min}
                    max={max}
                    onKeyDown={handleKeyDown}
                />
            </>
        );
    },
);
