import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { UtiCardStyle } from "./utiCard.jss";
import { IConfigCreateRequest, ITransportUnitData } from "../../../../../../models";
import { useAppSelector } from "../../../../../../redux/hooks";
import { NameIcons } from "../../../../../../assets/icons";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { BooleanValue } from "../../../../../common/booleanValue/booleanValue";

interface IProps {
    unit: ITransportUnitData;
    editCard: (unit: ITransportUnitData, indexOrder: number) => void;
    indexOrder: number;
    deleteCard: (indexOrder: number) => void;
    isContainer: boolean;
    schema: IConfigCreateRequest;
}
export const UtiCards: React.FC<IProps> = ({ unit, editCard, indexOrder, deleteCard, isContainer, schema }) => {
    const theme = useTheme();
    const styles = UtiCardStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });
    const containerSizes = useAppSelector((store) => store.catalog.transportUnitSizes.data);

    const containerSizeName = useMemo(() => {
        return containerSizes.find((cont) => cont.id === unit.transportUnitSizeId)?.description;
    }, [containerSizes, unit]);

    return (
        <>
            <div
                className={styles.utiCardContainer}
                key={indexOrder}
            >
                <div className={styles.utiCardActionButtons}>
                    <div
                        className={styles.utiCardButtonEdit}
                        onClick={() => editCard(unit, indexOrder)}
                    >
                        {t("BUTTONS.EDIT")}
                    </div>
                    <div className={styles.utiCardButtonDelete}>
                        <CustomIcons
                            iconName={NameIcons.REMOVE}
                            onClick={() => deleteCard(indexOrder)}
                        />
                    </div>
                </div>
                <div className={styles.utiCardDataContainer}>
                    <div className={styles.utiCardRow}>
                        {isContainer && (
                            <div className={styles.utiCardItem}>
                                <div className={styles.utiCardLabel}>{t("FIELDS.CONTAINER_SIZE")}</div>
                                <div className={styles.utiCardData}>{containerSizeName}</div>
                            </div>
                        )}
                        <div className={styles.utiCardItem}>
                            <div className={styles.utiCardLabel}>{t("FIELDS.TRANSPORT_UNIT")}</div>
                            <div className={styles.utiCardData}>{unit.transportUnitNumber}</div>
                        </div>
                    </div>
                    <div className={styles.utiCardRow}>
                        <div className={styles.utiGrossData}>
                            <div className={styles.utiCardItem}>
                                <div className={styles.utiCardLabel}>{t("FIELDS.PACKAGES")}</div>
                                <div className={styles.utiCardData}>{unit.packages || "-"}</div>
                            </div>
                            <div className={styles.utiCardItem}>
                                <div className={styles.utiCardLabel}>{t("FIELDS.GROSS_KG")}</div>
                                <div className={styles.utiCardData}>{unit.grossWeight || "-"}</div>
                            </div>
                            <div className={styles.utiCardItem}>
                                <div className={styles.utiCardLabel}>{t("FIELDS.PALLETIZED")}</div>
                                <div className={styles.utiCardData}>
                                    <BooleanValue value={unit.palletized} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.seals}>
                        <div className={styles.utiCardLabel}>{t("FIELDS.ORIGIN_SEAL")}</div>
                        <div
                            className={styles.sealsContainer}
                            title={unit.seals?.flatMap((seal) => seal)?.join(" , ")}
                        >
                            {unit.seals.map((seal) => (
                                <div className={styles.card}>{seal}</div>
                            ))}
                        </div>
                    </div>
                    {schema.batches.visible && (
                        <div className={styles.seals}>
                            <div className={styles.utiCardLabel}>{t("FIELDS.BATCH")}</div>
                            <div
                                className={styles.sealsContainer}
                                title={unit.batches?.flatMap((batch) => batch)?.join(" , ")}
                            >
                                {unit.batches?.map((bt) => (
                                    <div className={styles.card}>{bt}</div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
