import { IColumn } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IGenericPayload } from "../../../../../models";
import { IConfigDockBlockItem } from "../../../../../models/config/blockDock";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setConfigDockBlockShowPopUp, updateFormProperty } from "../../../../../redux/reducers/config/dockBlock/form";
import { updateSortDockBlockColumn } from "../../../../../redux/reducers/config/dockBlock/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty, onUpdateSortedColumnUtil } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { MasterBlocksColumnsStyle } from "./columnStyle.jss";

export const ConfigDockBlockListColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterBlocksColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();
    const { sort } = useAppSelector((state) => state.configDockBlockList);
    
    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().configDockBlockList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortDockBlockColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IConfigDockBlockItem>("dockDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: compileNameOfProperty<IConfigDockBlockItem>("dockDescription"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockBlockItem>("dockDescription")}
                    title={t("DOCK_MERCHANDISE.DOCK")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockBlockItem>("dockDescription"))}
                />
            ),
            onRender: (item: IConfigDockBlockItem) => <span>{item.dockDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockBlockItem>("dockBlockCode"),
            minWidth: 120,
            maxWidth: 200,
            name: t("DOCK_BLOCK.BLOCK_CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockBlockItem>("dockBlockCode")}
                    title={t("DOCK_BLOCK.BLOCK_CODE")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockBlockItem>("dockBlockCode"))}
                />
            ),
            onRender: (item: IConfigDockBlockItem) => <span>{item.dockBlockCode}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockBlockItem>("dockBlockDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: t("DOCK_BLOCK.BLOCK_DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockBlockItem>("dockBlockDescription")}
                    title={t("DOCK_BLOCK.BLOCK_DESCRIPTION")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockBlockItem>("dockBlockDescription"))}
                />
            ),
            onRender: (item: IConfigDockBlockItem) => <span>{item.dockBlockDescription}</span>,
            isResizable: true,
        },
       
       
        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IConfigDockBlockItem) => (
                <span className={styles.actions}>
                   
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(setConfigDockBlockShowPopUp(PopUpCodes.DELETE));
                            const payload: IGenericPayload = {
                                name: compileNameOfProperty<IConfigDockBlockItem>("id"),
                                value: item.id,
                            };
                            dispatch(updateFormProperty(payload));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
