import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { emptyInspection, IInspection } from "../../../../../models/requests/IEditRequest";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";


interface EditRequestInspectionState {
    form: IInspection;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: EditRequestInspectionState = {
    form: emptyInspection(),
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const editRequesInspectionFormSlice = createSlice({
    name: "editRequestReducer",
    initialState,
    reducers: {
        resetEditRequestInspectionForm: () => initialState,
        updateEditRequestInspectionFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setEditRequestInspectionLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEditRequestInspectionShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setEditRequestInspectionForm: (state, action: PayloadAction<IInspection>) => {
            state.form = action.payload;
        },
    },
});

export const {
    updateEditRequestInspectionFormProperty,
    setEditRequestInspectionLoading,
    setEditRequestInspectionShowPopUp,
    resetEditRequestInspectionForm,
    setEditRequestInspectionForm,
} = editRequesInspectionFormSlice.actions;

export default editRequesInspectionFormSlice.reducer;
