import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";

interface IIInvoiceSummaryListFooterColumnProps {
    listColumnsFooter: IColumn[];
}

export const IInvoiceSummaryListFooterColumn = (props: IIInvoiceSummaryListFooterColumnProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "INVOICED.FOOTERTABLE" });
    const {  totalAmount, totalQuantity } = useAppSelector((state) => state.invoicingSummaryConceptList);

    const columns: IColumn[] = [
        {
            key: "totalFinal",
            name: t("TOTALFINAL"),
            fieldName: "name",
            minWidth: 120,
            maxWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            isResizable: true,
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTALFINAL")}</b>,
        },
        {
            key: "totalQuantity",
            name: `${totalQuantity}`,
            fieldName: "value",
            minWidth: 100,
            maxWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            isResizable: true,
            onRender: () => <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}> {totalQuantity} </b>,
        },
        {
            key: "totalAmount",
            name: `${totalAmount}`,
            fieldName: "totalAmount",
            minWidth: 100,
            maxWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            isResizable: true,
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}>{totalAmount}</b>,
        },
    ];

    return columns;
};
