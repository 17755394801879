import { useTranslation } from "react-i18next";
import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementNotificationFilter } from "./filter/filter";
import { ManagementNotificationList } from "./list/list";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { ManagementNotificationControllerForm } from "./controller/controller";
import { setManagementnotificationFormPopUp } from "../../../redux/reducers/management/notification/form";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

export const ManagementNotificationContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.NOTIFICATION" });
    const dispatch = useAppDispatch();

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonPrimary
                        title={t("NEW_NOTIFICATION")}
                        handleClick={() => {
                            dispatch(setManagementnotificationFormPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />

            <ManagementNotificationControllerForm />

            <ManagementNotificationFilter />

            <ManagementNotificationList />
        </>
    );
};
