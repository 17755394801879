import { useTheme } from "react-jss";
import { CodeViewerStyle } from "./codeViewer.jss";
import { CustomIcons } from "../customIcons/customIcons";

import { IContextualMenuProps } from "@fluentui/react";
import ReactJsonViewCompare from "react-json-view-compare";
import { useMemo } from "react";

interface ICopyCodeProps {
    newData?: any;
    oldData?: any;
    height?: string;
    menuIconProps?: IContextualMenuProps;
    replaceName?: (value: any) => any;
    oldDataMenuText?:string;
    newDataMenuText?:string;
    showMenuOption:boolean;
}

export const JsonCompare: React.FC<ICopyCodeProps> = ({ newData, oldData, height, replaceName, showMenuOption, newDataMenuText, oldDataMenuText }) => {
    const theme = useTheme();
    const style = CodeViewerStyle({ theme, height });
    const menuProps = useMemo(() => {
        if ( !oldData || !showMenuOption) {
            return undefined;
        }
        return {
            shouldFocusOnMount: true,
            items: [
                {
                    key: "old",
                    text: oldDataMenuText,

                    onClick: () => {
                        copyToClipBoard(oldData !== null ? JSON.stringify(oldData) : "");
                    },
                },
                {
                    key: "new",
                    text: newDataMenuText,
                    onClick: () => {
                        copyToClipBoard(newData !== null ? JSON.stringify(newData) : "");
                    },
                },
            ],
        };
    }, [oldData, newData]);
    const copyToClipBoard = (data: any) => {
               data &&
            navigator.clipboard
                .writeText(JSON.stringify(data))
                .then(() => {})
                .catch((_err) => {});
    };

    const setOldData = useMemo(() => {
        if (replaceName) {
            return replaceName(oldData);
        }
        return oldData;
    }, [oldData, replaceName]);
    
    const setNewData = useMemo(() => {
        if (replaceName) {
            return replaceName(newData);
        }
        return newData;
    }, [newData, replaceName]);
   
    return (
        <div className={style.container}>
            <div className={style.header}>
                <CustomIcons
                    iconName="copy"
                    size={20}
                    margin={"0px"}
                    menuProps={ menuProps}
                    className={style.copyIcon}
                    onClick={
                         (!oldData || !showMenuOption)
                            ? () => {
                                  copyToClipBoard(newData);
                              }
                            : undefined
                    }
                    isButton={true}
                />
            </div>
            <div className={style.codeArea}>
                <div className={`${style.code} ${style.integrationBox}`}>
                    <ReactJsonViewCompare
                        oldData={setOldData ?? setNewData}
                        newData={setNewData ?? null}
                    />
                </div>
            </div>
        </div>
    );
};
