import { createUseStyles } from "react-jss";

import { mediaQueries } from "../../../../../common/constants";

export const UserSignupResumeDocumentsStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        padding: theme.paddings.default,
        gap:theme.gaps.sizes.base
    },
    document: {

        [mediaQueries.MOBILE]: {
            whiteSpace: "none"
        },
    },
}));
