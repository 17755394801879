import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IBoardHistoryAppointmentTypeConfiguration } from "../../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../redux/hooks";
import { updateMasterAppointmentTypeConfigurationProperty } from "../../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentTypeConfiguration/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../../../../../utils";
import { FormNumberField } from "../../../../../../../../../../common/numberField/numberField";
import { MerchandiseTypeWithGroupSelector } from "../../../../../../../../../../request/common/selectors/merchandiseTypeWithGroup/merchandiseTypeWithGroup";
import { AppointmentTypesSelector } from "../../../form/appointmentSelector/appointmentSelector";
import { MasterBoardHistoryAppointmentDockFormStyle } from "./formStyle.jss";
import { FormatNumberInput } from "../../../../../../../../../../common/numberField/utils";
import { MerchandiseTypeSelector } from "../../../../../../../../../../request/common/selectors";
import { DefaultValues } from "../../../../../../../../../../../common/enum/DefaultValues";

export const MasterBoardHistoryAppointmentDockForm = () => {
    const theme = useTheme();
    const style = MasterBoardHistoryAppointmentDockFormStyle({ theme });

    const { form } = useAppSelector((state) => state.masterAppointmentTypeConfigurationForm);
    const dispatch = useAppDispatch();
    const { control, register } = useForm<IBoardHistoryAppointmentTypeConfiguration>({ mode: "onChange" });
    const { merchandiseCategoryGroupId } = useAppSelector((store) => store.masterAppointmentBoardEdit.form);

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_TYPE_CONFIGURATION" });

    const onNumberChange = (event: any, _newValue?: any) => {
        const { name, value } = getGenericPayloadFromEvent(event);
        dispatch(updateMasterAppointmentTypeConfigurationProperty({ name: name, value: FormatNumberInput(value) }));
    };

    const onSelectorChange = (option: any, name: string) => {
        dispatch(updateMasterAppointmentTypeConfigurationProperty({ name: name, value: option.key }));
    };

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <div className={style.input}>
                    <AppointmentTypesSelector
                        title={t("APPOINTMENT_TYPE")}
                        onChange={onSelectorChange}
                        selectedKey={form.appointmentTypeId}
                        name={compileNameOfProperty<IBoardHistoryAppointmentTypeConfiguration>("appointmentTypeId")}
                        isRequired={true}
                    />
                </div>

                <div className={style.input}>
                    {merchandiseCategoryGroupId ? (
                        <MerchandiseTypeWithGroupSelector
                            filterOptions={false}
                            onChange={onSelectorChange}
                            selectedKey={String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR && form.merchandiseTypeId ? form.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                            propertyName={compileNameOfProperty<IBoardHistoryAppointmentTypeConfiguration>("merchandiseTypeId")}
                            title={t("MERCHANDISE_TYPE")}
                            control={control}
                            isRequired={false}
                            groupId={merchandiseCategoryGroupId.toString()}
                            allOption={true}
                        />
                    ) : (
                        <MerchandiseTypeSelector
                            filterOptions={false}
                            onChange={onSelectorChange}
                            selectedKey={String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR && form.merchandiseTypeId ? form.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                            propertyName={compileNameOfProperty<IBoardHistoryAppointmentTypeConfiguration>("merchandiseTypeId")}
                            title={t("MERCHANDISE_TYPE")}
                            control={control}
                            allOption={true}
                        />
                    )}
                </div>
            </div>
            <div className={style.rowForm}>
                <div className={style.input}>
                    <AppointmentTypesSelector
                        title={t("FORCED_APPOINTMENT_TYPE")}
                        onChange={onSelectorChange}
                        selectedKey={form.forcedAppointmentTypeId}
                        name={compileNameOfProperty<IBoardHistoryAppointmentTypeConfiguration>("forcedAppointmentTypeId")}  
                    />
                </div>
                <div className={style.input}>
                    <FormNumberField
                        value={form.timeZones ? String(form.timeZones) : ""}
                        label={t("TIME_ZONES")}
                        isRequired={true}
                        {...register(compileNameOfProperty<IBoardHistoryAppointmentTypeConfiguration>("timeZones"), {
                            onChange: onNumberChange,
                            required: true,
                        })}
                    />
                </div>
            </div>

            <div className={style.rowForm}>
                <FormNumberField
                    value={form.maxAppointments ? String(form.maxAppointments) : ""}
                    label={t("MAXAPPOINTMENTS")}
                    isRequired={false}
                    {...register(compileNameOfProperty<IBoardHistoryAppointmentTypeConfiguration>("maxAppointments"), {
                        onChange: onNumberChange,
                        required: false,
                    })}
                />
            </div>
        </div>
    );
};
