import { UserApi } from "../../../../api";
import { throwErrorToastrAsync } from "../../../../utils";
import { setLoadingViewPermission, setViewPermissionReceived } from "../../../reducers/user/role/view";
import { AppThunk, store } from "../../../store";

const userApi = new UserApi();

export const fetchViewsAction = (forceLoading?: boolean): AppThunk => async (dispatch) => {
    try {
        const { isViewLoaded } = store.getState().userRoleViews;
        if (isViewLoaded && !forceLoading) return;
        dispatch(setLoadingViewPermission(true));
        const response = await userApi.getUserViews();
        dispatch(setViewPermissionReceived(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    }
};
