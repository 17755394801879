import { IColumn } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../common/separator/separator";
import { MasterCustomsStatesColumnsStyle } from "./columnStyle.jss";
import { MethodsSort } from "../../../../../../../common/enum/sort/Methods";
import { SortTableName } from "../../../../../../../common/enum/sort/TableName";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterCustomsState } from "../../../../../../../models/resources/master/ICustomsState";
import { patchSortCustomsStatesList, fetchMasterCustomStates } from "../../../../../../../redux/actions/resources/master/customsStates/customsStates";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";

export const MasterCustomsStatesColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterCustomsStatesColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const { list } = useAppSelector((state) => state.masterCustomsStatesList);
    const lastIndex = list ? list.length - 1 : 0;
    const columns: IColumn[] = [
        {
            key: "sort",
            minWidth: 100,
            maxWidth: 200,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={t("PRIORITY")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState, index) => (
                <span className={styles.actions}>
                    <CustomIcons
                        className={styles.arrow}
                        iconName={"ChevronUpSmall"}
                        isDisabled={index === 0}
                        onClick={() => {
                            dispatch(patchSortCustomsStatesList(SortTableName.CustomState, item.id ?? 0, MethodsSort.UP));
                        }}
                    />

                    <Separator size="auto" />

                    <CustomIcons
                        iconName={"ChevronDownSmall"}
                        className={styles.arrow}
                        onClick={() => {
                            dispatch(patchSortCustomsStatesList(SortTableName.CustomState, item.id ?? 0, MethodsSort.DOWN));
                        }}
                        isDisabled={index === lastIndex || (index === 0 && list?.length === 1) ? true : false}
                    />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterCustomsState>("code"),
            minWidth: 70,
            maxWidth: 100,
            name: t("CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCustomsState>("code")}
                    title={t("CODE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => <span>{item.code ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },


        {
            key: compileNameOfProperty<IMasterCustomsState>("description"),
            minWidth: 400,
            maxWidth: 500,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCustomsState>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterCustomsState>("flow"),
            minWidth: 100,
            maxWidth: 200,
            name: t("FLOW"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCustomsState>("flow")}
                    title={t("FLOW")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => <span>{item.flow ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterCustomsState>("type"),
            minWidth: 100,
            maxWidth: 200,
            name: t("TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCustomsState>("type")}
                    title={t("TYPE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => <span>{item.type ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterCustomsState>("temporaryRegime"),
            minWidth: 100,
            maxWidth: 100,
            name: t("TEMPORAL_REGIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCustomsState>("temporaryRegime")}
                    title={t("TEMPORAL_REGIME")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => <span><BooleanValue value={item.temporaryRegime}/> </span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterCustomsState>("active"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCustomsState>("active")}
                    title={t("ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => <span><BooleanValue value={item.active}/></span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCustomsState) => (
                <span>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterCustomStates(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
