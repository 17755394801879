import React from "react";
import { ManagementMenuSettingsByRolContainer } from "./container";

export class ManagementMenuSettingsByRolController extends React.Component {
    componentWillUnmount(): void {
        
    }

    render(): React.ReactNode {
        return <ManagementMenuSettingsByRolContainer />;
    }
}
