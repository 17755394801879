import { DetailsRow, IDetailsFooterProps } from "@fluentui/react/lib/DetailsList";
import { useEffect } from "react";
import { useTheme } from "react-jss";
import { fetchInvoicingSummaryByConceptAction } from "../../../../redux/actions/invoicing/summaryByConcept/summaryByConcept";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { InvoicingSummaryByConceptColumn } from "./column/column";
import { IInvoiceSummaryListFooterColumn } from "./column/columnFooter";
import { InvoicingSummaryByConceptListStyle } from "./listStyle.jss";
import { sortArray } from "../../../../utils";

export const InvoicingSummaryByConceptList = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = InvoicingSummaryByConceptListStyle({ theme });
    const { list, loading, sort } = useAppSelector((state) => state.invoicingSummaryConceptList);

    useEffect(() => {
       list === undefined && dispatch(fetchInvoicingSummaryByConceptAction());
    }, [dispatch, list]);

    const onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps): JSX.Element => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };

        const columns = IInvoiceSummaryListFooterColumn(dataFooter);

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={columns}
                item={{}}
                itemIndex={5}
                groupNestingDepth={detailsFooterProps && detailsFooterProps.groupNestingDepth}
                styles={styles}
            />
        );
    };

    return (
        <>
            {!loading ? (
                <>
                    <CustomDetailsList
                        data={sortArray(list, sort) ?? []}
                        renderColumns={InvoicingSummaryByConceptColumn}
                        height={heights.invoiceListHeigt}
                        customFooter={onRenderDetailsFooter}
                    />
                </>
            ) : (
                <>
                    <Skeleton rows={5}></Skeleton>
                </>
            )}
        </>
    );
};
