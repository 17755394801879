import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { Selector } from "../../../common/selectors/selector";
import { MasterSelectorStyle } from "./masterSelectorStyle.jss";
import { sortArray } from "../../../../utils";
import { OrderColumn } from "../../../../common/enum/OrderColumn";
import { MasterAppointmentsTypes } from "../../../../common/enum/masters/masterAppointmentsType";
interface IMasterAppointmentSelectorProps {
    onChange: (_event: FormEvent<HTMLDivElement>, option: any, name: string) => any;
    selectedKey?: string;
}
export const MasterAppointmentSelector: React.FC<IMasterAppointmentSelectorProps> = ({ onChange, selectedKey }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.SELECTOR" });

    const theme = useTheme();
    const style = MasterSelectorStyle({ theme });

    const data: any[] = [
        {
            key: MasterAppointmentsTypes.APPOINTMENT_TYPES,
            text: t("APPOINTMENT_TYPE"),
        sort :1
        },
        {
            key: MasterAppointmentsTypes.APPOINTMENT_TYPE_ACTUATION,
            text: t("APPOINTMENT_TYPE_ACTUATION"),
            sort :2
        },
        {
            key: MasterAppointmentsTypes.RESTRICTION_DATE,
            text: t("RESTRICTED_DATE"),
            sort :3
        },
        {
            key: MasterAppointmentsTypes.BOARD,
            text: t("BOARD"),
            sort :4
        },
        {
            text: t("APPOINTMENT_PERMISSION"),
            key: MasterAppointmentsTypes.APPOINTMENT_PERMISSION,
            sort :5
        },
    ];



    return (
        <div className={style.container}>
            <Selector
                name={""}
                title={""}
                className={style.selector}
                options={sortArray(data, {column:"sort", orderColumn:  OrderColumn.ASC})}
                selectedKey={selectedKey}
                onChange={onChange}
            />
        </div>
    );
};
