import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ExceptionType } from "../../../../../../../../common/enum/management/ExceptionType";
import { IValidationParkingRuleException } from "../../../../../../../../models/management/parkingValidationRule/IParkingValidationRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { deleteAllExceptionRule, setExceptionRuleInspector } from "../../../../../../../../redux/reducers/management/parkingValidation/form";
import { CheckboxSelector } from "../../../../../../../common/checkboxSelector/checkboxSelector";
import { Section } from "../../section/section";
import { exceptionSectionStyle } from "../exceptionSectionStyle.jss";

export const ManagementInspectorSectionForm = () => {
    const theme = useTheme();
    const style = exceptionSectionStyle({ theme });
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const [showExceptionContent, setShowExceptionContent] = useState<boolean>(false);

    const { view } = useAppSelector((state) => state.managementParkingValidationConfiguration);
    const {
        form: { id: ruleId, validationParkingRuleExceptions },
    } = useAppSelector((state) => state.managementParkingValidationRuleForm);

    const viewParams = useMemo(
        () => view?.validationParkingExceptions.find((item) => item.id === ExceptionType.INSPECTOR),
        [validationParkingRuleExceptions],
    );

    const options = useMemo(
        () =>
            view?.validationParkingInspectorExceptionLines.map((item) => ({
                key: item.id,
                text: item.description,
                active: validationParkingRuleExceptions.some(
                    (exception) =>
                        exception.validationParkingExceptionId === viewParams?.id &&
                        exception.validationParkingInspectorExceptionLineId === item.id &&
                        (exception.deleted === false || exception.deleted === null),
                ),
            })) ?? [],
        [validationParkingRuleExceptions, viewParams],
    );

    const active = useMemo(() => {
        const activeContent = validationParkingRuleExceptions.some(
            (exception) =>
                exception.validationParkingExceptionId === ExceptionType.INSPECTOR && (exception.deleted === false || exception.deleted === null),
        );
        setShowExceptionContent(activeContent);
        return activeContent;
    }, [validationParkingRuleExceptions]);

    const onCheckboxChange = useCallback((event?: any, checked = false) => {
        if (!checked) {
            dispatch(deleteAllExceptionRule(ExceptionType.INSPECTOR));
        }
        setShowExceptionContent(checked);
    }, []);

    return (
        <Section
            title={viewParams?.description ?? ""}
            showBubble={active || showExceptionContent}
            showDisabledController={true}
            disabledControllerLabel={!active ? t("COMMON.DEACTIVATE") : t("COMMON.ACTIVE")}
            onChangeDisabledController={onCheckboxChange}
            sectionActive={active || showExceptionContent}
            value={
                <div className={style.container}>
                    <CheckboxSelector
                        options={options}
                        onChange={(name: string, checked: any, indexOption: number) => {
                            const optionSelected = options[indexOption].key;
                            if (checked) {
                                const payload: IValidationParkingRuleException = {
                                    id: null,
                                    validationParkingAppointmentExceptionHour: null,
                                    validationParkingAppointmentExceptionLineId: null,
                                    validationParkingAppointmentExceptionTypeId: null,
                                    validationParkingExceptionId: ExceptionType.INSPECTOR,
                                    validationParkingInspectorExceptionLineId: +optionSelected,
                                    validationParkingRuleId: ruleId,
                                    deleted: false,
                                };
                                dispatch(setExceptionRuleInspector(payload));
                            } else {
                                const restriction = validationParkingRuleExceptions.find(
                                    (restriction) =>
                                        restriction.validationParkingExceptionId === ExceptionType.INSPECTOR &&
                                        restriction.validationParkingInspectorExceptionLineId === +optionSelected,
                                );
                                restriction && dispatch(setExceptionRuleInspector(restriction));
                            }
                        }}
                        name={""}
                    />
                </div>
            }
        />
    );
};
