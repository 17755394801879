import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IConfigDockBlockItem } from "../../../../models/config/blockDock";

interface IConfigDockBlockFilterState {
    filter: IConfigDockBlockItem;
}

const initialState: IConfigDockBlockFilterState = {
    filter: {
        dockId: -1,
        dockBlockId:-1
    },
};

export const configDockBlockFilterSlice = createSlice({
    name: "configDockBlockFilterSlice",
    initialState,
    reducers: {
        resetConfigDockBlockFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetConfigDockBlockFilter, updateFilterProperty } = configDockBlockFilterSlice.actions;

export default configDockBlockFilterSlice.reducer;
