import { SignupResumeDataTypeStyle } from "./resumeDataType.jss";

interface Props {
  type: string;
}
export const SignupResumeDataType: React.FC<Props> = ({ type }) => {
  const styles = SignupResumeDataTypeStyle();

  return (
    <div className={styles.container}>
      <label>{type}</label>
    </div>
  );
};
