import { useTheme } from "react-jss";
import { TableIncidence } from "./list/list";
import { EditRequestIncidenceStyle } from "./containerStyle.jss";
import { RequestIncidenceType } from "../../../../../../common/enum/categoryIncidence";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
interface EditRequestTabsIncidenceContainerProps {
    schema: IConfigEditRequest;
}
export const EditRequestTabsIncidenceContainer: React.FC<EditRequestTabsIncidenceContainerProps> = ({ schema }) => {
    const theme = useTheme();
    const styles = EditRequestIncidenceStyle({ theme });

    return (
        <div className={styles.generalContainerTabIncidence}>
            {schema.incidence_organism.visible && (
                <TableIncidence
                    category={RequestIncidenceType.ORGANISM}
                    schema={schema}
                />
            )}
            {schema.incidence_operational.visible && (
                <TableIncidence
                    category={RequestIncidenceType.OPERATIONAL}
                    schema={schema}
                />
            )}
        </div>
    );
};
