import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { compileNameOfProperty, formatDate } from "../../../../utils";
import { IRequestIntegrations } from "../../../../models";
import { CustomHeaderColumn } from "../../../common/customDetailsList/customHeader";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { BooleanValue } from "../../../common/booleanValue/booleanValue";
import { VlcportHistoricListColumnStyle } from "./columnStyle.jss";
import { useAppDispatch } from "../../../../redux/hooks";
import { CustomIcons } from "../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../assets/icons";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { setRequestIntegrationForm, setRequestIntegrationFormPopUpShow } from "../../../../redux/reducers/request/requestIntegrations/form";


export const IntegrationRequestListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = VlcportHistoricListColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.HISTORIC" });
    const dispatch = useAppDispatch();
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IRequestIntegrations>("date"),
            minWidth: 100,
            maxWidth: 100,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestIntegrations>("date")}
                    title={t("DATE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IRequestIntegrations) => <span>{formatDate(item.date, FormatDate.DATE_HOUR_MINUTE)}</span>,
            isResizable: true,
        },
        
        {
            key: compileNameOfProperty<IRequestIntegrations>("integrationReference"),
            minWidth: 200,
            maxWidth: 250,
            name: t("REFERENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestIntegrations>("integrationReference")}
                    title={t("REFERENCE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IRequestIntegrations) => <span>{item.integrationReference}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestIntegrations>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 150,
            name: t("UTI_TRANSPORT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestIntegrations>("transportUnitNumber")}
                    title={t("UTI_TRANSPORT_NUMBER")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IRequestIntegrations) => <span>{item.transportUnitNumber}</span>,
            isResizable: true,
        },
        
        {
            key: compileNameOfProperty<IRequestIntegrations>("withErrors"),
            minWidth: 100,
            maxWidth: 100,
            name: t("WITH_ERROR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestIntegrations>("withErrors")}
                    title={t("WITH_ERROR")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IRequestIntegrations) => (
                <span>
                    {" "}
                    <BooleanValue value={item.withErrors} />{" "}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestIntegrations>("messageData"),
            minWidth: 100,
            maxWidth: 100,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestIntegrations>("messageData")}
                    title={""}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),

            onRender: (item: IRequestIntegrations) => (
                <span className={styles.action}>
                    <CustomIcons
                        iconName={NameIcons.EYEICON}
                        size={20}
                        tooltip={t("VIEW_ERRORS")}
                        onClick={() => {
                            dispatch(setRequestIntegrationFormPopUpShow(PopUpCodes.CREATE));
                            dispatch(
                                setRequestIntegrationForm({
                                    messaageData: item.messageData,
                                    response: item.response,
                                }),
                            );
                        }}
                    />                 
                </span>
            ),
            isResizable: true,
        },    
    ];

    return columns;
};
