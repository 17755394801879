import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserProfileEntityContainerStyle = createUseStyles((_theme: any) => ({
    entityContainer: {
        padding: 24,
        marginBottom: 50,
        display: "flex",
        [mediaQueries.TABLET]: {
            flexDirection: "column",
            padding: 12,
        },
        overflowY: "auto",
    },
    entityContent: {
        width: "66%",

        [mediaQueries.TABLET]: {
            width: "100%",
        },
        height: "calc(100vh - 19rem)",
    },
    usersContent: {
        width: "34%",

        [mediaQueries.TABLET]: {
            width: "100%",
        },
    },
}));
