import { createUseStyles } from "react-jss";

export const UserProfileAdminEmailItemStyle = createUseStyles((theme: any) =>({
    emailContainer: {
        display: "flex",
        justifyContent: "space-between",
        "& i": {
            paddingTop: theme.paddings.sizes.base,
        },
    },
    emailContent: {
        display: "flex",
        width: "100% ",
        "& i": {
            paddingTop: theme.paddings.sizes.base
        },
    },
    addEmail: {
        display: "flex",
        width: 50,
        justifyContent: "flex-end",
        "& i": {
            paddingTop: theme.paddings.sizes.base
        },
    },
   
}));
