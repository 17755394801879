import { toast } from "react-toastify";

export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export const throwErrorToastrAsync = async (error: Error | any) => {
    const errorData = error?.response?.data;

    const message =
        (errorData && `${errorData?.detail || errorData?.title} `) ||
        error?.message ||
        "Error en la comunicación con el servidor. Contacte con el administrador";

    toast.error(message);
};

export const throwSuccessToastrAsync = async (response?: Error | any) => {
    const message = response?.message || "Operación realizada con éxito";
    toast.success(message);
};

export const getFileFromBlobUrl = async (file: any) => {
    return fetch(file).then((r) => r.blob());
};
