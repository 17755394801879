import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";



import { IGenericPayload } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { IManagementUserForm, updateManagementUserProperty } from "../../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import { Checkboxs } from "../../../../../../common/checkbox/checkbox";
import { FormField } from "../../../../../../common/fields/fields";
import { GridClass } from "../../../../../../../common/enum/GridClass";
import { myProfileResolver } from "../../user/resolver";

export const ManagementCustomerFormEntityRepresentativeData = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const dispatch = useDispatch();

    const {
        register,
        formState: { errors: error },
    } = useForm<IManagementUserForm>({ mode: "onChange", resolver: myProfileResolver });

    const { generalUserData } = useAppSelector((store) => store.managementUserForm);

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            const payload: IGenericPayload = {
                name: event.target.name,
                value: checked,
            };

            dispatch(updateManagementUserProperty(payload));
        },
        [dispatch],
    );
    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            const payload: IGenericPayload = {
                name: name,
                value: value,
            };
            dispatch(updateManagementUserProperty(payload));
        },
        [dispatch],
    );

    return (
        <div className={GridClass.ROW_GRID}>
            <div className={GridClass.THREE_CELL}>
                <Checkboxs
                    label={t("ENABLE_REPRESENTATIVE")}
                    name={compileNameOfProperty<IManagementUserForm>("hasRepresentativeData")}
                    isChecked={generalUserData.hasRepresentativeData ?? false}
                    handleChange={onCheckboxChange}
                    defaultWidth={true}
                />
            </div>
            <div className={GridClass.THREE_CELL}>
                <FormField
                    type="text"
                    label={t("CRMCODE")}
                    value={generalUserData.crmCode ?? DefaultValues.NOT_TEXT}
                    error={error.crmCode}
                    {...register(compileNameOfProperty<IManagementUserForm>("crmCode"), {
                        onChange: onInputChange,
                        value: generalUserData.crmCode,
                    })}
                    isRequired={true}
                />
            </div>
            <div className={GridClass.THREE_CELL}>
                <FormField
                    type="text"
                    value={generalUserData.externalCode ?? DefaultValues.NOT_TEXT}
                    label={t("EXTERNALCODE")}
                    {...register(compileNameOfProperty<IManagementUserForm>("externalCode"), {
                        onChange: onInputChange,
                        value: generalUserData.externalCode,
                    })}
                />
            </div>
        </div>
    );
};
