import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IStatisticsOperatorIncidenceState } from "../../../../../models/statistics/IOperator";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { generateRandomKeys } from "../../../../../utils/keys";

interface IInvoicingUninvoicedColumnFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsIncidenceOperatorListColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INCIDENCE.OPERATOR.TABLE" });

    const { totalIncidences } = useAppSelector((store) => store.statisticsOperatorIncidenceList.data);
    const columns: IColumn[] = [
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(0, 1, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOperatorIncidenceState>("totalIncidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 1, props.listColumnsFooter)}>{t("TOTAL_FINAL")}</b>,
            isResizable: true,
        },
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(1, 2, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOperatorIncidenceState>("totalIncidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> {totalIncidences}</b>,
            isResizable: true,
        },
    ];

    return columns;
};
