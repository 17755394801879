import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetVlcportHistoricForm } from "../../../../redux/reducers/vlcPort/historic/form";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { VlcportHistoricForm } from "./form/form";

export const VlcportHistoryController = () => {
    const { showPopUp } = useAppSelector((state) => state.vlcportHistoricForm);
    const dispatch = useAppDispatch();
    const onCancel = () => {
        dispatch(resetVlcportHistoricForm());
    };
    const onSave = () => {
        onCancel();
    };

    return (
        <PopUpForm
            content={<VlcportHistoricForm />}
            title={""}
            isVisible={showPopUp === PopUpCodes.CREATE}
            onCancel={onCancel}
            onSave={onSave}
            width={"80vw"}
            onlyHidden={true}
            height={"calc(80vh - 10rem)"}
        />
    );
};
