import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterRepresentativePresenceList } from "./list/list";
import { setRepresentativePresenceTypes } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterPresenceRepresentativeList } from "../../../../../redux/reducers/resources/master/representativePresence/list";

export class MasterPresenceRepresentativeContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterPresenceRepresentativeList());
        store.dispatch(setRepresentativePresenceTypes([]));
    }

    render(): React.ReactNode {
        return (
            <MasterRepresentativePresenceList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
