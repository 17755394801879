import { RequestApi } from "../../../../../api";
import { IInspection } from "../../../../../models/requests/IEditRequest";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setInspections, setLoadingRequestInspection } from "../../../../reducers/request/edit/editRequest";
import { setActuationFormLoading } from "../../../../reducers/request/edit/form/actuation";
import { setEditRequestInspectionForm, setEditRequestInspectionLoading } from "../../../../reducers/request/edit/form/inspection";
import { AppThunk } from "../../../../store";
import { setGlobalLoadingAction } from "../../../common";
import { updateAppointmentValueOfRequest } from "./requestEdit";

const requestApi = new RequestApi();

export const deleteEditRequestInspection =
    (requestId: number, inspectionId: number, updateAppointmentRequest?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setEditRequestInspectionLoading(true));
            await requestApi.deleteInspectionRequests(requestId, inspectionId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchEditRequestInspection(requestId));
            dispatch(setEditRequestInspectionLoading(false));
            if (updateAppointmentRequest) {
                dispatch(updateAppointmentValueOfRequest(requestId));
            }
        }
    };

export const getEditRequestInspectionForm =
    (requestId: number, inspectionId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setEditRequestInspectionLoading(true));
            const response = await requestApi.getInspectionRequestForm(requestId, inspectionId);

            dispatch(setEditRequestInspectionForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setEditRequestInspectionLoading(false));
        }
    };

export const fetchEditRequestInspection =
    (requestId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingRequestInspection(true));
            const response = await requestApi.getInspectionsRequests(requestId);
            dispatch(setInspections(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingRequestInspection(false));
        }
    };

export const postEditRequestInspection = (requestId: number, inspection: IInspection): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setEditRequestInspectionLoading(true));
            if (inspection.id) {
                await requestApi.updateInspectionRequest(requestId, inspection);
            } else {
                await requestApi.createInspectionRequest(requestId, inspection);
            }
            dispatch(fetchEditRequestInspection(requestId));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setEditRequestInspectionLoading(false));
            return false;
        }
    };
};

export const postAllInspectionNewActuation =
    (requestId: number, actuations: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.postSetMultipleActuations(requestId, actuations);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchEditRequestInspection(requestId));
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const deleteInspectionActuation = (requestId: number, inspectionId: number, actuations: any, updateAppointmentRequest?: boolean): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setActuationFormLoading(true));
            await requestApi.deleteRequestInspectionActuation(requestId, inspectionId, actuations);
            dispatch(fetchEditRequestInspection(requestId));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            if (updateAppointmentRequest) {
                dispatch(updateAppointmentValueOfRequest(requestId));
            }
            dispatch(setActuationFormLoading(false));
        }
    };
};
