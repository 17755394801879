import { Dispatch } from "@reduxjs/toolkit";
import { setGlobalLoadingAction } from "../../redux/actions/common";
import { setConcurrency } from "../../redux/reducers/request/edit/editRequest";
import { throwErrorToastrAsync } from "../../utils";
import { HttpStatus } from "../enum/api/HttpStatus";

export const handleError = (error: Error | any, dispatch: Dispatch<any>, specificAction?: (error: Error | any, dispatch: Dispatch<any>) => void) => {
    if (error.response.status === HttpStatus.CONFLICT) {
        dispatch(setGlobalLoadingAction(false));
        dispatch(
            setConcurrency({
                isError: true,
                message: error.response.data.detail,
            }),
        );
    } else {
        specificAction && specificAction(error, dispatch);
        throwErrorToastrAsync(error);
        dispatch(setGlobalLoadingAction(false));
    }
};
