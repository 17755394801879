import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IAppointmentPermission } from "../../../../models/masterAppointment/IAppointmentPermission";

export interface IMasterAppointmentPermissionFormState {
    form: IAppointmentPermission;
    loading: boolean;
}

const initialState: IMasterAppointmentPermissionFormState = {
    form: {
        merchandiseCategoryGroupId: 0,
        transportUnitId: 0,
    },
    loading: false,
};

export const masterAppointmentPermissionForm = createSlice({
    name: "masterAppointmentPermissionForm",
    initialState,
    reducers: {
        resetMasterAppointmentPermissionForm: () => initialState,
        setLoadingMasterAppointmentPermissionForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentPermissionForm: (state, action: PayloadAction<IAppointmentPermission>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentPermissionProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterAppointmentPermissionForm,
    setLoadingMasterAppointmentPermissionForm,
    setMasterAppointmentPermissionForm,
    updateMasterAppointmentPermissionProperty,
} = masterAppointmentPermissionForm.actions;

export default masterAppointmentPermissionForm.reducer;
