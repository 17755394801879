import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const UserSignupEntityInfoStyle = createUseStyles((theme: any) => ({
    row: {
        boxSizing: "border-box",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
        [mediaQueries.MOBILE] : {
            display:"flex", 
            flexDirection:"column", 
            gap:35
        }
    },
    column: {
        flexBasis: "100%",
    },

    "column-70": {
        flexBasis: "60%",
    },
    title: {
        flexBasis: "100%",
    },
}));
