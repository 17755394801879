import { createUseStyles } from "react-jss";

export const FieldsStyle = createUseStyles((theme: any) => ({
    formGroup: {
        position: "relative",
        height: 31.5,
        width: "100%",
        " & input:focus + label": {
            color: theme.colors.form.input.focus,
            fontSize: theme.fonts.sizes.xs,
            transform: "translateY(0)",
            top: -10,
        },
        " & .filled": {
            fontSize: theme.fonts.sizes.xs,
            transform: "translateY(0)",
            top: -10,
        },
        " & label": {
            fontFamily: theme.fonts.families.site,
            top: -10,
        },
    },
    formGroupTextArea: {
        position: "relative",
        height: 60,
        width: "100%",
        paddingBottom: 7,
        " & textarea": {
            backgroundColor: "transparent",
            border: "0px solid",
            borderBottom: `1.3px solid ${theme.colors.form.input.default}`,
            marginTop: 2,
            padding: "5px 0 0 0",
            width: "100%",
        },
        " & textarea:disabled": {
            borderBottom: theme.borders.selector.disabledBorder,            
            fontWeight: "200",
        },
        " & textarea:focus + label": {
            color: theme.colors.form.input.focus,
            fontSize: theme.fonts.sizes.xs,
            transform: "translateY(0)",
            top: -10,
        },
        " & .filled": {
            fontSize: theme.fonts.sizes.xs,
            transform: "translateY(0)",
            top: -10,
        },
        " & label": {
            top: -10
        }
    },
    error: {
        fontFamily: theme.fonts.families.site,
        fontSize: theme.fonts.sizes.xxs,
        color: theme.colors.form.input.invalid,
        padding: theme.paddings.forms.errorsMessage.defaultPadding,
    },
    inputNumberCallout : {
        position:"absolute",
        top:-10
    }
}));
