import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterDocumentTypesForm, setMasterDocumentTypesForm } from "../../../../reducers/resources/master/documentTypes/form";
import { setLoadingMasterDocumentTypesList, setMasterDocumentTypesList } from "../../../../reducers/resources/master/documentTypes/list";
import { AppThunk, store } from "../../../../store";

const catalogAPI = new CatalogAPI();

export const fetchMasterDocumentTypesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterDocumentTypesList(true));
        const response = await catalogAPI.getMasterDocumentTypesList();
        dispatch(setMasterDocumentTypesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterDocumentTypesList(false));
    }
};

export const fetchMasterDocumentType =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterDocumentTypesForm(true));
            const response = await catalogAPI.getMasterDocumentType(id);
            dispatch(setMasterDocumentTypesForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterDocumentTypesForm(false));
        }
    };

export const postToMasterDocumentTypeForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterDocumentTypesForm;

        try {
            dispatch(setLoadingMasterDocumentTypesForm(true));
            if (form.id) {
                await catalogAPI.putMasterDocumentType(form);
            } else {
                await catalogAPI.postMasterDocumentType(form);
            }
            dispatch(fetchMasterDocumentTypesList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterDocumentTypesForm(false));
            return false;
        }
    };
};

export const patchSortDocumentTypesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterDocumentTypesList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterDocumentTypesList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterDocumentTypesList(false));
        }
    };
