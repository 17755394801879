import React, { useEffect, useMemo, useState } from "react";
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles, IDropdownOption } from "@fluentui/react";
import { numericDefaultValue } from "../../../../../utils";
import { MultipleSelectorFilterStyle } from "./multipleSelectorFilterStyle.jss";
import { useTheme } from "react-jss";

interface IMultipleSelectorFilterprops {
    selectedKeys: number[];
    onChange: (name: string, value: any) => any;
    options: IComboBoxOption[];
    label: string;
    isDisabled?: boolean;
    propertyName: string;
    isRequired?: boolean;
}

export const MultipleNumberSelectorFilter: React.FC<IMultipleSelectorFilterprops> = ({
    label,
    onChange,
    options,
    selectedKeys,
    isDisabled,
    propertyName,
}) => {
    const theme = useTheme();
    const style = MultipleSelectorFilterStyle({ theme });

    const comboBoxStyles: Partial<IComboBoxStyles> = {
        container: style.root,
        label: style.label,
        input: style.inputText,
        optionsContainer: style.optionsContainer,
    };

    const comboBoxRef = React.useRef<IComboBox>(null);
    const onOpenClick = React.useCallback(() => comboBoxRef.current?.focus(true), []);

    const [filteredText, setFilteredText] = useState<string>("");

    const handleChange = (option?: IDropdownOption) => {
        if (option) {
            if (Number(option.key) === Number(numericDefaultValue.key) && option.selected) {
                onChange(propertyName, [Number(numericDefaultValue.key)]);
            } else {
                const newKeys = option?.selected ? [...selectedKeys, Number(option.key)] : selectedKeys.filter((key) => key !== Number(option.key));

                let newKeysFiltered: number[] = [];
                switch (newKeys.length) {
                    case 1:
                        newKeysFiltered = newKeys;
                        break;
                    case 0:
                        newKeysFiltered = [Number(numericDefaultValue.key)];
                        break;
                    default:
                        const optionsWithoutDefaultValue = newKeys.filter((key) => key !== Number(numericDefaultValue.key));
                        newKeysFiltered =
                            options.length === optionsWithoutDefaultValue.length ? [Number(numericDefaultValue.key)] : optionsWithoutDefaultValue;
                        break;
                }
                onChange(propertyName, newKeysFiltered);
            }
        }
    };
    const optionsWithDefault = useMemo(() => {
        const defaultValueArray: IDropdownOption[] = [numericDefaultValue];
        return defaultValueArray.concat(options);
    }, [options]);

    useEffect(() => {
        filteredText && setFilteredText("");
    }, []);
    const onRenderItem = (option: any, defaultRender: any) => {
        return <div style={{ display: "flex", alignItems: "center" }}>{defaultRender(option)}</div>;
    };

    return (
        <div className={style.container}>
            <ComboBox
                componentRef={comboBoxRef}
                multiSelect
                autoComplete="off"
                selectedKey={selectedKeys}
                label={label}
                options={
                    !filteredText
                        ? optionsWithDefault
                        : optionsWithDefault.filter((item) => {
                              let filter = filteredText && item.text.toLocaleLowerCase().includes(filteredText.toLocaleLowerCase());
                              return filter;
                          })
                }
                onChange={(_: any, option: any) => {
                    handleChange(option);
                }}
                onRenderItem={onRenderItem}
                allowFreeform={true}
                styles={comboBoxStyles}
                onInputValueChange={(value: any) => {
                    onOpenClick();
                    setFilteredText(value);
                }}
                onClick={() => onOpenClick()}
                disabled={isDisabled}
            />
        </div>
    );
};
