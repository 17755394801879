import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterDisplayDoock } from "../../../../../models/resources/master/IDisplayDock";

export interface IMasterDocksFormState {
    form: IMasterDisplayDoock;
    loading: boolean;
}

const initialState: IMasterDocksFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterDocksForm = createSlice({
    name: "masterDisplayDocksForm",
    initialState,
    reducers: {
        resetMasterDocksForm: () => initialState,
        setLoadingMasterDocksForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterDocksForm: (state, action: PayloadAction<IMasterDisplayDoock>) => {
            state.form = action.payload;
        },
        updateMasterDocksProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterDocksForm, setLoadingMasterDocksForm, setMasterDocksForm, updateMasterDocksProperty } = masterDocksForm.actions;

export default masterDocksForm.reducer;
