import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { IUserOrganization } from "../../../../../models";
import { IStatisticsFilter } from "../../../../../models/statistics/IStatisticsFilters";
import { getUserOrganization } from "../../../../../redux/selectors/user/organization/organization";
import { compileNameOfProperty } from "../../../../../utils";
import { MultipleCertificateSelector } from "../../../../request/common/selectors/certificate/multipleCertificate";
import { MultipleFinalDestinationSelector } from "../../../../request/common/selectors/finalDestination/multipleFinalDestination";
import { MultipleInspectorRoleSelector } from "../../../../request/common/selectors/inspector/multipleInspectors";
import { MultipleOrganismSelector } from "../../../../request/common/selectors/organism/multipleOrganism";
import { StatisticsExpedientInfoPivotFilterStyle } from "./expedientStyle.jss";
import { OrganizationTypes } from "../../../../../common/enum";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onMultipleStringKeysChange: (name: string, options: string[]) => void;
    filter: IStatisticsFilter;
}

export const StatisticsExpedientInfoPivotFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, onMultipleStringKeysChange, filter }) => {
    const theme = useTheme();
    const styles = StatisticsExpedientInfoPivotFilterStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.FILTER" });
    const userOrganization: IUserOrganization = useSelector(getUserOrganization);

    return (
        <div className={styles.requestExpedientInfoContainer}>
            <div className={styles.filterItem}>
                <MultipleCertificateSelector
                    selectedKeys={filter.certificateTypeIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("certificateTypeIds")}
                    title={t("CERTIFICATE_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>

            <div className={styles.filterItem}>
                <MultipleOrganismSelector
                    selectedKeys={filter.organismIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("organismIds")}
                    title={t("ORGANISM")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>

            <div className={styles.filterItem}>
                <MultipleInspectorRoleSelector
                    selectedKeys={filter.inspectorIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("inspectorIds")}
                    title={t("INSPECTOR")}
                    onChange={onMultipleStringKeysChange}
                    autocomplete={false}
                />
            </div>

            <div className={styles.filterItem}>
                {userOrganization.id === OrganizationTypes.VLC && (
                    <MultipleFinalDestinationSelector
                        selectedKeys={filter.finalDestinationTypeIds}
                        propertyName={compileNameOfProperty<IStatisticsFilter>("finalDestinationTypeIds")}
                        title={t("FINALDESTINATION")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                )}
            </div>
        </div>
    );
};
