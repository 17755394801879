import { createUseStyles } from "react-jss";

export const appointmentFormStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        gap: 15,
        alignItems: "end",
        maxHeight: 25,
    },
    ckeckBox: {
        minWidth: 100,
    },
    formField: {
        width: 150,
        "& > div > input": { padding: "5px 7px" },
    },
}));
