import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { ICreateIncidence } from "../../../../../models/requests/IRequestIncidence";

interface EditRequestIncidenceState {
    form: ICreateIncidence;
    loadingForm: boolean;
}

const initialState: EditRequestIncidenceState = {
    form: {
        typeId: 0,
        remarks: "",
        category: "",
    },
    loadingForm: false,
};

export const incidenceSlice = createSlice({
    name: "editRequestReducer",
    initialState,
    reducers: {
        updateIncidenceFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setIncidenceFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loadingForm = action.payload;
        },
        setIncidenceFormData: (state, action: PayloadAction<ICreateIncidence>) => {
            state.form = action.payload;
        },

        resetIncidenceFormDataState: () => initialState,

        resetIncidenceFormData: (state) => {
            state.form = {
                typeId: 0,
                remarks: "",
                category: "",
            };
        },
        updateIncidenceOrganismId: (state, action: PayloadAction<number>) => {
            state.form.organismId = action.payload;
        },
    },
});

export const {
    updateIncidenceFormProperty,
    resetIncidenceFormDataState,
    setIncidenceFormLoading,
    setIncidenceFormData,
    resetIncidenceFormData,
    updateIncidenceOrganismId,
} = incidenceSlice.actions;

export default incidenceSlice.reducer;
