import { IPivotStyles, IStyleSet, Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { generatePath, useNavigate, useParams } from "react-router";
import { mediaQueries, routeUrls } from "../../../../../../../common/constants";
import { FormatDate } from "../../../../../../../common/enum/dateTime/FormatDate";
import { fetchMasterAppointmentBoard } from "../../../../../../../redux/actions/masterAppointment/appointmentBoard/appointmentBoard";
import { fetchMasterAppointmentTypeList } from "../../../../../../../redux/actions/masterAppointment/appointmentType/appointmentType";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { resetAppointmentBoardEditForm } from "../../../../../../../redux/reducers/masterAppointment/appointmentBoard/edit";
import { formatDate } from "../../../../../../../utils";
import { NavHeaderComponent } from "../../../../../../common/navHeader/navHeader";
import { AppointmentBoardEditControllerStyle } from "./controllerStyle.jss";
import { AppointmentDockController } from "./tabs/appointmentDock/container";
import { MasterBoardHistoryAppointmentDockTimeZoneConfigurationContainer } from "./tabs/appointmentDockTimeZoneConfiguration/container";

import { AppointmentTimeZonesController } from "./tabs/appointmentTimeZones/container";
import { AppointmentTypeConfigurationContainer } from "./tabs/appointmentTypeConfiguration/container";

export const AppointmentBoardEditController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD" });
    const theme = useTheme() as any;
    const style = AppointmentBoardEditControllerStyle({ theme });

    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const { loading } = useAppSelector((store) => store.masterAppointmentBoardEdit);
    const appointmentTypes = useAppSelector((state) => state.masterAppointmentTypesList);

    const {
        date,
        merchandiseCategoryGroupDescription,
        appointmentDocks,
        appointmentTimeZones,
        appointmentDockTimeZoneConfigurations,
        appointmentTypeConfigurations,
    } = useAppSelector((store) => store.masterAppointmentBoardEdit.form);

    const onBackMasterAppointment = () => {
        const path = generatePath(routeUrls.MASTER_APPOINTMENT);
        navigate(path);
    };

    const showDate = () => {
        return date && formatDate(date, FormatDate.DATE).toString();
    };

    const pivotStyles: Partial<IStyleSet<IPivotStyles>> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 150,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, [theme.colors, theme.fonts]);
    useEffect(() => {
        id && !loading && dispatch(fetchMasterAppointmentBoard(id));
        id && !appointmentTypes && dispatch(fetchMasterAppointmentTypeList());
        return () => {
            dispatch(resetAppointmentBoardEditForm());
        };
    }, [id, dispatch]);
    return (
        <>
            <NavHeaderComponent
                backHandleClick={onBackMasterAppointment}
                title={merchandiseCategoryGroupDescription ? `${merchandiseCategoryGroupDescription} - ${showDate()}` : showDate()}
            />
            <div className={style.container}>
                <Pivot styles={pivotStyles}>
                    <PivotItem headerText={t("APPOINTMENT")}>
                        <AppointmentDockController list={appointmentDocks} />
                    </PivotItem>
                    <PivotItem headerText={t("ZONES")}>
                        <AppointmentTimeZonesController list={appointmentTimeZones} />
                    </PivotItem>
                    <PivotItem headerText={t("APPOINTMENT_CONDITIONS.TABB")}>
                        <MasterBoardHistoryAppointmentDockTimeZoneConfigurationContainer list={appointmentDockTimeZoneConfigurations} />
                    </PivotItem>
                    <PivotItem headerText={t("APPOINTMENT_TYPE")}>
                        <AppointmentTypeConfigurationContainer list={appointmentTypeConfigurations} />
                    </PivotItem>
                </Pivot>
            </div>
        </>
    );
};
