import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterMerchandiseTypesForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { postToMasterMerchandiseTypesForm } from "../../../../../../redux/actions/resources/master/merchandiseTypes/merchandiseTypes";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { resetMasterMerchandiseTypesForm } from "../../../../../../redux/reducers/resources/master/merchandiseTypes/form";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterMerchandiseTypesControllerForm {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterMerchandiseTypesControllerForm: React.FC<IMasterMerchandiseTypesControllerForm> = ({ widthPopUp, heigthPopUp }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { loading, form } = useAppSelector((state) => state.masterMerchandiseTypesForm);

    const onDimissed = () => {
        dispatch(resetMasterMerchandiseTypesForm());
    };
    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };

    const onSavePopUp = () => {
        dispatch(postToMasterMerchandiseTypesForm()).then((response: any) => {
            response && onCancelPopUp();
        });
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.MERCHANDISE_TYPE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.MERCHANDISE_TYPE");
        }

        return "";
    }, [showPopUp]);

    const isShowPopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, false, undefined);
    }, [form]);

    return (
        <PopUpForm
            content={<MasterMerchandiseTypesForm />}
            title={titlePopUp}
            isVisible={isShowPopUp}
            onCancel={onCancelPopUp}
            onSave={onSavePopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
