import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { postRequestInvoiceConcepts } from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import {
    resetEditRequestInvoiceConceptFormDataState,
    setEditRequestInvoiceConceptFormPopUp,
} from "../../../../../../../redux/reducers/request/edit/form/concept";
import { heights } from "../../../../../../../styles/romeu/heights";
import { widths } from "../../../../../../../styles/romeu/widths";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../../../../common/requiredMessage/requiredMessage";
import { EditRequestInvoiceConceptTariffForm } from "./form/concept/form";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";

export const EditRequestInvoiceConceptConceptFormController = () => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.TITLES" });

    const { headerRequest } = useAppSelector((state) => state.editRequest);
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });

    const { form, loading, showPopUp } = useAppSelector((state) => state.editRequestInvoiceConceptForm);

    const dispatch = useAppDispatch();
    const onDimissed = () => {
        dispatch(resetEditRequestInvoiceConceptFormDataState());
    };
    const onCloseInvoiceConceptForm = () => {
        dispatch(setEditRequestInvoiceConceptFormPopUp(PopUpCodes.NONE));
    };

    const onSaveInvoiceConceptForm = () => {
        dispatch(postRequestInvoiceConcepts(headerRequest.id, form)).then((response: any) => {
            response && onCloseInvoiceConceptForm();
        });
    };

    const isDisabledButton = useMemo(() => {
        let keys: string[] = ["tariffId", "quantity", "unitPrice"];
        let item: any = form;
        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }

        return false;
    }, [form]);

    const isShowPopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false;
    }, [showPopUp]);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("NEWCONCEPT");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("EDITCONCEPT");
        }
        return "";
    }, [showPopUp]);

    return (
        <PopUpForm
            isVisible={isShowPopUp}
            onCancel={onCloseInvoiceConceptForm}
            onSave={onSaveInvoiceConceptForm}
            content={<EditRequestInvoiceConceptTariffForm />}
            height={isMobile ? heights.editRequest.invoiceConcept.mobileHeight : heights.editRequest.invoiceConcept.height}
            width={isMobile ? widths.editRequest.invoiceConcept.mobileWidth : widths.editRequest.invoiceConcept.width}
            title={titlePopUp}
            isDisabledButton={isDisabledButton}
            leftButtonContent={<RequiredFieldsMessage></RequiredFieldsMessage>}
            isLoading={loading}
            onDimissed={onDimissed}
        />
    );
};
