import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const InvoicingFilterStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        gap: theme.gaps.filter.container,
        alignItems: "end",
    },
    selector: {
        width: theme.widths.selector.md,
    },
    verticalWhiteSeparator: {
        height: "175%",
        marginBottom: -15,
        borderLeft: theme.borders.default.whiteBorder,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    },
}));
