import { Icon } from "@fluentui/react";
import { useState } from "react";
import { useTheme } from "react-jss";
import { ISiteNavItemProps, SiteNavItem } from "../siteNavItem/siteNavItem";
import { SiteNavGroupStyles } from "./siteNavGroup.jss";

export interface ISiteNavGroupProps {
    title?: string;
    featureId: string;
    items: ISiteNavItemProps[];
    onClick?: () => void;
    folded?: boolean;
    onFold: (featureIdChange: string, foldedChange: boolean) => void;
}

export const SiteNavGroup = (props: ISiteNavGroupProps) => {
    const theme = useTheme();
    const styles = SiteNavGroupStyles({ theme });
    const [isFolded, setFolded] = useState<boolean>(props.folded ?? false);

    const onClickFold = () => {
        const currentFolded = isFolded;
        setFolded(!isFolded);
        props.onFold(props.featureId, !currentFolded);
    };

    return (
        <>
            <div className={styles.group}>
                <div
                    className={styles.containerTitle}
                    onClick={onClickFold}
                >
                    <h2>{props.title}</h2>
                    <Icon
                        iconName="ChevronDownIcon"
                        className={isFolded ? styles.iconArrow : styles.iconArrowUp}
                    />
                </div>
                {!isFolded && (
                    <ul>
                        {props.items?.map((item, index) => (
                            <li key={index}>
                                <SiteNavItem
                                    name={item.name}
                                    baseUri={item.baseUri}
                                    id={item.id}
                                    openNewWindow={item.openNewWindow}
                                    onClick={props.onClick}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
};
