import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IIncidenceTypesItem } from "../../../../models/management/incidenceType/IIncidenceType";

interface ManagementIncidenceTypeListState {
    list?: IIncidenceTypesItem[];
    sort: ISortState;
    loading: boolean;
}

const initialState: ManagementIncidenceTypeListState = {
   
    sort: emptySortState(),
    loading: false,
};

export const managementIncidenceTypeListSlice = createSlice({
    name: "managementIncidenceTypeListSlice",
    initialState,
    reducers: {
        resetManagementIncidenceTypeList: () => initialState,

        setLoadingManagementIncidenceTypeList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementIncidenceTypeList: (state, action: PayloadAction<IIncidenceTypesItem[]>) => {
            state.list = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
    },
});

export const { resetManagementIncidenceTypeList, setLoadingManagementIncidenceTypeList, fetchManagementIncidenceTypeList, updateSortColumn } =
    managementIncidenceTypeListSlice.actions;

export default managementIncidenceTypeListSlice.reducer;
