import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentPermission } from "../../../../models/masterAppointment/IAppointmentPermission";

export interface IMasterAppointmentPermissionListState {
    list?: IAppointmentPermission[];
    loading: boolean;
}

const initialState: IMasterAppointmentPermissionListState = {
    loading: false,
};

export const masterAppointmentPermissionList = createSlice({
    name: "masterAppointmentPermissionList",
    initialState,
    reducers: {
        resetMasterAppointmentPermissionList: () => initialState,
        setLoadingMasterAppointmentPermissionList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentPermissionList: (state, action: PayloadAction<IAppointmentPermission[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterAppointmentPermissionList, setLoadingMasterAppointmentPermissionList, setMasterAppointmentPermissionList } =
    masterAppointmentPermissionList.actions;

export default masterAppointmentPermissionList.reducer;
