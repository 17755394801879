import { MasterAppointmentDockBoardAPI } from "../../../../../api/masterAppointment/appointmentBoard/AppointmentDock";
import { IBoardHistoryAppointmentDock } from "../../../../../models/masterAppointment/IAppointmentBoard";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterAppointmentDockForm } from "../../../../reducers/masterAppointment/appointmentBoard/appointmentDock/form";
import { fetchMasterAppointmentBoard } from "../appointmentBoard";

const appointmentApi = new MasterAppointmentDockBoardAPI();

export const postMasterAppointmentDock = (dashboardId: string, form: IBoardHistoryAppointmentDock): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentDockForm(true));
            const response = await appointmentApi.postMasterAppointmentDockBoard(dashboardId, form); dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);dispatch(setLoadingMasterAppointmentDockForm(false));
            return false;
        } 
    };
};

export const updateMasterAppointmentDock = (dashboardId: string, id: string, form: IBoardHistoryAppointmentDock): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentDockForm(true));
            const response = await appointmentApi.updateMasterAppointmentDockBoard(dashboardId, id, form);
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentDockForm(false));
            return false;
        }
    };
};

export const deleteMasterAppointmentDock = (dashboardId: string, id: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentDockForm(true));
            const response = await appointmentApi.deleteMasterAppointmentDockBoard(dashboardId, id);
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingMasterAppointmentDockForm(false));
            dispatch(fetchMasterAppointmentBoard(dashboardId));
        }
    };
};
