import { createUseStyles } from "react-jss";

export const RequestListCardStyle = createUseStyles((theme: any) => ({
    card: {
        backgroundColor: "transparent",
        padding: 5,
    },
    cardContent: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignContent: "space-around",
        backgroundColor: theme.colors.palette.white,
        padding: theme.paddings.default,
        width: "100%",
        minHeight: "65px",
        borderRadius: 5,
        border: `1px solid ${theme.colors.palette.greyLight}`,
    },
    containerHeader:(props:any)=>( {
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
        borderBottom:props.showCardData? `1px solid ${theme.colors.palette.greyLight}` : "",
    }),
    containerHidden: {
        display: "flex",
        flexDirection: "column",
        alignContent: "space-around",
        width: "100%",
        overflow: "auto",
        transition: "height ease 0.3s",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      
        "&::-webkit-scrollbar ": {
            display: "none",
        },
    },
    content: {
        width: "100%",
        borderBottom: `1px solid ${theme.colors.palette.greyLight}`,
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignContent: "space-around",
        height:"100%"
    },
    containerFullWidth: {
        flexWrap: "unset",
        width: "100%",
        borderBottom: `1px solid ${theme.colors.palette.greyLight}`,
    },
    containerInspections: {
        flexWrap: "unset",
        width: "100%",
    },
    inspections: {
        display: "flex",
        gap: 5,
        width: "100%",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        flex: "1 1 auto",
        padding: 15,
    },
    title: {
        fontSize: 10,
        textTransform: "uppercase",
    },
    description: {
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: 13,
    },
    iconEditIcon: {
        width: "100%",
        "& i": {
            width: 15,
            fill: "white !important",
        },
    },
    iconDropDownActivated: {
        color: `${theme.colors.palette.purple} !important`,
        fontSize: 25,
    },
    cardListContainer: {
        height: "calc(100vh - 350px)",
        overflow: "auto",
    },
    iconColumn: {
        display: "flex",
        justifyContent: "end",
        gap: 10,
        marginTop: -25,
    },
    actionRow: {
        display: "flex",
        flexWrap: "nowrap",
        height: "auto",
        backgroundColor: "red",
    },
    stateContainer: {
        display: "block",
        color: theme.colors.palette.white,
        borderRadius: theme.borders.default.radius,
        padding: 3,
        width: 120,
        position: "relative",
        textAlign: "center",
    },
    "state-0": {},
    "state-10": {
        backgroundColor: theme.colors.appointmentBoardStates.greyStatusContainer,
    },
    "state-20": {
        backgroundColor: theme.colors.appointmentBoardStates.yellowStatusContainer,
    },
    "state-30": {
        backgroundColor: theme.colors.appointmentBoardStates.yellowStatusContainer,
    },
    "state-40": {
        backgroundColor: theme.colors.appointmentBoardStates.orangeStatusContainer,
    },
    "state-50": {
        backgroundColor: theme.colors.appointmentBoardStates.redStatusContainer,
    },
    "state-60": {
        backgroundColor: theme.colors.appointmentBoardStates.greenLigthStatusCode,
    },
    "state-70": {
        backgroundColor: theme.colors.appointmentBoardStates.redStatusContainer,
    },
    "state-80": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-90": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-100": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-110": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
    "state-120": {
        backgroundColor: theme.colors.appointmentBoardStates.greenStatusContainer,
    },
}));
