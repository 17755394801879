import { IDropdownOption } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { FormField } from "../../../../../../common/fields/fields";
import { BooleanSelector, MerchandiseOriginSelector } from "../../../../../../request/common/selectors";
import { useTheme } from "react-jss";
import { MasterTypesWareFormStyle } from "./formStyle.jss";
import { IMasterMerchandiseType } from "../../../../../../../models/resources/master/IMerchandiseTypes";
import { updateMasterMerchandiseTypesProperty } from "../../../../../../../redux/reducers/resources/master/merchandiseTypes/form";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";

export const MasterMerchandiseTypesForm = () => {
    const theme = useTheme();
    const style = MasterTypesWareFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { form } = useAppSelector((state) => state.masterMerchandiseTypesForm);
    const { register, control } = useForm<IMasterMerchandiseType>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateMasterMerchandiseTypesProperty({ name, value }));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = useCallback(
        (option: any, name: string) => {
            onUpdateFormProperty(name, option.key);
        },
        [dispatch],
    );

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code}
                    {...register(compileNameOfProperty<IMasterMerchandiseType>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? undefined,
                    })}
                    isRequired={true}
                    maxLength={64}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? undefined}
                    disabled={false}
                    {...register(compileNameOfProperty<IMasterMerchandiseType>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? undefined,
                    })}
                    isRequired={true}
                    maxLength={64}
                />
            </div>

            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.humanConsumption}
                    propertyName={compileNameOfProperty<IMasterMerchandiseType>("humanConsumption")}
                    title={t("HUMAN_CONSUMPTION")}
                    control={control}
                    isRequired={true}
                />

                <MerchandiseOriginSelector
                    onChange={onSelectorChange}
                    selectedKey={String(form.merchandiseOriginId) !== DefaultValues.DEFAULT_ALL_SELECTOR && form.merchandiseOriginId ? form.merchandiseOriginId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    propertyName={compileNameOfProperty<IMasterMerchandiseType>("merchandiseOriginId")}
                    title={t("MERCHANDISE_ORIGIN")}
                    control={control}
                    allOption={true}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.parkingAuthorization}
                    propertyName={compileNameOfProperty<IMasterMerchandiseType>("parkingAuthorization")}
                    title={t("PARKINGAUTHORIZATION")}
                    control={control}
                    isRequired={true}
                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterMerchandiseType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                />
            </div>
        </div>
    );
};
