import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
import { fetchRequestInvoiceConcepts } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { setHasInvoiceConcepts, setInvoicedRequest } from "../../../../../../redux/reducers/request/edit/editRequest";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { EditRequestTabsInvoiceConceptRemarksForm } from "./controller/form/remarks/form";
import { EditRequestInvoiceItem } from "./invoice/invoice";
import { EditRequestTabsInvoiceConceptContainerStyle } from "./containerStyle.jss";
import { EditRequestInvoiceConceptConceptFormController } from "./controller/concept";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";

import { setEditRequestInvoiceConceptFormPopUp } from "../../../../../../redux/reducers/request/edit/form/concept";

interface IEditRequestTabsInvoiceConceptContainerProps {
    schema: IConfigEditRequest;
}

export const EditRequestTabsInvoiceConceptContainer: React.FC<IEditRequestTabsInvoiceConceptContainerProps> = ({ schema }) => {
    const theme = useTheme();
    const style = EditRequestTabsInvoiceConceptContainerStyle({ theme });
    const dispatch = useAppDispatch();

    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const { headerRequest, invoicesConcepts } = useAppSelector((state) => state.editRequest);

    const showButtonContainer = schema.invoiceConcept_invoiced.visible || schema.invoiceConcept_create.visible;

    const onClickShowConcept = () => {
        dispatch(setInvoicedRequest(!!!headerRequest.invoiced));
    };

    useEffect(() => {
        dispatch(setHasInvoiceConcepts(!!invoicesConcepts.invoices && invoicesConcepts.invoices.length > 0));

        if (invoicesConcepts.invoices === undefined) {
            dispatch(fetchRequestInvoiceConcepts(headerRequest.id));
        }
    }, [invoicesConcepts.invoices]);

    const showRequestInvoiceConcept = useMemo(() => {
        return schema.invoiceConceptAdmin.visible || (headerRequest.invoiced && schema.invoiceConcept.visible);
    }, [headerRequest, schema]);
    const list = useMemo(() => {
        return invoicesConcepts.invoices ?? [];
    }, [invoicesConcepts.invoices]);
    return (
        <div className={style.invoiceConceptRequestGeneralContainer}>
            {showButtonContainer && (
                <div className={style.buttonActionsContainer}>
                    {schema.invoiceConcept_create.visible && (
                        <ButtonPrimaryInverseWithBorder
                            handleClick={() => {
                                dispatch(setEditRequestInvoiceConceptFormPopUp(PopUpCodes.CREATE));
                            }}
                            title={`+ ${t("BUTTONS.NEWCONCEPT")}`}
                        />
                    )}

                    {schema.invoiceConcept_invoiced.visible && (
                        <ButtonPrimaryInverseWithBorder
                            title={t(!headerRequest.invoiced ? "BUTTONS.SHOW_CONCEPTS_CLIENT" : "BUTTONS.HIDE_CONCEPTS_CLIENT")}
                            handleClick={onClickShowConcept}
                        />
                    )}
                </div>
            )}

            <EditRequestTabsInvoiceConceptRemarksForm schema={schema} />

            {showRequestInvoiceConcept &&
                (invoicesConcepts.loading ? (
                    <Skeleton
                        rows={5}
                        marginRow={10}
                    />
                ) : (
                    <div className={style.invoiceContainerData}>
                        {list.length > 0 ? (
                            list.map((item) => {
                                return (
                                    <EditRequestInvoiceItem
                                        billing={item}
                                        schema={schema}
                                    />
                                );
                            })
                        ) : (
                            <>{t("FIELDS.NOT_DATA")}</>
                        )}
                    </div>
                ))}

            <EditRequestInvoiceConceptConceptFormController />
        </div>
    );
};
