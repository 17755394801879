import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux/hooks";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { setPopUpConfigOrganismCertificateTypeForm } from "../../../redux/reducers/resources/organismCertificatedType/form";
import { ConfigOrganismWithCertificateController } from "./form/controller";
import { OrganismWithCertificateList } from "./list/list";
import { ConfigOrganismCertificateTypeFilter } from "./filter/filter";

export const ConfigOrganismWithCertificateContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE.ORGANISMCERTIFICATETYPE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setPopUpConfigOrganismCertificateTypeForm(PopUpCodes.CREATE));
                        }}
                    />
                }
            />
            <ConfigOrganismCertificateTypeFilter />

            <OrganismWithCertificateList />

            <ConfigOrganismWithCertificateController />
        </>
    );
};
