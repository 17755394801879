import { createUseStyles } from "react-jss";

export const ImageSliderContainerStyle = createUseStyles((theme: any) => ({
    container: {
        background: "rgba(0, 0, 0, 0.8)",
        position: "absolute",
        top: "0",
        left: 0,
        height: "100vh",
        width: "100vw",
        transition: "all .5s",
        zIndex: "9999999999999999999999999999999999999",
        overflow:"hidden"
    },
    close: {
        position: "absolute",
        bottom: 20,
        right:30, 
        color:theme.colors.palette.purple
    },
}));
