import { useTranslation } from "react-i18next";
import { setDocumentFileAction } from "../../../../../../redux/actions/user/signup/signup";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { ResumeDocumentItemStyle } from "./resumeDocumentItem.jss";
import { UploadFileCustom } from "../../../../../common/uploadFile/uploadFile";
import { removeDocumentFile } from "../../../../../../redux/reducers/user/signup/signup";

import { IRequestedDocument, IUploadUserDocument } from "../../../../../../models";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { ContentTypeExtensions } from "../../../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../../../common/enum/files/FileSize";
import { RequiredSpan } from "../../../../../common/required/requiredSpan";
import { useSelector } from "react-redux";
import { userSignupFormSelector } from "../../../../../../redux/selectors/user/signup/signup";
import { UserType } from "../../../../../../common/enum/UserType";

interface Props {
    document: IRequestedDocument;
}

export const ResumeDocumentItem: React.FC<Props> = ({ document }) => {
    const styles = ResumeDocumentItemStyle();
    const dispatch = useAppDispatch();

    const { t } = useTranslation("common");

    const onChangeFile = (file: File | undefined) => {
        const payload: IUploadUserDocument = {
            index: document.index,
            url: !!file ? URL.createObjectURL(file) : "",
            contentType: !!file ? file.type : "",
            filename: !!file ? file.name : "",
        };

        dispatch(setDocumentFileAction(payload));
    };
    const form = useSelector(userSignupFormSelector);

    return (
        <div
            key={`userDocuments_${document.index}`}
            className={styles.container}
        >
            <div className={styles.labelContainer}>
                <label title={document.documentName}>{document.documentName}</label>
                {UserType.TRUCKER !== form.userType && <RequiredSpan />}
            </div>

            <UploadFileCustom
                allowedExtensions={ContentTypeExtensions.USER_DOCUMENT}
                onChangeFile={onChangeFile}
                title={t("USER.SIGNUP.UPLOAD_TITLE")}
                buttonStyle={ColorType.primary}
                maxSize={FileSize._5MB}
                file={document.file}
                removeDocument={() => {
                    dispatch(removeDocumentFile(document.index));
                }}
            ></UploadFileCustom>
        </div>
    );
};
