import { useTranslation } from "react-i18next";
import { IManagementUserForm } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty } from "../../../../../../utils";
import { Checkboxs } from "../../../../../common/checkbox/checkbox";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useMemo } from "react";
import { findRoleById } from "../../../../../../services/user/roleService";
import { GridClass } from "../../../../../../common/enum/GridClass";

interface IManagementAddMultipleActuationProps {
    generalUserData: IManagementUserForm;
    onCheckBoxChange?: (ev?: any, checked?: boolean) => void;
}

export const AddMultipleActuation: React.FC<IManagementAddMultipleActuationProps> = ({ generalUserData, onCheckBoxChange }) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });

    const roles = useAppSelector((store) => store.catalog.roles.data);

    const roleByUser = useMemo(() => {
        if (roles) {
            return findRoleById(roles, generalUserData.roleId);
        }
        return null;
    }, [roles]);

    return roleByUser?.addMultipleActuation ? (
        <div className={GridClass.ROW_GRID}>
            <div className={GridClass.LONG_DOBLE_CELL}>
                <Checkboxs
                    name={compileNameOfProperty<IManagementUserForm>("addMultipleActuation")}
                    label={t("ADD_MULTIPLE_ACTUATION")}
                    isChecked={generalUserData.addMultipleActuation}
                    handleChange={onCheckBoxChange}
                />
            </div>
        </div>
    ) : (
        <></>
    );
};
