import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const ManageUsersListStyle = createUseStyles((theme: any) => ({
    userListContainer: {
        padding: "8px 0",
        display: "flex",
        flexDirection: "column",
        rowGap: theme.gaps.sizes.md,
        width: "100%",
        overflowY: "auto",
        height: "calc(100vh - 400px)",
        [mediaQueries.TABLET]: {
            maxHeight: "100%",
            overflowY: "unset",
        },
    },
}));
