import { createUseStyles } from "react-jss";

export const UserStepNumberStyled = createUseStyles((theme: any) => ({
    StepNumber: {
        backgroundColor: theme.colors.buttons.secondary.background,
        display: "flex",
        fontWeight: "600",
        justifyContent: "center",
        alignItems: "center",
        color: theme.colors.buttons.secondary.color,
        borderRadius: "5px",
        height: 40,
        width: 40,
    },
    StepNumberSelected: {
        backgroundColor: theme.colors.buttons.primary.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "600",
        color: theme.colors.buttons.primary.color,
        height: 40,
        width: 40,
        borderRadius: "5px",
    },
}));
