import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { fetchRequestStateHistory } from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { TableList } from "../../../../../../common/tableList/tableList";
import { StateHistoryItem } from "./stateHistoryItem/stateHistoryItem";
import { validateStatesDates } from "./stateHistoryItem/stateHistoryValidationDates";
import { stateHistoryPopUpStyles } from "./stateHistoryPopUp.jss";

interface IProps {
    schema: IConfigEditRequest;
}
export const StateHistoryPopUp: React.FC<IProps> = ({ schema }) => {
    const theme = useTheme();
    const isVisible = schema.requestStateHistory_viewAll.visible;
    const styles = stateHistoryPopUpStyles({ theme, isVisible });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.POPUPSTATEHISTORY" });
    const dispatch = useAppDispatch();
    const requestId = useAppSelector((store) => store.editRequest.headerRequest.id);
    const stateHistoryItems = useAppSelector((store) => store.editRequest.stateHistory.stateHistory);
    const isLoadingStateHistoryItems = useAppSelector((store) => store.editRequest.stateHistory.loadingStateHistory);
    const [hasError, setHasError] = useState<number[]>([]);

    useEffect(() => {
        if (!isLoadingStateHistoryItems && requestId) {
            dispatch(fetchRequestStateHistory(requestId));
        }
    }, []);

    useEffect(() => {
        setHasError(validateStatesDates());
    }, [stateHistoryItems]);

    const tableHeaderContent = (
        <>
            {schema.requestStateHistory_stateDescription.visible && <div>{t("STATE")}</div>}
            {schema.requestStateHistory_createdOn.visible && <div>{t("DATE")}</div>}
            {schema.requestStateHistory_createdOn.visible && <div>{t("HOUR")}</div>}
            {schema.requestStateHistory_date.visible && <div>{t("DATE")}</div>}
            {schema.requestStateHistory_date.visible && <div>{t("HOUR")}</div>}
        </>
    );

    const tableBodyContent = stateHistoryItems
        .filter((item) => isVisible || item.active)
        .map((item, index) => (
            <StateHistoryItem
                key={item.id}
                index={index}
                stateHistory={item}
                schema={schema}
                errors={hasError}
            />
        ));

    return (
        <div className={styles.containerPopup}>
            <div className={styles.container}>
                {isLoadingStateHistoryItems && isVisible && (
                    <div className={styles.tableTitle}>
                        <div className="system">{t("SYSTEM")}</div>
                        <div>{t("REPORTED")}</div>
                    </div>
                )}

                <TableList
                    headerContent={tableHeaderContent}
                    bodyContent={tableBodyContent}
                    hasData={stateHistoryItems.length > 0}
                    isLoading={!isLoadingStateHistoryItems}
                />
            </div>
        </div>
    );
};
