import { ContextualMenu, IButtonStyles, IconButton, IDragOptions, IIconProps, IModalStyles, Modal } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import React from "react";
import { TitleH5 } from "../titles/titles";
import { PopUpStyled } from "./popUpStyle.jss";
import { useTheme } from "react-jss";
import { ColorType } from "../../../common/enum/ColorType";

interface IPopUpProps {
    hideModal?: (e: any) => any;
    title?: string;
    children: React.ReactNode;
    state: boolean;
    isBlocking?: boolean;
    className?: string;
    skipHeader?: boolean;
    skipPadding?: boolean;
    titleColor?: ColorType;
    backGroundColor?: string;
    hiddeCloseButon?: boolean;
    onDimissed?: () => any;
}

export const PopUp = (props: IPopUpProps) => {
    const theme = useTheme();
    const skipPadding = props.skipPadding;

    const styles = PopUpStyled({ theme, skipPadding, backGround: props.backGroundColor });
    const [isDraggable] = useBoolean(false);
    const [keepInBounds] = useBoolean(false);

    const dragOptions = React.useMemo(
        (): IDragOptions => ({
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu,
            keepInBounds,
        }),
        [keepInBounds],
    );

    const titleId = useId("title");

    const cancelIcon: IIconProps = { iconName: "Cancel" };

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: styles.closeButton,
        icon: styles.closeIcon,
    };

    const modalStyles: Partial<IModalStyles> = {
        main: {
            borderRadius: 25,
        },
    };

    return (
        <div hidden={true}>
            <Modal
                titleAriaId={titleId}
                isOpen={props.state}
                onDismiss={props.hideModal}
                isBlocking={props.isBlocking}
                containerClassName={styles.container}
                dragOptions={isDraggable ? dragOptions : undefined}
                styles={modalStyles}
                allowTouchBodyScroll={true}
                layerProps={{ eventBubblingEnabled: true }}
                onDismissed={() => {
                    props.onDimissed && props.onDimissed();
                }}
            >
                {!props.skipHeader && (
                    <div className={styles.header}>
                        {props.title && (
                            <span id={titleId}>
                                <TitleH5
                                    title={props.title}
                                    color={props.titleColor || ColorType.primary}
                                    bold={true}
                                />
                            </span>
                        )}

                        {!props.hiddeCloseButon && (
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={cancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={props.hideModal}
                            />
                        )}
                    </div>
                )}
                <div className={styles.body}>{props.children}</div>
            </Modal>
        </div>
    );
};
