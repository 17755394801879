import React from "react";
import { store } from "../../../redux/store";
import { InvoicingUninvoicedContainer } from "./container";
import { resetInvoicingUninvoicedFilter } from "../../../redux/reducers/invoicing/uninvoiced/filter";
import { resetInvoicingUninvoicedList } from "../../../redux/reducers/invoicing/uninvoiced/list";

export class InvoicingUninvoicedController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetInvoicingUninvoicedList());
        store.dispatch(resetInvoicingUninvoicedFilter());
    }

    render(): React.ReactNode {
        return <InvoicingUninvoicedContainer />;
    }
}
