import { IColumn, ITooltipHostStyles, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IStatisticsReaderRecordRatio } from "../../../../../models/statistics/IReaderRecordRatio";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/statistics/incidenceOperator/list";
import { store } from "../../../../../redux/store";
import { colors } from "../../../../../styles/romeu/colors";
import { compileNameOfProperty, convertToLocal, formatDate, onUpdateSortedColumnUtil } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { StatisticsReaderRecordRatioColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsReaderRecordRatioListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = StatisticsReaderRecordRatioColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.READER_RECORD_RATIO.TABLE" });
    const dispatch = useAppDispatch();
    const tooltipId = useId("tooltip");

    const styleTooltip: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
    const calloutProps = { gapSpace: 0 };

    const { sort } = useAppSelector((store) => store.statisticsOperatorIncidenceList.data);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().statisticsOperatorIncidenceList.data.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const onRenderToolTip = (hour: JSX.Element, dataIn?: JSX.Element, dataOut?: JSX.Element) => {
        return (
            <TooltipHost
                tooltipProps={{
                    onRenderContent: () => (
                        <ul style={{ margin: 10, padding: 0 }}>
                            <li>{dataIn}</li>
                            <li>{dataOut}</li>
                        </ul>
                    ),
                    calloutProps: {
                        styles: {
                            root: { borderRadius: 8 },
                            container: { borderRadius: 8 },
                            beak: { background: colors.tooltip.background },
                            beakCurtain: { background: colors.tooltip.background, borderRadius: 8 },
                            calloutMain: { background: colors.tooltip.background },
                        },
                    },
                }}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={styleTooltip}
            >
                {hour}
            </TooltipHost>
        );
    };

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("date"),
            minWidth: 80,
            maxWidth: 90,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("date")}
                    title={t("DATE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("date"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => (
                <span className={styles.dataCell}>{item.date ? formatDate(item.date, FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 180,
            name: t("TRANSPORT_UNIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitNumber")}
                    title={t("TRANSPORT_UNIT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitNumber"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => (
                <span className={styles.dataCell}>{item.transportUnitNumber ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitCode"),
            minWidth: 35,
            maxWidth: 70,
            name: t("TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitCode")}
                    title={t("TYPE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitCode"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => (
                <span className={styles.dataCell}>{item.transportUnitCode ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitSizeCode"),
            minWidth: 50,
            maxWidth: 70,
            name: t("SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitSizeCode")}
                    title={t("SIZE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("transportUnitSizeCode"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => (
                <span className={styles.dataCell}>{item.transportUnitSizeCode ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("merchandiseTypeCode"),
            minWidth: 100,
            maxWidth: 110,
            name: t("MERCHANDISE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("merchandiseTypeCode")}
                    title={t("MERCHANDISE_TYPE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("merchandiseTypeCode"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => (
                <span className={styles.dataCell}>{item.merchandiseTypeCode ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("organisms"),
            minWidth: 60,
            maxWidth: 90,
            name: t("ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("organisms")}
                    title={t("ORGANISM")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("organisms"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => <span className={styles.dataCell}>{item.organisms ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("entityName"),
            minWidth: 300,
            maxWidth: 340,
            name: t("CUSTOMER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("entityName")}
                    title={t("CUSTOMER")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("entityName"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => <span className={styles.dataCell}>{item.entityName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("stateDescription"),
            minWidth: 90,
            maxWidth: 140,
            name: t("STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("stateDescription")}
                    title={t("STATE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("stateDescription"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => (
                <span className={styles.dataCell}>{item.stateDescription ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("timeZone"),
            minWidth: 90,
            maxWidth: 120,
            name: t("TIME_ZONE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("timeZone")}
                    title={t("TIME_ZONE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("timeZone"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => <span className={styles.dataCell}>{item.timeZone ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("entryOn"),
            minWidth: 65,
            maxWidth: 90,
            name: t("ENTRY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("entryOn")}
                    title={t("ENTRY")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("entryOn"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => {
                const date: JSX.Element = (
                    <span className={styles.dataHours}>
                        {item.entryOn ? convertToLocal(item.entryOn, FormatDate.HOUR_MINUTE) : DefaultValues.EMPTY_LIST}
                    </span>
                );
                const dateIn: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_IN")}: </span>
                        {formatDate(item.entryOn, FormatDate.DATE)}
                    </>
                );
                return item.entryOn ? onRenderToolTip(date, dateIn) : DefaultValues.EMPTY_LIST;
            },
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("dockStartOn"),
            minWidth: 85,
            maxWidth: 90,
            name: t("ENTRY_DOCK"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("dockStartOn")}
                    title={t("ENTRY_DOCK")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("dockStartOn"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => {
                const date: JSX.Element = (
                    <div className={styles.dataHours}>
                        {convertToLocal(item.dockStartOn, FormatDate.HOUR_MINUTE) ?? DefaultValues.EMPTY_LIST}
                        <span className={styles.dataDays}>{item.dockStartDays > 0 && ` (+${item.dockStartDays}\)`}</span>
                    </div>
                );
                const dateIn: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_IN")}: </span>
                        {formatDate(item.entryOn, FormatDate.DATE)}
                    </>
                );
                const dateOut: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_IN_DOCK")}: </span>
                        {formatDate(item.dockStartOn, FormatDate.DATE)}
                    </>
                );
                return item.dockStartOn ? onRenderToolTip(date, dateIn, dateOut) : DefaultValues.EMPTY_LIST;
            },
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("dockEndOn"),
            minWidth: 80,
            maxWidth: 90,
            name: t("EXIT_DOCK"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("dockEndOn")}
                    title={t("EXIT_DOCK")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("dockEndOn"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => {
                const date: JSX.Element = (
                    <div className={styles.dataHours}>
                        {convertToLocal(item.dockEndOn, FormatDate.HOUR_MINUTE) ?? DefaultValues.EMPTY_LIST}
                        <span className={styles.dataDays}>{item.dockEndDays > 0 && ` (+${item.dockEndDays}\)`}</span>
                    </div>
                );

                const dateIn: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_IN")}: </span>
                        {formatDate(item.entryOn, FormatDate.DATE)}
                    </>
                );
                const dateOut: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_EXIT_DOCK")}: </span>
                        {formatDate(item.dockEndOn, FormatDate.DATE)}
                    </>
                );
                return item.dockEndOn ? onRenderToolTip(date, dateIn, dateOut) : DefaultValues.EMPTY_LIST;
            },
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsReaderRecordRatio>("exitOn"),
            minWidth: 60,
            maxWidth: 90,
            name: t("EXIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsReaderRecordRatio>("exitOn")}
                    title={t("EXIT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsReaderRecordRatio>("exitOn"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsReaderRecordRatio) => {
                const date: JSX.Element = (
                    <div className={styles.dataHours}>
                        {convertToLocal(item.exitOn, FormatDate.HOUR_MINUTE) ?? DefaultValues.EMPTY_LIST}
                        <span className={styles.dataDays}>{item.exitDays > 0 && ` (+${item.exitDays}\)`}</span>
                    </div>
                );

                const dateIn: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_IN")}: </span>
                        {formatDate(item.entryOn, FormatDate.DATE)}
                    </>
                );
                const dateOut: JSX.Element = (
                    <>
                        <span className={styles.dataDays}>{t("DATE_OUT")}: </span>
                        {formatDate(item.exitOn, FormatDate.DATE)}
                    </>
                );

                return item.exitOn ? onRenderToolTip(date, dateIn, dateOut) : DefaultValues.EMPTY_LIST;
            },
            isResizable: true,
        },
    ];

    return columns;
};
