import { IDropdownOption } from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchMerchandiseCategoriesRealtionShipTypes, fetchMerchandiseCategoriesTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../IBaseSelectorProps";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { findMerchandiseCategory } from "../../../../../utils/request/requestUtils";
interface IMerchandiseCategoryIdProps extends IBaseSelectorProps {
    merchandiseTypeId: number;
    humanConsumption: boolean;
    merchandiseOriginId: number;
}

export const MerchandiseCategoryId: React.FC<IMerchandiseCategoryIdProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    isRequired,
    control,
    rules,
    disabled,
    humanConsumption,
    merchandiseOriginId,
    merchandiseTypeId,
}) => {
    const dispatch = useDispatch();
    const [merchandiseCategoryId, setMerchandiseCategoryId] = useState<number>(selectedKey !== undefined ? +selectedKey : DefaultValues.DEFAULT_ID);

    const {
        catalog: {
            merchandiseCategories: { data },
            merchandiseCategoriesRelationShip: { data: categoriesRelationShip },
        },
    } = useAppSelector((store) => store);

    const options = useMemo(() => mapIDescriptionToDropDownOptions(data), [data]);

    useEffect(() => {
        if (!data.length) dispatch(fetchMerchandiseCategoriesTypes());
        if (!categoriesRelationShip.length) dispatch(fetchMerchandiseCategoriesRealtionShipTypes());
    }, [data.length, categoriesRelationShip.length]);

    useEffect(() => {
        if (selectedKey !== undefined && selectedKey !== merchandiseCategoryId) {
            setMerchandiseCategoryId(+selectedKey);
        }
    }, [selectedKey]);

    useEffect(() => {
        const { id, option } = findMerchandiseCategory(merchandiseTypeId, humanConsumption, merchandiseOriginId, categoriesRelationShip, options);

        if (option && merchandiseCategoryId !== +id) {
            onChange(option, propertyName);
            setMerchandiseCategoryId(+id);
        } else if (!option && merchandiseCategoryId !== DefaultValues.DEFAULT_ID) {
            onChange(0, propertyName);
            setMerchandiseCategoryId(DefaultValues.DEFAULT_ID);
        }
    }, [merchandiseTypeId, humanConsumption, merchandiseOriginId, categoriesRelationShip, options, merchandiseCategoryId]);

    const onSelectorChange = (_evt: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={merchandiseCategoryId}
            options={options}
            disabled={disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
