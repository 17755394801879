import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    emptyInspection,
    emptyTransportUnit,
    ICreateDraft,
    ICreateRequest,
    IFormInspection,
    IGenericPayload,
    Inspection,
    InspectionWithIndex,
    IRequestSectionValidation,
    IResumeCreateRequest,
    ITransportUnitData,
    ITransportUnitDataForm,
    ITransportUnitDataWithIndex,
    OriginSealWithIndex,
} from "../../../../models";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface DraftState {
    loadingDraft: boolean;
    request: ICreateDraft;
    uti: ITransportUnitDataForm;
    inspection: IFormInspection;
    sectionValidation: IRequestSectionValidation;
    resumeRequest: IResumeCreateRequest[];
    activeStep: number;
    toPresentDraft: boolean;
    groupId: string;
}

const initialState: DraftState = {
    loadingDraft: false,
    request: {
        parkingAuthorization: true,
        temporaryRegime: false,
        organicProduct: false,
        halalCertified: false,
        dryCharge: false,
        fumigated: false,
        transportUnits: [] as ITransportUnitData[],
        inspections: [] as Inspection[],
        invoiceCustomerId: 0,
    } as ICreateRequest,
    uti: { form: emptyTransportUnit(), showPopUp: PopUpCodes.NONE },
    inspection: { form: emptyInspection(), showPopUp: PopUpCodes.NONE },
    sectionValidation: {
        utiStepIsValid: false,
        informationStepIsValid: false,
        certificateStepIsValid: false,
    },
    resumeRequest: [],
    activeStep: 1,
    toPresentDraft: false,
    groupId: "",
};

export const draftSlice = createSlice({
    name: "draftReducer",
    initialState,
    reducers: {
        updateDraftProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.request = {
                ...state.request,
                [action.payload.name]: action.payload.value,
            };
        },
        resetDraftState: () => initialState,
        resetUtiDraft: (state) => {
            state.uti = { form: emptyTransportUnit(), showPopUp: PopUpCodes.NONE };
        },
        setLoadingDraft: (state, action: PayloadAction<boolean>) => {
            state.loadingDraft = action.payload;
        },
        setUtiDraft: (state, action: PayloadAction<ITransportUnitData>) => {
            state.uti.form = action.payload;
        },
        setUtiDraftShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.uti.showPopUp = action.payload;
        },
        setEditDraft: (state, action: PayloadAction<ICreateRequest>) => {
            state.request = action.payload;
        },
        updateUtiDraftProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.uti.form = {
                ...state.uti.form,
                [action.payload.name]: action.payload.value,
            };
        },
        pushUtiDraftToList: (state, action: PayloadAction<ITransportUnitData>) => {
            state.request.transportUnits.push(action.payload);
        },
        deleteUtiDraftFromList: (state, action: PayloadAction<number>) => {
            state.request.transportUnits.splice(action.payload, 1);
        },
        editUtiDraftFromList: (state, action: PayloadAction<ITransportUnitDataWithIndex>) => {
            state.request.transportUnits[action.payload.index] = action.payload.transportUnit;
        },
        addSealDraftItem: (state, action: PayloadAction<string>) => {
            state.uti.form.seals.push(action.payload);
        },
        removeSealDraftItem: (state, action: PayloadAction<number>) => {
            state.uti.form.seals.splice(action.payload, 1);
        },
        updateSealDraftItem: (state, action: PayloadAction<OriginSealWithIndex>) => {
            state.uti.form.seals[action.payload.index] = action.payload.seal;
        },
        addBatchDraftItem: (state, action: PayloadAction<string>) => {
            state.uti.form.batches.push(action.payload);
        },
        removeBatchDraftItem: (state, action: PayloadAction<number>) => {
            state.uti.form.batches.splice(action.payload, 1);
        },
        resetInspectionDraft: (state) => {
            state.inspection = {
                form: emptyInspection(),
                showPopUp: PopUpCodes.NONE,
            };
        },
        setInspectionDraft: (state, action: PayloadAction<Inspection>) => {
            state.inspection.form = action.payload;
        },
        setInspectionShowPopUpDraft: (state, action: PayloadAction<PopUpCodes>) => {
            state.inspection.showPopUp = action.payload;
        },
        updateInspectionDraftProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.inspection.form = {
                ...state.inspection.form,
                [action.payload.name]: action.payload.value,
            };
        },
        pushInspectionDraftToList: (state, action: PayloadAction<Inspection>) => {
            state.request.inspections.push(action.payload);
        },
        deleteInspectionDraftFromList: (state, action: PayloadAction<number>) => {
            state.request.inspections.splice(action.payload, 1);
        },
        editInspectionDraftFromList: (state, action: PayloadAction<InspectionWithIndex>) => {
            state.request.inspections[action.payload.index] = action.payload.inspection;
        },
        setUtiDraftStepIsValid: (state, action: PayloadAction<boolean>) => {
            state.sectionValidation.utiStepIsValid = action.payload;
        },
        setInformationDraftStepIsValid: (state, action: PayloadAction<boolean>) => {
            state.sectionValidation.informationStepIsValid = action.payload;
        },
        setCertificateDraftStepIsValid: (state, action: PayloadAction<boolean>) => {
            state.sectionValidation.certificateStepIsValid = action.payload;
        },
        setDraftCreated: (state, action: PayloadAction<IResumeCreateRequest[]>) => {
            state.resumeRequest = action.payload;
        },
        nextStepDraft: (state) => {
            state.activeStep++;
        },
        setStepDraft: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload;
        },
        previousStepDraft: (state) => {
            state.activeStep--;
        },
        setPresentDraft: (state, action: PayloadAction<boolean>) => {
            state.toPresentDraft = action.payload;
        },
        setGroupId: (state, action: PayloadAction<string>) => {
            state.groupId = action.payload;
        },

        resetTerminal: (state) => {
            state.request.terminalId = undefined;
        },
    },
});

export const {
    updateDraftProperty,
    resetDraftState,
    resetUtiDraft,
    setUtiDraft,
    setEditDraft,
    updateUtiDraftProperty,
    pushUtiDraftToList,
    deleteUtiDraftFromList,
    editUtiDraftFromList,
    addSealDraftItem,
    removeSealDraftItem,
    updateSealDraftItem,
    addBatchDraftItem,
    removeBatchDraftItem,
    resetInspectionDraft,
    setInspectionDraft,
    updateInspectionDraftProperty,
    pushInspectionDraftToList,
    deleteInspectionDraftFromList,
    editInspectionDraftFromList,
    setUtiDraftStepIsValid,
    setInformationDraftStepIsValid,
    setCertificateDraftStepIsValid,
    setDraftCreated,
    nextStepDraft,
    previousStepDraft,
    setStepDraft,
    setPresentDraft,
    setGroupId,
    setLoadingDraft,
    resetTerminal,
    setUtiDraftShowPopUp,
    setInspectionShowPopUpDraft,
} = draftSlice.actions;

export default draftSlice.reducer;
