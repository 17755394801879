import { createUseStyles } from "react-jss";

export const DockAssignerPopUpStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        minWidth: "650px",
        justifyContent: "space-between",
    },
    noData: {
        marginTop: "20px",
    },
    table: {
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
    },
    tableTitle: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "2vw",
        width: theme.widths.sectionContainer.complete,
        paddingBottom: theme.paddings.forms.inputs.defaultPadding,

        "& > div ": {
            width: "40%",
            wordBreak: "break-all",
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid",
            borderColor: theme.colors.titles.primary.color,
            textTransform: "uppercase",
            padding: "5px 0 5px 0",
            color: theme.colors.titles.primary.color,
            fontWeight: "bold",
        },
        "& .system": {
            width: "38%",
        },
    },

    tableHeader: {
        display: "flex",
        width: theme.widths.sectionContainer.complete,
        paddingBottom: theme.paddings.forms.inputs.defaultPadding,
        "& > div ": {
            width: "50%",
            wordBreak: "break-all",
            display: "flex",
            color: theme.colors.table.header.color,
        },
    },
    tableBodyAdmmin: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        height: "calc(70vh - 180px)",
        overflow: "auto",
        marginBottom: "20px",
        color: theme.colors.table.body.color,
    },
    tableBody: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        maxHeight: "calc(80.5vh - 130px)",
        overflow: "auto",
        paddingTop: "0px",
        marginBottom: "20px",
        color: theme.colors.table.body.color,
        "& .ms-ChoiceFieldGroup": {
            "& > div ": {
                width: theme.widths.sectionContainer.complete,
            },
            "& .ms-ChoiceField-wrapper": {
                width: theme.widths.sectionContainer.complete,
            },
        },
    },
    tableItem: {
        display: "flex",
        width: theme.widths.sectionContainer.complete,
        paddingBottom: theme.paddings.forms.inputs.defaultPadding,
        borderBottom: theme.borders.default.borderDotted,
        "& > div ": {
            width: "50%",
            wordBreak: "break-all",
            display: "flex",
            paddingLeft: 1,
        },
    },
    requestButtonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingBottom: 32,
    },
}));
