import { throwErrorToastrAsync } from "../../../../utils";
import { AppThunk, store } from "../../../store";
import { setAppointmenttHistoricLoading, setAppointmenttHistoricList } from "../../../reducers/appointment/historic/list/appointment";
import { setApointmentHistoricIncidenceLoading, setAppointmentHistoricIncidenceList } from "../../../reducers/appointment/historic/list/incidence";
import { setAppointmentHistoricInspectorsLoading, setAppointmentHistoricInspectorList } from "../../../reducers/appointment/historic/list/inspector";
import { AppointmentHistoricAPI } from "../../../../api/appointments/historic";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";

const historicApi = new AppointmentHistoricAPI();

export const fetchAppointmentHistoricIncidenceListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().appointmentHistoricFilter;
        dispatch(setGlobalLoading(true));
        dispatch(setApointmentHistoricIncidenceLoading(true));
        const response = await historicApi.getAppointmentHistoricInidenceList(filter);
        dispatch(setAppointmentHistoricIncidenceList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
        dispatch(setApointmentHistoricIncidenceLoading(false));
    }
};

export const fetchAppointmentHistoricInspectorListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoading(true));
        const { filter } = store.getState().appointmentHistoricFilter;

        dispatch(setAppointmentHistoricInspectorsLoading(true));
        const response = await historicApi.getAppointmentHistoricInspectorList(filter);
        dispatch(setAppointmentHistoricInspectorList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
        dispatch(setAppointmentHistoricInspectorsLoading(false));
    }
};

export const fetchAppointmentHistoricAppointmentListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoading(true));
        const { filter } = store.getState().appointmentHistoricFilter;

        dispatch(setAppointmenttHistoricLoading(true));
        const response = await historicApi.getAppointmentHistoricAppointmentList(filter);
        dispatch(setAppointmenttHistoricList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
        dispatch(setAppointmenttHistoricLoading(false));
    }
};
