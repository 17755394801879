import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { MasterTypes } from "../../../../common/enum/masters/marterTypes";


interface IMasterPopUpState {
    showPopUp: PopUpCodes;
    codeComponent: string;
}

const initialState: IMasterPopUpState = {
    showPopUp: PopUpCodes.NONE,
    codeComponent: MasterTypes.ORGANISM,
};

export const masterPopUp = createSlice({
    name: "masterPopUp",
    initialState,
    reducers: {
        resetMasterPopUp: () => initialState,
        setShowPopUpMaster: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setComponetMaster: (state, action: PayloadAction<string>) => {
            state.codeComponent = action.payload;
        },
        clearMasterPopUp: (state) => {
            state.showPopUp = PopUpCodes.NONE;
        },
    },
});

export const { resetMasterPopUp, setShowPopUpMaster, setComponetMaster, clearMasterPopUp } = masterPopUp.actions;

export default masterPopUp.reducer;
