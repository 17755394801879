import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
    fetchAppointmentTypesRemaining,
    postAndPutMasterAppointmentType,
} from "../../../../../../redux/actions/masterAppointment/appointmentType/appointmentType";
import { useAppSelector } from "../../../../../../redux/hooks";
import { resetMasterAppointmentTypeForm } from "../../../../../../redux/reducers/masterAppointment/appointmentType/form";
import { clearMasterAppointmentPopUp } from "../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { MasterAppointmentTypeForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterActuationTypeControllerProps {}

export const MasterAppointmentTypeFormController: React.FC<IMasterActuationTypeControllerProps> = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useDispatch();

    const { showPopUp } = useAppSelector((state) => state.masterAppointment);
    const { loading, form } = useAppSelector((state) => state.masterAppointmentTypesForm);
    const { data } = useAppSelector((state) => state.catalog.appointmentTypesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postAndPutMasterAppointmentType()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterAppointmentPopUp());
    };

    const onDimissed = () => {
        dispatch(resetMasterAppointmentTypeForm());
    };
    const isVisiblePopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false;
    }, [showPopUp]);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.APPOINTMENT_TYPE").toLocaleLowerCase();
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.APPOINTMENT_TYPE").toLocaleLowerCase();
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        let keys = ["description", "code"];
        showPopUp === PopUpCodes.CREATE && keys.push("referenceId");
        let item: any = form;
        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }
        return false;
    }, [form, showPopUp]);

    const showNotData = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !data?.length ? true : false;
    }, [data, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchAppointmentTypesRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterAppointmentTypeForm
                    showNotData={showNotData}
                    selectorData={data ?? []}
                />
            }
            title={titlePopUp}
            isVisible={isVisiblePopUp}
            onDimissed={onDimissed}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            height={"30vh"}
            width={"40vh"}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onlyHidden={showNotData}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
