import { Icon } from "@fluentui/react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ManagementProcessesApi } from "../../../api/management/processes";
import { ColorType } from "../../../common/enum/ColorType";
import { throwErrorToastrAsync, throwSuccessToastrAsync } from "../../../utils";
import { Button } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementProcessesContainerStyle } from "./containerStyle.jss";

export const ManagementProcessesContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PROCESSES" });
    const theme = useTheme();
    const style = ManagementProcessesContainerStyle({ theme });
    const [loadingState, setLoadingState] = useState({
        poisonEmails: false,
        clearCache: false,
        inspectionCardLocks: false,
    });

    const processes = new ManagementProcessesApi();

    const handleAction = useCallback(async (action: () => Promise<void>, actionKey: keyof typeof loadingState) => {
        try {
            setLoadingState((prev) => ({ ...prev, [actionKey]: true }));
            await action();
            throwSuccessToastrAsync();
        } catch (error: Error | any) {
            throwErrorToastrAsync(error);
        } finally {
            setLoadingState((prev) => ({ ...prev, [actionKey]: false }));
        }
    }, []);

    return (
        <>
            <NavHeaderComponent title={t("TITLE")} />
            <div className={style.content}>
                <div className={style.container}>
                    <Button
                        color={ColorType.primary}
                        className={style.customWidthBtn}
                        title={t("PROCESS")}
                        handleClick={() => handleAction(processes.postSendPoisonEmails, "poisonEmails")}
                        loading={loadingState.poisonEmails}
                        disabled={loadingState.poisonEmails}
                    />
                    <div className={style.infoSection}>
                        <Icon iconName="info" />
                        {t("HELPER_POISON_EMAILS")}
                    </div>
                </div>
                <div className={style.container}>
                    <Button
                        color={ColorType.primary}
                        className={style.customWidthBtn}
                        title={t("PROCESS")}
                        handleClick={() => handleAction(processes.postClearCache, "clearCache")}
                        loading={loadingState.clearCache}
                        disabled={loadingState.clearCache}
                    />
                    <div className={style.infoSection}>
                        <Icon iconName="info" /> {t("HELPER_CLEAR_CACHE")}
                    </div>
                </div>
                <div className={style.container}>
                    <Button
                        color={ColorType.primary}
                        className={style.customWidthBtn}
                        title={t("PROCESS")}
                        handleClick={() => handleAction(processes.deleteClearInspectionCardLocks, "inspectionCardLocks")}
                        loading={loadingState.inspectionCardLocks}
                        disabled={loadingState.inspectionCardLocks}
                    />
                    <div className={style.infoSection}>
                        <Icon iconName="info" /> {t("HELPER_DELETE_LOCKS")}
                    </div>
                </div>
            </div>
        </>
    );
};
