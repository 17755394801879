import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { routeFeatures } from "../../../common/constants/routeFeatures";
import { IGenericPayload, ViewType } from "../../../models";
import { IUserRoleView } from "../../../models/requests/IUserRoleView";
import { fetchExportRequestAppointmentsListAction, fetchRequestAppointmentsListAction } from "../../../redux/actions/appointments/appointments";
import { fetchViewsAction } from "../../../redux/actions/user/role/view";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { UpcomingAppointmentsList } from "./list/list";
import { AppointmentUpcomingFilter } from "../filter/filter";
import { generateUpcomingAppointmentSortFilterSchema } from "../filter/generateSchema";
import { resetUpcomingAppointmentsFilter, updateUpcomingAppointmentsFilterProperty } from "../../../redux/reducers/upcomingAppointments/filter";
import { generateUpcomingAppointmentListSchema } from "./list/generateSchema";

export const UpcomingAppointmentsContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT" });
    const dispatch = useAppDispatch();

    const { filter } = useAppSelector((state) => state.requestUpcomingAppointmentsFilter);

    const { viewPermission } = useAppSelector((state) => state.userRoleViews);
    const viewRequestPermision: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === routeFeatures.UPCOMING_APPOINTMENTS);

    const generateShema = (type: string) => {
        let viewConfig = viewRequestPermision ? (viewRequestPermision?.views.find((view) => view.type === type) as any) : null;
        if (type === ViewType.LIST) {
            return generateUpcomingAppointmentListSchema(viewConfig?.fields ? viewConfig.fields : []);
        } else if (type === ViewType.SEARCH) {
            return generateUpcomingAppointmentSortFilterSchema(viewConfig?.fields ? viewConfig.fields : []);
        }
    };

    useEffect(() => {
        viewRequestPermision === undefined && dispatch(fetchViewsAction());
    }, [viewRequestPermision]);

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={
                    <ExportDataIcon
                        onExportCSV={() => {
                            dispatch(fetchExportRequestAppointmentsListAction());
                        }}
                    />
                }
            />

            <AppointmentUpcomingFilter
                schema={generateShema(ViewType.SEARCH)}
                filter={filter}
                onApplyFilter={() => {
                    dispatch(fetchRequestAppointmentsListAction());
                }}
                onResetFilter={() => {
                    dispatch(resetUpcomingAppointmentsFilter());
                    dispatch(fetchRequestAppointmentsListAction());
                }}
                updateProperty={(payload: IGenericPayload) => {
                    dispatch(updateUpcomingAppointmentsFilterProperty(payload));
                }}
            />

            <UpcomingAppointmentsList schema={generateShema(ViewType.LIST)} />
        </>
    );
};
