import { Method } from "../../../common/enum";
import { IManagemenTActiveSummaryFilter, IManagementActiveSummary } from "../../../models/management/summary/ISummary";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/summaries`;

export class ManagementSummaryApi {
    async getManagementSummaryListAsync(
        filter: IManagemenTActiveSummaryFilter,
        currentPage: number,
        totalSize: number,
    ): Promise<IManagementActiveSummary> {
        let summaryFilter = filter.summaryNumber ? `&summaryNumber=${filter.summaryNumber}` : "";
        let urlFilter = `?page=${currentPage}&size=${totalSize}`;
        const url = `${baseURL}${urlFilter}${summaryFilter}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IManagementActiveSummary>();
    }
    async postMasterSummaryAsync(item: any): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(item).execute<boolean>();
    }

    async removeManagementSummaryAsync(id: number): Promise<boolean> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
