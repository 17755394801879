import { Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isValidEmail, isValidPhoneNumber } from "../../../../common/validations/validationsForm";
import { IGenericPayload } from "../../../../models";
import { IUserSignup } from "../../../../models/user/IUserSignup";
import { setAvatarFileAction, userExitsAction } from "../../../../redux/actions/user/signup/signup";
import { useAppDispatch } from "../../../../redux/hooks";
import { previousStep } from "../../../../redux/reducers/user/signup/stepper";
import { userSignupFormSelector } from "../../../../redux/selectors/user/signup/signup";
import { compileNameOfProperty } from "../../../../utils";
import { Avatar } from "../../../common/avatar/avatar";
import { ButtonPrimary, ButtonSecondary } from "../../../common/buttons/buttons";
import { Disclaimer } from "../../../common/disclaimer/disclaimer";
import { UserSignupProfileStyle } from "./containerStyle.jss";
import { ProfileForm } from "./form/form";
import { UserType } from "../../../../common/enum/UserType";

const resolver: Resolver<IUserSignup> = async (values: IUserSignup) => {
    let errors = {};

    Object.entries(values).forEach(([name, value]) => {
        switch (name) {
            case compileNameOfProperty<IUserSignup>("email"):
                if (!isValidEmail(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.EMAIL",
                        },
                    };
                }
                break;

            case compileNameOfProperty<IUserSignup>("phoneNumber"):
                if (!isValidPhoneNumber(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.PHONE",
                        },
                    };
                }

                break;
            case compileNameOfProperty<IUserSignup>("lastName"):
                break;
            default:
                if (!value) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.REQUIRE",
                        },
                    };
                }
                break;
        }
    });
    return { values, errors };
};

export const UserProfileSignupData = () => {
    const styles = UserSignupProfileStyle();
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const {
        register,
        formState: { errors, isValid },
    } = useForm<IUserSignup>({ mode: "onChange", resolver: resolver });

    const form = useSelector(userSignupFormSelector);

    const onAvatarClick = (file: File | undefined) => {
        const isFile = file instanceof File;
        const payload: IGenericPayload = {
            name: isFile ? file.name : "",
            value: isFile ? URL.createObjectURL(file) : "",
        };

        dispatch(setAvatarFileAction(payload));
    };

    return (
        <>
            <div className={styles.content}>
                <div className={styles.infoContainer}>
                    <Disclaimer
                        body={t("USER.SIGNUP.PROFILE_MESSAGE_BODY")}
                        color="#7367f0"
                        backgroundColor="#e0deff"
                    />
                    <div className={styles.form}>
                        <div className={styles.item}>
                            <div className={styles.avatar}>
                                <Avatar
                                    src={form.avatar.file}
                                    size="145px"
                                    onChangeAvatar={onAvatarClick}
                                    edit={true}
                                />
                            </div>
                        </div>
                        <div className={styles.itemForm}>
                            <ProfileForm
                                data={form}
                                register={register}
                                errors={errors}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.buttonsRow}>
                    <ButtonSecondary
                        handleClick={() => dispatch(previousStep())}
                        title={t("USER.SIGNUP.BACKBUTTON")}
                    />
                    <ButtonPrimary
                        disabled={!isValid || (form.userType === UserType.CUSTOMER && !form.userPositionType)}
                        handleClick={() => dispatch(userExitsAction())}
                        title={t("USER.SIGNUP.NEXTBUTTON")}
                    />
                </div>
            </div>
        </>
    );
};
