import { ActionButton, IIconProps } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
import { patchRequestStateHistory } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { setLoadingStateHistory } from "../../../../../../redux/reducers/request/edit/editRequest";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { StateHistoryPopUp } from "./stateHistoryPopUp/stateHistoryPopUp";

interface IProps {
    schema: IConfigEditRequest;
}

export const StateHistory: React.FC<IProps> = ({ schema }) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.POPUPSTATEHISTORY" });
    const dispatch = useAppDispatch();

    const historyIcon: IIconProps = { iconName: "HistoryIcon", style: { width: 25 } };

    const [isStateHistoryPopUpVisible, setStateHistoryPopUpVisible] = useState<boolean>(false);

    const requestId = useAppSelector((store) => store.editRequest.headerRequest.id);
    const stateHistoryItems = useAppSelector((store) => store.editRequest.stateHistory.stateHistory);

    const onCancelStateHistory = () => {
        setStateHistoryPopUpVisible(false);
        dispatch(setLoadingStateHistory(false));
    };
    const onSaveStateHistory = () => {
        const filteredStateHistoryItems = stateHistoryItems.filter((state) => state.active === true);
        dispatch(patchRequestStateHistory(requestId.toString(), filteredStateHistoryItems));
    };

    return (
        <>
            {schema.requestStateHistory.visible && (
                <PopUpForm
                    content={<StateHistoryPopUp schema={schema} />}
                    title={t("STATE_HISTORY")}
                    isVisible={isStateHistoryPopUpVisible}
                    onCancel={onCancelStateHistory}
                    width={"100vh"}
                    height={"auto"}
                    onSave={onSaveStateHistory}
                    isBlocking={false}
                    onlyHidden={!schema.requestStateHistory_date.visible}
                />
            )}

            {schema.requestStateHistory.visible && (
                <ActionButton
                    iconProps={historyIcon}
                    allowDisabledFocus
                    onClick={() => setStateHistoryPopUpVisible(true)}
                />
            )}
        </>
    );
};
