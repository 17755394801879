import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementRequestDatePermissionContainerStyle } from "./containerStyle.jss";
import { ManagementRequestDatePermisionController } from "./controller/controller";
import { ManagementRequestDatePermisionList } from "./list/list";
import { useAppDispatch } from "../../../redux/hooks";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";





import { setManagementRequestDatePermissionShowPopUp } from "../../../redux/reducers/management/requestdatepermission/form";

export const ManagementRequestDatePermissionContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PERMISSIONS" });
    const theme = useTheme();
    const style = ManagementRequestDatePermissionContainerStyle({ theme });
    const dispatch = useAppDispatch();

    return (
        <div className={style.generalContainer}>
            <NavHeaderComponent
                title={t("TITLE")}
                action={            <ButtonPrimary
                    title={t("BUTTON.NEWPERMISION")}
                    handleClick={() => {
                        dispatch(setManagementRequestDatePermissionShowPopUp(PopUpCodes.CREATE));
                    }}
                />
    }
            />
            <ManagementRequestDatePermisionList />

            <ManagementRequestDatePermisionController />
        </div>
    );
};
