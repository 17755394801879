import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IDocument } from "../../../../models/user/IDocument";

export const userSignupDocumentTemplatesAdapter = createEntityAdapter<IDocument>({
    selectId: (userType) => userType.name,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const setControlProps = (state: any, isLoading: boolean, hasErrors: boolean, errorMessage: string) => {
    state.isLoading = isLoading;
    state.hasErrors = hasErrors;
    state.errorMessage = errorMessage;
};

export const userSignupDocumentTemplatesSlice = createSlice({
    name: "userSignupDocumentTemplates",
    initialState: userSignupDocumentTemplatesAdapter.getInitialState({
        isLoading: false,
        hasErrors: false,
        errorMessage: "",
    }),
    reducers: {
        userSignupDocumentTemplatesReceived: (state, action) => {
            userSignupDocumentTemplatesAdapter.setAll(state, action.payload);
            setControlProps(state, false, false, "");
        },
        resetUserSignupDocumentTemplates: (state) => {
            userSignupDocumentTemplatesAdapter.setAll(state, []);
            setControlProps(state, false, false, "");
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            setControlProps(state, false, false, action.payload);
        },
    },
});

export const { userSignupDocumentTemplatesReceived, resetUserSignupDocumentTemplates, setLoading, setError } =
    userSignupDocumentTemplatesSlice.actions;

export default userSignupDocumentTemplatesSlice.reducer;
