import { createUseStyles } from "react-jss";

export const PopUpStyled = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
        fontFamily: theme.fonts.families.site,
        background: props.backGround ?? "white",
    }),
    header: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        paddingLeft: 32,
        paddingRight: 0,
        paddingTop: 32,
        paddingBottom: 16,
        minHeight: 16,
    },
    body: (props: any) => ({
        flex: "4 4 auto",
        padding: props.skipPadding ? "0" : "0 32px 8px 32px",
        overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    }),
    closeButton: {
        padding: 0,
        marginLeft: "auto",
        backgroundColor: theme.colors.buttons.primary.background,
        color: theme.colors.buttons.primary.color,
        borderBottomLeftRadius: 4,
        marginTop: -40,
        width: 40,
        height: 40,
        "& :hover": {
            backgroundColor: theme.colors.buttons.primary.color,
            color: theme.colors.buttons.primary.background,
            "& i": {
                color: theme.colors.buttons.primary.background,
            },
        },
    },
    closeIcon: {
        color: theme.colors.buttons.primary.color,
        fontWeight: "600",
        fontSize: 12,
    },
}));
