import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { IAppointmentHistoricForm } from "../../../../models/appointment/IAppointmentHistoric";


interface IAppointmenttHistoricFormState {
    form: IAppointmentHistoricForm;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmenttHistoricFormState = {
    form: {
        response: "",
        messaageData: "",
    },
    showPopUp: PopUpCodes.NONE,
};

export const apointmenttHistoricFormReducer = createSlice({
    name: "apointmenttHistoricFormReducer",
    initialState,
    reducers: {
        resetAppointmentHistoricForm: () => initialState,
        updateAppointmentHistoricFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setAppointmentHistoricFormPopUpShow: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setAppointmentHistoricForm: (state, action: PayloadAction<IAppointmentHistoricForm>) => {
            state.form = action.payload;
        },
    },
});

export const { resetAppointmentHistoricForm, updateAppointmentHistoricFormProperty, setAppointmentHistoricFormPopUpShow, setAppointmentHistoricForm } =
    apointmenttHistoricFormReducer.actions;

export default apointmenttHistoricFormReducer.reducer;
