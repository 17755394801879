import { createUseStyles } from "react-jss";

export const MultiSelectorStyled = createUseStyles((theme: any) => ({
    multiSelectorContainer: (props: any) => ({
        position: "relative",
        width: "100%",
        marginTop: props.isFormSelector ? 0 : 5,
        fontFamily: props.isFormSelector ? theme.fonts.families.siteImportant : theme.fonts.families.site,
    }),
    rootStyles: (props: any) => ({
        borderRadius: props.isFormSelector ? 0 : theme.borders.default.radius,
        backgroundColor: theme.colors.palette.white,
        borderBottom: props.isFormSelector ? `1.3px solid ${theme.colors.form.input.default}` : 0,
        color: theme.colors.form.input.filled,
        fontSize: theme.fonts.sizes.base,
        paddingLeft: props.isFormSelector ? 0 : "7px",
        fontFamily: "unset",
        "& .ms-Dropdown-optionText": {
            fontFamily: props.isFormSelector ? theme.fonts.families.siteImportant : theme.fonts.families.site,
        },
        "& .ms-Label": {
            fontFamily: props.isFormSelector ? theme.fonts.families.siteImportant : theme.fonts.families.site,
        },
        "& .is-disabled ": {
            opacity: "0.5",
        },
    }),
    inputText: {
        color: theme.colors.form.input.filled,
        backgroundColor: "transparent",
        paddingTop: 3,
    },
    callout: {
        marginTop: theme.paddings.combo.wrapperoffset,
        "& .ms-Checkbox": {
            color: theme.colors.form.input.filled,
        },
        "& .is-checked": {
            "& .ms-Checkbox-checkbox": {
                backgroundColor: theme.colors.palette.purple,
            },
        },
    },
    optionText: {
        minHeight: 30,
    },
    label: (props: any) => ({
        color: props.isFormSelector ? theme.colors.form.input.default : theme.colors.palette.grey,
        fontSize: props.isFormSelector ? theme.fonts.sizes.xs : theme.fonts.sizes.xxs,
        fontWeight: props.isFormSelector ? 450 : "bold",
        position: "absolute",
        pointerEvents: "none",
        top: props.isFormSelector ? -15 : -11,
        transform: "translateY(0px)",
        transition: "all 0.2s ease-in-out",
        left: "0px",
        paddingLeft: props.isFormSelector ? 0 : theme.paddings.sizes.xs,
        fontFamily: props.isFormSelector ? theme.fonts.families.siteImportant : theme.fonts.families.site,
        zIndex:90
    }),
    error: {
        position: "absolute",
        fontFamily: theme.fonts.families.site,
        fontSize: theme.fonts.sizes.xs,
        color: theme.colors.form.input.invalid,
        padding: theme.paddings.forms.errorsMessage.defaultPadding,
    },
}));
