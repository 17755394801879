import { IDropdownOption } from "@fluentui/react";
import { useEffect } from "react";
import { fetchCustomStates } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const CustomStatesSelector: React.FC<IBaseSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    disabled,
    isRequired,
    control,
    rules,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.customsStates);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchCustomStates());
    }, [dispatch, data]);

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={mapIDescriptionToDropDownOptions(data)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
