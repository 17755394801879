import { RootState } from "../../store";

export const userDataSelector = (state: RootState) => {
    return state.userData;
};

export const getUserData = (state: RootState) => {
    const auth = userDataSelector(state);
    return auth.profile;
};
