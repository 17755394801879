import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterAppointmentType } from "../../../../../../../models/masterAppointment/IAppointmentType";
import { IMasterActuationGroup } from "../../../../../../../models/resources/master/IActuationGroup";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterCertificateTypeFormStyle } from "./formStyle.jss";
import { setMasterActuationGroupForm, updateMasterActuationGroupProperty } from "../../../../../../../redux/reducers/resources/master/actuationGroup/form";
interface IMasterAppointmentTypeFormProps {
    showNotData: boolean;
    selectorData: IMasterAppointmentType[];
}
export const MasterActuationGroupForm: React.FC<IMasterAppointmentTypeFormProps> = ({ showNotData, selectorData }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const theme = useTheme();
    const style = MasterCertificateTypeFormStyle({ theme });
    const dispatch = useAppDispatch();
    const updatePropertyForm = (name: string, value: any) => {
        dispatch(updateMasterActuationGroupProperty({ name, value }));
    };
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { form } = useAppSelector((state) => state.masterActuationGroupForm);
    const { register, control } = useForm<IMasterAppointmentType>({ mode: "onChange" });

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        updatePropertyForm(propertyName, !!+option.key);
    };
    const onSelectorChange = (event: any, option: any, name: string) => {
        updatePropertyForm(name, option.key);
        let actuationGroup = selectorData.find((item) => {
            return item.id === option.key;
        });
        if (actuationGroup) {
            const form: IMasterActuationGroup = {
                active: true,
                referenceId: String(option.key),
                code: actuationGroup.code,
                description: actuationGroup.description,
                id:0            
            };

            dispatch(setMasterActuationGroupForm(form));
        }
    };

    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {showPopUp === PopUpCodes.CREATE && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterAppointmentType>("referenceId")}
                        title={t("FORM.ACTUATION_GROUP")}
                        options={mapIDescriptionToDropDownOptions(selectorData)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code}
                    {...register(compileNameOfProperty<IMasterAppointmentType>("code"), {
                        onChange: onInputChange,
                        value: form.code,
                    })}
                    isRequired={true}
                    disabled={true}
                />
                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? ""}
                    disabled={disabledInput}
                    {...register(compileNameOfProperty<IMasterAppointmentType>("description"), {
                        onChange: onInputChange,
                        value: form.description,
                    })}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterAppointmentType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
