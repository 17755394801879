import React,{ useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterMerchandiseOriginControllerForm } from "../controller/controller";
import { MasterOriginalWareColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/enum/sizes";
import { fetchMasterMerchandiseOriginsList } from "../../../../../../redux/actions/resources/master/merchandiseOrigins/merchandiseOrigin";
interface IMasterOriginalMerchandiseList {
  widthPopUp:any;
    heigthPopUp:any;
}

export const MasterOriginalMerchandiseList:React.FC<IMasterOriginalMerchandiseList> = ({heigthPopUp, widthPopUp}) => {
    const dispatch = useDispatch();
    const { loading, list } = useAppSelector((state) => state.masterMerchandiseOriginsList);
    const onRenderColumns = () => {
        return MasterOriginalWareColumns();
    };

    useEffect(() => {
        !list && !loading && dispatch(fetchMasterMerchandiseOriginsList());
    }, []);
    return (
        <div style={{
            height:"calc(100vh - 15rem)"
        }}>
            {loading ? (
                                <Skeleton rows={SkeletonSizes.DEFAULT} />

            ) : (
                    <CustomDetailsList
                        data={list ?? []}
                        renderColumns={onRenderColumns}
                    height={"calc(100vh - 10rem)"}
                    ></CustomDetailsList>
            )}
            <MasterMerchandiseOriginControllerForm widthPopUp={widthPopUp} heigthPopUp={heigthPopUp}/>
        </div>
    );
};
