import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../../../../../common/constants";

export const MasterBoardHistoryAppointmentFringeFormStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.form.column,
        paddingTop: 20,
        width: "100%",
    },
    rowForm: {
        display: "flex",
        gap: theme.gaps.form.column,
        width: "100%",
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
        },
    },
    checkRow: {
        display: "flex",
        gap: 48,
        width: "100%",
    },
    checkRowMobile: {
        display: "flex",
        gap: theme.gaps.form.column,
        width: "100%",
        justifyContent: "space-between",
    },
    item : {
        width:"320px", 
    }
}));
