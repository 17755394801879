import { stringDefaultValue } from "../../../utils";

export interface IManagementNotificationFilter {
    roleId: string;
    notificationTypeId: string;
   
}

export const emptyManagementNotificationFilter = (): IManagementNotificationFilter => {
    return {
        roleId: stringDefaultValue.key.toString(),
        notificationTypeId: stringDefaultValue.key.toString(),
    };
};
