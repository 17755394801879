import { mapToManagedUsers, UserApi } from "../../../../api";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";
import { IUserNotifications } from "../../../../models/user/notifications/IUserNotifications";
import messages from "../../../../translations/es/messages.json";
import { showMessageSuccessFromAssets, throwErrorToastrAsync } from "../../../../utils";
import { resetRegiteredEmailList } from "../../../reducers/user/profile/registerUsers";
import {
    loadingUserProfile,
    setEntityUsersList,
    setLoadingNotificationsUsers,
    setLoadingUsers,
    setRepresentativeUser,
    setUserNotifications,
    setUserProfile,
} from "../../../reducers/user/profile/userProfile";
import { setRepresentativeLoading } from "../../../reducers/user/representatives/userRepresentatives";
import { AppThunk, store } from "../../../store";
import { setGlobalLoadingAction } from "../../common";
import { fetchUserData } from "../../userData/userData";

const userProfileApi = new UserApi();

export const fetchUserProfileAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingUserProfile(true));
        const response = await userProfileApi.getUserProfile();
        response.operatorData.emails = response.operatorData.emails.map((email) => {
            return {
                email: String(email.email),
                isValid: true,
            };
        });
        response.adminData.emails = response.adminData.emails.map((email) => {
            return {
                email: String(email.email),
                isValid: true,
            };
        });

        dispatch(setUserProfile(response));
    } catch (error) {
        dispatch(HandleAxiosError(error));
    } finally {
        dispatch(loadingUserProfile(false));
    }
};

export const fetchUserProfileUsersList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingUsers(true));
        const response: any = await userProfileApi.getUserProfileEntityListUsers();
        dispatch(setEntityUsersList(mapToManagedUsers(response)));
    } catch (error) {
        dispatch(HandleAxiosError(error));
    } finally {
        dispatch(setLoadingUsers(false));
    }
};

export const updateUserProfileAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingUserProfile(true));
        const promises: any[] = [];
        const user = store.getState().userProfile.profile.user;

        const userPromise = userProfileApi.updateUserProfileData(user);
        promises.push(userPromise);
        if (user.hasImageChanged) {
            const imagePromise = userProfileApi.updateUserProfileImage(user.image);
            promises.push(imagePromise);
        }
        const result = await Promise.all(promises);
        if (result.every((response) => !!response)) {
            showMessageSuccessFromAssets(messages.SUCCESS.USER_UPDATE_PROFILE);
        }
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(fetchUserData());
        dispatch(loadingUserProfile(false));
    }
};

export const bulkSignUpRequestAction = (): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const emails = store.getState().registeredEmails.registeredMails;
            const emailList = [...new Set(emails.map((email) => email.email))];
            await userProfileApi.sendUserSignupRequests(emailList);
            showMessageSuccessFromAssets(messages.SUCCESS.REGISTER_USERS_MAILS);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(resetRegiteredEmailListAction());
            dispatch(setGlobalLoadingAction(false));
        }
    };
};

export const resetRegiteredEmailListAction = (): AppThunk => async (dispatch) => {
    dispatch(resetRegiteredEmailList());
};

export const fetchUserRepresentative = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoadingAction(true));
        const response = await userProfileApi.getUserRepresentatives();
        dispatch(setRepresentativeUser(response));
    } catch (error) {
        throwErrorToastrAsync(error);
        dispatch(setGlobalLoadingAction(false));
    } finally {
        dispatch(setGlobalLoadingAction(false));
    }
};

export const sendUserRepresentative = (): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setRepresentativeLoading(true));
            const representative = store.getState().userRepresentative.form;
            await userProfileApi.sendUserRepresentatives(representative);
            dispatch(fetchUserRepresentative());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setRepresentativeLoading(false));
        }
    };
};

export const fetchProfileUserNotifications = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingNotificationsUsers(true));
        const response = await userProfileApi.getUserNotifications();

        let newResponse: IUserNotifications[] = [];

        response.forEach((item: IUserNotifications) => {
            newResponse.push({ ...item, emailList: item.email ? item.email?.split(";") : [""] });
        });

        dispatch(setUserNotifications(newResponse));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingNotificationsUsers(false));
    }
};

export const onSaveDataProfileUserNotification = (): AppThunk => async (dispatch) => {
    const { list } = store.getState().userProfile.profile.userNotifications;
    try {
        dispatch(setLoadingNotificationsUsers(true));
        list && (await userProfileApi.putActiveUserNotification(list));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(fetchProfileUserNotifications());
    }
};
