import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDockQueueConfiguration } from "../../../../models/management/dockQueueRule/IDockQueueRule";
export interface IManagementDockQueueRuleConfigurationState {
    view?: IDockQueueConfiguration;
    loading: boolean;
}

const initialState: IManagementDockQueueRuleConfigurationState = {
    view: undefined,
    loading: false,
};

export const managementDockQueueRuleConfigurationSlice = createSlice({
    name: "managementDockQueueRuleConfiguration",
    initialState,
    reducers: {
        resetDockQueueRuleView: () => initialState,
        setLoadingDockQueueRuleConfiguration: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDockQueueRuleConfigurationView: (state, action: PayloadAction<IDockQueueConfiguration>) => {
            state.view = action.payload;
        },
    },
});

export const { resetDockQueueRuleView, setLoadingDockQueueRuleConfiguration, setDockQueueRuleConfigurationView } =
managementDockQueueRuleConfigurationSlice.actions;

export default managementDockQueueRuleConfigurationSlice.reducer;
