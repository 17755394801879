import { IDropdownOption } from "@fluentui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigDockMerchandiseForm } from "../../../../../models/config/dockMerchandise";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateFormProperty } from "../../../../../redux/reducers/config/dockMerchandise/form";
import { compileNameOfProperty } from "../../../../../utils";
import { BooleanSelector, MerchandiseOriginSelector, MerchandiseTypeSelector } from "../../../../request/common/selectors";
import { DockSelector } from "../../../../request/common/selectors/docks/docksSelector";
import { ConfigMerchandiseCategoryFormStyle } from "./formStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ConfigDockMerchandiseForm = () => {
    const theme = useTheme();
    const style = ConfigMerchandiseCategoryFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { form } = useAppSelector((state) => state.configDockMerchandiseForm);
    const { control } = useForm<IConfigDockMerchandiseForm>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateFormProperty({ name, value }));
    };

    const onSelectorChange = (option: any, name: string) => {
        onUpdateFormProperty(name, option.key);
    };

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        if (option.key !== -1) {
            onUpdateFormProperty(propertyName, !!+option.key);
        } else {
            onUpdateFormProperty(propertyName, undefined);
        }
    };
    return (
        <div className={style.form}>
            <div className={style.row}>
                <DockSelector
                    onChange={onSelectorChange}
                    selectedKey={form.dockId}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("dockId")}
                    title={t("DOCK_MERCHANDISE.DOCK")}
                    control={control}
                    isRequired={true}
                ></DockSelector>

                <MerchandiseOriginSelector
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseOriginId !== -1 ? form.merchandiseOriginId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("merchandiseOriginId")}
                    title={t("MERCHANDISE_ORIGIN")}
                    control={control}
                    allOption={true}
                />
            </div>
            <div className={style.row}>
                <MerchandiseTypeSelector
                    filterOptions={false}
                    onChange={onSelectorChange}
                    selectedKey={form.merchandiseTypeId !== -1 ? form.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("merchandiseTypeId")}
                    title={t("MERCHANDISE_TYPE")}
                    control={control}
                    allOption={true}
                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={form.humanConsumption === null || form.humanConsumption === undefined ? -1 : +form.humanConsumption}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("humanConsumption")}
                    title={t("HUMAN_CONSUMPTION")}
                    control={control}
                    allOption={true}
                />
            </div>
            <div className={style.row}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={form.organicProduct === null || form.organicProduct === undefined ? -1 : +form.organicProduct}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("organicProduct")}
                    title={t("ORGANIC_PRODUCT")}
                    control={control}
                    allOption={true}
                />
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={form.fumigated === null || form.fumigated === undefined ? -1 : +form.fumigated}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("fumigated")}
                    title={t("FUMIGATED")}
                    control={control}
                    allOption={true}
                />
            </div>
            <div className={style.simpleRow}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={form.halalCertified === null || form.halalCertified === undefined ? -1 : +form.halalCertified}
                    propertyName={compileNameOfProperty<IConfigDockMerchandiseForm>("halalCertified")}
                    title={t("HALAL_CERTIFIED")}
                    control={control}
                    allOption={true}
                />
            </div>
        </div>
    );
};
