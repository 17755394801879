import React,{ useCallback, useMemo } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch } from "../../../../../redux/hooks";
import { updateOperationSignupProperty } from "../../../../../redux/reducers/user/signup/signup";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { FormField } from "../../../../common/fields/fields";
import { TitleH5 } from "../../../../common/titles/titles";
import { UserSignupOperationEmailItem } from "./item/item";
import { UserSignupOperationEmailsFormStyle } from "./listStyle.jss";
import { IAdminData } from "../../../../../models";
import { ColorType } from "../../../../../common/enum/ColorType";

interface IUserSignupOperationEmailsFormProps {
    data: IAdminData;
    adminRegister: UseFormRegister<IAdminData>;
    adminErrors: FieldErrors<IAdminData>;
}

export const UserSignupOperationEmailsForm: React.FC<IUserSignupOperationEmailsFormProps> = ({ data, adminErrors, adminRegister }) => {
    const theme = useTheme();
    const style = UserSignupOperationEmailsFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateOperationSignupProperty(payload));
        },
        [dispatch],
    );

    const maxIndex = useMemo(() => {
        return data.emails.length - 1;
    }, [data.emails]);

    return (
        <>
            <div className={style.row}>
                <div className={style.title}>
                    <TitleH5
                        title={t("USER.TITLES.OPERATION_DEPARTMENT")}
                        color={ColorType.primary}
                        bold={true}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.LANDLINE")}
                        value={data.phone}
                        error={adminErrors.phone}
                        {...adminRegister(compileNameOfProperty<IAdminData>("phone"), { onChange: onInputChange, value: data.phone })}
                    isRequired={true}
                    />
                </div>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.MOBILE_PHONE")}
                        value={data.mobilePhone}
                        error={adminErrors.mobilePhone}
                        {...adminRegister(compileNameOfProperty<IAdminData>("mobilePhone"), {
                            onChange: onInputChange,
                            value: data.mobilePhone,
                        })}
                    />
                </div>
            </div>
            <div className={style.rowEmails}>
                {data.emails.map((email, index) => (
                    <div
                        className={style.columnEmail}
                        key={`${UserSignupOperationEmailsForm.name}-${index}`}
                    >
                        <UserSignupOperationEmailItem
                            email={email}
                            key={`${UserSignupOperationEmailsForm.name}-${index}`}
                            isLastIndex={index === maxIndex}
                            isUniqueIndex={data.emails.length === 1}
                            index={index}
                            
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
