interface ILabelNumberProps {
    value: any;
}

export const LabelNumber = (props: ILabelNumberProps) => {
    const exportTonumber = () => {
        return String(props.value).replace(".", ",");
    };

    return <label>{exportTonumber()}</label>;
};
