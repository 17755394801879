import { ManagementPositioningDisplayApi } from "../../../../api/management/positioningDisplay";
import { Milliseconds } from "../../../../common/enum";
import { Toastr } from "../../../../components/common/toast/toast";

const managementPositioningDisplayApi = new ManagementPositioningDisplayApi();

export const fetchManagementPositioningDisplayAction = async (organization?: string): Promise<any> => {
    try {
        return await managementPositioningDisplayApi.getManagementPositioningDisplayAsync(organization);
    } catch (error) {
        Toastr("", { autoClose: Milliseconds.FIVE_SEC, pauseOnFocusLoss: false }, error).error();
        return false;
    }
};
