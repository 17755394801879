import { RootState } from "../../store";

export const requestListSelector = (state: RootState) => {
    return state.requestlist.list;
};
export const loadingRequestListSelector = (state: RootState) => {
    return state.requestlist.loading;
};

export const currentRequestSelector = (state: RootState) => {
    return state.editRequest;
};

export const editRequestId = (state: RootState) => {
    return state.editRequest.headerRequest.id;
};
