import { t } from "i18next";
import { useEffect, useMemo } from "react";
import { useTheme } from "react-jss";
import { routeFeatures } from "../../../../../common/constants/routeFeatures";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IRequestDetail, IRequestState, ViewType } from "../../../../../models";
import { IResponsive, IUserRoleView, IView, IField } from "../../../../../models/requests/IUserRoleView";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { compileNameOfProperty, convertToLocal } from "../../../../../utils";
import { RequestListCardStyle } from "../requestCard.jss";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { RequestInspections } from "../../../common/columns/utils/requestPartials/requestListPartials";
import { RoleType } from "../../../../../common/enum";
import { useSelector } from "react-redux";
import { getUserRole } from "../../../../../redux/selectors/user/role/role";
import { RequestListColumnsStyle } from "../../../common/columns/requestListColumnsStyle.jss";

interface IColumnsCard {
    id: string;
    name: string;
    onRender: (e: IRequestDetail, style?: string) => any;
    responsive?: IResponsive | null;
}
export const RequestCardColumns = (): IColumnsCard[] => {
    const viewColumns: any[] = [];
    const { viewPermission } = useAppSelector((state) => state.userRoleViews);
    const { data } = useAppSelector((state) => state.catalog.requestStates);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = RequestListCardStyle({ theme });
    const userRole: any = useSelector(getUserRole);
    const inspectionStyle = RequestListColumnsStyle({ theme });

    const stateClass = (item: IRequestDetail) => {
        return `state-${item.stateCode}`;
    };

    const findStateDescription = (item: IRequestDetail) => {
        if (item.stateDescription) {
            return item.stateDescription;
        }

        if (data) {
            return data.find((state: IRequestState) => state.sort === item.stateCode)?.description;
        }

        return DefaultValues.EMPTY_LIST;
    };
    useEffect(() => {
        data === undefined && dispatch(fetchRequestStates());
    }, [dispatch, data]);

    const columnsCard: IColumnsCard[] = [
        {
            id: compileNameOfProperty<IRequestDetail>("transportUnitNumber"),
            name: t("LIST.NAME.TRANSPORT_UNIT_NUMBER"),
            onRender: (item: IRequestDetail) => item.transportUnitNumber,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("transportUnitCode"),
            name: t("LIST.NAME.TRANSPORT_UNIT_TYPE"),
            onRender: (item: IRequestDetail) => item.transportUnitCode,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("valued"),
            name: t("LIST.NAME.VALUED"),
            onRender: (item: IRequestDetail) => item.valued,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("description"),
            name: t("LIST.NAME.DESCRIPTION"),
            onRender: (item: IRequestDetail) => item.description,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("customerName"),
            name: t("LIST.NAME.CUSTOMER"),
            onRender: (item: IRequestDetail) => item.customerName,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("stateCode"),
            name: "",
            onRender: (item: IRequestDetail) => (
                <span className={`${styles.stateContainer} ${styles[stateClass(item) as keyof typeof styles]}`}>{findStateDescription(item)}</span>
            ),
            responsive: null,
        },

        {
            id: compileNameOfProperty<IRequestDetail>("customerReference"),
            name: t("LIST.NAME.CUSTOMER_REFERENCE"),
            onRender: (item: IRequestDetail) => item.customerReference,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("dockCode"),
            name: t("LIST.NAME.DOCK"),
            onRender: (item: IRequestDetail) => item.dockCode,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("merchandiseTypeDescription"),
            name: t("LIST.NAME.MERCHANDISE_TYPE"),
            onRender: (item: IRequestDetail) => item.merchandiseTypeDescription,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("date"),
            name: t("LIST.NAME.DATE"),
            onRender: (item: IRequestDetail) => item.date,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("dockAssignmentOn"),
            name: t("LIST.NAME.DOCK_ASSIGNMENT_DATE_TIME"),
            onRender: (item: IRequestDetail) => item.dockAssignmentOn,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("representativePresenceTypeDescription"),
            name: t("LIST.NAME.REPRESENTATIVE_PRESENCE"),
            onRender: (item: IRequestDetail) => item.representativePresenceTypeDescription,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("entryOn"),
            name: t("LIST.NAME.ENTRY_ON"),
            onRender: (item: IRequestDetail) => (item.entryOn ? convertToLocal(item.entryOn.toString(), FormatDate.HOUR_MINUTE) : null),
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("dockStartOn"),
            name: t("LIST.NAME.DOCK_START_STATE"),
            onRender: (item: IRequestDetail) => item.dockStartOn,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("dockEndOn"),
            name: t("LIST.NAME.DOCK_END_STATE"),
            onRender: (item: IRequestDetail) => item.dockEndOn,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("exitOn"),
            name: t("LIST.NAME.EXIT_STATE"),
            onRender: (item: IRequestDetail) => item.exitOn,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("sort"),
            name: t("LIST.NAME.SORT"),
            onRender: (item: IRequestDetail) => item.sort,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("customerName"),
            name: t("LIST.NAME.CUSTOMER"),
            onRender: (item: IRequestDetail) => item.customerName,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("documentsNumber"),
            name: t("LIST.NAME.DOCUMENTS_NUMBER"),
            onRender: (item: IRequestDetail) => item.documentsNumber,
            responsive: null,
        },
        {
            id: compileNameOfProperty<IRequestDetail>("inspections"),
            name: t("LIST.NAME.INSPECTIONS"),
            onRender: (item: IRequestDetail) => (
                <RequestInspections
                    requestInspections={item.inspections}
                    showActuationGroup={userRole.id === RoleType.CUSTOMER_ADMIN || userRole.id === RoleType.CUSTOMER_USER}
                    styles={inspectionStyle}
                />
            ),
            responsive: null,
        },
    ];
    const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === routeFeatures.REQUEST);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view) => view.type === ViewType.LIST) as IView;
    }, [viewRequestPermission?.views]);

    viewConfig.fields.forEach((value: IField) => {
        const columnFilter = columnsCard.filter((column: IColumnsCard) => column.id.toLocaleLowerCase() === value.name.toLocaleLowerCase()).shift();
        if (columnFilter) {
            columnFilter.responsive = value.responsive;
            viewColumns.push(columnFilter);
        }
    });

    return viewColumns;
};
