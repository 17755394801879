import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IConfigDockMerchandiseForm } from "../../../../models/config/dockMerchandise";

interface IConfigDockMerchandiseFilterState {
    filter: IConfigDockMerchandiseForm;
}

const initialState: IConfigDockMerchandiseFilterState = {
    filter: {
        dockId: -1,
        merchandiseOriginId: -1,
        merchandiseTypeId: -1,
    },
};

export const configDockMerchandiseFilterSlice = createSlice({
    name: "configDockMerchandiseFilterSlice",
    initialState,
    reducers: {
        resetConfigDockMerchandiseFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetConfigDockMerchandiseFilter, updateFilterProperty } = configDockMerchandiseFilterSlice.actions;

export default configDockMerchandiseFilterSlice.reducer;
