import { IColumn } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { IMasterAppointmentTypeActuation } from "../../../../../../../models/masterAppointment/IAppointmentTypeActuation";
import { fetchMasterAppointmentTypeActuation } from "../../../../../../../redux/actions/masterAppointment/appointmentTypeActuation/appointmentTypeActuation";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { setShowPopUpMasterAppointment } from "../../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterBlocksColumnsStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";

export const MasterAppointmentTypeActuationColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterBlocksColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useAppDispatch();

    const columns: IColumn[] = [
       
        {
            key: compileNameOfProperty<IMasterAppointmentTypeActuation>("merchandiseCategoryGroupDescription"),
            minWidth: 170,
            maxWidth: 200,
            name: t("APPOINTMENT_TYPES_ACTUATION.MERCHANDISE_CATEGORY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterAppointmentTypeActuation>("merchandiseCategoryGroupDescription")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.MERCHANDISE_CATEGORY")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterAppointmentTypeActuation) => <span>{item.merchandiseCategoryGroupDescription ?? t("ALL")}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterAppointmentTypeActuation>("merchandiseTypeDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: t("APPOINTMENT_TYPES_ACTUATION.MERCHANDISE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterAppointmentTypeActuation>("merchandiseTypeDescription")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.MERCHANDISE_TYPE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterAppointmentTypeActuation) => <span>{item.merchandiseTypeDescription ?? t("ALL")}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterAppointmentTypeActuation>("actuationTypeDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: t("APPOINTMENT_TYPES_ACTUATION.ACTUATION_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterAppointmentTypeActuation>("actuationTypeDescription")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.ACTUATION_TYPE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterAppointmentTypeActuation) => <span>{item.actuationTypeDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterAppointmentTypeActuation>("appointmentTypeDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: t("APPOINTMENT_TYPES_ACTUATION.APPOINTMENT_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterAppointmentTypeActuation>("appointmentTypeDescription")}
                    title={t("APPOINTMENT_TYPES_ACTUATION.APPOINTMENT_TYPE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterAppointmentTypeActuation) => <span>{item.appointmentTypeDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterAppointmentTypeActuation) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMasterAppointment(PopUpCodes.UPDATE));
                            dispatch(fetchMasterAppointmentTypeActuation(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
