import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";

import { postAndPutConfigMerchandiseCategory, removeConfigMerchandiseCategory } from "../../../../redux/actions/config/merchandiseCategory";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    resetConfigmerchandiseCategoryForm,
    setConfigMerchandiseCategoryShowPopUp,
} from "../../../../redux/reducers/config/merchandiseCategory/form";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ConfigMerchandiseCategoryForm } from "./form/form";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ConfigMerchandiseCategoryFormController = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { loading, form, showPopUp } = useAppSelector((state) => state.configMerchandiseCategoryForm);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }

        return "";
    }, [showPopUp]);

    const onCancelPopUp = () => {
        dispatch(setConfigMerchandiseCategoryShowPopUp(PopUpCodes.NONE));
    };

    const onDimissed = () => {
        dispatch(resetConfigmerchandiseCategoryForm());
    };
    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postAndPutConfigMerchandiseCategory()).then((response: any) => {
                response && onCancelPopUp();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(removeConfigMerchandiseCategory()).then((response: any) => {
                response && onCancelPopUp();
            });
        }
    };

    const isDisabledButton = useMemo(() => {
        const keys = ["merchandiseTypeId", "merchandiseOriginId", "merchandiseCategoryId"];
        let item: any = form;
        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }
        return false;
    }, [form]);

    return (
        <>
            <PopUpForm
                content={<ConfigMerchandiseCategoryForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancelPopUp}
                onSave={onSave}
                onDimissed={onDimissed}
                isLoading={loading}
                height={"30vh"}
                isDisabledButton={isDisabledButton}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancelPopUp}
                onAccept={onSave}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
                width={"auto"}
                loading={loading}
                disabledActions={loading}
            />
        </>
    );
};
