import { IManagemenTActiveSummaryFilter } from "../../../../models/management/summary/ISummary";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetManagementSummaryFilter, updateFilterProperty } from "../../../../redux/reducers/management/summary/filter";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { TextInput } from "../../../common/inputs/textInput";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { fetchManagementSummaryListAction, setPageAction } from "../../../../redux/actions/management/summary/summary";

export const ManagementSummaryFilter = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.SUMMARY" });
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.managementSummaryFilter);

    const onApplyFilter = () => {
        dispatch(setPageAction(1));
        dispatch(fetchManagementSummaryListAction());
    };

    const onResetFilter = () => {
        dispatch(resetManagementSummaryFilter());
        onApplyFilter();
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    return (
        <FilterHeader
            onApply={onApplyFilter}
            onReset={onResetFilter}
            content={
                <div style={{ width: "30%" }}>
                    <TextInput
                        value={filter.summaryNumber}
                        propertyName={compileNameOfProperty<IManagemenTActiveSummaryFilter>("summaryNumber")}
                        label={t("SUMMARY_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onApplyFilter}
                    />
                </div>
            }
        />
    );
};
