import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITimeZone, IZoneAvaiable } from "../../../models/appointment/IDashboardZonesAvaiable";
import { IAppointmentAssign } from "../../../models/appointment/IAppointmentAssign";

export interface IAppointmentsAvailableZone {
    available: IZoneAvaiable;
    loading: boolean;
    selected?: ITimeZone;
    disalbedRequestEditForm: boolean;
    selectedToAssign: IAppointmentAssign | null;
    forceAppointmentType: boolean;
}

const initialState: IAppointmentsAvailableZone = {
    available: {
        appointmentTypeId: 0,
        turnId: 0,
    },
    selected: undefined,
    selectedToAssign: null,
    disalbedRequestEditForm: false,
    forceAppointmentType: false,
    loading: false,
};

export const appointmentAvailableZone = createSlice({
    name: "appointmentAvailableZone",
    initialState,
    reducers: {
        resetAppointmentAvailable: () => initialState,
        setLoadingAppointmentAvailableZone: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAppointmentAvailableZone: (state, action: PayloadAction<IZoneAvaiable>) => {
            state.available = action.payload;
        },
        setAppointmentSelectionZone: (state, action: PayloadAction<ITimeZone | undefined>) => {
            state.selected = action.payload;
        },
        setAppointmentSelectionToAssign: (state, action: PayloadAction<IAppointmentAssign | null>) => {
            state.selectedToAssign = action.payload;
        },
        setDisabledRequestEditForm: (state, action: PayloadAction<boolean>) => {
            state.disalbedRequestEditForm = action.payload;
        },
        setAvailableTimeZones: (state, action: PayloadAction<ITimeZone[] | undefined>) => {
            state.available.timeZones = action.payload;
        },
        setForceAppointmentType: (state, action: PayloadAction<boolean>) => {
            state.forceAppointmentType = action.payload;
        },
    },
});

export const {
    resetAppointmentAvailable,
    setLoadingAppointmentAvailableZone,
    setAppointmentAvailableZone,
    setAppointmentSelectionZone,
    setAppointmentSelectionToAssign,
    setForceAppointmentType,
} = appointmentAvailableZone.actions;

export default appointmentAvailableZone.reducer;
