import { createUseStyles } from "react-jss";

export const CodeViewerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
    header: {
        background: theme.colors.palette.purpleLighter,
        display: "flex",
        justifyContent: "end",
    },

    codeArea: (props: any) => ({
        height: props.height ?? "calc(85vh - 10rem)",
        border: `2px solid ${theme.colors.palette.purpleLighter}`,
    }),
    integrationBox: {
        height: "100%",
        overflowY: "scroll",
    },

    code: {
        "& .linenumber": {
            minWidth: "0px !important",
        },
        "& pre": {
            background: "transparent !important",
        },
    },
    copyIcon: {
        color: theme.colors.palette.purple,
        fontWeight: "bolder",
        padding: 5,
    },
}));
