import { MasterAppointmentTimeZonesBoardAPI } from "../../../../../api/masterAppointment/appointmentBoard/AppointmentTimeZones";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IBoardHistoryAppointmentTimeZone } from "../../../../../models/masterAppointment/IAppointmentBoard";
import { convertToLocal, throwErrorToastrAsync } from "../../../../../utils";
import { setAppointmentTimeZoneData } from "../../../../reducers/catalog/catalog";
import { setLoadingMasterAppointmentTimeZoneForm } from "../../../../reducers/masterAppointment/appointmentBoard/appointmentDockTimeZone/form";
import { fetchMasterAppointmentBoard } from "../appointmentBoard";

const appointmentApi = new MasterAppointmentTimeZonesBoardAPI();

export const postMasterAppointmentTimeZones = (dashboardId: string, form: IBoardHistoryAppointmentTimeZone): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentTimeZoneForm(true));
            form = transformDateToHourString(form);
            const response = await appointmentApi.postMasterAppointmentTimeZonesBoard(dashboardId, form);

            dispatch(setAppointmentTimeZoneData(undefined));
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentTimeZoneForm(false));
            return false;
        } 
    };
};

export const updateMasterAppointmentTimeZones = (dashboardId: string, id: string, form: IBoardHistoryAppointmentTimeZone): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentTimeZoneForm(true));
            form = transformDateToHourString(form);
            const response = await appointmentApi.updateMasterAppointmentTimeZonesBoard(dashboardId, id, form);
            dispatch(setAppointmentTimeZoneData(undefined));
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentTimeZoneForm(false));
            return false;
        }
    };
};

export const deleteMasterAppointmentTimeZones = (dashboardId: string, id: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentTimeZoneForm(true));
            const response = await appointmentApi.deleteMasterAppointmentTimeZonesBoard(dashboardId, id);
            dispatch(setAppointmentTimeZoneData(undefined));

            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingMasterAppointmentTimeZoneForm(false));
            dispatch(fetchMasterAppointmentBoard(dashboardId));
        }
    };
};

const transformDateToHourString = (form: IBoardHistoryAppointmentTimeZone): IBoardHistoryAppointmentTimeZone => {
    return {
        ...form,
        startTime: convertToLocal(form.startTime, FormatDate.HOUR_MINUTE),
        endTime: convertToLocal(form.endTime, FormatDate.HOUR_MINUTE),
    };
};
