import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterSealType } from "../../../../../models/resources/master/ITypeSeal";


export interface IMasterTypesSealsFormState {
    form: IMasterSealType;
    loading: boolean;
}

const initialState: IMasterTypesSealsFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterTypesSealsForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterTypesSealsForm: () => initialState,
        setLoadingMasterTypesSealsForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTypesSealsForm: (state, action: PayloadAction<IMasterSealType>) => {
            state.form = action.payload;
        },
        updateMasterTypesSealsProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterTypesSealsForm, setLoadingMasterTypesSealsForm, setMasterTypesSealsForm, updateMasterTypesSealsProperty } =
    masterTypesSealsForm.actions;

export default masterTypesSealsForm.reducer;
