import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../models";
import { IManagementUserFilter } from "../../../../models/management/user/IManagementUserFilter";
import { fetchRoleOptions } from "../../../../redux/actions/catalog/catalog";
import { fetchManagementUserListAction } from "../../../../redux/actions/management/user/user";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { managementUserFilterReset, updateFilterProperty } from "../../../../redux/reducers/management/user/filter";
import { compileNameOfProperty, mapRolesToDropDownOptions } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { TextInput } from "../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../common/selectors";
import { CustomSelector } from "../../../common/selectors/customSelector/customSelector";
import { ManagementUserFilterStyle } from "./filterStyle.jss";
import { getRolesFiltered } from "../../../../common/utils/filterUserRole";
import { RoleType } from "../../../../common/enum";

export const ManagementUserFilter = () => {
    const theme = useTheme();
    const styles = ManagementUserFilterStyle({ theme });
    const { filter } = useAppSelector((state) => state.managementUserFilter);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.USER.LIST" });
    const dispatch = useAppDispatch();

    const roles = useAppSelector((store) => store.catalog.roles.data);
    const currentUserRoleData = useAppSelector((store) => store.role.roleData);
    const isSuperAdmin = currentUserRoleData.id === RoleType.SUPER_ADMIN;

    useEffect(() => {
        if (roles === undefined) {
            dispatch(fetchRoleOptions());
        }
    }, [roles]);

    const onInputChange = useCallback(
        (event: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: event.target.value,
            };
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            const payload: IGenericPayload = {
                name,
                value: event.key,
            };
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onBooleanSelectorChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onApply = useCallback(() => {
        dispatch(fetchManagementUserListAction());
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(managementUserFilterReset());
        dispatch(fetchManagementUserListAction());
    }, [dispatch]);

    const superAdminFilterItems = () => {
        return (
            <div className={styles.filterContainer}>
                <div className={styles.filterSection}>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.name}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("name")}
                            label={t("NAME")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.entityName}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("entityName")}
                            label={t("ENTITY")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <CustomSelector
                            onChange={onSelectorChange}
                            selectedKey={filter.roleId}
                            options={mapRolesToDropDownOptions(getRolesFiltered(roles ?? [], currentUserRoleData.roleManagement))}
                            title={t("TYPE")}
                            name={compileNameOfProperty<IManagementUserFilter>("roleId")}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <BooleaMultiSelector
                            selectedKey={filter.unregistered}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("unregistered")}
                            title={t("UNREGISTERED")}
                            onChange={onBooleanSelectorChange}
                        />
                    </div>
                </div>
                <div className={styles.filterSection}>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.parentId}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("parentId")}
                            label={t("EMAIL_ADMIN")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.email}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("email")}
                            label={t("EMAIL")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const defaultFilterItems = () => {
        return (
            <div className={styles.filterContainer}>
                <div className={styles.filterSection}>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.name}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("name")}
                            label={t("NAME")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.entityName}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("entityName")}
                            label={t("ENTITY")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <TextInput
                            value={filter.email}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("email")}
                            label={t("EMAIL")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <CustomSelector
                            onChange={onSelectorChange}
                            selectedKey={filter.roleId}
                            options={mapRolesToDropDownOptions(getRolesFiltered(roles ?? [], currentUserRoleData.roleManagement))}
                            title={t("TYPE")}
                            name={compileNameOfProperty<IManagementUserFilter>("roleId")}
                        />
                    </div>
                    <div className={styles.filterItem}>
                        <BooleaMultiSelector
                            selectedKey={filter.unregistered}
                            propertyName={compileNameOfProperty<IManagementUserFilter>("unregistered")}
                            title={t("UNREGISTERED")}
                            onChange={onBooleanSelectorChange}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <FilterHeader
            buttonColumn={isSuperAdmin}
            onApply={onApply}
            content={isSuperAdmin ? superAdminFilterItems() : defaultFilterItems()}
            onReset={onReset}
            height={isSuperAdmin ? 120 : undefined}
        />
    );
};
