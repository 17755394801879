import { IOrganization } from "../../../../../../models";
import { useAppSelector } from "../../../../../../redux/hooks";
import { SignupResumeDataOrganizationStyle } from "./resumeOrganization.jss";

interface Props {
    organizationId: string;
}
export const SignupResumeOrganization: React.FC<Props> = ({ organizationId }) => {
    const styles = SignupResumeDataOrganizationStyle();

    const organiations: IOrganization[] = useAppSelector((store) => store.catalog.organizations.data);

    const organization = organiations.filter((org: IOrganization) => org.id === organizationId)[0]?.name;
    return (
        <div className={styles.container}>
            <label>{organization}</label>
        </div>
    );
};
