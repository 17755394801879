import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserClaimsState {
    token: string;
    isClaimsLoaded: boolean;
}

const initialState: UserClaimsState = {
    token: "",
    isClaimsLoaded: false,
};

export const userClaimsSlice = createSlice({
    name: "userClaims",
    initialState,
    reducers: {
        setUserClaims: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            state.isClaimsLoaded = (state.token ? true : false);
        },
        resetUserClaims: () => initialState,
    },
});

export const { setUserClaims, resetUserClaims } = userClaimsSlice.actions;

export default userClaimsSlice.reducer;
