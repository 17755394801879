import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { RequiredSpan } from "../required/requiredSpan";
import { RequiredFieldMessageStyle } from "./requiredMessageStyle.jss";

export const RequiredFieldsMessage = () => {
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST.FIELDS" });
    const theme = useTheme();
    const style = RequiredFieldMessageStyle({ theme });
    return (
        <div className={style.container}>
            <RequiredSpan></RequiredSpan> {t("REQUIRED_FIELDS")}
        </div>
    );
};
