import { RootState } from "../../store";


export const ErrorHandlerSelector = (state: RootState) => {
    return state.errorHandler;
};

export const getErrorHandler = (state: RootState) => {
    const errorHandler = ErrorHandlerSelector(state);
    return errorHandler.errorHandlerData;
};
