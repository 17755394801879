import { IGenericPayload } from "../../../../../models";
import { Checkboxs } from "../../../../common/checkbox/checkbox";
import { RadioButtonGroup } from "../../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { compileNameOfProperty } from "../../../../../utils";
import { IOrganizationEventData } from "../../../../../models/organization/IOrganizationEvent";
interface IEventItemProps {
    eventData: IOrganizationEventData;
    eventName: string;
    checkBoxLabel: string;
    radioButtonLabel: string;
    onChangeEvent: (payload: IGenericPayload) => any;
}
export const EventItem: React.FC<IEventItemProps> = ({ onChangeEvent, eventData, eventName, checkBoxLabel, radioButtonLabel }) => {
    const onRadioButtonChange = (optionKey: string, name: string) => {
        const payload: IGenericPayload = {
            name: eventName,
            value: {
                requiredForInspection: !!+optionKey ? eventData.requiredForInspection : false,
                active: !!+optionKey,
            },
        };

        onChangeEvent(payload);
    };
    const onCheckboxChange = (event?: any, checked = false) => {
        const payload: IGenericPayload = {
            name: eventName,
            value: {
                ...eventData,
                requiredForInspection: eventData.active ? checked : false,
            },
        };
        onChangeEvent(payload);
    };

    return (
        <>
            <RadioButtonGroup
                selectedKey={eventData.active ? "1" : "0"}
                onChange={onRadioButtonChange}
                name={compileNameOfProperty<IOrganizationEventData>("active")}
                label={radioButtonLabel}
            />
            <Checkboxs
                name={compileNameOfProperty<IOrganizationEventData>("requiredForInspection")}
                label={checkBoxLabel}
                isChecked={eventData.requiredForInspection}
                handleChange={onCheckboxChange}
                disabled={!eventData.active}
            />
        </>
    );
};
