import { UserStepNumber } from "../stepNumber/stepNumber";
import { UserStepStyled } from "./step.jss";

interface IStepNumberProps {
    number: string;
    title: string;
    subtitle?: string;
    id: number;
    step: number;
    onClick?: () => void;
}

export const UserStep = (props: IStepNumberProps) => {
    const stepStyled = UserStepStyled();
    const notSelectedStepClass = props.step !== props.id ? stepStyled.stepNotSelected : "";

    return (
        <>
            <div className={`${stepStyled.step} ${notSelectedStepClass}`}>
                <div className={stepStyled.leftColumn}>
                    <UserStepNumber
                        selected={props.step === props.id}
                        title={props.number}
                    />
                </div>
                <div className={stepStyled.rightColumn}>
                    <div className={props.step === props.id ? stepStyled.titleContainerSelected : stepStyled.titleContainer}>{props.title}</div>
                    {props.subtitle && (
                        <div className={props.step === props.id ? stepStyled.subtitleContainerSelected : stepStyled.subtitleContainer}>
                            {props.subtitle}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
