import { IDropdownOption } from "@fluentui/react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { IGenericPayload } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils/formUtils";
import { FormField } from "../../../../../../common/fields/fields";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { MasterTerminalFormStyle } from "./formStyle.jss";

import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterTerminal } from "../../../../../../../models/resources/master/ITerminal";
import { updateMasterTerminalProperty } from "../../../../../../../redux/reducers/resources/master/terminal/form";

export const MasterTerminalForm = () => {
    const theme = useTheme();
    const style = MasterTerminalFormStyle({ theme });
    const dispatch = useAppDispatch();

    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const { form } = useAppSelector((state) => state.masterTerminalForm);
    const { register, control } = useForm<IMasterTerminal>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateMasterTerminalProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterTerminal>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterTerminal>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterTerminal>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                />
            </div>
        </div>
    );
};
