import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const UserSelfSignUpcontainerStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        paddingTop: "5vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    content: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        maxWidth: "60%",
        backgroundColor: theme.colors.site.background,
        flexDirection: "column",
        padding: theme.paddings.default,
        rowGap: theme.gaps.sizes.base,

        [mediaQueries.TABLET]: {
            maxWidth: "100%",
            padding: 32,
        },
    },
    dataContainer: {
        display: "flex",
        flexFlow: "column",
        rowGap: theme.gaps.sizes.base,

        [mediaQueries.MOBILE]: {
            display: "flex",
        },
    },
    form: {
        flexFlow: "row",
        display: "flex",
        columnGap: 32,
        [mediaQueries.MOBILE]: {
            flexFlow: "column",
        },
    },
    avatar: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    data: {
        width: "100%",
        rowGap: 20,
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-around",
        [mediaQueries.MOBILE]: {
            padding: "0 20px 20px 20px",
        },
    },
    buttonContainer: {
        flexFlow: "row",
        display: "flex",
        justifyContent: "end",
        columnGap: theme.paddings.default,
        width: "100%",
    },
}));
