import { createUseStyles } from "react-jss";

export const CustomSelectorStyled = createUseStyles((theme: any) => ({
    selectorContainer: {
        position: "relative",
        width: "100%",
        marginTop: 5,
    },
    rootStyles: {
        borderRadius: theme.borders.default.radius,
        backgroundColor: theme.colors.palette.white,
        color: theme.colors.form.input.filled,
        fontSize: theme.fonts.sizes.base,
        paddingLeft: "7px",
        "& .is-disabled ": {
            opacity: "0.5",
        },
    },
    inputText: (props: any) => ({
        color: theme.colors.form.input.filled,
        backgroundColor: "transparent",
        paddingTop: 3,
    }),
    callout: {
        marginTop: theme.paddings.combo.wrapperoffset,
        "& .ms-Checkbox": {
            color: theme.colors.form.input.filled,
        },
        "& .is-checked": {
            "& .ms-Checkbox-checkbox": {
                backgroundColor: theme.colors.palette.purple,
            },
        },
    },
    optionText: {
        minHeight: 30,
    },
    label: {
        fontFamily: theme.fonts.families.site,
        fontSize: theme.fonts.sizes.xxs,
        fontWeight: "bold",
        color: theme.colors.palette.grey,
        padding: "2px 5px 0px 5px",
        position: "absolute",
        pointerEvents: "none",
        top: -11,
        transform: "translateY(0px)",
        transition: "all 0.2s ease-in-out",
        left: "0px",
    },
}));
