import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterTurnsForm, setMasterTurnsForm } from "../../../../reducers/resources/master/turns/form";
import { setMasterLoadingTurnsList, setMasterTurnsList } from "../../../../reducers/resources/master/turns/list";
import { AppThunk, store } from "../../../../store";


const catalogAPI = new CatalogAPI();

export const fetchMasterTurnsList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMasterLoadingTurnsList(true));
        const response = await catalogAPI.getMasterTurnsList();
        dispatch(setMasterTurnsList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setMasterLoadingTurnsList(false));
    }
};

export const fetchMasterTurn = (id:number): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterTurnsForm(true));
        const response = await catalogAPI.getMasterTurn(id);
        dispatch(setMasterTurnsForm(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterTurnsForm(false));
    }
};

export const postToMasterTurnForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterTurnsForm;

        try {
            dispatch(setLoadingMasterTurnsForm(true));
            if (form.id) {
                await catalogAPI.putMasterTurns(form);
            } else {
                await catalogAPI.postMasterTurns(form);
            }
            dispatch(fetchMasterTurnsList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error); dispatch(setLoadingMasterTurnsForm(false));
            return false;
        } 
    };
};

export const patchSortTurnsList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setMasterLoadingTurnsList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterTurnsList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setMasterLoadingTurnsList(false));
        }
    };

