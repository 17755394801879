import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { fetchSealTypesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterSealTypesForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { postToMasterSealTypesForm } from "../../../../../../redux/actions/resources/master/sealtypes/sealTypes";
import { setShowPopUpMaster } from "../../../../../../redux/reducers/resources/master/master";
import { resetMasterTypesSealsForm } from "../../../../../../redux/reducers/resources/master/sealTypes/form";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterSelTypesControllerForm {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterSelTypesControllerForm: React.FC<IMasterSelTypesControllerForm> = ({ widthPopUp, heigthPopUp }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { data } = useAppSelector((state) => state.catalog.sealTypeRemaining);
    const { form, loading } = useAppSelector((state) => state.masterSealTypesForm);

    const onAcceptPopUp = () => {
        dispatch(postToMasterSealTypesForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(setShowPopUpMaster(PopUpCodes.NONE));
    };

    const onDimissed = () => {
        dispatch(resetMasterTypesSealsForm());
    };

    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    const titlePopUp = useMemo(() => {
        let title = "";
        if (showPopUp === PopUpCodes.CREATE) {
            title = t("CREATE") + " " + t("BUTTON.SEAL_TYPES");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            title = t("UPDATE") + " " + t("BUTTON.SEAL_TYPES");
        }
        return title;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchSealTypesRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterSealTypesForm
                    showNotData={showNotData}
                    data={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            onlyHidden={showNotData}
            isDisabledButton={isDisabledButton}
            isLoading={loading}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
