import { StepperState } from "../../../reducers/user/signup/stepper";
import { RootState } from "../../../store";

export const userSignupStepperSelector = (state: RootState): StepperState => {
    return state.userSignupStepper;
};

export const currentUserSignupStepSelector = (state: RootState): number => {
    const step = userSignupStepperSelector(state);
    return step.step;
};
