import { IComboBoxOption } from "@fluentui/react";
import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { IGenericPayload } from "../../../../models";
import { IManagementUserSignupFilter } from "../../../../models/management/userSignup/IManagementUserSignupFilter";
import IUserSignupState from "../../../../models/user/IUserSignupState";
import IUserSignupType from "../../../../models/user/IUserSignupType";
import { fetchManagementUserSignupListAction } from "../../../../redux/actions/management/userSignup/userSignup";
import { fetchUserSignupStatesAction } from "../../../../redux/actions/user/signup/states";
import { fetchUserSignupTypesAction } from "../../../../redux/actions/user/signup/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetFilter, updateFilterProperty } from "../../../../redux/reducers/management/userSignup/filter";
import { getAllUserTypesSelector } from "../../../../redux/selectors/user/signup/types";
import { compileNameOfProperty } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { TextInput } from "../../../common/inputs/textInput";
import { CustomSelector } from "../../../common/selectors/customSelector/customSelector";
import { ManagementUserSignupFilterStyle } from "./filterStyle.jss";

export const ManagementUserSignupFilter = () => {
    const theme = useTheme();
    const styles = ManagementUserSignupFilterStyle({ theme });
    const { filter } = useAppSelector((state) => state.managementUserSignupFilter);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.USER_SIGNUP.LIST" });
    const dispatch = useAppDispatch();

    const userSignupTypes = useSelector(getAllUserTypesSelector.selectAll);
    const userSignupStates = useAppSelector((store) => store.userSignupStates.data);

    useEffect(() => {
        !userSignupTypes.length && dispatch(fetchUserSignupTypesAction());
    }, [dispatch, userSignupTypes]);

    useEffect(() => {
        !userSignupStates.length && dispatch(fetchUserSignupStatesAction());
    }, [dispatch, userSignupStates]);

    const onInputChange = useCallback(
        (event: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: event.target.value,
            };
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            const payload: IGenericPayload = {
                name,
                value: event.key,
            };
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onApply = useCallback(() => {
        dispatch(fetchManagementUserSignupListAction());
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(resetFilter());
        dispatch(fetchManagementUserSignupListAction());
    }, [dispatch]);

    const userSignupTypesOptions: IComboBoxOption[] = userSignupTypes.map((item: IUserSignupType) => {
        return {
            key: item.id,
            text: item.name,
        };
    });

    const userSignupStatesOptions: IComboBoxOption[] = userSignupStates.map((item: IUserSignupState) => {
        return {
            key: item.code,
            text: item.description,
        };
    });

    const FiltersItems = () => {
        return (
            <div className={styles.filterContainer}>
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.name}
                        propertyName={compileNameOfProperty<IManagementUserSignupFilter>("name")}
                        label={t("NAME")}
                        onChange={onInputChange}
                        onEnter={onApply}
                    />
                </div>
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.email}
                        propertyName={compileNameOfProperty<IManagementUserSignupFilter>("email")}
                        label={t("EMAIL")}
                        onChange={onInputChange}
                        onEnter={onApply}
                    />
                </div>
                <div className={styles.filterItem}>
                    <CustomSelector
                        onChange={onSelectorChange}
                        selectedKey={filter.userTypeId}
                        options={userSignupTypesOptions}
                        title={t("TYPE")}
                        name={compileNameOfProperty<IManagementUserSignupFilter>("userTypeId")}
                    />
                </div>
                <div className={styles.filterItem}>
                    <CustomSelector
                        onChange={onSelectorChange}
                        selectedKey={filter.stateCode}
                        options={userSignupStatesOptions}
                        title={t("STATE")}
                        name={compileNameOfProperty<IManagementUserSignupFilter>("stateCode")}
                    />
                </div>
            </div>
        );
    };

    return (
        <FilterHeader
            content={FiltersItems()}
            onApply={onApply}
            onReset={onReset}
        />
    );
};
