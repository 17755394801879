import { useState } from "react";
import { NameIcons } from "../../../../../assets/icons";
import { deleteDraft } from "../../../../../redux/actions/request/draft/draft";
import { useAppDispatch } from "../../../../../redux/hooks";
import { widths } from "../../../../../styles/romeu/widths";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { PopUpConfirmation } from "../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../../common/enum/PopUpConfirmationType";

interface IDeleteDraft {
    groupId: string;
}

export const DeleteDraft: React.FC<IDeleteDraft> = ({ groupId }): JSX.Element => {
    const [showDeleteNotification, setShowDeleteNotification] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onCancelDeleteNotification = () => {
        setShowDeleteNotification(false);
    };

    const onAcceptDeleteNotification = () => {
        dispatch(deleteDraft(groupId));
        setShowDeleteNotification(false);
    };

    return (
        <>
            <div>
                <CustomIcons
                    size={"20px"}
                    iconName={NameIcons.REMOVE}
                    onClick={() => setShowDeleteNotification(true)}
                ></CustomIcons>

                <PopUpConfirmation
                    showPopUp={showDeleteNotification}
                    onCancel={onCancelDeleteNotification}
                    onAccept={onAcceptDeleteNotification}
                    type={PopUpConfirmationType.DELETE}
                    width={widths.confirmPopUp.remove}
                    height={"auto"}
                />
            </div>
        </>
    );
};
