import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ICustomersStatisticsItem } from "../../../../../models/statistics/ICustomer";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";

interface IInvoicingUninvoicedColumnFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsCustomersListColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.CUSTOMER.TABLE" });
    const {
        totalAverageIncidencesTime,
        totalContainers20,
        totalContainers40,
        totalIncidencesTime,
        totalTrucks,
        totalVehicles,
        totalIncidences,
    } = useAppSelector((store) => store.statiticsCustomerList.data);

   

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("entityName"),
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("entityName"),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTAL_FINAL")}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("containers20"),
            minWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("containers20"),
            onRender: () => <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}> {totalContainers20}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("containers40"),
            minWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("containers40"),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> {totalContainers40}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("trucks"),
            minWidth: calcWidthColumFooter(3, 3, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("trucks"),
            onRender: () => <b style={generateStyleWhithCustomFooter(3, 3, props.listColumnsFooter)}> {totalTrucks}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("vehicles"),
            minWidth: calcWidthColumFooter(4, 4, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("vehicles"),
            onRender: () => <b style={generateStyleWhithCustomFooter(4, 4, props.listColumnsFooter)}> {totalVehicles}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("incidences"),
            minWidth: calcWidthColumFooter(5, 5, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("incidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(5, 5, props.listColumnsFooter)}> {totalIncidences}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("incidencesTime"),
            minWidth: calcWidthColumFooter(6, 6, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("incidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(6, 6, props.listColumnsFooter)}> {totalIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("averageIncidencesTime"),
            minWidth: calcWidthColumFooter(7, 7, props.listColumnsFooter),
            name: compileNameOfProperty<ICustomersStatisticsItem>("averageIncidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(7, 7, props.listColumnsFooter)}> {totalAverageIncidencesTime}</b>,
            isResizable: true,
        },
    ];

    return columns;
};
