import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setShowManagementSummaryPopUp } from "../../../redux/reducers/management/summary/form";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementSummaryFormController } from "./controller/controller";
import { ManagementSummaryFilter } from "./filter/filter";
import { ManagementSummaryList } from "./list/list";
import { routeFeatures } from "../../../common/constants/routeFeatures";
import { IUserRoleView, IView } from "../../../models/requests/IUserRoleView";
import { ViewType } from "../../../models";
import { useMemo } from "react";
import { generateManagementSummarySchema } from "./generateSchema/generateSchema";

export const ManagementActiveSummaryContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.SUMMARY" });
    const dispatch = useAppDispatch();

    const { viewPermission } = useAppSelector((state) => state.userRoleViews);
    const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === routeFeatures.MANAGEMENT_SUMMARY);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view: any) => view.type === ViewType.COMMANDS) as IView;
    }, [viewRequestPermission?.views]);

    const schema = useMemo(() => {
        return generateManagementSummarySchema(viewConfig?.fields ? viewConfig.fields : []);
    }, [viewConfig]);

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    schema.create.visible ? (
                        <ButtonPrimary
                            title={t("CREATE")}
                            handleClick={() => {
                                dispatch(setShowManagementSummaryPopUp(PopUpCodes.CREATE));
                            }}
                        ></ButtonPrimary>
                    ): undefined
                }
            />

            <ManagementSummaryFilter />
            <ManagementSummaryList schema={schema} />
            <ManagementSummaryFormController />
        </>
    );
};
