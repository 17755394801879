import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterMerchandiseOrigin } from "../../../../../models/resources/master/IMerchandiseOrigin";

export interface IMasterOriginWareListState {
    list?: IMasterMerchandiseOrigin[];
    loading: boolean;
}

const initialState: IMasterOriginWareListState = {
    loading: false,
};

export const masterOriginWareList = createSlice({
    name: "masterOriginWareList",
    initialState,
    reducers: {
        resetMasterMerchandiseOriginList: () => initialState,
        setLoadingMasterMerchandiseOriginsList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterMerchandiseOriginsList: (state, action: PayloadAction<IMasterMerchandiseOrigin[]>) => {
            state.list = action.payload;
        },
    },
});

export const {resetMasterMerchandiseOriginList, setLoadingMasterMerchandiseOriginsList, setMasterMerchandiseOriginsList } = masterOriginWareList.actions;

export default masterOriginWareList.reducer;
