import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IPaymentData } from "../../../../../../models";
import { ILabelTextStyleProps, LabelText } from "../../../../../common/labels/labelText/labelText";
import { SignupResumeDataPaymentStyle } from "./resumeDataPayment.jss";
import { PaymentMethod } from "../../../../../../common/enum/PaymentMethod";

interface Props {
    data: IPaymentData;
}
export const SignupResumeDataPayment: React.FC<Props> = ({ data }) => {
    const styles = SignupResumeDataPaymentStyle();
    const { t } = useTranslation("common");
    const theme = useTheme() as any;

    const propsStyle: ILabelTextStyleProps = {
        dataColor: theme.colors.titles.secondary.color,
        dataSize: "",
        labelColor: theme.colors.titles.secondary.color,
        labelSize: "",
    };

    const paymentMethod = useMemo(() => {
        switch (data.paymentMethod) {
            case PaymentMethod.BANK_DRAFT:
                return "Giro Bancario";
            case PaymentMethod.CASH:
                return "Efectivo";
            default:
                return "";
        }
    }, [data.paymentMethod]);
    return (
        <div className={styles.container}>
            <div className={styles.oneItemRow}>
                <LabelText
                    name={t("USER.SIGNUP.PAYMENT_METHOD")}
                    value={paymentMethod}
                    styleProps={propsStyle}
                />
            </div>
            <div className={styles.twoItemsRow}>
                <LabelText
                    name={t("USER.SIGNUP.BANK_ACCOUNT")}
                    value={data.accountNumber}
                    styleProps={propsStyle}
                />
                <LabelText
                    name={t("USER.SIGNUP.BANK_ENTITY")}
                    value={data.bankEntity}
                    styleProps={propsStyle}
                />
            </div>
        </div>
    );
};
