import { ManagementDockQueueRuleApi } from "../../../../api/management/dockQueueRule";
import { ManagementSortTableApi } from "../../../../api/management/sort/sort";
import { throwErrorToastrAsync } from "../../../../utils";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import { setLoadingManagementDockQueueRuleForm, setManagementDockQueueRuleForm } from "../../../reducers/management/dockQueueRule/form";
import { fetchManagementDockQueueRuleList, setLoadingManagementDockQueueRuleList } from "../../../reducers/management/dockQueueRule/list";
import { setDockQueueRuleConfigurationView } from "../../../reducers/management/dockQueueRule/view";
import { AppThunk, store } from "../../../store";

const dockQueueRuleAPI = new ManagementDockQueueRuleApi();
const managementSort = new ManagementSortTableApi();

export const fetchManagementDockQueueRuleListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingManagementDockQueueRuleList(true));
        const response = await dockQueueRuleAPI.getAllRules();
        dispatch(fetchManagementDockQueueRuleList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingManagementDockQueueRuleList(false));
    }
};

export const getDockQueueRuleConfiguration = (): any => async (dispatch: any) => {
    return new Promise<boolean>(async (resolve) => {
        try {
            const response = await dockQueueRuleAPI.getConfiguration();
            dispatch(setDockQueueRuleConfigurationView(response));
            resolve(true);
        } catch (error) {
            throwErrorToastrAsync(error);
            resolve(false);
        }
    });
};

export const postAndPutManagementDockQueueRuleAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().managementDockQueueRuleForm;
            dispatch(setLoadingManagementDockQueueRuleForm(true));
            if (form.id) {
                await dockQueueRuleAPI.updateDockQueueRuleAsync(form);
            } else {
                await dockQueueRuleAPI.createDockQueueRuleAsync(form);
            }
            dispatch(fetchManagementDockQueueRuleListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingManagementDockQueueRuleForm(false));
            return false;
        }
    };
};


export const deleteManagementDockQueueRuleAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { id } = store.getState().managementDockQueueRuleForm.form;

            dispatch(setLoadingManagementDockQueueRuleForm(true));
            await dockQueueRuleAPI.deleteDockQueueRule(id);
            dispatch(fetchManagementDockQueueRuleListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingManagementDockQueueRuleForm(false));
        }
    };
};


export const getManagementDockQueueRuleAsync =
    (id: number): any =>
    async (dispatch: any) => {
        return new Promise<boolean>(async (resolve) => {
            try {
                dispatch(setGlobalLoading(true));
                const response = await dockQueueRuleAPI.getDockQueueRuleRuleAsync(id);
                dispatch(setManagementDockQueueRuleForm(response));
                resolve(true);
            } catch (error) {
                throwErrorToastrAsync(error);
                resolve(false);
            } finally {
                dispatch(setGlobalLoading(false));
            }
        });
    };



export const patchManagementDockQueuePriorityAsync =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementDockQueueRuleForm(true));
            await managementSort.managementSort(tableName, id, sortType);
            dispatch(fetchManagementDockQueueRuleListAction());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementDockQueueRuleForm(false));
        }
    };

