import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsIncidenceMonthlyListColumn } from "./column/column";
import { useTheme } from "react-jss";
import { StatisticsInspectionGroupedByMonthContainerStyle } from "./listStyle.jss";
import { DetailsRow, IDetailsFooterProps } from "@fluentui/react";
import { StatisticsIncidenceMonthlyListColumnFooter, StatisticsIncidenceMonthlyPercentListColumnFooter } from "./column/footer";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect } from "react";
import { fetchStatisticsInspectionAcumulativeByMonthAction } from "../../../../redux/actions/statistics/statistics";
import { SkeletonSizes } from "../../../../common/enum/sizes";

export const StatisticsInspectionGroupedByMonthContainer = () => {
    const theme = useTheme();
    const style = StatisticsInspectionGroupedByMonthContainerStyle({ theme });
    const { loading, inspectionsAccumulativeStatistics } = useAppSelector((store) => store.statiticsInspectionAccumulativeList.data);
    const list = inspectionsAccumulativeStatistics.inspectionAccumulativeStatistics;
    const dispatch = useAppDispatch();

    const onRenderColumns = () => {
        return StatisticsIncidenceMonthlyListColumn();
    };

    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };
        const totalFooterColumns = StatisticsIncidenceMonthlyListColumnFooter(dataFooter);
        const totalPercent = StatisticsIncidenceMonthlyPercentListColumnFooter(dataFooter);

        return (
            <>
                <DetailsRow
                    {...detailsFooterProps}
                    columns={totalFooterColumns}
                    item={{}}
                    itemIndex={5}
                    groupNestingDepth={detailsFooterProps.groupNestingDepth}
                    selection={detailsFooterProps.selection}
                    styles={style}
                />
                <DetailsRow
                    {...detailsFooterProps}
                    columns={totalPercent}
                    item={{}}
                    itemIndex={5}
                    groupNestingDepth={detailsFooterProps.groupNestingDepth}
                    selection={detailsFooterProps.selection}
                    styles={style}
                />
            </>
        );
    };

    useEffect(() => {
        dispatch(fetchStatisticsInspectionAcumulativeByMonthAction());
    }, []);

    return !loading ? (
        <CustomDetailsList
            data={list}
            renderColumns={onRenderColumns}
            height={heights.statistics.inspection}
            customFooter={_onRenderDetailsFooter}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
