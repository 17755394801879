import { createUseStyles } from "react-jss";


export const MasterSelectorStyle = createUseStyles((theme: any) => ({
    container : {},

    selector: {
        width: 370,
        flexFlow: "column",
        "& .is-disabled": {
            opacity: "1 !important",
        },
        "& .is-disabled .ms-Dropdown-caretDownWrapper": {
            display: "none",
        },
        "& .ms-Dropdown-title": {
            color: `${theme.colors.palette.black} !important`,
            fontSize: 20,
        },
        "& .ms-Dropdown-caretDownWrapper": {
            "& i ": {
                fontSize: 20,
            },
        },
        border: "none",
    },
}));