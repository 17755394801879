import { createUseStyles } from "react-jss";

export const CookiesContentStyles = createUseStyles((theme: any) => ({
    container: {
        position: "absolute",
        background: "white",
        zIndex: 9999,
        width: 670,
        height: 230,
        bottom: "1vw",
        right: "1vw",
        padding: 5,
        borderRadius: 8,
        boxShadow: "0px 0px 28px -12px rgba(0,0,0,0.75)",
        "-webkit-box-shadow": "0px 0px 28px -12px rgba(0,0,0,0.75)",
        "-moz-box-shadow": "0px 0px 28px -12px rgba(0,0,0,0.75)",
    },
    infoIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "5rem",
        color: theme.colors.palette.purple,
        width: 100,
        height: 100,
    },
    header: {
        display: "flex",
        justifyContent: "end",
        padding: "15px 0",
        color: theme.colors.palette.purple,
        fontSize: "0.94rem",
        fontWeight: "normal",
        textDecoration: "none",
    },
    title: {
        padding: 5,
        margin: 5.3,
        fontSize: "0.94rem",
        color: theme.colors.palette.purple,
        fontWeight: "normal",
        textDecoration: "none",
    },
    line: {
        height: 1,
        width: "100%",
        background: theme.colors.palette.purple,
    },
    content: {
        display: "flex",
        padding: "15px 0",
        justifyContent: "center",
        alignItems: "center",
    },
    contentConfiguration: {
        display: "flex",
        padding: "15px 0",
        flexDirection: "column",
    },
    configuration: {
        display: "flex",
        padding: "15px 0",
        flexDirection: "column",
        gap: 15,
    },
    buttons: {
        display: "flex",
        position: "absolute",
        padding: "15px 0",
        justifyContent: "end",
        alignItems: "center",
        bottom: 10,
        right: 10,
        gap: 5,
    },
    backdrop: {
        position: "absolute",
        width: "100vw",
        height: "100vh",
        zIndex: 9999,
        background: "black",
        opacity: "0.5",
        top:0
    },
    link: {
        padding: 5,
        margin: 5.3,
        fontSize: "0.94rem",
        color: theme.colors.palette.purple,
        fontWeight: "normal",
        textDecoration: "none",
        
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));
