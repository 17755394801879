import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ICustomersStatisticsItem } from "../../../../../models/statistics/ICustomer";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { InvoicingUninvoicedColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsCustomersListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = InvoicingUninvoicedColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.CUSTOMER.TABLE" });

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("entityName"),
            minWidth: 200,
            maxWidth: 240,
            name: t("CUSTOMER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("entityName")}
                    title={t("CUSTOMER")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.entityName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("containers20"),
            minWidth: 110,
            maxWidth: 110,
            name: t("CONTAINER_20"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("containers20")}
                    title={t("CONTAINER_20")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.containers20 ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("containers40"),
            minWidth: 110,
            maxWidth: 110,
            name: t("CONTAINER_40"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("containers40")}
                    title={t("CONTAINER_40")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.containers40 ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("trucks"),
            minWidth: 90,
            maxWidth: 90,
            name: t("TRUCKS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("trucks")}
                    title={t("TRUCKS")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.trucks ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("vehicles"),
            minWidth: 60,
            maxWidth: 70,
            name: t("VEHICLES"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("vehicles")}
                    title={t("VEHICLES")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.vehicles ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("incidences"),
            minWidth: 180,
            maxWidth: 200,
            name: t("VEHICLES_ICIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("incidences")}
                    title={t("VEHICLES_ICIDENCE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.incidences ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("incidencesTime"),
            minWidth: 145,
            maxWidth: 150,
            name: t("INCIDENCE_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("incidencesTime")}
                    title={t("INCIDENCE_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => <span className={styles.dataCell}>{item.incidencesTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<ICustomersStatisticsItem>("averageIncidencesTime"),
            minWidth: 150,
            maxWidth: 200,
            name: t("AVERAGE_INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<ICustomersStatisticsItem>("averageIncidencesTime")}
                    title={t("AVERAGE_INCIDENCE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: ICustomersStatisticsItem) => (
                <span className={styles.dataCell}>{item.averageIncidencesTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
