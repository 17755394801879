import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../models";
import { exportStatisticsCustomersAction, fetchStatiticsCustomerFilterAction } from "../../../redux/actions/statistics/statistics";
import { useAppSelector } from "../../../redux/hooks";
import { resetStatisticsCustomerFilter, updateFilterProperty } from "../../../redux/reducers/statistics/customer/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsCustomersList } from "./list/list";

export const StatisticsCustomersContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.CUSTOMER" });
    const dispatch = useDispatch();
    const { filter } = useAppSelector((state) => state.statiticsCustomerFilter);

    const onExportDataStaticsCustomers = () => {
        return dispatch(exportStatisticsCustomersAction());
    };
    const updateHeaderPropierty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };
    const onApplyFilters = () => {
        dispatch(fetchStatiticsCustomerFilterAction());
    };
    const onResetFilters = () => {
        dispatch(resetStatisticsCustomerFilter());
        onApplyFilters();
    };

    const statisticsCustomerContainerHeaderActions = () => {
        return (
            <>
                <ExportDataIcon onExportCSV={onExportDataStaticsCustomers} />
            </>
        );
    };

    return (
        <div>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={statisticsCustomerContainerHeaderActions()}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderPropierty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <StatisticsCustomersList></StatisticsCustomersList>
        </div>
    );
};
