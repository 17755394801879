import { Dropdown, IDropdown, IDropdownOption, IDropdownStyles, IRefObject } from "@fluentui/react";
import React, { useMemo } from "react";
import { stringDefaultValue } from "../../../../utils";
import { CustomSelectorStyled } from "./customSelector.jss";

export interface ISelectorProps {
    title: string;
    options?: IDropdownOption[];
    onChange: (name: string, keysSelected: any) => void;
    comboBoxRef?: IRefObject<IDropdown>;
    selectedKey: any;
    disabled?: boolean;
    className?: string;
    id?: string;
    name: string;
}

export const CustomSelector: React.FC<ISelectorProps> = ({ onChange, selectedKey, title, disabled, options, name, comboBoxRef, className }) => {
    const isDisabled = useMemo(() => {
        return !!disabled;
    }, [disabled]);

    const customSelectorStyled = CustomSelectorStyled({ isDisabled });

    const styles: Partial<IDropdownStyles> = {
        root: customSelectorStyled.rootStyles,
        callout: customSelectorStyled.callout,
        dropdownItem: customSelectorStyled.optionText,
        dropdownItemSelected: customSelectorStyled.optionText,
        title: customSelectorStyled.inputText,
        label: customSelectorStyled.label,
    };

    const handleChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        if (option) {
            onChange(name, option);
        }
    };

    const optionsWithDefaultValue = useMemo(() => {
        const defaultValueArray: IDropdownOption[] = [stringDefaultValue];
        return defaultValueArray.concat(options ?? []);
    }, [options]);

    const optionsSelectedTooltip = useMemo(() => {
        const selectedoptions = optionsWithDefaultValue.filter((item) => selectedKey === String(item.key));
        return selectedoptions.map((item) => item.text).join("; ");
    }, [optionsWithDefaultValue, selectedKey]);

    return (
        <div
            className={customSelectorStyled.selectorContainer}
            title={optionsSelectedTooltip}
        >
            <Dropdown
                onChange={handleChange}
                componentRef={comboBoxRef}
                selectedKey={selectedKey}
                label={title}
                disabled={disabled}
                options={optionsWithDefaultValue}
                className={className}
                styles={styles}
                key={`${name}-${CustomSelector.name}`}
            />
        </div>
    );
};
