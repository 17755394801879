import { useCallback } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { updatePaymentSignupProperty } from "../../../../../../redux/reducers/user/signup/signup";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../utils";
import { Checkboxs } from "../../../../../common/checkbox/checkbox";
import { FormField } from "../../../../../common/fields/fields";
import { TitleH5 } from "../../../../../common/titles/titles";
import { UserEntityPaymentInfoStyle } from "./infoStyle.jss";

import { IPaymentData } from "../../../../../../models";
import { PaymentMethod } from "../../../../../../common/enum/PaymentMethod";
import { ColorType } from "../../../../../../common/enum/ColorType";

interface IEntityFormProps {
    data: IPaymentData;
    register: UseFormRegister<IPaymentData>;
    errors: FieldErrors<IPaymentData>;
}

export const UserEntityPaymentInfoData = (props: IEntityFormProps) => {
    const style = UserEntityPaymentInfoStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updatePaymentSignupProperty(payload));
        },
        [dispatch],
    );

    const onCheckboxChange = useCallback(
        (paymentMethod) => (event: any) => {
            const payload = getGenericPayloadFromEvent(event);
            if (paymentMethod === PaymentMethod.BANK_DRAFT) {
                payload.value = PaymentMethod.BANK_DRAFT;
            } else if (paymentMethod === PaymentMethod.CASH) {
                payload.value = PaymentMethod.CASH;
            }
            dispatch(updatePaymentSignupProperty(payload));
        },
        [dispatch],
    );

    return (
        <>
            <div className={style.row}>
                <div className={style.title}>
                    <TitleH5
                        title={t("USER.TITLES.PAYMENT_DATA")}
                        color={ColorType.primary}
                        bold={true}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.columnBox}>
                    <Checkboxs
                        name={compileNameOfProperty<IPaymentData>("paymentMethod")}
                        label={t("USER.SIGNUP.BANK_DRAFT")}
                        isChecked={true}
                        handleChange={onCheckboxChange(PaymentMethod.BANK_DRAFT)}
                        required={true}
                    />
                </div>
               
            </div>
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.BANK_ACCOUNT")}
                        value={props.data.accountNumber}
                        error={props.errors.accountNumber}
                        {...props.register(compileNameOfProperty<IPaymentData>("accountNumber"), {
                            onChange: onInputChange,
                            value: props.data.accountNumber,
                        })}
                        isRequired={true}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.BANK_ENTITY")}
                        value={props.data.bankEntity}
                        error={props.errors.bankEntity}
                        {...props.register(compileNameOfProperty<IPaymentData>("bankEntity"), {
                            onChange: onInputChange,
                            value: props.data.bankEntity,
                        })}
                        isRequired={true}
                    />
                </div>
            </div>
        </>
    );
};
