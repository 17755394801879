import { UserApi } from "../../../../api";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";
import { resetUserClaims, setUserClaims } from "../../../reducers/user/claims/claims";
import { AppThunk } from "../../../store";
import { setGlobalLoadingAction } from "../../common";

const userApi = new UserApi();

export const resetUSerClaimsAction = (): AppThunk => (dispatch) => {
    dispatch(resetUserClaims());
};

export const fetchUserClaimsAction =
    (organizationId: string, roleId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const response = await userApi.getClaims(organizationId, roleId);
            dispatch(setUserClaims(response.token));
        } catch (error) {
            dispatch(HandleAxiosError(error));
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };
