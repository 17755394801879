import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../../../../../../utils";
import { Selector } from "../../../../../../../../../common/selectors/selector";

interface IAppointmentTypeSelector {
    title: string;
    onChange: (keysSelected: any, name: string) => void;
    selectedKey: any;
    disabled?: boolean;
    className?: string;
    name: string;
    isRequired?: boolean;
}

export const AppointmentDockSelector: React.FC<IAppointmentTypeSelector> = ({ name, onChange, selectedKey, title, className }) => {
    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => {
        onChange(option, name);
    };
    const { appointmentDocks } = useAppSelector((state) => state.masterAppointmentBoardEdit.form);

    const options = useMemo(() => {
        return mapIDescriptionToDropDownOptions(appointmentDocks ?? []);
    }, [appointmentDocks]);
    return (
        <Selector
            selectedKey={selectedKey}
            name={name}
            title={title}
            options={options}
            onChange={onSelectorChange}
            required={true}
            className={className}
        />
    );
};
