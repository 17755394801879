import { useTranslation } from "react-i18next";
import { IRequestedDocument } from "../../../../../models";
import { FormSection } from "../../../../common/section/formSection";
import { ResumeDocumentItem } from "./item/resumeDocumentItem";
import { UserSignupResumeDocumentsStyle } from "./resumeDocuments.jss";

interface Props {
    documents: IRequestedDocument[];
}

export const UserSignupResumeDocuments: React.FC<Props> = ({ documents }) => {
    const styles = UserSignupResumeDocumentsStyle();
    const { t } = useTranslation("common");

    const documentsJSX: JSX.Element = (
        <div className={styles.container}>
            {documents.map((document, index) => (
                <div
                    className={styles.document}
                    key={`${UserSignupResumeDocuments.name}${index}`}
                >
                    <ResumeDocumentItem document={document} />
                </div>
            ))}
        </div>
    );

    return (
        <FormSection
            title={t("USER.TITLES.DOCUMENTATION")}
            children={documentsJSX}
        />
    );
};
