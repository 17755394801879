import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const ConfigMerchandiseCategoryFormStyle = createUseStyles((theme: any) => ({
    form: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.form.default, 
        paddingTop:theme.paddings.sizes.base
    },
    row: {
        display:"flex", 
        gap : theme.gaps.form.default, 
        [mediaQueries.MOBILE]:{
            flexDirection:"column", 
            width:"100%"
        }
    },
    simpleRow : {
        width:"48%",
        [mediaQueries.MOBILE]:{
            width:"100%"
        }
    }
}));
