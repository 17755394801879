import { pageSizes } from "../../../../common/constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IManagementUserSignup } from "../../../../models/management/userSignup/IManagementUserSignup";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { getFirstItemNumber, getLastItemNumber, getTotalPages } from "../../../../utils";

interface ManagementUserSignupListState {
    list: IManagementUserSignup[];
    sort: ISortState;
    pagination: IPaginationState;
    loading: boolean;
}

const initialState: ManagementUserSignupListState = {
    list: [],
    sort: emptySortState(),
    pagination: emptyPaginationState(pageSizes.MANAGEMENT_USER_SIGNUP),
    loading: false
};

export const managementUserSignupListSlice = createSlice({
    name: "managementUserSignupList",
    initialState,
    reducers: {
        resetManagementUserSignupList: () => initialState,

        setLoadingManagementUserSignupList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementUserSignupList: (state, action: PayloadAction<IManagementUserSignup[]>) => {
            state.list = action.payload;
            state.pagination.totalItems = action.payload.length;
            state.pagination.totalPages = getTotalPages(state.pagination);
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
    },
});

export const { resetManagementUserSignupList, setLoadingManagementUserSignupList, fetchManagementUserSignupList, updateSortColumn, setPage } = managementUserSignupListSlice.actions;

export default managementUserSignupListSlice.reducer;
