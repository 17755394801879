import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import {
    setLoadingMasterFinalDestinationTypesForm,
    setMasterFinalDestinationTypesForm,
} from "../../../../reducers/resources/master/finalDestinationTypes/form";
import {
    setLoadingFinalDestinationTypesList,
    setMasterFinalDestinationTypesList,
} from "../../../../reducers/resources/master/finalDestinationTypes/list";
import { AppThunk, store } from "../../../../store";

const catalogAPI = new CatalogAPI();

export const fetchMasterFinalDestinationTypesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingFinalDestinationTypesList(true));
        const response = await catalogAPI.getFinalDestinationTypesList();
        dispatch(setMasterFinalDestinationTypesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingFinalDestinationTypesList(false));
    }
};

export const fetchMasterFinalDestinationType =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterFinalDestinationTypesForm(true));
            const response = await catalogAPI.getMasterFinalDestinationTypes(id);
            dispatch(setMasterFinalDestinationTypesForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterFinalDestinationTypesForm(false));
        }
    };

export const postToMasterFinalDestinationTypeForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterFinalDestinationTypesForm;

        try {
            dispatch(setLoadingMasterFinalDestinationTypesForm(true));
            if (form.id) {
                await catalogAPI.putMasterFinalDestinationTypes(form);
            } else {
                await catalogAPI.postMasterFinalDestinationTypes(form);
            }
            dispatch(fetchMasterFinalDestinationTypesList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterFinalDestinationTypesForm(false));
            return false;
        }
    };
};

export const patchSortFinalDestinationTypesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingFinalDestinationTypesList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterFinalDestinationTypesList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingFinalDestinationTypesList(false));
        }
    };
