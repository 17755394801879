import { createUseStyles } from "react-jss";

export const InspectionListStyle = createUseStyles((theme: any) => ({
    inspectionlistContainer: {
        display: "flex",
        width: "100%",
        "& .ms-Viewport": {
            width: "100%",
        },
        "& .ms-DetailsHeader-cellName": {
            color: theme.colors.palette.grey,
            fontFamily: theme.fonts.families.site,
        },
        "& .ms-DetailsRow-cell": {
            color: theme.colors.palette.shadowBlack,
            fontFamily: theme.fonts.families.site,
        },
    },
    icons: {
      display:"flex", 
      justifyContent:"end"
    },
}));
