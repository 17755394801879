import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../common/constants";

export const ManagementAppointmentPermisionFormStyle = createUseStyles((theme: any) => ({
    appointmentPermissionFormContainer: {
        width: "100%",
    },

    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.paddings.sizes.xl,
        gap: theme.gaps.sizes.xxl,
        width: "100%",
    },
    rowForm: {
        display: "flex",
        gap: theme.gaps.sizes.md,
        alignItems: "end",
        width: "100%",
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
            alignItems: "start",
        },
    },
    item: {
        width: "50%",
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },
    pivot: {
        paddingBottom: theme.paddings.sizes.base,
        width: "100%",
        height: "100%",
        "& .ms-FocusZone": {
            height: 43,
            borderBottom: `1px solid ${theme.colors.navLinks.primary.color}`,
        },
        "& .ms-Button": {
            height: 42,
            minWidth: 170,
            fontWeight: "bold",
            color: theme.colors.palette.greyPurple,
        },
        "& .ms-Button:hover": {
            color: theme.colors.palette.purple,
            backgroundColor: "transparent",
        },
        "& .ms-Button:active": {
            color: theme.colors.palette.purple,
        },
        "& .is-selected": {
            color: theme.colors.palette.purple,
        },
        "& .is-selected::before": {
            color: theme.colors.palette.purple,
            backgroundColor: theme.colors.palette.purple,
        },
        "& .is-selected::after": {
            color: theme.colors.palette.purple,
        },
    },
    pivotItem: {
        height: "64vh",
        overflowY: "auto",
    },
}));
