import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdminDataCustom, IDocument, IEmailItemWithIndex, IEntityData, IPaymentData, IRequestedDocument } from "../../../../models";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { PaymentMethod } from "../../../../common/enum/PaymentMethod";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { IOrganizationData } from "../../../../models/user/IOrganizationData";
import { add } from "lodash";

export interface IManagementUserFormState {
    warningExternalCodePopUp: string;
    showPopUp: PopUpCodes;
    userRoleCode: string;
    selectedPivotKey: string;
    loading: boolean;
    generalUserData: IManagementUserForm;
    migrationUser: IManagementUserForm;
}

export interface IDeleteUserFormDocument {
    DocumentId:string;
    FileName:string;
}
export interface IManagementUserForm {
    id?: string;
    email: string;
    name: string;
    surName?: string;
    lastName?: string;
    phoneNumber?: string;
    roleId: string;
    crmCode?: string;
    externalCode?: string;
    parentId?: string;
    organismId?: number;
    terminalId?: number;
    unregistered?: boolean;
    unregisteredBy?: string;
    unregisteredOn?: string;
    entityData: IEntityData;
    paymentData: IPaymentData;
    adminData: IAdminDataCustom;
    operatorData: IAdminDataCustom;
    organizations: IOrganizationData[];
    availableOrganizations: IOrganizationData[];
    discharged?: "";
    hasRepresentativeData?: boolean;
    documents: IRequestedDocument[];
    documentTypes?: string;
    blobs?: IDocument[];
    documentTypesDeleted: IDeleteUserFormDocument[];
    addMultipleActuation: boolean;
}

export const emptyAdminData = () => {
    return { emails: [""], mobilePhone: "", phone: "" };
};

export const emptyEntityData = (): IEntityData => {
    return {
        city: "",
        documentNumber: "",
        name: "",
        postalCode: "",
        province: "",
        socialAddress: "",
    };
};

export const emptyPaymentData = (): IPaymentData => {
    return {
        accountNumber: "",
        bankEntity: "",
        paymentMethod: PaymentMethod.BANK_DRAFT,
    };
};

export const emptyGeneralUserData = () => {
    return {
        email: "",
        name: "",
        roleId: "",
        crmCode: "",
        externalCode: "",
        surName: "",
        phoneNumber: "",
        entityData: emptyEntityData(),
        adminData: emptyAdminData(),
        operatorData: emptyAdminData(),
        paymentData: emptyPaymentData(),
        organizations: [],
        availableOrganizations: [],
        documents: [],
        documentTypesDeleted: [],
        addMultipleActuation: false,
    };
};

const initialState: IManagementUserFormState = {
    warningExternalCodePopUp: "",
    showPopUp: PopUpCodes.NONE,
    userRoleCode: "",
    selectedPivotKey: "0",
    loading: false,
    generalUserData: emptyGeneralUserData(),
    migrationUser: emptyGeneralUserData(),
};

export const managementUserFormSlice = createSlice({
    name: "managementUserFormSlice",
    initialState,
    reducers: {
        resetManagementUser: () => initialState,

        setLoadingManagementUser: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setManagementUserSelectedPivotKey: (state, action: PayloadAction<string>) => {
            state.selectedPivotKey = action.payload;
        },
        updateManagementUserProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.generalUserData = {
                ...state.generalUserData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateManagementUserEntityDataProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.generalUserData.entityData = {
                ...state.generalUserData.entityData,
                [action.payload.name]: action.payload.value,
            };
        },
        setManagementUser: (state, action: PayloadAction<IManagementUserForm>) => {
            state.generalUserData = action.payload;
        },
        addOperationEmailItem: (state) => {
            state.generalUserData.operatorData.emails.push("");
        },
        removeOperationEmailItem: (state, action: PayloadAction<number>) => {
            state.generalUserData.operatorData.emails.splice(action.payload, 1);
        },
        updateOperationEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.generalUserData.operatorData.emails[action.payload.index] = action.payload.email;
        },
        addAdminEmailItem: (state) => {
            state.generalUserData.adminData.emails.push("");
        },
        removeAdminEmailItem: (state, action: PayloadAction<number>) => {
            state.generalUserData.adminData.emails.splice(action.payload, 1);
        },
        updateAdminEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.generalUserData.adminData.emails[action.payload.index] = action.payload.email;
        },
        updateAdminDataProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.generalUserData.adminData = {
                ...state.generalUserData.adminData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateOperatorDataProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.generalUserData.operatorData = {
                ...state.generalUserData.operatorData,
                [action.payload.name]: action.payload.value,
            };
        },
        updatePaymentDataProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.generalUserData.paymentData = {
                ...state.generalUserData.paymentData,
                [action.payload.name]: action.payload.value,
            };
        },
        setDocumentsUser: (state, action: PayloadAction<IRequestedDocument>) => {
            state.generalUserData.documents.push(action.payload);
            state.generalUserData.documentTypesDeleted = state.generalUserData.documentTypesDeleted.filter((item) => {
                return item.DocumentId !== String(action.payload.index);
            });
        },
        setDocumentsDeleteUser: (state, action: PayloadAction<IDeleteUserFormDocument>) => {
            state.generalUserData.documentTypesDeleted.push(action.payload);
        },

        updateDocumentsUser: (state, action: PayloadAction<IRequestedDocument>) => {
            state.generalUserData.documents[action.payload.index] = action.payload;
        },
        removeDocument: (state, action: PayloadAction<number>) => {
            state.generalUserData.documents = state.generalUserData.documents.filter((item) => item.documentId !== action.payload);
        },
        setShowPopUpUser: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setWarningExternalCodePopUp: (state, action: PayloadAction<string>) => {
            state.warningExternalCodePopUp = action.payload;
        },
        setMigrationUser: (state, action: PayloadAction<IManagementUserForm>) => {
            state.migrationUser = action.payload;
        },
        removeDocumentBlobs: (state, action: PayloadAction<string>) => {
            if (state.generalUserData.blobs) {
                state.generalUserData.blobs = state.generalUserData.blobs.filter((item) => item.type !== action.payload);
            }
        },
    },
});

export const {
    resetManagementUser,
    setLoadingManagementUser,
    setManagementUserSelectedPivotKey,
    updateManagementUserProperty,
    setManagementUser,
    updateManagementUserEntityDataProperty,
    addAdminEmailItem,
    addOperationEmailItem,
    removeOperationEmailItem,
    updateOperationEmailItem,
    removeAdminEmailItem,
    updateAdminEmailItem,
    updateOperatorDataProperty,
    updateAdminDataProperty,
    updatePaymentDataProperty,
    setDocumentsUser,
    updateDocumentsUser,
    removeDocument,
    setWarningExternalCodePopUp,
    setShowPopUpUser,
    removeDocumentBlobs,
    setDocumentsDeleteUser,
} = managementUserFormSlice.actions;

export default managementUserFormSlice.reducer;
