import { createUseStyles } from "react-jss";

export const DisclaimerStyles = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        padding: theme.paddings.sizes.base,
        rowGap: 12,
        display: "flex",
        flexDirection: "column",
        backgroundColor: props.backgroundColor,
        color: props.color,
        borderRadius: theme.borders.default.radius,
    }),
    title: {
        fontWeight: theme.fonts.weight.bold,
        textTransform: "uppercase",
    },
    body: {
        fontWeight: theme.fonts.weight.normal,
    },
}));
