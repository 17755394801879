import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterTerminal } from "../../../../../models/resources/master/ITerminal";


export interface IMasterTerminalFormState {
    form: IMasterTerminal;
    loading: boolean;
}

const initialState: IMasterTerminalFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterTerminalForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterTerminalForm: () => initialState,
        setLoadingMasterTerminalForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTerminalForm: (state, action: PayloadAction<IMasterTerminal>) => {
            state.form = action.payload;
        },
        updateMasterTerminalProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
       
    },
});

export const { resetMasterTerminalForm, setLoadingMasterTerminalForm, setMasterTerminalForm, updateMasterTerminalProperty } =
    masterTerminalForm.actions;

export default masterTerminalForm.reducer;
