import { FieldArray, FieldArrayPath, FieldError, FormState, UseFormRegisterReturn, Validate, ValidationRule, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../../../../../../../../common/constants";
import { IBoardHistoryAppointmentDockTimeZoneConfiguration } from "../../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../redux/hooks";
import { updateAppointmentDockTimeZoneConfigurationProperty } from "../../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDockTimeZoneConfiguration/form";
import { compileNameOfProperty } from "../../../../../../../../../../../utils";
import { ControlledRadioButtonGroup } from "../../../../../../../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { MerchandiseTypeWithGroupSelector } from "../../../../../../../../../../request/common/selectors/merchandiseTypeWithGroup/merchandiseTypeWithGroup";
import { AppointmentDockSelector } from "../../../form/appointmentDockSelector/appointmentDockSelector";
import { AppointmentTypesSelector } from "../../../form/appointmentSelector/appointmentSelector";
import { AppointmentTimeZoneSelector } from "../../../form/appointmentTimeZoneSelector/appointmentTimeZoneSelector";
import { MasterBoardHistoryAppointmentFringeFormStyle } from "./formStyle.jss";
import { MerchandiseTypeSelector } from "../../../../../../../../../../request/common/selectors";
import { DirectionType } from "../../../../../../../../../../../common/enum/DirectionType";
import { DefaultValues } from "../../../../../../../../../../../common/enum/DefaultValues";

export const MasterBoardHistoryAppointmentFringeForm = () => {
    const theme = useTheme();
    const style = MasterBoardHistoryAppointmentFringeFormStyle({ theme });
    const { form } = useAppSelector((state) => state.masterAppointmentDockTimeZoneConfigurationForm);
    const dispatch = useAppDispatch();
    const { control } = useForm<IBoardHistoryAppointmentDockTimeZoneConfiguration>({ mode: "onChange" });
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const { merchandiseCategoryGroupId } = useAppSelector((store) => store.masterAppointmentBoardEdit.form);

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_CONDITIONS" });

    const onSelectorChange = (option: any, name: string) => {
        dispatch(updateAppointmentDockTimeZoneConfigurationProperty({ name: name, value: option.key }));
    };
    const onRadioButtonChange = (optionKey: string, name: string) => {
        dispatch(updateAppointmentDockTimeZoneConfigurationProperty({ name: name, value: !!+optionKey }));
    };

    const checkFumigated = (
        <div>
            <ControlledRadioButtonGroup
                selectedKey={String(+form?.fumigated)}
                onRadioButtonChange={onRadioButtonChange}
                control={control}
                direction={DirectionType.horizontal}
                name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("fumigated")}
                label={t("CONTAINER_FUMIGATED")}
                required={true}
            />
        </div>
    );
    const checkHalal = (
        <ControlledRadioButtonGroup
            selectedKey={String(+form?.halalCertified)}
            onRadioButtonChange={onRadioButtonChange}
            control={control}
            direction={DirectionType.horizontal}
            name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("halalCertified")}
            label={t("HALAL")}
            required={true}
        />
    );
    const checkOrganicProduct = (
        <ControlledRadioButtonGroup
            selectedKey={String(+form?.organicProduct)}
            onRadioButtonChange={onRadioButtonChange}
            control={control}
            direction={DirectionType.horizontal}
            name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("organicProduct")}
            label={t("ORGANIC_PRODUCT")}
            required={true}
        />
    );
    const checkHumanConsuption = (
        <ControlledRadioButtonGroup
            selectedKey={String(+form?.humanConsumption)}
            onRadioButtonChange={onRadioButtonChange}
            control={control}
            direction={DirectionType.horizontal}
            name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("humanConsumption")}
            label={t("HUMAN_CONSUMPTION")}
            required={true}
        />
    );
    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <div className={style.item}>
                    {merchandiseCategoryGroupId ? (
                        <MerchandiseTypeWithGroupSelector
                            filterOptions={false}
                            onChange={onSelectorChange}
                            selectedKey={String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                            propertyName={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("merchandiseTypeId")}
                            title={t("MERCHANDISE_TYPE")}
                            control={control}
                            isRequired={false}
                            groupId={merchandiseCategoryGroupId.toString()}
                            allOption={true}
                        />
                    ) : (
                        <MerchandiseTypeSelector
                            filterOptions={false}
                            onChange={onSelectorChange}
                            selectedKey={String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                            propertyName={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("merchandiseTypeId")}
                            title={t("MERCHANDISE_TYPE")}
                            control={control}
                            allOption={true}
                        />
                    )}
                </div>
                <div className={style.item}>
                    <AppointmentTypesSelector
                        title={t("APPOINTMENT_TYPE")}
                        onChange={onSelectorChange}
                        selectedKey={form.appointmentTypeId}
                        name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("appointmentTypeId")}
                        isRequired={true}
                    />
                </div>
            </div>

            <div className={style.rowForm}>
                <div className={style.item}>
                    <AppointmentDockSelector
                        title={t("DOCK_DESCRIPTION")}
                        onChange={onSelectorChange}
                        selectedKey={form.appointmentDockId}
                        name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("appointmentDockId")}
                    />
                </div>
                <div className={style.item}>
                    <AppointmentTimeZoneSelector
                        title={t("TIME_ZONE_DESCRIPTION")}
                        onChange={onSelectorChange}
                        selectedKey={form.appointmentTimeZoneId}
                        name={compileNameOfProperty<IBoardHistoryAppointmentDockTimeZoneConfiguration>("appointmentTimeZoneId")}
                    />
                </div>
            </div>

            {!isMobile && (
                <div className={style.checkRow}>
                    {checkFumigated}
                    {checkHumanConsuption}
                    {checkHalal}
                    {checkOrganicProduct}
                </div>
            )}

            {isMobile && (
                <div className={style.checkRowMobile}>
                    {checkFumigated}
                    {checkHumanConsuption}
                </div>
            )}
            {isMobile && (
                <div className={style.checkRowMobile}>
                    {checkHalal}
                    {checkOrganicProduct}
                </div>
            )}
        </div>
    );
};
