import { useAppSelector } from "../../../../../../../../redux/hooks";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import { mediaQueries } from "../../../../../../../../common/constants";
import { useMemo, useState } from "react";
import { convertToLocal } from "../../../../../../../../utils";
import { FormatDate } from "../../../../../../../../common/enum/dateTime/FormatDate";
import { CodeViewer } from "../../../../../../../common/codeViewer/codeViewer";
import { JsonCompare } from "../../../../../../../common/codeViewer/jsonCompare";
import { EmailViewer } from "../../../../../../../common/emailViewer/emailViewer";

export const EditRequestHistoryChangeForm = () => {
    const [selectedKey, setSelectedKey] = useState("0");

    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const { form } = useAppSelector((state) => state.editRequestHistoryChangeForm);
    const theme = useTheme() as any;

    const keys = [
        "date",
        "description",
        "transportUnitDescription",
        "transportUnitSizeDescription",
        "transportUnitNumber",
        "tractorNumber",
        "trailerNumber",
        "stateDescription",
        "entryStateOn",
        "preparedStateOn",
        "dockStartStateOn",
        "dockEndStateOn",
        "exitStateOn",
        "entryNoticeSended",
        "dockAssignmentNoticeSended",
        "exitNoticeSended",
        "preparedConfirmedOn",
        "dockDescription",
        "dockAssignmentOn",
        "turnDescription",
        "appointmentTypeDescription",
        "appointmentStateDescription",
        "appointmentSort",
        "appointmentNotified",
        "originCountryCode",
        "merchandiseOriginDescription",
        "merchandiseCategoryDescription",
        "humanConsumption",
        "merchandiseTypeDescription",
        "customsStateDescription",
        "customerId",
        "invoiceCustomerId",
        "customerReference",
        "customerNotifiedOn",
        "carrierId",
        "carrierPhone",
        "carrierRegisterOn",
        "carrierDockAssignmentNotifiedOn",
        "carrierEndNotifiedOn",
        "terminalDescription",
        "exporter",
        "importer",
        "booking",
        "tariffHeading",
        "summaryNumber",
        "summaryActive",
        "saplNumber",
        "saplCreatedOn",
        "authorizedOn",
        "cancelledOn",
        "duaMrnNumber",
        "vuaNumber",
        "c5Number",
        "merchandiseArrivalShip",
        "packages",
        "grossWeight",
        "parkingAuthorization",
        "automaticParkingAuthorization",
        "palletized",
        "inspectionPresence",
        "fumigated",
        "halalCertified",
        "temporaryRegime",
        "dryCharge",
        "organicProduct",
        "representativePresenceTypeDescription",
        "finalDestinationTypeDescription",
        "invoiced",
        "invoicingRemarks",
        "generic",
        "transportUnitNumberAssigned",
        "integrationReference",
        "integrationFinished",
        "remarks",
        "validationRemarks",
        "sort",
        "createdBy",
        "createdOn",
        "lastModifiedBy",
        "lastModifiedOn",
        "documents",
        "typeDescription",
        "photos",
        "id",
        "incidences",
        "category",
        "organismDescription",
        "seals",
        "code",
        "batches",
        "campaignName",
        "areaCode",
        "isSpecialInspection",
        "inspectionCards",
        "representative",
        "customerValidatedBy",
        "customerValidatedOn",
        "validatedBy",
        "validatedOn",
        "inspectionCardLines",
        "sampleUnits",
        "sampleWeight",
        "storageUnits",
        "storageWeight",
        "invoiceConcepts",
        "tariffDescription",
        "quantity",
        "unitPrice",
        "amount",
        "referenceInvoiceConceptId",
        "referenceInvoiceId",
        "invoiceNumber",
        "invoiceDate",
        "synchronized",
        "appointments",
        "appointmentTimeZoneDescription",
        "appointmentDockDescription",
        "inspections",
        "certificateTypeDescription",
        "certificateNumber",
        "year",
        "documentary",
        "manually",
        "vuaOrganismCode",
        "duaItemNumber",
        "inspectorArrivedOn",
        "appointmentReceivedOn",
        "expedient",
        "inspectorId",
        "alert",
        "actuations",
        "groupDescription",
        "instructions",
        "number",
        "readyForInstructionOn",
        "representativeArrivalOn",
        "customsAuthorityArrivalOn",
        "appointmentAssignedTypeDescription",
        "representativeId",
    ];

    const replaceName = (data: any) => {
        if (data === null || data === undefined) {
            return null;
        }

        const transformData = {
            ...data,
            date: data.date ? convertToLocal(data.date, FormatDate.DATE_HOUR_MINUTE) : null,
            entryStateOn: data.entryStateOn ? convertToLocal(data.entryStateOn, FormatDate.DATE_HOUR_MINUTE) : null,
            preparedStateOn: data.preparedStateOn ? convertToLocal(data.preparedStateOn, FormatDate.DATE_HOUR_MINUTE) : null,
            dockStartStateOn: data.dockStartStateOn ? convertToLocal(data.dockStartStateOn, FormatDate.DATE_HOUR_MINUTE) : null,
            dockEndStateOn: data.dockEndStateOn ? convertToLocal(data.dockEndStateOn, FormatDate.DATE_HOUR_MINUTE) : null,
            exitStateOn: data.exitStateOn ? convertToLocal(data.exitStateOn, FormatDate.DATE_HOUR_MINUTE) : null,
            dockAssignmentOn: data.dockAssignmentOn ? convertToLocal(data.dockAssignmentOn, FormatDate.DATE_HOUR_MINUTE) : null,
            preparedConfirmedOn: data.preparedConfirmedOn ? convertToLocal(data.preparedConfirmedOn, FormatDate.DATE_HOUR_MINUTE) : null,
            carrierRegisterOn: data.carrierRegisterOn ? convertToLocal(data.carrierRegisterOn, FormatDate.DATE_HOUR_MINUTE) : null,
            carrierDockAssignmentNotifiedOn: data.carrierDockAssignmentNotifiedOn
                ? convertToLocal(data.carrierDockAssignmentNotifiedOn, FormatDate.DATE_HOUR_MINUTE)
                : null,
            carrierEndNotifiedOn: data.carrierEndNotifiedOn ? convertToLocal(data.carrierEndNotifiedOn, FormatDate.DATE_HOUR_MINUTE) : null,
            saplCreatedOn: data.saplCreatedOn ? convertToLocal(data.saplCreatedOn, FormatDate.DATE_HOUR_MINUTE) : null,
            authorizedOn: data.authorizedOn ? convertToLocal(data.authorizedOn, FormatDate.DATE_HOUR_MINUTE) : null,
            cancelledOn: data.cancelledOn ? convertToLocal(data.cancelledOn, FormatDate.DATE_HOUR_MINUTE) : null,
            createdOn: data.createdOn ? convertToLocal(data.createdOn, FormatDate.DATE_HOUR_MINUTE) : null,
            readyForInstructionOn: data.readyForInstructionOn ? convertToLocal(data.readyForInstructionOn, FormatDate.DATE_HOUR_MINUTE) : null,
            representativeArrivalOn: data.representativeArrivalOn ? convertToLocal(data.representativeArrivalOn, FormatDate.DATE_HOUR_MINUTE) : null,
            customsAuthorityArrivalOn: data.customsAuthorityArrivalOn
                ? convertToLocal(data.customsAuthorityArrivalOn, FormatDate.DATE_HOUR_MINUTE)
                : null,
            customerNotifiedOn: data.customerNotifiedOn ? convertToLocal(data.customerNotifiedOn, FormatDate.DATE_HOUR_MINUTE) : null,
            lastModifiedOn: data.lastModifiedOn ? convertToLocal(data.lastModifiedOn, FormatDate.DATE_HOUR_MINUTE) : null,
            seals: data.seals
                ? data.seals.map((item: any) => {
                      return {
                          id: item.id,
                          typeDescription: item.typeDescription,
                          number: item.code,
                      };
                  })
                : null,
            invoiceConcepts: data.invoiceConcepts
                ? data.invoiceConcepts.map((item: any) => {
                      return { ...item, date: item.date ? convertToLocal(item.date, FormatDate.DATE_HOUR_MINUTE) : null };
                  })
                : null,
            inspections: data.inspections
                ? data.inspections.map((item: any) => {
                      return {
                          ...item,
                          inspectorArrivedOn: item.inspectorArrivedOn ? convertToLocal(item.inspectorArrivedOn, FormatDate.DATE_HOUR_MINUTE) : null,
                      };
                  })
                : null,
            inspectionCards: data.inspectionCards
                ? data.inspectionCards.map((item: any) => {
                      return {
                          customerValidatedBy: item.customerValidatedBy,
                          customerValidatedOn: item.customerValidatedOn
                              ? convertToLocal(item.customerValidatedOn, FormatDate.DATE_HOUR_MINUTE)
                              : null,
                          id: item.id,
                          inspectionCardLines: item.inspectionCardLines,
                          representative: item.representative,
                          stateDescription: item.stateDescription,
                          validatedBy: item.validatedBy,
                          validatedOn: item.validatedOn ? convertToLocal(item.validatedOn, FormatDate.DATE_HOUR_MINUTE) : null,
                          validationRemarks: item.validationRemarks,
                      };
                  })
                : null,
        };

        let newData = JSON.stringify(transformData);
        for (let key of keys) {
            const upperKey: any = "FIELDS." + key.toUpperCase();
            newData = newData.replaceAll(`"${key}"`, `"${t(upperKey)}"`);
        }

        let transformDataJson = JSON.parse(newData);
        return transformDataJson;
    };

    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
            root: {
                display: "flex",
                width: "100%",
            },
            itemContainer: {
                paddingLeft: 8,
                width: "100%",
                height: "100%",
                "& > div": {
                    width: "100%",
                    height: "100%",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    return !form.integrationData && !form.emailData ? (
        <JsonCompare
            oldData={form.oldRequest ?? form.newRequest}
            newData={form.newRequest ?? null}
            replaceName={replaceName}
            showMenuOption={form.oldRequest ? true : false}
            newDataMenuText={t("FIELDS.COPY_NEW_REQUEST")}
            oldDataMenuText={t("FIELDS.COPY_OLD_REQUEST")}
            height="calc(92vh - 10rem)"
        />
    ) : form.emailData ? (
        <EmailViewer
            height="calc(92vh - 14rem)"
            tos={form.emailData.tos}
            subject={form.emailData.subject}
            body={form.emailData.body}
        />
    ) : (
        <div style={{ width: "99%" }}>
            <Pivot
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                {form.newRequest && (
                    <PivotItem
                        headerText={t("TITLES.CHANGES")}
                        itemKey="0"
                    >
                        <JsonCompare
                            oldData={form.oldRequest ?? form.newRequest}
                            newData={form.newRequest ?? null}
                            replaceName={replaceName}
                            showMenuOption={form.oldRequest ? true : false}
                            newDataMenuText={t("FIELDS.COPY_NEW_REQUEST")}
                            oldDataMenuText={t("FIELDS.COPY_OLD_REQUEST")}
                            height="calc(86vh - 10rem)"
                        />
                    </PivotItem>
                )}

                {form.integrationData && (
                    <PivotItem
                        headerText={t("TITLES.HISTORY_INTEGRATION")}
                        itemKey="1"
                    >
                        <CodeViewer
                            height="calc(86vh - 10rem)"
                            code={form.integrationData}
                        />
                    </PivotItem>
                )}
            </Pivot>
        </div>
    );
};
