import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const OrganizationContainerStyle = createUseStyles((theme: any) => ({
    container: {
        height: "calc(100vh - 207px)",
        paddingLeft: theme.paddings.header.paddingLeft,
        backgroundColor: "white",
        justifyContent: "space-between",
        marginTop: "15px",
        paddingTop: theme.paddings.sizes.base,
    },
    disclaimer: {
        display: "flex",
        width: "100%",
        columnGap: 4,
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.sm,
        alignContent: "center",
        "& span": {
            color: theme.colors.titles.danger.color,
            fontWeight: 600,
        },
        [mediaQueries.TABLET]: {
            display: "flex",
            justifyContent: "start",
        },
    },
    actionButtons: {
        padding: "17px 24px 0px 24px",
        display: "flex",
        gap: theme.gaps.buttons.default,
        justifyContent: "space-between",
        alignItems: "center",
        "& div:nth-child(1)": {
            width: "40%",
        },
        "& button": {
            minWidth: 110,
        },
        "& div:nth-child(2)": {
            display: "flex",
            gap: theme.gaps.buttons.default,
            width: "50%",
            justifyContent: "end",
        },
        [mediaQueries.TABLET]: {
            display: "flex",
            flexDirection: "column",
            "& div:nth-child(1)": {
                width: "100%",
            },

            "& div:nth-child(2)": {
                width: "100%",
                display: "flex",
                gap: theme.gaps.buttons.default,
            },
        },
    },
}));
