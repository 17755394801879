import { createUseStyles } from "react-jss";
import { cssConstants } from "../../../../../../common/constants";

export const UtiCardStyle = createUseStyles((theme: any) => ({
    utiCardContainer: {
        display: "flex",
        width: 400,
        height: 280,
        backgroundColor: theme.colors.site.background,
        flexDirection: "column",
        fontSize: 12,
        boxShadow: "7px 10px 10px 0 rgb(0 0 0 / 20%)",
    },
    utiCardRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px dotted grey",
        paddingBottom: 12,
    },
    utiCardActionButtons: {
        width: 100,
        display: "flex",
        marginLeft: "auto",
        backgroundColor: theme.colors.palette.greyLight,
        alignSelf: "flex-end",
        padding: 4,
    },
    utiCardButtonEdit: {
        borderRight: `1px solid ${theme.colors.palette.grey}`,
        color: theme.colors.titles.primary.color,
        width: "70%",
        fontWeight: 700,
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
    },
    utiCardButtonDelete: {
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 800,
        fontSize: 18,
        width: "30%",
        padding: 4,
        "& i": { color: theme.colors.titles.primary.color, fontSize: 16 },
    },
    utiCardDataContainer: {
        padding: 16,
        rowGap: 8,
        flexDirection: "column",
        overflowY: "auto",
        display: "flex",
    },
    utiCardItem: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        rowGap: 8,
    },
    utiCardLabel: {
        fontWeight: 500,
        color: theme.colors.palette.grey,
    },
    utiCardData: {
        color: theme.colors.palette.black,
        textTransform: "uppercase",
        fontWeight: 600,
    },

    utiUnitSection: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        rowGap: 8,
    },
    utiGrossData: {
        display: "flex",
        justifyContent: cssConstants.SPACE_BETWEEN,
        width: "100%",
    },
    seals: {
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
        width: "100%",
    },
    sealsContainer: {
        display: "flex",
        overflow: "auto",
        rowGap: 4,
        columnGap: 4,
        flexWrap: "wrap",
        paddingTop: 8,
    },
    card: {
        backgroundColor: theme.colors.palette.greyBlue,
        padding: 6,
        width: "auto",
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: 600,
        color: theme.colors.palette.purpleDarker,
        borderRadius: 50,
    },
}));
