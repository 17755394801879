import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const UserStepStyled = createUseStyles((theme: any) => ({
    step: {
        display: "flex",
        columnGap: "10px",
        minHeight: "40px",
    },
    leftColumn: {
        flexFlow: "column",
        display: "flex",
        flex: "15",
    },
    rightColumn: {
        flexFlow: "column",
        display: "flex",
        flex: "85",
        justifyContent: "center",
        [mediaQueries.MOBILE] : {
            width:"100%"
        }
    },
    titleContainer: {
        fontWeight: "600",
        flexFlow: "row",
        display: "flex",
        fontSize: "13px",
        color: theme.colors.navLinks.secondary.color,
    },
    titleContainerSelected: {
        fontWeight: "600",
        flexFlow: "row",
        display: "flex",
        fontSize: "13px",
        color: theme.colors.navLinks.primary.color,
    },
    subtitleContainer: {
        fontWeight: "200",
        flexFlow: "row",
        display: "flex",
        fontSize: "12px",
    },
    subtitleContainerSelected: {
        fontWeight: "500",
        flexFlow: "row",
        display: "flex",
        fontSize: "12px",
    },

    stepNotSelected: {
        extend: "step",
        display: "flex",
        [mediaQueries.MOBILE]: {
            display: "none",
        },
    },
}));
