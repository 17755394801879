import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { fetchInspectionActuationsGroup } from "../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { FormNumberField } from "../../../../../../common/numberField/numberField";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterCertificateTypeFormStyle } from "./formStyle.jss";
import { FormatNumberInput } from "../../../../../../common/numberField/utils";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterActuationType, IMasterActuationTypeRemaining } from "../../../../../../../models/resources/master/IActuationType";
import { updateMasterActuationTypeProperty, setMasterActuationTypeForm } from "../../../../../../../redux/reducers/resources/master/actuationType/form";
interface IMasterActuationTypeFormProps {
    showNotData: boolean;
    selectorData: IMasterActuationTypeRemaining[];
}
export const MasterActuationTypeForm: React.FC<IMasterActuationTypeFormProps> = ({ showNotData, selectorData }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const theme = useTheme();
    const style = MasterCertificateTypeFormStyle({ theme });
    const dispatch = useAppDispatch();
    const updatePropertyForm = (name: string, value: any) => {
        dispatch(updateMasterActuationTypeProperty({ name, value }));
    };
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { form } = useAppSelector((state) => state.masterActuationTypeForm);
    const { data } = useAppSelector((state) => state.catalog.actuationsGroup);
    const { register, control } = useForm<IMasterActuationType>({ mode: "onChange" });

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );

    const onInputNumberchange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, FormatNumberInput(value));
        },
        [dispatch],
    );



    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        updatePropertyForm(propertyName, !!+option.key);
    };
    const onSelectorChange = (event: any, option: any, name: string) => {
        updatePropertyForm(name, option.key);
        let actuationType = selectorData.find((item) => {
            return item.id === option.key;
        });
        if (actuationType) {
            const form: IMasterActuationType = {
                active: true,
                referenceId: String(option.key),
                code: actuationType.code,
                description: actuationType.description,
                groupId: 0,
                mark: false,
            };

            dispatch(setMasterActuationTypeForm(form));
        }
    };

    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    useEffect(() => {
        !data.length && dispatch(fetchInspectionActuationsGroup());
    }, []);

    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {showPopUp === PopUpCodes.CREATE && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterActuationType>("referenceId")}
                        title={t("FORM.ACTUATION_TYPE")}
                        options={mapIDescriptionToDropDownOptions(selectorData)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code}
                    {...register(compileNameOfProperty<IMasterActuationType>("code"), {
                        onChange: onInputChange,
                        value: form.code,
                    })}
                    isRequired={true}
                    disabled={true}
                />
                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? ""}
                    disabled={disabledInput}
                    {...register(compileNameOfProperty<IMasterActuationType>("description"), {
                        onChange: onInputChange,
                        value: form.description,
                    })}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <Selector
                    selectedKey={form.groupId}
                    name={compileNameOfProperty<IMasterActuationType>("groupId")}
                    title={t("FORM.ACTUATION_GROUP")}
                    options={mapIDescriptionToDropDownOptions(data)}
                    onChange={onSelectorChange}
                    required={true}
                    disabled={disabledInput}

                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.mark}
                    propertyName={compileNameOfProperty<IMasterActuationType>("mark")}
                    title={t("MARK")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>

            <div className={style.rowForm}>
                <FormNumberField
                    value={String(form.sortApp ?? "")}
                    label={t("SORT")}
                    isRequired={true}
                    {...register(compileNameOfProperty<IMasterActuationType>("sortApp"), {
                        onChange: onInputNumberchange,
                        value: form.sortApp,

                        required: true,
                    })}
                    disabled={disabledInput}
                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterActuationType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
