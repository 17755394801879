import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IGenericPayload } from "../../../../models";
import { IControlExpedientForm } from "../../../../models/control/expedient/IExpedient";
import { convertToLocal } from "../../../../utils";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface IControlExpedientFormState {
    form: IControlExpedientForm;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IControlExpedientFormState = {
    form: {
        certificateNumber: "",
        certificateTypeId: 0,
        pending: true,
        alert: false,
        year: Number(convertToLocal(new Date(), FormatDate.YEAR)),
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const controlExpedientFormSlice = createSlice({
    name: "controlExpedientListSlice",
    initialState,
    reducers: {
        resetControlExpedientState: () => initialState,
        resetControlExpedientForm: (state) => {
            state.form = initialState.form;
        },
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setControlExpedientForm: (state, action: PayloadAction<IControlExpedientForm>) => {
            state.form = action.payload;
        },
        setLoadingForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetControlExpedientState, resetControlExpedientForm,
    updateFormProperty,
    setControlExpedientForm,
    setLoadingForm,
    setShowPopUp,
} = controlExpedientFormSlice.actions;

export default controlExpedientFormSlice.reducer;
