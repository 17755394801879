import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterActuationType } from "../../../../../models/resources/master/IActuationType";

export interface IMasterActuationTypeFormState {
    form: IMasterActuationType;
    loading: boolean;
}

const initialState: IMasterActuationTypeFormState = {
    form: {
        active: true,
        code: "",
        description: "",
        groupId: 0,
        mark: false,
    },
    loading: false,
};

export const masterActuationTypeForm = createSlice({
    name: "masterActuationTypeForm",
    initialState,
    reducers: {
        resetMasterActuationTypeForm: () => initialState,
        setLoadingMasterActuationTypeForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterActuationTypeForm: (state, action: PayloadAction<IMasterActuationType>) => {
            state.form = action.payload;
        },
        updateMasterActuationTypeProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterActuationTypeForm, setLoadingMasterActuationTypeForm, setMasterActuationTypeForm, updateMasterActuationTypeProperty } =
    masterActuationTypeForm.actions;

export default masterActuationTypeForm.reducer;
