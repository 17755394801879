import { IPivotStyles, IStyleSet, Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useParams } from "react-router";
import { mediaQueries } from "../../../common/constants";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { IChartOption, IChartSeries } from "../../../models/common/IChartOptions";
import { fetchStatisticsRequestGraphAction } from "../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { convertToLocal } from "../../../utils";
import { mapCategoriesRequestGraph, mapSeriesRequestGraph } from "../../../utils/chart";
import { Charts } from "../../common/chart/chart";
import { TitleH6 } from "../../common/titles/titles";
import { StatisticsRequestGraphContainerStyle } from "./containerStyle.jss";
import { StatisticsRequestGraphWithOutLoginContainer } from "./requestGraphWithOutLogin/container";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";

export const StatisticsRequestGraphContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.REQUEST_GRAPH" });
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.statiticsRequestGraph);
    const theme = useTheme() as any;
    const { organization } = useParams();
    const organizationParam = organization ? organization : "";

    const pivotStyles: Partial<IStyleSet<IPivotStyles>> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    const style = StatisticsRequestGraphContainerStyle({ theme });
    const options: IChartOption & any = {
        chart: {
            height: 350,
            stacked: true,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        xaxis: {
            categories: mapCategoriesRequestGraph(data.requestGraphDateStates, "date", t("ACCUMULATED")),
        },
    };

    const seriesContainer: IChartSeries[] = mapSeriesRequestGraph(data.requestGraphDateStates, "containers", "stateDescription", {
        description: t("ACCUMULATED"),
        data: data.totalContainers,
    });
    const seriesTruck: IChartSeries[] = mapSeriesRequestGraph(data.requestGraphDateStates, "trucks", "stateDescription", {
        description: t("ACCUMULATED"),
        data: data.totalTrucks,
    });

    useEffect(() => {
        dispatch(fetchStatisticsRequestGraphAction(organizationParam));
    }, []);

    const requestGraphContent = () => {
        return !organizationParam ? (
            <>
                <NavHeaderComponent title={t("TITLE")} />
                <div className={style.content}>
                    <Pivot styles={pivotStyles}>
                        <PivotItem
                            headerText={t("TITLE_TRUCK")}
                            itemKey="0"
                        >
                            <div className={style.container}>
                                <div className={style.chart}>
                                    <Charts
                                        options={options}
                                        series={seriesTruck}
                                        type="bar"
                                        height={400}
                                    />
                                    <div className={style.message}>
                                        <TitleH6
                                            title={t("MESSAGE", {
                                                date: convertToLocal(new Date().toISOString(), FormatDate.DATE),
                                                count: data.totalTrucks,
                                                interpolation: { escapeValue: false },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </PivotItem>

                        <PivotItem
                            headerText={t("TITLE_CONTAINER")}
                            itemKey="1"
                        >
                            <div className={style.container}>
                                <div className={style.chart}>
                                    <Charts
                                        options={options}
                                        series={seriesContainer}
                                        type="bar"
                                        height={400}
                                    />
                                    <div className={style.message}>
                                        <TitleH6
                                            title={t("MESSAGE", {
                                                date: convertToLocal(new Date().toISOString(), FormatDate.DATE),
                                                count: data.totalContainers,
                                                interpolation: { escapeValue: false },
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                    </Pivot>
                </div>
            </>
        ) : (
            <>
                <StatisticsRequestGraphWithOutLoginContainer
                    seriesContainer={seriesContainer}
                    seriesTruck={seriesTruck}
                />
            </>
        );
    };

    return <>{requestGraphContent()}</>;
};
