import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpConfirmationType } from "../../../../../common/enum/PopUpConfirmationType";
import { CustomIcons } from "../../../customIcons/customIcons";
import { confirmMessageStyle } from "./confirmMessageStyle.jss";
import { useEffect, useState } from "react";

interface IConfirmMessageProps {
    type: PopUpConfirmationType;
    message?: string;
    fontSize?: string;
    iconSize?: string;
    showPopUp?: boolean;
}

export const ConfirmMessage: React.FC<IConfirmMessageProps> = ({ type, message, fontSize, iconSize, showPopUp }) => {
    const theme = useTheme();
    const style = confirmMessageStyle({ theme, fontSize });
    const { t } = useTranslation("messages", { keyPrefix: "WARNING_POPUP" });

    const getIconMessage = () => {
        switch (type) {
            case PopUpConfirmationType.SUCCESS:
                return (
                    <CustomIcons
                        className={style.icon}
                        iconName="succes"
                        disabledSelect={true}
                        margin={"0px"}
                        size={iconSize ?? "5rem"}
                    />
                );
            case PopUpConfirmationType.DELETE:
                return (
                    <CustomIcons
                        className={style.removeIcon}
                        iconName="RemoveIcon"
                        disabledSelect={true}
                        margin={"0px"}
                        size={iconSize ? iconSize : "5rem"}
                    />
                );
            case PopUpConfirmationType.WARNING:
                return (
                    <CustomIcons
                        className={style.iconWarning}
                        iconName="warning"
                        disabledSelect={true}
                        margin={"0px"}
                        size={iconSize ? iconSize : "5rem"}
                    />
                );
            case PopUpConfirmationType.INFO:
                return (
                    <CustomIcons
                        className={style.infoIcon}
                        iconName="InfoIcon"
                        disabledSelect={true}
                        margin={"0px"}
                        size={iconSize ? iconSize : "5rem"}
                    />
                );
        }
    };
    const getMessageAlert = () => {
        switch (type) {
            case PopUpConfirmationType.SUCCESS:
                return "";
            case PopUpConfirmationType.DELETE:
                return t("DELETE_ITEM_MESSAGE");

            case PopUpConfirmationType.WARNING:
                return "";
            case PopUpConfirmationType.INFO:
                return "";
            default:
                return "";
        }
    };

    const [messageConfirmation, setMessageConfirmation] = useState<string>("");

    useEffect(() => {
        if (showPopUp) {
            setMessageConfirmation(message ? message : getMessageAlert());
        }
    }, [showPopUp, message, type]);

    return (
        <div className={style.container}>
            <div>{getIconMessage()}</div>
            <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: messageConfirmation }}
            ></div>
        </div>
    );
};
