import { ManagementIncidenceTypesApi } from "../../../../api/management/incidenceTypes";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { IIncidenceTypesFilter } from "../../../../models/management/incidenceType/IIncidenceType";
import { throwErrorToastrAsync } from "../../../../utils";
import { setManagementIncidenceTypeFormData, setManagementIncidenceTypeFormLoading } from "../../../reducers/management/incidenceType/form";
import { fetchManagementIncidenceTypeList, setLoadingManagementIncidenceTypeList } from "../../../reducers/management/incidenceType/list";
import { AppThunk, store } from "../../../store";

const managementIncidenceTypesApi = new ManagementIncidenceTypesApi();
export const fetchManagementIncidencesTypesAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().managementIncidenceTypesFilter;

        dispatch(setLoadingManagementIncidenceTypeList(true));
        const response = await managementIncidenceTypesApi.getManagementIncidencesTypesAsync(mapStatiticsFilter(filter));
        dispatch(fetchManagementIncidenceTypeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingManagementIncidenceTypeList(false));
    }
};

export const postManagementIncidenceTypeAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().managementIncidenceTypesForm;

            dispatch(setManagementIncidenceTypeFormLoading(true));

            if (form.id) {
                await managementIncidenceTypesApi.putManagementIncidenceTypeItemAsync(form);
            } else {
                await managementIncidenceTypesApi.postManagementIncidenceTypeItemAsync(form);
            }
            dispatch(fetchManagementIncidencesTypesAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setManagementIncidenceTypeFormLoading(false));
            return false;
        }
    };
};
export const removeManagementIncidenceTypeAction = (id: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setManagementIncidenceTypeFormLoading(true));

            await managementIncidenceTypesApi.removeManagementIncidenceTypeItemAsync(id);
            dispatch(fetchManagementIncidencesTypesAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setManagementIncidenceTypeFormLoading(false));
        }
    };
};

export const fetchManagementIncidenceTypeItemAction =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setManagementIncidenceTypeFormLoading(true));
            const response = await managementIncidenceTypesApi.getManagementIncidenceTypeItemAsync(id);
            dispatch(setManagementIncidenceTypeFormData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setManagementIncidenceTypeFormLoading(false));
        }
    };

const mapStatiticsFilter = (filter: IIncidenceTypesFilter): IIncidenceTypesFilter => {
    const filters: IIncidenceTypesFilter = {
        ...filter,
        incidenceType: filter.incidenceType && String(filter.incidenceType) !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.incidenceType : undefined,
        organismIds: isAllSearchInFilter(filter.organismIds),
    };
    return filters;
};

const isAllSearchInFilter = (list: any) => {
    const a = !list.includes(DefaultValues.DEFAULT_ALL_SELECTOR) && !list.includes(-1);
    return a ? list : null;
};
