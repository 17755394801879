import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ButtonPrimary } from "../../../../../common/buttons/buttons";
import { ResumeButtonsStyle } from "./resumeButtons.jss";

interface IProps {
    onAccept: () => void;
}

export const ResumeButtons: React.FC<IProps> = ({ onAccept }) => {
    const theme = useTheme();
    const styles = ResumeButtonsStyle({ theme });

    const { t } = useTranslation("common");

    return (
        <div className={styles.resumeButtonsContainer}>
            <div className={styles.disclaimer}></div>
            <div className={styles.resumeButtons}>
                <ButtonPrimary
                    handleClick={onAccept}
                    title={t("CREATE_REQUEST.BUTTONS.FINALIZE")}
                />
            </div>
        </div>
    );
};
