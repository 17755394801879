import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { emptyStatisticsFilter, IStatisticsFilter } from "../../../../models/statistics/IStatisticsFilters";

interface IStatiticsOperatorIncidenceFilterState {
    filter: IStatisticsFilter;
}

const initialState: IStatiticsOperatorIncidenceFilterState = {
    filter: emptyStatisticsFilter(),
};

export const statisticsOperatorIncidenceFilterSlice = createSlice({
    name: "statisticsOperatorIncidenceFilterSlice",
    initialState,
    reducers: {
        resetOperatorIncidenceFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetOperatorIncidenceFilter, updateFilterProperty } = statisticsOperatorIncidenceFilterSlice.actions;

export default statisticsOperatorIncidenceFilterSlice.reducer;
