import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterTurn } from "../../../../../models/resources/master/ITurn";

export interface IMasterTurnsState {
    list?: IMasterTurn[];
    loading: boolean;
}

const initialState: IMasterTurnsState = {
    loading: false,
};

export const masterTurnsList = createSlice({
    name: "masterTurnsList",
    initialState,
    reducers: {
        resetMasterTurnsList: () => initialState,
        setMasterLoadingTurnsList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTurnsList: (state, action: PayloadAction<any[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterTurnsList, setMasterLoadingTurnsList, setMasterTurnsList } = masterTurnsList.actions;

export default masterTurnsList.reducer;
