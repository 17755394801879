import { IColumn } from "@fluentui/react";
import { useEffect } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../utils";
import { IManagemenTActiveSummaryItem } from "../../../../../models/management/summary/ISummary";
import { RoleType } from "../../../../../common/enum";

export const SummarySchema = (columns: IColumn[]): IColumn[] => {
    const currentUserRole = useAppSelector((store) => store.role.roleData.id);
    const viewColumns: IColumn[] = [];
    let columnSchema = [
        {
            name: compileNameOfProperty<IManagemenTActiveSummaryItem>("summaryNumber"),
        },
        {
            name: compileNameOfProperty<IManagemenTActiveSummaryItem>("createdOn"),
        },
        {
            name: compileNameOfProperty<IManagemenTActiveSummaryItem>("activatedOn"),
        },

        {
            name: compileNameOfProperty<IManagemenTActiveSummaryItem>("activationType"),
        },
        {
            name: "actions",
        },
    ];

    columnSchema.forEach((value: any) => {
        const columnFilter = columns.filter((column) => column.key.toLocaleLowerCase() === value.name.toLocaleLowerCase()).find(() => true);

        if (columnFilter && columnFilter.key.toLowerCase() === compileNameOfProperty<IManagemenTActiveSummaryItem>("createdOn").toLowerCase()) {
            if (currentUserRole !== RoleType.CUSTOMER_ADMIN && currentUserRole !== RoleType.CUSTOMER_USER) {
                viewColumns.push(columnFilter);
            }
        } else {
            if (columnFilter) {
                viewColumns.push(columnFilter);
            }
        }
    });

    return viewColumns;
};
