import { useTheme } from "react-jss";
import { AlertStyle } from "./alertStyle.jss";
import { CustomIcons } from "../customIcons/customIcons";
import { ColorType } from "../../../common/enum/ColorType";

interface IAlertProps {
    title?: string;
    data: any;
    icon?: string;
    colorType?: ColorType;
    height?: any;
    width?: any;
    fontSize?: any;
}

export const Alert: React.FC<IAlertProps> = ({ data, children, icon, title, colorType, height, width, fontSize }) => {
    const theme = useTheme();
    const style = AlertStyle({ theme, height, width, fontSize });
    const getColorClassName = (type?: ColorType) => {
        switch (type) {
            case ColorType.primary:
                return style.primary;
            case ColorType.primaryInverse:
                return style.primaryInverse;
            case ColorType.primaryInverseWithBorder:
                return style.primaryInverseWithBorder;
            case ColorType.secondary:
                return style.secondary;
            case ColorType.success:
                return style.success;
            case ColorType.danger:
                return style.danger;
            case ColorType.warning:
                return style.warning;
            case ColorType.info:
                return style.info;
            case ColorType.dangerInverseWithBorder:
                return style.dangerInverseWithBorder;
            default:
                return style.default;
        }
    };

    return (
        <div className={`${getColorClassName(colorType)} ${style.container}`}>
            {(icon || title) && (
                <div className={style.header}>
                    {icon && (
                        <CustomIcons
                            className={getColorClassName(colorType)}
                            iconName={icon}
                            disabledSelect={true}
                            position={"start"}
                            margin={"0px"}
                            size={20}
                        />
                    )}
                    {title && (
                        <div className={style.header}>
                            <b>{title}</b>
                        </div>
                    )}
                </div>
            )}

            <div className={style.data}>{data}</div>
        </div>
    );
};
