import { createUseStyles } from "react-jss";

export const InspectionCardStyle = createUseStyles((theme: any) => ({
    inspectionCardContainer: {
        display: "flex",
        width: 400,
        height: 280,
        backgroundColor: theme.colors.site.background,
        flexDirection: "column",
        fontSize: 12,
        boxShadow: "7px 10px 10px 0 rgb(0 0 0 / 20%)",
    },
    inspectionCardRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px dotted grey",
        paddingBottom: 12,
    },
    inspectionCardLastRow: {
        display: "flex",
        flexDirection: "row",
    },
    inspectionCardActionButtons: {
        width: 100,
        display: "flex",
        marginLeft: "auto",
        backgroundColor: theme.colors.palette.greyLight,
        alignSelf: "flex-end",
        padding: 4,
    },
    inspectionCardButtonEdit: {
        borderRight: `1px solid ${theme.colors.palette.grey}`,
        color: theme.colors.titles.primary.color,
        width: "70%",
        fontWeight: 700,
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
    },
    inspectionCardButtonDelete: {
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 800,
        fontSize: 18,
        width: "30%",
        padding: 4,
        "& i": { color: theme.colors.titles.primary.color, fontSize: 16 },
    },
    inspectionCardDataContainer: {
        padding: 16,
        rowGap: 8,
        flexDirection: "column",
        overflowY: "auto",
        display: "flex",
    },
    inspectionCardItem: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        rowGap: 8,
    },
    inspectionCardLabel: {
        fontWeight: 500,
        color: theme.colors.palette.grey,
    },
    inspectionCardData: {
        color: theme.colors.palette.black,
        textTransform: "uppercase",
        fontWeight: 600,
    },
}));
