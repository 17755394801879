import { createUseStyles } from "react-jss";

export const SectionStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",

        gap: 7,
        width: "97%",
        padding: 5,
    },
    titleFormContainer: (props: any) => ({
        display: "flex",
        opacity: !props.sectionActive && props.showDisabledController ? "0.5" : "1",
    }),
    titleSection: (props: any) => ({
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        gap: props.showDisabledController ? 0 : theme.gaps.sizes.base,
        userSelect: "none",
    }),
    valueSection: {
        marginLeft: 35,
    },
    bubble: {
        color: "red",
        marginLeft: 8,
        fontSize: 6,
        width: "10px",
        userSelect: "none",
    },
    bubbleOrange: {
        color: "orange",
        display: "inline",
        fontSize: 7,
        marginTop: 4,
        marginLeft: 4,
    },
}));
