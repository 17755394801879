import { createUseStyles } from "react-jss";

export const ImageListStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
    },
    image: {
        width: 60,
        height: 50,
        cursor: "pointer",
    },

    createdByEmail: {
        cursor: "pointer",
    },
}));
