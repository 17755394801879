import { createUseStyles } from "react-jss";

export const FilterHeaderStyle = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        display: "flex",
        gap: theme.gaps.filter.default,
        paddingLeft: theme.paddings.header.paddingLeft,
        paddingRight: theme.paddings.header.paddingRight,
        backgroundColor: theme.colors.headerNav.background,
        color: theme.colors.headerNav.color,
        padding: theme.paddings.sizes.base,
        marginTop: theme.margins.sizes.base,
        marginBottom: theme.margins.sizes.base,
        minHeight: theme.heights.filter.default,
        height: props.height ?? theme.heights.filter.default,
       
    }),
}));
