import { Method } from "../../../../common/enum";
import { IBoardHistoryAppointmentTypeConfiguration } from "../../../../models/masterAppointment/IAppointmentBoard";
import { apiFetchOAuthWithClaims } from "../../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment/dashboards`;

export class MasterAppointmentTypeConfigurationBoardAPI {
    async getMasterAppointmentTypeConfigurationBoard(dashboardId: string, id: string): Promise<IBoardHistoryAppointmentTypeConfiguration> {
        const url = `${baseURL}/${dashboardId}/typeconfigurations/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IBoardHistoryAppointmentTypeConfiguration>();
    }

    async postMasterAppointmentTypeConfigurationBoard(dashboardId: string, form: any): Promise<number> {
        const url = `${baseURL}/${dashboardId}/typeconfigurations`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<number>();
    }

    async updateMasterAppointmentTypeConfigurationBoard(
        dashboardId: string,
        id: string,
        form: any,
    ): Promise<number> {
        const url = `${baseURL}/${dashboardId}/typeconfigurations/${id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<number>();
    }

    async deleteMasterAppointmentTypeConfigurationBoard(dashboardId: string, id: string): Promise<boolean> {
        const url = `${baseURL}/${dashboardId}/typeconfigurations/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
