import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../assets/icons";
import { CustomIcons } from "../customIcons/customIcons";
import { ExportDataStyle } from "./exportDataStyle.jss";

interface IExportDataIcon {
    onExportCSV: () => void;
    text?:string
    iconName?:string
    iconSize?:number
}

export const ExportDataIcon: React.FC<IExportDataIcon> = ({ onExportCSV, text, iconName, iconSize }) => {
    const theme = useTheme();
    const style = ExportDataStyle({ theme });
    const { t } = useTranslation("common");

    return (
        <>
            <div
                className={style.exportBtn}
                onClick={onExportCSV}
            >
                <CustomIcons
                    size={iconSize ?? 30}
                    iconName={iconName ?? NameIcons.EXPORTDATA }
                />

                {text ? text :t("EXPORT_DATA_BUTTON")}
            </div>
        </>
    );
};
