import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterDestinationFinalTypes } from "./list/list";
import { setFinalDestinations } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterFinalDestinationTypesList } from "../../../../../redux/reducers/resources/master/finalDestinationTypes/list";

export class MasterFinalDestinationTypesContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterFinalDestinationTypesList());
        store.dispatch(setFinalDestinations([]));
    }

    render(): React.ReactNode {
        return (
            <MasterDestinationFinalTypes
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
