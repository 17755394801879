import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigationWithBlocker = (isDirtyForm: boolean) => {
    const navigate = useNavigate();
    const [pendingNavigation, setPendingNavigation] = useState({ to: null, options: {} });
    const [showWarningBack, setShowWarningBack] = useState(false);
    const isDirtyFormRef = useRef(isDirtyForm);

    useEffect(() => {
        isDirtyFormRef.current = isDirtyForm;
    }, [isDirtyForm]);

    const navigateWithBlock = useCallback(
        (to, options) => {
            if (isDirtyFormRef.current) {
                setPendingNavigation({ to, options });
                setShowWarningBack(true);
            } else {
                navigate(to, options);
            }
        },
        [navigate],
    );

    const confirmNavigation = useCallback(() => {
        if (pendingNavigation.to) {
            navigate(pendingNavigation.to, pendingNavigation.options ?? {});
            setPendingNavigation({ to: null, options: {} });
            setShowWarningBack(false);
        }
    }, [navigate, pendingNavigation]);

    const cancelNavigation = useCallback(() => {
        setPendingNavigation({ to: null, options: {} });
        setShowWarningBack(false);
    }, []);

    return {
        navigateWithBlock,
        confirmNavigation,
        cancelNavigation,
        showWarningBack,
    };
};
