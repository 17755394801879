import { useTheme } from "react-jss";
import { CodeViewerStyle } from "./codeViewer.jss";
import { CustomIcons } from "../customIcons/customIcons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { useMemo } from "react";
import { validateXml } from "../../../utils/xmlUtils";
import { IContextualMenuProps } from "@fluentui/react";
import xmlFormat from 'xml-formatter';
import ReactJsonViewCompare from "react-json-view-compare";

interface ICodeViewerProps {
    code?: any;
    oldCode?: any;
    height?: string;
    menuIconProps?: IContextualMenuProps ;
    renderCustomComponen?: any;
    customCopyFunction?: () => any;
}

export const CodeViewer: React.FC<ICodeViewerProps> = ({ code, height, menuIconProps, renderCustomComponen, customCopyFunction }) => {
    const theme = useTheme();
    const style = CodeViewerStyle({ theme, height });
    const detectFormat = useMemo(() => {
        try {
            if (validateXml(code)) {
                return "xml";
            } else if (JSON.parse(code)) {
                return "json";
            } else {
                return "txt";
            }
        } catch (error) {
            try {
                if (JSON.parse(code)) {
                    return "json";
                }
            } catch (error) {
                return "txt";
            }

            return "txt";
        }
    }, [code]);

    const copyToClipBoard = (data: any) => {
        data &&
            navigator.clipboard
                .writeText(data)
                .then(() => {})
                .catch((_err) => {});
    };

    const formatCode = (code: any) => {
        if (detectFormat === "xml") {
            return xmlFormat(code,{
                collapseContent: true, 
 
            });
        }

        return code;
    };

    return (
        <div className={style.container}>
            <div className={style.header}>
                <CustomIcons
                    iconName="copy"
                    size={20}
                    margin={"0px"}
                    menuProps={menuIconProps}
                    className={style.copyIcon}
                    isDisabled={!code}
                    onClick={
                        !menuIconProps
                            ? () => {
                                  customCopyFunction ? customCopyFunction() : copyToClipBoard(code);
                              }
                            : undefined
                    }
                    isButton={code}
                />
            </div>
            <div className={style.codeArea}>
                <div className={`${style.code} ${style.integrationBox}`}>
                    {detectFormat === "json" ? (
                        <ReactJsonViewCompare
                            oldData={JSON.parse(code)}
                            newData={JSON.parse(code)}
                        />
                    ) : (
                        <SyntaxHighlighter
                            language={detectFormat}
                            wrapLongLines={true}
                            lineProps={{ style: { wordBreak: "break-all", whiteSpace: "pre-wrap" } }}
                            showLineNumbers={true}
                            startingLineNumber={true}
                        >
                            {formatCode(code)}
                        </SyntaxHighlighter>
                    )}
                </div>
            </div>
        </div>
    );
};
