import { borders } from "./romeu/borders";
import { colors } from "./romeu/colors";
import { fonts } from "./romeu/fonts";
import { gaps } from "./romeu/gaps";
import { margins } from "./romeu/margins";
import { paddings } from "./romeu/paddings";
import { widths } from "./romeu/widths";
import { heights } from "./romeu/heights";

export const romeuTheme = {
    id: "ROMEU-THEME",
    name: "Romeu default theme",
    colors: colors,
    margins: margins,
    gaps: gaps,
    fonts: fonts,
    paddings: paddings,
    widths: widths,
    heights: heights,
    borders: borders,
};
