import { useEffect } from "react";
import { fetchTransportUnits } from "../../../../../../redux/actions/catalog/catalog";
import { fetchMasterAppointmentDatePermissionList } from "../../../../../../redux/actions/masterAppointment/appointmentDatePermission/appointmentDatePermission";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterAppointmentDatePermissionColumns } from "./column/column";
export const ManagementAppointmentdatePermissionList = () => {
    const dispatch = useAppDispatch();

    const {
        masterAppointmentDatePermissionList: { loading, list },
        catalog: {
            transportUnits: { data: transportUnitsData },
        },
    } = useAppSelector((state) => state);

    useEffect(() => {
        transportUnitsData.length === 0 && dispatch(fetchTransportUnits());
    }, [transportUnitsData]);

    const onRenderColumns = () => {
        return MasterAppointmentDatePermissionColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterAppointmentDatePermissionList());
    }, [list]);

    return loading ? (
        <Skeleton rows={3} />
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={onRenderColumns}
            height={"calc(100vh - 8.73rem)"}
        />
    );
};
