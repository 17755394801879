import { useTheme } from "react-jss";
import { ConfigOrganismWithCertificateFormStyle } from "./formStyle.jss";
import { useTranslation } from "react-i18next";
import { BooleanSelector, CertificateSelector, OrganismSelector } from "../../../../request/common/selectors";
import { useForm } from "react-hook-form";
import { IConfigOrganismCertificateType } from "../../../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../utils";
import { updateConfigOrganismCertificateTypeProperty } from "../../../../../redux/reducers/resources/organismCertificatedType/form";
import { IDropdownOption } from "@fluentui/react";
export const ConfigOrganismWithCertificateForm = () => {
    const theme = useTheme();
    const style = ConfigOrganismWithCertificateFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONFIG.ORGANISMCERTIFICATETYPE" });
    const { control } = useForm<IConfigOrganismCertificateType>({ mode: "onChange" });
    const { form } = useAppSelector((state) => state.configOrganismCertificateTypeForm);
    const dispatch = useAppDispatch();
    const onUpdateProperty = (name: string, value: any) => {
        dispatch(updateConfigOrganismCertificateTypeProperty({ name, value }));
    };

    return (
        <div className={style.form}>
            <div className={style.row}>
                <OrganismSelector
                    control={control}
                    onChange={(option: any, name: string) => {
                        onUpdateProperty(name, option.key);
                    }}
                    selectedKey={form.organismId}
                    propertyName={compileNameOfProperty<IConfigOrganismCertificateType>("organismId")}
                    title={t("ORGANISM")}
                    isRequired={true}
                ></OrganismSelector>

                <CertificateSelector
                    control={control}
                    onChange={(option: any, name: string) => {
                        onUpdateProperty(name, option.key);
                    }}
                    selectedKey={form.certificateTypeId}
                    propertyName={compileNameOfProperty<IConfigOrganismCertificateType>("certificateTypeId")}
                    title={t("CERTIFICATE_TYPE")}
                    isRequired={true}
                />
            </div>

            <div className={style.row}>
                <BooleanSelector
                    onChange={(option: IDropdownOption, name: string) => {
                        onUpdateProperty(name, !!+option.key);
                    }}
                    selectedKey={+form.automaticExpedient}
                    propertyName={compileNameOfProperty<IConfigOrganismCertificateType>("automaticExpedient")}
                    title={t("AUTOMATIC_EXPEDIENT")}
                    control={control}
                    isRequired={true}
                ></BooleanSelector>

                <BooleanSelector
                    onChange={(option: IDropdownOption, name: string) => {
                        onUpdateProperty(name, !!+option.key);
                    }}
                    selectedKey={+form.automaticCertificateNumber}
                    propertyName={compileNameOfProperty<IConfigOrganismCertificateType>("automaticCertificateNumber")}
                    title={t("AUTOMATIC_CERTIFICATE_NUMBER")}
                    control={control}
                    isRequired={true}
                ></BooleanSelector>
            </div>
        </div>
    );
};
