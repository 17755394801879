import { DirectionalHint, TeachingBubble } from "@fluentui/react";
import { stylesBuble } from "./initTeachingBubbleStyles";

interface IProps {
    children: React.ReactNode;
    id: string;
    onToggle: () => void;
}

export const TeachingBubbleComponent = (props: IProps) => {
    return (
        <>
            <TeachingBubble
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                target={`#${props.id}`}
                onDismiss={props.onToggle}
                styles={stylesBuble}
            >
                {props.children}
            </TeachingBubble>
        </>
    );
};
