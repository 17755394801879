import { useTheme } from "react-jss";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IDailyChargeStatistics } from "../../../../../models/statistics/IDailyCharge";
import { convertToLocal } from "../../../../../utils";
import { generateRandomKeys } from "../../../../../utils/keys";
import { StatisticsDailyChargeListInspectorItem } from "../inspector/inspector";
import { StatisticsDailyChargeListDateItemtStyle } from "./dateStyle.jss";

interface IStatisticsDailyChargeListDateItem {
    date: IDailyChargeStatistics;
}
export const StatisticsDailyChargeListDateItem: React.FC<IStatisticsDailyChargeListDateItem> = ({ date }) => {
    const theme = useTheme();
    const style = StatisticsDailyChargeListDateItemtStyle({ theme });
    return (
        <>
            <div className={style.date}>{convertToLocal(date.date, FormatDate.DATE)}</div>

            {date.dailyChargeByDateStatistic.map((item, index) => {
                return (
                    <StatisticsDailyChargeListInspectorItem
                        key={generateRandomKeys.toString()}
                        inspector={item}
                        index={index}
                    />
                );
            })}
        </>
    );
};
