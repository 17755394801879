import { IDropdownOption } from "@fluentui/react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../models";
import { fetchInvoiceConceptsAction } from "../../../../../redux/actions/management/tariff/tariff";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateTariffConditionForm } from "../../../../../redux/reducers/management/tariff/form";
import { mapIDescriptionToDropDownOptions, mapInvoiceConceptsTariff } from "../../../../../utils";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils/formUtils";
import { FormNumberField } from "../../../../common/numberField/numberField";
import { ControlledRadioButtonGroup } from "../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { ControlledDropdown } from "../../../../common/selectors";
import { ManagementTariffFormStyle } from "./formStyle.jss";
import { ITariffCondition } from "../../../../../models/management/tariff/ITariffData";
import { FormatNumberInput } from "../../../../common/numberField/utils";

interface IManagementTariffFormProps {
    isValidForm: (value: any) => any;
}

export const ManagementTariffForm: React.FC<IManagementTariffFormProps> = ({ isValidForm }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.TARIFF.FORM" });
    const theme = useTheme();
    const style = ManagementTariffFormStyle({ theme });
    const { automatic, requestStateId, unitPrice, conceptId, conceptCode } = useAppSelector((store) => store.managementTariffForm.form);
    const invoiceConcepts = useAppSelector((store) => store.catalog.invoiceConcepts);
    const requestStates = useAppSelector((store) => store.catalog.requestStates);

    const {
        register,
        control,
        formState: { isValid },
        trigger,
        setValue,
    } = useForm<ITariffCondition>({ mode: "onChange" });

    useEffect(() => {
        !invoiceConcepts.data.length && !invoiceConcepts.loading && dispatch(fetchInvoiceConceptsAction());
        requestStates.data === undefined  && dispatch(fetchRequestStates());
        if (!conceptCode) {
            const concept = invoiceConcepts.data.find((item) => item.conceptId === conceptId);
            updateTariffProperty(compileNameOfProperty<ITariffCondition>("conceptCode"), concept?.conceptCode);
            updateTariffProperty(compileNameOfProperty<ITariffCondition>("conceptDescription"), concept?.conceptDescription);
        }
    }, [dispatch, invoiceConcepts.data, requestStates.data]);

    useEffect(() => {
        isValidForm(isValid);
    }, [dispatch, isValid]);

    const updateTariffProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateTariffConditionForm(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updateTariffProperty(name, FormatNumberInput(value));
        },
        [dispatch],
    );

    const onRadioButtonChange = useCallback(
        (optionKey: string, propertyName: string) => {
            updateTariffProperty(propertyName, !!+optionKey);
            if (!!+optionKey === false) {
                updateTariffProperty(compileNameOfProperty<ITariffCondition>("requestStateId"), null);
                setValue(compileNameOfProperty<ITariffCondition>("requestStateId"), undefined);
            }
            trigger("requestStateId", { shouldFocus: true });
            trigger("conceptId", { shouldFocus: true });
            trigger("unitPrice", { shouldFocus: true });
        },
        [dispatch],
    );

    const customOnChangeInvoiceConcept = (_event: React.FormEvent<any>, option?: IDropdownOption, _index?: number) => {
        const concept = invoiceConcepts.data.find((item) => item.conceptId === option?.key);
        updateTariffProperty(compileNameOfProperty<ITariffCondition>("conceptId"), option?.key);
        updateTariffProperty(compileNameOfProperty<ITariffCondition>("conceptCode"), concept?.conceptCode);
        updateTariffProperty(compileNameOfProperty<ITariffCondition>("conceptDescription"), concept?.conceptDescription);
    };

    const customOnChangeRequestState = (_event: React.FormEvent<any>, option?: IDropdownOption, _index?: number) => {
        updateTariffProperty(compileNameOfProperty<ITariffCondition>("requestStateId"), option?.key);
    };

    return (
        <div className={style.containerForm}>
            <div className={style.rowForm}>
                <ControlledDropdown
                    required={true}
                    options={mapInvoiceConceptsTariff(invoiceConcepts.data)}
                    label={t("INVOICECONCEPT")}
                    control={control}
                    name={compileNameOfProperty<ITariffCondition>("conceptId")}
                    onChange={customOnChangeInvoiceConcept}
                    selectedKey={conceptId}
                    rules={{ required: true }}
                />

                <FormNumberField
                    value={String(unitPrice)}
                    label={t("IMPORT")}
                    {...register(compileNameOfProperty<ITariffCondition>("unitPrice"), {
                        onChange: onInputChange,
                        value: String(unitPrice),
                        required: true,
                    })}
                    isRequired={true}
                    required={true}
                    decimalNumber={2}
                />
            </div>

            <div className={style.rowForm}>
                <div className={style.automateInput}>
                    <ControlledRadioButtonGroup
                        label={t("CREATEAUTO")}
                        selectedKey={automatic ? "1" : "0"}
                        onRadioButtonChange={onRadioButtonChange}
                        control={control}
                        name={compileNameOfProperty<ITariffCondition>("automatic")}
                    />
                </div>

                <ControlledDropdown
                    options={mapIDescriptionToDropDownOptions(requestStates.data ?? [])}
                    label={t("CHANGE")}
                    control={control}
                    name={compileNameOfProperty<ITariffCondition>("requestStateId")}
                    onChange={customOnChangeRequestState}
                    selectedKey={requestStateId}
                    rules={{ required: automatic }}
                    required={automatic}
                    disabled={!automatic}
                />
            </div>
        </div>
    );
};
