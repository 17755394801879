import { IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import OrganicProduct from "../../../../../assets/icons/eu-organic-logo.png";
import Export from "../../../../../assets/icons/export.svg";
import Fumigated from "../../../../../assets/icons/fumigated.png";
import Halal from "../../../../../assets/icons/halal.png";
import Information from "../../../../../assets/icons/information.svg";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { routeFeatures } from "../../../../../common/constants/routeFeatures";
import { RoleType } from "../../../../../common/enum";
import { IRole } from "../../../../../models/common/IRole";
import { IRequestDetail, IRequestDetailWithIndex } from "../../../../../models/requests/IRequestDetail";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { fetchRequestListAction, patchRequestState } from "../../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getUserRole } from "../../../../../redux/selectors/user/role/role";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty, mapIDescriptionToDropdownOption } from "../../../../../utils";
import { convertToLocal } from "../../../../../utils/dateUtils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { Checkboxs } from "../../../../common/checkbox/checkbox";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Selector } from "../../../../common/selectors/selector";
import { Letters, RequestInspections, RequestOrganisms } from "../../../common/columns/utils/requestPartials/requestListPartials";
import { RequestLastActuation } from "../../../common/columns/components/lastActuationColumn";
import { ExpedientsColumnComponent } from "../../../common/columns/components/expedientsColumn";
import { DeleteDraft } from "../../../common/columns/utils/deleteDraft";
import { RequestSchema } from "../../../common/columns/utils/schema";
import { EditDraft } from "../../../common/columns/utils/editDraft";
import { RequestListColumnsStyle } from "../../../common/columns/requestListColumnsStyle.jss";
import { setShowAllinspections, updateAllChangeDate, updateChangeDate } from "../../../../../redux/reducers/request/list/requestlist";
import { updateSortColumn } from "../../../../../redux/reducers/request/draft/draftlist";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const DraftColumns = (featureId: string): IColumn[] => {
    const { t } = useTranslation();
    const theme = useTheme();
    const styles = RequestListColumnsStyle({ theme });
    const dispatch = useAppDispatch();
    const [isIndeterminate, setIndeterminate] = useState<boolean>(false);
    const [isChecked, setChecked] = useState<boolean>(false);
    const userRole: IRole = useSelector(getUserRole);
    const requestStates = useAppSelector((store) => store.catalog.requestStates.data);
    const { sort, showAllInspections } = useAppSelector((state) => state.draftlist);

    useEffect(() => {
        requestStates === undefined && dispatch(fetchRequestStates());
    }, [dispatch, requestStates]);

    const onUpdateSortedColumn = useCallback(
        (column: string[]) => () => {
            const { sort } = store.getState().draftlist;
            let payload = onUpdateSortedColumnUtil(column, sort);
            dispatch(updateSortColumn(payload));
            dispatch(fetchRequestListAction(1));
        },
        [dispatch],
    );

    const onUpdateAllChangeDate = useCallback(
        () => () => {
            dispatch(updateAllChangeDate(!isChecked));
            checksBoxItems();
        },
        [dispatch, isChecked],
    );

    const onUpdateChangeDate = useCallback(
        (item: IRequestDetailWithIndex, index) => (_event: any) => {
            const payload: IRequestDetailWithIndex = {
                index,
                changeDate: !item.changeDate,
            };
            dispatch(updateChangeDate(payload));
            checksBoxItems();
        },
        [dispatch],
    );

    const checksBoxItems = () => {
        const { list } = store.getState().requestlist;
        const requestSelected = list.filter((item) => item.changeDate === true).length;
        if (requestSelected === 0) {
            setIndeterminate(false);
            setChecked(false);
        }

        if (requestSelected > 0 && requestSelected < list.length) {
            setIndeterminate(true);
            setChecked(false);
        }

        if (requestSelected === list.length) {
            setIndeterminate(false);
            setChecked(true);
        }
    };

    const onStateChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        const index: number = +(event.target as HTMLDivElement).id;
        const stateId: number = +option.key;
        const request = store.getState().requestlist.list[index];

        if (request && request.stateId && request.stateId !== stateId) {
            const requestId: number = +request.id!;
            dispatch(patchRequestState(requestId, stateId, index, request.stateId));
        }
    };

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IRequestDetail>("changeDate"),
            minWidth: 25,
            maxWidth: 25,
            name: "",
            onRenderHeader: () => (
                <div className={styles.selectAllRequest}>
                    <Checkboxs
                        isChecked={isChecked}
                        isIndeterminate={isIndeterminate}
                        handleChange={onUpdateAllChangeDate()}
                    />
                </div>
            ),
            onRender: (item: IRequestDetailWithIndex, index) => (
                <Checkboxs
                    isChecked={item.changeDate ? item.changeDate : false}
                    name={item.id}
                    handleChange={onUpdateChangeDate(item, index)}
                />
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("date"),
            minWidth: 65,
            maxWidth: 70,
            name: t("LIST.NAME.DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("date")}
                    title={t("LIST.NAME.DATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("date")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <span>{item.date ? convertToLocal(item.date.toString(), FormatDate.DATE) : null}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("sequentialSort"),
            minWidth: 25,
            maxWidth: 30,
            name: "",
            className: styles.secuencialSort,
            onRender: (_item: IRequestDetail, index) => {
                const { pagination } = store.getState().requestlist;
                return <>{pagination.firstItemNumber + Number(index)}</>;
            },
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("sort"),
            minWidth: 60,
            maxWidth: 90,
            name: t("LIST.NAME.SORT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("sort")}
                    title={t("LIST.NAME.SORT")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("sort")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <span>{item.sort}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitDescription"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.TRANSPORT_UNIT_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitDescription")}
                    title={t("LIST.NAME.TRANSPORT_UNIT_TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitDescription")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <span>{item.transportUnitDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitSizeCode"),
            minWidth: 45,
            maxWidth: 60,
            name: t("LIST.NAME.CONTAINER_SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitSizeCode")}
                    title={t("LIST.NAME.CONTAINER_SIZE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitSizeCode")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <span>{item.transportUnitSizeCode}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitCode"),
            minWidth: 30,
            maxWidth: 50,
            name: t("LIST.NAME.TRANSPORT_UNIT_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitNumber")}
                    title={t("LIST.NAME.TRANSPORT_UNIT_TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitNumber")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <span>{item.transportUnitCode}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("transportUnitNumber"),
            minWidth: 144,
            maxWidth: 250,
            name: t("LIST.NAME.TRANSPORT_UNIT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("transportUnitNumber")}
                    title={t("LIST.NAME.TRANSPORT_UNIT_NUMBER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("transportUnitNumber")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <>
                    <div className={styles.transportUnitNumberTitle}>{item.transportUnitNumber}</div>

                    <div className={styles.transportUnitInfo}>
                        {item.fumigated ? (
                            <TooltipHost content={t("LIST.NAME.FUMIGATED")}>
                                <img
                                    src={Fumigated}
                                    className={styles.iconImg}
                                    alt={Fumigated}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.export ? (
                            <TooltipHost content={t("LIST.NAME.EXPORT")}>
                                <img
                                    src={Export}
                                    className={styles.iconImg}
                                    alt={Export}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.remarks ? (
                            <TooltipHost content={item.remarks}>
                                <img
                                    src={Information}
                                    className={styles.iconImg}
                                    alt={Information}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.halalCertified ? (
                            <TooltipHost content={t("LIST.NAME.HALAL")}>
                                <img
                                    src={Halal}
                                    className={styles.iconImg}
                                    alt={Halal}
                                />
                            </TooltipHost>
                        ) : null}
                        {item.organicProduct ? (
                            <TooltipHost content={t("LIST.NAME.ORGANIC_PRODUCT")}>
                                <img
                                    src={OrganicProduct}
                                    className={styles.iconImg}
                                    alt={OrganicProduct}
                                />
                            </TooltipHost>
                        ) : null}
                        <Letters
                            message={t("LIST.NAME.ALERT")}
                            letter="(A)"
                            classStyle={styles.aLetter}
                            visible={item.alert ?? false}
                        />
                        <Letters
                            message={t("LIST.NAME.FORCED_DATE")}
                            letter="(D)"
                            classStyle={styles.dLetter}
                            visible={item.forcedDate ?? false}
                        />
                        <Letters
                            message={t("LIST.NAME.SUMMARY")}
                            letter="(V)"
                            classStyle={styles.vLetter}
                            visible={item.summaryActive ?? false}
                        />
                        <Letters
                            message={t("LIST.NAME.SUMMARY")}
                            letter="DSA"
                            classStyle={styles.dsaLetter}
                            visible={item.summaryActive ?? false}
                        />
                        <Letters
                            message={t("LIST.NAME.TURN_CODE")}
                            letter={`(${item.turnCode})`}
                            classStyle={styles.turnLetter}
                            visible={item.turnCode ? true : false}
                        />
                        <Letters
                            message={t("LIST.NAME.APP")}
                            letter="APP"
                            classStyle={styles.appLetter}
                            visible={item.app ?? false}
                        />
                    </div>
                </>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("description"),
            minWidth: 150,
            maxWidth: 350,
            name: t("LIST.NAME.DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("description")}
                    title={t("LIST.NAME.DESCRIPTION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("description")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.description ?? DefaultValues.EMPTY_LIST}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("valued"),
            minWidth: 80,
            maxWidth: 90,
            name: t("LIST.NAME.VALUED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("valued")}
                    title={t("LIST.NAME.VALUED")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("valued")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div> {item.valued && t(`LIST.NAME.${item.valued}`)}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("customerName"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.CUSTOMER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("customerName")}
                    title={t("LIST.NAME.CUSTOMER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("customerName")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.customerName}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("stateId"),
            minWidth: 150,
            maxWidth: 180,
            name: t("LIST.NAME.STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("stateId")}
                    title={t("LIST.NAME.STATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("stateId")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail, index) =>
                requestStates?.find((obj) => obj.id === item.stateId && !obj.internal && !obj.last) ? (
                    <>
                        <Selector
                            id={index?.toString()}
                            name=""
                            title=""
                            options={requestStates ? requestStates?.filter((obj) => !obj.internal).map(mapIDescriptionToDropdownOption) : []}
                            selectedKey={item.stateId}
                            onChange={onStateChange}
                            className={styles.stateSelector}
                        />
                    </>
                ) : (
                    <>
                        <div>{requestStates?.find((obj) => obj.id === item.stateId)?.description}</div>
                    </>
                ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("stateDescription"),
            minWidth: 70,
            maxWidth: 150,
            name: t("LIST.NAME.STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("stateDescription")}
                    title={t("LIST.NAME.STATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("stateDescription")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.stateDescription}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("customerReference"),
            minWidth: 90,
            maxWidth: 150,
            name: t("LIST.NAME.CUSTOMER_REFERENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("customerReference")}
                    title={t("LIST.NAME.CUSTOMER_REFERENCE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("customerReference")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.customerReference}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockDescription"),
            minWidth: 100,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockDescription")}
                    title={t("LIST.NAME.DOCK")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockDescription")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.dockDescription}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("merchandiseTypeCode"),
            minWidth: 95,
            maxWidth: 150,
            name: t("LIST.NAME.MERCHANDISE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("merchandiseTypeCode")}
                    title={t("LIST.NAME.MERCHANDISE_TYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("merchandiseTypeCode")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.merchandiseTypeCode}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("inspectionPresence"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.INSPECTION_PRESENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("inspectionPresence")}
                    title={t("LIST.NAME.INSPECTION_PRESENCE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("inspectionPresence")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.inspectionPresence}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("representativePresenceTypeDescription"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.REPRESENTATIVE_PRESENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("representativePresenceTypeDescription")}
                    title={t("LIST.NAME.REPRESENTATIVE_PRESENCE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("representativePresenceTypeDescription")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.representativePresenceTypeDescription}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockAssignmentOn"),
            minWidth: 80,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK_ASSIGNMENT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockAssignmentOn")}
                    title={t("LIST.NAME.DOCK_ASSIGNMENT")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockAssignmentOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div>{item.dockAssignmentOn ? convertToLocal(item.dockAssignmentOn.toString(), FormatDate.HOUR_MINUTE) : null}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("entryOn"),
            minWidth: 55,
            maxWidth: 100,
            name: t("LIST.NAME.ENTRY_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("entryOn")}
                    title={t("LIST.NAME.ENTRY_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("entryOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.entryOn ? convertToLocal(item.entryOn.toString(), FormatDate.HOUR_MINUTE) : null}</div>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockStartOn"),
            minWidth: 75,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK_START_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockStartOn")}
                    title={t("LIST.NAME.DOCK_START_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockStartOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div>{item.dockStartOn ? convertToLocal(item.dockStartOn.toString(), FormatDate.HOUR_MINUTE) : null}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("dockEndOn"),
            minWidth: 73,
            maxWidth: 150,
            name: t("LIST.NAME.DOCK_END_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("dockEndOn")}
                    title={t("LIST.NAME.DOCK_END_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("dockEndOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <div>{item.dockEndOn ? convertToLocal(item.dockEndOn.toString(), FormatDate.HOUR_MINUTE) : null}</div>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("exitOn"),
            minWidth: 55,
            maxWidth: 150,
            name: t("LIST.NAME.EXIT_ON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("exitOn")}
                    title={t("LIST.NAME.EXIT_ON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("exitOn")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.exitOn ? convertToLocal(item.exitOn.toString(), FormatDate.HOUR_MINUTE) : null}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("documentsNumber"),
            minWidth: 20,
            maxWidth: 30,
            name: t("LIST.NAME.DOCUMENTS_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("documentsNumber")}
                    iconTitle={
                        <CustomIcons
                            size={"1.10rem"}
                            disabledSelect={true}
                            iconName={"Documentation"}
                            margin={"0px"}
                        ></CustomIcons>
                    }
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("documentsNumber")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div className={styles.documentsNumber}>{item.documentsNumber}</div>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("organisms"),
            minWidth: 65,
            maxWidth: 85,
            name: t("LIST.NAME.ORGANISMS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("organisms")}
                    title={t("LIST.NAME.ORGANISMS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("organisms")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <RequestOrganisms requestOrganisms={item.organisms} />,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("lastActuation"),
            minWidth: 50,
            maxWidth: 150,
            name: t("LIST.NAME.LAST_ACTUATION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("lastActuation")}
                    title={t("LIST.NAME.LAST_ACTUATION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("lastActuation")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <>
                    <RequestLastActuation lastActuation={item.lastActuation} />
                </>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("expedients"),
            minWidth: 300,
            maxWidth: 400,
            name: t("LIST.NAME.EXPEDIENTS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("expedients")}
                    title={t("LIST.NAME.EXPEDIENTS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("expedients")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <>
                    <ExpedientsColumnComponent
                        requestExpedient={item.expedients}
                        requestId={Number.parseInt(item.id || "")}
                        styles={styles}
                    />
                </>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("appointmentTimeZone"),
            minWidth: 85,
            maxWidth: 150,
            name: t("LIST.NAME.APPOINTMENT_TIME_ZONE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("appointmentTimeZone")}
                    title={t("LIST.NAME.APPOINTMENT_TIME_ZONE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("appointmentTimeZone")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => <div>{item.appointmentTimeZone}</div>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IRequestDetail>("inspections"),
            minWidth: 230,
            maxWidth: 300,
            name: t("LIST.NAME.INSPECTIONS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IRequestDetail>("inspections")}
                    iconTitle={
                        <CustomIcons
                            margin={"10px"}
                            iconName={showAllInspections ? "Remove" : "Add"}
                            onClick={() => {
                                dispatch(setShowAllinspections(!showAllInspections));
                            }}
                        ></CustomIcons>
                    }
                    title={t("LIST.NAME.INSPECTIONS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn([compileNameOfProperty<IRequestDetail>("inspections")])}
                    routeFeature={routeFeatures.REQUEST}
                    sort={sort}
                />
            ),
            onRender: (item: IRequestDetail) => (
                <>
                    <RequestInspections
                        requestInspections={item.inspections}
                        showActuationGroup={userRole.id === RoleType.CUSTOMER_ADMIN || userRole.id === RoleType.CUSTOMER_USER}
                        styles={styles}
                    />
                </>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IRequestDetail>("edit" && "delete"),
            minWidth: 30,
            maxWidth: 50,
            name: "",
            onRender: (item: IRequestDetail) => {
                return (
                    <div className={styles.groupActions}>
                        <EditDraft groupId={item.groupId || ""} />
                        <DeleteDraft groupId={item.groupId || ""} />
                    </div>
                );
            },
            isResizable: false,
        },
    ];

    return RequestSchema(columns, featureId);
};
