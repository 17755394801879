import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterCustomsStatesForm, setMasterCustomsStatesForm } from "../../../../reducers/resources/master/customsStates/form";
import { setLoadingMasterCustomsStatesList, setMasterCustomsStatesList } from "../../../../reducers/resources/master/customsStates/list";
import { AppThunk, store } from "../../../../store";

const catalogApi = new CatalogAPI();

export const fetchMasterCustomsStatesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterCustomsStatesList(true));
        const response = await catalogApi.getMasterCustomsStatesList();
        dispatch(setMasterCustomsStatesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterCustomsStatesList(false));
    }
};

export const fetchMasterCustomStates =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterCustomsStatesForm(true));
            const response = await catalogApi.getMasterCustomsState(id);
            dispatch(setMasterCustomsStatesForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterCustomsStatesForm(false));
        }
    };

export const postAndPutMasterCustomsStates = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterCustomsStatesForm;
            dispatch(setLoadingMasterCustomsStatesForm(true));
            if (form.id) {
                await catalogApi.putMasterCustomsStates(form);
            } else {
                await catalogApi.postMasterCustomsStates(form);
            }
            dispatch(fetchMasterCustomsStatesList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterCustomsStatesForm(false));
            return false;
        } 
    };
};

export const patchSortCustomsStatesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterCustomsStatesList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterCustomsStatesList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterCustomsStatesList(false));
        }
    };
