import { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { Label } from "../labels/label";
import { TextInputStyled } from "./textInput.jss";

interface INumericInputProps {
    propertyName: string;
    label: string;
    value: number | undefined;
    onChange: (value: number | undefined, name: string) => void;
    disabled?: boolean;
    className?: string;
    max?: number;
    min?: number;
    onEnter?: () => void;
}

export const NumericInput: React.FC<INumericInputProps> = ({ propertyName, label, value, onChange, disabled, className, max, min, onEnter }) => {
    const theme = useTheme() as any;
    const styles = TextInputStyled({ theme });
    const [numericKey, setNumericKey] = useState<number>(0);
    const filled = value ? "filled" : "";

    useEffect(() => {
        value === undefined && setNumericKey((key) => key + 1);
    }, [value]);

    const isValid = (numberValue: number): boolean => {
        if (min && numberValue < min) {
            return false;
        }
        if (max && numberValue > max) {
            return false;
        }

        return true;
    };

    const handleChange = (event: any, _newValue?: string) => {
        if (event.target.value) {
            const numberValue = Number(event.target.value);
            if (numberValue <= 0) {
                onChange(undefined, propertyName);
                setNumericKey((key) => key + 1);
            }
            if (isValid(numberValue)) {
                onChange(numberValue, propertyName);
            }
        } else {
            onChange(undefined, propertyName);
            setNumericKey((key) => key + 1);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            onEnter && onEnter();
        }
    };

    return (
        <div className={styles.input}>
            <input
                autoComplete={"none"}
                key={`${propertyName}-${numericKey}`}
                name={propertyName}
                value={!!value ? String(value) : ""}
                type="number"
                onChange={handleChange}
                disabled={disabled}
                className={className}
                onKeyDown={handleKeyDown}
            />
            {label && (
                <Label
                    title={label}
                    className={filled}
                />
            )}
        </div>
    );
};
