import { useTranslation } from "react-i18next";
import { IGenericPayload } from "../../../models";
import { exportStatisticsIncidenceOperatorAction, fetchStatiticsIncidenceOperatorFilterAction } from "../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetOperatorIncidenceFilter, updateFilterProperty } from "../../../redux/reducers/statistics/incidenceOperator/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsIncidenceOperatorList } from "./list/list";

export const StatisticsIncidenceOperatorContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INCIDENCE.OPERATOR" });
    const { filter: filter } = useAppSelector((state) => state.statisticsOperatorIncidenceFilter);

    const dispatch = useAppDispatch();
    const onExportDataStaticsIncidenceOperator = () => {
        return dispatch(exportStatisticsIncidenceOperatorAction());
    };
  
    const updateHeaderPropierty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };
    const onApplyFilters = () => {
        return dispatch(fetchStatiticsIncidenceOperatorFilterAction());
    };
    const onResetFilters = () => {
        dispatch(resetOperatorIncidenceFilter());
        onApplyFilters();
    };

    return (
        <div>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={<ExportDataIcon onExportCSV={onExportDataStaticsIncidenceOperator} />}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderPropierty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <StatisticsIncidenceOperatorList />
        </div>
    );
};
