import { FormatDate } from "../common/enum/dateTime/FormatDate";
import { IChartSeries } from "../models/common/IChartOptions";
import { convertToLocal } from "./dateUtils";

export const mapCategoriesRequestGraph = <T>(data: Array<T & any>, key: keyof T, additional?: string): string[] => {
    let categories: string[] = [];
    if (data) {
        data.map((item) =>
            categories.indexOf(convertToLocal(item[key], FormatDate.DATE)) === -1
                ? categories.push(convertToLocal(item[key], FormatDate.DATE))
                : null,
        );
    }

    if (additional) {
        categories.push(additional);
    }
    return categories;
};

export const mapSeriesRequestGraph = <T>(
    data: Array<T & any>,
    key: keyof T,
    description: keyof T,
    additional?: { description: string; data: number },
): IChartSeries[] => {
    let series: IChartSeries[] = [];
    data.map((item) =>
        series.filter((serie) => serie.name === item[description]).length === 0
            ? series.push({ data: [Number.parseInt(item[key])], name: item[description] })
            : series.find((serie) => serie.name === item[description])?.data.push(Number.parseInt(item[key])),
    );
    if (additional && series.length > 0) {
        series.push({ name: additional.description, data: [...series[0].data.map(() => 0), additional.data] });
    }
    return series;
};
