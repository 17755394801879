import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterActuationGroupListContainer } from "./list/list";
import { setActuationsGroup } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterActuationGroupList } from "../../../../../redux/reducers/resources/master/actuationGroup/list";

export class MasterActuacionTypeGroupContainer extends React.Component {
   
    componentWillUnmount(): void {
        store.dispatch(resetMasterActuationGroupList());
        store.dispatch(setActuationsGroup([]));
    }

    render(): React.ReactNode {
        return (
            <MasterActuationGroupListContainer
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
