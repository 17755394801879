import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { IGenericPayload } from "../../../../../models";
import { IConfigMerchandiseCategoryListItem } from "../../../../../models/config/merchandiseCategory";
import { fetchConfigMerchandiseCategoryAction } from "../../../../../redux/actions/config/merchandiseCategory";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setConfigMerchandiseCategoryShowPopUp, updateFormProperty } from "../../../../../redux/reducers/config/merchandiseCategory/form";
import { updateSortConfigMerchandiseCategoryColumn } from "../../../../../redux/reducers/config/merchandiseCategory/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty, onUpdateSortedColumnUtil } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { MasterBlocksColumnsStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";



export const ConfigMerchandiseCategoryListColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterBlocksColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();
    const { sort } = useAppSelector((state) => state.configMerchandiseCategoryList);
    
    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().configMerchandiseCategoryList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortConfigMerchandiseCategoryColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseTypeDescription"),
            minWidth: 200,
            maxWidth: 300,
            name: compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseTypeDescription"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseTypeDescription")}
                    title={t("MERCHANDISE_TYPE")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseTypeDescription"))}
                />
            ),
            onRender: (item: IConfigMerchandiseCategoryListItem) => <span>{item.merchandiseTypeDescription}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IConfigMerchandiseCategoryListItem>("humanConsumption"),
            minWidth: 120,
            maxWidth: 200,
            name: t("HUMAN_CONSUMPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigMerchandiseCategoryListItem>("humanConsumption")}
                    title={t("HUMAN_CONSUMPTION")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigMerchandiseCategoryListItem>("humanConsumption"))}
                />
            ),
            onRender: (item: IConfigMerchandiseCategoryListItem) => <span> <BooleanValue value={item.humanConsumption}/> </span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseOriginDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("MERCHANDISE_ORIGIN"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseOriginDescription")}
                    title={t("MERCHANDISE_ORIGIN")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseOriginDescription"))}
                />
            ),
            onRender: (item: IConfigMerchandiseCategoryListItem) => <span>{item.merchandiseOriginDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseCategoryDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("MERCHANDISE_NATURE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseCategoryDescription")}
                    title={t("MERCHANDISE_NATURE")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigMerchandiseCategoryListItem>("merchandiseCategoryDescription"))}
                />
            ),
            onRender: (item: IConfigMerchandiseCategoryListItem) => <span>{item.merchandiseCategoryDescription}</span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 100,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IConfigMerchandiseCategoryListItem) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setConfigMerchandiseCategoryShowPopUp(PopUpCodes.UPDATE));
                            dispatch(fetchConfigMerchandiseCategoryAction(item.id));
                        }}
                    />
                    <Separator size="auto" />
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(setConfigMerchandiseCategoryShowPopUp(PopUpCodes.DELETE));
                            const payload: IGenericPayload = {
                                name: compileNameOfProperty<IConfigMerchandiseCategoryListItem>("id"),
                                value: item.id,
                            };
                            dispatch(updateFormProperty(payload));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
