import { useTheme } from "react-jss";
import { PopUp } from "../popUp";
import { Spinner } from "../../spinner/spinner";
import { PopUpFormButtonActions } from "./buttonActions/buttonActions";
import { PopUpFormStyle } from "./popUpFormStyle.jss";
import { useEffect, useState } from "react";
import { ColorType } from "../../../../common/enum/ColorType";

interface IPopUpFormProps {
    content: any;
    leftButtonContent?: any;
    underButtonContent?: any;
    width?: any;
    height?: any;
    title: string;
    titleColor?: ColorType;
    isDisabledButton?: boolean;
    isLoading?: boolean;
    isVisible: boolean;
    onCancel: (value: any) => any;
    onSave: (value: any) => any;
    isBlocking?: boolean;
    isHiddenButtons?: boolean;
    buttonSaveTitle?: string;
    buttonCancelTitle?: string;
    backGroundColor?: string;
    hiddenClose?: boolean;
    hiddenSave?: boolean;
    onlyHidden?: boolean;
    customActions?: any;
    onDimissed?: () => any;
}

export const PopUpForm: React.FC<IPopUpFormProps> = ({
    content,
    leftButtonContent,
    width,
    height,
    title,
    titleColor,
    isDisabledButton,
    isLoading,
    isVisible,
    onCancel,
    onSave,
    isBlocking,
    isHiddenButtons,
    buttonCancelTitle,
    buttonSaveTitle,
    hiddenClose,
    hiddenSave,
    onlyHidden,
    underButtonContent,
    backGroundColor,
    customActions,
    onDimissed,
}) => {
    const [widthPopUp, setWidthPopUp] = useState("");
    const [heightPopUp, setHeigthPopUp] = useState("");
    const [titlePopup, setTitlePopUp] = useState("");
    const [contentPopUp, setContentPopUp] = useState<any>();
    const theme = useTheme();
    const style = PopUpFormStyle({ theme, width: widthPopUp, height: heightPopUp });

    const underButtonsContent = () => {
        if (!underButtonContent) {
            return null;
        }
        return underButtonContent;
    };

    const childrenButtons = () => {
        if (isHiddenButtons) {
            return null;
        }
        return (
            <PopUpFormButtonActions
                customActions={customActions}
                onSave={onSave}
                onCancel={onCancel}
                leftButtonContents={leftButtonContent}
                disabledButton={isDisabledButton}
                buttonSaveTitle={buttonSaveTitle}
                buttonCancelTitle={buttonCancelTitle}
                isLoading={isLoading}
                hiddenCancel={hiddenClose || onlyHidden}
                hiddenSave={hiddenSave || onlyHidden}
            />
        );
    };
    const childrenContainer = () => {
        return (
            <div className={style.popUpContainer}>
                <div className={style.content}>{contentPopUp}</div>
                {childrenButtons()}
                {underButtonsContent()}
            </div>
        );
    };
    const loadingContainer = (
        <div className={style.popUpContainer}>
            <div className={style.content}>
                <Spinner />
            </div>
            {childrenButtons()}
            {underButtonsContent()}
        </div>
    );

    useEffect(() => {
        if (isVisible) {
            setWidthPopUp(width);
            setHeigthPopUp(height);
            setTitlePopUp(title);
            setContentPopUp(content);
        }
    }, [isVisible, height, width, title, content]);

    return (
        <PopUp
            children={isLoading ? loadingContainer : childrenContainer()}
            titleColor={titleColor ? titleColor : ColorType.black}
            title={titlePopup}
            state={isVisible}
            hideModal={onCancel}
            isBlocking={isBlocking !== undefined ? isBlocking : true}
            skipHeader={false}
            backGroundColor={backGroundColor}
            onDimissed={onDimissed}
        />
    );
};
