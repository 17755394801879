import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const SelectorStyled = createUseStyles((theme: any) => ({
    selector: {
        minWidth: 200,
        flexFlow: "column",
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },
    rootStyles: {
        borderBottom: `1.3px solid ${theme.colors.form.input.default}`,
        minWidth: "200px",
        fontSize: "13px",
        color: theme.colors.form.input.filled,
        fontFamily: "unset",

        "& .is-disabled ": {
            color: theme.colors.form.input.filled,
        },
        "& .ms-Dropdown-optionText": {
            fontFamily: theme.fonts.families.siteImportant,
        },
        "& .ms-Label": {
            fontFamily: theme.fonts.families.siteImportant,
        },
        "& .ms-Label::after": {
            color: theme.colors.palette.red,
        },
    },

    callout: {
        marginTop: theme.paddings.combo.wrapperoffset,
    },
    dropdown: {
        "&:hover  .ms-Dropdown-title, &:active  .ms-Dropdown-title,  &:focus  .ms-Dropdown-title": {
            color: theme.colors.form.input.filled,
        },
        "&:hover  .ms-Dropdown-caretDown, &:active  .ms-Dropdown-caretDown,  &:focus  .ms-Dropdown-caretDown": {
            color: theme.colors.form.input.filled,
        },
    },
    optionText: {
        fontSize: 13,
        color: theme.colors.navLinks.secondary.color,
        minHeight: 26,
        fontFamily: theme.fonts.families.siteImportant,
    },
    input: {
        color: theme.colors.form.input.filled,
    },

    calloutContainer: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        // width: "calc(100% - 40px)",
        justifyContent: "space-between",
        alignItems: "center",
        transform: "translateY(0)",
        zIndex: 99999,
        gap:8,

        "& i": {
            color: theme.colors.buttons.primary.background,
            cursor: "help",
        },
        "& label": {
            color: theme.colors.form.input.default,
            fontSize: theme.fonts.sizes.base,
            fontWeight: 100,
            transform: "translateY(0px)",
            fontFamily: theme.fonts.families.siteImportant,
        },
    },
    calloutContainerSelected: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
   //  width: "calc(100% - 40px)",
      gap:8,
        justifyContent: "space-between",
        alignItems: "center",
        transform: "translateY(-18px)",
        fontSize: theme.fonts.sizes.xs,
        "& i": {
            color: theme.colors.buttons.primary.background,
            cursor: "help",
        },
        "& label": {
            color: theme.colors.form.input.default,
            fontSize: theme.fonts.sizes.xs,
            fontWeight: 100,
            fontFamily: theme.fonts.families.siteImportant,
        },
    },
    calloutMessage: {
        padding: 16,
        fontSize: theme.fonts.sizes.xs,
        fontWeight: 100,
        fontFamily: theme.fonts.families.siteImportant,
        lineHeight: "1.6",
        whiteSpace: "pre-wrap",
    },
    selectorRequired: {
        color: theme.colors.palette.red,
    },
    calloutLabelContainer: {
        display: "flex",
        columnGap: 4,
        whiteSpace: "pre",
    },

    iconContainer: {
        display: "flex",
    },
    errorMessage: {
        fontFamily: theme.fonts.families.site,

        position: "absolute",
        fontSize: theme.fonts.sizes.xxs,
        color: theme.colors.form.input.invalid,
        paddingTop: 5,
        paddingBottom: 5,
    },

    selectorContainer: {
        position: "relative",
        width: "100%",
        fontFamily: theme.fonts.families.siteImportant,

        "& .ms-Dropdown-container": {
            borderBottom: theme.borders.selector.borderBottom,
        },
        "& .ms-Dropdown-caretDownWrapper": {
            display: theme.borders.selector.borderBottom,
        },
    },
    selectorContainerDisabled: {
        position: "relative",
        width: "100%",
        fontFamily: theme.fonts.families.siteImportant,

        "& .ms-Dropdown-container": {
            borderBottom: theme.borders.selector.disabledBorder,
        },
        "& .ms-Dropdown-caretDownWrapper": {
            display: "none",
        },
    },

    label: {
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.base,
        fontWeight: 100,
        position: "absolute",
        pointerEvents: "none",
        top: -15,
        transform: "translateY(14px)",
        transition: "all 0.2s ease-in-out",
        left: "0px",
        fontFamily: theme.fonts.families.siteImportant,
    },
    labelSelected: {
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.xs,
        fontWeight: "100",
        position: "absolute",
        pointerEvents: "none",
        top: -15,
        transform: "translateY(0px)",
        transition: "all 0.2s ease-in-out",
        left: "0px",
        fontFamily: theme.fonts.families.siteImportant,
    },
    inputText: {
        color: theme.colors.form.input.filled,
        fontSize: theme.fonts.sizes.base,
        minHeight: 26,
        backgroundColor: "transparent",
        fontFamily: theme.fonts.families.siteImportant,
        height: 30,
    },
    rootStylesFocus: {
        minWidth: 200,
        fontSize: 13,
        color: theme.colors.form.input.focus,
        fontFamily: theme.fonts.families.siteImportant,
    },
}));
