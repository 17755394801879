import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IStatisticsFilter } from "../../../../../models/statistics/IStatisticsFilters";
import { compileNameOfProperty } from "../../../../../utils";
import { MultipleDocumentTypesSelector } from "../../../../request/common/selectors/documentTypes/multipleDocumentTypes";
import { StatisticsInspectionPivotFilterStyle } from "./inspectionStyle";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    filter: any;
}

export const StatisticsInspectionPivotFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, filter }) => {
    const theme = useTheme();
    const styles = StatisticsInspectionPivotFilterStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.FILTER" });
    return (
        <div className={styles.requestInspectionContainer}>
            <div className={styles.filterItem}>
                <MultipleDocumentTypesSelector
                    selectedKeys={filter.documentTypeIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("documentTypeIds")}
                    title={t("DOCUMENT_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>
        </div>
    );
};
