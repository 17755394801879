import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const UserProfileUserFormStyle = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
        maxHeight: "calc(100vh - 14rem)",
        overflowY: "scroll",
        [mediaQueries.TABLET]: {
            height: "100%",
        },
    },
    section: {
        flexFlow: "row",
        display: "flex",
        [mediaQueries.TABLET]: {
            flexFlow: "column",
        },
    },
    infoContainer: {
        display: "flex",
        flexFlow: "column",

        [mediaQueries.TABLET]: {
            display: "block",
        },
    },
    item: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        [mediaQueries.TABLET]: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            rowGap: 20,
        },
    },
    form: {
        padding: "20px",
        minWidth: "30vw",
        display: "flex",
        flexDirection: "column",
        gap: 30,
        [mediaQueries.TABLET]: {
            padding: "0 20px 20px 20px",
        },
    },

    avatar: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
    },
    buttonsRow: {
        flexFlow: "column",
        display: "flex",
        justifyContent: "flex-start",
        columnGap: theme.paddings.default,
        padding: "20px",
        width: "100%",
        "& button": {
            width: 250,
            [mediaQueries.TABLET]: {
                width: "100%",
            },
        },
    },
}));
