import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const OrganizationSelectorStyled = createUseStyles((theme: any) => ({
   
    selector: {
        width: 300,
        flexFlow: "column",
        "& .is-disabled": {
            opacity: "1 !important",
        },
        "& .is-disabled .ms-Dropdown-caretDownWrapper": {
            display: "none",
        },
        "& .ms-Dropdown-title": {
            color: `${theme.colors.palette.black} !important`,
            fontSize: 20,
        },
        "& .ms-Dropdown-caretDownWrapper": {
            "& i ": {
                fontSize: 20,
            },
        },
        border: "none",
        [mediaQueries.MOBILE] : {
            width: 250,
            "& .ms-Dropdown-title": {
                color: `${theme.colors.palette.black} !important`,
                fontSize: 16,
            },
            "& .ms-Dropdown-caretDownWrapper": {
                "& i ": {
                    fontSize: 16,
                },
            },  
        }
    },
}));
