import { useTheme } from "react-jss";
import { NameIcons } from "../../../assets/icons";
import { CustomIcons } from "../customIcons/customIcons";
import { DownloadDataStyle } from "./downloadDataStyle.jss";

interface IDownloadDataIcon {
    title: string;
    onDownloadAction: () => void;
}

export const DownloadDataIcon: React.FC<IDownloadDataIcon> = ({ title, onDownloadAction }) => {
    const theme = useTheme();
    const style = DownloadDataStyle({ theme });

    return (
        <>
            <div
                className={style.exportBtn}
                onClick={onDownloadAction}
            >
                <CustomIcons
                    size={30}
                    iconName={NameIcons.DOWNLOAD}
                />

                {title}
            </div>
        </>
    );
};
