import { useTheme } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";
import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import { useMemo, useState } from "react";
import { CodeViewer } from "../../../../common/codeViewer/codeViewer";
import { useAppSelector } from "../../../../../redux/hooks";
import { useTranslation } from "react-i18next";

export const AppointmentHistoricForm = () => {
    const [selectedKey, setSelectedKey] = useState<string>("0");
    const { messaageData, response } = useAppSelector((state) => state.appointmetnHistoricForm.form);
    const theme = useTheme() as any;
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT_HISTORIC" });

    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
            root: {
                display: "flex",
                width: "100%",
            },
            itemContainer: {
                // width: "100%",
                paddingLeft: 8,
                height: "99%",
                "& > div": {
                    width: "100%",
                    height: "100%",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    return (
        <div style={{ width: "100%" }}>
            <Pivot
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("MESSAGE_DATA")}
                    itemKey={"0"}
                >
                    {
                        <CodeViewer
                            height="calc(70vh - 10rem)"
                            code={messaageData}
                        />
                    }
                </PivotItem>
              
                    <PivotItem
                        headerText={t("RESPONSE")}
                        itemKey={"1"}
                    >
                        {
                            <CodeViewer
                                height="calc(70vh - 10rem)"
                                code={response}
                            />
                        }
                    </PivotItem>
            </Pivot>
        </div>
    );
};
