import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../assets/icons";
import { PopUpConfirmationType } from "../../../../../../../../common/enum/PopUpConfirmationType";
import { IConfigEditRequest } from "../../../../../../../../models/requests/IConfigEditRequest";
import { IRequestImage } from "../../../../../../../../models/requests/IRequestDocument";
import { deleteImageRequest, fetchRequesImagesAction } from "../../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { widths } from "../../../../../../../../styles/romeu/widths";
import { convertToLocal } from "../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../common/customIcons/customIcons";
import { PopUpConfirmation } from "../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { Separator } from "../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../common/tableCustom/tableCustom";
import { useTheme } from "react-jss";
import { ImageListStyle } from "./listStyle.jss";
import { DefaultValues } from "../../../../../../../../common/enum/DefaultValues";
import { setPopUpRequestImages } from "../../../../../../../../redux/reducers/request/edit/editRequest";
import { PopUpCodes } from "../../../../../../../../common/enum/PopUpCodes";
import { ImageSlider } from "../../../../../../../common/imageSlider/imageSlider";
import { TooltipDelay, TooltipHost } from "@fluentui/react";
import { ContainerImageSlider } from "../../../../../../../common/imageSlider/container/container";
interface IEditRequestImageListProps {
    schema: IConfigEditRequest;
    requestId: number;
}

export const EditRequestImageList: React.FC<IEditRequestImageListProps> = ({ schema, requestId }) => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const { images, loadingImages, imagePopUp } = useAppSelector((store) => store.editRequest.requestDocuments);
    const [deletionPhotoId, setDeletionPhotoId] = useState<number>(DefaultValues.DEFAULT_ID);
    const [showPopUpImageSlider, setShowPopUpImageSlider] = useState<boolean>(false);
    const [imageIndex, setImageIndex] = useState<number>(0);

    const theme = useTheme();
    const style = ImageListStyle({ theme });

    const setFormImage = (index: number) => {
        setImageIndex(index);
        setShowPopUpImageSlider(true);
    };

    const onDeleteImageItem = (id: number) => {
        setDeletionPhotoId(id);
        dispatch(setPopUpRequestImages(PopUpCodes.DELETE));
    };
    const onDownloadImage = (uri: string) => {
        window.open(uri, "_blank");
    };

    const onAcceptDeleteImage = () => {
        if (deletionPhotoId > DefaultValues.DEFAULT_ID) {
            dispatch(deleteImageRequest(requestId, deletionPhotoId));
            onCancelDeleteImage();
        }
    };

    const columns: ColumnType<IRequestImage, keyof IRequestImage>[] = [
        { key: "contentImage", title: t("EDIT_REQUEST.FIELDS.PHOTONAME"), width: "65%" },
        { key: "createdBy", title: t("EDIT_REQUEST.FIELDS.CREATEDBY"), width: "20%" },
        { key: "createdOn", title: t("EDIT_REQUEST.FIELDS.CREATEDON"), width: "20%" },
        { key: "actions", title: "", width: "20%" },
    ];

    const list = useMemo(() => {
        return images ?? [];
    }, [images]);

    const newImageList = list.map((item, index) => {
        return {
            ...item,
            fileName: null,
            contentImage: (
                <span className={style.container}>
                    <img
                        onClick={() => setFormImage(index)}
                        className={style.image}
                        src={item.uri}
                    ></img>
                </span>
            ),
            createdBy: (
                <div className={style.createdByEmail}>
                    <TooltipHost
                        content={item.createdByMail}
                        delay={TooltipDelay.zero}
                    >
                        {item.createdBy}
                    </TooltipHost>
                </div>
            ),
            type: DefaultValues.EMPTY,
            createdOn: convertToLocal(item.createdOn),
            actions: (
                <CustomTableActions
                    children={
                        <div>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.EYEICON}
                                onClick={() => setFormImage(index)}
                            />
                            <Separator></Separator>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.DOWNLOAD}
                                onClick={() => onDownloadImage(item.uri)}
                            />

                            {schema.image_delete.visible && (
                                <>
                                    <Separator></Separator>
                                    <CustomIcons
                                        isButton={true}
                                        iconName={NameIcons.REMOVE}
                                        onClick={() => onDeleteImageItem(item.id)}
                                    />
                                </>
                            )}
                        </div>
                    }
                />
            ),
        };
    });

    const onCancelDeleteImage = () => {
        dispatch(setPopUpRequestImages(PopUpCodes.NONE));
    };

    useEffect(() => {
        if (images === undefined && requestId !== undefined) {
            dispatch(fetchRequesImagesAction(requestId));
        }
    }, [images, requestId]);

    return (
        <>
            <CustomTableList
                data={newImageList}
                columns={columns}
                loading={loadingImages}
            />

            <PopUpConfirmation
                showPopUp={imagePopUp === PopUpCodes.DELETE}
                onCancel={onCancelDeleteImage}
                onAccept={onAcceptDeleteImage}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
            />

            <ContainerImageSlider
                images={
                    images?.map((item) => {
                        return item.uri;
                    }) ?? []
                }
                imageIndex={imageIndex}
                setShowImageSlider={setShowPopUpImageSlider}
                showImageSlider={showPopUpImageSlider}
            />
        </>
    );
};
