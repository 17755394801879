import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../assets/icons";

import { IConfigEditRequest } from "../../../../../../../../models/requests/IConfigEditRequest";
import { IRequestDocument } from "../../../../../../../../models/requests/IRequestDocument";
import { fetchDocumentTypes } from "../../../../../../../../redux/actions/catalog/catalog";
import { deleteDocumentRequest, fetchRequesDocumentAction } from "../../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { setShowFumigatedMessage } from "../../../../../../../../redux/reducers/request/edit/editRequest";
import { heights } from "../../../../../../../../styles/romeu/heights";
import { widths } from "../../../../../../../../styles/romeu/widths";
import { convertToLocal } from "../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../common/customIcons/customIcons";
import { PopUpConfirmation } from "../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { Separator } from "../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../common/tableCustom/tableCustom";
import { PopUpConfirmationType } from "../../../../../../../../common/enum/PopUpConfirmationType";
import { DocumentTypes } from "../../../../../../../../common/enum";
import { DefaultValues } from "../../../../../../../../common/enum/DefaultValues";
import { TooltipDelay, TooltipHost } from "@fluentui/react";
import { useTheme } from "react-jss";
import { DocumentContainerStyle } from "../containerStyle.jss";

interface IEditRequestDocumentListProps {
    schema: IConfigEditRequest;
    requestId: number;
}
export const EditRequestDocumentList: React.FC<IEditRequestDocumentListProps> = ({ schema, requestId }) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = DocumentContainerStyle({ theme });
    const { documents, loadingDocuments } = useAppSelector((store) => store.editRequest.requestDocuments);
    const { headerRequest } = useAppSelector((store) => store.editRequest);
    const documentTypes = useAppSelector((store) => store.catalog.documentTypes.data);

    const [showCorfirmDeleteDocumentItem, setShowCorfirmDeleteDocumentItem] = useState<boolean>(false);
    const [deletionDocumentId, setDeletionDocumentId] = useState<number>(DefaultValues.DEFAULT_ID);

    const columns: ColumnType<IRequestDocument, keyof IRequestDocument>[] = [
        { key: "fileName", title: t("DOCUMENTNAME"), width: "45%" },
        { key: "typeDescription", title: t("DOCUMENTTYPE") },
        { key: "createdBy", title: t("CREATEDBY") },
        { key: "createdOn", title: t("CREATEDON") },
        { key: "actions", title: "" },
    ];
    const onDownloadDocument = (uri: string) => {
        window.open(uri, "_blank");
    };

    const checkAlertFumigated = () => {
        const documentFumigated = documentTypes.find((doc) => doc.code === DocumentTypes.FUMIGATION_REPORT);
        if (
            documents?.filter((document) => document.typeDescription === documentFumigated?.description).length === 0 &&
            (headerRequest.fumigated === true || headerRequest.dryCharge === true)
        ) {
            dispatch(setShowFumigatedMessage(true));
        } else {
            dispatch(setShowFumigatedMessage(false));
        }
    };

    const onAcceptDeleteDocument = () => {
        if (deletionDocumentId > DefaultValues.DEFAULT_ID) {
            dispatch(deleteDocumentRequest(requestId, deletionDocumentId));
            onCancelDeleteDocument();
        }
    };

    const onCancelDeleteDocument = () => {
        setShowCorfirmDeleteDocumentItem(false);
        setDeletionDocumentId(DefaultValues.DEFAULT_ID);
    };

    const onClickRemoveDocument = (id: number) => {
        setShowCorfirmDeleteDocumentItem(true);
        setDeletionDocumentId(id);
    };

    const list = useMemo(() => {
        return documents ?? [];
    }, [documents]);

    const newDocumentsList = list.map((item) => {
        return {
            ...item,
            createdBy: (
                <div className={style.createdByEmail}>
                    <TooltipHost
                        content={item.createdByMail}
                        delay={TooltipDelay.zero}
                    >
                        {item.createdBy}
                    </TooltipHost>
                </div>
            ),
            createdOn: convertToLocal(item.createdOn),
            actions: (
                <CustomTableActions
                    children={
                        <div>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.DOWNLOAD}
                                onClick={() => onDownloadDocument(item.uri)}
                            />

                            {schema.document_delete.visible && (
                                <>
                                    <Separator size="auto"></Separator>

                                    <CustomIcons
                                        isButton={true}
                                        iconName={NameIcons.REMOVE}
                                        onClick={() => onClickRemoveDocument(item.id)}
                                    />
                                </>
                            )}
                        </div>
                    }
                />
            ),
        };
    });
    useEffect(() => {
        documentTypes.length && documents !== undefined && checkAlertFumigated();
    }, [documentTypes, documents, headerRequest.dryCharge, headerRequest.fumigated]);

    useEffect(() => {
        if (documents === undefined && requestId !== undefined) {
            dispatch(fetchRequesDocumentAction(requestId));
        }
        if (!documentTypes.length) {
            dispatch(fetchDocumentTypes());
        }
    }, [documents, requestId]);

    return (
        <>
            <CustomTableList
                data={newDocumentsList}
                columns={columns}
                loading={loadingDocuments}
            />

            <PopUpConfirmation
                showPopUp={showCorfirmDeleteDocumentItem}
                onCancel={onCancelDeleteDocument}
                onAccept={onAcceptDeleteDocument}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={heights.confirmPopUp.remove}
            />
        </>
    );
};
