import { stringDefaultValue } from "../../../utils";

export interface IManagementUserSignupFilter {
    email: string;
    name: string;
    userTypeId: string;
    stateCode: string;
}

export const emptyManagementUserSignupFilter = (): IManagementUserSignupFilter => {
    return {
        email: "",
        name: "",
        userTypeId: stringDefaultValue.key.toString(),
        stateCode: stringDefaultValue.key.toString(),
    };
};
