import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const ResumeCardsStyle = createUseStyles((theme: any) => ({
    resumeCardsContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: theme.gaps.sizes.xl,
        padding: "16px 32px",
        height: "70vh",
        overflowY: "auto",
        [mediaQueries.MOBILE]: {
            padding: 8,
        },
        [mediaQueries.TABLET]: {
            padding: 12,
        },
    },
    resumeCard: {
        display: "flex",
        flexDirection: "row",
        padding: 16,
        borderRadius: 8,
        maxWidth: 612,
        rowGap: 16,
        backgroundColor: theme.colors.cards.request,
        columnGap: 32,
    },
    resumeCardData: {
        width: "calc(100% - 24px)",
        "& > div": {
            width: "50%",
        },
        display: "flex",
        alignItems: "flex-end",
        columnGap: 32,
    },
    resumeCardColumn: {
        display: "flex",
        flexDirection: "column",
    },
    resumeCardLabel: {
        color: theme.colors.form.input.default,
        fontSize: 16,
    },
    resumeCardRequestData: {
        borderBottom: `1px solid ${theme.colors.form.input.default}`,
        color: theme.colors.palette.shadowBlack,
        width: "100%",
        fontSize: 18,
        padding: "8px 0",
    },
    resumeCardLink: {
        width: 24,
        "& i": {
            fontSize: 24,
            color: theme.colors.titles.primary.color,
            cursor: "pointer",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "end",
    },
    
}));
