import { createUseStyles } from "react-jss";
import { DirectionType } from "../../../../common/enum/DirectionType";

export const RadioButtonGroupStyled = createUseStyles((theme: any) => ({
    radioButtonContainer: (props: any) => ({
        "& .ms-Label": {
            padding: 0,
            color: theme.colors.form.input.default,
            fontSize: "0.75rem",
            fontWeight: "100",
            fontFamily: theme.fonts.families.site,
        },
        "& .ms-ChoiceFieldGroup-flexContainer": {
            display: props.direction === DirectionType.horizontal ? "flex" : "inherit",
            columnGap: props.direction === DirectionType.horizontal ? 12 : "normal",
        },
        "& .ms-ChoiceFieldGroup": {
            display: "flex",
            flexDirection: props.direction === DirectionType.horizontal ? "column" : "row",
        },

        "& .is-checked": {
            "&:after": {
                borderColor: theme.colors.radioButtons.color,
            },
            "&:before": {
                borderColor: theme.colors.radioButtons.borderColor,
            },
        },
    })
}));
