import { Checkbox, ICheckboxStyles } from "@fluentui/react";

interface ICheckboxProps {
    name?: string;
    label?: string;
    isChecked: boolean;
    handleChange?: (e: any) => any;
    required?: boolean;
    isIndeterminate?: boolean;
    disabled?: boolean;
    defaultWidth?: boolean;
}

export const Checkboxs = (props: ICheckboxProps) => {
    const customStyles: ICheckboxStyles = {
        root: {
            width: props.defaultWidth ? undefined : "100%",
            alignItems: "center",
            "&:hover .ms-Checkbox-checkbox": {
                background: props.isChecked ? "#7367f0" : "transparent",
                borderColor: "#7367f0",
            },
        },
        checkbox: {
            backgroundColor: props.isChecked ? "#7367f0" : "transparent",
            borderRadius: "3px",
            borderColor: "#7367f0",
        },
    };

    return (
            <Checkbox
                styles={customStyles}
                name={props.name}
                label={props.label}
                checked={props.isChecked}
                onChange={props.handleChange}
                required={props.required}
                indeterminate={props.isIndeterminate}
                disabled={props.disabled}
            />
    );
};
