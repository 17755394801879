import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { IGenericPayload } from "../../../../../models";
import { IBoardHistoryAppointmentDockTimeZoneConfiguration } from "../../../../../models/masterAppointment/IAppointmentBoard";

export interface IAppointmentDockTimeZoneConfigurationFormState {
    form: IBoardHistoryAppointmentDockTimeZoneConfiguration;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmentDockTimeZoneConfigurationFormState = {
    form: {
        id: 0,
        appointmentDockId: 0,
        appointmentTimeZoneId: 0,
        appointmentTypeId: 0,
        merchandiseTypeCode: "",
        appointmentDockCode: "",
        appointmentDockDescription: "",
        appointmentTimeZoneCode: "",
        appointmentTimeZoneDescription: "",
        appointmentTypeCode: "",
        appointmentTypeDescription: "",
        merchandiseTypeDescription: "",
        merchandiseTypeId: -1,
        active: true,
        enabled: true,
        fumigated: true,
        halalCertified: true,
        humanConsumption: true,
        organicProduct: true,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const masterAppointmentDockTimeZoneConfigurationForm = createSlice({
    name: "masterAppointmentDockTimeZoneConfigurationForm",
    initialState,
    reducers: {
        resetAppointmentDockTimeZoneConfigurationForm: () => initialState,
        setLoadingAppointmentDockTimeZoneConfigurationForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAppointmentDockTimeZoneConfigurationForm: (state, action: PayloadAction<IBoardHistoryAppointmentDockTimeZoneConfiguration>) => {
            state.form = action.payload;
        },
        updateAppointmentDockTimeZoneConfigurationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setShowPopUpAppointmentDockTimeZoneConfiguration: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetAppointmentDockTimeZoneConfigurationForm,
    setLoadingAppointmentDockTimeZoneConfigurationForm,
    setAppointmentDockTimeZoneConfigurationForm,
    updateAppointmentDockTimeZoneConfigurationProperty,
    setShowPopUpAppointmentDockTimeZoneConfiguration,
} = masterAppointmentDockTimeZoneConfigurationForm.actions;

export default masterAppointmentDockTimeZoneConfigurationForm.reducer;
