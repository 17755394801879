import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { emptyInvoiceFilter, IInvoicingFilter } from "../../../../models/invoicing/filter/IInvoiceFilter";

interface IInvoicingSummaryConceptFilterState {
    filter: IInvoicingFilter;
}

const initialState: IInvoicingSummaryConceptFilterState = {
    filter: emptyInvoiceFilter(),
};

export const invoicingSummaryByConceptFilterSlice = createSlice({
    name: "invoicingSummaryByConceptFilterSlice",
    initialState,
    reducers: {
        resetInvoicingSummaryConceptFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetInvoicingSummaryConceptFilter, updateFilterProperty } = invoicingSummaryByConceptFilterSlice.actions;

export default invoicingSummaryByConceptFilterSlice.reducer;
