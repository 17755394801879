import React, { useEffect } from "react";
import { fetchMasterAppointmentTypeList } from "../../../../../../redux/actions/masterAppointment/appointmentType/appointmentType";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterAppointmentTypeFormController } from "../controller/controller";
import { MasterAppointmentTypeColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/enum/sizes";

interface IMasterAppointmentTypeListContainerProps {}

export const MasterAppointmentTypeList: React.FC<IMasterAppointmentTypeListContainerProps> = () => {
    const { list, loading } = useAppSelector((state) => state.masterAppointmentTypesList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return MasterAppointmentTypeColumns();
    };
    useEffect(() => {
        list === undefined && !loading && dispatch(fetchMasterAppointmentTypeList());
    }, [list]);

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 10rem)"}
                />
            )}

            <MasterAppointmentTypeFormController />
        </div>
    );
};
