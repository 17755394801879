export const validateDefaultForm = (form: any, referenceId: boolean, keys?: string[], newKeys?: string[]): boolean => {
    let defaultKeys = keys ?? ["code", "description"];
    if (newKeys) {
        defaultKeys = defaultKeys.concat(newKeys);
    }

    if (referenceId) {
        defaultKeys.push("referenceId");
    }

    let item: any = form;
    for (let key of defaultKeys) {
        if (!item[key]) {
            return true;
        }
    }

    return false;
};
