import { Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ButtonsStyled } from "./buttons.jss";
import { ColorType } from "../../../common/enum/ColorType";

interface IButtonProps {
    title?: string;
    color?: ColorType;
    icon?: string;
    className?: string;
    handleClick?: (e: any) => void;
    disabled?: boolean;
    innerRef?: any;
    loading?: boolean;
}

export const Button = (props: IButtonProps) => {
    const buttonStyled = ButtonsStyled();
    const { t } = useTranslation("messages");

    const icon = props.icon ? <Icon iconName={props.icon} /> : "";

    const className = props.className ?? "";

    const getColorClassName = (type?: ColorType) => {
        switch (type) {
            case ColorType.primary:
                return buttonStyled.primary;
            case ColorType.primaryInverse:
                return buttonStyled.primaryInverse;
            case ColorType.primaryInverseWithBorder:
                return buttonStyled.primaryInverseWithBorder;
            case ColorType.secondary:
                return buttonStyled.secondary;
            case ColorType.success:
                return buttonStyled.success;
            case ColorType.danger:
                return buttonStyled.danger;
            case ColorType.warning:
                return buttonStyled.warning;
            case ColorType.info:
                return buttonStyled.info;
            case ColorType.dangerInverseWithBorder:
                return buttonStyled.dangerInverseWithBorder;
            default:
                return "";
        }
    };

    return (
        <button
            type="button"
            className={`${buttonStyled.button} ${getColorClassName(props.color)} ${className}`}
            onClick={props.handleClick}
            disabled={props.disabled}
            ref={props.innerRef}
        >
            {icon}
            {!props.loading ? props.title : t("API.LOADING")}
        </button>
    );
};

export const ButtonPrimary = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.primary}
            {...props}
        />
    );
};

export const ButtonPrimaryInverse = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.primaryInverse}
            {...props}
        />
    );
};

export const ButtonPrimaryInverseWithBorder = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.primaryInverseWithBorder}
            {...props}
        />
    );
};

export const ButtonSecondary = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.secondary}
            {...props}
        />
    );
};

export const ButtonSuccess = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.success}
            {...props}
        />
    );
};

export const ButtonDanger = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.danger}
            {...props}
        />
    );
};
export const ButtonDangerInverseWithBorder = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.dangerInverseWithBorder}
            {...props}
        />
    );
};
export const ButtonWarning = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.warning}
            {...props}
        />
    );
};

export const ButtonInfo = (props: IButtonProps) => {
    return (
        <Button
            color={ColorType.info}
            {...props}
        />
    );
};
