import { useEffect } from "react";
import { fetchManagementIncidencesTypesAction } from "../../../../redux/actions/management/incidenceTypes/incidenceTypes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { sortArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementIncidenceTypesListColumn } from "./column/column";
import { SkeletonSizes } from "../../../../common/enum/sizes";

export const ManagementIncidenceTypesList = () => {
    const { list, loading, sort } = useAppSelector((state) => state.managementIncidenceTypesList);
    const dispatch = useAppDispatch();

    const renderColumns = () => {
        return ManagementIncidenceTypesListColumn();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchManagementIncidencesTypesAction());
    }, [list]);
    return !loading ? (
        <CustomDetailsList
            data={sortArray(list ?? [], sort)}
            renderColumns={renderColumns}
            height={heights.management.tables}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
