import { createUseStyles } from "react-jss";

export const UserProfileEntityUsersSendSignupContainerStyle = createUseStyles((theme: any) => ({
    sendSignUpContainer: {
        paddingBottom: 24,
    },
    emailContainer: {
        display: "flex",
        flexDirection: "column",
        width: "400px",
    },

}));
