import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../../../../../common/constants";

export const MasterBoardHistoryAppointmentDockFormStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.form.column,
        paddingTop: 20,
        width: "100%",
    },
    rowForm: {
        display: "flex",
        gap: theme.gaps.form.column,
        width: "100%",
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
        },
    },
    checkRow: {
        display: "flex",
        gap: theme.gaps.form.column,
        width: "100%",
    },
    checkRowMobile: {
        display: "flex",
        gap: theme.gaps.form.column,
        width: "100%",
        justifyContent: "space-between",
    },
    input :{
        width:"250px", 
        maxWidth:"49%"
    }
}));
