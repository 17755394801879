import { useCallback, useMemo } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { updateAdminProfileProperty } from "../../../../../../../redux/reducers/user/profile/userProfile";
import { getGenericPayloadFromEvent, compileNameOfProperty } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { TitleH6 } from "../../../../../../common/titles/titles";
import { UserProfileAdminEmailItem } from "./item/item";
import { UserSignupAdminEmailsStyle } from "./listStyle.jss";
import { ColorType } from "../../../../../../../common/enum/ColorType";



import { IAdminData } from "../../../../../../../models";

interface IEntityFormProps {
    data: IAdminData;
    adminRegister: UseFormRegister<IAdminData>;
    adminErrors: FieldErrors<IAdminData>;
}

export const UserProfileAdminEmails: React.FC<IEntityFormProps> = ({ data, adminErrors, adminRegister }) => {
    const theme = useTheme();
    const style = UserSignupAdminEmailsStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateAdminProfileProperty(payload));
        },
        [dispatch],
    );

    const maxIndex = useMemo(() => {
        return data.emails.length - 1;
    }, [data.emails]);

    return (
        <>
            <div className={style.row}>
                <div className={style.title}>
                    <TitleH6
                        title={t("USER.TITLES.ADMIN_DATA")}
                        color={ColorType.primary}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.LANDLINE")}
                        value={data.phone}
                        error={adminErrors.phone}
                        isRequired={true}
                        {...adminRegister(compileNameOfProperty<IAdminData>("phone"), { onChange: onInputChange, value: data.phone })}
                    />
                </div>
                <div className={style.column}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.MOBILE_PHONE")}
                        value={data.mobilePhone}
                        error={adminErrors.mobilePhone}
                        {...adminRegister(compileNameOfProperty<IAdminData>("mobilePhone"), {
                            onChange: onInputChange,
                            value: data.mobilePhone,
                        })}
                    />
                </div>
            </div>
            <div className={style.rowEmails}>
                {data.emails.map((email, index) => (
                    <div
                        className={style.columnEmail}
                        key={`${UserProfileAdminEmails.name}-${index}`}
                    >
                        <UserProfileAdminEmailItem
                            email={email}
                            key={`${UserProfileAdminEmails.name}-${index}`}
                            isLastIndex={index === maxIndex}
                            isUniqueIndex={data.emails.length === 1}
                            index={index}
                        />
                    </div>
                ))}
            </div>
        </>
    );
};
