import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ContentType } from "../../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../../common/enum/files/FileSize";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { IVlcPortImportRequestForm, updateFormProperty } from "../../../../../redux/reducers/vlcPort/importRequest/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { validateXml } from "../../../../../utils/xmlUtils";
import { Textarea } from "../../../../common/textarea/textarea";
import { UploadFileDropzone } from "../../../../common/uploadFileDropzone/uploadFileDropzone";
import { ImportRequest } from "../../container";
import { VlcPortImportRequestFormStyle } from "./formStyle.jss";

interface IVlcPortImportRequestFormProps {
    setDocument: (value: any) => any;
    codeComponent: string;
    document: any;
}

export const VlcPortImportRequestForm: React.FC<IVlcPortImportRequestFormProps> = ({ setDocument, document, codeComponent }) => {
    const theme = useTheme();
    const style = VlcPortImportRequestFormStyle({ theme });
    const dispatch = useAppDispatch();
    const onInputChange = (event: any) => {
        const payload = getGenericPayloadFromEvent(event);
        dispatch(updateFormProperty(payload));
    };
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.IMPORT_REQUEST" });

    const { form } = useAppSelector((state) => state.VlcportImportRequestForm);
    const error = useMemo(() => {
        return !validateXml(form.xml) && form.xml ? true : false;
    }, [form]);
    return (
        <div className={style.container}>
            {codeComponent === ImportRequest.DOCUMENT && (
                <div className={style.file}>
                    <UploadFileDropzone
                        allowedExtensions={[ContentType.EXCEL]}
                        onFilesChange={setDocument}
                        disabled={false}
                        maxSize={FileSize._5MB}
                    />
                </div>
            )}

            {codeComponent === ImportRequest.CODE && (
                <div className={style.code}>
                    <Textarea
                        name={compileNameOfProperty<IVlcPortImportRequestForm>("xml")}
                        onChange={onInputChange}
                        value={form.xml}
                        label={t("CODE")}
                        error={error ? t("XML_VALID") : undefined}
                    ></Textarea>
                </div>
            )}
        </div>
    );
};
