import { Method } from "../../../common/enum";
import { IManagementNonWorkDays } from "../../../models/management/workCalendar/IManagementNonWorkDays";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/nonworkdays`;

export class ManagementWorkCalendarApi {
    async getNonWorkDaysAsync(year: number, month: number): Promise<IManagementNonWorkDays[]> {
        const url = `${baseURL}?month=${month}&year=${year}`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IManagementNonWorkDays[]>();
    }

    async setNonWorkDaysAsync(date: string): Promise<number> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({ date }).execute<number>();
    }

    async deleteNonWorkDaysAsync(id: number): Promise<boolean> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute<boolean>();
    }
}
