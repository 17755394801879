import { IComboBoxOption } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { InspectionCardStyle } from "./inspectionCard.jss";
import { ICertificateType, Inspection } from "../../../../../../models";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../../assets/icons";

interface IProps {
    inspection: Inspection;
    editCard: (inspection: Inspection, indexOrder: number) => void;
    indexOrder: number;
    deleteCard: (indexOrder: number) => void;
    certificatesOptions: ICertificateType[];
    organismsOptions: IComboBoxOption[];
}
export const InspectionCard: React.FC<IProps> = ({ inspection, editCard, indexOrder, deleteCard, certificatesOptions, organismsOptions }) => {
    const theme = useTheme();
    const styles = InspectionCardStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });

    const organismName = useMemo(() => {
        return organismsOptions.find((org) => org.key === inspection.organismId)?.text;
    }, [organismsOptions, inspection]);

    const certificateName = useMemo(() => {
        return certificatesOptions.find((cert) => cert.id === inspection.certificateTypeId)?.description;
    }, [certificatesOptions, inspection]);

    return (
        <>
            <div
                className={styles.inspectionCardContainer}
                key={indexOrder}
            >
                <div className={styles.inspectionCardActionButtons}>
                    <div
                        className={styles.inspectionCardButtonEdit}
                        onClick={() => editCard(inspection, indexOrder)}
                    >
                        {t("BUTTONS.EDIT")}
                    </div>
                    <div className={styles.inspectionCardButtonDelete}>
                        <CustomIcons
                            iconName={NameIcons.REMOVE}
                            onClick={() => deleteCard(indexOrder)}
                        />
                    </div>
                </div>
                <div className={styles.inspectionCardDataContainer}>
                    <div className={styles.inspectionCardRow}>
                        <div className={styles.inspectionCardItem}>
                            <div className={styles.inspectionCardLabel}>{t("FIELDS.ORGANISM")}</div>
                            <div className={styles.inspectionCardData}>{organismName}</div>
                        </div>
                        <div className={styles.inspectionCardItem}>
                            <div className={styles.inspectionCardLabel}>{t("FIELDS.CERTIFICATE")}</div>
                            <div className={styles.inspectionCardData}>{certificateName}</div>
                        </div>
                    </div>
                    <div className={styles.inspectionCardRow}>
                        <div className={styles.inspectionCardItem}>
                            <div className={styles.inspectionCardLabel}>{t("FIELDS.CERTIFICATE_NUMBER")}</div>
                            <div className={styles.inspectionCardData}>{inspection.certificateNumber}</div>
                        </div>
                        <div className={styles.inspectionCardItem}>
                            <div className={styles.inspectionCardLabel}>{t("FIELDS.EXPORTER")}</div>
                            <div className={styles.inspectionCardData}>{inspection.exporter}</div>
                        </div>
                    </div>
                    <div className={styles.inspectionCardLastRow}>
                        <div className={styles.inspectionCardItem}>
                            <div className={styles.inspectionCardLabel}>{t("FIELDS.IMPORTER")}</div>
                            <div className={styles.inspectionCardData}>{inspection.importer}</div>
                        </div>
                        <div className={styles.inspectionCardItem}>
                            <div className={styles.inspectionCardLabel}>{t("FIELDS.YEAR")}</div>
                            <div className={styles.inspectionCardData}>{inspection.year}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
