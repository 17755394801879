import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../../../../common/constants";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";

import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { postEditRequestInspection } from "../../../../../../../redux/actions/request/edit/action/inspection";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { resetEditRequestInspectionForm } from "../../../../../../../redux/reducers/request/edit/form/inspection";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../../../../common/requiredMessage/requiredMessage";
import { EditRequestInspectionForm } from "./form/inspection/form";
interface IRequestInspectionFormControllerProps {
    requestId: number;
    schema: IConfigEditRequest;
}

export const RequestInspectionFormController: React.FC<IRequestInspectionFormControllerProps> = ({ requestId, schema }) => {
    const isMobile = useMediaQuery({ query: componentsMediaQueries.TABLETPORTRAIT });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();
    const { loading, showPopUp, form } = useAppSelector((store) => store.editRequestInspectionForm);
    const certificateTypes = useAppSelector((store) => store.catalog.certificatesByOrganism.data);

    const disabledButton = useMemo(() => {
        let autoDocumentNumber = certificateTypes.find((item) => item.id === form.certificateTypeId)?.automaticCertificateNumber;
        let keys = ["organismId", "certificateTypeId", "year"];
        !autoDocumentNumber && keys.push("certificateNumber");
        let item: any = form;
        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }
        return false;
    }, [form]);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.UPDATE) {
            return t("TITLES.EDIT_INSPECTION");
        } else if (showPopUp === PopUpCodes.CREATE) {
            return t("BUTTONS.ADD_INSPECTION_REQUEST");
        }

        return "";
    }, [showPopUp, form]);

    const onCancelFormInspection = () => {
        dispatch(resetEditRequestInspectionForm());
    };

    const onSaveFormInspection = () => {
        dispatch(postEditRequestInspection(requestId, form)).then((response: any) => {
            response && onCancelFormInspection();
        });
    };

    const onDimissed = () => {
        dispatch(resetEditRequestInspectionForm());
    };

    return (
        <PopUpForm
            content={<EditRequestInspectionForm schema={schema} />}
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelFormInspection}
            onSave={onSaveFormInspection}
            onDimissed={onDimissed}
            height={isMobile ? "50vh" : "45vh"}
            width={"40vw"}
            isBlocking={false}
            isDisabledButton={disabledButton}
            isLoading={loading}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
