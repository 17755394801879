import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRequestHistoryItem } from "../../../../../models/requests/request/IRequestHistoryItem";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";


interface EditRequestHistoryChangeState {
    form: IRequestHistoryItem;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: EditRequestHistoryChangeState = {
    form: {
        integrationData: "",
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const editRequestHistoryChange = createSlice({
    name: "editRequestHistoryRequest",
    initialState,
    reducers: {
        resetEditRequestHistoryChangeState: () => initialState,

        setEditRequestHistoryChangeLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEditRequestHistoryChangeData: (state, action: PayloadAction<any>) => {
            state.form = action.payload;
        },
        setEditRequestHistoryChangePopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetEditRequestHistoryChangeState,
    setEditRequestHistoryChangeData,
    setEditRequestHistoryChangeLoading,
    setEditRequestHistoryChangePopUp,
} = editRequestHistoryChange.actions;

export default editRequestHistoryChange.reducer;
