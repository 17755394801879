import { useTheme } from "react-jss";
import { TimeLineItemStyle } from "./timeLineStyle.jss";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import React, { useMemo } from "react";
import { IRequestHistory } from "../../../../../../../models/requests/request/IRequestHistory";
import { convertToLocal } from "../../../../../../../utils";
import { FormatDate } from "../../../../../../../common/enum/dateTime/FormatDate";
import { Trans, useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { historyActions } from "./data";
import { IPersonaSharedProps, Persona, PersonaPresence, PersonaSize, TooltipDelay, TooltipHost } from "@fluentui/react";
import { setEditRequestHistoryChangePopUp } from "../../../../../../../redux/reducers/request/edit/history/form";
import { fetchEditRequestHistoryChanges } from "../../../../../../../redux/actions/request/edit/action/requestHistory";

interface ITimeLineItem {
    data: IRequestHistory;
    previousId: string;
}

export const TimeLineItem: React.FC<ITimeLineItem> = ({ data, previousId }) => {
    const theme = useTheme();
    const style = TimeLineItemStyle({ theme });
    const { t } = useTranslation("timeline");
    const dispatch = useAppDispatch();
    const requestId = useAppSelector((state) => state.editRequest.headerRequest.id);

    const action = useMemo(() => {
        return historyActions.find((item) => item.action === data.action);
    }, [data]);

    const personaProps: IPersonaSharedProps = {
        imageUrl: data.userPhotoUri ? data.userPhotoUri : "",
        text: data.userName ?? "",
        secondaryText: convertToLocal(data.timestamp ?? "", FormatDate.DATE_HOUR_MINUTE),
    };
    const personaStyles = {
        root: {
            cursor: "pointer",
        },
        primaryText: {
            "font-weight": "bold",
        },
        secondaryText: {
            "text-align": "left",
        },
    };

    return (
        <div className={style.timeLineItem}>
            <span className={style.arrow}></span>
            <span
                className={style.circle}
                onClick={() => {
                    dispatch(setEditRequestHistoryChangePopUp(PopUpCodes.CREATE));
                    dispatch(fetchEditRequestHistoryChanges(requestId, data.id, previousId));
                }}
            >
                <CustomIcons
                    iconName={action?.icon}
                    size={15}
                    margin={"0px"}
                    position="center"
                    columnPosition="end"
                    className={style.icon}
                />
            </span>
            <p className={style.actionDescription}>
                <Trans
                    t={t}
                    i18nKey={action?.label as any}
                    values={{
                        value: data.value,
                        value2: data.value2,
                        value3: data.value3,
                    }}
                    tOptions={{ interpolation: { escapeValue: false } }}
                />
            </p>

            <div className={style.userData}>
                <TooltipHost
                    content={data.userId}
                    delay={TooltipDelay.zero}
                >
                    <Persona
                        {...personaProps}
                        size={PersonaSize.size40}
                        presence={PersonaPresence.none}
                        styles={personaStyles}
                        showOverflowTooltip={false}
                    />
                </TooltipHost>
            </div>
        </div>
    );
};
