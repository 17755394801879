import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";

import { fetchNotificationTypesRemaining } from "../../../../../../redux/actions/catalog/catalog";

import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterNotificationTypesForm } from "./form/form";
import { postToMasterNotificationTypesForm } from "../../../../../../redux/actions/resources/master/notificationTypes/notificationTypes";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { resetMasterNotificationTypesForm } from "../../../../../../redux/reducers/resources/master/notificationType/form";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterNotificationTypesControllerForm {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterNotificationTypesControllerForm: React.FC<IMasterNotificationTypesControllerForm> = ({ widthPopUp, heigthPopUp }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { loading, form } = useAppSelector((state) => state.masterNotificationTypesForm);
    const { data } = useAppSelector((state) => state.catalog.notificationTypesRemaining);

    const onSavePopUp = () => {
        dispatch(postToMasterNotificationTypesForm()).then((response: any) => {
            response && onCancelPopUp();
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };

    const onDimissed = () => {
        dispatch(resetMasterNotificationTypesForm());
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return `${t("CREATE")}  ${t("BUTTON.NOTIFICATION_TYPES")}`;
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.NOTIFICATION_TYPES");
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form]);

    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchNotificationTypesRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterNotificationTypesForm
                    showNotData={showNotData}
                    data={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onDimissed={onDimissed}
            onSave={onSavePopUp}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onlyHidden={showNotData}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
