import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { VlcPortFinishedRequestList } from "./list/list";
import { useTheme } from "react-jss";
import { VlcPortFinishedRequestContainerStyle } from "./containerStyle.jss";
import { PopUpForm } from "../../common/popup/popUpForm/popUpForm";
import { CodeViewer } from "../../common/codeViewer/codeViewer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { setVlcportRequestFinishedPopUpShow } from "../../../redux/reducers/vlcPort/requestFinished/list";
import { NameIcons } from "../../../assets/icons";
import { ActionButton, IIconProps, IPivotStyles, Pivot, PivotItem, TooltipDelay, TooltipHost } from "@fluentui/react";
import { fetchFinishedREquestList } from "../../../redux/actions/vlcPort/finishedRequest/finishedRequest";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useMemo, useState } from "react";
import { mediaQueries } from "../../../common/constants";

export const VlcPortFinishedRequestContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.FINISHED_REQUESTS" });
    const dispatch = useAppDispatch();
    const theme = useTheme() as any;
    const style = VlcPortFinishedRequestContainerStyle({ theme });
    const { showPopUp, detail, sendedValenciaportMessage } = useAppSelector((state) => state.vlcPortFinishedRequestList);
    const reloadIcon: IIconProps = { iconName: NameIcons.RELOAD, style: { width: 25 } };
    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
            root: {
                display: "flex",
                width: "100%",
            },
            itemContainer: {
                paddingLeft: 8,
                width: "100%",
                height: "100%",
                "& > div": {
                    width: "100%",
                    height: "100%",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    const [itemSelected, setItemSelected] = useState<any>();

    const form = useMemo(() => {
        if (detail && sendedValenciaportMessage) {
            return (
                <div className={style.codeContainer}>
                    <Pivot
                        styles={pivotStyles}
                        selectedKey={String(itemSelected)}
                        onLinkClick={(event) => {
                            setItemSelected(event?.props.itemKey ? event?.props.itemKey : "1");
                        }}
                    >
                        <PivotItem
                            headerText={t("MESSAGE_VLCPORT")}
                            itemKey="1"
                        >
                            <CodeViewer
                                height="calc(86vh - 10rem)"
                                code={sendedValenciaportMessage}
                            />
                        </PivotItem>
                        <PivotItem
                            headerText={t("DETAILS")}
                            itemKey="2"
                        >
                            <CodeViewer
                                height="calc(86vh - 10rem)"
                                code={detail}
                            />
                        </PivotItem>
                    </Pivot>
                </div>
            );
        } else if (detail && !sendedValenciaportMessage) {
            return (
                <div className={style.codeContainer}>
                    <CodeViewer
                        height="calc(86vh - 10rem)"
                        code={detail}
                    />
                </div>
            );
        } else {
            return (
                <div className={style.codeContainer}>
                    <CodeViewer
                        height="calc(86vh - 10rem)"
                        code={sendedValenciaportMessage}
                    />
                </div>
            );
        }
    }, [detail, sendedValenciaportMessage, showPopUp, itemSelected]);

    const titlePopUp = useMemo(() => {
        if (detail && sendedValenciaportMessage) {
            return "";
        } else if (detail && !sendedValenciaportMessage) {
            return t("DETAILS");
        } else {
            return t("MESSAGE_VLCPORT");
        }
    }, [detail, sendedValenciaportMessage, showPopUp]);

    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={
                    <TooltipHost
                        content={t("MESSAGE")}
                        delay={TooltipDelay.zero}
                    >
                        <ActionButton
                            iconProps={reloadIcon}
                            onClick={() => {
                                dispatch(fetchFinishedREquestList());
                            }}
                            allowDisabledFocus
                        />
                    </TooltipHost>
                }
            />

            <VlcPortFinishedRequestList />

            <PopUpForm
                onSave={() => {}}
                content={form}
                title={titlePopUp}
                isVisible={PopUpCodes.CREATE === showPopUp}
                onCancel={() => {
                    dispatch(setVlcportRequestFinishedPopUpShow(PopUpCodes.NONE));
                    setItemSelected("1");
                }}
                onlyHidden={true}
                height={"calc(100vh - 12rem)"}
                width={"90vw"}
            />
        </div>
    );
};
