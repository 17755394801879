import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IStatisticsOrganismsIncidenceState } from "../../../../../models/statistics/IOrganism";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { generateRandomKeys } from "../../../../../utils/keys";

interface IcustomFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsIncidenceOrganismListColumnFooter = (props: IcustomFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INCIDENCE.OPERATOR.TABLE" });

    const { totalAverageIncidencesTime, totalIncidencesPercentage, totalIncidencesTime, totalIncidences,   } = useAppSelector((store) => store.statisticsOrganismIncidenceList.data);
    const columns: IColumn[] = [
        {
            key: generateRandomKeys().toString(),
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("sort"),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTAL_FINAL")}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalIncidences"),
            minWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalIncidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}> {totalIncidences}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalIncidencesPercentage"),
            minWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalIncidencesPercentage"),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> { totalIncidencesPercentage}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalIncidencesTime"),
            minWidth: calcWidthColumFooter(3, 3, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalIncidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(3, 3, props.listColumnsFooter)}> { totalIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalAverageIncidencesTime"),
            minWidth: calcWidthColumFooter(4, 4, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsOrganismsIncidenceState>("totalAverageIncidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(4, 4, props.listColumnsFooter)}> { totalAverageIncidencesTime}</b>,
            isResizable: true,
        },
    ];

    return columns;
};
