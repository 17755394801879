import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants/mediaQueries";

export const ResumeDocumentItemStyle = createUseStyles((theme: any) => ({
    container: {
        padding: theme.paddings.inputFile,
        display: "flex",
        flexDirection: "column",
        rowGap: 8,

        "& label": {
            color: theme.colors.titles.secondary,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "block",
            [mediaQueries.MOBILE]: {
                whiteSpace: "normal"
            },

        },
    },
    selectorRequired: {
        color: theme.colors.palette.red,
    },
    labelContainer : {
        display:"flex", 
        gap:3
    }
}));
