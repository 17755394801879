export enum RequestState {
    DRAFT = 1,
    SUBMITTED = 2,
    PARKING = 3,
    CUSTOMS_BLOCKED = 4,
    PREPARED = 5,
    DOCK = 6,
    DOCK_EXIT = 7,
    BLOCKED = 8,
    EXIT = 9,
    NOT_POSITIONED = 10,
    CANCELED = 11,
    CUSTOMS_CONFIRMATION = 12,
    CUSTOMS_CONFIRMATION_ERRORS = 13,
}
