import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpcomingAppointmentSortItem } from "../../../models/upcomingAppointmentSort/IUpcomingAppointmentSort";

export interface IRequestUpcomingAppointmentssortList {
    list?: IUpcomingAppointmentSortItem[];
    loading: boolean;
}

const initialState: IRequestUpcomingAppointmentssortList = {
    loading: false,
};

export const requestUpcomingAppointmentsSortListReducer = createSlice({
    name: "requestUpcomingAppointmentsSortListReducer",
    initialState,
    reducers: {
        resetRequestUpcomingAppointmentsSortList: () => initialState,
        setLoadingRequestUpcomingAppointmentsSortList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setRequestUpcomingAppointmentsSortList: (state, action: PayloadAction<IUpcomingAppointmentSortItem[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetRequestUpcomingAppointmentsSortList, setLoadingRequestUpcomingAppointmentsSortList, setRequestUpcomingAppointmentsSortList } =
    requestUpcomingAppointmentsSortListReducer.actions;

export default requestUpcomingAppointmentsSortListReducer.reducer;
