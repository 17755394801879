import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { fetchFinalDestinationTypesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

import { PopUpConfirmation } from "../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterFinalDestinyTypesForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../common/enum/PopUpConfirmationType";
import { postToMasterFinalDestinationTypeForm } from "../../../../../../redux/actions/resources/master/finalDestinationType/finalDestinationType";
import {
    setMasterFinalDestinationTypesWarning,
    resetMasterFinalDestinationTypesForm,
} from "../../../../../../redux/reducers/resources/master/finalDestinationTypes/form";
import { setShowPopUpMaster } from "../../../../../../redux/reducers/resources/master/master";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterFinalDestinationTypesFormController {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterFinalDestinationTypesFormController: React.FC<IMasterFinalDestinationTypesFormController> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { data } = useAppSelector((state) => state.catalog.finalDestinationTypeRemaining);
    const { form, loading, warningPopUp } = useAppSelector((state) => state.masterFinalDestinationTypesForm);
    const { list } = useAppSelector((state) => state.masterFinalDestinationTypesList);

    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const onAcceptPopUp = () => {
        dispatch(setMasterFinalDestinationTypesWarning(""));

        dispatch(postToMasterFinalDestinationTypeForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(setShowPopUpMaster(PopUpCodes.NONE));
    };

    const onDimissed = () => {
        dispatch(resetMasterFinalDestinationTypesForm());
    };
    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    const existFinalDestinationDefault = useMemo(() => {
        let finalDestinationItem = list?.find((item) => item.defaultValue);

        if (showPopUp === PopUpCodes.CREATE) {
            return finalDestinationItem?.defaultValue;
        }
        return form.hasDefaultValue && form.id !== finalDestinationItem?.id;
    }, [showPopUp, list, form]);

    const titlePopUp = useMemo(() => {
        let title = "";
        if (showPopUp === PopUpCodes.CREATE) {
            title = t("CREATE") + " " + t("BUTTON.TYPE_FINAL_DESTINY");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            title = t("UPDATE") + " " + t("BUTTON.TYPE_FINAL_DESTINY");
        }
        return title;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchFinalDestinationTypesRemaining());
    }, [showPopUp]);

    return (
        <>
            <PopUpForm
                content={
                    <MasterFinalDestinyTypesForm
                        showNotData={showNotData}
                        selectorData={data}
                    />
                }
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false}
                onCancel={onCancelPopUp}
                onSave={() => {
                    if (existFinalDestinationDefault && form.defaultValue) {
                        dispatch(setMasterFinalDestinationTypesWarning(PopUpCodes.WARNING));
                    } else {
                        onAcceptPopUp();
                    }
                }}
                onDimissed={onDimissed}
                height={heigthPopUp}
                width={widthPopUp}
                isLoading={loading}
                isDisabledButton={isDisabledButton}
                leftButtonContent={<RequiredFieldsMessage />}
                onlyHidden={showPopUp === PopUpCodes.CREATE ? showNotData : false}
            />
            <PopUpConfirmation
                showPopUp={warningPopUp === PopUpCodes.WARNING}
                message={t("TYPE_FINAL_DESTINY.WARNING")}
                onCancel={() => {
                    dispatch(setMasterFinalDestinationTypesWarning(""));
                }}
                onAccept={onAcceptPopUp}
                type={PopUpConfirmationType.INFO}
                height={"auto"}
                width={"auto"}
                loading={loading}
                disabledActions={loading}
            />
        </>
    );
};
