import { useTranslation } from "react-i18next";
import { IGenericPayload } from "../../../models";
import { exportStatiticsReaderRecordRatioAction, fetchStatiticsReaderRecordRatioAction } from "../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetReaderRecordRatioFilter, updateFilterProperty } from "../../../redux/reducers/statistics/readerRecordRatio/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsReaderRecordRatioList } from "./list/list";

export const StatisticsReaderRecordRatioContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.READER_RECORD_RATIO" });
    const { filter: filter } = useAppSelector((state) => state.statisticsReaderRecordRatioFilter);

    const dispatch = useAppDispatch();

    const onExportDataStaticsIncidenceOperator = () => {
        return dispatch(exportStatiticsReaderRecordRatioAction());
    };

    const updateHeaderPropierty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };
    const onApplyFilters = () => {
        return dispatch(fetchStatiticsReaderRecordRatioAction());
    };
    const onResetFilters = () => {
        dispatch(resetReaderRecordRatioFilter());
        onApplyFilters();
    };

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={<ExportDataIcon onExportCSV={onExportDataStaticsIncidenceOperator} />}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderPropierty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <StatisticsReaderRecordRatioList />
        </>
    );
};
