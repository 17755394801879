import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { SkeletonSizes } from "../../../../common/constants";
import { routeFeatures } from "../../../../common/constants/routeFeatures";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";

import { IGenericPayload, ViewType } from "../../../../models";
import { IUserRoleView, IView } from "../../../../models/requests/IUserRoleView";
import { onExitDraftRequestAction, setEditDraftRequestData } from "../../../../redux/actions/request/draft/draft";
import { fetchDraftListAction } from "../../../../redux/actions/request/draft/draftList";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setLoadingDraft, setStepDraft, updateDraftProperty } from "../../../../redux/reducers/request/draft/draft";
import { widths } from "../../../../styles/romeu/widths";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { RequestStepper } from "../../../common/user/stepper/requestStepper";
import { CertificatesDataContainer } from "./certificates/container";
import { FormDraftContainerStyle } from "./containerStyle.jss";
import { GoodInfoDataContainer } from "./goodInformation/container";
import { RequestUtiFormContainer } from "./uti/container";
import { generateRequestPropertySchema } from "./validation/generateRequestPropertySchema";
import { Spinner } from "../../../common/spinner/spinner";
import { IEditRequest } from "../../../../models/requests/IEditRequest";

interface IProps {
    onAbandon: () => void;
    groupId?: string;
}

export const DraftContainer: React.FC<IProps> = ({ onAbandon, groupId }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = FormDraftContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });
    const [enabledNavigation, setEnabledNavigation] = useState<boolean>(groupId ? true : false);
    const [showWarningCloseCreateRequest, setShowWarningCloseCreateRequest] = useState(false);
    const {
        userRoleViews: { viewPermission },
        draftRequest: { activeStep, toPresentDraft, loadingDraft },
        userData: {
            profile: { organizations },
        },
        organization: {
            organization: { id: currentOrganization },
        },
    } = useAppSelector((state) => state);

    let viewDraftPermission: IUserRoleView | undefined = useMemo(
        () =>
            toPresentDraft
                ? viewPermission.find((feature) => feature.id === routeFeatures.REQUEST)
                : viewPermission.find((feature) => feature.id === routeFeatures.DRAFT),
        [toPresentDraft],
    );

    let viewConfig = useMemo(() => {
        return viewDraftPermission?.views.find((view) => view.type === ViewType.CREATE) as IView;
    }, [viewDraftPermission?.views]);

    const schema = useMemo(() => {
        return generateRequestPropertySchema(viewConfig.fields);
    }, [viewConfig.fields]);

    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    const onSelectorChange = (option: IDropdownOption, name: string, optionalParam?: any) => {
        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        dispatch(updateDraftProperty(payload));

        if (optionalParam) {
            const payload: IGenericPayload = {
                name: compileNameOfProperty<IEditRequest>("representativeId"),
                value: optionalParam,
            };
            dispatch(updateDraftProperty(payload));
        }
    };

    const onDateChange = (date: Date | undefined | null, name: string) => {
        const payload: IGenericPayload = {
            value: date,
            name,
        };

        dispatch(updateDraftProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateDraftProperty(payload));
        },
        [dispatch],
    );

    const resetPropertyWhenParentChange = (name: string) => {
        const payload: IGenericPayload = {
            name,
            value: undefined,
        };
        dispatch(updateDraftProperty(payload));
    };

    const onYesNoOptionsChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: !!+option.key,
        };
        dispatch(updateDraftProperty(payload));
    };

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            const payload: IGenericPayload = {
                name: event.target.name,
                value: checked,
            };

            dispatch(updateDraftProperty(payload));
        },
        [dispatch],
    );

    const onRadioButtonChange = useCallback(
        (optionKey: string, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: !!+optionKey,
            };
            dispatch(updateDraftProperty(payload));
        },
        [dispatch],
    );

    const onButtonClick = useCallback(() => {
        if (activeStep === 4) {
            onAcceptWarningCreateRequest();
        } else {
            setShowWarningCloseCreateRequest(true);
        }
    }, [activeStep]);

    const onAcceptWarningCreateRequest = () => {
        onCancelWarningCreateRequest();
        onAbandon();
        dispatch(fetchDraftListAction());
    };

    const onCancelWarningCreateRequest = () => {
        setShowWarningCloseCreateRequest(false);
    };

    const navigateTo = (step: number) => {
        groupId && dispatch(setStepDraft(step));
    };
    const nodes = [
        {
            title: t("TITLES.UTI"),
            node: (
                <RequestUtiFormContainer
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={onDateChange}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onCheckBoxChange={onCheckboxChange}
                    onResumeAccept={onAcceptWarningCreateRequest}
                    editMode={groupId ? true : false}
                />
            ),
        },
        {
            title: t("TITLES.INFORMATION"),
            node: (
                <GoodInfoDataContainer
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={onDateChange}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onResumeAccept={onAcceptWarningCreateRequest}
                    editMode={groupId ? true : false}
                />
            ),
        },
        {
            title: t("TITLES.CERTIFICATES"),
            node: (
                <CertificatesDataContainer
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={onDateChange}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onRadioButtonChange={onRadioButtonChange}
                    onResumeAccept={onAcceptWarningCreateRequest}
                    editMode={groupId ? true : false}
                    organization={organization}
                />
            ),
        },
    ];
    useEffect(() => {
        return () => {
            dispatch(onExitDraftRequestAction());
        };
    }, []);

    useEffect(() => {
        if (toPresentDraft) {
            viewDraftPermission = viewPermission.find((feature) => feature.id === routeFeatures.REQUEST);
            viewConfig = viewDraftPermission?.views.find((view) => view.type === ViewType.CREATE) as IView;
            setEnabledNavigation(false);
            dispatch(setStepDraft(1));
        } else {
            setEnabledNavigation(true);
        }
    }, [toPresentDraft]);

    useEffect(() => {
        if (!groupId) {
            dispatch(setLoadingDraft(true));
        }
        groupId && !loadingDraft && dispatch(setEditDraftRequestData(groupId));
    }, []);

    return (
        <>
            <div className={styles.requestContainer}>
                {!loadingDraft ? (
                    <Spinner size={60} />
                ) : (
                    <RequestStepper
                        draft={true}
                        steps={nodes}
                        title={t(!groupId ? "TITLES.DRAFT_TITLE" : "TITLES.DRAFT_TITLE_EDIT")}
                        onClickButton={onButtonClick}
                        enabledNav={enabledNavigation}
                        navigateTo={navigateTo}
                    />
                )}
            </div>
            <PopUpConfirmation
                showPopUp={showWarningCloseCreateRequest}
                onCancel={onCancelWarningCreateRequest}
                onAccept={onAcceptWarningCreateRequest}
                type={PopUpConfirmationType.WARNING}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                message={t("CLOSE_REQUEST.MESSAGE")}
                buttonAcceptTitle={t("CLOSE_REQUEST.EXIT")}
            />
        </>
    );
};
