import { useTheme } from "react-jss";
import { ColorType } from "../../common/enum/ColorType";
import { useAppSelector } from "../../redux/hooks";
import { TitleH1, TitleH5 } from "../common/titles/titles";
import { SuggestionsMailboxStyle } from "./container.jss";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const SuggestionsMailboxContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "SUGGESTIONS_MAILBOX" });
    const theme = useTheme();
    const styles = SuggestionsMailboxStyle({ theme });

    const {
        userData: {
            profile: { organizations },
        },
    } = useAppSelector((state) => state);

    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <TitleH1
                    title={t("TITLE")}
                    color={ColorType.primary}
                />
            </div>
            <div className={styles.content}>
                <TitleH5 title={t("MESSAGE")} />
            </div>
            <div className={styles.content}>
                <a href={`mailto:${organization?.contactEmail}`}>{organization?.contactEmail}</a>
            </div>
        </div>
    );
};
