import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementUserSignupFilter } from "./filter/filter";
import { ManagementUserSignupList } from "./list/list";

export const ManagementUserSignupContainer = () => {
    const { t } = useTranslation("common");
    return (
        <>
            <NavHeaderComponent title={t("MANAGEMENT.USER_SIGNUP.TITLE")} />

            <ManagementUserSignupFilter />

            <ManagementUserSignupList />
        </>
    );
};
