import { Icon, IPivotItemProps, Pivot, PivotItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../../redux/hooks";
import { ManagementCustomerFormEntityData } from "../adminCustomer/entity/entity";
import { ManagementCustomerFormUserData } from "../adminCustomer/user/user";
import { ManagementUserDefaultFormStyle } from "../baseFormStyle.jss";
import { schemaRole } from "../../../../../common/enum/management/Schema";
import { ManagementUserFormOrganizationsTab } from "../common/organizationsTab/organizationsTab";
import { useDispatch } from "react-redux";
import { setManagementUserSelectedPivotKey } from "../../../../../redux/reducers/management/user/form";
interface IManagementUserCarrierUserFormProps {
    onInputChange: (event: any) => any;
    setIsAvailableSave: (value: any) => any;
}

export const ManagementUserCarrierUserForm: React.FC<IManagementUserCarrierUserFormProps> = ({ onInputChange, setIsAvailableSave }) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER.TABS" });
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const { selectedPivotKey, generalUserData } = useAppSelector((store) => store.managementUserForm);
    const [validUserDataTab, setValidUserDataTab] = useState<boolean>(false);
    const [validUserEntityDataBubble, setValidUserEntityDataBubble] = useState<boolean>(false);

    const setSelectedPivotKey = (key: string) => {
        dispatch(setManagementUserSelectedPivotKey(key));
    };
    
    const bubbleRender = (link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        let showBubble: boolean = false;
        switch (link.itemKey) {
            case "0":
                showBubble = !validUserDataTab;
                break;
            case "1":
                showBubble = !validUserEntityDataBubble;
                break;
        }

        return (
            <span style={{ flex: "0 1 100%" }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                {showBubble && (
                    <Icon
                        iconName={link.itemIcon}
                        style={{ color: "orange" }}
                    />
                )}
            </span>
        );
    };
    useEffect(() => {
        setIsAvailableSave(validUserDataTab && validUserEntityDataBubble ? true : false);
    }, [validUserDataTab, validUserEntityDataBubble]);

    return (
        <Pivot
            selectedKey={String(selectedPivotKey)}
            className={style.pivot}
            onLinkClick={(event) => {
                setSelectedPivotKey(event?.props.itemKey ? event?.props.itemKey : "0");
            }}
        >
            <PivotItem
                headerText={t("USER")}
                itemKey="0"
                itemIcon="LocationDot"
                onRenderItemLink={bubbleRender}
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementCustomerFormUserData
                        onInputChange={onInputChange}
                        generalUserData={generalUserData}
                        setIsValidTab={setValidUserDataTab}
                        role={schemaRole.CARRIER} userMigration={false}                    />
                </div>
            </PivotItem>

            <PivotItem
                headerText={t("ENTITY")}
                itemKey="1"
                itemIcon="LocationDot"
                onRenderItemLink={bubbleRender}
                alwaysRender={true}
            >
                <div className={style.pivotItem}>
                    <ManagementCustomerFormEntityData
                        generalUserData={generalUserData}
                        setIsValidTab={setValidUserEntityDataBubble}
                        role={schemaRole.CARRIER}
                    />
                </div>
            </PivotItem>
            
            {generalUserData.availableOrganizations.length > 1 && (
                <PivotItem
                headerText={t("ORGANIZATIONS")}
                itemKey="2"
                alwaysRender={true}
                >
                    <div className={style.pivotItem}>
                        <ManagementUserFormOrganizationsTab
                            generalUserData={generalUserData}
                        />
                    </div>
                </PivotItem>
            )}
        </Pivot>
    );
};
