import { Method, RoleType } from "../../../common/enum";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import { UserType } from "../../../common/enum/UserType";
import { IDocument, IRequestedDocument } from "../../../models";
import { IManagementUser } from "../../../models/management/user/IManagementUser";
import { IManagementUserFilter } from "../../../models/management/user/IManagementUserFilter";
import { IManagementUserForm } from "../../../redux/reducers/management/user/form";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";
import { compileNameOfProperty, getFileFromBlobUrl } from "../../../utils";
type MetadataItem = {
    DocumentId: string;
    FileName: string;
};
const baseURL = `${process.env.REACT_APP_API_URL}/users`;

export class ManagementUserApi {
    async getUserListAsync(filter: IManagementUserFilter): Promise<IManagementUser[]> {
        const url = `${baseURL}?name=${filter.name}&entityName=${filter.entityName}&unregistered=${
            filter.unregistered === undefined ? "" : filter.unregistered
        }&parentId=${filter.parentId}&email=${filter.email}&roleId=${filter.roleId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({ filter }).execute<IManagementUser[]>();
    }

    async getUserAsync(userId: string): Promise<IManagementUserForm> {
        const url = `${baseURL}/${userId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IManagementUserForm>();
    }

    async updateUserAsync(user: any): Promise<boolean> {
        const formData = await mapUserSignupToFormData(user, false);
        const url = `${baseURL}/${user?.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(formData).execute<boolean>();
    }

    async unregisterUserAsync(userId: any): Promise<boolean> {
        const url = `${baseURL}/${userId}/unregister`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody({}).execute<boolean>();
    }

    async registerUserAsync(userId: string): Promise<boolean> {
        const url = `${baseURL}/${userId}/register`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody({}).execute<boolean>();
    }
    async createUserAsync(user: IManagementUserForm): Promise<boolean> {
        const formData = await mapUserSignupToFormData(user, false);
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).execute<boolean>();
    }

    async migrateUserAsync(user: IManagementUserForm): Promise<boolean> {
        const formData = await mapUserSignupToFormData(user, true);
        const url = `${baseURL}/${user.id}/migrate`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(formData).execute<boolean>();
    }
}

const mapUserSignupToFormData = async (user: IManagementUserForm, userMigration: boolean): Promise<FormData> => {
    const formData = new FormData();
    formData.append(compileNameOfProperty<IManagementUserForm>("email"), user.email ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("name"), user.name);
    formData.append(compileNameOfProperty<IManagementUserForm>("surName"), user.surName ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("lastName"), user.lastName ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("phoneNumber"), user.phoneNumber ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("roleId"), user.roleId ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("crmCode"), user.crmCode ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("addMultipleActuation"), user.addMultipleActuation ? "true" : "false");
    user.externalCode && formData.append(compileNameOfProperty<IManagementUserForm>("externalCode"), user.externalCode ?? DefaultValues.NOT_TEXT);
    formData.append(compileNameOfProperty<IManagementUserForm>("hasRepresentativeData"), user.hasRepresentativeData ? "true" : "false");
    user.organismId && formData.append(compileNameOfProperty<IManagementUserForm>("organismId"), String(user.organismId) ?? DefaultValues.NOT_TEXT);
    user.terminalId && formData.append(compileNameOfProperty<IManagementUserForm>("terminalId"), String(user.terminalId) ?? DefaultValues.NOT_TEXT);
    !user.id && formData.append(compileNameOfProperty<IManagementUserForm>("parentId"), user.parentId ?? DefaultValues.NOT_TEXT);

    if (user.organizations.length > 0) {
        for (let organization of user.organizations) {
            formData.append(compileNameOfProperty<IManagementUserForm>("organizations"), organization.id ?? DefaultValues.NOT_TEXT);
        }
    }

    user.documentTypesDeleted.length > 0 &&
        formData.append(compileNameOfProperty<IManagementUserForm>("documentTypesDeleted"), JSON.stringify(user.documentTypesDeleted));

    let userType = "";
    if (user.roleId === RoleType.CARRIER_ADMIN || user.roleId === RoleType.CARRIER_USER) {
        userType = UserType.TRUCKER;
    } else if (user.roleId === RoleType.CUSTOMER_ADMIN || user.roleId === RoleType.CUSTOMER_USER) {
        userType = UserType.CUSTOMER;
    }

    userType && formData.append("userType", userType);

    let keys = ["name", "documentNumber", "socialAddress", "postalCode", "city", "province"];
    let item: any = user.entityData;
    for (let key of keys) {
        if (item[key]) {
            formData.append(`entityData.${key}`, item[key] ?? DefaultValues.NOT_TEXT);
        }
    }

    if (!!user.entityData && !!user.paymentData && user.roleId !== RoleType.CARRIER_ADMIN && user.roleId !== RoleType.CARRIER_USER) {
        let keys = ["paymentMethod", "accountNumber", "bankEntity"];
        let item: any = user.paymentData;
        for (let key of keys) {
            if (item[key]) {
                formData.append(`paymentData.${key}`, item[key] ?? DefaultValues.NOT_TEXT);
            }
        }
    }

    if (!!user.adminData && user.roleId !== RoleType.CARRIER_ADMIN && user.roleId !== RoleType.CARRIER_USER) {
        formData.append(`adminData.phone`, user.adminData.phone ?? DefaultValues.NOT_TEXT);
        formData.append(`adminData.mobilePhone`, user.adminData.mobilePhone ?? DefaultValues.NOT_TEXT);
        for (let email of user.adminData.emails) {
            formData.append(`adminData.emails`, email ?? DefaultValues.NOT_TEXT);
        }
    }
    if (!!user.adminData && user.roleId !== RoleType.CARRIER_ADMIN && user.roleId !== RoleType.CARRIER_USER) {
        formData.append(`operatorData.phone`, user.operatorData.phone ?? DefaultValues.NOT_TEXT);
        formData.append(`operatorData.mobilePhone`, user.operatorData.mobilePhone ?? DefaultValues.NOT_TEXT);
        for (let email of user.operatorData.emails) {
            formData.append(`operatorData.emails`, email ?? DefaultValues.NOT_TEXT);
        }
    }

    if (userMigration) {
        let listDocumetTypes: any = [];
        if (user.documents) {
            listDocumetTypes = setDocumentTypes(user.documents);
            appendRegisterUserFiles(formData, user.documents);
        }
        let blobsTypes = setDocumentTypesBlobs(user.blobs ?? []);
        let list = blobsTypes.concat(listDocumetTypes);
        formData.append(compileNameOfProperty<IManagementUserForm>("documentTypes"), JSON.stringify(list));

        return appendRegisterUserBlobs(formData, user.blobs ?? []);
    } else {
        formData.append(compileNameOfProperty<IManagementUserForm>("documentTypes"), JSON.stringify(setDocumentTypes(user.documents)));

        return appendRegisterUserFiles(formData, user.documents);
    }
};
const setDocumentTypesBlobs = (documents: IDocument[]) => {
    const metadataList: MetadataItem[] = [];
    documents.forEach((doc) => {
        const metadata: MetadataItem = {
            DocumentId: doc.type,
            FileName: `${doc.type}-${doc.fileName}` || "",
        };

        metadataList.push(metadata);
    });
    return metadataList;
};

const appendRegisterUserBlobs = async (formData: FormData, documents: IDocument[]) => {
    await Promise.all(
        documents.map(async (document: IDocument) => {
            const blob = await getFileFromBlobUrl(document.uri);
            blob && formData.append(compileNameOfProperty<IManagementUserForm>("documents"), blob, `${String(document.type)}-${document.fileName}`);
        }),
    );
    return formData;
};

const appendRegisterUserFiles = async (formData: FormData, documents: IRequestedDocument[]) => {
    await Promise.all(
        documents.map(async (document: IRequestedDocument) => {
            const blob = await getFileFromBlobUrl(document.file);
            blob &&
                formData.append(compileNameOfProperty<IManagementUserForm>("documents"), blob, `${String(document.documentId)}-${document.fileName}`);
        }),
    );

    return formData;
};

const setDocumentTypes = (documents: IRequestedDocument[]) => {
    const metadataList: MetadataItem[] = [];
    documents.forEach((doc) => {
        const metadata: MetadataItem = {
            DocumentId: String(doc.documentId),
            FileName: `${doc.documentId}-${doc.fileName}` || "",
        };
        metadataList.push(metadata);
    });
    return metadataList;
};
