import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterNotificationTypesControllerForm } from "../controller/controller";
import { MasterNotificationTypesColumns } from "./column/column";
import { fetchMasterNotificationTypesList } from "../../../../../../redux/actions/resources/master/notificationTypes/notificationTypes";
interface IMasterNotificationTypesList {
     widthPopUp:any;
    heigthPopUp:any;
}
export const MasterNotificationTypesList:React.FC<IMasterNotificationTypesList> = ({heigthPopUp, widthPopUp}) => {
    const dispatch = useAppDispatch();
    const { list, loading } = useAppSelector((state) => state.masterNotificationTypesList);
    
    const renderColumn = () => {
        return MasterNotificationTypesColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterNotificationTypesList());
    }, [list]);

    return loading ? (
        <Skeleton rows={2}></Skeleton>
    ) : (
        <>
            <CustomDetailsList
                data={list ?? []}
                renderColumns={renderColumn}
                height={"calc(100vh - 10rem)"}
           />
            <MasterNotificationTypesControllerForm  heigthPopUp={heigthPopUp} widthPopUp={widthPopUp}/>
        </>
    );
};
