import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux/hooks";
import { setConfigMerchandiseCategoryShowPopUp } from "../../../redux/reducers/config/merchandiseCategory/form";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ConfigMerchandiseCategoryFormController } from "./controller/controller";
import { ConfigMerchandiseCategoryFilter } from "./filter/filter";
import { ConfigMerchandiseCategoryList } from "./list/list";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

export const ConfigMerchandiseCategoryContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();

    return (
        <>
            <NavHeaderComponent
                title={t("MERCHANDISE_CATEGORY.TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setConfigMerchandiseCategoryShowPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />
            <ConfigMerchandiseCategoryFilter />
            <ConfigMerchandiseCategoryList />
            <ConfigMerchandiseCategoryFormController />
        </>
    );
};
