import { useTheme } from "react-jss";
import { UpsertInspectionStyle } from "./formStyle.jss";
import { compileNameOfProperty, getGenericPayloadFromEvent, requiredMessage } from "../../../../../utils";
import { IGenericPayload, Inspection } from "../../../../../models";
import { CertificateWhithOrganismSelector, OrganismSelector, YearSelector } from "../../selectors";
import { FormField } from "../../../../common/fields/fields";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { IDropdownOption } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { fetchOrganisms } from "../../../../../redux/actions/catalog/catalog";
import { setCertificatesByOrganism } from "../../../../../redux/reducers/catalog/catalog";
import { useTranslation } from "react-i18next";

interface IInspectionRequestFormProps {
    schema: any;
    updateInspectionProperty: (payload: IGenericPayload) => void;
    setValidForm: (value: boolean) => void;
    inspection: Inspection;
}

export const InspectionRequestForm: React.FC<IInspectionRequestFormProps> = ({ schema, setValidForm, updateInspectionProperty, inspection }) => {
    const theme = useTheme();
    const styles = UpsertInspectionStyle({ theme });
    const dispatch = useAppDispatch();
    const {
        register,
        control,
        formState: { errors, isValid },
    } = useForm<Inspection>({ mode: "all" });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });

    const [blockedDocumentNumber, setBlockedDocumentNumber] = useState<boolean>(true);
    const organismTypes = useAppSelector((store) => store.catalog.organisms.data);
    const allOrganisms = useAppSelector((store) => store.catalog.organisms.data);

    const handleChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        updateInspectionProperty(payload);
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            updateInspectionProperty(payload);
        },
        [dispatch],
    );

    const resetPropertyWhenParentChange = (name: string) => {
        updateInspectionProperty({ name, value: undefined });
    };

    const onCertificateTypeChange = (option: IDropdownOption, name: string) => {
        handleChange(option, name);
        blockedCertyficateNumber(inspection.organismId, Number(option.key));
        // dispatch(setInspectionCertificateType(Number(option.key)));
    };

    const blockedCertyficateNumber = (organismId?: number, certificateTypeId?: number) => {
        const organism = allOrganisms.find((item: any) => item.id === organismId);
        const certificate = organism?.certificates.find((item: any) => item.id === certificateTypeId);
        setBlockedDocumentNumber(certificate ? certificate?.automaticCertificateNumber : false);
        return certificate?.automaticCertificateNumber;
    };

    useEffect(() => {
        blockedCertyficateNumber(inspection.organismId, inspection.certificateTypeId);
        !organismTypes.length && dispatch(fetchOrganisms());
        if (inspection.organismId) {
            const organism = allOrganisms.find((item) => item.id === inspection.organismId);
            dispatch(setCertificatesByOrganism(organism ? organism.certificates : []));
        }
    }, [inspection.organismId, organismTypes]);

    useEffect(() => {
        if (blockedCertyficateNumber(inspection.organismId, inspection.certificateTypeId)) {
            updateInspectionProperty({ name: "certificateNumber", value: "" });
        }
    }, [inspection.certificateTypeId]);

    useEffect(() => {
        setValidForm(isValid);
    }, [isValid]);

    return (
        <div className={styles.upsertInspectionContainer}>
            <div className={styles.upsertInspectionData}>
                {schema.inspection_organismId.visible && (
                    <div className={styles.upsertInspectionRow}>
                        <OrganismSelector
                            onChange={handleChange}
                            selectedKey={inspection.organismId!}
                            propertyName={compileNameOfProperty<Inspection>("organismId")}
                            title={t("FIELDS.ORGANISM")}
                            isRequired={schema.inspection_organismId.required}
                            control={control}
                            rules={{ required: schema.inspection_organismId.required ? requiredMessage : false }}
                        />
                    </div>
                )}

                <div className={styles.upsertInspectionRow}>
                    {schema.inspection_certificateTypeId.visible && (
                        <CertificateWhithOrganismSelector
                            onChange={onCertificateTypeChange}
                            selectedKey={inspection.certificateTypeId!}
                            propertyName={compileNameOfProperty<Inspection>("certificateTypeId")}
                            title={t("FIELDS.CERTIFICATE")}
                            organismId={inspection.organismId!}
                            isRequired={schema.inspection_certificateTypeId.required}
                            resetSelectorAtRedux={resetPropertyWhenParentChange}
                            control={control}
                            rules={{
                                required: schema.inspection_certificateTypeId.required ? requiredMessage : false,
                                validate: () => {
                                    return !schema.inspection_certificateTypeId.required || !!inspection.certificateTypeId;
                                },
                            }}
                        />
                    )}
                    {schema.inspection_certificateNumber.visible && (
                        <FormField
                            type="text"
                            value={inspection.certificateNumber}
                            label={t("FIELDS.CERTIFICATE_NUMBER")}
                            error={errors.certificateNumber}
                            disabled={blockedDocumentNumber}
                            isRequired={blockedDocumentNumber ? !blockedDocumentNumber : schema.inspection_certificateNumber.required}
                            {...register(compileNameOfProperty<Inspection>("certificateNumber"), {
                                onChange: onInputChange,
                                value: inspection.certificateNumber,
                                required: blockedDocumentNumber ? !blockedDocumentNumber : schema.inspection_certificateNumber.required,
                            })}
                        />
                    )}
                </div>
                <div className={styles.upsertInspectionRow}>
                    {schema.inspection_exporter.visible && (
                        <FormField
                            type="text"
                            value={inspection.exporter}
                            label={t("FIELDS.EXPORTER")}
                            error={errors.exporter}
                            isRequired={schema.inspection_exporter.required}
                            {...register(compileNameOfProperty<Inspection>("exporter"), {
                                onChange: onInputChange,
                                value: inspection.exporter,
                                validate: () => (schema.inspection_exporter.required ? !!inspection.exporter : true),
                                required: schema.inspection_exporter.required ? requiredMessage : false,
                            })}
                        />
                    )}
                    {schema.inspection_importer.visible && (
                        <FormField
                            type="text"
                            value={inspection.importer}
                            label={t("FIELDS.IMPORTER")}
                            error={errors.importer}
                            isRequired={schema.inspection_importer.required}
                            {...register(compileNameOfProperty<Inspection>("importer"), {
                                onChange: onInputChange,
                                value: inspection.importer,
                                validate: () => (schema.inspection_importer.required ? !!inspection.importer : true),
                                required: schema.inspection_importer.required ? requiredMessage : false,
                            })}
                        />
                    )}
                </div>

                {schema.inspection_year.visible && (
                    <div className={styles.upsertInspectionRow}>
                        <YearSelector
                            selectedKey={inspection.year!}
                            onChange={handleChange}
                            propertyName={compileNameOfProperty<Inspection>("year")}
                            title={t("FIELDS.YEAR")}
                            yearsAfter={5}
                            isRequired={schema.inspection_year.required}
                            control={control}
                            rules={{ required: schema.inspection_year.required ? requiredMessage : false }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
