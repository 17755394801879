import { Method } from "../../../common/enum";

import { IControlExpedientFilter, IControlExpedientForm, IControlExpedientItem } from "../../../models/control/expedient/IExpedient";

import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/control/expedients`;

export class ControlAPI {
    async getExpedientsList(filter : IControlExpedientFilter): Promise<IControlExpedientItem[]> {
        const url = `${baseURL}/search`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({filters: filter}).execute<IControlExpedientItem[]>();
    }

    async getExpedient(idExpedient : number): Promise<IControlExpedientForm> {
        const url = `${baseURL}/${idExpedient}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IControlExpedientForm>();
    }
    async deleteExpedient(idExpedient : number): Promise<IControlExpedientForm> {
        const url = `${baseURL}/${idExpedient}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<IControlExpedientForm>();
    }
    async postExpedient(expedient : IControlExpedientForm): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(expedient).execute<boolean>();
    }
    async putExpedient(expedient : IControlExpedientForm): Promise<boolean> {
        const url = `${baseURL}/${expedient.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(expedient).execute<boolean>();
    }
}
