import { IRole } from "../common/IRole";
import { IUserOrganization } from "./IUserOrganization";

export interface IUserAuthData {
    email: string;
    parentId?:string;
    name: string;
    surName: string;
    lastName: string;
    phoneNumber: string;
    organismId?: number;
    image: string;
    organizations: IUserOrganization[];
    roles: IRole[];
    principalRole: string;
    impersonatedOrganismId?: number;
}

export const emptyUserAuthData = (): IUserAuthData => {
    return {
        email: "",
        name: "",
        surName: "",
        lastName: "",
        phoneNumber: "",
        image: "",
        organizations: [],
        roles: [],
        principalRole: "",
    };
};
