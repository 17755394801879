import { IOrganismStatic } from "../../../../models/statistics/inspection/IDetailed";

export const dinamicItemData = (list: any[], organism: IOrganismStatic, percent?: string) => {
    let value = 0;
    if (list && list.length) {
        for (let item of list) {
            if (percent && item.id === organism.id) {
                value = item.requestsPositionedWithInvoiceConceptsPercentage ? item.requestsPositionedWithInvoiceConceptsPercentage : 0;
                break;
            }

            if (item.id === organism.id) {
                value = item.requestsPositionedWithInvoiceConcepts;
                break;
            }
        }
    } 

    return value;
};
