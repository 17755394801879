import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterTurnsFormController } from "../controller/controller";
import { MasterTurnsColumns } from "./column/column";
import { fetchMasterTurnsList } from "../../../../../../redux/actions/resources/master/turns/turns";
interface IMasterTurnsList {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterTurnsList: React.FC<IMasterTurnsList> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { loading, list } = useAppSelector((state) => state.masterTurnsList);

    const renderColumns = () => {
        return MasterTurnsColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterTurnsList());
    }, [list]);

    return (
        <>
            {loading ? (
                <Skeleton rows={5}></Skeleton>
            ) : (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                />
            )}
            <MasterTurnsFormController
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </>
    );
};
