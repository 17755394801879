import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface IVlcportHistoricForm {
    response: string;
    messaageData: string;
}
interface IVlcportHistoricFormState {
    form: IVlcportHistoricForm;
    showPopUp: PopUpCodes;
}

const initialState: IVlcportHistoricFormState = {
    form: {
        response: "",
        messaageData: "",
    },
    showPopUp: PopUpCodes.NONE,
};

export const vlcportHistoricFormReducer = createSlice({
    name: "vlcportHistoricFormReducer",
    initialState,
    reducers: {
        resetVlcportHistoricForm: () => initialState,
        updateVlcportHistoricFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setVlcportHistoricFormPopUpShow: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setVlcportHistoricForm: (state, action: PayloadAction<IVlcportHistoricForm>) => {
            state.form = action.payload;
        },
    },
});

export const { resetVlcportHistoricForm, updateVlcportHistoricFormProperty, setVlcportHistoricFormPopUpShow, setVlcportHistoricForm } =
    vlcportHistoricFormReducer.actions;

export default vlcportHistoricFormReducer.reducer;
