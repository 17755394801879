import { stringDefaultValue } from "../../../utils";

export interface IManagementUserFilter {
    name: string;
    entityName: string;
    parentId: string;
    email: string;
    roleId: string;
    unregistered?: boolean;
}

export const emptyManagementUserFilter = (): IManagementUserFilter => {
    return {
        name: "",
        entityName: "",
        parentId: "",
        email: "",
        roleId: stringDefaultValue.key.toString(),
        unregistered: false,
    };
};
