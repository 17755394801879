import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../common/constants";
import { Skeleton } from "../skeleton/skeleton";
import { CustomTableBody } from "./body/tableBody";
import { TableHeaderCustom } from "./head/tableHead";
import { TableCustomStyle } from "./tableCustomStyle.jss";

export type ColumnType<T, K extends keyof T> = {
    key: K;
    title: string;
    actions?: JSX.Element;
    showColumn?:boolean;
    className ?:string;
    width?:string,
    padding?:string

};

export type TableData<T, K extends keyof T> = {
    data: T[];
    columns: Array<ColumnType<T, K>>;
    loading: boolean;
    showHeader?: boolean;
    underActions?: (value?:any)=> any;
    breackResponse ?: string;
};

export interface IActionsTableResponse {
    actions?: JSX.Element;
}

export const CustomTableList = <T, K extends keyof T>({ data, columns, loading, showHeader,underActions, breackResponse }: TableData<T, K>): JSX.Element => {
    const isMobile = useMediaQuery({ query: breackResponse ? breackResponse : componentsMediaQueries.TABLET });
    const showHeaderTable = showHeader !== undefined ? showHeader : true;
    const { t } = useTranslation("common", { keyPrefix: "COMMON" });
    const theme = useTheme();
    const style = TableCustomStyle({ theme });

    const tableWhitLoading = loading ? (
        <Skeleton rows={3} />
    ) : (
        <div className={style.container}>
            <div>{!isMobile && showHeaderTable && <TableHeaderCustom columns={columns} />}</div>
            <div className={`${style.dataContainer}`}>
                <CustomTableBody
                    columns={columns}
                    data={data}
                    isMobile={isMobile}
                    underActions={underActions}
                />
            </div>
        </div>
    );

    return data.length ? tableWhitLoading : <div className={style.notData}>{t("NO_DATA")}</div>;
};
