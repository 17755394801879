import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsInspectionGroupedListColumn } from "./column/column";
import { useTheme } from "react-jss";
import { StatisticsInspectionGroupedContainerStyle } from "./listStyle.jss";
import { DetailsRow, IDetailsFooterProps } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { StatisticsInspectionGroupedListColumnFooter } from "./column/footer";
import { useEffect } from "react";
import { fetchStatisticsInspectionGroupedFilterAction } from "../../../../redux/actions/statistics/statistics";
import { SkeletonSizes } from "../../../../common/constants";

export const StatisticsInspectionGroupedContainer = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const style = StatisticsInspectionGroupedContainerStyle({ theme });
    const { loading, inspectionGroupedStatistics: inspectionStatistics } = useAppSelector((store) => store.statiticsInspectionGroupedList.data);

    const onRenderColumns = () => {
        return StatisticsInspectionGroupedListColumn();
    };

    useEffect(() => {
        dispatch(fetchStatisticsInspectionGroupedFilterAction());
    }, []);

    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };
        const totalFooterColumns = StatisticsInspectionGroupedListColumnFooter(dataFooter);

        return (
            <>
                <DetailsRow
                    {...detailsFooterProps}
                    columns={totalFooterColumns}
                    item={{}}
                    itemIndex={5}
                    groupNestingDepth={detailsFooterProps.groupNestingDepth}
                    selection={detailsFooterProps.selection}
                    styles={style}
                />
            </>
        );
    };

    return !loading ? (
        <CustomDetailsList
            data={inspectionStatistics}
            renderColumns={onRenderColumns}
            height={heights.statistics.inspection}
            customFooter={_onRenderDetailsFooter}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
