import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterAppointmentTypeActuation } from "../../../../models/masterAppointment/IAppointmentTypeActuation";

export interface IMasterAppointmentTypeActuationListState {
    list?: IMasterAppointmentTypeActuation[];
    loading: boolean;
}

const initialState: IMasterAppointmentTypeActuationListState = {
    loading: false,
};

export const masterAppointmentTypeActuationList = createSlice({
    name: "masterAppointmentTypeList",
    initialState,
    reducers: {
        resetMasterAppointmentTypeActuationList: () => initialState,
        setLoadingAppointmentTypeActuationList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentTypeActuationList: (state, action: PayloadAction<IMasterAppointmentTypeActuation[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterAppointmentTypeActuationList, setLoadingAppointmentTypeActuationList, setMasterAppointmentTypeActuationList } =
    masterAppointmentTypeActuationList.actions;

export default masterAppointmentTypeActuationList.reducer;
