import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";

export interface IAssignInspector {
    expedientNumber: string;
    inspectorId: string;
    organismId: number;
    requestId: number;
    inspectionId: number;
}
export interface IUnassignInspector {
    requestId: number;
    expedientNumber: string;
    inspectionId: number;
    inspector?: string;
}

interface EditRequestAsingInspectorState {
    form: IAssignInspector;
    loading: boolean;
    unassignInspector: IUnassignInspector;
}

const initialState: EditRequestAsingInspectorState = {
    form: {
        expedientNumber: "aaa",
        inspectorId: "",
        organismId: 0,
        requestId: 0,
        inspectionId: 0,
    },
    loading: false,
    unassignInspector: {
        inspectionId: 0,
        requestId: 0,
        expedientNumber: "",
    },
};

export const assignInspectorSlice = createSlice({
    name: "assignInspectorReducer",
    initialState,
    reducers: {
        updateAssignInspectorFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setAssignInspectorFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        resetAssignInspectorFormDataState: () => initialState,
        setAssignInspectorFormData: (state, action: PayloadAction<IAssignInspector>) => {
            state.form = action.payload;
        },
        setUnassignInspectorFormData: (state, action: PayloadAction<IUnassignInspector>) => {
            state.unassignInspector = action.payload;
        },
        resetUnassignInspectorFormData: (state) => {
            state.unassignInspector = {
                expedientNumber: "",
                inspectionId: 0,
                requestId: 0,
            };
        },
        resetAssignInspectorFormData: (state) => {
            state.form = {
                expedientNumber: "",
                inspectorId: "",
                organismId: 0,
                requestId: 0,
                inspectionId: 0,
            };
        },
    },
});

export const {
    updateAssignInspectorFormProperty,
    setAssignInspectorFormLoading,
    setAssignInspectorFormData,
    resetAssignInspectorFormData,
    resetUnassignInspectorFormData,
    setUnassignInspectorFormData,
} = assignInspectorSlice.actions;

export default assignInspectorSlice.reducer;
