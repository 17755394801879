import { userSignupTypesAdapter } from "../../../reducers/user/signup/types";
import { RootState } from "../../../store";

export const userSignupTypesSelector = (state: RootState) => {
    return state.userSignupTypes;
};

export const getUserSignupTypesSelector = (state: RootState) => {
    return userSignupTypesSelector(state);
};

export const getAllUserTypesSelector = userSignupTypesAdapter.getSelectors<RootState>((state) => state.userSignupTypes);

export const getLoadingTypes = (state: RootState) => {
    return state.userSignupTypes.isLoading;
};
