import { IDropdownOption } from "@fluentui/react";
import React from "react";
import { yesNoOptions, yesNoOptionsWithDefault } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface IBooleanSelectorProps extends IBaseSelectorProps {
    calloutMessage?: string;
    allOption?: boolean;
}

export const BooleanSelector: React.FC<IBooleanSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    disabled,
    isRequired,
    control,
    rules,
    calloutMessage,
    allOption,
    calloutLabel,
}) => {
    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };
    const effectiveSelectedKey = selectedKey !== null ? selectedKey : undefined;
    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={effectiveSelectedKey}
            options={allOption ? yesNoOptionsWithDefault : yesNoOptions}
            label={title}
            disabled={disabled}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
            isBooleanSelector={true}
            calloutLabel={calloutLabel}
            calloutMessage={calloutMessage}
        />
    );
};
