import { ReactChild, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { SkeletonSizes } from "../../../../common/constants";
import { routeFeatures } from "../../../../common/constants/routeFeatures";
import { fetchDraftListAction } from "../../../../redux/actions/request/draft/draftList";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getUserClaimsLoaded } from "../../../../redux/selectors/user/claims/claims";
import { ButtonPrimary } from "../../../common/buttons/buttons";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { NavHeaderComponent } from "../../../common/navHeader/navHeader";
import { PopUp } from "../../../common/popup/popUp";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { DraftColumns } from "./columns/columns";
import { DraftContainer } from "../form/container";
import { RequestDraftListStyle } from "./list.jss";
import { sortArray } from "../../../../utils";
import { RequestCard } from "../../list/card/requestCard";

export const RequestDraftList = () => {
    const theme = useTheme();
    const styles = RequestDraftListStyle({ theme });
    const dispatch = useAppDispatch();
    const { loading, list, sort } = useAppSelector((store) => store.draftlist);
    const { t } = useTranslation("common");
    const [isCreateRequestVisible, setCreateRequestVisible] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });
    const { isViewLoaded } = useAppSelector((state) => state.userRoleViews);
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);

    useEffect(() => {
        isUserClaimsLoaded && !loading && dispatch(fetchDraftListAction());
    }, [dispatch, isUserClaimsLoaded]);

    const onClosePopUp = useCallback(() => {
        setCreateRequestVisible(false);
    }, []);

    const onCreateRequestClick = useCallback(() => {
        setCreateRequestVisible(true);
    }, []);

    const actionButtons: ReactChild = (
        <>
            <PopUp
                children={<DraftContainer onAbandon={onClosePopUp} />}
                state={isCreateRequestVisible}
                isBlocking={true}
                skipHeader={true}
                skipPadding={true}
            />
            <ButtonPrimary
                title={t("DRAFT.BUTTON.ADD")}
                handleClick={onCreateRequestClick}
            />
        </>
    );

    const renderColumns = () => {
        return DraftColumns(routeFeatures.DRAFT);
    };

    const listResponsive = () => {
        return !isMobile ? (
            <CustomDetailsList
                data={sortArray(list, sort)}
                renderColumns={renderColumns}
                height={"100%"}
            />
        ) : (
            <div className={styles.cardList}>
                {list.map((value) => {
                    return (
                        <RequestCard
                            key={value.id}
                            data={value}
                            forceShow={false}
                            showEdit={false}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.generalContainer}>
            <NavHeaderComponent
                title={t("DRAFT.MY_DRAFT")}
                action={actionButtons}
            />
            <div className={styles.requestContainer}>
                {!isUserClaimsLoaded || !isViewLoaded ? <Skeleton rows={SkeletonSizes.REQUEST} /> : listResponsive()}
            </div>
        </div>
    );
};
