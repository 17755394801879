import { IColumn } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IGenericPayload } from "../../../../../models";
import { IManagementNotification } from "../../../../../models/management/notification/IManagementNotification";
import { getMananagementNotificationAction } from "../../../../../redux/actions/management/notification/notication";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setManagementnotificationFormPopUp, updateManagementNotificationProperty } from "../../../../../redux/reducers/management/notification/form";
import { updateSortColumn } from "../../../../../redux/reducers/management/notification/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { ManagementNotificationListColumnStyle } from "./columnStyle.jss";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";

export const ManagementNotificationListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.NOTIFICATION" });
    const theme = useTheme();
    const styles = ManagementNotificationListColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.managementNotificationList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().managementNotificationList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IManagementNotification>("roleDescription"),
            minWidth: 200,
            maxWidth: 350,
            name: t("ROLE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementNotification>("roleDescription")}
                    title={t("ROLE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementNotification>("roleDescription"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementNotification) => <span>{item.roleDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementNotification>("notificationModeDescription"),
            minWidth: 150,
            maxWidth: 350,
            name: t("NOTIFICATIONMODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementNotification>("notificationModeDescription")}
                    title={t("NOTIFICATIONMODE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementNotification>("notificationModeDescription"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementNotification) => <span>{item.notificationModeDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementNotification>("notificationTypeDescription"),
            minWidth: 300,
            maxWidth: 500,
            name: t("NOTIFICATIONTYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementNotification>("notificationTypeDescription")}
                    title={t("NOTIFICATIONTYPE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementNotification>("notificationTypeDescription"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementNotification) => <span>{item.notificationTypeDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementNotification>("otherEmails"),
            minWidth: 100,
            maxWidth:150,
            name: t("OTHER_EMAILS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementNotification>("otherEmails")}
                    title={t("OTHER_EMAILS")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementNotification>("otherEmails"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementNotification) => <span><BooleanValue value={item.otherEmails}></BooleanValue></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementNotification>("scheduleTime"),
            minWidth: 150,
            maxWidth: 200,
            name: t("SCHEDULE_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementNotification>("scheduleTime")}
                    title={t("SCHEDULE_TIME")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementNotification>("scheduleTime"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementNotification) => <span><BooleanValue value={item.scheduleTime}></BooleanValue></span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IManagementNotification>("activeByDefault"),
            minWidth: 150,
            maxWidth: 200,
            name: t("ACTIVEBYDEFAULT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementNotification>("activeByDefault")}
                    title={t("ACTIVEBYDEFAULT")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IManagementNotification>("activeByDefault"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IManagementNotification) => <span> <BooleanValue value={item.activeByDefault}></BooleanValue></span>,
            isResizable: true,
        },
        {
            key: "",
            minWidth: 100,
            maxWidth: 100,
            name: "",
            onRender: (item: IManagementNotification) => (
                <div className={styles.buttonsContainer}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setManagementnotificationFormPopUp(PopUpCodes.UPDATE));
                            dispatch(getMananagementNotificationAction(String(item.id)));
                        }}
                    ></CustomIcons>

                    <Separator size="none"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            let payload: IGenericPayload = {
                                name: compileNameOfProperty<IManagementNotification>("id"),
                                value: item.id,
                            };
                            dispatch(updateManagementNotificationProperty(payload));
                            dispatch(setManagementnotificationFormPopUp(PopUpCodes.DELETE));
                        }}
                    ></CustomIcons>
                </div>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
