import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { emptyStatisticsFilter, IStatisticsFilter } from "../../../../models/statistics/IStatisticsFilters";

interface IStatiticsDailyChargeFilterState {
    filter: IStatisticsFilter;
}

const initialState: IStatiticsDailyChargeFilterState = {
    filter: emptyStatisticsFilter(),
};

export const statisticsDailyChargeFilterSlice = createSlice({
    name: "statisticsDailyChargeFilterSlice",
    initialState,
    reducers: {
        resetDailyChargeFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetDailyChargeFilter,
    updateFilterProperty,
} = statisticsDailyChargeFilterSlice.actions;

export default statisticsDailyChargeFilterSlice.reducer;
