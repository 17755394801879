import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../../../../../../common/enum/dateTime/FormatDate";
import { IConfigEditRequest } from "../../../../../../../../models/requests/IConfigEditRequest";
import { IRequestStateHistory, IRequestStateHistoryWithIndex } from "../../../../../../../../models/requests/IRequestStateHistory";
import { useAppDispatch } from "../../../../../../../../redux/hooks";
import { updateRequestStateHistoryFromList } from "../../../../../../../../redux/reducers/request/edit/editRequest";
import { compileNameOfProperty } from "../../../../../../../../utils";
import { convertToLocal } from "../../../../../../../../utils/dateUtils";
import { ControlledDatePicker } from "../../../../../../../common/datePicker/controlledDatePicker";
import { TimePickerComponent } from "../../../../../../../common/timePicker/timePicker";
import { StateHistoryItemStyles } from "./stateHistoryItem.jss";

interface IProps {
    index: number;
    stateHistory: IRequestStateHistory;
    schema: IConfigEditRequest;
    errors?: number[];
}

export const StateHistoryItem: React.FC<IProps> = ({ index, stateHistory, schema, errors }) => {
    const theme = useTheme();
    const styles = StateHistoryItemStyles({ theme });
    const dispatch = useAppDispatch();
    const { control } = useForm<IRequestStateHistory>({ mode: "onChange" });

    const onDateChange = (date: Date | undefined | null) => {
        if (date) {
            const oldDate = new Date(stateHistory.date);
            let updateStateHistory: IRequestStateHistory = Object.assign({}, stateHistory);
            updateStateHistory.date = new Date(
                new Date(date.getFullYear(), date.getMonth(), date.getDate(), oldDate.getHours(), oldDate.getMinutes()).toISOString(),
            );

            dispatch(updateRequestStateHistoryFromList({ index: index, stateHistory: updateStateHistory } as IRequestStateHistoryWithIndex));
        }
    };

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            if (date) {
                let updateStateHistory: IRequestStateHistory = Object.assign({}, stateHistory);

                updateStateHistory.date = new Date(new Date(date.toISOString()).setSeconds(0));
                dispatch(updateRequestStateHistoryFromList({ index: index, stateHistory: updateStateHistory } as IRequestStateHistoryWithIndex));
            }
        },
        [dispatch],
    );

    return (
        <div className={`${styles.container} ${!stateHistory.active ? styles.unactive : ""}`.trim()}>
            {schema.requestStateHistory_stateDescription.visible && <div className="state">{stateHistory.stateDescription}</div>}
            {schema.requestStateHistory_createdOn.visible && (
                <div>{convertToLocal(schema.requestStateHistory_date.visible ? stateHistory.createdOn : stateHistory.date, FormatDate.DATE)}</div>
            )}
            {schema.requestStateHistory_createdOn.visible && (
                <div>
                    {convertToLocal(schema.requestStateHistory_date.visible ? stateHistory.createdOn : stateHistory.date, FormatDate.HOUR_MINUTE)}
                </div>
            )}
            {schema.requestStateHistory_date.visible &&
                (stateHistory.active ? (
                    <>
                        <div>
                            <div className={styles.datePicker}>
                                <ControlledDatePicker
                                    key={stateHistory.id}
                                    name={compileNameOfProperty<IRequestStateHistory>("date")}
                                    label=""
                                    onDateChange={onDateChange}
                                    initValue={new Date(stateHistory.date)}
                                    control={control}
                                    hasError={errors?.includes(stateHistory.id)}
                                    disabled={schema.requestStateHistory_date.readonly}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>{convertToLocal(stateHistory.date, FormatDate.DATE)}</div>
                    </>
                ))}
            {schema.requestStateHistory_date.visible &&
                (stateHistory.active ? (
                    <>
                        <div>
                            <div className={styles.timePicker}>
                                <TimePickerComponent
                                    key={stateHistory.id}
                                    onChange={onTimeChange}
                                    value={stateHistory.date}
                                    label=""
                                    hasError={errors?.includes(stateHistory.id)}
                                    disabled={schema.requestStateHistory_date.readonly}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div>{convertToLocal(stateHistory.date, FormatDate.HOUR_MINUTE)}</div>
                    </>
                ))}
        </div>
    );
};
