import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterFinalDestinationType } from "../../../../../models/resources/master/IFinalDestinationType";

export interface IMasterFinalDestinationTypesFormState {
    form: IMasterFinalDestinationType;
    loading: boolean;
    warningPopUp :string;
}

const initialState: IMasterFinalDestinationTypesFormState = {
    form: {
        active: true,
        code: "",
        description: "",
        defaultValue:false
    },
    loading: false,
    warningPopUp:""

};

export const masterFinalDestinationTypesForm = createSlice({
    name: "masterDisplayDocksForm",
    initialState,
    reducers: {
        resetMasterFinalDestinationTypesForm: () => initialState,
        setLoadingMasterFinalDestinationTypesForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterFinalDestinationTypesForm: (state, action: PayloadAction<IMasterFinalDestinationType>) => {
            state.form = action.payload;
        },
        updateMasterFinalDestinationTypesProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setMasterFinalDestinationTypesWarning: (state, action: PayloadAction<string>) => {
            state.warningPopUp = action.payload;
        },
    },
});

export const {
    resetMasterFinalDestinationTypesForm,
    setLoadingMasterFinalDestinationTypesForm,
    setMasterFinalDestinationTypesForm,
    updateMasterFinalDestinationTypesProperty,
    setMasterFinalDestinationTypesWarning
} = masterFinalDestinationTypesForm.actions;

export default masterFinalDestinationTypesForm.reducer;
