import { Method } from "../../../common/enum";
import { IMenuSetting } from "../../../models/management/menuSetting/IMenySetting";
import { IManagementMenuSettingsByRoleByRoleFormState } from "../../../redux/reducers/management/menuSettingsByRole/form";

import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/catalog/menusettingsbyrole`;

export class ManagementMenuSettingsApi {
    async getMenuSettingsListAsync(form: IManagementMenuSettingsByRoleByRoleFormState): Promise<IMenuSetting[]> {
        const url = `${baseURL}/${form.roleId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody(form).execute<IMenuSetting[]>();
    }

    async updateMenuSettingsAsync(form: IManagementMenuSettingsByRoleByRoleFormState): Promise<boolean> {
        const url = `${baseURL}/${form.roleId}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form.form).execute<boolean>();
    }
}
