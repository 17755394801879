import React, { useEffect } from "react";
import { fetchDocks } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../../../common/selectors/customSelector/customSelector";

export const CustomDocksSelector: React.FC<ISelectorProps> = ({ onChange, selectedKey, name, title, disabled }) => {
    const { data } = useAppSelector((store) => store.catalog.docks);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchDocks());
    }, []);

    return (
        <CustomSelector
            title={title}
            options={mapIDescriptionToDropDownOptions(data)}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabled}
        ></CustomSelector>
    );
};
