import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../models";
import { fetchInspectorOptions } from "../../../../../../../redux/actions/user/users/users";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IAssignInspector, updateAssignInspectorFormProperty } from "../../../../../../../redux/reducers/request/list/form/assignInspector";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { RequestListExpedientsAddInspectorContainerStyle } from "./formStyle.jss";

export const RequestListExpedientsAddInspectorContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "LIST.EXPEDIENTS_DESCRIPTIONS.ASSIGN_INSPECTOR" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = RequestListExpedientsAddInspectorContainerStyle({ theme });

    const { form } = useAppSelector((store) => store.assignInspectorForm);
    const { organismId } = useAppSelector((store) => store.userData.profile);
    const { options } = useAppSelector((store) => store.usersByRole.allInspectors);


    const { register } = useForm<IAssignInspector>({ mode: "onChange" });
    const updatePropertyForm = (propertyName: string, value: any) => {
        const payload: IGenericPayload = {
            name: propertyName,
            value,
        };
        dispatch(updateAssignInspectorFormProperty(payload));
    };
    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );
    const onSelectorChange = (event: any, option: any, name: string) => {
        updatePropertyForm(name, option.key);
    };

    useEffect(() => {
        form.organismId && dispatch(fetchInspectorOptions(form.organismId ?? organismId));
    }, [organismId]);

    useMemo(() => {
        if (options.length === 1 && !form.inspectorId) {
            updatePropertyForm(compileNameOfProperty<IAssignInspector>("inspectorId"), options[0].key);
        }
    }, [options, form]);

    return (
        <div className={style.formContainer}>
            <FormField
                type="text"
                value={form.expedientNumber}
                maxLength={255}
                label={t("EXPEDIENT_NUMBER")}
                {...register(compileNameOfProperty<IAssignInspector>("expedientNumber"), {
                    onChange: onInputChange,
                    value: form.expedientNumber,
                    required: true,
                })}
                isRequired={true}
                disabled={true}
            />
            <Selector
                key={String(0) + t("INSPECTOR")}
                title={t("INSPECTOR")}
                options={options}
                selectedKey={form.inspectorId}
                name={compileNameOfProperty<IAssignInspector>("inspectorId")}
                onChange={onSelectorChange}
                required={true}
            />
        </div>
    );
};
