export enum FormatDate {
    DATE = "DD/MM/YYYY",
    YEAR = "YYYY",
    HOUR = "HH:mm:ss",
    HOUR_MINUTE = "HH:mm",
    DATE_MONTH = "D MMM YYYY",
    DATE_HOUR = "DD/MM/YYYY HH:mm:ss",
    DATE_HOUR_MINUTE = "DD/MM/YYYY HH:mm",
    DATE_HOUR_MINUTE_INVERSE = "HH:mm DD/MM/YYYY",
    ISO = "YYYY-MM-DD",
    FULL = "YYYY-MM-DDTHH:mm:ss",
}
