import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../assets/icons";
import { IEditRequest } from "../../../../../../models/requests/IEditRequest";
import { useAppSelector } from "../../../../../../redux/hooks";
import { compileNameOfProperty, convertToLocal } from "../../../../../../utils";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { booleanValidation, renderRequiredData } from "../../../create/validation/requestValidationUtils";
import { ControlledRadioButtonGroup } from "../../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { FormField, FormFieldTextarea } from "../../../../../common/fields/fields";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { CarrierSelector, FinalDestinationSelector, RepresentativePresenceTypeSelector } from "../../../../common/selectors";
import { requestMaxLength } from "../../../create/validation/requestConfig";
import { GeneralInformationEditContainerStyle } from "../containerStyle.jss";
import { IEditRequestStepProps } from "../../../../../../models/requests/request/IEditRequestStepProps";
import { DirectionType } from "../../../../../../common/enum/DirectionType";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { TrafficLightToogle } from "../../../../../common/toogle/toogle";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { TitleH5 } from "../../../../../common/titles/titles";
import { DefaultValues } from "../../../../../../common/enum/DefaultValues";

export const MerchandiseData: React.FC<IEditRequestStepProps> = ({
    schema,
    onInputChange,
    onSelectorChange,
    onRadioButtonChange,
    control,
    errors,
    register,
    setValue,
    organization,
    onToggleChange,
    disabledAppointmentData,
    onUpdateRequest,
}) => {
    const theme = useTheme();
    const style = GeneralInformationEditContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const { headerRequest } = useAppSelector((store) => store.editRequest);

    const showRadioButtonRow =
        (schema.fumigated.visible && organization?.fumigated) ||
        (schema.dryCharge.visible && organization?.dryCharge) ||
        (schema.organicProduct.visible && organization?.organicProduct) ||
        (schema.halalCertified.visible && organization?.halalCertified);
    const importerAndExporterRow = schema.exporter.visible || schema.importer.visible;
    const representativeAndDestinationRow = schema.representativePresenceId.visible || schema.finalDestinationTypeId.visible;

    return (
        <div className={style.container}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                {schema.parkingAuthorization.visible && organization?.parkingAuthorization && (
                    <>
                        <TitleH5
                            title={t("TITLES.PARKING_VALIDATION")}
                            color={ColorType.primary}
                            bold={true}
                        />

                        <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                            <div className={`${GridClass.SIMPLE_CELL} ${style.parkingValidation}`}>
                                <ControlledRadioButtonGroup
                                    selectedKey={String(+headerRequest?.automaticParkingAuthorization)}
                                    onRadioButtonChange={onRadioButtonChange}
                                    control={control}
                                    direction={DirectionType.horizontal}
                                    name={compileNameOfProperty<IEditRequest>("automaticParkingAuthorization")}
                                    label={t("FIELDS.AUTOMATICPARKINGAUTHORIZATION")}
                                    disabled={schema.parkingAuthorization.readonly}
                                />
                                <div className={style.parkingValidationAction}>
                                    <TrafficLightToogle
                                        checked={headerRequest.parkingAuthorization}
                                        name={compileNameOfProperty<IEditRequest>("parkingAuthorization")}
                                        onChage={onToggleChange}
                                        inlineLabel={true}
                                        label={t("FIELDS.PARKINGAUTHORIZATION")}
                                        disabled={headerRequest?.automaticParkingAuthorization || schema.parkingAuthorization.readonly}
                                    />
                                    <CustomIcons
                                        iconName={NameIcons.RELOAD}
                                        size={"1.2rem"}
                                        isButton={true}
                                        margin={"0px"}
                                        tooltip={t("FIELDS.PARKINGACTION")}
                                        isDisabled={schema.parkingAuthorization.readonly}
                                        onClick={() => onUpdateRequest && onUpdateRequest()}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {showRadioButtonRow && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.fumigated.visible && organization?.fumigated && (
                            <div className={GridClass.THREE_CELL}>
                                <ControlledRadioButtonGroup
                                    selectedKey={headerRequest?.fumigated !== null ? String(+headerRequest?.fumigated) : undefined}
                                    onRadioButtonChange={onRadioButtonChange}
                                    required={schema.fumigated.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.fumigated.required),
                                        validate: () => booleanValidation(schema.fumigated.required, headerRequest.fumigated),
                                    }}
                                    direction={DirectionType.horizontal}
                                    name={compileNameOfProperty<IEditRequest>("fumigated")}
                                    calloutLabel={t("FIELDS.FUMIGATED")}
                                    calloutMessage={t("INFO.FUMIGATED")}
                                    disabled={schema.fumigated.readonly || disabledAppointmentData}
                                />
                            </div>
                        )}
                        {schema.dryCharge.visible && organization?.dryCharge && (
                            <div className={GridClass.THREE_CELL}>
                                <ControlledRadioButtonGroup
                                    selectedKey={headerRequest?.dryCharge !== null ? String(+headerRequest?.dryCharge) : undefined}
                                    onRadioButtonChange={onRadioButtonChange}
                                    required={schema.dryCharge.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.dryCharge.required),
                                        validate: () => booleanValidation(schema.dryCharge.required, headerRequest.dryCharge),
                                    }}
                                    direction={DirectionType.horizontal}
                                    name={compileNameOfProperty<IEditRequest>("dryCharge")}
                                    calloutLabel={t("FIELDS.DRYCHARGE")}
                                    calloutMessage={t("INFO.DRY_CHARGE")}
                                    disabled={schema.dryCharge.readonly || disabledAppointmentData}
                                />
                            </div>
                        )}

                        {schema.organicProduct.visible && organization?.organicProduct && (
                            <div className={GridClass.THREE_CELL}>
                                <ControlledRadioButtonGroup
                                    label={t("FIELDS.ORGANICPRODUCT")}
                                    selectedKey={headerRequest?.organicProduct !== null ? String(+headerRequest?.organicProduct) : undefined}
                                    onRadioButtonChange={onRadioButtonChange}
                                    required={schema.organicProduct.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.organicProduct.required),
                                        validate: () => booleanValidation(schema.organicProduct.required, headerRequest.organicProduct),
                                    }}
                                    direction={DirectionType.horizontal}
                                    name={compileNameOfProperty<IEditRequest>("organicProduct")}
                                    disabled={schema.organicProduct.readonly || disabledAppointmentData}
                                />
                            </div>
                        )}
                        {schema.halalCertified.visible && organization?.halalCertified && (
                            <div className={GridClass.THREE_CELL}>
                                <ControlledRadioButtonGroup
                                    label={t("FIELDS.HALALCERTIFIED")}
                                    selectedKey={headerRequest?.halalCertified !== null ? String(+headerRequest?.halalCertified) : undefined}
                                    onRadioButtonChange={onRadioButtonChange}
                                    required={schema.halalCertified.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.halalCertified.required),
                                        validate: () => booleanValidation(schema.halalCertified.required, headerRequest.halalCertified),
                                    }}
                                    direction={DirectionType.horizontal}
                                    name={compileNameOfProperty<IEditRequest>("halalCertified")}
                                    disabled={schema.halalCertified.readonly || disabledAppointmentData}
                                />
                            </div>
                        )}
                    </div>
                )}

                {importerAndExporterRow && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.exporter.visible && (
                            <div className={GridClass.LONG_DOBLE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.exporter}
                                    maxLength={requestMaxLength.EXPORTER}
                                    label={t("FIELDS.EXPORTER")}
                                    isRequired={schema.exporter.required}
                                    disabled={schema.exporter.readonly}
                                    error={errors.exporter}
                                    {...register(compileNameOfProperty<IEditRequest>("exporter"), {
                                        onChange: onInputChange,
                                        validate: () => (schema.exporter.required ? !!headerRequest.exporter : true),
                                        required: renderRequiredData(schema.exporter.required),
                                    })}
                                />
                            </div>
                        )}
                        {schema.importer.visible && (
                            <div className={GridClass.LONG_DOBLE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.importer}
                                    maxLength={requestMaxLength.IMPORTER}
                                    label={t("FIELDS.IMPORTER")}
                                    isRequired={schema.importer.required}
                                    disabled={schema.importer.readonly}
                                    error={errors.importer}
                                    {...register(compileNameOfProperty<IEditRequest>("importer"), {
                                        onChange: onInputChange,
                                        validate: () => (schema.importer.required ? !!headerRequest.importer : true),
                                        required: renderRequiredData(schema.importer.required),
                                    })}
                                />
                            </div>
                        )}
                    </div>
                )}

                {representativeAndDestinationRow && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.representativePresenceId.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <RepresentativePresenceTypeSelector
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.representativePresenceTypeId}
                                    propertyName={compileNameOfProperty<IEditRequest>("representativePresenceTypeId")}
                                    title={t("FIELDS.INSPECTIONPRESENCE")}
                                    isRequired={schema.representativePresenceId.required}
                                    control={control}
                                    disabled={schema.representativePresenceId.readonly}
                                    rules={{ required: renderRequiredData(schema.representativePresenceId.required) }}
                                />
                            </div>
                        )}
                        {schema.finalDestinationTypeId.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FinalDestinationSelector
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.finalDestinationTypeId}
                                    propertyName={compileNameOfProperty<IEditRequest>("finalDestinationTypeId")}
                                    title={t("FIELDS.FINALDESTINATION")}
                                    isRequired={schema.finalDestinationTypeId.required}
                                    control={control}
                                    disabled={schema.finalDestinationTypeId.readonly}
                                    rules={{ required: renderRequiredData(schema.finalDestinationTypeId.required) }}
                                    setValue={setValue}
                                />
                            </div>
                        )}
                        {schema.c5Number.visible && (
                            <div className={GridClass.THREE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.c5Number}
                                    label={t("FIELDS.C5NUMBER")}
                                    isRequired={schema.c5Number.required}
                                    error={errors.c5Number}
                                    disabled={schema.c5Number.readonly}
                                    {...register(compileNameOfProperty<IEditRequest>("c5Number"), {
                                        onChange: onInputChange,
                                        validate: () => (schema.c5Number.required ? !!headerRequest.c5Number : true),
                                        required: renderRequiredData(schema.c5Number.required),
                                    })}
                                />
                            </div>
                        )}
                    </div>
                )}

                {(schema.carrierId.visible || schema.carrierPhone.visible) && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.carrierId.visible && (
                            <div className={GridClass.LONG_DOBLE_CELL}>
                                <CarrierSelector
                                    onChange={onSelectorChange}
                                    selectedKey={headerRequest.carrierId}
                                    title={t("FIELDS.CARRIERID")}
                                    propertyName={compileNameOfProperty<IEditRequest>("carrierId")}
                                    isRequired={schema.carrierId.required}
                                    control={control}
                                    disabled={schema.carrierId.readonly}
                                    rules={{ required: renderRequiredData(schema.carrierId.required) }}
                                />
                            </div>
                        )}

                        {schema.carrierPhone.visible && (
                            <div className={GridClass.LONG_DOBLE_CELL}>
                                <FormField
                                    type="text"
                                    value={headerRequest.carrierPhone}
                                    label={t("FIELDS.CARRIERPHONE")}
                                    isRequired={schema.carrierPhone.required}
                                    error={errors.carrierPhone}
                                    disabled={schema.carrierPhone.readonly}
                                    maxLength={requestMaxLength.PHONE_NUMBER}
                                    {...register(compileNameOfProperty<IEditRequest>("carrierPhone"), {
                                        onChange: onInputChange,
                                        validate: (value) => {
                                            if (!value && schema.carrierPhone.required) {
                                                return false;
                                            }

                                            if (value && value.toString().length !== requestMaxLength.PHONE_NUMBER) {
                                                return t("INFO.VALIDATION_PHONE_NUMBER", { value: requestMaxLength.PHONE_NUMBER });
                                            }

                                            return true;
                                        },
                                        required: renderRequiredData(schema.carrierPhone.required && !headerRequest.carrierPhone),
                                        maxLength: requestMaxLength.PHONE_NUMBER,
                                        minLength: 1,
                                    })}
                                />
                            </div>
                        )}
                    </div>
                )}

                {(schema.carrierDockAssignmentNotifiedOn.visible || schema.carrierDockAssignmentNotifiedOn.visible) && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        {schema.carrierDockAssignmentNotifiedOn.visible && (
                            <div className={GridClass.LONG_DOBLE_CELL}>
                                <FormField
                                    type="text"
                                    value={
                                        headerRequest.carrierDockAssignmentNotifiedOn
                                            ? convertToLocal(headerRequest.carrierDockAssignmentNotifiedOn, FormatDate.DATE_HOUR_MINUTE)
                                            : DefaultValues.EMPTY
                                    }
                                    label={t("FIELDS.CARRIERON")}
                                    isRequired={false}
                                    error={errors.carrierDockAssignmentNotifiedOn}
                                    disabled={true}
                                    {...register(compileNameOfProperty<IEditRequest>("carrierDockAssignmentNotifiedOn"), {
                                        onChange: onInputChange,
                                    })}
                                />
                            </div>
                        )}
                        {schema.carrierDockAssignmentNotifiedOn.visible && (
                            <div className={GridClass.LONG_DOBLE_CELL}>
                                <FormField
                                    type="text"
                                    value={
                                        headerRequest.carrierEndNotifiedOn
                                            ? convertToLocal(headerRequest.carrierEndNotifiedOn, FormatDate.DATE_HOUR_MINUTE)
                                            : DefaultValues.EMPTY
                                    }
                                    label={t("FIELDS.CARRIERENDNOTIFIEDON")}
                                    isRequired={false}
                                    error={errors.carrierEndNotifiedOn}
                                    disabled={true}
                                    {...register(compileNameOfProperty<IEditRequest>("carrierEndNotifiedOn"), {
                                        onChange: onInputChange,
                                    })}
                                />
                            </div>
                        )}
                    </div>
                )}

                {schema.remarks.visible && (
                    <div className={`${GridClass.ROW_GRID} ${style.row}`}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <FormFieldTextarea
                                value={headerRequest.remarks}
                                label={t("FIELDS.REMARKS")}
                                isRequired={schema.remarks.required}
                                error={errors.remarks}
                                disabled={schema.remarks.readonly}
                                {...register(compileNameOfProperty<IEditRequest>("remarks"), {
                                    onChange: onInputChange,
                                    validate: () => (schema.remarks.required ? !!headerRequest.remarks : true),
                                    required: renderRequiredData(schema.remarks.required),
                                })}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
