import { useTranslation } from "react-i18next";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { IRepresentative } from "../../../../../../models/user/representatives/IRepresentative";
import { useAppSelector } from "../../../../../../redux/hooks";
import { convertToLocal } from "../../../../../../utils";
import { ColumnType, CustomTableList } from "../../../../../common/tableCustom/tableCustom";
import { Representative } from "./item/container";
import { DefaultValues } from "../../../../../../common/enum/DefaultValues";

export const UserRepresentativeList = () => {
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE.REPRESENTATIVE" });
    const { representatives } = useAppSelector((state) => state.userProfile.profile.user);

    const columns: ColumnType<IRepresentative, any>[] = [
        { key: "representativeName", title: t("TITLES.COMPANY_NAME") },
        { key: "representativeDocumentNumber", title: t("TITLES.NIF") },
        { key: "startDate", title: t("TITLES.REPRESENTATIVE_DATE") },
        { key: "endDate", title: t("TITLES.REPRESENTATIVE_DATE_END") },
        { key: "actions", title: t("TITLES.VALIDATION_STATE") },
    ];
    const newRepresentativesTable = representatives
        ? representatives.map((item) => {
              return {
                  representativeName: item.representativeName ? item.representativeName : "",
                  representativeDocumentNumber: item.representativeDocumentNumber ? item.representativeDocumentNumber : "",
                  startDate: item.startDate ? convertToLocal(item.startDate, FormatDate.DATE) : DefaultValues.EMPTY,
                  endDate: item.endDate ? convertToLocal(item.endDate, FormatDate.DATE) : DefaultValues.EMPTY,
                  actions: <Representative validated={item.validated} />,
              };
          })
        : [];

    return (
        <CustomTableList
            data={newRepresentativesTable}
            columns={columns}
            loading={false}
        />
    );
};
