import { RequestApi } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setEditRequestHistoryChangeData, setEditRequestHistoryChangeLoading } from "../../../../reducers/request/edit/history/form";
import { setEditRequestHistoryRequestData, setEditRequestHistoryRequestLoading } from "../../../../reducers/request/edit/history/list";
import { AppThunk } from "../../../../store";

const requestApi = new RequestApi();
export const fetchEditRequestTimeLineHistory =
    (requestId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setEditRequestHistoryRequestLoading(true));

            const response = await requestApi.getRequestTimeLineHistory(requestId);

            dispatch(setEditRequestHistoryRequestData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setEditRequestHistoryRequestLoading(false));
        }
    };

export const fetchEditRequestHistoryChanges =
    (requestId: number, id: string, previousId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setEditRequestHistoryChangeLoading(true));
            const response = await requestApi.getRequestHistoryChange(requestId, id, previousId);
            dispatch(setEditRequestHistoryChangeData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setEditRequestHistoryChangeLoading(false));
        }
    };
