import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterTypesSealsForm, setMasterTypesSealsForm } from "../../../../reducers/resources/master/sealTypes/form";
import { setLoadingSealTypesList, setMasterSealTypesList } from "../../../../reducers/resources/master/sealTypes/list";
import { AppThunk, store } from "../../../../store";

const catalogAPI = new CatalogAPI();

export const fetchMasterSealTypesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingSealTypesList(true));
        const response = await catalogAPI.getMasterSealTypesList();
        dispatch(setMasterSealTypesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingSealTypesList(false));
    }
};

export const fetchMasterSealType =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterTypesSealsForm(true));
            const response = await catalogAPI.getMasterSealType(id);
            dispatch(setMasterTypesSealsForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterTypesSealsForm(false));
        }
    };

export const postToMasterSealTypesForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterSealTypesForm;

        try {
            dispatch(setLoadingMasterTypesSealsForm(true));
            if (form.id) {
                await catalogAPI.putMasterSealType(form);
            } else {
                await catalogAPI.postMasterSealType(form);
            }
            dispatch(fetchMasterSealTypesList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterTypesSealsForm(false));
            return false;
        }
    };
};

export const patchSortSealTypeList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingSealTypesList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterSealTypesList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingSealTypesList(false));
        }
    };
