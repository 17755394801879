import {  IDropdownOption } from "@fluentui/react";
import React, { useEffect } from "react";
import { fetchMerchandiseOrigins } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface IMerchandiseOriginSelector extends IBaseSelectorProps {
    allOption?: boolean;
}

export const MerchandiseOriginSelector: React.FC<IMerchandiseOriginSelector> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    disabled,
    isRequired,
    control,
    rules,
    allOption,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.merchandiseOrigins);
    const dispatch = useAppDispatch();
    useEffect(() => {
        data.length === 0 && dispatch(fetchMerchandiseOrigins());
    }, [dispatch, data]);

    const onSelectorChange = (_evt: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(data) : mapIDescriptionToDropDownOptions(data)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
