import { Method } from "../../../common/constants";
import { IAppointmentHistoricList } from "../../../models/appointment/IAppointmentHistoric";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment`;

export class AppointmentHistoricAPI {
    async getAppointmentHistoricAppointmentList(filter: any): Promise<IAppointmentHistoricList[]> {
        const url = `${baseURL}/preappointments`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapFilters(filter)).execute<IAppointmentHistoricList[]>();
    }
    async getAppointmentHistoricInidenceList(filter: any): Promise<IAppointmentHistoricList[]> {
        const url = `${baseURL}/incidences`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapFilters(filter)).execute<IAppointmentHistoricList[]>();
    }
    async getAppointmentHistoricInspectorList(filter: any): Promise<IAppointmentHistoricList[]> {
        const url = `${baseURL}/inspectors`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(mapFilters(filter)).execute<IAppointmentHistoricList[]>();
    }
}

const mapFilters = (filters: any) => {
    return { ...filters };
};
