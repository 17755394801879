import { createUseStyles } from "react-jss";

export const RequestPartialsStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        justifyContent: "start",
        gap: 3,
    },
    notSelect: {
        userSelect: "none",
    },

    actuation: (props: any) => ({
        color: props.color ? props.color : "black",
        fontWeight: theme.fonts.sizes.bold,
        width: 30,
        cursor: "default",
    }),
    letter: {
        width: 12,
        cursor: "default",
    },
}));
