import { useTheme } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../common/constants";
import { CustomTableActionsStyle } from "./actionsStyle";

interface ICustomTableActions {
    children: JSX.Element;
    className?: string;
    position?: string;
    breackResponse?:string;
}

export const CustomTableActions: React.FC<ICustomTableActions> = ({ children, position, breackResponse }) => {
    const isMobile = useMediaQuery({ query: breackResponse ? breackResponse : componentsMediaQueries.TABLET });

    const theme = useTheme();
    const newPosition = isMobile ? "center" : position;
    const style = CustomTableActionsStyle({ theme, isMobile, position: newPosition });

    return <div className={`${style.iconActions}`}> {children}</div>;
};
