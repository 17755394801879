import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FormEvent, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import {
    IValidationParkingAppointmentExceptionLine,
    IValidationParkingRuleException,
} from "../../../../../../../../../models/management/parkingValidationRule/IParkingValidationRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../redux/hooks";
import {
    setExceptionAppointmentRule,
    setExceptionRuleAppointmentExceptionHour,
    setExceptionRuleAppointmentTypeId,
} from "../../../../../../../../../redux/reducers/management/parkingValidation/form";
import { compileNameOfProperty, formatHMToDate, formatHour, mapIDescriptionToDropDownOptions } from "../../../../../../../../../utils";
import { Checkboxs } from "../../../../../../../../common/checkbox/checkbox";
import { FormNumericField } from "../../../../../../../../common/numberField/numericField";
import { Selector } from "../../../../../../../../common/selectors/selector";
import { TimePickerComponent } from "../../../../../../../../common/timePicker/timePicker";
import { appointmentFormStyle } from "./appointmentStyle.jss";

interface IExceptionAppointmentForm {
    data: IValidationParkingAppointmentExceptionLine;
    updateData: (payload: IValidationParkingRuleException) => any;
    disabledData: boolean;
}

export const ExceptionAppointmentForm: React.FC<IExceptionAppointmentForm> = ({ data, updateData, disabledData }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = appointmentFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PARKING_VALIDATION_RULE.FORM" });
    const { view } = useAppSelector((state) => state.managementParkingValidationConfiguration);
    const {
        form: { id: ruleId, validationParkingRuleExceptions },
    } = useAppSelector((state) => state.managementParkingValidationRuleForm);

    const { register } = useForm<IValidationParkingRuleException>({ mode: "onChange" });

    const exceptionConfiguration = useMemo(
        () =>
            validationParkingRuleExceptions.find(
                (exception) =>
                    exception.validationParkingExceptionId === data.validationParkingExceptionId &&
                    exception.validationParkingAppointmentExceptionLineId === data.id &&
                    (exception.deleted === false || exception.deleted === null),
            ),
        [validationParkingRuleExceptions, data],
    );

    const configuratonAppointmentForm = useMemo(
        () =>
            view?.validationParkingAppointmentExceptionTypes.find(
                (exceptionType) => exceptionType.id === exceptionConfiguration?.validationParkingAppointmentExceptionTypeId,
            ),
        [exceptionConfiguration?.validationParkingAppointmentExceptionTypeId, view],
    );

    const indexException = useMemo(
        () =>
            validationParkingRuleExceptions.findIndex(
                (exception) =>
                    exception.validationParkingExceptionId === data.validationParkingExceptionId &&
                    exception.validationParkingAppointmentExceptionLineId === data.id &&
                    (exception.deleted === false || exception.deleted === null),
            ),
        [validationParkingRuleExceptions, data.id],
    );

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            if (date) {
                dispatch(setExceptionRuleAppointmentExceptionHour({ index: indexException, hour: formatHour(date) }));
            }
        },
        [indexException],
    );

    const onNumberChange = useCallback(
        (event: any) => {
            dispatch(
                setExceptionRuleAppointmentExceptionHour({
                    index: indexException,
                    hour: event.target.value,
                }),
            );
        },
        [indexException],
    );

    const onSelectorChange = (_event: FormEvent<HTMLDivElement>, option: any, name: string) => {
        dispatch(setExceptionRuleAppointmentTypeId({ index: indexException, typeId: option.key }));
        dispatch(setExceptionRuleAppointmentExceptionHour({ index: indexException, hour: null }));
    };

    return (
        <div className={style.form}>
            <div className={style.ckeckBox}>
                <Checkboxs
                    name={""}
                    isChecked={!!exceptionConfiguration}
                    label={data.description}
                    disabled={disabledData}
                    handleChange={(ev, checked = false) => {
                        if (checked) {
                            let payload: IValidationParkingRuleException = {
                                id: null,
                                validationParkingExceptionId: data.validationParkingExceptionId,
                                validationParkingAppointmentExceptionHour: null,
                                validationParkingAppointmentExceptionLineId: data.id,
                                validationParkingAppointmentExceptionTypeId: null,
                                validationParkingInspectorExceptionLineId: null,
                                validationParkingRuleId: ruleId,
                                deleted: false,
                            };
                            updateData(payload);
                        } else {
                            const restriction = validationParkingRuleExceptions.find(
                                (restriction) =>
                                    restriction.validationParkingExceptionId === data.validationParkingExceptionId &&
                                    restriction.validationParkingAppointmentExceptionLineId === data.id,
                            );
                            restriction && dispatch(setExceptionAppointmentRule(restriction));
                        }
                    }}
                />
            </div>
            <div>
                <Selector
                    name=""
                    options={mapIDescriptionToDropDownOptions(view?.validationParkingAppointmentExceptionTypes ?? [])}
                    onChange={onSelectorChange}
                    selectedKey={exceptionConfiguration?.validationParkingAppointmentExceptionTypeId ?? ""}
                    title={t("DAY")}
                    disabled={!exceptionConfiguration}
                />
            </div>

            <div className={style.formField}>
                {configuratonAppointmentForm?.timeValue ? (
                    <TimePickerComponent
                        onChange={onTimeChange}
                        value={
                            exceptionConfiguration?.validationParkingAppointmentExceptionHour
                                ? formatHMToDate(exceptionConfiguration?.validationParkingAppointmentExceptionHour)
                                : null
                        }
                        label={t("HOUR")}
                        required={true}
                    />
                ) : (
                    <FormNumericField
                        value={exceptionConfiguration?.validationParkingAppointmentExceptionHour ?? ""}
                        label={t("HOUR")}
                        {...register(compileNameOfProperty<IValidationParkingRuleException>("validationParkingAppointmentExceptionHour"), {
                            onChange: onNumberChange,
                            value: exceptionConfiguration?.validationParkingAppointmentExceptionHour,
                        })}
                        isRequired={exceptionConfiguration?.validationParkingAppointmentExceptionTypeId ? true : false}
                        disabled={!exceptionConfiguration?.validationParkingAppointmentExceptionTypeId}
                    />
                )}
            </div>
        </div>
    );
};
