import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { IConfigCreateRequest } from "../../../../../models";
import { createDraftRequestAction } from "../../../../../redux/actions/request/draft/draft";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { nextStepDraft, previousStepDraft, setPresentDraft } from "../../../../../redux/reducers/request/draft/draft";
import { getCurrentDate, throwErrorToastrAsync } from "../../../../../utils";
import { ButtonPrimary, ButtonPrimaryInverseWithBorder } from "../../../../common/buttons/buttons";
import { PopUp } from "../../../../common/popup/popUp";
import { CustomToogle } from "../../../../common/toogle/toogle";
import { RequestButtonsStyle } from "./requestButtons.jss";
import { SummaryMessage } from "./summaryMessage/summaryMessage";
import { OrganismTypes, RoleType, OrganizationTypes, RepresentativePresenceTypes } from "../../../../../common/enum";

interface IProps {
    isValid: boolean;
    isLastStep: boolean;
    isFirstStep: boolean;
    showSummaryMessage?: boolean;
    schema: IConfigCreateRequest;
    editDraft: boolean;
    onCloseModal?: () => void;
}

export const RequestButtons: React.FC<IProps> = ({ isValid, isFirstStep, isLastStep, showSummaryMessage, schema, onCloseModal, editDraft }) => {
    const theme = useTheme();
    const styles = RequestButtonsStyle({ theme });
    const dispatch = useAppDispatch();
    const [showSummaryPopUp, setShowSummaryPopUp] = useState(false);
    const { t } = useTranslation("common");
    const { request, toPresentDraft } = useAppSelector((store) => store.draftRequest);
    const { certificateStepIsValid, informationStepIsValid, utiStepIsValid } = useAppSelector((store) => store.draftRequest.sectionValidation);
    const representativePresenceTypes = useAppSelector((store) => store.catalog.representativePresenceTypes.data);
    const representativePresenceType = representativePresenceTypes.find((fd) => fd.id === request.representativePresenceTypeId);
    const organims = useAppSelector((store) => store.catalog.organisms.data);
    const organism = organims.find((org) => org.code === OrganismTypes.CUSTOMS);
    const inspections = request.inspections.filter((ins) => ins.organismId === organism?.id);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);
    const currentUserRole = useAppSelector((store) => store.role.roleData.id);
    const [isValidData, setIsValidData] = useState(false);
    const [presentDraftRequest, setPresentDraftRequest] = useState(toPresentDraft ?? false);

    useEffect(() => {
        dispatch(setPresentDraft(presentDraftRequest));
    }, [presentDraftRequest]);

    useEffect(() => {
        setIsValidData(certificateStepIsValid && informationStepIsValid && utiStepIsValid);
    }, [isValidData, certificateStepIsValid, informationStepIsValid, utiStepIsValid]);

    const onSave = useCallback(() => {
        if (
            (currentUserRole === RoleType.CUSTOMER_ADMIN || currentUserRole === RoleType.CUSTOMER_USER) &&
            currentOrganization === OrganizationTypes.TNF &&
            representativePresenceType?.code === RepresentativePresenceTypes.NO &&
            inspections.length > 0
        ) {
            const error: Error = {
                message: t("CREATE_REQUEST.INFO.CUSTOMS_MESSAGE"),
                name: "",
            };
            return throwErrorToastrAsync(error);
        }

        if (toPresentDraft && request.date && request.date < getCurrentDate()) {
            const error: Error = {
                message: t("CREATE_REQUEST.INFO.REQUEST_VALIDATION_DATE"),
                name: "",
            };
            return throwErrorToastrAsync(error);
        }

        dispatch(createDraftRequestAction(toPresentDraft)).then((response: any) => {
            if (response) {
                if (onCloseModal) onCloseModal();
            }
        });
    }, [dispatch, representativePresenceType, inspections]);

    const onClickPrevious = useCallback(() => {
        dispatch(previousStepDraft());
    }, [dispatch]);

    const onClickNext = useCallback(() => {
        if (showSummaryMessage) {
            setShowSummaryPopUp(true);
        } else {
            dispatch(nextStepDraft());
        }
    }, [dispatch, showSummaryMessage]);

    const onClosePopUp = () => {
        setShowSummaryPopUp(false);
    };

    const onAccept = () => {
        setShowSummaryPopUp(false);
        dispatch(nextStepDraft());
    };

    return (
        <>
            <PopUp
                children={
                    <SummaryMessage
                        onAccept={onAccept}
                        onCancel={onClosePopUp}
                    />
                }
                state={showSummaryPopUp}
                hideModal={onClosePopUp}
                isBlocking={true}
                title={t("CREATE_REQUEST.TITLES.SUMMARY_TITLE")}
            />

            <div className={styles.requestButtonsContainer}>
                <div className={styles.disclaimer}>
                    <span>* </span> {t("CREATE_REQUEST.FIELDS.REQUIRED_FIELDS")}
                </div>
                <div className={styles.buttons}>
                    {!editDraft && !isFirstStep && (
                        <ButtonPrimaryInverseWithBorder
                            className="button"
                            handleClick={onClickPrevious}
                            title={t("CREATE_REQUEST.BUTTONS.BACK")}
                        />
                    )}
                    {!editDraft && !isLastStep && (
                        <ButtonPrimary
                            className="button"
                            disabled={!isValid}
                            handleClick={onClickNext}
                            title={t("CREATE_REQUEST.BUTTONS.NEXT")}
                        />
                    )}
                    {!editDraft && isLastStep && (
                        <ButtonPrimary
                            className="button"
                            disabled={!isValid}
                            handleClick={onSave}
                            title={t("CREATE_REQUEST.BUTTONS.SAVE")}
                        />
                    )}
                    {editDraft && (
                        <CustomToogle
                            checked={toPresentDraft}
                            onChage={() => setPresentDraftRequest(!!!presentDraftRequest)}
                            inlineLabel={true}
                            label={t("CREATE_REQUEST.BUTTONS.PRESENT")}
                        />
                    )}
                    {editDraft && !toPresentDraft && (
                        <ButtonPrimary
                            className="button"
                            handleClick={onSave}
                            title={t("CREATE_REQUEST.BUTTONS.SAVE")}
                        />
                    )}
                    {editDraft && toPresentDraft && !isFirstStep && (
                        <ButtonPrimaryInverseWithBorder
                            className="button"
                            handleClick={onClickPrevious}
                            title={t("CREATE_REQUEST.BUTTONS.BACK")}
                        />
                    )}{" "}
                    {editDraft && toPresentDraft && !isLastStep && (
                        <ButtonPrimary
                            className="button"
                            disabled={!isValid}
                            handleClick={onClickNext}
                            title={t("CREATE_REQUEST.BUTTONS.NEXT")}
                        />
                    )}
                    {editDraft && toPresentDraft && isLastStep && (
                        <ButtonPrimary
                            className="button"
                            disabled={!isValidData}
                            handleClick={onSave}
                            title={t("CREATE_REQUEST.BUTTONS.PRESENT")}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
