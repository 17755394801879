import React from "react";
import { useTheme } from "react-jss";
import { ButtonPrimary, ButtonPrimaryInverseWithBorder } from "../../buttons/buttons";
import { ActionsFilterHeaderStyle } from "./actionsFilterHeaderStyle.jss.";

interface IFilterHeaderActionButtonsProps {
    onApply: (event?: any) => void;
    onReset: (event?: any) => void;
    textApplyButton?: string;
    textResetButton?: string;
    column?:boolean;
}

export const FilterHeaderActionButtons: React.FC<IFilterHeaderActionButtonsProps> = ({ onApply, onReset, column }) => {
    const theme = useTheme();
    const style = ActionsFilterHeaderStyle({ theme, column });

    return (
        <div className={style.container}>
            <div>
                <ButtonPrimaryInverseWithBorder
                    handleClick={onReset}
                    icon="ClearFilter"
                />

                <ButtonPrimary
                    handleClick={onApply}
                    icon="Search"
                />
            </div>
        </div>
    );
};
