import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setCreateRegTempCheck } from "../../../../redux/reducers/request/create/createRequest";
import { setResetEditCheckRegTemp } from "../../../../redux/reducers/request/edit/editRequest";
import { Checkboxs } from "../../../common/checkbox/checkbox";

interface IPropsRegCheckTemp {
    name?: string;
    label?: string;
    isChecked: boolean;
    handleChange?: (e: any) => any;
    disabled?: boolean;
    required?: boolean;
    isIndeterminate?: boolean;
    selectedCustomState: number;
    isCreateRequest: boolean;
    setShowTemporyReg?: (value: boolean) => void;
}

export const RegCheckTemp = (props: IPropsRegCheckTemp) => {
    const { data } = useAppSelector((store) => store.catalog.customsStates);
    const itemSelected = data.find((item) => item.id === props.selectedCustomState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (itemSelected && !itemSelected.temporaryRegime) {
            if (props.isCreateRequest) {
                dispatch(setCreateRegTempCheck(false));
            } else {
                dispatch(setResetEditCheckRegTemp(false));
            }
        }
    }, [itemSelected]);

    useEffect(() => {
        props.setShowTemporyReg && props.setShowTemporyReg(itemSelected && itemSelected.temporaryRegime ? itemSelected.temporaryRegime : false);
    }, [itemSelected]);

    return itemSelected && itemSelected.temporaryRegime ? (
        <Checkboxs
            name={props.name}
            label={props.label}
            isChecked={props.isChecked}
            handleChange={props.handleChange}
            disabled={props.disabled}
        />
    ) : null;
};
