import { createUseStyles } from "react-jss";

export const UploadFileStyle = createUseStyles((theme: any) => ({
    input: {
        display: "none",
    },
    selectedFile: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    fileContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    cancelButton: {
        cursor: "pointer",
    },
    errorContainer: {
        marginTop: 12,
        color: theme.colors.form.input.invalid,
        fontSize: 12,
        fontFamily: theme.fonts.families.site,
    },

    customSelectedDocumentDescription: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    removeIcon: {
        color: theme.colors.palette.red,
        fontWeight: theme.fonts.weight.bold,
    },
    documentUri: (props: any) => ({
        color: props.uri ? theme.colors.palette.purple : theme.colors.palette.black,
        display: "flex",
        cursor: "pointer",
        width: props.hiddeRemoveDocument ? "100%" : "90%",
        overflow: "hidden",
    }),
    customSelectedDocumentContainer: (props: any) => ({
        display: "flex",
        width: "auto",
        maxWidth: 300,
        gap: 10,
        border: "1px solid #82868B",
        padding: 7,
        borderRadius: 10,
        "&:hover": {
            background: props.uri ? theme.colors.palette.greyLight : "none",
        },
        zIndex: 9,
    }),
}));
