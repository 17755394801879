import { createUseStyles } from "react-jss";

export const StatisticsRequestGraphContainerStyle = createUseStyles((theme: any) => ({
    container: {
        maxWidth: 1280,
        margin: "0 auto",
    },
    containerHeader: {
        composes: "$container",
        display: "flex",
        justifyContent: "space-between",
        padding: 15,
        alignItems: "center",
    },
    header: {
        width: "100%",
        height: 90,
    },
    logo: {
        width: 150,
    },
    title: {
        width: "100%",
        textAlign: "center",
        padding: 20,
    },
    message: {
        width: "100%",
        textAlign: "center",
    },
    chart: {
        width: "100%",
        padding: 15,
    },
}));
