import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterCertificateType } from "../../../../../models/resources/master/ICertificateType";

export interface IMasterCertificateTypeListState {
    list: IMasterCertificateType[];
    loading: boolean;
}

const initialState: IMasterCertificateTypeListState = {
    list: [],
    loading: false,
};

export const masterCertificateTypeList = createSlice({
    name: "masterCertificateTypeList",
    initialState,
    reducers: {
        resetMasterCertificateTypeList: () => initialState,
        setLoadingMasterCertificateTypeList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterCertificateTypeList: (state, action: PayloadAction<IMasterCertificateType[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterCertificateTypeList, setLoadingMasterCertificateTypeList, setMasterCertificateTypeList } =
    masterCertificateTypeList.actions;

export default masterCertificateTypeList.reducer;
