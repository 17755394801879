import { Icon } from "@fluentui/react";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "react-jss";
import { ShowWithAnimation } from "../showWithAnimation/showWithAnimation";
import { CollapsibleCardStyles } from "./collapsibleCard.jss";
import { CustomIcons } from "../customIcons/customIcons";

interface IProps {
    title: string;
    border?: boolean;
    showBubble: boolean;
}

export const CollapsibleCard: React.FC<IProps> = ({ title, children, showBubble }) => {
    const theme = useTheme();
    const [isCardExpanded, setExpanded] = useState<boolean>(true);

    const styles = CollapsibleCardStyles({ theme });

    const onIconClick = useCallback(() => {
        setExpanded((prevValue) => !prevValue);
    }, []);

    const iconName = useMemo(() => (isCardExpanded ? "ChevronUp" : "ChevronDown"), [isCardExpanded]);

    return (
        <div className={styles.collapsibleCardContainer}>
            <div
                className={styles.title}
                onClick={onIconClick}
            >
                {title}
                <CustomIcons iconName={iconName}></CustomIcons>

                {showBubble && (
                    <Icon
                        iconName={"FullCircleMask"}
                        style={{ color: "orange", fontSize: 10 }}
                    />
                )}
            </div>
            <ShowWithAnimation
                isMounted={isCardExpanded}
                delay={400}
                avoidFirstRender={true}
            >
                <div className={styles.body}>{children}</div>
            </ShowWithAnimation>
        </div>
    );
};
