import { createUseStyles } from "react-jss";

export const StatisticsDailyChargeListStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 21.99rem)",
        overflowY: "auto",
        alignItems: "center",
    },
}));
