import { useEffect, useMemo, useRef } from "react";
import { useTheme } from "react-jss";
import { useDelayUnmount } from "../../../hooks/delayUnmountHook";
import { ShowAnimationStyled } from "./showWithAnimation.jss";

interface IProps {
    isMounted: boolean;
    delay: number;
    avoidFirstRender: boolean;
}

export const ShowWithAnimation: React.FC<IProps> = ({ isMounted, children, delay, avoidFirstRender }) => {
    const showDiv = useDelayUnmount(isMounted, delay);
    const theme = useTheme();

    const isFirstRender = useRef<boolean>(true);

    useEffect(() => {
        if (isFirstRender.current) {
            setTimeout(() => (isFirstRender.current = false), delay);
        }
    }, []);

    const styles = ShowAnimationStyled({ theme, delay });

    const animationClass = useMemo(() => (isMounted ? styles.mountedClass : styles.unmountedClass), [isMounted]);

    const className = useMemo(
        () => (avoidFirstRender && isFirstRender.current ? "" : animationClass),
        [isFirstRender, animationClass, avoidFirstRender],
    );
    return <div>{showDiv && <div className={className}>{children}</div>}</div>;
};
