import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterPresenceRepresentative } from "../../../../../models/resources/master/IPresenceRepresentative";


export interface IMasterPresenceRepresentativeFormState {
    form: IMasterPresenceRepresentative;
    loading: boolean;
}

const initialState: IMasterPresenceRepresentativeFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterPresenceRepresentativeForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterPresenceRepresentativeForm: () => initialState,
        setLoadingMasterPresenceRepresentativeForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterPresenceRepresentativeForm: (state, action: PayloadAction<IMasterPresenceRepresentative>) => {
            state.form = action.payload;
        },
        updateMasterPresenceRepresentativeProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterPresenceRepresentativeForm,
    setLoadingMasterPresenceRepresentativeForm,
    setMasterPresenceRepresentativeForm,
    updateMasterPresenceRepresentativeProperty,
} = masterPresenceRepresentativeForm.actions;

export default masterPresenceRepresentativeForm.reducer;
