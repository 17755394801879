import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterMerchandiseCategory } from "../../../../../models/resources/master/IMerchandiseCategory";

export interface IMasterMerchandiseCategoryState {
    form: IMasterMerchandiseCategory;
    loading: boolean;
}

const initialState: IMasterMerchandiseCategoryState = {
    form: {
        active: false,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterTypesWareForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterMerchandiseCategoryForm: () => initialState,
        setLoadingMasterMerchandiseCategoryForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterMerchandiseCategoryForm: (state, action: PayloadAction<IMasterMerchandiseCategory>) => {
            state.form = action.payload;
        },
        updateMasterMerchandiseCategoryProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterMerchandiseCategoryForm,
    setLoadingMasterMerchandiseCategoryForm,
    setMasterMerchandiseCategoryForm,
    updateMasterMerchandiseCategoryProperty,
} = masterTypesWareForm.actions;

export default masterTypesWareForm.reducer;
