import { createUseStyles } from "react-jss";

export const StatisticsInspectionDetailedColumnStyle = createUseStyles((theme: any) => ({
    header: {
        display: "flex",
        height: "-webkit-fill-available",
        alignItems: "center",
        whiteSpace: "pre-line",
        lineHeight: "initial",
    },
    sort: {
        marginTop: "5px",
    },
    iconSort: {
        fill: theme.colors.iconSort.disabled,
        width: "16px",
    },
    iconSorted: {
        fill: theme.colors.iconSort.enabled,
        width: "16px",
    },
    sortColumn: {
        width: 14,
        display: "flex",
        position: "absolute",
    },
    dataCell: {
        textAlign: "center",
    },
}));
