import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBoardHistoryAppointmentTable } from "../../../../models/masterAppointment/IAppointmentBoard";

export interface IMasterAppointmentBoardListState {
    list?: IBoardHistoryAppointmentTable[];
    loading: boolean;
}

const initialState: IMasterAppointmentBoardListState = {
    loading: false,
};

export const masterAppointmentBoardList = createSlice({
    name: "masterAppointmentBoardList",
    initialState,
    reducers: {
        resetMasterAppointmentBoardList: () => initialState,
        setLoadingAppointmentBoardList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentBoardList: (state, action: PayloadAction<IBoardHistoryAppointmentTable[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterAppointmentBoardList, setLoadingAppointmentBoardList, setMasterAppointmentBoardList } = masterAppointmentBoardList.actions;

export default masterAppointmentBoardList.reducer;
