import { Method } from "../../../common/enum";
import { IManagementNotification } from "../../../models/management/notification/IManagementNotification";
import { IManagementNotificationFilter } from "../../../models/management/notification/IManagementNotificationFilter";
import { IManagementNotificationForm } from "../../../redux/reducers/management/notification/form";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/notifications`;

export class ManagementNotificationApi {
    async getNotificationListAsync(filter: IManagementNotificationFilter): Promise<IManagementNotification[]> {
        const url = `${baseURL}?roleId=${filter.roleId}&notificationTypeId=${filter.notificationTypeId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({ filter }).execute<IManagementNotification[]>();
    }

    async updateNotificationAsync(notification: any): Promise<boolean> {
        const url = `${baseURL}/${notification?.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(notification).execute<boolean>();
    }

    async createNotificationAsync(notification: any): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(notification).execute<boolean>();
    }

    async getNotificationAsync(notificationId: string): Promise<IManagementNotificationForm> {
        const url = `${baseURL}/${notificationId}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IManagementNotificationForm>();
    }

    async deleteNotification(notification: number): Promise<boolean> {
        const url = `${baseURL}/${notification}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute();
    }
}
