import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IManagementAuthorizedTransportUnitNumber } from "../../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface ManagementAuthorizedTransportUnitNumberFormState {
    form: IManagementAuthorizedTransportUnitNumber;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: ManagementAuthorizedTransportUnitNumberFormState = {
    form: {
        description: "",
        transportUnitNumber: "",
        authorized: true,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managementAuthorizedTransportUnitNumberFormSlice = createSlice({
    name: "managementAuthorizedTransportUnitNumberFormtSlice",
    initialState,
    reducers: {
        resetManagementAuthorizedTransportUnitNumberState: () => initialState,
        resetManagementAuthorizedTransportUnitNumberForm: (state, ) => {
            state.form = initialState.form;
        },
        updateManagementAuthorizedTransportUnitNumberFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setManagementAuthorizedTransportUnitNumberFormData: (state, action: PayloadAction<IManagementAuthorizedTransportUnitNumber>) => {
            state.form = action.payload;
        },
        setManagementAuthorizedTransportUnitNumberFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowPopUpAuthorizedTransportUnitNumberForm: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetManagementAuthorizedTransportUnitNumberState, resetManagementAuthorizedTransportUnitNumberForm,
    setManagementAuthorizedTransportUnitNumberFormData,
    updateManagementAuthorizedTransportUnitNumberFormProperty,
    setManagementAuthorizedTransportUnitNumberFormLoading,
    setShowPopUpAuthorizedTransportUnitNumberForm,
} = managementAuthorizedTransportUnitNumberFormSlice.actions;

export default managementAuthorizedTransportUnitNumberFormSlice.reducer;
