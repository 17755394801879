import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IMonthlySummaryMonthlySummaryItem } from "../../../../../models/statistics/IMonthlySummary";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { generateRandomKeys } from "../../../../../utils/keys";

interface IInvoicingUninvoicedColumnFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsTotalMonthlySummaryListColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.CUSTOMER.TABLE" });
    const { data } = useAppSelector((store) => store.statisticsMonthlySummaryList);

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("yearSummary"),
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("yearSummary"),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTAL_FINAL")}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("monthSummary"),
            minWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("monthSummary"),
            onRender: () => <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}> {data.totalInspections}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime"),
            minWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> {data.totalStayTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime"),
            minWidth: calcWidthColumFooter(3, 3, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(3, 3, props.listColumnsFooter)}> {data.totalInspectionTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime"),
            minWidth: calcWidthColumFooter(4, 4, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(4, 4, props.listColumnsFooter)}> {data.totalIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences"),
            minWidth: calcWidthColumFooter(5, 5, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(5, 5, props.listColumnsFooter)}> {data.totalIncidences}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime"),
            minWidth: calcWidthColumFooter(6, 6, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(6, 6, props.listColumnsFooter)}> {data.totalStayAverageTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime"),
            minWidth: calcWidthColumFooter(7, 7, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(7, 7, props.listColumnsFooter)}> {data.totalInspectionAverageTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime"),
            minWidth: 100,
            maxWidth: 100,
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime"),

            onRender: () => <b style={generateStyleWhithCustomFooter(8, 8, props.listColumnsFooter)}> {data.totalAverageIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime"),
            minWidth: 100,
            maxWidth: 100,
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime"),

            onRender: () => <b style={generateStyleWhithCustomFooter(9, 9, props.listColumnsFooter)}> {data.totalNetTime}</b>,
            isResizable: true,
        },
    ];

    return columns;
};

export const StatisticsAverageMonthlySummaryListColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.MONTHLY_SUMMARY.TABLE" });
    const { data } = useAppSelector((store) => store.statisticsMonthlySummaryList);

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("monthSummary"),
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("AVERAGE_MONTHLY")}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspection"),
            minWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspection"),
            onRender: () => <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}> {data.monthlyInspections}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime"),
            minWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> {data.monthlyStayTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime"),
            minWidth: calcWidthColumFooter(3, 3, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(3, 3, props.listColumnsFooter)}> {data.monthlyInspectionTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime"),
            minWidth: calcWidthColumFooter(4, 4, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(4, 4, props.listColumnsFooter)}> {data.monthlyIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences"),
            minWidth: calcWidthColumFooter(5, 5, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(5, 5, props.listColumnsFooter)}>{data.monthlyIncidences} </b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime"),
            minWidth: calcWidthColumFooter(6, 6, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(6, 6, props.listColumnsFooter)}> {data.monthlyStayAverageTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime"),
            minWidth: calcWidthColumFooter(7, 7, props.listColumnsFooter),
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(7, 7, props.listColumnsFooter)}> {data.monthlyInspectionAverageTime} </b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime"),
            minWidth: 100,
            maxWidth: 100,
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime"),

            onRender: () => <b style={generateStyleWhithCustomFooter(8, 8, props.listColumnsFooter)}>{data.monthlyAverageIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime"),
            minWidth: 100,
            maxWidth: 100,
            name: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime"),

            onRender: () => <b style={generateStyleWhithCustomFooter(9, 9, props.listColumnsFooter)}> {data.monthlyNetTime} </b>,
            isResizable: true,
        },
    ];

    return columns;
};
