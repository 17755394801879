import React,{ useEffect } from "react";
import { fetchDocumentTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { NumericMultiSelector } from "../../../../common/selectors/multiSelectors/numericMultiSelector/numericMultiSelector";
import { IBaseMultipleNumberSelectorProps } from "../IBaseMultipleSelectorProps";
import { MultipleNumberSelectorFilter } from "../multipleSelectorFilter/multipleNumberSelectorFilter";

export const MultipleDocumentTypesSelector: React.FC<IBaseMultipleNumberSelectorProps> = ({  autocomplete, onChange, propertyName, title, selectedKeys }) => {
    const { data, loading } = useAppSelector((store) => store.catalog.documentTypes);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchDocumentTypes());
    }, [dispatch, data]);

    return autocomplete ? (
        <MultipleNumberSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapIDescriptionToDropDownOptions(data)}
            label={title}
            propertyName={propertyName}
        ></MultipleNumberSelectorFilter>
    ) : (
        <NumericMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapIDescriptionToDropDownOptions(data)}
            disabled={loading}
            title={title}
            name={propertyName}
        />
    );
};
