import { Callout, DirectionalHint, Icon } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "react-jss";
import { HookFormProps } from "../../../../models";
import { DirectionType } from "../../../../common/enum/DirectionType";
import { radioButtonDefaultOptions } from "../../../../utils";
import { RequiredSpan } from "../../required/requiredSpan";
import { ControlledRadioButtonGroupStyled, ShowCallOutComponentStyle } from "./controlledRadioButtonGroup.jss";

interface IRadioButtonProps {
    name: string;
    label?: string;
    calloutMessage?: string;
    calloutLabel?: string;
    selectedKey?: string | number;
    options?: IChoiceGroupOption[];
    onRadioButtonChange: (option: string, name: string) => void;
    direction?: DirectionType;
    required?: boolean;
    disabled?: boolean;
}

export const ControlledRadioButtonGroup: FC<HookFormProps & IRadioButtonProps> = (props) => {
    const theme = useTheme();
    const styles = ControlledRadioButtonGroupStyled({ theme });
    const iconId = useId("callout-iconButton");

    const _onChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        props.onRadioButtonChange(option?.key!, props.name);
    };

    const [isCalloutVisible, setCalloutVisibility] = useState<boolean>(false);

    const showCallOut = props.calloutLabel && props.calloutMessage;
    return (
        <div className={styles.radioButtonContainer}>
            {showCallOut && (
                <ShowCallOutComponent
                    calloutLabel={props.calloutLabel}
                    required={props.required}
                    iconId={iconId}
                    setCalloutVisibility={setCalloutVisibility}
                    isCalloutVisible={isCalloutVisible}
                    calloutMessage={props.calloutMessage}
                />
            )}

            <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.selectedKey}
                render={({ field: { onChange, onBlur, value } }) => (
                    <ChoiceGroup
                        {...props}
                        label={props.label}
                        options={props.options ?? radioButtonDefaultOptions}
                        selectedKey={props.selectedKey}
                        onChange={(_e, option) => {
                            _onChange(_e, option);
                            onChange(_e, option?.key);
                        }}
                        onBlur={onBlur}
                        defaultValue={(props?.selectedKey as string) || value}
                        required={props.required}
                        disabled={props.disabled}
                    />
                )}
            />
        </div>
    );
};

interface IShowCallOutComponentProps {
    calloutLabel?: string;
    required?: boolean;
    iconId: string;
    setCalloutVisibility: (value: any) => any;
    isCalloutVisible: boolean;
    calloutMessage?: string;
}

export const ShowCallOutComponent = (props: IShowCallOutComponentProps) => {
    const theme = useTheme();
    const styles = ShowCallOutComponentStyle({ theme });
    return (
        <div className={styles.radioButtonCalloutContainer}>
            <div className={styles.radioButtonCalloutLabelContainer}>
                <label>{props.calloutLabel}</label>
                {props.required && <RequiredSpan />}
            </div>
            <div className={styles.iconContainer}>
                <Icon
                    id={props.iconId}
                    onClick={() => props.setCalloutVisibility(!props.isCalloutVisible)}
                    iconName="InfoSolid"
                />
            </div>
            {props.isCalloutVisible && (
                <Callout
                    role="dialog"
                    target={`#${props.iconId}`}
                    isBeakVisible={true}
                    onDismiss={() => props.setCalloutVisibility(false)}
                    setInitialFocus
                    calloutMaxWidth={250}
                    calloutMaxHeight={200}
                    hideOverflow={false}
                    gapSpace={16}
                    directionalHint={DirectionalHint.bottomRightEdge}
                >
                    <div className={styles.radioButtonCalloutMessage}>{props.calloutMessage}</div>
                </Callout>
            )}
        </div>
    );
};
