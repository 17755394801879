import { IConfigRoleProperty } from "../../../../models";
import { IField } from "../../../../models/requests/IUserRoleView";
import { compileNameOfProperty, isFieldReadOnly, isFieldRequired, isFieldVisible } from "../../../../utils";


export interface IConfigManagementSummary {
   
    create:IConfigRoleProperty;
    delete:IConfigRoleProperty;
}

export const generateManagementSummarySchema = (fields: IField[]): IConfigManagementSummary => {
    return {
        create: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigManagementSummary>("create")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigManagementSummary>("create")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigManagementSummary>("create")),
        },
        delete: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigManagementSummary>("delete")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigManagementSummary>("delete")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigManagementSummary>("delete")),
        },
        
    };
};
