import { createUseStyles } from "react-jss";

export const RejectionReasonStyle = createUseStyles((theme: any) => ({
    rejectionContainer: {
        paddingBottom: 24,
        width: "100%",
    },
    reasonContainer: {
        display: "flex",
        flexDirection: "row",
        width: "95%",
        paddingTop:10
    },
    buttonsRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
        paddingTop: theme.paddings.default,
        columnGap: theme.gaps.sizes.md,
    },
}));
