import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../common/constants";

export const InvoiceStyle = createUseStyles((theme: any) => ({
    generalContainer: {
        backgroundColor: theme.colors.sections.background,
        borderRadius: 10,
        color: "black",
        padding: 15,
        display: "flex",
        flexFlow: "column",
        gap: 30,
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: 20,
    },
    dropDown: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.colors.palette.purple,
    },
    headerContainerData: {
        display: "flex",
        gap: 30,
        flexWrap: "wrap",
    },
    labelFactura: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
    conceptosHeaderItem: {
        width: "16.66%",
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },
    reloadIcon: {
        color: theme.colors.palette.purple,
        fontWeight: "bold",
    },
}));
