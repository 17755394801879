import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import {
    postManagementIncidenceTypeAction,
    removeManagementIncidenceTypeAction,
} from "../../../../redux/actions/management/incidenceTypes/incidenceTypes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetManagementIncidenceTypeState, setShowPopUpIncidenceTypeForm } from "../../../../redux/reducers/management/incidenceType/form";
import { widths } from "../../../../styles/romeu/widths";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ManagementIncidencesTypesForm } from "./form/form";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ManagementIncidencesTypesFormController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.INCIDENCE_TYPE.FORM" });
    const { form, loading, showPopUp } = useAppSelector((state) => state.managementIncidenceTypesForm);
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery({ query: `(max-width: 769px)` });

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postManagementIncidenceTypeAction()).then((response: any) => {
                response && onCancel();
            });
        } else if (showPopUp === PopUpCodes.DELETE && form.id) {
            dispatch(removeManagementIncidenceTypeAction(form.id)).then((response: any) => {
                response && onCancel();
            });
        }
    };

    const onDimissed = () => {
        dispatch(resetManagementIncidenceTypeState());
    };
    const onCancel = () => {
        dispatch(setShowPopUpIncidenceTypeForm(PopUpCodes.NONE));
    };
    const isFormNotValid = useMemo(() => {
        let item: any = form;
        let keys = ["description"];
        if (form.incidenceType || form.organismId) {
            keys.push("organismId");
        }

        for (let key of keys) {
            if (!item[key]) {
                return true;
            }
        }

        return false;
    }, [form]);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("NEW_INCIDENCE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE_INCIDENCE");
        }
        return "";
    }, [showPopUp]);
    return (
        <>
            <PopUpForm
                content={<ManagementIncidencesTypesForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"25vh"}
                isLoading={loading}
                width={isMobile ? "80vw" : "30vw"}
                isDisabledButton={isFormNotValid}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancel}
                onAccept={onSave}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                loading={loading}
            />
        </>
    );
};
