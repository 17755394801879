import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux/hooks";
import { setConfigDockBlockShowPopUp } from "../../../redux/reducers/config/dockBlock/form";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ConfigDockBlockFormController } from "./controller/controller";
import { ConfigDockBlockFilter } from "./filter/filter";
import { ConfigDockBlockList } from "./list/list";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
export const ConfigDockBlockContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();

    return (
        <div>
            <NavHeaderComponent
                title={t("DOCK_BLOCK.TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setConfigDockBlockShowPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />
            <ConfigDockBlockFilter></ConfigDockBlockFilter>
            <ConfigDockBlockList></ConfigDockBlockList>
            <ConfigDockBlockFormController></ConfigDockBlockFormController>
        </div>
    );
};
