import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterRequestStatesForm, setMasterRequestStatesForm } from "../../../../reducers/resources/master/requestStates/form";
import { setMasterLoadingRequestStatesList, setMasterRequestStatesList } from "../../../../reducers/resources/master/requestStates/list";
import { AppThunk, store } from "../../../../store";

const catalogAPI = new CatalogAPI();

export const fetchMasterRequestStatesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setMasterLoadingRequestStatesList(true));
        const response = await catalogAPI.getMasterRequestStatesList();
        dispatch(setMasterRequestStatesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setMasterLoadingRequestStatesList(false));
    }
};

export const fetchMasterRequestState =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterRequestStatesForm(true));
            const response = await catalogAPI.getMasterRequestState(id);
            dispatch(setMasterRequestStatesForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterRequestStatesForm(false));
        }
    };

export const postToMasterRequestStateForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterRequestStatesForm;
        try {
            dispatch(setLoadingMasterRequestStatesForm(true));
            if (form.id) {
                await catalogAPI.putMasterRequestState(form);
            } else {
                await catalogAPI.postMasterRequestState(form);
            }
            dispatch(fetchMasterRequestStatesList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterRequestStatesForm(false));
            return false;
        }
    };
};
