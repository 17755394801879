import { InvoicingNonSynchronizedApi } from "../../../../api/invoicing/nonSynchronized";
import { throwErrorToastrAsync } from "../../../../utils";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import { resetInvoiceNonSynchronized, setList, setLoading } from "../../../reducers/invoicing/nonSynchronized/list";
import { AppThunk } from "../../../store";

const requestApi = new InvoicingNonSynchronizedApi();

export const fetchInvoicingNonSynchronizedAction =
    (silentReload?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            !silentReload && dispatch(setLoading(true));
            const response = await requestApi.getNonSynchronized();
            dispatch(setList(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            !silentReload && dispatch(setLoading(false));
        }
    };

export const putInvoicingNonSynchronizedAction =
    (ids: number[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoading(true));
            await requestApi.putSynchronizeConcepts(ids);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchInvoicingNonSynchronizedAction(true));
            dispatch(setGlobalLoading(false));
        }
    };

export const resetInvoiceNonSynchronizedData = (): AppThunk => (dispatch) => {
    dispatch(resetInvoiceNonSynchronized());
};
