import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pageSizes } from "../../../../common/constants";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { IManagementActiveSummary } from "../../../../models/management/summary/ISummary";
import { getFirstItemNumber, getLastItemNumber } from "../../../../utils";

interface ManagementSummaryActiveList {
    data?: IManagementActiveSummary;
    loading: boolean;
    pagination: IPaginationState;
}

const initialState: ManagementSummaryActiveList = {
    loading: false,
    pagination: emptyPaginationState(pageSizes.MANAGEMENT_SUMMARY),
};

export const managementTariffList = createSlice({
    name: "summaryConceptList",
    initialState,
    reducers: {
        resetManagmentSummaryList: () => initialState,
        setManagmentSummaryList: (state, action: PayloadAction<IManagementActiveSummary>) => {
            state.data = action.payload;
            state.pagination.totalItems = action.payload.summaries.length;
            state.pagination.totalPages = action.payload.pages;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
        setLoadingManagementSummaryList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
    },
});

export const { resetManagmentSummaryList, setLoadingManagementSummaryList, setManagmentSummaryList, setPage } = managementTariffList.actions;

export default managementTariffList.reducer;
