import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";

import { fetchAppointmentTypesRemaining } from "../../../../../../redux/actions/masterAppointment/appointmentType/appointmentType";
import { postAndPutMasterAppointmentTypeActuation } from "../../../../../../redux/actions/masterAppointment/appointmentTypeActuation/appointmentTypeActuation";
import { useAppSelector } from "../../../../../../redux/hooks";
import { resetMasterAppointmentTypeActuationForm } from "../../../../../../redux/reducers/masterAppointment/appointmentTypeActuation/form";
import { clearMasterAppointmentPopUp } from "../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";

import { MasterAppointmentTypeForm } from "./form/form";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterActuationTypeControllerProps {}

export const MasterAppointmentTypeFormController: React.FC<IMasterActuationTypeControllerProps> = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useDispatch();

    const { showPopUp } = useAppSelector((state) => state.masterAppointment);
    const { loading, form } = useAppSelector((state) => state.masterAppointmentTypeActuationForm);
    const { data } = useAppSelector((state) => state.catalog.appointmentTypesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postAndPutMasterAppointmentTypeActuation()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterAppointmentPopUp());
    };

    const onDimissed = () => {
        dispatch(resetMasterAppointmentTypeActuationForm());
    };

    const isVisiblePopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false;
    }, [showPopUp]);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.APPOINTMENT_TYPE_ACTUATION");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.APPOINTMENT_TYPE_ACTUATION");
        }

        return "";
    }, [showPopUp]);
    const isDisabledButton = useMemo(() => {
        return !form.actuationTypeId || !form.appointmentTypeId;
    }, [form, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && data === undefined && dispatch(fetchAppointmentTypesRemaining());
    }, [showPopUp, data]);

    return (
        <PopUpForm
            content={<MasterAppointmentTypeForm />}
            title={titlePopUp}
            isVisible={isVisiblePopUp}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissed}
            height={"30vh"}
            width={"50vw"}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
