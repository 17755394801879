import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

const sealGap = 12;

export const OriginSealsStyle = createUseStyles((theme: any) => ({
    titleContainer: {
        display: "block",
        width: "100%",
    },
    sealsContainer: {
        overflowY: "auto",
        rowGap: 12,
        paddingTop: 16,
        width: "100%",
        flexWrap: "no wrap",
        display: "flex",
        flexDirection: "row",
        boxSizing: "content-box",
        columnGap: sealGap,
    },
    upsertSealData:(props:any)=>({
        overflowY: "auto",
        rowGap: 12,
        paddingTop: 8,
        maxHeight: props.isContainer   ? "15vh":"19vh",
        width: "100%",
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
        boxSizing: "content-box",
        columnGap: sealGap,
        maxWidth: props.widthSealData ?? "30vw",
    }) ,
    buttonContainer: {
        minWidth: 130,
    },
    column: {
        display: "flex",
        flexDirection: "row",
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },

    title: {
        flexBasis: "100%",
    },
    addSeal: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        cursor: "pointer",
        color: theme.colors.navLinks.primary.color,
        fontWeight: 400,
    },
}));
