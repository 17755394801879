
import { useTheme } from "react-jss";
import { ResumeButtons } from "./buttons/resumeButtons";
import { ResumeCards } from "./resumeCards/resumeCards";
import { ResumeContainerStyle } from "./containerStyle.jss";

interface IProps {
    onResumeAccept: () => void;
}
export const ResumeContainer: React.FC<IProps> = ({ onResumeAccept }) => {
    const theme = useTheme();
    const styles = ResumeContainerStyle({ theme });

    return (
        <div className={styles.resumeContainer}>
            <ResumeCards />
            <ResumeButtons onAccept={onResumeAccept} />
        </div>
    );
};
