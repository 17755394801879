import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { fetchCertificatesByOrganism } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector } from "../../../../common/selectors/customSelector/customSelector";
import { Selector } from "../../../../common/selectors/selector";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

interface ICustomDocumentSelectorProps {
    name: string;
    selectedKey: any;
    onChange: (name: string, keysSelected: any) => any;
}

export const CustomDocumentSelector: React.FC<ICustomDocumentSelectorProps> = ({ name, selectedKey, onChange }) => {
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.FILTER" });
    const { organismId } = useAppSelector((state) => state.userData.profile);

    const { data, loading } = useAppSelector((state) => state.catalog.certificatesByOrganism);
    const dispatch = useAppDispatch();
    useEffect(() => {
        data.length === 0 && !loading && dispatch(fetchCertificatesByOrganism(organismId ? organismId : 0));
    }, []);

    return (
        <CustomSelector
            title={t("DOCUMENT_TYPE")}
            options={mapIDescriptionToDropDownOptions(data)}
            onChange={onChange}
            selectedKey={selectedKey ? selectedKey : DefaultValues.DEFAULT_ALL_SELECTOR}
            name={name}
        />
    );
};

interface IDocumentSelectorProps {
    name: string;
    selectedKey: number;
    onChange: (event: any, name: string, keysSelected: any) => any;
    isRequired: boolean;
}

export const DocumentSelector: React.FC<IDocumentSelectorProps> = ({ name, selectedKey, onChange, isRequired }) => {
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.FILTER" });
    const { data, loading } = useAppSelector((store) => store.catalog.certificatesByOrganism);
    const dispatch = useAppDispatch();
    const { organismId } = useAppSelector((state) => state.userData.profile);
    useEffect(() => {
        data.length === 0 && !loading && dispatch(fetchCertificatesByOrganism(organismId ? organismId : 0));
    }, []);
    return (
        <Selector
            selectedKey={selectedKey}
            name={name}
            onChange={onChange}
            options={useMemo(() => mapIDescriptionToDropDownOptions(data), [data])}
            title={t("DOCUMENT_TYPE")}
            required={isRequired}
        />
    );
};
