import { Method } from "../../../common/enum";
import {
    IBoardHistoryAppointment,
    IBoardHistoryAppointmentCreate,
    IBoardHistoryAppointmentTable,
} from "../../../models/masterAppointment/IAppointmentBoard";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment`;
const baseMasterURL = `${baseURL}/dashboard`;
export class MasterAppointmentBoardAPI {
    async getMasterAppointmentBoardAll(): Promise<IBoardHistoryAppointmentTable[]> {
        const url = `${baseMasterURL}/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IBoardHistoryAppointmentTable[]>();
    }

    async getMasterAppointmentBoard(id: string): Promise<IBoardHistoryAppointment> {
        const url = `${baseMasterURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IBoardHistoryAppointment>();
    }

    async postMasterAppointmentBoard(form: IBoardHistoryAppointmentCreate): Promise<boolean> {
        const url = `${baseMasterURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }
}
