import { Method } from "../../../common/enum";
import { IIncidenceTypesFilter, IIncidenceTypesItem } from "../../../models/management/incidenceType/IIncidenceType";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/incidencetypes`;

export class ManagementIncidenceTypesApi {
    async getManagementIncidencesTypesAsync(filter: IIncidenceTypesFilter): Promise<IIncidenceTypesItem[]> {
        const url = `${baseURL}/search`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IIncidenceTypesItem[]>();
    }

    async getManagementIncidenceTypeItemAsync(id: number): Promise<IIncidenceTypesItem> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IIncidenceTypesItem>();
    }

    async postManagementIncidenceTypeItemAsync(form: IIncidenceTypesItem): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }
    async putManagementIncidenceTypeItemAsync(form: IIncidenceTypesItem): Promise<boolean> {
        const url = `${baseURL}/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async removeManagementIncidenceTypeItemAsync(id: number): Promise<boolean> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
