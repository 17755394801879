import { IDropdownOption } from "@fluentui/react";
import React, { useEffect } from "react";
import {  fetchMerchandiseCategoryGroup } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../IBaseSelectorProps";
interface IMerchandiseCategoryGroupSelector extends IBaseSelectorProps {

}
export const MerchandiseCategoryGroupSelector: React.FC<IMerchandiseCategoryGroupSelector> = ({
    onChange,
    propertyName,
    title,
    selectedKey,
    control,
    rules,
    disabled,
    isRequired,
    allOption, 
    allOptionText
    
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.merchandiseCategoryGroup);
    const dispatch = useAppDispatch();

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _ind?: number) => {
        onChange(option, propertyName);
    };
    useEffect(() => {
        !data.length && dispatch(fetchMerchandiseCategoryGroup());
    }, []);

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(data, allOptionText):  mapIDescriptionToDropDownOptions(data)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
