import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import {
    IInspectionAccumulativeByMonthStatistics,
    IInspectionsAccumulativeByMonthStatistics,
} from "../../../../../models/statistics/inspection/IAccumulated";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, compileNameOfProperty, generateStyleWhithCustomFooter } from "../../../../../utils";
import { generateRandomKeys } from "../../../../../utils/keys";
import { dinamicItemData } from "../../utils/utils";

interface IInvoicingUninvoicedColumnFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsIncidenceMonthlyListColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.ACCUMULATED_TABLE" });

    const { inspectionsAccumulativeStatistics: inspectionsAccumulativeByMonthStatistics, organisms } = useAppSelector(
        (store) => store.statiticsInspectionAccumulativeList.data,
    );
    const searchIndex = (code: string) => {
        return props.listColumnsFooter.findIndex((item) => item.key === code);
    };

    const columns: IColumn[] = [
        {
            key: "1",
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTAL")}</b>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("totalInspectionsPositionedWithInvoiceConcepts"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.totalInspectionsPositionedWithInvoiceConcepts}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("totalRequests"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.totalRequests}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("totalRequestsPositionedWithInvoiceConcepts"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.totalRequestsPositionedWithInvoiceConcepts}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("totalRequestsPositionedWithInvoiceConceptsWithManyOrganisms"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.totalRequestsPositionedWithInvoiceConceptsWithManyOrganisms}
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("totalRequestsWithManyOrganisms"),
            minWidth: calcWidthColumFooter(
                searchIndex(
                    compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                ),
                searchIndex(
                    compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                ),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(
                            compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                        ),
                        searchIndex(
                            compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                        ),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.totalRequestsWithManyOrganisms}
                </span>
            ),
            isResizable: true,
        },
    ];

    const dinamicColumns = () => {
        const newColumns = [];
        newColumns.push(columns[0]);

        for (let organism of organisms) {
            const index = props.listColumnsFooter.findIndex((item) => item.key === organism.code);
            newColumns.push({
                key: organism.code,
                minWidth: calcWidthColumFooter(index, index, props.listColumnsFooter),
                name: organism.code,

                onRender: () => (
                    <span style={generateStyleWhithCustomFooter(index, index, props.listColumnsFooter)}>
                        {dinamicItemData(inspectionsAccumulativeByMonthStatistics.organismRequests, organism)}
                    </span>
                ),
                isResizable: false,
            });
        }
        for (const [index, column] of columns.entries()) {
            index > 0 && newColumns.push(column);
        }
        return newColumns;
    };

    return dinamicColumns();
};

export const StatisticsIncidenceMonthlyPercentListColumnFooter = (props: IInvoicingUninvoicedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.ACCUMULATED_TABLE" });

    const { inspectionsAccumulativeStatistics: inspectionsAccumulativeByMonthStatistics, organisms } = useAppSelector(
        (store) => store.statiticsInspectionAccumulativeList.data,
    );
    const searchIndex = (code: string) => {
        return props.listColumnsFooter.findIndex((item) => item.key === code);
    };

    const columns: IColumn[] = [
        {
            key: "1",
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: generateRandomKeys().toString(),
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter, 8)}>{t("PERCENT")}</b>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConceptsPercentage"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.inspectionsPositionedWithInvoiceConceptsPercentage}%
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("totalRequests"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")),
                        props.listColumnsFooter,
                    )}
                ></span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsPercentage"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.requestsPositionedWithInvoiceConceptsPercentage}%
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganismsPercentage"),
            minWidth: calcWidthColumFooter(
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                        searchIndex(compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.requestsPositionedWithInvoiceConceptsWithManyOrganismsPercentage}%
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInspectionsAccumulativeByMonthStatistics>("requestsWithManyOrganismsPercentage"),
            minWidth: calcWidthColumFooter(
                searchIndex(
                    compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                ),
                searchIndex(
                    compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                ),
                props.listColumnsFooter,
            ),
            name: "aaa",

            onRender: () => (
                <span
                    style={generateStyleWhithCustomFooter(
                        searchIndex(
                            compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                        ),
                        searchIndex(
                            compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
                        ),
                        props.listColumnsFooter,
                    )}
                >
                    {inspectionsAccumulativeByMonthStatistics.requestsWithManyOrganismsPercentage}%
                </span>
            ),
            isResizable: true,
        },
    ];

    const dinamicColumns = () => {
        const newColumns = [];
        newColumns.push(columns[0]);

        for (let organism of organisms) {
            const index = props.listColumnsFooter.findIndex((item) => item.key === organism.code);
            newColumns.push({
                key: organism.code,
                minWidth: calcWidthColumFooter(index, index, props.listColumnsFooter),
                name: organism.code,

                onRender: () => (
                    <span style={generateStyleWhithCustomFooter(index, index, props.listColumnsFooter)}>
                        {dinamicItemData(inspectionsAccumulativeByMonthStatistics.organismRequests, organism, "aquiiii")}%
                    </span>
                ),
                isResizable: false,
            });
        }
        for (const [index, column] of columns.entries()) {
            index > 0 && newColumns.push(column);
        }
        return newColumns;
    };

    return dinamicColumns();
};
