import { createUseStyles } from "react-jss";

export const ContextMenuStyles = createUseStyles((theme: any) => ({
    iconMenuColor: {
        color: theme.colors.titles.primary.color,
        fontWeight: "600",
    },
    userProfile: {
        cursor: "pointer",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: theme.paddings.default,
    },
    link: {
        textDecoration: "none",
    },
}));
