import { Resolver } from "react-hook-form";
import { isValidEmail, isValidPhoneNumber } from "../../../../../../common/validations/validationsForm";
import { IAdminDataCustom, IEntityData, IPaymentData } from "../../../../../../models";
import { IManagementUserForm } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty } from "../../../../../../utils";
import { isRequiredField } from "../../validators/schema";
import { schemaRole, schemaView } from "../../../../../../common/enum/management/Schema";

export const myProfileResolver: Resolver<IManagementUserForm> = async (values: IManagementUserForm) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (name === compileNameOfProperty<IManagementUserForm>("email")) {
            if (!isValidEmail(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.EMAIL",
                    },
                };
            } else if (value === "") {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            }
        } else if (name === compileNameOfProperty<IManagementUserForm>("phoneNumber")) {
            if (value !== "" && !isValidPhoneNumber(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.PHONE",
                    },
                };
            }
        } else {
            if (name !== compileNameOfProperty<IManagementUserForm>("lastName") && !value) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            }
        }
    });
    return { values, errors };
};
export const myProfileCarrierResolver: Resolver<IManagementUserForm> = async (values: IManagementUserForm) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (name === compileNameOfProperty<IManagementUserForm>("email")) {
            if (value.length > 0 && !isValidEmail(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "validate",
                        message: "FORM.VALIDATION.EMAIL",
                    },
                };
            } else if (value === "") {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            }
        } else if (name === compileNameOfProperty<IManagementUserForm>("phoneNumber")) {
            if (value.length > 0 && !isValidPhoneNumber(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "validate",
                        message: "FORM.VALIDATION.PHONE",
                    },
                };
            }
        } else if (isRequiredField(schemaRole.CARRIER, schemaView.USER_DATA, name) && value.length === 0) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: "FORM.VALIDATION.REQUIRE",
                },
            };
        }
    });
    return { values, errors };
};

export const myProfileEntityResolver: Resolver<IEntityData> = async (values: IEntityData) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (!value) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: "FORM.VALIDATION.REQUIRE",
                },
            };
        }
    });
    return { values, errors };
};

export const myProfileEntityCarrierResolver: Resolver<IEntityData> = async (values: IEntityData) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (isRequiredField(schemaRole.CARRIER, schemaView.ENTITY_DATA, name) && value.length === 0) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: "FORM.VALIDATION.REQUIRE",
                },
            };
        }
    });
    return { values, errors };
};

export const myProfileAdminResolver: Resolver<IAdminDataCustom> = async (values: IAdminDataCustom) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (name === compileNameOfProperty<IAdminDataCustom>("phone")) {
            if (!value) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            } else if (value !== "" && !isValidPhoneNumber(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.PHONE",
                    },
                };
            }
        } else if (name === compileNameOfProperty<IAdminDataCustom>("mobilePhone")) {
            if (value !== "" && !isValidPhoneNumber(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.PHONE",
                    },
                };
            }
        }
    });
    return { values, errors };
};

export const myProfileBanckPayResolver: Resolver<IPaymentData> = async (values: IPaymentData) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (!value) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: "FORM.VALIDATION.REQUIRE",
                },
            };
        }
    });
    return { values, errors };
};

export const myEmailResolver: Resolver<any> = async (values: any) => {
    let errors = {};
    Object.entries(values).forEach(([name, data]) => {
        if (name === compileNameOfProperty<any>("email")) {
            let value: any = data;
            if (value && !isValidEmail(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.EMAIL",
                    },
                };
            }
        } else {
            if (!data) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            }
        }
    });
    return { values, errors };
};
