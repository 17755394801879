import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpConfirmationType } from "../../../../../common/enum/PopUpConfirmationType";
import { ButtonPrimary, ButtonPrimaryInverse } from "../../../buttons/buttons";
import { CustomPopUpConfirmButtonActionsStyle } from "./popUpConfirmActionsStyle.jss";
import { ColorType } from "../../../../../common/enum/ColorType";

interface IPopUpConfirmButtonActionsProps {
    onAccept: (value: any) => any;
    onCancel: (value: any) => any;
    buttonAcceptTitle?: string;
    buttonCancelTitle?: string;
    type: PopUpConfirmationType;
    disabled?: boolean;
    loading?: boolean;
    hiddeCancelButon?: boolean;
}

export const PopUpConfirmButtonActions: React.FC<IPopUpConfirmButtonActionsProps> = ({
    onAccept,
    onCancel,
    buttonAcceptTitle,
    buttonCancelTitle,
    type,
    disabled,
    loading,
    hiddeCancelButon,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "POPUPACTIONS" });
    const theme = useTheme();
    const style = CustomPopUpConfirmButtonActionsStyle({ theme });

    const titleButtonAcept = () => {
        switch (type) {
            case PopUpConfirmationType.SUCCESS:
                return t("SUCCESS");
            case PopUpConfirmationType.DELETE:
                return t("DELETE");
            case PopUpConfirmationType.WARNING:
                return t("WARNING");
            case PopUpConfirmationType.INFO:
                return t("INFO");
            default:
                return t("SAVE");
        }
    };
    const buttonType = () => {
        switch (type) {
            case PopUpConfirmationType.SUCCESS:
                return ColorType.success;
            case PopUpConfirmationType.DELETE:
                return ColorType.danger;
            case PopUpConfirmationType.WARNING:
                return ColorType.warning;
            case PopUpConfirmationType.INFO:
                return ColorType.info;
            default:
                return ColorType.primary;
        }
    };
    return (
        <div className={style.generalContainerButtons}>
            <div className={style.sectionContainerButtons}>
                {!hiddeCancelButon && (
                    <ButtonPrimaryInverse
                        title={buttonCancelTitle ? buttonCancelTitle : t("CANCEL")}
                        handleClick={onCancel}
                        disabled={disabled }
                    />
                )}

                <ButtonPrimary
                    title={buttonAcceptTitle ? buttonAcceptTitle : titleButtonAcept()}
                    handleClick={onAccept}
                    color={buttonType()}
                    disabled={disabled}
                    loading={loading}
                />
            </div>
        </div>
    );
};
