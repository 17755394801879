export interface IRepresentative {
    id?: number;
    representativeName?: string;
    representativeDocumentNumber: string;
    startDate?: Date;
    endDate?: Date;
    validated?: boolean;
    document?: File;
}

export interface ITableRepresentative {}

export const emptyRepresentative = (): IRepresentative => {
    return {
        representativeDocumentNumber: "",
        document: undefined,
    };
};
