import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import {
    bulkSignUpRequestAction,
    fetchUserProfileUsersList,
    resetRegiteredEmailListAction,
} from "../../../../../redux/actions/user/profile/userProfile";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { ButtonPrimary } from "../../../../common/buttons/buttons";
import { PopUpForm } from "../../../../common/popup/popUpForm/popUpForm";
import { Skeleton } from "../../../../common/skeleton/skeleton";
import { TitleH6 } from "../../../../common/titles/titles";
import { ManageUsersStyle } from "./containerStyle.jss";
import { ManagementEntityUserList } from "./list/list";
import { UserProfileEntityUsersSendSignupContainer } from "./list/sendSignup/container";
import { ColorType } from "../../../../../common/enum/ColorType";
import { SkeletonSizes } from "../../../../../common/constants";

export const UserProfileEntityUsersContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE.ENTITY" });
    const theme = useTheme();
    const style = ManageUsersStyle({ theme });
    const dispatch = useAppDispatch();

    const [showAddUsersEmailForm, setShowAddUsersEmailForm] = useState(false);
    const [isValidAddUsersEmailForm, setIsValidAddUsersEmailForm] = useState<boolean>(false);

    const onButtonClick = () => {
        setShowAddUsersEmailForm(true);
    };
    const {
        profile: { users },
        loadingUsers,
    } = useAppSelector((store) => store.userProfile);

    const onCancelAddUsersEmailForm = () => {
        dispatch(resetRegiteredEmailListAction());
        setShowAddUsersEmailForm(false);
    };

    const onSaveAddUsersEmailForm = () => {
        dispatch(bulkSignUpRequestAction()).then((response: any) => {
            response && setShowAddUsersEmailForm(false);
        });
    };

    useEffect(() => {
        !loadingUsers && !users.length && dispatch(fetchUserProfileUsersList());
    }, []);

    return (
        <>
            <PopUpForm
                content={<UserProfileEntityUsersSendSignupContainer setIsValidForm={setIsValidAddUsersEmailForm} />}
                title={t("ADD_USERS")}
                isVisible={showAddUsersEmailForm}
                height={"auto"}
                width={"auto"}
                onCancel={onCancelAddUsersEmailForm}
                onSave={onSaveAddUsersEmailForm}
                isDisabledButton={isValidAddUsersEmailForm}
            />

            <div className={style.manageUsersContainer}>
                {loadingUsers ? (
                    <Skeleton rows={SkeletonSizes.DEFAULT} />
                ) : (
                    <>
                        <div className={style.manageUsersHeader}>
                            <TitleH6
                                title={t("USERS_TITLE")}
                                color={ColorType.primary}
                                bold={true}
                            />
                            <ButtonPrimary
                                handleClick={onButtonClick}
                                title={t("ADD_USERS")}
                            />
                        </div>
                        <div className={style.users}>
                            <ManagementEntityUserList users={users} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
