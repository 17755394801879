import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigDockBlockItem } from "../../../../../models/config/blockDock";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateFormProperty } from "../../../../../redux/reducers/config/dockBlock/form";
import { compileNameOfProperty } from "../../../../../utils";
import { DockBlockSelector } from "../../../../request/common/selectors/dockBlock/dockBlockSelector";
import { DockSelector } from "../../../../request/common/selectors/docks/docksSelector";
import { ConfigMerchandiseCategoryFormStyle } from "./formStyle.jss";

export const ConfigDockBlockForm = () => {
    const theme = useTheme();
    const style = ConfigMerchandiseCategoryFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { form } = useAppSelector((state) => state.configDockBlockForm);
    const { control } = useForm<IConfigDockBlockItem>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateFormProperty({ name, value }));
    };

    const onSelectorChange = (option: any, name: string) => {
        onUpdateFormProperty(name, option.key);
    };

    return (
        <div className={style.form}>
            <div className={style.row}>
                <DockSelector
                    onChange={onSelectorChange}
                    selectedKey={form.dockId}
                    propertyName={compileNameOfProperty<IConfigDockBlockItem>("dockId")}
                    title={t("DOCK_MERCHANDISE.DOCK")}
                    control={control}
                    isRequired={true}
                ></DockSelector>
                <DockBlockSelector
                    onChange={onSelectorChange}
                    selectedKey={form.dockBlockId}
                    propertyName={compileNameOfProperty<IConfigDockBlockItem>("dockBlockId")}
                    title={t("DOCK_BLOCK.BLOCK")}
                    control={control}
                    isRequired={true}
                ></DockBlockSelector>
            </div>
        </div>
    );
};
