import { RequestApi } from "../../../api";
import { throwErrorToastrAsync } from "../../../utils";
import { setLoadingreviewsAssignmentQueueList, setReviewsAssignmentQueueList } from "../../reducers/assignment/list";
import { AppThunk, store } from "../../store";

const requestApi = new RequestApi();

export const fetchRequestPendingAssignmentListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingreviewsAssignmentQueueList(true));
        const response = await requestApi.getRequestPendingAssignments(mapFilterRequestPendingAssignment());
        dispatch(setReviewsAssignmentQueueList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingreviewsAssignmentQueueList(false));
    }
};

export const mapFilterRequestPendingAssignment = () => {
    const { filter } = store.getState().requestPendingAssignmentFilter;
    return {
        requestDescription: filter.requestDescription ? filter.requestDescription : null,
        certificateNumber: filter.certificateNumber ? filter.certificateNumber : null,
        transportUnitNumber: filter.transportUnitNumber ? filter.transportUnitNumber : null,
        showAssigned: filter.showAssigned,
    };
};
