import { IDropdownOption } from "@fluentui/react";
import { useEffect } from "react";
import { fetchRequestDayPermissionModes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const RequestDayPermissionSelector: React.FC<IBaseSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    isRequired,
    control,
    rules,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.requestDayPermission);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchRequestDayPermissionModes());
    }, [dispatch, data]);

    const onSelectorChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };
    return (
        <ControlledDropdown
            required={isRequired}
            options={mapIDescriptionToDropDownOptions(data)}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={loading}
            onChange={onSelectorChange}
            selectedKey={selectedKey}
        />
    );
};
