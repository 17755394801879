import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const EditRequestHistoryContainerStyle = createUseStyles((theme: any) => ({
    container: {
        [mediaQueries.TABLET]: {
            height: "calc(100vh - 18.5rem)",
        },

        height: theme.heights.requestEditContent,
        overflowY: "scroll",

        "& .timeline-container": {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            margin: "40px 0",
            paddingLeft: 306,
        },

        "& .timeline-container::after": {
            content: " ",
            position: "absolute",
            left: "269px",
            width: "1px",
            borderLeft: `3px dotted ${theme.colors.palette.purple}`,
            height: "100%",
            paddingRight: 10,
        },
    },
}));
