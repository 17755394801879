import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterTerminalColumns } from "./column/column";
import { fetchMasterTerminalList } from "../../../../../../redux/actions/resources/master/terminal/terminal";

interface IMasterTerminalList {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterTerminalList: React.FC<IMasterTerminalList> = ({ heigthPopUp, widthPopUp }) => {
    const { loading, list } = useAppSelector((state) => state.masterTerminalList);
    const dispatch = useAppDispatch();

    const renderColumns = () => {
        return MasterTerminalColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterTerminalList());
    }, [list]);

    return loading ? (
        <Skeleton rows={5}></Skeleton>
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={renderColumns}
        />
    );
};
