import { stringDefaultValue, throwErrorToastrAsync } from "../../../../utils";
import { AppThunk, store } from "../../../store";
import { UserApi } from "../../../../api";
import { IManagementUserSignupFilter } from "../../../../models/management/userSignup/IManagementUserSignupFilter";
import { fetchManagementUserSignupList, setLoadingManagementUserSignupList, setPage } from "../../../reducers/management/userSignup/list";

const userApi = new UserApi();

const getManagementUserSignupFilter = (): IManagementUserSignupFilter => {
    const { filter } = store.getState().managementUserSignupFilter;

    return {
        ...filter,
        userTypeId: filter.userTypeId !== stringDefaultValue.key ? filter.userTypeId : "",
        stateCode: filter.stateCode !== stringDefaultValue.key ? filter.stateCode : ""
    };
};

export const fetchManagementUserSignupListAction = (resetPagination : boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementUserSignupList(true));
            const filter = getManagementUserSignupFilter();
            const response = await userApi.getUserSignupListAsync(filter);
            dispatch(fetchManagementUserSignupList(response));
            if (resetPagination) {
                dispatch(setPage(1));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementUserSignupList(false));
        }
};

export const setPageAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        dispatch(setPage(page));
};

