import  { createUseStyles } from "react-jss";

export const ManagementUserSignupListColumnStyle = createUseStyles((theme: any) => ({
    header: {
        display: "flex",
    },
    sort: {
        marginTop: "5px",
    },
    iconSort: {
        fill: theme.colors.iconSort.disabled,
        width: "16px",
    },
    iconSorted: {
        fill: theme.colors.iconSort.enabled,
        width: "16px",
    },
    sortColumn: {
        width: 14,
        display: "flex",
        position: "absolute",
    },
    statePending: {
        fontWeight: theme.fonts.weight.bold,
        color: theme.colors.titles.warning.color,
    },
    stateApproved: {
        fontWeight: theme.fonts.weight.bold,
        color: theme.colors.titles.success.color,
    },
    stateRejected: {
        fontWeight: theme.fonts.weight.bold,
        color: theme.colors.titles.danger.color,
    },
}));
