import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IConfigMerchandiseCategoryForm } from "../../../../models/config/merchandiseCategory";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface IConfigMerchandiseCategoryFormState {
    form: IConfigMerchandiseCategoryForm;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IConfigMerchandiseCategoryFormState = {
    form: {
        merchandiseCategoryId: 0,
        merchandiseOriginId: 0,
        merchandiseTypeId: 0,
        humanConsumption: false,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const configMerchandiseCategoryFormSlice = createSlice({
    name: "controlExpedientListSlice",
    initialState,
    reducers: {
        resetConfigmerchandiseCategoryForm: () => initialState,
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setConfigMerchandiseCategoryForm: (state, action: PayloadAction<IConfigMerchandiseCategoryForm>) => {
            state.form = action.payload;
        },
        setConfigMerchandiseCategoryLoadingForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setConfigMerchandiseCategoryShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetConfigmerchandiseCategoryForm,
    setConfigMerchandiseCategoryForm,
    updateFormProperty,
    setConfigMerchandiseCategoryLoadingForm,
    setConfigMerchandiseCategoryShowPopUp,
} = configMerchandiseCategoryFormSlice.actions;

export default configMerchandiseCategoryFormSlice.reducer;
