import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IAppointmentHistoricFilter } from "../../../../models/appointment/IAppointmentHistoric";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { convertToLocal } from "../../../../utils";

interface IAppointmentFilterState {
    filter: IAppointmentHistoricFilter;
}

const initialState: IAppointmentFilterState = {
    filter: {
        startDate: convertToLocal(new Date(), FormatDate.ISO),
        endDate: convertToLocal(new Date(), FormatDate.ISO),
    },
};

export const appointmentHistoricFilterSlice = createSlice({
    name: "appointmentHistoricFilterSlice",
    initialState,
    reducers: {
        resetAppointmentHistoricFilter: () => initialState,
        updateAppointmentHistoricFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetAppointmentHistoricFilter, updateAppointmentHistoricFilterProperty } = appointmentHistoricFilterSlice.actions;

export default appointmentHistoricFilterSlice.reducer;
