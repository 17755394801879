import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IIncidenceTypesFilter } from "../../../../models/management/incidenceType/IIncidenceType";

interface ManagementIncidenceTypeFilterState {
    filter: IIncidenceTypesFilter;
}

const initialState: ManagementIncidenceTypeFilterState = {
    filter: {
        organismIds:[-1]
    },
};

export const managementIncidenceTypeFilterSlice = createSlice({
    name: "managementIncidenceTypeFilterSlice",
    initialState,
    reducers: {
        resetManagementIncidenceTypeFilter: () => initialState,
        updateManagementIncidenceTypeFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetManagementIncidenceTypeFilter, updateManagementIncidenceTypeFilterProperty } = managementIncidenceTypeFilterSlice.actions;

export default managementIncidenceTypeFilterSlice.reducer;
