import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { OriginSealItemStyle } from "./sealItem.jss";
interface IProps {
    seal: string;
    index: number;
    removeSeal:(index:number)=>void;
}

export const OriginSealItem: React.FC<IProps> = ({ seal, index, removeSeal }) => {
    const style = OriginSealItemStyle();


    const onRemoveItem = () => {
        removeSeal(index);
        
    };

    return (
        <>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.text}>{seal}</div>
                    <CustomIcons
                        iconName="Cancel"
                        onClick={onRemoveItem}
                        className={style.button}
                    ></CustomIcons>
                </div>
            </div>
        </>
    );
};
