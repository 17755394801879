import { pageSizes } from "../../../../common/constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IManagementNotification } from "../../../../models/management/notification/IManagementNotification";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { getFirstItemNumber, getLastItemNumber, getTotalPages } from "../../../../utils";

interface ManagementNotificationListState {
    list: IManagementNotification[];
    sort: ISortState;
    pagination: IPaginationState,
    loading: boolean;
}

const initialState: ManagementNotificationListState = {
    list: [],
    sort: emptySortState(),
    pagination: emptyPaginationState(pageSizes.MANAGEMENT_NOTIFICATION),
    loading: false,
};

export const managementNotificationListSlice = createSlice({
    name: "managementNotificationList",
    initialState,
    reducers: {
        resetManagementNotificationList: () => initialState,

        setLoadingManagementNotificationList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementNotificationList: (state, action: PayloadAction<IManagementNotification[]>) => {
            state.list = action.payload;
            state.pagination.totalItems = action.payload.length;
            state.pagination.totalPages = getTotalPages(state.pagination);
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
    },
});

export const { resetManagementNotificationList, setLoadingManagementNotificationList, fetchManagementNotificationList, updateSortColumn, setPage } =
    managementNotificationListSlice.actions;

export default managementNotificationListSlice.reducer;
