import { useTranslation } from "react-i18next";
import { IBaseMultipleNumberSelectorProps } from "../../../../request/common/selectors/IBaseMultipleSelectorProps";
import { MultipleNumberSelectorFilter } from "../../../../request/common/selectors/multipleSelectorFilter/multipleNumberSelectorFilter";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { NumericMultiSelector } from "../../multiSelectors/numericMultiSelector/numericMultiSelector";

export const MultiWeekDaySelector: React.FC<IBaseMultipleNumberSelectorProps> = ({ autocomplete, onChange, propertyName, selectedKeys, title }) => {
    const { t } = useTranslation("common", { keyPrefix: "WEEKDAYS" });
    const data = [
        {
            code: "M",
            description: t("MONDAY"),
            id: 1,
        },
        {
            code: "T",
            description: t("TUESDAY"),
            id: 2,
        },
        {
            code: "W",
            description: t("WEDNESDAY"),
            id: 3,
        },
        {
            code: "Th",
            description: t("THURSDAY"),
            id: 4,
        },
        {
            code: "F",
            description: t("FRIDAY"),
            id: 5,
        },
        {
            code: "ST",
            description: t("SATURDAY"),
            id: 6,
        },
        {
            code: "SN",
            description: t("SUNDAY"),
            id: 7,
        },
    ];

    return autocomplete ? (
        <MultipleNumberSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapIDescriptionToDropDownOptions(data)}
            label={title}
            propertyName={propertyName}
        />
    ) : (
        <NumericMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapIDescriptionToDropDownOptions(data)}
            isFormSelector
            title={title}
            name={propertyName}
        />
    );
};
