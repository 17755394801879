import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterDocumentType } from "../../../../../models/resources/master/IDocumentType";

export interface IMasterDocumentsTypesState {
    list?: IMasterDocumentType[];
    loading: boolean;
}

const initialState: IMasterDocumentsTypesState = {
    loading: false,
};

export const masterDocumentTypesList = createSlice({
    name: "masterTypeWareList",
    initialState,
    reducers: {
        resetMasterDocumentTypesList: () => initialState,
        setLoadingMasterDocumentTypesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterDocumentTypesList: (state, action: PayloadAction<IMasterDocumentType[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterDocumentTypesList, setLoadingMasterDocumentTypesList, setMasterDocumentTypesList } = masterDocumentTypesList.actions;

export default masterDocumentTypesList.reducer;
