import { createUseStyles } from "react-jss";

export const LoginStyle = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        paddingTop: "15vh",
        display: "flex",
        flexFlow: "row nowrap",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.gaps.sizes.md,
    },
    card: {
        display: "flex",
        flexFlow: "column",
        gap: theme.gaps.sizes.base,
        padding: "30px",
        width: "100vw",
        maxWidth: "450px",
        paddingTop: theme.paddings.login.default,
    },
}));
