export enum DocumentTypes {
    ACTION_ORGANISM = "AO",
    C5 = "C5",
    FITO = "CFS",
    CUSTOMS_DOCUMENT = "DUA",
    INVOICE = "FAC",
    FUMIGATION_REPORT = "IF",
    OTHERS = "OT",
    PACKING_LIST = "PL",
    TRANSFER_REQUEST = "ST",
}

export enum DocumentTypesWithId {
    ACTION_ORGANISM = 1,
    C5 = 2,
    FITO = 3,
    CUSTOMS_DOCUMENT = 4,
    INVOICE = 5,
    FUMIGATION_REPORT = 6,
    OTHERS = 7,
    PACKING_LIST = 8,
    TRANSFER_REQUEST = 9,
}
