import { useTranslation } from "react-i18next";
import { TitleH4, TitleH5 } from "../../../../../common/titles/titles";
import { UserTruckerFileProfileDataStyle } from "./filesStyle.jss";
import { useSelector } from "react-redux";
import { userProfileSelector } from "../../../../../../redux/selectors/user/profile/userProfile";
import { useMemo } from "react";
import { UploadFileCustom } from "../../../../../common/uploadFile/uploadFile";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { FileSize } from "../../../../../../common/enum/files/FileSize";
import { ProfileDocumentTypes } from "../../../../../../common/enum/ProfileDocumentTypes";
export const UserTruckerFileProfileData = () => {
    const style = UserTruckerFileProfileDataStyle();
    const { t } = useTranslation("common");
    const userProfile = useSelector(userProfileSelector);

    const fiscalCaldBlob = useMemo(() => {
        return userProfile.blobs.find((item) => item.type === ProfileDocumentTypes.FISCAL_CARD);
    }, [userProfile]);

    const banckAuthorizationBlob = useMemo(() => {
        return userProfile.blobs.find((item) => item.type === ProfileDocumentTypes.BANK_AUTHORIZATION);
    }, [userProfile]);

    return (
        <>
            <div className={style.row}>
                <div className={style.title}>
                    <TitleH4
                        title={t("USER.TITLES.FILES_DATA")}
                        color={ColorType.primary}
                    />
                </div>
            </div>

            <div className={style.row}>
                <div className={style.columnDocument}>
                    <TitleH5
                        title={t("USER.TITLES.SECURITY_INFO")}
                        className={style.documentTitle}
                    ></TitleH5>
                    {banckAuthorizationBlob && (
                        <div className={style.document}>
                            <UploadFileCustom
                                name={""}
                                onChangeFile={() => {}}
                                title={""}
                                buttonStyle={ColorType.primary}
                                maxSize={FileSize._5MB}
                                hiddeRemoveDocument={true}
                                file={banckAuthorizationBlob}
                            />
                        </div>
                    )}
                </div>
                <div className={style.columnDocument}>
                    <TitleH5
                        title={t("USER.TITLES.FISCAL_CARD")}
                        className={style.documentTitle}
                    ></TitleH5>
                    {fiscalCaldBlob && (
                        <div className={style.document}>
                            <UploadFileCustom
                                name={""}
                                onChangeFile={() => {}}
                                title={""}
                                buttonStyle={ColorType.primary}
                                maxSize={FileSize._5MB}
                                hiddeRemoveDocument={true}
                                file={fiscalCaldBlob}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
