import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const UserSignupResumeContainerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: theme.paddings.default,
    },
    buttonContainer: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "end",
        columnGap: theme.paddings.default,
        paddingBottom: theme.paddings.sizes.xl,
    },
}));
