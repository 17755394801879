import { AppThunk } from "../../store";
import { sessionKeysLoaded } from "../../reducers/common/initSessionKeys";
import { LocalStorageKey, LocalStorageService } from "../../../services/localStorageService";
import { IRequestFilter } from "../../../models";
import { initFilterFromSession, setRequestFilterEndDate, setRequestFilterStartDate } from "../../reducers/request/list/filter";

export const initSessionAction = (): AppThunk => (dispatch) => {
    const localStorageService = new LocalStorageService();
    try {
        const filter = localStorageService.getItem(LocalStorageKey.REQUEST_FILTER) as IRequestFilter;
        const startDate = localStorageService.getItem(LocalStorageKey.REQUEST_FILTER_START_DATE) as string;
        const endDate = localStorageService.getItem(LocalStorageKey.REQUEST_FILTER_END_DATE) as string;
        filter && dispatch(initFilterFromSession(filter));
        startDate && dispatch(setRequestFilterStartDate(startDate));
        endDate && dispatch(setRequestFilterEndDate(endDate));
    } finally {
        dispatch(sessionKeysLoaded());
    }
};
