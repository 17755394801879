import { FontIcon } from "@fluentui/react";
import { useTheme } from "react-jss";
import { IPagination } from "../../../models/common/IPagination";
import { PaginationStyle } from "./pagination.jss";

export const PaginateComponent: React.FC<IPagination> = ({
    currentPage,
    totalPages,
    totalItems,
    footerTitle,
    onPageUp,
    onPageDown,
    onFirstPage,
    onLastPage,
}) => {
    const theme = useTheme();
    const styles = PaginationStyle({ theme });

    const disabledButton = (visible: boolean): string | null => {
        return visible ? styles.iconDisabled : null;
    };

    return (
        <div className={styles.container}>
            {totalItems > 0 ? (
                <>
                    <div className={styles.requests}>{footerTitle}</div>
                    <div className={styles.pages}>
                        <FontIcon
                            aria-label="ChevronLeftEnd"
                            iconName="ChevronLeftEnd6"
                            onClick={() => onFirstPage(currentPage)}
                            className={`${styles.icon} ${disabledButton(currentPage === 1)}`}
                        />
                        <FontIcon
                            aria-label="ChevronLeft"
                            iconName="ChevronLeftSmall"
                            onClick={() => onPageDown(currentPage)}
                            className={`${styles.icon}  ${disabledButton(currentPage === 1)}`}
                        />
                        <div>
                            {currentPage}/{totalPages}
                        </div>
                        <FontIcon
                            aria-label="ChevronRight"
                            iconName="ChevronRightSmall"
                            onClick={() => onPageUp(currentPage)}
                            className={`${styles.icon}  ${disabledButton(currentPage === totalPages)}`}
                        />
                        <FontIcon
                            aria-label="ChevronRightSmall"
                            iconName="ChevronRightEnd6"
                            onClick={() => onLastPage(currentPage)}
                            className={`${styles.icon}  ${disabledButton(currentPage === totalPages)}`}
                        />
                    </div>
                </>
            ) : null}
        </div>
    );
};
