import { createUseStyles } from "react-jss";

export const ImageSliderStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        "& .image-gallery": {
            width: "100%",
        },
        "& .image-gallery-svg": {
            color: `${theme.colors.palette.purple} !important`,
        },
        "& .image-gallery-index": {
            color: `${theme.colors.palette.purple} !important`,
        },
        "& button.image-gallery-bullet.active": {
            background: `${theme.colors.palette.purple} !important`,
        },
        "& button.image-gallery-bullet:hover": {
            borderColor: `${theme.colors.palette.purple} !important`,
        },

        "& .image-gallery-thumbnail.active": {
            borderColor: `${theme.colors.palette.purple} !important`,
        },
        "& .image-gallery-thumbnail:hover": {
            borderColor: `${theme.colors.palette.purple} !important`,
            opacity: "0.7",
        },
    },
}));
