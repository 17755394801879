import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IAdminData } from "../../../../../../models";
import { ILabelTextStyleProps, LabelText } from "../../../../../common/labels/labelText/labelText";
import { TitleH5 } from "../../../../../common/titles/titles";
import { SignupResumeDataAdminStyle } from "./resumeDataAdmin.jss";

interface Props {
    data: IAdminData;
}
export const SignupResumeDataAdmin: React.FC<Props> = ({ data }) => {
    const styles = SignupResumeDataAdminStyle();
    const { t } = useTranslation("common");
    const theme = useTheme() as any;
    const propsStyle: ILabelTextStyleProps = {
        dataColor: theme.colors.titles.secondary,
        dataSize: "",
        labelColor: theme.colors.titles.secondary,
        labelSize: "",
    };

    return (
        <div className={styles.container}>
            <div className={styles.twoItemsRow}>
                <LabelText
                    name={t("USER.SIGNUP.LANDLINE")}
                    value={data.phone}
                    styleProps={propsStyle}
                />
                <LabelText
                    name={t("USER.SIGNUP.MOBILE_PHONE")}
                    value={data.mobilePhone}
                    styleProps={propsStyle}
                />
            </div>
            <TitleH5 title={"Emails"}></TitleH5>
            <ul className={styles.emailList}>
                {data.emails.map((email) => {
                    return <li className={styles.emailItem}>{email.email}</li>;
                })}
            </ul>
        </div>
    );
};
