import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { fetchRequestIntegrationRequest } from "../../redux/actions/request/integrationsRequest/integrations";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { NavHeaderComponent } from "../common/navHeader/navHeader";
import { IntegrationRequestList } from "./list/list";
import { RequestsIntegrationFilter } from "./filter/filter";
import { IntegrationRequestController } from "./controller/controller";


export const IntegrationRequestContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "REQUESTS" });
    const dispatch = useAppDispatch();
    const theme: any = useTheme();

    const {loading, list} = useAppSelector((state) => state.requestIntegrationList);

 

    useEffect(() => {
       list === undefined && dispatch(fetchRequestIntegrationRequest());
    }, [list]);

    return (
        <div>
            <NavHeaderComponent title={t("INTEGRATIONS")} />
            <RequestsIntegrationFilter />
            <IntegrationRequestList loading={loading} list={list}    />
            <IntegrationRequestController />
        </div>
    );
};
