import { useTheme } from "react-jss";
import { CustomIcons } from "../customIcons/customIcons";
import { EmailViewerStyle } from "./emailViewer.jss";

interface IEmailViewerProps {
    tos: string[];
    subject: string;
    body: string;
    height?: string;
}

export const EmailViewer: React.FC<IEmailViewerProps> = ({ tos, subject, body, height }) => {
    const theme = useTheme();
    const style = EmailViewerStyle({ theme, height });
    
    return (
        <div className={style.container}>
            <div className={style.to}>
                <CustomIcons
                    iconName="Contact"
                    size={15}
                    isButton={false}
                    margin={"0px"}
                    className={style.toIcon}
                    position="start"
                    disabledSelect={true}
                />
                {tos.map((email, index: number) => (
                    <div className={style.toPill} key={email}>
                        {email}
                    </div>
                ))}
            </div>
            <div className={style.subject}>
                {subject}
            </div>
            <div className={style.body}>
                <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
        </div>
    );
};
