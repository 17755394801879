import { createUseStyles } from "react-jss";

export const ManagementWorkCalendarContainerStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        padding: 15,
    },
    headerSelector: {
        display: "flex",
        height: 65,
        background: theme.colors.workCalendar.headerSelector,
        borderRadius: 10,
        padding: 10,
    },
    separador: {
        borderLeft: "1px dotted black",
        margin: "0 20px",
    },
    selectorMonth: {
        display: "flex",
        alignItems: "center",
    },
    nonWorkDay: {
        display: "flex",
        background: theme.colors.workCalendar.nonWorkDayBackground,
        color: "#B53F7A",
        fontSize: theme.fonts.sizes.xxs,
        padding: 5,
        borderRadius: 25,
    },
    daySelected: {
        color: "#B53F7A",
    },
    totalDays: {
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
        color: "#B53F7A",
    },
    selectorYear: {
        color: theme.colors.workCalendar.weekColor,
        display: "flex",
        gap: 15,
        fontSize: theme.fonts.sizes.xl,
        alignItems: "center",
    },
    selector: {
        alignItems: "center",
        minWidth: 200,
        width: 200,
        " & .ms-Dropdown-container": {
            borderBottom: "none",
        },
        " & .ms-Dropdown": {
            paddingBottom: 6,
            "&:hover, &:active, &:focus": {
                color: `${theme.colors.workCalendar.weekColor} !important`,
            },
        },
        " & .ms-Dropdown-title": {
            height: "auto",
            lineHeight: "normal",
            minHeight: 0,
            color: theme.colors.workCalendar.weekColor,
            fontWeight: theme.fonts.weight.bold,
            fontSize: theme.fonts.sizes.xl,
            "&:hover, &:active, &:focus": {
                color: `${theme.colors.workCalendar.weekColor} !important`,
            },
        },
        " & .ms-Dropdown-caretDownWrapper": {
            height: "auto",
            paddingTop: 5,
            " & i": {
                color: theme.colors.workCalendar.weekColor,
                fontSize: theme.fonts.sizes.xl,
                fontWeight: theme.fonts.weight.bold,
                "&:hover, &:active, &:focus": {
                    color: `${theme.colors.workCalendar.weekColor} !important`,
                },
            },
        },
    },
    icon: {
        cursor: "pointer",
    },
    iconDelete: {
        cursor: "pointer",
        transform: "rotate(45deg)",
        fontSize: theme.fonts.sizes.sm,
        marginLeft: "auto",
    },
    disabled: {
        background: `${theme.colors.workCalendar.disabledBackground} !important`,
        cursor: "default !important",
        color: "#D4D4D4 !important",
    },
    weekend: {
        background: `${theme.colors.workCalendar.weekend} !important`,
    },
    calendar: {
        height: "calc(100vh - 250px )",
    },
    rowWeek: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        " & div": {
            fontSize: theme.fonts.sizes.md,
            color: theme.colors.workCalendar.weekColor,
            fontWeight: theme.fonts.weight.bold,
            width: "15%",
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: 10,
            gap: 20,
        },
    },
    row: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        "&:nth-child(2) ": {
            width: "auto",
            overflow: "hidden",
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            "& div.grid-element:first-child": { borderTopLeftRadius: 10 },
            "& div.grid-element:last-child": { borderTopRightRadius: 10 },
        },
        "&:last-child ": {
            width: "auto",
            overflow: "hidden",
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            "& div.grid-element:first-child": { borderBottomLeftRadius: 10 },
            "& div.grid-element:last-child": { borderBottomRightRadius: 10 },
        },
        " & div.grid-element": {
            fontSize: theme.fonts.sizes.lg,
            color: theme.colors.workCalendar.color,
            background: theme.colors.workCalendar.background,
            border: `1px solid ${theme.colors.workCalendar.borderColor}`,
            fontWeight: theme.fonts.weight.bold,
            width: "15%",
            height: "calc(10.5vh - 1.5px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 10,
            position: "relative",
            cursor: "pointer",
        },
    },
    spinnerContainer: {
        position: "absolute",
        left: 0,
        top: 25,
        height: "100%",
        width: "100%",
        zIndex: 1000,
    },
    backdrop: {
        zIndex: 0,
        position: "absolute",
        opacity: 0.2,
        backgroundColor: "black",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
}));
