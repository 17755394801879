import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IRequestDetail } from "../../../../models/requests/IRequestDetail";

interface DraftListState {
    list: IRequestDetail[];
    loading: boolean;
    sort: ISortState;
    showAllInspections: boolean;
}

const initialState: DraftListState = {
    list: [] as IRequestDetail[],
    loading: false,
    sort: emptySortState(),
    showAllInspections: false,
};

export const draftListSlice = createSlice({
    name: "draftList",
    initialState,
    reducers: {
        resetDraftList: () => initialState,

        loadingDraftList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchDraftList: (state, action: PayloadAction<IRequestDetail[]>) => {
            state.list = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        }, 
    },
});

export const { resetDraftList,updateSortColumn, loadingDraftList, fetchDraftList } = draftListSlice.actions;

export default draftListSlice.reducer;
