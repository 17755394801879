import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDockDisplay } from "../../../../models/management/dockDisplay/IDockDisplay";

export interface IManagementDockDisplayState {
    data?: IDockDisplay | null;
    interval: NodeJS.Timer | undefined;
    autoReload: boolean;
    loading: boolean;
}

const initialState: IManagementDockDisplayState = {
    loading: false,
    interval: undefined,
    autoReload: true,
};

export const managementDockDisplaySlice = createSlice({
    name: "managementDockDisplaySlice",
    initialState,
    reducers: {
        resetManagementDockDisplay: () => initialState,
        setLoadingManagementDockDisplay: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setManagementDockDisplay: (state, action: PayloadAction<IDockDisplay>) => {
            state.data = action.payload;
        },

        setManagementClearDockDisplay: (state) => {
            state.data = null;
        },
        setIntervalState: (state, action: PayloadAction<NodeJS.Timer | undefined>) => {
            state.interval = action.payload;
        },
        setAutoReload: (state, action: PayloadAction<boolean>) => {
            state.autoReload = action.payload;
        },
    },
});

export const {
    setIntervalState,
    resetManagementDockDisplay,
    setLoadingManagementDockDisplay,
    setManagementDockDisplay,
    setManagementClearDockDisplay,
} = managementDockDisplaySlice.actions;

export default managementDockDisplaySlice.reducer;
