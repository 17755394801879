import { createUseStyles } from "react-jss";
import { cssConstants, mediaQueries } from "../../../../../../common/constants";

const sealGap = 24;
export const OriginSealsStyle = createUseStyles((theme: any) => ({
    upsertSealsContainer:(props:any)=> ({
        display: "flex",
        width: "100%",
        maxWidth: props.isOriginalSeal ? "30vw" : "50vw",
        flexDirection: "column",
        [mediaQueries.MOBILE]: {
            maxWidth: "100vw",
        },
    }),
    sealDataContainer: {
        rowGap: 12,
        paddingTop: 16,
        paddingBottom: 16,
        flexWrap: "no wrap",
        display: "flex",
        flexDirection: "row",
        boxSizing: "content-box",
        columnGap: sealGap,
    },
    buttonContainer: {
        minWidth: 100,
        display: "flex",
        justifyContent: "end",
    },
    titleContainer: {
        width: "100%",
    },
    upsertSealData: {
        overflowY: "auto",
        rowGap: 12,
        paddingTop: 8,
        paddingBottom: 8,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
        boxSizing: "content-box",
        columnGap: sealGap,
        [mediaQueries.MOBILE]: {
            maxHeight: "50vh",
        },
        maxHeight: "25vh",
    },
    column: {
        display: "flex",
        flexDirection: "row",
        height: 30,
    },
    title: {
        flexBasis: "100%",
        paddingTop: 8,
        paddingBottom: 8,
    },
    addSeal: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        cursor: "pointer",
        color: theme.colors.navLinks.primary.color,
        fontWeight: 400,
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: cssConstants.SPACE_BETWEEN,
        alignItems: "start",
        paddingTop: theme.paddings.default,
        marginTop: "auto",
        marginLeft: "auto",
    },
    buttons: {
        display: "flex",
        columnGap: theme.gaps.sizes.md,
        "& button": {
            minWidth: 120,
        },
    },
}));
