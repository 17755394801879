import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMerchandiseCategoryGroup } from "../../../../../models/resources/master/IGroupMerchandiseCategory";

export interface IMasterGroupMerchandiseCategoryState {
    list?: IMerchandiseCategoryGroup[];
    loading: boolean;
}

const initialState: IMasterGroupMerchandiseCategoryState = {
    loading: false,
};

export const masterGroupMerchandiseCategoryList = createSlice({
    name: "masterTypeWareList",
    initialState,
    reducers: {
        resetMasterGroupMerchandiseCategoryList: () => initialState,
        setLoadingMasterGroupMerchandiseCategoryList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterGroupMerchandiseCategoryList: (state, action: PayloadAction<IMerchandiseCategoryGroup[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterGroupMerchandiseCategoryList, setLoadingMasterGroupMerchandiseCategoryList, setMasterGroupMerchandiseCategoryList } =
    masterGroupMerchandiseCategoryList.actions;

export default masterGroupMerchandiseCategoryList.reducer;
