import { IPage, IRenderFunction, Sticky, StickyPositionType, TooltipHost } from "@fluentui/react";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsColumnRenderTooltipProps,
    IDetailsFooterProps,
    IDetailsHeaderProps,
    IDetailsList,
    IDetailsListProps,
    IDetailsRowProps,
    IDetailsRowStyles,
    SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import React, { createRef, useEffect, useRef } from "react";
import { useTheme } from "react-jss";
import { IPagination } from "../../../models/common/IPagination";
import { LocalStorageKey, LocalStorageService } from "../../../services/localStorageService";
import { PaginateComponent } from "../pagination/pagination";
import { CustomDetailsListStyle } from "./customDetailsList.jss";

interface IProps {
    data: any[];
    pagination?: IPagination;
    renderColumns: () => IColumn[];
    height?: string;
    customFooter?: (props: IDetailsFooterProps) => JSX.Element;
    onRenderRow?: IRenderFunction<IDetailsRowProps>;
    persistScroll?: boolean;
}
export const CustomDetailsList: React.FC<IProps> = ({ data, pagination, renderColumns, height, customFooter, onRenderRow, persistScroll }) => {
    const theme = useTheme();
    const styles = CustomDetailsListStyle({ theme, height });
    const detailsListRef = createRef<IDetailsList>();
    const scrollContainerRef = useRef(null);
    const localStorageService = new LocalStorageService();

    useEffect(() => {
        const attemptAttachScrollListener = () => {
            const contentWrapper = document.querySelector(".ms-DetailsList-contentWrapper");

            if (!contentWrapper) {
                setTimeout(attemptAttachScrollListener, 100);
                return;
            }

            const handleScroll = () => {
                const scrollPosition = contentWrapper.scrollTop;
                localStorageService.setItem(LocalStorageKey.DETAILS_LIST_SCROLL_TOP, scrollPosition.toString());
            };

            contentWrapper.addEventListener("scroll", handleScroll);

            return () => {
                contentWrapper.removeEventListener("scroll", handleScroll);
            };
        };

        attemptAttachScrollListener();
    }, []);

    const onRenderDetailsFooter: IRenderFunction<IDetailsFooterProps> = (props, defaultRender) => {
        if (!props && !pagination && !customFooter) {
            return null;
        }

        const paginationCustom = () => {
            const resetScroll = () => {
                if (detailsListRef.current) {
                    detailsListRef.current.scrollToIndex(0);
                    localStorageService.setItem(LocalStorageKey.DETAILS_LIST_SCROLL_TOP, "0");
                }
            };

            if (pagination && data.length > 0) {
                const setPagination = {
                    ...pagination,
                    onFirstPage: (e: any) => {
                        resetScroll();
                        pagination.onFirstPage(e);
                    },
                    onLastPage: (e: any) => {
                        resetScroll();
                        pagination.onLastPage(e);
                    },
                    onPageDown: (e: any) => {
                        resetScroll();
                        pagination.onPageDown(e);
                    },
                    onPageUp: (e: any) => {
                        resetScroll();
                        pagination.onPageUp(e);
                    },
                };

                return <PaginateComponent {...setPagination} />;
            }
            return null;
        };

        const footer = (
            <div className={styles.footer}>
                {customFooter && props && data.length > 0 && <div className={styles.customFooter}>{customFooter(props)}</div>}
                {paginationCustom()}
            </div>
        );

        return (customFooter && props) || (pagination && data.length > 0) ? footer : <></>;
    };

    const renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => (
            <TooltipHost {...tooltipHostProps} />
        );
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced
            >
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    const handlePagesUpdated = (pages: IPage<any>[]) => {
        const scrollSaved = localStorageService.getItem(LocalStorageKey.DETAILS_LIST_SCROLL_TOP);
        const contentWrapper = document.querySelector(".ms-DetailsList-contentWrapper");
        if (scrollSaved !== null && contentWrapper) {
            setTimeout(() => {
                persistScroll && contentWrapper.scrollTo(0, +scrollSaved);
            }, 250);
        }
    };

    const defaultOnRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = { color: "black" };

            return (
                <DetailsRow
                    {...props}
                    styles={customStyles}
                />
            );
        }
        return null;
    };

    return (
        <div
            className={styles.listContainer}
            ref={scrollContainerRef}
        >
            <DetailsList
                componentRef={detailsListRef}
                items={data}
                setKey="set"
                selectionMode={SelectionMode.none}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderDetailsHeader={renderFixedDetailsHeader}
                onRenderDetailsFooter={onRenderDetailsFooter}
                columns={renderColumns()}
                onRenderRow={onRenderRow ?? defaultOnRenderRow}
                initialFocusedIndex={pagination?.firstItemNumber}
                listProps={{ onPagesUpdated: handlePagesUpdated }}
            />
        </div>
    );
};
