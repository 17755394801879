import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestPendingAssignment } from "../../../models/assignment/IAssignment";

interface IInitialState {
    data?: IRequestPendingAssignment;
    loading: boolean;
}

const initialState: IInitialState = {
    loading: false,
};

export const requestPendingAssignmentQueue = createSlice({
    name: "statiticsCustomerList",
    initialState,
    reducers: {
        resetReviewsAssignmentQueueList: () => initialState,
        setReviewsAssignmentQueueList: (state, action: PayloadAction<IRequestPendingAssignment>) => {
            state.data = action.payload;
        },
        setLoadingreviewsAssignmentQueueList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetReviewsAssignmentQueueList, setLoadingreviewsAssignmentQueueList, setReviewsAssignmentQueueList } =
    requestPendingAssignmentQueue.actions;

export default requestPendingAssignmentQueue.reducer;
