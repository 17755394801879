import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IIncidenceTypesItem } from "../../../../models/management/incidenceType/IIncidenceType";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface ManagementIncidenceTypeListState {
    form: IIncidenceTypesItem;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: ManagementIncidenceTypeListState = {
    form: {
        description: "",
        customerNotice: false,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managementIncidenceTypeFormSlice = createSlice({
    name: "managementIncidenceTypeFormtSlice",
    initialState,
    reducers: {
        resetManagementIncidenceTypeState: () => initialState,
        resetManagementIncidenceTypeForm: (state) => {
            state.form = initialState.form;
        },
        updateManagementIncidenceTypeFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setManagementIncidenceTypeFormData: (state, action: PayloadAction<IIncidenceTypesItem>) => {
            state.form = action.payload;
        },
        setManagementIncidenceTypeFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowPopUpIncidenceTypeForm: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetManagementIncidenceTypeState, 
    resetManagementIncidenceTypeForm,
    setManagementIncidenceTypeFormData,
    updateManagementIncidenceTypeFormProperty,
    setManagementIncidenceTypeFormLoading,
    setShowPopUpIncidenceTypeForm,
} = managementIncidenceTypeFormSlice.actions;

export default managementIncidenceTypeFormSlice.reducer;
