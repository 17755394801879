import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IAppointmentDatePermissionForm } from "../../../../models/masterAppointment/IAppointmentDatePermission";

export interface IMasterAppointmentDatePermissionFormState {
    form: IAppointmentDatePermissionForm;
    loading: boolean;
}

const initialState: IMasterAppointmentDatePermissionFormState = {
    form: {
        active: true,
        referenceDayId: 0,
        endTime: "",
        startTime: "",
        workDays: false,
        friday: false,
        manual: false,
        merchandiseCategoryGroupId: 0,
        monday: false,
        saturday: false,
        sunday: false,
        thursday: false,
        transportUnitId: 0,
        tuesday: false,
        wednesday: false,
        weekDays: [-1],
    },
    loading: false,
};

export const masterAppointmentDatePermissionForm = createSlice({
    name: "masterAppointmentDatePermissionForm",
    initialState,
    reducers: {
        resetMasterAppointmentDatePermissionForm: () => initialState,
        setLoadingMasterAppointmentDatePermissionForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentDatePermissionForm: (state, action: PayloadAction<IAppointmentDatePermissionForm>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentDatePermissionProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterAppointmentDatePermissionForm,
    setLoadingMasterAppointmentDatePermissionForm,
    setMasterAppointmentDatePermissionForm,
    updateMasterAppointmentDatePermissionProperty,
} = masterAppointmentDatePermissionForm.actions;

export default masterAppointmentDatePermissionForm.reducer;
