import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IInvoicingNonSynchronized } from "../../../../../models";
import { putInvoicingNonSynchronizedAction } from "../../../../../redux/actions/invoicing/nonSynchronized/nonSynchronized";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/invoicing/nonSynchronized/list";
import { store } from "../../../../../redux/store";
import { widths } from "../../../../../styles/romeu/widths";
import { convertToLocal } from "../../../../../utils/dateUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { InvoicingNonSynchronizedColumnStyle } from "./columnStyle.jss";

export const InvoicingNonSynchronizedColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = InvoicingNonSynchronizedColumnStyle({ theme });
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const { sort } = useAppSelector((store) => store.invoicingNonSynchronizedList);

    const onClickSyncInvoicing = useCallback(
        (id: number) => {
            dispatch(putInvoicingNonSynchronizedAction([id]));
        },
        [dispatch],
    );

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().invoicingNonSynchronizedList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 200,
            name: t("INVOICED.NON_SYNCHRONIZED.UTI"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("transportUnitNumber")}
                    title={t("INVOICED.NON_SYNCHRONIZED.UTI")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("transportUnitNumber"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => <span>{item.transportUnitNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("date"),
            minWidth: 100,
            maxWidth: 100,
            name: t("INVOICED.NON_SYNCHRONIZED.DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("date")}
                    title={t("INVOICED.NON_SYNCHRONIZED.DATE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("date"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => <span>{convertToLocal(item.date, FormatDate.DATE) ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("tariffDescription"),
            minWidth: 150,
            maxWidth: 300,
            name: t("INVOICED.NON_SYNCHRONIZED.TARIFFDESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("tariffDescription")}
                    title={t("INVOICED.NON_SYNCHRONIZED.TARIFFDESCRIPTION")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("tariffDescription"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => <span>{item.tariffDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("quantity"),
            minWidth: 50,
            maxWidth: 100,
            name: t("INVOICED.NON_SYNCHRONIZED.QUANTITY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("quantity")}
                    title={t("INVOICED.NON_SYNCHRONIZED.QUANTITY")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("quantity"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => <span>{item.quantity ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("unitPrice"),
            minWidth: 50,
            maxWidth: 100,
            name: t("INVOICED.NON_SYNCHRONIZED.UNITPRICE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("unitPrice")}
                    title={t("INVOICED.NON_SYNCHRONIZED.UNITPRICE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("unitPrice"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => (
                <span>{item.unitPrice ? `${item.unitPrice}${DefaultValues.EURO}` : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("amount"),
            minWidth: 50,
            maxWidth: 100,
            name: t("INVOICED.NON_SYNCHRONIZED.AMOUNT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("amount")}
                    title={t("INVOICED.NON_SYNCHRONIZED.AMOUNT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("amount"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => (
                <span>{item.amount ? `${item.amount}${DefaultValues.EURO}` : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("invoiced"),
            minWidth: 50,
            maxWidth: 100,
            name: t("INVOICED.NON_SYNCHRONIZED.INVOICED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("invoiced")}
                    title={t("INVOICED.NON_SYNCHRONIZED.INVOICED")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("invoiced"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => <span>{item.invoiced ? t("COMMON.YES") : t("COMMON.NO")}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingNonSynchronized>("action"),
            minWidth: 100,
            maxWidth: 150,
            name: t("INVOICED.NON_SYNCHRONIZED.ACTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingNonSynchronized>("action")}
                    title={t("INVOICED.NON_SYNCHRONIZED.ACTION")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingNonSynchronized>("action"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingNonSynchronized) => <span>{item.action}</span>,
            isResizable: true,
        },
        {
            key: "",
            minWidth: 40,
            maxWidth: 70,
            name: "",
            onRender: (item: IInvoicingNonSynchronized) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={"SyncOccurence"}
                        className={styles.reloadIcon}
                        tooltip={t("INVOICED.NON_SYNCHRONIZED.SYNC_UP_CONCEPT")}
                        onClick={() => onClickSyncInvoicing(item.id)}
                        size={widths.management.icons}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
