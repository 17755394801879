import { useTranslation } from "react-i18next";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";

import { PopUpConfirmation } from "../popUpConfirmation/popUpConfirmation";

interface IPopUpBlockerProps {
    showPopUp: boolean;
    stayOn: () => void;
    exitTo: () => void;
}

export const PopUpBlocker = ({ showPopUp, stayOn, exitTo }: IPopUpBlockerProps) => {
    const { t } = useTranslation("common", { keyPrefix: "BLOCKER_MESSAGES" });

    return (
        <PopUpConfirmation
            showPopUp={showPopUp}
            onCancel={stayOn}
            onAccept={exitTo}
            type={PopUpConfirmationType.INFO}
            width={"30vw"}
            height={"auto"}
            message={t("CONFIRMATION_MESSAGE")}
            buttonAcceptTitle={t("ACCEPT")}
            buttonCancelTitle={t("CANCEL")}
        />
    );
};
