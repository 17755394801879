import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { IGenericPayload } from "../../../../../models";
import { IConceptFormTariff } from "../../../../../models/requests/IEditRequestBilling";

interface EditRequestInvoiceConceptState {
    form: IConceptFormTariff;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: EditRequestInvoiceConceptState = {
    form: {
        quantity: 0,
        unitPrice: 0,
        tariffId: 0,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const editRequestInvoiceConceptForm = createSlice({
    name: "editRequestInvoiceConceptForm",
    initialState,
    reducers: {
        resetEditRequestInvoiceConceptFormDataState: () => initialState,

        setEditRequestInvoiceConceptFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEditRequestInvoiceConceptFormData: (state, action: PayloadAction<IConceptFormTariff>) => {
            state.form = action.payload;
        },

        updateEditRequestInvoiceConceptProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setEditRequestInvoiceConceptFormPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetEditRequestInvoiceConceptFormDataState,
    setEditRequestInvoiceConceptFormLoading,
    setEditRequestInvoiceConceptFormData,
    updateEditRequestInvoiceConceptProperty,
    setEditRequestInvoiceConceptFormPopUp,
} = editRequestInvoiceConceptForm.actions;

export default editRequestInvoiceConceptForm.reducer;
