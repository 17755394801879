import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterTypeCertificateListContainer } from "./list/list";
import { setCertificates, setCertificatesByOrganism } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterCertificateTypeList } from "../../../../../redux/reducers/resources/master/certificateType/list";

export class MasterTypeCertificateContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterCertificateTypeList());
        store.dispatch(setCertificates([]));
        store.dispatch(setCertificatesByOrganism([]));
    }

    render(): React.ReactNode {
        return (
            <MasterTypeCertificateListContainer
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
