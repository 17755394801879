import { useTheme } from "react-jss";
import { NavLink } from "react-router-dom";
import { SiteNavItemStyles } from "./siteNavItem.jss";

export interface ISiteNavItemProps {
    id: string;
    name: string;
    baseUri: string;
    openNewWindow?: boolean;
    onClick?: () => void;
}

export const SiteNavItem = (props: ISiteNavItemProps) => {
    const theme = useTheme();
    const styles = SiteNavItemStyles({ theme });

    return (
        <>
            <NavLink
                className={({ isActive }) => (isActive ? styles.itemActive : styles.item)}
                to={props.baseUri}
                onClick={props.onClick}
                target={props.openNewWindow ? "_blank" : "_self"}
            >
                {props.name}
            </NavLink>
        </>
    );
};
