import { createUseStyles } from "react-jss";

export const UserEntityPaymentInfoStyle = createUseStyles((theme: any) => ({
    row: {
        boxSizing: "border-box",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
    },
    column: {
        flexBasis: "100%",
    },

    columnBox: {
        padding: "10px",
        flexBasis: "100%",
    },
    title: {
        flexBasis: "100%",
        paddingTop: theme.paddings.sizes.base,
    },
}));
