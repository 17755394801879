import { AppointmentsAPI } from "../../../../api/appointments";
import { throwErrorToastrAsync } from "../../../../utils";
import {
    setLoadingMasterAppointmentTypeActuationForm,
    setMasterAppointmentTypeActuationForm,
} from "../../../reducers/masterAppointment/appointmentTypeActuation/form";
import {
    setLoadingAppointmentTypeActuationList,
    setMasterAppointmentTypeActuationList,
} from "../../../reducers/masterAppointment/appointmentTypeActuation/list";
import { AppThunk, store } from "../../../store";

const appointmentApi = new AppointmentsAPI();

export const fetchMasterAppointmentTypeActuationList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingAppointmentTypeActuationList(true));
        const response = await appointmentApi.getMasterAppointmentTypeActuationList();
        dispatch(setMasterAppointmentTypeActuationList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingAppointmentTypeActuationList(false));
    }
};
export const patchSortAppointmentTypeActuationsList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingAppointmentTypeActuationList(true));
            await appointmentApi.managementSort(tableName, id, sortType);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchMasterAppointmentTypeActuationList());
        }
    };

export const fetchMasterAppointmentTypeActuation =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterAppointmentTypeActuationForm(true));
            const response = await appointmentApi.getMasterAppointmentTypeActuationForm(id);
            dispatch(setMasterAppointmentTypeActuationForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterAppointmentTypeActuationForm(false));
        }
    };

export const postAndPutMasterAppointmentTypeActuation = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterAppointmentTypeActuationForm;
            let newForm = {
                ...form,
                actuationTypeId: form.actuationTypeId !== 0 ? form.actuationTypeId : null,
                appointmentTypeId: form.appointmentTypeId !== 0 ? form.appointmentTypeId : null,
                merchandiseCategoryGroupId: form.merchandiseCategoryGroupId !== -1 ? form.merchandiseCategoryGroupId : null,
                merchandiseTypeId: form.merchandiseTypeId !== -1 ? form.merchandiseTypeId : null,
            };

            dispatch(setLoadingMasterAppointmentTypeActuationForm(true));
            if (form.id) {
                await appointmentApi.putMasterAppointmentTypeActuationForm(newForm);
            } else {
                await appointmentApi.postMasterAppointmentTypeActuationForm(newForm);
            }
            dispatch(fetchMasterAppointmentTypeActuationList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentTypeActuationForm(false));
            return false;
        }
    };
};
