import { Label } from "@fluentui/react";
import { LabelTimeStyle } from "./labelTimeStyle.jss";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { convertToLocal } from "../../../../utils/dateUtils";
import { DefaultValues } from "../../../../common/enum/DefaultValues";

interface ILabelTimeProps {
    name: string;
    value?: string | null | Date;
    format?: FormatDate;
}

export const LabelTime = (props: ILabelTimeProps) => {
    const style = LabelTimeStyle();

    return (
        <>
            <div className={style.timeLabelContainer}>
                <label className={style.label}>{props.name}</label>
                <Label className={style.data}>{props.value ? convertToLocal(props.value.toString(), props.format) : DefaultValues.EMPTY}</Label>
            </div>
        </>
    );
};
