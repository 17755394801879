import { createUseStyles } from "react-jss";

export const filterStyle = createUseStyles((theme: any) => ({
    filter: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.filter.default,
    },
    section: {
        display: "flex",
        gap: theme.gaps.filter.default,
    },
}));
