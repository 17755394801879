import { Method } from "../../../common/enum";
import { IManagementRequestDatePermission, IManagementRequestDatePermissionForm } from "../../../models/management/requestdatepermission/IRequestdatepermission";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/requestdatepermission`;

export class ManagementRequestDatePermisionApi {
    async getRequestDatePermisionListAsync(): Promise<IManagementRequestDatePermission[]> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IManagementRequestDatePermission[]>();
    }
    async getRequestDatePermisionAsync(id: number): Promise<IManagementRequestDatePermissionForm> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IManagementRequestDatePermissionForm>();
    }

    async updateRequestDatePermisionAsync(representation: any): Promise<boolean> {
        const url = `${baseURL}/${representation?.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(representation).execute<boolean>();
    }
    async postRequestDatePermisionAsync(item: any): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(item).execute<boolean>();
    }

    async removeRequestDatePermisionAsync(id: number): Promise<boolean> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
