import React, { useEffect } from "react";
import { fetchConfigDockMerchandiseListAction } from "../../../../redux/actions/config/dockMerchandise";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ConfigDockMerchandiseListColumns } from "./column/column";
import { sortArray } from "../../../../utils";
import { SkeletonSizes } from "../../../../common/enum/sizes";

export const ConfigDockMerchandiseList = () => {
    const { list, loading, sort } = useAppSelector((state) => state.configDockMerchandiseList);
    const dispatch = useAppDispatch();

    const renderColumns = () => {
        return ConfigDockMerchandiseListColumns();
    };
    
    useEffect(() => {
        list === undefined && dispatch(fetchConfigDockMerchandiseListAction());
    }, [list]);

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={sortArray(list, sort) ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 18rem)"}
                />
            )}
        </div>
    );
};
