import { createUseStyles } from "react-jss";

export const UserProfileEntityInfoFormStyle = createUseStyles((theme: any) => ({
    row: {
        display: "flex",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
    },

    icon: {
        fill: theme.colors.iconSort.enabled,
        width: "16px",
    },

    columnDocument: {
        display: "flex",
        alignItems: "center",
        minWidth: "5rem",
    },
    documentTitle: {
        fontSize: "0.75rem",
    },
    entityName: {
        width: "66%",
    },
    disabledIcon: {
        opacity: "0.6",
        backgroundColor: "transparent",
    },
}));
