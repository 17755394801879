import { createUseStyles } from "react-jss";

export const SiteNavGroupStyles = createUseStyles((theme: any) => ({
    group: {
        paddingTop: theme.paddings.sizes.sm,
        paddingBottom: theme.paddings.sizes.xs,
        cursor: "pointer",
        textDecoration: "none",
        msUserSelect: "none",
        userSelect: "none",
        "& h2": {
            fontSize: theme.fonts.sizes.base,
            fontWeight: theme.fonts.weight.bold,
            textTransform: "uppercase",
            margin: 0,
            width: 229,
            padding: theme.paddings.siteNav.groupTitle,
        },
        "& ul, & li": {
            display: "block",
            margin: 0,
            height: "auto",
            width: 229,
            padding: theme.paddings.siteNav.group,
            listStyle: "none",
        },
    },
    iconArrow: {
        width: "8%",
        paddingRight: "10px",
        paddingTop: "4px",
        color: theme.colors.siteNav.color,
        "& .downArrowViolet": {
            fill: theme.colors.siteNav.color,
        },
    },
    iconArrowUp: {
        width: "8%",
        paddingRight: "10px",
        paddingTop: "4px",
        transform: "rotate(180deg)",
        paddingLeft: "10px",
        "& .downArrowViolet": {
            fill: theme.colors.siteNav.color,
        },
    },
    containerTitle: {
        display: "flex",
    },
}));
