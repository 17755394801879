import { ConfigDockBlockAPI } from "../../../api/config/dockBlock";
import { throwErrorToastrAsync } from "../../../utils";
import { setConfigDockBlockForm, setConfigDockBlockLoadingForm } from "../../reducers/config/dockBlock/form";
import { setConfigDockBlockList, setConfigDockBlockLoadingList } from "../../reducers/config/dockBlock/list";
import { AppThunk, store } from "../../store";

const configDockBlockApi = new ConfigDockBlockAPI();

export const fetchConfigDockBlockListAction = (): AppThunk => async (dispatch) => {
    const { filter } = store.getState().configDockBlockFilter;

    const newFilter = {
        dockBlockId: Number(filter.dockBlockId) !== -1 ? filter.dockBlockId : null,
        dockId: Number(filter.dockId) !== -1 ? filter.dockId : null,
    };
    try {
        dispatch(setConfigDockBlockLoadingList(true));
        const response = await configDockBlockApi.getConfigDockBlockList(newFilter);
        dispatch(setConfigDockBlockList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setConfigDockBlockLoadingList(false));
    }
};

export const fetchConfigDockBlockAction =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setConfigDockBlockLoadingForm(true));
            const response = await configDockBlockApi.getConfigDockBlock(id);

            dispatch(setConfigDockBlockForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setConfigDockBlockLoadingForm(false));
        }
    };

export const postConfigDockBlock = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().configDockBlockForm;
        try {
            dispatch(setConfigDockBlockLoadingForm(true));

            await configDockBlockApi.postConfigDockBlock(form); dispatch(fetchConfigDockBlockListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);   dispatch(setConfigDockBlockLoadingForm(false));
        } 
    };
};

export const removeConfigDockBlock = (): any => {
    return async (dispatch: any) => {
        const { id } = store.getState().configDockBlockForm.form;
        try {
            dispatch(setConfigDockBlockLoadingForm(true));
            await configDockBlockApi.deleteConfigDockBlock(id ?? 0);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchConfigDockBlockListAction());
            dispatch(setConfigDockBlockLoadingForm(false));
        }
    };
};
