import { useTheme } from "react-jss";
import { EditRequestHistoryContainerStyle } from "./containerStyle.jss";
import { TimeLineItem } from "./timeLine/timeLine";
import "./timeLine/style.css";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { EditRequestChangeHistoryController } from "./controller/controller";
import { useEffect } from "react";
import { fetchEditRequestTimeLineHistory } from "../../../../../../redux/actions/request/edit/action/requestHistory";
import { Skeleton } from "../../../../../common/skeleton/skeleton";

export const EditRequestHistoryTimeLineContainer = () => {
    const theme = useTheme();
    const style = EditRequestHistoryContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const { list, loading } = useAppSelector((state) => state.editRequestHistoryRequest);
    const { id } = useAppSelector((state) => state.editRequest.headerRequest);

    useEffect(() => {
        dispatch(fetchEditRequestTimeLineHistory(String(id)));
    }, []);

    return (
        <div className={style.container}>
            {loading ? (
                <Skeleton rows={3} />
            ) : (
                list &&
                list.length > 0 && (
                    <div className="timeline-container">
                        {list.map((item, index: number) => (
                            <TimeLineItem
                                data={item}
                                key={item.id}
                                previousId={index >= 1 ? list[index - 1].id : ""}
                            />
                        ))}
                    </div>
                )
            )}

            <EditRequestChangeHistoryController />
        </div>
    );
};
