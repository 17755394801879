import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { vlcPortImportRequestAction } from "../../../../redux/actions/vlcPort/importRequest/importRequest";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetVlcportImportRequestForm } from "../../../../redux/reducers/vlcPort/importRequest/form";
import { validateXml } from "../../../../utils/xmlUtils";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ImportRequest } from "../container";
import { VlcPortImportRequestForm } from "./form/form";

interface IVlcPortImportRequestFormControllerProps {
    codeComponent: string;
    setCodeComponent: (value: any) => any;
}

export const VlcPortImportRequestFormController: React.FC<IVlcPortImportRequestFormControllerProps> = ({ codeComponent, setCodeComponent }) => {
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.IMPORT_REQUEST" });
    const { isVisible, loading, form } = useAppSelector((state) => state.VlcportImportRequestForm);
    const [document, setDocument] = useState<any>();
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(resetVlcportImportRequestForm());
        setDocument(undefined);
        setCodeComponent("");
    };

    const onSave = () => {
        dispatch(vlcPortImportRequestAction(document, form.xml)).then((response: any) => {
            response && onCancel();
        });
    };

    const width = useMemo(() => {
        return isVisible && codeComponent === ImportRequest.CODE ? "70vw" : "40vw";
    }, [isVisible]);

    const heigth = useMemo(() => {
        return isVisible && codeComponent === ImportRequest.CODE ? "70vh" : "30vh";
    }, [isVisible]);

    const disabledForm = useMemo(() => {
        if (codeComponent === ImportRequest.CODE) {
            return !form.xml || !validateXml(form.xml);
        }
        if (codeComponent === ImportRequest.DOCUMENT) {
            return !document;
        }

        return true;
    }, [document, form]);

    const titlePopUp = useMemo(() => {
        if (codeComponent === ImportRequest.DOCUMENT) {
            return t("IMPORT_DOCUMENT");
        } else if (codeComponent === ImportRequest.CODE) {
            return t("IMPORT_CODE");
        }
    }, [codeComponent, isVisible]);

    return (
        <PopUpForm
            content={
                <VlcPortImportRequestForm
                    setDocument={setDocument}
                    codeComponent={codeComponent}
                    document={document}
                />
            }
            title={titlePopUp ?? ""}
            isLoading={loading}
            isVisible={isVisible === PopUpCodes.CREATE || isVisible === PopUpCodes.UPDATE}
            onCancel={onCancel}
            onSave={onSave}
            height={heigth}
            width={width}
            buttonSaveTitle={t("ACCEPT")}
            isDisabledButton={disabledForm}
        />
    );
};
