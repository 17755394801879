import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomerImportColumnsStyle } from "./columnStyle.jss";
import { IUserErrorDetail } from "../../../../../models/resources/userImport/IUserImport";

export const CustomerImportListColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "USER_IMPORT.LIST" });
    const theme = useTheme();
    const styles = CustomerImportColumnsStyle({ theme });

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IUserErrorDetail>("lineNumber"),
            minWidth: 50,
            maxWidth: 100,
            name: t("LINE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUserErrorDetail>("lineNumber")}
                    title={t("LINE")}
                    styles={styles}
                />
            ),
            onRender: (item: IUserErrorDetail) => <span>{item.lineNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IUserErrorDetail>("details"),
            minWidth: 250,
            maxWidth: 450,
            name: t("DETAILS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IUserErrorDetail>("details")}
                    title={t("DETAILS")}
                    styles={styles}
                />
            ),
            onRender: (item: IUserErrorDetail) => <span>{item.details}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
