import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { EditRequestHistoryChangeForm } from "./form/form";
import { resetEditRequestHistoryChangeState } from "../../../../../../../redux/reducers/request/edit/history/form";

export const EditRequestChangeHistoryController = () => {
    const { showPopUp, loading } = useAppSelector((state) => state.editRequestHistoryChangeForm);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const onCancel = () => {
        dispatch(resetEditRequestHistoryChangeState());
    };

    return (
        <PopUpForm
            onSave={() => {}}
            content={<EditRequestHistoryChangeForm />}
            title={t("TITLES.HISTORY")}
            isVisible={showPopUp === PopUpCodes.CREATE}
            onCancel={onCancel}
            onlyHidden={true}
            height={"calc(100vh - 12rem)"}
            isLoading={loading}
            width={"90vw"}
        />
    );
};
