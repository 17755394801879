import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterActuationTypeForm, setMasterActuationTypeForm } from "../../../../reducers/resources/master/actuationType/form";
import { setLoadingMasterActuationTypeList, setMasterActuationTypeList } from "../../../../reducers/resources/master/actuationType/list";
import { AppThunk, store } from "../../../../store";


const catalogApi = new CatalogAPI();

export const fetchMasterActuationTypeList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterActuationTypeList(true));
        const response = await catalogApi.getActuationTypeList();
        dispatch(setMasterActuationTypeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterActuationTypeList(false));
    }
};

export const fetchMasterActuationType =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterActuationTypeForm(true));
            const response = await catalogApi.getActuationType(id);
            dispatch(setMasterActuationTypeForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterActuationTypeForm(false));
        }
    };

export const postAndPutMasterActuationType = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterActuationTypeForm;
            dispatch(setLoadingMasterActuationTypeForm(true));
            if (form.id) {
                await catalogApi.putActuationType(form);
            } else {
                await catalogApi.postActuationType(form);
            }
            dispatch(fetchMasterActuationTypeList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);dispatch(setLoadingMasterActuationTypeForm(false));
            return false;
        }
    };
};

export const patchSortActuationTypesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterActuationTypeList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterActuationTypeList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterActuationTypeList(false));
        }
    };
