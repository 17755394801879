import { createUseStyles } from "react-jss";

export const LegalNoticeContainerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.md,
        padding: 24,
        color: "black",

        overflow: "hidden",
        height: "100%",
    },
    centerText: {
        display: "flex",
        justifyContent: "center",
    },
    section: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
    },
    list: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
    table: {
        width: "50%",
        borderCollapse: "collapse",
        margin: "auto",
        "& th, td": {
            textAlign: "start",
            border: "1px solid black",
        },
    },
}));
