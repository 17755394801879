import React, { useEffect } from "react";
import { fetchCertificatesByOrganism } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { NumericMultiSelector } from "../../../../common/selectors/multiSelectors/numericMultiSelector/numericMultiSelector";
import { IBaseMultipleNumberSelectorProps } from "../IBaseMultipleSelectorProps";
import { MultipleNumberSelectorFilter } from "../multipleSelectorFilter/multipleNumberSelectorFilter";

export const MultipleCertificateSelector: React.FC<IBaseMultipleNumberSelectorProps> = ({
    autocomplete,
    onChange,
    propertyName,
    title,
    selectedKeys,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.certificatesByOrganism);
    const { organismId } = useAppSelector((state) => state.userData.profile);

    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchCertificatesByOrganism(organismId ?? 0));
    }, [dispatch, data]);

    return autocomplete ? (
        <MultipleNumberSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapIDescriptionToDropDownOptions(data)}
            label={title}
            propertyName={propertyName}
        />
    ) : (
        <NumericMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapIDescriptionToDropDownOptions(data)}
            disabled={loading}
            title={title}
            name={propertyName}
        />
    );
};
