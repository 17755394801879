import { AppointmentsAPI } from "../../../../api/appointments";
import { IAppointmentDatePermissionForm } from "../../../../models/masterAppointment/IAppointmentDatePermission";
import { formatHour, throwErrorToastrAsync } from "../../../../utils";
import { setGlobalLoading } from "../../../reducers/common/globalLoading";
import {
    setLoadingMasterAppointmentDatePermissionForm,
    setMasterAppointmentDatePermissionForm,
} from "../../../reducers/masterAppointment/appointmentDatePermission/form";
import {
    setLoadingMasterAppointmentDatePermissionList,
    setMasterAppointmentDatePermissionList,
} from "../../../reducers/masterAppointment/appointmentDatePermission/list";

import { AppThunk, store } from "../../../store";
import { mapWorkDays } from "../../management/requestdatepermision/requestdatepermision";

const appointmentApi = new AppointmentsAPI();

export const fetchMasterAppointmentDatePermissionList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterAppointmentDatePermissionList(true));
        const response = await appointmentApi.getMasterAppointmentDatePermissionList();
        dispatch(setMasterAppointmentDatePermissionList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterAppointmentDatePermissionList(false));
    }
};
export const patchSortAppointmentDatePermissionsList =
    (tableName: string, id: number, sortDatePermission: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterAppointmentDatePermissionList(true));
            await appointmentApi.managementSort(tableName, id, sortDatePermission);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchMasterAppointmentDatePermissionList());
        }
    };

export const fetchMasterAppointmentDatePermission =
    (id: number): any =>
    async (dispatch: any) => {
        try {
            dispatch(setGlobalLoading(true));
            dispatch(setLoadingMasterAppointmentDatePermissionForm(true));
            const response = await appointmentApi.getMasterAppointmentDatePermissionForm(id);
            let item: IAppointmentDatePermissionForm = {
                ...response,
                weekDays: mapWorkDays(response),
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            };
            dispatch(setMasterAppointmentDatePermissionForm(item));
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingMasterAppointmentDatePermissionForm(false));
            dispatch(setGlobalLoading(false));
            return true;
        }
    };

export const postAndPutMasterAppointmentDatePermission = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterAppointmentDatePermissionForm;
            dispatch(setLoadingMasterAppointmentDatePermissionForm(true));

            const newForm = {
                ...form,

                startTime: formatHour(form.startTime?.toString()),
                endTime: formatHour(form.endTime?.toString()),
                transportUnitId: form.transportUnitId ? form.transportUnitId : null,
                transportUnitSizeId: form.transportUnitSizeId ? form.transportUnitSizeId : undefined,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                weekDays: null,
            };
            for (let item of form.weekDays) {
                if (item === -1) {
                    newForm.monday = true;
                    newForm.tuesday = true;
                    newForm.wednesday = true;
                    newForm.thursday = true;
                    newForm.friday = true;
                    newForm.saturday = true;
                    newForm.sunday = true;
                } else if (item === 1) {
                    newForm.monday = true;
                } else if (item === 2) {
                    newForm.tuesday = true;
                } else if (item === 3) {
                    newForm.wednesday = true;
                } else if (item === 4) {
                    newForm.thursday = true;
                } else if (item === 5) {
                    newForm.friday = true;
                } else if (item === 6) {
                    newForm.saturday = true;
                } else if (item === 7) {
                    newForm.sunday = true;
                }
            }
            if (form.id) {
                await appointmentApi.putMasterAppointmentDatePermissionForm(newForm);
            } else {
                await appointmentApi.postMasterAppointmentDatePermissionForm(newForm);
            }
            dispatch(fetchMasterAppointmentDatePermissionList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentDatePermissionForm(false));
            return false;
        }
    };
};
export const deleteMasterAppointmentDatePermission = (): AppThunk => async (dispatch) => {
    try {
        const { id } = store.getState().masterAppointmentDatePermissionForm.form;

        dispatch(setLoadingMasterAppointmentDatePermissionForm(true));

        await appointmentApi.removeMasterAppointmentDatePermissionForm(id ?? 0);
        dispatch(fetchMasterAppointmentDatePermissionList());
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterAppointmentDatePermissionForm(false));
    }
};
