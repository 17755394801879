import { useTheme } from "react-jss";
import { SeparatorStyle } from "./separatorStyle.jss";

interface ISeparatorProps {
    horizontal?: boolean;
    size?: string;
}

export const Separator = (props: ISeparatorProps) => {
    const theme = useTheme();

    const horizontal = props.horizontal ?? false;
    const size = props.size;

    const styles = SeparatorStyle({ theme, size });

    return <div className={horizontal ? styles.horizontalSeparator : styles.separator}></div>;
};
