import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { RequestFilterPopUpStyle } from "../filterStyle.jss";
import { IRequestFilter } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { BooleaMultiSelector } from "../../../../../../common/selectors";
import { MultipleAppointmentTimeZoneSelector } from "../../../../../common/selectors/appointmentTimeZones/multipleAppointmentTimeZones";
import { MultipleDockSelector } from "../../../../../common/selectors/docks/multipledocks";
import { MultipleDocumentTypesSelector } from "../../../../../common/selectors/documentTypes/multipleDocumentTypes";
import { MultipleRequestStateSelector } from "../../../../../../common/selectors/controllerSelectors/requestStates/multipleRequestState";
import { MultipleTurnSelector } from "../../../../../common/selectors/turn/multipleTurn";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    schema: any;
}

export const RequestInspectionFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, onNullableBooleanChange, schema }) => {
    const theme = useTheme();
    const styles = RequestFilterPopUpStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });

    return (
        <div className={styles.section}>
            {schema.turnIdVisible && (
                <MultipleTurnSelector
                    selectedKeys={filter.turnId}
                    propertyName={compileNameOfProperty<IRequestFilter>("turnId")}
                    title={t("TURN")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.stateIdVisible && (
                <MultipleRequestStateSelector
                    selectedKeys={filter.stateId}
                    propertyName={compileNameOfProperty<IRequestFilter>("stateId")}
                    title={t("REQUEST_STATE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}

            {schema.dockIdVisible && (
                <MultipleDockSelector
                    selectedKeys={filter.dockId}
                    propertyName={compileNameOfProperty<IRequestFilter>("dockId")}
                    title={t("DOCK")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.documentTypeIdVisible && (
                <MultipleDocumentTypesSelector
                    selectedKeys={filter.documentTypeId}
                    propertyName={compileNameOfProperty<IRequestFilter>("documentTypeId")}
                    title={t("DOCUMENT_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.summaryActiveVisible && (
                <BooleaMultiSelector
                    selectedKey={filter.summaryActive}
                    propertyName={compileNameOfProperty<IRequestFilter>("summaryActive")}
                    title={t("SUMMARY_ACTIVE")}
                    onChange={onNullableBooleanChange}
                />
            )}
            {schema.parkingAuthorizationVisible && (
                <BooleaMultiSelector
                    selectedKey={filter.parkingAuthorization}
                    propertyName={compileNameOfProperty<IRequestFilter>("parkingAuthorization")}
                    title={t("PARKINGAUTHORIZATION")}
                    onChange={onNullableBooleanChange}
                />
            )}
            {schema.appVisible && (
                <BooleaMultiSelector
                    selectedKey={filter.app}
                    propertyName={compileNameOfProperty<IRequestFilter>("app")}
                    title={t("APP")}
                    onChange={onNullableBooleanChange}
                />
            )}
            {schema.appointmentTimeZoneVisible && (
                <MultipleAppointmentTimeZoneSelector
                    onChange={onMultipleNumberKeysChange}
                    selectedKeys={filter.appointmentTimeZoneId}
                    autocomplete={false}
                    propertyName={compileNameOfProperty<IRequestFilter>("appointmentTimeZoneId")}
                    title={t("APPOINTMENT_TIME_ZONE")}
                />
            )}
            {schema.appointmentAssigned && (
                <BooleaMultiSelector
                    selectedKey={filter.appointmentAssigned}
                    propertyName={compileNameOfProperty<IRequestFilter>("appointmentAssigned")}
                    title={t("APPOINMENT")}
                    onChange={onNullableBooleanChange}
                />
            )}
        </div>
    );
};
