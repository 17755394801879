import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchActuationTypeRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterActuationTypeForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { postAndPutMasterActuationType } from "../../../../../../redux/actions/resources/master/actuationType/actuationType";
import { resetMasterActuationTypeForm } from "../../../../../../redux/reducers/resources/master/actuationType/form";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterActuationTypeControllerProps {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterActuationTypeFormController: React.FC<IMasterActuationTypeControllerProps> = ({ widthPopUp, heigthPopUp }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useDispatch();

    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { loading, form } = useAppSelector((state) => state.masterActuationTypeForm);
    const { data } = useAppSelector((state) => state.catalog.actuationTypesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postAndPutMasterActuationType()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };
    const onDimissed = () => {
        dispatch(resetMasterActuationTypeForm());
    };
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.ACTUATION_TYPE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.ACTUATION_TYPE");
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE, undefined, ["sortApp"]);
    }, [form, showPopUp]);

    const showNotData = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !data.length;
    }, [data, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchActuationTypeRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterActuationTypeForm
                    showNotData={showNotData}
                    selectorData={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            onDimissed={onDimissed}
            isDisabledButton={isDisabledButton}
            onlyHidden={showNotData}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
