import React from "react";
import { store } from "../../../redux/store";
import { UserProfileContainer } from "./container";
import { resetUserProfile } from "../../../redux/reducers/user/profile/userProfile";

export class UserProfileController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetUserProfile());
    }

    render(): React.ReactNode {
        return <UserProfileContainer />;
    }
}
