import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { IUserNotifications } from "../../../../models/user/notifications/IUserNotifications";
import { fetchProfileUserNotifications } from "../../../../redux/actions/user/profile/userProfile";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { generateRandomKeys } from "../../../../utils/keys";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ProfileUserNotificationContainerStyle } from "./containerStyle.jss";
import { ProfileUserNotificationItem } from "./item/item";
import { ProfileUserEmailItems } from "./item/listEmails/list";

export const ProfileUserNotificationsContainer = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ProfileUserNotificationContainerStyle({ theme });
    const { list } = useAppSelector((store) => store.userProfile.profile.userNotifications);
    const loading = useAppSelector((store) => store.userProfile.loadingNotifications);

    useEffect(() => {
        list === undefined && dispatch(fetchProfileUserNotifications());
    }, [list]);

    return (
        <div className={style.notificationContainer}>
            <div className={style.notificationContent}>
                {loading ? (
                    <Skeleton rows={3} />
                ) : (
                    list?.map((item: IUserNotifications, index) => {
                        return (
                            <div className={style.row}>
                                <div key={`${ProfileUserNotificationsContainer.name}-${generateRandomKeys()} `}>
                                    <ProfileUserNotificationItem
                                        key={generateRandomKeys()}
                                        notification={item}
                                        notificationIndex={index}
                                    />
                                </div>
                                {item.notificationOtherEmails && (
                                    <ProfileUserEmailItems
                                        notification={item}
                                        notificationIndex={index}
                                    />
                                )}
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
};
