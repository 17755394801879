import { WebStorageStateStore } from "oidc-client";

export const getClientSettings = () => {
    return {
        authority: process.env.REACT_APP_AUTHORITY,
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/signing-callback`,
        silent_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/silent-renew.html`,
        // tslint:disable-next-line:object-literal-sort-keys
        post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        response_type: "code",
        scope: process.env.REACT_APP_SCOPE,
        automaticSilentRenew: true,
        monitorSession: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
};
