import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IMasterAppointmentTypeActuation } from "../../../../models/masterAppointment/IAppointmentTypeActuation";

export interface IMasterAppointmentTypeActuationFormState {
    form: IMasterAppointmentTypeActuation;
    loading: boolean;
}

const initialState: IMasterAppointmentTypeActuationFormState = {
    form: {
        actuationTypeId: 0,
        appointmentTypeId: 0,
        merchandiseCategoryGroupId: -1,
        merchandiseTypeId: -1,
    },
    loading: false,
};

export const masterAppointmentTypeActuationForm = createSlice({
    name: "masterAppointmentTypeActuationForm",
    initialState,
    reducers: {
        resetMasterAppointmentTypeActuationForm: () => initialState,
        setLoadingMasterAppointmentTypeActuationForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentTypeActuationForm: (state, action: PayloadAction<IMasterAppointmentTypeActuation>) => {
            state.form = {
                ...action.payload,
                merchandiseCategoryGroupId: action.payload.merchandiseCategoryGroupId ? action.payload.merchandiseCategoryGroupId : -1,
                merchandiseTypeId : action.payload.merchandiseTypeId ? action.payload.merchandiseTypeId : -1
            };
        },
        updateMasterAppointmentTypeActuationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterAppointmentTypeActuationForm,
    setLoadingMasterAppointmentTypeActuationForm,
    setMasterAppointmentTypeActuationForm,
    updateMasterAppointmentTypeActuationProperty,
} = masterAppointmentTypeActuationForm.actions;

export default masterAppointmentTypeActuationForm.reducer;
