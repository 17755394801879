import { createUseStyles } from "react-jss";

export const UserProfileEntityUsersSendSignupEmailsItemStyle = createUseStyles({
    emailItem: {
        display: "flex",
        justifyContent: "space-between",
        "& i": {
            paddingTop: 15,
        },
    },
    emailContent: {
        display: "flex",
        width: "calc(100% - 50px)",
        "& i": {
            paddingTop: 15,
        },
    },
    addEmail: {
        display: "flex",
        width: 50,
        justifyContent: "flex-end",
        "& i": {
            paddingTop: 15,
            paddingRight: 8,
        },
    },
   
});
