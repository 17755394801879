import { UserApi } from "../../../api";
import { routeUrls } from "../../../common/constants";
import { resetUserAuth, setUserData } from "../../reducers/userData/userData";
import { AppThunk } from "../../store";

const userApi = new UserApi();

export const resetUserAuthAction = (): AppThunk => (dispatch) => {
    dispatch(resetUserAuth());
};

export const fetchUserData = (): AppThunk => async (dispatch) => {
    try {
        const response = await userApi.getUserData();
        dispatch(setUserData(response));
    } catch (error) {
        window.location.replace(routeUrls.FORBIDDEN);
    }
};
