import { createSlice } from "@reduxjs/toolkit";
import { emptyUserRoleViewData, IUserRoleView } from "../../../../models/requests/IUserRoleView";

interface ViewRoleState {
    viewPermission: IUserRoleView[];
    isViewLoaded: boolean;
}

const initialState: ViewRoleState = {
    viewPermission: emptyUserRoleViewData(),
    isViewLoaded: false,
};

export const userRoleViewPermissionSlice = createSlice({
    name: "userRoleViewPermission",
    initialState,
    reducers: {
        setLoadingViewPermission: (state, action) => {
            state.isViewLoaded = action.payload;
        },
        setViewPermission: (state, action) => {
            state.viewPermission = action.payload;
        },
        setViewPermissionReceived: (state, action) => {
            state.viewPermission = action.payload;
        },
    },
});

export const { setViewPermission, setLoadingViewPermission, setViewPermissionReceived } = userRoleViewPermissionSlice.actions;

export default userRoleViewPermissionSlice.reducer;
