import { createUseStyles } from "react-jss";

export const ManagementUserFilterStyle = createUseStyles((theme: any) => ({
    filterContainer: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: theme.gaps.filter.default,
    },

    filterSection: {
        display: "flex",
        gap: theme.gaps.filter.default,
    },

    filterItem: {
        width: 205,
    },
}));
