import React from "react";
import { BatchItemStyle } from "./batchStyle.jss";
import { IBatch } from "../../../../../../../models/requests/IEditRequest";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { removeBatchEditRequest, removeNewBatchForm } from "../../../../../../../redux/reducers/request/edit/editRequest";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";

interface IBatchProps {
    batch: IBatch;
    index: number;
    isNew: boolean;
    allowRemove?: boolean;
}

export const Batch: React.FC<IBatchProps> = ({ batch, index, isNew, allowRemove }) => {
    const style = BatchItemStyle();
    const dispatch = useAppDispatch();

    const removeBatch = () => {
        if (isNew) {
            dispatch(removeNewBatchForm(index));
        } else {
            if (batch.id) {
                dispatch(removeBatchEditRequest({ id: batch.id }));
            } else {
                dispatch(removeBatchEditRequest({ index }));
            }
        }
    };

    return (
        <>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.text}>{batch.code}</div>
                    {allowRemove && (
                        <CustomIcons
                            iconName="Cancel"
                            onClick={removeBatch}
                            className={style.button}
                        ></CustomIcons>
                    )}
                </div>
            </div>
        </>
    );
};
