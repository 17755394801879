import { createUseStyles } from "react-jss";

export const UserProfileEntityManageUsersListItemStyle = createUseStyles((theme: any) => ({
    userItemContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    button: {
        color: theme.colors.icon.edit,
        cursor: "pointer",
    },
  
}));
