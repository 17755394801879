import { DetailsRow, IDetailsFooterProps } from "@fluentui/react";
import { useEffect } from "react";
import { useTheme } from "react-jss";
import { fetchStatiticsIncidenceOrganismFilterAction } from "../../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsIncidenceOrganismListColumn } from "./column/column";
import { StatisticsIncidenceOrganismListColumnFooter } from "./column/footer";
import { StatisticsIncidenceOrganismListStyle } from "./listStyle.jss";
import { SkeletonSizes } from "../../../../common/enum/sizes";

export const StatisticsIncidenceOrganismList = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = StatisticsIncidenceOrganismListStyle({ theme });
    const { incidencesByOrganismStatistic: organismIncidenceStatistics, loading } = useAppSelector(
        (state) => state.statisticsOrganismIncidenceList.data,
    );

    useEffect(() => {
        !organismIncidenceStatistics.length && !loading && dispatch(fetchStatiticsIncidenceOrganismFilterAction());
    }, [dispatch]);

    const onRenderColumns = () => {
        return StatisticsIncidenceOrganismListColumn();
    };

    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };
        const footerColumns = StatisticsIncidenceOrganismListColumnFooter(dataFooter);

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={footerColumns}
                item={{}}
                itemIndex={5}
                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                selection={detailsFooterProps.selection}
                styles={style}
            />
        );
    };

    return (
        <>
            {!loading ? (
                <>
                    <CustomDetailsList
                        data={organismIncidenceStatistics}
                        renderColumns={onRenderColumns}
                        height={heights.statistics.incidence}
                        customFooter={_onRenderDetailsFooter}
                    />
                </>
            ) : (
                <>
                                    <Skeleton rows={SkeletonSizes.DEFAULT} />

                </>
            )}
        </>
    );
};
