import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../models";
import { IManagementRepresentationFilter } from "../../../../models/management/representation/IManagementRepresentationFilter";
import { fetchManagementRepresentationListAction } from "../../../../redux/actions/management/representation/representation";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetFilter, updateFilterProperty } from "../../../../redux/reducers/management/representation/filter";
import { compileNameOfProperty } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { BooleaMultiSelector } from "../../../common/selectors";
import { ManagementRepresentationFilterStyle } from "./filterStyle.jss";
import { CustomerFilterSelector } from "../../../common/selectors/controllerSelectors/customerSelector/customerFilterSelector";

export const ManagementRepresentationFilter = () => {
    const theme = useTheme();
    const styles = ManagementRepresentationFilterStyle({ theme });
    const { filter } = useAppSelector((state) => state.managementRepresentationFilter);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.REPRESENTATION.FILTER" });
    const dispatch = useAppDispatch();

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            const payload: IGenericPayload = {
                name,
                value: event.key,
            };
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onBooleanSelectorChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onApply = useCallback(() => {
        dispatch(fetchManagementRepresentationListAction());
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(resetFilter());
        dispatch(fetchManagementRepresentationListAction());
    }, [dispatch]);

    const FiltersItems = () => {
        return (
            <div className={styles.filterContainer}>
                <div className={styles.filterItem}>
                    <CustomerFilterSelector
                        onChange={onSelectorChange}
                        selectedKey={filter.userId}
                        title={t("USERNAME")}
                        name={compileNameOfProperty<IManagementRepresentationFilter>("userId")}
                        filteredRegisteredCustomer={true}
                    />
                </div>
                <div className={styles.filterItem}>
                    <CustomerFilterSelector
                        onChange={onSelectorChange}
                        selectedKey={filter.representativeUserId}
                        title={t("REPRESENTATIVENAME")}
                        name={compileNameOfProperty<IManagementRepresentationFilter>("representativeUserId")}
                        filteredRegisteredCustomer={true}
                    />
                </div>
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.validated}
                        propertyName={compileNameOfProperty<IManagementRepresentationFilter>("validated")}
                        title={t("VALID")}
                        onChange={onBooleanSelectorChange}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <FilterHeader
                onApply={onApply}
                content={FiltersItems()}
                onReset={onReset}
            />
        </>
    );
};
