import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { useTheme } from "react-jss";
import { useEffect, useMemo, useState } from "react";
import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import { mediaQueries } from "../../../common/constants";
import { AppointmentHistoricList } from "./list/list";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { AppointmentHistoricFilter } from "./filter/filter";
import {
    fetchAppointmentHistoricIncidenceListAction,
    fetchAppointmentHistoricAppointmentListAction,
    fetchAppointmentHistoricInspectorListAction,
} from "../../../redux/actions/appointments/historic/historic";
import { AppointmentHistoryController } from "./controller/controller";

export enum AppointmentHistoric {
    INCIDENCE = "1",
    INSPECTOR = "2",
    APPOINTMENT = "3",
}

export const AppointmentHistoricContainer = () => {
    const { t } = useTranslation("common");
    const theme: any = useTheme();
    const [selectedKey, setSelectedKey] = useState<string>(AppointmentHistoric.INCIDENCE);
    const dispatch = useAppDispatch();

    const appointmentHistoricIncidence = useAppSelector((state) => state.appointmentHistoricIncidence);
    const appointmentHistoricAppointment = useAppSelector((state) => state.appointmentHistoricAppointment);
    const appointmentHistoricInspector = useAppSelector((state) => state.appointmentHistoricInspector);

    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    useEffect(() => {
        selectedKey === AppointmentHistoric.INCIDENCE && dispatch(fetchAppointmentHistoricIncidenceListAction());
        selectedKey === AppointmentHistoric.APPOINTMENT && dispatch(fetchAppointmentHistoricAppointmentListAction());
        selectedKey === AppointmentHistoric.INSPECTOR && dispatch(fetchAppointmentHistoricInspectorListAction());
    }, [selectedKey]);

    return (
        <>
            <NavHeaderComponent title={t("APPOINTMENT_HISTORIC.TITLE")} />
            <AppointmentHistoricFilter selectedPivot={selectedKey} />
            <Pivot
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("APPOINTMENT_HISTORIC.INCIDENCE")}
                    itemKey={AppointmentHistoric.INCIDENCE}
                >
                    {
                        <AppointmentHistoricList
                            list={appointmentHistoricIncidence.list}
                            loading={appointmentHistoricIncidence.loading}
                            selectedKey={AppointmentHistoric.INCIDENCE}
                        />
                    }
                </PivotItem>

                <PivotItem
                    headerText={t("APPOINTMENT_HISTORIC.INSPECTOR")}
                    itemKey={AppointmentHistoric.INSPECTOR}
                >
                    {
                        <AppointmentHistoricList
                            list={appointmentHistoricInspector.list}
                            loading={appointmentHistoricInspector.loading}
                            selectedKey={AppointmentHistoric.INSPECTOR}
                        />
                    }
                </PivotItem>
                <PivotItem
                    headerText={t("APPOINTMENT_HISTORIC.APPOINMENT")}
                    itemKey={AppointmentHistoric.APPOINTMENT}
                >
                    {
                        <AppointmentHistoricList
                            list={appointmentHistoricAppointment.list}
                            loading={appointmentHistoricAppointment.loading}
                            selectedKey={AppointmentHistoric.APPOINTMENT}
                        />
                    }
                </PivotItem>
            </Pivot>
            <AppointmentHistoryController />
        </>
    );
};
