// IMonthlySummaryState

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IMonthlySummaryState, IMonthlySummaryMonthlySummaryItem } from "../../../../models/statistics/IMonthlySummary";
import { sortArray } from "../../../../utils";

interface IInitialState {
    data: IMonthlySummaryState;
}
const initialSateData = (): IMonthlySummaryState => {
    return {
        loading: false,
        sort: emptySortState(),
        monthlySummaryStatistic: [],
        totalAverageIncidencesTime: "",
        totalIncidences: 0,
        totalIncidencesTime: "",
        totalInspectionAverageTime: "",
        totalInspections: 0,
        totalInspectionTime: "",
        totalNetTime: "",
        totalStayAverageTime: "",
        totalStayTime: "",
        monthlyAverageIncidencesTime:"", 
        monthlyIncidences:0,
        monthlyIncidencesTime:"",
        monthlyInspectionAverageTime:"",
        monthlyInspections:0,
        monthlyInspectionTime:"",
        monthlyNetTime:"", 
        monthlyStayAverageTime:"", 
        monthlyStayTime:""
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsOrganismsIncidenceListSlice = createSlice({
    name: "statiticsOrganismsIncidenceListSlice",
    initialState,
    reducers: {
        resetStatisticsMonthlySummary: () => initialState,
        setList: (state, action: PayloadAction<IMonthlySummaryMonthlySummaryItem[]>) => {
            state.data.monthlySummaryStatistic = action.payload;
        },

        setStatisticsMonthlySummaryLoading: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
        setStatisticsMonthlySummaryData: (state, action: PayloadAction<IMonthlySummaryState>) => {
            state.data = action.payload;
            state.data.sort = {
                column: "",
                orderColumn: "",
            };
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.data.sort = action.payload;
            state.data.monthlySummaryStatistic = sortArray(state.data.monthlySummaryStatistic, state.data.sort);
        },
    },
});

export const { resetStatisticsMonthlySummary, setList, setStatisticsMonthlySummaryLoading, setStatisticsMonthlySummaryData, updateSortColumn } =
    statiticsOrganismsIncidenceListSlice.actions;

export default statiticsOrganismsIncidenceListSlice.reducer;
