import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentHistoricList } from "../../../../../models/appointment/IAppointmentHistoric";

interface IAppointmentHistoricInicenceListState {
    list?: IAppointmentHistoricList[];
    loading: boolean;
}

const initialState: IAppointmentHistoricInicenceListState = {
    loading: false,
};

export const appointmentHistoricIncidenceListReducer = createSlice({
    name: "appointmentHistoricIncidenceListReducer",
    initialState,
    reducers: {
        resetAppointmenttHistoricIncidenceList: () => initialState,
        setAppointmentHistoricIncidenceList: (state, action: PayloadAction<any[]>) => {
            state.list = action.payload;
        },
        setApointmentHistoricIncidenceLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const {
    resetAppointmenttHistoricIncidenceList,
    setAppointmentHistoricIncidenceList,
    setApointmentHistoricIncidenceLoading,
} = appointmentHistoricIncidenceListReducer.actions;

export default appointmentHistoricIncidenceListReducer.reducer;
