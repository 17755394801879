import { Callout, DirectionalHint, Icon } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useState } from "react";
import { useTheme } from "react-jss";
import { InfoCalloutStyle } from "./infoCalloutStyle.jss";

interface IInfoCalloutProps {
    message?: string;
}

export const InfoCallout = (props: IInfoCalloutProps) => {
    const theme = useTheme();
    const styles = InfoCalloutStyle({ theme });

    const iconId = useId("callout-iconButton");

    const [isCalloutVisible, setCalloutVisibility] = useState<boolean>(false);

    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <Icon
                    id={iconId}
                    onClick={() => setCalloutVisibility(!isCalloutVisible)}
                    iconName="InfoSolid"
                />
            </div>
            {isCalloutVisible && (
                <Callout
                    role="dialog"
                    target={`#${iconId}`}
                    isBeakVisible={true}
                    onDismiss={() => setCalloutVisibility(false)}
                    setInitialFocus
                    calloutMaxWidth={250}
                    calloutMaxHeight={200}
                    hideOverflow={false}
                    gapSpace={16}
                    directionalHint={DirectionalHint.bottomRightEdge}
                >
                    <div className={styles.message}>{props.message}</div>
                </Callout>
            )}
        </div>
    );
};
