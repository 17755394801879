import { Resolver } from "react-hook-form";
import { isValidPhoneNumber } from "../../../../common/validations/validationsForm";
import { IAdminData, IEntityData, IPaymentData } from "../../../../models";
import { compileNameOfProperty } from "../../../../utils";

const validationRequired = "FORM.VALIDATION.REQUIRE";

export const entityResolver: Resolver<IEntityData> = async (values: IEntityData) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (!value) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: validationRequired,
                },
            };
        }
    });
    return { values, errors };
};

export const paymentResolver: Resolver<IPaymentData> = async (values: IPaymentData) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (!value) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: validationRequired,
                },
            };
        }
    });
    return { values, errors };
};

export const adminResolver: Resolver<IAdminData> = async (values: IAdminData) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        switch (name) {
            case compileNameOfProperty<IAdminData>("phone"):
                if (!isValidPhoneNumber(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.PHONE",
                        },
                    };
                }
                break;
            case compileNameOfProperty<IAdminData>("mobilePhone"):
                if (!isValidPhoneNumber(value) && value !== "") {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.PHONE",
                        },
                    };
                }
                break;
            default:
                if (!value) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: validationRequired,
                        },
                    };
                }
                break;
        }
    });
    return { values, errors };
};
