import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pageSizes } from "../../../../common/constants";
import { IInvoicingUninvoiced, IInvoicingUninvoicedData } from "../../../../models";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { getFirstItemNumber, getLastItemNumber, getTotalPages } from "../../../../utils";

interface IInvoicingUninvoicedListState {
    list?: IInvoicingUninvoiced[];
    sort: ISortState;
    pagination: IPaginationState,
    totalAmount: number;
    loading?: boolean;
}

const initialState: IInvoicingUninvoicedListState = {
    
    sort: emptySortState(),
    pagination: emptyPaginationState(pageSizes.INVOICING_UNINVOICED),
    totalAmount: 0,
    loading: false,
};

export const invoicingUninvoicedListSlice = createSlice({
    name: "invoicingUninvoicedList",
    initialState,
    reducers: {
        resetInvoicingUninvoicedList: () => initialState,
        setList: (state, action: PayloadAction<IInvoicingUninvoicedData>) => {
            state.list = action.payload.uninvoicedConcepts;
            state.pagination.totalItems = action.payload.uninvoicedConcepts.length;
            state.pagination.totalPages = getTotalPages(state.pagination);
            state.totalAmount = action.payload.totalAmount;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetInvoicingUninvoicedList, setList, setPage, updateSortColumn, setLoading } = invoicingUninvoicedListSlice.actions;

export default invoicingUninvoicedListSlice.reducer;
