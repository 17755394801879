import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../models";
import { IControlExpedientFilter } from "../../../../models/control/expedient/IExpedient";
import { DaySelector } from "../../../../models/requests/IDaySelector";
import { fetchControlExpedientsAction } from "../../../../redux/actions/control/expedient/expedient";
import { useAppSelector } from "../../../../redux/hooks";
import { resetcontrolExpedientFilter, updateFilterProperty } from "../../../../redux/reducers/control/expedients/filter";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { CustomDatePicker } from "../../../common/customDatePicker/customDatePicker";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { TextInput } from "../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../common/selectors";
import { CustomDocumentSelector } from "./documentSelector/documentSelector";
import { ControlExpedientsFilterStyle } from "./filtersStyle.jss";
import { CustomInspectorSelector } from "./inspectorSelector/inspectorSelector";

export const ControlExpedientsFilter = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.FILTER" });

    const { filter } = useAppSelector((state) => state.controlExpedientsFilter);
    const { organismId } = useAppSelector((state) => state.userData.profile);
    const theme = useTheme();
    const style = ControlExpedientsFilterStyle({ theme });

    const updateFilter = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateFilterProperty(payload));
    };
    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            updateFilter(name, event.key);
        },
        [dispatch],
    );

    const onBooleanSelectorChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onApply = () => {
        dispatch(fetchControlExpedientsAction());
    };
    const onReset = () => {
        dispatch(resetcontrolExpedientFilter());
        onApply();
    };

    const filterContent = () => {
        return (
            <div className={style.container}>
                <CustomDatePicker
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    maxDaySelected={DaySelector.MAX_YEAR_SELECTED}
                    minDaySelected={DaySelector.MIN_DAYS_SELECTED}
                    setFilterProperty={updateFilter}
                />
                <div className={style.verticalWhiteSeparator}></div>
                <div className={style.filter}>
                    <div className={style.row}>
                        <TextInput
                            value={filter.certificateNumber}
                            propertyName={compileNameOfProperty<IControlExpedientFilter>("certificateNumber")}
                            label={t("EXPEDIENT_NUMBER")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                        <CustomInspectorSelector
                            name={compileNameOfProperty<IControlExpedientFilter>("inspectorId")}
                            selectedKey={filter.inspectorId}
                            onChange={onSelectorChange}
                            organismId={organismId ? organismId : 0}
                        />
                    </div>

                    <div className={style.row}>
                        <CustomDocumentSelector
                            name={compileNameOfProperty<IControlExpedientFilter>("certificateTypeId")}
                            selectedKey={filter.certificateTypeId}
                            onChange={onSelectorChange}
                        />

                        <BooleaMultiSelector
                            selectedKey={filter.alert}
                            propertyName={compileNameOfProperty<IControlExpedientFilter>("alert")}
                            title={t("WARNING")}
                            onChange={onBooleanSelectorChange}
                        />
                        <BooleaMultiSelector
                            selectedKey={filter.pending}
                            propertyName={compileNameOfProperty<IControlExpedientFilter>("pending")}
                            title={t("PENDING")}
                            onChange={onBooleanSelectorChange}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <FilterHeader
            height={120}
            content={filterContent()}
            onApply={onApply}
            onReset={onReset}
        />
    );
};
