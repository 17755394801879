import { IDropdownOption } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormField } from "../../../../../../common/fields/fields";
import { useForm } from "react-hook-form";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { useTheme } from "react-jss";
import { MasterOriginWareFormStyle } from "./formStyle.jss";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { Selector } from "../../../../../../common/selectors/selector";
import { IMerchandiseOrigin } from "../../../../../../../models";

import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterPresenceRepresentative } from "../../../../../../../models/resources/master/IPresenceRepresentative";
import { updateMasterPresenceRepresentativeProperty, setMasterPresenceRepresentativeForm } from "../../../../../../../redux/reducers/resources/master/representativePresence/form";


interface IMasterRepresentativePresenceFormProps {
    showNotData: boolean;
    selectorData: IMerchandiseOrigin[];
}

export const MasterRepresentativePresenceForm: React.FC<IMasterRepresentativePresenceFormProps> = ({ selectorData, showNotData }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { form } = useAppSelector((state) => state.masterPresenceRepresentativeForm);
    const { register, control } = useForm<IMasterPresenceRepresentative>({ mode: "onChange" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const theme = useTheme();
    const style = MasterOriginWareFormStyle({ theme });
    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateMasterPresenceRepresentativeProperty({ name, value }));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = (event: any, option: any, name: string) => {
        onUpdateFormProperty(name, String(option.key));

        let presenceRepresentative = selectorData.find((item) => item.id === option.key);
        if (presenceRepresentative) {
            let newForm: IMasterPresenceRepresentative = {
                active: true,
                description: presenceRepresentative.description,
                code: presenceRepresentative.code,
                referenceId: String(option.key),
            };
            dispatch(setMasterPresenceRepresentativeForm(newForm));
        }
    };

    const disabledInputs = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterPresenceRepresentative>("referenceId")}
                        title={t("FORM.PRESENCE_REPRESENTATIVE_SELECTOR")}
                        options={mapIDescriptionToDropDownOptions(selectorData)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? undefined}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterPresenceRepresentative>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? undefined}
                    disabled={disabledInputs}
                    {...register(compileNameOfProperty<IMasterPresenceRepresentative>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                    
                />
            </div>

            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterPresenceRepresentative>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInputs}
                />
            </div>
        </div>
    );
};
