import { DatePicker, DayOfWeek, IDatePicker, IDatePickerStrings } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { convertToLocal } from "../../../utils/dateUtils";
import { DatePickerStyled } from "./datePicker.jss";

interface Props {
    initValue?: Date;
    onChange: (date: Date | undefined | null, name: string) => void;
    label: string;
    name: string;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    className?: string;
    required?: boolean;
    resetDate?: boolean;
    setResetDate?: (reset: boolean) => any;
}

export const onFormatDate = (date?: Date): string => {
    return !date ? "" : convertToLocal(date, FormatDate.DATE);
};

export const DayPickerStringsES: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    days: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
    shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    goToToday: "Ir a día actual",
    prevMonthAriaLabel: "Ir a mes anterior",
    nextMonthAriaLabel: "Ir a mes siguiente",
    prevYearAriaLabel: "Ir a año anterior",
    nextYearAriaLabel: "Ir a año siguiente",
    closeButtonAriaLabel: "Cerrar selector",
    isRequiredErrorMessage: "",
    invalidInputErrorMessage: "Formato invalido.",
};

export const DatePickerComponent: React.FC<Props> = ({
    initValue,
    label,
    onChange,
    name,
    disabled,
    minDate,
    maxDate,
    required,
    resetDate,
    setResetDate,
}) => {
    const [value, setValue] = useState<Date | undefined>();
    const datePickerRef = useRef<IDatePicker>(null);
    const isFirstRender = useRef<boolean>(true);

    const theme = useTheme();

    const datePickerStyles = DatePickerStyled({ theme, hasValue: !!value });

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            initValue && setValue(initValue);
        } else {
            setValue(initValue);
        }
    }, [initValue]);

    useEffect(() => {
        if (resetDate && setResetDate) {
            setResetDate(false);
            setValue(undefined);
        }
    }, [resetDate]);

    const onParseDateFromString = React.useCallback(
        (newValue: string): Date => {
            const previousValue = value || new Date();
            const newValueParts = (newValue || "").trim().split("/");
            const day = newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
            const month = newValueParts.length > 1 ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1 : previousValue.getMonth();

            const year = newValueParts.length === 3 && newValueParts[2].length === 4 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
            const newDate = new Date(year, month, day);

            if (minDate && minDate > newDate) {
                return minDate;
            }

            if (maxDate && maxDate < newDate) {
                return maxDate;
            }
            return newDate;
        },
        [maxDate, minDate, value],
    );

    const onSelectDate = (date: Date | undefined | null): void => {
        if (date === null) {
            date = undefined;
        }
        setValue(date);
        onChange(date, name);
    };

    return (
        <div className={datePickerStyles.datePickerContainer}>
            <DatePicker
                componentRef={datePickerRef}
                label={label}
                ariaLabel="Select a date. Input format is day slash month slash year."
                value={value}
                onSelectDate={onSelectDate}
                formatDate={onFormatDate}
                parseDateFromString={onParseDateFromString}
                className={datePickerStyles.customStyles}
                strings={DayPickerStringsES}
                firstDayOfWeek={DayOfWeek.Monday}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                isRequired={required}
            />
        </div>
    );
};
