export const validateXml = (xmlString: string) => {
    const parser = new DOMParser();

    try {
        const xmlDoc = parser.parseFromString(xmlString, "application/xml");
        const parserError = xmlDoc.getElementsByTagName("parsererror");
        return parserError.length === 0;
    } catch (e) {
        return false;
    }
};
