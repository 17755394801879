import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserImportResponse } from "../../../../models/resources/userImport/IUserImport";

interface ICarrierImportState {
    loading: boolean;
    importResponse?: IUserImportResponse;
    templateCarrierImport?: string;
}

const initialState: ICarrierImportState = {
    loading: false,
    importResponse: undefined,
    templateCarrierImport: undefined,
};

export const carrierImportSlice = createSlice({
    name: "CarrierImportSlice",
    initialState,
    reducers: {
        resetCarrierImport: () => initialState,
        setLoadingCarrierImport: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDetailsCarrierResponse: (state, action: PayloadAction<IUserImportResponse>) => {
            state.importResponse = action.payload;
        },
        setUriTemplateCarrierImport: (state, action: PayloadAction<string>) => {
            state.templateCarrierImport = action.payload;
        },
    },
});

export const { resetCarrierImport, setLoadingCarrierImport, setDetailsCarrierResponse, setUriTemplateCarrierImport } = carrierImportSlice.actions;

export default carrierImportSlice.reducer;
