import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    emptyInspection,
    emptyTransportUnit,
    ICreateRequest,
    IFormInspection,
    IGenericPayload,
    Inspection,
    InspectionWithIndex,
    IRequestSectionValidation,
    IResumeCreateRequest,
    ITransportUnitData,
    ITransportUnitDataForm,
    ITransportUnitDataWithIndex,
    OriginSealWithIndex,
} from "../../../../models";
import { IAvailableDates } from "../../../../models/requests/utils/IAvailableDates";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface CreateRequestState {
    request: ICreateRequest;
    uti: ITransportUnitDataForm;
    inspection: IFormInspection;
    sectionValidation: IRequestSectionValidation;
    resumeRequest: IResumeCreateRequest[];
    activeStep: number;
    availableDates?: IAvailableDates;
}

const initialState: CreateRequestState = {
    request: {
        parkingAuthorization: true,
        temporaryRegime: false,
        organicProduct: false,
        halalCertified: false,
        transportUnits: [] as ITransportUnitData[],
        inspections: [] as Inspection[],
        invoiceCustomerId: 0,
        automaticParkingAuthorization: true,
    } as ICreateRequest,
    uti: {
        form: emptyTransportUnit(),
        showPopUp: PopUpCodes.NONE,
    },
    inspection: {
        form: emptyInspection(),
        showPopUp: PopUpCodes.NONE,
    },
    sectionValidation: {
        utiStepIsValid: false,
        informationStepIsValid: false,
        certificateStepIsValid: false,
    },
    resumeRequest: [],
    activeStep: 1,
};

export const createRequestSlice = createSlice({
    name: "createRequestReducer",
    initialState,
    reducers: {
        updateRequestProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.request = {
                ...state.request,
                [action.payload.name]: action.payload.value,
            };
        },
        resetRequestState: () => initialState,
        resetCreateRequestUtiForm: (state) => {
            state.uti = {
                form: emptyTransportUnit(),
                showPopUp: PopUpCodes.NONE,
            };
        },
        setCreateRequestUtiShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.uti.showPopUp = action.payload;
        },
        setUtiForm: (state, action: PayloadAction<ITransportUnitData>) => {
            state.uti.form = action.payload;
        },
        updateUtiProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.uti.form = {
                ...state.uti.form,
                [action.payload.name]: action.payload.value,
            };
        },
        pushCreateRequestUtiList: (state, action: PayloadAction<ITransportUnitData>) => {
            state.request.transportUnits.push(action.payload);
        },
        deleteUtiFromList: (state, action: PayloadAction<number>) => {
            state.request.transportUnits.splice(action.payload, 1);
        },
        updateCreateRequestUtiList: (state, action: PayloadAction<ITransportUnitDataWithIndex>) => {
            state.request.transportUnits[action.payload.index] = action.payload.transportUnit;
        },
        pushCreateRequestUtiSeal: (state, action: PayloadAction<string>) => {
            state.uti.form.seals.push(action.payload);
        },
        removeCreateRequestUtiSeal: (state, action: PayloadAction<number>) => {
            state.uti.form.seals.splice(action.payload, 1);
        },
        updateSealItem: (state, action: PayloadAction<OriginSealWithIndex>) => {
            state.uti.form.seals[action.payload.index] = action.payload.seal;
        },
        pushCreateRequestUtiBatch: (state, action: PayloadAction<string>) => {
            state.uti.form.batches.push(action.payload);
        },
        removeCreateRequestUtiBatch: (state, action: PayloadAction<number>) => {
            state.uti.form.batches.splice(action.payload, 1);
        },
        resetCreateRequestInspectionForm: (state) => {
            state.inspection = {
                form: emptyInspection(),
                showPopUp: PopUpCodes.NONE,
            };
        },
        setCreateRequestInspectionForm: (state, action: PayloadAction<Inspection>) => {
            state.inspection.form = action.payload;
        },
        setCreateRequestInspectionShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.inspection.showPopUp = action.payload;
        },
        updateInspectionProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.inspection.form = {
                ...state.inspection.form,
                [action.payload.name]: action.payload.value,
            };
        },
        pushCreateRequestInspectionList: (state, action: PayloadAction<Inspection>) => {
            state.request.inspections.push(action.payload);
        },
        deleteInspectionFromList: (state, action: PayloadAction<number>) => {
            state.request.inspections.splice(action.payload, 1);
        },
        updateCreateRequestInspectionList: (state, action: PayloadAction<InspectionWithIndex>) => {
            state.request.inspections[action.payload.index] = action.payload.inspection;
        },
        setUtiStepIsValid: (state, action: PayloadAction<boolean>) => {
            state.sectionValidation.utiStepIsValid = action.payload;
        },
        setInformationStepIsValid: (state, action: PayloadAction<boolean>) => {
            state.sectionValidation.informationStepIsValid = action.payload;
        },
        setCertificateStepIsValid: (state, action: PayloadAction<boolean>) => {
            state.sectionValidation.certificateStepIsValid = action.payload;
        },
        setRequestsCreated: (state, action: PayloadAction<IResumeCreateRequest[]>) => {
            state.resumeRequest = action.payload;
        },
        nextStep: (state) => {
            state.activeStep++;
        },
        previousStep: (state) => {
            state.activeStep--;
        },
        setCreateRegTempCheck: (state, action: PayloadAction<boolean>) => {
            state.request.temporaryRegime = action.payload;
        },
        setInspectionCertificateType: (state, action: PayloadAction<number>) => {
            state.inspection.form.certificateTypeId = action.payload;
        },
        setAvailableDates: (state, action: PayloadAction<any>) => {
            state.availableDates = action.payload;
        },
    },
});

export const {
    updateRequestProperty,
    resetRequestState,
    resetCreateRequestUtiForm,
    setUtiForm,
    updateUtiProperty,
    pushCreateRequestUtiList,
    deleteUtiFromList,
    updateCreateRequestUtiList,
    pushCreateRequestUtiSeal,
    removeCreateRequestUtiSeal,
    updateSealItem,
    pushCreateRequestUtiBatch,
    removeCreateRequestUtiBatch,
    resetCreateRequestInspectionForm,
    setCreateRequestInspectionForm,
    updateInspectionProperty,
    pushCreateRequestInspectionList,
    deleteInspectionFromList,
    updateCreateRequestInspectionList,
    setCertificateStepIsValid,
    setInformationStepIsValid,
    setUtiStepIsValid,
    setRequestsCreated,
    nextStep,
    previousStep,
    setCreateRegTempCheck,
    setInspectionCertificateType,
    setAvailableDates,
    setCreateRequestUtiShowPopUp,
    setCreateRequestInspectionShowPopUp,
} = createRequestSlice.actions;

export default createRequestSlice.reducer;
