import React from "react";
import { resetMasterAppointmentTypeForm } from "../../../../../redux/reducers/masterAppointment/appointmentType/form";
import { resetMasterAppointmentTypeList } from "../../../../../redux/reducers/masterAppointment/appointmentType/list";
import { store } from "../../../../../redux/store";
import { MasterAppointmentTypeList } from "./list/list";

export class IMasterAppointmentTypeContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterAppointmentTypeList());
        store.dispatch(resetMasterAppointmentTypeForm());
    }

    render(): React.ReactNode {
        return (
            <>
                <MasterAppointmentTypeList />
            </>
        );
    }
}
