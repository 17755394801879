export interface IUserRoleView {
    id: string;
    views: IView[];
}

export interface IFilters {
    name: string;
}

export interface IView {
    type?: string;
    state?: string;
    fields: IField[];
}

export interface IResponsive {
    detail: boolean;
    header: boolean;
    totalWidth: boolean;
}

export interface IField {
    name: string;
    required?: boolean;
    readOnly?: boolean;
    sortable?: boolean;
    responsive?: IResponsive;
}

export const emptyUserRoleViewData = (): IUserRoleView[] => {
    return [{
        id: "",
        views: [
            {
                type: "",
                fields: [],
            },
        ],
    }];
};
