import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../common/constants";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { postAndPutManagmentTariffAction, deleteManagmentTariffAction } from "../../../../redux/actions/management/tariff/tariff";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetManagementTariffState, setShowPopUpTariff } from "../../../../redux/reducers/management/tariff/form";
import { widths } from "../../../../styles/romeu/widths";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { ManagementTariffForm } from "./form/form";

export const ManagmentTariffController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.TARIFF" });
    const dispatch = useAppDispatch();

    const [isValidManagementTariffForm, setIsValidManagementTariffForm] = useState<boolean>(true);
    const { form, loading, showPopUp } = useAppSelector((store) => store.managementTariffForm);

    const isMobile = useMediaQuery({ query: componentsMediaQueries.LAPTOP });

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postAndPutManagmentTariffAction(form)).then((response: any) => {
                response && closePopUp();
            });
        } else if (showPopUp === PopUpCodes.DELETE && form.id) {
            dispatch(deleteManagmentTariffAction(form.id)).then((response: any) => {
                response && closePopUp();
            });
        }
    };
    const onDimissed = () => {
        dispatch(resetManagementTariffState());
    };
    const closePopUp = () => {
        dispatch(setShowPopUpTariff(PopUpCodes.NONE));
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.UPDATE) {
            return t("FORM.TITLE.UPDATE");
        } else if (showPopUp === PopUpCodes.CREATE) {
            return t("FORM.TITLE.CREATE");
        }

        return "";
    }, [showPopUp]);

    return (
        <>
            <PopUpForm
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={closePopUp}
                content={<ManagementTariffForm isValidForm={setIsValidManagementTariffForm} />}
                width={"40vw"}
                height={isMobile ? "calc(100vh - 60vh)" : "20vh"}
                title={titlePopUp}
                onSave={onSave}
                onDimissed={onDimissed}
                isDisabledButton={!isValidManagementTariffForm}
                leftButtonContent={<RequiredFieldsMessage />}
                isLoading={loading}
            />
            <PopUpConfirmation
            onDimissed={onDimissed}
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={closePopUp}
                loading={loading}
                onAccept={onSave}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
            />
        </>
    );
};
