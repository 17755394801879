export enum LocalStorageKey {
    ORGANIZATION = "organization",
    ROLE = "role",
    REQUEST_FILTER = "requestfilter",
    REQUEST_FILTER_START_DATE = "requestfilter_startdate",
    REQUEST_FILTER_END_DATE = "requestfilter_enddate",
    REQUEST_AUTO_RELOAD = "requestAutoReload",
    DETAILS_LIST_SCROLL_TOP = "detailsListScrollTop",
    NAV_MENU_SCROLL_TOP = "navmenu_scrolltop",
    NAV_MENU_FIXED = "navmenu_fixed",
    NAV_MENU_COLAPSED = "navmenu_colapsed",
    NAV_MENU_FOLDED = "navmenu_folded",
    MASTER_MANAGEMENT = "masterManagement",
    MASTER_APPOINTMENTS_MANAGEMENT = "masterAppointmentsManagement",
}

export class LocalStorageService {
    clear(): void {
        localStorage.clear();
    }

    setItem(key: LocalStorageKey, item: any): void {
        localStorage.setItem(key, JSON.stringify(item));
    }

    getItem(key: LocalStorageKey): any {
        const data = localStorage.getItem(key);
        if (!data) {
            return null;
        }
        return JSON.parse(data);
    }
}
