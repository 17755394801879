import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../common/constants";

export const imageContainerStyle = createUseStyles((theme: any) => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: theme.paddings.small,
    },
    documentHeader: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        alignItems: "center",
        color: theme.colors.titles.primary.color,
        flexWrap: "wrap",
        [mediaQueries.MOBILE]: {
            gap: 15,
        },
    }
}));
