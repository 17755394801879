import { createUseStyles } from "react-jss";

export const CustomPopUpButtonActionsStyle = createUseStyles((theme: any) => ({
    generalContainerButtons: (props: any) => ({
        display: "flex",
        justifyContent: props.customActions ? "space-between" : "end",
        paddingBottom: theme.paddings.sizes.sm,
        width: "100%",
        flex: "1 1 auto",
        gap: 20,
    }),
    sectionContainer: {
        display: "flex",
        gap: theme.gaps.buttons.default,
        width: "70%",
        paddingRight: 20,
    },
    sectionContainerButtons: (props: any) => ({
        display: "flex",
        gap: theme.gaps.buttons.default,
        width: props.customActions ? "100%" : "30%",
        justifyContent: "end",
        alignItems: "center",
        "& > * ": {
            maxHeight: 40,
        },
    }),
}));
