import React from "react";
import { FieldError } from "react-hook-form";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import i18n from "../../../services/i18n";
import { Input } from "../inputs/input";
import { Label } from "../labels/label";
import { FieldsStyle } from "./fields.jss";

interface IFieldsProps {
    name: string;
    type: string;
    label?: string;
    value: string;
    error?: FieldError;
    disabled?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    onChange: (e: any) => any;
    readonly?: boolean;
    ref?: React.Ref<HTMLInputElement>;
}

export const FormFieldCustom: React.FC<IFieldsProps> = ({
    name,
    type,
    label,
    value,
    error,
    disabled,
    isRequired,
    maxLength,
    onChange,
    readonly,
    ref,
}) => {
    const inputStyle = FieldsStyle();
    const valueInput = !value && disabled ? DefaultValues.EMPTY : value;

    const filled: string | number = !!valueInput && valueInput[0]?.length > 0 ? "filled" : "";

    return (
        <>
            <div className={inputStyle.formGroup}>
                <Input
                    name={name}
                    value={valueInput}
                    type={type}
                    maxLength={maxLength}
                    onChange={onChange}
                    ref={ref}
                    disabled={disabled}
                    readonly={readonly}
                />
                {label && (
                    <Label
                        title={label}
                        className={filled}
                        isRequired={isRequired}
                    />
                )}
                {error && <div className={inputStyle.error}>{i18n.t(error.message ?? "")}</div>}
            </div>
        </>
    );
};
