import { useCallback } from "react";
import { useTheme } from "react-jss";
import { fetchAppointmentsBoardAction } from "../../../../redux/actions/appointments/appointments";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setAppointmentBoardDate } from "../../../../redux/reducers/appointment/board";
import { CustomDatePickerOnlyDate } from "../../../common/customDatePicker/customDatePickerOnlyDate";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { AppoimentBoardFilterStyle } from "./filterStyle.jss";

export const AppointmentBoardFilter = () => {
    const theme = useTheme();
    const styles = AppoimentBoardFilterStyle({ theme });
    const dispatch = useAppDispatch();

    const { date } = useAppSelector((state) => state.appointmentBoardGrid);

    const updateFilter = useCallback(
        (name: string, value: any) => {
            dispatch(setAppointmentBoardDate(value));
            dispatch(fetchAppointmentsBoardAction(value));
        },
        [dispatch],
    );

    const onApply = useCallback(() => {}, [dispatch]);

    const onReset = useCallback(() => {}, [dispatch]);

    const filterContent = () => {
        return (
            <div className={styles.container}>
                <CustomDatePickerOnlyDate
                    date={new Date(date)}
                    showLeftArrowController={true}
                    setFilterProperty={updateFilter}
                />
            </div>
        );
    };

    return (
        <FilterHeader
            content={filterContent()}
            onApply={onApply}
            onReset={onReset}
            hideActionButtons={true}
        />
    );
};
