import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { routeUrls } from "../../../../common/constants";
import { fetchRequestedDocumentAction, uploadSignUpRequestAction } from "../../../../redux/actions/user/signup/signup";
import { goPreviousStep } from "../../../../redux/actions/user/signup/stepper";
import { useAppDispatch } from "../../../../redux/hooks";
import { loadingRequiredDocumentsSelector, userSignupFormSelector, userSignupSuccessSelector } from "../../../../redux/selectors/user/signup/signup";
import { Button, ButtonSecondary } from "../../../common/buttons/buttons";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { UserSignupResumeData } from "./data/resumeData";
import { UserSignupResumeDocuments } from "./documents/resumeDocuments";
import { UserSignupResumeContainerStyle } from "./containerStyle.jss";
import { UserSignupResumeTermsAndConditions } from "./termsAndConditions/container";
import { ColorType } from "../../../../common/enum/ColorType";
import { UserType } from "../../../../common/enum/UserType";


export const UserSignupResumeContainer = () => {
    const styles = UserSignupResumeContainerStyle();
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const form = useSelector(userSignupFormSelector);
    const signUpSuccess = useSelector(userSignupSuccessSelector);
    const loadingDocuments = useSelector(loadingRequiredDocumentsSelector);

    const disableButton = useMemo(() => {
        if (form.userType === UserType.TRUCKER) {
            return !form.acceptedTermsAndConditions;
        }

        return form.documents.some((item) => !item.file) || !form.acceptedTermsAndConditions;
    }, [form.documents, form.acceptedTermsAndConditions]);

    useEffect(() => {
        form.userType && form.documents?.length === 0 && dispatch(fetchRequestedDocumentAction(form.userType));
    }, [dispatch]);

    useEffect(() => {
        if (signUpSuccess) {
            navigate(routeUrls.HOME);
        }
    }, [signUpSuccess, navigate]);

    const onClickFinish = () => {
        dispatch(uploadSignUpRequestAction());
    };
    const backToPrevious = () => {
        dispatch(goPreviousStep());
    };

    return (
        <div className={styles.container}>
            <UserSignupResumeData data={form} />

            {loadingDocuments ? (
                <Skeleton
                    rows={2}
                    marginRow={20}
                />
            ) : (
                <UserSignupResumeDocuments documents={form.documents} />
            )}

            <UserSignupResumeTermsAndConditions data={form} />

            <div className={styles.buttonContainer}>
                <ButtonSecondary
                    handleClick={backToPrevious}
                    title={t("USER.SIGNUP.BACKBUTTON")}
                />
                <Button
                    title={t("USER.CONFIRMATION.FINISH_BUTTON")}
                    handleClick={onClickFinish}
                    color={ColorType.primary}
                    disabled={disableButton}
                />
            </div>
        </div>
    );
};
