import { IDropdownOption } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchMerchandiseCategoriesTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const MerchandiseCategorySelector: React.FC<IBaseSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    isRequired,
    control,
    rules,
    disabled,
}) => {
    const dispatch = useDispatch();
    const { data, loading } = useAppSelector((store) => store.catalog.merchandiseCategories);

    useEffect(() => {
        !data.length && !loading && dispatch(fetchMerchandiseCategoriesTypes());
    }, []);

    const onSelectorChange = (_evt: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={mapIDescriptionToDropDownOptions(data)}
            disabled={disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
        />
    );
};
