import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { IManagementUserForm, updateManagementUserProperty } from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, mapUserEntityWithEmailToDropDownOptions, mapUserWithEmailToDropDownOptions } from "../../../../../../utils";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { useEffect } from "react";
import { myProfileResolver } from "../../default/resolver";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { Selector } from "../../../../../common/selectors/selector";
import { fetchActiveAgents, fetchActiveCarriers, fetchActiveCustomers, fetchActiveOrganinsms, fetchActivePortLectors, fetchActiveTerminals } from "../../../../../../redux/actions/user/users/users";
import { RoleType } from "../../../../../../common/enum";
import { IGenericPayload } from "../../../../../../models";

interface IManagementUserParentSelectorProps {
    generalUserData: IManagementUserForm;
    setIsValidTab: (value: boolean) => any;
    onSelectorChange: (event: any, option: any, name: string) => any;
    editMode: boolean;
}

export const ManagementUserParentSelector: React.FC<IManagementUserParentSelectorProps> = ({
    generalUserData,
    setIsValidTab,
    onSelectorChange,
    editMode
}) => {

    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const { activeCustomers, activeCarriers, activeAgents, activeOrganisms, 
            activePortLectors, activeTerminals } = useAppSelector((store) => store.usersByRole);

    const {
        register,
        formState: { errors: error, isValid },
    } = useForm<IManagementUserForm>({ mode: "onChange", resolver: myProfileResolver });

    useEffect(() => {
        generalUserData.roleId === RoleType.CUSTOMER_USER && !activeCustomers.loading && !activeCustomers.data?.length && 
        dispatch(fetchActiveCustomers());
    }, [activeCustomers]);

    useEffect(() => {
        generalUserData.roleId === RoleType.CARRIER_USER && !activeCarriers.loading && !activeCarriers.data?.length && 
        dispatch(fetchActiveCarriers());
    }, [activeCarriers]);

    useEffect(() => {
        generalUserData.roleId === RoleType.AGENT_USER && !activeAgents.loading && !activeAgents.data?.length && 
        dispatch(fetchActiveAgents());
    }, [activeAgents]);

    useEffect(() => {
        (generalUserData.roleId === RoleType.ORGANISM_INSPECTOR || generalUserData.roleId === RoleType.ORGANISM_TECHNICIAN)  && 
        !activeOrganisms.loading && !activeOrganisms.data?.length && dispatch(fetchActiveOrganinsms());
    }, [activeOrganisms]);

    useEffect(() => {
        generalUserData.roleId === RoleType.PORT_LECTOR_USER && !activePortLectors.loading && !activePortLectors.data?.length && 
        dispatch(fetchActivePortLectors());
    }, [activePortLectors]);

    useEffect(() => {
        generalUserData.roleId === RoleType.TERMINAL_USER && !activeTerminals.loading && !activeTerminals.data?.length && 
        dispatch(fetchActiveTerminals());
    }, [activeTerminals]);

    useEffect(() => {
        setIsValidTab(generalUserData.parentId !== undefined && isValid);
    }, [generalUserData.terminalId, isValid]);

    const getParentTitle = () => {
        switch (generalUserData.roleId) {
            case RoleType.CUSTOMER_USER:
                return t("CUSTOMER_PARENT");
            case RoleType.CARRIER_USER:
                return t("CARRIER_PARENT");
            case RoleType.AGENT_USER:
                return t("AGENT_PARENT");
            case RoleType.ORGANISM_INSPECTOR:
                return t("ORGANISM_PARENT");
            case RoleType.ORGANISM_TECHNICIAN:
                return t("ORGANISM_PARENT");
            case RoleType.PORT_LECTOR_USER:
                return t("PORT_LECTOR_PARENT");
            case RoleType.TERMINAL_USER:
                return t("TERMINAL_PARENT");
            default:
                return "";
        }
    };

    const getParentOptions = () => {
        switch (generalUserData.roleId) {
            case RoleType.CUSTOMER_USER:
                return mapUserEntityWithEmailToDropDownOptions(activeCustomers.data ?? []);
            case RoleType.CARRIER_USER:
                return mapUserEntityWithEmailToDropDownOptions(activeCarriers.data ?? []);
            case RoleType.AGENT_USER:
                return mapUserWithEmailToDropDownOptions(activeAgents.data ?? []);
            case RoleType.ORGANISM_INSPECTOR:
                return mapUserWithEmailToDropDownOptions(activeOrganisms.data ?? []);
            case RoleType.ORGANISM_TECHNICIAN:
                return mapUserWithEmailToDropDownOptions(activeOrganisms.data ?? []);
            case RoleType.PORT_LECTOR_USER:
                return mapUserWithEmailToDropDownOptions(activePortLectors.data ?? []);
            case RoleType.TERMINAL_USER:
                return mapUserWithEmailToDropDownOptions(activeTerminals.data ?? []);
            default:
                return [];
        }
    };

    const getAvailableOrganizationsByUser = (email: string) => {
        switch (generalUserData.roleId) {
            case RoleType.CUSTOMER_USER:
                return activeCustomers?.data?.find((x) => x.id === email)?.organizations ?? [];
            case RoleType.CARRIER_USER:
                return activeCarriers?.data?.find((x) => x.id === email)?.organizations ?? [];
            case RoleType.AGENT_USER:
                return activeAgents?.data?.find((x) => x.id === email)?.organizations ?? [];
            case RoleType.ORGANISM_INSPECTOR:
                return activeOrganisms?.data?.find((x) => x.id === email)?.organizations ?? [];
            case RoleType.ORGANISM_TECHNICIAN:
                return activeOrganisms?.data?.find((x) => x.id === email)?.organizations ?? [];
            case RoleType.PORT_LECTOR_USER:
                return activePortLectors?.data?.find((x) => x.id === email)?.organizations ?? [];
            case RoleType.TERMINAL_USER:
                return activeTerminals?.data?.find((x) => x.id === email)?.organizations ?? [];
            default:
                return [];
        }
    };

    const onParentSelectorChange = (event: any, option: any, name: string) => {
        let payload: IGenericPayload = {
            name: compileNameOfProperty<IManagementUserForm>("availableOrganizations"),
            value: getAvailableOrganizationsByUser(option.key),
        };
        dispatch(updateManagementUserProperty(payload));

        onSelectorChange(event, option, name);
    };

    return (
        <div className={`${style.containerForm}`}>
            <div className={`ms-Grid ${style.gridContainer}`} dir="ltr">
            {!editMode && (
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.FULL_CELL}>
                        <Selector
                            key={String(generalUserData.parentId)}
                            id={String(generalUserData.parentId)}
                            title={getParentTitle()}
                            options={getParentOptions()}
                            selectedKey={generalUserData.parentId}
                            name={compileNameOfProperty<IManagementUserForm>("parentId")}
                            onChange={onParentSelectorChange}
                            disabled={generalUserData.id ? true : false}
                            required={true}
                        />
                    </div>
                    <div className={GridClass.FULL_CELL}></div>
                </div>
            )}
            </div>
        </div>
    );
};
