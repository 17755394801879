import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IEntityData, IGenericPayload, IRequestedDocument } from "../../../../../../models";
import {
    addAdminEmailItem,
    addOperationEmailItem,
    IManagementUserForm,
    removeAdminEmailItem,
    removeDocument,
    removeDocumentBlobs,
    removeOperationEmailItem,
    setDocumentsUser,
    updateAdminDataProperty,
    updateAdminEmailItem,
    updateManagementUserEntityDataProperty,
    updateOperationEmailItem,
    updateOperatorDataProperty,
} from "../../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../utils";
import { FormField } from "../../../../../common/fields/fields";
import { ManagementUserFormCarrierAdminEntityDocuments } from "../../adminCarrier/documents/documents";
import { myProfileEntityCarrierResolver, myProfileEntityResolver } from "../user/resolver";
import { ManagementUserCustomerFormEntityEmailList } from "./entityEmail/list";
import { ManagementCustomerFormEntityRepresentativeData } from "./representative/representative";
import { ManagementUserDefaultFormStyle } from "../../baseFormStyle.jss";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { LabelText } from "../../../../../common/labels/labelText/labelText";
import { UploadFileCustom } from "../../../../../common/uploadFile/uploadFile";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { ProfileDocumentTypes } from "../../../../../../common/enum/ProfileDocumentTypes";
import { ContentTypeExtensions } from "../../../../../../common/enum/files/ContentType";
import { DefaultValues } from "../../../../../../common/enum/DefaultValues";
import { FileSize } from "../../../../../../common/enum/files/FileSize";
import { RoleType } from "../../../../../../common/enum";
import { isFieldRequiredWithSchema } from "../../validators/schema";
import { schemaRole, schemaView } from "../../../../../../common/enum/management/Schema";

interface IManagementCustomerFormUserDataProps {
    generalUserData: IManagementUserForm;
    setIsValidTab: (value: boolean) => any;
    role?: schemaRole;
}

export const ManagementCustomerFormEntityData: React.FC<IManagementCustomerFormUserDataProps> = ({ generalUserData, setIsValidTab, role }) => {
    const { entityData, adminData, operatorData } = generalUserData;
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const theme = useTheme();
    const style = ManagementUserDefaultFormStyle({ theme });
    const schema = isFieldRequiredWithSchema(role ?? schemaRole.DEFAULT, schemaView.ENTITY_DATA);

    const dispatch = useDispatch();

    const [isValidAdminData, setIsValidAdminData] = useState<boolean>(false);
    const [isValidOperatorData, setIsValidOperatorData] = useState<boolean>(false);
    const resolver = role === schemaRole.CARRIER ? myProfileEntityCarrierResolver : myProfileEntityResolver;
    const {
        register,
        formState: { errors: formError, isValid },
    } = useForm<IEntityData>({ mode: "onChange", resolver: resolver });

    const isRequiredField = (field: string) => {
        return schema.includes(field);
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            const payload: IGenericPayload = {
                name: name,
                value: value,
            };
            dispatch(updateManagementUserEntityDataProperty(payload));
        },
        [dispatch],
    );

    const showEmailListData = useMemo(() => {
        return generalUserData.roleId === RoleType.CUSTOMER_ADMIN ? true : false;
    }, [generalUserData.roleId, adminData]);

    const fiscalCard = useMemo(() => {
        if (!generalUserData.documents) {
            return null;
        }

        return generalUserData.documents.find((item) => item.documentId === Number(ProfileDocumentTypes.FISCAL_CARD));
    }, [generalUserData]);

    const fiscalCardBlob = useMemo(() => {
        if (!generalUserData.blobs) {
            return null;
        }

        return generalUserData.blobs.find((item) => item.type === ProfileDocumentTypes.FISCAL_CARD);
    }, [generalUserData]);
    const updateDocument = (file?: File, name?: string) => {
        if (file) {
            const item: IRequestedDocument = {
                documentId: Number(name),
                documentName: file?.name,
                file: file ? URL.createObjectURL(file) : "",
                index: Number(name),
                fileName: file?.name,
            };
            dispatch(setDocumentsUser(item));
        }
    };
    useEffect(() => {
        const entityData = isValid;
        const adminData = generalUserData.roleId === RoleType.CUSTOMER_ADMIN ? isValidAdminData : true;
        const operatorData = generalUserData.roleId === RoleType.CUSTOMER_ADMIN ? isValidOperatorData : true;
        const document = () => {
            if (generalUserData.roleId === RoleType.CUSTOMER_ADMIN) {
                return fiscalCard || fiscalCardBlob ? true : false;
            }
            return true;
        };
        const representativeData = () => {
            if (generalUserData.roleId === RoleType.CUSTOMER_ADMIN) {
                return generalUserData.crmCode ? true : false;
            }
            return true;
        };

        setIsValidTab(entityData && adminData && operatorData && document() && representativeData() ? true : false);
    }, [isValid, isValidOperatorData, isValidAdminData, fiscalCard, fiscalCardBlob, generalUserData.crmCode, generalUserData]);

    return (
        <div className={`  ${style.containerForm}`}>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("ENTITY_NAME")}
                            value={entityData?.name ? entityData?.name : DefaultValues.NOT_TEXT}
                            error={formError.name}
                            {...register(compileNameOfProperty<IEntityData>("name"), {
                                onChange: onInputChange,
                                value: entityData?.name ? entityData?.name : DefaultValues.NOT_TEXT,
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IEntityData>("name"))}
                        />
                    </div>

                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("CIF")}
                            value={entityData?.documentNumber ?? DefaultValues.NOT_TEXT}
                            error={formError.documentNumber}
                            {...register(compileNameOfProperty<IEntityData>("documentNumber"), {
                                onChange: onInputChange,
                                value: entityData?.documentNumber,
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IEntityData>("documentNumber"))}
                        />
                    </div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("FISCAL_ADDRESS")}
                            value={entityData?.socialAddress ?? DefaultValues.NOT_TEXT}
                            error={formError.socialAddress}
                            {...register(compileNameOfProperty<IEntityData>("socialAddress"), {
                                onChange: onInputChange,
                                value: entityData?.socialAddress,
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IEntityData>("socialAddress"))}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("MUNICIPALITY")}
                            value={entityData?.city ?? DefaultValues.NOT_TEXT}
                            error={formError.city}
                            {...register(compileNameOfProperty<IEntityData>("city"), {
                                onChange: onInputChange,
                                value: entityData?.city,
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IEntityData>("city"))}
                        />
                    </div>
                </div>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("POSTAL_CODE")}
                            value={entityData?.postalCode ?? DefaultValues.NOT_TEXT}
                            error={formError.postalCode}
                            {...register(compileNameOfProperty<IEntityData>("postalCode"), {
                                onChange: onInputChange,
                                value: entityData?.postalCode,
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IEntityData>("postalCode"))}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("PROVINCE")}
                            value={entityData?.province ?? DefaultValues.NOT_TEXT}
                            error={formError.province}
                            {...register(compileNameOfProperty<IEntityData>("province"), {
                                onChange: onInputChange,
                                value: entityData?.province,
                            })}
                            isRequired={isRequiredField(compileNameOfProperty<IEntityData>("province"))}
                        />
                    </div>
                </div>
                {generalUserData.roleId === RoleType.CUSTOMER_ADMIN && (
                    <div className={`${GridClass.ROW_GRID}`}>
                        <div className={`${GridClass.SIMPLE_CELL} ${style.documentRow}`}>
                            <LabelText
                                name={t("FISCAL_CARD")}
                                isRequired={isRequiredField(compileNameOfProperty<IEntityData>("name"))}
                                hiddenValue={true}
                            />
                            <UploadFileCustom
                                removeDocument={() => {
                                    dispatch(removeDocument(Number(ProfileDocumentTypes.FISCAL_CARD)));
                                    dispatch(removeDocumentBlobs(ProfileDocumentTypes.FISCAL_CARD));
                                }}
                                name={ProfileDocumentTypes.FISCAL_CARD}
                                allowedExtensions={ContentTypeExtensions.USER_DOCUMENT}
                                onChangeFile={updateDocument}
                                title={t("ADD_DOCUMENTS")}
                                buttonStyle={ColorType.primary}
                                maxSize={FileSize._5MB}
                                file={fiscalCard ?? fiscalCardBlob}
                            />
                        </div>
                    </div>
                )}

                {generalUserData.roleId === RoleType.CUSTOMER_ADMIN && <ManagementCustomerFormEntityRepresentativeData />}

                {showEmailListData && (
                    <div className={GridClass.ROW_GRID}>
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <ManagementUserCustomerFormEntityEmailList
                                data={adminData}
                                title={t("ADMIN_DATA")}
                                updateDataProperty={(value: IGenericPayload) => {
                                    dispatch(updateAdminDataProperty(value));
                                }}
                                updateEmailItem={(payload) => {
                                    dispatch(updateAdminEmailItem(payload));
                                }}
                                removeEmailItem={(index: number) => {
                                    dispatch(removeAdminEmailItem(index));
                                }}
                                addEmailItem={() => {
                                    dispatch(addAdminEmailItem());
                                }}
                                setValidData={setIsValidAdminData}
                            />
                        </div>
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <ManagementUserCustomerFormEntityEmailList
                                data={operatorData}
                                title={t("OPERATION_DEPARTMENT")}
                                updateDataProperty={(value: IGenericPayload) => {
                                    dispatch(updateOperatorDataProperty(value));
                                }}
                                updateEmailItem={(payload) => {
                                    dispatch(updateOperationEmailItem(payload));
                                }}
                                removeEmailItem={(index: number) => {
                                    dispatch(removeOperationEmailItem(index));
                                }}
                                addEmailItem={() => {
                                    dispatch(addOperationEmailItem());
                                }}
                                setValidData={setIsValidOperatorData}
                            />
                        </div>
                    </div>
                )}

                {generalUserData.roleId === RoleType.CARRIER_ADMIN && (
                    <ManagementUserFormCarrierAdminEntityDocuments generalUserData={generalUserData} />
                )}
            </div>
        </div>
    );
};
