import { IButtonStyles, IconButton, IIconProps } from "@fluentui/react";
import React, { ReactNode } from "react";
import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../redux/hooks";
import { TitleH5 } from "../../titles/titles";
import { RequestStepperStyled } from "./requestStepper.jss";
import { UserStep } from "./step/step";

import { ColorType } from "../../../../common/enum/ColorType";



interface IRequestStepperProps {
    steps: StepNode[];
    title: string;
    draft?: boolean;
    onClickButton: () => void;
    enabledNav?: boolean;
    navigateTo?: (step: number) => void;
}

interface StepNode {
    title: string;
    subtitle?: string;
    node: ReactNode;
}

export const RequestStepper: React.FC<IRequestStepperProps> = ({ steps, title, draft = false, onClickButton, enabledNav = false, navigateTo }) => {
    const theme = useTheme();
    const styles = RequestStepperStyled({ theme });
    const step = useAppSelector((state) => (draft ? state.draftRequest.activeStep : state.createRequest.activeStep));

    const cancelIcon: IIconProps = { iconName: "Cancel" };

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: styles.exitButton,
        icon: styles.closeIcon,
    };

    const stepButtons = steps.map((item, index: number) => (
        <div
            className={`${enabledNav ? styles.clickable : ""}`}
            key={`index-${index}`}
            onClick={() => enabledNav && navigateTo && navigateTo(index + 1)}
        >
            <UserStep
                key={index}
                id={index + 1}
                number={(index + 1).toString()}
                title={item.title}
                subtitle={item.subtitle}
                step={step}
            />
        </div>
    ));

    const stepContents = steps.map((item, index: number) => {
        if (step === index + 1) {
            return <> {item.node} </>;
        }
        return <></>;
    });

    return (
        <>
            <div className={styles.container}>
                <div className={styles.leftColumnContainer}>
                    <div className={styles.leftColumnContent}>
                        <div className={styles.leftColumntitle}>
                            <TitleH5
                                title={title}
                                color={ColorType.primary}
                                bold={true}
                            />
                        </div>
                        <div className={styles.leftColumnButtons}> {stepButtons}</div>
                    </div>
                </div>
                <div className={styles.rightColumnContainer}>
                    <div className={styles.buttonContainer}>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            onClick={onClickButton}
                        />
                    </div>
                    <div className={styles.rightColumnContent}>{stepContents}</div>
                </div>
            </div>
        </>
    );
};
