import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IUserNotifications } from "../../../../../models/user/notifications/IUserNotifications";
import { useAppDispatch } from "../../../../../redux/hooks";
import { updateUserNotificationActive, updateUserProfileNotificationTime } from "../../../../../redux/reducers/user/profile/userProfile";
import { TimePickerComponent } from "../../../../common/timePicker/timePicker";
import { CustomToogle } from "../../../../common/toogle/toogle";
import { ProfileUserNotificationItemStyle } from "./itemStyle.jss";
import { ITimeNotification } from "./listEmails/list";

interface IProfileUserNotificationItem {
    notification: IUserNotifications;
    notificationIndex: number;
}

export interface IPayloadUserChecknotification {
    active: boolean;
    notificationIndex: number;
}

export const ProfileUserNotificationItem: React.FC<IProfileUserNotificationItem> = ({ notification, notificationIndex }) => {
    const { t } = useTranslation("common", { keyPrefix: "USER.NOTIFICATIONS" });
    const theme = useTheme();
    const style = ProfileUserNotificationItemStyle({ theme });
    const dispatch = useAppDispatch();

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            if (date) {
                const payload: ITimeNotification = {
                    notificationIndex: notificationIndex,
                    time: new Date(date.toISOString()),
                };
                dispatch(updateUserProfileNotificationTime(payload));
            }
        },
        [dispatch],
    );
   
    const toogleCheckNotification = () => {
        const payload: IPayloadUserChecknotification = {
            active: !notification.active,
            notificationIndex,
        };

        dispatch(updateUserNotificationActive(payload));
    };

    return (
        <>
            <div className={style.container}>
                <div className={style.row}>
                    <CustomToogle
                        checked={notification.active}
                        onChage={toogleCheckNotification}
                        inlineLabel={true}
                        label={notification.notificationDescription}
                        info={notification.notificationDetails}
                    />
                    {notification.notificationScheduleTime && (
                        <TimePickerComponent
                            onChange={onTimeChange}
                            value={notification.scheduleTimeOn}
                            label={t("HOUR")}
                            required={true}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
