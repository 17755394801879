import { RootState } from "../../../store";

export const userSignupFormSelector = (state: RootState) => {
    return state.userSignup.form;
};
export const userSignupSuccessSelector = (state: RootState) => {
    return state.userSignup.userSignupSuccess;
};

export const loadingRequiredDocumentsSelector = (state: RootState) => {
    return state.userSignup.loadingRequiredDocuments;
};
