import { StatiticsApi } from "../../../api/statitics";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import { IStatisticsFilter, IStatisticsSearchFilter } from "../../../models/statistics/IStatisticsFilters";
import { throwErrorToastrAsync } from "../../../utils";
import { setGlobalLoading } from "../../reducers/common/globalLoading";
import { resetStatisticsCustomerFilter } from "../../reducers/statistics/customer/filter";
import { resetCustomerList, setDataCustomerList, setLoadingCustomerList } from "../../reducers/statistics/customer/list";
import { resetDailyChargeFilter } from "../../reducers/statistics/dailyCharge/filter";
import {
    resetStatiticsDailyChargeList,
    setLoadingStatisticsDailyChargeList,
    setStatiticsDailyChargeList,
} from "../../reducers/statistics/dailyCharge/list";
import { resetOperatorIncidenceFilter } from "../../reducers/statistics/incidenceOperator/filter";
import {
    resetStatisticsIncidenceOperator,
    setStatisticsIncidenceOperatorData,
    setStatisticsIncidenceOperatorLoading,
} from "../../reducers/statistics/incidenceOperator/list";
import { resetOrganismIncidenceFilter } from "../../reducers/statistics/incidenceOrganism/filter";
import {
    setStatisticsIncidenceOrganismLoading,
    setStatisticsIncidenceOrganismData,
    resetIncidenceOrganismList,
} from "../../reducers/statistics/incidenceOrganism/list";
import { setAccumulatedInspectionData, setLoadingInspectionAccumulativeList } from "../../reducers/statistics/inspection/accumulative";
import {
    resetStatisticsInspectionDetailedData,
    setStatisticsInspectionDetailedData,
    setStatisticsInspectionDetailedLoading,
} from "../../reducers/statistics/inspection/detailed";
import { resetStatisticsInspectionFilter } from "../../reducers/statistics/inspection/filter";
import { setStatisticsInspectionGroupedData, setStatisticsInspectionGroupedLoading } from "../../reducers/statistics/inspection/grouped";
import { resetMonthlySummaryFilter } from "../../reducers/statistics/monthlySummary/filter";
import {
    resetStatisticsMonthlySummary,
    setStatisticsMonthlySummaryData,
    setStatisticsMonthlySummaryLoading,
} from "../../reducers/statistics/monthlySummary/list";
import { resetReaderRecordRatioFilter } from "../../reducers/statistics/readerRecordRatio/filter";
import { resetReaderRecordRatioListState, setStatisticsReaderRecordRatioData, setStatisticsReaderRecordRatioLoading } from "../../reducers/statistics/readerRecordRatio/list";
import { setStatisticsRequestGraph } from "../../reducers/statistics/requestGraph/list";

import { AppThunk, store } from "../../store";

const requestApi = new StatiticsApi();

export const fetchStatiticsCustomerFilterAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statiticsCustomerFilter;

        dispatch(setLoadingCustomerList(true));
        const response = await requestApi.getStatiticsCustomerAsync(mapStatiticsFilter(filter));
        dispatch(setDataCustomerList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingCustomerList(false));
    }
};

export const exportStatisticsCustomersAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statiticsCustomerFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsCustomersExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatiticsIncidenceOrganismFilterAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsOrganismIncidenceFilter;
        dispatch(setStatisticsIncidenceOrganismLoading(true));
        const response = await requestApi.getStatiticsIncidenceOrganismAsync(mapStatiticsFilter(filter));
        dispatch(setStatisticsIncidenceOrganismData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setStatisticsIncidenceOrganismLoading(false));
    }
};

export const exportStatisticsIncidenceOrganismAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsOrganismIncidenceFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsIncidenceOrganismExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatiticsIncidenceOperatorFilterAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsOperatorIncidenceFilter;
        dispatch(setStatisticsIncidenceOperatorLoading(true));
        const response = await requestApi.getStatiticsIncidenceOperatorAsync(mapStatiticsFilter(filter));
        dispatch(setStatisticsIncidenceOperatorData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setStatisticsIncidenceOperatorLoading(false));
    }
};

export const exportStatisticsIncidenceOperatorAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsOperatorIncidenceFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsIncidenceOperatorExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatiticsMonthlySummaryFilterAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsMonthlySummaryFilter;
        dispatch(setStatisticsMonthlySummaryLoading(true));
        const response = await requestApi.getStatiticsMonthlySummaryAsync(mapStatiticsFilter(filter));
        dispatch(setStatisticsMonthlySummaryData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setStatisticsMonthlySummaryLoading(false));
    }
};

export const exportStatisticsMonthlySummaryAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsMonthlySummaryFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsMonthlySummaryExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatisticsInspectionDetailedFilterAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsInspectionFilter;

        dispatch(setStatisticsInspectionDetailedLoading(true));
        const response = await requestApi.getStatisticsInspectionDetailed(mapStatiticsFilter(filter));
        dispatch(setStatisticsInspectionDetailedData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setStatisticsInspectionDetailedLoading(false));
    }
};

export const exportStatisticsInspectionsDetailedAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsInspectionFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsInspectionDetailedExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatisticsInspectionGroupedFilterAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsInspectionFilter;

        dispatch(setStatisticsInspectionGroupedLoading(true));
        const response = await requestApi.getStatisticsInspectionGrouped(mapStatiticsFilter(filter));
        dispatch(setStatisticsInspectionGroupedData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setStatisticsInspectionGroupedLoading(false));
    }
};

export const exportStatisticsInspectionsGroupedAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsInspectionFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsInspectionGroupedExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatisticsDailyChargeAction = (): AppThunk => async (dispatch) => {
    try {
        let  filter  = store.getState().statisticsDailyChargeFilter.filter;
        const { organismId } = store.getState().userData.profile;
        if (organismId) {
            filter ={...filter, organismIds : [organismId]};
        }
        dispatch(setLoadingStatisticsDailyChargeList(true));
        const response = await requestApi.getStatisticsDailyCharge(mapStatiticsFilter(filter));
        dispatch(setStatiticsDailyChargeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingStatisticsDailyChargeList(false));
    }
};
export const exportStatisticsDailyChargeAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsDailyChargeFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsDailyChargeExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatisticsInspectionAcumulativeByMonthAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsInspectionFilter;
        dispatch(setLoadingInspectionAccumulativeList(true));
        const response = await requestApi.getStatisticsInspectionAgrupedByMonth(mapStatiticsFilter(filter));
        dispatch(setAccumulatedInspectionData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingInspectionAccumulativeList(false));
    }
};
export const exportStatisticsInspectionAcumulativeByMonthAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsInspectionFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsInspectionAgrupedByMonthExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatiticsReaderRecordRatioAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsReaderRecordRatioFilter;
        dispatch(setStatisticsReaderRecordRatioLoading(true));
        const response = await requestApi.getStatisticsReaderRecordRatio(mapStatiticsFilter(filter));
        dispatch(setStatisticsReaderRecordRatioData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setStatisticsReaderRecordRatioLoading(false));
    }
};

export const exportStatiticsReaderRecordRatioAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().statisticsReaderRecordRatioFilter;
        dispatch(setGlobalLoading(true));
        const response = await requestApi.statisticsReaderRecordRatioExport(mapStatiticsFilter(filter));
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};

export const fetchStatisticsRequestGraphAction =
    (organizationParam: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoading(true));
            const response = await requestApi.getStatisticsRequestGraph(organizationParam);
            dispatch(setStatisticsRequestGraph(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoading(false));
        }
    };

const mapStatiticsFilter = (filter: IStatisticsFilter): IStatisticsSearchFilter => {
    const filters: IStatisticsFilter = {
        ...filter,
        transportUnitIds: isAllSearchInFilter(filter.transportUnitIds),
        transportUnitSizeIds: isAllSearchInFilter(filter.transportUnitSizeIds),
        customerIds: isAllSearchInFilter(filter.customerIds),
        merchandiseTypeIds: isAllSearchInFilter(filter.merchandiseTypeIds),
        merchandiseOriginIds: isAllSearchInFilter(filter.merchandiseOriginIds),
        customsStateIds: isAllSearchInFilter(filter.customsStateIds),
        finalDestinationTypeIds: isAllSearchInFilter(filter.finalDestinationTypeIds),
        organismIds: isAllSearchInFilter(filter.organismIds),
        certificateTypeIds: isAllSearchInFilter(filter.certificateTypeIds),
        documentTypeIds: isAllSearchInFilter(filter.documentTypeIds),
        inspectorIds: isAllSearchInFilter(filter.inspectorIds),
        startDate: filter.startDate,
        endDate: filter.endDate,
        dryCharge: isAllBooleanInFilter(filter.dryCharge),
        fumigated: isAllBooleanInFilter(filter.fumigated),
        halalCertified: isAllBooleanInFilter(filter.halalCertified),
        organicProduct: isAllBooleanInFilter(filter.organicProduct),
    };
    return { filters: filters };
};
const isAllSearchInFilter = (list: any) => {
    const a = !list.includes(DefaultValues.DEFAULT_ALL_SELECTOR) && !list.includes(-1);

    return a ? list : null;
};
const isAllBooleanInFilter = (value: any) => {
    return value !== null && value !== undefined ? value : null;
};

export const resetStatisticsData = (): AppThunk => (dispatch) => {
    dispatch(resetCustomerList());
    dispatch(resetStatisticsCustomerFilter());
    dispatch(resetIncidenceOrganismList());
    dispatch(resetOrganismIncidenceFilter());
    dispatch(resetStatisticsIncidenceOperator());
    dispatch(resetOperatorIncidenceFilter());
    dispatch(resetStatisticsMonthlySummary());
    dispatch(resetMonthlySummaryFilter());
    dispatch(resetStatisticsInspectionFilter());
    dispatch(resetStatisticsInspectionDetailedData());
    dispatch(resetDailyChargeFilter());
    dispatch(resetStatiticsDailyChargeList());
    dispatch(resetReaderRecordRatioFilter());
    dispatch(resetReaderRecordRatioListState());
};
