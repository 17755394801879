import { ISortState } from "../../common/ISortState";

export interface ITariffData {
    listTariffCondition: ITariffCondition[];
    loading: boolean;
    tariffCondition: ITariffCondition;
    sort: ISortState;
}

export interface ITariffCondition {
    id?: number;
    conceptId?: number;
    conceptCode: string;
    conceptDescription: string;
    unitPrice: number;
    automatic: boolean;
    requestStateId?: number;
}

export interface IInvoiceConcept {
    [x: string]: any;
    conceptId: number;
    conceptCode: string;
    conceptDescription: string;
}

export const resetInitialStateManagementTariffForm = () => {
    return {
        conceptCode: "",
        conceptDescription: "",
        unitPrice: 0,
        automatic: false,
    };
};

export const resetInitialStateManagementTariffList = () => {
    return {
        listTariffCondition: [],
        loading: false,
        sort: {
            column: "",
            orderColumn: "",
        },
    };
};
