import React, { useMemo } from "react";
import { useTheme } from "react-jss";
import { IUserNotifications } from "../../../../../../models/user/notifications/IUserNotifications";
import { useAppDispatch } from "../../../../../../redux/hooks";
import {
    addUserProfileNotificationEmailItem,
    removeUserProfileNotificationEmailItem,
    updateUserProfileNotificationEmailItem,
} from "../../../../../../redux/reducers/user/profile/userProfile";
import { NotificationEmailItem } from "./item/item";
import { ProfileUserNotificationListStyle } from "./listStyle.jss";

interface IProfileUserEmailItems {
    notification: IUserNotifications;
    notificationIndex: number;
}
export interface INotificationEmail {
    notificationIndex: number;
    emailIndex: number;
    email: string;
}

export interface ITimeNotification {
    notificationIndex: number;
    time: any;
}

export const ProfileUserEmailItems: React.FC<IProfileUserEmailItems> = ({ notification, notificationIndex }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ProfileUserNotificationListStyle({ theme });

    const maxIndex = useMemo(() => {
        let number = 0;
        if (notification.emailList && notification.emailList !== null) {
            number = notification.emailList.length - 1;
        }

        return number;
    }, [notification.emailList]);

    return (
        <div className={style.list}>
            {notification.emailList.map((item, index) => {
                return (
                    <div className={style.element}>
                        <NotificationEmailItem
                            email={item}
                            isLastIndex={index === maxIndex && notification.emailList.length <= 2}
                            isUniqueIndex={false}
                            index={index}
                            updateEmailItem={(data: INotificationEmail) => {
                                dispatch(updateUserProfileNotificationEmailItem(data));
                            }}
                            removeEmailItem={(data: INotificationEmail) => {
                                notification.emailList.length > 0 && dispatch(removeUserProfileNotificationEmailItem(data));
                                if (notification.emailList.length === 1) {
                                    dispatch(addUserProfileNotificationEmailItem(notificationIndex));
                                }
                            }}
                            addEmailItem={() => {
                                dispatch(addUserProfileNotificationEmailItem(notificationIndex));
                            }}
                            notificationIndex={notificationIndex}
                        ></NotificationEmailItem>
                    </div>
                );
            })}
        </div>
    );
};
