import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterNotificationTypesForm, setMasterNotificationTypesForm } from "../../../../reducers/resources/master/notificationType/form";
import { setLoadingMasterNotificationTypesList, setMasterNotificationTypesList } from "../../../../reducers/resources/master/notificationType/list";
import { AppThunk, store } from "../../../../store";


const catalogAPI = new CatalogAPI();

export const fetchMasterNotificationTypesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterNotificationTypesList(true));
        const response = await catalogAPI.getMasterNotificationTypesList();
        dispatch(setMasterNotificationTypesList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterNotificationTypesList(false));
    }
};

export const fetchMasterNotificationTypes =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterNotificationTypesForm(true));
            const response = await catalogAPI.getMasterNotificationType(id);
            dispatch(setMasterNotificationTypesForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterNotificationTypesForm(false));
        }
    };

export const postToMasterNotificationTypesForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterNotificationTypesForm;

        try {
            dispatch(setLoadingMasterNotificationTypesForm(true));
            if (form.id) {
                await catalogAPI.putMasterNotificationTypes(form);
            } else {
                await catalogAPI.postMasterNotificationTypes(form);
            }
            dispatch(fetchMasterNotificationTypesList());
            return true;
        } catch (error) { dispatch(setLoadingMasterNotificationTypesForm(false));
            throwErrorToastrAsync(error);
            return false;
        }
    };
};

export const patchSortNotificationTypesList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterNotificationTypesList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterNotificationTypesList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterNotificationTypesList(false));
        }
    };
