import { useTheme } from "react-jss";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
import { useAppSelector } from "../../../../../../redux/hooks";
import { EditRequestDocumentContainer } from "./document/container";
import { EditRequestImageContainer } from "./image/container";
import { EditRequestTabDocumentsContainerStyle } from "./container.jss";
interface IEditRequestTabDocumentsContainerProps {
    schema: IConfigEditRequest;
}
export const EditRequestTabDocumentsContainer: React.FC<IEditRequestTabDocumentsContainerProps> = ({ schema }) => {
    const theme = useTheme();
    const style = EditRequestTabDocumentsContainerStyle({ theme });
    const requestId = useAppSelector((store) => store.editRequest.headerRequest.id);

    return (
        <div className={style.container}>
            <EditRequestDocumentContainer
                schema={schema}
                requestId={requestId}
            />
            <EditRequestImageContainer
                schema={schema}
                requestId={requestId}
            />
        </div>
    );
};
