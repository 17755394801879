import { Method } from "../../../common/enum";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/processes`;

export class ManagementProcessesApi {
    async postSendPoisonEmails(): Promise<any> {
        const url = `${baseURL}/sendpoisonemails`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({}).execute<any>();
    }

    async postClearCache(): Promise<any> {
        const url = `${baseURL}/clearcache`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody({}).execute<any>();
    }

    async deleteClearInspectionCardLocks(): Promise<any> {
        const url = `${baseURL}/inspectioncardlocks`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<any>();
    }
}
