import { DatePicker, DayOfWeek, ICalendarProps, IDatePicker, IDatePickerProps } from "@fluentui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "react-jss";
import { HookFormProps } from "../../../models";
import { DayPickerStringsES, onFormatDate } from "./datePicker";
import { DatePickerStyled } from "./datePicker.jss";

export interface CustomProps {
    initValue?: Date;
    minDate?: Date;
    maxDate?: Date;
    onDateChange: (date: Date | undefined | null, name: string) => void;
    name: string;
    required?: boolean;
    hasError?: boolean;
    calendarProps?: ICalendarProps;
}

export const ControlledDatePicker: FC<HookFormProps & IDatePickerProps & CustomProps> = (props) => {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const datePickerRef = useRef<IDatePicker>(null);
    const isFirstRender = useRef<boolean>(true);
    const theme = useTheme();

    const datePickerStyles = DatePickerStyled({ theme, hasValue: !!selectedDate, disabled: props.disabled });

    const { initValue, minDate, maxDate, onDateChange, name } = props;

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            initValue && setSelectedDate(initValue);
        } else {
            setSelectedDate(initValue);
        }
    }, [initValue]);

    const onParseDateFromString = useCallback(
        (newValue: string): Date => {
            const previousValue = selectedDate || new Date();
            const newValueSplitted = (newValue || "").trim().split("/");
            const inferredDay = newValueSplitted.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueSplitted[0], 10))) : previousValue.getDate();
            const inferredMonth =
                newValueSplitted.length > 1 ? Math.max(1, Math.min(12, parseInt(newValueSplitted[1], 10))) - 1 : previousValue.getMonth();

            const year =
                newValueSplitted.length === 3 && newValueSplitted[2].length === 4 ? parseInt(newValueSplitted[2], 10) : previousValue.getFullYear();
            const newDate = new Date(year, inferredMonth, inferredDay);

            if (minDate && minDate > newDate) {
                return minDate;
            }

            if (maxDate && maxDate < newDate) {
                return maxDate;
            }
            return newDate;
        },
        [maxDate, minDate, selectedDate],
    );

    const onSelectDate = (date: Date | undefined | null): void => {
        if (date === null) {
            date = undefined;
        }
        setSelectedDate(date);
        onDateChange(date, name);
    };

    return (
        <div className={datePickerStyles.datePickerContainer}>
            <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={initValue || selectedDate}
                render={({ field: { onChange, onBlur, name: fieldName }, fieldState: { error } }) => (
                    <DatePicker
                        {...props}
                        value={initValue}
                        onBlur={onBlur}
                        textField={{
                            name: fieldName,
                            errorMessage: error && error.message,
                        }}
                        onSelectDate={(date) => {
                            onSelectDate(date);
                            onChange(date);
                        }}
                        calendarProps={props.calendarProps}
                        formatDate={onFormatDate}
                        parseDateFromString={onParseDateFromString}
                        className={datePickerStyles.customStyles}
                        strings={DayPickerStringsES}
                        firstDayOfWeek={DayOfWeek.Monday}
                        disabled={props.disabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        isRequired={props.required}
                        componentRef={datePickerRef}
                        label={props.label}
                        ariaLabel="Select a date. Input format is day slash month slash year."
                    />
                )}
            />
        </div>
    );
};
