import { ManagementSummaryApi } from "../../../../api/management/summary";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { formatDate, formatHour, throwErrorToastrAsync } from "../../../../utils";
import { setLoadingManagementSummaryForm } from "../../../reducers/management/summary/form";
import { setLoadingManagementSummaryList, setManagmentSummaryList, setPage } from "../../../reducers/management/summary/list";
import { AppThunk, store } from "../../../store";

const managementSummaryApi = new ManagementSummaryApi();

export const fetchManagementSummaryListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().managementSummaryFilter;
        const { pagination } = store.getState().managementSummaryList;
        dispatch(setLoadingManagementSummaryList(true));
        const response = await managementSummaryApi.getManagementSummaryListAsync(filter, pagination.currentPage, pagination.pageSize);
        dispatch(setManagmentSummaryList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingManagementSummaryList(false));
    }
};
export const postManagementSummaryAction = (): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementSummaryForm(true));
            await managementSummaryApi.postMasterSummaryAsync(mapManagementSummaryForm());
            dispatch(fetchManagementSummaryListAction());

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingManagementSummaryForm(false));
            return false;
        }
    };
};

export const deleteManagementSummaryAction = (): any => {
    return async (dispatch: any) => {
        dispatch(setLoadingManagementSummaryForm(true));

        try {
            const { form } = store.getState().managementSummaryForm;
            await managementSummaryApi.removeManagementSummaryAsync(form.id ?? 0);
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchManagementSummaryListAction());
            dispatch(setLoadingManagementSummaryForm(false));
        }
    };
};
export const setPageAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        dispatch(setPage(page));
    };

const mapManagementSummaryForm = () => {
    const { form } = store.getState().managementSummaryForm;
    let date = new Date(formatDate(form.date, FormatDate.ISO));
    let hour = formatHour(form.hour);
    date.setHours(Number(hour.substring(0, 2)));
    date.setMinutes(Number(hour.substring(3, 5)));

    return {
        summaryNumber: form.summaryNumber,
        activatedOn: date,
    };
};
