import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const FormDraftContainerStyle = createUseStyles((theme: any) => ({
    requestContainer: {
        display: "flex",
        width: "90vw",
        height: "95vh",

        [mediaQueries.MOBILE]: {
            width: "95vw",
            height: "95vh",
        },
    },
    stepperContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        maxHeight: "95vh",
        height: "95vh",
        overflow: "auto",
    },

    gridContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 35,
        padding: 32,
    },
    section: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
    },
    titleWhithAction: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        alignItems: "center",
    },
    inspectionDataTitle: {
        display: "flex",
        gap: 20,
        flexDirection: "row",
        alignItems: "center",
    },
    inspectionSection: {
        display: "flex",

        flexDirection: "column",
    },
    inspectionButtonRow: {
        display: "flex",
        flexDirection: "row",
        columnGap: 8,
    },

    inspectionCards: {
        flexFlow: "row wrap",
        display: "flex",
        flexDirection: "row",
        columnGap: 24,
        rowGap: theme.gaps.sizes.xl,
    },
    messageSpace: {
        marginTop: 10,
    },
}));
