import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../assets/icons";
import { SkeletonSizes, componentsMediaQueries } from "../../../../../../../../common/constants";
import { FormatDate } from "../../../../../../../../common/enum/dateTime/FormatDate";
import { IConfigEditRequest } from "../../../../../../../../models/requests/IConfigEditRequest";
import { IActuation, IInspection } from "../../../../../../../../models/requests/IEditRequest";
import {
    deleteEditRequestInspection,
    getEditRequestInspectionForm,
    postEditRequestInspection,
    fetchEditRequestInspection,
} from "../../../../../../../../redux/actions/request/edit/action/inspection";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import {
    setEditRequestActuationPopUp,
    updateActuationFormProperty,
    updateOrganismId,
} from "../../../../../../../../redux/reducers/request/edit/form/actuation";
import { setEditRequestInspectionShowPopUp } from "../../../../../../../../redux/reducers/request/edit/form/inspection";
import { convertToLocal } from "../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../common/separator/separator";
import { Skeleton } from "../../../../../../../common/skeleton/skeleton";
import { CustomTableActions } from "../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../common/tableCustom/tableCustom";
import { isShowAddActuation, isShowAddMultipleIcon } from "../../../../../../common/validation/inspections";
import { EditRequestGeneralInformationEditRequestInspectionsActuationList } from "../actuation/list";
import { RequestInspectionBuubbleInspector } from "./bubbleInspector/bubbleInspector";
import { PopUpConfirmation } from "../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { widths } from "../../../../../../../../styles/romeu/widths";
import { PopUpCodes } from "../../../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../../../common/enum/PopUpConfirmationType";
import { RoleType } from "../../../../../../../../common/enum";
interface IEditRequestGeneralInformationEditRequestInspectionsListProps {
    schema: IConfigEditRequest;
    requestId: number;
    invalidRequest: boolean;
    showFormActuationModal: (value: any) => any;
    showDeleteActuationModal: (value: any) => any;
}

export const EditRequestGeneralInformationEditRequestInspectionsList: React.FC<IEditRequestGeneralInformationEditRequestInspectionsListProps> = ({
    schema,
    requestId,
    invalidRequest,
    showFormActuationModal,
    showDeleteActuationModal
}) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();
    const { inspections, loading } = useAppSelector((store) => store.editRequest.requestInspection);
    const { roles } = useAppSelector((store) => store.userData.profile);
    const requestDockStartOn = useAppSelector((state) => state.editRequest.headerRequest.dockStartOn);
    const requestDockEndOn = useAppSelector((state) => state.editRequest.headerRequest.dockEndOn);
    const { organizations } = useAppSelector((state) => state.userData.profile);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    const [showWarningRemoveInspection, setShowWarningRemoveInspection] = useState<boolean>(false);
    const [showWarningRemoveHasSampleStorageData, setShowWarningRemoveHasSampleStorageData] = useState<boolean>(false);
    const [removedInspectionId, setRemovedInspectionId] = useState<number>(0);
    const [showAlertLastInspection, setShowAlertLastInspection] = useState<boolean>(false);
    const [showNotDockEntryOn, setShowNotDockEntryOn] = useState<boolean>(false);
    const [errorNotAssingInspector, setErrorNotAssingInspector] = useState<string>("");
    const [tempInspection, setTempInspection] = useState<IInspection | null>(null);
    const [widthPopUpWarning, setWidthPopUpWarning] = useState<string>("auto");
    const [showCancelButton, setShowCancelButton] = useState<boolean>(false);

    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    const customerEditInspection = useMemo(() => {
        return roles.find((item) => item.id === RoleType.CUSTOMER_ADMIN || item.id === RoleType.CUSTOMER_USER) ? true : false;
    }, [roles]);

    const ButtonOfAddActuationAllInspction = useMemo(() => {
        if (!schema.actuation_create.visible || !isShowAddMultipleIcon([], inspections)) {
            return undefined;
        }

        return (
            <CustomIcons
                iconName={NameIcons.ADDRED}
                onClick={() => {
                    dispatch(setEditRequestActuationPopUp(PopUpCodes.CREATE));
                    dispatch(updateActuationFormProperty({ name: "isMultiple", value: true }));
                    showFormActuationModal(true);
                }}
                isButton={true}
                tooltip={t("TOOLTIP.CREATE_MULTIPLE_ACTUATIONS")}
                position={"end"}
                breackResponse={componentsMediaQueries.TABLETPORTRAIT}
                isDisabled={invalidRequest}
            />
        );
    }, [invalidRequest, schema.actuation_create.visible, isShowAddMultipleIcon([], inspections)]);

    const ButtonAssignArriveTimeOfInspectorIfWhitNotHaveDateAssign = (date: any, inspection: IInspection) => {
        if (date) {
            return (
                <div>
                    <>{date}</>
                </div>
            );
        }

        return (
            <CustomTableActions
                position="start"
                children={
                    <CustomIcons
                        iconName={NameIcons.CLOCK}
                        onClick={() => setArrivedInspectorTimeInspection(inspection)}
                        isButton={!schema.inspection_inspectorArrivedOn.readonly}
                        tooltip={t("TOOLTIP.ARRIVE_INSPECTOR")}
                        margin={"0px"}
                        position={"start"}
                        isDisabled={schema.inspection_inspectorArrivedOn.readonly}
                    />
                }
            />
        );
    };

    const setArrivedInspectorTimeInspection = (inspection: IInspection) => {
        let newDate = new Date();
        let dockEndOn = new Date(requestDockEndOn);

        if (!requestDockStartOn) {
            setShowNotDockEntryOn(true);
            setErrorNotAssingInspector(t("INFO.NOT_DOCK_ENTRY_ON"));
            setWidthPopUpWarning("auto");
        } else if (newDate > dockEndOn && requestDockEndOn) {
            setTempInspection(inspection);
            setShowNotDockEntryOn(true);
            setErrorNotAssingInspector(
                t("INFO.MIN_DOCK_EXIT_ON", {
                    dockEndOn: convertToLocal(dockEndOn, FormatDate.DATE_HOUR_MINUTE),
                    interpolation: { escapeValue: false },
                }),
            );
            setShowCancelButton(true);
            setWidthPopUpWarning("50vw");
        } else {
            let date: any = new Date().toISOString();

            const updateInspection: IInspection = {
                ...inspection,
                inspectorArrivedOn: date,
            };
            dispatch(postEditRequestInspection(requestId, updateInspection));
        }
    };

    const onAceptRemoveWarningInpectionItem = () => {
        if (removedInspectionId > 0) {
            dispatch(deleteEditRequestInspection(requestId, removedInspectionId, organization?.preappointment));
            onCancelRemoveWarningInpectionItem();
        }
    };

    const onCancelRemoveWarningInpectionItem = () => {
        setShowWarningRemoveInspection(false);
        setShowWarningRemoveHasSampleStorageData(false);
    };

    const columns: ColumnType<IInspection, any>[] = [
        { key: "organismDescription", title: t("FIELDS.ORGANISMDESCRIPTION"), showColumn: schema.inspection_organismId.visible },
        { key: "certificateTypeDescription", title: t("FIELDS.CERTIFICATETYPEDESCRIPTION"), showColumn: schema.inspection_certificateTypeId.visible },
        { key: "certificateNumber", title: t("FIELDS.DOCUMENT_NUMBER"), showColumn: schema.inspection_certificateNumber.visible },
        { key: "vuaOrganismCode", title: t("FIELDS.VUA"), showColumn: true },
        { key: "importer", title: t("FIELDS.IMPORTER"), showColumn: schema.inspection_importer.visible },
        { key: "exporter", title: t("FIELDS.EXPORTER"), showColumn: schema.inspection_exporter.visible },
        { key: "year", title: t("FIELDS.YEAR"), showColumn: schema.inspection_year.visible, width: "13%", padding: "15px" },
        { key: "inspectorId", title: t("FIELDS.INSPECTORID"), showColumn: schema.inspection_year.visible },
        {
            key: "inspectorArrivedOn",
            title: t("FIELDS.HOUR_INSPECTOR_ARRIVED_ON"),
            showColumn: schema.inspection_inspectorArrivedOn.visible,
            width: "10%",
        },
        {
            key: "actions",
            title: "",
            showColumn:
                (schema.actuation_create.visible && isShowAddMultipleIcon([], inspections)) ||
                schema.actuation_create.visible ||
                schema.inspection_update.visible ||
                schema.inspection_delete.visible,
            className: "",
            width: "300px",
            padding: "0px",
            actions: ButtonOfAddActuationAllInspction,
        },
    ];

    const newInspectionTable = inspections.map((inspection) => {
        const countOrganism = inspection.hasSampleStorageData
            ? inspections.filter((item) => item.organismId === inspection.organismId && inspection.hasSampleStorageData)
            : null;
        let isShowActions = !schema.actuation_create.visible && !schema.inspection_update.visible && !schema.inspection_delete.visible ? false : true;
        let editAction = customerEditInspection && inspection.actuations.length > 0 ? false : schema.inspection_update.visible;
        let date = !isNaN(new Date(inspection.inspectorArrivedOn!).valueOf())
            ? convertToLocal(inspection.inspectorArrivedOn, FormatDate.DATE_HOUR_MINUTE_INVERSE)
            : "";

        let row = {
            ...inspection,
            organismDescription: inspection.organismDescription,
            certificateTypeDescription: inspection.certificateTypeDescription,
            certificateNumber: inspection.certificateNumber,
            vuaOrganismCode: inspection.vuaOrganismCode,
            importer: inspection.importer,
            exporter: inspection.exporter,
            actuations: inspection.actuations,
            year: inspection.year,
            inspectorId: <RequestInspectionBuubbleInspector inspection={inspection} />,
            inspectorArrivedOn: ButtonAssignArriveTimeOfInspectorIfWhitNotHaveDateAssign(date, inspection),
            actions: !isShowActions ? null : (
                <CustomTableActions
                    breackResponse={componentsMediaQueries.TABLETPORTRAIT}
                    children={
                        <div>
                            {schema.actuation_create.visible && isShowAddActuation(inspection.organismId) && (
                                <CustomIcons
                                    iconName={NameIcons.ADDBLUE}
                                    onClick={() => {
                                        dispatch(updateOrganismId(inspection.organismId ? inspection.organismId : 0));
                                        dispatch(setEditRequestActuationPopUp(PopUpCodes.CREATE));
                                        dispatch(updateActuationFormProperty({ name: "inspectionId", value: inspection.id }));
                                        showFormActuationModal(true);
                                    }}
                                    isDisabled={invalidRequest}
                                    isButton={true}
                                    tooltip={t("TOOLTIP.CREATE_ACTUATION")}
                                    breackResponse={componentsMediaQueries.TABLETPORTRAIT}
                                />
                            )}

                            {schema.actuation_create.visible && editAction && <Separator size="auto" />}

                            {editAction && (
                                <CustomIcons
                                    iconName={NameIcons.EDIT}
                                    onClick={() => {
                                        dispatch(setEditRequestInspectionShowPopUp(PopUpCodes.UPDATE));
                                        dispatch(getEditRequestInspectionForm(requestId, inspection.id));
                                    }}
                                    isButton={true}
                                    tooltip={t("TOOLTIP.UPDATE_INSPECTION")}
                                    breackResponse={componentsMediaQueries.TABLETPORTRAIT}
                                />
                            )}
                            {schema.inspection_update.visible && schema.inspection_delete.visible && <Separator size="auto" />}

                            {schema.inspection_delete.visible && (
                                <CustomIcons
                                    breackResponse={componentsMediaQueries.TABLETPORTRAIT}
                                    iconName={NameIcons.REMOVE}
                                    onClick={() => {
                                        setRemovedInspectionId(inspection.id);
                                        if (inspections.length <= 1) {
                                            setShowAlertLastInspection(true);
                                        } else {
                                            if (countOrganism !== null && countOrganism.length <= 1) {
                                                setShowWarningRemoveHasSampleStorageData(true);
                                            } else if (inspection.hasSampleStorageData) {
                                                setShowWarningRemoveInspection(true);
                                            } else {
                                                setShowWarningRemoveInspection(true);
                                            }
                                        }
                                    }}
                                    isButton={true}
                                    tooltip={t("TOOLTIP.REMOVE_INSPECTION")}
                                />
                            )}
                        </div>
                    }
                />
            ),
        };

        return row;
    });

    const PrintTableIfInspectionContainActuation = (value: any) => {
        if (value.actuations && value.actuations.length && schema.actuation_instructions) {
            const actuationList: IActuation[] = value.actuations;
            return (
                <EditRequestGeneralInformationEditRequestInspectionsActuationList
                    actuation={actuationList}
                    schema={schema}
                    requestId={requestId}
                    requestInspectionColumnsShow={columns.filter((item) => item.showColumn).length}
                    invalidRequest={invalidRequest}
                    showFormActuationModal={showFormActuationModal}
                    showDeleteActuationModal={showDeleteActuationModal}
                />
            );
        }
        return null;
    };

    useEffect(() => {
        if (!inspections || inspections.length === 0) {
            dispatch(fetchEditRequestInspection(requestId));
        }
    }, []);

    return (
        <>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomTableList
                    data={newInspectionTable}
                    columns={columns}
                    loading={loading}
                    underActions={PrintTableIfInspectionContainActuation}
                    breackResponse={componentsMediaQueries.TABLETPORTRAIT}
                />
            )}

            <PopUpConfirmation
                showPopUp={showWarningRemoveInspection || showWarningRemoveHasSampleStorageData}
                onCancel={onCancelRemoveWarningInpectionItem}
                onAccept={onAceptRemoveWarningInpectionItem}
                type={PopUpConfirmationType.DELETE}
                width={showWarningRemoveHasSampleStorageData ? "50vw" : widths.confirmPopUp.remove}
                message={showWarningRemoveHasSampleStorageData ? <Trans i18nKey={"EDIT_REQUEST.INFO.HAS_STORAGE_SAMPLE_DATA"} /> : null}
                height={"auto"}
            />

            <PopUpConfirmation
                showPopUp={showAlertLastInspection}
                onCancel={() => {
                    setShowAlertLastInspection(false);
                }}
                onAccept={() => {
                    setShowAlertLastInspection(false);
                }}
                type={PopUpConfirmationType.INFO}
                hiddeCancelButon={true}
                width={"50vw"}
                message={t("INFO.LAST_INSPECTION")}
                height={"auto"}
            />

            <PopUpConfirmation
                showPopUp={showNotDockEntryOn}
                onCancel={() => {
                    setShowNotDockEntryOn(false);
                    setErrorNotAssingInspector("");
                    setShowCancelButton(false);
                    setTempInspection(null);
                }}
                onAccept={() => {
                    let newDate = new Date();
                    let dockEndOn = new Date(requestDockEndOn);
                    if (newDate > dockEndOn && tempInspection) {
                        const updateInspection: IInspection = {
                            ...tempInspection,
                            inspectorArrivedOn: dockEndOn,
                        };
                        dispatch(postEditRequestInspection(requestId, updateInspection));
                    }
                    setShowNotDockEntryOn(false);
                    setErrorNotAssingInspector("");
                    setShowCancelButton(false);
                    setTempInspection(null);
                }}
                type={PopUpConfirmationType.INFO}
                hiddeCancelButon={!showCancelButton}
                width={widthPopUpWarning}
                message={errorNotAssingInspector}
                height={"auto"}
            />
        </>
    );
};
