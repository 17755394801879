import { useCallback, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import CookieService from "../../../services/cookieService";
import { ButtonPrimary, ButtonPrimaryInverse } from "../buttons/buttons";
import { CustomIcons } from "../customIcons/customIcons";
import { CustomToogle } from "../toogle/toogle";
import { CookiesContentStyles } from "./cookiesContent.jss";
import { CookieLink } from "./cookiesLink";
import { useTranslation } from "react-i18next";

interface ICookiesProps {}

export const Cookies: React.FC<ICookiesProps> = ({}) => {
    const theme = useTheme();
    const { t } = useTranslation("common", { keyPrefix: "COOKIES" });
    const styles = CookiesContentStyles({ theme });
    const [configureCookies, setConfigureCookies] = useState<boolean>(false);
    const [accept, setAccept] = useState<boolean>(true);

    useEffect(() => {
        const cookie = CookieService.get("accepted_cookie");
        setTimeout(() => {
            if (cookie) {
                setAccept(true);
            } else {
                document.body.scrollTo(0, 0);
                document.body.style.overflow = "hidden";
                setAccept(false);
            }
        }, 0);
    }, [accept]);

    const onAcceptCookie = useCallback(() => {
        CookieService.set("accepted_cookie", "true", { sameSite: "Strict", path: "/", expires: new Date(2147483647 * 1000) });
        document.body.style.overflow = "";
        setAccept(true);
    }, []);

    return !accept ? (
        <>
            <div className={styles.backdrop}></div>
            <div className={styles.container}>
                <div className={styles.header}>
                    {!configureCookies && <CookieLink />}
                    {configureCookies && <p>{t("CONFIG_TITLE")}</p>}
                </div>
                <div className={styles.line}></div>
                {configureCookies && (
                    <div className={styles.contentConfiguration}>
                        <div>{t("CONTENT_CONFIGURATION")}</div>
                        <div className={styles.configuration}>
                            <CustomToogle
                                checked
                                onText={t("CONTENT_ACCEPT")}
                                onChage={() => null}
                                disabled={true}
                                inlineLabel={true}
                                label={t("CONTENT_COOKIES_TEC")}
                            />
                            {t("CONTENT_COOKIES_DESCRIPTION")}
                        </div>
                    </div>
                )}
                {!configureCookies && (
                    <div className={styles.content}>
                        <div>
                            <CustomIcons
                                iconName="InfoSolid"
                                size={"1.2rem"}
                                isButton={false}
                                margin={"0px"}
                                className={styles.infoIcon}
                                position="start"
                                disabledSelect={true}
                            />
                        </div>
                        <div>{t("COOKIES_LEGAL")}</div>
                    </div>
                )}
                <div className={styles.buttons}>
                    <ButtonPrimaryInverse
                        title={!configureCookies ? t("CONFIG") : t("BACK")}
                        handleClick={() => setConfigureCookies(!!!configureCookies)}
                    />
                    <ButtonPrimary
                        title={t("ACCEPT")}
                        handleClick={onAcceptCookie}
                    />
                </div>
            </div>
        </>
    ) : (
        <></>
    );
};
