import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementDockQueueRuleContainerStyle } from "./containerStyle.jss";
import { ManagementParkingValidationRuleList } from "./list/list";

import { useEffect } from "react";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { getDockQueueRuleConfiguration } from "../../../redux/actions/management/dockQueueRule/dockQueueRule";
import { setManagementDockQueueRuleFormPopUp } from "../../../redux/reducers/management/dockQueueRule/form";
import { ManagementParkingValidationRuleFormController } from "./controller/controller";

export const ManagementDockQueueRuleContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.DOCK_QUEUE_RULE" });
    const theme = useTheme();
    const style = ManagementDockQueueRuleContainerStyle({ theme });
    const { view } = useAppSelector((state) => state.managementDockQueueRuleConfiguration);

    const dispatch = useAppDispatch();

    useEffect(() => {
        view === undefined && dispatch(getDockQueueRuleConfiguration());
    }, [view]);

    return (
        <div className={style.generalContainer}>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setManagementDockQueueRuleFormPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />

            <ManagementParkingValidationRuleList />
            <ManagementParkingValidationRuleFormController />
        </div>
    );
};
