import { AppointmentsAPI } from "../../../api/appointments";
import { throwErrorToastrAsync } from "../../../utils";
import { setGlobalLoading } from "../../reducers/common/globalLoading";
import { setLoadingRequestUpcomingAppointmentsSortList, setRequestUpcomingAppointmentsSortList } from "../../reducers/upcomingAppointmentsSort/list";
import { AppThunk, store } from "../../store";

const appointmentsApi = new AppointmentsAPI();

export const fetchRequestAppointmentsUpcomingSortListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().upcomingAppointmentsSortFilter;

        dispatch(setLoadingRequestUpcomingAppointmentsSortList(true));
        const response = await appointmentsApi.getUpcomingAppointmentsSortList(filter);
        dispatch(setRequestUpcomingAppointmentsSortList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingRequestUpcomingAppointmentsSortList(false));
    }
};
export const exportRequestAppointmentsUpcomingSortListAction = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().upcomingAppointmentsSortFilter;

        dispatch(setGlobalLoading(true));
        const response = await appointmentsApi.getExportUpcomingAppointmentsSortList(filter);
        window.open(response);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoading(false));
    }
};
