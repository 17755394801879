import { createUseStyles } from "react-jss";

export const StateHistoryItemStyles = createUseStyles((theme: any) => ({
    container: {
        width: theme.widths.sectionContainer.complete,
        display: "flex",
        alignItems: "center",
        borderBottom: theme.borders.table.body.border,
        "& > div ": {
            width: "20%",
            wordBreak: "break-all",
            display: "flex",
            fontWeight: theme.fonts.weight.normal,
        },
        "& .state ": {
            fontWeight: theme.fonts.weight.bold,
        },
    },
    unactive: {
        color: theme.colors.site.warning,
        paddingTop: "12px !important",
        paddingBottom: "12px !important",
    },
    datePicker: {
        width: 110,
        marginTop: 3,
        "& .ms-TextField-fieldGroup": {
            minWidth: 110,
        },
        "& .ms-TextField-field": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.table.body.color,
            paddingTop: 2,
            paddingLeft: 0,
        },
    },
    timePicker: {
        width: 45,
        marginTop: -10,
        "& .MuiInput-root": {
            fontSize: theme.fonts.sizes.base,
            color: `${theme.colors.table.body.color} !important`,
        },
        "& .MuiInput-root:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
            cursor: "pointer !important",
            pointerEvents: "all",
        },
        "& .MuiFormLabel-root": {
            fontSize: "0.8rem !important",
        },
        "& .MuiInput-root:after": {
            borderBottom: "1px solid #7367f0 !important",
        },
        "& .MuiInput-root > input": {
            color: `${theme.colors.table.body.color} !important`,
        },
        "& input": {
            color: `${theme.colors.table.body.color} !important`,
        },
    },
}));
