import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterDisplayDocksList } from "./list/list";
import { setDocks } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterDisplayDocksList } from "../../../../../redux/reducers/resources/master/docks/list";

export class MasterDisplayDocksContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterDisplayDocksList());
        store.dispatch(setDocks([]));
    }

    render(): React.ReactNode {
        return (
            <MasterDisplayDocksList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
