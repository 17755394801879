import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IManagementRequestDatePermissionForm } from "../../../../models/management/requestdatepermission/IRequestdatepermission";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface IManagementRequestDatePermissionFormState {
    form: IManagementRequestDatePermissionForm;
    showPopUp: PopUpCodes;
    loading: boolean;
}

const initialState: IManagementRequestDatePermissionFormState = {
    form: {
        referenceDayId: 0,
        organismId: 0,
        time: "",
        transportUnitId: 0,
        workDays: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        weekDays: [-1],
    },
    showPopUp: PopUpCodes.NONE,
    loading: false,
};

export const managementRequestDatePermissionFormSlice = createSlice({
    name: "managementRequestDatePermissionFormSlice",
    initialState,
    reducers: {
        resetManagementRequestDatePermissionState: () => initialState,
        resetManagementRequestDatePermissionForm: (state) => {
            state.form = initialState.form;
        },
        setLoadingManagementRequestDatePermissionForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateRequestDatePermissionHeaderPropertyForm: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setManagementRequestDatePermissionForm: (state, action: PayloadAction<IManagementRequestDatePermissionForm>) => {
            state.form = action.payload;
        },
        setManagementRequestDatePermissionShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetManagementRequestDatePermissionState,
    setLoadingManagementRequestDatePermissionForm,
    updateRequestDatePermissionHeaderPropertyForm,
    setManagementRequestDatePermissionForm,
    setManagementRequestDatePermissionShowPopUp,
    resetManagementRequestDatePermissionForm,
} = managementRequestDatePermissionFormSlice.actions;

export default managementRequestDatePermissionFormSlice.reducer;
