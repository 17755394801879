import React from "react";
import { store } from "../../../redux/store";
import { resetInvoiceSummaryByConcept } from "../../../redux/reducers/invoicing/summaryByConcept/list";
import { resetInvoicingSummaryConceptFilter } from "../../../redux/reducers/invoicing/summaryByConcept/filter";
import { InvoicingSummaryByConceptContainer } from "./container";

export class InvoicingSummaryByConceptController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetInvoiceSummaryByConcept());
        store.dispatch(resetInvoicingSummaryConceptFilter());
    }

    render(): React.ReactNode {
        return <InvoicingSummaryByConceptContainer />;
    }
}
