import { createUseStyles } from "react-jss";

export const VlcPortFinishedRequestContainerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection:"column",
        width: "100%",
        gap: theme.gaps.sizes.base,
    },
   codeContainer : {
    width:"99%"
   }
}));
