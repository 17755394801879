import { Method } from "../../common/enum";
import { ICustomersStatisticsState } from "../../models/statistics/ICustomer";
import { IDailyChargeStatistics } from "../../models/statistics/IDailyCharge";
import { IStatisticsSearchFilter } from "../../models/statistics/IStatisticsFilters";
import { IStatisticsInspectionAccumulativeMonthState } from "../../models/statistics/inspection/IAccumulated";
import { IStatisticsInspectionDetailedState } from "../../models/statistics/inspection/IDetailed";
import { IStatisticsInspectionGroupedState } from "../../models/statistics/inspection/IGrouped";
import { IMonthlySummaryState } from "../../models/statistics/IMonthlySummary";
import { IStatisticsOperatorIncidenceState } from "../../models/statistics/IOperator";
import { IStatisticsOrganismsIncidenceState } from "../../models/statistics/IOrganism";
import { IStatisticsReaderRecordRatio } from "../../models/statistics/IReaderRecordRatio";
import { apiFetchOAuthWithClaims } from "../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/statistics`;

export class StatiticsApi {
    async getStatiticsCustomerAsync(filters: IStatisticsSearchFilter): Promise<ICustomersStatisticsState> {
        const url = `${baseURL}/customers`;

        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<ICustomersStatisticsState>();
    }
    async statisticsCustomersExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/customers/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
    async getStatiticsIncidenceOrganismAsync(filters: IStatisticsSearchFilter): Promise<IStatisticsOrganismsIncidenceState> {
        const url = `${baseURL}/incidences/organism`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IStatisticsOrganismsIncidenceState>();
    }

    async statisticsIncidenceOrganismExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/incidences/organism/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }

    async getStatiticsIncidenceOperatorAsync(filters: IStatisticsSearchFilter): Promise<IStatisticsOperatorIncidenceState> {
        const url = `${baseURL}/incidences/operator`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IStatisticsOperatorIncidenceState>();
    }

    async statisticsIncidenceOperatorExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/incidences/operator/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
    async getStatiticsMonthlySummaryAsync(filters: IStatisticsSearchFilter): Promise<IMonthlySummaryState> {
        const url = `${baseURL}/monthlysummary`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IMonthlySummaryState>();
    }

    async statisticsMonthlySummaryExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/monthlysummary/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }

    async getStatisticsInspectionAgrupedByMonth(filters: IStatisticsSearchFilter): Promise<IStatisticsInspectionAccumulativeMonthState> {
        const url = `${baseURL}/inspections/monthlycumulative`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IStatisticsInspectionAccumulativeMonthState>();
    }

    async statisticsInspectionAgrupedByMonthExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/inspections/monthlycumulative/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }

    async getStatisticsInspectionDetailed(filters: IStatisticsSearchFilter): Promise<IStatisticsInspectionDetailedState> {
        const url = `${baseURL}/inspections/detailed`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IStatisticsInspectionDetailedState>();
    }
    async statisticsInspectionDetailedExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/inspections/detailed/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
    async getStatisticsInspectionGrouped(filters: IStatisticsSearchFilter): Promise<IStatisticsInspectionGroupedState> {
        const url = `${baseURL}/inspections/grouped`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IStatisticsInspectionGroupedState>();
    }
    async statisticsInspectionGroupedExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/inspections/grouped/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
    async getStatisticsDailyCharge(filters: IStatisticsSearchFilter): Promise<IDailyChargeStatistics[]> {
        const url = `${baseURL}/dailycharge`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IDailyChargeStatistics[]>();
    }
    async statisticsDailyChargeExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/dailycharge/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
    async getStatisticsReaderRecordRatio(filters: IStatisticsSearchFilter): Promise<IStatisticsReaderRecordRatio[]> {
        const url = `${baseURL}/readerrecordratio`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<IStatisticsReaderRecordRatio[]>();
    }
    async statisticsReaderRecordRatioExport(filters: IStatisticsSearchFilter): Promise<string> {
        const url = `${baseURL}/readerrecordratio/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }

    async getStatisticsRequestGraph(organizationParam: string): Promise<any> {
        const url = organizationParam ? `${baseURL}/requestgraph/${organizationParam}` : `${baseURL}/requestgraph`;
        return organizationParam
            ? apiFetchOAuthWithClaims(Method.GET, url).withHeader("x-organization-identifier", organizationParam).execute<any>()
            : apiFetchOAuthWithClaims(Method.GET, url).execute<any>();
    }
}
