import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { MasterRequestStatesFormStyle } from "./formStyle.jss";
import React, { useCallback, useEffect, useMemo } from "react";
import { Selector } from "../../../../../../common/selectors/selector";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";

import { FormField } from "../../../../../../common/fields/fields";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { IDropdownOption } from "@fluentui/react";
import { fetchRequestStates } from "../../../../../../../redux/actions/catalog/catalog";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { updateMasterRequestStatesProperty } from "../../../../../../../redux/reducers/resources/master/requestStates/form";
import { IMasterRequestState, IMasterRequestStateRemaining } from "../../../../../../../models/resources/master/IRequestState";
import { RequestStatesSelector } from "../../../../../../common/selectors/controllerSelectors/requestStates/requestStatesSelector";

interface IMasterRequestStatesFormProps {
    showNotData: boolean;
    data: IMasterRequestStateRemaining[];
}

export const MasterRequestStatesForm: React.FC<IMasterRequestStatesFormProps> = ({ data, showNotData }) => {
    const theme = useTheme();
    const style = MasterRequestStatesFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { form } = useAppSelector((state) => state.masterRequestStatesForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);

    const { register, control } = useForm<IMasterRequestState>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateMasterRequestStatesProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );

    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateFormProperty(name, String(option.key));
            onUpdateFormProperty(compileNameOfProperty<IMasterRequestState>("description"), option.text);
        },
        [dispatch, data],
    );
    const onDropDownSelector = useCallback(
        (option: any, name: string) => {
            onUpdateFormProperty(name, option.key);
        },
        [dispatch, data],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    useEffect(() => {
        data === undefined && dispatch(fetchRequestStates());
    }, [dispatch, data]);

    const stateId = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return form.referenceId ?? 0;
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return form.id ?? 0;
        }

        return 0;
    }, [form, showPopUp]);
    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    return showNotData && showPopUp === PopUpCodes.CREATE ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterRequestState>("referenceId")}
                        title={t("FORM.REQUEST_STATES_SELECTOR")}
                        options={mapIDescriptionToDropDownOptions(data)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}
            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterRequestState>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.main}
                    propertyName={compileNameOfProperty<IMasterRequestState>("main")}
                    title={t("FORM.MAIN")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.internal}
                    propertyName={compileNameOfProperty<IMasterRequestState>("internal")}
                    title={t("FORM.INTERNAL")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>

            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.last}
                    propertyName={compileNameOfProperty<IMasterRequestState>("last")}
                    title={t("FORM.LAST_STATE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.dockAssignment}
                    propertyName={compileNameOfProperty<IMasterRequestState>("dockAssignment")}
                    title={t("FORM.DOCK_ASSIGNMENT")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>

            <div className={style.rowForm}>
                <RequestStatesSelector
                    control={control}
                    onChange={onDropDownSelector}
                    selectedKey={form.nextAppStateId ?? 0}
                    propertyName={compileNameOfProperty<IMasterRequestState>("nextAppStateId")}
                    title={t("FORM.NEXT_STATE")}
                    stateId={Number(stateId)}
                    disabled={disabledInput}
                />

                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterRequestState>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
