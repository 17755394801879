import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { resetUserSignFormAction } from "../../../redux/actions/user/signup/signup";
import { goSelectedStep } from "../../../redux/actions/user/signup/stepper";
import { useAppDispatch } from "../../../redux/hooks";
import { UserStepper } from "../../common/user/stepper/userStepper";
import { UserSignupDocumentTemplates } from "./documentTemplates/container";
import { UserEntitySignupDataContainer } from "./entity/container";
import { UserProfileSignupData } from "./profile/container";
import { UserSignupResumeContainer } from "./resume/container";
import { UserSignupContainerStyle } from "./containerStyle.jss";
import { UserSignupTypeContainer } from "./type/container";

export const UserSignupContainer = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const userSignupStyle = UserSignupContainerStyle();

    useEffect(() => {
        return () => {
            dispatch(goSelectedStep(1));
            dispatch(resetUserSignFormAction());
        };
    });

    const nodes = [
        {
            title: t("USER.SIGNUP.FIRSTSTEPTITLE"),
            subtitle: t("USER.SIGNUP.FIRSTSTEPSUB"),
            node: (
                <>
                    <UserSignupTypeContainer />
                </>
            ),
        },
        {
            title: t("USER.SIGNUP.SECONDSTEPTITLE"),
            subtitle: t("USER.SIGNUP.SECONDSTEPSUB"),
            node: (
                <>
                    <UserProfileSignupData />
                </>
            ),
        },
        {
            title: t("USER.SIGNUP.FOURTHSTEPTITLE"),
            subtitle: t("USER.SIGNUP.FOURTHSTEPSUB"),
            node: (
                <>
                    <UserEntitySignupDataContainer />
                </>
            ),
        },
        {
            title: t("USER.SIGNUP.THIRDSTEPTITLE"),
            subtitle: t("USER.SIGNUP.THIRDSTEPSUB"),
            node: (
                <>
                    <UserSignupDocumentTemplates />
                </>
            ),
        },
        {
            title: t("USER.SIGNUP.FIVESTEPTITLE"),
            subtitle: t("USER.SIGNUP.FIVETHSTEPSUB"),
            node: (
                <>
                    <UserSignupResumeContainer />
                </>
            ),
        },
    ];

    return (
        <>
            <div className={userSignupStyle.content}>
                <UserStepper
                    disableNavigation={true}
                    steps={nodes}
                />
            </div>
        </>
    );
};
