import { OrderColumn } from "../common/enum/OrderColumn";
import { ISortState } from "../models/common/ISortState";

export const onUpdateSortedColumnUtil = (column: string | string[], actualSort: ISortState): ISortState => {
    let payload: ISortState;

    let isEqualsOrder = (column: string | string[]) => {
        if (Array.isArray(column)) {
            if (Array.isArray(actualSort.column)) {
                return column.find((item) => item === actualSort.column[0]);
            }

            return column.find((item) => item === actualSort.column) ? true : false;
        }

        return column === actualSort.column ? true : false;
    };

    if (isEqualsOrder(column) && actualSort.orderColumn === OrderColumn.DESC) {
        payload = {
            column: "",
            orderColumn: "",
        };
    } else {

        let order =
            !isEqualsOrder(column) || actualSort.orderColumn === "" || actualSort.orderColumn === OrderColumn.DESC
                ? OrderColumn.ASC
                : OrderColumn.DESC;
        payload = {
            column: column,
            orderColumn: order,
        };
    }

    return payload;
};
