import { useTheme } from "react-jss";
import { useEffect, useState } from "react";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../../../assets/icons";
import { TitleH6 } from "../../../../../../common/titles/titles";
import { ColorType } from "../../../../../../../common/enum/ColorType";
import { Checkboxs } from "../../../../../../common/checkbox/checkbox";
import { SectionStyle } from "./sectionStyle.jss";
import { Bubble } from "../../../../../../common/bubble/bubble";
import { Icon } from "@fluentui/react";

interface ISection {
    title: string;
    value: any;
    showBubble?: boolean;
    showDisabledController?: boolean;
    disabledControllerLabel?: string;
    onChangeDisabledController?: (e: any, checked?: boolean) => any;
    sectionActive?: boolean;
}

export const Section: React.FC<ISection> = ({ title, value, showBubble, showDisabledController, onChangeDisabledController, sectionActive }) => {
    const [showSection, setShowSection] = useState<boolean>(true);
    const theme = useTheme();
    const style = SectionStyle({ theme, sectionActive, showDisabledController });

    useEffect(() => {
        if (sectionActive) {
            setShowSection(true);
        }

        if (!sectionActive && showDisabledController) {
            setShowSection(false);
        }
    }, [sectionActive]);
    return (
        <div className={style.container}>
            <div
                className={style.titleSection}
                onClick={() => {
                    if (!showDisabledController) {
                        setShowSection(!showSection);
                    }
                }}
            >
                {showDisabledController && (
                    <Checkboxs
                        defaultWidth={true}
                        handleChange={onChangeDisabledController}
                        name="active"
                        isChecked={sectionActive ?? false}
                    />
                )}
                <div className={style.titleFormContainer}>
                    {!showDisabledController && (
                        <CustomIcons
                            isDisabled={!sectionActive && showDisabledController}
                            iconName={showSection ? NameIcons.CHEVRONUP : NameIcons.CHEVRONDOWN}
                        />
                    )}
                    <span
                        onClick={() => {
                            let ev = {
                                target: {
                                    name: "active",
                                },
                            };
                            onChangeDisabledController && showDisabledController && onChangeDisabledController(ev, !sectionActive);
                        }}
                    >
                        <TitleH6
                            bold
                            title={title}
                            color={ColorType.black}
                        />
                    </span>

                    {showBubble && (
                        <Icon
                            iconName={"CircleFill"}
                            className={style.bubbleOrange}
                        />
                    )}
                </div>
            </div>
            {showSection && <div className={style.valueSection}>{value}</div>}
        </div>
    );
};
