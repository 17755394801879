import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";

import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import {
    deleteManagementDockQueueRuleAction,
    postAndPutManagementDockQueueRuleAction,
} from "../../../../redux/actions/management/dockQueueRule/dockQueueRule";
import { resetManagementDockQueueRuleForm, setManagementDockQueueRuleFormPopUp } from "../../../../redux/reducers/management/dockQueueRule/form";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { ManagementParkingValidationForm } from "./form/form";

export const ManagementParkingValidationRuleFormController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PARKING_VALIDATION_RULE" });
    const dispatch = useAppDispatch();
    const { showPopUp, loading, form } = useAppSelector((state) => state.managementDockQueueRuleForm);

    const onDimissed = () => {
        dispatch(resetManagementDockQueueRuleForm());
    };
    const onCancel = () => {
        dispatch(setManagementDockQueueRuleFormPopUp(PopUpCodes.NONE));
        onDimissed();
    };

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postAndPutManagementDockQueueRuleAction()).then((response: any) => {
                response && onCancel();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(deleteManagementDockQueueRuleAction()).then((response: any) => {
                response && onCancel();
            });
        }
    };
    const titlePopUp = useMemo(() => {
        switch (showPopUp) {
            case PopUpCodes.CREATE:
                return t("CREATE");
            case PopUpCodes.UPDATE:
                return t("UPDATE");
            default:
                return "";
        }
    }, [showPopUp]);

    const disabledForm = useMemo(() => !form.description, [form]);

    return (
        <>
            <PopUpForm
                content={<ManagementParkingValidationForm />}
                title={titlePopUp}
                isLoading={loading}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"calc(90vh - 7rem)"}
                width={"80vw"}
                leftButtonContent={<RequiredFieldsMessage />}
                isDisabledButton={disabledForm}
            />

            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancel}
                onDimissed={onDimissed}
                onAccept={onSave}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
                width={"auto"}
            />
        </>
    );
};
