import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../../../common/constants";

export const EditRequestActuationFormStyle = createUseStyles((theme: any) => ({
    generalContainer: {
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        gap: theme.gaps.form.default,
        height: "auto",
    },
    actuationFormContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.form.default,
        width: "100%",
        paddingTop: theme.paddings.sizes.base,
    },
    actuationFormRow: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        width: "100%",
        gap: theme.gaps.form.default,
        [mediaQueries.MOBILE]: {
            flexWrap: "wrap",
        },
    },
    actionsForm: {
        display: "flex",
        justifyContent: "end",
        alignContent: "center",
        gap: theme.gaps.buttons.default,
        padding: theme.paddings.sizes.sm,
    },
}));
