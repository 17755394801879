import { ManagementRequestDatePermisionApi } from "../../../../api/management/requestdatepermision";
import { ManagementSortTableApi } from "../../../../api/management/sort/sort";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IManagementRequestDatePermissionForm } from "../../../../models/management/requestdatepermission/IRequestdatepermission";
import { formatDate, throwErrorToastrAsync } from "../../../../utils";
import {
    setLoadingManagementRequestDatePermissionForm,
    setManagementRequestDatePermissionForm,
} from "../../../reducers/management/requestdatepermission/form";
import {
    setLoadingManagementRequestDatePermissionList,
    setManagementRequestDatePermissionList,
} from "../../../reducers/management/requestdatepermission/list";
import { AppThunk } from "../../../store";

const managemenetRequestDatePermisionApi = new ManagementRequestDatePermisionApi();
const managementSort = new ManagementSortTableApi();

export const uploadPriorityRequestDatePermision =
    (_type: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementRequestDatePermissionList(true));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchRequestDatePermisionListAction());
        }
    };

export const fetchRequestDatePermisionListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingManagementRequestDatePermissionList(true));
        const response = await managemenetRequestDatePermisionApi.getRequestDatePermisionListAsync();
        dispatch(setManagementRequestDatePermissionList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingManagementRequestDatePermissionList(false));
    }
};

export const removeRequestDatePermisionAction = (id: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementRequestDatePermissionForm(true));
            await managemenetRequestDatePermisionApi.removeRequestDatePermisionAsync(id);
            dispatch(fetchRequestDatePermisionListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingManagementRequestDatePermissionForm(false));
        }
    };
};

export const patchRequestDatePermissionList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementRequestDatePermissionList(true));
            await managementSort.managementSort(tableName, id, sortType);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchRequestDatePermisionListAction());
        }
    };

export const mapWorkDays = (item: any) => {
    let selectedKeys: number[] = [];
    let keys = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    let i = 0;

    for (let key of keys) {
        i = i + 1;
        if (item[key]) {
            selectedKeys.push(i);
        }
    }
    return selectedKeys.length === 7 ? [-1] : selectedKeys;
};

export const getRequestDatePermisionAction =
    (id: number): any =>
    async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementRequestDatePermissionForm(true));
            const response = await managemenetRequestDatePermisionApi.getRequestDatePermisionAsync(id);
            let item: IManagementRequestDatePermissionForm = {
                ...response,
                weekDays: mapWorkDays(response),
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            };
            dispatch(setManagementRequestDatePermissionForm(item));
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingManagementRequestDatePermissionForm(false));
            return true;
        }
    };

export const newRequestDatePermisionAction = (form: IManagementRequestDatePermissionForm): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementRequestDatePermissionForm(true));
            const newForm: IManagementRequestDatePermissionForm = {
                ...form,
                time: formatDate(form.time?.toString(), FormatDate.HOUR_MINUTE),
                transportUnitSizeId: form.transportUnitSizeId ? form.transportUnitSizeId : undefined,
                weekDays: [],
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            };
            for (let item of form.weekDays) {
                if (item === -1) {
                    newForm.monday = true;
                    newForm.tuesday = true;
                    newForm.wednesday = true;
                    newForm.thursday = true;
                    newForm.friday = true;
                    newForm.saturday = true;
                    newForm.sunday = true;
                } else if (item === 1) {
                    newForm.monday = true;
                } else if (item === 2) {
                    newForm.tuesday = true;
                } else if (item === 3) {
                    newForm.wednesday = true;
                } else if (item === 4) {
                    newForm.thursday = true;
                } else if (item === 5) {
                    newForm.friday = true;
                } else if (item === 6) {
                    newForm.saturday = true;
                } else if (item === 7) {
                    newForm.sunday = true;
                }
            }

            if (form.id) {
                await managemenetRequestDatePermisionApi.updateRequestDatePermisionAsync(newForm);
            } else {
                await managemenetRequestDatePermisionApi.postRequestDatePermisionAsync(newForm);
            }

            dispatch(fetchRequestDatePermisionListAction());
            return true;
        } catch (error) {
            dispatch(setLoadingManagementRequestDatePermissionForm(false));
            throwErrorToastrAsync(error);
            return false;
        }
    };
};
