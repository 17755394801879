import { useTranslation } from "react-i18next";
import { IGenericPayload } from "../../../models";
import { exportStatisticsIncidenceOrganismAction, fetchStatiticsIncidenceOrganismFilterAction } from "../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetOrganismIncidenceFilter, updateFilterProperty } from "../../../redux/reducers/statistics/incidenceOrganism/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsIncidenceOrganismList } from "./list/list";

export const StatisticsIncidenceOrganismContainer = () => {
    const { filter: filter } = useAppSelector((state) => state.statisticsOrganismIncidenceFilter);
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INCIDENCE.ORGANISM" });
    const dispatch = useAppDispatch();
    const onExportDataStaticsCustomers = () => {
        return dispatch(exportStatisticsIncidenceOrganismAction());
    };

    const updateHeaderPropierty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };
    const onApplyFilters = () => {
        return dispatch(fetchStatiticsIncidenceOrganismFilterAction());
    };
    const onResetFilters = () => {
        dispatch(resetOrganismIncidenceFilter());
        onApplyFilters();
    };
   
    return (
        <div>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={<ExportDataIcon onExportCSV={onExportDataStaticsCustomers}/>}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderPropierty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <StatisticsIncidenceOrganismList />
        </div>
    );
};
