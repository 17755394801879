import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";



import {  IAdminData, IAdminDataCustom, IEmailItemWithIndex, IGenericPayload } from "../../../../../../../models";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { TitleH6 } from "../../../../../../common/titles/titles";
import { myProfileAdminResolver } from "../../user/resolver";
import { ManagementUserEmailItem } from "./item/item";
import { ManagementUserCustomerFormEntityAdminEmailListStyle } from "./listStyle.jss";
import { GridClass } from "../../../../../../../common/enum/GridClass";
import { isValidEmail } from "../../../../../../../common/validations/validationsForm";
import { ColorType } from "../../../../../../../common/enum/ColorType";



interface IManagementUserCustomerFormEntityAdminEmailListProps {
    data: IAdminDataCustom;
    title: string;
    updateDataProperty: (value: IGenericPayload) => any;
    updateEmailItem: (value: IEmailItemWithIndex) => any;
    removeEmailItem: (value: number) => any;
    addEmailItem: () => any;
    setValidData: (value: boolean) => any;
}

export const ManagementUserCustomerFormEntityEmailList: React.FC<IManagementUserCustomerFormEntityAdminEmailListProps> = ({
    title,
    data,
    addEmailItem,
    removeEmailItem,
    updateDataProperty,
    updateEmailItem,
    setValidData,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.FORMS.MANAGEMENT_USER" });
    const theme = useTheme();
    const style = ManagementUserCustomerFormEntityAdminEmailListStyle({ theme });


    const {
        register,
        formState: { errors: formError, isValid },
    } = useForm<IAdminDataCustom>({ mode: "onChange", resolver: myProfileAdminResolver });

    const dispatch = useDispatch();
    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            const payload: IGenericPayload = {
                name: name,
                value: value,
            };

            updateDataProperty(payload);
        },
        [dispatch],
    );
    const maxIndex = useMemo(() => {
        let number = 0;
        if (data && data.emails !== null) {
            number = data.emails.length - 1;
        }

        return number;
    }, [data]);
    useEffect(() => {
        if (!data?.emails) {
            const payload: IGenericPayload = {
                name: "emails",
                value: [""],
            };

            updateDataProperty(payload);
        }
    }, [data]);

    useEffect(() => {
        const validEntityAdminEmails = () => {
            let valid = false;
            if (data) {
                for (let email of data.emails) {
                    if (!isValidEmail(email)) {
                        return valid;
                    }
                }
                valid = true;
            }

            return valid;
        };

        setValidData(isValid && validEntityAdminEmails());
    }, [isValid, data.emails]);

    return (
        <div className={`${GridClass.ROW_GRID} ${style.container}`}>
            <div className={GridClass.SIMPLE_CELL}>
                <TitleH6
                    title={title}
                    color={ColorType.primary}
                />
            </div>
            <div className={GridClass.SIMPLE_CELL}>
                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("LANDLINE")}
                            value={data?.phone ? data.phone : DefaultValues.NOT_TEXT}
                            error={formError.phone}
                            isRequired={true}
                            {...register(compileNameOfProperty<IAdminData>("phone"), {
                                onChange: onInputChange,
                                value: data?.phone ? data.phone : "",
                            })}
                        />
                    </div>
                    <div className={GridClass.LONG_DOBLE_CELL}>
                        <FormField
                            type="text"
                            label={t("MOBILE_PHONE")}
                            value={data?.mobilePhone ? data.mobilePhone : DefaultValues.NOT_TEXT}
                            error={formError.mobilePhone}
                            {...register(compileNameOfProperty<IAdminData>("mobilePhone"), {
                                onChange: onInputChange,
                                value: data?.mobilePhone ? data.mobilePhone : "",
                            })}
                        />
                    </div>
                </div>
            </div>

            {data?.emails && (
                <div className={`${GridClass.SIMPLE_CELL} ${style.emailListContainer}`}>
                    {data?.emails.map((email, index) => {
                        return (
                            <div key={`${ManagementUserCustomerFormEntityEmailList.name}-${index}`}>
                                <ManagementUserEmailItem
                                    email={email}
                                    key={`${ManagementUserCustomerFormEntityEmailList.name}-${index}`}
                                    isLastIndex={index === maxIndex}
                                    isUniqueIndex={data.emails.length === 1}
                                    index={index}
                                    updateEmailItem={updateEmailItem}
                                    removeEmailItem={removeEmailItem}
                                    addEmailItem={addEmailItem}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
