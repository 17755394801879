import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { IBaseMultipleNumberSelectorProps } from "../IBaseMultipleSelectorProps";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { MultipleNumberSelectorFilter } from "../multipleSelectorFilter/multipleNumberSelectorFilter";
import { NumericMultiSelector } from "../../../../common/selectors";
import { fetchAppointmentTimeZone } from "../../../../../redux/actions/catalog/catalog";

export const MultipleAppointmentTimeZoneSelector: React.FC<IBaseMultipleNumberSelectorProps> = ({
    autocomplete,
    onChange,
    propertyName,
    selectedKeys,
    title,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.appointmentTimeZone);
    const dispatch = useAppDispatch();
    useEffect(() => {
        data === undefined && dispatch(fetchAppointmentTimeZone());
    }, [dispatch]);

    return autocomplete ? (
        <MultipleNumberSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapIDescriptionToDropDownOptions(data ?? [])}
            label={title}
            propertyName={propertyName}
        />
    ) : (
        <NumericMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapIDescriptionToDropDownOptions(data ?? [])}
            disabled={loading}
            title={title}
            name={propertyName}
        />
    );
};
