import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { AppointmentImportColumnStyle } from "./columnStyle.jss";
import { updateSortColumn } from "../../../../../redux/reducers/vlcPort/importRequest/list";
import { IGenericImportFinishedError } from "../../../../../models/common/IImportGeneric";

export const QueuAssignmentImportColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.IMPORT_REQUEST" });
    const theme = useTheme();
    const styles = AppointmentImportColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.appointmentImportList.sort);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().appointmentImportList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IGenericImportFinishedError>("lineNumber"),
            minWidth: 90,
            maxWidth: 100,
            name: t("LINE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IGenericImportFinishedError>("lineNumber")}
                    title={t("LINE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IGenericImportFinishedError>("lineNumber"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IGenericImportFinishedError) => <span>{item.lineNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IGenericImportFinishedError>("details"),
            minWidth: 150,
            maxWidth: 160,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IGenericImportFinishedError>("details")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IGenericImportFinishedError>("details"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: IGenericImportFinishedError) => <span>{item.details}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
