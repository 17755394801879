import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterRequestStatesList } from "./list/list";
import { setRequestStates } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterRequestStatesList } from "../../../../../redux/reducers/resources/master/requestStates/list";

export class MasterRequestStatesContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterRequestStatesList());
        store.dispatch(setRequestStates([]));
    }

    render(): React.ReactNode {
        return (
            <MasterRequestStatesList
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
