import { createUseStyles } from "react-jss";

export const stateHistoryPopUpStyles = createUseStyles((theme: any) => ({
    containerPopup: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "70vh",
        minWidth: "650px",
    },
    container: (props: any) => ({
        marginTop: 10,
        "& .tableBody": {
            display: "flex",
            flexDirection: "column",
            height: props.isAdminView ? "calc(70vh - 165px)" : "calc(70vh - 128px)",
            overflow: "auto",
            marginBottom: "20px",
            paddingTop: 0,
            "& > div": {
                paddingTop: props.isAdminView ? "0px" : "12px",
                paddingBottom: props.isAdminView ? "5px" : "12px",
            },
        },
        "& .skeleton": {
            height: "calc(70vh - 74px)",
        },
    }),
    tableTitle: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "2vw",
        width: theme.widths.sectionContainer.complete,
        paddingBottom: theme.paddings.forms.inputs.defaultPadding,

        "& > div ": {
            width: "40%",
            wordBreak: "break-all",
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid",
            borderColor: theme.colors.titles.primary.color,
            textTransform: "uppercase",
            padding: "5px 0 5px 0",
            color: theme.colors.titles.primary.color,
            fontWeight: "bold",
        },
        "& .system": {
            width: "38%",
        },
    },
}));
