import { IManagementUserForm, updateManagementUserProperty } from "../../../../../../redux/reducers/management/user/form";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CheckboxSelector } from "../../../../../common/checkboxSelector/checkboxSelector";
import { IFormOption } from "../../../../../../models/common/IFormOptions";
import { IGenericPayload, IOrganization } from "../../../../../../models";
import { useTheme } from "react-jss";
import { ManagementUserFormOrganizationsTabStyle } from "./organizationsTabStyle.jss";
import { IOrganizationData } from "../../../../../../models/user/IOrganizationData";
import { compileNameOfProperty } from "../../../../../../utils";
import { useEffect } from "react";
import { fetchOrganizations } from "../../../../../../redux/actions/catalog/catalog";

interface IManagementUserFormOrganizationsTabProps {
    generalUserData: IManagementUserForm;
}

export const ManagementUserFormOrganizationsTab: React.FC<IManagementUserFormOrganizationsTabProps> = ({ generalUserData }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ManagementUserFormOrganizationsTabStyle({ theme });
    const organizations: IOrganization[] = useAppSelector((store) => store.catalog.organizations.data);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    useEffect(() => {
        !organizations.length && dispatch(fetchOrganizations());
    }, [dispatch, organizations]);

    const checkboxSelectorOptions = () => {
        let options: IFormOption[] = generalUserData.availableOrganizations.map((item) => {
            return {
                key: item.id,
                text: organizations.find((o) => item.id === o.id)?.name ?? "",
                active: item.id === currentOrganization || generalUserData.organizations.some((o) => item.id === o.id),
                disabled: item.id === currentOrganization
            };
        });

        return options;
    };

    const updateUserOrganizations = (selected: boolean, indexOption: number) => {
        const organization: IOrganizationData = {
            id: generalUserData.availableOrganizations[indexOption].id
        };

        var organizations : IOrganizationData[] = [];
        generalUserData.organizations.forEach(val => organizations.push(Object.assign({}, val)));

        if (selected) {
            organizations.push(organization);
        } else {
            organizations = organizations.filter(o => o.id !== organization.id);
        }

        let payload: IGenericPayload = {
            name: compileNameOfProperty<IManagementUserForm>("organizations"),
            value: organizations,
        };
        dispatch(updateManagementUserProperty(payload));
    };

    return (
        <div className={style.container}>
            <CheckboxSelector
                options={checkboxSelectorOptions()}
                name={""}
                onChange={function (name: string, value: boolean, indexOption: number): void {
                    updateUserOrganizations(value, indexOption);
                }}
            />
        </div>
    );
};
