import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterTransportUnitColumns } from "./column/column";
import { MasterTransportUnitControllerForm } from "../controller/controller";
import { fetchMasterTransportUnitList } from "../../../../../../redux/actions/resources/master/transportUnit/transportUnit";

interface IMasterTransportUnitList {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterTransportUnitList: React.FC<IMasterTransportUnitList> = ({ heigthPopUp, widthPopUp }) => {
    const { loading, list } = useAppSelector((state) => state.masterTransportUnitList);
    const dispatch = useAppDispatch();

    const renderColumns = () => {
        return MasterTransportUnitColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterTransportUnitList());
    }, [list]);

    return loading ? (
        <Skeleton rows={5}></Skeleton>
    ) : (
        <>
            <CustomDetailsList
                data={list ?? []}
                renderColumns={renderColumns}
            />
            <MasterTransportUnitControllerForm
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </>
    );
};
