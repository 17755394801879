import { FontIcon } from "@fluentui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import {
    deleteManagementWorkCalendarAction,
    fetchManagementWorkCalendarAction,
    setManagementWorkCalendarAction,
} from "../../../redux/actions/management/workCalendar/workCalendar";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { convertToLocal, mapStringToDropdown } from "../../../utils";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { Selector } from "../../common/selectors/selector";
import { Spinner } from "../../common/spinner/spinner";
import { ManagementWorkCalendarContainerStyle } from "./containerStyle.jss";
import { IManagementNonWorkDays } from "../../../models/management/workCalendar/IManagementNonWorkDays";
import { CalendarElement } from "./elements/calendarElement";

export const ManagementWorkCalendarContainer = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = ManagementWorkCalendarContainerStyle({ theme });
    const { t } = useTranslation("common");

    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [year, setYear] = useState<number>(new Date().getFullYear());

    const weekDays: string[] = [
        t("WEEKDAYS.MONDAY"),
        t("WEEKDAYS.TUESDAY"),
        t("WEEKDAYS.WEDNESDAY"),
        t("WEEKDAYS.THURSDAY"),
        t("WEEKDAYS.FRIDAY"),
        t("WEEKDAYS.SATURDAY"),
        t("WEEKDAYS.SUNDAY"),
    ];
    const months: string[] = [
        t("MONTH.JANUARY"),
        t("MONTH.FEBRUARY"),
        t("MONTH.MARCH"),
        t("MONTH.APRIL"),
        t("MONTH.MAY"),
        t("MONTH.JUNE"),
        t("MONTH.JULY"),
        t("MONTH.AUGUST"),
        t("MONTH.SEPTEMBER"),
        t("MONTH.OCTUBER"),
        t("MONTH.NOVEMBER"),
        t("MONTH.DECEMBER"),
    ];
    const { list } = useAppSelector((store) => store.managementWorkCalendarList);

    useEffect(() => {
        dispatch(fetchManagementWorkCalendarAction(year, month + 1));
    }, [month, year]);

    const onSelectorChange = (event: any, option: any) => {
        setMonth(option.key);
    };

    const onRemoveNonWorkDay = (id: number, setLoading: Dispatch<SetStateAction<boolean>>) => {
        setLoading(true);
        dispatch(deleteManagementWorkCalendarAction(id)).then(() => {
            dispatch(fetchManagementWorkCalendarAction(year, month + 1)).then(() => {
                setLoading(false);
            });
        });
    };

    const weekHeader = () => {
        return (
            <div className={styles.rowWeek}>
                {weekDays.map((value, index) => {
                    return <div key={`index-${value}-${index}`}>{value}</div>;
                })}
            </div>
        );
    };

    const days = (index: number, date: number) => {
        let content = [];
        let lastDays = 0;
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(year, month, date);
            const lastMonth = new Date(year, month, 0);
            const nextMonth = new Date(year, month + 1, 1);
            let currentDay = currentDate.getDay() - 1;

            if (currentDay === -1) {
                currentDay = 6;
            }

            if (index === 0 && i < currentDay) {
                content.push(
                    <div
                        key={`index-${index}-${i}`}
                        className={`grid-element ${styles.disabled}`}
                    >
                        {lastMonth.getDate() - (currentDay === 6 ? 5 : currentDay - 1) + i}
                    </div>,
                );
            } else if (date > daysInMonth(month, year)) {
                content.push(
                    <div
                        key={`index-${index}-${i}`}
                        className={`grid-element ${styles.disabled}`}
                    >
                        {nextMonth.getDate() + lastDays}
                    </div>,
                );
                lastDays++;
            } else {
                const mapString = (x: string) => {
                    return x.length === 1 ? "0" + x : x;
                };

                let mapDate = `${year}-${mapString(String(month + 1))}-${mapString(String(date))}`;
                const gridDate = new Date(mapDate);
                const workingDay = list.find(
                    (item) => convertToLocal(item.date, FormatDate.DATE) === convertToLocal(gridDate.toISOString(), FormatDate.DATE),
                );

                content.push(
                    <CalendarElement
                        currentDate={currentDate}
                        index={index}
                        id={i}
                        date={date}
                        gridDate={gridDate}
                        workingDay={workingDay}
                        month={month}
                        year={year}
                        onRemoveNonWorkDay={onRemoveNonWorkDay}
                    />,
                );

                date++;
            }
        }
        return { content, date };
    };

    const weeks = (month: number, year: number) => {
        let content = [];
        let date = 1;
        for (let i = 0; i < weekCount(year, month); i++) {
            const daysGrid = days(i, date);
            content.push(
                <div
                    key={`index-${i}`}
                    className={styles.row}
                >
                    {daysGrid.content}
                </div>,
            );
            date = daysGrid.date;
        }
        return content;
    };

    function daysInMonth(iMonth: any, iYear: any) {
        return 32 - new Date(iYear, iMonth, 32).getDate();
    }

    const weekCount = (year: number, month: number) => {
        const firstOfMonth = new Date(year, month, 1);
        const lastOfMonth = new Date(year, month + 1, 0);
        const used = firstOfMonth.getDay() + lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 0) {
            return 5;
        }

        if (firstOfMonth.getDay() === 0 && lastOfMonth.getDate() > 29) {
            return 6;
        }
        return Math.ceil(used / 7);
    };

    return (
        <>
            <NavHeaderComponent title={t("WORKCALENDAR.TITLE")} />
            <div className={styles.container}>
                <div className={styles.headerSelector}>
                    <div className={styles.selectorMonth}>
                        <Selector
                            name="month"
                            onChange={onSelectorChange}
                            options={mapStringToDropdown(months)}
                            selectedKey={month}
                            title=""
                            className={styles.selector}
                        />
                    </div>
                    <div className={styles.separador}></div>
                    <div className={styles.selectorYear}>
                        <FontIcon
                            aria-label="ChevronLeft"
                            iconName="ChevronLeft"
                            onClick={() => setYear(year - 1)}
                            className={`${styles.icon}`}
                        />
                        <div>{year}</div>
                        <FontIcon
                            aria-label="ChevronRight"
                            iconName="ChevronRight"
                            onClick={() => setYear(year + 1)}
                            className={`${styles.icon}`}
                        />
                    </div>
                    <div className={styles.totalDays}>{t("WORKCALENDAR.DAYSNONWORK", { days: list.length })}</div>
                </div>
                <div className={styles.calendar}>
                    {weekHeader()}
                    {weeks(month, year)}
                </div>
            </div>
        </>
    );
};
