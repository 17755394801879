import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IGenericImportFinishedData } from "../../../../models/common/IImportGeneric";

interface IAppointmentImportFinishedState {
    loading: boolean;
    data?: IGenericImportFinishedData;
    sort: ISortState;
}
const initialState: IAppointmentImportFinishedState = {
    loading: false,
    sort: emptySortState(),
};

export const AppointmentImportListReducer = createSlice({
    name: "AppointmentImportListReducer",
    initialState,
    reducers: {
        setAppointmentImportListData: (state, action: PayloadAction<IGenericImportFinishedData>) => {
            state.data = action.payload;
        },
        setAppointmentImportListLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },

        resetAppointmentImportList: () => initialState,
    },
});

export const { resetAppointmentImportList, updateSortColumn, setAppointmentImportListLoading, setAppointmentImportListData } =
    AppointmentImportListReducer.actions;

export default AppointmentImportListReducer.reducer;
