import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { FinishedRequest } from "../../../../models/vlcPort/IFinishedRequest";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface VlcPortRquestFinished {
    loading: boolean;
    list?: any[];
    sort: ISortState;
    showPopUp: PopUpCodes;
    detail: string;
    sendedValenciaportMessage:string;
}
const initialState: VlcPortRquestFinished = {
    loading: false,
    sort: emptySortState(),
    showPopUp: PopUpCodes.NONE,
    detail: "",
    sendedValenciaportMessage:""
};

export const VlcPortRequestFinishedListReducer = createSlice({
    name: "VlcportRequestFinishedListReducer",
    initialState,
    reducers: {
        setVlcportRequestFinishedListData: (state, action: PayloadAction<FinishedRequest[]>) => {
            state.list = action.payload;
        },
        setVlcportRequestFinishedListLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setVlcportRequestFinishedPopUpShow: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },

        setVlcportRequestFinishedDetail: (state, action: PayloadAction<string>) => {
            state.detail = action.payload;
        },
        setVlcportRequestFinishedsendedValenciaportMessage: (state, action: PayloadAction<string>) => {
            state.sendedValenciaportMessage = action.payload;
        },
        resetVlcportRequestFinishedList: () => initialState,
    },
});

export const {
    resetVlcportRequestFinishedList,
    updateSortColumn,
    setVlcportRequestFinishedListData,
    setVlcportRequestFinishedListLoading,
    setVlcportRequestFinishedPopUpShow,
    setVlcportRequestFinishedDetail,
    setVlcportRequestFinishedsendedValenciaportMessage
} = VlcPortRequestFinishedListReducer.actions;

export default VlcPortRequestFinishedListReducer.reducer;
