import { useBoolean } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../../assets/icons";
import { FormatDate } from "../../../../../../../../../common/enum/dateTime/FormatDate";
import { IInspection } from "../../../../../../../../../models/requests/IEditRequest";
import { convertToLocal } from "../../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../../common/customIcons/customIcons";
import { TeachingBubbleComponent } from "../../../../../../../../common/teachingBubble/teachingBubble";
import { BubbleInspectorListStyles } from "./bubbleInspectionStyle.jss";

interface IRequestInspectionInspectorProps {
    inspection: IInspection;
}
export const RequestInspectionBuubbleInspector: React.FC<IRequestInspectionInspectorProps> = ({ inspection }) => {
    const [inspectorsHistory, { toggle: toggleinspectorHistory }] = useBoolean(false);
    let buttonId = `inspectorBubbleId-${inspection.id}`;
    const style = BubbleInspectorListStyles();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });

    return (
        <>
            <div
                id={buttonId}
                onClick={toggleinspectorHistory}
                style={{ cursor: inspection.expedientHistories.length > 0 ? "pointer" : "", display: "flex" }}
            >
                <span
                    className={style.inspectorName}
                >
                    {inspection.inspectorName ?? t("FIELDS.NOT_INSPECTOR")}
                </span>

                {inspection.expedientHistories.length > 0 && (
                    <div className={style.iconRange}>
                        <CustomIcons
                            id={buttonId}
                            iconName={NameIcons.CHEVRONDOWNICON}
                            className={inspectorsHistory ? "arrowUp" : "arrow"}
                        ></CustomIcons>
                    </div>
                )}
            </div>

            {inspection.expedientHistories.length > 0 && inspectorsHistory && (
                <TeachingBubbleComponent
                    children={
                        <div className={style.container}>
                            {inspection.expedientHistories?.map((item) => {
                                return (
                                    <span
                                        className={style.inpector}
                                        key={buttonId}
                                    >{`A ${convertToLocal(item.createdOn, FormatDate.DATE_HOUR)} ${
                                        inspection.inspectorName ?? t("FIELDS.NOT_INSPECTOR")
                                    }`}</span>
                                );
                            })}
                        </div>
                    }
                    onToggle={toggleinspectorHistory}
                    id={buttonId}
                />
            )}
        </>
    );
};
