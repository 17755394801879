import { createUseStyles } from "react-jss";

export const SuggestionsMailboxStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        padding: 20,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    content: {
        display: "flex",
        justifyContent: "center",
    },
}));
