import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HttpStatus } from "../../../common/enum/api/HttpStatus";
import { Milliseconds } from "../../../common/enum";

interface IApiException {
    error?: {
        htmlStatus?: HttpStatus;
        message?: string;
        iconName?: string;
        request: string;
    };
    timeNextRequest: number;
}
const initialState: IApiException = {
    error: {
        htmlStatus: undefined,
        iconName: undefined,
        message: undefined,
        request: "",
    },
    timeNextRequest: Milliseconds.TWO_MIN,
};

export const siteNavSlice = createSlice({
    name: "apiException",
    initialState,
    reducers: {
        setApiErrorResponse: (state, action: PayloadAction<{ htmlStatus: number; iconName: string; request: string }>) => {
            state.error = {
                htmlStatus: action.payload.htmlStatus,
                iconName: action.payload.iconName,
                request: action.payload.request,
            };
        },
        reset: () => initialState,
    },
});

export const { setApiErrorResponse, reset } = siteNavSlice.actions;

export default siteNavSlice.reducer;
