import { Resolver } from "react-hook-form";
import { isValidEmail, isValidPhoneNumber } from "../../../../../common/validations/validationsForm";
import { IManagementUserForm } from "../../../../../redux/reducers/management/user/form";
import { compileNameOfProperty } from "../../../../../utils";

export const myProfileResolver: Resolver<IManagementUserForm> = async (values: IManagementUserForm) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (name === compileNameOfProperty<IManagementUserForm>("email")) {
            if (!isValidEmail(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.EMAIL",
                    },
                };
            }
        } else if (name === compileNameOfProperty<IManagementUserForm>("phoneNumber")) {
            if (value && value !== "" && !isValidPhoneNumber(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.PHONE",
                    },
                };
            }
        } else {
            if (name !== compileNameOfProperty<IManagementUserForm>("lastName") && !value) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            }
        }
    });
    return { values, errors };
};
