import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const UserSignupOperationEmailsFormStyle = createUseStyles((theme: any) => ({
    row: {
        boxSizing: "border-box",
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
        [mediaQueries.MOBILE]: {
            display: "flex",
            flexDirection: "column",
            gap: 35,
        },
    },
    rowEmails: {
        rowGap: "20px",
        display: "flex",
        flex: "0 1 auto",
        flexFlow: "wrap",
        marginRight: "-0.5rem",
        marginLeft: "-0.5rem",
        columnGap: 20,
        [mediaQueries.MOBILE]: {
            rowGap: 35,
        },
    },
    column: {
        flexBasis: "100%",
    },
    columnEmail: {
        width: "49%",
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    },
    title: {
        flexBasis: "100%",
        paddingTop: theme.paddings.sizes.base,
    },
}));
