import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { emptyUserSelfSignUpModel, IUserSelfSignup } from "../../../../models/";

interface SelfSignupState {
    form: IUserSelfSignup;
    signupSuccess: boolean;
}

const initialState: SelfSignupState = {
    form: emptyUserSelfSignUpModel(),
    signupSuccess: false,
};

export const selfSignUpSlice = createSlice({
    name: "selfSignupReducer",
    initialState,
    reducers: {
        updateSelfSignupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        resetSelfSignupForm: () => initialState,

        selfSignUpSuccess: (state) => {
            state.signupSuccess = true;
        },
    },
});

export const { updateSelfSignupProperty, resetSelfSignupForm, selfSignUpSuccess } = selfSignUpSlice.actions;

export default selfSignUpSlice.reducer;
