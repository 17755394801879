import { ManagementUserApi } from "../../../../api/management/user";
import { Timer } from "../../../../common/enum/Timer";
import { IManagementUserFilter } from "../../../../models/management/user/IManagementUserFilter";
import {  stringDefaultValue, throwErrorToastrAsync } from "../../../../utils";
import {
    emptyAdminData,
    emptyEntityData,
    emptyPaymentData,
    IManagementUserForm,
    setLoadingManagementUser,
    setManagementUser,
} from "../../../reducers/management/user/form";
import { fetchManagementUserList, setLoadingManagementUserList, setPage } from "../../../reducers/management/user/list";
import { setLoadingUsers } from "../../../reducers/user/profile/userProfile";
import { setCustomerAdminOptions } from "../../../reducers/user/users/usersByRole";
import { AppThunk, store } from "../../../store";
import { fetchUserProfileUsersList } from "../../user/profile/userProfile";

const managementUserApi = new ManagementUserApi();

const getManagementUserFilter = (): IManagementUserFilter => {
    const { filter } = store.getState().managementUserFilter;

    return {
        ...filter,
        roleId: filter.roleId !== stringDefaultValue.key ? filter.roleId : "",
    };
};

export const fetchManagementUserListAction =
    (resetPagination: boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementUserList(true));
            const filter = getManagementUserFilter();
            const response = await managementUserApi.getUserListAsync(filter);
            dispatch(fetchManagementUserList(response));
            if (resetPagination) {
                dispatch(setPage(1));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementUserList(false));
        }
    };

export const setPageAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        dispatch(setPage(page));
    };

export const createOrUpdateManagementUserAction = (userProps: IManagementUserForm): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementUser(true));
            if (userProps.id) {
                await managementUserApi.updateUserAsync(userProps);
            } else {
                await managementUserApi.createUserAsync(userProps);
            }

            dispatch(fetchManagementUserListAction(userProps.id === undefined));
            
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingManagementUser(false));
        }
    };
};

export const migrateManagementUserAction = (userProps: IManagementUserForm): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementUser(true));
            await managementUserApi.migrateUserAsync(userProps);

            setTimeout(() => {
                dispatch(setLoadingManagementUser(false));
            }, Timer.LOADING);

            dispatch(fetchManagementUserListAction(userProps.id === undefined));
            dispatch(fetchManagementUserListAction());

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingManagementUser(false));
            return false;
        }
    };
};

export const getMananagementUserAction =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementUser(true));
            const response = await managementUserApi.getUserAsync(id);
            let userRedux: IManagementUserForm = {
                ...response,
                entityData: response.entityData ?? emptyEntityData(),
                adminData: response.adminData ?? emptyAdminData(),
                operatorData: response.operatorData ?? emptyAdminData(),
                paymentData: response.paymentData ?? emptyPaymentData(),
                documents: [],
                documentTypesDeleted: [],
            };

            dispatch(setManagementUser(userRedux));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementUser(false));
        }
    };

export const setManagementUserUnregisteredAction = (id: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementUser(true));
            await managementUserApi.unregisterUserAsync(id);
            dispatch(getMananagementUserAction(id));
            dispatch(fetchManagementUserListAction());
            dispatch(setCustomerAdminOptions(undefined));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingManagementUser(false));
        }
    };
};

export const setManagementUserRegisteredAction =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementUser(true));
            await managementUserApi.registerUserAsync(id);
            dispatch(getMananagementUserAction(id));
            dispatch(fetchManagementUserListAction());
            dispatch(setCustomerAdminOptions(undefined));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementUser(false));
        }
    };

export const updateProfileUser = (userProps: IManagementUserForm): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingUsers(true));
            if (userProps.id) {
                await managementUserApi.updateUserAsync(userProps);
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchUserProfileUsersList());
        }
    };
};
