import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentDatePermission } from "../../../../models/masterAppointment/IAppointmentDatePermission";

export interface IMasterAppointmentDatePermissionListState {
    list?: IAppointmentDatePermission[];
    loading: boolean;
}

const initialState: IMasterAppointmentDatePermissionListState = {
    loading: false,
};

export const masterAppointmentDatePermissionList = createSlice({
    name: "masterAppointmentDatePermissionList",
    initialState,
    reducers: {
        resetMasterAppointmentDatePermissionList: () => initialState,
        setLoadingMasterAppointmentDatePermissionList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentDatePermissionList: (state, action: PayloadAction<IAppointmentDatePermission[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterAppointmentDatePermissionList, setLoadingMasterAppointmentDatePermissionList, setMasterAppointmentDatePermissionList } = masterAppointmentDatePermissionList.actions;

export default masterAppointmentDatePermissionList.reducer;
