import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterBlockMasterController } from "../controller/controller";

import { MasterActuationGroupColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/enum/sizes";
import { fetchMasterActuationGroupList } from "../../../../../../redux/actions/resources/master/actuationGroup/actuationGroup";

interface IMasterActuationGroupListContainerProps {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterActuationGroupListContainer: React.FC<IMasterActuationGroupListContainerProps> = ({ heigthPopUp, widthPopUp }) => {
    const { list, loading } = useAppSelector((state) => state.masterActuationGroupList);
    const dispatch = useAppDispatch();
    
    const renderColumns = () => {
        return MasterActuationGroupColumns();
    };
    
    useEffect(() => {
        list === undefined && !loading && dispatch(fetchMasterActuationGroupList());
    }, [list]);

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 10rem)"}
                />
            )}

            <MasterBlockMasterController
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </div>
    );
};
