import { Method } from "../../../common/enum";
import { IInvoicingNonSynchronized } from "../../../models";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/invoicing/invoiceconcepts/`;

export class InvoicingNonSynchronizedApi {
    async getNonSynchronized(): Promise<IInvoicingNonSynchronized[]> {
        const url = `${baseURL}nonsynchronized`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IInvoicingNonSynchronized[]>();
    }

    async putSynchronizeConcepts(invoiceConceptIds: number[]): Promise<string> {
        const url = `${baseURL}synchronize`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody({ invoiceConceptIds }).execute<string>();
    }
}
