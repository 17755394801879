import { RequestApi } from "../../../../../api/request";
import { routeUrls } from "../../../../../common/constants";
import { Milliseconds } from "../../../../../common/enum";
import { HttpStatus } from "../../../../../common/enum/api/HttpStatus";
import { RequestIncidenceType } from "../../../../../common/enum/categoryIncidence";
import { handleError } from "../../../../../common/utils/handleError";
import { IGenericPayload } from "../../../../../models";
import { IAppointmentAssign, IAppointmentData } from "../../../../../models/appointment/IAppointmentAssign";
import { IActuation, IEditRequest } from "../../../../../models/requests/IEditRequest";
import { IConceptFormTariff } from "../../../../../models/requests/IEditRequestBilling";
import { ICreateIncidence } from "../../../../../models/requests/IRequestIncidence";
import { IRequestStateHistory } from "../../../../../models/requests/IRequestStateHistory";
import { throwErrorToastrAsync, throwSuccessToastrAsync } from "../../../../../utils";
import { resetAppointmentAvailable } from "../../../../reducers/appointment/zoneAvailable";
import {
    resetRequestState,
    setAvailableDates,
    setCertificatesFormValid,
    setEditIsLoaded,
    setEditRequest,
    setGoodInfoFormValid,
    setIsUpdatedDataRequest,
    setLoadedInvoiced,
    setLoadingIncidenceOperational,
    setLoadingIncidenceOrganism,
    setLoadingRequestDocuments,
    setLoadingRequestImages,
    setOperationalIncidenceRequest,
    setOrganismIncidenceRequest,
    setRequestDocuments,
    setRequestImages,
    setRequestInvoiceConcepts,
    setRequestStateHistory,
    setUserNotified,
    setUtiFormValid,
    updateRequestHeaderProperty,
} from "../../../../reducers/request/edit/editRequest";
import { setActuationFormData, setActuationFormLoading } from "../../../../reducers/request/edit/form/actuation";
import { setEditRequestInvoiceConceptFormData, setEditRequestInvoiceConceptFormLoading } from "../../../../reducers/request/edit/form/concept";
import { setIncidenceFormData, setIncidenceFormLoading } from "../../../../reducers/request/edit/form/incidence";
import { IAddInspectionData } from "../../../../reducers/request/list/form/addInspection";
import { AppThunk, store } from "../../../../store";
import { postAppointmentAssignAction } from "../../../appointments/appointments";
import { setGlobalLoadingAction } from "../../../common/globalLoading";
import { fetchPromiseInvoiceCustomerOptions } from "../../../user/users/users";
import { fetchRequestListAction, fetchSilentRequestListAction } from "../../list/requestList";
import { fetchEditRequestInspection } from "./inspection";

const requestApi = new RequestApi();

export const fetchRequesDocumentAction =
    (requestId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingRequestDocuments(true));
            const response = await requestApi.getRequestDocuments(requestId);
            dispatch(setRequestDocuments(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingRequestDocuments(false));
        }
    };

export const deleteDocumentRequest =
    (requestId: number, documentId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingRequestDocuments(true));
            await requestApi.deleteDocumentRequest(requestId, documentId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchRequesDocumentAction(requestId));
            dispatch(setLoadingRequestDocuments(false));
        }
    };
export const uploadDocumentRequest = (codumentTypeId: number, document: File, requestId: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingRequestDocuments(true));
            await requestApi.uploadDocuments(codumentTypeId.toString(), document, requestId);
            dispatch(fetchRequesDocumentAction(requestId));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingRequestDocuments(false));
            return false;
        }
    };
};

export const fetchRequesImagesAction =
    (requestId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingRequestImages(true));
            const response = await requestApi.getPhotos(requestId);
            dispatch(setRequestImages(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingRequestImages(false));
        }
    };

export const deleteImageRequest =
    (requestId: number, imageId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingRequestImages(true));
            await requestApi.deleteImageRequest(requestId, imageId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchRequesImagesAction(requestId));
            dispatch(setLoadingRequestImages(false));
        }
    };

export const uploadImageRequest = (imageRequestList: File[], requestId: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingRequestImages(true));
            await requestApi.uploadImageRequest(imageRequestList, requestId);
            dispatch(fetchRequesImagesAction(requestId));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingRequestImages(false));
            return false;
        }
    };
};
export const fetchEditRequestData =
    (requestId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setEditIsLoaded(false));
            dispatch(setUtiFormValid(false));
            dispatch(setGoodInfoFormValid(false));
            dispatch(setCertificatesFormValid(false));
            dispatch(setGlobalLoadingAction(true));
            const response = await requestApi.getEditRequest(requestId);
            dispatch(setIsUpdatedDataRequest(response));
            dispatch(setEditRequest(response));
            await dispatch(fetchPromiseInvoiceCustomerOptions(response.customerId));
        } catch (error: Error | any) {
            dispatch(setGlobalLoadingAction(false));
            const errorData = error?.response?.data;

            if (errorData.status === HttpStatus.BAD_REQUEST || errorData.status === HttpStatus.NOT_FOUND) {
                window.location.replace(routeUrls.NOT_FOUND);
            } else {
                throwErrorToastrAsync(error);
            }
        } finally {
            dispatch(setGlobalLoadingAction(false));
            dispatch(setEditIsLoaded(true));
        }
    };

export const fetchRequestStateHistory =
    (requestId: number): AppThunk =>
    async (dispatch) => {
        try {
            const response = await requestApi.getRequestStateHistory(requestId);
            dispatch(setRequestStateHistory(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        }
    };

export const patchRequestStateHistory =
    (requestId: string, stateHistory: IRequestStateHistory[]): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.patchRequestStateHistory(requestId, stateHistory);
            const response = await requestApi.getEditRequest(requestId);
            dispatch(setEditRequest(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const fetchIncidencesRequest =
    (requestId: number, category: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(loadingIncidenceType(category, true));
            const response = await requestApi.getIncidencesRequests(requestId, category);
            if (category === RequestIncidenceType.ORGANISM) {
                dispatch(setOrganismIncidenceRequest(response));
            } else if (category === RequestIncidenceType.OPERATIONAL) {
                dispatch(setOperationalIncidenceRequest(response));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingIncidenceType(category, false));
        }
    };

export const deleteIncidencesRequest =
    (requestId: number, incidenceId: number, category: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(loadingIncidenceType(category, true));
            await requestApi.deleteIncidencesRequests(requestId, incidenceId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchIncidencesRequest(requestId, category));
        }
    };
export const getIncidencRequest =
    (requestId: number, incidenceId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIncidenceFormLoading(true));
            const response = await requestApi.getIncidenceRequest(requestId, incidenceId);
            dispatch(setIncidenceFormData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setIncidenceFormLoading(false));
        }
    };

export const postIncidencesRequest = (requestId: number, incidence: ICreateIncidence): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setIncidenceFormLoading(true));

            if (incidence.id) {
                await requestApi.updateIncidenceRequest(requestId, incidence);
            } else {
                await requestApi.createIncidenceRequest(requestId, incidence);
            }
            dispatch(fetchIncidencesRequest(requestId, incidence.category));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setIncidenceFormLoading(false));
            return false;
        }
    };
};

export const fetchRequestInvoiceConcepts =
    (idRequest: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadedInvoiced(true));
            const response = await requestApi.getRequestInvoiceConcepts(idRequest);
            dispatch(setRequestInvoiceConcepts(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadedInvoiced(false));
        }
    };

export const putSynchronizedRequestInvoiceConceptsAction =
    (idRequest: number, invoceConceptId: any): AppThunk =>
    async (dispatch) => {
        let response;
        try {
            dispatch(setLoadedInvoiced(true));
            response = await requestApi.putSynchronizedRequestInvoiceConcepts(idRequest, invoceConceptId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            response && dispatch(fetchRequestInvoiceConcepts(idRequest));
            dispatch(setLoadedInvoiced(false));
        }
    };

export const postRequestInvoiceConcepts = (idRequest: number, invoicedConcept: IConceptFormTariff): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setEditRequestInvoiceConceptFormLoading(true));

            if (invoicedConcept.id) {
                await requestApi.putRequestInvoiceConcepts(idRequest, invoicedConcept);
            } else {
                await requestApi.postRequestInvoiceConcepts(idRequest, invoicedConcept);
            }
            dispatch(fetchRequestInvoiceConcepts(idRequest));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setEditRequestInvoiceConceptFormLoading(false));
            return false;
        }
    };
};

export const deleteRequestInvoiceConcepts = (idRequest: number, invoicedConceptId: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setEditRequestInvoiceConceptFormLoading(true));

            await requestApi.deleteRequestInvoiceConcepts(idRequest, invoicedConceptId);
            dispatch(fetchRequestInvoiceConcepts(idRequest));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setEditRequestInvoiceConceptFormLoading(false));
            return false;
        }
    };
};

export const getRequestInvoiceConceptDataForm =
    (idRequest: number, invoicedConceptId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setEditRequestInvoiceConceptFormLoading(true));
            const response = await requestApi.getRequestInvoiceConceptData(idRequest, invoicedConceptId);
            dispatch(setEditRequestInvoiceConceptFormData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setEditRequestInvoiceConceptFormLoading(false));
        }
    };

export const patchRequestData =
    (request: IEditRequest, closeToSave?: () => any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.putRequestData(request);

            process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));

            if (closeToSave) {
                closeToSave();
            } else {
                dispatch(fetchEditRequestData(request.id.toString()));
            }
        } catch (error: Error | any) {
            handleError(error, dispatch);
        } finally {
            closeToSave && dispatch(setGlobalLoadingAction(false));
        }
    };

export const patchRequestDataToAssignAppointment =
    (request: IEditRequest, assignAppointment: IAppointmentAssign): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.putRequestData(request);
        } catch (error: Error | any) {
            handleError(error, dispatch);
        } finally {
            dispatch(resetAppointmentAvailable());
            dispatch(postAppointmentAssignAction(assignAppointment, request));
        }
    };

export const updateRequestStateId =
    (request: IEditRequest, stateId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));

            const requestCopy: IEditRequest = {
                ...request,
                stateId: stateId,
            };

            await requestApi.putRequestData(requestCopy);
            dispatch(fetchEditRequestData(request.id.toString()));
            dispatch(setRequestDocuments(undefined));
            dispatch(setRequestImages(undefined));
            dispatch(setRequestInvoiceConcepts(undefined));
            dispatch(setOrganismIncidenceRequest(undefined));
            dispatch(setOperationalIncidenceRequest(undefined));
        } catch (error: Error | any) {
            handleError(error, dispatch);
            const previousStateId = store.getState().editRequest.copyHeaderRequest.stateId;
            const payload: IGenericPayload = { name: "stateId", value: previousStateId };
            dispatch(updateRequestFormData(payload));
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const updateRequestDockId =
    (request: IEditRequest, dockId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));

            const requestCopy: IEditRequest = {
                ...request,
                dockId: dockId,
            };

            await requestApi.putRequestData(requestCopy);
            dispatch(fetchEditRequestData(request.id.toString()));
        } catch (error: Error | any) {
            handleError(error, dispatch);
        }
    };

export const postInspectionMultipleActuations = (requestIds: number[], params: IAddInspectionData): any => {
    return async (dispatch: any) => {
        try {
            await requestApi.postRequestListInspectionActuationMultiple(requestIds, params);
            throwSuccessToastrAsync();
            dispatch(fetchRequestListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        }
    };
};

export const postSetMultipleActuations = (requestId: number, actuations: any, delay?: boolean, updateAppointmentRequest?: boolean): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setActuationFormLoading(true));

            await requestApi.postSetMultipleActuations(requestId, actuations);
            if (delay) {
                process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
                dispatch(fetchSilentRequestListAction());
            }
            dispatch(fetchEditRequestInspection(requestId));
            if (updateAppointmentRequest) {
                dispatch(updateAppointmentValueOfRequest(requestId));
            }

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setActuationFormLoading(false));
        }
    };
};

export const deleteInspectionActuations =
    (requestId: number, inspectionId: number, actuations: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.deleteRequestInspectionActuation(requestId, inspectionId, actuations);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchEditRequestInspection(requestId));
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const getInspectionActuations =
    (requestId: number, inspectionId: number, actuationId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setActuationFormLoading(true));
            const response = await requestApi.getRequestInspectionActuation(requestId, inspectionId, actuationId);
            dispatch(setActuationFormData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setActuationFormLoading(false));
        }
    };

export const getReportRequest =
    (requestId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const response = await requestApi.getRequestReport(requestId);
            window.open(response);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

const loadingIncidenceType =
    (category: string, value: boolean): AppThunk =>
    async (dispatch) => {
        if (category === RequestIncidenceType.ORGANISM) {
            dispatch(setLoadingIncidenceOrganism(value));
        } else {
            dispatch(setLoadingIncidenceOperational(value));
        }
    };

export const deleteRequest = (requestId: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.deleteRequest(requestId);

            process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
            dispatch(resetRequestState());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };
};

export const getAvailableDatesRequestAction =
    (params?: { merchandiseCategoryId: string; transportUnitId: string; transportUnitSizeId: string; hasAppointment: boolean }): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const response = await requestApi.getRequestAvailableDates(params);
            dispatch(setAvailableDates(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const patchEditRequestCustomerNotified =
    (requestId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            await requestApi.patchCustomerNotified(requestId);
            dispatch(setUserNotified(false));
            dispatch(fetchEditRequestData(requestId));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const fetchRequestInspectionCard =
    (requestId: any, organizationId: string): AppThunk =>
    async (dispatch) => {
        if (process.env.REACT_APP_INSPECTION_CARD_URI) {
            const url = process.env.REACT_APP_INSPECTION_CARD_URI.replace(":id", requestId).replace(":organizationId", organizationId);
            window.open(url, "_blank");
        }
    };

export const getRequestInspectionCardLocks = async (requestId: any): Promise<{ isLock: boolean; message: string }> =>
    await requestApi.getInspectionCardLocks(requestId);

export const updateRequestFormData =
    (data: IGenericPayload): AppThunk =>
    async (dispatch) => {
        const { headerRequest } = store.getState().editRequest;
        let newRequest = {
            ...headerRequest,
            [data.name]: data.value,
        };

        dispatch(updateRequestHeaderProperty(newRequest));
    };
export const updateAppointmentValueOfRequest =
    (requestId: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const response = await requestApi.getRequestAppointmentData(requestId);
            const { headerRequest, copyHeaderRequest } = store.getState().editRequest;
            dispatch(setIsUpdatedDataRequest(mapHeaderRequestAppointment(copyHeaderRequest, response)));
            dispatch(updateRequestHeaderProperty(mapHeaderRequestAppointment(headerRequest, response)));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };

const mapHeaderRequestAppointment = (request: IEditRequest, response: IAppointmentData) => {
    return {
        ...request,
        date: new Date(response.date),
        appointmentTypeId: response.appointmentTypeId,
        appointmentTypeCode: response.appointmentTypeCode,
        appointmentTypeDescription: response.appointmentTypeDescription,
        appointmentCreate: response.appointmentCreate,
        appointmentAssigned: response.appointmentAssigned,
        appointment: response.appointment,
        lastModifiedBy: response.lastModifiedBy,
        lastModifiedOn: response.lastModifiedOn,
    };
};
