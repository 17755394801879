import { MasterAppointmentDockTimeZoneConfigurationBoardAPI } from "../../../../../api/masterAppointment/appointmentBoard/AppointmentDockTimeZoneConfiguration";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { IBoardHistoryAppointmentDockTimeZoneConfiguration } from "../../../../../models/masterAppointment/IAppointmentBoard";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterAppointmentDockForm } from "../../../../reducers/masterAppointment/appointmentBoard/appointmentDock/form";
import { setLoadingAppointmentDockTimeZoneConfigurationForm } from "../../../../reducers/masterAppointment/appointmentBoard/appointmentDockTimeZoneConfiguration/form";
import { fetchMasterAppointmentBoard } from "../appointmentBoard";

const appointmentApi = new MasterAppointmentDockTimeZoneConfigurationBoardAPI();

export const postMasterAppointmentDockTimeZoneConfiguration = (dashboardId: string, form: IBoardHistoryAppointmentDockTimeZoneConfiguration): any => {
    return async (dispatch: any) => {
        
        let customForm = {
            ...form,
            merchandiseTypeId: String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseTypeId : null,
        };

        try {
            dispatch(setLoadingAppointmentDockTimeZoneConfigurationForm(true));
            const response = await appointmentApi.postMasterAppointmentDockTimeZoneConfigurationBoard(dashboardId, customForm);
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingAppointmentDockTimeZoneConfigurationForm(false));
            return false;
        }
    };
};

export const updateMasterAppointmentDockTimeZoneConfiguration = (
    dashboardId: string,
    id: string,
    form: IBoardHistoryAppointmentDockTimeZoneConfiguration,
): any => {
    return async (dispatch: any) => {
        let customForm = {
            ...form,
            merchandiseTypeId: String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseTypeId : null,
        };

        try {
            dispatch(setLoadingAppointmentDockTimeZoneConfigurationForm(true));
            const response = await appointmentApi.updateMasterAppointmentDockTimeZoneConfigurationBoard(dashboardId, id, customForm);
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            dispatch(setLoadingAppointmentDockTimeZoneConfigurationForm(false));
            throwErrorToastrAsync(error);
            return false;
        } 
    };
};

export const deleteMasterAppointmentDockTimeZoneConfiguration = (dashboardId: string, id: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentDockForm(true));
            const response = await appointmentApi.deleteMasterAppointmentDockTimeZoneConfigurationBoard(dashboardId, id);
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingMasterAppointmentDockForm(false));
            dispatch(fetchMasterAppointmentBoard(dashboardId));
        }
    };
};
