import { createUseStyles } from "react-jss";

export const RequestFilterPopUpStyle = createUseStyles((theme: any) => ({
    requestFilterContainer: {
        width: "calc(100vw - 6.5rem)",
        height: "calc(100vh - 12rem)",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.md,
        paddingRight: theme.paddings.sizes.sm,
    },
    section: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        width: "100%",
    },

   
}));
