import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    newRequestDatePermisionAction,
    removeRequestDatePermisionAction,
} from "../../../../redux/actions/management/requestdatepermision/requestdatepermision";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
    resetManagementRequestDatePermissionState,
    setManagementRequestDatePermissionShowPopUp,
    updateRequestDatePermissionHeaderPropertyForm,
} from "../../../../redux/reducers/management/requestdatepermission/form";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ManagementRequestDatePermisionForm } from "./form/form";
import { Alert } from "../../../common/alert/alert";
import { NameIcons } from "../../../../assets/icons";
import { ManagementMessageDayTypeFormItem } from "../../../resources/masterAppointment/list/appointmentDatePermission/controller/messageDayTipe/messageDayType";
import { compileNameOfProperty } from "../../../../utils";
import { IManagementRequestDatePermissionForm } from "../../../../models/management/requestdatepermission/IRequestdatepermission";
import { IGenericPayload } from "../../../../models";
import { widths } from "../../../../styles/romeu/widths";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { RequiredFieldMessageStyle } from "../../../common/requiredMessage/requiredMessageStyle.jss";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ManagementRequestDatePermisionController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PERMISSIONS.FORM" });
    const dispatch = useAppDispatch();
    const { loading, form, showPopUp } = useAppSelector((store) => store.managementRequestDatePermissionForm);
    const { data } = useAppSelector((store) => store.catalog.transportUnits);
    const requestDayPermissionData = useAppSelector((store) => store.catalog.requestDayPermission);
    const onDimissed = () => {
        dispatch(resetManagementRequestDatePermissionState());
    };
    const onCancel = () => {
        dispatch(setManagementRequestDatePermissionShowPopUp(PopUpCodes.NONE));
    };

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(newRequestDatePermisionAction(form)).then((response: any) => {
                response && onCancel();
            });
        } else if (showPopUp === PopUpCodes.DELETE && form.id) {
            dispatch(removeRequestDatePermisionAction(form.id)).then((response: any) => {
                response && onCancel();
            });
        }
    };
    const isContainer = useMemo(() => {
        let transportUnit = data.find((item) => item.id === form.transportUnitId);
        if (transportUnit) {
            return transportUnit.container;
        }
        return false;
    }, [data, form.transportUnitId]);

    const disabledButtonForm = useMemo(() => {
        type KeysOfManagementRequestDatePermissionForm = keyof IManagementRequestDatePermissionForm;
        let keys: KeysOfManagementRequestDatePermissionForm[] = ["referenceDayId", "organismId", "transportUnitId", "time"];
        isContainer && keys.push("transportUnitSizeId");
        let item: any = form;
        let valid = false;
        for (let key of keys) {
            if (!item[key]) {
                valid = true;
                break;
            }
        }
        return valid;
    }, [form, isContainer]);

    const titlePopUp = useMemo(() => {
        switch (showPopUp) {
            case PopUpCodes.CREATE:
                return t("NEWPERMISION");
            case PopUpCodes.UPDATE:
                return t("UPDATEPERMISION");
            default:
                return "";
        }
    }, [showPopUp]);

    useEffect(() => {
        const payload: IGenericPayload = {
            name: compileNameOfProperty<IManagementRequestDatePermissionForm>("transportUnitSizeId"),
            value: null,
        };

        !isContainer && form.transportUnitSizeId && dispatch(updateRequestDatePermissionHeaderPropertyForm(payload));
    }, [isContainer]);

    return (
        <>
            <PopUpForm
                content={<ManagementRequestDatePermisionForm isContainer={isContainer} />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                height={"35vh"}
                width={"35vw"}
                isLoading={loading || requestDayPermissionData.loading}
                isBlocking={false}
                onDimissed={onDimissed}
                leftButtonContent={<RequiredFieldsMessage />}
                underButtonContent={
                    <Alert
                        height={"25vh"}
                        icon={NameIcons.INFOICON}
                        title={t("INFORMATION")}
                        data={requestDayPermissionData.data.map((item) => {
                            return (
                                <ManagementMessageDayTypeFormItem
                                    key={item.id}
                                    description={item.info}
                                    title={item.description ?? DefaultValues.EMPTY_LIST}
                                />
                            );
                        })}
                    />
                }
                isDisabledButton={disabledButtonForm}
            />

            <PopUpConfirmation
                onDimissed={onDimissed}
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancel}
                onAccept={onSave}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                loading={loading}
            />
        </>
    );
};
