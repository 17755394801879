import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementAuthorizedTransportUnitNumberList } from "./list/list";
import { ManagementAuthorizedTransportUnitNumberFilter } from "./filter/filter";
import { ManagementAuthorizedTransportUnitNumberController } from "./controller/controller";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { useAppDispatch } from "../../../redux/hooks";
import { setShowPopUpAuthorizedTransportUnitNumberForm } from "../../../redux/reducers/management/authorizedTransportUnitNumber/form";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

export const ManagementAuthorizedTransportUnitNumberContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.AUTHORIZED_TRANSPORT_UNIT_NUMBER" });
    const dispatch = useAppDispatch();

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setShowPopUpAuthorizedTransportUnitNumberForm(PopUpCodes.CREATE));
                        }}
                    />
                }
            />
            <ManagementAuthorizedTransportUnitNumberFilter />
            <ManagementAuthorizedTransportUnitNumberList />
            <ManagementAuthorizedTransportUnitNumberController />
        </>
    );
};
