import { CookieSectionFormat, IItemTranslate } from "../controller/controller";

export const PrivacyPolicyContainer = () => {
    const translate: IItemTranslate[] = [
        {
            header: "PRIVACY_POLICY.TITLE",
            description: "PRIVACY_POLICY.TITLE_DESCRIPTION",
        },

        {
            header: "PRIVACY_POLICY.WHO_WILL_PROCESS_YOUR_PERSONAL_DATA_HEADER",
            description: "PRIVACY_POLICY.WHO_WILL_PROCESS_YOUR_PERSONAL_DATA_DESCRIPTION",
        },

        {
            header: "PRIVACY_POLICY.NEED_TO_PROCESS_YOUR_PERSONAL_DATA_HEADER",
            description: "PRIVACY_POLICY.NEED_TO_PROCESS_YOUR_PERSONAL_DATA_DESCRIPTION",
        },
        {
            header: "PRIVACY_POLICY.CONTACT_FORM_HEADER",
            description: "PRIVACY_POLICY.CONTACT_FORM_DESCRIPTION",
        },

        {
            header: "PRIVACY_POLICY.ACCESS_AND_USE_OF_THE_EMPLOYMENT_PORTAL_HEADER",
            description: "PRIVACY_POLICY.ACCESS_AND_USE_OF_THE_EMPLOYMENT_PORTAL_DESCRIPTION",
            listHeader: "PRIVACY_POLICY.ACCESS_AND_USE_OF_THE_EMPLOYMENT_PORTAL_LIST",
        },
        {
            header: "PRIVACY_POLICY.RESPONSIBILITY_OF_THE_USER_HEADER",
            description: "PRIVACY_POLICY.RESPONSIBILITY_OF_THE_USER_DESCRIPTION",
            listHeader: "PRIVACY_POLICY.RESPONSIBILITY_OF_THE_USER_LIST",
        },

        {
            header: "PRIVACY_POLICY.EXERCISE_OF_RIGHTS_HEADER",
            description: "PRIVACY_POLICY.EXERCISE_OF_RIGHTS_DESCRIPTION",
            listHeader: "PRIVACY_POLICY.EXERCISE_OF_RIGHTS_LIST",
        },
        
        {
            header: "PRIVACY_POLICY.SECURITY_MEASURES_HEADER",
            description: "PRIVACY_POLICY.SECURITY_MEASURES_DESCRIPTION",
        },
        {
            description: "LAST_UPDATE",
        },
    ];

    return <CookieSectionFormat translate={translate} />;
};
