import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestGraph } from "../../../../models/statistics/IRequestGraph";

interface IInitialState {
    data: IRequestGraph;
    loading: boolean;
}

const initialState: IInitialState = {
    data: {
        logoDocksBase64: "",
        logoOrganizationBase64: "",
        requestGraphDateStates: [],
        totalContainers: 0,
        totalTrucks: 0,
    },
    loading: false,
};

export const statiticsRequestGraphSlice = createSlice({
    name: "statiticsInspectionList",
    initialState,
    reducers: {
        reset: () => initialState,

        setStatisticsRequestGraph: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { reset, setStatisticsRequestGraph, setLoading } = statiticsRequestGraphSlice.actions;

export default statiticsRequestGraphSlice.reducer;
