import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

import { IGenericPayload } from "../../../../../models";
import { IConfigDockMerchandiseListItem } from "../../../../../models/config/dockMerchandise";
import { fetchConfigDockMerchandiseAction } from "../../../../../redux/actions/config/dockMerchandise";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setConfigDockMerchandiseShowPopUp, updateFormProperty } from "../../../../../redux/reducers/config/dockMerchandise/form";
import { updateSortDockMerchandiseColumn } from "../../../../../redux/reducers/config/dockMerchandise/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty, onUpdateSortedColumnUtil } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { MasterBlocksColumnsStyle } from "./columnStyle.jss";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";

export const ConfigDockMerchandiseListColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterBlocksColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();
    const { sort } = useAppSelector((state) => state.configDockMerchandiseList);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().configDockMerchandiseList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortDockMerchandiseColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("dockDescription"),
            minWidth: 130,
            maxWidth: 190,
            name: compileNameOfProperty<IConfigDockMerchandiseListItem>("dockDescription"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("dockDescription")}
                    title={t("DOCK_MERCHANDISE.DOCK")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("dockDescription"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => <span>{item.dockDescription}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("merchandiseTypeDescription"),
            minWidth: 130,
            maxWidth: 190,
            name: t("MERCHANDISE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("merchandiseTypeDescription")}
                    title={t("MERCHANDISE_TYPE")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("merchandiseTypeDescription"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => <span>{item.merchandiseTypeDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("merchandiseOriginDescription"),
            minWidth: 130,
            maxWidth: 180,
            name: t("MERCHANDISE_ORIGIN"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("merchandiseOriginDescription")}
                    title={t("MERCHANDISE_ORIGIN")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("merchandiseOriginDescription"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => <span>{item.merchandiseOriginDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("humanConsumption"),
            minWidth: 130,
            maxWidth: 130,
            name: t("HUMAN_CONSUMPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("humanConsumption")}
                    title={t("HUMAN_CONSUMPTION")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("humanConsumption"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => (
                <span>
                    <BooleanValue value={item.humanConsumption}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("halalCertified"),
            minWidth: 130,
            maxWidth: 130,
            name: t("HALAL_CERTIFIED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("halalCertified")}
                    title={t("HALAL_CERTIFIED")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("halalCertified"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => (
                <span>
                    <BooleanValue value={item.halalCertified}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("fumigated"),
            minWidth: 100,
            maxWidth: 100,
            name: t("FUMIGATED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("fumigated")}
                    title={t("FUMIGATED")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("fumigated"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => (
                <span>
                    <BooleanValue value={item.fumigated}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IConfigDockMerchandiseListItem>("organicProduct"),
            minWidth: 135,
            maxWidth: 135,
            name: t("ORGANIC_PRODUCT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IConfigDockMerchandiseListItem>("organicProduct")}
                    title={t("ORGANIC_PRODUCT")}
                    styles={styles}
                    sort={sort}
                    isSortable={true}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IConfigDockMerchandiseListItem>("organicProduct"))}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => (
                <span>
                    <BooleanValue value={item.organicProduct}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: "actions",
            minWidth: 50,
            maxWidth: 100,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IConfigDockMerchandiseListItem) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setConfigDockMerchandiseShowPopUp(PopUpCodes.UPDATE));
                            dispatch(fetchConfigDockMerchandiseAction(item.id));
                        }}
                    />
                    <Separator size="auto" />
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            const payload: IGenericPayload = {
                                name: compileNameOfProperty<IConfigDockMerchandiseListItem>("id"),
                                value: item.id,
                            };
                            dispatch(updateFormProperty(payload));
                            dispatch(setConfigDockMerchandiseShowPopUp(PopUpCodes.DELETE));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
