export const LEGALNOTICEDATA = {
    COMPANY: 'DOCKS LOGISTICS SPAIN, S.A. (en adelante "DOCKS LOGISTICS SPAIN, S.A."),',
    REGISTERED_OFFICE: "Avda. Puerto de Alicante 11 - 46190 Valencia, España.",
    CIF: "A81395220",
    PUBLIC_REGISTRY: "Registro Mercantil de Valencia , Tomo 7.602, Libro 4.902, Folio 1, Hoja V-92.450. Inscripción -",
    PHONE: "+34 96 335 90 02",
    EMAIL: "dpo@docks.es",
};
export const URL_DIRECTIONS = {
    CHROME: "http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647",
    EXPLORE: "http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9",
    FIREFOX: "http://support.mozilla.org/es/kb/Borrar%20cookies",
    SAFARY: "http://support.apple.com/kb/ph5042",
    OPERA: "http://help.opera.com/Windows/11.50/es-ES/cookies.html",
};

export const  TechnicalCookiesData = [
    {
        name: "oidc.user",
        description: "Información del usuario obtenida a través de OpenID Connect (OIDC)",
        type: "Sesión",
    },
    {
        name: "i18next",
        description: "Idioma seleccionado por el usuario para la internacionalización",
        type: "Persistente",
    },
    {
        name: "accepted_cookies",
        description: "Identifica si el usuario ha aceptado el uso de cookies",
        type: "Persistente",
    },
];