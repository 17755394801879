import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useTheme } from "react-jss";
import { DirectionType } from "../../../../common/enum/DirectionType";
import { radioButtonDefaultOptions } from "../../../../utils";
import { RadioButtonGroupStyled } from "./radioButtonGroup.jss";

interface IRadioButtonProps {
    name?: string;
    label?: string;
    selectedKey?: string;
    options?: IChoiceGroupOption[];
    onChange: (option: any, name?: any) => void;
    direction?: DirectionType;
    required?: boolean;
}

export const RadioButtonGroup: React.FC<IRadioButtonProps> = ({ name, label, onChange, selectedKey, options, direction, required }) => {
    const theme = useTheme();
    direction = direction ?? DirectionType.horizontal;
    const styles = RadioButtonGroupStyled({ theme, direction });

    const _onChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        onChange(option?.key!, name);
    };

    return (
        <div className={styles.radioButtonContainer}>
            <ChoiceGroup
                defaultSelectedKey={selectedKey}
                options={options ?? radioButtonDefaultOptions}
                onChange={_onChange}
                label={label}
                required={required}
            />
        </div>
    );
};
