import { ErrorType } from "../../common/enum/ErrorType";

export interface IErrorHandler {
    error: boolean;
    type: ErrorType | null;
}

export const emptyErrorHandler = (): IErrorHandler => {
    return {
        error: false,
        type: null
    };
};
