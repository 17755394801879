import es from "date-fns/locale/es";
import React from "react";
import { DayPicker, DayPickerProps } from "react-day-picker";
import { useTheme } from "react-jss";
import { DayPickerStyled } from "./dayPicker.jss";

interface Props {
    onSelect: (date: Date | undefined) => void;
    selected: Date | undefined;
    defaultMonth: Date;
    mode: "single";
    className?: string;
}

export const DayPickerOnlyComponent: React.FC<Props & DayPickerProps> = ({ mode, selected, onSelect, defaultMonth }) => {
    const theme = useTheme();
    const styles = DayPickerStyled({ theme });
    const css = `
                .rdp {
                    margin: 0; 
                }
                .rdp-month {
                    padding: 15px;
                    margin: none;
                    color: black;
                }
                .rdp-caption_label {
                    text-transform: uppercase;
                }
                .rdp-button:focus, .rdp-button:active {
                    border: none;
                }
                .rdp-button:hover:not([aria-disabled='true']) {
                    color: black;
                }
                `;

    return (
        <div>
            <style>{css}</style>
            <DayPicker
                locale={es}
                mode={mode}
                defaultMonth={defaultMonth}
                selected={selected}
                onSelect={onSelect}
                modifiersClassNames={{
                    selected: styles.selected,
                    today: styles.today,
                }}
            />
        </div>
    );
};
