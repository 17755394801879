import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../models/common/IGenericPayload";
import { IUpcomingAppointmentSortFilter } from "../../../models/upcomingAppointmentSort/IUpcomingAppointmentSort";
import { DefaultValues } from "../../../common/enum/DefaultValues";


interface IUpcomingAppointmentsSortFilterState {
    filter: IUpcomingAppointmentSortFilter;
}

const initialState: IUpcomingAppointmentsSortFilterState = {
    filter: {
        customerId: DefaultValues.DEFAULT_ALL_SELECTOR as any,
        inspectorId: DefaultValues.DEFAULT_ALL_SELECTOR as any,
        organismId: DefaultValues.DEFAULT_ALL_SELECTOR as any,
        endDate:new Date().toDateString(),
        startDate:new Date().toDateString(),
    },
};

export const upcomingAppointmentsSortFilterSlice = createSlice({
    name: "upcomingAppointmentsFilterSlice",
    initialState,
    reducers: {
        resetUpcomingAppointmentsSortFilter: () => initialState,
        updateUpcomingAppointmentsSortFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetUpcomingAppointmentsSortFilter, updateUpcomingAppointmentsSortFilterProperty } = upcomingAppointmentsSortFilterSlice.actions;

export default upcomingAppointmentsSortFilterSlice.reducer;
