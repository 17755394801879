import { ConstrainMode, DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { UtiListStyle } from "./listStyle";
import { IConfigCreateRequest, ITransportUnitData, ITransportUnitDataWithIndex } from "../../../../../../models";
import { useAppSelector } from "../../../../../../redux/hooks";
import { LabelNumber } from "../../../../../common/labels/labelNumber/labelNumber";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../../assets/icons";
import { Separator } from "../../../../../common/separator/separator";
import { BooleanValue } from "../../../../../common/booleanValue/booleanValue";

interface IProps {
    units: ITransportUnitData[];
    editCard: (unit: ITransportUnitData, indexOrder: number) => void;
    deleteCard: (indexOrder: number) => void;
    isContainer: boolean;
    schema: IConfigCreateRequest;
}
export const UtiList: React.FC<IProps> = ({ units, editCard, deleteCard, isContainer, schema }) => {
    const theme = useTheme();
    const styles = UtiListStyle({ theme });
    const { t } = useTranslation("common");
    const containerSizes = useAppSelector((store) => store.catalog.transportUnitSizes.data);

    const unitsWithIndex: ITransportUnitDataWithIndex[] = units.map((item, index) => {
        return { transportUnit: item, index };
    });

    const utiColumns: IColumn[] = useMemo(
        () => [
            {
                key: "transportUnitSizeId",
                name: t("CREATE_REQUEST.FIELDS.CONTAINER_SIZE"),
                fieldName: "transportUnitSizeId",
                minWidth: 200,
                maxWidth: 250,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return (
                        <span>
                            {item.transportUnit?.transportUnitSizeId &&
                                containerSizes?.find((cont) => cont.id === item.transportUnit?.transportUnitSizeId)?.description}
                        </span>
                    );
                },
            },
            {
                key: "transportUnitNumber",
                name: t("CREATE_REQUEST.FIELDS.TRANSPORT_UNIT"),
                fieldName: "transportUnitNumber",
                minWidth: 100,
                maxWidth: 150,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return <span>{item.transportUnit?.transportUnitNumber}</span>;
                },
            },
            {
                key: "packages",
                name: t("CREATE_REQUEST.FIELDS.PACKAGES"),
                fieldName: "packages",
                minWidth: 60,
                maxWidth: 75,
                data: "string",
                isPadded: false,

                isRowHeader: true,
                isResizable: true,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return <span>{item.transportUnit?.packages || "-"}</span>;
                },
            },
            {
                key: "grossWeight",
                name: t("CREATE_REQUEST.FIELDS.GROSS_KG"),
                fieldName: "grossWeight",
                minWidth: 60,
                maxWidth: 75,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item?: ITransportUnitDataWithIndex) => {
                    return <LabelNumber value={item?.transportUnit?.grossWeight || "-"} />;
                },
            },
            {
                key: "palletized",
                name: t("CREATE_REQUEST.FIELDS.PALLETIZED"),
                fieldName: "palletized",
                minWidth: 70,
                maxWidth: 75,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return (
                        <span>
                            <BooleanValue value={item.transportUnit?.palletized} />
                        </span>
                    );
                },
            },
            {
                key: "seals",
                name: t("CREATE_REQUEST.FIELDS.ORIGIN_SEAL"),
                fieldName: "seals",
                minWidth: 110,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return (
                        <div
                            className={styles.sealsContainer}
                            title={item.transportUnit.seals?.flatMap((seal) => seal)?.join(" , ")}
                        >
                            {item.transportUnit.seals.map((ut) => (
                                <div className={styles.seal}>{ut}</div>
                            ))}
                        </div>
                    );
                },
            },
            {
                key: "batches",
                name: t("CREATE_REQUEST.FIELDS.BATCH"),
                fieldName: "batches",
                minWidth: 110,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return (
                        <div
                            className={styles.sealsContainer}
                            title={item.transportUnit.batches?.flatMap((seal) => seal)?.join(" , ")}
                        >
                            {item.transportUnit.batches?.map((ut) => (
                                <div className={styles.seal}>{ut}</div>
                            ))}
                        </div>
                    );
                },
            },
            {
                key: "actionButtons",
                fieldName: "actionButtons",
                name: "",
                minWidth: 60,
                maxWidth: 75,
                data: "string",
                isPadded: false,
                isRowHeader: false,
                isResizable: false,
                onRender: (item: ITransportUnitDataWithIndex) => {
                    return (
                        <span className={styles.icons}>
                            <CustomIcons
                                iconName={NameIcons.EDIT}
                                onClick={() => editCard(item.transportUnit, item.index)}
                            />
                            <Separator size="auto" />
                            <CustomIcons
                                iconName={NameIcons.REMOVE}
                                onClick={() => deleteCard(item.index)}
                            />
                        </span>
                    );
                },
            },
        ],
        [containerSizes],
    );

    const columns = useMemo(() => {
        let filteredColumns = [...utiColumns];

        if (!schema.batches.visible) {
            filteredColumns = filteredColumns.filter((item) => item.key !== "batches");
        }

        if (!isContainer) {
            filteredColumns = filteredColumns.filter((item) => item.key !== "transportUnitSizeId");
        }
        return filteredColumns;
    }, [isContainer, schema.batches.visible]);

    return (
        <div className={styles.listContainer}>
            <DetailsList
                items={unitsWithIndex}
                columns={columns}
                selectionMode={SelectionMode.none}
                constrainMode={ConstrainMode.horizontalConstrained}
            />
        </div>
    );
};
