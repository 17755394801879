import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptyUserAuthData, IUserAuthData } from "../../../models/user/IUserAuthData";

interface AuthState {
    profile: IUserAuthData;
    isProfileLoaded: boolean;
}
const initialState: AuthState = {
    profile: emptyUserAuthData(),
    isProfileLoaded: false,
};

export const userDataSlice = createSlice({
    name: "userData",
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<IUserAuthData>) => {
            state.profile = action.payload;
            state.isProfileLoaded = true;
        },
        setImpersonatedOrganismId :(state, action: PayloadAction<number | undefined>) => {
            state.profile.organismId = action.payload;
        },
        setPrincipalRole: (state, action: PayloadAction<string>) => {
            state.profile.principalRole = action.payload;
        },
        resetUserAuth: () => initialState,
    },
});

export const { setUserData, resetUserAuth, setImpersonatedOrganismId, setPrincipalRole } = userDataSlice.actions;

export default userDataSlice.reducer;
