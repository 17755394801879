import { RequestApi } from "../../../../api/request";
import { OrderColumn } from "../../../../common/enum/OrderColumn";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";

import { loadingRequestExportList, updateExport } from "../../../reducers/request/list/export";
import { AppThunk, store } from "../../../store";
import { capitalizeAllFirstLetter, completeRequestFilter } from "../list/requestList";

const requestApi = new RequestApi();

export const fetchRequestExportAction = (): AppThunk => async (dispatch) => {
    try {
        const { startDate, endDate, filter } = store.getState().requestListFilter;
        const { id } = store.getState().role.roleData;

        const { sort } = store.getState().requestlist;
        const fullFilter = completeRequestFilter({ ...filter }, startDate, endDate);
        dispatch(loadingRequestExportList(true));
        let customOrderBy = Array.isArray(sort.column)
            ? sort.column.map((item: string, index: number) => {
                  return {
                      field: capitalizeAllFirstLetter(item),
                      order: index === 0 ? sort.orderColumn : OrderColumn.ASC,
                  };
              })
            : [
                  {
                      field: capitalizeAllFirstLetter(sort.column),
                      order: sort.orderColumn,
                  },
              ];
        const response: string = await requestApi.getExportRequestsAsync(customOrderBy, fullFilter, id);
        dispatch(updateExport(response));
        if (response) {
            window.open(response, "_blank");
        }
    } catch (error) {
        dispatch(HandleAxiosError(error));
    } finally {
        dispatch(loadingRequestExportList(false));
    }
};

export const fetchRequestReportAction = (): AppThunk => async (dispatch) => {
    try {
        const { startDate, endDate, filter } = store.getState().requestListFilter;
        const { id } = store.getState().role.roleData;

        const { sort } = store.getState().requestlist;
        const fullFilter = completeRequestFilter({ ...filter }, startDate, endDate);
        dispatch(loadingRequestExportList(true));
        let customOrderBy = Array.isArray(sort.column)
            ? sort.column.map((item: string, index: number) => {
                  return {
                      field: capitalizeAllFirstLetter(item),
                      order: index === 0 ? sort.orderColumn : OrderColumn.ASC,
                  };
              })
            : [
                  {
                      field: capitalizeAllFirstLetter(sort.column),
                      order: sort.orderColumn,
                  },
              ];
        const response: string = await requestApi.getReportRequestsAsync(customOrderBy, fullFilter, id);
        dispatch(updateExport(response));
        if (response) {
            window.open(response, "_blank");
        }
    } catch (error) {
        dispatch(HandleAxiosError(error));
    } finally {
        dispatch(loadingRequestExportList(false));
    }
};
