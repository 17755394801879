import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IBoardHistoryAppointmentDock } from "../../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../redux/hooks";
import { updateMasterAppointmentDockProperty } from "../../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDock/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../../../../../utils";
import { FormField } from "../../../../../../../../../../common/fields/fields";
import { MasterBoardHistoryAppointmentDockFormStyle } from "./formStyle.jss";
import { DefaultValues } from "../../../../../../../../../../../common/enum/DefaultValues";

export const MasterBoardHistoryAppointmentDockForm = () => {
    const theme = useTheme();
    const style = MasterBoardHistoryAppointmentDockFormStyle({ theme });

    const { form } = useAppSelector((state) => state.masterAppointmentDockForm);
    const dispatch = useAppDispatch();
    const { register } = useForm<IBoardHistoryAppointmentDock>({ mode: "onChange" });

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD" });

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateMasterAppointmentDockProperty(payload));
        },
        [dispatch],
    );

    return (
        <div className={style.form}>
            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("FORM.APPOINTMENT_CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IBoardHistoryAppointmentDock>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("FORM.DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IBoardHistoryAppointmentDock>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />
            </div>
        </div>
    );
};
