import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IRequestFilter, IUserOrganization } from "../../../../../../../models";
import { IConfigFilterRequest } from "../../../../../../../models/requests/IConfigFilterRequest";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { BooleaMultiSelector } from "../../../../../../common/selectors/multiSelectors/booleanMultiSelector/booleanMultiSelector";
import { MultipleCustomStateSelector } from "../../../../../common/selectors/customStates/multipleCustomState";
import { MultipleMerchandiseOriginSelector } from "../../../../../common/selectors/merchandiseOrigin/multipleMerchandiseOrigin";
import { MultipleMerchandiseTypeSelector } from "../../../../../common/selectors/merchandiseType/multipleMerchandiseType";
import { RequestFilterPivotStyle } from "../filterStyle.jss";
import { TextInput } from "../../../../../../common/inputs/textInput";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    onInputChange: (event: any, name: string) => void;
    onEnterKey: () => void;
    schema: IConfigFilterRequest;
    organization?: IUserOrganization;
}
export const RequestMerchandisePivotFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, onNullableBooleanChange, onEnterKey, onInputChange, schema, organization }) => {
    const theme = useTheme();
    const styles = RequestFilterPivotStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });

    return (
        <div className={`${styles.containerPivot}`}>
            {schema.merchandiseTypeIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleMerchandiseTypeSelector
                        selectedKeys={filter.merchandiseTypeId}
                        propertyName={compileNameOfProperty<IRequestFilter>("merchandiseTypeId")}
                        title={t("MERCHANDISE_TYPE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.merchandiseOriginIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleMerchandiseOriginSelector
                        selectedKeys={filter.merchandiseOriginId}
                        propertyName={compileNameOfProperty<IRequestFilter>("merchandiseOriginId")}
                        title={t("MERCHANDISE_ORIGIN")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.customsStateIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleCustomStateSelector
                        selectedKeys={filter.customsStateId}
                        propertyName={compileNameOfProperty<IRequestFilter>("customsStateId")}
                        title={t("CUSTOMS_STATE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.halalCertifiedVisible && organization?.halalCertified && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.halalCertified}
                        propertyName={compileNameOfProperty<IRequestFilter>("halalCertified")}
                        title={t("HALAL")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.dryChargeVisible && organization?.dryCharge && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.dryCharge}
                        propertyName={compileNameOfProperty<IRequestFilter>("dryCharge")}
                        title={t("DRY_CARGO")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.organicProductVisible && organization?.organicProduct && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.organicProduct}
                        propertyName={compileNameOfProperty<IRequestFilter>("organicProduct")}
                        title={t("ORGANIC_PRODUCT")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.fumigatedVisible && organization?.fumigated && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.fumigated}
                        propertyName={compileNameOfProperty<IRequestFilter>("fumigated")}
                        title={t("FUMIGATED_CONTAINER")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.descriptionVisible && (
                <div className={styles.filterItem}>
                    <TextInput
                        value={filter.description}
                        propertyName={compileNameOfProperty<IRequestFilter>("description")}
                        label={t("DESCRIPTION")}
                        onChange={onInputChange}
                        onEnter={onEnterKey}
                    />
                </div>
            )}
        </div>
    );
};
