import { createUseStyles } from "react-jss";

export const BubbleInspectorListStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    inpector: {
        display: "flex",
    },
    iconRange: {
        display: "flex",
        alignItems: "center",
        " & i": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.palette.purple,
        },
        " & .arrow": {
            transform: "none",
        },
        " & .arrowUp": {
            transform: "rotate(180deg)",
        },
    },
    inspectorName: {
        maxWidth: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
}));
