import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IConfigMerchandiseCategoryListItem } from "../../../../models/config/merchandiseCategory";

interface IConfigMerchandiseCategoryState {
    list?: IConfigMerchandiseCategoryListItem[];
    sort: ISortState;
    loading: boolean;
}

const initialState: IConfigMerchandiseCategoryState = {
    sort: emptySortState(),
    loading: false,
};

export const configMerchandiseCategoryListSlice = createSlice({
    name: "configMerchandiseCategoryListSlice",
    initialState,
    reducers: {
        resetConfigMerchandiseCategoryList: () => initialState,
        setConfigMerchandiseCategoryList: (state, action: PayloadAction<IConfigMerchandiseCategoryListItem[]>) => {
            state.list = action.payload;
        },
        updateSortConfigMerchandiseCategoryColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setConfigMerchandiseCategoryLoadingList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const {
    resetConfigMerchandiseCategoryList,
    setConfigMerchandiseCategoryList,
    setConfigMerchandiseCategoryLoadingList,
    updateSortConfigMerchandiseCategoryColumn,
} = configMerchandiseCategoryListSlice.actions;

export default configMerchandiseCategoryListSlice.reducer;
