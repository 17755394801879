import { Method } from "../../../../common/enum";
import { IBoardHistoryAppointmentDockTimeZoneConfiguration } from "../../../../models/masterAppointment/IAppointmentBoard";
import { apiFetchOAuthWithClaims } from "../../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment/dashboards`;

export class MasterAppointmentDockTimeZoneConfigurationBoardAPI {
    async getMasterAppointmentDockTimeZoneConfigurationBoard(
        dashboardId: string,
        id: string,
    ): Promise<IBoardHistoryAppointmentDockTimeZoneConfiguration> {
        const url = `${baseURL}/${dashboardId}/docktimezones/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IBoardHistoryAppointmentDockTimeZoneConfiguration>();
    }

    async postMasterAppointmentDockTimeZoneConfigurationBoard(
        dashboardId: string,
        form: any,
    ): Promise<number> {
        const url = `${baseURL}/${dashboardId}/docktimezones`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<number>();
    }

    async updateMasterAppointmentDockTimeZoneConfigurationBoard(
        dashboardId: string,
        id: string,
        form: any,
    ): Promise<number> {
        const url = `${baseURL}/${dashboardId}/docktimezones/${id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<number>();
    }

    async deleteMasterAppointmentDockTimeZoneConfigurationBoard(dashboardId: string, id: string): Promise<boolean> {
        const url = `${baseURL}/${dashboardId}/docktimezones/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
