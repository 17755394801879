import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { sendUserRepresentative } from "../../../../redux/actions/user/profile/userProfile";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetUserRepresentative, setRepresentativeShowPopup } from "../../../../redux/reducers/user/representatives/userRepresentatives";
import { heights } from "../../../../styles/romeu/heights";
import { widths } from "../../../../styles/romeu/widths";
import { ButtonPrimaryInverseWithBorder } from "../../../common/buttons/buttons";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { UserProfileEntityContainerStyle } from "./containerStyle.jss";
import { UserRepresentativeDataForm } from "./data/form/form";
import { UserRepresentativeList } from "./data/list/list";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

export const UserProfileRepresentativeContainer = () => {
    const theme = useTheme();
    const style = UserProfileEntityContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE.REPRESENTATIVE" });

    const { form, loading, showPopUp } = useAppSelector((store) => store.userRepresentative);

    const isDisabledButton = useMemo(() => {
        return !form.representativeDocumentNumber || !form.document;
    }, [form]);

    const handleClickUploadDocument = () => {
        dispatch(sendUserRepresentative()).then((response: any) => {
            if (response) {
                onCloseForm();
            }
        });
    };

    const onCloseForm = () => {
        dispatch(resetUserRepresentative());
    };

    return (
        <div className={style.representativeContainer}>
            <div className={style.addRepresentative}>
                <ButtonPrimaryInverseWithBorder
                    handleClick={() => {
                        dispatch(setRepresentativeShowPopup(PopUpCodes.CREATE));
                    }}
                    title={`+ ${t("BUTTONS.ADD_REPRESENTATIVE")} `}
                />
            </div>

            <UserRepresentativeList />
            <PopUpForm
                isLoading={loading}
                isVisible={showPopUp === PopUpCodes.CREATE}
                content={<UserRepresentativeDataForm />}
                title={t("TITLES.POPUP_TITLE")}
                width={widths.representative.width}
                height={heights.representative.heigth}
                onSave={handleClickUploadDocument}
                onCancel={onCloseForm}
                leftButtonContent={<RequiredFieldsMessage />}
                isDisabledButton={isDisabledButton}
            />
        </div>
    );
};
