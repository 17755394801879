import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons/buttons";
import { CustomIcons } from "../customIcons/customIcons";
import { UploadFileStyle } from "./uploadFile.jss";
import { useTheme } from "react-jss";
import { ColorType } from "../../../common/enum/ColorType";
import { ContentType } from "../../../common/enum/files/ContentType";
import { FileSize } from "../../../common/enum/files/FileSize";

interface Props {
    name?: string;
    title?: string;
    onChangeFile: (file: File | undefined, name?: string) => void;
    allowedExtensions?: ContentType[];
    buttonStyle?: ColorType;
    maxSize: FileSize;
    storedFileName?: string;
    disabled?: boolean;
    removeDocument?: () => any;
    file: any;
    hiddeRemoveDocument?: boolean;
}

export const UploadFileCustom: React.FC<Props> = ({
    removeDocument,
    name,
    onChangeFile,
    allowedExtensions,
    title,
    buttonStyle,
    maxSize,
    disabled,
    file,
    hiddeRemoveDocument,
}) => {
    const firstUpdate = useRef(true);
    const { t } = useTranslation("messages");

    const [selectedFile, setFile] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const theme = useTheme();
    const styles = UploadFileStyle({ theme, uri: selectedFile && selectedFile.uri ? selectedFile.uri : "", hiddeRemoveDocument });
    const inputFileRef = useRef<HTMLInputElement>(null);

    const onUploadFile = (event: any) => {
        const file = event.target.files[0] as File;
        const isValid = isFileValid(file);
        if (isValid) {
            setErrorMessage("");
            setFile(file);
            onChangeFile(file, name);
        }
    };

    const isFileValid = (file: File): boolean => {
        if (!file || !file.size) {
            setErrorMessage(t("INPUT_FILE_ERRORS.NO_CONTENT"));
            return false;
        }
        if (file.size > maxSize) {
            setErrorMessage(t("INPUT_FILE_ERRORS.MAX_SIZE_DOCUMENT_EXCEED"));
            return false;
        }
        if (allowedExtensions && !allowedExtensions?.includes(file.type as ContentType)) {
            setErrorMessage(t("INPUT_FILE_ERRORS.CONTENT_TYPE_INVALID"));
            return false;
        }
        return true;
    };

    const clickInputFile = () => {
        inputFileRef.current?.click();
    };

    const onDownloadFile = () => {
        selectedFile.uri && window.open(selectedFile.uri, "_blank");
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }
        selectedFile && onChangeFile(selectedFile, name);
    }, []);

    const renderFileSelected = (): JSX.Element => {
        return (
            <>
                <div className={styles.customSelectedDocumentContainer}>
                    <span
                        onClick={() => {
                            selectedFile.uri && onDownloadFile();
                        }}
                        className={styles.documentUri}
                    >
                        {selectedFile.uri && (
                            <CustomIcons
                                iconName={"Attach"}
                                className={styles.documentUri}
                                margin={"0px"}
                            ></CustomIcons>
                        )}

                        <span className={` ${styles.customSelectedDocumentDescription}`}>
                            {selectedFile?.fileName || selectedFile?.documentName || selectedFile?.name}
                        </span>
                    </span>
                    {!hiddeRemoveDocument && (
                        <div>
                            <CustomIcons
                                iconName="Cancel"
                                className={styles.removeIcon}
                                onClick={() => {
                                    setFile(undefined);
                                    removeDocument ? removeDocument() : onChangeFile(undefined);
                                }}
                                margin={"0px"}
                            ></CustomIcons>
                        </div>
                    )}
                </div>
            </>
        );
    };
    const renderUploadButton = (): JSX.Element => {
        return (
            <>
                <Button
                    title={title ?? ""}
                    handleClick={clickInputFile}
                    icon="CloudUpload"
                    color={buttonStyle}
                    disabled={disabled}
                />
                <input
                    autoComplete={"none"}
                    type="file"
                    className={styles.input}
                    ref={inputFileRef}
                    accept={String(allowedExtensions)}
                    name={name}
                    onChange={onUploadFile}
                />
                {errorMessage && <div className={styles.errorContainer}>{errorMessage}</div>}
            </>
        );
    };
    useEffect(() => {
        file && !selectedFile && setFile(file);
    }, []);
    return <div>{selectedFile ? renderFileSelected() : renderUploadButton()}</div>;
};
