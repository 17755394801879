export const fonts = {
    sizes: {
        xxs: "0.7rem",
        xs: "0.75rem",
        sm: "0.80rem",
        base: "0.90rem",
        md: "1.05rem",
        lg: "1.286rem",
        xl: "1.5rem",
        xxl: "1.714rem",
        xxxl: "2rem",
    },
    weight: {
        lighter: "100",
        normal: "500",
        bold: "600",
        bolder: "700",
    },
    families: {
        site: "'Montserrat', -apple-system, BlinkMacSystemFont, Helvetica, Arial, serif;",
        siteImportant: "'Montserrat', -apple-system, BlinkMacSystemFont, Helvetica, Arial, serif !important;",
        customTable:"'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif"
    },
    defaults: {
        selector: "13px",
        datePicker: "1rem",
    },
    icons: {
        default: 20,
        navBarIcons: 24,
    },
    dockDisplay: {
        header:"2.5rem",        
        text: "2.5rem",
        icon:"2rem",
        weight:600
    },
};
