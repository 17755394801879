import { UserApi } from "../../../../api";
import messages from "../../../../translations/es/messages.json";
import { showMessageSuccessFromAssets, throwErrorToastrAsync } from "../../../../utils";
import { selfSignUpSuccess } from "../../../reducers/user/signup/selfSignup";
import { AppThunk, store } from "../../../store";
import { setGlobalLoadingAction } from "../../common";

const userSignupApi = new UserApi();

export const selfSignUpAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setGlobalLoadingAction(true));
        const form = store.getState().selfSignUp.form;
        await userSignupApi.selfUserSignup(form);
        dispatch(selfSignUpSuccess());
        showMessageSuccessFromAssets(messages.SUCCESS.SELF_REGISTRATION);
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setGlobalLoadingAction(false));
    }
};
