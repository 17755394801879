import { Icon } from "@fluentui/react";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routeUrls } from "../../../common/constants";
import { ColorType } from "../../../common/enum/ColorType";
import { HttpStatus } from "../../../common/enum/api/HttpStatus";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ButtonPrimary } from "../buttons/buttons";
import { TitleH4 } from "../titles/titles";
import { ForbiddenStyled } from "./accessError.jss";
import { reset } from "../../../redux/reducers/webConfig/apiException";

interface ICallbackProps {
    iconName: string;
    title: string;
    hiddenButton?: boolean;
    message: string;
    handleClickButton?: () => any;
    buttonTitle?: string;
    text?: string;
}

export const AccessError: React.FC<ICallbackProps> = ({ hiddenButton, iconName, message, title, handleClickButton, buttonTitle, text }) => {
    const style = ForbiddenStyled();
    const { t } = useTranslation("common", { keyPrefix: "API_ERRORS_MESSAGE" });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { error, timeNextRequest } = useAppSelector((state) => state.apiException);

    const apiCheck = () => {
        setTimeout(() => {
            dispatch(reset());
            navigate(routeUrls.REQUEST);
        }, timeNextRequest);
    };

    useEffect(() => {
        if (error?.htmlStatus !== undefined) {
            apiCheck();
        }
    }, []);

    const getTranslationKeyForError = (status: number): string => {
        switch (status) {
            case HttpStatus.INTERNAL_SERVER_ERROR:
                return t("500");
            case HttpStatus.SERVICE_UNAVAILABLE:
                return t("503");
            default:
                return t("DEFAULT_MESSAGE");
        }
    };

    const [params, setParams] = useState<{ iconName?: string; message?: string; title?: string; text?: string }>({
        iconName: error?.iconName ?? iconName,
        message: error?.htmlStatus?.toString() ? getTranslationKeyForError(error?.htmlStatus) : message,
        title: error?.htmlStatus?.toString() ? `ERROR ${error?.htmlStatus?.toString()}` : title,
        text: text ?? "",
    });

    useEffect(() => {
        if (error?.htmlStatus && error?.message) {
            setParams({ title: error?.htmlStatus.toString(), message: error?.message });
        }
    }, [error?.htmlStatus, error?.message]);

    return (
        <>
            <div className={style.content}>
                <div className={style.container}>
                    <div className={style.mainRow}>
                        <div className={style.iconColumn}>
                            {params.text}
                            <Icon iconName={params.iconName} />
                        </div>
                        <div className={style.panelColumn}>
                            <div className={style.titleRow}>
                                <TitleH4
                                    title={params.title ?? title}
                                    color={ColorType.primary}
                                    bold={true}
                                />
                            </div>
                            <div className={style.messageRow}>{params.message}</div>
                            {!hiddenButton && (
                                <div className={style.buttonRow}>
                                    <ButtonPrimary
                                        className={style.button}
                                        handleClick={handleClickButton}
                                        title={buttonTitle}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
