export enum MasterTypes {
    ORGANISM = "organism",
    CERTIFICATE_TYPE = "certificateType",
    MERCHANDISE_ORIGIN = "merchandiseOrigins",
    MERCHANDISE_TYPES = "merchandiseTypes",
    MERCHANDISE_CATEGORY = "marchandiseCategory",
    GROUPS_MERCHANDISE_CATEGORY = "groupsMerchandiseCategory",
    DOCKS = "docks",
    REQUEST_STATES = "requestStates",
    CUSTOMS_STATES = "customsStates",
    ACTUATION_TYPE = "actuationType",
    ACTUATION_GROUP = "actuationGroup",
    DOCUMENT_TYPE = "documentType",
    FINAL_TYPE_DESTINY = "finalTypeDestiny",
    REPRESENTATIVE_PRESENCE_TYPES = "representativePresenceTypes",
    SEAL_TYPES = "sealTypes",
    TURNS = "turns",
    BLOCK = "block",
    NOTIFICATION_TYPES = "notificationTypes",
    TRANSPORT_UNITS = "transportUnits",
    TERMINAL="terminal"
}
