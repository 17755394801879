import { apiFetchOAuthWithClaims } from "../../../services/apiClient";
import { Method } from "../../../common/enum";
import { IInvoicingUninvoicedData, } from "../../../models";
import { IInvoicingFilter } from "../../../models/invoicing/filter/IInvoiceFilter";

const baseURL = `${process.env.REACT_APP_API_URL}/invoicing/uninvoiced`;

export class InvoicingUninvoicedApi {

    async getUninvoiced(filters?: IInvoicingFilter): Promise<IInvoicingUninvoicedData> {
        const filtersUrl = `?startDate=${filters?.startDate}&endDate=${filters?.endDate}${
            filters?.customerId ? `&customerId=${filters?.customerId}` : ""
        }`;
        const url = `${baseURL}${filters ? filtersUrl : ""}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IInvoicingUninvoicedData>();
    }

    async exportUninvoiced(filters: IInvoicingFilter): Promise<string> {
        const url = `${baseURL}/export`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filters).execute<string>();
    }
}
