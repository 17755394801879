import React from "react";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../../../assets/icons";
import {
    IBoardHistoryAppointmentDockTimeZoneConfiguration,
    IBoardHistoryAppointmentDockTimeZoneConfigurationTable,
} from "../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch } from "../../../../../../../../../../redux/hooks";
import {
    setAppointmentDockTimeZoneConfigurationForm,
    setShowPopUpAppointmentDockTimeZoneConfiguration,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDockTimeZoneConfiguration/form";
import { CustomIcons } from "../../../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../../../common/tableCustom/tableCustom";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../../../../common/enum/DefaultValues";
interface IMasterBoardHistoryAppointmentDockTimeZoneConfigurationListProps {
    list: IBoardHistoryAppointmentDockTimeZoneConfiguration[];
}

export const MasterBoardHistoryAppointmentDockTimeZoneConfigurationList: React.FC<
    IMasterBoardHistoryAppointmentDockTimeZoneConfigurationListProps
> = ({ list }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_CONDITIONS" });
    const dispatch = useAppDispatch();

    let loading = false;

    const columns: ColumnType<
        IBoardHistoryAppointmentDockTimeZoneConfigurationTable,
        keyof IBoardHistoryAppointmentDockTimeZoneConfigurationTable
    >[] = [
        { key: "appointmentDockDescription", title: t("DOCK_DESCRIPTION") },
        { key: "appointmentTimeZoneDescription", title: t("TIME_ZONE_DESCRIPTION") },
        { key: "merchandiseTypeDescription", title: t("MERCHANDISE_TYPE") },
        { key: "appointmentTypeDescription", title: t("APPOINTMENT_TYPE") },
        { key: "fumigated", title: t("CONTAINER_FUMIGATED") },
        { key: "humanConsumption", title: t("HUMAN_CONSUMPTION") },
        { key: "halalCertified", title: t("HALAL") },
        { key: "organicProduct", title: t("ORGANIC_PRODUCT") },
        { key: "actions", title: "" },
    ];

    const rows = list.map((item) => {
        return {
            ...item,
            appointmentDockDescription: item.appointmentDockDescription ?? DefaultValues.EMPTY_LIST,
            appointmentTimeZoneDescription: item.appointmentTimeZoneDescription ?? DefaultValues.EMPTY_LIST,
            merchandiseTypeDescription: item.merchandiseTypeDescription ?? DefaultValues.EMPTY_LIST,
            appointmentTypeDescription: item.appointmentTypeDescription ?? DefaultValues.EMPTY_LIST,

            organicProduct: <BooleanValue value={item.organicProduct} />,
            fumigated: <BooleanValue value={item.fumigated} />,
            humanConsumption: <BooleanValue value={item.humanConsumption} />,
            halalCertified: <BooleanValue value={item.halalCertified} />,

            actions: (
                <CustomTableActions
                    children={
                        <div>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.EDIT}
                                onClick={() => {
                                    dispatch(setShowPopUpAppointmentDockTimeZoneConfiguration(PopUpCodes.UPDATE));
                                    dispatch(setAppointmentDockTimeZoneConfigurationForm(item));
                                }}
                            />

                            <Separator size="auto"></Separator>

                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.REMOVE}
                                onClick={() => {
                                    dispatch(setShowPopUpAppointmentDockTimeZoneConfiguration(PopUpCodes.DELETE));
                                    dispatch(setAppointmentDockTimeZoneConfigurationForm(item));
                                }}
                            />
                        </div>
                    }
                />
            ),
        };
    });

    return (
        <CustomTableList
            data={rows}
            columns={columns}
            loading={loading}
        />
    );
};
