import { Milliseconds, ToastType } from "../../../common/enum";
import { IToastifyConfig } from "../../../models";

export const defaultErrorOptions = (): IToastifyConfig => {
    return { isLoading: false, autoClose: Milliseconds.FIVE_SEC, type: ToastType.ERROR, closeButton: true };
};

export const defaultWarningOptions = (): IToastifyConfig => {
    return { isLoading: false, autoClose: false, type: ToastType.WARNING, closeButton: true };
};

export const defaultSucccesOptions = (): IToastifyConfig => {
    return {
        isLoading: false,
        autoClose: Milliseconds.TWO_SEC,
        type: ToastType.SUCCESS,
        closeButton: true,
        closeOnClick: true,
        hideProgressBar: false,
        pauseOnFocusLoss: false,
    };
};

export const defaultLoadingOptions = (): IToastifyConfig => {
    return { autoClose: false };
};
