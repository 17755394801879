import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IManagmentPositioningDisplay } from "../../../../models/management/positioningDisplay/IManagmentPositioningDisplay";

export interface IManagementPositioningDisplayState {
    data: IManagmentPositioningDisplay;
    interval: NodeJS.Timer | undefined;
    autoReload: boolean;
    loading: boolean;
}

const initialState: IManagementPositioningDisplayState = {
    data: {
        organisms: [],
        positioningDocks: [],
    },
    loading: false,
    interval: undefined,
    autoReload: true,
};

export const managementPositioningDisplaySlice = createSlice({
    name: "managementPositioningDisplaySlice",
    initialState,
    reducers: {
        resetManagementPositioningDisplay: () => initialState,
        setLoadingManagementPositioningDisplay: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setManagementPositioningDisplay: (state, action: PayloadAction<IManagmentPositioningDisplay>) => {
            state.data = action.payload;
        },
        setIntervalState: (state, action: PayloadAction<NodeJS.Timer | undefined>) => {
            state.interval = action.payload;
        },
        setAutoReload: (state, action: PayloadAction<boolean>) => {
            state.autoReload = action.payload;
        },
    },
});

export const { setIntervalState, resetManagementPositioningDisplay, setLoadingManagementPositioningDisplay, setManagementPositioningDisplay } =
    managementPositioningDisplaySlice.actions;

export default managementPositioningDisplaySlice.reducer;
