import React from "react";
import { VlcPortFinishedRequestContainer } from "./container";
import { store } from "../../../redux/store";
import { resetVlcportRequestFinishedList } from "../../../redux/reducers/vlcPort/requestFinished/list";

export class VlcPortFinishedRequestContainerController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetVlcportRequestFinishedList());
    }

    render(): React.ReactNode {
        return <VlcPortFinishedRequestContainer />;
    }
}
