import { useEffect } from "react";
import { fetchManagmentTariffListAction } from "../../../../redux/actions/management/tariff/tariff";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementTariffListColumn } from "./column/column";
import { SkeletonSizes } from "../../../../common/enum/sizes";
import { sortArray } from "../../../../utils";

export const ManagementTariffList = () => {
    const dispatch = useAppDispatch();
    const { listTariffCondition, loading, sort } = useAppSelector((store) => store.managementTariffList);

    useEffect(() => {
        !listTariffCondition.length && !loading && dispatch(fetchManagmentTariffListAction());
    }, [dispatch, listTariffCondition]);

    const columns = () => {
        return ManagementTariffListColumn();
    };

    return !loading ? (
        <CustomDetailsList
            data={sortArray(listTariffCondition, sort) ?? []}
            renderColumns={columns}
            height={heights.tariffListHeigt}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
