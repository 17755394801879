import { useTheme } from "react-jss";
import { IDailyChargeByDate } from "../../../../../models/statistics/IDailyCharge";
import { CustomTableList } from "../../customTableList/tableList";

import { StatisticsDailyChargeListDateInspectorItemtStyle } from "./inspectorStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

interface IStatisticsDailyChargeListInspectorItem {
    inspector: IDailyChargeByDate;
    index: number;
}
export const StatisticsDailyChargeListInspectorItem: React.FC<IStatisticsDailyChargeListInspectorItem> = ({ inspector, index }) => {
    const theme = useTheme();
    const style = StatisticsDailyChargeListDateInspectorItemtStyle({ theme });

    const bodyContent = inspector.dailyChargeByInspectorStatistic.map((item, index) => {
        return (
            <div className="row">
                <div className={style.inspector}>{index === 0 ? inspector.inspector ?? DefaultValues.EMPTY_LIST : null}</div>
                <div className="separator">
                    <div className="cell">{item.certificateNumber ?? DefaultValues.EMPTY_LIST}</div>
                    <div className="cell">
                        <span className={style.description}>{item.description ?? DefaultValues.EMPTY_LIST}</span>
                    </div>
                    <div className="cell">{item.transportUnitNumber ?? DefaultValues.EMPTY_LIST}</div>
                    <div className="cell">{item.customerName ?? DefaultValues.EMPTY_LIST}</div>
                </div>
            </div>
        );
    });

    return (
        <div className={style.container}>
            {index > 0 && <div className={style.separator}></div>}
            <CustomTableList bodyContent={bodyContent} />
        </div>
    );
};
