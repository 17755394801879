import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVlcportHistoricList } from "../../../../../models/vlcPort/IHistoricRequest";

interface IVlcportHistoricListState {
    list?: IVlcportHistoricList[];
    loading: boolean;
}

const initialState: IVlcportHistoricListState = {
    loading: false,
};

export const vlcportHistoricInspectionAppoimentListReducer = createSlice({
    name: "vlcportHistoricInspectionAppoimentListReducer",
    initialState,
    reducers: {
        resetVlcportHistoricInspectionAppoimentList: () => initialState,
        setVlcportHistoricInspectionAppoimentList: (state, action: PayloadAction<IVlcportHistoricList[]>) => {
            state.list = action.payload;
        },
        setVlcportHistoricInspectionAppoimentLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const {
    resetVlcportHistoricInspectionAppoimentList,
    setVlcportHistoricInspectionAppoimentList,
    setVlcportHistoricInspectionAppoimentLoading,
} = vlcportHistoricInspectionAppoimentListReducer.actions;

export default vlcportHistoricInspectionAppoimentListReducer.reducer;
