import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import {  IManagementAuthorizedTransportUnitNumberFilter } from "../../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";

interface IManagementAuthorizedTransportUnitNumberFilterState {
    filter: IManagementAuthorizedTransportUnitNumberFilter;
}

const initialState: IManagementAuthorizedTransportUnitNumberFilterState = {
    filter: {
        transportUnitNumber: "",
    },
};

export const managementAuthorizedTransportUnitNumberFilterSlice = createSlice({
    name: "managementAuthorizedTransportUnitNumberFilterSlice",
    initialState,
    reducers: {
        resetManagementAuthorizedTransportUnitNumberFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});
export const { resetManagementAuthorizedTransportUnitNumberFilter, updateFilterProperty } = managementAuthorizedTransportUnitNumberFilterSlice.actions;

export default managementAuthorizedTransportUnitNumberFilterSlice.reducer;
