import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterNotificationType } from "../../../../../models/resources/master/INotificationType";

export interface IMasterNotificationTypesListState {
    list?: IMasterNotificationType[];
    loading: boolean;
}

const initialState: IMasterNotificationTypesListState = {
    loading: false,
};

export const masterNotificationTypeList = createSlice({
    name: "masterNotificationTypeList",
    initialState,
    reducers: {
        resetMasterNotificationTypesList: () => initialState,
        setLoadingMasterNotificationTypesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterNotificationTypesList: (state, action: PayloadAction<IMasterNotificationType[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterNotificationTypesList, setLoadingMasterNotificationTypesList, setMasterNotificationTypesList} = masterNotificationTypeList.actions;

export default masterNotificationTypeList.reducer;
