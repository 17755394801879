import { createUseStyles } from "react-jss";

export const ManagementProcessesContainerStyle = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        backgroundColor: theme.colors.site.background,
        paddingTop: "2vh",
        height: "calc(100vh - 12rem)",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 20,
        padding: theme.paddings.default,
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
    },
    infoSection: {
        display: "flex",
        gap: 5,
        fontWeight: "bold",
    },
    customWidthBtn: {
        width: 150,
    },
}));
