import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { emptyRequestFilter, IGenericPayload, IRequestFilter } from "../../../../models";
import { convertToLocal } from "../../../../utils/dateUtils";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface RequestListFilterState {
    filter: IRequestFilter;
    startDate: string;
    endDate: string | undefined;
    showPopUp?: PopUpCodes;
}

const initialState: RequestListFilterState = {
    filter: emptyRequestFilter(),
    startDate: convertToLocal(new Date(), FormatDate.ISO),
    endDate: convertToLocal(new Date(), FormatDate.ISO),
    
};

export const requestListFilterSlice = createSlice({
    name: "requestListFilter",
    initialState,
    reducers: {
        resetRequestListFilter: () => initialState,
        resetFilter: (state) => {
            state.filter = emptyRequestFilter();
            state.startDate = convertToLocal(new Date(), FormatDate.ISO);
            state.endDate = convertToLocal(new Date(), FormatDate.ISO);
        },
        initFilterFromSession: (state, action: PayloadAction<IRequestFilter>) => {
            state.filter = action.payload;
        },
        updateRequestFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
        setRequestFilterStartDate: (state, action: PayloadAction<string>) => {
            state.startDate = action.payload;
        },
        setRequestFilterEndDate: (state, action: PayloadAction<string | undefined>) => {
            state.endDate = action.payload;
        },
        updateImpersonateOrganismIdFilter: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter.organismId = action.payload.value;
        },
        savePreviusOrganismIdFilter: (state, action: PayloadAction<number[]>) => {
            state.filter.previusOrganismId = action.payload;
        },
        setShowPopUpFilter: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetRequestListFilter,
    resetFilter,
    updateRequestFilterProperty,
    setRequestFilterEndDate,
    setRequestFilterStartDate,
    initFilterFromSession,
    updateImpersonateOrganismIdFilter,
    setShowPopUpFilter,
    savePreviusOrganismIdFilter,
} = requestListFilterSlice.actions;

export default requestListFilterSlice.reducer;
