import { RequestApi } from "../../../../../api";
import { Milliseconds } from "../../../../../common/enum";
import { IActuation } from "../../../../../models/requests/IEditRequest";
import { throwErrorToastrAsync } from "../../../../../utils";
import {
    setActuationFormData,
    setActuationFormLoading,
    setActuationList,
    setActuationListLoading,
} from "../../../../reducers/request/edit/form/actuation";
import { AppThunk } from "../../../../store";
import { fetchSilentRequestListAction } from "../../list/requestList";
import { fetchEditRequestInspection } from "./inspection";
import { updateAppointmentValueOfRequest } from "./requestEdit";
const requestApi = new RequestApi();

export const fetchActuationForm =
    (requestId: number, inspectionId: number, actuationId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setActuationFormLoading(true));
            const response = await requestApi.getRequestInspectionActuation(requestId, inspectionId, actuationId);
            dispatch(setActuationFormData(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setActuationFormLoading(false));
        }
    };

export const fetchExpedientActuationList =
    (requestId: number, inspectionId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setActuationListLoading(true));
            const response = await requestApi.getExpedientActuationList(requestId, inspectionId);
            dispatch(setActuationList(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setActuationListLoading(false));
        }
    };

export const removeActuation =
    (inspectionId: number, actuationId: any, delay?: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setActuationListLoading(true));
            await requestApi.deleteRequestInspectionActuation(0, inspectionId, actuationId);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setActuationListLoading(false));
            if (delay) {
                process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
                dispatch(fetchSilentRequestListAction());
            }
        }
    };

export const postInspectionActuation = (requestId: number, inspectionId: number, actuation: IActuation, updateAppointmentRequest?: boolean): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setActuationFormLoading(true));
            if (actuation.id) {
                await requestApi.putRequestInspectionActuation(requestId, inspectionId, actuation);
            } else {
                await requestApi.postRequestInspectionActuation(requestId, inspectionId, actuation);
            }
            dispatch(fetchEditRequestInspection(requestId));
            if (updateAppointmentRequest) {
                dispatch(updateAppointmentValueOfRequest(requestId));
            }

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setActuationFormLoading(false));
        }
    };
};

export const postRequestListActuation = (requestId: number, inspectionId: number, actuation: IActuation, delay?: boolean): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setActuationFormLoading(true));
            if (actuation.id) {
                await requestApi.putRequestInspectionActuation(requestId, inspectionId, actuation);
            } else {
                await requestApi.postRequestInspectionActuation(requestId, inspectionId, actuation);
            }
            if (delay) {
                process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
                dispatch(fetchSilentRequestListAction());
            }
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setActuationFormLoading(false));
        }
    };
};

export const deleteRequestListActuation = (requestId: number, inspectionId: number, actuationId: number, delay?: boolean): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setActuationFormLoading(true));
            await requestApi.deleteRequestInspectionActuation(requestId, inspectionId, actuationId);
            if (delay) {
                process.env.REACT_APP_DEVELOPMENT && (await new Promise((r) => setTimeout(r, Milliseconds.TWO_SEC)));
                dispatch(fetchSilentRequestListAction());
            }
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setActuationFormLoading(false));
        }
    };
};
