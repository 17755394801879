import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router";
import { routeUrls } from "../../../../../../../common/constants";

import { postMasterAppointmentBoardForm } from "../../../../../../../redux/actions/masterAppointment/appointmentBoard/appointmentBoard";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { resetMasterAppointmentBoardForm } from "../../../../../../../redux/reducers/masterAppointment/appointmentBoard/form";
import { clearMasterAppointmentPopUp } from "../../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { AppointmentCreateBoardForm } from "./form/form";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../../../../common/requiredMessage/requiredMessage";

export const AppointmentBoardCreateController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = false;

    const { form } = useAppSelector((state) => state.masterAppointmentBoardCreateForm);
    const { showPopUp } = useAppSelector((state) => state.masterAppointment);

    const onAcceptPopUp = () => {
        dispatch(postMasterAppointmentBoardForm(form)).then((response: any) => {
            if (response) {
                onCancelPopUp();
                const path = generatePath(routeUrls.MASTER_APPOINTMENT_BOARD, { id: response });
                navigate(path);
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterAppointmentPopUp());
    };
    const onDimissed = () => {
        dispatch(resetMasterAppointmentBoardForm());
    };
    const isVisiblePopUp = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return !form.date;
    }, [showPopUp, form]);

    return (
        <PopUpForm
            content={<AppointmentCreateBoardForm />}
            title={t("BUTTON.APPOINTMENT_BOARD")}
            isVisible={isVisiblePopUp}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            height={"15vh"}
            width={"40vw"}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onDimissed={onDimissed}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
