import { ActionButton, IIconProps } from "@fluentui/react";
import { useTheme } from "react-jss";
import { NavHeaderStyled } from "./navHeader.jss";
import { useMemo } from "react";
import { Environment } from "../../../common/enum/environment/Environment";

interface INavHeaderProps {
    backHandleClick?: () => void;
    title: string;
    action?: React.ReactChild | React.ReactChildren;
    endAction?: React.ReactChild | React.ReactChildren;
    environmentBackground?: boolean;
}

export const NavHeaderComponent: React.FC<INavHeaderProps> = ({ backHandleClick, title, action, endAction, environmentBackground }) => {
    const showTitleContainer = backHandleClick || title ? true : false;
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const theme = useTheme() as any;
    const background = useMemo(() => {
        if (environmentBackground) {
            if (environment === Environment.DEV) {
                return theme.colors.siteHeader.devBackground;
            } else if (environment === Environment.PRE) {
                return theme.colors.siteHeader.preBackground;
            }
        }
        return null;
    }, [environment]);

    const styles = NavHeaderStyled({ theme, showTitleContainer, background });
    const backIcon: IIconProps = { iconName: "ChevronLeft", style: { fontSize: 25 } };
    return (
        <div className={styles.container}>
            {showTitleContainer && (
                <div className={styles.titleContainer}>
                    {backHandleClick && (
                        <ActionButton
                            iconProps={backIcon}
                            onClick={() => backHandleClick && backHandleClick()}
                            allowDisabledFocus
                        />
                    )}
                    {title && <div className={styles.title}>{title}</div>}
                </div>
            )}

            {action && <div className={styles.action}>{action}</div>}

            {endAction && <div className={styles.endAction}>{endAction}</div>}
        </div>
    );
};
