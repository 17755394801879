import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISortState } from "../../../../models/common/ISortState";
import { IInvoicingSummaryByConceptData, IInvoicingSummaryByConcept } from "../../../../models/invoicing/summaryByConcept/IInvoicingSummaryByConcept";

interface IInvoicingSummaryConceptListState {
    list?: IInvoicingSummaryByConcept[];
    totalQuantity: number;
    totalAmount: number;
    sort: ISortState;
    loading: boolean;
}

const initialState: IInvoicingSummaryConceptListState = {
    
    totalQuantity: 0,
    totalAmount: 0,
    sort: {
        column: "",
        orderColumn: "",
    },
    loading: false,
};

export const invoicingSummaryByConceptListSlice = createSlice({
    name: "invoicingSummaryByConceptList",
    initialState,
    reducers: {
        resetInvoiceSummaryByConcept: () => initialState,
        setList: (state, action: PayloadAction<IInvoicingSummaryByConceptData>) => {
            state.list = action.payload.invoiceConcepts;
            state.totalAmount = action.payload.totalAmount;
            state.totalQuantity = action.payload.totalQuantity;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    },
});

export const {
    resetInvoiceSummaryByConcept,
    setList,
    updateSortColumn,
    setLoading,
} = invoicingSummaryByConceptListSlice.actions;

export default invoicingSummaryByConceptListSlice.reducer;
