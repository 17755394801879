import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import {
    setLoadingMasterPresenceRepresentativeForm,
    setMasterPresenceRepresentativeForm,
} from "../../../../reducers/resources/master/representativePresence/form";
import {
    setLoadingMasterPresenceRepresentativeList,
    setMasterPresenceRepresentativeList,
} from "../../../../reducers/resources/master/representativePresence/list";
import { AppThunk, store } from "../../../../store";

const catalogAPI = new CatalogAPI();

export const fetchMasterPresenceRepresentativeList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterPresenceRepresentativeList(true));
        const response = await catalogAPI.getMasterRepresentativePresenceTypesList();
        dispatch(setMasterPresenceRepresentativeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterPresenceRepresentativeList(false));
    }
};

export const fetchMasterPresenceRepresentative =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterPresenceRepresentativeForm(true));
            const response = await catalogAPI.getMasterPresenceRepresentativeType(id);
            dispatch(setMasterPresenceRepresentativeForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterPresenceRepresentativeForm(false));
        }
    };

export const postToMasterPresenceRepresentativeForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterPresenceRepresentativeForm;

        try {
            dispatch(setLoadingMasterPresenceRepresentativeForm(true));
            if (form.id) {
                await catalogAPI.putMasterPresenceRepresentativeTypes(form);
            } else {
                await catalogAPI.postMasterPresenceRepresentativeTypes(form);
            }
            dispatch(fetchMasterPresenceRepresentativeList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterPresenceRepresentativeForm(false));
            return false;
        }
    };
};

export const patchSortrepresentativePresenceList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterPresenceRepresentativeList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterPresenceRepresentativeList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterPresenceRepresentativeList(false));
        }
    };
