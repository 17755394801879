import React, { useEffect } from "react";
import { fetchMasterAppointmentBoardList } from "../../../../../../redux/actions/masterAppointment/appointmentBoard/appointmentBoard";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { AppointmentBoardCreateController } from "../controller/create/controller";
import { MasterAppointmentBoardColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/enum/sizes";

interface IMasterAppointmentBoardContainerProps {}

export const MasterAppointmentBoardListContainer: React.FC<IMasterAppointmentBoardContainerProps> = () => {
    const { list, loading } = useAppSelector((state) => state.masterAppointmentBoardList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return MasterAppointmentBoardColumns();
    };
    useEffect(() => {
        list === undefined && !loading && dispatch(fetchMasterAppointmentBoardList());
    }, [list]);

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 10rem)"}
                />
            )}

            <AppointmentBoardCreateController />
        </div>
    );
};
