import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { SkeletonSizes } from "../../../../common/constants";
import { routeFeatures } from "../../../../common/constants/routeFeatures";
import { ICreateRequest, IGenericPayload, ViewType } from "../../../../models";
import { IUserRoleView, IView } from "../../../../models/requests/IUserRoleView";
import { onExitRequestAction } from "../../../../redux/actions/request/create/createRequest";
import { fetchRequestListAction } from "../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateRequestProperty } from "../../../../redux/reducers/request/create/createRequest";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { RequestStepper } from "../../../common/user/stepper/requestStepper";
import { CertificatesDataContainer } from "./certificates/container";
import { CreateRequestContainerStyle } from "./containerStyle.jss";
import { GoodInfoDataContainer } from "./goodInformation/container";
import { ResumeContainer } from "./resume/container";
import { UtiDataContainer } from "./uti/container";
import { generateRequestPropertySchema } from "./validation/generateRequestPropertySchema";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { widths } from "../../../../styles/romeu/widths";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { IEditRequest } from "../../../../models/requests/IEditRequest";

interface IProps {
    onAbandon: () => void;
}

export const CreateRequestContainer: React.FC<IProps> = ({ onAbandon }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = CreateRequestContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });
    const [showWarningCloseCreateRequest, setShowWarningCloseCreateRequest] = useState(false);
    const step = useAppSelector((state) => state.createRequest.activeStep);

    const { isViewLoaded, viewPermission } = useAppSelector((state) => state.userRoleViews);
    const { roleData } = useAppSelector((state) => state.role);
    const { organizations } = useAppSelector((state) => state.userData.profile);
    const { request } = useAppSelector((store) => store.createRequest);
    const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === routeFeatures.REQUEST);
    const currentOrganization = useAppSelector((store) => store.organization.organization.id);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view) => view.type === ViewType.CREATE) as IView;
    }, [viewRequestPermission?.views]);

    const schema = useMemo(() => {
        return generateRequestPropertySchema(viewConfig.fields);
    }, [viewConfig.fields]);

    const organization = useMemo(() => {
        return organizations.find((item) => item.id === currentOrganization);
    }, [organizations, currentOrganization]);

    const onSelectorChange = (option: IDropdownOption, name: string, optionalParam?: any) => {
        if (name === compileNameOfProperty<ICreateRequest>("transportUnitId") && request.terminalId) {
            const payload: IGenericPayload = {
                name: compileNameOfProperty<ICreateRequest>("terminalId"),
                value: undefined,
            };
            dispatch(updateRequestProperty(payload));
        }

        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        dispatch(updateRequestProperty(payload));

        if (optionalParam) {
            const payload: IGenericPayload = {
                name: compileNameOfProperty<IEditRequest>("representativeId"),
                value: optionalParam,
            };
            dispatch(updateRequestProperty(payload));
        }
    };

    const onDateChange = (date: Date | undefined | null, name: string) => {
        const payload: IGenericPayload = {
            value: date,
            name,
        };

        dispatch(updateRequestProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateRequestProperty(payload));
        },
        [dispatch],
    );

    const resetPropertyWhenParentChange = (name: string) => {
        const payload: IGenericPayload = {
            name,
            value: undefined,
        };
        dispatch(updateRequestProperty(payload));
    };

    const onYesNoOptionsChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: !!+option.key,
        };
        dispatch(updateRequestProperty(payload));
    };

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            const payload: IGenericPayload = {
                name: event.target.name,
                value: checked,
            };

            dispatch(updateRequestProperty(payload));
        },
        [dispatch],
    );

    const onToggleChange = useCallback(
        (event: any, checked?: boolean | undefined, name?: string) => {
            const payload: IGenericPayload = {
                name: name ?? "",
                value: checked,
            };
            dispatch(updateRequestProperty(payload));
        },
        [dispatch],
    );

    const onRadioButtonChange = useCallback(
        (optionKey: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: !!+optionKey,
            };
            dispatch(updateRequestProperty(payload));
        },
        [dispatch],
    );

    const onButtonClick = useCallback(() => {
        if (step === 4) {
            onAcceptWarningCreateRequest();
        } else {
            setShowWarningCloseCreateRequest(true);
        }
    }, [step]);

    const onAcceptWarningCreateRequest = () => {
        onCancelWarningCreateRequest();
        onAbandon();
        dispatch(fetchRequestListAction());
    };

    const onCancelWarningCreateRequest = () => {
        setShowWarningCloseCreateRequest(false);
    };

    useEffect(() => {
        return () => {
            dispatch(onExitRequestAction());
        };
    }, []);
    const nodes = [
        {
            title: t("TITLES.UTI"),
            node: (
                <UtiDataContainer
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={onDateChange}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onCheckBoxChange={onCheckboxChange}
                    organization={organization}
                />
            ),
        },
        {
            title: t("TITLES.INFORMATION"),

            node: (
                <GoodInfoDataContainer
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={onDateChange}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onToggleChange={onToggleChange}
                    organization={organization}
                />
            ),
        },
        {
            title: t("TITLES.CERTIFICATES"),
            node: (
                <CertificatesDataContainer
                    schema={schema}
                    onInputChange={onInputChange}
                    onSelectorChange={onSelectorChange}
                    resetPropertyWhenParentChange={resetPropertyWhenParentChange}
                    onDateChange={onDateChange}
                    onYesNoOptionsChange={onYesNoOptionsChange}
                    onRadioButtonChange={onRadioButtonChange}
                    organization={organization}
                />
            ),
        },
        {
            title: t("TITLES.RESUME"),
            node: <ResumeContainer onResumeAccept={onAcceptWarningCreateRequest} />,
        },
    ];

    return (
        <>
            <div className={styles.requestContainer}>
                {!roleData || !isViewLoaded ? (
                    <Skeleton rows={SkeletonSizes.REQUEST} />
                ) : (
                    <RequestStepper
                        steps={nodes}
                        title={t("TITLES.LEFT_COLUMN")}
                        onClickButton={onButtonClick}
                    />
                )}
            </div>

            <PopUpConfirmation
                showPopUp={showWarningCloseCreateRequest}
                onCancel={onCancelWarningCreateRequest}
                onAccept={onAcceptWarningCreateRequest}
                type={PopUpConfirmationType.WARNING}
                width={widths.confirmPopUp.remove}
                height={"auto"}
                message={t("CLOSE_REQUEST.MESSAGE")}
                buttonAcceptTitle={t("CLOSE_REQUEST.EXIT")}
            />
        </>
    );
};
