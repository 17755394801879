import dayjs from "dayjs";
import { FormatDate } from "../common/enum/dateTime/FormatDate";
import utc from "dayjs/plugin/utc";

export const convertToLocal = (date: string | Date | undefined | null, format: FormatDate = FormatDate.DATE_HOUR): string => {
    dayjs.extend(utc);
    return dayjs.utc(date).local().format(format);
};

export const formatHour = (date: any): string => {
    return dayjs(date).format(FormatDate.HOUR_MINUTE);
};
export const formatDate = (date: any, format: FormatDate = FormatDate.DATE_HOUR): any => {
    return date ? dayjs(date).format(format) : null;
};

export const getLocalDate = (format?: FormatDate) => {
    return dayjs().format(format);
};

export const getCurrentDate = () => {
    return dayjs(new Date()).startOf("day").toDate();
};

export const addDayToDate = (date: string | Date | undefined, addType: any, total: number, format: FormatDate) => {
    return dayjs(date).add(total, addType).format(format);
};

export const getDisabledDaysFilter = (
    enabledDays = 2,
): {
    from: Date;
    to: Date;
}[] => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const fromPast = new Date();
    fromPast.setFullYear(currentDate.getFullYear() - 4);
    fromPast.setDate(currentDate.getDate() - 1);
    fromPast.setHours(0, 0, 0, 0);

    const toPast = new Date();
    toPast.setDate(currentDate.getDate() - 1);
    toPast.setHours(0, 0, 0, 0);

    const fromFuture = new Date();
    fromFuture.setDate(currentDate.getDate() + enabledDays);
    fromFuture.setHours(0, 0, 0, 0);

    const toFuture = new Date();
    toFuture.setFullYear(currentDate.getFullYear() + 4);
    toFuture.setDate(currentDate.getDate() + enabledDays);
    toFuture.setHours(0, 0, 0, 0);

    return [
        { from: fromPast, to: toPast },
        { from: fromFuture, to: toFuture },
    ];
};

export const getDaysDifference = (date: Date | undefined): number | null => {
    if (date === undefined) return null;
    const oneDay = 24 * 60 * 60 * 1000;
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const diffDays = Math.round((date.getTime() - currentDate.getTime()) / oneDay);
    return diffDays === -0 ? 0 : diffDays;
};

export const getBeforeOfToday = (date: Date | undefined): boolean => {
    let days = getDaysDifference(date) ?? 0;
    return days <= -1;
};

export const formatHMToDate = (hour: string): Date | null => {
    const parsedTime = dayjs(hour, FormatDate.HOUR_MINUTE);

    if (parsedTime.isValid()) {
        const currentDate = dayjs();

        const date = currentDate.set("hour", parsedTime.hour()).set("minute", parsedTime.minute());

        return date.toDate();
    } else {
        return null;
    }
};
