import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs from "dayjs";
import React, { useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IManagemenTActiveSummaryForm } from "../../../../../models/management/summary/ISummary";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateManagementSummaryConditionForm } from "../../../../../redux/reducers/management/summary/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { ControlledDatePicker } from "../../../../common/datePicker/controlledDatePicker";
import { FormField } from "../../../../common/fields/fields";
import { requestMaxLength } from "../../../../request/form/create/validation/requestConfig";
import { TimePickerComponent } from "../../../../common/timePicker/timePicker";
import { useTheme } from "react-jss";
import { ManagementSummaryFormFormStyle } from "./formStyle.jss";

export const ManagementSummaryForm = () => {
    const theme = useTheme();
    const style = ManagementSummaryFormFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.SUMMARY" });
    const { date, hour, summaryNumber } = useAppSelector((state) => state.managementSummaryForm.form);

    const { register, control } = useForm<IManagemenTActiveSummaryForm>({ mode: "onChange" });

    const onInputChange = (event: any, _newValue?: any) => {
        const payload = getGenericPayloadFromEvent(event);
        dispatch(updateManagementSummaryConditionForm(payload));
    };
    const minDate = useMemo(() => {
        return dayjs(new Date()).startOf("day").toDate();
    }, []);
    const onDateChange = (date: any, name: any) => {
        let payload = {
            name: name,
            value: date,
        };

        dispatch(updateManagementSummaryConditionForm(payload));
    };

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            if (date) {
                let payload = {
                    name: compileNameOfProperty<IManagemenTActiveSummaryForm>("hour"),
                    value: new Date(date.toISOString()),
                };

                dispatch(updateManagementSummaryConditionForm(payload));
            }
        },
        [dispatch],
    );
    return (
        <div className={style.containerForm}>
            <FormField
                type="text"
                value={summaryNumber}
                maxLength={requestMaxLength.SUMMARY}
                label={t("SUMMARY_NUMBER")}
                isRequired={true}
                {...register(compileNameOfProperty<IManagemenTActiveSummaryForm>("summaryNumber"), {
                    onChange: onInputChange,
                    required: true,
                })}
            />
            <div className={style.rowForm}>
                <ControlledDatePicker
                    name={compileNameOfProperty<IManagemenTActiveSummaryForm>("date")}
                    label={t("DATE")}
                    onDateChange={onDateChange}
                    initValue={new Date(date)}
                    required={true}
                    control={control}
                    minDate={minDate}
                />

                <div className={style.timePicker}>
                    <TimePickerComponent
                        onChange={onTimeChange}
                        value={hour}
                        label={t("HOUR")}
                        required={true}
                    />
                </div>
            </div>
        </div>
    );
};
