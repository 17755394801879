import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const PopUpConfirmationStyle = createUseStyles((_theme: any) => ({
    popUpContainer: (props: any) => ({
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: props.theme.gaps.sizes.base,
        minHeight: props.height ? props.height : props.theme.heights.customPopUp.maxHeigth,
        width:props.width,
        [mediaQueries.MOBILE]: {
            width: "100%",
        },
    }),

    content: (props: any) => ({
        maxHeight: props.height ? props.height : "auto",
        minHeight: props.height ? props.height : "auto",
        height: props.height ? props.height : props.theme.heights.customPopUp.heigth,
        minWidth: props.width ? props.width : "auto",
        flex: "1 1 auto",
        display: "flex",
        [mediaQueries.MOBILE]: {
            width: "90vw",
        },
    }),
}));
