import { useSelector } from "react-redux";
import { globalLoadingSelector } from "../../../../redux/selectors/common/globalLoading";
import literals from "../../../../translations/es/common.json";
import { SpinnerFullStyle } from "./spinnerFullStyle.jss";

export const SpinnerFull = () => {
  const styles = SpinnerFullStyle();

  const loading = useSelector(globalLoadingSelector);

  return (
    <>
      {loading && (
        <div className={styles.preloader}>
          <span className={styles.message}>{literals.SITE.LOADING}</span>
          <div className={styles.loader}></div>
        </div>
      )}
    </>
  );
};
