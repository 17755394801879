import { createUseStyles } from "react-jss";

export const StatisticsDailyChargeListDateInspectorItemtStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        width: "100%",
        flex: "0 1 auto",
        flexDirection: "column",
        alignItems: "center",
        background: "white",
    },
    inspector: {
        paddingLeft: 18,
        paddingTop: 0,
        border: "none",
        fontSize: 12,
        textTransform: "uppercase",
        width: "19.5%",
        background: "white",
        fontFamily: `${theme.fonts.families.customTable} !important`,
        fontWeight:400

    },

    border: {
        borderTop: theme.borders.table.header.border,
    },
    description: {
        width: "90%",
        whiteSpace: "initial",
    },
    separator: {
        borderTop: theme.borders.default.border,
        width: "99%",
        height: "1px",
    },
}));
