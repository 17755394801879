import React, { useEffect } from "react";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { NumericMultiSelector } from "../../multiSelectors/numericMultiSelector/numericMultiSelector";
import { IBaseMultipleNumberSelectorProps } from "../../../../request/common/selectors/IBaseMultipleSelectorProps";
import { MultipleNumberSelectorFilter } from "../../../../request/common/selectors/multipleSelectorFilter/multipleNumberSelectorFilter";

export const MultipleRequestStateSelector: React.FC<IBaseMultipleNumberSelectorProps> = ({ autocomplete, onChange, propertyName, title, selectedKeys }) => {
    const { data, loading } = useAppSelector((store) => store.catalog.requestStates);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data === undefined && dispatch(fetchRequestStates());
    }, [dispatch, data]);

    return autocomplete ? (
        <MultipleNumberSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapIDescriptionToDropDownOptions(data?.filter(item => item.sort > 0) ?? [])}
            label={title}
            propertyName={propertyName}
        ></MultipleNumberSelectorFilter>
    ) : (
        <NumericMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapIDescriptionToDropDownOptions(data?.filter(item => item.sort > 0) ?? [])}
            disabled={loading}
            title={title}
            name={propertyName}
        />
    );
};
