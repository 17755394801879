export const widths = {
    sizes: {
        xs: 5,
        sm: 10,
        base: 15,
        md: 20,
        lg: 25,
        xl: 30,
        xxl: 40,
        xxxl: 50,
    },
    siteNav: {
        panel: 230,
        logo: 100,
        hideMenu: 60,
    },
    selector: {
        sm: 125,
        base: 250,
        md: 375,
        lg: 500,
    },
    sectionContainer: {
        complete: "100%",
        small: "20%",
    },
    minContainer: {
        small: "20rem",
    },
    headerIcons: {
        default: 30,
    },
    vuaContainer: {
        default: 320,
    },
    exportBtn: {
        container: 100,
        icon: 30,
    },
    representative: {
        width: "20vw",
    },
    editRequest: {
        document: {
            width: "30vw",
            mobileWidth: "60vw",
        },
        incidence: {
            width: "30vw",
            mobileWidth: "80vw",
        },
        invoiceConcept: {
            width: "30vw",
            mobileWidth: "",
        },
        seal: {
            width: "30vw",
            mobileWidth: "60vw",
        },
        originalSeal: {
            width: "30vw",
            mobileWidth: "60vw",
        },
        batch: {
            width: "30vw",
            mobileWidth: "60vw",
        },
    },
    management: {
        user: {
            userForm: {
                width: "60vw",
            },
        },
        representation: {
            representationForm: {
                width: "35vw",
            },
        },
        notification: {
            notificationForm: {
                width: "35vw",
            },
        },
        icons: 18,
    },
    confirmPopUp: {
        remove: "auto",
        icon: "5rem",
    },
    master: {
        default: "30vw",
        phone: "80vw",
    },
};
