import { IUserAdminData } from "../user";

export interface IGenericOptionsWithLoading {
    loading: boolean;
}

export interface IGenericUsersData extends IGenericOptionsWithLoading {
    data?: IUserAdminData[];
}

export interface GenericOptionsWithLoading<T> {
    [x: string]: any;
    options: T[];
    loading: boolean;
}

export interface ICustomGenericOptionsWithLoading<T> {
    [x: string]: any;
    options?: T[];
    loading: boolean;
}
export const emptyDropdownOptionsWithLoading = (): GenericOptionsWithLoading<any> => {
    return {
        options: [],
        loading: false,
    };
};

export const emptyDropdownWithLoading = (): ICustomGenericOptionsWithLoading<any> => {
    return {
        loading: false,
    };
};

export const emptyUsersDataWithLoading = (): IGenericUsersData => {
    return {
        loading: false
    };
};

