import { InvoicingUninvoicedApi } from "../../../../api";
import { stringDefaultValue, throwErrorToastrAsync } from "../../../../utils";
import { resetInvoicingUninvoicedFilter } from "../../../reducers/invoicing/uninvoiced/filter";
import { setLoading, setPage, setList, resetInvoicingUninvoicedList } from "../../../reducers/invoicing/uninvoiced/list";
import { AppThunk, store } from "../../../store";

const requestApi = new InvoicingUninvoicedApi();

const getInvoicingUninvoicedFilter = (): any => {
    const { filter } = store.getState().invoicingUninvoicedConceptFilter;
    return {
        ...filter,
        customerId: filter.customerId !== stringDefaultValue.key ? filter.customerId : null,
    };
};

export const fetchInvoicingUninvoicedAction = (resetPagination : boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const filter = getInvoicingUninvoicedFilter();
            const response = await requestApi.getUninvoiced(filter);
            dispatch(setList(response));
            if (resetPagination) {
                dispatch(setPage(1));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoading(false));
        }
};

export const setPageAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        dispatch(setPage(page));
};

export const exportInvoicingUninvoicedAction = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const filter = getInvoicingUninvoicedFilter();
            const response = await requestApi.exportUninvoiced(filter);
            window.open(response);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoading(false));
        }
};

export const resetInvoicingUninvoicedAction = (): AppThunk =>
    (dispatch) => {
  dispatch(resetInvoicingUninvoicedList()) ; 
  dispatch(resetInvoicingUninvoicedFilter());
};
