import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { IInvoiceEntity, IKeyCodeDescription, IKeyCodeDescriptionWithDefault, IKeyDescription, IUserAdminData } from "../models";
import { IFoldedMenu } from "../models/common/IFoldedMenu";
import { IRole } from "../models/common/IRole";
import { IInvoiceConcept } from "../models/management/tariff/ITariffData";
import { ITransportUnitOptions, IMerchandiseTypeOptions, IComboBoxOptionWithDefault } from "../models/selectors";
import { IUserModuleData } from "../models/user/IUserModuleData";
import { stringDefaultValue } from "./formUtils";
import { ITimeZone } from "../models/appointment/IDashboardZonesAvaiable";

const noData: IComboBoxOption = {
    key: "",
    text: "",
    title: "",
};

export const mapAllOptionIDescriptionToDropDownOptions = (
    items: IKeyCodeDescription[] | IKeyDescription[],
    allOptionText?: string,
): IComboBoxOption[] => {
    let list: IComboBoxOption[] = [];

    let customStringDefaultValue: IDropdownOption = {
        ...stringDefaultValue,
        text: allOptionText ? allOptionText : stringDefaultValue.text,
    };

    if (items.length === 0) {
        return [customStringDefaultValue];
    }

    list.push(customStringDefaultValue);

    for (let item of items) {
        list.push(mapIDescriptionToDropdownOption(item));
    }

    return list;
};

export const mapIDescriptionToDropDownOptions = (items: IKeyCodeDescription[] | IKeyDescription[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }
    return items.map(mapIDescriptionToDropdownOption);
};

export const mapIDescriptionAppoitmentZoneToDropDownOptions = (items: ITimeZone[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [];
    }
    return items.map(mapICustomDescriptionToDropdownOption);
};

export const mapICustomDescriptionToDropdownOption = (item: ITimeZone): IComboBoxOption => {
    return {
        key: item.id,
        text: `${item.description} (${item.startTime}-${item.endTime})`,
        title: item.description,
    };
};

export const mapIDescriptionToDropdownOption = (item: IKeyCodeDescription | IKeyDescription): IComboBoxOption => {
    return {
        key: item.id,
        text: item.description,
        title: item.description,
    };
};

export const mapToITransportUnitOptions = (items: any): ITransportUnitOptions[] => {
    if (items.length === 0) {
        return [
            {
                key: "",
                text: "",
                title: "",
                isContainer: false,
            },
        ];
    }
    return items.map(mapIDescriptionToITransportUnit);
};

const mapIDescriptionToITransportUnit = (item: any): ITransportUnitOptions => {
    return {
        key: item.id,
        text: item.description,
        title: item.description,
        isContainer: item.id % 2 === 0,
    };
};

export const mapToIMerchandiseTypeOptions = (items: any): IMerchandiseTypeOptions[] => {
    if (items.length === 0) {
        return [
            {
                key: "",
                text: "",
                title: "",
                humanConsumption: false,
                merchandiseOriginId: -1,
            },
        ];
    }
    return items.map(mapToIMerchandiseTypeOption);
};

const mapToIMerchandiseTypeOption = (item: any): IMerchandiseTypeOptions => {
    return {
        key: item.id,
        text: item.description,
        title: item.description,
        humanConsumption: item.humanConsumption,
        merchandiseOriginId: item.merchandiseOriginId,
    };
};

export const mapUserAdminDataToDropDownOptions = (items: IUserAdminData[], registeredCustomer: boolean): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }
    if (registeredCustomer) {
        return items.filter((item) => !item.unregistered).map((item) => mapUserAdminDataToDropdownOption(item));
    }

    return items.map((item) => mapUserAdminDataToDropdownOption(item));
};

export const mapUserAdminDataToDropdownOption = (item: IUserAdminData): IComboBoxOption => {
    return {
        key: item.id,
        text: item.entityData?.name || item.id,
        title: item.entityData?.name || item.id,
    };
};

export const mapUserToDropDownOptions = (items: IUserAdminData[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }
    return items.map(mapUserToDropdownOption);
};

const mapUserToDropdownOption = (item: IUserAdminData): IComboBoxOption => {
    return {
        key: item.id,
        text: `${item.name} ${item.surName ? item.surName : ""} ${item.lastName ? item.lastName : ""}`,
        title: `${item.name} ${item.surName ? item.surName : ""} ${item.lastName ? item.lastName : ""}`,
    };
};

export const mapUserEntityWithEmailToDropDownOptions = (items: IUserAdminData[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }

    return items.map((item) => mapUserEntityWithEmailToDropDownOption(item));
};

export const mapUserEntityWithEmailToDropDownOption = (item: IUserAdminData): IComboBoxOption => {
    return {
        key: item.id,
        text: `${item.entityData?.name ?? ""} - ${item.id}`,
        title: `${item.entityData?.name ?? ""} - ${item.id}`,
    };
};

export const mapUserWithEmailToDropDownOptions = (items: IUserAdminData[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }

    return items.map((item) => mapUserWithEmailToDropDownOption(item));
};

export const mapUserWithEmailToDropDownOption = (item: IUserAdminData): IComboBoxOption => {
    return {
        key: item.id,
        text: `${item.name} ${item.surName ? item.surName : ""} ${item.lastName ? item.lastName : ""} - ${item.id}`,
        title: `${item.name} ${item.surName ? item.surName : ""} ${item.lastName ? item.lastName : ""} - ${item.id}`,
    };
};

export const mapRolesToDropDownOptions = (items: IRole[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }
    return items.map(mapRolesToDropdownOption);
};

const mapRolesToDropdownOption = (item: IRole): IComboBoxOption => {
    return {
        key: item.id,
        text: item.name,
        title: item.name,
    };
};

export const mapInvoiceEntitiesToDropDownOptions = (items: IInvoiceEntity[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }
    return items.map(mapInvoiceEntityToDropdownOption);
};

const mapInvoiceEntityToDropdownOption = (item: IInvoiceEntity): IComboBoxOption => {
    return {
        key: item.customerId,
        text: `${item.customerCode} - ${item.customerDescription}`,
        title: `${item.customerCode} - ${item.customerDescription}`,
    };
};

export const mapValuedTypesToDropDownOptions = (items: IKeyCodeDescription[]): IComboBoxOption[] => {
    if (items.length === 0) {
        return [noData];
    }
    return items.map(mapValuedTypesToDropDownOption);
};

const mapValuedTypesToDropDownOption = (item: IKeyCodeDescription): IComboBoxOption => {
    return {
        key: item.code,
        text: item.description,
        title: item.description,
    };
};

export const mapIDescriptionWithDefaultToDropDownOptions = (items: IKeyCodeDescriptionWithDefault[]): IComboBoxOptionWithDefault[] => {
    if (items.length === 0) {
        return [
            {
                key: "",
                text: "",
                title: "",
                defaultValue: false,
            },
        ];
    }
    return items.map(mapIDescriptionToDropdownOptionWithDefault);
};

const mapIDescriptionToDropdownOptionWithDefault = (item: IKeyCodeDescriptionWithDefault): IComboBoxOptionWithDefault => {
    return {
        key: item.id,
        text: item.description,
        title: item.description,
        defaultValue: item.defaultValue,
    };
};

export const mapInvoiceConceptsTariff = (list: IInvoiceConcept[]) => {
    const mapperList: IComboBoxOption[] = list.map((item) => {
        return {
            key: item.conceptId,
            text: item.conceptDescription,
        };
    });
    return mapperList;
};

export const mapStringToDropdown = (list: string[]) => {
    const mapperList: IComboBoxOption[] = list.map((item, index) => {
        return {
            key: index,
            text: item,
        };
    });
    return mapperList;
};

export const mapModuleToFoldedMenu = (modules: IUserModuleData[]) => {
    const mapperList: IFoldedMenu[] = modules.map((item, index) => {
        return {
            featureId: item.id,
            folded: item.folded!,
        };
    });
    return mapperList;
};

export const mapObjectToArrayNumber = <T extends Record<string, any>>(objList: T[], propertyName: keyof T): number[] => {
    return objList.map((obj) => obj[propertyName]);
};
