import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterMerchandiseCategory } from "../../../../../models/resources/master/IMerchandiseCategory";

export interface IMasterMerchandiseCategoryState {
    list?: IMasterMerchandiseCategory[];
    loading: boolean;
}

const initialState: IMasterMerchandiseCategoryState = {
    loading: false,
};

export const masterMerchandiseCategoryList = createSlice({
    name: "masterTypeWareList",
    initialState,
    reducers: {
        resetMasterMerchandiseCategoryList: () => initialState,
        setLoadingMasterMerchandiseCategoryList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterMerchandiseCategoryList: (state, action: PayloadAction<IMasterMerchandiseCategory[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterMerchandiseCategoryList, setLoadingMasterMerchandiseCategoryList, setMasterMerchandiseCategoryList } =
    masterMerchandiseCategoryList.actions;

export default masterMerchandiseCategoryList.reducer;
