import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../redux/hooks";
import { nextStep, previousStep } from "../../../../redux/reducers/user/signup/stepper";
import { userSignupFormSelector } from "../../../../redux/selectors/user/signup/signup";
import { ButtonPrimary, ButtonSecondary } from "../../../common/buttons/buttons";
import { UserSignupAdminInfoData } from "./info/admin/info";
import { UserSignupOperationEmailsForm } from "./list/list";
import { UserEntitySignupDataContainerStyles } from "./containerStyle.jss";
import { UserEntityInfoSignupData } from "./info/entity/info";
import { adminResolver, entityResolver, paymentResolver } from "./entityResolvers";
import { UserEntityPaymentInfoData } from "./info/payment/info";
import { UserType } from "../../../../common/enum/UserType";

export const UserEntitySignupDataContainer = () => {
    const styles = UserEntitySignupDataContainerStyles();

    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const {
        register: entityRegister,
        formState: { errors: entityErrors, isValid: isEntityValid },
    } = useForm<any>({ mode: "onChange", resolver: entityResolver });

    const {
        register: adminRegister,
        formState: { errors: adminErrors, isValid: isAdminValid },
    } = useForm<any>({ mode: "onChange", resolver: adminResolver });

    const {
        register: operationRegister,
        formState: { errors: operationErrors, isValid: isOperationValid },
    } = useForm<any>({ mode: "onChange", resolver: adminResolver });

    const {
        register: paymentRegister,
        formState: { errors: paymentErrors, isValid: isPaymentValid },
    } = useForm<any>({ mode: "onChange", resolver: paymentResolver });

    const form = useSelector(userSignupFormSelector);

    const areInvalidEmails = useMemo(() => {
        return form.operatorData.emails.some((email) => !email.isValid) || form.adminData.emails.some((email) => !email.isValid);
    }, [form.operatorData.emails, form.adminData.emails]);

    const isTruckDataValid = useMemo(() => {
        return form.userType === UserType.TRUCKER || (!areInvalidEmails && isAdminValid && isOperationValid && isPaymentValid);
    }, [form.userType, areInvalidEmails, isAdminValid, isOperationValid, isOperationValid, isPaymentValid]);

    const disableNextButton = useMemo(() => {
        return !isEntityValid || !form.organizationId || !isTruckDataValid;
    }, [isEntityValid, form.organizationId, isTruckDataValid]);

    return (
        <>
            <div className={styles.content}>
                <div className={styles.section}>
                    <UserEntityInfoSignupData
                        data={form.entityData}
                        register={entityRegister}
                        errors={entityErrors}
                    />
                </div>

                {form.userType === UserType.CUSTOMER && (
                    <>
                        <div className={styles.section}>
                            <UserEntityPaymentInfoData
                                data={form.paymentData}
                                register={paymentRegister}
                                errors={paymentErrors}
                            />
                        </div>
                        <div className={styles.section}>
                            <UserSignupAdminInfoData
                                data={form.adminData}
                                adminErrors={adminErrors}
                                adminRegister={adminRegister}
                            />
                        </div>
                        <div className={styles.section}>
                            <UserSignupOperationEmailsForm
                                data={form.operatorData}
                                adminErrors={operationErrors}
                                adminRegister={operationRegister}
                            />
                        </div>
                    </>
                ) }
                <div className={styles.divisor}></div>

                <div className={styles.buttonsRow}>
                    <ButtonSecondary
                        handleClick={() => dispatch(previousStep())}
                        title={t("USER.SIGNUP.BACKBUTTON")}
                    />
                    <ButtonPrimary
                        disabled={disableNextButton}
                        handleClick={() => dispatch(nextStep())}
                        title={t("USER.SIGNUP.NEXTBUTTON")}
                    />
                </div>
            </div>
        </>
    );
};
