import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { AppointmentHistoricListColumnStyle } from "./columnStyle.jss";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { formatDate } from "../../../../../utils";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../assets/icons";
import { useAppDispatch } from "../../../../../redux/hooks";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { IAppointmentHistoricList } from "../../../../../models/appointment/IAppointmentHistoric";
import { setAppointmentHistoricForm, setAppointmentHistoricFormPopUpShow } from "../../../../../redux/reducers/appointment/historic/form";

export const ApointmentHistoricListColumn = (selectedKey: string): IColumn[] => {
    const theme = useTheme();
    const styles = AppointmentHistoricListColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT_HISTORIC" });
    const dispatch = useAppDispatch();
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IAppointmentHistoricList>("date"),
            minWidth: 100,
            maxWidth: 100,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentHistoricList>("date")}
                    title={t("DATE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IAppointmentHistoricList) => <span>{formatDate(item.date, FormatDate.DATE_HOUR_MINUTE)}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentHistoricList>("certificateNumber"),
            minWidth: 120,
            maxWidth: 150,
            name: t("CERTIFICATE_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentHistoricList>("certificateNumber")}
                    title={t("CERTIFICATE_NUMBER")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IAppointmentHistoricList) => <span>{item.certificateNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentHistoricList>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 150,
            name: t("TRANSPORT_UNIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentHistoricList>("transportUnitNumber")}
                    title={t("TRANSPORT_UNIT")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IAppointmentHistoricList) => <span>{item.transportUnitNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentHistoricList>("withErrors"),
            minWidth: 120,
            maxWidth: 150,
            name: t("WITH_ERROR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentHistoricList>("withErrors")}
                    title={t("WITH_ERROR")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IAppointmentHistoricList) => (
                <span>
                    <BooleanValue value={item.withErrors} />
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IAppointmentHistoricList>("messageData"),
            minWidth: 100,
            maxWidth: 100,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentHistoricList>("messageData")}
                    title={""}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),

            onRender: (item: IAppointmentHistoricList) => (
                <span className={styles.action}>
                    <CustomIcons
                        iconName={NameIcons.EYEICON}
                        size={20}
                        tooltip={t("VIEW_ERRORS")}
                        onClick={() => {
                            dispatch(setAppointmentHistoricFormPopUpShow(PopUpCodes.CREATE));
                            dispatch(
                                setAppointmentHistoricForm({
                                    messaageData: item.messageData,
                                    response: item.responseData,
                                }),
                            );
                        }}
                    ></CustomIcons>
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
