import { IConfigRoleProperty } from "../../../models";
import { IField } from "../../../models/requests/IUserRoleView";
import { isFieldRequired, compileNameOfProperty, isFieldVisible, isFieldReadOnly } from "../../../utils";


export interface IConfigUpcominAppointmentSort {
    startDate: IConfigRoleProperty;
    endDate: IConfigRoleProperty;
    inspectorId: IConfigRoleProperty;
    customerId: IConfigRoleProperty;
    organismId: IConfigRoleProperty;
    merchandiseCategoryId:IConfigRoleProperty;
    merchandiseTypeId:IConfigRoleProperty;
}

export const generateUpcomingAppointmentSortFilterSchema = (fields: IField[]): IConfigUpcominAppointmentSort => {
    return {
        startDate: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("startDate")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("startDate")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("startDate")),
        },
        endDate: {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("endDate")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("endDate")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("endDate")),
        },
        inspectorId:{
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("inspectorId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("inspectorId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("inspectorId")),
        }, 
        customerId:{
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("customerId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("customerId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("customerId")),
        }, 
        organismId:{
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("organismId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("organismId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("organismId")),
        }, 
        merchandiseCategoryId:{
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("merchandiseCategoryId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("merchandiseCategoryId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("merchandiseCategoryId")),
        },
        merchandiseTypeId : {
            required: isFieldRequired(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("merchandiseTypeId")),
            visible: isFieldVisible(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("merchandiseTypeId")),
            readonly: isFieldReadOnly(fields, compileNameOfProperty<IConfigUpcominAppointmentSort>("merchandiseTypeId")),
        }
    };
};
