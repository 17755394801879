import { UserApi } from "../../../../api";
import { mapUserAdminDataToDropDownOptions } from "../../../../utils";
import { throwErrorToastrAsync } from "../../../../utils/apiUtils";
import { setDetailsCarrierResponse, setLoadingCarrierImport, setUriTemplateCarrierImport } from "../../../reducers/resources/carrierImport/import";
import { setDetailsResponse, setLoadingCustomerImport, setUriTemplateCustomerImport } from "../../../reducers/resources/customersImport/import";
import {
    loadingActiveAgents,
    loadingActiveCarriers,
    loadingActiveCustomers,
    loadingActiveOrganisms,
    loadingActivePortLectors,
    loadingActiveTerminals,
    loadingAllInspectorOptions,
    loadingCarrierAdminOptions,
    loadingCustomerAdminOptions,
    loadingInspectorOptions,
    loadingInvoiceCustomersOptions,
    setActiveAgents,
    setActiveCarriers,
    setActiveCustomers,
    setActiveOrganisms,
    setActivePortLectors,
    setActiveTerminals,
    setAllInspectorOptions,
    setCarrierAdminOptions,
    setCustomerAdminOptions,
    setInspectorOptions,
    setInvoiceCustomersOptions,
} from "../../../reducers/user/users/usersByRole";
import { AppThunk, store } from "../../../store";
import { setGlobalLoadingAction } from "../../common";

const userApi = new UserApi();

export const fetchCustomerOptions = (): AppThunk => async (dispatch) => {
    try {
        const { loading } = store.getState().usersByRole.customerAdmin;
        if (loading) return;
        dispatch(loadingCustomerAdminOptions(true));
        const response = await userApi.getAdminCustomers();
        dispatch(setCustomerAdminOptions(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingCustomerAdminOptions(false));
    }
};
export const fetchPromiseInvoiceCustomerOptions =
    (customerId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                dispatch(loadingInvoiceCustomersOptions(true));
                const response = await userApi.getInvoiceCustomers(customerId);
                dispatch(setInvoiceCustomersOptions(response));
                resolve();
            } catch (error) {
                throwErrorToastrAsync(error);
                reject(error);
            } finally {
                dispatch(loadingInvoiceCustomersOptions(false));
            }
        });
    };

export const fetchInvoiceCustomerOptions =
    (customerId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            dispatch(loadingInvoiceCustomersOptions(true));
            const response = await userApi.getInvoiceCustomers(customerId);
            dispatch(setInvoiceCustomersOptions(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingInvoiceCustomersOptions(false));
            dispatch(setGlobalLoadingAction(false));
        }
    };

export const fetchCarrierOptions = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingCarrierAdminOptions(true));
        const response = await userApi.getAdminCarriers();
        dispatch(setCarrierAdminOptions(mapUserAdminDataToDropDownOptions(response, false)));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingCarrierAdminOptions(false));
    }
};

export const fetchAllInspectorOptions =
    (organismId?: any): AppThunk =>
    async (dispatch) => {
        try {
            const { loading } = store.getState().usersByRole.inspector;
            if (loading) return;
            dispatch(loadingInspectorOptions(true));
            const response = await userApi.getAllRoleInspector(organismId);
            dispatch(setInspectorOptions(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingInspectorOptions(false));
        }
    };

export const fetchInspectorOptions =
    (organismId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(loadingAllInspectorOptions(true));
            const response = await userApi.getRoleInspector(organismId);
            dispatch(setAllInspectorOptions(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(loadingInspectorOptions(false));
        }
    };

export const fetchActiveCustomers = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActiveCustomers(true));
        const response = await userApi.getActiveCustomers();
        dispatch(setActiveCustomers(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActiveCustomers(false));
    }
};

export const fetchActiveCarriers = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActiveCarriers(true));
        const response = await userApi.getActiveCarriers();
        dispatch(setActiveCarriers(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActiveCarriers(false));
    }
};

export const fetchActiveAgents = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActiveAgents(true));
        const response = await userApi.getActiveAgents();
        dispatch(setActiveAgents(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActiveAgents(false));
    }
};

export const fetchActiveOrganinsms = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActiveOrganisms(true));
        const response = await userApi.getActiveOrganisms();
        dispatch(setActiveOrganisms(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActiveOrganisms(false));
    }
};

export const fetchActivePortLectors = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActivePortLectors(true));
        const response = await userApi.getActivePortLectors();
        dispatch(setActivePortLectors(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActivePortLectors(false));
    }
};

export const fetchActiveTerminals = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingActiveTerminals(true));
        const response = await userApi.getActiveTerminals();
        dispatch(setActiveTerminals(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingActiveTerminals(false));
    }
};

export const fetchImportTemplate = (): AppThunk => async (dispatch) => {
    try {
        const response = await userApi.getImportTemplate();
        dispatch(setUriTemplateCustomerImport(response.uri));
    } catch (error) {
        throwErrorToastrAsync(error);
    }
};

export const fetchImportCustomerFile =
    (excelFile: any): any =>
    (dispatch: any) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadingCustomerImport(true));
            userApi
                .postImportCustomerFile(excelFile)
                .then((response) => {
                    dispatch(setDetailsResponse(response));
                    resolve(true); // Resuelve la promesa con el valor true.
                })
                .catch((error) => {
                    throwErrorToastrAsync(error);
                    reject(error); // Rechaza la promesa con el error.
                })
                .finally(() => {
                    dispatch(setLoadingCustomerImport(false));
                });
        });
    };
export const fetchImportCarrierTemplate = (): AppThunk => async (dispatch) => {
    try {
        const response = await userApi.getImportCarrierTemplate();
        dispatch(setUriTemplateCarrierImport(response.uri));
    } catch (error) {
        throwErrorToastrAsync(error);
    }
};

export const fetchImportCarrierFile =
    (excelFile: any): any =>
    (dispatch: any) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadingCarrierImport(true));
            userApi
                .postImportCarrierFile(excelFile)
                .then((response) => {
                    dispatch(setDetailsCarrierResponse(response));
                    resolve(true); // Resuelve la promesa con el valor true.
                })
                .catch((error) => {
                    throwErrorToastrAsync(error);
                    reject(error); // Rechaza la promesa con el error.
                })
                .finally(() => {
                    dispatch(setLoadingCarrierImport(false));
                });
        });
    };
