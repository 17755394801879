import { FormatDate } from "../../../common/enum/dateTime/FormatDate";
import { convertToLocal, stringDefaultValue } from "../../../utils";

export interface IInvoicingFilter {
    startDate: string;
    endDate: string;
    customerId?: string;
}

export const emptyInvoiceFilter = (): IInvoicingFilter => {
    return {
        startDate: convertToLocal(new Date(), FormatDate.ISO),
        endDate: convertToLocal(new Date(), FormatDate.ISO),
        customerId: stringDefaultValue.key.toString()
    };
};