import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface IRequestIntegrationForm {
    response: string;
    messaageData: string;
}
interface IRequestIntegrationFormState {
    form: IRequestIntegrationForm;
    showPopUp: PopUpCodes;
}

const initialState: IRequestIntegrationFormState = {
    form: {
        response: "",
        messaageData: "",
    },
    showPopUp: PopUpCodes.NONE,
};

export const requestIntegrationFormReducer = createSlice({
    name: "requestIntegrationFormReducer",
    initialState,
    reducers: {
        resetRequestIntegrationForm: () => initialState,
        updateRequestIntegrationFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setRequestIntegrationFormPopUpShow: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        setRequestIntegrationForm: (state, action: PayloadAction<IRequestIntegrationForm>) => {
            state.form = action.payload;
        },
    },
});

export const { resetRequestIntegrationForm, updateRequestIntegrationFormProperty, setRequestIntegrationFormPopUpShow, setRequestIntegrationForm } =
requestIntegrationFormReducer.actions;

export default requestIntegrationFormReducer.reducer;
