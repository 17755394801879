import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { IGenericPayload } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";

import { mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils/formUtils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterOrganismFormStyle } from "./formStyle.jss";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterFinalDestinationTypeRemaining, IMasterFinalDestinationType } from "../../../../../../../models/resources/master/IFinalDestinationType";
import { updateMasterFinalDestinationTypesProperty, setMasterFinalDestinationTypesForm } from "../../../../../../../redux/reducers/resources/master/finalDestinationTypes/form";
interface IMasterOrganismFormProps {
    showNotData: boolean;
    selectorData: IMasterFinalDestinationTypeRemaining[];
}

export const MasterFinalDestinyTypesForm: React.FC<IMasterOrganismFormProps> = ({ showNotData, selectorData }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const theme = useTheme();
    const style = MasterOrganismFormStyle({ theme });
    const { form } = useAppSelector((state) => state.masterFinalDestinationTypesForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { register, control } = useForm<IMasterFinalDestinationType>({ mode: "onChange" });

   

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateMasterFinalDestinationTypesProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateFormProperty(name, option.key);
            let finalDestination = selectorData.find((item) => item.id === option.key);
            if (finalDestination) {
                let newForm: IMasterFinalDestinationType = {
                    ...form,
                    code: finalDestination.code,
                    description: finalDestination.description,
                    referenceId: String(option.key),
                };
                dispatch(setMasterFinalDestinationTypesForm(newForm));
            }
        },
        [dispatch, selectorData],
    );

    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form.referenceId]);

    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterFinalDestinationType>("referenceId")}
                        title={t("FORM.FINALDESTINATION_SELECTOR")}
                        options={mapIDescriptionToDropDownOptions(selectorData)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterFinalDestinationType>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterFinalDestinationType>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.defaultValue}
                    propertyName={compileNameOfProperty<IMasterFinalDestinationType>("defaultValue")}
                    title={t("DEFAULT")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterFinalDestinationType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
