import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IInspectionAccumulativeByMonthStatistics } from "../../../../../models/statistics/inspection/IAccumulated";
import { useAppSelector } from "../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { MonthDescription } from "../../../monthlySummary/list/column/column";
import { dinamicItemData } from "../../utils/utils";
import { CumulativeMonthColumnStyle } from "./columnStyle.jss";
import { OrganismTypes } from "../../../../../common/enum";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsIncidenceMonthlyListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = CumulativeMonthColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.ACCUMULATED_TABLE" });

    const { organisms } = useAppSelector((store) => store.statiticsInspectionAccumulativeList.data);
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("monthSummary"),
            minWidth: 65,
            maxWidth: 150,
            name: t("MONTH"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("monthSummary")}
                    title={t("MONTH")}
                    styles={styles}
                    isSortable={false}
                    handleClick={() => {}}
                   
                />
            ),
            onRender: (item: IInspectionAccumulativeByMonthStatistics) => (
                <span className={styles.dataCell}>
                    <MonthDescription idMonth={item.monthSummary}></MonthDescription>
                </span>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts"),
            minWidth: 90,
            maxWidth: 100,
            name: t("TOTAL_CERTIFICATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("inspectionsPositionedWithInvoiceConcepts")}
                    title={t("TOTAL_CERTIFICATE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                   
                />
            ),
            onRender: (item: IInspectionAccumulativeByMonthStatistics) => (
                <span className={styles.dataCell}>{item.inspectionsPositionedWithInvoiceConcepts ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests"),
            minWidth: 80,
            maxWidth: 150,
            name: t("REQUEST_MOUNTH"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requests")}
                    title={t("REQUEST_MOUNTH")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                   
                />
            ),
            onRender: (item: IInspectionAccumulativeByMonthStatistics) => <span className={styles.dataCell}>{item.requests?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts"),
            minWidth: 140,
            maxWidth: 200,
            name: t("INVOICE_CONCEPT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConcepts")}
                    title={t("INVOICE_CONCEPT")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                   
                />
            ),
            onRender: (item: IInspectionAccumulativeByMonthStatistics) => (
                <span className={styles.dataCell}>{item.requestsPositionedWithInvoiceConcepts?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsPositionedWithInvoiceConceptsWithManyOrganisms")}
                    title={t("ORGANISM")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                   
                />
            ),
            onRender: (item: IInspectionAccumulativeByMonthStatistics) => <span className={styles.dataCell}>{item.requestsPositionedWithInvoiceConceptsWithManyOrganisms?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: false,
        },
        {
            key: compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms"),
            minWidth: 100,
            maxWidth: 110,
            name: t("REQUEST_ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInspectionAccumulativeByMonthStatistics>("requestsWithManyOrganisms")}
                    title={t("REQUEST_ORGANISM")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                   
                />
            ),
            onRender: (item: IInspectionAccumulativeByMonthStatistics) => (
                <span className={styles.dataCell}>{item.requestsWithManyOrganisms?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: false,
        },
    ];

    const dinamicColumns = () => {
        const newColumns = [];
        newColumns.push(columns[0]);

        for (let organism of organisms) {
            newColumns.push({
                key: organism.code,
                minWidth: 55,
                maxWidth: 80,
                name: organism.code,
                onRenderHeader: () => (
                    <CustomHeaderColumn
                        name={organism.code}
                        title={dinamicTranslate(organism.code)}
                        styles={styles}
                    />
                ),
                onRender: (item: IInspectionAccumulativeByMonthStatistics) => (
                    <span className={styles.dataCell}>{dinamicItemData(item.organismRequests, organism)}</span>
                ),
                isResizable: false,
            });
        }
        for (const [index, column] of columns.entries()) {
            index > 0 && newColumns.push(column);
        }
        return newColumns;
    };

    const dinamicTranslate = (idOrganims: string) => {
        switch (idOrganims) {
            case OrganismTypes.CUSTOMS:
                return t("ADUANA");
            case OrganismTypes.TAX_CANARY:
                return t("CANARIA");
            case OrganismTypes.ANIMAL_HEALTH:
                return t("ANIMAL");
            case OrganismTypes.FOREIGN_HEALTH:
                return t("EXTERIOR");
            case OrganismTypes.PLANT_HEALTH:
                return t("VEGETAL");
            case OrganismTypes.SOIVRE:
                return t("SOIVRE");
            case OrganismTypes.OTHERS:
                return t("OTHERS");
            case OrganismTypes.PHARMACY:
                return t("PHARMACY");
            case OrganismTypes.FISHING:
                return t("FISHING");
            case OrganismTypes.BORDER_INSPECTION_BODY:
                return t("BORDER_INSPECTION_BODY");
            default:
                return  DefaultValues.EMPTY_LIST;
        }
    };

    return dinamicColumns();
};
