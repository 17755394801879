import React from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { IAdminData, IEntityData, IPaymentData } from "../../../../models";
import { loadingUserProfileSelector, userProfileSelector } from "../../../../redux/selectors/user/profile/userProfile";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { UserProfileEntityContainerStyle } from "./containerStyle.jss";
import { PerfilEntityDataContainer } from "./data/container";
import { UserProfileEntityUsersContainer } from "./users/container";

interface IUserProfileEntityContainerProps {
    disabled: boolean;
    entityRegister: UseFormRegister<IEntityData>;
    entityErrors: FieldErrors<IEntityData>;
    paymentRegister: UseFormRegister<IPaymentData>;
    paymentErrors: FieldErrors<IPaymentData>;
    adminRegister: UseFormRegister<IAdminData>;
    adminErrors: FieldErrors<IAdminData>;
    operationRegister: UseFormRegister<IAdminData>;
    operationErrors: FieldErrors<IAdminData>;
}
export const UserProfileEntityContainer: React.FC<IUserProfileEntityContainerProps> = ({
    disabled,
    entityRegister,
    entityErrors,
    paymentRegister,
    paymentErrors,
    adminRegister,
    adminErrors,
    operationRegister,
    operationErrors,
}) => {
    const theme = useTheme();
    const style = UserProfileEntityContainerStyle({ theme });
    const loading = useSelector(loadingUserProfileSelector);

    const form = useSelector(userProfileSelector);
    return (
        <div className={style.entityContainer}>
            <div className={style.entityContent}>
                {loading ? (
                    <Skeleton rows={5}></Skeleton>
                ) : (
                    <PerfilEntityDataContainer
                        userProfile={form}
                        adminErrors={adminErrors}
                        adminRegister={adminRegister}
                        entityErrors={entityErrors}
                        entityRegister={entityRegister}
                        operationErrors={operationErrors}
                        operationRegister={operationRegister}
                        paymentErrors={paymentErrors}
                        paymentRegister={paymentRegister}
                    />
                )}
            </div>
            <div
                className={style.usersContent}
                style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
            >
                <UserProfileEntityUsersContainer />
            </div>
        </div>
    );
};
