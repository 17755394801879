import { Method } from "../../../common/enum";
import {
    IParkingValidationConfiguration,
    IParkingValidationFormData,
    IParkingValidationRulesList,
} from "../../../models/management/parkingValidationRule/IParkingValidationRule";

import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/validationparking`;

export class ManagementParkingValidationRuleApi {
    async getAllRules(): Promise<IParkingValidationRulesList[]> {
        const url = `${baseURL}/rules/all`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute<IParkingValidationRulesList[]>();
    }

    async getConfiguration(): Promise<IParkingValidationConfiguration> {
        const url = `${baseURL}/configuration`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IParkingValidationConfiguration>();
    }

    async updateParkingValidationRuleAsync(parkingValidationFormData: IParkingValidationFormData): Promise<boolean> {
        const url = `${baseURL}/rule`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).withBody(parkingValidationFormData).execute<boolean>();
    }

    async createParkingValidationRuleAsync(parkingValidationFormData: IParkingValidationFormData): Promise<boolean> {
        const url = `${baseURL}/rule`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(parkingValidationFormData).execute<boolean>();
    }

    async getParkingValidationRuleAsync(id: number): Promise<IParkingValidationFormData> {
        const url = `${baseURL}/rule/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IParkingValidationFormData>();
    }

    async deleteParkingValidationRule(id: number | null): Promise<boolean> {
        const url = `${baseURL}/rule/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute();
    }

    async putParkingValidationRulePriorityAsync(id: number, metod: string): Promise<boolean> {
        const url = `${baseURL}/sorted/${id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).execute<boolean>();
    }
}
