import { createUseStyles } from "react-jss";

export const exceptionSectionStyle = createUseStyles((theme: any) => ({
    parkingValidationExceptionActiveContainer: {
        display: "flex",
        gap: theme.gaps.sizes.default,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: 5,
        marginTop: 10,
        gap: 10,
    },
    form: {
        marginBottom: 10,
        display: "flex",
        gap: 20,
        alignItems: "end",
    },
    ckeckBox: {
        width: 100,
    },
    formField: {
        width: 230,
    },
    appointment: {
        display: "flex",
        flexDirection: "column",
        gap: 40,
    },
}));
