import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterDocksForm, setMasterDocksForm } from "../../../../reducers/resources/master/docks/form";
import { setLoadingMasterDisplayDocksList, setMasterDisplayDocksList } from "../../../../reducers/resources/master/docks/list";
import { AppThunk, store } from "../../../../store";


const catalogAPI = new CatalogAPI();

export const fetchMasterDocksList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterDisplayDocksList(true));
        const response = await catalogAPI.getMasterDocksList();
        dispatch(setMasterDisplayDocksList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterDisplayDocksList(false));
    }
};

export const fetchMasterDocksForm =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterDocksForm(true));
            const response = await catalogAPI.getMasterDock(id);
            dispatch(setMasterDocksForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterDocksForm(false));
        }
    };

export const postToMasterDocksForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterDocksForm;

        try {
            dispatch(setLoadingMasterDocksForm(true));
            if (form.id) {
                await catalogAPI.putMasterDocks(form);
            } else {
                await catalogAPI.postMasterDocks(form);
            }
            dispatch(fetchMasterDocksList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error); dispatch(setLoadingMasterDocksForm(false));
            return false;
        } 
    };
};

export const patchSortDocksList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterDisplayDocksList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterDocksList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterDisplayDocksList(false));
        }
    };
