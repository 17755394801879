import React, { useMemo } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTheme } from "react-jss";
import { PerfilEntityDataContainerStyle } from "./containerStyle.jss";

import { UserProfileEntityInfoForm } from "./info/entity/info";
import { UserProfilePaymentInfoForm } from "./info/payment/info";
import { useSelector } from "react-redux";
import { TitleH4 } from "../../../../common/titles/titles";
import { useTranslation } from "react-i18next";
import { getUserRole } from "../../../../../redux/selectors/user/role/role";
import { UserTruckerFileProfileData } from "./truckerFiles/files";
import { UserProfileAdminEmails } from "./list/adminEmail/list";
import { UserProfileOperationEmails } from "./list/operationalEmail/list";
import { ColorType } from "../../../../../common/enum/ColorType";


import { IUserProfile, IEntityData, IPaymentData, IAdminData } from "../../../../../models";
import { RoleType } from "../../../../../common/enum";

interface Props {
    userProfile: IUserProfile;
    entityRegister: UseFormRegister<IEntityData>;
    entityErrors: FieldErrors<IEntityData>;
    paymentRegister: UseFormRegister<IPaymentData>;
    paymentErrors: FieldErrors<IPaymentData>;
    adminRegister: UseFormRegister<IAdminData>;
    adminErrors: FieldErrors<IAdminData>;
    operationRegister: UseFormRegister<IAdminData>;
    operationErrors: FieldErrors<IAdminData>;
}

export const PerfilEntityDataContainer: React.FC<Props> = ({
    userProfile,
    entityRegister,
    entityErrors,
    paymentRegister,
    paymentErrors,
    adminRegister,
    adminErrors,
    operationRegister,
    operationErrors,
}) => {
    const theme = useTheme();
    const style = PerfilEntityDataContainerStyle({ theme });
    const { t } = useTranslation("common");
    const userRole = useSelector(getUserRole);

    const userRoleType = useMemo(() => {
        return userRole?.id;
    }, [userRole]);


    return (
        <>
            <div className={style.section}>
                <UserProfileEntityInfoForm
                    data={userProfile.entityData}
                    register={entityRegister}
                    errors={entityErrors}
                />
            </div>
            {userRoleType === RoleType.CUSTOMER_ADMIN && (
                <div className={style.sectionTitle}>
                    <TitleH4
                        title={t("USER.TITLES.CONTACT_DATA")}
                        color={ColorType.primary}
                    />
                </div>
            )}
            {userRoleType === RoleType.CUSTOMER_ADMIN && (
                <div className={style.sectionEmails}>
                    <div className={style.sectionEmailsData}>
                        <UserProfileAdminEmails
                            data={userProfile.adminData}
                            adminErrors={adminErrors}
                            adminRegister={adminRegister}
                        />
                    </div>
                    <div className={style.sectionEmailsData}>
                        <UserProfileOperationEmails
                            data={userProfile.operatorData}
                            adminErrors={operationErrors}
                            adminRegister={operationRegister}
                        />
                    </div>
                </div>
            )}
            {userRoleType === RoleType.CUSTOMER_ADMIN && (
                <div className={style.section}>
                    <UserProfilePaymentInfoForm
                        data={userProfile.paymentData}
                        register={paymentRegister}
                        errors={paymentErrors}
                    />
                </div>
            )}
            {userRoleType === RoleType.CARRIER_ADMIN && (
                <div className={style.section}>
                    <UserTruckerFileProfileData  />
                </div>
            )}
        </>
    );
};
