import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationEmail, ITimeNotification } from "../../../../components/user/profile/notifications/item/listEmails/list";
import { emptyUserProfileModel, IEmailItemWithIndex, IGenericPayload, IUserManaged, IUserProfile } from "../../../../models";
import { IUserNotifications, IUserNotificationState } from "../../../../models/user/notifications/IUserNotifications";
import { IRepresentative } from "../../../../models/user/representatives/IRepresentative";
import { IPayloadUserChecknotification } from "../../../../components/user/profile/notifications/item/item";

interface UserProfileState {
    profile: {
        user: IUserProfile;
        users: IUserManaged[];
        userNotifications: IUserNotificationState;
    };
    profileCopy: {
        user: IUserProfile;
        users: IUserManaged[];
        userNotifications: IUserNotificationState;
    };
    loadingUsers: boolean;
    loadingUser: boolean;
    loadingNotifications: boolean;
}

const initialState: UserProfileState = {
    profile: {
        user: emptyUserProfileModel(),
        users: [],
        userNotifications: {
            list: [],
        },
    },
    profileCopy: {
        user: emptyUserProfileModel(),
        users: [],
        userNotifications: {
            list: [],
        },
    },
    loadingUser: false,
    loadingUsers: false,
    loadingNotifications: false,
};

export const userProfileSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        resetUserProfile: () => initialState,
        updateUserProfileProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.profile.user = {
                ...state.profile.user,
                [action.payload.name]: action.payload.value,
            };
        },
        setUserProfile: (state, action: PayloadAction<IUserProfile>) => {
            state.profile.user = action.payload;
            state.profileCopy.user = action.payload;
        },
        loadingUserProfile: (state, action: PayloadAction<boolean>) => {
            state.loadingUser = action.payload;
        },
        updateAdminProfileProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.profile.user.adminData = {
                ...state.profile.user.adminData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateOperationProfileProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.profile.user.operatorData = {
                ...state.profile.user.operatorData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateEntityProfileProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.profile.user.entityData = {
                ...state.profile.user.entityData,
                [action.payload.name]: action.payload.value,
            };
        },
        updatePaymentProfileProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.profile.user.paymentData = {
                ...state.profile.user.paymentData,
                [action.payload.name]: action.payload.value,
            };
        },
        addOperationEmailItem: (state) => {
            state.profile.user.operatorData.emails.push({
                email: "",
                isValid: false,
            });
        },
        removeOperationEmailItem: (state, action: PayloadAction<number>) => {
            state.profile.user.operatorData.emails.splice(action.payload, 1);
        },
        updateOperationEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.profile.user.operatorData.emails[action.payload.index].email = action.payload.email;
        },
        updateOperationEmailItemIsValid: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.profile.user.operatorData.emails[action.payload.index].isValid = action.payload.isValid;
        },
        addAdminEmailItem: (state) => {
            state.profile.user.adminData.emails.push({
                email: "",
                isValid: false,
            });
        },
        removeAdminEmailItem: (state, action: PayloadAction<number>) => {
            state.profile.user.adminData.emails.splice(action.payload, 1);
        },
        updateAdminEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.profile.user.adminData.emails[action.payload.index].email = action.payload.email;
        },
        updateAdminEmailItemIsValid: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.profile.user.adminData.emails[action.payload.index].isValid = action.payload.isValid;
        },
        setRepresentativeUser: (state, action: PayloadAction<IRepresentative[]>) => {
            state.profile.user.representatives = action.payload;
        },
        setEntityUsersList: (state, action: PayloadAction<IUserManaged[]>) => {
            state.profile.users = action.payload;
            state.profileCopy.users = action.payload;
        },
        setLoadingUsers: (state, action: PayloadAction<boolean>) => {
            state.loadingUsers = action.payload;
        },
        setUserNotifications: (state, action: PayloadAction<IUserNotifications[]>) => {
            state.profile.userNotifications.list = action.payload;
            state.profileCopy.userNotifications.list = action.payload;
        },
        setLoadingNotificationsUsers: (state, action: PayloadAction<boolean>) => {
            state.loadingNotifications = action.payload;
        },
        addUserProfileNotificationEmailItem: (state, action: PayloadAction<number>) => {
            state.profile.userNotifications.list[action.payload].emailList.push("");
        },
        removeUserProfileNotificationEmailItem: (state, action: PayloadAction<INotificationEmail>) => {
            state.profile.userNotifications.list[action.payload.notificationIndex].emailList.splice(action.payload.emailIndex, 1);
        },
        updateUserProfileNotificationEmailItem: (state, action: PayloadAction<INotificationEmail>) => {
            state.profile.userNotifications.list[action.payload.notificationIndex].emailList[action.payload.emailIndex] = action.payload.email;
        },
        updateUserProfileNotificationTime: (state, action: PayloadAction<ITimeNotification>) => {
            state.profile.userNotifications.list[action.payload.notificationIndex].scheduleTimeOn = action.payload.time;
        },
        updateUserNotificationActive: (state, action: PayloadAction<IPayloadUserChecknotification>) => {
            state.profile.userNotifications.list[action.payload.notificationIndex].active = action.payload.active;
        },
        updateProfileCopy: (state) => {
            state.profileCopy = state.profile;
        },
    },
});

export const {
    updateUserProfileProperty,
    updateAdminProfileProperty,
    updateOperationProfileProperty,
    updateEntityProfileProperty,
    updatePaymentProfileProperty,
    setUserProfile,
    loadingUserProfile,
    addOperationEmailItem,
    removeOperationEmailItem,
    updateOperationEmailItem,
    updateOperationEmailItemIsValid,
    addAdminEmailItem,
    removeAdminEmailItem,
    updateAdminEmailItem,
    updateAdminEmailItemIsValid,
    setRepresentativeUser,
    setLoadingUsers,
    setEntityUsersList,
    setUserNotifications,
    setLoadingNotificationsUsers,
    addUserProfileNotificationEmailItem,
    removeUserProfileNotificationEmailItem,
    updateUserProfileNotificationEmailItem,
    updateUserProfileNotificationTime,
    updateUserNotificationActive,
    resetUserProfile,
    updateProfileCopy,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
