import { createUseStyles } from "react-jss";

export const EditRequestAppointmentFormStyle = createUseStyles((theme: any) => ({
    appointmentContent: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    form: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: 15,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 15,
    },
    appointmentType: (props: any) => ({
        width: props.hiddenActionsAppointment ? "30%" : "32.5%",
    }),
    appointment: (props: any) => ({
        width: props.appointmentWidth,
    }),
    infoIcon: {
        color: theme.colors.palette.red,
    },
    reloadButton: {
        maxWidth: "50px",
    },
    actionContainer: {
        display: "flex",
        gap: theme.gaps.sizes.sm,
    },
    popForm: {
        marginTop: 10,
    },
    alertInfo: {
        marginTop: 10,
    },
}));
