import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../models";
import { IOrganizationData } from "../../../../models/organization/IOrganizationData";
import { useAppSelector } from "../../../../redux/hooks";
import { updateOrganizationEventProperty, updateOrganizationProperty } from "../../../../redux/reducers/user/organization/organization";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { FormField } from "../../../common/fields/fields";
import { RadioButtonGroup } from "../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { TitleH5 } from "../../../common/titles/titles";
import { OrganizationFormStyle } from "./formStyle.jss";
import { OrganiationResolver } from "./resolver";

import { ColorType } from "../../../../common/enum/ColorType";
import { IOrganizationEvent } from "../../../../models/organization/IOrganizationEvent";
import { EventItem } from "./event/event";

interface IOrganizationFormContainerProps {
    setValidForm: (value: boolean) => void;
}

export const OrganizationFormContainer: React.FC<IOrganizationFormContainerProps> = ({ setValidForm }) => {
    const theme = useTheme() as any;
    const styles = OrganizationFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "ORGANIZATION" });
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors, isValid },
    } = useForm<IOrganizationData>({ mode: "onChange", resolver: OrganiationResolver });

    const {
        name,
        fiscalName,
        email,
        fromEmail,
        fiscalAddress,
        address,
        postalCode,
        city,
        fumigated,
        apba,
        parkingBarrier,
        summaryActive,
        valenciaport,
        vua,
        scsi,
        dryCharge,
        organicProduct,
        halalCertified,
        events,
        preappointment,
        parkingAuthorization,
        contactEmail,
    } = useAppSelector((store) => store.organization.organizationData);

    const { customsAuthorityArrival, readyForInstruction, representativeArrival } = events;
    const updatePropertyForm = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateOrganizationProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );
    const onRadioButtonChange = useCallback(
        (optionKey: string, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: !!+optionKey,
            };
            dispatch(updateOrganizationProperty(payload));
        },
        [dispatch],
    );

    const onChangeEvent = (payload: IGenericPayload) => {
        dispatch(updateOrganizationEventProperty(payload));
    };

    useEffect(() => {
        setValidForm(isValid);
    }, [isValid]);
    return (
        <div className={styles.container}>
            <TitleH5
                title={t("TITLES.GENERAL")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.sectionForm}>
                <div className={styles.rowForm}>
                    <FormField
                        type="text"
                        value={name ? name : ""}
                        label={t("NAME")}
                        {...register(compileNameOfProperty<IOrganizationData>("name"), {
                            onChange: onInputChange,
                            value: name,
                        })}
                        error={errors.name}
                        isRequired={true}
                    />

                    <FormField
                        type="text"
                        value={fiscalName ? fiscalName : ""}
                        label={t("FISCALNAME")}
                        {...register(compileNameOfProperty<IOrganizationData>("fiscalName"), {
                            onChange: onInputChange,
                            value: fiscalName,
                        })}
                        error={errors.fiscalName}
                        isRequired={true}
                    />
                </div>
                <div className={styles.rowForm}>
                    <FormField
                        type="text"
                        value={email ? email : ""}
                        label={t("EMAIL")}
                        {...register(compileNameOfProperty<IOrganizationData>("email"), {
                            onChange: onInputChange,
                            value: email,
                        })}
                        error={errors.email}
                        isRequired={true}
                    />
                    <FormField
                        type="text"
                        value={fromEmail ? fromEmail : ""}
                        label={t("FROMEMAIL")}
                        {...register(compileNameOfProperty<IOrganizationData>("fromEmail"), {
                            onChange: onInputChange,
                            value: fromEmail,
                        })}
                        error={errors.fromEmail}
                        isRequired={true}
                    />
                </div>
                <div className={styles.rowForm}>
                    <FormField
                        type="text"
                        value={contactEmail ? contactEmail : ""}
                        label={t("CONTACTEMAIL")}
                        {...register(compileNameOfProperty<IOrganizationData>("contactEmail"), {
                            onChange: onInputChange,
                            value: contactEmail,
                            required: false,
                        })}
                        error={errors.contactEmail}
                        isRequired={false}
                    />
                </div>
            </div>
            <TitleH5
                title={t("TITLES.ADDRESS")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.sectionForm}>
                <div className={styles.rowForm}>
                    <FormField
                        type="text"
                        value={address ? address : ""}
                        label={t("ADDRESS")}
                        {...register(compileNameOfProperty<IOrganizationData>("address"), {
                            onChange: onInputChange,
                            value: address,
                        })}
                        error={errors.address}
                        isRequired={true}
                    />

                    <FormField
                        type="text"
                        value={fiscalAddress ? fiscalAddress : ""}
                        label={t("FISCALADDRESS")}
                        {...register(compileNameOfProperty<IOrganizationData>("fiscalAddress"), {
                            onChange: onInputChange,
                            value: fiscalAddress,
                        })}
                        error={errors.fiscalAddress}
                        isRequired={true}
                    />
                </div>
                <div className={styles.rowForm}>
                    <FormField
                        type="text"
                        value={postalCode ? postalCode : ""}
                        label={t("POSTALCODE")}
                        {...register(compileNameOfProperty<IOrganizationData>("postalCode"), {
                            onChange: onInputChange,
                            value: postalCode,
                        })}
                        error={errors.postalCode}
                        isRequired={true}
                    />
                    <FormField
                        type="text"
                        value={city ? city : ""}
                        label={t("CITY")}
                        {...register(compileNameOfProperty<IOrganizationData>("city"), {
                            onChange: onInputChange,
                            value: city,
                        })}
                        error={errors.city}
                        isRequired={true}
                    />
                </div>
            </div>
            <TitleH5
                title={t("APPOINMENT")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.containerForm}>
                <div className={styles.dataRow}>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+preappointment)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("preappointment")}
                            label={t("APPOINMENT")}
                        />
                    </div>
                </div>
            </div>
            <TitleH5
                title={t("PARKING_AUTHORIZATION")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.containerForm}>
                <div className={styles.dataRow}>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+parkingAuthorization)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("parkingAuthorization")}
                            label={t("PARKING_AUTHORIZATION")}
                        />
                    </div>
                </div>
            </div>
            <TitleH5
                title={t("INTEGRATIONS")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.containerForm}>
                <div className={styles.dataRow}>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+apba)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("apba")}
                            label={t("APBA")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+parkingBarrier)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("parkingBarrier")}
                            label={t("PARKING_BARRIER")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+summaryActive)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("summaryActive")}
                            label={t("SUMMARY_ACTIVE")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+valenciaport)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("valenciaport")}
                            label={t("VALENCIA_PORT")}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.containerForm}>
                <div className={styles.dataRow}>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+vua)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("vua")}
                            label={t("VUA")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+scsi)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("scsi")}
                            label={t("SCSI")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}></div>
                    <div className={styles.radioButtonItem}></div>
                </div>
            </div>

            <TitleH5
                title={t("TITLES.LOAD")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.containerForm}>
                <div className={styles.dataRow}>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+fumigated)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("fumigated")}
                            label={t("FUMIGATED")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+dryCharge)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("dryCharge")}
                            label={t("DRY_CHARGE")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+organicProduct)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("organicProduct")}
                            label={t("ORGANIC_PRODUCT")}
                        />
                    </div>
                    <div className={styles.radioButtonItem}>
                        <RadioButtonGroup
                            selectedKey={String(+halalCertified)}
                            onChange={onRadioButtonChange}
                            name={compileNameOfProperty<IOrganizationData>("halalCertified")}
                            label={t("HALAL")}
                        />
                    </div>
                </div>
            </div>

            <TitleH5
                title={t("TITLES.REGISTEREVENTS")}
                color={ColorType.primary}
                bold={true}
            />
            <div className={styles.containerForm}>
                <div className={styles.dataRow}>
                    <div className={styles.radioButtonItem + " " + styles.eventItem}>
                        <EventItem
                            eventData={customsAuthorityArrival}
                            eventName={compileNameOfProperty<IOrganizationEvent>("customsAuthorityArrival")}
                            checkBoxLabel={t("REQUIREDFORINSPECTION")}
                            radioButtonLabel={t("ARRIVAL_RESGUARD")}
                            onChangeEvent={onChangeEvent}
                        />
                    </div>
                    <div className={styles.radioButtonItem + " " + styles.eventItem}>
                        <EventItem
                            eventData={representativeArrival}
                            eventName={compileNameOfProperty<IOrganizationEvent>("representativeArrival")}
                            checkBoxLabel={t("REQUIREDFORINSPECTION")}
                            radioButtonLabel={t("ARRIVAL_REPRESENTATIVE")}
                            onChangeEvent={onChangeEvent}
                        />
                    </div>
                    <div className={styles.radioButtonItem + " " + styles.eventItem}>
                        <EventItem
                            eventData={readyForInstruction}
                            eventName={compileNameOfProperty<IOrganizationEvent>("readyForInstruction")}
                            checkBoxLabel={t("REQUIREDFORINSPECTION")}
                            radioButtonLabel={t("CHECK_INSTRUCTION")}
                            onChangeEvent={onChangeEvent}
                        />
                    </div>
                    <div className={styles.radioButtonItem}></div>
                </div>
            </div>
        </div>
    );
};
