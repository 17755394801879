import { ActionButton, IIconProps } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../assets/icons";
import { getInspectionCardRequest } from "../../../../../../redux/actions/request/edit/action/inspectionCards";
import { getReportRequest } from "../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../common/separator/separator";
import { PrintRequestStyle } from "./printRequest.jss";

interface IPrintRequestProps {
    showValuedDeliveryNote?: boolean;
    showInspectionCard?: boolean;
    showSchemaPrintActions?: boolean;
}

export const PrintRequest: React.FC<IPrintRequestProps> = ({ showInspectionCard, showValuedDeliveryNote }) => {
    const theme = useTheme();
    const styles = PrintRequestStyle({ theme });
    const [statesVisible, { toggle: onToggleStatesVisible }] = useBoolean(false);
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();
    const requestId = useAppSelector((store) => store.editRequest.headerRequest.id);

    const PrintSelectorOption = (label: string, report?: boolean) => {
        const getUrlDocument = () => {
            if (report) {
                dispatch(getReportRequest(requestId));
            } else {
                dispatch(getInspectionCardRequest(requestId));
            }
        };

        const printIcon: IIconProps = { iconName: NameIcons.PRINT, style: { width: 20 } };

        return (
            <div
                className={styles.optionPrintSelectorContainer}
                onClick={getUrlDocument}
            >
                <ActionButton
                    iconProps={printIcon}
                    allowDisabledFocus
                />
                <div className={styles.optionPrintText}>{label}</div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div
                className={styles.selector}
                onClick={onToggleStatesVisible}
            >
                <div className={styles.currentState}>{t("PRINT_REQUEST.PRINT")}</div>
                <CustomIcons
                    className={statesVisible ? "arrowUp" : "arrow"}
                    iconName={NameIcons.CHEVRONDOWNICON}
                />
            </div>

            {statesVisible && (
                <div className={styles.actionMenu}>
                    {showValuedDeliveryNote && PrintSelectorOption(t("PRINT_REQUEST.ALBARAN"), true)}
                    {showValuedDeliveryNote && showInspectionCard && (
                        <Separator
                            horizontal
                            size="100%"
                        />
                    )}

                    {showInspectionCard && PrintSelectorOption(t("PRINT_REQUEST.INSPECTION_ACTA"), false)}
                </div>
            )}
        </div>
    );
};
