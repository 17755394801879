export enum UpdateCommandType {
    PRINT_VALUED_DELIVERY_NOTE = "printValuedDeliveryNote",
    PRINT_INSPECTION_CARD = "printInspectionCard",
    REFRESH = "refresh",
    DELETE = "delete",
    SAVE = "save",
    INSPECTION_CARD = "inspectionCard",
}
export enum ListCommandType {
    CREATE = "create",
    EDIT = "edit",
    EXPORT = "export",
    CHANGE_DATE = "changeDate",
    ADD_MULTIPLE_ACTUATION = "addMultipleActuation",
    UPDATE = "edit",
    REPORT = "report",
}
