import { useTheme } from "react-jss";
import { ContentType } from "../../../../../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../../../../../common/enum/files/FileSize";
import { UploadFileDropzone } from "../../../../../../../common/uploadFileDropzone/uploadFileDropzone";
import { attachImagesStyle } from "./formStyle.jss";

interface IEditRequestImagesFormProps {
    setImageList: (value: any) => any;
}

export const EditRequestImagesForm: React.FC<IEditRequestImagesFormProps> = ({ setImageList }) => {
    const theme = useTheme();
    const style = attachImagesStyle({ theme });

    const handleFilesChange = (list: File[]) => {
        setImageList(list);
    };

    return (
        <div className={style.container}>
            <UploadFileDropzone
                disabled={false}
                allowedExtensions={[ContentType.JPG, ContentType.JPEG, ContentType.PNG]}
                maxSize={FileSize._10MB}
                multiple
                onFilesChangeMultiple={handleFilesChange}
            />
        </div>
    );
};
