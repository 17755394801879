import { IDropdownOption } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IGenericPayload } from "../../../../models";
import { IControlExpedientForm } from "../../../../models/control/expedient/IExpedient";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateFormProperty } from "../../../../redux/reducers/control/expedients/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { FormField } from "../../../common/fields/fields";
import { BooleanSelector, YearSelector } from "../../../request/common/selectors";
import { InspectorSelector } from "../filters/inspectorSelector/inspectorSelector";
import React, { useCallback } from "react";
import { useTheme } from "react-jss";
import { ControlExpedientsFormStyle } from "./formStyle.jss";
import { DocumentSelector } from "../filters/documentSelector/documentSelector";

export const ControlerExpedientsForm = () => {
    const {
        register,
        control,
        formState: { errors: formError },
    } = useForm<IControlExpedientForm>({ mode: "onChange" });
    const { form } = useAppSelector((state) => state.controlExpedientsForm);
    const { organismId } = useAppSelector((state) => state.userData.profile);

    const theme = useTheme();
    const style = ControlExpedientsFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.FORM" });
    const dispatch = useAppDispatch();
    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateFormProperty(payload));
    };

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = (event: any, option: any, name: string) => {
        onUpdateFormProperty(name, option.key);
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );

    const handleChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        dispatch(updateFormProperty(payload));
    };

    return (
        <div className={style.container}>
            <div className={style.row}>
                <DocumentSelector
                    name={compileNameOfProperty<IControlExpedientForm>("certificateTypeId")}
                    selectedKey={form.certificateTypeId}
                    onChange={onSelectorChange}
                    isRequired={true}
                ></DocumentSelector>
                <FormField
                    type="text"
                    label={t("EXPEDIENT_NUMBER")}
                    value={form.certificateNumber}
                    error={formError.certificateNumber}
                    {...register(compileNameOfProperty<IControlExpedientForm>("certificateNumber"), {
                        onChange: onInputChange,
                        value: form.certificateNumber,
                    })}
                    isRequired={true}
                />
            </div>

            <InspectorSelector
                name={compileNameOfProperty<IControlExpedientForm>("inspectorId")}
                selectedKey={form.inspectorId}
                onChange={onSelectorChange}
                organismId={organismId ? organismId : 0}
            ></InspectorSelector>
            <div className={style.row}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.alert}
                    propertyName={compileNameOfProperty<IControlExpedientForm>("alert")}
                    title={t("WARNING")}
                    control={control}
                    isRequired={true}
                ></BooleanSelector>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.pending}
                    propertyName={compileNameOfProperty<IControlExpedientForm>("pending")}
                    title={t("PENDING")}
                    control={control}
                    disabled={true}
                ></BooleanSelector>
            </div>

            <YearSelector
                selectedKey={form.year}
                onChange={handleChange}
                propertyName={compileNameOfProperty<IControlExpedientForm>("year")}
                title={t("YEAR")}
                yearsAfter={5}
                isRequired={true}
                control={control}
            />
        </div>
    );
};
