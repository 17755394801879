import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../models";
import { useAppSelector } from "../../../../redux/hooks";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { widths } from "../../../../styles/romeu/widths";
import { heights } from "../../../../styles/romeu/heights";
import { ManagementNotificationForm } from "./form/form";
import {
    resetManagementNotificationState,
    setManagementnotificationFormPopUp,
    updateManagementNotificationProperty,
} from "../../../../redux/reducers/management/notification/form";
import { deleteNotificationAction, postAndPutManagementNotificationAction } from "../../../../redux/actions/management/notification/notication";
import { IDropdownOption } from "@fluentui/react";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

export const ManagementNotificationControllerForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.NOTIFICATION" });
    const dispatch = useDispatch();
    const { form, loading, showPopUp } = useAppSelector((store) => store.managementNotificationForm);

    const onCloseForm = () => {
        dispatch(setManagementnotificationFormPopUp(PopUpCodes.NONE));
    };
    const onDimissed = () => {
        dispatch(resetManagementNotificationState());
    };
    const onSaveForm = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postAndPutManagementNotificationAction()).then((response: any) => {
                response && onCloseForm();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(deleteNotificationAction()).then((response: any) => {
                response && onCloseForm();
            });
        }
    };

    const handleChange = (option?: IDropdownOption, name?: string) => {
        const payload: IGenericPayload = {
            name: name!,
            value: option?.key,
        };
        dispatch(updateManagementNotificationProperty(payload));
    };

    const updatePropertyForm = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateManagementNotificationProperty(payload));
    };

    const onRadioButtonChange = useCallback(
        (optionKey: string, propertyName: string) => {
            updatePropertyForm(propertyName, !!+optionKey);
        },
        [dispatch],
    );
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }

        return "";
    }, [showPopUp, showPopUp]);

    const isDisabledButton = useMemo(() => {
        return !form.roleId || !form.notificationTypeId || !form.notificationModeId || (form.scheduleTime && !form.defaultScheduleTime);
    }, [form]);

    return (
        <>
            <PopUpForm
                content={
                    <ManagementNotificationForm
                        onChangeSelector={handleChange}
                        onRadioButtonChange={onRadioButtonChange}
                    />
                }
                onDimissed={onDimissed}
                leftButtonContent={<RequiredFieldsMessage />}
                width={widths.management.notification.notificationForm.width}
                height={heights.management.notification.notificationForm.height}
                title={titlePopUp}
                isDisabledButton={isDisabledButton}
                isLoading={loading}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onSave={onSaveForm}
                onCancel={onCloseForm}
            />

            <PopUpConfirmation
                onDimissed={onDimissed}
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCloseForm}
                loading={loading}
                disabledActions={loading}
                onAccept={onSaveForm}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
            />
        </>
    );
};
