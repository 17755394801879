import { useMemo } from "react";
import { MasterTypes } from "../../../../common/enum/masters/marterTypes";
import { useAppSelector } from "../../../../redux/hooks";
import { MasterActuacionTypeContainer } from "./actuationType/container";
import { MasterBlockContainer } from "./block/container";
import { MasterTypeCertificateContainer } from "./certificateType/container";
import { MasterActuacionTypeGroupContainer } from "./actuationGroup/container";
import { MasterOrganismContainer } from "./organism/container";
import { MasterMerchandiseOriginContainer } from "./merchandiseOrigin/container";
import { MasterMerchandiseTypesContainer } from "./merchandiseTypes/container";
import { MasterMerchandiseCategoryContainer } from "./merchandiseCategory/container";
import { MasterMerchandiseGroupCategoryContainer } from "./groupMerchandiseCategory/container";
import { MasterDisplayDocksContainer } from "./displayDocks/container";
import { MasterRequestStatesContainer } from "./requestStates/container";
import { MasterDocumentTypesContainer } from "./documentTypes/container";
import { MasterFinalDestinationTypesContainer } from "./finalDestinationTypes/container";
import { MasterPresenceRepresentativeContainer } from "./presenceRepresentative/container";
import { MasterSealTypesContainer } from "./sealTypes/container";
import { MasterTurnsContainer } from "./turns/container";
import { MasterCustomStatesContainer } from "./customsStates/container";
import { MasterNotificationTypesContainer } from "./notificationTypes/container";
import { MasterTransportUnitContainer } from "./transportUnit/container";
import { MasterTerminalContainer } from "./terminal/container";

export const MasterListComponent = () => {
    const { codeComponent } = useAppSelector((state) => state.masterPopUp);
    const managementListComponent = useMemo(() => {
        switch (codeComponent) {
            case MasterTypes.ORGANISM:
                return <MasterOrganismContainer />;
            case MasterTypes.CERTIFICATE_TYPE:
                return <MasterTypeCertificateContainer />;
            case MasterTypes.MERCHANDISE_ORIGIN:
                return <MasterMerchandiseOriginContainer />;
            case MasterTypes.MERCHANDISE_TYPES:
                return <MasterMerchandiseTypesContainer />;
            case MasterTypes.GROUPS_MERCHANDISE_CATEGORY:
                return <MasterMerchandiseGroupCategoryContainer />;
            case MasterTypes.MERCHANDISE_CATEGORY:
                return <MasterMerchandiseCategoryContainer />;
            case MasterTypes.DOCKS:
                return <MasterDisplayDocksContainer />;
            case MasterTypes.REQUEST_STATES:
                return <MasterRequestStatesContainer />;
            case MasterTypes.ACTUATION_TYPE:
                return <MasterActuacionTypeContainer></MasterActuacionTypeContainer>;
            case MasterTypes.DOCUMENT_TYPE:
                return <MasterDocumentTypesContainer />;
            case MasterTypes.FINAL_TYPE_DESTINY:
                return <MasterFinalDestinationTypesContainer />;
            case MasterTypes.REPRESENTATIVE_PRESENCE_TYPES:
                return <MasterPresenceRepresentativeContainer />;
            case MasterTypes.SEAL_TYPES:
                return <MasterSealTypesContainer />;
            case MasterTypes.TURNS:
                return <MasterTurnsContainer />;
            case MasterTypes.BLOCK:
                return <MasterBlockContainer />;
            case MasterTypes.ACTUATION_GROUP:
                return <MasterActuacionTypeGroupContainer />;
            case MasterTypes.CUSTOMS_STATES:
                return <MasterCustomStatesContainer />;
            case MasterTypes.NOTIFICATION_TYPES:
                return <MasterNotificationTypesContainer />;
            case MasterTypes.TRANSPORT_UNITS:
                return <MasterTransportUnitContainer />;
            case MasterTypes.TERMINAL:
                return <MasterTerminalContainer />;
            default:
                return <></>;
        }
    }, [codeComponent]);
    return managementListComponent;
};
