import {  IPersonaSharedProps, Persona, PersonaPresence, PersonaSize } from "@fluentui/react";
import {} from "@material-ui/core";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { IUserManaged } from "../../../../../../../models";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { UserProfileEntityManageUsersListItemStyle } from "./managedUserItem.jss";

interface IUserProfileEntityManageUsersListItemProps {
    user: IUserManaged;
    showEditButton: boolean;
    onClickEditButton: () => void;
}

export const UserProfileEntityManageUsersListItem: React.FC<IUserProfileEntityManageUsersListItemProps> = ({
    user,
    onClickEditButton,
    showEditButton,
}) => {
    const theme = useTheme();
    const style = UserProfileEntityManageUsersListItemStyle({ theme });
    const personaStyles = {
        primaryText: {
            "font-weight": 500,
        },
    };
    const personaProps: IPersonaSharedProps = {
        imageUrl: user?.imageUrl,
        text: `${user.name} ${user.surName}`,
        secondaryText: user.roleName,
    };

    return (
        <div className={style.userItemContainer}>
            <Persona
                {...personaProps}
                styles={personaStyles}
                size={PersonaSize.size40}
                presence={PersonaPresence.none}
            />
            {showEditButton && (
                <CustomIcons
                    iconName={NameIcons.EDIT}
                    size={20}
                    onClick={onClickEditButton}
                    isButton={true}
                />
            )}
        </div>
    );
};
