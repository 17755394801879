import { IDropdownOption } from "@fluentui/react";
import React,{ useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterCertificateTypeFormStyle } from "./formStyle.jss";
import { IMasterCertificateTypesRemaining, IMasterCertificateType } from "../../../../../../../models/resources/master/ICertificateType";
import { updateMasterCertificateTypeProperty, setMasterCertificateTypeForm } from "../../../../../../../redux/reducers/resources/master/certificateType/form";
interface IMasterCertificateTypeFormProps {
    showNotData: boolean;
    data: IMasterCertificateTypesRemaining[];
}
export const MasterCertificateTypeForm: React.FC<IMasterCertificateTypeFormProps> = ({ showNotData, data }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = MasterCertificateTypeFormStyle({ theme });

    const { form } = useAppSelector((state) => state.masterCertificateTypeForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { register, control } = useForm<IMasterCertificateType>({ mode: "onChange" });

    const updatePropertyForm = (name: string, value: any) => {
        dispatch(updateMasterCertificateTypeProperty({ name, value }));
    };

    const onSelectorChange = (event: any, option: any, name: string) => {
        updatePropertyForm(name, option.key);
        let certificateType = data.find((item) => {
            return item.id === option.key;
        });
        if (certificateType) {
            const form: IMasterCertificateType = {
                active: true,
                referenceId: String(option.key),
                code: certificateType.code,
                description: certificateType.description,
            };

            dispatch(setMasterCertificateTypeForm(form));
        }
    };
    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            updatePropertyForm(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        updatePropertyForm(propertyName, !!+option.key);
    };

    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterCertificateType>("referenceId")}
                        title={t("FORM.CERTIFICATE_SELECTOR")}
                        options={mapIDescriptionToDropDownOptions(data)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? ""}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterCertificateType>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? undefined,
                    })}
                    isRequired={true}
                />
                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? ""}
                    disabled={disabledInput}
                    {...register(compileNameOfProperty<IMasterCertificateType>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? "",
                    })}
                    isRequired={true}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterCertificateType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
