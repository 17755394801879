import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterCustomsState } from "../../../../../models/resources/master/ICustomsState";

export interface IMasterCustomsStatesState {
    list?: IMasterCustomsState[];
    loading: boolean;
}

const initialState: IMasterCustomsStatesState = {
    loading: false,
};

export const masterCustomsStatesListReducer = createSlice({
    name: "masterCustomsStatesListReducer",
    initialState,
    reducers: {
        resetMasterCustomsStatesList: () => initialState,
        setLoadingMasterCustomsStatesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterCustomsStatesList: (state, action: PayloadAction<IMasterCustomsState[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterCustomsStatesList, setLoadingMasterCustomsStatesList, setMasterCustomsStatesList } = masterCustomsStatesListReducer.actions;

export default masterCustomsStatesListReducer.reducer;
