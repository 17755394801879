import { createUseStyles } from "react-jss";

export const DateTimePickerComponentStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexFlow: "column",
    },
    label: {
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.xs,
        fontWeight: "100",
        fontFamily: theme.fonts.families.site,
    },
    dateTimePicker: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.sizes.base,
    },
    datePicker: {
        width: 110,
        "& .ms-TextField-fieldGroup": {
            minWidth: 110,
        },
        "& .ms-TextField-field": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.form.input.filled,
            paddingTop: 2,
            paddingLeft: 0,
        },
        marginBottom: -5,
    },
    timePicker: {
        width: 45,
        "& .MuiFormLabel-root": {
            fontSize: "0.8rem !important",
        },
        "& .MuiInput-root": {
            fontSize: theme.fonts.sizes.base,
            color: theme.colors.form.input.filled,
        },
        "& .MuiInput-root:after": {
            borderBottom: "1px solid #7367f0 !important",
        },
        "& .MuiInput-root:hover:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
            cursor: "pointer !important",
            pointerEvents: "all",
        },
    },
    error: {
        width: "90%",
        fontFamily: theme.fonts.families.site,
        fontSize: theme.fonts.sizes.xxs,
        color: theme.colors.form.input.invalid,
        padding: theme.paddings.forms.errorsMessage.defaultPadding,
    },
    removeIcon: {
        color: theme.colors.palette.red,
    },
}));
