import { createUseStyles } from "react-jss";

export const UserDocumentListStyled = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
    },
    item: {
        display: "block",
        padding: theme.paddings.default,
        borderRadius: theme.borders.default.radius,
        border: "1px solid " + theme.colors.site.color,
        backgroundColor: theme.colors.site.background,
        "&:hover": {
            filter: "none",
            backgroundColor: theme.colors.site.white,
        },
        "& i": {
            fontSize: theme.fonts.sizes.lg,
        },
    },
}));
