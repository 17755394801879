import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRequestHistory } from "../../../../../models/requests/request/IRequestHistory";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

interface EditRequestHistoryRequestState {
    list?: IRequestHistory[];
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: EditRequestHistoryRequestState = {
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const editRequestHistoryRequest = createSlice({
    name: "editRequestHistoryRequest",
    initialState,
    reducers: {
        resetEditRequestHistoryRequestDataState: () => initialState,

        setEditRequestHistoryRequestLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEditRequestHistoryRequestData: (state, action: PayloadAction<IRequestHistory[]>) => {
            state.list = action.payload;
        },
        setEditRequestHistoryRequestPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetEditRequestHistoryRequestDataState,
    setEditRequestHistoryRequestLoading,
    setEditRequestHistoryRequestData,
    setEditRequestHistoryRequestPopUp,
} = editRequestHistoryRequest.actions;

export default editRequestHistoryRequest.reducer;
