import { userSignupDocumentTemplatesAdapter } from "../../../reducers/user/signup/documentTemplates";
import { RootState } from "../../../store";

export const userSignupDocumentTemplatesSelector = (state: RootState) => {
    return state.userSignupDocumentTemplates;
};

export const getUserSignupDocumentTemplatesSelector = userSignupDocumentTemplatesAdapter.getSelectors<RootState>(
    (state) => state.userSignupDocumentTemplates,
);
