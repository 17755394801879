import { useId } from "@fluentui/react-hooks";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { FieldError } from "react-hook-form/dist/types/errors";
import { FieldsStyle } from "../fields/fields.jss";
import { Input } from "../inputs/input";
import { Label } from "../labels/label";
import { ShowCallOutComponent } from "../radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
interface IFieldsProps {
    name: string;
    label?: string;
    value: string;
    error?: FieldError;
    disabled?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    onChange?: (e: any) => any;
    readonly?: boolean;
    minNumber?: string;
    calloutMessage?: string;
    calloutLabel?: string;
    decimalNumber?: boolean;
}
export const FormNumericField = React.forwardRef<HTMLInputElement, IFieldsProps & ReturnType<UseFormRegister<any>>>(
    ({ onChange, name, label, value, disabled, isRequired, maxLength, readonly, minNumber, calloutMessage, calloutLabel, decimalNumber }, ref) => {
        const iconId = useId("callout-iconButton");
        const [isCalloutVisible, setCalloutVisibility] = useState<boolean>(false);
        const showCallOut = calloutLabel && calloutMessage;
        const inputStyle = FieldsStyle();
        const filled: string | number = !!value && value[0]?.length > 0 ? "filled" : "";
        const [error, setErrorMessage] = useState<string>("");

        useEffect(() => {
            if (isRequired && value.length === 0) {
                setErrorMessage(t("FORM.VALIDATION.REQUIRE"));
            }
        }, []);

        const regex = /^-?\d+$/;

        const changeData = (event: any, _newValue?: string) => {
            const currentValue = String(event.target.value);

            if (currentValue && !regex.test(currentValue)) {
                setErrorMessage(t("FORM.VALIDATION.NO_DECIMALS"));
            }
            if (regex.test(currentValue)) {
                setErrorMessage("");
                onChange(event);
            }

            if (!currentValue) {
                onChange(event);
            }
        };

        return (
            <div className={inputStyle.formGroup}>
                <Input
                    name={name}
                    value={value}
                    type="number"
                    maxLength={maxLength}
                    onChange={changeData}
                    ref={ref}
                    disabled={disabled}
                    readonly={readonly}
                    min={minNumber}
                />
                {label && !showCallOut && (
                    <>
                        <Label
                            title={label}
                            className={filled}
                            isRequired={isRequired}
                        />
                    </>
                )}
                {error && <div className={inputStyle.error}>{error ? error : ""}</div>}{" "}
                {showCallOut && (
                    <span className={inputStyle.inputNumberCallout}>
                        <ShowCallOutComponent
                            calloutLabel={calloutLabel}
                            required={isRequired}
                            iconId={iconId}
                            setCalloutVisibility={setCalloutVisibility}
                            isCalloutVisible={isCalloutVisible}
                            calloutMessage={calloutMessage}
                        />
                    </span>
                )}
            </div>
        );
    },
);
