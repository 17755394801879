import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";

import {
    IManagementRequestDatePermission,
    IManagementRequestDatePermissionForm,
} from "../../../../../models/management/requestdatepermission/IRequestdatepermission";
import {
    getRequestDatePermisionAction,
    patchRequestDatePermissionList,
} from "../../../../../redux/actions/management/requestdatepermision/requestdatepermision";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { widths } from "../../../../../styles/romeu/widths";
import { compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { ManagementRequestDatePermisionListColumnStyle } from "./columnStyle.jss";
import {
    setManagementRequestDatePermissionShowPopUp,
    updateRequestDatePermissionHeaderPropertyForm,
} from "../../../../../redux/reducers/management/requestdatepermission/form";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IGenericPayload } from "../../../../../models";
import { MethodsSort } from "../../../../../common/enum/sort/Methods";
import { SortTableName } from "../../../../../common/enum/sort/TableName";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ManagementRequestDatePermisionListColumn = (): IColumn[] => {
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const styles = ManagementRequestDatePermisionListColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PERMISSIONS.TABLE" });
    const { list } = useAppSelector((state) => state.managementRequestDatePermisionList);

    const columns: IColumn[] = [
        {
            key: "sort",
            minWidth: 70,
            maxWidth: 100,
            name: t("PRIORITY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={"sort"}
                    title={t("PRIORITY")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission, index) => (
                <span className={styles.buttonsContainer}>
                    <CustomIcons
                        className={styles.arrow}
                        position="start"
                        iconName="ChevronUpSmall"
                        isDisabled={index === 0}
                        onClick={() => {
                            dispatch(patchRequestDatePermissionList(SortTableName.RequestDatePermission, item.id, MethodsSort.UP));
                        }}
                    ></CustomIcons>
                    <Separator size="auto"></Separator>

                    <CustomIcons
                        className={styles.arrow}
                        iconName="ChevronDownSmall"
                        onClick={() => {
                            dispatch(patchRequestDatePermissionList(SortTableName.RequestDatePermission, item.id, MethodsSort.DOWN));
                        }}
                        isDisabled={list && index === list.length - 1}
                    ></CustomIcons>
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("transportUnitDescription"),
            minWidth: 90,
            maxWidth: 100,
            name: t("CONDITION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("transportUnitDescription")}
                    title={t("CONDITION")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => <span>{item.transportUnitDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("transportUnitSizeCode"),
            minWidth: 70,
            maxWidth: 100,
            name: t("SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("transportUnitSizeCode")}
                    title={t("SIZE")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => <span>{item.transportUnitSizeCode ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("organismDescription"),
            minWidth: 100,
            maxWidth: 150,
            name: t("ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("organismDescription")}
                    title={t("ORGANISM")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => <span>{item.organismDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("dayDescription"),
            minWidth: 80,
            maxWidth: 100,
            name: t("DAY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("dayDescription")}
                    title={t("DAY")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => <span>{item.dayDescription}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("time"),
            minWidth: 100,
            maxWidth: 200,
            name: t("HOUR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("time")}
                    title={t("HOUR")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => <span>{item.time}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("workDays"),
            minWidth: 120,
            maxWidth: 200,
            name: t("WORK_DAY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("workDays")}
                    title={t("WORK_DAY")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => (
                <span>
                    <BooleanValue value={item.workDays}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagementRequestDatePermission>("applicationDay"),
            minWidth: 120,
            maxWidth: 200,
            name: t("APLICATION_DAY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagementRequestDatePermission>("applicationDay")}
                    title={t("APLICATION_DAY")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagementRequestDatePermission) => <span>{item.applicationDay}</span>,
            isResizable: true,
        },

        {
            key: "",
            minWidth: 100,
            maxWidth: 200,
            name: "",
            onRender: (item: IManagementRequestDatePermission) => (
                <div className={styles.buttonsContainer}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(getRequestDatePermisionAction(item.id)).then(() =>
                                dispatch(setManagementRequestDatePermissionShowPopUp(PopUpCodes.UPDATE)),
                            );
                        }}
                        size={widths.management.icons}
                    />

                    <Separator size="auto"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            let payload: IGenericPayload = {
                                name: compileNameOfProperty<IManagementRequestDatePermissionForm>("id"),
                                value: item.id,
                            };
                            dispatch(updateRequestDatePermissionHeaderPropertyForm(payload));

                            dispatch(setManagementRequestDatePermissionShowPopUp(PopUpCodes.DELETE));
                        }}
                        size={widths.management.icons}
                    ></CustomIcons>
                </div>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
