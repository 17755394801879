import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IManagementNonWorkDays } from "../../../../models/management/workCalendar/IManagementNonWorkDays";
import { fetchManagementWorkCalendarAction, setManagementWorkCalendarAction } from "../../../../redux/actions/management/workCalendar/workCalendar";
import { useAppDispatch } from "../../../../redux/hooks";
import { convertToLocal } from "../../../../utils";
import { Spinner } from "../../../common/spinner/spinner";
import { ManagementWorkCalendarContainerStyle } from "../containerStyle.jss";
import { FontIcon } from "@fluentui/react";

interface CalendarElementProps {
    currentDate: Date;
    index: number;
    id: number;
    date: number;
    gridDate: Date;
    workingDay?: IManagementNonWorkDays;
    year: number;
    month: number;
    onRemoveNonWorkDay: any;
}

export const CalendarElement: React.FC<CalendarElementProps> = ({
    currentDate,
    index,
    id,
    date,
    gridDate,
    workingDay,
    year,
    month,
    onRemoveNonWorkDay,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const theme = useTheme();
    const styles = ManagementWorkCalendarContainerStyle({ theme });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSelectDate = (date: Date, setLoading: Dispatch<SetStateAction<boolean>>) => {
        setLoading(true);
        dispatch(setManagementWorkCalendarAction(convertToLocal(date, FormatDate.ISO))).then(() => {
            dispatch(fetchManagementWorkCalendarAction(year, month + 1)).then(() => {
                setLoading(false);
            });
        });
    };

    const nonWorkDay = (id: number, setLoading: Dispatch<SetStateAction<boolean>>) => {
        return (
            <div
                className={styles.nonWorkDay}
                onClick={() => onRemoveNonWorkDay(id, setLoading)}
            >
                {t("WORKCALENDAR.NONWORK")}
                <FontIcon
                    aria-label="CircleAdditionSolid"
                    iconName="CircleAdditionSolid"
                    className={`${styles.iconDelete}`}
                />
            </div>
        );
    };

    return (
        <>
            <div
                key={`index-${index}-${id}`}
                className={`grid-element ${currentDate.getDay() === 0 || currentDate.getDay() === 6 ? styles.weekend : ""}`}
                onClick={() => !isLoading && !workingDay && onSelectDate(gridDate, setIsLoading)}
            >
                <div className={workingDay ? styles.daySelected : ""}>{date.toString()}</div>
                {workingDay && nonWorkDay(workingDay.id, setIsLoading)}
                {isLoading && (
                    <>
                        <div className={styles.backdrop}></div>
                        <div className={styles.spinnerContainer}>
                            <Spinner
                                key={`spinner-${index}-${id}`}
                                size={40}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
