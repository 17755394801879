import { useTheme } from "react-jss";
import { paddings } from "../../../../styles/romeu/paddings";
import { generateRandomKeys } from "../../../../utils/keys";
import { ColumnType } from "../tableCustom";
import { IsShowCustomTableListCell } from "../utils/utils";
import { TableBodyStyle } from "./tableBodyStyle.jss";

type TableBodyCustom<T, K extends keyof T> = {
    data: Array<T>;
    columns: Array<ColumnType<T, K>>;
    isMobile: boolean;
    underActions?: (value?: any) => any;
};

export const CustomTableBody = <T, K extends keyof T>({ data, columns, isMobile, underActions }: TableBodyCustom<T, K>) => {
    const theme = useTheme();
    const style = TableBodyStyle({ theme });
    const responsiveBody = data.map((row, _indexRow) => {
        return (
            <div
                key={`tr-${generateRandomKeys()}`}
                className={style.mobileTable}
            >
                {columns.map((column, _indexColumn) => {
                    return (
                        IsShowCustomTableListCell(column.showColumn) && (
                            <div
                                key={`td1-${generateRandomKeys()}`}
                                className={style.movileRow}
                            >
                                {column.title && (
                                    <div
                                        key={`td3-${generateRandomKeys()}`}
                                        className={style.movileHeader}
                                    >
                                        {column.title}
                                    </div>
                                )}
                                <div
                                    key={`td-${generateRandomKeys()}`}
                                    className={column.title ? style.movileData : style.movileActions}
                                >
                                    {row[column.key]}
                                </div>
                            </div>
                        )
                    );
                })}

                {underActions && <div className={style.movileRow}>{underActions(row)}</div>}
            </div>
        );
    });

    const body = data.map((row) => {
        return (
            <div
                key={`tr-${generateRandomKeys()}`}
                className={style.row}
            >
                <div key={`td1-${generateRandomKeys()}`}>
                    {columns.map((column, _indexColumn) => {
                        return (
                            IsShowCustomTableListCell(column.showColumn) && (
                                <div
                                    key={`td-${generateRandomKeys()}`}
                                    className="td"
                                    style={{
                                        width: column.width ? column.width : "20%",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingRight: column.padding ? column.padding : paddings.sizes.base,
                                    }}
                                >
                                    <>{row[column.key]}</>
                                </div>
                            )
                        );
                    })}
                </div>

                {underActions && <div className="a">{underActions(row)}</div>}
            </div>
        );
    });
    return <>{isMobile ? responsiveBody : body}</>;
};
