import { createUseStyles } from "react-jss";

export const DockAssignerStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: theme.paddings.default,
        paddingRight: theme.paddings.default,
        width: "260px",
        fontSize: theme.fonts.sizes.sm,
    },
    dockAssigner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 12,
        width: "100%",
        padding: theme.paddings.default,
        borderRadius: theme.borders.default.radius,
        backgroundColor: theme.colors.palette.white,
        position: "relative",
        WebkitBoxShadow: theme.borders.default.boxShadow,
        MozBoxShadow: theme.borders.default.boxShadow,
        boxShadow: theme.borders.default.boxShadow,
        " & .selector": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
            fontWeight: theme.fonts.weight.bold,
            color: theme.colors.stateChanger.other.color,
            " & .description": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            " & .arrowUp": {
                transform: "rotate(180deg)",
            },
        },

        " & .noPointer": {
            cursor: "auto !important",
        },

        " & .actionMenu": {
            position: "absolute",
            zIndex: "99999",
            top: 34,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 12,
            width: "236px",
            backgroundColor: theme.colors.palette.white,
            borderRadius: theme.borders.default.radius,
            WebkitBoxShadow: theme.borders.actionMenu.boxShadow,
            MozBoxShadow: theme.borders.actionMenu.boxShadow,
            boxShadow: theme.borders.actionMenu.boxShadow,
            padding: theme.paddings.default,
        },
    },
}));
