import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { IInvoicingFilter, emptyInvoiceFilter } from "../../../../models/invoicing/filter/IInvoiceFilter";

interface IInvoicingUninvoicedFilterState {
    filter: IInvoicingFilter;
}

const initialState: IInvoicingUninvoicedFilterState = {
    filter: emptyInvoiceFilter(),
};

export const invoicingUninvoicedFilterSlice = createSlice({
    name: "invoicingUninvoicedFilterSlice",
    initialState,
    reducers: {
        resetInvoicingUninvoicedFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetInvoicingUninvoicedFilter, updateFilterProperty } = invoicingUninvoicedFilterSlice.actions;

export default invoicingUninvoicedFilterSlice.reducer;
