import { IAppointment, IAppointmentLocks } from "../../../../models/appointment/IBoard";
import { IBoardHistoryAppointmentDock, IBoardHistoryAppointmentTimeZone } from "../../../../models/masterAppointment/IAppointmentBoard";
import { generateRandomKeys } from "../../../../utils/keys";
import { AppointmentGridComponent } from "./appointment";

interface CellBoardProps {
    appointmentDocks: IBoardHistoryAppointmentDock[];
    appointmentTimeZones: IBoardHistoryAppointmentTimeZone[];
    appointments: IAppointment[];
    locks: IAppointmentLocks[];
}

export const CellBoardComponent = ({ appointmentDocks, appointmentTimeZones, appointments, locks }: CellBoardProps) => {
    const cellGrid: any = [];
    const checkAppointmentsZones: string[] = [];

    appointmentTimeZones.forEach((timeZone, t) => {
        const row = [];
        row.push(<th>{`${timeZone.startTime} ${timeZone.endTime}`}</th>);

        appointmentDocks.forEach((dock, d) => {
            const appointment = appointments.find((c) => c.appointmentDockId === dock.id && c.appointmentTimeZoneId === timeZone.id);
            const key: string = `${dock.id}-${timeZone.id}`;

            if (appointment) {
                const timeZones = appointment.timeZones;
                if (timeZones > 1) {
                    for (let tz = 1; tz < appointment.timeZones; tz++) {
                        const nextkey = `${dock.id}-${appointmentTimeZones[t + tz].id}`;
                        checkAppointmentsZones.push(nextkey);
                    }
                }

                row.push(
                    <td
                        key={`td-${generateRandomKeys()}`}
                        rowSpan={timeZones}
                    >
                        <AppointmentGridComponent
                            appointment={appointment}
                            dock={dock}
                            timeZone={timeZone}
                        />
                    </td>,
                );
            } else {
                const lock = locks.find((lock) => lock.appointmentDockId === dock.id && lock.appointmentTimeZoneId === timeZone.id);
                if (!checkAppointmentsZones.includes(key)) {
                    row.push(
                        <td key={`td-${generateRandomKeys()}`}>
                            <AppointmentGridComponent
                                dock={dock}
                                timeZone={timeZone}
                                lock={lock}
                            />
                        </td>,
                    );
                }
            }
        });

        cellGrid.push(<tr key={`tr-${generateRandomKeys()}`}>{row}</tr>);
    });

    return cellGrid;
};
