import { IDropdownOption } from "@fluentui/react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../models";
import { IIncidenceTypesItem } from "../../../../../models/management/incidenceType/IIncidenceType";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateManagementIncidenceTypeFormProperty } from "../../../../../redux/reducers/management/incidenceType/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { FormField } from "../../../../common/fields/fields";
import { BooleanSelector, OrganismSelector } from "../../../../request/common/selectors";
import { requestMaxLength } from "../../../../request/form/create/validation/requestConfig";
import { ManagementIncidencesTypesFormStyle } from "./formStyle.jss";

export const ManagementIncidencesTypesForm = () => {
    const theme = useTheme();
    const style = ManagementIncidencesTypesFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.INCIDENCE_TYPE.FORM" });
    const dispatch = useAppDispatch();

    const { form } = useAppSelector((state) => state.managementIncidenceTypesForm);
    const { profile } = useAppSelector((state) => state.userData);
    const { register, control } = useForm<IIncidenceTypesItem>({ mode: "onChange" });

    const handleChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        dispatch(updateManagementIncidenceTypeFormProperty(payload));
    };
    const onYesNoOptionsChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: !!+option.key,
        };
        dispatch(updateManagementIncidenceTypeFormProperty(payload));
    };
    const onInputChange = (event: any, _newValue?: any) => {
        const payload = getGenericPayloadFromEvent(event);
        dispatch(updateManagementIncidenceTypeFormProperty(payload));
    };
    const showOrganismSelector = useMemo(() => {
        return form.incidenceType || form.organismId;
    }, [form]);
    
    useEffect(() => {
        if (profile.organismId) {
            const payload: IGenericPayload = {
                name: compileNameOfProperty<IIncidenceTypesItem>("organismId"),
                value: profile.organismId,
            };
            dispatch(updateManagementIncidenceTypeFormProperty(payload));
        }
    }, [profile]);

    return (
        <div className={style.formController}>
            {showOrganismSelector && (
                <OrganismSelector
                    onChange={handleChange}
                    selectedKey={form.organismId ?? 0}
                    propertyName={compileNameOfProperty<IIncidenceTypesItem>("organismId")}
                    title={t("ORGANISM")}
                    isRequired={true}
                    control={control}
                    disabled={profile.organismId ? true : false}
                />
            )}

            <FormField
                type="text"
                value={form.description}
                maxLength={requestMaxLength.TRACTOR_NUMBER}
                label={t("DESCRIPTION")}
                isRequired={true}
                {...register(compileNameOfProperty<IIncidenceTypesItem>("description"), {
                    onChange: onInputChange,
                    value: form.description,
                    required: true,
                })}
            />

            <BooleanSelector
                onChange={onYesNoOptionsChange}
                selectedKey={+form.customerNotice}
                propertyName={compileNameOfProperty<IIncidenceTypesItem>("customerNotice")}
                title={t("WARNING")}
                isRequired={true}
                control={control}
            />
        </div>
    );
};
