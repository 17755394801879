import { Dropdown, IDropdown, IDropdownOption, IDropdownStyles, IRefObject } from "@fluentui/react";
import React, { useMemo } from "react";
import { stringDefaultValue } from "../../../../../utils";
import { MultiSelectorStyled } from "../multiSelector.jss";

interface IStringMultiSelectorProps {
    title: string;
    options: IDropdownOption[];
    onChange: (name: string, keysSelected: string[]) => void;
    comboBoxRef?: IRefObject<IDropdown>;
    selectedKeys: string[];
    disabled?: boolean;
    className?: string;
    name: string;
}

export const StringMultiSelector: React.FC<IStringMultiSelectorProps> = ({
    onChange,
    selectedKeys,
    title,
    disabled,
    options,
    name,
    comboBoxRef,
    className,
}) => {
    const isDisabled = useMemo(() => {
        return !!disabled;
    }, [disabled]);

    const multiSelectorStyled = MultiSelectorStyled({ isDisabled });

    const styles: Partial<IDropdownStyles> = {
        root: multiSelectorStyled.rootStyles,
        callout: multiSelectorStyled.callout,
        dropdownItem: multiSelectorStyled.optionText,
        dropdownItemSelected: multiSelectorStyled.optionText,
        title: multiSelectorStyled.inputText,
        label: multiSelectorStyled.label,
    };

    const onOptionSelected = (_event: any, option?: IDropdownOption, _index?: number) => {
        if (option) {
            if (String(option.key) === String(stringDefaultValue.key) && option.selected) {
                onChange(name, [String(stringDefaultValue.key)]);
            } else {
                const newKeys = option?.selected ? [...selectedKeys, String(option.key)] : selectedKeys.filter((key) => key !== String(option.key));

                let newKeysFiltered: string[] = [];
                switch (newKeys.length) {
                    case 1:
                        newKeysFiltered = newKeys;
                        break;
                    case 0:
                        newKeysFiltered = [String(stringDefaultValue.key)];
                        break;
                    default:
                        const optionsWithoutDefaultValue = newKeys.filter((key) => key !== String(stringDefaultValue.key));
                        newKeysFiltered =
                            options.length === optionsWithoutDefaultValue.length ? [String(stringDefaultValue.key)] : optionsWithoutDefaultValue;
                        break;
                }
                onChange(name, newKeysFiltered);
            }
        }
    };

    const optionsWithDefault = useMemo(() => {
        const defaultValueArray: IDropdownOption[] = [stringDefaultValue];
        return defaultValueArray.concat(options);
    }, [options]);

    const optionsSelectedTooltip = useMemo(() => {
        const selectedoptions = optionsWithDefault.filter((item) => selectedKeys.includes(String(item.key)));
        return selectedoptions.map((item) => item.text).join("; ");
    }, [optionsWithDefault, selectedKeys]);

    const focusOption = (key: any) => {
        const option = options.find((opt) => opt.key === key);
        if (option) {
            // dropdownRef.current!.fo
        }
    };

    function handleKeyDown(event: any) {
        const keyPressed = event.key;
        if (/^[a-zA-Z0-9]$/.test(keyPressed)) {
            const option = options.find((opt) => opt.text.startsWith(keyPressed.toUpperCase()));
            if (option) {
                focusOption(option.key);
            }
        }
    }

    return (
        <div
            className={multiSelectorStyled.multiSelectorContainer}
            title={optionsSelectedTooltip}
        >
            <Dropdown
                onKeyDown={handleKeyDown}
                multiSelect
                onChange={onOptionSelected}
                componentRef={comboBoxRef}
                selectedKeys={selectedKeys}
                label={title}
                disabled={disabled}
                options={optionsWithDefault}
                className={className}
                styles={styles}
            />
        </div>
    );
};
