import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const RequestListFilterStyle = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        display: "flex",
        flexDirection: "row",
        height: props.extendedHeight ? 145 : 105,
        backgroundColor: theme.colors.requestFilter.backgroundColor,
        margin: "15px 0",
        alignItems: "flex-start",
        [mediaQueries.TABLET]: {
            flexDirection: "column",
            backgroundColor: "transparent",
            minHeight: "30vh",
        },
    }),
    dateContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "15px 0",
        alignItems: "flex-start",
        gap: theme.gaps.sizes.base,
        "& .datePicker": {
            paddingLeft: theme.paddings.header.paddingLeft,
            paddingRight: theme.paddings.header.paddingRight,
        },
        "& .dateChangePicker": {
            marginTop: -5,
            paddingLeft: theme.paddings.header.paddingLeft,
        },
    },
    verticalWhiteSeparator: {
        height: "100%",
        borderLeft: theme.borders.default.whiteBorder,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    },
    horizontalWhiteSeparator: {
        width: "100%",
        borderBottom: `3px solid ${theme.colors.palette.white}`,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    },
    resetContainer: {
        display: "flex",
        justifyContent: "end",
        width: "100%",
    },

    actionsContainer: {
        display: "flex",
        flexDirection: "row",
        gap: 20,
    },
    addActuations: {
        marginTop: -4,
        marginLeft: 32,
    },
}));
