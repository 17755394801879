import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConfigOrganismCertificateTypeFilter } from "../../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { IGenericPayload } from "../../../../models";

interface IOrganismCertificateTypeFilterState {
    filter: IConfigOrganismCertificateTypeFilter;
}

const initialState: IOrganismCertificateTypeFilterState = {
    filter: {
        certificateTypeId: undefined,
        organismId: undefined,
    },
};

export const upcomingOrganismCertificateTypeFilterSlice = createSlice({
    name: "upcomingAppointmentsFilterSlice",
    initialState,
    reducers: {
        resetOrganismCertificateTypeFilter: () => initialState,
        updateOrganismCertificateTypeFilterFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetOrganismCertificateTypeFilter, updateOrganismCertificateTypeFilterFilterProperty } = upcomingOrganismCertificateTypeFilterSlice.actions;

export default upcomingOrganismCertificateTypeFilterSlice.reducer;
