import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IConfigDockBlockItem } from "../../../../models/config/blockDock";

interface IConfigDockBlockState {
    list?: IConfigDockBlockItem[];
    sort: ISortState;
    loading: boolean;
}

const initialState: IConfigDockBlockState = {
    sort: emptySortState(),
    loading: false,
};

export const configDockBlockListSlice = createSlice({
    name: "configDockBlockListSlice",
    initialState,
    reducers: {
        resetConfigDockBlockList: () => initialState,
        setConfigDockBlockList: (state, action: PayloadAction<IConfigDockBlockItem[]>) => {
            state.list = action.payload;
        },
        updateSortDockBlockColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setConfigDockBlockLoadingList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetConfigDockBlockList, setConfigDockBlockList, setConfigDockBlockLoadingList, updateSortDockBlockColumn } =
    configDockBlockListSlice.actions;

export default configDockBlockListSlice.reducer;
