import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IManagemenTActiveSummaryForm } from "../../../../models/management/summary/ISummary";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface IManagmentSummaryFormState {
    form: IManagemenTActiveSummaryForm;
    loading: boolean;
    showPopUp?: PopUpCodes;
}

const initialState: IManagmentSummaryFormState = {
    form: {
        date: new Date(),
        hour: "",
        summaryNumber: "",
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managmentSummaryFormReducer = createSlice({
    name: "managmentSummaryFormReducer",
    initialState,
    reducers: {
        resetManagementSummaryForm: () => initialState,

        updateManagementSummaryConditionForm: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setLoadingManagementSummaryForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowManagementSummaryPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
            if (action.payload === PopUpCodes.CREATE) {
                state.form = {
                    ...state.form,
                    date: new Date(),
                    hour: new Date().toISOString(),
                };
            }
        },
    },
});

export const { resetManagementSummaryForm, setLoadingManagementSummaryForm, setShowManagementSummaryPopUp, updateManagementSummaryConditionForm } =
    managmentSummaryFormReducer.actions;

export default managmentSummaryFormReducer.reducer;
