import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IConfigMerchandiseCategoryFilter } from "../../../../models/config/merchandiseCategory";

interface IConfigMerchandiseCategoryFilterState {
    filter: IConfigMerchandiseCategoryFilter;
}

const initialState: IConfigMerchandiseCategoryFilterState = {
    filter: {
        merchandiseCategoryId: -1,
        merchandiseOriginId: -1,
        merchandiseTypeId: -1,
    },
};

export const configMerchandiseCategoryFilterSlice = createSlice({
    name: "configMerchandiseCategoryFilterSlice",
    initialState,
    reducers: {
        resetConfigMerchandiseCategoryFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetConfigMerchandiseCategoryFilter, updateFilterProperty } = configMerchandiseCategoryFilterSlice.actions;

export default configMerchandiseCategoryFilterSlice.reducer;
