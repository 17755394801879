import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import React, { useEffect, useMemo } from "react";
import { fetchRequestStates } from "../../../../../redux/actions/catalog/catalog";
import { useAppSelector, useAppDispatch } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { IBaseSelectorProps } from "../../../../request/common/selectors/IBaseSelectorProps";
import { ControlledDropdown } from "../../controller/controlledSelector";

interface IMasterRequestStatesSelector extends IBaseSelectorProps {
    stateId: number;
}

export const RequestStatesSelector: React.FC<IMasterRequestStatesSelector> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    isRequired,
    control,
    rules,
    disabled,
    stateId,
    allOption,
    calloutMessage,
    calloutLabel,
    allOptionText,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.requestStates);
    const dispatch = useAppDispatch();

    const onSelectorChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };

    useEffect(() => {
        data === undefined && dispatch(fetchRequestStates());
    }, [dispatch, data]);

    const options = useMemo(() => {
        return data?.filter((item) => item.main && item.id !== stateId);
    }, [selectedKey, data]);

    return (
        <ControlledDropdown
            required={isRequired}
            options={
                allOption ? mapAllOptionIDescriptionToDropDownOptions(options ?? [], allOptionText) : mapIDescriptionToDropDownOptions(options ?? [])
            }
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={disabled ?? loading}
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            calloutLabel={calloutLabel}
            calloutMessage={calloutMessage}
        />
    );
};
