import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IAppointmentHistoricFilter } from "../../../../models/appointment/IAppointmentHistoric";
import { DaySelector } from "../../../../models/requests/IDaySelector";
import {
    fetchAppointmentHistoricAppointmentListAction,
    fetchAppointmentHistoricIncidenceListAction,
    fetchAppointmentHistoricInspectorListAction,
} from "../../../../redux/actions/appointments/historic/historic";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetAppointmentHistoricFilter, updateAppointmentHistoricFilterProperty } from "../../../../redux/reducers/appointment/historic/filter";
import { compileNameOfProperty } from "../../../../utils";
import { CustomDatePicker } from "../../../common/customDatePicker/customDatePicker";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { TextInput } from "../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../common/selectors";
import { AppointmentHistoric } from "../container";
import { AppointmentHistoricFilterStyle } from "./filterStyle.jss";

interface IAppointmentHistoricFilterProps {
    selectedPivot: string;
}

export const AppointmentHistoricFilter: React.FC<IAppointmentHistoricFilterProps> = ({ selectedPivot }) => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.appointmentHistoricFilter);
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT_HISTORIC" });
    const theme = useTheme();
    const style = AppointmentHistoricFilterStyle({ theme });

    const onApply = () => {
        selectedPivot === AppointmentHistoric.INCIDENCE && dispatch(fetchAppointmentHistoricIncidenceListAction());
        selectedPivot === AppointmentHistoric.APPOINTMENT && dispatch(fetchAppointmentHistoricAppointmentListAction());
        selectedPivot === AppointmentHistoric.INSPECTOR && dispatch(fetchAppointmentHistoricInspectorListAction());
    };

    const onReset = () => {
        dispatch(resetAppointmentHistoricFilter());
        onApply();
    };

    const updateHeaderProperty = (name: string, value: any) => {
        dispatch(updateAppointmentHistoricFilterProperty({ name, value }));
    };

    const onBooleanSelectorChange = (name: string, nullablebooleanValue: any) => {
        updateHeaderProperty(name, nullablebooleanValue);
    };

    const onInputChange = (event: any, name: string) => {
        updateHeaderProperty(name, event.target.value);
    };

    const content = (
        <div className={style.container}>
            <CustomDatePicker
                startDate={filter.startDate}
                endDate={filter.endDate}
                maxDaySelected={DaySelector.MAX_YEAR_SELECTED}
                minDaySelected={DaySelector.MIN_DAYS_SELECTED}
                setFilterProperty={updateHeaderProperty}
                showLeftArrowController={false}
            />
            <div className={style.verticalWhiteSeparator}></div>

            <div className={style.filter}>
                <div className={style.item}>
                    <BooleaMultiSelector
                        selectedKey={filter.withErrors}
                        propertyName={compileNameOfProperty<IAppointmentHistoricFilter>("withErrors")}
                        title={t("WITH_ERROR")}
                        onChange={onBooleanSelectorChange}
                    />
                </div>

                <div className={style.item}>
                    <TextInput
                        value={filter.certificateNumber}
                        propertyName={compileNameOfProperty<IAppointmentHistoricFilter>("certificateNumber")}
                        label={t("CERTIFICATE_NUMBER")}
                        onChange={onInputChange}
                    />
                </div>
                <div className={style.item}>
                    <TextInput
                        value={filter.transportUnitNumber}
                        propertyName={compileNameOfProperty<IAppointmentHistoricFilter>("transportUnitNumber")}
                        label={t("TRANSPORT_UNIT")}
                        onChange={onInputChange}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <FilterHeader
            buttonColumn={true}
            content={content}
            onApply={onApply}
            onReset={onReset}
            height={120}
        />
    );
};
