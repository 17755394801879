import { ConstrainMode, DetailsList, IColumn, IComboBoxOption, SelectionMode } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { InspectionListStyle } from "./inspectionList.jss";
import { ICertificateType, Inspection, InspectionWithIndex } from "../../../../../../models";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../common/separator/separator";
import { NameIcons } from "../../../../../../assets/icons";

interface IProps {
    inspections: Inspection[];
    editCard: (inspection: Inspection, indexOrder: number) => void;
    deleteCard: (indexOrder: number) => void;
    certificatesOptions: ICertificateType[];
    organismsOptions: IComboBoxOption[];
}
export const InspectionList: React.FC<IProps> = ({ inspections, editCard, deleteCard, certificatesOptions, organismsOptions }) => {
    const theme = useTheme();
    const styles = InspectionListStyle({ theme });
    const { t } = useTranslation("common");

    const inspectionWithIndex: InspectionWithIndex[] = inspections.map((item, index) => {
        return { inspection: item, index };
    });

    const inspectionColumns: IColumn[] = useMemo(
        () => [
            {
                key: "organismId",
                name: t("CREATE_REQUEST.FIELDS.ORGANISM"),
                fieldName: "organismId",
                minWidth: 100,
                maxWidth: 150,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: InspectionWithIndex) => {
                    return (
                        <span>{item.inspection?.organismId && organismsOptions?.find((org) => org.key === item.inspection?.organismId)?.text}</span>
                    );
                },
            },
            {
                key: "certificateTypeId",
                name: t("CREATE_REQUEST.FIELDS.CERTIFICATE"),
                fieldName: "certificateTypeId",
                minWidth: 100,
                maxWidth: 100,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: InspectionWithIndex) => {
                    return (
                        <span>
                            {item.inspection?.certificateTypeId &&
                                certificatesOptions?.find((cert) => cert.id === item.inspection?.certificateTypeId)?.description}
                        </span>
                    );
                },
            },
            {
                key: "certificateNumber",
                name: t("CREATE_REQUEST.FIELDS.CERTIFICATE_NUMBER"),
                fieldName: "certificateNumber",
                minWidth: 150,
                maxWidth: 200,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item?: InspectionWithIndex) => {
                    return <span>{item?.inspection.certificateNumber}</span>;
                },
            },
            {
                key: "exporter",
                name: t("CREATE_REQUEST.FIELDS.EXPORTER"),
                fieldName: "exporter",
                minWidth: 80,
                maxWidth: 150,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: InspectionWithIndex) => {
                    return <span>{item.inspection?.exporter}</span>;
                },
            },
            {
                key: "importer",
                name: t("CREATE_REQUEST.FIELDS.IMPORTER"),
                fieldName: "importer",
                minWidth: 80,
                maxWidth: 150,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: InspectionWithIndex) => {
                    return <span>{item.inspection?.importer}</span>;
                },
            },
            {
                key: "year",
                name: t("CREATE_REQUEST.FIELDS.YEAR"),
                fieldName: "year",
                minWidth: 60,
                maxWidth: 75,
                data: "string",
                isPadded: false,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: InspectionWithIndex) => {
                    return <span>{item.inspection?.year}</span>;
                },
            },

            {
                key: "actionButtons",
                fieldName: "actionButtons",
                name: "",
                minWidth: 60,
                maxWidth: 75,
                data: "string",
                isPadded: false,
                isRowHeader: false,
                isResizable: false,
                onRender: (item: InspectionWithIndex) => {
                    return (
                        <span className={styles.icons}>
                            <CustomIcons
                                iconName={NameIcons.EDIT}
                                onClick={() => editCard(item.inspection, item.index)}
                            ></CustomIcons>
                            <Separator size="auto"></Separator>
                            <CustomIcons
                                iconName={NameIcons.REMOVE}
                                onClick={() => deleteCard(item.index)}
                            ></CustomIcons>
                        </span>
                    );
                },
            },
        ],
        [organismsOptions, certificatesOptions],
    );

    return (
        <div className={styles.inspectionlistContainer}>
            <DetailsList
                items={inspectionWithIndex}
                columns={inspectionColumns}
                selectionMode={SelectionMode.none}
                constrainMode={ConstrainMode.horizontalConstrained}
            />
        </div>
    );
};
