import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuSetting, IMenuSettingPayload } from "../../../../models/management/menuSetting/IMenySetting";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

export interface IManagementMenuSettingsByRoleByRoleFormState {
    roleId?: string;
    form?: IMenuSetting[];
    loading: boolean;
    showPopUp?:PopUpCodes;
}

const initialState: IManagementMenuSettingsByRoleByRoleFormState = {
    loading: false,
   
};

export const managementMenuSettingsByRoleFormSlice = createSlice({
    name: "managementMenuSettingsByRoleFormSlice",
    initialState,
    reducers: {
        resetMenuSettingsByRoleForm: () => initialState,

        setLoadingMenuSettingsByRoleForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateMenuSettingForm: (state, action: PayloadAction<IMenuSettingPayload>) => {
            if (state.form) {
                state.form[action.payload.indexModule].features[action.payload.indexOption].active = action.payload.active;
            }
        },
        setMenuSettingsByRoleForm: (state, action: PayloadAction<IMenuSetting[]>) => {
            state.form = action.payload;
        },
        setManagementMenuSettingsByRoleRole: (state, action: PayloadAction<string>) => {
            state.roleId = action.payload;
        },
        setManagementMenuSettingsByRoleRolePopUpConfirmation: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetMenuSettingsByRoleForm,
    setLoadingMenuSettingsByRoleForm,
    setMenuSettingsByRoleForm,
    updateMenuSettingForm,
    setManagementMenuSettingsByRoleRole,
    setManagementMenuSettingsByRoleRolePopUpConfirmation
} = managementMenuSettingsByRoleFormSlice.actions;

export default managementMenuSettingsByRoleFormSlice.reducer;
