import { IDropdownOption } from "@fluentui/react";
import { useEffect, useMemo } from "react";
import { fetchTransportUnitSizes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

export const ContainerSizeSelector: React.FC<IBaseSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    isRequired,
    control,
    rules,
    disabled,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.transportUnitSizes);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchTransportUnitSizes());
    }, [dispatch, data]);

    const customOnChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };
    const options = useMemo(() => {
        return mapIDescriptionToDropDownOptions(data);
    }, [data]);
    return (
        <ControlledDropdown
            required={isRequired}
            options={options}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={loading || disabled}
            onChange={customOnChange}
            selectedKey={selectedKey}
        />
    );
};
