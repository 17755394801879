import { useCallback } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { IGenericPayload } from "../../../models";
import { InvoicingFilterStyle } from "./filterStyle.jss";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { CustomDatePicker } from "../../common/customDatePicker/customDatePicker";
import { DaySelector } from "../../../models/requests/IDaySelector";
import { FilterHeader } from "../../common/filterHeader/filterHeader";
import { compileNameOfProperty } from "../../../utils";
import { IInvoicingFilter } from "../../../models/invoicing/filter/IInvoiceFilter";
import { CustomerFilterSelector } from "../../common/selectors/controllerSelectors/customerSelector/customerFilterSelector";

interface IInvoicingFilterContainerProps {
    filter: IInvoicingFilter;
    applyFilter: () => any;
    resetFilter: () => any;
    updateFilterProperty: (payload: IGenericPayload) => any;
}

export const InvoicingFilterContainer: React.FC<IInvoicingFilterContainerProps> = ({ updateFilterProperty, filter, applyFilter, resetFilter }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const theme = useTheme();
    const style = InvoicingFilterStyle({ theme });

    const setFilterProperty = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        return updateFilterProperty(payload);
    };

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            setFilterProperty(name, event.key);
        },
        [dispatch],
    );
    return (
        <FilterHeader
            content={
                <div className={style.container}>
                    <CustomDatePicker
                        startDate={filter.startDate}
                        endDate={filter.endDate}
                        maxDaySelected={DaySelector.MAX_YEAR_SELECTED}
                        minDaySelected={DaySelector.MIN_DAYS_SELECTED}
                        setFilterProperty={setFilterProperty}
                        showLeftArrowController={false}
                    />
                    <div className={style.verticalWhiteSeparator}> </div>
                    <div className={style.selector}>
                        <CustomerFilterSelector
                            title={t("FIELDS.CUSTOMERID")}
                            onChange={onSelectorChange}
                            selectedKey={filter.customerId}
                            name={compileNameOfProperty<IInvoicingFilter>("customerId")}
                            filteredRegisteredCustomer={true}
                        />
                    </div>
                </div>
            }
            onApply={applyFilter}
            onReset={resetFilter}
        />
    );
};
