import { schemaRole, schemaView } from "../../../../../common/enum/management/Schema";

export const schemaConfigurations = {
    [schemaRole.DEFAULT]: {
        [schemaView.USER_DATA]: [
            "email",
            "name",
            "surName",
            "lastName",
            "phoneNumber",
            "roleId",
            "crmCode",
            "externalCode",
            "organismId",
            "terminalId",
        ],
        [schemaView.ENTITY_DATA]: ["name", "documentNumber", "socialAddress", "postalCode", "city", "province"],
        [schemaView.PAYMENT_DATA]: ["accountNumber", "paymentMethod", "bankEntity"],
        [schemaView.ADMIN_DATA]: ["phone", "mobilePhone", "emails"],
        [schemaView.OPERATOR_DATA]: ["phone", "mobilePhone", "emails"],
        [schemaView.DOCUMENT_DATA]: ["documentName", "documentId", "file", "contentType", "fileName", "index", "uri"],
    },
    [schemaRole.CARRIER]: {
        [schemaView.USER_DATA]: ["name", "email"],
        [schemaView.ENTITY_DATA]: ["name"],
        [schemaView.PAYMENT_DATA]: [],
        [schemaView.ADMIN_DATA]: [],
        [schemaView.OPERATOR_DATA]: [],
        [schemaView.DOCUMENT_DATA]: [],
    },
};

export const isFieldRequiredWithSchema = (role: schemaRole, form: schemaView) => {

    const currentSchemaConfiguration = schemaConfigurations[role];
    if (currentSchemaConfiguration.hasOwnProperty(form)) {
        const requiredFields = currentSchemaConfiguration[form];
        return requiredFields;
    }

    return [];
};

export const isRequiredField = (role: schemaRole, form: schemaView, field: string) => {

    return isFieldRequiredWithSchema(role, form).includes(field);
};
