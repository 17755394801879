import { Icon } from "@fluentui/react";
import { NavLink } from "react-router-dom";
import { NavButtonsStyled } from "./navButtons.jss";
import { ColorType } from "../../../common/enum/ColorType";

interface INavLinkButtonProps {
    to: string;
    title: string;
    color?: ColorType;
    icon?: string;
    target?: string;
    className?: string;
}

export const NavButton = (props: INavLinkButtonProps) => {
    const navButtonStyled = NavButtonsStyled();
    const icon = props.icon ? <Icon iconName={props.icon} /> : "";
    const target = props.target ?? "_self";
    const className = props.className ?? "";

    const getColorClassName = (type?: ColorType) => {
        switch (type) {
            case ColorType.primary:
                return navButtonStyled.primary;
            case ColorType.secondary:
                return navButtonStyled.secondary;
            case ColorType.success:
                return navButtonStyled.success;
            case ColorType.danger:
                return navButtonStyled.danger;
            case ColorType.warning:
                return navButtonStyled.warning;
            case ColorType.info:
                return navButtonStyled.info;
            default:
                return "";
        }
    };

    return (
        <>
            <NavLink
                className={`${navButtonStyled.navButton} ${getColorClassName(props.color)} ${className}`}
                to={props.to}
                target={target}
            >
                {icon}
                {props.title}
            </NavLink>
        </>
    );
};

export const NavButtonPrimary = (props: INavLinkButtonProps) => {
    return (
        <>
            <NavButton
                color={ColorType.primary}
                {...props}
            />
        </>
    );
};

export const NavButtonSecondary = (props: INavLinkButtonProps) => {
    return (
        <>
            <NavButton
                color={ColorType.secondary}
                {...props}
            />
        </>
    );
};

export const NavButtonSuccess = (props: INavLinkButtonProps) => {
    return (
        <>
            <NavButton
                color={ColorType.success}
                {...props}
            />
        </>
    );
};

export const NavButtonDanger = (props: INavLinkButtonProps) => {
    return (
        <>
            <NavButton
                color={ColorType.danger}
                {...props}
            />
        </>
    );
};

export const NavButtonWarning = (props: INavLinkButtonProps) => {
    return (
        <>
            <NavButton
                color={ColorType.warning}
                {...props}
            />
        </>
    );
};

export const NavButtonInfo = (props: INavLinkButtonProps) => {
    return (
        <>
            <NavButton
                color={ColorType.info}
                {...props}
            />
        </>
    );
};
