import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { removeConfigDockBlock, postConfigDockBlock } from "../../../../redux/actions/config/dockBlock";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetConfigDockBlockForm, setConfigDockBlockShowPopUp } from "../../../../redux/reducers/config/dockBlock/form";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ConfigDockBlockForm } from "./form/form";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { RequiredFieldMessageStyle } from "../../../common/requiredMessage/requiredMessageStyle.jss";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ConfigDockBlockFormController = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { loading, form, showPopUp } = useAppSelector((state) => state.configDockBlockForm);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }

        return "";
    }, [showPopUp]);

    const onCancelPopUp = () => {
        dispatch(setConfigDockBlockShowPopUp(PopUpCodes.NONE));
    };

    const onAcceptPopUp = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postConfigDockBlock()).then((response: any) => {
                response && onCancelPopUp();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(removeConfigDockBlock()).then((response: any) => {
                if (response) {
                    onCancelPopUp();
                    onDimissed();
                }
            });
        }
    };

    const onDimissed = () => {
        dispatch(resetConfigDockBlockForm());
    };
    return (
        <>
            <PopUpForm
                content={<ConfigDockBlockForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE}
                onCancel={onCancelPopUp}
                onSave={onAcceptPopUp}
                onDimissed={onDimissed}
                isLoading={loading}
                height={"30vh"}
                width={"40vw"}
                isDisabledButton={!form.dockId || !form.dockBlockId}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancelPopUp}
                onAccept={onAcceptPopUp}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
                width={"auto"}
                loading={loading}
                disabledActions={loading}
            />
        </>
    );
};
