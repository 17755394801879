import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IStatisticsOrganismItem } from "../../../../../models/statistics/IOrganism";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/statistics/incidenceOrganism/list";
import { store } from "../../../../../redux/store";
import { onUpdateSortedColumnUtil, compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { StatisticsIncidenceOrganismColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsIncidenceOrganismListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = StatisticsIncidenceOrganismColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INCIDENCE.ORGANISM.TABLE" });
    const dispatch = useAppDispatch();

    const { sort } = useAppSelector((store) => store.statisticsOrganismIncidenceList.data);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().statisticsOrganismIncidenceList.data.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IStatisticsOrganismItem>("organismDescription"),
            minWidth: 200,
            maxWidth: 240,
            name: t("ORGANISM"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOrganismItem>("organismDescription")}
                    title={t("ORGANISM")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOrganismItem>("organismDescription"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOrganismItem) => <span className={styles.dataCell}>{item.organismDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismItem>("incidences"),
            minWidth: 200,
            maxWidth: 300,
            name: t("VEHICLES_ICIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOrganismItem>("incidences")}
                    title={t("VEHICLES_ICIDENCE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOrganismItem>("incidences"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOrganismItem) => <span className={styles.dataCell}>{item.incidences ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismItem>("incidencesPercentage"),
            minWidth: 110,
            maxWidth: 110,
            name: t("PERCENT_INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOrganismItem>("incidencesPercentage")}
                    title={t("PERCENT_INCIDENCE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOrganismItem>("incidencesPercentage"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOrganismItem) => <span className={styles.dataCell}>{item.incidencesPercentage ? `${item.incidencesPercentage}%` : DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismItem>("incidencesTime"),
            minWidth: 150,
            maxWidth: 250,
            name: t("INCIDENCE_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOrganismItem>("incidencesTime")}
                    title={t("INCIDENCE_TIME")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOrganismItem>("incidencesTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOrganismItem) => <span className={styles.dataCell}>{item.incidencesTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsOrganismItem>("averageIncidencesTime"),
            minWidth: 100,
            maxWidth: 150,
            name: t("AVERAGE_INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsOrganismItem>("averageIncidencesTime")}
                    title={t("AVERAGE_INCIDENCE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IStatisticsOrganismItem>("averageIncidencesTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IStatisticsOrganismItem) => <span className={styles.dataCell}>{item.averageIncidencesTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
