import { RootState } from "../../../store";

export const moduleSelector = (state: RootState) => {
    return state.modules;
};

export const getUserModules = (state: RootState) => {
    const modules = moduleSelector(state);
    return modules.modules;
};
export const getUserFeatures = (state: RootState) => {
    const modules = moduleSelector(state);
    return modules.feature;
};

export const loadingUserModules = (state: RootState) => {
    const modules = moduleSelector(state);
    return modules.isLoading;
};
