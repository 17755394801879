import { createUseStyles } from "react-jss";

export const tableIncidenceStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.md,
        width: theme.widths.sectionContainer.complete,
        padding: theme.paddings.small,
        paddingLeft: 0,
    },
    header: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.gaps.sizes.md,
        color: theme.colors.titles.primary.color,
    }, 
   
}));
