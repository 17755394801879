import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { CustomDocksSelector } from "../../../request/common/selectors/docks/customDockSelector";
import { fetchConfigDockBlockListAction } from "../../../../redux/actions/config/dockBlock";
import { resetConfigDockBlockFilter, updateFilterProperty } from "../../../../redux/reducers/config/dockBlock/filters";
import { CustomDockBlockSelector } from "../../../request/common/selectors/dockBlock/customDockBlockSelector";
import { IConfigDockBlockItem } from "../../../../models/config/blockDock";
import { ConfigDockBlockFilterStyle } from "./filterStyle.jss";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../common/enum/DefaultValues";

export const ConfigDockBlockFilter = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const theme = useTheme();

    const style = ConfigDockBlockFilterStyle({ theme });
    const { filter } = useAppSelector((state) => state.configDockBlockFilter);
    const updateProperty = (name: string, value: any) => {
        dispatch(updateFilterProperty({ name, value }));
    };
    const onSelectorChange = (name: string, option: any) => {
        updateProperty(name, option.key );
    };

    const contetFilter = (
        <div className={style.filter}>
            <div className={style.item}>
                <CustomDocksSelector
                    title={t("DOCK_MERCHANDISE.DOCK")}
                    onChange={onSelectorChange}
                    selectedKey={filter.dockId !== -1 ? filter.dockId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigDockBlockItem>("dockId")}
                ></CustomDocksSelector>
            </div>

            <div className={style.longItem}>
                <CustomDockBlockSelector
                    title={t("DOCK_BLOCK.BLOCK")}
                    onChange={onSelectorChange}
                    selectedKey={filter.dockBlockId !== -1 ? filter.dockBlockId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigDockBlockItem>("dockBlockId")}
                ></CustomDockBlockSelector>
            </div>
        </div>
    );
    const onApplyFilter = () => {
        dispatch(fetchConfigDockBlockListAction());
    };
    const onResetFilter = () => {
        dispatch(resetConfigDockBlockFilter());
        onApplyFilter();
    };

    return (
        <FilterHeader
            content={contetFilter}
            onApply={onApplyFilter}
            onReset={onResetFilter}
        />
    );
};
