import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { RequestIncidenceType } from "../../../../../../../common/enum/categoryIncidence";

import { IGenericPayload } from "../../../../../../../models";
import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { ICreateIncidence, IRequestIncidenceItem } from "../../../../../../../models/requests/IRequestIncidence";
import {
    deleteIncidencesRequest,
    fetchIncidencesRequest,
    getIncidencRequest,
} from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { updateIncidenceFormProperty } from "../../../../../../../redux/reducers/request/edit/form/incidence";
import { editRequestId } from "../../../../../../../redux/selectors/request/request";
import { widths } from "../../../../../../../styles/romeu/widths";
import { compileNameOfProperty, convertToLocal } from "../../../../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../../../../common/buttons/buttons";
import { PopUpConfirmation } from "../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { Separator } from "../../../../../../common/separator/separator";
import { TitleH5 } from "../../../../../../common/titles/titles";
import { CustomTableActions } from "../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../common/tableCustom/tableCustom";
import { isShowIncidenceActions } from "../../../../../common/validation/incidence";
import { EditRequestIncidenceFormControll } from "../controller/controller";
import { tableIncidenceStyles } from "./listStyle.jss";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpConfirmationType } from "../../../../../../../common/enum/PopUpConfirmationType";

interface PropsTableIncidence {
    category: string;
    schema: IConfigEditRequest;
}

export const TableIncidence: React.FC<PropsTableIncidence> = ({ schema, category }) => {
    const theme = useTheme();
    const styles = tableIncidenceStyles({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const dispatch = useAppDispatch();

    const [showIncidenceForm, setShowIncidenceForm] = useState<boolean>(false);
    const [showRemoveIncidenceConfirm, setShowRemoveIncidenceConfirm] = useState<boolean>(false);

    const [idRemoveIncidenceConfirm, setIdRemoveIncidenceConfirm] = useState<number>(DefaultValues.DEFAULT_ID);
    const requestId = useAppSelector(editRequestId);

    const incidences = useAppSelector((store) =>
        category === RequestIncidenceType.OPERATIONAL
            ? store.editRequest.incidences.incidenceOperational
            : store.editRequest.incidences.incidenceOrganism,
    );

    const loading = useAppSelector((store) =>
        category === RequestIncidenceType.OPERATIONAL
            ? store.editRequest.incidences.loadingIncidenceOperational
            : store.editRequest.incidences.loadingIncidenceOrganism,
    );

    const titleTable = category === RequestIncidenceType.OPERATIONAL ? t("LOAD_INCIDENCE") : t("INCIDENCE_OPERATOR");

    const onAcceptDeleteIncidence = () => {
        dispatch(deleteIncidencesRequest(requestId, idRemoveIncidenceConfirm, category));
        onCancelDeleteIncidence();
    };
    const onCancelDeleteIncidence = () => {
        setIdRemoveIncidenceConfirm(DefaultValues.DEFAULT_ID);
        setShowRemoveIncidenceConfirm(false);
    };

    const onCreateNewIncidence = () => {
        updateIncidenceCategory();
        setShowIncidenceForm(true);
    };

    const updateIncidenceCategory = () => {
        const payload: IGenericPayload = {
            name: compileNameOfProperty<ICreateIncidence>("category"),
            value: category,
        };
        dispatch(updateIncidenceFormProperty(payload));
    };

    const columns: ColumnType<IRequestIncidenceItem, keyof IRequestIncidenceItem>[] = [
        { key: "typeDescription", title: t("INCIDENCETYPE") },
        { key: "remarks", title: t("REMARKS") },
        { key: "createdBy", title: t("CREATEDBY") },
        { key: "createdOn", title: t("REGIST") },
        { key: "openingOn", title: t("OPENINGON"), showColumn:schema.incidence_openingOn.visible },
        { key: "closingOn", title: t("CLOSINGON"), showColumn:schema.incidence_closingOn.visible  },
        { key: "actions", title: "" },
    ];

    const list = useMemo(() => {
        return incidences ?? [];
    }, [incidences]);

    const newIncidenceList = list.map((item) => {
        return {
            ...item,
            createdOn: convertToLocal(item.createdOn),
            openingOn:item.openingOn ? convertToLocal(item.openingOn) :  DefaultValues.EMPTY_LIST,
            closingOn: item.closingOn ? convertToLocal(item.closingOn) : DefaultValues.EMPTY_LIST,
            actions: (
                <CustomTableActions
                    children={
                        <div>
                            {schema.incidence_update.visible && isShowIncidenceActions(item.organismId, item.createdBy) && (
                                <CustomIcons
                                    isButton={true}
                                    iconName={NameIcons.EDIT}
                                    onClick={() => {
                                        dispatch(getIncidencRequest(requestId, item.id));
                                        setShowIncidenceForm(true);
                                    }}
                                ></CustomIcons>
                            )}
                            {schema.incidence_delete.visible && isShowIncidenceActions(item.organismId, item.createdBy) && (
                                <>
                                    <Separator />

                                    <CustomIcons
                                        isButton={true}
                                        iconName={NameIcons.REMOVE}
                                        onClick={() => {
                                            setIdRemoveIncidenceConfirm(item.id);
                                            setShowRemoveIncidenceConfirm(true);
                                        }}
                                    ></CustomIcons>
                                </>
                            )}
                        </div>
                    }
                />
            ),
        };
    });
    useEffect(() => {
        if (incidences === undefined) {
            dispatch(fetchIncidencesRequest(requestId, category));
        }
        updateIncidenceCategory();
    }, [incidences]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <TitleH5
                    title={`${titleTable} (${incidences ? incidences.length : 0})`}
                    bold={true}
                />
                {schema.incidence_create.visible && (
                    <ButtonPrimaryInverseWithBorder
                        handleClick={onCreateNewIncidence}
                        title={`+ ${t("BUTTONADDINCIDENCE")}`}
                    />
                )}
            </div>

            <CustomTableList
                data={newIncidenceList}
                columns={columns}
                loading={loading}
            />
            <PopUpConfirmation
                showPopUp={showRemoveIncidenceConfirm}
                onCancel={onCancelDeleteIncidence}
                onAccept={onAcceptDeleteIncidence}
                type={PopUpConfirmationType.DELETE}
                width={widths.confirmPopUp.remove}
                height={"auto"}
            />

            <EditRequestIncidenceFormControll
                showIncidenceForm={showIncidenceForm}
                setShowIncidenceForm={setShowIncidenceForm}
                category={category}
                requestId={requestId}
            />
        </div>
    );
};
