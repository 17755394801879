import { DisclaimerStyles } from "./disclaimer.jss";
import { useTheme } from "react-jss";

interface IProps {
    title?: string;
    body: string;
    color: string;
    backgroundColor: string;
}

export const Disclaimer: React.FC<IProps> = ({ title, body, color, backgroundColor }) => {
    const theme = useTheme();

    const styles = DisclaimerStyles({ theme, backgroundColor, color });

    return (
        <div className={styles.container}>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.body}>{body}</div>
        </div>
    );
};
