import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const ManagementTariffFormStyle = createUseStyles((theme: any) => ({
    containerForm: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.xl,
        width: "100%",
        overflowY: "auto",
        justifyContent: "space-evenly",  
    },
    rowForm: {
        display: "flex",
        justifyContent: "space-between",
        gap: theme.gaps.sizes.xl,
        flexWrap: "wrap",
        "&  > *": {
            maxWidth: "45%",
        },
        [mediaQueries.TABLETPORTRAIT]: {
            display: "flex",
            flexDirection: "column",
            gap: theme.gaps.sizes.xl,
            flexWrap: "wrap",
            "&  > *": {
                maxWidth: "100%",
            },
        },
    },
    automateInput: {
        marginTop: -12,
    },
}));
