export const paddings = {
    sizes: {
        xs: 5,
        sm: 10,
        base: 15,
        md: 20,
        lg: 25,
        xl: 30,
        xxl: 40,
        xxxl: 50,
    },
    default: "12px",
    small: "3px",
    combo: {
        wrapperoffset: "3px",
    },
    siteNav: {
        logo: "17px",
        groupTitle: "6px 20px",
        group: "0",
        item: "7px 5px 7px 30px",
    },
    siteHeader: {
        title: "30px",
    },
    site: {
        content: "32px",
    },
    layout2Columns: {
        container: "30px",
    },
    layoutHeader2Columns: {
        container: "30px",
    },
    button: {
        container: "10px",
        icon: "5px",
    },
    navButton: {
        container: "10px",
        icon: "5px",
    },
    link: {
        icon: "5px",
    },
    navLink: {
        icon: "5px",
    },
    login: {
        default: "20px",
    },
    forms: {
        inputs: {
            defaultPadding: "10px",
        },
        errorsMessage: {
            defaultPadding: "2px 0",
        },
        section: {
            defaultPadding: "20px 0",
            firstSectionPadding: "0 0 20px 0",
        },
    },
    inputFile: 6,
    generalContainer: {
        default: 12,
    },
    header : {
        paddingLeft : 24,
        paddingRight : 24
    },
    table: {
        header: {
            paddingBottom: "10px",
        },
        body: {
            paddingBottom: "10px",
        }
    },
    popUp: {
        content : 25,
    }
};
