import { useCallback, useMemo, useState } from "react";
import { IFormOption } from "../../../../../../../models/common/IFormOptions";
import { IDockQueueRuleComponent, IDockQueueRuleComponentsValue } from "../../../../../../../models/management/dockQueueRule/IDockQueueRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { deleteRuleComponentValue, updateQueueRuleComponentValueForm } from "../../../../../../../redux/reducers/management/dockQueueRule/form";
import { CheckboxSelector } from "../../../../../../common/checkboxSelector/checkboxSelector";
import { RadioButtonGroup } from "../../../../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { Section } from "../section/section";

export const DockQueueRuleComponentContainer = () => {
    const { view } = useAppSelector((state) => state.managementDockQueueRuleConfiguration);
    const {
        form: { dockQueueRuleComponentValues },
    } = useAppSelector((state) => state.managementDockQueueRuleForm);
    const dispatch = useAppDispatch();

    const [active, setActive] = useState<{ [key: number]: boolean }>({});


    const isActive = (dockQueueRuleComponentId: number, value: number, ruleComponents: IDockQueueRuleComponentsValue[]): boolean =>
        ruleComponents.some(
            (ruleComponent) =>
                ruleComponent.dockQueueRuleComponentId === dockQueueRuleComponentId &&
                ruleComponent.value === value &&
                (ruleComponent.deleted === false || ruleComponent.deleted === null),
        );

    const onRadioButtonChange = (option: any, dockQueueRuleComponentId: number) => {
        let payload = {
            dockQueueRuleComponentId,
            value: +option,
            isBoolValue: true,
        };
        dispatch(updateQueueRuleComponentValueForm(payload));
    };

    const onCheckboxChange = (dockQueueRuleComponentId: number, value: number) => {
        let payload = {
            dockQueueRuleComponentId,
            value: +value,
            isBoolValue: false,
        };
        
        dispatch(updateQueueRuleComponentValueForm(payload));
    };

    const getValueFromActive = useCallback((key: number): boolean | undefined => active[key], [active]);


    const printComponent = (item: IDockQueueRuleComponent, index: number, options: IFormOption[]) => {
        let itemSelected = false;

        if (item.boolValue) {

            itemSelected = !!dockQueueRuleComponentValues.find(
                (r) => r.dockQueueRuleComponentId === item.id && (r.deleted === false || r.deleted === null),
            )?.value 

            if (itemSelected === null) {
                onRadioButtonChange(false, item.id);
            }
        }       

        return item.boolValue ? (
            <RadioButtonGroup
                name={item.description}
                onChange={(option: any, name?: any) => {
                    onRadioButtonChange(!!+option, item.id);
                }}
                selectedKey={itemSelected ? itemSelected.toString() : "0"}
            />
        ) : (
            <CheckboxSelector
                options={options}
                name={item.description}
                onChange={(name: string, value: any, indexOption, id) => {
                    if (id) {
                        onCheckboxChange(item.id, +id);
                    }
                }}
            />
        );
    };

    const onCheckboxChangeSection = useCallback(
        (componentValue: IDockQueueRuleComponent, existContent: boolean, event?: any, checked = false) => {            
            setActive((prevState) => ({
                ...prevState,
                [componentValue.id]: checked ?? existContent,
            }));

            if (checked && componentValue.boolValue) {
                onRadioButtonChange(false, componentValue.id);
            }

            if (!checked) {
                dispatch(deleteRuleComponentValue(componentValue.id));
            }
        },
        [active, dockQueueRuleComponentValues],
    );


    const form = useMemo(() => {
        return view?.dockQueueRuleComponents.map((ruleComponent: IDockQueueRuleComponent, index) => {
            const options = ruleComponent?.dockQueueRuleComponentValues.map((value): IFormOption => {
                return {
                    key: value.id,
                    text: value.description,
                    active: isActive(ruleComponent.id, value.id, dockQueueRuleComponentValues),
                    disabled: false,
                };
            });


            const existContent = () => {
                return dockQueueRuleComponentValues.some(
                    (componentValues) =>
                        componentValues.dockQueueRuleComponentId === ruleComponent.id &&
                        (componentValues.deleted === false || componentValues.deleted === null),
                );
            };

            return (
                <Section
                    title={ruleComponent.description}
                    value={printComponent(ruleComponent, index, options)}
                    showBubble={existContent()}
                    onChangeDisabledController={(e: any, checked?: boolean) => {
                        onCheckboxChangeSection(ruleComponent, existContent(), e, checked);
                    }}
                    sectionActive={getValueFromActive(ruleComponent.id) ?? existContent()}
                    showDisabledController={true}
                />
            );
        });
    }, [view, active, dockQueueRuleComponentValues]);

    return <>{form}</>;
};
