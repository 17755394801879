import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IUserOrganization } from "../../models";
import { IRole } from "../../models/common/IRole";
import { IUserAuthData } from "../../models/user/IUserAuthData";
import { fetchUserClaimsAction } from "../../redux/actions/user/claims/claims";
import { setUserOrganization } from "../../redux/actions/user/organization/organization";
import { setUserRole } from "../../redux/actions/user/role/role";
import { fetchUserData } from "../../redux/actions/userData/userData";
import { useAppDispatch } from "../../redux/hooks";
import { getUserClaimsLoaded } from "../../redux/selectors/user/claims/claims";
import { getUserOrganization } from "../../redux/selectors/user/organization/organization";
import { getUserRole } from "../../redux/selectors/user/role/role";
import { getUserData } from "../../redux/selectors/userData/userData";
import { LocalStorageKey, LocalStorageService } from "../../services/localStorageService";

export const ClaimsToken = () => {
    const dispatch = useAppDispatch();
    const userData: IUserAuthData = useSelector(getUserData);
    const organizationData: IUserOrganization = useSelector(getUserOrganization);
    const roleData: IRole = useSelector(getUserRole);
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);

    useEffect(() => {
        !userData.email && dispatch(fetchUserData());
    }, []);

    useEffect(() => {
        if (userData?.organizations?.length && !organizationData.id) {
            const localStorageService = new LocalStorageService();
            let organization = localStorageService.getItem(LocalStorageKey.ORGANIZATION);

            if (!organization || !userData.organizations.find((x) => x.id === organization.id)) {
                organization = userData.organizations[0];
            }

            dispatch(setUserOrganization(organization));
        }

        if (userData?.roles?.length && !roleData.id) {
            dispatch(setUserRole(userData.roles[0]));
        }
    }, [dispatch, organizationData.id, roleData.id, userData.organizations, userData.roles]);

    useEffect(() => {
        !isUserClaimsLoaded && organizationData.id && roleData.id && dispatch(fetchUserClaimsAction(organizationData.id, roleData.id));
    }, [dispatch, isUserClaimsLoaded, organizationData, roleData]);

    return <></>;
};
