import React, { useEffect } from "react";
import { fetchMerchandiseTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../../../common/selectors/customSelector/customSelector";

export const CustomMerchandiseTypeSelector: React.FC<ISelectorProps> = ({ onChange, name, title, selectedKey }) => {
    const { data } = useAppSelector((store) => store.catalog.merchandiseTypes);
    const dispatch = useAppDispatch();

    useEffect(() => {
        data.length === 0 && dispatch(fetchMerchandiseTypes());
    }, [dispatch, data]);

    return (
        <CustomSelector
            title={title}
            options={mapIDescriptionToDropDownOptions(data)}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
        ></CustomSelector>
    );
};
