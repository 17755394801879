import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { OrganismWithCertificateListColumns } from "./column/column";
import { SkeletonSizes } from "../../../../common/enum/sizes";
import { fetchOrganismCertificateTypeList } from "../../../../redux/actions/resources/organismCertificateType/organismCertificateType";
import { sortArray } from "../../../../utils";

export const OrganismWithCertificateList = () => {
    const dispatch = useAppDispatch();

    const { list, loading, sort } = useAppSelector((state) => state.configOrganismCertificateTypeList);

    const renderColumns = () => {
        return OrganismWithCertificateListColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchOrganismCertificateTypeList());
    }, [list]);

    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={sortArray(list, sort) ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 14.5rem)"}
                />
            )}
        </div>
    );
};
