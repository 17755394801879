import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IRequestFilter } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { NumericInput } from "../../../../../../common/inputs/numericInput";
import { TextInput } from "../../../../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../../../../common/selectors/multiSelectors/booleanMultiSelector/booleanMultiSelector";
import { MultipleContainerSizesSelector } from "../../../../../common/selectors/containerSize/multipleContainerSize";
import { MultipleTransportUnitSelector } from "../../../../../common/selectors/transportUnit/multipleTransportUnit";
import { MultipleValuedTypesSelector } from "../../../../../common/selectors/valuedTypes/multipleValuedTypes";

import { RequestFilterPopUpStyle } from "../filterStyle.jss";
import { AllCustomerSelector } from "../../../../../../common/selectors/controllerSelectors/customerSelector/allCustomerSelector";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    onInputChange: (event: any, name: string) => void;
    onMultipleStringKeysChange: (name: string, options: string[]) => void;
    onInputNumberChange: (value: number | undefined, name: string) => void;
    schema: any;
    onEnter: () => void;
}

export const RequestUtiFilter: React.FC<IProps> = ({
    onMultipleNumberKeysChange,
    onNullableBooleanChange,
    onInputChange,
    onMultipleStringKeysChange,
    onInputNumberChange,
    schema,
    onEnter,
}) => {
    const theme = useTheme();
    const styles = RequestFilterPopUpStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });

    return (
        <div className={styles.section}>
            {schema.sortVisible && (
                <NumericInput
                    value={filter.sort}
                    propertyName={compileNameOfProperty<IRequestFilter>("sort")}
                    label={t("ORDER")}
                    onChange={onInputNumberChange}
                    min={1}
                    max={1000}
                    onEnter={onEnter}
                />
            )}
            {schema.transportUnitNumberVisible && (
                <TextInput
                    value={filter.transportUnitNumber}
                    propertyName={compileNameOfProperty<IRequestFilter>("transportUnitNumber")}
                    label={t("TRANSPORT_UNIT")}
                    onChange={onInputChange}
                    onEnter={onEnter}
                />
            )}
            {schema.transportUnitIdVisible && (
                <MultipleTransportUnitSelector
                    selectedKeys={filter.transportUnitId}
                    propertyName={compileNameOfProperty<IRequestFilter>("transportUnitId")}
                    title={t("TRANSPORT_UNIT_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                />
            )}
            {schema.transportUnitSizeIdVisible && (
                <MultipleContainerSizesSelector
                    selectedKeys={filter.transportUnitSizeId}
                    propertyName={compileNameOfProperty<IRequestFilter>("transportUnitSizeId")}
                    title={t("CONTAINER_SIZE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}

            {schema.customerIdVisible && (
                <AllCustomerSelector
                    selectedKeys={filter.customerId}
                    propertyName={compileNameOfProperty<IRequestFilter>("customerId")}
                    title={t("CUSTOMER")}
                    onChange={onMultipleStringKeysChange}
                    autocomplete={true}
                />
            )}
            {schema.valuedVisible && (
                <MultipleValuedTypesSelector
                    selectedKeys={filter.valued}
                    propertyName={compileNameOfProperty<IRequestFilter>("valued")}
                    title={t("VALUED")}
                    onChange={onMultipleStringKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.invoicedVisible && (
                <BooleaMultiSelector
                    selectedKey={filter.invoiced}
                    propertyName={compileNameOfProperty<IRequestFilter>("invoiced")}
                    title={t("INVOICED")}
                    onChange={onNullableBooleanChange}
                />
            )}
        </div>
    );
};
