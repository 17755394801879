import { createUseStyles } from "react-jss";

export const CustomPopUpConfirmButtonActionsStyle = createUseStyles((theme: any) => ({
    generalContainerButtons: {
        display: "flex",
        justifyContent: "end",
        paddingBottom: theme.paddings.sizes.sm,
        width: "100%",
        flex: "1 1 auto",
    },
    
    sectionContainerButtons: {
        display: "flex",
        gap: theme.gaps.buttons.default,
        justifyContent: "end",
        alignItems: "center",
        "& > * ": {
            maxHeight: 40,
        },
    },
}));
