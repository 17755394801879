import { ManagementDockDisplayApi } from "../../../../api/management/dockDisplay";
import { Milliseconds } from "../../../../common/enum";
import { Toastr } from "../../../../components/common/toast/toast";
import { throwErrorToastrAsync } from "../../../../utils";

const managementDockDisplayApi = new ManagementDockDisplayApi();

export const fetchManagementDockDisplayAction = async (organization?: string): Promise<any> => {
    try {
        return await managementDockDisplayApi.getManagementDocksDisplayAsync(organization);
    } catch (error) {
        Toastr("", { autoClose: Milliseconds.FIVE_SEC, pauseOnFocusLoss: false }, error).error();
        return false;
    }
};
