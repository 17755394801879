import { IIconProps, IPivotStyles, IStyleSet, Pivot, PivotItem } from "@fluentui/react";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { generatePath, useNavigate, useParams } from "react-router";
import { NameIcons } from "../../../../assets/icons";
import { routeUrls } from "../../../../common/constants";
import { routeFeatures } from "../../../../common/constants/routeFeatures";
import { OrganismTypes, OrganizationTypes, RepresentativePresenceTypes, RoleType, ToastType } from "../../../../common/enum";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { SealTypes } from "../../../../common/enum/SealType";
import { useEditRequestLogic, useEditRequestSelector } from "../../../../hooks/request/useEditRequestLogic";
import { useNavigationWithBlocker } from "../../../../hooks/useBlockerHook";
import { IEditRequest } from "../../../../models/requests/IEditRequest";
import { IUserRoleView } from "../../../../models/requests/IUserRoleView";
import { fetchOrganisms } from "../../../../redux/actions/catalog/catalog";
import { deleteRequest, fetchEditRequestData, patchRequestData } from "../../../../redux/actions/request/edit/action/requestEdit";
import { suplantOrganismUser } from "../../../../redux/actions/request/list/requestList";
import { useAppDispatch } from "../../../../redux/hooks";
import { resetRequestState } from "../../../../redux/reducers/request/edit/editRequest";
import { getPivotStyles, throwErrorToastrAsync } from "../../../../utils";
import { ButtonPrimary, ButtonPrimaryInverseWithBorder } from "../../../common/buttons/buttons";
import { NavHeaderComponent } from "../../../common/navHeader/navHeader";
import { PopUpBlocker } from "../../../common/popup/popUpBlocker/popUpBlocker";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { Separator } from "../../../common/separator/separator";
import { Toastr } from "../../../common/toast/toast";
import { EndActionButtonsContainer } from "./components/actions";
import { EditRequestCustomerNotificationController } from "./components/customerNotification/controller";
import { StateChanger } from "./components/stateChanger/stateChanger";
import { StateHistory } from "./components/stateHistory/stateHistory";
import { EditRequestContainerStyle } from "./containerStyle.jss";
import { GeneralInformationEditContainer } from "./generalInformation/container";
import { EditRequestTabDocumentsContainer } from "./tabs/documents/container";
import { EditRequestTabsIncidenceContainer } from "./tabs/incidence/container";
import { EditRequestTabsInvoiceConceptContainer } from "./tabs/invoiceConcept/container";
import { EditRequestHistoryTimeLineContainer } from "./tabs/timeLineHistory/container";
import { EditRequestTabsVUAContainer } from "./tabs/vua/container";
import { setPersistScrollOnDetailsList } from "../../../../redux/reducers/webConfig/globalConfig";

interface IEditRequestContainerProps {
    backRoute?: string;
}

export const EditRequestContainer: React.FC<IEditRequestContainerProps> = ({ backRoute }) => {
    const [selectedKey, setSelectedKey] = useState("0");
    const theme = useTheme() as any;
    const styles = EditRequestContainerStyle({ theme, selectedKey });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id, impersonatedOrganismId } = useParams();

    const {
        data,
        headerRequest,
        requestInspection,
        copyHeaderRequest,
        isLoadedRequest,
        representativePresenceTypes,
        organims,
        currentOrganization,
        currentUserRole,
        viewPermission,
        canAssignAppointment,
        concurrency,
    } = useEditRequestSelector();

    const representativePresenceType = representativePresenceTypes.find((fd) => fd.id === headerRequest.representativePresenceTypeId);

    const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === routeFeatures.REQUEST);

    const organism = organims.find((org) => org.code === OrganismTypes.CUSTOMS);
    const inspections = requestInspection.inspections.filter((ins) => ins.organismId === organism?.id);

    const {
        schema,
        showEditRequestComands,
        visibleActa,
        visibleDelete,
        showPrintRequestInspectionCard,
        showPrintRequestValuedDeliveryNote,
        visibleRefresh,
        visibleSave,
    } = useEditRequestLogic(viewRequestPermission, headerRequest);

    const [showDeleteAlertRequest, setShowDeleteRequestAlert] = useState<boolean>(false);
    const [disabledButton, setDisabledButton] = useState(false);

    const [isDirtyForm, setIsDirtyForm] = useState(false);
    const { navigateWithBlock, confirmNavigation, cancelNavigation, showWarningBack } = useNavigationWithBlocker(isDirtyForm);

    const removeIcon: IIconProps = { iconName: NameIcons.REMOVE, style: { width: 25 } };
    const reloadIcon: IIconProps = { iconName: NameIcons.RELOAD, style: { width: 25 } };

    const onReloadRequest = useCallback(() => {
        if (id) {
            dispatch(resetRequestState());
            dispatch(fetchEditRequestData(id));
        }
    }, [dispatch]);

    const pivotStyles = useMemo<Partial<IStyleSet<IPivotStyles>>>(() => getPivotStyles(theme, selectedKey), [theme, selectedKey]);

    const onBackRequestList = useCallback(() => {
        dispatch(setPersistScrollOnDetailsList(true));
        navigateWithBlock(generatePath(backRoute ?? routeUrls.REQUEST, { impersonatedOrganismId }), {});
    }, [navigate, backRoute, impersonatedOrganismId]);

    const onUpdate = useCallback(
        (closeToSave?: () => any) => {
            if (
                (currentUserRole === RoleType.CUSTOMER_ADMIN || currentUserRole === RoleType.CUSTOMER_USER) &&
                currentOrganization === OrganizationTypes.TNF &&
                representativePresenceType?.code === RepresentativePresenceTypes.NO &&
                inspections.length > 0
            ) {
                const error: Error = {
                    message: t("INFO.CUSTOMS_MESSAGE"),
                    name: "",
                };
                return throwErrorToastrAsync(error);
            }

            if (
                currentOrganization === OrganizationTypes.VLC &&
                schema.originSeal.required &&
                headerRequest.seals.filter((seal) => seal.typeId === SealTypes.ORIGIN).length === 0
            ) {
                const error: Error = {
                    message: t("INFO.ORIGIN_SEALS_REQUIRED"),
                    name: "",
                };
                return throwErrorToastrAsync(error);
            }

            setIsDirtyForm(false);
            dispatch(setPersistScrollOnDetailsList(true));
            dispatch(patchRequestData(headerRequest, closeToSave));
        },
        [dispatch, representativePresenceType, inspections],
    );

    const copy = useMemo(() => {
        let tempCopy: IEditRequest = { ...copyHeaderRequest };
        delete tempCopy.showCustomerNotificationMessage;
        return tempCopy;
    }, [copyHeaderRequest]);

    const edit = useMemo(() => {
        let tempEdit: IEditRequest = { ...headerRequest };
        delete tempEdit.showCustomerNotificationMessage;
        return tempEdit;
    }, [headerRequest]);

    useEffect(() => {
        if (visibleSave && !isEqual(copy, edit)) {
            setIsDirtyForm(true);
        } else {
            setIsDirtyForm(false);
        }
    }, [visibleSave, copy, edit]);

    useEffect(() => {
        id && !isLoadedRequest && dispatch(fetchEditRequestData(id));
        return () => {
            dispatch(resetRequestState());
        };
    }, [id, dispatch]);

    useEffect(() => {
        if (headerRequest.fumigated || headerRequest.dryCharge) {
            if (headerRequest.showFumigatedMessage) {
                Toastr("EDIT_REQUEST.INFO.FUMIGATED_MESSAGE", { autoClose: false, type: ToastType.WARNING }).show(`EditRequestContainer`);
            }
        }
        if (headerRequest.showFumigatedMessage === false) {
            Toastr().clear(`EditRequestContainer`);
        }
    }, [headerRequest.showFumigatedMessage, headerRequest.fumigated, headerRequest.dryCharge]);

    useEffect(() => {
        data.length > 0 &&
            dispatch(suplantOrganismUser(undefined, impersonatedOrganismId, showEditRequestComands, () => navigate(routeUrls.REQUEST)));
    }, [impersonatedOrganismId, data, showEditRequestComands, dispatch, navigate]);

    const handleClickCloseRequest = useCallback(() => onBackRequestList(), [onBackRequestList]);

    useEffect(() => {
        !data.length && dispatch(fetchOrganisms());
    }, [data]);

    useEffect(() => {
        if (
            currentOrganization === OrganizationTypes.ALG &&
            schema.appointmentTimeZones.visible &&
            headerRequest.appointmentAssigned &&
            headerRequest.appointment === null &&
            canAssignAppointment
        ) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [headerRequest, currentOrganization, canAssignAppointment]);

    const onAcceptDeleteRequest = () => {
        const requestId = Number(id);
        dispatch(deleteRequest(requestId)).then((response: any) => {
            if (response) {
                onCancelDeleteRequest();
                onBackRequestList();
            }
        });
    };

    const onCancelDeleteRequest = () => {
        setShowDeleteRequestAlert(false);
    };

    return (
        <>
            {isLoadedRequest && (
                <>
                    <NavHeaderComponent
                        backHandleClick={handleClickCloseRequest}
                        title={headerRequest.transportUnitNumber}
                        action={
                            <>
                                <Separator />
                                <StateChanger schema={schema} />
                                <Separator />
                                <StateHistory schema={schema} />
                            </>
                        }
                        endAction={
                            <EndActionButtonsContainer
                                visibleDelete={visibleDelete ? true : false}
                                showPrintRequestValuedDeliveryNote={showPrintRequestValuedDeliveryNote ? true : false}
                                showPrintRequestInspectionCard={showPrintRequestInspectionCard ? true : false}
                                onBackRequestList={onBackRequestList}
                                visibleActa={visibleActa ? true : false}
                                visibleRefresh={visibleRefresh ? true : false}
                                styles={styles}
                                reloadIcon={reloadIcon}
                                removeIcon={removeIcon}
                                onReloadRequest={onReloadRequest}
                                hasInvoiceConcepts={headerRequest.hasInvoiceConcepts}
                            />
                        }
                    />
                    <div className={styles.container}>
                        <Pivot
                            styles={pivotStyles}
                            selectedKey={String(selectedKey)}
                            onLinkClick={(event) => {
                                setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                            }}
                        >
                            {(schema.uti.visible || schema.goodInformation.visible || schema.certificate.visible || schema.inspection.visible) && (
                                <PivotItem
                                    headerText={t("TITLES.GENERAL")}
                                    itemKey="0"
                                    alwaysRender={true}
                                >
                                    {
                                        <GeneralInformationEditContainer
                                            currentRole={currentUserRole}
                                            schema={schema}
                                            onUpdate={onUpdate}
                                        />
                                    }
                                </PivotItem>
                            )}

                            {schema.vuaNumber.visible && headerRequest.vuaNumber && (
                                <PivotItem
                                    headerText={t("TITLES.VUA")}
                                    itemKey="1"
                                >
                                    {<EditRequestTabsVUAContainer />}
                                </PivotItem>
                            )}

                            {schema.document.visible && (
                                <PivotItem
                                    headerText={t("TITLES.DOCUMENTS")}
                                    itemKey="2"
                                    alwaysRender={true}
                                >
                                    <EditRequestTabDocumentsContainer schema={schema} />
                                </PivotItem>
                            )}

                            {schema.incidence.visible && (
                                <PivotItem
                                    headerText={t("TITLES.INCIDENCE")}
                                    itemKey="3"
                                >
                                    {<EditRequestTabsIncidenceContainer schema={schema} />}
                                </PivotItem>
                            )}

                            {(schema.invoiceConceptAdmin.visible || (headerRequest.invoiced && schema.invoiceConcept.visible)) && (
                                <PivotItem
                                    headerText={t("TITLES.BILLING")}
                                    itemKey="4"
                                >
                                    {<EditRequestTabsInvoiceConceptContainer schema={schema} />}
                                </PivotItem>
                            )}
                            {schema.history.visible && (
                                <PivotItem
                                    headerText={t("TITLES.HISTORY")}
                                    itemKey="5"
                                >
                                    {<EditRequestHistoryTimeLineContainer />}
                                </PivotItem>
                            )}
                        </Pivot>
                    </div>
                    <div className={styles.actionButtons}>
                        <div className={styles.disclaimer}>
                            <RequiredFieldsMessage />
                        </div>
                        <div>
                            <ButtonPrimaryInverseWithBorder
                                title={t("BUTTONS.CLOSE")}
                                handleClick={handleClickCloseRequest}
                            />
                            {visibleSave && (
                                <ButtonPrimary
                                    title={t("BUTTONS.SAVE")}
                                    disabled={disabledButton}
                                    handleClick={() => {
                                        onUpdate();
                                    }}
                                />
                            )}
                            {visibleSave && (
                                <ButtonPrimary
                                    title={t("BUTTONS.CLOSE_SAVE")}
                                    disabled={disabledButton}
                                    handleClick={() => {
                                        onUpdate(onBackRequestList);
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <PopUpConfirmation
                        showPopUp={showDeleteAlertRequest}
                        onCancel={onCancelDeleteRequest}
                        onAccept={onAcceptDeleteRequest}
                        type={PopUpConfirmationType.DELETE}
                        width={"30vw"}
                        height={"auto"}
                        message={t("INFO.REQUEST_DELETE")}
                    />

                    <PopUpConfirmation
                        showPopUp={concurrency.isError}
                        onCancel={() => null}
                        onAccept={onReloadRequest}
                        type={PopUpConfirmationType.WARNING}
                        width={"30vw"}
                        height={"auto"}
                        message={concurrency.message}
                        hiddeCancelButon={true}
                        hiddeCloseButon={true}
                        buttonAcceptTitle={t("BUTTONS.RELOAD_REQUEST")}
                    />

                    <PopUpBlocker
                        stayOn={cancelNavigation}
                        exitTo={confirmNavigation}
                        showPopUp={showWarningBack}
                    />
                    <EditRequestCustomerNotificationController />
                </>
            )}
        </>
    );
};
