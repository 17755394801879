import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IStatisticsOperatorItem, IStatisticsOperatorIncidenceState } from "../../../../models/statistics/IOperator";
import { sortArray } from "../../../../utils";

interface IInitialState {
    data: IStatisticsOperatorIncidenceState;
}
const initialSateData = (): IStatisticsOperatorIncidenceState => {
    return {
        loading: false,
        operatorStatistics: [],
        sort: emptySortState(),
        totalIncidences: 0,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsOperatorsIncidenceListSlice = createSlice({
    name: "statiticsOperatorsIncidenceListSlice",
    initialState,
    reducers: {
        resetStatisticsIncidenceOperator: () => initialState,
        setList: (state, action: PayloadAction<IStatisticsOperatorItem[]>) => {
            state.data.operatorStatistics = action.payload;
        },

        setStatisticsIncidenceOperatorLoading: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
        setStatisticsIncidenceOperatorData: (state, action: PayloadAction<IStatisticsOperatorIncidenceState>) => {
            state.data = action.payload;
            state.data.sort = {
                column: "",
                orderColumn: "",
            };
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.data.sort = action.payload;
            state.data.operatorStatistics = sortArray(state.data.operatorStatistics, state.data.sort);
        },
    },
});

export const { resetStatisticsIncidenceOperator, setList, setStatisticsIncidenceOperatorLoading, setStatisticsIncidenceOperatorData, updateSortColumn } =
    statiticsOperatorsIncidenceListSlice.actions;

export default statiticsOperatorsIncidenceListSlice.reducer;
