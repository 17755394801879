import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { IBoardHistoryAppointmentTypeConfiguration } from "../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch } from "../../../../../../../../../redux/hooks";
import { setShowPopUpMasterAppointmentTypeConfiguration } from "../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentTypeConfiguration/form";
import { ButtonPrimaryInverseWithBorder } from "../../../../../../../../common/buttons/buttons";
import { TitleH5 } from "../../../../../../../../common/titles/titles";
import { MasterAppointmentTypeConfigurationContainerStyle } from "./containerStyle.jss";
import { MasterBoardHistoryAppointmentDockControllerForm } from "./controller/controller";
import { MasterBoardHistoryAppointmentTypeConfigurationList } from "./list/list";
import { ColorType } from "../../../../../../../../../common/enum/ColorType";
import { PopUpCodes } from "../../../../../../../../../common/enum/PopUpCodes";

interface IMasterAppointmentDockControllerProps {
    list: IBoardHistoryAppointmentTypeConfiguration[];
}

export const AppointmentTypeConfigurationContainer: React.FC<IMasterAppointmentDockControllerProps> = ({ list }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.TITLES" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = MasterAppointmentTypeConfigurationContainerStyle({ theme });
    return (
        <div className={style.container}>
            <div className={style.header}>
                <TitleH5
                    title={t("APPOINTMENT_TYPE_CONFIGURATION")}
                    bold={true}
                    color={ColorType.primary}
                />
                <ButtonPrimaryInverseWithBorder
                    handleClick={() => {
                        dispatch(setShowPopUpMasterAppointmentTypeConfiguration(PopUpCodes.CREATE));
                    }}
                    title={`+ ${t("ADD")}`}
                />
            </div>

            <MasterBoardHistoryAppointmentTypeConfigurationList list={list} />
            <MasterBoardHistoryAppointmentDockControllerForm />
        </div>
    );
};
