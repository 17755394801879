import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterSelTypesControllerForm } from "../controller/controller";
import { MasterSealTypesColumns } from "./column/column";
import { fetchMasterSealTypesList } from "../../../../../../redux/actions/resources/master/sealtypes/sealTypes";
 interface IMasterSealTypesList {
    widthPopUp:any;
    heigthPopUp:any;
 }
export const MasterSealTypesList:React.FC<IMasterSealTypesList> = ({heigthPopUp, widthPopUp}) => {
    const { loading, list } = useAppSelector((state) => state.masterSealTypesList);
    const dispatch = useAppDispatch();
    
    const renderColumns = () => {
        return MasterSealTypesColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterSealTypesList());
    }, [list]);

    return loading ? (
        <Skeleton rows={5}></Skeleton>
    ) : (
        <>
            <CustomDetailsList
                data={list ?? []}
                renderColumns={renderColumns}
            />
            <MasterSelTypesControllerForm widthPopUp={widthPopUp} heigthPopUp={heigthPopUp} />
        </>
    );
};
