import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterRequestStatesFormController } from "../controller/controller";
import { MasterRequestStatesColumns } from "./column/column";
import { SkeletonSizes } from "../../../../../../common/enum/sizes";
import { fetchMasterRequestStatesList } from "../../../../../../redux/actions/resources/master/requestStates/requestStates";
interface IMasterRequestStatesList {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterRequestStatesList: React.FC<IMasterRequestStatesList> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { loading, list } = useAppSelector((state) => state.masterRequestStatesList);

    const renderColumns = () => {
        return MasterRequestStatesColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterRequestStatesList());
    }, [list]);
    return (
        <div style={{ height: "100%" }}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <CustomDetailsList
                    data={list ?? []}
                    renderColumns={renderColumns}
                    height={"calc(100vh - 9rem)"}
                ></CustomDetailsList>
            )}
            <MasterRequestStatesFormController
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </div>
    );
};
