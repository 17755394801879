import { createUseStyles } from "react-jss";

export const UpcomingAppointmentsListStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 20.92rem)",
        overflowY: "auto",
        alignItems: "center",
    }, hourCell : {
        width: "15% !important",
    }, 
    space: {
        width: "12% !important",
    },
}));
