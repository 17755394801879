import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { fetchRequestPendingAssignmentListAction } from "../../redux/actions/assignment/assignment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { NavHeaderComponent } from "../common/navHeader/navHeader";
import { Skeleton } from "../common/skeleton/skeleton";
import { RequestPendingAssignmentFilter } from "./filter/filter";
import { RequestpendingAssinmentList } from "./list/list";
import { RequestPendingAssigmentContainerStyle } from "./containerStyle.jss";
import { SkeletonSizes } from "../../common/enum/sizes";

export const RequestPendingAssignmentContainer = () => {
    const theme = useTheme();
    const style = RequestPendingAssigmentContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "ASSIGNMENT" });
    const { data, loading } = useAppSelector((store) => store.requestPendingAssignmentList);

    useEffect(() => {
        data === undefined && dispatch(fetchRequestPendingAssignmentListAction());
    }, [data]);

    return (
        <>
            <NavHeaderComponent title={t("TITLE")} />
            <RequestPendingAssignmentFilter></RequestPendingAssignmentFilter>
            <div className={style.containerList}>
                {loading ? (
                    <Skeleton rows={SkeletonSizes.DEFAULT} />
                ) : (
                    <>
                        <RequestpendingAssinmentList
                            code={t("LIST.PONA")}
                            list={data?.vegetalAssignations ?? []}
                        ></RequestpendingAssinmentList>
                        <RequestpendingAssinmentList
                            code={t("LIST.POA")}
                            list={data?.animalAssignations ?? []}
                        ></RequestpendingAssinmentList>
                    </>
                )}
            </div>
        </>
    );
};
