import { useTheme } from "react-jss";
import { IAppointmentBoard } from "../../../../models/appointment/IBoard";
import { generateRandomKeys } from "../../../../utils/keys";
import { TitleH3 } from "../../../common/titles/titles";
import { AppointmentBoardContainerStyle } from "../containerStyle.jss";
import { CellBoardComponent } from "./cell";

interface GridBoardProps {
    data: IAppointmentBoard;
}

export const GridBoardComponent = ({ data }: GridBoardProps) => {
    const theme = useTheme();
    const styles = AppointmentBoardContainerStyle({ theme });
    return (
        <>
            <TitleH3 title={data.merchandiseCategoryGroupDescription} />
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th></th>
                            {data.appointmentDocks.map((d, index) => (
                                <th key={`th-${index}`}>{d.description}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <CellBoardComponent
                            key={generateRandomKeys()}
                            appointmentDocks={data.appointmentDocks}
                            appointmentTimeZones={data.appointmentTimeZones}
                            appointments={data.appointments}
                            locks={data.locks}
                        />
                    </tbody>
                </table>
            </div>
        </>
    );
};
