import React, { useCallback } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IGenericPayload } from "../../../../models";
import { IUserSignupConfirmation } from "../../../../models/user/IUserSignupConfirmation";
import { updateUserSignupConfirmationPropertyAction } from "../../../../redux/actions/user/confirmation/confirmation";
import { useAppDispatch } from "../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../utils";
import { Checkboxs } from "../../../common/checkbox/checkbox";
import { FormField } from "../../../common/fields/fields";

interface IProfileFormProps {
    data: IUserSignupConfirmation;
    register: UseFormRegister<IUserSignupConfirmation>;
    errors: FieldErrors<IUserSignupConfirmation>;
}

export const ConfirmationForm: React.FC<IProfileFormProps> = ({ data, register, errors }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateUserSignupConfirmationPropertyAction(payload));
        },
        [dispatch],
    );

    const onCheckboxChange = useCallback(
        (event?: any, checked = false) => {
            const payload: IGenericPayload = {
                name: event.target.name,
                value: checked,
            };

            dispatch(updateUserSignupConfirmationPropertyAction(payload));
        },
        [dispatch],
    );

    return (
        <>
            <div>
                <Checkboxs
                    label={t("USER.CONFIRMATION.ENABLE_REPRESENTATIVE")}
                    name={compileNameOfProperty<IUserSignupConfirmation>("hasRepresentativeData")}
                    isChecked={data.hasRepresentativeData}
                    handleChange={onCheckboxChange}
                    defaultWidth={true}
                />
            </div>

            <div>
                <FormField
                    type="text"
                    label={t("USER.CONFIRMATION.CRMCODE")}
                    value={data.crmCode}
                    error={errors.crmCode}
                    {...register(compileNameOfProperty<IUserSignupConfirmation>("crmCode"), { onChange: onInputChange, value: data.crmCode })}
                    isRequired={true}
                />
            </div>

            <div>
                <FormField
                    type="text"
                    value={data.externalCode}
                    error={errors.externalCode}
                    label={t("USER.CONFIRMATION.EXTERNALCODE")}
                    {...register(compileNameOfProperty<IUserSignupConfirmation>("externalCode"), {
                        onChange: onInputChange,
                        value: data.externalCode,
                    })}
                />
            </div>
        </>
    );
};
