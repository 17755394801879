import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentBoard } from "../../../models/appointment/IBoard";

export interface IAppointmentsBoardConfig {
    list: IAppointmentBoard[];
    date: string;
    loading: boolean;
}

const initialState: IAppointmentsBoardConfig = {
    list: [],
    date: new Date().toISOString(),
    loading: false,
};

export const appointmentBoardGrid = createSlice({
    name: "appointmentBoardGrid",
    initialState,
    reducers: {
        resetBoard: () => initialState,
        setLoadingAppointmentBoard: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setAppointmentBoard: (state, action: PayloadAction<IAppointmentBoard[]>) => {
            state.list = action.payload;
        },
        setAppointmentBoardDate: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
        },
    },
});

export const { resetBoard, setLoadingAppointmentBoard, setAppointmentBoard, setAppointmentBoardDate } = appointmentBoardGrid.actions;

export default appointmentBoardGrid.reducer;
