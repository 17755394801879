import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterBlockForm } from "./form/form";
import { resetMasterBlockForm } from "../../../../../../redux/reducers/resources/master/block/form";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { postAndPutMasterBlock } from "../../../../../../redux/actions/resources/master/block/block";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterBlockMasterControllerProps {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterBlockMasterController: React.FC<IMasterBlockMasterControllerProps> = ({ widthPopUp, heigthPopUp }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useDispatch();

    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { loading, form } = useAppSelector((state) => state.masterBlockForm);

    const onAcceptPopUp = () => {
        dispatch(postAndPutMasterBlock()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };

    const onDimissed = () => {
        dispatch(resetMasterBlockForm());
    };
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.BLOCK");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.BLOCK");
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, false);
    }, [form]);

    return (
        <PopUpForm
            content={<MasterBlockForm />}
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
