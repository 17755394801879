import { useMediaQuery } from "react-responsive";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetManagementSummaryForm, setShowManagementSummaryPopUp } from "../../../../redux/reducers/management/summary/form";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ManagementSummaryForm } from "./form/form";
import { componentsMediaQueries } from "../../../../common/constants";
import { PopUpConfirmation } from "../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { deleteManagementSummaryAction, postManagementSummaryAction } from "../../../../redux/actions/management/summary/summary";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";

export const ManagementSummaryFormController = () => {
    const { form, loading, showPopUp } = useAppSelector((state) => state.managementSummaryForm);
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.SUMMARY" });

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE) {
            dispatch(postManagementSummaryAction()).then((response: any) => {
                response && onCancelPopUp();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(deleteManagementSummaryAction()).then((response: any) => {
                response && onCancelPopUp();
            });
        }
    };

    const onCancelPopUp = () => {
        dispatch(setShowManagementSummaryPopUp(PopUpCodes.NONE));
    };

    const onDimissed = () => {
        dispatch(resetManagementSummaryForm());
    };

    const isDisabledButton = useMemo(() => {
        return !form.date || !form.hour || !form.summaryNumber;
    }, [form]);
    return (
        <>
            <PopUpForm
                content={<ManagementSummaryForm />}
                title={t("CREATE")}
                isVisible={showPopUp === PopUpCodes.CREATE}
                onCancel={onCancelPopUp}
                onSave={onSave}
                isLoading={loading}
                isDisabledButton={isDisabledButton}
                height={isMobile ? "30vh" : "25vh"}
                width={"25vw"}
                isBlocking={false}
                onDimissed={onDimissed}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                onDimissed={onDimissed}
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancelPopUp}
                onAccept={onSave}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
                width={"25vw"}
                loading={loading}
                disabledActions={loading}
            />
        </>
    );
};
