import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTheme } from "react-jss";
import { IUserProfile } from "../../../../models";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { UserProfileDataForm } from "./form/form";
import { UserProfileUserContainerStyle } from "./profileStyle.jss";
import { SkeletonSizes } from "../../../../common/constants";
interface IUserProfileDataContainerProps {
    loading: boolean;
    userRegister: UseFormRegister<IUserProfile>;
    userErrors: FieldErrors<IUserProfile>;
    userData: IUserProfile;
}

export const UserProfileDataContainer: React.FC<IUserProfileDataContainerProps> = ({ loading, userRegister, userErrors, userData }) => {
    const theme = useTheme();
    const style = UserProfileUserContainerStyle({ theme });

    return (
        <div className={style.container}>
            {loading ? (
                <Skeleton rows={SkeletonSizes.DEFAULT} />
            ) : (
                <>
                    <UserProfileDataForm
                        userErrors={userErrors}
                        userRegister={userRegister}
                        userData={userData}
                    />
                </>
            )}
        </div>
    );
};
