import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchMerchandiseCategoriesTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { CustomSelector, ISelectorProps } from "../../../../common/selectors/customSelector/customSelector";

export const CustomMerchandiseCategoryIdSelector: React.FC<ISelectorProps> = ({ onChange, selectedKey,  name, title, disabled }) => {
    const dispatch = useDispatch();

    const { data, loading } = useAppSelector((store) => store.catalog.merchandiseCategories);

    useEffect(() => {
        !data.length && !loading && dispatch(fetchMerchandiseCategoriesTypes());
    }, []);

    return (
        <CustomSelector
            title={title}
            options={mapIDescriptionToDropDownOptions(data)}
            onChange={onChange}
            selectedKey={selectedKey}
            name={name}
            disabled={disabled}
        ></CustomSelector>
    );
};
