import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SkeletonSizes } from "../../../../common/constants";
import { IPagination } from "../../../../models/common/IPagination";
import { fetchManagementUserListAction, setPageAction } from "../../../../redux/actions/management/user/user";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getUserClaimsLoaded } from "../../../../redux/selectors/user/claims/claims";
import { heights } from "../../../../styles/romeu/heights";
import { sortAndPaginateArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementUserListColumn } from "./column/column";
import { RoleType } from "../../../../common/enum";

export const ManagementUserList = () => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);
    const { list, sort, pagination, loading } = useAppSelector((state) => state.managementUserList);
    const currentUserRole = useAppSelector((store) => store.role.roleData.id);
    const isSuperAdmin = currentUserRole === RoleType.SUPER_ADMIN;

    useEffect(() => {
        isUserClaimsLoaded && !loading && dispatch(fetchManagementUserListAction());
    }, [dispatch, isUserClaimsLoaded]);

    const currentList = sortAndPaginateArray(list, sort, pagination);

    const onPageUp = useCallback(
        (skip: number) => {
            const currentPage: number = skip + 1;
            if (currentPage <= pagination.totalPages) {
                dispatch(setPageAction(currentPage));
            }
        },
        [pagination.totalPages],
    );

    const onFirstPage = useCallback((skip: number) => {
        if (skip !== 1) {
            dispatch(setPageAction(1));
        }
    }, []);

    const onPageDown = useCallback((skip: number) => {
        const currentPage: number = skip - 1;
        if (currentPage >= 1) {
            dispatch(setPageAction(currentPage));
        }
    }, []);

    const onLastPage = useCallback(
        (skip: number) => {
            if (skip !== pagination.totalPages) {
                dispatch(setPageAction(pagination.totalPages));
            }
        },
        [pagination.totalPages],
    );

    const renderColumns = () => {
        return ManagementUserListColumn();
    };

    const paginationProps: IPagination = {
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
        firstItemNumber: pagination.firstItemNumber,
        lastItemNumber: pagination.lastItemNumber,
        footerTitle: t("MANAGEMENT.USER.PAGINATION_FOOTER", {
            firstItemNumber: pagination.firstItemNumber,
            lastItemNumber: pagination.lastItemNumber,
            totalItems: pagination.totalItems,
        }),
        onFirstPage,
        onLastPage,
        onPageDown,
        onPageUp,
    };

    return (
        <>
            {loading || !isUserClaimsLoaded ? (
                <Skeleton rows={SkeletonSizes.MANAGEMENT_USER} />
            ) : (
                <CustomDetailsList
                    data={currentList}
                    pagination={paginationProps}
                    renderColumns={renderColumns}
                    height={isSuperAdmin ? heights.management.user.superAdminTable : heights.management.user.defaultTable}
                />
            )}
        </>
    );
};
