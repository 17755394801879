import { formatDate, stringDefaultValue, throwErrorToastrAsync } from "../../../../utils";
import { AppThunk, store } from "../../../store";
import { IManagementNotificationFilter } from "../../../../models/management/notification/IManagementNotificationFilter";
import {
    setLoadingManagementNotificationForm,
    setManagementNotificationForm,
    setManagementnotificationFormPopUp,
} from "../../../reducers/management/notification/form";
import { setLoadingManagementNotificationList, fetchManagementNotificationList, setPage } from "../../../reducers/management/notification/list";
import { ManagementNotificationApi } from "../../../../api/management/notification";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";

const managementNotificationApi = new ManagementNotificationApi();

const getManagementNotificationFilter = (): IManagementNotificationFilter => {
    const { filter } = store.getState().managementNotificationFilter;

    return {
        ...filter,
        roleId: filter.roleId !== stringDefaultValue.key ? filter.roleId : "",
        notificationTypeId: filter.notificationTypeId !== stringDefaultValue.key ? filter.notificationTypeId : "",
    };
};

export const fetchManagementNotificationListAction =
    (resetPagination: boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementNotificationList(true));
            const filter = getManagementNotificationFilter();
            const response = await managementNotificationApi.getNotificationListAsync(filter);
            dispatch(fetchManagementNotificationList(response));
            if (resetPagination) {
                dispatch(setPage(1));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementNotificationList(false));
        }
    };

export const setPageAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        dispatch(setPage(page));
    };

export const postAndPutManagementNotificationAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().managementNotificationForm;
            let customForm = {
                ...form,
                defaultScheduleTime: formatDate(form.defaultScheduleTime, FormatDate.HOUR_MINUTE),
            };
            dispatch(setLoadingManagementNotificationForm(true));
            if (form.id) {
                await managementNotificationApi.updateNotificationAsync(customForm);
            } else {
                await managementNotificationApi.createNotificationAsync(customForm);
            }
            dispatch(fetchManagementNotificationListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingManagementNotificationForm(false));
        }
    };
};

export const getMananagementNotificationAction =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementNotificationForm(true));
            const response = await managementNotificationApi.getNotificationAsync(id);
            dispatch(setManagementNotificationForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementNotificationForm(false));
        }
    };

export const deleteNotificationAction = (): any => {
    return async (dispatch: any) => {
        try {
            const { id } = store.getState().managementNotificationForm.form;

            dispatch(setLoadingManagementNotificationForm(true));
            await managementNotificationApi.deleteNotification(id ?? 0);
            dispatch(setManagementnotificationFormPopUp(PopUpCodes.NONE));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(fetchManagementNotificationListAction());
            dispatch(setLoadingManagementNotificationForm(false));
        }
    };
};
