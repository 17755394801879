import { AvatarStyle } from "./avatar.jss";
import profileImage from "../../../assets/images/profile-generic.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileSize } from "../../../common/enum/files/FileSize";
import { ContentType } from "../../../common/enum/files/ContentType";

interface IAvatarProps {
    size: string;
    name?: string;
    src?: string;
    onChangeAvatar?: (file: File | undefined) => void;
    edit?: boolean;
    maxSize?: FileSize;
}

export const Avatar: React.FC<IAvatarProps> = ({ name, size, src, onChangeAvatar, edit, maxSize = FileSize._1MB }) => {
    const firstUpdate = React.useRef(true);
    const allowedExtensions: ContentType[] = [ContentType.JPEG, ContentType.JPG, ContentType.PNG];
    const image = src ? src : profileImage;
    const style = AvatarStyle({
        size: size,
        edit: edit,
    });
    const { t } = useTranslation("messages");

    const [selectedImage, setImage] = useState<File>();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const inputFileRef = React.useRef<HTMLInputElement>(null);

    const clickInputFile = () => {
        inputFileRef.current?.click();
    };

    const onUploadAvatar = (event: any) => {
        const file = event.target.files[0] as File;
        const isValid = isImageValid(file);
        if (isValid) {
            setErrorMessage("");
            setImage(file);
        } else {
            setImage(undefined);
        }
    };
    const isImageValid = (file: File): boolean => {
        if (!file.size) {
            setErrorMessage(t("INPUT_FILE_ERRORS.NO_CONTENT"));
            return false;
        }
        if (file.size > maxSize) {
            setErrorMessage(t("INPUT_FILE_ERRORS.MAX_SIZE_EXCEED"));
            return false;
        }
        if (allowedExtensions && !allowedExtensions?.includes(file.type as ContentType)) {
            setErrorMessage(t("INPUT_FILE_ERRORS.CONTENT_TYPE_INVALID"));
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else if (onChangeAvatar) {
            onChangeAvatar(selectedImage);
        }
    }, [selectedImage]);

    return (
        <div
            className={style.content}
            onClick={clickInputFile}
        >
            <img
                className={style.avatar}
                src={image}
                alt={name}
            />
            {edit && (
                <>
                    <input
                        type="file"
                        className={style.input}
                        accept={String(allowedExtensions)}
                        ref={inputFileRef}
                        onChange={onUploadAvatar}
                    />
                    <div className={style.overlay}>+</div>
                    <div className={style.errorContainer}>{errorMessage}</div>
                </>
            )}
        </div>
    );
};
