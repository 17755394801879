import { ActionButton, IIconProps } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { PopUpConfirmationType } from "../../../../../common/enum/PopUpConfirmationType";
import { IUserOrganization } from "../../../../../models";
import {
    deleteRequest,
    fetchRequestInspectionCard,
    getRequestInspectionCardLocks,
} from "../../../../../redux/actions/request/edit/action/requestEdit";
import { getUserOrganization } from "../../../../../redux/selectors/user/organization/organization";
import { ButtonPrimary } from "../../../../common/buttons/buttons";
import { PopUpConfirmation } from "../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { Separator } from "../../../../common/separator/separator";
import { PrintRequest } from "./printRequest/PrintRequest";

type EndActionButtonsContainerProps = {
    showPrintRequestValuedDeliveryNote: boolean;
    showPrintRequestInspectionCard: boolean;
    visibleActa: boolean;
    visibleRefresh: boolean;
    visibleDelete: boolean;
    onReloadRequest: () => void;
    removeIcon: IIconProps;
    reloadIcon: IIconProps;
    styles: any;
    onBackRequestList: () => void;
    hasInvoiceConcepts: boolean;
};
export const EndActionButtonsContainer: React.FC<EndActionButtonsContainerProps> = ({
    showPrintRequestValuedDeliveryNote,
    showPrintRequestInspectionCard,
    visibleActa,
    visibleRefresh,
    visibleDelete,
    onReloadRequest,
    removeIcon,
    reloadIcon,
    styles,
    onBackRequestList,
    hasInvoiceConcepts,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const { id } = useParams();
    const [showDeleteAlertRequest, setShowDeleteRequestAlert] = useState<boolean>(false);
    const [showLockActa, setShowLockActa] = useState<boolean>(false);
    const [showMessageLockActa, setShowMessageLockActa] = useState<string>("");
    const userOrganization: IUserOrganization = useSelector(getUserOrganization);

    return (
        <>
            {(showPrintRequestValuedDeliveryNote || showPrintRequestInspectionCard) && (
                <>
                    <PrintRequest
                        showValuedDeliveryNote={showPrintRequestValuedDeliveryNote}
                        showInspectionCard={showPrintRequestInspectionCard}
                    />
                    {(visibleActa || visibleRefresh || visibleDelete) && <Separator />}
                </>
            )}
            {visibleActa && (
                <>
                    <ButtonPrimary
                        title={t("TITLES.ACTA")}
                        handleClick={async () => {
                            const response = await getRequestInspectionCardLocks(id);
                            if (response.isLock) {
                                setShowLockActa(true);
                                setShowMessageLockActa(response.message);
                            } else {
                                dispatch(fetchRequestInspectionCard(id, userOrganization.id));
                            }
                        }}
                        className={styles.actaButton}
                        icon="Link"
                    />
                    <PopUpConfirmation
                        showPopUp={showLockActa}
                        onCancel={() => {
                            setShowLockActa(false);
                        }}
                        onAccept={() => {
                            dispatch(fetchRequestInspectionCard(id, userOrganization.id));
                            setShowLockActa(false);
                        }}
                        type={PopUpConfirmationType.WARNING}
                        width={"30vw"}
                        height={"auto"}
                        message={showMessageLockActa}
                    />
                    {(visibleRefresh || visibleDelete) && <Separator />}
                </>
            )}
            {visibleRefresh && (
                <>
                    <ActionButton
                        iconProps={reloadIcon}
                        onClick={onReloadRequest}
                        allowDisabledFocus
                    />
                    {visibleDelete && <Separator />}
                </>
            )}

            {visibleDelete && (
                <>
                    <ActionButton
                        onClick={() => setShowDeleteRequestAlert(true)}
                        iconProps={removeIcon}
                        allowDisabledFocus
                    />

                    <PopUpConfirmation
                        showPopUp={showDeleteAlertRequest}
                        onCancel={() => {
                            setShowDeleteRequestAlert(false);
                        }}
                        onAccept={() => {
                            const requestId = Number(id);
                            dispatch(deleteRequest(requestId)).then((response: any) => {
                                if (response) {
                                    setShowDeleteRequestAlert(false);
                                    onBackRequestList();
                                }
                            });
                        }}
                        type={PopUpConfirmationType.DELETE}
                        width={"30vw"}
                        height={"auto"}
                        message={hasInvoiceConcepts ? t("INFO.REQUEST_DELETE_HAS_INVOICED_CONCEPT") : t("INFO.REQUEST_DELETE")}
                    />
                </>
            )}
        </>
    );
};
