import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISortState, emptySortState } from "../../../../models/common/ISortState";
import { IManagementAuthorizedTransportUnitNumber } from "../../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";

interface ManagementAuthorizedTransportUnitNumberListState {
    loading: boolean;
    list?: IManagementAuthorizedTransportUnitNumber[];
    sort: ISortState;
}

const initialState: ManagementAuthorizedTransportUnitNumberListState = {    
    sort: emptySortState(),
    loading: false,
};

export const managementAuthorizedTransportUnitNumberListSlice = createSlice({
    name: "managementAuthorizedTransportUnitNumberList",
    initialState,
    reducers: {
        resetManagementAuthorizedTransportUnitNumberList: () => initialState,

        setLoadingManagementAuthorizedTransportUnitNumberList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementAuthorizedTransportUnitNumberList: (state, action: PayloadAction<IManagementAuthorizedTransportUnitNumber[]>) => {
            state.list = action.payload;
           
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
       
    },
});

export const { resetManagementAuthorizedTransportUnitNumberList, setLoadingManagementAuthorizedTransportUnitNumberList, fetchManagementAuthorizedTransportUnitNumberList, updateSortColumn } =
    managementAuthorizedTransportUnitNumberListSlice.actions;

export default managementAuthorizedTransportUnitNumberListSlice.reducer;
