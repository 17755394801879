import { IRole } from "../../../../models/common/IRole";
import { resetUserClaims } from "../../../reducers/user/claims/claims";
import { setRole } from "../../../reducers/user/role/role";
import { setPrincipalRole } from "../../../reducers/userData/userData";
import { AppDispatch, AppThunk } from "../../../store";

export const setUserRole =
    (role: IRole): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(setRole(role));
        dispatch(setPrincipalRole(role.id));
        dispatch(resetUserClaims());
};
