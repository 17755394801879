import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEmailItem, IEmailItemWithIndex } from "../../../../models";

interface RegisterUsersState {
    registeredMails: IEmailItem[];
}

const initialState: RegisterUsersState = {
    registeredMails: [
        {
            email: "",
            isValid: false,
        },
    ],
};

export const registerUsersSlice = createSlice({
    name: "registerUsersReducer",
    initialState,
    reducers: {
        resetRegiteredEmailList: () => initialState,
        addRegisterEmailItem: (state) => {
            state.registeredMails.push({
                email: "",
                isValid: false,
            });
        },
        removeRegisterEmailItem: (state, action: PayloadAction<number>) => {
            state.registeredMails.splice(action.payload, 1);
        },
        updateRegisterEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.registeredMails[action.payload.index].email = action.payload.email;
        },
        updateRegisterEmailItemIsValid: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.registeredMails[action.payload.index].isValid = action.payload.isValid;
        },
    },
});

export const { resetRegiteredEmailList, addRegisterEmailItem, removeRegisterEmailItem, updateRegisterEmailItem, updateRegisterEmailItemIsValid } =
    registerUsersSlice.actions;

export default registerUsersSlice.reducer;
