import { Icon } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import logo from "../../assets/images/logo-min.png";
import { componentsMediaQueries } from "../../common/constants";
import { IFoldedMenu } from "../../models/common/IFoldedMenu";
import { IUserModuleData } from "../../models/user/IUserModuleData";
import { fetchUserModules } from "../../redux/actions/user/module/module";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setFixedSiteNav, setShowSiteNav } from "../../redux/reducers/webConfig/globalConfig";
import { getUserClaimsLoaded } from "../../redux/selectors/user/claims/claims";
import { getUserModules, loadingUserModules } from "../../redux/selectors/user/module/module";
import { LocalStorageKey, LocalStorageService } from "../../services/localStorageService";
import { mapModuleToFoldedMenu } from "../../utils";
import { Skeleton } from "../common/skeleton/skeleton";
import { SiteNavStyles } from "./siteNav.jss";
import { SiteNavGroup } from "./siteNavGroup/siteNavGroup";

export const SiteNav = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const styles = SiteNavStyles({ theme });
    const localStorageService = new LocalStorageService();
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);
    const userModules: IUserModuleData[] = useSelector(getUserModules);
    const loading: boolean = useSelector(loadingUserModules);
    const navMenu = React.useRef<HTMLInputElement>(null);
    const sideBar = React.useRef<HTMLInputElement>(null);
    const isMobile = useMediaQuery({ query: componentsMediaQueries.TABLET }); 

    const { showSiteNav } = useAppSelector((state) => state.globalConfig);

    const [isColapsed, setColapsed] = useState<boolean>(localStorageService.getItem(LocalStorageKey.NAV_MENU_COLAPSED) ?? false);
    const [isFixedBar, setFixed] = useState<boolean>(localStorageService.getItem(LocalStorageKey.NAV_MENU_FIXED) ?? false);
    const [foldStatus, setFoldStatus] = useState<IFoldedMenu[]>(localStorageService.getItem(LocalStorageKey.NAV_MENU_FOLDED) ?? []);

    useEffect(() => {
        if (isMobile) {
            setColapsed(false);
            setFixed(false);
        }
    }, [isMobile]);

    useEffect(() => {
        isColapsed && localStorageService.setItem(LocalStorageKey.NAV_MENU_COLAPSED, false);
    }, []);

    useEffect(() => {
        dispatch(setFixedSiteNav(isFixedBar));
    }, [isFixedBar]);

    useEffect(() => {
        setColapsed(showSiteNav);
    }, [showSiteNav]);

    useEffect(() => {
        if (!isColapsed) {
            dispatch(setShowSiteNav(isColapsed));
        }
    }, [isColapsed]);

    useEffect(() => {
        if (isUserClaimsLoaded && !userModules.length) {
            dispatch(fetchUserModules());
        }
    }, [isUserClaimsLoaded, userModules]);



    useEffect(() => {
        if (userModules && userModules.length > 0 && !foldStatus.length) {
            setFoldStatus(mapModuleToFoldedMenu(userModules));
        }
    }, [userModules]);

    useEffect(() => {
        if (navMenu.current && !loading) {
            navMenu.current?.scrollTo(0, parseInt(localStorageService.getItem(LocalStorageKey.NAV_MENU_SCROLL_TOP) ?? 0));
        }
    }, [navMenu.current, loading]);

    const onClickEvent = () => {
        if (isMobile) {
            setColapsed(false);
            localStorageService.setItem(LocalStorageKey.NAV_MENU_COLAPSED, false);
        } else {
            localStorageService.setItem(LocalStorageKey.NAV_MENU_COLAPSED, true);
        }
        const scrollTop = navMenu.current!.scrollTop;
        localStorageService.setItem(LocalStorageKey.NAV_MENU_SCROLL_TOP, scrollTop);
    };

    const onSetFixedSideBar = () => {
        setFixed(!isFixedBar);
        localStorageService.setItem(LocalStorageKey.NAV_MENU_FIXED, !isFixedBar);
        localStorageService.setItem(LocalStorageKey.NAV_MENU_COLAPSED, false);
    };

    const onCollapseMenu = () => {
        setColapsed(false);
        localStorageService.setItem(LocalStorageKey.NAV_MENU_COLAPSED, false);
    };

    const onFoldMenu = (featureIdChange: string, foldedChange: boolean) => {
        let foldedMenu = foldStatus.find((x) => x.featureId == featureIdChange);
        if (foldedMenu) {
            foldedMenu.folded = foldedChange;
        }
        localStorageService.setItem(LocalStorageKey.NAV_MENU_FOLDED, foldStatus);
    };

    return (
        <div
            id="side-bar"
            ref={sideBar}
            className={`${styles.siteNavContainer} ${isColapsed || isFixedBar ? styles.siteNavContainerFull : ""}`}
            onMouseOver={() => !isFixedBar && setColapsed(true)}
            onMouseLeave={() => !isFixedBar && setColapsed(false)}
        >
            {loading ? (
                <div className={styles.skeletonContainer}>
                    <Skeleton
                        rows={5}
                        marginRow={20}
                    />
                </div>
            ) : (
                <>
                    <div className={`${styles.sidebar} ${isColapsed || isFixedBar ? styles.sidebarFull : ""}`}>
                        <img
                            className={`${styles.logo} ${isFixedBar || isColapsed ? "full-image" : null}`}
                            src={logo}
                            alt="Logo"
                        />
                        {!isMobile && (
                            <Icon
                                iconName={isFixedBar ? "Cancel" : !isColapsed ? "ChevronRightMed" : "Pinned"}
                                className={`${styles.collapsedNavButton} ${!isFixedBar && !isColapsed ? styles.collapsedNavButtonSmall : ""}`}
                                onClick={onSetFixedSideBar}
                            />
                        )}
                        {isMobile && (
                            <Icon
                                iconName={"Cancel"}
                                className={`${styles.collapsedNavButton} ${!isFixedBar && !isColapsed ? styles.collapsedNavButtonSmall : ""}`}
                                onClick={onCollapseMenu}
                            />
                        )}
                    </div>

                    <div
                        ref={navMenu}
                        id="nav-menu"
                        className={`${styles.navMenu} ${isColapsed || isFixedBar ? styles.navMenuBlock : null}`}
                    >
                        {userModules?.map((item, index) => (
                            <SiteNavGroup
                                key={`${SiteNavGroup.name}-${index}`}
                                featureId={item.id}
                                title={item.name}
                                items={item.features}
                                folded={foldStatus.find((x) => x.featureId == item.id)?.folded}
                                onClick={onClickEvent}
                                onFold={onFoldMenu}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
