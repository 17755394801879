import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterActuationType } from "../../../../../models/resources/master/IActuationType";

export interface IMasterActuationTypeListState {
    list?: IMasterActuationType[];
    loading: boolean;
}

const initialState: IMasterActuationTypeListState = {
   
    loading: false,
};

export const masterActuationTypeList = createSlice({
    name: "masterActuationTypeList",
    initialState,
    reducers: {
        resetMasterActuationTypeList: () => initialState,
        setLoadingMasterActuationTypeList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterActuationTypeList: (state, action: PayloadAction<IMasterActuationType[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterActuationTypeList, setLoadingMasterActuationTypeList, setMasterActuationTypeList } = masterActuationTypeList.actions;

export default masterActuationTypeList.reducer;
