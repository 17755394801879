import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";

import { fetchFinalPresenceRepresentativeTypesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterRepresentativePresenceForm } from "./form/form";
import { postToMasterPresenceRepresentativeForm } from "../../../../../../redux/actions/resources/master/presenceRepresentative/presenceRepresentative";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { resetMasterPresenceRepresentativeForm } from "../../../../../../redux/reducers/resources/master/representativePresence/form";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterPresenceRepresentativeFormControler {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterPresenceRepresentativeFormControler: React.FC<IMasterPresenceRepresentativeFormControler> = ({ widthPopUp, heigthPopUp }) => {
    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { loading, form } = useAppSelector((state) => state.masterPresenceRepresentativeForm);
    const { data } = useAppSelector((state) => state.catalog.representativePresenceTypesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postToMasterPresenceRepresentativeForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };
    const onDimissed = () => {
        dispatch(resetMasterPresenceRepresentativeForm());
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.REPRESENTATIVE_PRESENCE_TYPES");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.REPRESENTATIVE_PRESENCE_TYPES");
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form, showPopUp]);

    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchFinalPresenceRepresentativeTypesRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterRepresentativePresenceForm
                    showNotData={showNotData}
                    selectorData={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onlyHidden={showNotData}
            leftButtonContent={<RequiredFieldsMessage />}
        />
    );
};
