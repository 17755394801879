import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IManagementRequestDatePermission } from "../../../../models/management/requestdatepermission/IRequestdatepermission";

interface IManagementRequestDatePermissionListState {
    list?: IManagementRequestDatePermission[];
    loading: boolean;
}

const initialState: IManagementRequestDatePermissionListState = {
    loading: false
};

export const managementRequestDatePermissionListSlice = createSlice({
    name: "managementRepresentationList",
    initialState,
    reducers: {
        resetManagementRequestDatePermissionList: () => initialState,

        setLoadingManagementRequestDatePermissionList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setManagementRequestDatePermissionList: (state, action: PayloadAction<IManagementRequestDatePermission[]>) => {
            state.list = action.payload;     
        },
       
    },
});

export const {
    resetManagementRequestDatePermissionList,
    setLoadingManagementRequestDatePermissionList,
    setManagementRequestDatePermissionList,
} = managementRequestDatePermissionListSlice.actions;

export default managementRequestDatePermissionListSlice.reducer;
