import { ConfigOrganismCertificateTypeAPI } from "../../../../api/resources/organismCertificateType";
import { throwErrorToastrAsync } from "../../../../utils";
import { AppThunk, store } from "../../../store";
import {
    setConfigOrganismCertificateTypeForm,
    setLoadingConfigOrganismCertificateTypeForm,
} from "../../../reducers/resources/organismCertificatedType/form";
import { setLoadingOrganismCertificateTypeList, setOrganismCertificateTypeList } from "../../../reducers/resources/organismCertificatedType/list";
import { setCertificatesByOrganism } from "../../../reducers/catalog/catalog";
const catalogAPI = new ConfigOrganismCertificateTypeAPI();

export const fetchOrganismCertificateTypeList = (): AppThunk => async (dispatch) => {
    try {
        const { filter } = store.getState().configOrganismCertificateTypeFilter;

        dispatch(setLoadingOrganismCertificateTypeList(true));
        const response = await catalogAPI.getOrganismCertificateTypeList(filter);
        dispatch(setOrganismCertificateTypeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingOrganismCertificateTypeList(false));
    }
};

export const fetchOrganismCertificateType =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingConfigOrganismCertificateTypeForm(true));
            const response = await catalogAPI.getOrganismCertificateType(id);
            dispatch(setConfigOrganismCertificateTypeForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingConfigOrganismCertificateTypeForm(false));
        }
    };

export const postToOrganismCertificateTypeForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().configOrganismCertificateTypeForm;

        try {
            dispatch(setLoadingConfigOrganismCertificateTypeForm(true));
            if (!form.id) {
                await catalogAPI.postOrganismCertificateType(form);
            } else {
                await catalogAPI.putOrganismCertificateType(form);
            }
            dispatch(fetchOrganismCertificateTypeList());
            dispatch(setCertificatesByOrganism([]));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingConfigOrganismCertificateTypeForm(false));
            return false;
        }
    };
};
export const removeOrganismCertificateTypeForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().configOrganismCertificateTypeForm;

        try {
            dispatch(setLoadingConfigOrganismCertificateTypeForm(true));
            await catalogAPI.deleteOrganismCertificateType(form.id ?? 0);
            dispatch(fetchOrganismCertificateTypeList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingConfigOrganismCertificateTypeForm(false));
        }
    };
};
