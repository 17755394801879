import { IColumn } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";


import { useAppDispatch } from "../../../../../../../redux/hooks";

import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterRequestStatesColumnsStyle } from "./columnStyle.jss";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterRequestState } from "../../../../../../../models/resources/master/IRequestState";
import { fetchMasterRequestState } from "../../../../../../../redux/actions/resources/master/requestStates/requestStates";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";

export const MasterRequestStatesColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterRequestStatesColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();

    const columns: IColumn[] = [
        {
            key: "sort",
            minWidth: 100,
            maxWidth: 200,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={t("SORT")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState, index: any) => <span>{index >= 0 && index + 1}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterRequestState>("description"),
            minWidth: 100,
            maxWidth: 200,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterRequestState>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterRequestState>("main"),
            minWidth: 100,
            maxWidth: 200,
            name: t("FORM.MAIN"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterRequestState>("main")}
                    title={t("FORM.MAIN")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => (
                <span>
                    <BooleanValue value={item.main}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterRequestState>("internal"),
            minWidth: 100,
            maxWidth: 200,
            name: t("FORM.INTERNAL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterRequestState>("internal")}
                    title={t("FORM.INTERNAL")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => (
                <span>
                    <BooleanValue value={item.internal}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterRequestState>("last"),
            minWidth: 100,
            maxWidth: 200,
            name: t("FORM.LAST_STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterRequestState>("last")}
                    title={t("FORM.LAST_STATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => (
                <span>
                    <BooleanValue value={item.last}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterRequestState>("nextAppStateId"),
            minWidth: 100,
            maxWidth: 200,
            name: t("FORM.NEXT_STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterRequestState>("nextAppStateId")}
                    title={t("FORM.NEXT_STATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => <span>{item.nextAppStateDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterRequestState>("active"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterRequestState>("active")}
                    title={t("ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => (
                <span>
                    <BooleanValue value={item.active}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterRequestState) => (
                <span>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterRequestState(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
