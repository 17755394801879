import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants/mediaQueries";

export const EditRequestIncidenceStyle = createUseStyles((theme: any) => ({
    generalContainerTabIncidence: {
        display: "flex",
        gap: theme.gaps.sizes.xxl,
        flexDirection: "column",
        overflowY: "scroll",
        paddingTop: theme.paddings.default,
        height: theme.heights.requestEditContent,
        [mediaQueries.TABLET]: {
            height: theme.heights.requestEditMobileContent,
        },
    },
}));
