import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";

import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";

import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { MethodsSort } from "../../../../../../../common/enum/sort/Methods";
import { SortTableName } from "../../../../../../../common/enum/sort/TableName";
import { IMasterOrganismItem } from "../../../../../../../models/resources/master/IOrganism";
import { fetchMasterOrganismForm, patchSortOrganismList } from "../../../../../../../redux/actions/resources/master/organism/organism";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";
import { compileNameOfProperty } from "../../../../../../../utils";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../common/separator/separator";
import { MasterOrganismColumnsStyle } from "./columnStyle.jss";

export const MasterOrganismColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterOrganismColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const { list } = useAppSelector((state) => state.masterOrganismList);
    const lastIndex = list ? list.length - 1 : 0;
    const columns: IColumn[] = [
        {
            key: "sort",
            minWidth: 100,
            maxWidth: 200,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={t("PRIORITY")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem, index) => (
                <span className={styles.actions}>
                    <CustomIcons
                        className={styles.arrow}
                        iconName={"ChevronUpSmall"}
                        isDisabled={index === 0}
                        onClick={() => {
                            dispatch(patchSortOrganismList(SortTableName.Organism, item.id ?? 0, MethodsSort.UP));
                        }}
                    />

                    <Separator size="auto" />

                    <CustomIcons
                        iconName={"ChevronDownSmall"}
                        className={styles.arrow}
                        onClick={() => {
                            dispatch(patchSortOrganismList(SortTableName.Organism, item.id ?? 0, MethodsSort.DOWN));
                        }}
                        isDisabled={index === lastIndex || (index === 0 && list?.length === 1) ? true : false}
                    />
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterOrganismItem>("code"),
            minWidth: 120,
            maxWidth: 200,
            name: t("CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterOrganismItem>("code")}
                    title={t("CODE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem) => <span>{item.code ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterOrganismItem>("description"),
            minWidth: 100,
            maxWidth: 200,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterOrganismItem>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterOrganismItem>("viewAll"),
            minWidth: 100,
            maxWidth: 200,
            name: t("VIEW_ALL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterOrganismItem>("viewAll")}
                    title={t("VIEW_ALL")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem) => (
                <span>
                    <BooleanValue value={item.viewAll}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterOrganismItem>("internal"),
            minWidth: 100,
            maxWidth: 200,
            name: t("INTERNAL"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterOrganismItem>("internal")}
                    title={t("INTERNAL")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem) => (
                <span>
                    <BooleanValue value={item.internal}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterOrganismItem>("active"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterOrganismItem>("active")}
                    title={t("ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem) => (
                <span>
                    <BooleanValue value={item.active}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterOrganismItem) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterOrganismForm(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
