import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { fetchCertificateTypesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterCertificateTypeForm } from "./form/form";
import { postAndPutMasterTypesCertificate } from "../../../../../../redux/actions/resources/master/certificateType/certificateType";
import { resetMasterCertificateTypeForm } from "../../../../../../redux/reducers/resources/master/certificateType/form";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterCertificateTypesMasterController {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterCertificateTypesMasterController: React.FC<IMasterCertificateTypesMasterController> = ({ heigthPopUp, widthPopUp }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useDispatch();

    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { loading, form } = useAppSelector((state) => state.masterCertificateTypeForm);
    const { data } = useAppSelector((state) => state.catalog.certificateTypesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postAndPutMasterTypesCertificate()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.CERTIFICATE_TYPE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.CERTIFICATE_TYPE");
        }

        return "";
    }, [showPopUp]);

    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchCertificateTypesRemaining());
    }, [showPopUp]);

    const onDimissedPopUp = () => {
        dispatch(resetMasterCertificateTypeForm());
    };
    return (
        <PopUpForm
            content={
                <MasterCertificateTypeForm
                    showNotData={showNotData}
                    data={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissedPopUp}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            leftButtonContent={<RequiredFieldsMessage />}
            onlyHidden={showPopUp === PopUpCodes.CREATE ? showNotData : false}
            isDisabledButton={isDisabledButton}
        />
    );
};
