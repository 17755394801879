import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { ITariffCondition, resetInitialStateManagementTariffForm } from "../../../../models/management/tariff/ITariffData";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
interface ManagmentTariffForm {
    form: ITariffCondition;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: ManagmentTariffForm = {
    form: resetInitialStateManagementTariffForm(),
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managmentReducer = createSlice({
    name: "summaryConceptList",
    initialState,
    reducers: {
        resetManagementTariffState: () => initialState,
        resetManagementTariffForm: (state) => {
            state.form = initialState.form;
        },
        updateTariffConditionForm: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setTariffConditionItem: (state, action: PayloadAction<ITariffCondition>) => {
            state.form = action.payload;
        },
        resetFormTariffCondition: (state) => {
            state.form = resetInitialStateManagementTariffForm();
            state.loading = false;
            state.showPopUp = PopUpCodes.NONE;
        },
        setLoadingFormTariff: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShowPopUpTariff: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetManagementTariffForm,
    resetManagementTariffState,
    updateTariffConditionForm,
    setTariffConditionItem,
    resetFormTariffCondition,
    setLoadingFormTariff,
    setShowPopUpTariff,
} = managmentReducer.actions;

export default managmentReducer.reducer;
