import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterBlockListContainer } from "./list/list";
import { setDockBlockData } from "../../../../../redux/reducers/catalog/catalog";
import { resetMasterBlockList } from "../../../../../redux/reducers/resources/master/block/list";

export class MasterBlockContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterBlockList());
        store.dispatch(setDockBlockData([]));
    }

    render(): React.ReactNode {
        return (
            <MasterBlockListContainer
                widthPopUp={widths.master.default}
                heigthPopUp={heights.master.default}
            />
        );
    }
}
