import { Method } from "../../../../common/enum";
import { IBoardHistoryAppointmentTimeZone } from "../../../../models/masterAppointment/IAppointmentBoard";
import { apiFetchOAuthWithClaims } from "../../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/appointment/dashboards`;

export class MasterAppointmentTimeZonesBoardAPI {
    async getMasterAppointmentTimeZonesBoard(dashboardId: string, id: string): Promise<IBoardHistoryAppointmentTimeZone> {
        const url = `${baseURL}/${dashboardId}/zones/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IBoardHistoryAppointmentTimeZone>();
    }

    async postMasterAppointmentTimeZonesBoard(dashboardId: string, form: IBoardHistoryAppointmentTimeZone): Promise<number> {
        const url = `${baseURL}/${dashboardId}/zones`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<number>();
    }

    async updateMasterAppointmentTimeZonesBoard(dashboardId: string, id: string, form: IBoardHistoryAppointmentTimeZone): Promise<number> {
        const url = `${baseURL}/${dashboardId}/zones/${id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<number>();
    }

    async deleteMasterAppointmentTimeZonesBoard(dashboardId: string, id: string): Promise<boolean> {
        const url = `${baseURL}/${dashboardId}/zones/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
