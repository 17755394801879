import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const ManagementAuthorizedTransportUnitNumberFormStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: "1 1 auto",
        gap: theme.gaps.sizes.xl,
        paddingTop: theme.paddings.sizes.lg,
    },

    row: {
        display: "flex",
        gap: theme.gaps.sizes.xl,
        [mediaQueries.TABLETPORTRAIT]: {
            display: "flex",
            flexDirection: "column",
            gap: theme.gaps.sizes.xl,
        },
    },
}));
