import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPayloadUserChecknotification } from "../../../../../components/user/profile/notifications/item/item";
import { IGenericPayload } from "../../../../../models";
import { IMasterTransportUnit } from "../../../../../models/resources/master/ITransportUnit";


export interface IMasterTransportUnitFormState {
    form: IMasterTransportUnit;
    loading: boolean;
}

const initialState: IMasterTransportUnitFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterTransportUnitForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterTransportUnitForm: () => initialState,
        setLoadingMasterTransportUnitForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTransportUnitForm: (state, action: PayloadAction<IMasterTransportUnit>) => {
            state.form = action.payload;
        },
        updateMasterTransportUnitProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        updateMasterTransportUnitSizeActive: (state, action: PayloadAction<IPayloadUserChecknotification>) => {
            if (state.form.transportUnitSizes){
                state.form.transportUnitSizes[action.payload.notificationIndex].active = action.payload.active;
            }
            
        },
    },
});

export const { resetMasterTransportUnitForm, setLoadingMasterTransportUnitForm, setMasterTransportUnitForm, updateMasterTransportUnitProperty,updateMasterTransportUnitSizeActive } =
    masterTransportUnitForm.actions;

export default masterTransportUnitForm.reducer;
