import { useTranslation } from "react-i18next";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementParkingValidationRuleList } from "./list/list";
import { useTheme } from "react-jss";
import { ManagementParkingValidationRuleContainerStyle } from "./containerStyle.jss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setManagementParkingValidationRuleFormPopUp } from "../../../redux/reducers/management/parkingValidation/form";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

import { ManagementParkingValidationRuleFormController } from "./controller/controller";
import { getParkingValidationConfiguration } from "../../../redux/actions/management/parkingValidation/parkingValidation";
import { useEffect } from "react";

export const ManagementParkingValidationRuleContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PARKING_VALIDATION_RULE" });
    const theme = useTheme();
    const style = ManagementParkingValidationRuleContainerStyle({ theme });
    const { view } = useAppSelector((state) => state.managementParkingValidationConfiguration);

    const dispatch = useAppDispatch();

    useEffect(() => {
        view === undefined && dispatch(getParkingValidationConfiguration());
    }, [view]);

    return (
        <div className={style.generalContainer}>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setManagementParkingValidationRuleFormPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />

            <ManagementParkingValidationRuleList />
            <ManagementParkingValidationRuleFormController />
        </div>
    );
};
