import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchControlExpedientsAction } from "../../../../redux/actions/control/expedient/expedient";
import { useAppSelector } from "../../../../redux/hooks";
import { sortArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ControlExpedientsColumns } from "./columns/columns";

export const ControllExpedientsList = () => {
    const { list, loading, sort } = useAppSelector((store) => store.controlExpedientsList);
    const dispatch = useDispatch();
    const renderColumns = () => {
        return ControlExpedientsColumns();
    };

    useEffect(() => {
        !list.length && !loading && dispatch(fetchControlExpedientsAction());
    }, []);

    
    return loading ? (
        <Skeleton rows={5}></Skeleton>
    ) : (
        <CustomDetailsList
            data={sortArray(list, sort)}
            renderColumns={renderColumns}
        />
    );
};
