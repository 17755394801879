import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";

import { StatisticsDailyChargeListDateItem } from "./appointments/appointment";
import { CustomTableList } from "./customTableList/tableList";
import { UpcomingAppointmentsListStyle } from "./listStyle.jss";
import { fetchRequestAppointmentsListAction } from "../../../../redux/actions/appointments/appointments";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { generateRandomKeys } from "../../../../utils/keys";
import { Skeleton } from "../../../common/skeleton/skeleton";


interface IUpcomingAppointmentsListProps {
    schema: any;
}

export const UpcomingAppointmentsList: React.FC<IUpcomingAppointmentsListProps> = ({ schema }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = UpcomingAppointmentsListStyle({ theme });
    const { loading, list } = useAppSelector((store) => store.requestUpcomingAppointmentsList);
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT.LIST" });

    const tableHeaderContent = (
        <>
            <div></div>
            {schema.transportUnitNumber.visible && <div>{t("TRANSPORT_UNIT")}</div>}
            {schema.transportUnitSizeCode.visible && <div>{t("SIZE")}</div>}
            {schema.appointmentDockDescription.visible && <div>{t("DOOCK")}</div>}
            {schema.appointmentDate.visible && <div>{t("DATE")}</div>}
            {schema.appointmentTimeZoneTimeRange.visible && <div className="hour">{t("HOUR")}</div>}
            {schema.terminalDescription.visible && <div>{t("TERMINAL")}</div>}
            {schema.expedientInspector.visible && <div className="inspector">{t("INSPECTOR")}</div>}
            {schema.customerName.visible && <div className="inspector">{t("CUSTOMER")}</div>}
            
            {schema.requestStateDescription.visible && <div>{t("STATE")}</div>}
        </>
    );
    const bodyContent = (
        <div className={style.container}>
            {list?.map((date) => {
                return (
                    <StatisticsDailyChargeListDateItem
                        key={generateRandomKeys.toString()}
                        codeItem={date}
                        schema={schema}
                    />
                );
            })}
        </div>
    );

    useEffect(() => {
        list === undefined && dispatch(fetchRequestAppointmentsListAction());
    }, [list]);

    return loading ? (
        <Skeleton rows={3}></Skeleton>
    ) : (
        <CustomTableList
            headerContent={tableHeaderContent}
            bodyContent={bodyContent}
        />
    );
};
