import { useMemo } from "react";
import { Environment } from "../../../common/enum/environment/Environment";
import { useTranslation } from "react-i18next";
import { EnvironmentDescriptionStyle } from "./environmentStyle.jss";
import { useTheme } from "react-jss";

export const EnvironmentDescription = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const { t } = useTranslation("common");
    const theme = useTheme() as any;
    const background = useMemo(() => {
        if (environment === Environment.DEV) {
            return theme.colors.appointmentBoardStates.orangeStatusContainer;
        } else if (environment === Environment.PRE) {
            return theme.colors.appointmentBoardStates.redStatusContainer;
        }
        return "";
    }, [environment]);

    const style = EnvironmentDescriptionStyle({ theme, background });

    const showEnvironment = useMemo(() => {
        let value = false;
        if (environment === Environment.DEV || environment === Environment.PRE) {
            value = true;
        }

        return value;
    }, [environment]);

    const environmentDescription = useMemo(() => {
        if (environment === Environment.DEV) {
            return t("COMMON.DEV");
        } else if (environment === Environment.PRE) {
            return t("COMMON.PRE");
        }
        return null;
    }, [environment]);

    return showEnvironment ? <div className={style.container}>{environmentDescription}</div> : <></>;
};
