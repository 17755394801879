import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IManagemenTActiveSummaryFilter } from "../../../../models/management/summary/ISummary";

interface IManagementSummaryFilterState {
    filter: IManagemenTActiveSummaryFilter;
}

const initialState: IManagementSummaryFilterState = {
    filter: {
        summaryNumber:""
    },
};

export const managementSummaryFilterSlice = createSlice({
    name: "managementSummaryFilterSlice",
    initialState,
    reducers: {
        resetManagementSummaryFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});
export const { resetManagementSummaryFilter, updateFilterProperty } = managementSummaryFilterSlice.actions;

export default managementSummaryFilterSlice.reducer;
