import React from "react";
import { ConfigOrganismWithCertificateContainer } from "./container";
import { resetOrganismCertificateTypeList } from "../../../redux/reducers/resources/organismCertificatedType/list";
import { store } from "../../../redux/store";
import { resetConfigOrganismCertificateTypeForm } from "../../../redux/reducers/resources/organismCertificatedType/form";
import { resetOrganismCertificateTypeFilter } from "../../../redux/reducers/resources/organismCertificatedType/filter";

export class ConfigOrganismWithCertificateController extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetOrganismCertificateTypeList());
        store.dispatch(resetConfigOrganismCertificateTypeForm());
        store.dispatch(resetOrganismCertificateTypeFilter());
    }

    render(): React.ReactNode {
        return <ConfigOrganismWithCertificateContainer />;
    }
}
