import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import {
    IParkingValidationFormData,
    IValidationParkingRuleException,
    IValidationParkingRuleRestriction,
} from "../../../../models/management/parkingValidationRule/IParkingValidationRule";
import {
    IDockQueueRuleComponentsValue,
    IDockQueueRuleFormData,
    IDockQueueRulePriorityValues,
} from "../../../../models/management/dockQueueRule/IDockQueueRule";

export interface IManagementDockQueueRuleFormState {
    form: IDockQueueRuleFormData;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IManagementDockQueueRuleFormState = {
    form: {
        id: null,
        description: "",
        minimumSort: 1,
        dockQueueRuleComponentValues: [],
        dockQueueRulePriorityValues: [],
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managementDockQueueRuleFormSlice = createSlice({
    name: "managementDockQueueRuleForm",
    initialState,
    reducers: {
        resetManagementDockQueueRuleForm: () => initialState,
        setManagementDockQueueRuleFormPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },

        setManagementDockQueueRuleForm: (state, action: PayloadAction<IDockQueueRuleFormData>) => {
            state.form = action.payload;
        },
        updateManagementDockQueueRuleSort: (state, action: PayloadAction<number>) => {
          state.form.minimumSort = action.payload;
        },
        setNameManagementDockQueueRuleForm: (state, action: PayloadAction<string>) => {
            state.form.description = action.payload;
        },
        setLoadingManagementDockQueueRuleForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        createQueueRuleComponent: (state, action: PayloadAction<IDockQueueRuleComponentsValue>) => {
            state.form.dockQueueRuleComponentValues.push(action.payload);
        },
        createQueueRulePriority: (state, action: PayloadAction<IDockQueueRulePriorityValues>) => {
            state.form.dockQueueRulePriorityValues.push(action.payload);
        },
        deleteRuleComponentValue: (state, action: PayloadAction<number>) => {
            const ruleComponentValue: number[] = [];
            state.form.dockQueueRuleComponentValues.forEach((restriction, index) => {
                if (restriction.dockQueueRuleComponentId === action.payload) {
                    ruleComponentValue.push(index);
                }
            });

            ruleComponentValue.forEach((index) => {
                if (state.form.dockQueueRuleComponentValues[index].id !== null) {
                    state.form.dockQueueRuleComponentValues[index].deleted = true;
                    state.form.dockQueueRuleComponentValues[index].value = undefined;
                } else {
                    state.form.dockQueueRuleComponentValues.splice(index, 1);
                }
            });
        },
        deleteRulePriorityValue: (state, action: PayloadAction<number>) => {
            const dockQueueRulePriorityIds: number[] = [];

            state.form.dockQueueRulePriorityValues.forEach((restriction, index) => {
                if (restriction.dockQueueRulePriorityId === action.payload) {
                    dockQueueRulePriorityIds.push(index);
                }
            });

            dockQueueRulePriorityIds.forEach((index) => {
                if (state.form.dockQueueRulePriorityValues[index].id !== null) {
                    state.form.dockQueueRulePriorityValues[index].deleted = true;
                    state.form.dockQueueRulePriorityValues[index].value = undefined;
                } else {
                    state.form.dockQueueRulePriorityValues.splice(index, 1);
                }
            });
        },
        updateQueueRuleComponentValueForm: (
            state,
            action: PayloadAction<{ dockQueueRuleComponentId: number; value: number; isBoolValue: boolean }>,
        ) => {
            const { dockQueueRuleComponentId, value, isBoolValue } = action.payload;

            const restrictionIndex = state.form.dockQueueRuleComponentValues.findIndex(
                (queueRuleComponent) =>
                    queueRuleComponent.dockQueueRuleComponentId === dockQueueRuleComponentId &&
                    (isBoolValue ? true : queueRuleComponent.value === value),
            );

            if (restrictionIndex !== -1) {
                const restriction = state.form.dockQueueRuleComponentValues[restrictionIndex];
                if (isBoolValue && (restriction.deleted === false || restriction.deleted === null)) {
                    restriction.value = value;
                } else {
                    if (restriction.id !== null && (restriction.deleted === false || restriction.deleted === null)) {
                        restriction.deleted = true;
                    } else if (restriction.id !== null && restriction.deleted === true) {
                        restriction.deleted = false;
                    } else {
                        state.form.dockQueueRuleComponentValues.splice(restrictionIndex, 1);
                    }
                }
            } else {
                const newRestriction: IDockQueueRuleComponentsValue = {
                    id: null,
                    dockQueueRuleComponentId: dockQueueRuleComponentId,
                    dockQueueRuleId: state.form.id,
                    value,
                    deleted: false,
                };
                state.form.dockQueueRuleComponentValues.push(newRestriction);
            }
        },
  
        updateQueueRulePrioritiesValueForm: (
            state,
            action: PayloadAction<{ dockQueueRulePriorityId: number; value: number; isBoolValue: boolean }>,
        ) => {
            const { dockQueueRulePriorityId, value, isBoolValue } = action.payload;

            const restrictionIndex = state.form.dockQueueRulePriorityValues.findIndex(
                (queueRuleComponent) =>
                    queueRuleComponent.dockQueueRulePriorityId === dockQueueRulePriorityId &&
                    (isBoolValue ? true : queueRuleComponent.value === value),
            );

            if (restrictionIndex !== -1) {
                const restriction = state.form.dockQueueRulePriorityValues[restrictionIndex];
                if (isBoolValue && (restriction.deleted === false || restriction.deleted === null)) {
                    restriction.value = value;
                } else {
                    if (restriction.id !== null && (restriction.deleted === false || restriction.deleted === null)) {
                        restriction.deleted = true;
                    } else if (restriction.id !== null && restriction.deleted === true) {
                        restriction.deleted = false;
                    } else {
                        state.form.dockQueueRulePriorityValues.splice(restrictionIndex, 1);
                    }
                }
            } else {
                const newRestriction: IDockQueueRulePriorityValues = {
                    id: null,
                    dockQueueRulePriorityId,
                    dockQueueRuleId: state.form.id,
                    value,
                    deleted: false,
                };
                state.form.dockQueueRulePriorityValues.push(newRestriction);
            }
        },
    },
});

export const {
    resetManagementDockQueueRuleForm,
    setLoadingManagementDockQueueRuleForm,
    setNameManagementDockQueueRuleForm,
    createQueueRuleComponent,
    createQueueRulePriority,
    setManagementDockQueueRuleFormPopUp,
    setManagementDockQueueRuleForm,
    deleteRuleComponentValue,
    deleteRulePriorityValue,
    updateQueueRuleComponentValueForm,
    updateQueueRulePrioritiesValueForm,
    updateManagementDockQueueRuleSort,
} = managementDockQueueRuleFormSlice.actions;

export default managementDockQueueRuleFormSlice.reducer;
