import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IPagination } from "../../../../models/common/IPagination";
import { fetchManagementSummaryListAction, setPageAction } from "../../../../redux/actions/management/summary/summary";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementSummaryListColumn } from "./column/column";
import { SkeletonSizes } from "../../../../common/enum/sizes";

interface IManagementSummaryListProps {
    schema: any;
}
export const ManagementSummaryList: React.FC<IManagementSummaryListProps> = ({ schema }) => {
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const { loading, data, pagination } = useAppSelector((state) => state.managementSummaryList);
   
    const onRenderColumn = () => {
        return ManagementSummaryListColumn(schema);
    };

    const onPageUp = useCallback(
        (skip: number) => {
            const currentPage: number = skip + 1;
            if (currentPage <= pagination.totalPages) {
                dispatch(setPageAction(currentPage));
                dispatch(fetchManagementSummaryListAction());
            }
        },
        [pagination.totalPages],
    );

    const onFirstPage = useCallback((skip: number) => {
        if (skip !== 1) {
            dispatch(setPageAction(1));
            dispatch(fetchManagementSummaryListAction());
        }
    }, []);

    const onPageDown = useCallback((skip: number) => {
        const currentPage: number = skip - 1;
        if (currentPage >= 1) {
            dispatch(setPageAction(currentPage));
            dispatch(fetchManagementSummaryListAction());
        }
    }, []);

    const onLastPage = useCallback(
        (skip: number) => {
            if (skip !== pagination.totalPages) {
                dispatch(setPageAction(pagination.totalPages));
                dispatch(fetchManagementSummaryListAction());
            }
        },
        [pagination.totalPages],
    );

    const paginationProps: IPagination = {
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
        firstItemNumber: pagination.firstItemNumber,
        lastItemNumber: pagination.lastItemNumber,
        footerTitle: t("INVOICED.FOOTERTABLE.PAGINATION_FOOTER", {
            firstItemNumber: pagination.firstItemNumber,
            lastItemNumber: pagination.lastItemNumber,
            totalItems: pagination.totalItems,
        }),
        onFirstPage,
        onLastPage,
        onPageDown,
        onPageUp,
    };

    useEffect(() => {
        data === undefined && dispatch(fetchManagementSummaryListAction());
    }, [data]);

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={data?.summaries ?? []}
            renderColumns={onRenderColumn}
            pagination={paginationProps}
            height={"calc(100vh - 14.5rem)"}
        />
    );
};
