import { RequestApi } from "../../../../api";
import { throwErrorToastrAsync } from "../../../../utils";
import { setVlcportRequestFinishedListData, setVlcportRequestFinishedListLoading } from "../../../reducers/vlcPort/requestFinished/list";
import { AppThunk } from "../../../store";
const requestApi = new RequestApi();

export const fetchFinishedREquestList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setVlcportRequestFinishedListLoading(true));
        const response = await requestApi.fetchVlcPortNotFinishedRequest();
        dispatch(setVlcportRequestFinishedListData(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setVlcportRequestFinishedListLoading(false));
    }
};
