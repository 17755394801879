import { createUseStyles } from "react-jss";

export const ManagementIncidencesTypesFormStyle = createUseStyles((theme: any) => ({
    formController: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: "1 1 auto",
        gap: theme.gaps.sizes.xl,
        paddingTop: theme.paddings.sizes.lg,
    },
}));
