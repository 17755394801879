import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentHistoricList } from "../../../../../models/appointment/IAppointmentHistoric";

interface IAppointmentHistoricInspectorListState {
    list?: IAppointmentHistoricList[];
    loading: boolean;
}

const initialState: IAppointmentHistoricInspectorListState = {
    loading: false,
};

export const appointmentHistoricInspectorListReducer = createSlice({
    name: "appointmentHistoricInspectorListReducer",
    initialState,
    reducers: {
        resetAppointmentHistoricInspectorList: () => initialState,
        setAppointmentHistoricInspectorList: (state, action: PayloadAction<IAppointmentHistoricList[]>) => {
            state.list = action.payload;
        },
        setAppointmentHistoricInspectorsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetAppointmentHistoricInspectorList, setAppointmentHistoricInspectorList, setAppointmentHistoricInspectorsLoading } =
    appointmentHistoricInspectorListReducer.actions;

export default appointmentHistoricInspectorListReducer.reducer;
