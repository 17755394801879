import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { emptyUserSignupConfirmationModel, IUserSignupConfirmation } from "../../../../models/user/IUserSignupConfirmation";
import { UserSignupState } from "../../../../common/enum/UserSignupState";

interface UserSignupConfirmationState {
    form: IUserSignupConfirmation;
    loadingSingupConfirmation: boolean;
    userSignupConfirmationSuccess: boolean;
}

const initialState: UserSignupConfirmationState = {
    form: emptyUserSignupConfirmationModel(),
    loadingSingupConfirmation: false,
    userSignupConfirmationSuccess: false,
};

export const userSignupConfirmationSlice = createSlice({
    name: "userSignupConfirmationReducer",
    initialState,
    reducers: {
        updateUserSignupConfirmationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        updateUserSignupConfirmation: (state, action: PayloadAction<IUserSignupConfirmation>) => {
            state.form = action.payload;
        },
        resetUserSignupConfirmationForm: () => initialState,
        loadingUserSignupConfirmation: (state, action: PayloadAction<boolean>) => {
            state.loadingSingupConfirmation = action.payload;
        },
        userSignUpConfirmationSuccess: (state) => {
            state.userSignupConfirmationSuccess = true;
        },
        userSignUpConfirmationFailure: (state) => {
            state.userSignupConfirmationSuccess = false;
        },
        updateSignUpState: (state, action: PayloadAction<UserSignupState>) => {
            state.form.confirmation.state = action.payload;
        },
    },
});

export const {
    updateUserSignupConfirmationProperty,
    updateUserSignupConfirmation,
    resetUserSignupConfirmationForm,
    loadingUserSignupConfirmation,
    userSignUpConfirmationSuccess,
    userSignUpConfirmationFailure,
    updateSignUpState,
} = userSignupConfirmationSlice.actions;

export default userSignupConfirmationSlice.reducer;
