import { createUseStyles } from "react-jss";

export const AllCustomerSelectorStyle = createUseStyles((theme: any) => ({
    unregisteredOptionText: {
        color: theme.colors.allCustomerSelector.unregisteredOptionColor,
    },
    disabledHeader: {
        opacity: "0.5",
        color: theme.colors.form.input.filled,
    },
    headerOption: {
        color: theme.colors.allCustomerSelector.headerOptionColor,
      height:30
    },
}));
