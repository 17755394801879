import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { fetchStatisticsDailyChargeAction } from "../../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { generateRandomKeys } from "../../../../utils/keys";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { CustomTableList } from "../customTableList/tableList";
import { StatisticsDailyChargeListDateItem } from "./date/date";
import { StatisticsDailyChargeListStyle } from "./listStyle.jss";

export const StatisticsDailyChargeList = () => {
    const theme = useTheme();
    const style = StatisticsDailyChargeListStyle({ theme });
    const dispatch = useAppDispatch();
    const { loading, list } = useAppSelector((store) => store.statiticsDailyChargeList.data);
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.DAILYE_CHARGE" });
    const tableHeaderContent = (
        <>
            <div></div>
            <div>{t("DOCUMENT")}</div>
            <div>{t("DESCRIPTION")}</div>
            <div>{t("UTI")}</div>
            <div>{t("CUSTOMER_NAME")}</div>
        </>
    );
    const bodyContent = (
        <div className={style.container}>
            {list.map((date) => {
                return (
                    <StatisticsDailyChargeListDateItem
                        key={generateRandomKeys.toString()}
                        date={date}
                    />
                );
            })}
        </div>
    );

    useEffect(() => {
        !list.length && !loading && dispatch(fetchStatisticsDailyChargeAction());
    }, []);

    return loading ? (
        <Skeleton rows={3}></Skeleton>
    ) : (
        <>
            <CustomTableList
                headerContent={tableHeaderContent}
                bodyContent={bodyContent}
            />
        </>
    );
};
