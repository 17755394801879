import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useParams } from "react-router";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { IChartOption, IChartSeries } from "../../../../models/common/IChartOptions";
import { fetchStatisticsRequestGraphAction } from "../../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { convertToLocal } from "../../../../utils";
import { mapCategoriesRequestGraph } from "../../../../utils/chart";
import { Charts } from "../../../common/chart/chart";
import { TitleH1, TitleH4, TitleH6 } from "../../../common/titles/titles";
import { StatisticsRequestGraphContainerStyle } from "./containerStyle.jss";

interface IStatisticsRequestGraphProps {
    seriesContainer: IChartSeries[];
    seriesTruck: IChartSeries[];
}

export const StatisticsRequestGraphWithOutLoginContainer: React.FC<IStatisticsRequestGraphProps> = ({ seriesContainer, seriesTruck }) => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.REQUEST_GRAPH" });
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.statiticsRequestGraph);
    const theme = useTheme();
    const { organization } = useParams();
    const organizationParam = organization ? organization : "";

    const style = StatisticsRequestGraphContainerStyle({ theme });
    const options: IChartOption & any = {
        chart: {
            height: "353px",
            stacked: true,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        xaxis: {
            categories: mapCategoriesRequestGraph(data.requestGraphDateStates, "date", t("ACCUMULATED")),
        },
    };

    useEffect(() => {
        dispatch(fetchStatisticsRequestGraphAction(organizationParam));
    }, []);

    return (
        <>
            <div className={style.header}>
                <div className={style.containerHeader}>
                    <div>
                        {data.logoDocksBase64 && (
                            <img
                                className={style.logo}
                                src={`data:image/jpeg;base64, ${data.logoDocksBase64}`}
                            />
                        )}
                    </div>
                    <div>
                        <TitleH1 title={t("TITLE")} />
                    </div>
                    <div>
                        {data.logoOrganizationBase64 ? (
                            <img
                                className={style.logo}
                                src={`data:image/jpeg;base64, ${data.logoOrganizationBase64}`}
                            />
                        ) : (
                            <div className={style.logo}></div>
                        )}
                    </div>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.title}>
                    <TitleH4 title={t("TITLE_TRUCK")} />
                </div>
                <div className={style.chart}>
                    <Charts
                        options={options}
                        series={seriesContainer}
                        type="bar"
                        height={230}
                    />
                    <div className={style.message}>
                        <TitleH6
                            title={t("MESSAGE", {
                                date: convertToLocal(new Date().toISOString(), FormatDate.DATE),
                                count: data.totalTrucks,
                                interpolation: { escapeValue: false },
                            })}
                        />
                    </div>
                </div>

                <div className={style.title}>
                    <TitleH4 title={t("TITLE_CONTAINER")} />
                </div>
                <div className={style.chart}>
                    <Charts
                        options={options}
                        series={seriesTruck}
                        type="bar"
                        height={230}
                    />
                    <div className={style.message}>
                        <TitleH6
                            title={t("MESSAGE", {
                                date: convertToLocal(new Date().toISOString(), FormatDate.DATE),
                                count: data.totalContainers,
                                interpolation: { escapeValue: false },
                            })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
