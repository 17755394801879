import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../../../common/constants";

export const NewConceptStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.form.default,
        flex: "1 1 auto",
        alignItems: "center",
        padding: theme.paddings.forms.section.defaultPadding,
        width: "100%",
    },
    row: {
        width: "99%",
        display: "flex",
        gap: theme.gaps.form.default,
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
        },
    },
}));
