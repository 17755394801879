import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { ManagementParkingValidationRuleListColumn } from "./column/column";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { fetchManagementParkingValidationRuleListAction } from "../../../../redux/actions/management/parkingValidation/parkingValidation";
import { SkeletonSizes } from "../../../../common/constants";
export const ManagementParkingValidationRuleList = () => {
    const { loading, list } = useAppSelector((state) => state.managementParkingValidationRuleList);
    const dispatch = useAppDispatch();
    const renderColumns = () => {
        return ManagementParkingValidationRuleListColumn();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchManagementParkingValidationRuleListAction());
    }, [list]);

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={renderColumns}
        />
    );
};
