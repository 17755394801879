import { useForm } from "react-hook-form";
import { IManagementAuthorizedTransportUnitNumber } from "../../../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { requestMaxLength } from "../../../../request/form/create/validation/requestConfig";
import { FormField } from "../../../../common/fields/fields";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import { updateManagementAuthorizedTransportUnitNumberFormProperty } from "../../../../../redux/reducers/management/authorizedTransportUnitNumber/form";
import { useTheme } from "react-jss";
import { ManagementAuthorizedTransportUnitNumberFormStyle } from "./formStyle.jss";
import { BooleanSelector } from "../../../../request/common/selectors";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";

export const ManagementAuthorizedTransportUnitNumberForm = () => {
    const { register, control } = useForm<IManagementAuthorizedTransportUnitNumber>({ mode: "onChange" });
    const { form } = useAppSelector((state) => state.managementAuthorizedTransportUnitNumberForm);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.AUTHORIZED_TRANSPORT_UNIT_NUMBER" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = ManagementAuthorizedTransportUnitNumberFormStyle({ theme });
    const onupdateFormProperty = (name: string, value: any) => {
        dispatch(updateManagementAuthorizedTransportUnitNumberFormProperty({ name, value }));
    };

    const onInputChange = (event: any) => {
        const { name, value } = getGenericPayloadFromEvent(event);
        onupdateFormProperty(name, value);
    };
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onupdateFormProperty(propertyName, !!+option.key);
    };
    return (
        <div className={style.form}>
            <div className={style.row}>
                <FormField
                    type="text"
                    value={form.transportUnitNumber}
                    maxLength={requestMaxLength.TRANSPORT_UNIT_NUMBER}
                    label={t("TRANSPORT_UNIT_NUMBER")}
                    isRequired={true}
                    {...register(compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("transportUnitNumber"), {
                        onChange: onInputChange,
                        value: form.transportUnitNumber,
                        required: true,
                    })}
                />
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.authorized!}
                    propertyName={compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("authorized")}
                    title={t("AUTHORIZED")}
                    control={control}
                />
            </div>
            <div className={style.row}>
                <FormField   
                    type="text"                    
                    value={form.description}
                    label={t("DESCRIPTION")}
                    isRequired={true}
                    {...register(compileNameOfProperty<IManagementAuthorizedTransportUnitNumber>("description"), {
                        onChange: onInputChange,
                        value: form.description,
                        required: true,
                    })}
                />
            </div>
        </div>
    );
};
