import React from "react";
import { store } from "../../../../../redux/store";
import { heights } from "../../../../../styles/romeu/heights";
import { widths } from "../../../../../styles/romeu/widths";
import { MasterTerminalList } from "./list/list";
import { resetMasterTerminalForm } from "../../../../../redux/reducers/resources/master/terminal/form";
import { resetMasterTerminalList } from "../../../../../redux/reducers/resources/master/terminal/list";
import { setTerminals } from "../../../../../redux/reducers/catalog/catalog";
import { MasterTerminalFormController } from "./controller/controller";
export class MasterTerminalContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterTerminalForm());
        store.dispatch(resetMasterTerminalList());
        store.dispatch(setTerminals([]));
    }

    render(): React.ReactNode {
        return (
            <>
                <MasterTerminalList
                    widthPopUp={widths.master.default}
                    heigthPopUp={heights.master.default}
                />
                <MasterTerminalFormController
                    widthPopUp={widths.master.default}
                    heigthPopUp={heights.master.default}
                />
            </>
        );
    }
}
