import { createUseStyles } from "react-jss";
export const CustomTableActionsStyle = createUseStyles((_theme: any) => ({
    iconActions: (props: any) => ({
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "&  div:not([class]) ": {
            width:"100%",
            display: "flex",
            justifyContent: props.isMobile ? "center" : "end",
           // flexWrap: "wrap",
        },
    }),
}));
