import { ReactChild } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useAppDispatch } from "../../../redux/hooks";
import { setShowPopUpTariff } from "../../../redux/reducers/management/tariff/form";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementTariffContainerStyle } from "./containerStyle.jss";
import { ManagementTariffList } from "./list/list";
import { ManagmentTariffController } from "./controller/controller";

export const ManagementTariffContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.TARIFF" });
    const theme = useTheme();
    const style = ManagementTariffContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const startActionButtons: ReactChild = (
        <ButtonPrimary
            title={t("BUTTONS.NEWCONDITION")}
            handleClick={() => {
                dispatch(setShowPopUpTariff(PopUpCodes.CREATE));
            }}
        />
    );

    return (
        <div className={style.generalContainer}>
            <NavHeaderComponent
                title={t("TITLE")}
                action={startActionButtons}
            />

            <ManagementTariffList />
            <ManagmentTariffController />
        </div>
    );
};
