import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { FormatDate } from "../../../../../../common/enum/dateTime/FormatDate";
import { useAppSelector } from "../../../../../../redux/hooks";
import { FormField } from "../../../../../common/fields/fields";
import { LabelTime } from "../../../../../common/labels/labelTime/labelTime";
import { EditRequestTabsVUAContainerStyle } from "./containerStyle.jss";

export const EditRequestTabsVUAContainer = () => {
    const theme = useTheme();
    const style = EditRequestTabsVUAContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const { saplNumber, saplCreatedOn, authorizedOn, cancelledOn, duaMrnNumber, vuaNumber } = useAppSelector(
        (store) => store.editRequest.headerRequest,
    );

    return (
        <div
            className={`ms-Grid ${style.generalContainerVua}`}
            dir="ltr"
        >
            <div className={`${GridClass.ROW_GRID}`}>
                <div className={`${GridClass.THREE_CELL} ${style.field}`}>
                    <FormField
                        name={t("SAPL")}
                        onChange={() => null}
                        type="text"
                        value={saplNumber}
                        label={t("SAPL")}
                        disabled={true}
                    />
                </div>
                <div className={`${GridClass.THREE_CELL} ${style.field}`}>
                    <FormField
                        name={t("VUANUMBER")}
                        onChange={() => null}
                        type="text"
                        value={vuaNumber}
                        label={t("VUANUMBER")}
                        disabled={true}
                    />
                </div>
                <div className={`${GridClass.THREE_CELL} ${style.field}`}>
                    <FormField
                        name={t("MRN")}
                        onChange={() => null}
                        type="text"
                        value={duaMrnNumber}
                        label={t("MRN")}
                        disabled={true}
                    />
                </div>
            </div>
            <div className={`${GridClass.ROW_GRID}`}>
                <div className={`${GridClass.THREE_CELL}`}>
                    <LabelTime
                        name={t("SAPLCREATEDON")}
                        value={saplCreatedOn}
                        format={FormatDate.DATE_HOUR_MINUTE}
                    />
                </div>
                <div className={`${GridClass.THREE_CELL}`}>
                    <LabelTime
                        name={t("AUTHORIZEDON")}
                        value={authorizedOn}
                        format={FormatDate.DATE_HOUR_MINUTE}
                    />
                </div>
                <div className={`${GridClass.THREE_CELL}`}>
                    <LabelTime
                        name={t("CANCELLEDON")}
                        value={cancelledOn}
                        format={FormatDate.DATE_HOUR_MINUTE}
                    />
                </div>
            </div>
        </div>
    );
};
