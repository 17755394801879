import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { goSelectedStep } from "../../../../redux/actions/user/signup/stepper";
import { useAppDispatch } from "../../../../redux/hooks";
import { userSignupStepperSelector } from "../../../../redux/selectors/user/signup/stepper";
import { UserStep } from "./step/step";
import { UserStepperStyled } from "./userStepper.jss";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../common/constants";

interface INewClientFormProps {
    steps: StepNode[];
    disableNavigation?: boolean;
}

interface StepNode {
    title: string;
    subtitle: string;
    node: ReactNode;
}

export const UserStepper = (props: INewClientFormProps) => {
    const userSignupStepperStyle = UserStepperStyled();
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const step = useSelector(userSignupStepperSelector);
    const dispatch = useAppDispatch();

    const handleStepChange = (id: number) => {
        dispatch(goSelectedStep(id));
    };

    const mapSteperItem = (item: any, index: number) => {
        return (
            <div
                key={"index-" + index}
                className={!props.disableNavigation ? userSignupStepperStyle.stepContainer : ""}
                onClick={() => !props.disableNavigation && handleStepChange(index + 1)}
            >
                <UserStep
                    key={index}
                    id={index + 1}
                    number={(index + 1).toString()}
                    title={item.title}
                    subtitle={item.subtitle}
                    step={step.step}
                />
            </div>
        );
    };

    const stepButtons = isMobile
        ? props.steps.map((item, index: number) => {
              if (step.step === index + 1) {
                  return mapSteperItem(item, index);
              }
              return null;
          })
        : props.steps.map((item, index: number) => mapSteperItem(item, index));

    const stepContents = props.steps.map((item, index: number) => {
        if (step.step === index + 1) {
            return <> {item.node} </>;
        }
        return <></>;
    });

    return (
        <>
            <div className={userSignupStepperStyle.container}>
                <div className={userSignupStepperStyle.leftColumn}>{stepButtons}</div>
                <div className={userSignupStepperStyle.rightColumn}>{stepContents}</div>
            </div>
        </>
    );
};
