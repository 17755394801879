import { OrderColumn } from "../common/enum/OrderColumn";
import { IPaginationState } from "../models/common/IPaginationState";
import { ISortState } from "../models/common/ISortState";

export const sortArray = (list: any, sort: ISortState) => {
    const sortValue = (value: string | string[]) => {
        let sortValue = Array.isArray(value) ? value[0] : value;
        return sortValue;
    };

    const isString = (value: any) => {
        if (typeof value === "string") {
            return value.toLocaleLowerCase();
        }
        return value;
    };

    if (!sort || sort.column === "") {
        return list;
    }

    if (sort.orderColumn === OrderColumn.ASC) {
        return list.slice().sort((a: any, b: any) => {
            if (a[sortValue(sort.column)] === undefined || isString(a[sortValue(sort.column)]) > isString(b[sortValue(sort.column)])) {
                return 1;
            } else if (b[sortValue(sort.column)] === undefined || isString(b[sortValue(sort.column)]) > isString(a[sortValue(sort.column)])) {
                return -1;
            } else {
                return 0;
            }
        });
    } else {
        return list.slice().sort((a: any, b: any) => {
       
            if (b[sortValue(sort.column)] === undefined || isString(a[sortValue(sort.column)]) < isString(b[sortValue(sort.column)])) {
                return 1;
            } else if (a[sortValue(sort.column)] === undefined || isString(b[sortValue(sort.column)]) < isString(a[sortValue(sort.column)])) {
                return -1;
            } else {
                return 0;
            }
        });
    }
};

export const sortAndPaginateArray = (list: any, sort: ISortState, pagination: IPaginationState) => {
    return sortArray(list, sort).slice(
        (pagination.currentPage - 1) * pagination.pageSize,
        (pagination.currentPage - 1) * pagination.pageSize + pagination.pageSize,
    );
};
