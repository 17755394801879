import { createUseStyles } from "react-jss";

export const MasterAppointmentDockFormStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        paddingTop: theme.paddings.sizes.base,
        height: "calc(100vh - 187px)",
        overflow: "auto",
    },
    header: {
        display: "flex",
        gap: theme.gaps.sizes.base,
        alignItems: "center",
        flexWrap: "wrap",
        padding: "15px 0",
    },
    button: {
        maxWidth: "20%",
        margin: "20px 0",
    },
}));
