import { createUseStyles } from "react-jss";

export const UtiCardSectionStyle = createUseStyles((theme: any) => ({
    utiCardSectionContainer: {
        display: "flex",
        width: "100%",
        columnGap: 24,
        flexWrap: "wrap",
        rowGap: theme.gaps.sizes.xl,
    },
}));
