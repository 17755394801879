import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../models";
import { exportStatisticsDailyChargeAction, fetchStatisticsDailyChargeAction } from "../../../redux/actions/statistics/statistics";
import { useAppSelector } from "../../../redux/hooks";
import { resetDailyChargeFilter, updateFilterProperty } from "../../../redux/reducers/statistics/dailyCharge/filter";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { StatisticsFilterContainer } from "../filters/container";
import { StatisticsDailyChargeList } from "./list/list";


export const StatisticsDailyChargeContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.DAILYE_CHARGE" });
    const dispatch = useDispatch();

    const { filter } = useAppSelector((store) => store.statisticsDailyChargeFilter);
    const onExportStatisticsDailyChargeData = () => {
        dispatch(exportStatisticsDailyChargeAction());
    };
    const statisticsDailyChargeHeaderActions = () => {
        return <ExportDataIcon onExportCSV={onExportStatisticsDailyChargeData} />;
    };

    const updateHeaderPropierty = (payload: IGenericPayload) => {
        dispatch(updateFilterProperty(payload));
    };

    const onApplyFilters = () => {
        dispatch(fetchStatisticsDailyChargeAction());
    };
    const onResetFilters = () => {
        dispatch(resetDailyChargeFilter());
        onApplyFilters();
    };
   

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                endAction={statisticsDailyChargeHeaderActions()}
            />
            <StatisticsFilterContainer
                updateFilterProperty={updateHeaderPropierty}
                filterData={filter}
                onApplyFilters={onApplyFilters}
                onResetFilters={onResetFilters}
            />
            <StatisticsDailyChargeList />
        </>
    );
};
