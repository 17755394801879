import { createUseStyles } from "react-jss";

export const LabelStyle = createUseStyles((theme: any) => ({
    label: {
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.base,
        fontWeight: "100",
        position: "absolute",
        pointerEvents: "none",
        top: "0",
        transform: "translateY(14px)",
        transition: "all 0.2s ease-in-out",
       
    },
    required: {
        color: theme.colors.palette.red,
    },
    readOnlyLabel: {
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.base,
        fontWeight: "100",
    },
    readOnlyContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));
