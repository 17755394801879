import { RoleType } from "../../../../common/enum";import { store } from "../../../../redux/store";

export const isShowIncidenceActions = (organismUserId?: number, createdBy?: string): boolean => {
    const { organismId, roles, email } = store.getState().userData.profile;

    for (let rol of roles) {
        
        
         if (rol.id === RoleType.ORGANISM_INSPECTOR) {
            if (createdBy) {
                let re = new RegExp(`^${createdBy}*`);
                return re.test(email);
            }
            return false;
        } else if (rol.id === RoleType.ORGANISM_TECHNICIAN) {
            if (createdBy) {
                let re = new RegExp(`^${createdBy}*`);
                return re.test(email) && organismId === organismUserId;
            }
            return false;
        } else if (rol.id === RoleType.ORGANISM_ADMIN || organismId) {
            return organismId === organismUserId;
        } 
    }

    return true;
};
