import { MasterAppointmentTypeConfigurationBoardAPI } from "../../../../../api/masterAppointment/appointmentBoard/appointmentTypeConfiguration";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { IBoardHistoryAppointmentTypeConfiguration } from "../../../../../models/masterAppointment/IAppointmentBoard";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterAppointmentTypeConfigurationForm } from "../../../../reducers/masterAppointment/appointmentBoard/appointmentTypeConfiguration/form";
import { fetchMasterAppointmentBoard } from "../appointmentBoard";

const appointmentApi = new MasterAppointmentTypeConfigurationBoardAPI();

export const postMasterAppointmentTypeConfiguration = (dashboardId: string, form: IBoardHistoryAppointmentTypeConfiguration): any => {
    return async (dispatch: any) => {
        try {
            let customForm = {
                ...form,
                forcedAppointmentTypeId: form.forcedAppointmentTypeId !== 0 ? form.forcedAppointmentTypeId : null,
                merchandiseTypeId: String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseTypeId : null,
                maxAppointments: form.maxAppointments !== "" ? Number(form.maxAppointments) : null,
            };

            dispatch(setLoadingMasterAppointmentTypeConfigurationForm(true));
            const response = await appointmentApi.postMasterAppointmentTypeConfigurationBoard(dashboardId, customForm);
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentTypeConfigurationForm(false));
            return false;
        }
    };
};

export const updateMasterAppointmentTypeConfiguration = (dashboardId: string, id: string, form: IBoardHistoryAppointmentTypeConfiguration): any => {
    return async (dispatch: any) => {
        try {
            let customForm = {
                ...form,
                forcedAppointmentTypeId: form.forcedAppointmentTypeId !== 0 ? form.forcedAppointmentTypeId : null,
                merchandiseTypeId: String(form.merchandiseTypeId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseTypeId : null,
                maxAppointments: form.maxAppointments !== "" ? Number(form.maxAppointments) : null,
            };
            dispatch(setLoadingMasterAppointmentTypeConfigurationForm(true));
            const response = await appointmentApi.updateMasterAppointmentTypeConfigurationBoard(dashboardId, id, customForm);
            dispatch(fetchMasterAppointmentBoard(dashboardId));
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterAppointmentTypeConfigurationForm(false));
            return false;
        }
    };
};

export const deleteMasterAppointmentTypeConfiguration = (dashboardId: string, id: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingMasterAppointmentTypeConfigurationForm(true));
            const response = await appointmentApi.deleteMasterAppointmentTypeConfigurationBoard(dashboardId, id);
            return response;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingMasterAppointmentTypeConfigurationForm(false));
            dispatch(fetchMasterAppointmentBoard(dashboardId));
        }
    };
};
