import { useTranslation } from "react-i18next";
import { exportInvoicingUninvoicedAction, fetchInvoicingUninvoicedAction } from "../../../redux/actions/invoicing/uninvoiced/uninvoiced";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ExportDataIcon } from "../../common/exportedData/exportData";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { InvoicingUninvoicedList } from "./list/list";
import { InvoicingFilterContainer } from "../filter/filter";
import { useCallback } from "react";
import { IGenericPayload } from "../../../models";
import { resetInvoicingUninvoicedFilter, updateFilterProperty } from "../../../redux/reducers/invoicing/uninvoiced/filter";

export const InvoicingUninvoicedContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "INVOICED" });
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.invoicingUninvoicedConceptFilter);

    const onApply = useCallback(() => {
        dispatch(fetchInvoicingUninvoicedAction());
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(resetInvoicingUninvoicedFilter());
        dispatch(fetchInvoicingUninvoicedAction());
    }, [dispatch]);
    
    return (
        <>
            <NavHeaderComponent
                title={t("TITLES.UNINVOICED")}
                endAction={<ExportDataIcon onExportCSV={() => dispatch(exportInvoicingUninvoicedAction())} />}
            />
            <InvoicingFilterContainer
                filter={filter}
                applyFilter={onApply}
                resetFilter={onReset}
                updateFilterProperty={(payload: IGenericPayload) => {
                    dispatch(updateFilterProperty(payload));
                }}
            />

            <InvoicingUninvoicedList />
        </>
    );
};
