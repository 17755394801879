import { createUseStyles } from "react-jss";

export const DayPickerStyled = createUseStyles((theme: any) => ({
    selected: {
        backgroundColor: theme.colors.daypicker.selectedBackground,
        color: theme.colors.daypicker.selectedColor,
    },
    today: {
        backgroundColor: theme.colors.daypicker.todayBackground,
        color: theme.colors.daypicker.todayColor
    }
}));
