import { createUseStyles } from "react-jss";

export const FormSectionStyle = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        padding: props.firstSection ? theme.paddings.forms.section.firstSectionPadding : theme.paddings.forms.section.defaultPadding,
        display: "flex",
        flexDirection: "column",
        borderBottom: props.lastSection ? "0" : `1px dotted ${theme.colors.form.section.separator}`,
        rowGap: theme.gaps.sizes.md,
    }),
    titleContainer: {
        display: "flex",
    },
}));
