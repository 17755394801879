import { PopUpCodes } from "../../common/enum/PopUpCodes";

export interface ICreateRequest {
    //step 1 UTI
    transportUnitId?: number; // selector
    date?: Date;
    turnId?: number; // selector
    customerId: string; //selector
    invoiceCustomerId: string | number; //selector
    customerReference?: string;
    booking: string;
    customsStateId: number; //selector ---
    temporaryRegime: boolean;
    transportUnits: ITransportUnitData[];
    parkingAuthorization: boolean;
    representativeId?: number | null;

    //step 2. Información de partida
    merchandiseOriginId: number; //selector ---
    humanConsumption: boolean;
    merchandiseTypeId: number; //selector ---
    description: string;
    tariffHeading: string;
    merchandiseArrivalShip: string;
    summaryNumber: string;
    terminalId?: number;
    merchandiseCategoryId: number;
    automaticParkingAuthorization: boolean;

    //step 3. Certificados
    fumigated: boolean;
    dryCharge: boolean;
    organicProduct: boolean;
    halalCertified: boolean;
    exporter: string;
    importer: string;
    carrierId: string; //selector --- pendiente
    remarks: string;
    inspectionPresence: boolean;
    representativePresenceTypeId: number; //selector
    finalDestinationTypeId: number; //selector
    inspections: Inspection[];
}

export interface ICreateDraft extends ICreateRequest {
    groupId?: string;
}

export interface ITransportUnitDataForm {
    form: ITransportUnitData;
    showPopUp: PopUpCodes;
}

export interface ITransportUnitData {
    transportUnitSizeId?: number;
    transportUnitNumber: string;
    packages?: number;
    grossWeight?: number;
    palletized?: boolean;
    seals: string[];
    batches: string[];
}

export interface ITransportUnitDataWithIndex {
    transportUnit: ITransportUnitData;
    index: number;
}

export interface OriginSeal {
    seal: string;
}
export interface OriginSealWithIndex extends OriginSeal {
    index: number;
}

export interface IFormInspection {
    form: Inspection;
    showPopUp: PopUpCodes;
}
export interface Inspection {
    organismId?: number; // selector
    organismCode?: string;
    certificateTypeId?: number; //selector con logica con organismo
    certificateNumber: string;
    exporter: string;
    importer: string;
    year: number;
}

export interface InspectionWithIndex {
    inspection: Inspection;
    index: number;
}

export const emptyInspection = (): Inspection => {
    return {
        certificateNumber: "",
        exporter: "",
        importer: "",
        year: new Date().getFullYear(),
    };
};
export const emptyTransportUnit = (): ITransportUnitData => {
    return {
        transportUnitNumber: "",
        seals: [],
        batches: [],
    };
};

export const emptyOriginSeal = (): OriginSeal => {
    return {
        seal: "",
    };
};
