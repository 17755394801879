import React from "react";
import { TextareaStyle } from "./textarea.jss";

interface ITextareaProps {
    name: string;
    onChange: (e: any) => void;
    className?: string;
    value: string;
    ref: React.ForwardedRef<HTMLTextAreaElement>;
    rows?: number;
    label?: string;
    disabled?: boolean;
    error?: string;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(({ onChange, name, label, value, rows, disabled, error }, ref) => {
    const style = TextareaStyle();
    return (
        <>
            {label && <label className={style.label}>{label} </label>}
            <textarea
                rows={rows ? rows : 1}
                className={`${style.input}`}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                ref={ref}
            />
            {error && <span className={style.error}>{error}</span>}
        </>
    );
});
