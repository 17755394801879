import { CatalogAPI } from "../../../../api";
import { IUserOrganization } from "../../../../models";
import { LocalStorageKey, LocalStorageService } from "../../../../services/localStorageService";
import { throwErrorToastrAsync } from "../../../../utils";
import { resetUserClaims } from "../../../reducers/user/claims/claims";
import { setOrganization, setOrganizationData, setOrganizationLoading } from "../../../reducers/user/organization/organization";
import { AppDispatch, AppThunk, store } from "../../../store";
import { fetchUserData } from "../../userData/userData";
const localStorageService = new LocalStorageService();

const catalogApi = new CatalogAPI();

export const setUserOrganization =
    (organization: IUserOrganization): AppThunk =>
    (dispatch: AppDispatch) => {
        localStorageService.setItem(LocalStorageKey.ORGANIZATION, organization);
        dispatch(setOrganization(organization));
        dispatch(resetUserClaims());
    };

export const fetchUserOrganization = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setOrganizationLoading(true));
        const response = await catalogApi.getUserOrganization();

        dispatch(
            setOrganizationData(response),
        );
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setOrganizationLoading(false));
    }
};

export const putUserOrganization = (): any => {
    return async (dispatch: any) => {
        const { organizationData } = store.getState().organization;

        try {
            dispatch(setOrganizationLoading(true));
            await catalogApi.putUserOrganization(organizationData);
            dispatch(fetchUserOrganization());
            dispatch(fetchUserData());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };
};
