import { useTheme } from "react-jss";
import { useAppSelector } from "../../../../../redux/hooks";
import { RequestButtons } from "../buttons/requestButtons";
import { GoodInfoData } from "./form/form";

import { IRequestStepProps } from "../../../../../models/requests/draft/IRequestStepProps";
import { FormDraftContainerStyle } from "../containerStyle.jss";

export const GoodInfoDataContainer: React.FC<IRequestStepProps> = (props) => {
    const theme = useTheme();
    const styles = FormDraftContainerStyle({ theme });
    const { informationStepIsValid } = useAppSelector((store) => store.draftRequest.sectionValidation);

    let showSummaryMessage = !useAppSelector((store) => store.draftRequest.request.summaryNumber);
    showSummaryMessage = !props.schema.summaryNumber.visible ? false : showSummaryMessage;
    return (
        <>
            <div className={styles.stepperContainer}>
                <GoodInfoData {...props} />
            </div>
            <RequestButtons
                isValid={informationStepIsValid}
                isLastStep={false}
                isFirstStep={false}
                showSummaryMessage={showSummaryMessage}
                onCloseModal={props.editMode ? props.onResumeAccept : undefined}
                schema={props.schema}
                editDraft={props.editMode ?? false}
            />
        </>
    );
};
