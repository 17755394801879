import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterGroupMerchandiseCategoryForm, setMasterGroupMerchandiseCategoryForm } from "../../../../reducers/resources/master/groupMerchandiseCategory/form";
import { setLoadingMasterGroupMerchandiseCategoryList, setMasterGroupMerchandiseCategoryList } from "../../../../reducers/resources/master/groupMerchandiseCategory/list";
import { AppThunk, store } from "../../../../store";


const catalogApi = new CatalogAPI();

export const fetchMasterGroupMerchandiseCategoryList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterGroupMerchandiseCategoryList(true));
        const response = await catalogApi.getMasterGroupMerchandiseCategoryList();
        dispatch(setMasterGroupMerchandiseCategoryList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterGroupMerchandiseCategoryList(false));
    }
};

export const fetchMasterGroupMerchandiseCategory =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterGroupMerchandiseCategoryForm(true));
            const response = await catalogApi.getMasterGroupMerchandiseCategory(id);
            dispatch(setMasterGroupMerchandiseCategoryForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterGroupMerchandiseCategoryForm(false));
        }
    };

export const postMasterGroupMerchandiseCategory = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterGroupMerchandiseCategoryForm;

            let apiForm = {
                ...form,
                id: form.id === 0 ? null : form.id,
            };

            dispatch(setLoadingMasterGroupMerchandiseCategoryForm(true));
            if (form.id) {
                await catalogApi.putMasterGroupMerchandiseCategory(apiForm);
            } else {
                await catalogApi.postMasterGroupMerchandiseCategory(apiForm);
            }

            dispatch(fetchMasterGroupMerchandiseCategoryList());

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);  dispatch(setLoadingMasterGroupMerchandiseCategoryForm(false));
        } 
    };
};
