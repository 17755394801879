import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    deleteMasterAppointmentDatePermission,
    postAndPutMasterAppointmentDatePermission,
} from "../../../../../../redux/actions/masterAppointment/appointmentDatePermission/appointmentDatePermission";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { resetMasterAppointmentDatePermissionForm } from "../../../../../../redux/reducers/masterAppointment/appointmentDatePermission/form";
import { clearMasterAppointmentPopUp } from "../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { ManagementAppointmentDatePermisionForm } from "./form/form";
import { ManagementMessageDayTypeForm } from "./messageDayTipe/messageDayType";

import { PopUpConfirmation } from "../../../../../common/popup/popUpConfirmation/popUpConfirmation";

import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../common/enum/PopUpConfirmationType";
import { useContainer } from "../../../../../../hooks/request/useContainer";
import { IAppointmentDatePermissionForm } from "../../../../../../models/masterAppointment/IAppointmentDatePermission";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

export const MasterAppointmenDateRestrictionFormController = () => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useAppDispatch();
    const { showPopUp } = useAppSelector((state) => state.masterAppointment);
    const { loading, form } = useAppSelector((store) => store.masterAppointmentDatePermissionForm);

    const isContainer = useContainer(form.transportUnitId);

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return `${t("CREATE")} ${t("BUTTON.RESTRICTED_DATE")}`;
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return `${t("UPDATE")} ${t("BUTTON.RESTRICTED_DATE")}`;
        }
        return "";
    }, [showPopUp]);

    const onCancel = () => {
        dispatch(clearMasterAppointmentPopUp());
    };

    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE) {
            dispatch(postAndPutMasterAppointmentDatePermission()).then((response: any) => {
                response && onCancel();
            });
        } else if (showPopUp === PopUpCodes.DELETE && form.id) {
            dispatch(deleteMasterAppointmentDatePermission());
            onCancel();
        }
    };
    const onDimissed = () => {
        dispatch(resetMasterAppointmentDatePermissionForm());
    };
    const isValidForm = useMemo(() => {
        type KeysOfAppointmentDatePermissionForm = keyof IAppointmentDatePermissionForm;
        let keys: KeysOfAppointmentDatePermissionForm[] = ["referenceDayId", "merchandiseCategoryGroupId", "startTime", "endTime"];
        isContainer && keys.push("transportUnitSizeId");
        let item: any = form;
        let valid = false;
        for (let key of keys) {
            if (!item[key]) {
                valid = true;
                break;
            }
        }
        return valid;
    }, [form, isContainer]);

    return (
        <>
            <PopUpForm
                content={<ManagementAppointmentDatePermisionForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onCancel}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"45vh"}
                width={"35vw"}
                isLoading={loading}
                isBlocking={false}
                underButtonContent={<ManagementMessageDayTypeForm />}
                isDisabledButton={isValidForm}
                leftButtonContent={<RequiredFieldsMessage />}
            />
            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onCancel}
                onAccept={onSave}
                onDimissed={onDimissed}
                loading={loading}
                type={PopUpConfirmationType.DELETE}
                height={"auto"}
            />
        </>
    );
};
