import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../models";
import { useAppSelector } from "../../../../redux/hooks";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { RequiredFieldsMessage } from "../../../common/requiredMessage/requiredMessage";
import { widths } from "../../../../styles/romeu/widths";
import { heights } from "../../../../styles/romeu/heights";
import {
    resetManagementRepresentation,
    setManagementRepresentationPopUp,
    updateManagementRepresentationProperty,
} from "../../../../redux/reducers/management/representation/form";
import { updateManagementRepresentationAction } from "../../../../redux/actions/management/representation/representation";
import { ManagementRepresentationDefaultForm } from "./form/form";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";
import { convertToLocal } from "../../../../utils/dateUtils";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

export const ManagementRepresentationBaseForm = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.REPRESENTATION" });
    const dispatch = useDispatch();
    const { generalRepresentationData, loading, showPopUp } = useAppSelector((store) => store.managementRepresentationForm);
    const [isAvailableSave, setIsAvailableSave] = useState<boolean>(false);
    const onDimissed = () => {
        dispatch(resetManagementRepresentation());
    };
    const onCloseForm = () => {
        dispatch(setManagementRepresentationPopUp(PopUpCodes.NONE));
    };

    const onSaveForm = () => {
        dispatch(updateManagementRepresentationAction(generalRepresentationData)).then((response: any) => {
            response && onCloseForm();
        });
    };

    const updatePropertyForm = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateManagementRepresentationProperty(payload));
    };

    const onDateChange = (date: Date | undefined | null, propertyName: string) => {
        const dateLocal = date ? convertToLocal(date, FormatDate.FULL) : "";
        updatePropertyForm(propertyName, dateLocal);
    };

    const onRadioButtonChange = useCallback(
        (optionKey: string, propertyName: string) => {
            updatePropertyForm(propertyName, !!+optionKey);
        },
        [dispatch],
    );

    return (
        <>
            <PopUpForm
                content={
                    <ManagementRepresentationDefaultForm
                        setIsAvailableSave={setIsAvailableSave}
                        onRadioButtonChange={onRadioButtonChange}
                        onDateChange={onDateChange}
                    />
                }
                leftButtonContent={<RequiredFieldsMessage />}
                width={widths.management.representation.representationForm.width}
                height={heights.management.representation.representationForm.height}
                title={t("FORM.TITLE.UPDATE")}
                isDisabledButton={!isAvailableSave}
                isLoading={loading}
                isVisible={showPopUp === PopUpCodes.CREATE}
                onSave={onSaveForm}
                onCancel={onCloseForm}
                onDimissed={onDimissed}
            />
        </>
    );
};
