import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import {
    Appointment,
    emptyBatch,
    emptyBatches,
    emptyInspections,
    emptyOriginSeal,
    emptyOriginSeals,
    IBatch,
    IEditRequest,
    IInspection,
    IInspectionWithIndex,
    IRequestInspection,
    ISeal,
} from "../../../../models/requests/IEditRequest";
import { IBillingdata, IEditRequestBilling } from "../../../../models/requests/IEditRequestBilling";
import { IFormValidationRequest } from "../../../../models/requests/IFormValidationRequest";
import { IRequestDocument, IRequestDocumentState } from "../../../../models/requests/IRequestDocument";
import { IRequestIncidence } from "../../../../models/requests/IRequestIncidence";
import { IRequestStateHistory, IRequestStateHistoryState, IRequestStateHistoryWithIndex } from "../../../../models/requests/IRequestStateHistory";
import { IAvailableDates } from "../../../../models/requests/utils/IAvailableDates";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { IConcurrence } from "../../../../models/common/IConcurrence";

interface EditRequestState {
    requestId: number;
    headerRequest: IEditRequest;
    requestInspection: IRequestInspection;
    requestDocuments: IRequestDocumentState;
    seals: ISeal[];
    seal: ISeal;
    batches: IBatch[];
    batch: IBatch;
    incidences: IRequestIncidence;
    invoicesConcepts: IEditRequestBilling;
    stateHistory: IRequestStateHistoryState;
    copyHeaderRequest: IEditRequest;
    form: IFormValidationRequest;
    isLoadedRequest: boolean;
    availableDates?: IAvailableDates;
    canAssignAppointment?: boolean;
    concurrency: IConcurrence;
    indexTabRequest: number;
}

const initialState: EditRequestState = {
    requestId: 0,
    headerRequest: {
        parkingAuthorization: true,
        seals: [] as ISeal[],
        batches: [] as IBatch[],
        temporaryRegime: false,
        hasInvoiceConcepts: false,
    } as IEditRequest,
    requestDocuments: {
        loadingDocuments: false,
        loadingImages: false,
        imagePopUp: PopUpCodes.NONE,
        documenPopUp: PopUpCodes.NONE,
    },
    seals: emptyOriginSeals(),
    seal: emptyOriginSeal(),
    batches: emptyBatches(),
    batch: emptyBatch(),
    incidences: {
        loadingIncidenceOperational: true,
        loadingIncidenceOrganism: true,
    },
    invoicesConcepts: {
        loading: false,
    },
    stateHistory: {
        stateHistory: [],
        loadingStateHistory: false,
    },
    copyHeaderRequest: {
        parkingAuthorization: true,
        seals: [] as ISeal[],
        batches: [] as IBatch[],
        temporaryRegime: false,
        hasInvoiceConcepts: false,
    } as IEditRequest,
    requestInspection: {
        inspections: emptyInspections(),
        loading: false,
    },
    form: {
        utiForm: false,
        goodInfoForm: false,
        certificatesForm: false,
    },
    isLoadedRequest: false,
    concurrency: {
        isError: false,
        message: "",
    },
    indexTabRequest: 0,
};

interface ISealRemove {
    id?: number;
    index?: number;
}

interface IBatchRemove {
    id?: number;
    index?: number;
}

export const editRequestSlice = createSlice({
    name: "editRequestReducer",
    initialState,
    reducers: {
        updateRequestHeaderProperty: (state, action: PayloadAction<IEditRequest>) => {
            state.headerRequest = action.payload;
        },
        resetRequestState: () => initialState,
        //Seals
        removeNewSealForm: (state, action: PayloadAction<number>) => {
            state.seals.splice(action.payload, 1);
        },
        addSealItem: (state, action: PayloadAction<ISeal>) => {
            state.seals.push({
                code: action.payload.code,
                typeId: action.payload.typeId,
            });
        },
        removeSealEditRequest: (state, action: PayloadAction<ISealRemove>) => {
            if (action.payload.id !== undefined) {
                state.headerRequest.seals = state.headerRequest.seals.filter((seal) => seal.id !== action.payload.id);
            } else if (action.payload.index !== undefined) {
                state.headerRequest.seals.splice(action.payload.index, 1);
            }
        },
        resetSeals: (state) => {
            state.seals = emptyOriginSeals();
        },
        pushSealToList: (state, action: PayloadAction<ISeal[]>) => {
            state.headerRequest.seals = state.headerRequest.seals.concat(action.payload);
        },
        updateSealProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.seal = {
                ...state.seal,
                [action.payload.name]: action.payload.value,
            };
        },
        resetSealProperty: (state) => {
            state.seal = emptyOriginSeal();
        },
        //Batches
        removeNewBatchForm: (state, action: PayloadAction<number>) => {
            state.batches.splice(action.payload, 1);
        },
        addBatchItem: (state, action: PayloadAction<IBatch>) => {
            state.batches.push({
                code: action.payload.code,
            });
        },
        removeBatchEditRequest: (state, action: PayloadAction<IBatchRemove>) => {
            if (action.payload.id !== undefined) {
                state.headerRequest.batches = state.headerRequest.batches.filter((batch) => batch.id !== action.payload.id);
            } else if (action.payload.index !== undefined) {
                state.headerRequest.batches.splice(action.payload.index, 1);
            }
        },
        resetBatches: (state) => {
            state.batches = emptyBatches();
        },
        pushBatchToList: (state, action: PayloadAction<IBatch[]>) => {
            state.headerRequest.batches = state.headerRequest.batches.concat(action.payload);
        },
        updateBatchProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.batch = {
                ...state.batch,
                [action.payload.name]: action.payload.value,
            };
        },
        resetBatchProperty: (state) => {
            state.batch = emptyBatch();
        },
        //Inspections
        setLoadingRequestInspection: (state, action: PayloadAction<boolean>) => {
            state.requestInspection.loading = action.payload;
        },
        setInspections: (state, action: PayloadAction<IInspection[]>) => {
            state.requestInspection.inspections = action.payload;
        },

        pushInspectionToList: (state, action: PayloadAction<IInspection>) => {
            state.requestInspection.inspections.push(action.payload);
        },
        deleteInspectionFromList: (state, action: PayloadAction<number>) => {
            state.requestInspection.inspections.splice(action.payload, 1);
        },
        editInspectionFromList: (state, action: PayloadAction<IInspectionWithIndex>) => {
            state.requestInspection.inspections[action.payload.index] = action.payload.inspection;
        },
        setLoadingInspection: (state, action: PayloadAction<boolean>) => {
            state.requestInspection.loading = action.payload;
        },
        //Documents
        setRequestDocuments: (state, action: PayloadAction<IRequestDocument[] | undefined>) => {
            state.requestDocuments.documents = action.payload;
        },
        setLoadingRequestDocuments: (state, action: PayloadAction<boolean>) => {
            state.requestDocuments.loadingDocuments = action.payload;
        },
        setLoadingRequestImages: (state, action: PayloadAction<boolean>) => {
            state.requestDocuments.loadingImages = action.payload;
        },
        setPopUpRequestDocuments: (state, action: PayloadAction<PopUpCodes>) => {
            state.requestDocuments.documenPopUp = action.payload;
        },
        //Images
        setPopUpRequestImages: (state, action: PayloadAction<PopUpCodes>) => {
            state.requestDocuments.imagePopUp = action.payload;
        },
        setRequestImages: (state, action: PayloadAction<any>) => {
            state.requestDocuments.images = action.payload;
        },
        //Incidences
        setOrganismIncidenceRequest: (state, action: PayloadAction<any>) => {
            state.incidences.incidenceOrganism = action.payload;
        },
        setOperationalIncidenceRequest: (state, action: PayloadAction<any>) => {
            state.incidences.incidenceOperational = action.payload;
        },
        setLoadingIncidenceOperational: (state, action: PayloadAction<boolean>) => {
            state.incidences.loadingIncidenceOperational = action.payload;
        },
        setLoadingIncidenceOrganism: (state, action: PayloadAction<boolean>) => {
            state.incidences.loadingIncidenceOrganism = action.payload;
        },
        //Invoice
        setValidationRemarksInvoicedConcept: (state, action: PayloadAction<string>) => {
            state.headerRequest.validationRemarks = action.payload;
        },
        setInvoicingRemarks: (state, action: PayloadAction<string>) => {
            state.headerRequest.invoicingRemarks = action.payload;
        },
        setRequestInvoiceConcepts: (state, action: PayloadAction<IBillingdata[] | undefined>) => {
            state.invoicesConcepts.invoices = action.payload;
        },
        setLoadedInvoiced: (state, action: PayloadAction<boolean>) => {
            state.invoicesConcepts.loading = action.payload;
        },
        setInvoicedRequest: (state, action: PayloadAction<boolean>) => {
            state.headerRequest.invoiced = action.payload;
        },
        //Request
        setIdRequest: (state, action: PayloadAction<any>) => {
            state.requestId = action.payload;
        },
        setEditRequest: (state, action: PayloadAction<IEditRequest>) => {
            state.headerRequest = action.payload;
        },
        setRequestStateHistory: (state, action: PayloadAction<IRequestStateHistory[]>) => {
            state.stateHistory.stateHistory = action.payload;
            state.stateHistory.loadingStateHistory = true;
        },
        updateRequestStateHistoryFromList: (state, action: PayloadAction<IRequestStateHistoryWithIndex>) => {
            const history = state.stateHistory.stateHistory.find((x) => x.id === action.payload.stateHistory.id);
            if (history != null) {
                history.date = action.payload.stateHistory.date;
            }
        },
        setLoadingStateHistory: (state, action: PayloadAction<boolean>) => {
            state.stateHistory.loadingStateHistory = action.payload;
        },
        setResetEditCheckRegTemp: (state, action: PayloadAction<boolean>) => {
            state.headerRequest.temporaryRegime = action.payload;
            state.copyHeaderRequest.temporaryRegime = action.payload;
        },
        setIsUpdatedDataRequest: (state, action: PayloadAction<IEditRequest>) => {
            state.copyHeaderRequest = action.payload;
        },
        resetIsUpdatedDataRequest: (state) => {
            state.copyHeaderRequest = {
                parkingAuthorization: true,
                seals: [] as ISeal[],
                batches: [] as IBatch[],
                temporaryRegime: false,
                hasInvoiceConcepts: false,
            } as IEditRequest;
        },
        setUtiFormValid: (state, action: PayloadAction<boolean>) => {
            state.form.utiForm = action.payload;
        },
        setGoodInfoFormValid: (state, action: PayloadAction<boolean>) => {
            state.form.goodInfoForm = action.payload;
        },
        setCertificatesFormValid: (state, action: PayloadAction<boolean>) => {
            state.form.certificatesForm = action.payload;
        },
        setEditIsLoaded: (state, action: PayloadAction<boolean>) => {
            state.isLoadedRequest = action.payload;
        },
        setShowFumigatedMessage: (state, action: PayloadAction<boolean>) => {
            state.headerRequest.showFumigatedMessage = action.payload;
        },
        setAvailableDates: (state, action: PayloadAction<IAvailableDates>) => {
            state.availableDates = action.payload;
        },
        updateAppoitmentsZones: (state, action: PayloadAction<Appointment | null | undefined>) => {
            state.headerRequest.appointment = action.payload;
        },
        resetAppoitmentType: (state, action: PayloadAction<number | undefined>) => {
            state.headerRequest.appointmentTypeId = action.payload;
        },
        updateAppoitmentAssigned: (state, action: PayloadAction<boolean>) => {
            state.headerRequest.appointmentAssigned = action.payload;
        },
        setUserNotified: (state, action: PayloadAction<boolean>) => {
            state.headerRequest.showCustomerNotificationMessage = action.payload;
        },
        setCanAssignAppointment: (state, action: PayloadAction<boolean | undefined>) => {
            state.canAssignAppointment = action.payload;
        },
        setConcurrency: (state, action: PayloadAction<IConcurrence>) => {
            state.concurrency = action.payload;
        },
        resetConcurrency: (state) => {
            state.concurrency = {
                isError: false,
                message: "",
            };
        },
        setHasInvoiceConcepts: (state, action: PayloadAction<boolean>) => {
            state.headerRequest.hasInvoiceConcepts = action.payload;
            state.copyHeaderRequest.hasInvoiceConcepts = action.payload;
        },
        setIndexTabRequest: (state, action: PayloadAction<number>) => {
            state.indexTabRequest = action.payload;
        },
    },
});

export const {
    updateRequestHeaderProperty,
    resetRequestState,
    addSealItem,
    removeSealEditRequest,
    removeNewSealForm,
    resetSeals,
    pushSealToList,
    updateSealProperty,
    resetSealProperty,
    addBatchItem,
    removeBatchEditRequest,
    removeNewBatchForm,
    resetBatches,
    pushBatchToList,
    updateBatchProperty,
    resetBatchProperty,
    setInspections,
    setLoadingRequestInspection,
    pushInspectionToList,
    deleteInspectionFromList,
    editInspectionFromList,
    setLoadingInspection,
    setLoadingRequestDocuments,
    setRequestDocuments,
    setRequestImages,
    setIdRequest,
    setEditRequest,
    setOrganismIncidenceRequest,
    setOperationalIncidenceRequest,
    setLoadingIncidenceOperational,
    setLoadingIncidenceOrganism,
    setLoadingRequestImages,
    setValidationRemarksInvoicedConcept,
    setInvoicingRemarks,
    setRequestStateHistory,
    updateRequestStateHistoryFromList,
    setLoadingStateHistory,
    setLoadedInvoiced,
    setInvoicedRequest,
    setResetEditCheckRegTemp,
    setIsUpdatedDataRequest,
    setUtiFormValid,
    setGoodInfoFormValid,
    setEditIsLoaded,
    setCertificatesFormValid,
    setShowFumigatedMessage,
    resetIsUpdatedDataRequest,
    setAvailableDates,
    setRequestInvoiceConcepts,
    setUserNotified,
    updateAppoitmentsZones,
    updateAppoitmentAssigned,
    resetAppoitmentType,
    setCanAssignAppointment,
    setPopUpRequestDocuments,
    setPopUpRequestImages,
    setConcurrency,
    resetConcurrency,
    setHasInvoiceConcepts,
    setIndexTabRequest,
} = editRequestSlice.actions;

export default editRequestSlice.reducer;
