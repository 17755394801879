import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRoleType } from "../../../../../hooks/role/useRoleType";
import { IOrganism } from "../../../../../models";
import { fetchOrganisms } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getOrganimsRoles } from "../../../../../services/user/roleService";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { NumericMultiSelector } from "../../../../common/selectors/multiSelectors/numericMultiSelector/numericMultiSelector";
import { IBaseMultipleNumberSelectorProps } from "../IBaseMultipleSelectorProps";
import { MultipleNumberSelectorFilter } from "../multipleSelectorFilter/multipleNumberSelectorFilter";

export const MultipleOrganismSelector: React.FC<IBaseMultipleNumberSelectorProps> = ({
    autocomplete,
    onChange,
    propertyName,
    title,
    selectedKeys,
    disabled,
}) => {
    const { data: organisms, loading } = useAppSelector((store) => store.catalog.organisms);
    const dispatch = useAppDispatch();
    const { organismId } = useAppSelector((state) => state.userData.profile);
    const isOrganism = useRoleType(getOrganimsRoles());
    const [viewAll, setViewAll] = useState<boolean>(false);
    const [organism, setOrganism] = useState<IOrganism[]>([]);

    const fetchAndSetOrganisms = useCallback(() => {
        if (organisms.length === 0) {
            dispatch(fetchOrganisms());
        }
        if (organisms) {
            const organism = organisms.find((x) => x.id === organismId);
            organism && setViewAll(organism.viewAll ?? false);
            const organismFiltered = isOrganism ? organisms.filter((x) => x.internal === false) : organisms;
            setOrganism(organismFiltered);
        }
    }, [dispatch, organisms, viewAll, organismId]);

    useEffect(fetchAndSetOrganisms, [dispatch, organisms, viewAll, organismId]);

    const isDisabled = useMemo(() => {
        if (isOrganism) {
            return !viewAll;
        }
        return disabled ?? loading;
    }, [disabled, organismId, viewAll]);

    return autocomplete ? (
        <MultipleNumberSelectorFilter
            selectedKeys={selectedKeys}
            onChange={onChange}
            options={mapIDescriptionToDropDownOptions(organism)}
            label={title}
            propertyName={propertyName}
            isDisabled={true}
        ></MultipleNumberSelectorFilter>
    ) : (
        <NumericMultiSelector
            onChange={onChange}
            selectedKeys={selectedKeys}
            options={mapIDescriptionToDropDownOptions(organism)}
            disabled={isDisabled}
            title={title}
            name={propertyName}
        />
    );
};
