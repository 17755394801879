import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { IGenericPayload } from "../../../../models";
import { IConfigDockBlockItem } from "../../../../models/config/blockDock";

interface IConfigDockBlockFormState {
    form: IConfigDockBlockItem;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IConfigDockBlockFormState = {
    form: {
        dockBlockId: 0,
        dockId: 0,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const configDockBlockFormSlice = createSlice({
    name: "configDockBlockFormSlice",
    initialState,
    reducers: {
        resetConfigDockBlockForm: () => initialState,
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setConfigDockBlockForm: (state, action: PayloadAction<IConfigDockBlockItem>) => {
            state.form = action.payload;
        },
        setConfigDockBlockLoadingForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setConfigDockBlockShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const { updateFormProperty, resetConfigDockBlockForm, setConfigDockBlockForm, setConfigDockBlockLoadingForm, setConfigDockBlockShowPopUp } =
    configDockBlockFormSlice.actions;

export default configDockBlockFormSlice.reducer;
