import { createUseStyles } from "react-jss";

export const StatisticsInspectionPivotFilterStyle = createUseStyles((theme: any) => ({
    requestInspectionContainer: {
        display: "flex",
        flexFlow: "row wrap",
        columnGap: theme.gaps.sizes.sm,
        paddingTop: theme.paddings.sizes.xs,
        width: "100%",
    },
    filterItem: {
        paddingTop: 5,
        width: 205,
    },
    filterItem2: {
        paddingTop: 5,
        width: 420,
    },
}));
