import { IColumn } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IAppointmentPermission } from "../../../../../../../models/masterAppointment/IAppointmentPermission";
import { fetchMasterAppointmentPermission } from "../../../../../../../redux/actions/masterAppointment/appointmentPermission/appointmentPermission";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { setShowPopUpMasterAppointment } from "../../../../../../../redux/reducers/masterAppointment/masterAppointment";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterAppointmentPermissionColumnsStyle } from "./columnStyle.jss";
import { Separator } from "../../../../../../common/separator/separator";
import { IGenericPayload } from "../../../../../../../models";
import { updateMasterAppointmentPermissionProperty } from "../../../../../../../redux/reducers/masterAppointment/appointmentPermission/form";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";

export const MasterAppointmentPermissionColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterAppointmentPermissionColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const dispatch = useAppDispatch();

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IAppointmentPermission>("transportUnitDescription"),
            minWidth: 120,
            maxWidth: 170,
            name: t("APPOINTMENT_PERMISSION.TRANSPORT_UNIT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("transportUnitDescription")}
                    title={t("APPOINTMENT_PERMISSION.TRANSPORT_UNIT")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.transportUnitDescription?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("transportUnitSizeDescription"),
            minWidth: 100,
            maxWidth: 110,
            name: t("APPOINTMENT_PERMISSION.TRANSPORT_SIZE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("transportUnitSizeDescription")}
                    title={t("APPOINTMENT_PERMISSION.TRANSPORT_SIZE")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.transportUnitSizeDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("merchandiseCategoryGroupDescription"),
            minWidth: 160,
            maxWidth: 200,
            name: t("APPOINTMENT_PERMISSION.MERCHANDISE_CATEGORY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("merchandiseCategoryGroupDescription")}
                    title={t("APPOINTMENT_PERMISSION.MERCHANDISE_CATEGORY")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.merchandiseCategoryGroupDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("stateDescription"),
            minWidth: 100,
            maxWidth: 150,
            name: t("APPOINTMENT_PERMISSION.STATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("stateDescription")}
                    title={t("APPOINTMENT_PERMISSION.STATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.stateDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IAppointmentPermission>("previousDays"),
            minWidth: 70,
            maxWidth: 100,
            name: t("APPOINTMENT_PERMISSION.PREVIOUS_DAY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("previousDays")}
                    title={t("APPOINTMENT_PERMISSION.PREVIOUS_DAY")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.previousDays ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("appointmentAssigned"),
            minWidth: 80,
            maxWidth: 100,
            name: t("APPOINTMENT_PERMISSION.APPOINTMENT_ASSIGNED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("appointmentAssigned")}
                    title={t("APPOINTMENT_PERMISSION.APPOINTMENT_ASSIGNED")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span><BooleanValue allOption={true} value={item.appointmentAssigned}/></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("summaryActive"),
            minWidth: 85,
            maxWidth: 100,
            name: t("APPOINTMENT_PERMISSION.SUMMARY_ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("summaryActive")}
                    title={t("APPOINTMENT_PERMISSION.SUMMARY_ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span><BooleanValue allOption={true} value={item.summaryActive}/></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("invoiced"),
            minWidth: 70,
            maxWidth: 100,
            name: t("APPOINTMENT_PERMISSION.INVOICED"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("invoiced")}
                    title={t("APPOINTMENT_PERMISSION.INVOICED")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span><BooleanValue allOption={true} value={item.invoiced}/></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IAppointmentPermission>("actuationTypeGroupDescription"),
            minWidth: 100,
            maxWidth: 150,
            name: t("APPOINTMENT_PERMISSION.ACTUATION_TYPE_GROUP"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("actuationTypeGroupDescription")}
                    title={t("APPOINTMENT_PERMISSION.ACTUATION_TYPE_GROUP")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.actuationTypeGroupDescription?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IAppointmentPermission>("certificateTypeDescription"),
            minWidth: 105,
            maxWidth: 150,
            name: t("APPOINTMENT_PERMISSION.CERTIFICATE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IAppointmentPermission>("certificateTypeDescription")}
                    title={t("APPOINTMENT_PERMISSION.CERTIFICATE_TYPE")}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => <span>{item.certificateTypeDescription?? DefaultValues.EMPTY_LIST }</span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 70,
            maxWidth: 150,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IAppointmentPermission) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMasterAppointment(PopUpCodes.UPDATE));
                            dispatch(fetchMasterAppointmentPermission(item.id ?? 0));
                        }}
                    />

                    <Separator size="auto"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            dispatch(setShowPopUpMasterAppointment(PopUpCodes.DELETE));
                            const payload: IGenericPayload = {
                                name: compileNameOfProperty<IAppointmentPermission>("id"),
                                value: item.id,
                            };
                            dispatch(updateMasterAppointmentPermissionProperty(payload));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
