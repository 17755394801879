import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { IVlcportHistoricFilter } from "../../../../models/vlcPort/IHistoricRequest";
import { convertToLocal } from "../../../../utils";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";

interface IVlcportHistoricFilterState {
    filter: IVlcportHistoricFilter;
}

const initialState: IVlcportHistoricFilterState = {
    filter: {
        endDate: convertToLocal(new Date(), FormatDate.ISO),
        startDate: convertToLocal(new Date(), FormatDate.ISO),
        fileName: "",
        integrationReference: "",
        processed: undefined,
        withErrors: undefined,
        transportUnitNumber: "",
    },
};

export const vlcportHistoricFilterReducer = createSlice({
    name: "vlcportHistoricFilterReducer",
    initialState,
    reducers: {
        resetVlcportHistoricFilter: () => initialState,
        updateVlcportHistoricFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetVlcportHistoricFilter, updateVlcportHistoricFilterProperty } = vlcportHistoricFilterReducer.actions;

export default vlcportHistoricFilterReducer.reducer;
