import { UserStepNumberStyled } from "./stepNumber.jss";

interface IStepNumberProps {
    title: string;
    selected: boolean;
}

export const UserStepNumber = (props: IStepNumberProps) => {
    const stepNumberStyled = UserStepNumberStyled();

    return (
        <>
            <div className={props.selected ? stepNumberStyled.StepNumberSelected : stepNumberStyled.StepNumber}>{props.title}</div>
        </>
    );
};
