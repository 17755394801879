import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IStatisticsOrganismItem, IStatisticsOrganismsIncidenceState } from "../../../../models/statistics/IOrganism";
import { sortArray } from "../../../../utils";

interface IInitialState {
    data: IStatisticsOrganismsIncidenceState;
}
const initialSateData = (): IStatisticsOrganismsIncidenceState => {
    return {
        loading: false,
        incidencesByOrganismStatistic: [],
        sort: emptySortState(),
        totalAverageIncidencesTime: "",
        totalIncidencesTime: "",
        totalIncidencesPercentage: "",
        totalIncidences: 0,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsOrganismsIncidenceListSlice = createSlice({
    name: "statiticsOrganismsIncidenceListSlice",
    initialState,
    reducers: {
        resetIncidenceOrganismList: () => initialState,
        setList: (state, action: PayloadAction<IStatisticsOrganismItem[]>) => {
            state.data.incidencesByOrganismStatistic = action.payload;
        },

        setStatisticsIncidenceOrganismLoading: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
        setStatisticsIncidenceOrganismData: (state, action: PayloadAction<IStatisticsOrganismsIncidenceState>) => {
            state.data = action.payload;
            state.data.sort = {
                column: "",
                orderColumn: "",
            };
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.data.sort = action.payload;
            state.data.incidencesByOrganismStatistic = sortArray(state.data.incidencesByOrganismStatistic, state.data.sort);
        },
    },
});

export const { resetIncidenceOrganismList, setList, setStatisticsIncidenceOrganismLoading, setStatisticsIncidenceOrganismData, updateSortColumn } = statiticsOrganismsIncidenceListSlice.actions;

export default statiticsOrganismsIncidenceListSlice.reducer;
