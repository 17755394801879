import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import {  IGenericPayload } from "../../../../../../models";
import { ICreateRequest, Inspection, InspectionWithIndex } from "../../../../../../models/requests/ICreateRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
    deleteInspectionDraftFromList,
    editInspectionDraftFromList,
    pushInspectionDraftToList,
    resetInspectionDraft,
    setCertificateDraftStepIsValid,
    setInspectionDraft,
    setInspectionShowPopUpDraft,
    updateInspectionDraftProperty,
} from "../../../../../../redux/reducers/request/draft/draft";
import { compileNameOfProperty } from "../../../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { Checkboxs } from "../../../../../common/checkbox/checkbox";
import { RequiredSpan } from "../../../../../common/required/requiredSpan";
import { TitleH5 } from "../../../../../common/titles/titles";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { IRequestStepProps } from "../../../../../../models/requests/draft/IRequestStepProps";
import { booleanValidation, renderRequiredData } from "../../validation/requestValidationUtils";
import { GridClass } from "../../../../../../common/enum/GridClass";

import { FormField } from "../../../../../common/fields/fields";
import { ControlledRadioButtonGroup } from "../../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { CarrierSelector, RepresentativePresenceTypeSelector, FinalDestinationSelector } from "../../../../common/selectors";
import { requestMaxLength } from "../../validation/requestConfig";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";

import { InspectionRequestForm } from "../../../../common/form/inspection/form";
import { FormDraftContainerStyle } from "../../containerStyle.jss";
import { InspectionSection } from "../../../../common/list/inspection/inspectionSection";
import { useSize } from "../../../../../../hooks/resizeHook";
import { ColorType } from "../../../../../../common/enum/ColorType";



export const CertificatesData: React.FC<IRequestStepProps> = ({
    schema,
    onInputChange,
    onSelectorChange,
    onYesNoOptionsChange,
    onCheckBoxChange,
    onDateChange,
    onRadioButtonChange,
    organization,
}) => {
    const dispatch = useAppDispatch();

    const theme = useTheme();
    const style = FormDraftContainerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });

    const [indexOrder, setIndexOrder] = useState<number>(-1);
    const [isValidForm, setIsValidForm] = useState<boolean>(false);

    const { height } = useSize();
    const { request, toPresentDraft } = useAppSelector((store) => store.draftRequest);
    const { certificateStepIsValid } = useAppSelector((store) => store.draftRequest.sectionValidation);
    const { form, showPopUp } = useAppSelector((store) => store.draftRequest.inspection);

    const {
        register,
        setValue,
        control,
        trigger,
        formState: { errors, isValid },
    } = useForm<ICreateRequest>({ mode: "onChange" });

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("BUTTONS.ADD_INSPECTION_REQUEST");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("BUTTONS.EDIT_INSPECTION_REQUEST");
        }

        return "";
    }, [showPopUp]);

    useEffect(() => {
        toPresentDraft && trigger();
        if (toPresentDraft && request.inspections.length === 0) {
            dispatch(setCertificateDraftStepIsValid(false));
            return;
        }
        const isStepValid = isValid;
        isStepValid !== certificateStepIsValid && dispatch(setCertificateDraftStepIsValid(isStepValid));
    }, [isValid, request.inspections, certificateStepIsValid, toPresentDraft, request]);
    const showRadioButtonRow =
        (schema.fumigated.visible && organization?.fumigated) ||
        (schema.dryCharge.visible && organization?.dryCharge) ||
        (schema.organicProduct.visible && organization?.organicProduct) ||
        (schema.halalCertified.visible && organization?.halalCertified);
    return (
        <>
            <div
                className={`ms-Grid ${style.gridContainer}`}
                dir="ltr"
            >
                {schema.parkingAuthorization.visible && (
                    <div className={GridClass.ROW_GRID}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <Checkboxs
                                label={t("FIELDS.PARKINGAUTHORIZATION")}
                                name={compileNameOfProperty<ICreateRequest>("parkingAuthorization")}
                                isChecked={request.parkingAuthorization}
                                handleChange={onCheckBoxChange}
                                required={schema.parkingAuthorization.required}
                            />
                        </div>
                    </div>
                )}
                {showRadioButtonRow && (
                    <div className={GridClass.ROW_GRID}>
                        {schema.fumigated.visible && organization?.fumigated && (
                            <div className={GridClass.DOBLE_CELL}>
                                <ControlledRadioButtonGroup
                                    selectedKey={String(+request?.fumigated)}
                                    onRadioButtonChange={onRadioButtonChange!}
                                    required={schema.fumigated.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.fumigated.required),
                                        validate: () => booleanValidation(schema.fumigated.required, request.fumigated),
                                    }}
                                    name={compileNameOfProperty<ICreateRequest>("fumigated")}
                                    calloutLabel={t("FIELDS.FUMIGATED_CONTAINER")}
                                    calloutMessage={t("INFO.FUMIGATED")}
                                    disabled={schema.fumigated.readonly}
                                />
                            </div>
                        )}
                        {schema.dryCharge.visible && organization?.dryCharge && (
                            <div className={GridClass.DOBLE_CELL}>
                                <ControlledRadioButtonGroup
                                    selectedKey={String(+request?.dryCharge)}
                                    onRadioButtonChange={onRadioButtonChange!}
                                    required={schema.dryCharge.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.dryCharge.required),
                                        validate: () => booleanValidation(schema.dryCharge.required, request.dryCharge),
                                    }}
                                    name={compileNameOfProperty<ICreateRequest>("dryCharge")}
                                    calloutLabel={t("FIELDS.DRY_CARGO")}
                                    calloutMessage={t("INFO.DRY_CHARGE")}
                                />
                            </div>
                        )}
                        {schema.organicProduct.visible && organization?.organicProduct && (
                            <div className={GridClass.DOBLE_CELL}>
                                <ControlledRadioButtonGroup
                                    label={t("FIELDS.ORGANIC_PRODUCT")}
                                    selectedKey={request?.organicProduct ? String(+request?.organicProduct) : "0"}
                                    onRadioButtonChange={onRadioButtonChange!}
                                    required={schema.organicProduct.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.organicProduct.required),
                                        validate: () => booleanValidation(schema.organicProduct.required, request.organicProduct),
                                    }}
                                    name={compileNameOfProperty<ICreateRequest>("organicProduct")}
                                />
                            </div>
                        )}
                        {schema.halalCertified.visible && organization?.halalCertified && (
                            <div className={GridClass.DOBLE_CELL}>
                                <ControlledRadioButtonGroup
                                    label={t("FIELDS.HALAL")}
                                    selectedKey={request?.halalCertified ? String(+request?.halalCertified) : "0"}
                                    onRadioButtonChange={onRadioButtonChange!}
                                    required={schema.halalCertified.required}
                                    control={control}
                                    rules={{
                                        required: renderRequiredData(schema.halalCertified.required),
                                        validate: () => booleanValidation(schema.halalCertified.required, request.halalCertified),
                                    }}
                                    name={compileNameOfProperty<ICreateRequest>("halalCertified")}
                                />
                            </div>
                        )}
                    </div>
                )}

                <div className={GridClass.ROW_GRID}>
                    {schema.exporter.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <FormField
                                type="text"
                                value={request.exporter}
                                maxLength={requestMaxLength.EXPORTER}
                                label={t("FIELDS.EXPORTER")}
                                isRequired={schema.exporter.required}
                                error={errors.exporter}
                                {...register("exporter", {
                                    onChange: onInputChange,
                                    validate: () => (schema.exporter.required ? !!request.exporter : true),
                                    required: renderRequiredData(schema.exporter.required),
                                })}
                            />
                        </div>
                    )}
                    {schema.importer.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <FormField
                                type="text"
                                value={request.importer}
                                maxLength={requestMaxLength.IMPORTER}
                                label={t("FIELDS.IMPORTER")}
                                isRequired={schema.importer.required}
                                error={errors.importer}
                                {...register("importer", {
                                    onChange: onInputChange,
                                    validate: () => (schema.importer.required ? !!request.importer : true),
                                    required: renderRequiredData(schema.importer.required),
                                })}
                            />
                        </div>
                    )}
                </div>
                <div className={GridClass.ROW_GRID}>
                    {schema.carrierId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <CarrierSelector
                                onChange={onSelectorChange}
                                selectedKey={request.carrierId}
                                title={t("FIELDS.CARRIER")}
                                propertyName={compileNameOfProperty<ICreateRequest>("carrierId")}
                                isRequired={schema.carrierId.required}
                                control={control}
                                rules={{
                                    required: renderRequiredData(schema.carrierId.required),
                                    validate: () => (schema.remarks.required ? !!request.carrierId : true),
                                }}
                            />
                        </div>
                    )}
                    {schema.representativePresenceId.visible && (
                        <div className={GridClass.LONG_DOBLE_CELL}>
                            <RepresentativePresenceTypeSelector
                                onChange={onSelectorChange}
                                selectedKey={request.representativePresenceTypeId}
                                propertyName={compileNameOfProperty<ICreateRequest>("representativePresenceTypeId")}
                                title={t("FIELDS.REPRESENTATIVE_PERSON")}
                                isRequired={schema.representativePresenceId.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.representativePresenceId.required) }}
                            />
                        </div>
                    )}
                </div>

                <div className={GridClass.ROW_GRID}>
                    {schema.remarks.visible && (
                        <div className={GridClass.SIMPLE_CELL}>
                            <FormField
                                type="text"
                                value={request.remarks}
                                label={t("FIELDS.REMARKS")}
                                isRequired={schema.remarks.required}
                                error={errors.remarks}
                                {...register("remarks", {
                                    onChange: onInputChange,
                                    validate: () => (schema.remarks.required ? !!request.remarks : true),
                                    required: renderRequiredData(schema.remarks.required),
                                })}
                            />
                        </div>
                    )}
                </div>

                {schema.finalDestinationTypeId.visible && (
                    <div className={GridClass.ROW_GRID}>
                        <div className={GridClass.SIMPLE_CELL}>
                            <FinalDestinationSelector
                                onChange={onSelectorChange}
                                selectedKey={request.finalDestinationTypeId}
                                propertyName={compileNameOfProperty<ICreateRequest>("finalDestinationTypeId")}
                                title={t("FIELDS.FINALDESTINATION")}
                                isRequired={schema.finalDestinationTypeId.required}
                                control={control}
                                rules={{ required: renderRequiredData(schema.finalDestinationTypeId.required) }}
                                setValue={setValue}
                            />
                        </div>
                    </div>
                )}

                <div className={GridClass.ROW_GRID}>
                    <div className={GridClass.SIMPLE_CELL}>
                        <div className={style.inspectionSection}>
                            <div className={style.inspectionDataTitle}>
                                <TitleH5
                                    title={t("TITLES.INSPECTION_SECTION")}
                                    color={ColorType.primary}
                                    bold={true}
                                />
                                {schema.inspection_create.visible && (
                                    <div className={style.inspectionButtonRow}>
                                        <ButtonPrimaryInverseWithBorder
                                            handleClick={() => {
                                                dispatch(setInspectionShowPopUpDraft(PopUpCodes.CREATE));
                                            }}
                                            title={`+ ${t("BUTTONS.ADD_INSPECTION_REQUEST")}`}
                                        />
                                        {schema.inspection_create.required && <RequiredSpan />}
                                    </div>
                                )}
                            </div>
                            <div className={style.inspectionCards}>
                                <InspectionSection
                                    setIndexOrder={setIndexOrder}
                                    setInspection={(inspection: Inspection) => {
                                        dispatch(setInspectionDraft(inspection));
                                    }}
                                    setInspectionShowPopUp={(code: PopUpCodes) => {
                                        dispatch(setInspectionShowPopUpDraft(code));
                                    }}
                                    deleteInspection={(index: number) => {
                                        dispatch(deleteInspectionDraftFromList(index));
                                    }}
                                    inspections={request.inspections}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PopUpForm
                content={
                    <InspectionRequestForm
                        schema={schema}
                        updateInspectionProperty={(payload: IGenericPayload) => {
                            dispatch(updateInspectionDraftProperty(payload));
                        }}
                        setValidForm={setIsValidForm}
                        inspection={form}
                    ></InspectionRequestForm>
                }
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={() => {
                    dispatch(resetInspectionDraft());
                }}
                onSave={() => {
                    if (showPopUp === PopUpCodes.CREATE) {
                        dispatch(pushInspectionDraftToList(form));
                    } else if (showPopUp === PopUpCodes.UPDATE) {
                        dispatch(editInspectionDraftFromList({ index: indexOrder, inspection: form } as InspectionWithIndex));
                    }

                    dispatch(resetInspectionDraft());
                }}
                height={height <= 746 ? "50vh" : "40vh"}
                width={"auto"}
                isDisabledButton={!isValidForm}
            ></PopUpForm>
        </>
    );
};
