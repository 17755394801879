import { createUseStyles } from "react-jss";

export const AssignmentListStyle = createUseStyles((theme: any) => ({
    containerList: {
        width: "50%",
        paddingLeft: theme.paddings.sizes.base,
        paddingRight: theme.paddings.sizes.base,
    },
    headerTable: {
        backgroundColor: theme.colors.palette.purpleLight,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: theme.paddings.sizes.sm,

        "& .code": {
            color: theme.colors.palette.purple,
            fontWeight: "600",
        },
        "& .last":{
            fontSize: theme.fonts.sizes.sm
        }
    },
}));
