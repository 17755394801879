export enum schemaRole {
    DEFAULT = "defaultRole",
    CARRIER = "adminCarrier",
}

export enum schemaView {
    USER_DATA = "userData",
    ENTITY_DATA = "entityData",
    PAYMENT_DATA = "paymentData",
    ADMIN_DATA = "adminData",
    OPERATOR_DATA = "operatorData",
    DOCUMENT_DATA = "documentData",
}
