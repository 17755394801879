import { createUseStyles } from "react-jss";

export const CustomIconsStyle = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        marginLeft: props.margin ? props.margin : theme.margins.icons.default,
        marginRight: props.margin ? props.margin : theme.margins.icons.default,
        display: "flex",
        alignItems: props.columnPosition ? props.columnPosition : "center",
        justifyContent: props.position ? props.position : "end",
        width: props.isMobile && props.isButton ? "20%" : "",
        "& .ms-Button": {
            flex: "1 1 auto",
        },
        "& button.is-disabled": {
            background: "transparent",
        },
        "& .ms-TooltipHost": {
            width: props.isMobile ? "100%" : "auto",
            display: "flex",
            justifyContent: props.isMobile ? "center" : "end",
            //marginTop:5,
            "& .ms-Button": {
                flex: "1 1 auto",
            },
        },
        "& .ms-Button-menuIcon": {
            display: "none",
        },
    }),

    icon: (props: any) => ({
        width: props.size ? props.size : 16,
        fontSize: props.size ? props.size : 16,
        fill: theme.colors.palette.purple,
        cursor: props.isDisabled || props.disabledSelect ? "" : "pointer",
        userSelect: "none",
    }),

    isDisabled: {
        opacity: "0.6",
        backgroundColor: "transparent",
        cursor: "",
    },
}));
