import { pageSizes } from "../../../../common/constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IManagementRepresentation } from "../../../../models/management/representation/IManagementRepresentation";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { getFirstItemNumber, getLastItemNumber, getTotalPages } from "../../../../utils";

interface ManagementRepresentationListState {
    list: IManagementRepresentation[];
    sort: ISortState;
    pagination: IPaginationState,
    loading: boolean;
}

const initialState: ManagementRepresentationListState = {
    list: [],
    sort: emptySortState(),
    pagination: emptyPaginationState(pageSizes.MANAGEMENT_REPRESENTATION),
    loading: false
};

export const managementRepresentationListSlice = createSlice({
    name: "managementRepresentationList",
    initialState,
    reducers: {
        resetManagementRepresentationList: () => initialState,

        setLoadingManagementRepresentationList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementRepresentationList: (state, action: PayloadAction<IManagementRepresentation[]>) => {
            state.list = action.payload;
            state.pagination.totalItems = action.payload.length;
            state.pagination.totalPages = getTotalPages(state.pagination);
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
    },
});

export const {
    resetManagementRepresentationList,
    setLoadingManagementRepresentationList,
    fetchManagementRepresentationList,
    updateSortColumn,
    setPage,
} = managementRepresentationListSlice.actions;

export default managementRepresentationListSlice.reducer;
