import { useEffect, useMemo } from "react";
import { SkeletonSizes } from "../../../../common/enum/sizes";
import { fetchInvoicingNonSynchronizedAction } from "../../../../redux/actions/invoicing/nonSynchronized/nonSynchronized";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { sortArray } from "../../../../utils";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { InvoicingNonSynchronizedColumn } from "./column/column";

export const InvoicingNonSynchronizedList = () => {
    const dispatch = useAppDispatch();
    const { list, loading, sort } = useAppSelector((state) => state.invoicingNonSynchronizedList);

    useEffect(() => {
        list === undefined && dispatch(fetchInvoicingNonSynchronizedAction());
    }, [list]);

    const renderColumns = () => {
        return InvoicingNonSynchronizedColumn();
    };

    const sortList = useMemo(() => {
        return list ? sortArray(list, sort) : [];
    }, [list, sort]);

    return (
        <>
            {!loading ? (
                <>
                    <CustomDetailsList
                        data={sortList ?? []}
                        renderColumns={renderColumns}
                        height={"calc(100vh - 9rem)"}
                    />
                </>
            ) : (
                <>
                    <Skeleton rows={SkeletonSizes.DEFAULT} />
                </>
            )}
        </>
    );
};
