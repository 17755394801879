import { stringDefaultValue } from "../../../utils";

export interface IManagementRepresentationFilter {
    userId: string;
    representativeUserId: string;
    validated?: boolean;
}

export const emptyManagementRepresentationFilter = (): IManagementRepresentationFilter => {
    return {
        userId: stringDefaultValue.key.toString(),
        representativeUserId: stringDefaultValue.key.toString(),
        validated: false,
    };
};
