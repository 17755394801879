import React, { useEffect, useMemo } from "react";
import { fetchCatalogAppointmentTypes } from "../../../../../../../../../../redux/actions/masterAppointment/appointmentType/appointmentType";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../../../../../../utils";
import { Selector } from "../../../../../../../../../common/selectors/selector";

interface IAppointmentTypeSelector {
    title: string;
    onChange: (keysSelected: any, name: string) => void;
    selectedKey: any;
    disabled?: boolean;
    className?: string;
    name: string;
    isRequired?: boolean;
}

export const AppointmentTypesSelector: React.FC<IAppointmentTypeSelector> = ({
    name,
    onChange,
    selectedKey,
    title,
    className,
    isRequired,
    disabled,
}) => {
    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => {
        onChange(option, name);
    };
    const dispatch = useAppDispatch();
    const { data } = useAppSelector((state) => state.catalog.appointmentTypes);
    useEffect(() => {
        data === undefined && dispatch(fetchCatalogAppointmentTypes());
    }, [data]);

    const options = useMemo(() => {
        return mapIDescriptionToDropDownOptions(data ?? []);
    }, [data]);
    return (
        <Selector
            selectedKey={selectedKey}
            name={name}
            title={title}
            options={options}
            onChange={onSelectorChange}
            required={isRequired}
            className={className}
            disabled={disabled}
        />
    );
};
