import { RequiredSpan } from "../required/requiredSpan";
import { LabelStyle } from "./labelStyle.jss";

interface ILabelProps {
    title: string;
    className?: string;
    isRequired?: boolean;
}

export const Label = (props: ILabelProps) => {
    const labelStyle = LabelStyle();

    return (
        <>
            <label className={`${props.className} ${labelStyle.label}`}>
                {props.title} {props.isRequired && <RequiredSpan />}
            </label>
        </>
    );
};
