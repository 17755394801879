import { createUseStyles } from "react-jss";

export const StatisticsRequestGraphContainerStyle = createUseStyles((theme: any) => ({
    content: {
        height: "calc(100vh - 9rem)",
        marginTop: 15,
        paddingLeft: 24,
        backgroundColor: "white",
        overflow: "hidden",
    },
    container: {
        height: "auto",
        marginTop: 15,
        paddingLeft: 24,
        backgroundColor: "white",
    },
    title: {
        width: "100%",
        textAlign: "center",
        padding: 20,
    },
    message: {
        width: "100%",
        textAlign: "center",
    },
    
    chart: {
        width: "100%",
        padding: 15,
    },
}));
