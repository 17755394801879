import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { BatchItemStyle } from "./batchItemStyle.jss";

interface IProps {
    batch: string;
    index: number;
    removeBatch:(index:number)=>void;
}

export const BatchItem: React.FC<IProps> = ({ batch, index, removeBatch }) => {
    const style = BatchItemStyle();

    const onRemoveItem = () => {
        removeBatch(index);
    };

    return (
        <>
            <div className={style.container}>
                <div className={style.content}>
                    <div className={style.text}>{batch}</div>
                    <CustomIcons
                        iconName="Cancel"
                        onClick={onRemoveItem}
                        className={style.button}
                    ></CustomIcons>
                </div>
            </div>
        </>
    );
};
