import { useTheme } from "react-jss";
import { CookiesContentStyles } from "./cookiesContent.jss";
import { routeUrls } from "../../../common/constants";
import { useTranslation } from "react-i18next";

export const CookieLink = () => {
    const theme = useTheme();
    const styles = CookiesContentStyles({ theme });
    const { t } = useTranslation("common", { keyPrefix: "SITE.SITE_FOOTER" });

    return (
        <p>
            <a
                className={styles.link}
                href={routeUrls.LEGAL_NOTICE}
            >
                {t("LEGAL_NOTICE")}
            </a>
            |
            <a
                className={styles.link}
                href={routeUrls.PRIVACY_POLICY}
            >
                {t("PRIVACY_POLICY")}
            </a>
            |
            <a
                className={styles.link}
                href={routeUrls.COOKIES_POLICE}
            >
                {t("COOKIE_POLICE")}
            </a>
        </p>
    );
};
