import { createSlice } from "@reduxjs/toolkit";

export interface StepperState {
    step: number;
}
const initialState: StepperState = {
    step: 1,
};

export const userSignupStepperSlice = createSlice({
    name: "userSignupStepper",
    initialState,
    reducers: {
        nextStep: (state) => {
            state.step++;
        },
        previousStep: (state) => {
            state.step--;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
    },
});

export const { nextStep, previousStep, setStep } = userSignupStepperSlice.actions;

export default userSignupStepperSlice.reducer;
