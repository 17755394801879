import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { IVlcportHistoricList } from "../../../../models/vlcPort/IHistoricRequest";
import { VlcportHistoricListColumn } from "./column/column";
import { SkeletonSizes } from "../../../../common/constants";

interface IVlcportHistoricListContainer {
    list?: IVlcportHistoricList[];
    loading: boolean;
    selectedKey: string;
}

export const VlcportHistoricList: React.FC<IVlcportHistoricListContainer> = ({ list, loading, selectedKey }) => {
    const renderColumns = () => {
        return VlcportHistoricListColumn(selectedKey);
    };

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={renderColumns}
            height="calc(100vh - 20.0rem)"
        />
    );
};
