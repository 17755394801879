import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IUserSignupState from "../../../../models/user/IUserSignupState";

interface UserSignupStateState {
    data: IUserSignupState[];
    isLoading: boolean;
}

const initialState: UserSignupStateState = {
    data: [], 
    isLoading: false
};

export const userSignupStateSlice = createSlice({
    name: "userSignupStates",
    initialState,
    reducers: {
        reset: () => initialState,
        setData: (state, action: PayloadAction<IUserSignupState[]>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    },
});

export const {
    reset,
    setData,
    setLoading,
} = userSignupStateSlice.actions;

export default userSignupStateSlice.reducer;
