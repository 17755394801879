import { InspectionCardApi } from "../../../../../api/inspectionCards";
import { throwErrorToastrAsync } from "../../../../../utils";
import { AppThunk } from "../../../../store";
import { setGlobalLoadingAction } from "../../../common";
const inspectionCardApi = new InspectionCardApi(); 
export const getInspectionCardRequest =
    (requestId: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            const response = await inspectionCardApi.getRequestInspectionCardAsync(requestId);
            window.open(response);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setGlobalLoadingAction(false));
        }
    };