import { Resolver } from "react-hook-form";
import { IOrganizationData } from "../../../../models/organization/IOrganizationData";
import { compileNameOfProperty } from "../../../../utils";
import { isValidEmail } from "../../../../common/validations/validationsForm";

export const OrganiationResolver: Resolver<IOrganizationData> = async (values: IOrganizationData) => {
    let errors = {};

    Object.entries(values).forEach(([name, value]) => {
        if ((compileNameOfProperty<IOrganizationData>("email") === name || compileNameOfProperty<IOrganizationData>("fromEmail") === name) && value) {
            if (!isValidEmail(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.EMAIL",
                    },
                };
            }
        } else if (compileNameOfProperty<IOrganizationData>("contactEmail") === name) {
            if (value && !isValidEmail(value)) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.EMAIL",
                    },
                };
            }
        } else {
            if (!value) {
                errors = {
                    ...errors,
                    [name]: {
                        type: "required",
                        message: "FORM.VALIDATION.REQUIRE",
                    },
                };
            }
        }
    });
    return { values, errors };
};
