import { createUseStyles } from "react-jss";

export const ManagementUserCustomerFormEntityAdminEmailListStyle = createUseStyles((theme: any) => ({
  container : {
    display:"flex", 
    flexDirection:"column", 
    gap:30
  }, 
  emailListContainer : {
    display:"flex", 
    flexDirection:"column", 
    gap:35
  }
}));
