import { IGenericPayload } from "../../../../models";
import { DaySelector } from "../../../../models/requests/IDaySelector";
import { IStatisticsFilter } from "../../../../models/statistics/IStatisticsFilters";
import { CustomDatePicker } from "../../../common/customDatePicker/customDatePicker";
interface IStatisticsDateRangeControllerProps {
    filter: IStatisticsFilter;
    updateFilterProperty: (value: IGenericPayload) => any;
}
export const StatisticsDateRangeController: React.FC<IStatisticsDateRangeControllerProps> = ({ filter, updateFilterProperty }) => {
    const setFilterProperty = (name: any, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };
        return updateFilterProperty(payload);
    };

    return (
        <CustomDatePicker
            startDate={filter.startDate}
            endDate={filter.endDate}
            maxDaySelected={DaySelector.MAX_YEAR_SELECTED}
            minDaySelected={DaySelector.MIN_DAYS_SELECTED}
            setFilterProperty={setFilterProperty}
            showLeftArrowController={false}
        />
    );
};
