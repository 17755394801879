import { useTheme } from "react-jss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { ButtonPrimaryInverseWithBorder } from "../../../../../../common/buttons/buttons";
import { TitleH5 } from "../../../../../../common/titles/titles";
import { imageContainerStyle } from "./containerStyle";
import { IConfigEditRequest } from "../../../../../../../models/requests/IConfigEditRequest";
import { EditRequestImageList } from "./list/list";
import { PopUpForm } from "../../../../../../common/popup/popUpForm/popUpForm";
import { uploadImageRequest } from "../../../../../../../redux/actions/request/edit/action/requestEdit";
import { EditRequestImagesForm } from "./form/form";
import { widths } from "../../../../../../../styles/romeu/widths";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../../../../common/constants";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { setPopUpRequestImages } from "../../../../../../../redux/reducers/request/edit/editRequest";

interface IEditRequestImageContainerProps {
    schema: IConfigEditRequest;
    requestId: number;
}

export const EditRequestImageContainer: React.FC<IEditRequestImageContainerProps> = ({ schema, requestId }) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = imageContainerStyle({ theme });
    const { loadingImages, imagePopUp } = useAppSelector((store) => store.editRequest.requestDocuments);

    const [imageRequestList, setImageRequestList] = useState<File[]>([]);
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    const onDimissed = () => {
        setImageRequestList([]);
    };
    const onCloseImageForm = () => {
        dispatch(setPopUpRequestImages(PopUpCodes.NONE));
    };

    const onSaveImageForm = () => {
        dispatch(uploadImageRequest(imageRequestList, requestId)).then((response: any) => {
            response && onCloseImageForm();
        });
    };

    return (
        <div className={style.container}>
            <div className={style.documentHeader}>
                <TitleH5
                    title={t("IMAGE")}
                    bold={true}
                />
                {schema.image_create.visible && (
                    <ButtonPrimaryInverseWithBorder
                        handleClick={() => {
                            dispatch(setPopUpRequestImages(PopUpCodes.CREATE));
                        }}
                        title={`+ ${t("ADD_IMAGE")}`}
                    />
                )}
            </div>
            <EditRequestImageList
                schema={schema}
                requestId={requestId}
            />

            <PopUpForm
                isVisible={imagePopUp === PopUpCodes.CREATE}
                onCancel={onCloseImageForm}
                content={<EditRequestImagesForm setImageList={setImageRequestList} />}
                height={"30vh"}
                width={isMobile ? "80vw" : widths.editRequest.document.width}
                title={t("ADD_IMAGE")}
                onSave={onSaveImageForm}
                isDisabledButton={!imageRequestList.length}
                isLoading={loadingImages}
                onDimissed={onDimissed}
            />
        </div>
    );
};
