import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IStatisticsFilter } from "../../../../../models/statistics/IStatisticsFilters";
import { compileNameOfProperty } from "../../../../../utils";
import { MultipleContainerSizesSelector } from "../../../../request/common/selectors/containerSize/multipleContainerSize";
import { MultipleTransportUnitSelector } from "../../../../request/common/selectors/transportUnit/multipleTransportUnit";
import { StatisticsUtiPivotFilterStyle } from "./statisticsUtiStyle.jss";
import { AllCustomerSelector } from "../../../../common/selectors/controllerSelectors/customerSelector/allCustomerSelector";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    onInputChange: (event: any, name: string) => void;
    onMultipleStringKeysChange: (name: string, options: string[]) => void;
    onInputNumberChange: (value: number | undefined, name: string) => void;
    filter: IStatisticsFilter;
}

export const StatisticsUtiPivotFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, onMultipleStringKeysChange, filter }) => {
    const theme = useTheme();
    const styles = StatisticsUtiPivotFilterStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.FILTER" });

    return (
        <div className={styles.statisticsUtiContainer}>
            <div className={styles.filterItem}>
                <MultipleTransportUnitSelector
                    selectedKeys={filter.transportUnitIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("transportUnitIds")}
                    title={t("TRANSPORT_UNIT_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>
            <div className={styles.filterItem}>
                <MultipleContainerSizesSelector
                    selectedKeys={filter.transportUnitSizeIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("transportUnitSizeIds")}
                    title={t("CONTAINER_SIZE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            </div>
            <div className={styles.filterItem2}>
                <AllCustomerSelector
                    selectedKeys={filter.customerIds}
                    propertyName={compileNameOfProperty<IStatisticsFilter>("customerIds")}
                    title={t("CUSTOMER")}
                    onChange={onMultipleStringKeysChange}
                    autocomplete={true}
               
                />
            </div>
        </div>
    );
};
