import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterDocumentType } from "../../../../../models/resources/master/IDocumentType";

export interface IMasterDocumentTypesFormState {
    form: IMasterDocumentType;
    loading: boolean;
}

const initialState: IMasterDocumentTypesFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterDocumentTypesForm = createSlice({
    name: "masterDisplayDocksForm",
    initialState,
    reducers: {
        resetMasterDocumentTypesForm: () => initialState,
        setLoadingMasterDocumentTypesForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterDocumentTypesForm: (state, action: PayloadAction<IMasterDocumentType>) => {
            state.form = action.payload;
        },
        updateMasterDocumentTypesProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterDocumentTypesForm, setLoadingMasterDocumentTypesForm, setMasterDocumentTypesForm, updateMasterDocumentTypesProperty } =
    masterDocumentTypesForm.actions;

export default masterDocumentTypesForm.reducer;
