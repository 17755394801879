import React from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";

import { useAppDispatch } from "../../../redux/hooks";
import { setConfigDockMerchandiseShowPopUp } from "../../../redux/reducers/config/dockMerchandise/form";
import { ButtonPrimary } from "../../common/buttons/buttons";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ConfigDockMerchandiseFormController } from "./controller/controller";
import { ConfigDockMerchandiseFilter } from "./filter/filter";
import { ConfigDockMerchandiseList } from "./list/list";

export const ConfigDockMerchandiseContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const dispatch = useAppDispatch();
    return (
        <>
            <NavHeaderComponent
                title={t("DOCK_MERCHANDISE.TITLE")}
                action={
                    <ButtonPrimary
                        title={t("CREATE")}
                        handleClick={() => {
                            dispatch(setConfigDockMerchandiseShowPopUp(PopUpCodes.CREATE));
                        }}
                    />
                }
            />
            <ConfigDockMerchandiseFilter />
            <ConfigDockMerchandiseList />
            <ConfigDockMerchandiseFormController />
        </>
    );
};
