import React from "react";
import { useTranslation } from "react-i18next";
import { IConfigMerchandiseCategoryFilter } from "../../../../models/config/merchandiseCategory";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetConfigMerchandiseCategoryFilter, updateFilterProperty } from "../../../../redux/reducers/config/merchandiseCategory/filter";
import { compileNameOfProperty } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { BooleaMultiSelector } from "../../../common/selectors";
import { CustomMerchandiseCategoryIdSelector } from "../../../request/common/selectors/merchandiseCategoryId/customMerchandiseCategory";
import { CustomMerchandiseTypeSelector } from "../../../request/common/selectors/merchandiseType/customMerchandiseTypeSelector";
import { CustomMerchandiseOriginSelector } from "../../../request/common/selectors/merchandiseOrigin/customMerchandiseOrigin";
import { fetchConfigMerchandiseCategoryListAction } from "../../../../redux/actions/config/merchandiseCategory";
import { ConfigMerchandiseCategoryFilterStyle } from "./filterStyle.jss";
import { useTheme } from "react-jss";
import { DefaultValues } from "../../../../common/enum/DefaultValues";

export const ConfigMerchandiseCategoryFilter = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG" });
    const { filter } = useAppSelector((state) => state.configMerchandiseCategoryFilter);
    const updateProperty = (name: string, value: any) => {
        dispatch(updateFilterProperty({ name, value }));
    };
    const onSelectorChange = (name: string, option: any) => {
        updateProperty(name, option.key);
    };
    const onBooleanSelectorChange = (name: string, nullablebooleanValue: any) => {
        updateProperty(name, nullablebooleanValue);
    };
    const theme = useTheme();
    const style = ConfigMerchandiseCategoryFilterStyle({ theme });
    const contetFilter = (
        <div className={style.filter}>
            <div className={style.item}>
                <CustomMerchandiseTypeSelector
                    onChange={onSelectorChange}
                    selectedKey={filter.merchandiseTypeId !== -1 ? filter.merchandiseTypeId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigMerchandiseCategoryFilter>("merchandiseTypeId")}
                    title={t("MERCHANDISE_TYPE")}
                />
            </div>
            <div className={style.item}>
                <BooleaMultiSelector
                    selectedKey={filter.humanConsumption}
                    propertyName={compileNameOfProperty<IConfigMerchandiseCategoryFilter>("humanConsumption")}
                    title={t("HUMAN_CONSUMPTION")}
                    onChange={onBooleanSelectorChange}
                />
            </div>
            <div className={style.item}>
                <CustomMerchandiseOriginSelector
                    title={t("MERCHANDISE_ORIGIN")}
                    onChange={onSelectorChange}
                    selectedKey={filter.merchandiseOriginId !== -1 ? filter.merchandiseOriginId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigMerchandiseCategoryFilter>("merchandiseOriginId")}
                />
            </div>
            <div className={style.item}>
                <CustomMerchandiseCategoryIdSelector
                    title={t("MERCHANDISE_NATURE")}
                    onChange={onSelectorChange}
                    selectedKey={filter.merchandiseCategoryId !== -1 ? filter.merchandiseCategoryId : DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigMerchandiseCategoryFilter>("merchandiseCategoryId")}
                />
            </div>
        </div>
    );
    const onApplyFilter = () => {
        dispatch(fetchConfigMerchandiseCategoryListAction());
    };
    const onResetFilter = () => {
        dispatch(resetConfigMerchandiseCategoryFilter());
        onApplyFilter();
    };

    return (
        <>
            <FilterHeader
                content={contetFilter}
                onApply={onApplyFilter}
                onReset={onResetFilter}
            />
        </>
    );
};
