import { Dropdown, IDropdown, IDropdownOption, IDropdownStyles, IRefObject } from "@fluentui/react";
import { useMemo } from "react";
import { numericDefaultValue } from "../../../../../utils";
import { MultiSelectorStyled } from "../multiSelector.jss";

interface INumericMultiSelectorProps {
    title: string;
    options: IDropdownOption[];
    onChange: (name: string, keysSelected: number[]) => void;
    comboBoxRef?: IRefObject<IDropdown>;
    selectedKeys: number[];
    disabled?: boolean;
    className?: string;
    name: string;
    isFormSelector?: boolean;
    isRequired?: boolean;
    hideAllSelector?: boolean;
    message?: string;
}

export const NumericMultiSelector: React.FC<INumericMultiSelectorProps> = ({
    onChange,
    selectedKeys,
    title,
    disabled,
    options,
    name,
    comboBoxRef,
    className,
    isFormSelector,
    isRequired,
    hideAllSelector,
    message,
}) => {
    const isDisabled = useMemo(() => {
        return !!disabled;
    }, [disabled]);

    const multiSelectorStyled = MultiSelectorStyled({ isDisabled, isFormSelector });

    const styles: Partial<IDropdownStyles> = {
        root: multiSelectorStyled.rootStyles,
        callout: multiSelectorStyled.callout,
        dropdownItem: multiSelectorStyled.optionText,
        dropdownItemSelected: multiSelectorStyled.optionText,
        title: multiSelectorStyled.inputText,
        label: multiSelectorStyled.label,
    };

    const handleChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        if (option) {
            if (Number(option.key) === Number(numericDefaultValue.key) && option.selected) {
                onChange(name, [Number(numericDefaultValue.key)]);
            } else {
                const newKeys = option?.selected ? [...selectedKeys, Number(option.key)] : selectedKeys.filter((key) => key !== Number(option.key));

                let newKeysFiltered: number[] = [];
                switch (newKeys.length) {
                    case 1:
                        newKeysFiltered = newKeys;
                        break;
                    case 0:
                        newKeysFiltered = [Number(numericDefaultValue.key)];
                        break;
                    default:
                        const optionsWithoutDefaultValue = newKeys.filter((key) => key !== Number(numericDefaultValue.key));
                        newKeysFiltered =
                            options.length === optionsWithoutDefaultValue.length ? [Number(numericDefaultValue.key)] : optionsWithoutDefaultValue;
                        break;
                }
                onChange(name, newKeysFiltered);
            }
        }
    };

    const optionsWithDefaultValue = useMemo(() => {
        if (hideAllSelector) return options;

        const defaultValueArray: IDropdownOption[] = [numericDefaultValue];
        return defaultValueArray.concat(options);
    }, [options]);

    const selectedOptionsTooltip = useMemo(() => {
        const selectedoptions = selectedKeys ? optionsWithDefaultValue.filter((item) => selectedKeys.includes(Number(item.key))) : [];
        return selectedoptions.map((item) => item.text).join("; ");
    }, [optionsWithDefaultValue, selectedKeys]);

    return (
        <div
            className={multiSelectorStyled.multiSelectorContainer}
            title={selectedOptionsTooltip}
        >
            <Dropdown
                multiSelect
                onChange={handleChange}
                componentRef={comboBoxRef}
                selectedKeys={selectedKeys}
                label={title}
                disabled={disabled}
                options={optionsWithDefaultValue}
                className={className}
                styles={styles}
                key={`${name}-${NumericMultiSelector.name}`}
                required={isRequired}
            />
            {message && <div className={multiSelectorStyled.error}>{message}</div>}
        </div>
    );
};
