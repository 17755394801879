import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const ManagementUserDefaultFormStyle = createUseStyles((theme: any) => ({
    containerForm: {
        width: "100%",
        overflowY: "auto",
        minHeight: "35vh"
    },
    gridContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
    },

    discharged: {
        color: theme.colors.messageAlert.warning.color,
        fontSize: theme.fonts.sizes.base,
    },
    documentRow: {
        display: "flex",
        gap: 15,
        alignItems: "center",
    },
    carrierDocument: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
    },
    row: {
        display: "flex",
        alignItems: "end",
        [mediaQueries.MOBILE]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    pivot: {
        paddingBottom: theme.paddings.sizes.base,
        width: "100%",
        height: "100%",
        "& .ms-FocusZone": {
            height: 43,
            borderBottom: `1px solid ${theme.colors.navLinks.primary.color}`,
        },
        "& .ms-Button": {
            height: 42,
            minWidth: 170,
            fontWeight: "bold",
            color: theme.colors.palette.greyPurple,
        },
        "& .ms-Button:hover": {
            color: theme.colors.palette.purple,
            backgroundColor: "transparent",
        },
        "& .ms-Button:active": {
            color: theme.colors.palette.purple,
        },
        "& .is-selected": {
            color: theme.colors.palette.purple,
        },
        "& .is-selected::before": {
            color: theme.colors.palette.purple,
            backgroundColor: theme.colors.palette.purple,
        },
        "& .is-selected::after": {
            color: theme.colors.palette.purple,
        },
    },
    pivotItem: {
        height: "64vh",
        overflowY:"auto"
    },
}));
