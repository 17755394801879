import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterDisplayDoock } from "../../../../../models/resources/master/IDisplayDock";

export interface IMasterDisplayDocksState {
    list?: IMasterDisplayDoock[];
    loading: boolean;
}

const initialState: IMasterDisplayDocksState = {
    loading: false,
};

export const masterDisplayDocksList = createSlice({
    name: "masterDisplayDocksList",
    initialState,
    reducers: {
        resetMasterDisplayDocksList: () => initialState,
        setLoadingMasterDisplayDocksList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterDisplayDocksList: (state, action: PayloadAction<IMasterDisplayDoock[]>) => {
            state.list = action.payload;
        },
    },
});

export const {resetMasterDisplayDocksList, setLoadingMasterDisplayDocksList, setMasterDisplayDocksList } = masterDisplayDocksList.actions;

export default masterDisplayDocksList.reducer;
