import { createUseStyles } from "react-jss";

export const AppointmentBoardEditControllerStyle = createUseStyles((theme: any) => ({
    container: {
        height: "auto",
        paddingLeft: theme.paddings.header.paddingLeft,
        backgroundColor: "white",
        justifyContent: "space-between",
        marginTop: "15px",
    },
}));
