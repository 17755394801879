import { useCallback, useEffect } from "react";
import { FieldErrors, Resolver, useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "../../../../../../common/validations/validationsForm";
import { IEmailItem, IEmailItemWithIndex } from "../../../../../../models";
import { useAppDispatch } from "../../../../../../redux/hooks";
import {
    addOperationEmailItem,
    removeOperationEmailItem,
    updateOperationEmailItem,
    updateOperationEmailItemIsValid,
} from "../../../../../../redux/reducers/user/signup/signup";
import { compileNameOfProperty } from "../../../../../../utils";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { FormField } from "../../../../../common/fields/fields";
import { UserSignupOperationEmailItemStyle } from "./itemStyle.jss";

interface IProps {
    email: IEmailItem;
    isLastIndex: boolean;
    isUniqueIndex: boolean;
    index: number;
}

export const UserSignupOperationEmailItem: React.FC<IProps> = ({ email, isLastIndex, isUniqueIndex, index }) => {
    const style = UserSignupOperationEmailItemStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const emailResolver: Resolver<IEmailItem> = async (values: IEmailItem) => {
        let errors = {};
        Object.entries(values).forEach(([name, value]) => {
            if (name === compileNameOfProperty<IEmailItem>("email")) {
                if (!isValidEmail(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.EMAIL",
                        },
                    };
                }
            }
        });
        return { values, errors };
    };
    const {
        register,
        formState: { errors: validationErrors, isValid },
    } = useForm<any>({ mode: "onChange", resolver: emailResolver });

    useEffect(() => {
        const payload: IEmailItemWithIndex = {
            index: index,
            email: email.email,
            isValid,
        };
        dispatch(updateOperationEmailItemIsValid(payload));
    }, [isValid]);

    const onInputChange = useCallback(
        (event) => {
            const payload: IEmailItemWithIndex = {
                index: index,
                email: event?.target?.value,
                isValid,
            };
            dispatch(updateOperationEmailItem(payload));
        },
        [dispatch],
    );

    const onRemoveItem = () => {
        dispatch(removeOperationEmailItem(index));
    };

    const onAddItem = () => {
        dispatch(addOperationEmailItem());
    };

    const emailRegister: UseFormRegister<IEmailItem> = register;
    const emailErrors: FieldErrors<IEmailItem> = validationErrors;

    return (
        <>
            <div className={style.emailContainer}>
                <div className={style.emailContent}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.EMAIL")}
                        value={email.email}
                        error={emailErrors.email}
                        {...emailRegister(compileNameOfProperty<IEmailItem>("email"), {
                            onChange: onInputChange,
                            value: email.email,
                        })}
                        isRequired={true}
                    />
                    {!isUniqueIndex && (
                        <CustomIcons
                            iconName="Cancel"
                            onClick={onRemoveItem}
                            size={14}
                            margin={"10px"}
                        ></CustomIcons>
                    )}
                </div>
                {isLastIndex && (
                    <div className={style.addEmail}>
                        <CustomIcons
                            iconName="Add"
                            onClick={onAddItem}
                            size={14}
                            margin={"10px"}
                        ></CustomIcons>
                    </div>
                )}
            </div>
        </>
    );
};
