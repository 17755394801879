import { IPaginationState } from "../models/common/IPaginationState";

export const getTotalPages = (pagination: IPaginationState) : number => {
    return Math.ceil(pagination.totalItems / pagination.pageSize);
};

export const getFirstItemNumber = (pagination: IPaginationState) : number => {
    return (pagination.currentPage - 1) * pagination.pageSize + 1;
};

export const getLastItemNumber = (pagination: IPaginationState) : number => {
    return Math.min(pagination.totalItems, (pagination.currentPage - 1) * pagination.pageSize + pagination.pageSize);
};


