import { DetailsRow, IDetailsFooterProps } from "@fluentui/react";
import { useEffect } from "react";
import { useTheme } from "react-jss";
import { fetchStatisticsInspectionDetailedFilterAction } from "../../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsincidenceDetailedListColumn } from "./column/column";
import { StatisticsInspectionDetailedListColumnFooter } from "./column/footer";
import { StatisticsInspectionDetailedContainerStyle } from "./listStyle.jss";
import { SkeletonSizes } from "../../../../common/enum/sizes";

export const StatisticsInspectionDetailedContainer = () => {
    const { loading, inspectionsStatistics } = useAppSelector((store) => store.statiticsInspectionDetailedList.data);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const style = StatisticsInspectionDetailedContainerStyle({ theme });
    const columns = StatisticsincidenceDetailedListColumn();
    const _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps) => {
        const dataFooter = {
            listColumnsFooter: detailsFooterProps.columns,
        };
        const footerColumns = StatisticsInspectionDetailedListColumnFooter(dataFooter);

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={footerColumns}
                item={{}}
                itemIndex={5}
                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                selection={detailsFooterProps.selection}
                styles={style}
            />
        );
    };

    useEffect(() => {
        dispatch(fetchStatisticsInspectionDetailedFilterAction());
    }, []);

    return !loading ? (
        <CustomDetailsList
            data={inspectionsStatistics.inspectionStatistics}
            renderColumns={() => {
                return columns;
            }}
            height={heights.statistics.inspection}
            customFooter={_onRenderDetailsFooter}
        />
    ) : (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    );
};
