import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IBoardHistoryAppointment,
    IBoardHistoryAppointmentDock,
    IBoardHistoryAppointmentTimeZone,
} from "../../../../models/masterAppointment/IAppointmentBoard";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
export interface IAppointmentBoardEditFormState {
    form: IBoardHistoryAppointment;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmentBoardEditFormState = {
    form: {
        id: 0,
        appointmentDocks: [],
        appointmentDockTimeZoneConfigurations: [],
        appointmentTimeZones: [],
        appointmentTypeConfigurations: [],
        date: "",
        merchandiseCategoryGroupCode: "",
        merchandiseCategoryGroupDescription: "",
        merchandiseCategoryGroupId: 0,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const masterAppointmentBoardEdit = createSlice({
    name: "masterAppointmentBoardEdit",
    initialState,
    reducers: {
        resetAppointmentBoardEditForm: () => initialState,
        setLoadingAppointmentBoardEditForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setAppointmentBoardEditForm: (state, action: PayloadAction<IBoardHistoryAppointment>) => {
            state.form = action.payload;
        },
        addAppointmentDocks: (state, action: PayloadAction<IBoardHistoryAppointmentDock>) => {
            state.form.appointmentDocks.push(action.payload);
        },
        removeAppointmentDocks: (state, action: PayloadAction<number>) => {
            state.form.appointmentDocks.splice(action.payload, 1);
        },
        addAppointmentTimeZone: (state, action: PayloadAction<IBoardHistoryAppointmentTimeZone>) => {
            state.form.appointmentTimeZones.push(action.payload);
        },
        removeAppointmentTimeZone: (state, action: PayloadAction<number>) => {
            state.form.appointmentTimeZones.splice(action.payload, 1);
        },
    },
});

export const {
    resetAppointmentBoardEditForm,
    setAppointmentBoardEditForm,
    setLoadingAppointmentBoardEditForm,
    addAppointmentDocks,
    removeAppointmentDocks,
    addAppointmentTimeZone,
    removeAppointmentTimeZone,
} = masterAppointmentBoardEdit.actions;

export default masterAppointmentBoardEdit.reducer;
