import { IContextualMenuItem } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { ManagementIncidenceTypesFilter } from "./filter/filter";
import { ManagementIncidencesTypesFormController } from "./controller/controller";
import { ManagementIncidenceTypesList } from "./list/list";
import { ButtonContextualMenu } from "../../common/contextualMenu/contextualMenu";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setShowPopUpIncidenceTypeForm, updateManagementIncidenceTypeFormProperty } from "../../../redux/reducers/management/incidenceType/form";
import { compileNameOfProperty } from "../../../utils";
import { IIncidenceTypesItem } from "../../../models/management/incidenceType/IIncidenceType";
import { RoleType } from "../../../common/enum";
import { useMemo } from "react";
import {  ButtonPrimary } from "../../common/buttons/buttons";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { IncidenceType } from "../../../common/enum/IncidenceTypes";
export const ManagementIncidenceTypesContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.INCIDENCE_TYPE" });
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((store) => store.userData);
    const isOrganismUser = useMemo(() => {
        let role = profile.roles.find((item) => item.id === RoleType.ORGANISM_ADMIN || item.id === RoleType.ORGANISM_TECHNICIAN || item.id === RoleType.ORGANISM_INSPECTOR);
        return role ? true : false;
    }, [profile]);

    const incidenceTypesContextualMenuOptions = [
        { id: IncidenceType.OPERATIONAL, name: t("CONTEXTUAL_MENU.OPERATIONAL_INCIDENCE") },
        { id: IncidenceType.CHARGE, name: t("CONTEXTUAL_MENU.CHARGE_INCIDENCE") },
    ];

    const menuItems: IContextualMenuItem[] = incidenceTypesContextualMenuOptions.map((type) => {
        return {
            key: type.id,
            text: type.name,
            onClick: () => {
                dispatch(setShowPopUpIncidenceTypeForm(PopUpCodes.CREATE));
                dispatch(
                    updateManagementIncidenceTypeFormProperty({
                        name: compileNameOfProperty<IIncidenceTypesItem>("incidenceType"),
                        value: type.id === IncidenceType.OPERATIONAL,
                    }),
                );
            },
        } as IContextualMenuItem;
    });

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    isOrganismUser ? (
                        <ButtonPrimary
                            title={t("CONTEXTUAL_MENU.BUTTON")}
                            handleClick={() => {
                                dispatch(setShowPopUpIncidenceTypeForm(PopUpCodes.CREATE));
                                dispatch(
                                    updateManagementIncidenceTypeFormProperty({
                                        name: compileNameOfProperty<IIncidenceTypesItem>("incidenceType"),
                                        value: true,
                                    }),
                                );
                            }}
                        />
                    ) : (
                        <ButtonContextualMenu
                            items={menuItems}
                            buttonTitle={t("CONTEXTUAL_MENU.BUTTON")}
                        />
                    )
                }
            />
            <ManagementIncidenceTypesFilter isOrganismUser={isOrganismUser} />
            <ManagementIncidenceTypesList />
            <ManagementIncidencesTypesFormController />
        </>
    );
};
