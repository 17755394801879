import { DatePicker, DayOfWeek } from "@fluentui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { toast } from "react-toastify";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { patchRequestsDate } from "../../../../../redux/actions/request/list/requestList";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSelectedChangeDate } from "../../../../../redux/reducers/request/list/requestlist";
import { setPersistScrollOnDetailsList } from "../../../../../redux/reducers/webConfig/globalConfig";
import { formatDate } from "../../../../../utils";
import { DayPickerStringsES, onFormatDate } from "../../../../common/datePicker/datePicker";
import { RequestListChangeDatePickerStyle } from "./requestListChangeDatePicker.jss";

export const RequestListChangeDatePicker = () => {
    const theme = useTheme();
    const styles = RequestListChangeDatePickerStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "LIST.CHANGE_DATE" });
    const dispatch = useAppDispatch();
    const {
        requestlist: { list, selectedChangeDate },
    } = useAppSelector((state) => state);

    const currentDate = useMemo(() => dayjs(new Date()).startOf("day").toDate(), []);

    const onSelectDate = (date: Date | undefined | null): void => {
        if (date === null) {
            date = undefined;
        }
        dispatch(updateSelectedChangeDate(date));
    };

    const onChangeDate = () => {
        if (!selectedChangeDate) {
            toast.warning(t("SELECT_DATE"));
            return;
        }

        const requestToChange = list.filter((i) => i.changeDate);
        if (requestToChange.length === 0) {
            toast.warning(t("SELECT_ANY_REQUEST"));
            return;
        }

        const requestIds = requestToChange.map(({ id }) => +id!);
        dispatch(patchRequestsDate(requestIds, formatDate(selectedChangeDate, FormatDate.ISO)));
        dispatch(setPersistScrollOnDetailsList(true));
    };

    return (
        <div className={styles.container}>
            <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                strings={DayPickerStringsES}
                placeholder={t("NEW_DATE")}
                formatDate={onFormatDate}
                onSelectDate={onSelectDate}
                value={selectedChangeDate}
                minDate={currentDate}
                className={styles.datePicker}
            />
            <button
                className={styles.datePickerBtn}
                onClick={onChangeDate}
                children={t("UPDATE")}
            />
        </div>
    );
};
