import { Icon } from "@fluentui/react";

import { LinkStyled } from "./links.jss";
import { ColorType } from "../../../common/enum/ColorType";

interface ILinkProps {
    to: string;
    title: string;
    color?: ColorType;
    bold?: boolean;
    icon?: string;
    target?: string;
    download?: boolean;
    className?: string;
    onClick?: (e: any) => any;
    border?:boolean;
}

export const Link = (props: ILinkProps) => {
    const linkStyled = LinkStyled({border:props.border});
    const icon = props.icon ? <Icon iconName={props.icon} /> : "";
    const target = props.target ?? "_self";
    const download = props.download ?? false;
    const className = props.className ?? "";

    const getColorClassName = (color?: ColorType) => {
        switch (color) {
            case ColorType.primary:
                return linkStyled.primary;
            case ColorType.secondary:
                return linkStyled.secondary;
            case ColorType.success:
                return linkStyled.success;
            case ColorType.danger:
                return linkStyled.danger;
            case ColorType.warning:
                return linkStyled.warning;
            case ColorType.info:
                return linkStyled.info;
            default:
                return "";
        }
    };

    return (
        <>
            <a
                className={`${linkStyled.link} ${getColorClassName(props.color)} ${props.bold ? linkStyled.bold : ""} ${className}`}
                href={props.to}
                target={target}
                download={download ? props.title : null}
                onClick={props.onClick}
            >
                {icon}
                {props.title}
            </a>
        </>
    );
};

export const LinkPrimary = (props: ILinkProps) => {
    return (
        <>
            <Link
                color={ColorType.primary}
                {...props}
            />
        </>
    );
};

export const LinkSecondary = (props: ILinkProps) => {
    return (
        <>
            <Link
                color={ColorType.secondary}
                {...props}
            />
        </>
    );
};

export const LinkSuccess = (props: ILinkProps) => {
    return (
        <>
            <Link
                color={ColorType.success}
                {...props}
            />
        </>
    );
};

export const LinkDanger = (props: ILinkProps) => {
    return (
        <>
            <Link
                color={ColorType.danger}
                {...props}
            />
        </>
    );
};

export const LinkWarning = (props: ILinkProps) => {
    return (
        <>
            <Link
                color={ColorType.warning}
                {...props}
            />
        </>
    );
};

export const LinkInfo = (props: ILinkProps) => {
    return (
        <>
            <Link
                color={ColorType.info}
                {...props}
            />
        </>
    );
};
