import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import {
    setLoadingMasterMerchandiseOriginsForm,
    setMasterMerchandiseOriginsForm,
} from "../../../../reducers/resources/master/merchandiseOrigins/form";
import {
    setLoadingMasterMerchandiseOriginsList,
    setMasterMerchandiseOriginsList,
} from "../../../../reducers/resources/master/merchandiseOrigins/list";
import { AppThunk, store } from "../../../../store";

const catalogApi = new CatalogAPI();

export const fetchMasterMerchandiseOriginsList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterMerchandiseOriginsList(true));
        const response = await catalogApi.getMasterMerchandiseOriginsList();
        dispatch(setMasterMerchandiseOriginsList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterMerchandiseOriginsList(false));
    }
};

export const postToMasterMerchandiseOriginsForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterMerchandiseOriginsForm;
        try {
            dispatch(setLoadingMasterMerchandiseOriginsForm(true));
            if (form.id) {
                await catalogApi.putMasterMerchandiseOrigins(form);
            } else {
                await catalogApi.postMasterMerchandiseOrigins(form);
            }
            dispatch(fetchMasterMerchandiseOriginsList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterMerchandiseOriginsForm(false));
            return false;
        }
    };
};

export const fetchMasterMerchandiseOrigin =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterMerchandiseOriginsForm(true));
            const response = await catalogApi.getMasterMerchandiseOrigin(id);
            dispatch(setMasterMerchandiseOriginsForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterMerchandiseOriginsForm(false));
        }
    };

export const patchSortMerchandiseOriginsList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterMerchandiseOriginsList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterMerchandiseOriginsList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterMerchandiseOriginsList(false));
        }
    };
