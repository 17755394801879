import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IControlExpedientItem } from "../../../../../models/control/expedient/IExpedient";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/control/expedients/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty, convertToLocal, onUpdateSortedColumnUtil } from "../../../../../utils";
import { ControlExpedientsColumnsStyle } from "./columnsStyle.jss";
import { useCallback } from "react";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { NameIcons } from "../../../../../assets/icons";
import { Separator } from "../../../../common/separator/separator";
import { fetchControlExpedientItemAction } from "../../../../../redux/actions/control/expedient/expedient";
import { setShowPopUp, updateFormProperty } from "../../../../../redux/reducers/control/expedients/form";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const ControlExpedientsColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = ControlExpedientsColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "CONTROL.EXPEDIENTS.LIST" });
    const dispatch = useAppDispatch();

    const { sort } = useAppSelector((store) => store.controlExpedientsList);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().controlExpedientsList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IControlExpedientItem>("certificateTypeDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: t("DOCUMENT_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("certificateTypeDescription")}
                    title={t("DOCUMENT_TYPE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("certificateTypeDescription"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span>{item.certificateTypeDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IControlExpedientItem>("certificateNumber"),
            minWidth: 100,
            maxWidth: 200,
            name: t("EXPEDIENT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("certificateNumber")}
                    title={t("EXPEDIENT_NUMBER")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("certificateNumber"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span>{item.certificateNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IControlExpedientItem>("inspectorName"),
            minWidth: 100,
            maxWidth: 200,
            name: t("INSPECTOR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("inspectorName")}
                    title={t("INSPECTOR")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("inspectorName"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span>{item.inspectorName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IControlExpedientItem>("createdOn"),
            minWidth: 100,
            maxWidth: 150,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("createdOn")}
                    title={t("DATE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("createdOn"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span>{ item.createdOn ? convertToLocal(item.createdOn, FormatDate.DATE) : DefaultValues.EMPTY_LIST }</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IControlExpedientItem>("year"),
            minWidth: 100,
            maxWidth: 150,
            name: t("YEAR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("year")}
                    title={t("YEAR")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("year"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span>{item.year ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IControlExpedientItem>("alert"),
            minWidth: 100,
            maxWidth: 200,
            name: t("WARNING"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("alert")}
                    title={t("WARNING")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("alert"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span><BooleanValue value={item.alert}></BooleanValue></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IControlExpedientItem>("pending"),
            minWidth: 100,
            maxWidth: 200,
            name: t("PENDING"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IControlExpedientItem>("pending")}
                    title={t("PENDING")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IControlExpedientItem>("pending"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IControlExpedientItem) => <span><BooleanValue value={item.pending}></BooleanValue></span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IControlExpedientItem>("pending"),
            minWidth: 100,
            maxWidth: 200,
            name: t("PENDING"),
            onRenderHeader: () => <></>,
            onRender: (item: IControlExpedientItem) => (
                <div className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            if (item.id) {
                                dispatch(updateFormProperty({ name: "id", value: item.id }));
                                dispatch(setShowPopUp(PopUpCodes.UPDATE));
                                dispatch(fetchControlExpedientItemAction(item.id));
                            }
                        }}
                    ></CustomIcons>

                    <Separator size="none"></Separator>
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        onClick={() => {
                            if (item.id) {
                                dispatch(updateFormProperty({ name: "id", value: item.id }));
                                dispatch(setShowPopUp(PopUpCodes.DELETE));
                            }
                        }}
                    ></CustomIcons>
                </div>
            ),

            isResizable: true,
        },
    ];

    return columns;
};
