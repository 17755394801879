import { store } from "../../../../../../../../redux/store";

export const validateStatesDates = (): number[] => {
    const stateItems = store.getState().editRequest.stateHistory.stateHistory;
    const items: number[] = [];
    stateItems.forEach((item, index) => {
        const prevItem = index <= stateItems.length - 1 && index > 0 ? stateItems[index - 1] : null;
        const nextItem = index >= 0 && index < stateItems.length - 1 ? stateItems[index + 1] : null;
        if (prevItem !== null && item.date < prevItem?.date) {
            items.push(item.id);
        }

        if (nextItem !== null && item.date > nextItem?.date) {
            items.push(item.id);
        }
    });

    return items;
};
