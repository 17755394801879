export const isValidEmail = (email: string) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
    );

export const isValidPhoneNumber = (phoneNumber: string) =>
    /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/.test(
        phoneNumber,
    );

export const isValidContainerNumber = (containerNumber: string) => {
    return /[a-zA-Z]{4}[\d]{7}$/.test(containerNumber);
};

export const isValidNIFCIF = (userId: string) => {
    if (!userId) {
        return false;
    }

    userId = userId.toUpperCase();

    const DNIPattern = /^(\d{8})([A-Z])$/;
    const CIFPattern = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const NIEPattern = /^[XYZ]\d{7,8}[A-Z]$/;

    if (DNIPattern.test(userId)) {
        return isValidDNI(userId);
    } else if (CIFPattern.test(userId)) {
        return isValidCIF(userId);
    } else if (NIEPattern.test(userId)) {
        return isValidNIE(userId);
    }

    return false;
};

const isValidDNI = (dni: string) => "TRWAGMYFPDXBNJZSQVHLCKE".charAt(parseInt(dni, 10) % 23) === dni.charAt(8);
const isValidNIE = (nie: any) => {
    let niePrefix = nie.charAt(0);
    switch (niePrefix) {
        case "X":
            niePrefix = 0;
            break;
        case "Y":
            niePrefix = 1;
            break;
        case "Z":
            niePrefix = 2;
            break;
    }
    return isValidDNI(niePrefix + nie.substr(1));
};
const isValidCIF = (cif: any) => {
    if (cif?.length !== 9) {
        return false;
    }
    const letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
    const digits = cif.substr(1, cif.length - 2);
    const letter = cif.substr(0, 1);
    const control = cif.substr(cif.length - 1);
    let sum = 0;
    let digit;
    if (!letter.match(/[A-Z]/)) {
        return false;
    }
    for (let i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);
        if (isNaN(digit)) {
            return false;
        }
        if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
                digit = Math.floor(digit / 10 + (digit % 10));
            }
            sum += digit;
        } else {
            sum += digit;
        }
    }
    sum %= 10;
    digit = sum !== 0 ? 10 - sum : sum;
    if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
    }
    return String(digit) === control || letters[digit] === control;
};
