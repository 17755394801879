import React from "react";
import { AppointmentHistoricContainer } from "./container";

export class AppointmentHistoricController extends React.Component {
   

    render(): React.ReactNode {
        return <AppointmentHistoricContainer />;
    }
}
