import { RootState } from "../../../store";

export const userClaimsSelector = (state: RootState) => {
    return state.userClaims;
};

export const getUserClaimsToken = (state: RootState) => {
    const userClaims = userClaimsSelector(state);
    return userClaims.token;
};

export const getUserClaimsLoaded = (state: RootState) => {
    const userClaims = userClaimsSelector(state);
    return userClaims.isClaimsLoaded;
};
