import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IStatisticsInspectionGroupedItem } from "../../../../../models/statistics/inspection/IGrouped";
import { useAppSelector } from "../../../../../redux/hooks";
import { calcWidthColumFooter, generateStyleWhithCustomFooter } from "../../../../../utils/footerListUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";

interface IInspectionGroupedColumnFooterProps {
    listColumnsFooter: IColumn[];
}

export const StatisticsInspectionGroupedListColumnFooter = (props: IInspectionGroupedColumnFooterProps): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.GROUPED_TABLE" });
    const {
        totalRequestsPositionedWithInvoiceConcepts: totalInspectionsCount,
        totalRequests: totalRequestsCount,
        totalStayTime: totalInstalationsTime,
        totalInspectionTime: totalInspectionsTime,
        totalIncidences: totalIncidencesCount,
        totalIncidencesPercentage,
        totalIncidencesTime,
        totalStayAverageTime: totalAverageInstalationsTime,
        totalInspectionAverageTime: totalAverageInspectionsTime,
        totalIncidencesAverageTime: totalAverageIncidencesTime,
        totalNetTime: totalAverageNet,
    } = useAppSelector((store) => store.statiticsInspectionGroupedList.data);

    const columns: IColumn[] = [
        {
            key: "",
            minWidth: calcWidthColumFooter(0, 0, props.listColumnsFooter),
            name: "",
            onRender: () => <b style={generateStyleWhithCustomFooter(0, 0, props.listColumnsFooter)}>{t("TOTAL_FINAL")}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("requestsPositionedWithInvoiceConcepts"),
            minWidth: calcWidthColumFooter(1, 1, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("requestsPositionedWithInvoiceConcepts"),
            onRender: () => <b style={generateStyleWhithCustomFooter(1, 1, props.listColumnsFooter)}> {totalInspectionsCount}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("requests"),
            minWidth: calcWidthColumFooter(2, 2, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("requests"),
            onRender: () => <b style={generateStyleWhithCustomFooter(2, 2, props.listColumnsFooter)}> {totalRequestsCount}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayTime"),
            minWidth: calcWidthColumFooter(3, 3, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(3, 3, props.listColumnsFooter)}> {totalInstalationsTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionTime"),
            minWidth: calcWidthColumFooter(4, 4, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(4, 4, props.listColumnsFooter)}> {totalInspectionsTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidences"),
            minWidth: calcWidthColumFooter(5, 5, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidences"),
            onRender: () => <b style={generateStyleWhithCustomFooter(5, 5, props.listColumnsFooter)}> {totalIncidencesCount}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesPercentage"),
            minWidth: calcWidthColumFooter(6, 6, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesPercentage"),
            onRender: () => <b style={generateStyleWhithCustomFooter(6, 6, props.listColumnsFooter)}> {totalIncidencesPercentage}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesTime"),
            minWidth: calcWidthColumFooter(7, 7, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(7, 7, props.listColumnsFooter)}> {totalIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayAverageTime"),
            minWidth: calcWidthColumFooter(8, 8, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayAverageTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(8, 8, props.listColumnsFooter)}> {totalAverageInstalationsTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionTime"),
            minWidth: calcWidthColumFooter(9, 9, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(9, 9, props.listColumnsFooter)}> {totalAverageInspectionsTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesAverageTime"),
            minWidth: calcWidthColumFooter(10, 10, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesAverageTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(10, 10, props.listColumnsFooter)}> {totalAverageIncidencesTime}</b>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("netTime"),
            minWidth: calcWidthColumFooter(11, 11, props.listColumnsFooter),
            name: compileNameOfProperty<IStatisticsInspectionGroupedItem>("netTime"),
            onRender: () => <b style={generateStyleWhithCustomFooter(11, 11, props.listColumnsFooter)}> {totalAverageNet}</b>,
            isResizable: true,
        },
    ];

    return columns;
};
