import { createUseStyles } from "react-jss";

export const SeparatorStyle = createUseStyles((theme: any) => ({
  separator: (props: any) => ({
    borderRight: theme.borders.default.borderDotted,
    height: props.size ?? "80%",
    width: "1px",
    maxWidth:2
  }),
  horizontalSeparator: (props: any) => ({
    borderTop: theme.borders.default.borderDotted,
    width: props.size ?? "80%",
    height: "1px"
  }),
}));
