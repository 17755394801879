import { createUseStyles } from "react-jss";

export const CollapsibleCardStyles = createUseStyles((theme: any) => ({
    collapsibleCardContainer: {
        rowGap: theme.gaps.sizes.sm,
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontWeight: theme.fonts.weight.bold,
        display: "flex",
        cursor: "pointer",
        paddingBottom: 10,
        fontSize: theme.fonts.sizes.md,
        color: theme.colors.titles.primary.color,
        borderBottom: theme.borders.default.purpleBorder,
        alignItems:"center"
    },
    body: {
        fontWeight: theme.fonts.weight.normal,
        transition: "opacity 0.3s ease-out, max-height 0.8s ease-out;",
        paddingBottom: 0,
        display: "flex",
        opacity: 1,
    },
    bodyHidden: {
        display: "none",
    },
}));
