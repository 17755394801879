import { TableListStyles } from "./tableListStyle.jss";

interface ITableListProps {
    headerContent?: React.ReactNode;
    bodyContent: React.ReactNode;
}

export const CustomTableList = (props: ITableListProps) => {
    const style = TableListStyles();
    return (
        <div className={`tableContainer ${style.tableContainer}`}>
            {props.headerContent && <div className={`tableHeader ${style.tableHeader}`}>{props.headerContent}</div>}
                <div className={`tableBody ${style.tableBody}`}>{props.bodyContent}</div>
        </div>
    );
};
