import { createUseStyles } from "react-jss";

export const RequestListAddActuationsStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: 25,
        width: "100%",
    },
    contentIcon: {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        alignItems: "center",
        fontWeight: 600,
        color: theme.colors.palette.black,
        cursor: "pointer",
    },
    iconBtn: {
        padding: 0,
        marginLeft: "auto",
        color: theme.colors.buttons.primary.color,
        width: 30,
        height: 30,
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}));
