import React from "react";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { useAppSelector } from "../../../../redux/hooks";
import { CarrierImportListColumn } from "./column/column";

export const CarrierImportList: React.FC = () => {
    const { loading, importResponse } = useAppSelector((state) => state.carrierImport);
    const onRenderColumn = () => {
        return CarrierImportListColumn();
    };

    return loading ? (
        <Skeleton
            rows={5}
            columns={2}
        />
    ) : (
        <CustomDetailsList
            data={importResponse?.errors ? importResponse.errors : []}
            renderColumns={onRenderColumn}
            height="calc(100v - 7.9rem)"
        />
    );
};
