import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useCallback, useMemo } from "react";
import { useTheme } from "react-jss";
import { useAppDispatch } from "../../../redux/hooks";
import { DatePickerComponent } from "../datePicker/datePicker";
import { TimePickerComponent } from "../timePicker/timePicker";
import { DateTimePickerComponentStyles } from "./dateTimePicker.jss";
import { CustomIcons } from "../customIcons/customIcons";

interface Props {
    name: string;
    label?: string;
    initValue?: Date;
    onChange: (date: Date | undefined | null, name: string) => void;
    disabled?: boolean;
    required?: boolean;
    minDate?: Date;
    dateLabel?: string;
    timeLabel?: string;
    error?: string;
    resetDate?: boolean;
    setResetDate?: (reset: boolean) => any;
    removeDate?: () => void;
}

export const DateTimePickerComponent: React.FC<Props> = ({
    name,
    label,
    initValue,
    onChange,
    minDate,
    dateLabel,
    timeLabel,
    error,
    resetDate,
    setResetDate,
    removeDate,
}) => {
    const theme = useTheme();
    const styles = DateTimePickerComponentStyles({ theme });
    const dispatch = useAppDispatch();

    const onDateChange = (date: Date | undefined | null) => {
        if (!date) return;
    
        const oldDate = initValue ? new Date(initValue) : new Date();
        const currentDate = new Date();
    
        if (minDate && !initValue && oldDate < minDate) {
            const minHours = minDate.getHours();
            const minMinutes = minDate.getMinutes();
            const currentHours = currentDate.getHours();
            const currentMinutes = currentDate.getMinutes();
    
            if (minHours < currentHours || (minHours === currentHours && minMinutes < currentMinutes)) {
                oldDate.setHours(currentHours);
                oldDate.setMinutes(currentMinutes);
            } else {
                oldDate.setHours(minHours);
                oldDate.setMinutes(minMinutes);
            }
        }
    
        const newDate = new Date(
            date.getFullYear(), date.getMonth(), date.getDate(), oldDate.getHours(), oldDate.getMinutes()
        );
        onChange(newDate, name);
    };

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            if (date) {
                const newDate = new Date(date.toISOString());
                onChange(newDate, name);
            }
        },
        [dispatch],
    );

    const removeDateComponent = useMemo(() => {
        if (initValue === undefined || initValue === null) return null;
        return (
            <CustomIcons
                iconName="trash"
                margin={"0px"}
                isButton
                className={styles.removeIcon}
                onClick={removeDate}
            />
        );
    }, [initValue]);

    return (
        <div className={styles.container}>
            {label && <label className={styles.label}>{label}</label>}

            <div className={styles.dateTimePicker}>
                <div className={styles.datePicker}>
                    <DatePickerComponent
                        name={name}
                        label={dateLabel ?? ""}
                        minDate={minDate ?? undefined}
                        onChange={onDateChange}
                        initValue={initValue ?? undefined}
                        resetDate={resetDate}
                        setResetDate={setResetDate}
                    />
                </div>
                <div className={styles.timePicker}>
                    <TimePickerComponent
                        label={timeLabel ?? ""}
                        onChange={onTimeChange}
                        value={initValue ?? null}
                    />
                </div>
                {removeDate && <div>{removeDateComponent}</div>}
            </div>
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
};
