import { createUseStyles } from "react-jss";

export const AvatarStyle = createUseStyles((theme: any) => ({
    content: (props: any) => ({
        cursor: props.edit ? "pointer" : "default",
        position: "relative",
        "> img": {
            transition: ".5s ease",
        },
        "&:hover img": {
            opacity: props.edit ? "0.3" : "1",
        },
        "&:hover $overlay": {
            opacity: "1",
        },
    }),
    avatar: (props: any) => ({
        width: props.size,
        height: props.size,
        overflow: "hidden",
        objectFit: "cover",
        borderRadius: "50%",
    }),
    overlay: {
        transition: ".5s ease",
        opacity: "0",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        fontSize: "6em",
        color: theme.colors.palette.purple,
    },
    input: {
        display: "none",
    },
    errorContainer: (props: any) => ({
        width: props.size,
        color: "red",
        fontSize: 12,
    }),
}));
