import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils/formUtils";
import { FormField } from "../../../../../../common/fields/fields";
import { Selector } from "../../../../../../common/selectors/selector";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { MasterOrganismFormStyle } from "./formStyle.jss";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterTypeSealRemaining, IMasterSealType } from "../../../../../../../models/resources/master/ITypeSeal";
import { updateMasterTypesSealsProperty, setMasterTypesSealsForm } from "../../../../../../../redux/reducers/resources/master/sealTypes/form";
interface IMasterSealTypesFormProps {
    showNotData: boolean;
    data: IMasterTypeSealRemaining[];
}

export const MasterSealTypesForm: React.FC<IMasterSealTypesFormProps> = ({ showNotData, data }) => {
    const theme = useTheme();
    const style = MasterOrganismFormStyle({ theme });
    const dispatch = useAppDispatch();

    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);

    const { form } = useAppSelector((state) => state.masterSealTypesForm);
    const { register, control } = useForm<IMasterSealType>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        const payload: IGenericPayload = {
            name,
            value,
        };

        dispatch(updateMasterTypesSealsProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );
    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = useCallback(
        (event: any, option: any, name: string) => {
            onUpdateFormProperty(name, option.key);
            let seal = data.find((item) => item.id === option.key);
            if (seal) {
                let newForm: IMasterSealType = {
                    active: true,
                    code: seal.code,
                    description: seal.description,
                    referenceId: String(option.key),
                };
                dispatch(setMasterTypesSealsForm(newForm));
            }
        },
        [dispatch, data],
    );
    const disabledInputs = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [form, showPopUp]);
    return showNotData ? (
        <NotDataWarningMessage></NotDataWarningMessage>
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterSealType>("referenceId")}
                        title={t("FORM.SEALS_TYPE")}
                        options={mapIDescriptionToDropDownOptions(data)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? DefaultValues.NOT_TEXT}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterSealType>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? DefaultValues.NOT_TEXT}
                    {...register(compileNameOfProperty<IMasterSealType>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                    disabled={disabledInputs}
                />
            </div>
            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterSealType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInputs}
                />
            </div>
        </div>
    );
};
