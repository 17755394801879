import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
export interface IManagementNotificationFormState {
    form: IManagementNotificationForm;
    loading: boolean;
    showPopUp: PopUpCodes;
}

export interface IManagementNotificationForm {
    id?: number;
    roleId: string;
    notificationModeId: string;
    notificationTypeId: string;
    activeByDefault: boolean;
    scheduleTime: boolean;
    otherEmails: boolean;
    defaultScheduleTime:string;
}

const initialState: IManagementNotificationFormState = {
    form: {
        roleId: "",
        notificationModeId: "",
        notificationTypeId: "",
        activeByDefault: false,
        otherEmails: false,
        scheduleTime: false,
        defaultScheduleTime:""
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const managementNotificationFormSlice = createSlice({
    name: "managementNotificationForm",
    initialState,
    reducers: {
        resetManagementNotificationState: () => initialState,
        resetManagementNotificationForm: (state) => {
            state.form = initialState.form;
        },
        setLoadingManagementNotificationForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        updateManagementNotificationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },

        setManagementNotificationForm: (state, action: PayloadAction<IManagementNotificationForm>) => {
            state.form = action.payload;
        },
        setManagementnotificationFormPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetManagementNotificationForm,
    resetManagementNotificationState,
    setLoadingManagementNotificationForm,
    updateManagementNotificationProperty,
    setManagementNotificationForm,
    setManagementnotificationFormPopUp,
} = managementNotificationFormSlice.actions;

export default managementNotificationFormSlice.reducer;
