import { useCallback, useMemo } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../../../../redux/hooks";
import { updateEntityProfileProperty } from "../../../../../../../redux/reducers/user/profile/userProfile";
import { getUserRole } from "../../../../../../../redux/selectors/user/role/role";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import { FormField } from "../../../../../../common/fields/fields";
import { TitleH4, TitleH5 } from "../../../../../../common/titles/titles";
import { UserProfileEntityInfoFormStyle } from "./infoStyle.jss";
import { UploadFileCustom } from "../../../../../../common/uploadFile/uploadFile";
import { userProfileSelector } from "../../../../../../../redux/selectors/user/profile/userProfile";
import { ColorType } from "../../../../../../../common/enum/ColorType";

import { IEntityData } from "../../../../../../../models";
import { RoleType } from "../../../../../../../common/enum";
import { FileSize } from "../../../../../../../common/enum/files/FileSize";
import { ProfileDocumentTypes } from "../../../../../../../common/enum/ProfileDocumentTypes";

interface IUserProfileEntityInfoFormProps {
    data: IEntityData;
    register: UseFormRegister<IEntityData>;
    errors: FieldErrors<IEntityData>;
}

export const UserProfileEntityInfoForm: React.FC<IUserProfileEntityInfoFormProps> = ({ data, register, errors }) => {
    const style = UserProfileEntityInfoFormStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const userRole = useSelector(getUserRole);

    const userRoleType = useMemo(() => {
        return userRole?.id;
    }, [userRole]);

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateEntityProfileProperty(payload));
        },
        [dispatch],
    );
    const userProfile = useSelector(userProfileSelector);
    const fiscalCaldBlob = useMemo(() => {
        return userProfile.blobs.find((item) => item.type === ProfileDocumentTypes.FISCAL_CARD);
    }, [userProfile]);

    return (
        <>
            <div className={style.row}>
                <TitleH4
                    title={t("USER.TITLES.ENTITY_DATA")}
                    color={ColorType.primary}
                />
            </div>
            <div className={style.row}>
                <div className={style.entityName}>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.ENTITY_NAME")}
                        value={data.name}
                        error={errors.name}
                        disabled={true}
                        {...register(compileNameOfProperty<IEntityData>("name"), { onChange: onInputChange, value: data.name })}
                    />
                </div>

                <div>
                    <FormField
                        type="text"
                        label={t("USER.SIGNUP.CIF")}
                        value={data.documentNumber?.toUpperCase()}
                        error={errors.documentNumber}
                        disabled={true}
                        {...register(compileNameOfProperty<IEntityData>("documentNumber"), {
                            onChange: onInputChange,
                            value: data.documentNumber,
                        })}
                    />
                </div>
            </div>
            <div className={style.row}>
                <FormField
                    type="text"
                    label={t("USER.SIGNUP.FISCAL_ADDRESS")}
                    value={data.socialAddress}
                    error={errors.socialAddress}
                    disabled={true}
                    {...register(compileNameOfProperty<IEntityData>("socialAddress"), {
                        onChange: onInputChange,
                        value: data.socialAddress,
                    })}
                />
            </div>

            <div className={style.row}>
                <FormField
                    type="text"
                    label={t("USER.SIGNUP.POSTAL_CODE")}
                    value={data.postalCode}
                    disabled={true}
                    error={errors.postalCode}
                    {...register(compileNameOfProperty<IEntityData>("postalCode"), {
                        onChange: onInputChange,
                        value: data.postalCode,
                    })}
                />
                <FormField
                    type="text"
                    label={t("USER.SIGNUP.MUNICIPALITY")}
                    disabled={true}
                    value={data.city}
                    error={errors.city}
                    {...register(compileNameOfProperty<IEntityData>("city"), { onChange: onInputChange, value: data.city })}
                />
                <FormField
                    type="text"
                    label={t("USER.SIGNUP.PROVINCE")}
                    disabled={true}
                    value={data.province}
                    error={errors.province}
                    {...register(compileNameOfProperty<IEntityData>("province"), { onChange: onInputChange, value: data.province })}
                />
            </div>

            {userRoleType === RoleType.CUSTOMER_ADMIN && (
                <div className={style.row}>
                    <div className={style.columnDocument}>
                        <TitleH5
                            title={t("USER.TITLES.FISCAL_CARD")}
                            className={style.documentTitle}
                        ></TitleH5>
                    </div>
                    {fiscalCaldBlob && (
                        <UploadFileCustom
                            name={""}
                            onChangeFile={() => {}}
                            title={""}
                            buttonStyle={ColorType.primary}
                            maxSize={FileSize._5MB}
                            hiddeRemoveDocument={true}
                            file={fiscalCaldBlob}
                        />
                    )}
                </div>
            )}
        </>
    );
};
