import { ContentType } from "../enum/files/ContentType";
import { ContentTypeIcon } from "../enum/files/ContentTypeIcon";

const getIconForMimeType = (mimeType: string) => {
    const key = Object.keys(ContentType).find((key) => ContentType[key as keyof typeof ContentType] === mimeType);
    return key ? ContentTypeIcon[key as keyof typeof ContentTypeIcon] : "Document";
};

const bytesToMegabytes = (bytes: number): string => (bytes / 1024 / 1024).toFixed(2);

export default { getIconForMimeType, bytesToMegabytes };
