import { createUseStyles } from "react-jss";

export const ActuationListStyle = createUseStyles((theme: any) => ({
    actuationContainer: (props: any) => ({
        marginTop: theme.margins.sizes.sm,
        backgroundColor: theme.colors.sections.background,
        borderRadius: theme.borders.default.radius,
        width: "100%",
        paddingLeft: 10,
        display: "flex",
        flexDirection: "column",
        marginLeft: `${100 / props.requestInspectionColumnsShow}%`,
    }),

    dropIconContainer: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginBottom: theme.margins.sizes.sx,
        paddingBottom: 5,
        color:theme.colors.palette.purple
    },

    icon: {
        paddingTop: 5,
        paddingRight: 5,
    },
}));
