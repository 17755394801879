import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterSealType } from "../../../../../models/resources/master/ITypeSeal";

export interface IMasterSealTypesState {
    list?: IMasterSealType[];
    loading: boolean;
}

const initialState: IMasterSealTypesState = {
    loading: false,
};

export const masterSealTypesList = createSlice({
    name: "masterOrganismList",
    initialState,
    reducers: {
        resetMasterSealTypesList: () => initialState,
        setLoadingSealTypesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterSealTypesList: (state, action: PayloadAction<IMasterSealType[]>) => {
            state.list = action.payload;
        },
    },
});

export const {resetMasterSealTypesList, setLoadingSealTypesList, setMasterSealTypesList } = masterSealTypesList.actions;

export default masterSealTypesList.reducer;
