import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { emptyRepresentative, IRepresentative } from "../../../../models/user/representatives/IRepresentative";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

interface IUserRepresentative {
    form: IRepresentative;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IUserRepresentative = {
    form: emptyRepresentative(),
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const userRepresentativeSlice = createSlice({
    name: "userRepresentative",
    initialState,
    reducers: {
        updateRepresentativeProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        resetUserRepresentative: (state) => {
            state.form = emptyRepresentative();
            state.loading = false;
            state.showPopUp = PopUpCodes.NONE;
        },
        addUserRepresentative: (state, action: PayloadAction<IRepresentative>) => {
            state.form = action.payload;
        },
        setDocumentRepresentativeFile: (state, action: PayloadAction<File | undefined>) => {
            state.form.document = action.payload;
        },
        setRepresentativeLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setRepresentativeShowPopup: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetUserRepresentative,
    addUserRepresentative,
    updateRepresentativeProperty,
    setDocumentRepresentativeFile,
    setRepresentativeLoading,
    setRepresentativeShowPopup,
} = userRepresentativeSlice.actions;

export default userRepresentativeSlice.reducer;
