import React from "react";
import { useTheme } from "react-jss";
import { Separator } from "../separator/separator";
import { FilterHeaderActionButtons } from "./actionButtons/actionsFilterHeader";
import { FilterHeaderStyle } from "./filterHeaderStyle.jss";
interface IFilterHeaderProps {
    content: React.ReactChild | React.ReactChildren;
    onApply: (event?: any) => void;
    onReset: (event?: any) => void;
    textApplyButton?: string;
    textResetButton?: string;
    buttonColumn?: boolean;
    hideActionButtons?: boolean;
    height?: string | number;
}

export const FilterHeader: React.FC<IFilterHeaderProps> = ({
    content,
    onApply,
    onReset,
    textApplyButton,
    textResetButton,
    buttonColumn,
    height,
    hideActionButtons,
}) => {
    const theme = useTheme();
    const style = FilterHeaderStyle({ theme, height });

    return (
        <div className={style.container}>
            {content}
            {!!!hideActionButtons && (
                <>
                    <Separator size="none" />

                    <FilterHeaderActionButtons
                        onApply={onApply}
                        onReset={onReset}
                        textApplyButton={textApplyButton}
                        textResetButton={textResetButton}
                        column={buttonColumn}
                    />
                </>
            )}
        </div>
    );
};
