
import { SkeletonSizes } from "../../../common/constants";
import { IRequestIntegrations } from "../../../models";
import { CustomDetailsList } from "../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../common/skeleton/skeleton";
import { IntegrationRequestListColumn } from "./column/column";

interface IIntegrationRequestListContainer {
    list?: IRequestIntegrations[];
    loading: boolean;
}

export const IntegrationRequestList: React.FC<IIntegrationRequestListContainer> = ({ list, loading }) => {
    const renderColumns = () => {
        return IntegrationRequestListColumn();
    };

    return loading ? (
        <Skeleton rows={SkeletonSizes.DEFAULT} />
    ) : (
        <CustomDetailsList
            data={list ?? []}
            renderColumns={renderColumns}
            height="calc(100vh - 20.0rem)"
        />
    );
};
