import { UserApi } from "../../../../api";
import { throwErrorToastrAsync } from "../../../../utils";
import { resetUserSignupDocumentTemplates, setLoading, userSignupDocumentTemplatesReceived } from "../../../reducers/user/signup/documentTemplates";
import { AppThunk, store } from "../../../store";

const userApi = new UserApi();

export const fetchUserSignupDocumentTemplatesAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const userType = store.getState().userSignup?.form?.userType;

        const response = await userApi.getUserSignupDocumentTemplatesAsync(userType);
        dispatch(userSignupDocumentTemplatesReceived(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoading(false));
    }
};

export const resetUserSignupDocumentTemplatesAction = (): AppThunk => (dispatch) => {
    dispatch(resetUserSignupDocumentTemplates());
};
