import { createUseStyles } from "react-jss";

export const MasterAppointmentsContainerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.base,
        height:"100%"
    },
}));
