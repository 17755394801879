import { Dropdown, IDropdownProps, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { FC, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useTheme } from "react-jss";
import { HookFormProps } from "../../../../models";
import { SelectorStyled } from "../selectorStyle.jss";
import { ShowCallOutComponent } from "../../radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { useId } from "@fluentui/react-hooks";

export interface ControlledDropdownCustomProps {
    isBooleanSelector?: boolean;
    customStyles?: Partial<IDropdownStyles>;
    calloutMessage?: string;
    calloutLabel?: string;
}

export const ControlledDropdown: FC<HookFormProps & IDropdownProps & ControlledDropdownCustomProps> = (props) => {
    const [isCalloutVisible, setCalloutVisibility] = useState<boolean>(false);

    const showCallOut = props.calloutLabel && props.calloutMessage;
    const isDisabled = useMemo(() => {
        return !!props.disabled;
    }, [props.disabled]);
    const theme = useTheme();
    const selectorStyled = SelectorStyled({ theme });

    const customStyles: Partial<IDropdownStyles> = {
        root: selectorStyled.rootStyles,
        callout: selectorStyled.callout,
        dropdownItem: selectorStyled.optionText,
        dropdownItemSelected: selectorStyled.optionText,
        errorMessage: selectorStyled.errorMessage,
        title: selectorStyled.inputText,
        label: props.selectedKey || (props.isBooleanSelector && props.selectedKey === 0) ? selectorStyled.labelSelected : selectorStyled.label,
    };
    const iconId = useId("callout-iconButton");
    
    return (
        <div className={isDisabled ? selectorStyled.selectorContainerDisabled : selectorStyled.selectorContainer}>
            {showCallOut && (
                <ShowCallOutComponent
                    calloutLabel={props.calloutLabel}
                    required={props.required}
                    iconId={iconId}
                    setCalloutVisibility={setCalloutVisibility}
                    isCalloutVisible={isCalloutVisible}
                    calloutMessage={props.calloutMessage}
                ></ShowCallOutComponent>
            )}
            <Controller
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.selectedKey}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Dropdown
                        {...props}
                        label={props.calloutLabel ? "" : props.label}
                        selectedKey={props.selectedKey}
                        onChange={(_e, option, index) => {
                            props.onChange && props.onChange(_e, option, index);
                            onChange(option?.key);
                        }}
                        onBlur={onBlur}
                        errorMessage={error && error.message}
                        defaultValue={(props?.selectedKey as number) || value}
                        disabled={props.disabled}
                        styles={props.customStyles ? props.customStyles : customStyles}
                    />
                )}
            />
        </div>
    );
};
