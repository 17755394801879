import { CatalogAPI } from "../../../../../api";
import { IMasterTerminal } from "../../../../../models/resources/master/ITerminal";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterTerminalForm, setMasterTerminalForm } from "../../../../reducers/resources/master/terminal/form";
import { setLoadingTerminalList, setMasterTerminalList } from "../../../../reducers/resources/master/terminal/list";

import { AppThunk, store } from "../../../../store";


const catalogAPI = new CatalogAPI();

export const fetchMasterTerminalList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingTerminalList(true));
        const response = await catalogAPI.getMasterTerminalList();
        dispatch(setMasterTerminalList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingTerminalList(false));
    }
};

export const fetchMasterTerminal = (form:IMasterTerminal): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterTerminalForm(true));
        const response = await catalogAPI.getMasterTerminal(form);
        dispatch(setMasterTerminalForm(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterTerminalForm(false));
    }
};

export const postToMasterTerminalForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterTerminalForm;

        try {
            dispatch(setLoadingMasterTerminalForm(true));
            if (form.id) {
                await catalogAPI.putMasterTerminal(form);
            } else {
                await catalogAPI.postMasterTerminal(form);
            }
            dispatch(fetchMasterTerminalList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error); dispatch(setLoadingMasterTerminalForm(false));
            return false;
        } 
    };
};