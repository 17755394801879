import { createUseStyles } from "react-jss";

export const RequestListColumnsStyle = createUseStyles((theme: any) => ({
    iconCell: {
        textAlign: "center",
    },
    iconImg: {
        verticalAlign: "middle",
        width: 16,
    },
    transportUnitInfo: {
        display: "flex",
        gap: 5,
        alignItems: "center",
    },
    transportUnitNumberTitle: {
        fontWeight: theme.fonts.weight.bold,
        justifyContent: "center",
    },
    stateSelector: {
        minWidth: 150,
        width: 150,
        " & .ms-Dropdown": {
            paddingBottom: 6,
        },
        " & .ms-Dropdown-title": {
            height: "auto",
            lineHeight: "normal",
            minHeight: 0,
            color: theme.colors.palette.black,
            fontSize: "12px",
        },
        " & .ms-Dropdown-caretDownWrapper": {
            height: "auto",
            lineHeight: "normal",
        },
    },
    secuencialSort: {
        display: "flex",
        justifyContent: "center",
        fontSize: theme.fonts.sizes.sm,
    },

    header: {
        display: "flex",
    },
    sort: {
        marginTop: "5px",
    },
    iconSort: {
        fill: theme.colors.iconSort.disabled,
        width: "16px",
    },
    iconSorted: {
        fill: theme.colors.iconSort.enabled,
        width: "16px",
    },
    sortColumn: {
        width: 14,
        display: "flex",
        position: "absolute",
    },
    selectAllRequest: {
        marginTop: "10px",
    },
    documentsNumber: {
        textAlign: "left",
        fontSize: theme.fonts.sizes.sm,
    },
    organismCode: {
        fontWeight: "500",
        color: theme.colors.palette.black,
    },
    aLetter: {
        color: theme.colors.palette.red,
        fontWeight: 600,
        cursor: "default",
    },
    dLetter: {
        color: theme.colors.palette.yellow,
        cursor: "default",
    },
    vLetter: {
        color: theme.colors.palette.blueDark,
        cursor: "default",
    },
    dsaLetter: {
        color: theme.colors.palette.green,
        cursor: "default",
    },
    exclamation: {
        color: theme.colors.palette.red,
        cursor: "default",
        fontWeight: "800",
    },
    turnLetter: {
        color: theme.colors.palette.blueLight,
        cursor: "default",
    },
    turnLetterAppointment: {
        color: theme.colors.palette.purple,
        cursor: "default",
    },
    appLetter: {
        color: theme.colors.palette.red,
        cursor: "default",
    },
    rowContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },

    buttonIcon: {
        borderRadius: 5,
        padding: 0,
        marginLeft: "auto",
        color: theme.colors.buttons.primary.color,
        width: 15,
        height: 15,
        "&:hover": {
            "& i": {
                color: theme.colors.buttons.primary.color,
            },
        },
    },
    icon: {
        color: theme.colors.buttons.primary.color,
        fontSize: 10,
        padding: 5,
    },
    buttonRed: {
        "& .st0-red": {
            fill: `${theme.colors.palette.red} !important`,
        },
    },
    buttonGreen: {
        "& .st0-red": {
            fill: `${theme.colors.palette.green} !important`,
        },
    },
    buttonBlue: {
        "& .st0-red": {
            fill: `${theme.colors.palette.blueDark} !important`,
        },
    },
    buttonOrange: {
        "& .st0-red": {
            fill: `${theme.colors.palette.orange} !important`,
        },
    },
    editIcon: {
        width: 20,
        cursor: "pointer",
        fill: theme.colors.palette.purple,
    },
    viewMore: {
        display: "flex",
        gap: 5,
        color: theme.colors.palette.purple,
        fontWeight: 900,
        cursor: "pointer",
        "& i": {
            width: 14,
            cursor: "pointer",
        },
    },

    buttonTrash: {
        marginTop: 2,
    },
    lastActuation: {
        display: "flex",
        flexDirection: "row",
        gap: 2,
    },
    highlight: {
        color: theme.colors.font.highlight,
        fontWeight: theme.fonts.weight.normal,
    },
    groupActions: {
        display: "flex",
        justifyContent: "end",
    },
    redRow: {
        color: `${theme.colors.palette.red} !important`,
    },
    greenRow: {
        color: `${theme.colors.palette.green} !important`,
    },

    line: {
        display: "flex",
        gap: 3,
        width: "100%",
    },
    expedient: {
        display: "flex",
        gap: 3,
        width: "100%",
        "& .certificateTypeCode": {},
    },
    inspectorName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    certificateNumber: {
        maxWidth: "130px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    orangeCell: {
        color: theme.colors.palette.darkOrange,
        fontWeight: theme.fonts.weight.bold,
    },
}));
