import { Method } from "../../../common/enum";
import { IConfigOrganismCertificateType } from "../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/catalog/organismcertificatetypes`;

export class ConfigOrganismCertificateTypeAPI {
    async getOrganismCertificateTypeList(filter: any): Promise<IConfigOrganismCertificateType[]> {
        const url = `${baseURL}/all`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IConfigOrganismCertificateType[]>();
    }

    async getOrganismCertificateType(idConfig: number): Promise<IConfigOrganismCertificateType> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IConfigOrganismCertificateType>();
    }
    async deleteOrganismCertificateType(idConfig: number): Promise<boolean> {
        const url = `${baseURL}/${idConfig}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
    async postOrganismCertificateType(config: any): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(config).execute<boolean>();
    }
    async putOrganismCertificateType(config: any): Promise<boolean> {
        const url = `${baseURL}/${config.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(config).execute<boolean>();
    }
}
