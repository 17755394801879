import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";import { IGenericPayload } from "../../../../models";
import { IConfigDockMerchandiseForm } from "../../../../models/config/dockMerchandise";

interface IConfigDockMerchandiseFormState {
    form: IConfigDockMerchandiseForm;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IConfigDockMerchandiseFormState = {
    form: {
        dockId: 0,
        merchandiseOriginId: -1,
        merchandiseTypeId: -1,
        id: 0,
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const configDockMerchandiseFormSlice = createSlice({
    name: "configDockMerchandiseFormSlice",
    initialState,
    reducers: {
        resetConfigDockMerchandiseForm: () => initialState,
        updateFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setConfigDockMerchandiseForm: (state, action: PayloadAction<IConfigDockMerchandiseForm>) => {
            state.form = action.payload;
        },
        setConfigDockMerchandiseLoadingForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setConfigDockMerchandiseShowPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    updateFormProperty,
    resetConfigDockMerchandiseForm,
    setConfigDockMerchandiseForm,
    setConfigDockMerchandiseLoadingForm,
    setConfigDockMerchandiseShowPopUp,
} = configDockMerchandiseFormSlice.actions;

export default configDockMerchandiseFormSlice.reducer;
