import { CatalogAPI } from "../../../../../api";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterMerchandiseTypesForm, setMasterMerchandiseTypesForm } from "../../../../reducers/resources/master/merchandiseTypes/form";
import { setLoadingMasterMerchandiseTypesList, setMasterMerchandiseTypeList } from "../../../../reducers/resources/master/merchandiseTypes/list";
import { AppThunk, store } from "../../../../store";

const catalogAPI = new CatalogAPI();

export const fetchMasterMerchandiseTypesList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterMerchandiseTypesList(true));
        const response = await catalogAPI.getMasterMerchandiseTypesList();
        dispatch(setMasterMerchandiseTypeList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterMerchandiseTypesList(false));
    }
};

export const fetchMasterMerchandiseTypes =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterMerchandiseTypesForm(true));
            const response = await catalogAPI.getMasterMerchandiseType(id);
            dispatch(setMasterMerchandiseTypesForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterMerchandiseTypesForm(false));
        }
    };

export const postToMasterMerchandiseTypesForm = (): any => {
    return async (dispatch: any) => {
        const { form } = store.getState().masterMerchandiseTypesForm;
        let customForm: any = {
            ...form,
            merchandiseOriginId: String(form.merchandiseOriginId) !== DefaultValues.DEFAULT_ALL_SELECTOR ? form.merchandiseOriginId : undefined,
        };

        try {
            dispatch(setLoadingMasterMerchandiseTypesForm(true));
            if (form.id) {
                await catalogAPI.putMasterMerchandiseTypes(customForm);
            } else {
                await catalogAPI.postMasterMerchandiseTyes(customForm);
            }
            dispatch(fetchMasterMerchandiseTypesList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterMerchandiseTypesForm(false));
            return false;
        }
    };
};
export const patchSortOrganismList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterMerchandiseTypesList(true));
            await catalogAPI.managementSort(tableName, id, sortType);
            dispatch(fetchMasterMerchandiseTypesList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterMerchandiseTypesList(false));
        }
    };
