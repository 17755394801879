import { Method } from "../../../common/enum";
import { IManagementAuthorizedTransportUnitNumber } from "../../../models/management/authorizedTransportUnitNumber/IAuthorizedTransportUnitNumber";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/authorizedtransportunitnumber`;

export class ManagementAuthorizedTransportUnitNumberApi {
    async getManagementAuthorizedTransportUnitNumberAsync(filter: any): Promise<IManagementAuthorizedTransportUnitNumber[]> {
        const url = `${baseURL}/search`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(filter).execute<IManagementAuthorizedTransportUnitNumber[]>();
    }

    async getManagementAuthorizedTransportUnitNumberItemAsync(id: number): Promise<IManagementAuthorizedTransportUnitNumber> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute<IManagementAuthorizedTransportUnitNumber>();
    }

    async postManagementAuthorizedTransportUnitNumberItemAsync(form: IManagementAuthorizedTransportUnitNumber): Promise<boolean> {
        const url = `${baseURL}`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(form).execute<boolean>();
    }
    async putManagementAuthorizedTransportUnitNumberItemAsync(form: IManagementAuthorizedTransportUnitNumber): Promise<boolean> {
        const url = `${baseURL}/${form.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(form).execute<boolean>();
    }

    async removeManagementAuthorizedTransportUnitNumberItemAsync(id: number): Promise<boolean> {
        const url = `${baseURL}/${id}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).withBody({}).execute<boolean>();
    }
}
