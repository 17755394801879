import { IMenuSettingFeature, IMenuSettingPayload } from "../../../../models/management/menuSetting/IMenySetting";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Section } from "../../parkingValidation/controller/form/components/section/section";
import { updateMenuSettingForm } from "../../../../redux/reducers/management/menuSettingsByRole/form";
import { useTheme } from "react-jss";
import { ManagementMenuSettingsByRolContainerStyle } from "../containerStyle.jss";
import { IFormOption } from "../../../../models/common/IFormOptions";
import { CheckboxSelector } from "../../../common/checkboxSelector/checkboxSelector";

export const ManagementMenuSettingsByRolList = () => {
    const dispatch = useAppDispatch();
    const { form } = useAppSelector((state) => state.managementMenuSettingsForm);
    const theme = useTheme();
    const style = ManagementMenuSettingsByRolContainerStyle({ theme });
    const mapFeaturesToOption = (features: IMenuSettingFeature[]) => {
        let options: IFormOption[] = features.map((item) => {
            return {
                active: item.active,
                key: item.id,
                text: item.name,
            };
        });

        return options;
    };

    return (
        <div className={style.list}>
            {form?.map((item, index) => {
                return (
                    <div className={style.item}>
                        <Section
                            key={item.name}
                            title={item.name}
                            value={
                                <CheckboxSelector
                                    options={mapFeaturesToOption(item.features)}
                                    name={""}
                                    onChange={function (name: string, value: boolean, indexOption: number): void {
                                        const payload: IMenuSettingPayload = {
                                            active: value,
                                            indexModule: index,
                                            indexOption,
                                        };
                                        dispatch(updateMenuSettingForm(payload));
                                    }}
                                />
                            }
                        />
                    </div>
                );
            })}
        </div>
    );
};
