import { UserApi } from "../../../../api";
import { UserSignupState } from "../../../../common/enum/UserSignupState";
import { HandleAxiosError } from "../../../../components/common/errorHandler/errorHandler";
import { IGenericPayload } from "../../../../models";
import messages from "../../../../translations/es/messages.json";
import { showMessageSuccessFromAssets } from "../../../../utils";
import {
    loadingUserSignupConfirmation,
    resetUserSignupConfirmationForm,
    updateSignUpState,
    updateUserSignupConfirmation,
    updateUserSignupConfirmationProperty,
    userSignUpConfirmationFailure,
    userSignUpConfirmationSuccess,
} from "../../../reducers/user/confirmation/confirmation";
import { AppThunk } from "../../../store";
import { setGlobalLoadingAction } from "../../common";

const userAPI = new UserApi();

export const updateUserSignupConfirmationPropertyAction =
    (payload: IGenericPayload): AppThunk =>
    (dispatch) => {
        dispatch(updateUserSignupConfirmationProperty(payload));
    };

export const resetUserSignupConfirmationAction = (): AppThunk => (dispatch) => {
    dispatch(resetUserSignupConfirmationForm());
};

export const fetchUserSignupConfirmationAction =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setGlobalLoadingAction(true));
            dispatch(loadingUserSignupConfirmation(true));
            const response = await userAPI.getUserSignupByIdAsync(id);

            if (response.imageBlob) {
                response.avatar = {
                    uri: response.imageBlob.uri,
                    documentId: 0,
                    documentName: response.imageBlob?.name,
                    file: response.imageBlob.uri,
                    index: 0,
                };
            }
            response.state = response.confirmation.state;
            if (response.operatorData) {
                response.operatorData.emails = response.operatorData?.emails.map((email) => {
                    return {
                        email: String(email),
                        isValid: true,
                    };
                });
            }
            if (response.adminData) {
                response.adminData.emails = !response.adminData.emails
                    ? []
                    : response.adminData?.emails.map((email) => {
                          return {
                              email: String(email),
                              isValid: true,
                          };
                      });
            }

            dispatch(updateUserSignupConfirmation(response));
            dispatch(userSignUpConfirmationSuccess());
        } catch (error) {
            dispatch(HandleAxiosError(error));
            dispatch(userSignUpConfirmationFailure());
        } finally {
            dispatch(setGlobalLoadingAction(false));
            dispatch(loadingUserSignupConfirmation(false));
        }
    };

export const acceptUserSignupConfirmationAction =
    (id: string, crmCode: string, externalCode: string, hasRepresentativeData: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(loadingUserSignupConfirmation(true));
            await userAPI.approveUserSignupAsync(id, crmCode, externalCode, hasRepresentativeData);
            dispatch(updateSignUpState(UserSignupState.APPROVED));
            showMessageSuccessFromAssets(messages.SUCCESS.USER_ACCEPTATION);
        } catch (error) {
            dispatch(HandleAxiosError(error));
        } finally {
            dispatch(loadingUserSignupConfirmation(false));
        }
    };

export const rejectUserSignupConfirmationAction = (id: string, reason: string): any => {
    return async (dispatch: any) => {
        try {
            dispatch(loadingUserSignupConfirmation(true));
            await userAPI.rejectUserSignupAsync(id, reason);
            dispatch(updateSignUpState(UserSignupState.REJECTED));
            showMessageSuccessFromAssets(messages.SUCCESS.USER_REJECTION);
            return true;
        } catch (error) {
            dispatch(HandleAxiosError(error));
            return false;
        } finally {
            dispatch(loadingUserSignupConfirmation(false));
        }
    };
};
