import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const SignupResumeOperationEmailsStyle = createUseStyles((theme: any) => ({
    container: {
        paddingLeft: theme.paddings.default,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        [mediaQueries.TABLET]: {
            flexDirection: "column",
            alignItems: "center",
            rowGap: theme.gaps.sizes.base,
        },
    },
    twoItemsRow: {
        display: "flex",
        paddingBottom: theme.paddings.default,
        width: "100%",
        [mediaQueries.TABLET]: {
            flexDirection: "column",
            rowGap: theme.gaps.sizes.base,
        },
        "& > * ": {
            width: "50%",
            [mediaQueries.TABLET]: {
                width: "100%",
            },
        },
    },
    emailList: {
        marginTop: "0px",
        display: "flex",
        rowGap: theme.gaps.sizes.base,
        flexDirection: "column",
        width: "100%",
        paddingLeft: theme.paddings.default,
    },
    emailItem: {
        fontWeight: theme.fonts.weight.bold,
    },
}));
