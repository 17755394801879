import { createUseStyles } from "react-jss";

export const SummaryMessageStyle = createUseStyles((theme: any) => ({
    summaryMessageContainer: {
        padding: "32px 12px",
    },
    textContainer: {
        display: "flex",
        flexDirection: "row",
        width: 400,
        alignItems: "center",
        paddingBottom: 32,
        fontFamily: theme.fonts.families.site,
        fontSize: theme.fonts.sizes.md,
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
        alignItems: "center",
        paddingTop: theme.paddings.default,
        columnGap: theme.gaps.sizes.md,
    },
}));
