import React from "react";
import { store } from "../../../redux/store";
import { resetCustomerImport } from "../../../redux/reducers/resources/customersImport/import";
import { CustomersImportContent } from "./content/container";

export class CustomersImportContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetCustomerImport());
    }

    render(): React.ReactNode {
        return <CustomersImportContent />;
    }
}
