import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IGenericImportFinishedData } from "../../../../models/common/IImportGeneric";

interface IAssignmentQueueImportFinishedState {
    loading: boolean;
    data?: IGenericImportFinishedData;
    sort: ISortState;
}
const initialState: IAssignmentQueueImportFinishedState = {
    loading: false,
    sort: emptySortState(),
};

export const AssignmentQueueImportListReducer = createSlice({
    name: "AppointmentImportListReducer",
    initialState,
    reducers: {
        setAssignmentQueueImportListData: (state, action: PayloadAction<IGenericImportFinishedData>) => {
            state.data = action.payload;
        },
        setAssignmentQueueImportListLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },

        resetAssignmentQueueImportList: () => initialState,
    },
});

export const { setAssignmentQueueImportListData, updateSortColumn, setAssignmentQueueImportListLoading, resetAssignmentQueueImportList } =
    AssignmentQueueImportListReducer.actions;

export default AssignmentQueueImportListReducer.reducer;
