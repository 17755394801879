import { IDropdownOption, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { IGenericPayload } from "../../../../../../../models";
import { IAppointmentPermission } from "../../../../../../../models/masterAppointment/IAppointmentPermission";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import {
    BooleanSelector,
    CertificateSelector,
    ContainerSizeSelector,
    OrganismSelector,
    TransportUnitSelector,
} from "../../../../../../request/common/selectors";
import { ManagementAppointmentPermisionFormStyle } from "./formStyle.jss";
import { updateMasterAppointmentPermissionProperty } from "../../../../../../../redux/reducers/masterAppointment/appointmentPermission/form";
import { MerchandiseCategoryGroupSelector } from "../../../../../../request/common/selectors/merchandiseCategoryGrop/merchandiseCategoryGroupSelector";
import { RequestStatesSelector } from "../../../../../../common/selectors/controllerSelectors/requestStates/requestStatesSelector";
import { FormNumberField } from "../../../../../../common/numberField/numberField";
import { ActuationGroupSelector } from "../../../../../../common/selectors/controllerSelectors/actuationGroup/actuationGroupSelecor";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";

interface IManagementAppointmenPermisionFormProps {
    isContainer: boolean;
}

export const ManagementAppointmentPermisionForm: React.FC<IManagementAppointmenPermisionFormProps> = ({ isContainer }) => {
    const { t } = useTranslation("common");
    const { form } = useAppSelector((state) => state.masterAppointmentPermissionForm);
    const dispatch = useDispatch();
    const theme: any = useTheme();
    const style = ManagementAppointmentPermisionFormStyle({ theme });
    const { control, register } = useForm<IAppointmentPermission>({ mode: "onChange" });

    const onUpdateHeaderPopperty = (name: string, value: any) => {
        let payload: IGenericPayload = {
            name,
            value,
        };
        dispatch(updateMasterAppointmentPermissionProperty(payload));
    };

    const onSelectorChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateHeaderPopperty(propertyName, option.key);
    };
    const [selectedKey, setSelectedKey] = useState<any>(0);

    const onInputNumberChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateHeaderPopperty(name, value);
        },
        [dispatch],
    );

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateHeaderPopperty(propertyName, option.key !== -1 ? !!+option.key : undefined);
    };

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <Pivot
                className={style.pivot}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("MASTER_APPOINTMENT.SELECTOR.APPOINTMENT_PERMISSION")}
                    itemKey="0"
                    alwaysRender={false}
                >
                    <div className={style.form}>
                        <div className={style.rowForm}>
                            <div className={style.item}>
                                <TransportUnitSelector
                                    onChange={onSelectorChange}
                                    selectedKey={form.transportUnitId}
                                    propertyName={compileNameOfProperty<IAppointmentPermission>("transportUnitId")}
                                    title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.TRANSPORT_UNIT")}
                                    control={control}
                                    isRequired={true}
                                    rules={{ required: true }}
                                />
                            </div>
                            <div className={style.item}>
                                <ContainerSizeSelector
                                    onChange={onSelectorChange}
                                    selectedKey={form.transportUnitSizeId ? form.transportUnitSizeId : 0}
                                    propertyName={compileNameOfProperty<IAppointmentPermission>("transportUnitSizeId")}
                                    disabled={!isContainer}
                                    title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.TRANSPORT_SIZE")}
                                    isRequired={isContainer}
                                    control={control}
                                    rules={{ required: true }}
                                />
                            </div>
                        </div>
                        <div className={style.rowForm}>
                            <div className={style.item}>
                                <MerchandiseCategoryGroupSelector
                                    onChange={onSelectorChange}
                                    selectedKey={form.merchandiseCategoryGroupId}
                                    propertyName={compileNameOfProperty<IAppointmentPermission>("merchandiseCategoryGroupId")}
                                    title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.MERCHANDISE_CATEGORY")}
                                    control={control}
                                    isRequired={true}
                                ></MerchandiseCategoryGroupSelector>
                            </div>
                        </div>
                    </div>
                </PivotItem>
                <PivotItem
                    headerText={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.APPOINTMENT_PERMISSION_AUTOMATIC")}
                    itemKey="1"
                    alwaysRender={false}
                >
                    <div className={style.form}>
                        <div className={style.rowForm}>
                            <div className={style.item}>
                                <RequestStatesSelector
                                    control={control}
                                    stateId={0}
                                    onChange={onSelectorChange}
                                    selectedKey={form.stateId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                                    propertyName={compileNameOfProperty<IAppointmentPermission>("stateId")}
                                    title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.STATE")}
                                    allOption={true}
                                    calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.STATE_INFO")}
                                    calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.STATE")}
                                ></RequestStatesSelector>
                            </div>

                            <div className={style.item}>
                                <FormNumberField
                                    value={String(form.previousDays ?? "")}
                                    label={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.PREVIOUS_DAY")}
                                    {...register(compileNameOfProperty<IAppointmentPermission>("previousDays"), {
                                        onChange: onInputNumberChange,
                                        value: String(form.previousDays ?? ""),
                                        required: false,
                                    })}
                                    isRequired={false}
                                    calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.PREVIOUS_DAY_INFO")}
                                    calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.PREVIOUS_DAY")}
                                />
                            </div>
                        </div>
                        <div className={style.rowForm}>
                            <BooleanSelector
                                onChange={onYesNoOptionsChange}
                                selectedKey={form.summaryActive !== undefined ? +!!form.summaryActive : -1}
                                propertyName={compileNameOfProperty<IAppointmentPermission>("summaryActive")}
                                title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.SUMMARY_ACTIVE")}
                                control={control}
                                allOption={true}
                                calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.SUMMARY_ACTIVE_INFO")}
                                calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.SUMMARY_ACTIVE")}
                            />

                            <BooleanSelector
                                onChange={onYesNoOptionsChange}
                                selectedKey={form.invoiced !== undefined ? +!!form.invoiced : -1}
                                propertyName={compileNameOfProperty<IAppointmentPermission>("invoiced")}
                                title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.INVOICED")}
                                control={control}
                                allOption={true}
                                calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.INVOICED_INFO")}
                                calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.INVOICED")}
                            />
                        </div>
                        <div className={style.rowForm}>
                            <ActuationGroupSelector
                                onChange={onSelectorChange}
                                selectedKey={form.actuationTypeGroupId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                                propertyName={compileNameOfProperty<IAppointmentPermission>("actuationTypeGroupId")}
                                title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.ACTUATION_TYPE_GROUP")}
                                control={control}
                                allOption={true}
                                allOptionText={t("COMMON.NONE")}
                                calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.ACTUATION_TYPE_GROUP_INFO")}
                                calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.ACTUATION_TYPE_GROUP")}
                            />
                            <OrganismSelector
                                control={control}
                                onChange={onSelectorChange}
                                selectedKey={form.organismId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                                propertyName={compileNameOfProperty<IAppointmentPermission>("organismId")}
                                calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.ORGANISM")}
                                allOption={true}
                                allOptionText={t("COMMON.NONE")}
                                calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.ORGANISM_INFO")}
                                title={""}
                            />
                        </div>
                        <div className={style.rowForm}>
                            <CertificateSelector
                                onChange={onSelectorChange}
                                selectedKey={form.certificateTypeId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                                propertyName={compileNameOfProperty<IAppointmentPermission>("certificateTypeId")}
                                title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.CERTIFICATE_TYPE")}
                                control={control}
                                allOption={true}
                                allOptionText={t("COMMON.NONE")}
                                calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.CERTIFICATE_TYPE_INFO")}
                                calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.CERTIFICATE_TYPE")}
                            />
                            <BooleanSelector
                                onChange={onYesNoOptionsChange}
                                selectedKey={form.appointmentAssigned !== undefined ? +!!form.appointmentAssigned : -1}
                                propertyName={compileNameOfProperty<IAppointmentPermission>("appointmentAssigned")}
                                title={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.APPOINTMENT_ASSIGNED")}
                                control={control}
                                allOption={true}
                                calloutMessage={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.APPOINTMENT_ASSIGNED_INFO")}
                                calloutLabel={t("MASTER_APPOINTMENT.APPOINTMENT_PERMISSION.APPOINTMENT_ASSIGNED")}
                            />
                        </div>
                    </div>
                </PivotItem>
            </Pivot>
        </div>
    );
};
