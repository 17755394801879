import { InvoicingSummaryByConceptApi } from "../../../../api/invoicing/summaryByConcept";
import { stringDefaultValue, throwErrorToastrAsync } from "../../../../utils";
import { resetInvoicingSummaryConceptFilter } from "../../../reducers/invoicing/summaryByConcept/filter";
import { resetInvoiceSummaryByConcept, setList, setLoading } from "../../../reducers/invoicing/summaryByConcept/list";
import { AppThunk, store } from "../../../store";

const requestApi = new InvoicingSummaryByConceptApi();

const getInvoicingSummaryByConceptFilter = (): any => {
    const { filter } = store.getState().invoicingSummaryConceptFilter;

    return {
        ...filter,
        customerId: filter.customerId !== stringDefaultValue.key ? filter.customerId : null,
    };
};

export const fetchInvoicingSummaryByConceptAction = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const filter = getInvoicingSummaryByConceptFilter();
            const response = await requestApi.getSummaryByConcept(filter);
            dispatch(setList(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoading(false));
        }
};

export const exportInvoicingSummaryByConceptAction = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const filter = getInvoicingSummaryByConceptFilter();
            const response = await requestApi.exportSummaryByConcept(filter);
            window.open(response);
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoading(false));
        }
};

export const resetInvoiceSummaryByConceptData = (): AppThunk =>
    (dispatch) => {
    dispatch(resetInvoiceSummaryByConcept());
    dispatch(resetInvoicingSummaryConceptFilter());
};



