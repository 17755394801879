import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IGenericPayload } from "../../../../../models";
import { AppointmentTypes } from "../../../../../common/enum/request/AppointmentTypes";

export interface IEditRequestAppointmentCreateForm {
    appointmentTypeId?: AppointmentTypes;
    timeZoneId?: number;
}
interface IEditRequestAppointmentFormState {
    loading: boolean;
    showPopUp: PopUpCodes;
    form: IEditRequestAppointmentCreateForm;
}

const initialState: IEditRequestAppointmentFormState = {
    loading: false,
    showPopUp: PopUpCodes.NONE,
    form: {
        appointmentTypeId: undefined,
        timeZoneId: undefined,
    },
};

export const EditRequestAppointmentSlice = createSlice({
    name: "editRequestAppointment",
    initialState,
    reducers: {
        resetAppointmentFormDataState: () => initialState,
        setAppointmentFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEditRequestAppointmentShowForm: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
        resetAppointmentTypeForm: (state, action: PayloadAction<AppointmentTypes | undefined>) => {
            state.form.appointmentTypeId = action.payload;
        },
        setEditRequestAppointmentCreateForm: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetAppointmentFormDataState,
    setAppointmentFormLoading,
    setEditRequestAppointmentShowForm,
    setEditRequestAppointmentCreateForm,
    resetAppointmentTypeForm,
} = EditRequestAppointmentSlice.actions;

export default EditRequestAppointmentSlice.reducer;
