import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import {
    emptyManagementNotificationFilter,
    IManagementNotificationFilter,
} from "../../../../models/management/notification/IManagementNotificationFilter";

interface IManagementNotificationFilterState {
    filter: IManagementNotificationFilter;
}

const initialState: IManagementNotificationFilterState = {
    filter: emptyManagementNotificationFilter(),
};

export const managementNotificationFilterSlice = createSlice({
    name: "managementNotificationFilter",
    initialState,
    reducers: {
        resetFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetFilter, updateFilterProperty } = managementNotificationFilterSlice.actions;

export default managementNotificationFilterSlice.reducer;
