import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterBlockForm, setMasterBlockForm } from "../../../../reducers/resources/master/block/form";
import { setLoadingMasterBlockeList, setMasterBlockList } from "../../../../reducers/resources/master/block/list";
import { AppThunk, store } from "../../../../store";

const catalogApi = new CatalogAPI();

export const fetchMasterBlocksList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterBlockeList(true));
        const response = await catalogApi.getMasterBlockList();
        dispatch(setMasterBlockList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterBlockeList(false));
    }
};

export const fetchMasterBlock =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterBlockForm(true));
            const response = await catalogApi.getMasterBlock(id);
            dispatch(setMasterBlockForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterBlockForm(false));
        }
    };

export const postAndPutMasterBlock = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterBlockForm;
            dispatch(setLoadingMasterBlockForm(true));
            if (form.id) {
                await catalogApi.putMasterBlock(form);
            } else {
                await catalogApi.postMasterBlock(form);
            }
            dispatch(fetchMasterBlocksList());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingMasterBlockForm(false));
            return false;
        }
    };
};

export const patchSortBlockList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterBlockeList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterBlocksList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterBlockeList(false));
        }
    };
