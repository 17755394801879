import { createSlice } from "@reduxjs/toolkit";

interface InitSessionState {
    loaded: boolean;
}

const initialState: InitSessionState = {
    loaded: false,
};

export const initSessionSlice = createSlice({
    name: "initSession",
    initialState,
    reducers: {
        sessionKeysLoaded: (state) => {
            state.loaded = true;
        },
    },
});

export const { sessionKeysLoaded } = initSessionSlice.actions;

export default initSessionSlice.reducer;
