import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../../../../../../../../assets/icons";

import {
    IBoardHistoryAppointmentTypeConfiguration,
    IBoardHistoryAppointmentTypeConfigurationTable,
} from "../../../../../../../../../../models/masterAppointment/IAppointmentBoard";
import { useAppDispatch } from "../../../../../../../../../../redux/hooks";
import {
    setMasterAppointmentTypeConfigurationForm,
    setShowPopUpMasterAppointmentTypeConfiguration,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentTypeConfiguration/form";
import { CustomIcons } from "../../../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../../../common/separator/separator";
import { CustomTableActions } from "../../../../../../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../../../../../../common/tableCustom/tableCustom";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../../../../../../common/enum/DefaultValues";

interface IMasterAppointmentTypeConfigurationProps {
    list: IBoardHistoryAppointmentTypeConfiguration[];
}

export const MasterBoardHistoryAppointmentTypeConfigurationList: React.FC<IMasterAppointmentTypeConfigurationProps> = ({ list }) => {
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_TYPE_CONFIGURATION" });
    const dispatch = useAppDispatch();

    let loading = false;

    const columns: ColumnType<IBoardHistoryAppointmentTypeConfigurationTable, keyof IBoardHistoryAppointmentTypeConfigurationTable>[] = [
        { key: "appointmentTypeDescription", title: t("APPOINTMENT_TYPE") },
        { key: "merchandiseTypeDescription", title: t("MERCHANDISE_TYPE") },
        { key: "forcedAppointmentTypeDescription", title: t("FORCED_APPOINTMENT_TYPE") },
        { key: "timeZones", title: t("TIME_ZONES") },
        { key: "maxAppointments", title: t("MAXAPPOINTMENTS") },
        { key: "actions", title: "" },
    ];

    let mapEmptyList = (value?: string) => {
        if (value === "0" || !value) {
            return DefaultValues.EMPTY_LIST;
        }

        return value;
    };

    const rows = list.map((item) => {
        return {
            ...item,
            forcedAppointmentTypeDescription: item.forcedAppointmentTypeDescription ?? DefaultValues.EMPTY_LIST,
            maxAppointments: mapEmptyList(item.maxAppointments),
            appointmentTypeDescription: item.appointmentTypeDescription,
            merchandiseTypeDescription: item.merchandiseTypeDescription ?? t("ALL"),
            actions: (
                <CustomTableActions
                    children={
                        <div>
                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.EDIT}
                                onClick={() => {
                                    dispatch(setMasterAppointmentTypeConfigurationForm(item));
                                    dispatch(setShowPopUpMasterAppointmentTypeConfiguration(PopUpCodes.UPDATE));
                                }}
                            />

                            <Separator size="auto"></Separator>

                            <CustomIcons
                                isButton={true}
                                iconName={NameIcons.REMOVE}
                                onClick={() => {
                                    dispatch(setMasterAppointmentTypeConfigurationForm(item));
                                    dispatch(setShowPopUpMasterAppointmentTypeConfiguration(PopUpCodes.DELETE));
                                }}
                            />
                        </div>
                    }
                />
            ),
        };
    });

    return (
        <CustomTableList
            data={rows}
            columns={columns}
            loading={loading}
        />
    );
};
