import { createUseStyles } from "react-jss";

export const ProfileUserNotificationItemStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
        width: "100%",
        marginBottom: 10
    },
    row: {
        display: "flex",
        gap: 15,
        width: "100%",
        alignItems: "end",
    },
    button: {
        height: 30,
        alignContent: "end",
    },
}));
