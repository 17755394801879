import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch } from "../../../../../redux/hooks";
import { OriginSealsStyle } from "./sealsStyle.jss";
import { OriginSeal } from "../../../../../models";
import { TitleH6 } from "../../../../common/titles/titles";
import { FormField } from "../../../../common/fields/fields";
import { ButtonPrimaryInverseWithBorder } from "../../../../common/buttons/buttons";
import { OriginSealItem } from "./sealItem/sealItem";
import { useSize } from "../../../../../hooks/resizeHook";
import { ColorType } from "../../../../../common/enum/ColorType";

interface IProps {
    seals: string[];
    addItem: (seal: string) => void;
    removeSeal: (index: number) => void;
    isContainer: boolean;
}

export const OriginSeals: React.FC<IProps> = ({ seals, addItem, removeSeal, isContainer }) => {
    const { width } = useSize();
    const widthSealData = useMemo(() => {
        if (width <= 1536) {
            return "31vw";
        }
        return "24.5vw";
    }, [width]);

    const theme = useTheme();
    const style = OriginSealsStyle({ theme, isContainer, widthSealData });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CREATE_REQUEST" });
    const [seal, setSeal] = useState<string>("");
    const {
        register,
        formState: { isValid },
    } = useForm<OriginSeal>({ mode: "onChange" });

    const onInputChange = (event: any) => {
        setSeal(event?.target?.value);
    };

    const onAddItem = useCallback(
        (event: any, _newValue?: any) => {
            addItem(seal);
            setSeal("");
        },
        [dispatch, seal],
    );

    return (
        <>
            <div className={style.titleContainer}>
                <div className={style.title}>
                    <TitleH6
                        title={t("FIELDS.ORIGIN_SEAL")}
                        color={ColorType.primary}
                    />
                </div>
            </div>
            <div className={style.sealsContainer}>
                <FormField
                    value={seal}
                    type="text"
                    label={t("FIELDS.SEAL")}
                    {...register("seal", {
                        onChange: onInputChange,
                        validate: () => !!seal,
                    })}
                />
                <div className={style.buttonContainer}>
                    <ButtonPrimaryInverseWithBorder
                        handleClick={onAddItem}
                        title={t("BUTTONS.ADD_SEAL")}
                        disabled={!isValid || !seal.length}
                    />
                </div>
            </div>
            <div className={style.upsertSealData}>
                {seals.map((seal, index) => (
                    <OriginSealItem
                        seal={seal}
                        key={`${index}`}
                        index={index}
                        removeSeal={removeSeal}
                    />
                ))}
            </div>
        </>
    );
};
