import { createUseStyles } from "react-jss";

export const TableCustomHeaderStyle = createUseStyles((theme: any) => ({
    header: {
        width:"100%",
        display: "flex",
        paddingBottom: theme.paddings.forms.inputs.defaultPadding,
        borderBottom: theme.borders.table.header.border,
        color: theme.colors.table.header.color,
        alignContent: "center",
        alignItems: "center",
        marginBottom: theme.margins.sizes.xs,
        "& > .th": {
            width: "20%",
            textAlign: "left",
        },
    },
}));
