import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppointmentHistoricList } from "../../../../../models/appointment/IAppointmentHistoric";

interface IAppointmentHistoricListState {
    list?: IAppointmentHistoricList[];
    loading: boolean;
}

const initialState: IAppointmentHistoricListState = {
    loading: false,
};

export const appointmenttHistoricListReducer = createSlice({
    name: "appointmenttHistoricListReducer",
    initialState,
    reducers: {
        resetAppointmenttHistoricList: () => initialState,
        setAppointmenttHistoricList: (state, action: PayloadAction<any[]>) => {
            state.list = action.payload;
        },
        setAppointmenttHistoricLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetAppointmenttHistoricList, setAppointmenttHistoricList, setAppointmenttHistoricLoading } = appointmenttHistoricListReducer.actions;

export default appointmenttHistoricListReducer.reducer;
