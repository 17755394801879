import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { MethodsSort } from "../../../../../../../common/enum/sort/Methods";
import { SortTableName } from "../../../../../../../common/enum/sort/TableName";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../common/separator/separator";
import { MasterCertificateTypesColumnsStyle } from "./columnStyle.jss";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterCertificateType } from "../../../../../../../models/resources/master/ICertificateType";
import { patchSortCertificateTypesList, fetchMasterTypeCertificate } from "../../../../../../../redux/actions/resources/master/certificateType/certificateType";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";

export const MasterCertificateTypesColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterCertificateTypesColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const { list } = useAppSelector((state) => state.masterCertificateTypeList);
    const lastIndex = list ? list.length - 1 : 0;
    const columns: IColumn[] = [
        {
            key: "sort",
            minWidth: 100,
            maxWidth: 200,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={t("PRIORITY")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCertificateType, index) => (
                <span className={styles.actions}>
                    <CustomIcons
                        className={styles.arrow}
                        iconName={"ChevronUpSmall"}
                        isDisabled={index === 0}
                        onClick={() => {
                            dispatch(patchSortCertificateTypesList(SortTableName.CertificateTypes, item.id ?? 0, MethodsSort.UP));
                        }}
                    />

                    <Separator size="auto" />

                    <CustomIcons
                        iconName={"ChevronDownSmall"}
                        className={styles.arrow}
                        onClick={() => {
                            dispatch(patchSortCertificateTypesList(SortTableName.CertificateTypes, item.id ?? 0, MethodsSort.DOWN));
                        }}
                        isDisabled={index === lastIndex || (index === 0 && list?.length === 1) ? true : false}
                    />
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterCertificateType>("code"),
            minWidth: 120,
            maxWidth: 200,
            name: t("CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCertificateType>("code")}
                    title={t("CODE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCertificateType) => <span>{item.code ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterCertificateType>("description"),
            minWidth: 100,
            maxWidth: 200,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCertificateType>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCertificateType) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterCertificateType>("active"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterCertificateType>("active")}
                    title={t("ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCertificateType) => <span><BooleanValue value={item.active}/></span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterCertificateType) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterTypeCertificate(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
