import { CookieSectionFormat, IItemTranslate } from "../controller/controller";

export const LegalNoticeContainer = () => {
    const translate: IItemTranslate[] = [
        {
            header: "LEGAL_NOTICE.TITLE",
            description: "LEGAL_NOTICE.TITLE_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.COMPANY_DATA_HEADER",
            description: "LEGAL_NOTICE.COMPANY_DATA_DESCRIPTION",
        },

        {
            header: "LEGAL_NOTICE.APPLICATION_PROPOSAL_HEADER",
            description: "LEGAL_NOTICE.APPLICATION_PROPOSAL_DESCRIPTION",
        },

        {
            header: "LEGAL_NOTICE.SERVICE_WEB_APPLICATION_HEADER",
            description: "LEGAL_NOTICE.SERVICE_WEB_APPLICATION_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.PROPERTY_RIGHTS_HEADER",
            description: "LEGAL_NOTICE.PROPERTY_RIGHTS_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.LINKS",
        },
        {
            header: "LEGAL_NOTICE.LINKS_FROM OTHER_PAGES_TO_THE_WEBSITE_HEADER",
            description: "LEGAL_NOTICE.LINKS_FROM OTHER_PAGES_TO_THE_WEBSITE_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.LINKS_TO_THIRD_PARTY_WEBSITES_LOCATED_ON_THE_WEBSITE_HEADER",
            description: "LEGAL_NOTICE.LINKS_TO_THIRD_PARTY_WEBSITES_LOCATED_ON_THE_WEBSITE_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.CONDITIONS_OF_USE_OF_THE_WEBSITE_HEADER",
            description: "LEGAL_NOTICE.CONDITIONS_OF_USE_OF_THE_WEBSITE_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.RESPONSIBILITIES AND GUARANTEES_HEADER",
            description: "LEGAL_NOTICE.RESPONSIBILITIES AND GUARANTEES_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.PRIVACY_AND_COOKIES_POLICY_HEADER",
            description: "LEGAL_NOTICE.PRIVACY_AND_COOKIES_POLICY_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.NULLITY_AND_INEFFICACY_OF_THE_CLAUSES_HEADER",
            description: "LEGAL_NOTICE.NULLITY_AND_INEFFICACY_OF_THE_CLAUSES_DESCRIPTION",
        },
        {
            header: "LEGAL_NOTICE.APPLICABLE_LAW_AND_COMPETENT_JURISDICTION_HEADER",
            description: "LEGAL_NOTICE.APPLICABLE_LAW_AND_COMPETENT_JURISDICTION_DESCRIPTION",
        },
        {
            description: "LAST_UPDATE",
        },
    ];

    return <CookieSectionFormat translate={translate} />;
};
