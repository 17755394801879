import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../common/constants";

export const ManagementNotificationDefaultFormStyle = createUseStyles((theme: any) => ({
    containerForm: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.xl,
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        paddingTop: theme.paddings.sizes.base,
    },
    rowForm: {
        display: "flex",
    
        gap: theme.gaps.sizes.xl,
        // "&  > div": {
        //     width: "100%",
        //     padding: 0,
        // },
        [mediaQueries.MOBILE]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    discharged: {
        color: theme.colors.messageAlert.warning.color,
        fontSize: theme.fonts.sizes.base,
    },
    details :{
        maxWidth:theme.widths.management.notification.notificationForm.width
    }, 
    hourContainer : {
        width:250, 
        marginTop:-20
    },
    checkBox : {
        width:150
    }
}));
