import { IComboBoxOption, IDropdown, IDropdownOption } from "@fluentui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { routeUrls } from "../../../../common/constants";
import { IGenericPayload, IOrganization, IUserSignup } from "../../../../models";
import { fetchOrganizations } from "../../../../redux/actions/catalog/catalog";
import { resetUserSignupDocumentTemplatesAction } from "../../../../redux/actions/user/signup/documentTemplates";
import { updateUserSignupPropertyAction } from "../../../../redux/actions/user/signup/signup";
import { goSelectedStep } from "../../../../redux/actions/user/signup/stepper";
import { fetchUserSignupTypesAction } from "../../../../redux/actions/user/signup/types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetDocumentTypesRequested, resetUserSignupForm } from "../../../../redux/reducers/user/signup/signup";
import { userSignupFormSelector } from "../../../../redux/selectors/user/signup/signup";
import { getAllUserTypesSelector, getLoadingTypes } from "../../../../redux/selectors/user/signup/types";
import { compileNameOfProperty } from "../../../../utils";
import { ButtonPrimary } from "../../../common/buttons/buttons";
import { NavButtonSecondary } from "../../../common/navButtons/navButtons";
import { Selector } from "../../../common/selectors/selector";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { UserSignupTypeContainerStyle } from "./container.jss";

export const UserSignupTypeContainer = () => {
    const { t } = useTranslation("common");
    const style = UserSignupTypeContainerStyle();

    const form = useSelector(userSignupFormSelector);
    const dispatch = useAppDispatch();

    const handleStepChange = (id: number) => {
        dispatch(goSelectedStep(id));
    };

    const userTypes = useSelector(getAllUserTypesSelector.selectAll);
    const loadingTypes = useSelector(getLoadingTypes);

    useEffect(() => {
        if (!userTypes || !userTypes.length) {
            dispatch(fetchUserSignupTypesAction());
        }
    }, []);

    const userTypesComboOptions: IComboBoxOption[] = userTypes.map((item: any) => {
        return {
            key: item.id,
            text: item.name,
        };
    });

    const comboBoxRef = React.useRef<IDropdown>(null);

    const selectedUserType = useSelector(userSignupFormSelector);

    const handleUserTypeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            const payload: IGenericPayload = {
                name: compileNameOfProperty<IUserSignup>("userType"),
                value: option.key,
            };
            dispatch(updateUserSignupPropertyAction(payload));

            const userPositionTypePayload: IGenericPayload = {
                name: compileNameOfProperty<IUserSignup>("userPositionType"),
                value: "",
            };
            dispatch(updateUserSignupPropertyAction(userPositionTypePayload));

            dispatch(resetUserSignupDocumentTemplatesAction());
            dispatch(resetDocumentTypesRequested());
        } else {
            dispatch(resetUserSignupForm());
        }
    };
    const organizations: IOrganization[] = useAppSelector((store) => store.catalog.organizations.data);

    const organizationOptions: IComboBoxOption[] = organizations.map((item: any, _index: any) => {
        return {
            key: item.id,
            text: item.name,
        };
    });

    const organizationSelectorRef = React.useRef<IDropdown>(null);

    useEffect(() => {
        !organizations.length && dispatch(fetchOrganizations());
    }, [dispatch, organizations]);

    const onOrganizationChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            dispatch(updateUserSignupPropertyAction({ name: "organizationId", value: option.key }));
        } else {
            dispatch(resetUserSignupForm());
        }
    };
    return (
        <>
            <div className={style.container}>
                <div className={style.column}>
                    {loadingTypes ? (
                        <Skeleton
                            rows={1}
                            marginRow={20}
                        />
                    ) : (
                        <div className={style.rowForm}>
                            <Selector
                                selectedKey={form.organizationId}
                                comboBoxRef={organizationSelectorRef}
                                title={t("USER.SIGNUP.SELECTORGANIZATION")}
                                options={organizationOptions}
                                onChange={onOrganizationChange}
                                name={compileNameOfProperty<IUserSignup>("organizationId")}
                                required={true}
                            />
                            <Selector
                                selectedKey={selectedUserType.userType}
                                comboBoxRef={comboBoxRef}
                                title={t("USER.SIGNUP.USERTYPELABEL")}
                                options={userTypesComboOptions}
                                onChange={handleUserTypeChange}
                                name={compileNameOfProperty<IUserSignup>("userType")}
                                required={true}
                            />
                        </div>
                    )}
                    <div className={style.buttonsRow}>
                        <NavButtonSecondary
                            title={t("USER.SIGNUP.BACKBUTTON")}
                            to={routeUrls.HOME}
                        />
                        <ButtonPrimary
                            disabled={!form.userType || !form.organizationId}
                            handleClick={() => handleStepChange(2)}
                            title={t("USER.SIGNUP.NEXTBUTTON")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
