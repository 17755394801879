import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const SignupResumeDataEntityStyle = createUseStyles((theme: any) => ({
  container: {
    padding: theme.paddings.default,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [mediaQueries.TABLET]: {
      flexDirection: "column",
      alignItems: "center",
      rowGap: theme.gaps.sizes.base,
    },
  },
  twoItemsRow: {
    display: "flex",
    paddingBottom: theme.paddings.default,
    width: "100%",
    [mediaQueries.MOBILE]: {
      flexDirection: "column",
      rowGap: theme.gaps.sizes.base,
    },
    "& > * ": {
      width: "50%",
      [mediaQueries.MOBILE]: {
        width: "100%",
      },
    },
  },
}));
