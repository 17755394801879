import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVlcportHistoricList } from "../../../../../models/vlcPort/IHistoricRequest";

interface IVlcportHistoricListState {
    list?: IVlcportHistoricList[];
    loading: boolean;
}

const initialState: IVlcportHistoricListState = {
    loading: false,
};

export const vlcportHistoricInspectionResultListReducer = createSlice({
    name: "vlcportHistoricInspectionResultListReducer",
    initialState,
    reducers: {
        resetVlcportHistoricInspectionResultList: () => initialState,
        setVlcportHistoricInspectionResultList: (state, action: PayloadAction<IVlcportHistoricList[]>) => {
            state.list = action.payload;
        },
        setVlcportHistoricInspectionResultLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetVlcportHistoricInspectionResultList, setVlcportHistoricInspectionResultList, setVlcportHistoricInspectionResultLoading } =
    vlcportHistoricInspectionResultListReducer.actions;

export default vlcportHistoricInspectionResultListReducer.reducer;
