import { useEffect, useState } from "react";

interface IDigitalTimerProps {
    className:string;
}

export const DigitalTimer = (props:IDigitalTimerProps ) => {
    const [time, changeTime] = useState(new Date().toLocaleTimeString());
    useEffect(function () {
        setInterval(() => {
            changeTime(new Date().toLocaleTimeString());
        }, 1000);
    }, []);

    return <div className={props.className}>{time}</div>;
};
