import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NameIcons } from "../../../../../assets/icons";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IActuation } from "../../../../../models/requests/IEditRequest";
import { fetchActuationForm } from "../../../../../redux/actions/request/edit/action/actuation";
import { setEditRequestActuationPopUp, updateActuationFormProperty } from "../../../../../redux/reducers/request/edit/form/actuation";
import { compileNameOfProperty, convertToLocal } from "../../../../../utils";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { CustomTableActions } from "../../../../common/tableCustom/actions/actions";
import { ColumnType, CustomTableList } from "../../../../common/tableCustom/tableCustom";
import { isShowActionsOfActuation } from "../../../common/validation/inspections";

interface IRequestListActuationListProps {
    list: IActuation[];
    requestId: number;
    showFormActuationModal: (value: any) => any;
    showDeleteActuationModal: (value: any) => any;
}

export const RequestListActuationList: React.FC<IRequestListActuationListProps> = ({ list, requestId, showFormActuationModal, showDeleteActuationModal }) => {
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });

    const dispatch = useDispatch();

    const actions = (actuation: IActuation) => {
        return (
            <div>
                <CustomIcons
                    iconName={NameIcons.EDIT}
                    isButton={true}
                    onClick={() => {
                        actuation.id && actuation.inspectionId && dispatch(fetchActuationForm(requestId, actuation.inspectionId, actuation.id));
                        dispatch(setEditRequestActuationPopUp(PopUpCodes.UPDATE));
                        showFormActuationModal(true);
                    }}
                    tooltip={t("TOOLTIP.UPDATE_ACTUATION")}
                />

                <Separator />

                <CustomIcons
                    iconName={NameIcons.REMOVE}
                    isButton={true}
                    onClick={() => {
                        dispatch(setEditRequestActuationPopUp(PopUpCodes.DELETE));
                        dispatch(
                            updateActuationFormProperty({ name: compileNameOfProperty<IActuation>("inspectionId"), value: actuation.inspectionId }),
                        );
                        dispatch(updateActuationFormProperty({ name: compileNameOfProperty<IActuation>("id"), value: actuation.id }));
                        showDeleteActuationModal(true);
                    }}
                    tooltip={t("TOOLTIP.REMOVE_ACTUATION")}
                />
            </div>
        );
    };
    
    const columns: ColumnType<IActuation, keyof IActuation>[] = [
        { key: "date", title: t("POPUPINSPECTION.DATA") },
        { key: "description", title: t("POPUPINSPECTION.ACTUATION") },
        { key: "instructions", title: t("POPUPINSPECTION.INSTRUCTION") },
        { key: "organismDescription", title: t("POPUPINSPECTION.ORGANISM") },
        { key: "actions", title: "" },
    ];

    const tableBodyContent = list.map((item) => {
        return {
            ...item,
            date: convertToLocal(item.date, FormatDate.DATE_HOUR_MINUTE),
            actions: <CustomTableActions children={isShowActionsOfActuation(item.organismId) ? actions(item) : <></>} />,
        };
    });

    return (
        <>
            <CustomTableList
                data={tableBodyContent}
                columns={columns}
                loading={false}
            />
        </>
    );
};
