import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterTurn } from "../../../../../models/resources/master/ITurn";


export interface IMasterTurnsFormState {
    form: IMasterTurn;
    loading: boolean;
}

const initialState: IMasterTurnsFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterTurnsForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterTurnsForm: () => initialState,
        setLoadingMasterTurnsForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterTurnsForm: (state, action: PayloadAction<IMasterTurn>) => {
            state.form = action.payload;
        },
        updateMasterTurnsProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterTurnsForm, setLoadingMasterTurnsForm, setMasterTurnsForm, updateMasterTurnsProperty } = masterTurnsForm.actions;

export default masterTurnsForm.reducer;
