import { pageSizes } from "../../../../common/constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IManagementUser } from "../../../../models/management/user/IManagementUser";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { emptyPaginationState, IPaginationState } from "../../../../models/common/IPaginationState";
import { getFirstItemNumber, getLastItemNumber, getTotalPages } from "../../../../utils";

interface ManagementUserListState {
    list: IManagementUser[];
    sort: ISortState;
    pagination: IPaginationState,
    loading: boolean;
}

const initialState: ManagementUserListState = {
    list: [],
    sort: emptySortState(),
    pagination: emptyPaginationState(pageSizes.MANAGEMENT_USER),
    loading: false,
};

export const managementUserListSlice = createSlice({
    name: "managementUserList",
    initialState,
    reducers: {
        resetManagementUserList: () => initialState,

        setLoadingManagementUserList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        fetchManagementUserList: (state, action: PayloadAction<IManagementUser[]>) => {
            state.list = action.payload;
            state.pagination.totalItems = action.payload.length;
            state.pagination.totalPages = getTotalPages(state.pagination);
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
            state.pagination.firstItemNumber = getFirstItemNumber(state.pagination);
            state.pagination.lastItemNumber = getLastItemNumber(state.pagination);
        },
    },
});

export const { resetManagementUserList, setLoadingManagementUserList, fetchManagementUserList, updateSortColumn, setPage } = managementUserListSlice.actions;

export default managementUserListSlice.reducer;
