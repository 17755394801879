import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IActuation } from "../../../../../models/requests/IEditRequest";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";

interface EditRequestInspectionActuationState {
    form: IActuation;
    showPopUp: PopUpCodes;
    loadingForm: boolean;
    loadingList: boolean;
    list?: IActuation[];
}

const initialState: EditRequestInspectionActuationState = {
    form: {
        organismId: 0,
        typeId: 0,
        instructions: "",
    },
    showPopUp: PopUpCodes.NONE,
    loadingForm: false,
    loadingList: false,
    list: undefined,
};

export const actuationSlice = createSlice({
    name: "editRequestReducer",
    initialState,
    reducers: {
        updateActuationFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setActuationFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loadingForm = action.payload;
        },
        setActuationListLoading: (state, action: PayloadAction<boolean>) => {
            state.loadingList = action.payload;
        },

        resetActuationFormDataState: () => initialState,
        setActuationFormData: (state, action: PayloadAction<IActuation>) => {
            state.form = action.payload;
        },
        setActuationList: (state, action: PayloadAction<IActuation[]>) => {
            state.list = action.payload;
        },
        resetActuationFormData: (state) => {
            state.form = {
                organismId: 0,
                typeId: 0,
                instructions: "",
            };
        },
        resetListActuations: (state) => {
            state.list = undefined;
        },
        updateOrganismId: (state, action: PayloadAction<number>) => {
            state.form.organismId = action.payload;
        },
        setEditRequestActuationPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    updateActuationFormProperty,
    resetActuationFormDataState,
    setActuationFormLoading,
    setActuationFormData,
    setActuationList,
    setActuationListLoading,
    resetListActuations,
    resetActuationFormData,
    updateOrganismId,
    setEditRequestActuationPopUp,
} = actuationSlice.actions;

export default actuationSlice.reducer;
