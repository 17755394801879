import { IDropdownOption } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import React, { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { FormField } from "../../../../../../common/fields/fields";
import { useForm } from "react-hook-form";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { useTheme } from "react-jss";
import { MasterOriginWareFormStyle } from "./formStyle.jss";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { Selector } from "../../../../../../common/selectors/selector";
import { IMerchandiseOrigin } from "../../../../../../../models";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { IMasterMerchandiseOrigin } from "../../../../../../../models/resources/master/IMerchandiseOrigin";
import { updateMasterMerchandiseOriginsProperty, setMasterMerchandiseOriginsForm } from "../../../../../../../redux/reducers/resources/master/merchandiseOrigins/form";

interface IMasterMerchandiseOriginFormProps {
    showNotData: boolean;
    data: IMerchandiseOrigin[];
}

export const MasterMerchandiseOriginsForm: React.FC<IMasterMerchandiseOriginFormProps> = ({ data, showNotData }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { form } = useAppSelector((state) => state.masterMerchandiseOriginsForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { register, control } = useForm<IMasterMerchandiseOrigin>({ mode: "onChange" });
    const theme = useTheme();
    const style = MasterOriginWareFormStyle({ theme });
    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateMasterMerchandiseOriginsProperty({ name, value }));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };
    const onSelectorChange = (event: any, option: any, name: string) => {
        onUpdateFormProperty(name, option.key);
        let merchandiseOriginItem = data.find((item) => item.id === option.key);

        if (merchandiseOriginItem) {
            let form: IMasterMerchandiseOrigin = {
                referenceId: String(option.key),
                code: merchandiseOriginItem.code,
                description: merchandiseOriginItem.description,
                active: true,
            };

            dispatch(setMasterMerchandiseOriginsForm(form));
        }
    };
    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp, form]);

    return showNotData ? (
        <NotDataWarningMessage />
    ) : (
        <div className={style.form}>
            {!form.id && (
                <div className={style.rowForm}>
                    <Selector
                        selectedKey={Number(form.referenceId)}
                        name={compileNameOfProperty<IMasterMerchandiseOrigin>("referenceId")}
                        title={t("FORM.ORIGIN_MERCHANDISE_SELECTOR")}
                        options={mapIDescriptionToDropDownOptions(data)}
                        onChange={onSelectorChange}
                        required={true}
                    />
                </div>
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code ?? undefined}
                    disabled={true}
                    {...register(compileNameOfProperty<IMasterMerchandiseOrigin>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />

                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? undefined}
                    disabled={disabledInput}
                    {...register(compileNameOfProperty<IMasterMerchandiseOrigin>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? DefaultValues.NOT_TEXT,
                    })}
                    isRequired={true}
                />
            </div>

            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterMerchandiseOrigin>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
