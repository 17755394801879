import { createUseStyles } from "react-jss";
export const TimeLineItemStyle = createUseStyles((theme: any) => ({
    timeLineItem: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        margin: "12px 0",
        left: "auto",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
        paddingLeft: 5,
        padding: 12,
        borderRadius: 5,
        width: "98%",
    },
    actionDescription: {
        paddingLeft: 5,
    },

    circle: {
        border: `3px solid ${theme.colors.palette.purple}`,
        height: 25,
        width: 25,
        borderRadius: 25,
        position: "absolute",
        left: -51,
        top: "calc(50% - 15px)",
        background: "white",
        zIndex: 2,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        alignItems: "center",
    },

    arrow: {
        content: " ",
        background: "#fff",
        boxShadow: "1px -1px 1px rgba(0, 0, 0, 0.2)",
        position: "absolute",
        left: -8,
        top: "calc(50% - 7.5px)",
        transform: "rotate(225deg)",
        width: 15,
        height: 15,
        zIndex: 1,
    },
    icon: {
        marginTop: 2,
        color:theme.colors.palette.purple
    },
    userData: {
        position: "absolute",
        left: -305,
        width: 250,
    },
}));
