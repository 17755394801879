import { createUseStyles } from "react-jss";

export const EmailViewerStyle = createUseStyles((theme: any) => ({
    container: (props: any) => ({
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    }),

    to: {
        background: theme.colors.palette.purpleLighter,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: theme.paddings.sizes.base,
        height: 32,
        borderBottom: `1px solid ${theme.colors.palette.white}`,
        gap: theme.gaps.sizes.xs
    },

    toIcon: {
        color: theme.colors.palette.purple,
        fontWeight: theme.fonts.weight.bold,
        paddingRight: theme.paddings.sizes.sm
    },

    toPill: {
        backgroundColor: theme.colors.palette.greyBlue,
        border: `1px solid ${theme.colors.palette.purple}`,
        color: theme.colors.palette.purple,
        padding: "2px 8px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        borderRadius: 16
    },


    subject: {
        background: theme.colors.palette.purpleLighter,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: theme.paddings.sizes.base,
        color: theme.colors.palette.purple,
        fontWeight: theme.fonts.weight.bold,
        height: 32,
    },

    body: (props: any) => ({
        height: props.height ?? "calc(85vh - 10rem)",
        border: `2px solid ${theme.colors.palette.purpleLighter}`,
        overflow: "auto",
        padding: theme.paddings.sizes.base,

        "& p": {
            paddingBottom: theme.paddings.sizes.base,
        }
    })
}));
