import { IDropdownOption } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fetchCertificates, fetchCertificatesByOrganism } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface ICertificateSelectorProps extends IBaseSelectorProps {
    organismId?: number;
    resetSelectorAtRedux?: (propertyName: string) => void;
}

export const CertificateWhithOrganismSelector: React.FC<ICertificateSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    organismId,
    disabled,
    isRequired,
    resetSelectorAtRedux,
    control,
    rules,
    allOptionText,
    calloutLabel,
    calloutMessage,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.certificatesByOrganism);
    const dispatch = useAppDispatch();
    const isFirstRender = useRef<boolean>(true);
    const [selectorkey, setSelectorKey] = useState<number>(0);
    useEffect(() => {
        organismId && cleanStoreIfProceed();
        evalIfIsFirstRender();
    }, [dispatch, organismId]);

    const cleanStoreIfProceed = useCallback(() => {
        if (resetSelectorAtRedux && !isFirstRender.current) {
            resetSelectorAtRedux(propertyName);
            setSelectorKey((value) => value + 1);
        }
        dispatch(fetchCertificatesByOrganism(organismId ?? 0));
    }, [dispatch, organismId, isFirstRender]);

    const evalIfIsFirstRender = useCallback(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, [dispatch, isFirstRender]);

    const onSelectorChange = (_e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            required={isRequired}
            options={useMemo(() => mapIDescriptionToDropDownOptions(data), [data])}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={loading || disabled || !organismId}
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            key={selectorkey}
            calloutLabel={calloutLabel}
            calloutMessage={calloutMessage}
        />
    );
};

export const CertificateSelector: React.FC<ICertificateSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    organismId,
    disabled,
    isRequired,
    control,
    rules,
    allOption,
    allOptionText,
    calloutLabel,
    calloutMessage,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.certificates);
    const dispatch = useAppDispatch();

    const onSelectorChange = (_e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };
    useEffect(() => {
        data.length === 0 && dispatch(fetchCertificates());
    }, []);
    return (
        <ControlledDropdown
            required={isRequired}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(data, allOptionText) : mapIDescriptionToDropDownOptions(data)}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={loading || disabled}
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            calloutLabel={calloutLabel}
            calloutMessage={calloutMessage}
        />
    );
};
