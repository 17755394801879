import { RoleType } from "../../../../common/enum";
import { IRequestExpedient } from "../../../../models";
import { IInspection } from "../../../../models/requests/IEditRequest";
import { store } from "../../../../redux/store";

export const isShowActionsOfActuation = (organismInspectionId?: number) => {
    const { organismId, roles } = store.getState().userData.profile;

    for (let rol of roles) {
        if (rol.id === RoleType.ORGANISM_ADMIN || rol.id === RoleType.ORGANISM_TECHNICIAN || rol.id === RoleType.ORGANISM_INSPECTOR || organismId) {
            return organismId === organismInspectionId;
        }
    }


    return true;
};

export const isShowAddActuation = (organismInspectionId?: number) => {
    return isShowActionsOfActuation(organismInspectionId);
};

export const isShowAddMultipleIcon = (requestExpedient: IRequestExpedient[], inspections: IInspection[]) => {
    const { roles, organismId } = store.getState().userData.profile;

    for (let rol of roles) {
        if (rol.id === RoleType.ORGANISM_ADMIN || rol.id === RoleType.ORGANISM_TECHNICIAN || rol.id === RoleType.ORGANISM_INSPECTOR || organismId) {
            return inspections.length ? fetchIsOrganismToInspection(inspections) : fetchIsOrganismToInspection(requestExpedient);
        }
    }

    return true;
};

const fetchIsOrganismToInspection = (list: any[]) => {
    const { organismId } = store.getState().userData.profile;
    if (list?.length) {
        for (let item of list) {
            if (item.organismId === organismId) {
                return true;
            }
        }
    }

    return false;
};
