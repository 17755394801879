import {  useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../redux/hooks";
import { EditRequestTabsInvoiceConceptRemarksFormStyle } from "./formStyle.jss";
import React, { useState } from "react";
import { ButtonPrimaryInverseWithBorder } from "../../../../../../../../common/buttons/buttons";
import { FormField } from "../../../../../../../../common/fields/fields";
import { requestMaxLength } from "../../../../../../create/validation/requestConfig";
import { setInvoicingRemarks, setValidationRemarksInvoicedConcept } from "../../../../../../../../../redux/reducers/request/edit/editRequest";
import { IConfigEditRequest } from "../../../../../../../../../models/requests/IConfigEditRequest";
import { PopUpConfirmation } from "../../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpConfirmationType } from "../../../../../../../../../common/enum/PopUpConfirmationType";

interface IEditRequestTabsInvoiceConceptRemarksFormProps {
    schema: IConfigEditRequest;
}

export const EditRequestTabsInvoiceConceptRemarksForm: React.FC<IEditRequestTabsInvoiceConceptRemarksFormProps> = ({ schema }) => {
    const theme = useTheme();
    const style = EditRequestTabsInvoiceConceptRemarksFormStyle({ theme });
    const billingConcept = useAppSelector((store) => store.editRequest);
    const [showInfoAlertCopyRemarcks, setShowInfoAlertCopyRemarcks] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const {
        register,
        formState: { errors },
    } = useForm<any>({ mode: "onChange" });

    const handleValidationRemarksInvoicedConcept = (value: any) => {
        dispatch(setValidationRemarksInvoicedConcept(value.target.value));
    };

    const handleRemarks = (value: any) => {
        dispatch(setInvoicingRemarks(value.target.value));
    };

    const onDumpRemarks = () => {
        if (!billingConcept.headerRequest.invoicingRemarks) {
            dispatch(setInvoicingRemarks(billingConcept.headerRequest.validationRemarks));
        } else {
            setShowInfoAlertCopyRemarcks(true);
        }
    };

    const onAcceptInfoAlertRemarks = () => {
        dispatch(setInvoicingRemarks(billingConcept.headerRequest.validationRemarks));
        onCancelInfoAlertRemarks();
    };

    const onCancelInfoAlertRemarks = () => {
        setShowInfoAlertCopyRemarcks(false);
    };

    return (
        <div className={style.generalContainer}>
            <div className={style.formRow}>
                {schema.invoiceConcept_validationRemarks.visible && (
                    <>
                        <div className={style.remarksFields}>
                            <FormField
                                type="text"
                                value={billingConcept.headerRequest.validationRemarks}
                                label={t("FIELDS.VALIDATIONREMARKS")}
                                maxLength={requestMaxLength.DESCRIPTION}
                                error={errors.remarks}
                                {...register("remarks", {
                                    onChange: handleValidationRemarksInvoicedConcept,
                                    value: billingConcept.headerRequest.validationRemarks,
                                })}
                            />
                        </div>
                        <ButtonPrimaryInverseWithBorder
                            handleClick={onDumpRemarks}
                            title={t("BUTTONS.REMARKSBUTTON")}
                        />
                    </>
                )}
            </div>

            <div className={style.remarksFields}>
                {schema.invoiceConcept_invoicingRemarks.visible && (
                    <FormField
                        type="text"
                        value={billingConcept.headerRequest.invoicingRemarks}
                        label={t("FIELDS.INVOICINGREMARKS")}
                        maxLength={requestMaxLength.DESCRIPTION}
                        error={errors.remarks}
                        {...register("remarksConcept", { onChange: handleRemarks, value: billingConcept.headerRequest.invoicingRemarks })}
                        disabled={schema.invoiceConcept_invoicingRemarks.readonly}
                    />
                )}
            </div>
            <div>
                <PopUpConfirmation
                    showPopUp={showInfoAlertCopyRemarcks}
                    onCancel={onCancelInfoAlertRemarks}
                    onAccept={onAcceptInfoAlertRemarks}
                    type={PopUpConfirmationType.INFO}
                    width={"30vw"}
                    height={"auto"}
                    message={t("INFO.MESSAGE_INVOICE_CONCEPT_REMARKS")}
                    buttonAcceptTitle={t("BUTTONS.OVERTURN_ANYWAY")}
                />
            </div>
        </div>
    );
};
