import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IExportState {
    uri: string | undefined;
    loadingExport: boolean;
}

const initialState: IExportState = {
    uri: undefined,
    loadingExport: false
};

export const requestExportSlice = createSlice({
    name: "requestExportList",
    initialState,
    reducers: {
        resetRequestExportList: () => initialState,
        loadingRequestExportList: (state, action: PayloadAction<boolean>) => {
            state.loadingExport = action.payload;
        },
        updateExport: (state, action: PayloadAction<string>) => {
            state.uri = action.payload
        },
    },
});

export const { resetRequestExportList, updateExport, loadingRequestExportList } = requestExportSlice.actions;

export default requestExportSlice.reducer;
