import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEmailItemWithIndex, IGenericPayload, IRequestedDocument, IUploadUserDocument } from "../../../../models";
import { IDocumentRequired } from "../../../../models/user/IDocumentRequired";
import { emptyUserRegisterModel, IUserSignup } from "../../../../models/user/IUserSignup";

interface UserSignupState {
    form: IUserSignup;
    loadingRequiredDocuments: boolean;
    userSignupSuccess: boolean;
}

const initialState: UserSignupState = {
    form: emptyUserRegisterModel(),
    loadingRequiredDocuments: false,
    userSignupSuccess: false,
};

export const userSignupSlice = createSlice({
    name: "userSignupReducer",
    initialState,
    reducers: {
        updateUserSignupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        updateAdminSignupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form.adminData = {
                ...state.form.adminData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateOperationSignupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form.operatorData = {
                ...state.form.operatorData,
                [action.payload.name]: action.payload.value,
            };
        },
        updateEntitySignupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form.entityData = {
                ...state.form.entityData,
                [action.payload.name]: action.payload.value,
            };
        },
        updatePaymentSignupProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form.paymentData = {
                ...state.form.paymentData,
                [action.payload.name]: action.payload.value,
            };
        },
        resetUserSignupForm: () => initialState,
        setDocumentTypesRequested: (state, action: PayloadAction<IDocumentRequired[]>) => {
            state.form.documents = setDocuments(action.payload);
        },
        resetDocumentTypesRequested: (state) => {
            state.form.documents = [];
        },
        loadingUserDocuments: (state, action: PayloadAction<boolean>) => {
            state.loadingRequiredDocuments = action.payload;
        },
        setDocumentFile: (state, action: PayloadAction<IUploadUserDocument>) => {
            state.form.documents[action.payload.index].file = action.payload.url;
            state.form.documents[action.payload.index].contentType = action.payload.contentType;
            state.form.documents[action.payload.index].fileName = action.payload.filename;
        },
        removeDocumentFile : (state, action: PayloadAction<number>) => {
            
            state.form.documents[action.payload].file = undefined;
            state.form.documents[action.payload].contentType = undefined;
            state.form.documents[action.payload].fileName = undefined;
        },

        setAvatarFile: (state, action: PayloadAction<IGenericPayload>) => {
            state.form.avatar.file = action.payload.value;
        },
        userSignUpSuccess: (state) => {
            state.userSignupSuccess = true;
        },
        addOperationEmailItem: (state) => {
            state.form.operatorData.emails.push({
                email: "",
                isValid: false,
            });
        },
        removeOperationEmailItem: (state, action: PayloadAction<number>) => {
            state.form.operatorData.emails.splice(action.payload, 1);
        },
        updateOperationEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.form.operatorData.emails[action.payload.index].email = action.payload.email;
        },
        updateOperationEmailItemIsValid: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.form.operatorData.emails[action.payload.index].isValid = action.payload.isValid;
        },
        addAdminEmailItem: (state) => {
            state.form.adminData.emails.push({
                email: "",
                isValid: false,
            });
        },
        removeAdminEmailItem: (state, action: PayloadAction<number>) => {
            state.form.adminData.emails.splice(action.payload, 1);
        },
        updateAdminEmailItem: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.form.adminData.emails[action.payload.index].email = action.payload.email;
        },
        updateAdminEmailItemIsValid: (state, action: PayloadAction<IEmailItemWithIndex>) => {
            state.form.adminData.emails[action.payload.index].isValid = action.payload.isValid;
        },
    },
});

const setDocuments = (payload: IDocumentRequired[]): IRequestedDocument[] => {
    const result: IRequestedDocument[] = [];
    payload.forEach((item, index) => {
        const document: IRequestedDocument = {
            documentName: item.name,
            documentId: item.id,
            file: null,
            index,
        };
        result.push(document);
    });
    return result;
};

export const {
    updateUserSignupProperty,
    resetUserSignupForm,
    setDocumentTypesRequested,
    loadingUserDocuments,
    setDocumentFile,
    setAvatarFile,
    userSignUpSuccess,
    updateEntitySignupProperty,
    updateAdminSignupProperty,
    updateOperationSignupProperty,
    updatePaymentSignupProperty,
    addOperationEmailItem,
    removeOperationEmailItem,
    updateOperationEmailItem,
    updateOperationEmailItemIsValid,
    addAdminEmailItem,
    removeAdminEmailItem,
    updateAdminEmailItem,
    updateAdminEmailItemIsValid,
    resetDocumentTypesRequested,
    removeDocumentFile
} = userSignupSlice.actions;

export default userSignupSlice.reducer;
