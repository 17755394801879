import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterDisplayDocksFormController } from "../controller/controller";
import { MasterDisplayDocksColumns } from "./column/column";
import { fetchMasterDocksList } from "../../../../../../redux/actions/resources/master/docks/docks";
interface IMasterDisplayDocksList {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterDisplayDocksList: React.FC<IMasterDisplayDocksList> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { list, loading } = useAppSelector((state) => state.masterDocksList);

    const renderColumns = () => {
        return MasterDisplayDocksColumns();
    };
    useEffect(() => {
        list === undefined && dispatch(fetchMasterDocksList());
    }, [list]);
    return loading ? (
        <Skeleton rows={3}></Skeleton>
    ) : (
        <div
            style={{
                height: "100%",
            }}
        >
            <CustomDetailsList
                data={list ?? []}
                renderColumns={renderColumns}
                height={"calc(100vh - 9rem)"}
            />

            <MasterDisplayDocksFormController
                widthPopUp={widthPopUp}
                heigthPopUp={heigthPopUp}
            />
        </div>
    );
};
