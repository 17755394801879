import React from "react";
import { useTheme } from "react-jss";

import { CustomTableList } from "../customTableList/tableList";
import { StatisticsDailyChargeListDateItemtStyle } from "./appointmentStyle.jss";
import { IConfigUpcominAppointmentListSchema } from "../generateSchema";
import { IUpcomingAppointment } from "../../../../../models/upcomingAppointment/IUpcomingAppointments";
import { generateRandomKeys } from "../../../../../utils/keys";
import { Separator } from "../../../../common/separator/separator";

interface IStatisticsDailyChargeListDateItem {
    codeItem: IUpcomingAppointment;
    schema: IConfigUpcominAppointmentListSchema;
}
export const StatisticsDailyChargeListDateItem: React.FC<IStatisticsDailyChargeListDateItem> = ({ codeItem, schema }) => {
    const theme = useTheme();
    const style = StatisticsDailyChargeListDateItemtStyle({ theme });
    const lastIndex = codeItem.upcomingAppointments && codeItem.upcomingAppointments.length - 1;

    const bodyContent = codeItem.upcomingAppointments.map((item, index) => {
        return (
            <div
                className="row"
                key={generateRandomKeys().toString()}
            >
                <div className="data">
                    <div className="cell"></div>
                    {schema.transportUnitNumber.visible && <div className="cell">{item.transportUnitNumber}</div>}
                    {schema.transportUnitSizeCode.visible && <div className="cell ">{item.transportUnitSizeCode}</div>}
                    {schema.appointmentDockDescription.visible && <div className="cell">{item.appointmentDockDescription}</div>}
                    {schema.appointmentDate.visible && <div className="cell ">{item.appointmentDate.substring(0, 10)}</div>}
                    {schema.appointmentTimeZoneTimeRange.visible && <div className="cell hour">{item.appointmentTimeZoneTimeRange}</div>}
                    {schema.terminalDescription.visible && <div className="cell ">{item.terminalDescription}</div>}
                    {schema.expedientInspector.visible && (
                        <div className="cell inspector">
                            <div className={style.inspectorCell}>
                                {item.expedientInspector.map((item) => {
                                    return <span key={`${item}-${generateRandomKeys()}`}>{item}</span>;
                                })}
                            </div>
                        </div>
                    )}
                    {schema.customerName.visible && <div className="cell inspector">{item.customerName}</div>}

                    {schema.requestStateDescription.visible && <div className="cell ">{item.requestStateDescription}</div>}
                </div>
                {lastIndex !== index && (
                    <Separator
                        size="89%"
                        horizontal={true}
                    />
                )}
            </div>
        );
    });

    return (
        <>
            <div className={style.code}>{codeItem.categoryGroupCode}</div>
            <CustomTableList bodyContent={bodyContent} />
        </>
    );
};
