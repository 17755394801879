import { Store } from "redux";
import { IUserModuleData } from "../../models/user/IUserModuleData";
import { getUserModules } from "../../redux/selectors/user/module/module";
import { ModuleTypes } from "../../common/enum/me/ModuleTypes";

class MeModuleChecker {
    static hasActiveModule(store: Store, module: ModuleTypes): boolean {
        const meModules: IUserModuleData[] = getUserModules(store.getState());
        return meModules.some((m) => m.id === module);
    }
}

export default MeModuleChecker;
