import { nextStep, previousStep, setStep } from "../../../reducers/user/signup/stepper";
import { AppDispatch, AppThunk } from "../../../store";

export const goNextStep = (): AppThunk => (dispatch: AppDispatch) => {
    dispatch(nextStep());
};

export const goPreviousStep = (): AppThunk => (dispatch: AppDispatch) => {
    dispatch(previousStep());
};

export const goSelectedStep =
    (step: number): AppThunk =>
    (dispatch: AppDispatch) => {
        dispatch(setStep(step));
    };
