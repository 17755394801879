import { createUseStyles } from "react-jss";

export const VlcPortImportRequestFormStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        gap: theme.gaps.sizes.base,
    },
    file: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: 40,
    },
    code: {
        width: "100%",
       // paddingTop: 15,
        height: "calc(70vh - 1rem)",
    },
}));
