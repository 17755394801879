import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { fetchNotificationModes, fetchNotificationTypes, fetchRoleOptions } from "../../../../../redux/actions/catalog/catalog";
import { useAppSelector } from "../../../../../redux/hooks";
import { IManagementNotificationForm, updateManagementNotificationProperty } from "../../../../../redux/reducers/management/notification/form";
import { mapIDescriptionToDropDownOptions, mapRolesToDropDownOptions } from "../../../../../utils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { ControlledRadioButtonGroup } from "../../../../common/radioButton/controlledRadioButtonGroup/controlledRadioButtonGroup";
import { ControlledDropdown } from "../../../../common/selectors";
import { ManagementNotificationDefaultFormStyle } from "./formStyle.jss";
import { Alert } from "../../../../common/alert/alert";
import { TimePickerComponent } from "../../../../common/timePicker/timePicker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IManagementNotificationDefaultFormProps {
    onChangeSelector: (option?: IDropdownOption, name?: string) => any;
    onRadioButtonChange: (optionKey: string, propertyName: string) => any;
}

export const ManagementNotificationForm: React.FC<IManagementNotificationDefaultFormProps> = ({ onChangeSelector, onRadioButtonChange }) => {
    const { control } = useForm<IManagementNotificationForm>({ mode: "onChange" });
    const { form } = useAppSelector((store) => store.managementNotificationForm);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.NOTIFICATION" });
    const theme = useTheme();
    const style = ManagementNotificationDefaultFormStyle({ theme });
    const dispatch = useDispatch();
    const { data } = useAppSelector((store) => store.catalog.notificationTypes);
    const roles = useAppSelector((store) => store.catalog.roles.data);
    const { data: notificationModes } = useAppSelector((store) => store.catalog.notificationModes);

    const customOnChangeRole = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChangeSelector(option, compileNameOfProperty<IManagementNotificationForm>("roleId"));
    };

    const customOnChangeType = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChangeSelector(option, compileNameOfProperty<IManagementNotificationForm>("notificationTypeId"));
    };

    const customOnChangeMode = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChangeSelector(option, compileNameOfProperty<IManagementNotificationForm>("notificationModeId"));
    };

    const onTimeChange = useCallback(
        (date: MaterialUiPickersDate) => {
            if (date) {
                let payload = {
                    name: compileNameOfProperty<IManagementNotificationForm>("defaultScheduleTime"),
                    value: new Date(date.toISOString()),
                };
                dispatch(updateManagementNotificationProperty(payload));
            }
        },
        [dispatch],
    );
    const details = useMemo(() => {
        if (form.notificationTypeId) {
            let item = data.find((item) => item.id === Number(form.notificationTypeId));
            return item?.details;
        }

        return "";
    }, [form.notificationTypeId, data]);

    useEffect(() => {
        if (roles === undefined) {
            dispatch(fetchRoleOptions());
        }
    }, [roles]);

    useEffect(() => {
        if (!data || !data.length) {
            dispatch(fetchNotificationTypes());
        }
    }, [data]);

    useEffect(() => {
        if (!notificationModes || !notificationModes.length) {
            dispatch(fetchNotificationModes());
        }
    }, [notificationModes]);

    useEffect(() => {
        if (!form.scheduleTime) {
            let payload = {
                name: compileNameOfProperty<IManagementNotificationForm>("defaultScheduleTime"),
                value: null,
            };
            dispatch(updateManagementNotificationProperty(payload));
        }
    }, [form.scheduleTime]);
    return (
        <div className={style.containerForm}>
            <div className={style.rowForm}>
                <ControlledDropdown
                    required={true}
                    options={mapRolesToDropDownOptions(roles ?? [])}
                    label={t("ROLE")}
                    control={control}
                    name={compileNameOfProperty<IManagementNotificationForm>("roleId")}
                    onChange={customOnChangeRole}
                    selectedKey={form.roleId}
                    rules={{ required: true }}
                />
            </div>
            <div className={style.rowForm}>
                <ControlledDropdown
                    required={true}
                    options={mapIDescriptionToDropDownOptions(notificationModes)}
                    label={t("NOTIFICATIONMODE")}
                    control={control}
                    name={compileNameOfProperty<IManagementNotificationForm>("notificationModeId")}
                    onChange={customOnChangeMode}
                    selectedKey={form.notificationModeId}
                    rules={{ required: true }}
                />
            </div>
            <div className={style.rowForm}>
                <ControlledDropdown
                    required={true}
                    options={mapIDescriptionToDropDownOptions(data)}
                    label={t("NOTIFICATIONTYPE")}
                    control={control}
                    name={compileNameOfProperty<IManagementNotificationForm>("notificationTypeId")}
                    onChange={customOnChangeType}
                    selectedKey={form.notificationTypeId}
                    rules={{ required: true }}
                />
            </div>
            <div className={style.rowForm}>
                <div className={style.checkBox}>
                    <ControlledRadioButtonGroup
                        label={t("SCHEDULE_TIME")}
                        selectedKey={String(+form.scheduleTime)}
                        onRadioButtonChange={onRadioButtonChange}
                        control={control}
                        name={compileNameOfProperty<IManagementNotificationForm>("scheduleTime")}
                        required={true}
                    />
                </div>

                <div className={style.hourContainer}>
                    <TimePickerComponent
                        onChange={onTimeChange}
                        value={form.defaultScheduleTime}
                        label={t("HOUR")}
                        disabled={!form.scheduleTime}
                        required={form.scheduleTime}
                    />
                </div>
            </div>

            <div className={style.rowForm}>
               <div className={style.checkBox}>
               <ControlledRadioButtonGroup
                    label={t("ACTIVEBYDEFAULT")}
                    selectedKey={String(+form.activeByDefault)}
                    onRadioButtonChange={onRadioButtonChange}
                    control={control}
                    name={compileNameOfProperty<IManagementNotificationForm>("activeByDefault")}
                    required={true}
                />
               </div>
                <div className={style.checkBox}>
                <ControlledRadioButtonGroup
                    label={t("OTHER_EMAILS")}
                    selectedKey={String(+form.otherEmails)}
                    onRadioButtonChange={onRadioButtonChange}
                    control={control}
                    name={compileNameOfProperty<IManagementNotificationForm>("otherEmails")}
                    required={true}
                />
                </div>
                
            </div>

            {details && (
                <div className={style.details}>
                    <Alert
                        icon="info"
                        data={details}
                        title={t("DETAILS")}
                    />
                </div>
            )}
        </div>
    );
};
