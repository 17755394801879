import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetAppointmentHistoricForm } from "../../../../redux/reducers/appointment/historic/form";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { AppointmentHistoricForm } from "./form/form";

export const AppointmentHistoryController = () => {
    const { showPopUp } = useAppSelector((state) => state.appointmetnHistoricForm);
    const dispatch = useAppDispatch();
    const onCancel = () => {
        dispatch(resetAppointmentHistoricForm());
    };
    
    return (
        <PopUpForm
            content={<AppointmentHistoricForm />}
            title={""}
            isVisible={showPopUp === PopUpCodes.CREATE}
            onCancel={onCancel}
            onSave={onCancel}
            width={"80vw"}
            onlyHidden={true}
            height={"calc(80vh - 10rem)"}
        />
    );
};
