import { createUseStyles } from "react-jss";

export const PrintRequestStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: theme.paddings.default,
        paddingRight: theme.paddings.default,
        width: "120px",
        height: "80%",
        position: "relative",
        fontSize: theme.fonts.sizes.sm,
    },
    selector: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 12,
        width: "100%",
        cursor: "pointer",
        height: "100%",
        " & i": {
            width: 16,
            " & .st0": {
                fill: theme.colors.stateChanger.primary.color,
                stroke: theme.colors.stateChanger.primary.color,
                strokeWidth: 0,
            },
        },
        " & .arrow": {
            marginLeft: "auto",
        },
        " & .arrowUp": {
            marginLeft: "auto",
            transform: "rotate(180deg)",
        },
    },
    currentState: {
        color: theme.colors.stateChanger.primary.color,
        fontWeight: theme.fonts.weight.bold,
    },
    actionMenu: {
        position: "absolute",
        zIndex: "100",
        top: 55,
        left: -50,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 5,
        width: "223px",
        backgroundColor: theme.colors.headerNav.background,
        borderRadius: theme.borders.default.radius,
        padding: theme.paddings.default,
        WebkitBoxShadow: theme.borders.actionMenu.boxShadow,
        MozBoxShadow: theme.borders.actionMenu.boxShadow,
        boxShadow: theme.borders.actionMenu.boxShadow,
        "& > div:hover": {
            backgroundColor: theme.colors.stateChanger.secondary.selectedBackground,
            borderRadius:5
        },
    },
    optionPrintSelectorContainer: {
        display: "flex",
        cursor: "pointer",
        width: "100%",
        "& *:hover": {},
    },
    optionPrintText: {
        display: "flex",
        alignItems: "center",
        color: theme.colors.stateChanger.primary.color,
    },
}));
