import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../models";
import { IManagementNotificationFilter } from "../../../../models/management/notification/IManagementNotificationFilter";
import { fetchNotificationTypes, fetchRoleOptions } from "../../../../redux/actions/catalog/catalog";
import { fetchManagementNotificationListAction } from "../../../../redux/actions/management/notification/notication";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetFilter, updateFilterProperty } from "../../../../redux/reducers/management/notification/filter";
import { compileNameOfProperty, mapIDescriptionToDropDownOptions, mapRolesToDropDownOptions } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { CustomSelector } from "../../../common/selectors/customSelector/customSelector";
import { ManagementNotificationFilterStyle } from "./filterStyle.jss";

export const ManagementNotificationFilter = () => {
    const theme = useTheme();
    const styles = ManagementNotificationFilterStyle({ theme });
    const { filter } = useAppSelector((state) => state.managementNotificationFilter);
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.NOTIFICATION" });
    const dispatch = useAppDispatch();

    const roles = useAppSelector((store) => store.catalog.roles.data);

    useEffect(() => {
        if (roles === undefined) {
            dispatch(fetchRoleOptions());
        }
    }, [roles]);

    const { data } = useAppSelector((store) => store.catalog.notificationTypes);

    useEffect(() => {
        if (!data || !data.length) {
            dispatch(fetchNotificationTypes());
        }
    }, [data]);

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            const payload: IGenericPayload = {
                name,
                value: event.key,
            };
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const onApply = useCallback(() => {
        dispatch(fetchManagementNotificationListAction());
    }, [dispatch]);

    const onReset = useCallback(() => {
        dispatch(resetFilter());
        dispatch(fetchManagementNotificationListAction());
    }, [dispatch]);

    const FiltersItems = () => {
        return (
            <div className={styles.filterContainer}>
                <div className={styles.filterItem}>
                    <CustomSelector
                        onChange={onSelectorChange}
                        selectedKey={filter.roleId}
                        options={mapRolesToDropDownOptions(roles ?? [])}
                        title={t("ROLE")}
                        name={compileNameOfProperty<IManagementNotificationFilter>("roleId")}
                    />
                </div>
                <div className={styles.filterItem2}>
                    <CustomSelector
                        onChange={onSelectorChange}
                        selectedKey={filter.notificationTypeId}
                        options={mapIDescriptionToDropDownOptions(data)}
                        title={t("NOTIFICATIONTYPE")}
                        name={compileNameOfProperty<IManagementNotificationFilter>("notificationTypeId")}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <FilterHeader
                onApply={onApply}
                content={FiltersItems()}
                onReset={onReset}
            />
        </>
    );
};
