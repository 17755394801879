import React,{ useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { CustomDetailsList } from "../../../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../../../common/skeleton/skeleton";
import { MasterDocumentTypesFormController } from "../controller/controller";
import { MasterDisplayDocksColumns } from "./column/column";
import { fetchMasterDocumentTypesList } from "../../../../../../redux/actions/resources/master/documentTypes/documentTypes";
interface IMasterDocumentTypesList {
 widthPopUp:any;
    heigthPopUp:any;
}

export const MasterDocumentTypesList:React.FC<IMasterDocumentTypesList> = ({heigthPopUp, widthPopUp}) => {
    const dispatch = useAppDispatch();
    const { list, loading } = useAppSelector((state) => state.masterDocumentTypesList);
    const renderColumns = () => {
        return MasterDisplayDocksColumns();
    };

    useEffect(() => {
        list === undefined && dispatch(fetchMasterDocumentTypesList());
    }, [list]);

    return loading ? (
        <Skeleton rows={5}></Skeleton>
    ) : (
        <>
            <CustomDetailsList
                data={list ?? []}
                renderColumns={renderColumns}
                height={"100%"}
            />
            <MasterDocumentTypesFormController widthPopUp={widthPopUp} heigthPopUp={heigthPopUp} />
        </>
    );
};
