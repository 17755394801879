import { ManagementTariffApi } from "../../../../api/management/tariff";

import { ITariffCondition } from "../../../../models/management/tariff/ITariffData";
import { throwErrorToastrAsync } from "../../../../utils/apiUtils";
import { setInvoiceConcepts, loadingInvoiceConcepts } from "../../../reducers/catalog/catalog";
import { setLoadingFormTariff, setTariffConditionItem } from "../../../reducers/management/tariff/form";
import { setLoadingTariffs, setTariffsManagment } from "../../../reducers/management/tariff/list";
import { AppThunk } from "../../../store";

const managementTariffApi = new ManagementTariffApi();

export const fetchManagmentTariffListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingTariffs(true));
        const response = await managementTariffApi.getTariffConcepts();
        dispatch(setTariffsManagment(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingTariffs(false));
    }
};

export const postAndPutManagmentTariffAction = (concept: ITariffCondition): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingFormTariff(true));
            if (concept.id) {
                await managementTariffApi.putInvoiceConcept(concept);
            } else {
                await managementTariffApi.postInvoiceConcept(concept);
            }
            dispatch(fetchManagmentTariffListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingFormTariff(false));
            return false;
        }
    };
};

export const deleteManagmentTariffAction = (tariff: number): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingFormTariff(true));
            await managementTariffApi.deleteTariffConcepts(tariff);
            dispatch(fetchManagmentTariffListAction());
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingFormTariff(false));
        }
    };
};

export const fetchInvoiceConceptsAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingInvoiceConcepts(true));
        const response = await managementTariffApi.getInvoiceConcepts();
        dispatch(setInvoiceConcepts(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(loadingInvoiceConcepts(false));
    }
};

export const getTariffItemDataForm =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingFormTariff(true));
            const response = await managementTariffApi.getTariffItem(id);

            dispatch(setTariffConditionItem(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingFormTariff(false));
        }
    };
