import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const ControlExpedientsFilterStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        gap: theme.gaps.filter.container,
        width: "100%",
        alignItems: "center",
    },
    filter: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: theme.gaps.filter.default,
    },

    row: {
        display: "flex",
        gap: theme.gaps.filter.default,
    },
    verticalWhiteSeparator: {
        height: "135%",
        borderLeft: theme.borders.default.whiteBorder,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    },
}));
