import { useMemo } from "react";

import { RoleType } from "../../common/enum";
import { UpdateCommandType } from "../../common/enum/ViewTypes";
import { generateEditRequestPropertySchema } from "../../components/request/form/create/validation/generateEditRequestPropertySchema";
import { ViewType } from "../../models";
import { IEditRequest } from "../../models/requests/IEditRequest";
import { IUserRoleView, IView } from "../../models/requests/IUserRoleView";
import { useAppSelector } from "../../redux/hooks";
import { findUpdateCommand } from "../../utils";

export const useEditRequestLogic = (viewRequestPermission: IUserRoleView | undefined, headerRequest: IEditRequest) => {
    const currentUserRole = useAppSelector((store) => store.role.roleData.id);

    const updateCommands = useMemo(() => {
        const { views = [] } = viewRequestPermission || {};
        const findView = (stateValue: string | null) =>
            views.find((view: any) => view.type === ViewType.UPDATE_COMMANDS && view.state === String(stateValue));
        return findView(String(headerRequest.stateSort)) || findView(null);
    }, [viewRequestPermission?.views, headerRequest.stateSort]);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find(
            (view: any) => view.type === ViewType.EDIT && (view.state === headerRequest.stateSort?.toString() || view.state === null),
        ) as IView;
    }, [headerRequest.stateSort, viewRequestPermission?.views]);

    const showEditRequestComands = Boolean(viewRequestPermission?.views.find((view: any) => view.type === ViewType.EDIT));

    const visiblePrintValues = useMemo(() => findUpdateCommand(UpdateCommandType.PRINT_VALUED_DELIVERY_NOTE, updateCommands), [updateCommands]);
    const visiblePrintCard = useMemo(() => findUpdateCommand(UpdateCommandType.PRINT_INSPECTION_CARD, updateCommands), [updateCommands]);
    const visibleRefresh = useMemo(() => findUpdateCommand(UpdateCommandType.REFRESH, updateCommands), [updateCommands]);
    const visibleDelete = useMemo(() => findUpdateCommand(UpdateCommandType.DELETE, updateCommands), [updateCommands]);
    const visibleSave = useMemo(() => findUpdateCommand(UpdateCommandType.SAVE, updateCommands), [updateCommands]);
    const visibleActa = useMemo(() => findUpdateCommand(UpdateCommandType.INSPECTION_CARD, updateCommands), [updateCommands]);

    const showPrintRequestInspectionCard = useMemo(() => {
        return visiblePrintCard ? headerRequest.allowPrintInspectionCard : false;
    }, [visiblePrintCard, headerRequest.allowPrintInspectionCard]);

    const showPrintRequestValuedDeliveryNote = useMemo(() => {
        if (visiblePrintValues) {
            if (currentUserRole === RoleType.CUSTOMER_ADMIN || currentUserRole === RoleType.CUSTOMER_USER) {
                return headerRequest.invoiced;
            }
            return true;
        }

        return false;
    }, [visiblePrintValues, currentUserRole, headerRequest.invoiced]);

    const schema = useMemo(() => {
        return generateEditRequestPropertySchema(viewConfig?.fields ? viewConfig.fields : []);
    }, [viewConfig]);

    return {
        showEditRequestComands,
        visiblePrintValues,
        visiblePrintCard,
        visibleRefresh,
        visibleDelete,
        visibleSave,
        visibleActa,
        showPrintRequestInspectionCard,
        showPrintRequestValuedDeliveryNote,
        schema,
    };
};

export const useEditRequestSelector = () => {
    const {
        catalog: { organisms, representativePresenceTypes },
        editRequest: { headerRequest, requestInspection, copyHeaderRequest, isLoadedRequest, canAssignAppointment, concurrency },
        role: {
            roleData: { id: currentUserRole },
        },
        organization: {
            organization: { id: currentOrganization },
        },
        userRoleViews: { viewPermission },
    } = useAppSelector((store) => store);

    return {
        data: organisms.data,
        headerRequest,
        requestInspection,
        copyHeaderRequest,
        isLoadedRequest,
        representativePresenceTypes: representativePresenceTypes.data,
        organims: organisms.data,
        currentOrganization,
        currentUserRole,
        viewPermission,
        canAssignAppointment,
        concurrency,
    };
};
