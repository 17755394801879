import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";
import { IConfigDockMerchandiseListItem } from "../../../../models/config/dockMerchandise";

interface IConfigDockMerchandiseState {
    list?: IConfigDockMerchandiseListItem[];
    sort: ISortState;
    loading: boolean;
}

const initialState: IConfigDockMerchandiseState = {
    sort: emptySortState(),
    loading: false,
    
};

export const configDockMerchandiseListSlice = createSlice({
    name: "configDockMerchandiseListSlice",
    initialState,
    reducers: {
        resetConfigDockMerchandiseList: () => initialState,
        setConfigDockMerchandiseList: (state, action: PayloadAction<IConfigDockMerchandiseListItem[]>) => {
            state.list = action.payload;
        },
        updateSortDockMerchandiseColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
        setConfigDockMerchandiseLoadingList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { resetConfigDockMerchandiseList, setConfigDockMerchandiseList, setConfigDockMerchandiseLoadingList, updateSortDockMerchandiseColumn } =
    configDockMerchandiseListSlice.actions;

export default configDockMerchandiseListSlice.reducer;
