import { ControlAPI } from "../../../../api/controll/expedients";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { IControlExpedientFilter } from "../../../../models/control/expedient/IExpedient";
import { throwErrorToastrAsync } from "../../../../utils";
import { setControlExpedientForm, setLoadingForm, setShowPopUp } from "../../../reducers/control/expedients/form";
import { setList, setLoading } from "../../../reducers/control/expedients/list";
import { AppThunk, store } from "../../../store";

const controlApi = new ControlAPI();

export const fetchControlExpedientsAction = (): AppThunk => async (dispatch) => {
    const filter = store.getState().controlExpedientsFilter.filter;
    let newFilter: IControlExpedientFilter = {
        ...filter,
        inspectorId: filter.inspectorId === DefaultValues.DEFAULT_ALL_SELECTOR ? undefined : filter.inspectorId,
        certificateNumber: filter.certificateNumber ? filter.certificateNumber : undefined,
        certificateTypeId: filter.certificateTypeId?.toString() === DefaultValues.DEFAULT_ALL_SELECTOR ? undefined : filter.certificateTypeId,
    };
    try {
        dispatch(setLoading(true));
        const response = await controlApi.getExpedientsList(newFilter);
        dispatch(setList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoading(false));
    }
};

export const fetchControlExpedientItemAction =
    (idExpedient: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingForm(true));
            const response = await controlApi.getExpedient(idExpedient);
            let newResponse = {
                ...response,
                certificateTypeId: Number(response.certificateTypeId),
            };

            dispatch(setControlExpedientForm(newResponse));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingForm(false));
        }
    };

export const updateControlExpedientItemAction = (): any => {
    return async (dispatch: any) => {
        const item = store.getState().controlExpedientsForm.form;

        try {
            dispatch(setLoadingForm(true));
            if (item.id) {
                await controlApi.putExpedient(item);
            } else {
                await controlApi.postExpedient(item);
            }
            dispatch(fetchControlExpedientsAction());

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingForm(false));
            return false;
        } 
    };
};

export const removeControlExpedientItemAction = (): any => {
    return async (dispatch: any) => {
        const item = store.getState().controlExpedientsForm.form;

        try {
            dispatch(setLoadingForm(true));
            if (item.id) {
                await controlApi.deleteExpedient(item.id);
            }
            dispatch(setShowPopUp(PopUpCodes.NONE));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            return false;
        } finally {
            dispatch(setLoadingForm(false));
            dispatch(fetchControlExpedientsAction());
        }
    };
};
