import { useCallback, useMemo } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { updatePaymentProfileProperty } from "../../../../../../../redux/reducers/user/profile/userProfile";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../../../utils";
import { Checkboxs } from "../../../../../../common/checkbox/checkbox";
import { FormField } from "../../../../../../common/fields/fields";
import { TitleH4, TitleH5 } from "../../../../../../common/titles/titles";
import { UserProfilePaymentInfoFormStyle } from "./infoStyle.jss";
import { UploadFileCustom } from "../../../../../../common/uploadFile/uploadFile";
import { useSelector } from "react-redux";
import { userProfileSelector } from "../../../../../../../redux/selectors/user/profile/userProfile";
import { ColorType } from "../../../../../../../common/enum/ColorType";
import { IPaymentData } from "../../../../../../../models";
import { FileSize } from "../../../../../../../common/enum/files/FileSize";
import { PaymentMethod } from "../../../../../../../common/enum/PaymentMethod";
import { ProfileDocumentTypes } from "../../../../../../../common/enum/ProfileDocumentTypes";

interface IUserProfilePaymentInfoFormProps {
    data: IPaymentData;
    register: UseFormRegister<IPaymentData>;
    errors: FieldErrors<IPaymentData>;
}

export const UserProfilePaymentInfoForm = (props: IUserProfilePaymentInfoFormProps) => {
    const style = UserProfilePaymentInfoFormStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updatePaymentProfileProperty(payload));
        },
        [dispatch],
    );

    const onCheckboxChange = useCallback(
        (paymentMethod) => (event: any) => {
            const payload = getGenericPayloadFromEvent(event);
            if (paymentMethod === PaymentMethod.BANK_DRAFT) {
                payload.value = PaymentMethod.BANK_DRAFT;
            } else if (paymentMethod === PaymentMethod.CASH) {
                payload.value = PaymentMethod.CASH;
            }
            dispatch(updatePaymentProfileProperty(payload));
        },
        [dispatch],
    );
    const userProfile = useSelector(userProfileSelector);
    const bankCertificateBlob = useMemo(() => {
        return userProfile.blobs.find((item) => item.type === ProfileDocumentTypes.BANK_CERTIFICATE);
    }, [userProfile]);
    const banckAuthorizationBlob = useMemo(() => {
        return userProfile.blobs.find((item) => item.type === ProfileDocumentTypes.BANK_AUTHORIZATION);
    }, [userProfile]);
    return (
        <>
            <div className={style.row}>
                <div className={style.title}>
                    <TitleH4
                        title={t("USER.TITLES.PAYMENT_DATA")}
                        color={ColorType.primary}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.columnBox}>
                    <Checkboxs
                        name={compileNameOfProperty<IPaymentData>("paymentMethod")}
                        label={t("USER.SIGNUP.BANK_DRAFT")}
                        isChecked={true}
                        handleChange={onCheckboxChange(PaymentMethod.BANK_DRAFT)}
                    />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.columnTitle}>
                    <FormField
                        type="text"
                        disabled={true}
                        label={t("USER.SIGNUP.BANK_ACCOUNT")}
                        value={props.data?.accountNumber}
                        error={props.errors.accountNumber}
                        {...props.register(compileNameOfProperty<IPaymentData>("accountNumber"), {
                            onChange: onInputChange,
                            value: props.data?.accountNumber,
                        })}
                    />
                </div>
                <div className={style.columnDocument}>
                    <TitleH5
                        title={t("USER.TITLES.BANK_CERTIFICATE")}
                        className={style.documentTitle}
                    ></TitleH5>
                    {bankCertificateBlob && (
                        <UploadFileCustom
                            name={""}
                            onChangeFile={() => {}}
                            title={""}
                            buttonStyle={ColorType.primary}
                            maxSize={FileSize._5MB}
                            hiddeRemoveDocument={true}
                            file={bankCertificateBlob}
                        />
                    )}
                </div>
            </div>
            <div className={style.row}>
                <div className={style.columnTitle}>
                    <FormField
                        type="text"
                        disabled={true}
                        label={t("USER.SIGNUP.BANK_ENTITY")}
                        value={props.data?.bankEntity}
                        error={props.errors.bankEntity}
                        {...props.register(compileNameOfProperty<IPaymentData>("bankEntity"), {
                            onChange: onInputChange,
                            value: props.data?.bankEntity,
                        })}
                    />
                </div>
                <div className={style.columnDocument}>
                    <TitleH5
                        title={t("USER.TITLES.BANK_AUTHORIZATION")}
                        className={style.documentTitle}
                    ></TitleH5>
                    {banckAuthorizationBlob && (
                        <UploadFileCustom
                            name={""}
                            onChangeFile={() => {}}
                            title={""}
                            buttonStyle={ColorType.primary}
                            maxSize={FileSize._5MB}
                            hiddeRemoveDocument={true}
                            file={banckAuthorizationBlob}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
