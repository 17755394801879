import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterBlock } from "../../../../../models/resources/master/IBlock";

export interface IMasterBlockFormState {
    form: IMasterBlock;
    loading: boolean;
}

const initialState: IMasterBlockFormState = {
    form: {
        active: true,
        code: "",
        description: "",
    },
    loading: false,
};

export const masterBlockeForm = createSlice({
    name: "masterBlockeForm",
    initialState,
    reducers: {
        resetMasterBlockForm: () => initialState,
        setLoadingMasterBlockForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterBlockForm: (state, action: PayloadAction<IMasterBlock>) => {
            state.form = action.payload;
        },
        updateMasterBlockProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetMasterBlockForm, setLoadingMasterBlockForm, setMasterBlockForm, updateMasterBlockProperty } = masterBlockeForm.actions;

export default masterBlockeForm.reducer;
