export interface IRole {
    id: string;
    name: string;
    operatorDataRequired?: boolean;
    hasEntityData?: boolean;
    hasRepresentativeData?: boolean;
    roleManagement?: IRoleManagement[];
}

export const emptyUserRoleData = (): IRole => {
    return {
        id: "",
        name: "",
        operatorDataRequired: false,
        hasEntityData: false,
        hasRepresentativeData: false,
        roleManagement: [],
    };
};

export interface IRoleManagement {
    id: string;
}
