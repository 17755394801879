import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { fetchDocumentTypesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterDocumentTypesForm } from "./form/form";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { postToMasterDocumentTypeForm } from "../../../../../../redux/actions/resources/master/documentTypes/documentTypes";
import { resetMasterDocumentTypesForm } from "../../../../../../redux/reducers/resources/master/documentTypes/form";
import { clearMasterPopUp } from "../../../../../../redux/reducers/resources/master/master";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterDocumentTypesFormController {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterDocumentTypesFormController: React.FC<IMasterDocumentTypesFormController> = ({ heigthPopUp, widthPopUp }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { form, loading } = useAppSelector((state) => state.masterDocumentTypesForm);
    const { data } = useAppSelector((state) => state.catalog.documentTypeRemaining);

    const onDimissed = () => {
        dispatch(resetMasterDocumentTypesForm());
    };
    const onCancelPopUp = () => {
        dispatch(clearMasterPopUp());
    };
    const onSavePopUp = () => {
        dispatch(postToMasterDocumentTypeForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };
    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE") + " " + t("BUTTON.DOCUMENT_TYPE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE") + " " + t("BUTTON.DOCUMENT_TYPE");
        }

        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form, showPopUp]);

    const showNotData = useMemo(() => {
        return !data.length && showPopUp === PopUpCodes.CREATE ? true : false;
    }, [data, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchDocumentTypesRemaining());
    }, [showPopUp]);
    return (
        <PopUpForm
            content={
                <MasterDocumentTypesForm
                    showNotData={showNotData}
                    selectorData={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
            onCancel={onCancelPopUp}
            onSave={onSavePopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            onlyHidden={showNotData}
            leftButtonContent={<RequiredFieldsMessage />}
        ></PopUpForm>
    );
};
