import { UserManager } from "oidc-client";
import { ComponentType, useEffect } from "react";
import { makeAuthenticator } from "react-oidc";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { routeUrls } from "../../common/constants";
import { IUserModuleData } from "../../models/user/IUserModuleData";
import { getUserModules } from "../../redux/selectors/user/module/module";
import { ErrorListener } from "../../services/errorListener";

interface IComponentWithAuthProps {
    userManager: UserManager;
    componentAuth: ComponentType;
}

export const ComponentWithAuth = (props: IComponentWithAuthProps & any) => {
    const location = useLocation();

    const userModules: IUserModuleData[] = useSelector(getUserModules);

    const navigate = useNavigate();

    useEffect(() => {
        if (userModules && userModules.length > 0) {
            const hasRequestFeature = userModules.some(module => 
                module.features.some(feature => feature.baseUri === routeUrls.REQUEST)
            );
            if (!hasRequestFeature && location.pathname === routeUrls.REQUEST) {
                navigate(userModules[0].features[0].baseUri);
            }
        }
    }, [userModules, navigate, location.pathname]);

    const AuthComponent = makeAuthenticator({
        userManager: props.userManager,
        signinArgs: {
            state: {
                navigateUri: location.pathname,
            },
            extraQueryParams: {
                branding: process.env.REACT_APP_BRANDING,
            },
        },
    })(props.componentAuth);

    return (
        <>
            <AuthComponent {...props} />
            <ErrorListener />
        </>
    );
};
