import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterOrganismColumnsStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterMerchandiseType } from "../../../../../../../models/resources/master/IMerchandiseTypes";
import { fetchMasterMerchandiseTypes } from "../../../../../../../redux/actions/resources/master/merchandiseTypes/merchandiseTypes";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";

export const MasterOrganismColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterOrganismColumnsStyle({ theme });
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IMasterMerchandiseType>("code"),
            minWidth: 150,
            maxWidth: 250,
            name: t("MASTERS.CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseType>("code")}
                    title={t("MASTERS.CODE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseType) => <span>{item.code ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterMerchandiseType>("description"),
            minWidth: 250,
            maxWidth: 300,
            name: t("MASTERS.DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseType>("description")}
                    title={t("MASTERS.DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseType) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterMerchandiseType>("humanConsumption"),
            minWidth: 100,
            maxWidth: 110,
            name: t("MASTERS.HUMAN_CONSUMPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseType>("humanConsumption")}
                    title={t("MASTERS.HUMAN_CONSUMPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseType) => (
                <span>
                    {" "}
                    <BooleanValue value={item.humanConsumption}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterMerchandiseType>("merchandiseOriginDescription"),
            minWidth: 100,
            maxWidth: 200,
            name: t("MASTERS.MERCHANDISE_ORIGIN"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseType>("merchandiseOriginDescription")}
                    title={t("MASTERS.MERCHANDISE_ORIGIN")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseType) => <span>{item.merchandiseOriginDescription ?? t("COMMON.ALL")}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterMerchandiseType>("parkingAuthorization"),
            minWidth: 120,
            maxWidth: 150,
            name: t("MASTERS.PARKINGAUTHORIZATION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseType>("parkingAuthorization")}
                    title={t("MASTERS.PARKINGAUTHORIZATION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseType) => (
                <span>
                    {" "}
                    <BooleanValue value={item.parkingAuthorization}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterMerchandiseType>("active"),
            minWidth: 50,
            maxWidth: 80,
            name: t("MASTERS.ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterMerchandiseType>("active")}
                    title={t("MASTERS.ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterMerchandiseType) => (
                <span>
                    <BooleanValue value={item.active}></BooleanValue>
                </span>
            ),
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 150,
            name: "",

            onRender: (item: IMasterMerchandiseType) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterMerchandiseTypes(item.id ?? 0));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
