import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISortState } from "../../../../models/common/ISortState";
import { ITariffCondition, resetInitialStateManagementTariffList } from "../../../../models/management/tariff/ITariffData";

interface ManagementTariffList {
    listTariffCondition: ITariffCondition[];
    loading: boolean;
    sort: ISortState;
}

const initialState: ManagementTariffList = resetInitialStateManagementTariffList();

export const managementTariffList = createSlice({
    name: "summaryConceptList",
    initialState,
    reducers: {
        resetManagementTariff: () => initialState,
        setTariffsManagment: (state, action: PayloadAction<ITariffCondition[]>) => {
            state.listTariffCondition = action.payload;
        },
        setLoadingTariffs: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
    },
});

export const { resetManagementTariff, setTariffsManagment, setLoadingTariffs, updateSortColumn } = managementTariffList.actions;

export default managementTariffList.reducer;

