import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { Skeleton } from "../../../../../../common/skeleton/skeleton";
import { ManagementMessageDayTypeFormStyle } from "./messageDayTypeStyle.jss";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { fetchAppointmentDayPermissionTypes } from "../../../../../../../redux/actions/catalog/catalog";

export const ManagementMessageDayTypeForm = () => {
    const dispatch = useAppDispatch();
    const { data, loading } = useAppSelector((store) => store.catalog.appointmentDayPermissionTypes);
    const theme = useTheme();
    const style = ManagementMessageDayTypeFormStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.PERMISSIONS.FORM" });

    const loadingComponent = loading ? (
        <Skeleton
            rows={1}
            size={"99%"}
        />
    ) : (
        <></>
    );

    return data.length ? (
        <div className={style.container}>
            <div className={style.header}>
                <CustomIcons
                    className={style.icon}
                    iconName={NameIcons.INFOICON}
                    disabledSelect={true}
                    position={"start"}
                    margin={"0px"}
                    size={20}
                />
                <div className={style.header}>
                    <b>{t("INFORMATION")}</b>
                </div>
            </div>
            <div className={style.data}>
                {data.map((item) => {
                    return (
                        <ManagementMessageDayTypeFormItem
                            key={item.id}
                            description={item.info}
                            title={item.description ?? DefaultValues.EMPTY_LIST}
                        />
                    );
                })}
            </div>
        </div>
    ) : (
        loadingComponent
    );
};

interface IManagementMessageDayTypeFormItemProps {
    title: string;
    description: string;
}

export const ManagementMessageDayTypeFormItem: React.FC<IManagementMessageDayTypeFormItemProps> = ({ description, title }) => {
    const theme = useTheme();
    const style = ManagementMessageDayTypeFormStyle({ theme });

    return (
        <>
            <div className={style.title}>
                <b>{title}</b>
            </div>
            <div
                className={style.description}
                dangerouslySetInnerHTML={{ __html: description }}
            ></div>
        </>
    );
};
