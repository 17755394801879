import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterAppointmentType } from "../../../../models/masterAppointment/IAppointmentType";

export interface IMasterAppointmentTypeListState {
    list?: IMasterAppointmentType[];
    loading: boolean;
}

const initialState: IMasterAppointmentTypeListState = {
    loading: false,
};

export const masterAppointmentTypeList = createSlice({
    name: "masterAppointmentTypeList",
    initialState,
    reducers: {
        resetMasterAppointmentTypeList: () => initialState,
        setLoadingMasterAppointmentTypeList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentTypeList: (state, action: PayloadAction<IMasterAppointmentType[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterAppointmentTypeList, setLoadingMasterAppointmentTypeList, setMasterAppointmentTypeList } = masterAppointmentTypeList.actions;

export default masterAppointmentTypeList.reducer;
