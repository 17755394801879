import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const EditRequestTabDocumentsContainerStyle = createUseStyles((theme: any) => ({
    container: {
        padding: theme.paddings.default,
        paddingLeft: 0,
        display: "flex",
        gap: theme.gaps.sizes.xxl,
        flexDirection: "column",
        overflowY: "scroll",
        height: theme.heights.requestEditContent,
        [mediaQueries.TABLET]: {
            height: theme.heights.requestEditMobileContent,
        },
    },
}));
