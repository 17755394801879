import { useEffect } from "react";
import { Control, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { GridClass } from "../../../../../../common/enum/GridClass";
import { useRoleType } from "../../../../../../hooks/role/useRoleType";
import { IAddInspectionData } from "../../../../../../redux/reducers/request/list/form/addInspection";
import { getUserData } from "../../../../../../redux/selectors/userData/userData";
import { getOrganimsRoles } from "../../../../../../services/user/roleService";
import { compileNameOfProperty } from "../../../../../../utils";
import { Checkboxs } from "../../../../../common/checkbox/checkbox";
import { FormField } from "../../../../../common/fields/fields";
import { OrganismSelector } from "../../../../common/selectors";
import { ActuationTypesSelector } from "../../../../common/selectors/actuationTypes/actuationTypesSelector";
import { requestMaxLength } from "../../../../form/create/validation/requestConfig";
import { RequestListAddActuationsStyles } from "../requestListAddActuationsStyles.jss";

interface IAddActuations {
    onSelectorChange: (option: any, name: string) => any;
    onInputChange: (event: any) => any;
    onCheckBoxChange: (event: any) => any;
    control: Control<IAddInspectionData>;
    register: UseFormRegister<IAddInspectionData>;
    form: IAddInspectionData;
}

export const AddActuationForm = ({ onSelectorChange, onInputChange, onCheckBoxChange, control, register, form }: IAddActuations) => {
    const { t } = useTranslation("common", { keyPrefix: "LIST.ACTUATION.MULTIPLE" });
    const theme = useTheme();
    const styles = RequestListAddActuationsStyles({ theme });
    const roleUserType = useRoleType(getOrganimsRoles());
    const { organismId } = useSelector(getUserData);

    useEffect(() => {
        if (roleUserType) {
            onSelectorChange({ key: organismId }, compileNameOfProperty<IAddInspectionData>("organismId"));
        }
    }, [roleUserType, organismId]);

    return (
        <div
            className={`ms-Grid ${styles.container}`}
            dir="ltr"
        >
            <div className={GridClass.ROW_GRID}>
                <div className={GridClass.FULL_CELL}>
                    <ActuationTypesSelector
                        control={control}
                        onChange={onSelectorChange}
                        selectedKey={form.typeId}
                        propertyName={compileNameOfProperty<IAddInspectionData>("typeId")}
                        title={t("ACTUATION")}
                        isRequired={true}
                    />
                </div>
            </div>
            <div className={GridClass.ROW_GRID}>
                <div className={GridClass.FULL_CELL}>
                    <FormField
                        type="text"
                        value={form.instructions}
                        label={t("INSTRUCTIONS")}
                        maxLength={requestMaxLength.DESCRIPTION}
                        {...register(compileNameOfProperty<IAddInspectionData>("instructions"), {
                            onChange: onInputChange,
                            value: form.instructions,
                            required: false,
                        })}
                    />
                </div>
            </div>
            <div className={GridClass.ROW_GRID}>
                <div className={GridClass.FULL_CELL}>
                    <OrganismSelector
                        control={control}
                        onChange={onSelectorChange}
                        propertyName={compileNameOfProperty<IAddInspectionData>("organismId")}
                        selectedKey={roleUserType ? organismId : form.organismId}
                        title={t("ORGANIMS")}
                        isRequired={true}
                        disabled={roleUserType}
                    />
                </div>
            </div>
            <div className={GridClass.ROW_GRID}>
                <div className={GridClass.FULL_CELL}>
                    <Checkboxs
                        isChecked={form.onlyInspectionsWithoutActuation}
                        handleChange={onCheckBoxChange}
                        name={compileNameOfProperty<IAddInspectionData>("onlyInspectionsWithoutActuation")}
                        label={t("ONLY_INSPECTION")}
                    />
                </div>
            </div>
        </div>
    );
};
