import { useTheme } from "react-jss";
import { TitleH5 } from "../titles/titles";
import { FormSectionStyle } from "./formSection.jss";
import { ColorType } from "../../../common/enum/ColorType";

interface IProps {
    children: React.ReactNode;
    title: string;
    firstSection?: boolean;
    lastSection?: boolean;
}
export const FormSection: React.FC<IProps> = ({ children, title, firstSection, lastSection }) => {
    const theme = useTheme();

    const styles = FormSectionStyle({ theme, firstSection, lastSection });

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <TitleH5
                    title={title}
                    color={ColorType.primary}
                    bold={true}
                />
            </div>
            {children}
        </div>
    );
};
