import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../utils";
import { FilterHeader } from "../../../common/filterHeader/filterHeader";
import { ConfigOrganismCertificateTypeFilterStyle } from "./filterStyle.jss";
import { useTheme } from "react-jss";
import {
    resetOrganismCertificateTypeFilter,
    updateOrganismCertificateTypeFilterFilterProperty,
} from "../../../../redux/reducers/resources/organismCertificatedType/filter";
import { IConfigOrganismCertificateTypeFilter } from "../../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { CustomCertificateSelector } from "../../../request/common/selectors/certificate/customCertificateSelector";
import { fetchOrganismCertificateTypeList } from "../../../../redux/actions/resources/organismCertificateType/organismCertificateType";
import { CustomOrganismSelector } from "../../../request/common/selectors/organism/customOrganismSelector";
import { DefaultValues } from "../../../../common/enum/DefaultValues";

export const ConfigOrganismCertificateTypeFilter = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "CONFIG.ORGANISMCERTIFICATETYPE" });
    const { filter } = useAppSelector((state) => state.configOrganismCertificateTypeFilter);
    const theme = useTheme();
    const style = ConfigOrganismCertificateTypeFilterStyle({ theme });

    const updateProperty = (name: string, value: any) => {
        dispatch(updateOrganismCertificateTypeFilterFilterProperty({ name, value }));
    };
    const onSelectorChange = (name: string, option: any) => {
        updateProperty(name, option.key === DefaultValues.DEFAULT_ALL_SELECTOR ? undefined : option.key);
    };

    const contetFilter = (
        <div className={style.filter}>
            <div className={style.item}>
                <CustomOrganismSelector
                    onChange={onSelectorChange}
                    selectedKey={filter.organismId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigOrganismCertificateTypeFilter>("organismId")}
                    title={t("ORGANISM")}
                />
            </div>
            <div className={style.item}>
                <CustomCertificateSelector
                    title={t("CERTIFICATE_TYPE")}
                    onChange={onSelectorChange}
                    selectedKey={filter.certificateTypeId ?? DefaultValues.DEFAULT_ALL_SELECTOR}
                    name={compileNameOfProperty<IConfigOrganismCertificateTypeFilter>("certificateTypeId")}
                />
            </div>
        </div>
    );
    const onApplyFilter = () => {
        dispatch(fetchOrganismCertificateTypeList());
    };
    const onResetFilter = () => {
        dispatch(resetOrganismCertificateTypeFilter());
        onApplyFilter();
    };

    return (
        <>
            <FilterHeader
                content={contetFilter}
                onApply={onApplyFilter}
                onReset={onResetFilter}
            />
        </>
    );
};
