export interface IPaginationState {
    pageSize: number,
    currentPage: number;
    totalPages: number;
    totalItems: number;
    firstItemNumber: number;
    lastItemNumber: number;
}

export const emptyPaginationState = (pageSize: number): IPaginationState => {
    return {
        pageSize: pageSize,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        firstItemNumber: 0,
        lastItemNumber: 0
    };
};