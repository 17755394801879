import { createUseStyles } from "react-jss";

export const MultipleSelectorFilterStyle = createUseStyles((theme: any) => ({
    container: {
        position: "relative",
        width: "100%",
        marginTop: 5,
        fontFamily: theme.fonts.families.site,
    },

    callout: {
        marginTop: theme.paddings.combo.wrapperoffset,
        "& .ms-Checkbox": {
            color: theme.colors.form.input.filled,
        },
        "& .is-checked": {
            "& .ms-Checkbox-checkbox": {
                backgroundColor: theme.colors.palette.purple,
            },
        },
    },

    root: {
        color: theme.colors.form.input.filled,
        fontSize: theme.fonts.sizes.base,
        backgroundColor: theme.colors.palette.white,
        borderRadius: theme.borders.default.radius,
        fontWeight: "normal",
        fontFamily: "unset",
        "& .ms-Dropdown-optionText": {
            fontFamily: theme.fonts.families.site,
        },

        "& .is-disabled ": {
            opacity: "0.5",
        },

        "& > div ": {
            background: "transparent",
            border: "none",
            "& > button::after": {
                heigth: 20,
                width: "32px !!important",
                background: "transparent !!important",
            },
            "& .ms-Button": {
                background: "transparent",
            },
        },

        "& .ms-ComboBox::after": {
            borderColor: "transparent",
        },
    },

    label: {
        color: theme.colors.palette.grey,
        fontSize: theme.fonts.sizes.xxs,
        position: "absolute",
        pointerEvents: "none",
        top: -12,
        transform: "translateY(0px)",
        transition: "all 0.2s ease-in-out",
        left: "0px",
        fontWeight: "bold",
        paddingLeft: 5,
        fontFamily: theme.fonts.families.site,
        zIndex: 90,
    },
    optionsContainer: {
        marginTop: theme.paddings.combo.wrapperoffset,
        "& .ms-Checkbox": {
            background: "transparent",
        },
        "& .is-checked": {
            background: "rgb(237, 235, 233)",

            "& .ms-Checkbox-checkbox": {
                backgroundColor: theme.colors.palette.purple,
            },
        },
        "& .is-disabled": {
            "& .ms-Checkbox-checkbox": {
                display: "none !important",
            },
        },
    },

    inputText: {
        color: theme.colors.form.input.filled,
        paddingTop: 3,
        background: "transparent",
    },
}));
