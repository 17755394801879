import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import IUserSignupType from '../../../../models/user/IUserSignupType';

export const userSignupTypesAdapter = createEntityAdapter<IUserSignupType>({
  selectId: (userType) => userType.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const userSignupTypeSlice = createSlice({
  name: 'userSignupTypes',
  initialState: userSignupTypesAdapter.getInitialState({
    isLoading: false,
    hasErrors: false,
    errorMessage: '',
    selected: '',
  }),
  reducers: {
    userSignupTypesReceived: (state, action) => {
      userSignupTypesAdapter.setAll(state, action.payload);
      state.isLoading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isLoading = false;
      state.hasErrors = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { userSignupTypesReceived, setLoading, setError } = userSignupTypeSlice.actions;

export default userSignupTypeSlice.reducer;
