import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../common/constants";

export const UpcomingAppointmentsFilterStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        gap: theme.gaps.filter.container,
    },
    column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap:15
    },
    row: {
        display: "flex",
        flexDirection: "row",
        gap: theme.gaps.filter.default,
        flex: "1 1 auto",
        alignItems: "center",
        "& .description": {
            width: "100%",
        },
        "& .origin": {
            width: "48%",
        },
    },
    item: {
        width: 210,
    },
    longItem: {
        width: 210,
    },
    verticalWhiteSeparator: (props:any)=>({
        height: !props.dobleRow? "175%": "133%",
        marginTop: -15,
        borderLeft: theme.borders.default.whiteBorder,
        [mediaQueries.TABLET]: {
            borderLeft: "none",
        },
    }),
}));
