import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../common/constants";

export const RequestStepperStyled = createUseStyles((theme: any) => ({
    container: {
        flexFlow: "row",
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        height: "100%",
        [mediaQueries.MOBILE]: {
            flexFlow: "column",
            alignItems: "center",
        },
    },

    leftColumnContainer: {
        borderRight: `1px solid ${theme.colors.titles.primary.color}`,
        padding: theme.paddings.default,
        flex: 20,
        minWidth: 250,
        [mediaQueries.MOBILE]: {
            minWidth: 300,
            flex: 0,
            rowGap: 10,
            borderRight: "none",
        },
    },
    rightColumnContainer: {
        flex: 80,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    leftColumnContent: {
        flexFlow: "column",
        display: "flex",
        padding: theme.paddings.default,
        rowGap: theme.gaps.sizes.base,
    },
    rightColumnContent: {
        flexFlow: "column",
        display: "flex",
        flex: 80,
        height: "100%",
        overflowY: "auto",
        [mediaQueries.MOBILE]: {
            minWidth: 300,
           
        },
    },
    leftColumntitle: { paddingTop: 40 },
    leftColumnButtons: {
        rowGap: theme.gaps.sizes.base,
        display: "flex",
        flexFlow: "column",
    },
    exitButton: {
        padding: 0,
        marginTop: -40,
        width: 40,
        height: 40,
        marginLeft: "auto",
        borderBottomLeftRadius: 4,
        backgroundColor: theme.colors.buttons.primary.background,
        color: theme.colors.buttons.primary.color,

        "& :hover": {
            backgroundColor: theme.colors.buttons.primary.color,
            color: theme.colors.buttons.primary.background,
            "& i": {
                color: theme.colors.buttons.primary.background,
            },
        },
        [mediaQueries.MOBILE]: {
            borderTopRightRadius: 16,
        },
    },
    buttonContainer: {
        textAlign: "end",
        marginTop: 10,
        [mediaQueries.MOBILE]: {
            position: "fixed",
            top: 24,
            right: 16,
        },
    },
    closeIcon: {
        color: theme.colors.buttons.primary.color,
        fontWeight: "600",
        fontSize: 12,
    },
    clickable: {
        cursor: "pointer",
    },
}));
