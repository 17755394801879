import { useEffect } from "react";
import { fetchStatiticsReaderRecordRatioAction } from "../../../../redux/actions/statistics/statistics";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { heights } from "../../../../styles/romeu/heights";
import { CustomDetailsList } from "../../../common/customDetailsList/customDetailsList";
import { Skeleton } from "../../../common/skeleton/skeleton";
import { StatisticsReaderRecordRatioListColumn } from "./column/column";
import { SkeletonSizes } from "../../../../common/constants";

export const StatisticsReaderRecordRatioList = () => {
    const dispatch = useAppDispatch();
    const { readerRecordsRatio, loading } = useAppSelector((state) => state.statiticsReaderRecordRatioList.data);

    useEffect(() => {
        readerRecordsRatio===undefined && dispatch(fetchStatiticsReaderRecordRatioAction());
    }, [dispatch, readerRecordsRatio]);

    const onRenderColumns = () => {
        return StatisticsReaderRecordRatioListColumn();
    };

    return !loading ? (
        <CustomDetailsList
            data={readerRecordsRatio ?? []}
            renderColumns={onRenderColumns}
            height={heights.statistics.incidence}
        />
    ) : (
                        <Skeleton rows={SkeletonSizes.DEFAULT} />

    );
};
