import { useMemo } from "react";
import { useTheme } from "react-jss";
import { IEmailItem } from "../../../../../../../../models";
import { UserProfileEntityUsersSendSignupEmailsItem } from "./item/item";
import { UserProfileEntityUsersSendSignupEmailsListStyle } from "./listStyle.jss";

interface IProps {
    emails: IEmailItem[];
}
export const UserProfileEntityUsersSendSignupEmailsList: React.FC<IProps> = ({ emails }) => {
    const theme = useTheme();
    const style = UserProfileEntityUsersSendSignupEmailsListStyle({ theme });

    const maxIndex = useMemo(() => {
        return emails.length - 1;
    }, [emails]);

    return (
        <>
            <div className={style.emailContainer}>
                {emails.map((email, index) => (
                    <UserProfileEntityUsersSendSignupEmailsItem
                        email={email}
                        key={`${UserProfileEntityUsersSendSignupEmailsList.name}-${index}`}
                        isLastIndex={index === maxIndex}
                        isUniqueIndex={emails.length === 1}
                        index={index}
                    />
                ))}
            </div>
        </>
    );
};
