import { useTheme } from "react-jss";
import { ImageSliderContainerStyle } from "./containerStyle.jss";
import { ImageSlider } from "../imageSlider";
import { ButtonPrimary } from "../../buttons/buttons";
import { useTranslation } from "react-i18next";
interface IContainerImageSliderProps {
    images?: string[];
    imageIndex: number;
    setShowImageSlider: (value: any) => void;
    showImageSlider:boolean;
}
export const ContainerImageSlider: React.FC<IContainerImageSliderProps> = ({ images, imageIndex, setShowImageSlider, showImageSlider }) => {
    const theme = useTheme();
    const style = ImageSliderContainerStyle({ theme });

    const { t } = useTranslation("common");

    return showImageSlider ?(
        <div className={style.container}>
            <ImageSlider
                images={images}
                imageIndex={imageIndex}
                setShowImageSlider={setShowImageSlider}
            />
            <span className={style.close}>
                <ButtonPrimary
                    handleClick={() => {
                        setShowImageSlider(false);
                    }}
                    title={t("COMMON.CLOSE")}
                ></ButtonPrimary>
            </span>
        </div>
    ) : <></>;
};
