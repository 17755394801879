import { UserManager } from "oidc-client";
import { Callback } from "react-oidc";
import { useNavigate } from "react-router";

interface ICallbackProps {
    userManager: UserManager;
}

export const CallbackAuth = (props: ICallbackProps) => {
    const navigate = useNavigate();

    return (
        <>
            <Callback
                onSuccess={(user) => {
                    navigate(user.state.navigateUri, { replace: true });
                }}
                onError={(error) => {
                    console.error("Error during signin callback", error);
                }}
                userManager={props.userManager}
            />
        </>
    );
};
