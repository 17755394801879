import { createUseStyles } from "react-jss";

export const RequestButtonsStyle = createUseStyles((theme: any) => ({
    requestButtonsContainer: {
        display: "flex",
        justifyContent: "space-between",

        backgroundColor: theme.colors.site.background,
        padding: 15,
    },

    disclaimer: {
        display: "flex",
        columnGap: 4,
        color: theme.colors.form.input.default,
        fontSize: theme.fonts.sizes.sm,
        "& span": {
            color: theme.colors.titles.danger.color,
            fontWeight: 600,
        },
    },
    buttons: {
        display: "flex",
        columnGap: 16,
        "& button": {
            minWidth: 110,
        },
    },
}));
