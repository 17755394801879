import { useTranslation } from "react-i18next";

import { useTheme } from "react-jss";
import { VlcportHistoricFilterStyle } from "./filterStyle.jss";
import { resetRequestIntegrationFilter, updateRequestIntegrationFilterProperty } from "../../../redux/reducers/request/requestIntegrations/filter";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CustomDatePicker } from "../../common/customDatePicker/customDatePicker";
import { DaySelector } from "../../../models/requests/IDaySelector";
import { TextInput } from "../../common/inputs/textInput";
import { compileNameOfProperty } from "../../../utils";
import { IRequestIntegrations } from "../../../models";
import { BooleaMultiSelector } from "../../common/selectors";
import { FilterHeader } from "../../common/filterHeader/filterHeader";
import { fetchRequestIntegrationRequest } from "../../../redux/actions/request/integrationsRequest/integrations";



export const RequestsIntegrationFilter = () => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.requestIntegrationFilter);
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.HISTORIC" });
    const theme = useTheme();
    const style = VlcportHistoricFilterStyle({ theme });

    const onApply = () => {
        dispatch(fetchRequestIntegrationRequest());       
    };

    const onReset = () => {
        dispatch(resetRequestIntegrationFilter());
        onApply();
    };

    const updateHeaderProperty = (name: string, value: any) => {
        dispatch(updateRequestIntegrationFilterProperty({ name, value }));
    };
    const onInputChange = (event: any, name: string) => {
        updateHeaderProperty(name, event.target.value);
    };
    const onBooleanSelectorChange = (name: string, nullablebooleanValue: any) => {
        updateHeaderProperty(name, nullablebooleanValue);
    };
    const content = (
        <div className={style.container}>
            <CustomDatePicker
                startDate={filter.startDate}
                endDate={filter.endDate}
                maxDaySelected={DaySelector.MAX_YEAR_SELECTED}
                minDaySelected={DaySelector.MIN_DAYS_SELECTED}
                setFilterProperty={updateHeaderProperty}
                showLeftArrowController={false}
            />
            <div className={style.verticalWhiteSeparator}></div>

            <div className={style.filter}>
                <div className={style.row}>
                    <div className={style.item}>
                    <TextInput
                            value={filter.integrationReference}
                            propertyName={compileNameOfProperty<IRequestIntegrations>("integrationReference")}
                            label={t("REFERENCE")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={style.item}>
                    <TextInput
                            value={filter.transportUnitNumber}
                            propertyName={compileNameOfProperty<IRequestIntegrations>("transportUnitNumber")}
                            label={t("UTI_TRANSPORT_NUMBER")}
                            onChange={onInputChange}
                            onEnter={onApply}
                        />
                    </div>
                    <div className={style.item}>
                    <BooleaMultiSelector
                            selectedKey={filter.withErrors}
                            propertyName={compileNameOfProperty<IRequestIntegrations>("withErrors")}
                            title={t("WITH_ERROR")}
                            onChange={onBooleanSelectorChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <FilterHeader
            buttonColumn={true}
            content={content}
            onApply={onApply}
            onReset={onReset}
            height={120}
        />
    );
};
