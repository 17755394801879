export { ReactComponent as FilterIcon } from "./svg/icono-filtros.svg";
export { ReactComponent as CalendarIcon } from "./svg/icono-calendario-big.svg";
export { ReactComponent as ChevronDownIcon } from "./svg/icono-flecha-down-violeta.svg";
export { ReactComponent as ExportIcon } from "./svg/icono-exportar-datos.svg";
export { ReactComponent as EditIcon } from "./svg/icono-editar.svg";
export { ReactComponent as DetailIcon } from "./svg/icono-detalle.svg";
export { ReactComponent as EyeIcon } from "./svg/icono-ojo.svg";
export { ReactComponent as FilterColumnIcon } from "./svg/icono-columna-filtrada.svg";
export { ReactComponent as FilterColumnUpIcon } from "./svg/icono-columna-filtro-up.svg";
export { ReactComponent as FilterColumnDownIcon } from "./svg/icono-columna-filtro-down.svg";
export { ReactComponent as DropDownUpIcon } from "./svg/icono-desplegable-up.svg";
export { ReactComponent as DropDownIcon } from "./svg/icono-desplegable-down.svg";
export { ReactComponent as PortIcon } from "./svg/icono-puerto.svg";
export { ReactComponent as HistoryIcon } from "./svg/icono-historial.svg";
export { ReactComponent as PrintIcon } from "./svg/icono-imprimir.svg";
export { ReactComponent as RemoveIcon } from "./svg/icono-eliminar-rojo.svg";
export { ReactComponent as ReloadIcon } from "./svg/icono-refrescar.svg";
export { ReactComponent as AddIconRed } from "./svg/icono-agregar-rojo.svg";
export { ReactComponent as AddIconBlue } from "./svg/icono-agregar-azul.svg";
export { ReactComponent as AddIcon } from "./svg/icono-agregar-rojo.svg";
export { ReactComponent as FlagIcon } from "./svg/icono-bandera-azul.svg";
export { ReactComponent as LightFlagIcon } from "./svg/icono-bandera-verde.svg";
export { ReactComponent as DarkFlagIcon } from "./svg/icono-bandera-azul-oscuro.svg";
export { ReactComponent as DownloadIcon } from "./svg/icono-descargar.svg";
export { ReactComponent as ClockIcon } from "./svg/icono-reloj.svg";
export { ReactComponent as InfoIcon } from "./svg/icono-importante.svg";
export { ReactComponent as FlagRight } from "./svg/icono-flecha-right-menu-lateral.svg";
export { ReactComponent as AddActuationMultiple } from "./svg/add_multiple_actuation.svg";
export { ReactComponent as FileDownload } from "./svg/file-download.svg";

export enum NameIcons {
    FILTER = "FilterIcon",
    ARROW = "FilterColumnIcon",
    UP = "FilterColumnUpIcon",
    DOWN = "FilterColumnDownIcon",
    REMOVE = "RemoveIcon",
    RELOAD = "ReloadIcon",
    EXPORTDATA = "ExportIcon",
    INFOICON = "InfoIcon",
    EDIT = "EditIcon",
    PRINT = "PrintIcon",
    CLOCK = "ClockIcon",
    LIGHTFLAG = "LightFlagIcon",
    DARCKFLAG = "DarkFlagIcon",
    ADD = "AddIcon",
    ADDBLUE = "AddIconBlue",
    ADDRED = "AddIconRed",
    DOWNLOAD = "DownloadIcon",
    FLAGRIGHT = "FlagRight",
    EYEICON = "EyeIcon",
    CHEVRONDOWNICON = "ChevronDownIcon",
    CALENDAR = "CalendarIcon",
    PORTICON = "PortIcon",
    CHEVRONUP = "ChevronUpSmall",
    CHEVRONDOWN = "ChevronDownSmall",
    ADDACTUATIONMULTIPLE = "AddActuationMultiple",
    FILEDOWNLOAD = "FileDownload",
}
