import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../assets/icons";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IManagemenTActiveSummaryItem } from "../../../../../models/management/summary/ISummary";
import { useAppDispatch } from "../../../../../redux/hooks";
import { setShowManagementSummaryPopUp, updateManagementSummaryConditionForm } from "../../../../../redux/reducers/management/summary/form";
import { compileNameOfProperty } from "../../../../../utils";
import { convertToLocal } from "../../../../../utils/dateUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { ManagementSummaryListColumnStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { IConfigManagementSummary } from "../../generateSchema/generateSchema";
import { SummarySchema } from "../generateSchema/generateSchema";

export const ManagementSummaryListColumn = (schema: IConfigManagementSummary): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.SUMMARY" });
    const theme = useTheme();
    const styles = ManagementSummaryListColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IManagemenTActiveSummaryItem>("summaryNumber"),
            minWidth: 100,
            maxWidth: 240,
            name: t("SUMMARY_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagemenTActiveSummaryItem>("summaryNumber")}
                    title={t("SUMMARY_NUMBER")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagemenTActiveSummaryItem) => <span>{item.summaryNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagemenTActiveSummaryItem>("createdOn"),
            minWidth: 150,
            maxWidth: 200,
            name: t("DATE_OVERTURN"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagemenTActiveSummaryItem>("createdOn")}
                    title={t("DATE_OVERTURN")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagemenTActiveSummaryItem) => <span>{convertToLocal(item.createdOn, FormatDate.DATE_HOUR_MINUTE)}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagemenTActiveSummaryItem>("activatedOn"),
            minWidth: 150,
            maxWidth: 240,
            name: t("DATE_ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagemenTActiveSummaryItem>("activatedOn")}
                    title={t("DATE_ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagemenTActiveSummaryItem) => <span>{convertToLocal(item.activatedOn, FormatDate.DATE_HOUR_MINUTE)}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IManagemenTActiveSummaryItem>("activationType"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ACTIVE_TYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IManagemenTActiveSummaryItem>("activationType")}
                    title={t("ACTIVE_TYPE")}
                    styles={styles}
                />
            ),
            onRender: (item: IManagemenTActiveSummaryItem) => <span>{item.activationType}</span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 30,
            maxWidth: 160,
            name: "",
            onRender: (item: IManagemenTActiveSummaryItem) =>
                schema.delete.visible && (
                    <CustomIcons
                        iconName={NameIcons.REMOVE}
                        position={"start"}
                        onClick={() => {
                            dispatch(setShowManagementSummaryPopUp(PopUpCodes.DELETE));
                            let payload = {
                                name: compileNameOfProperty<IManagemenTActiveSummaryItem>("id"),
                                value: item.id ?? 0,
                            };

                            dispatch(updateManagementSummaryConditionForm(payload));
                        }}
                    />
                ),
            isResizable: true,
        },
    ];

    return SummarySchema(columns);
};
