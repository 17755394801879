import { useTheme } from "react-jss";
import { PopUp } from "../popUp";
import { PopUpConfirmationStyle } from "./popUpConfirmationStyle.jss";
import { PopUpConfirmButtonActions } from "./actions/popUpConfirmActions";
import { ConfirmMessage } from "./message/confirmMessage";
import { PopUpConfirmationType } from "../../../../common/enum/PopUpConfirmationType";
import { useEffect, useMemo, useState } from "react";

interface IPopUpConfirmationProps {
    width?: any;
    height?: any;
    showPopUp: boolean;
    onCancel: (value: any) => any;
    onAccept: (value: any) => any;
    buttonAcceptTitle?: string;
    buttonCancelTitle?: string;
    type: PopUpConfirmationType;
    message?: any;
    disabledActions?: boolean;
    loading?: boolean;
    hiddeCancelButon?: boolean;
    hiddeCloseButon?: boolean;
    fontSize?: any;
    iconSize?: any;
    onDimissed?: () => any;
}

export const PopUpConfirmation: React.FC<IPopUpConfirmationProps> = ({
    width,
    height,
    showPopUp,
    onCancel,
    onAccept,
    buttonCancelTitle,
    buttonAcceptTitle,
    type,
    message,
    disabledActions,
    loading,
    hiddeCancelButon,
    hiddeCloseButon,
    fontSize,
    iconSize,
    onDimissed,
}) => {
    const [widthPopUp, setWidthPopUp] = useState();
    const [heightPopUp, setHeigthPopUp] = useState();

    const theme = useTheme();
    const style = PopUpConfirmationStyle({ theme, width: widthPopUp, height: heightPopUp, fontSize, iconSize });

    const childrenContainer = useMemo(() => {
        return (
            <div className={style.popUpContainer}>
                <div className={style.content}>
                    <ConfirmMessage
                        type={type}
                        message={message}
                        fontSize={fontSize}
                        iconSize={iconSize}
                        showPopUp={showPopUp}
                    />
                </div>
                <PopUpConfirmButtonActions
                    onAccept={onAccept}
                    onCancel={onCancel}
                    type={type}
                    buttonAcceptTitle={buttonAcceptTitle}
                    buttonCancelTitle={buttonCancelTitle}
                    disabled={disabledActions || loading}
                    loading={loading}
                    hiddeCancelButon={hiddeCancelButon}
                />
            </div>
        );
    }, [type, message, fontSize, iconSize, showPopUp, loading, hiddeCancelButon, disabledActions, onCancel, onAccept]);

    useEffect(() => {
        if (showPopUp) {
            setWidthPopUp(width);
            setHeigthPopUp(height);
        }
    }, [showPopUp, width, height]);

    return (
        <PopUp
            children={childrenContainer}
            state={showPopUp}
            hideModal={onCancel}
            skipHeader={false}
            onDimissed={onDimissed}
            hiddeCloseButon={hiddeCloseButon}
        />
    );
};
