import { IColumn } from "@fluentui/react";
import { CSSProperties } from "react";
const isValidNumber = (value: any) => {
    return value ? value : 0;
};

export const calcWidthColumFooter = (start: number, end: number, listColumns: IColumn[]): any => {
    let calcWidth = 0;
    if (start === end) {
        return isValidNumber(listColumns[start].calculatedWidth);
    }

    for (let i = start; i <= end; i++) {
        calcWidth = calcWidth + isValidNumber(listColumns[i].calculatedWidth);
    }

    return calcWidth;
};

export const styleColumFooter = (size: number, fontSize?:number): CSSProperties => {
    const style: CSSProperties = {
        width: size,
        whiteSpace:"pre-line", 
        fontSize: fontSize ? fontSize : "11px",
        display:"flex",
        alignItems:"center"
    };
    return style;
};

export const generateStyleWhithCustomFooter = (start: number, end: number, listColumns: IColumn[], fontSize?:number): CSSProperties => {
    return styleColumFooter(calcWidthColumFooter(start, end, listColumns), fontSize);
};

