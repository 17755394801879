import React from "react";
import { store } from "../../../../../redux/store";
import { ManagementAppointmentPermissionList } from "./list/list";
import { resetMasterAppointmentPermissionList } from "../../../../../redux/reducers/masterAppointment/appointmentPermission/list";
import { resetMasterAppointmentPermissionForm } from "../../../../../redux/reducers/masterAppointment/appointmentPermission/form";
import { MasterAppointmenRestrictionFormController } from "./controller/controller";

export class ManagementAppointmentPermissionContainer extends React.Component {
    componentWillUnmount(): void {
        store.dispatch(resetMasterAppointmentPermissionList());
        store.dispatch(resetMasterAppointmentPermissionForm());
    }

    render(): React.ReactNode {
        return (
            <>
                <ManagementAppointmentPermissionList />
                <MasterAppointmenRestrictionFormController />
            </>
        );
    }
}
