import { useTranslation } from "react-i18next";
import { SignupResumeDataEntityStyle } from "./resumeDataEntity.jss";
import { IEntityData } from "../../../../../../models";
import { ILabelTextStyleProps, LabelText } from "../../../../../common/labels/labelText/labelText";
import { useTheme } from "react-jss";

interface Props {
    data: IEntityData;
}
export const SignupResumeDataEntity: React.FC<Props> = ({ data }) => {
    const styles = SignupResumeDataEntityStyle();
    const { t } = useTranslation("common");
    const theme = useTheme() as any;
    const propsStyle: ILabelTextStyleProps = {
        dataColor: theme.colors.titles.secondary,
        dataSize: "",
        labelColor: theme.colors.titles.secondary,
        labelSize: "",
    };

    return (
        <div className={styles.container}>
            <div className={styles.twoItemsRow}>
                <LabelText
                    name={t("USER.SIGNUP.ENTITY_NAME")}
                    value={data.name}
                    styleProps={propsStyle}
                />

                <LabelText
                    name={t("USER.SIGNUP.CIF")}
                    value={data.documentNumber}
                    styleProps={propsStyle}
                />
            </div>
            <div className={styles.twoItemsRow}>
                <LabelText
                    name={t("USER.SIGNUP.FISCAL_ADDRESS")}
                    value={data.socialAddress}
                    styleProps={propsStyle}
                />
                <LabelText
                    name={t("USER.SIGNUP.POSTAL_CODE")}
                    value={data.postalCode}
                    styleProps={propsStyle}
                />
            </div>
            <div className={styles.twoItemsRow}>
                <LabelText
                    name={t("USER.SIGNUP.MUNICIPALITY")}
                    value={data.city}
                    styleProps={propsStyle}
                />
                <LabelText
                    name={t("USER.SIGNUP.PROVINCE")}
                    value={data.province}
                    styleProps={propsStyle}
                />
            </div>
        </div>
    );
};
