import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
import { ImportAppointmentAction } from "../../../../redux/actions/appointments/importAppointment/import";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetsetAppointmentImportForm, updateFormProperty } from "../../../../redux/reducers/appointment/appoitmentImport/form";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { ImportRequest } from "../container";
import { AppointmentImportForm } from "./form/form";

interface IVlcPortImportRequestFormControllerProps {
    codeComponent: string;
    setCodeComponent: (value: any) => any;
}

export const AppointmentImportFormController: React.FC<IVlcPortImportRequestFormControllerProps> = ({ codeComponent, setCodeComponent }) => {
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT.IMPORT" });
    const { isVisible, loading, form } = useAppSelector((state) => state.appointmentImportForm);
    const [document, setDocument] = useState<any>();
    const dispatch = useAppDispatch();

    const onCancel = () => {
        dispatch(resetsetAppointmentImportForm());
        setDocument(undefined);
        setCodeComponent("");
    };

    const onSave = () => {
        if (codeComponent === ImportRequest.DOCUMENT) {
            dispatch(ImportAppointmentAction(form, "document")).then((response: any) => {
                response && onCancel();
            });
        }

        if (codeComponent === ImportRequest.CODE) {
            dispatch(ImportAppointmentAction(form, isJsonString(form.document) ? "json" : "txt")).then((response: any) => {
                response && onCancel();
            });
        }
    };

    const width = useMemo(() => {
        return isVisible && codeComponent === ImportRequest.CODE ? "70vw" : "40vw";
    }, [isVisible]);

    const heigth = useMemo(() => {
        return isVisible && codeComponent === ImportRequest.CODE ? "70vh" : "30vh";
    }, [isVisible]);

    const fileExtension = useMemo(() => {
        if (document && document.name) {
            return document.name.split(".").pop();
        }
        return "";
    }, [document]);

    useEffect(() => {
        if (document) {
            dispatch(updateFormProperty({ name: "document", value: document }));
        }
    }, [document]);

    const isJsonString = (str: string): boolean => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const disabledForm = useMemo(() => {
        if (codeComponent === ImportRequest.CODE) {
            return !form.document;
        }
        if (codeComponent === ImportRequest.DOCUMENT) {
            return !document;
        }

        return true;
    }, [document, form]);

    const titlePopUp = useMemo(() => {
        if (codeComponent === ImportRequest.DOCUMENT) {
            return t("IMPORT_FILE");
        } else if (codeComponent === ImportRequest.CODE) {
            return t("IMPORT_TXT");
        }
    }, [codeComponent, isVisible]);

    return (
        <PopUpForm
            content={
                <AppointmentImportForm
                    setDocument={setDocument}
                    codeComponent={codeComponent}
                />
            }
            title={titlePopUp ?? ""}
            isLoading={loading}
            isVisible={isVisible === PopUpCodes.CREATE || isVisible === PopUpCodes.UPDATE}
            onCancel={onCancel}
            onSave={onSave}
            height={heigth}
            width={width}
            buttonSaveTitle={t("ACCEPT")}
            isDisabledButton={disabledForm}
        />
    );
};
