import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IStatisticsInspectionGroupedItem } from "../../../../../models/statistics/inspection/IGrouped";
import { compileNameOfProperty } from "../../../../../utils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { StatisticsInspectionGroupedListColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsInspectionGroupedListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = StatisticsInspectionGroupedListColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.INSPECTION.GROUPED_TABLE" });

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("merchandiseTypeDescription"),
            minWidth: 90,
            maxWidth: 130,
            name: t("MERCHANDISETYPE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("merchandiseTypeDescription")}
                    title={t("MERCHANDISETYPE")}
                    styles={styles}
                    isSortable={false}
                    handleClick={() => {}}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.merchandiseTypeDescription ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("requestsPositionedWithInvoiceConcepts"),
            minWidth: 70,
            maxWidth: 110,
            name: t("INSPECTIONS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("requestsPositionedWithInvoiceConcepts")}
                    title={t("INSPECTIONS")}
                    styles={styles}
                    isSortable={false}
                    handleClick={() => {}}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.requestsPositionedWithInvoiceConcepts ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("requests"),
            minWidth: 70,
            maxWidth: 110,
            name: t("REQUESTS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("requests")}
                    title={t("REQUESTS")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.requests ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayTime"),
            minWidth: 90,
            maxWidth: 130,
            name: t("INSTALATIONS_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayTime")}
                    title={t("INSTALATIONS_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.stayTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionTime"),
            minWidth: 70,
            maxWidth: 110,
            name: t("INSPECTIONS_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionTime")}
                    title={t("INSPECTIONS_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.inspectionTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidences"),
            minWidth: 70,
            maxWidth: 110,
            name: t("INCIDENCES"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidences")}
                    title={t("INCIDENCES")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.incidences ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesPercentage"),
            minWidth: 70,
            maxWidth: 110,
            name: t("INCIDENCES_PERCENTAGE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesPercentage")}
                    title={t("INCIDENCES_PERCENTAGE")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.incidencesPercentage ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesTime"),
            minWidth: 70,
            maxWidth: 110,
            name: t("INCIDENCES_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesTime")}
                    title={t("INCIDENCES_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.incidencesTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayAverageTime"),
            minWidth: 90,
            maxWidth: 130,
            name: t("AVERAGE_INSTALATIONS_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("stayAverageTime")}
                    title={t("AVERAGE_INSTALATIONS_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.stayAverageTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionAverageTime"),
            minWidth: 100,
            maxWidth: 130,
            name: t("AVERAGE_INSPECTIONS_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("inspectionAverageTime")}
                    title={t("AVERAGE_INSPECTIONS_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.inspectionAverageTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesAverageTime"),
            minWidth: 90,
            maxWidth: 130,
            name: t("AVERAGE_INCIDENCES_TIME"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("incidencesAverageTime")}
                    title={t("AVERAGE_INCIDENCES_TIME")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => (
                <span className={styles.dataCell}>{item.incidencesAverageTime ?? DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IStatisticsInspectionGroupedItem>("netTime"),
            minWidth: 90,
            maxWidth: 130,
            name: t("AVERAGE_NET"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IStatisticsInspectionGroupedItem>("netTime")}
                    title={t("AVERAGE_NET")}
                    handleClick={() => {}}
                    styles={styles}
                    isSortable={false}
                />
            ),
            onRender: (item: IStatisticsInspectionGroupedItem) => <span className={styles.dataCell}>{item.netTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
