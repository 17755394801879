import { IColumn } from "@fluentui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";
import { IInvoicingUninvoiced } from "../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/invoicing/uninvoiced/list";
import { store } from "../../../../../redux/store";
import { convertToLocal } from "../../../../../utils/dateUtils";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { InvoicingUninvoicedColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const InvoicingUninvoicedColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = InvoicingUninvoicedColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "INVOICED.UNINVOICEDLIST" });
    const dispatch = useAppDispatch();

    const { sort } = useAppSelector((store) => store.invoicingUninvoicedConceptList);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().invoicingUninvoicedConceptList.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("requestId"),
            minWidth: 120,
            maxWidth: 200,
            name: t("REQUEST"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingUninvoiced>("requestId")}
                    title={t("REQUEST")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingUninvoiced>("requestId"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingUninvoiced) => <span>{item.requestId ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("transportUnitNumber"),
            minWidth: 120,
            maxWidth: 200,
            name: t("UTI"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingUninvoiced>("transportUnitNumber")}
                    title={t("UTI")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingUninvoiced>("transportUnitNumber"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingUninvoiced) => <span>{item.transportUnitNumber ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("requestSort"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ORDER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingUninvoiced>("requestSort")}
                    title={t("ORDER")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingUninvoiced>("requestSort"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingUninvoiced) => <span>{item.requestSort ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("date"),
            minWidth: 100,
            maxWidth: 200,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingUninvoiced>("date")}
                    title={t("DATE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingUninvoiced>("date"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingUninvoiced) => (
                <span>{item.date ? convertToLocal(item.date, FormatDate.DATE) : DefaultValues.EMPTY_LIST}</span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("customerName"),
            minWidth: 100,
            maxWidth: 1000,
            name: t("CLIENT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingUninvoiced>("customerName")}
                    title={t("CLIENT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingUninvoiced>("customerName"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingUninvoiced) => <span>{item.customerName ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IInvoicingUninvoiced>("amount"),
            minWidth: 100,
            maxWidth: 200,
            name: t("IMPORT"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IInvoicingUninvoiced>("amount")}
                    title={t("IMPORT")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IInvoicingUninvoiced>("amount"))}
                    styles={styles}
                    isSortable={true}
                    sort={sort}
                />
            ),
            onRender: (item: IInvoicingUninvoiced) => <span>{item.amount ? `${item.amount}${DefaultValues.EURO}` : DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
