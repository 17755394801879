import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../../common/constants";

export const ManagementRequestDatePermisionDateFormStyle = createUseStyles((theme: any) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.paddings.sizes.sm,
        gap: theme.gaps.sizes.xl,
        width: "100%",
    },
    rowForm: {
        display: "flex",
        gap: theme.gaps.sizes.md,
        alignItems: "end",
        width: "100%",
        [mediaQueries.MOBILE]: {
            flexDirection: "column",
            alignItems: "start",
        },
    },
   smallRowForm :{
    display: "flex",
    gap: theme.gaps.sizes.md,
    alignItems: "end",
    width: "100%",
    
   },
    checkWorkDay: {
        width: "50%",
    },
    timeComponent: {
        width: "50%",
        marginTop:"-16px"
    },
}));
