import { createUseStyles } from "react-jss";

export const ShowAnimationStyled = createUseStyles((_theme: any) => ({
    mountedClass: (props: any) => ({
        animation: `$inAnimation ${props.delay}ms ease-in`,
    }),
    unmountedClass: (props: any) => ({
        animation: `$outAnimation ${props.delay}ms ease-out`,
        animationFillMode: "forwards",
    }),
    "@keyframes inAnimation": {
        from: {
            opacity: 0,
            maxHeight: 0,
        },
        to: {
            opacity: 1,
            maxHeight: 1600,
        },
    },
    "@keyframes outAnimation": {
        from: {
            opacity: 1,
            maxHeight: 1600,
        },
        to: {
            opacity: 0,
            maxHeight: 0,
        },
    },
}));
