import { IDropdownOption } from "@fluentui/react";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty, getGenericPayloadFromEvent, mapIDescriptionToDropDownOptions } from "../../../../../../../utils";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FormField, FormFieldTextarea } from "../../../../../../common/fields/fields";
import { BooleanSelector } from "../../../../../../request/common/selectors";
import { useTheme } from "react-jss";
import { MasterTypesWareFormStyle } from "./formStyle.jss";

import { Selector } from "../../../../../../common/selectors/selector";
import { NotDataWarningMessage } from "../../../../notDataWarning/notDataWarning";
import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterNotificationType } from "../../../../../../../models/resources/master/INotificationType";
import {
    updateMasterNotificationTypesProperty,
    setMasterNotificationTypesForm,
} from "../../../../../../../redux/reducers/resources/master/notificationType/form";

interface IMasterNotificationTypesFormProps {
    showNotData: boolean;
    data: IMasterNotificationType[];
}

export const MasterNotificationTypesForm: React.FC<IMasterNotificationTypesFormProps> = ({ data, showNotData }) => {
    const theme = useTheme();
    const style = MasterTypesWareFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { form } = useAppSelector((state) => state.masterNotificationTypesForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { register, control } = useForm<IMasterNotificationType>({ mode: "onChange" });

    const onUpdateFormProperty = (name: string, value: any) => {
        dispatch(updateMasterNotificationTypesProperty({ name, value }));
    };

    const onInputChange = useCallback(
        (event: any) => {
            const { name, value } = getGenericPayloadFromEvent(event);
            onUpdateFormProperty(name, value);
        },
        [dispatch],
    );

    const onYesNoOptionsChange = (option: IDropdownOption, propertyName: string) => {
        onUpdateFormProperty(propertyName, !!+option.key);
    };

    const onSelectorChange = (event: any, option: any, name: string) => {
        onUpdateFormProperty(name, option.key);
        let notificationType = data.find((item) => item.id === option.key);

        if (notificationType) {
            let form: IMasterNotificationType = {
                id: 0,
                referenceId: String(option.key),
                code: notificationType.code,
                description: notificationType.description,
                active: true,
            };

            dispatch(setMasterNotificationTypesForm(form));
        }
    };

    const disabledInput = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !form.referenceId;
    }, [showPopUp]);

    return showNotData ? (
        <NotDataWarningMessage />
    ) : (
        <div className={style.form}>
            {showPopUp === PopUpCodes.CREATE && (
                <Selector
                    name={compileNameOfProperty<IMasterNotificationType>("referenceId")}
                    title={t("FORM.NOTIFICATION_TYPES")}
                    options={mapIDescriptionToDropDownOptions(data)}
                    onChange={onSelectorChange}
                    selectedKey={Number(form.referenceId)}
                    required={true}
                />
            )}

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("CODE")}
                    value={form.code}
                    {...register(compileNameOfProperty<IMasterNotificationType>("code"), {
                        onChange: onInputChange,
                        value: form.code ?? undefined,
                    })}
                    isRequired={true}
                    maxLength={64}
                    disabled={true}
                />
            </div>

            <div className={style.rowForm}>
                <FormField
                    type="text"
                    label={t("DESCRIPTION")}
                    value={form.description ?? undefined}
                    disabled={disabledInput}
                    {...register(compileNameOfProperty<IMasterNotificationType>("description"), {
                        onChange: onInputChange,
                        value: form.description ?? undefined,
                    })}
                    isRequired={true}
                    maxLength={255}
                />
            </div>

            <div className={style.rowForm}>
                <FormFieldTextarea
                    label={t("DETAILS")}
                    value={form.details ?? DefaultValues.NOT_TEXT}
                    disabled={!form.referenceId}
                    {...register(compileNameOfProperty<IMasterNotificationType>("details"), {
                        onChange: onInputChange,
                        value: form.details ?? undefined,
                    })}
                />
            </div>

            <div className={style.rowForm}>
                <BooleanSelector
                    onChange={onYesNoOptionsChange}
                    selectedKey={+form.active}
                    propertyName={compileNameOfProperty<IMasterNotificationType>("active")}
                    title={t("ACTIVE")}
                    control={control}
                    isRequired={true}
                    disabled={disabledInput}
                />
            </div>
        </div>
    );
};
