import { useTranslation } from "react-i18next";
import { IRequestPendingAssignmentFilter } from "../../../models/assignment/IAssignment";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { resetFilter, updateFilterProperty } from "../../../redux/reducers/assignment/filter";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../utils";
import { FilterHeader } from "../../common/filterHeader/filterHeader";
import { TextInput } from "../../common/inputs/textInput";
import React, { useCallback } from "react";
import { BooleaMultiSelector } from "../../common/selectors";
import { IGenericPayload } from "../../../models";

import { useTheme } from "react-jss";
import { RequestPendingAssignmentFilterStyle } from "./filterStyle.jss";
import { fetchRequestPendingAssignmentListAction } from "../../../redux/actions/assignment/assignment";
export const RequestPendingAssignmentFilter = () => {
    const theme = useTheme();
    const style = RequestPendingAssignmentFilterStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "ASSIGNMENT.FILTER" });
    const { filter } = useAppSelector((state) => state.requestPendingAssignmentFilter);

    const onApplyFilter = () => {
        dispatch(fetchRequestPendingAssignmentListAction());
    };

    
    const onResetFilter = () => {
        dispatch(resetFilter());
        onApplyFilter();
    };
    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );
    const onBooleanSelectorChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };

            dispatch(updateFilterProperty(payload));
        },
        [dispatch],
    );

    const childrenFilter = (
        <div className={style.container}>
            <div className={`${style.column} ${style.filters}`}>
                <div className={style.row}>
                    <BooleaMultiSelector
                        selectedKey={filter.showAssigned}
                        propertyName={compileNameOfProperty<IRequestPendingAssignmentFilter>("showAssigned")}
                        title={t("ASSIGNED")}
                        onChange={onBooleanSelectorChange}
                    />

                    <TextInput
                        value={filter.certificateNumber}
                        propertyName={compileNameOfProperty<IRequestPendingAssignmentFilter>("certificateNumber")}
                        label={t("DOCUMENT_NUMBER")}
                        onChange={onInputChange}
                        onEnter={onApplyFilter}
                    />
                    <TextInput
                        value={filter.transportUnitNumber}
                        propertyName={compileNameOfProperty<IRequestPendingAssignmentFilter>("transportUnitNumber")}
                        label={t("UTI")}
                        onChange={onInputChange}
                        onEnter={onApplyFilter}
                    />
                </div>
                <div className={style.row}>
                    <div className="description">
                        <TextInput
                            value={filter.requestDescription}
                            propertyName={compileNameOfProperty<IRequestPendingAssignmentFilter>("requestDescription")}
                            label={t("DESCRIPTION")}
                            onChange={onInputChange}
                            onEnter={onApplyFilter}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <FilterHeader
            children={childrenFilter}
            content={childrenFilter}
            onApply={onApplyFilter}
            onReset={onResetFilter}
            buttonColumn={true}
            height={120}
        />
    );
};
