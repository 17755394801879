import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useDispatch } from "react-redux";
import { ColorType } from "../../../../common/enum/ColorType";
import { ContentType } from "../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../common/enum/files/FileSize";
import { fetchImportCarrierFile, fetchImportCarrierTemplate } from "../../../../redux/actions/user/users/users";
import { useAppSelector } from "../../../../redux/hooks";
import { Alert } from "../../../common/alert/alert";
import { ButtonPrimary } from "../../../common/buttons/buttons";
import { DownloadDataIcon } from "../../../common/downloadData/downloadData";
import { NavHeaderComponent } from "../../../common/navHeader/navHeader";
import { PopUpForm } from "../../../common/popup/popUpForm/popUpForm";
import { UploadFileDropzone } from "../../../common/uploadFileDropzone/uploadFileDropzone";
import { CarrierImportList } from "../list/list";

export const CustomersImportContent = () => {
    const theme = useTheme() as any;
    const dispatch = useDispatch();
    const { loading, templateCarrierImport, importResponse } = useAppSelector((state) => state.carrierImport);
    const { t } = useTranslation("common", { keyPrefix: "USER_IMPORT" });
    const [modalUpload, setModalUpload] = useState<boolean>(false);
    const [document, setDocument] = useState<any>();

    useEffect(() => {
        !templateCarrierImport && dispatch(fetchImportCarrierTemplate());
    }, [templateCarrierImport]);

    const downloadAction = () => {
        templateCarrierImport && window.open(templateCarrierImport, "_blank");
    };
    const uploadDocument = () => {
        dispatch(fetchImportCarrierFile(document)).then(() => {
            setModalUpload(false);
            setDocument(undefined);
        });
    };

    return (
        <>
            <NavHeaderComponent
                title={t("TITLE_CARRIER")}
                action={
                    <ButtonPrimary
                        title={t("BUTTON")}
                        handleClick={() => setModalUpload(true)}
                    />
                }
                endAction={
                    <DownloadDataIcon
                        title={t("DOWNLOAD")}
                        onDownloadAction={downloadAction}
                    />
                }
            />
            {importResponse && importResponse?.resultsError === 0 && (
                <Alert
                    colorType={ColorType.success}
                    fontSize={theme.fonts.sizes.xl}
                    data={t("MESSAGE_CARRIER")}
                />
            )}

            {importResponse && importResponse?.resultsError > 0 && <CarrierImportList />}
            <PopUpForm
                content={
                    <UploadFileDropzone
                        allowedExtensions={[ContentType.EXCEL]}
                        onFilesChange={setDocument}
                        disabled={false}
                        maxSize={FileSize._5MB}
                    />
                }
                title={t("MODAL_TITLE")}
                height={"30vh"}
                width={"40vw"}
                isVisible={modalUpload}
                isDisabledButton={!document}
                onSave={uploadDocument}
                buttonSaveTitle={t("ACCEPT")}
                onCancel={() => {
                    setModalUpload(false);
                    setDocument(undefined);
                }}
                isLoading={loading}
            />
        </>
    );
};
