import { useTheme } from "react-jss";
import { RequestListPopUpFilterStyle } from "./popUpStyle.jss";
import { RequestListDatePicker } from "../../component/requestListDatePicker/requestListDatePicker";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { ButtonPrimaryInverseWithBorder } from "../../../../common/buttons/buttons";
import { resetFilter, setShowPopUpFilter } from "../../../../../redux/reducers/request/list/filter";
import { setShowAllRequest } from "../../../../../redux/reducers/request/list/requestlist";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { onApplyFilterAction } from "../../../../../redux/actions/request/filter/filterRequest";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../../styles/romeu/colors";
import { PopUpForm } from "../../../../common/popup/popUpForm/popUpForm";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { PopUp } from "../../../../common/popup/popUp";
import { CreateRequestContainer } from "../../../form/create/container";
import { useCallback, useMemo, useState } from "react";
import { Separator } from "../../../../common/separator/separator";
import { NameIcons } from "../../../../../assets/icons";
import { RequestFilterPopUp } from "./filter/filter";
import { generateFilterRequestSchema } from "../../../common/schema/generateFilterRequestSchema";
import { ViewType } from "../../../../../models";
import { routeFeatures } from "../../../../../common/constants/routeFeatures";
import { IUserRoleView, IView } from "../../../../../models/requests/IUserRoleView";
import { useSize } from "../../../../../hooks/resizeHook";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";
interface IRequestListFilterPopUpContainerProps {
    showCreateRequest: boolean;
}
export const RequestListFilterPopUpContainer: React.FC<IRequestListFilterPopUpContainerProps> = ({ showCreateRequest }) => {
    const minUserWidt = 650;

    const { width } = useSize();
    const theme = useTheme();
    const style = RequestListPopUpFilterStyle({ theme, width: width - 60 > minUserWidt ? true : false });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common");
    const [isCreateRequestVisible, setCreateRequestVisible] = useState<boolean>(false);
    const {
        requestListFilter: { showPopUp, filter },
        requestlist: { showAllRequest },
        userData: { profile },
        userRoleViews: { viewPermission },
    } = useAppSelector((state) => state);

    const iconDrop = showAllRequest ? "ChevronUpSmall" : "ChevronDownSmall";
    const onApply = () => {
        dispatch(onApplyFilterAction());
    };

    const onReset = () => {
        dispatch(resetFilter());
        dispatch(onApplyFilterAction());
    };

    const viewRequestPermission: IUserRoleView | undefined = useMemo(() => {
        return viewPermission ? viewPermission.find((feature) => feature.id === routeFeatures.REQUEST) : undefined;
    }, [viewPermission]);

    const viewConfig = useMemo(() => {
        if (!viewRequestPermission) {
            return null;
        }

        return viewRequestPermission?.views.find((view) => view.type === ViewType.SEARCH) as IView;
    }, [viewRequestPermission?.views]);

    const schema = useMemo(() => {
        if (!viewConfig) {
            return undefined;
        }
        return generateFilterRequestSchema(viewConfig.fields);
    }, [viewConfig?.fields]);

    const showUtiBubble: boolean = useMemo(() => {
        let apply =
            filter.sort !== undefined ||
            (filter.transportUnitNumber !== undefined && filter.transportUnitNumber !== "") ||
            !(filter.transportUnitId.length === 1 && filter.transportUnitId[0] === -1) ||
            !(filter.transportUnitSizeId.length === 1 && filter.transportUnitSizeId[0] === -1) ||
            !(filter.customerId.length === 1 && filter.customerId[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            !(filter.valued.length === 1 && filter.valued[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            filter.invoiced !== undefined;

        return apply;
    }, [filter]);

    const showMerchandiseBubble: boolean = useMemo(() => {
        let apply =
            !(filter.merchandiseTypeId.length === 1 && filter.merchandiseTypeId[0] === -1) ||
            !(filter.merchandiseOriginId.length === 1 && filter.merchandiseOriginId[0] === -1) ||
            !(filter.customsStateId.length === 1 && filter.customsStateId[0] === -1) ||
            filter.halalCertified !== undefined ||
            filter.dryCharge !== undefined ||
            filter.organicProduct !== undefined ||
            filter.fumigated !== undefined;

        return apply;
    }, [filter]);

    const showExpedientBubble: boolean = useMemo(() => {
        let apply =
            (filter.certificateNumber !== undefined && filter.certificateNumber !== "") ||
            !(filter.certificateTypeId.length === 1 && filter.certificateTypeId[0] === -1) ||
            !(filter.actuationTypeId.length === 1 && filter.actuationTypeId[0] === -1) ||
            (!(filter.organismId.length === 1 && filter.organismId[0] === -1) && !profile.organismId) ||
            filter.inspectorAssigned !== undefined ||
            !(filter.inspectorId.length === 1 && filter.inspectorId[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            filter.vua !== undefined ||
            (filter.summaryNumber !== undefined && filter.summaryNumber !== "") ||
            !(filter.terminalId.length === 1 && filter.terminalId[0] === -1) ||
            !(filter.finalDestinationTypeId.length === 1 && filter.finalDestinationTypeId[0] === -1);

        return apply;
    }, [filter]);

    const showInspectionBubble: boolean = useMemo(() => {
        let apply =
            filter.parkingAuthorization !== undefined ||
            filter.summaryActive !== undefined ||
            !(filter.turnId.length === 1 && filter.turnId[0] === -1) ||
            !(filter.stateId.length === 1 && filter.stateId[0] === -1) ||
            !(filter.dockId.length === 1 && filter.dockId[0] === -1) ||
            !(filter.documentTypeId.length === 1 && filter.documentTypeId[0] === -1) ||
            !(filter.appointmentTimeZoneId && filter.appointmentTimeZoneId.length === 1 && filter.appointmentTimeZoneId[0] === -1) ||
            filter.app !== undefined;

        return apply;
    }, [filter]);

    const showUtiPivot: boolean = useMemo(() => {
        if (!schema) {
            return false;
        }

        return (
            schema.sortVisible ||
            schema.transportUnitNumberVisible ||
            schema.transportUnitIdVisible ||
            schema.transportUnitSizeIdVisible ||
            schema.customerIdVisible ||
            schema.valuedVisible ||
            schema.invoicedVisible
        );
    }, [schema]);

    const showMerchandisePivot: boolean = useMemo(() => {
        if (!schema) {
            return false;
        }

        return (
            schema.merchandiseTypeIdVisible ||
            schema.merchandiseOriginIdVisible ||
            schema.customsStateIdVisible ||
            schema.halalCertifiedVisible ||
            schema.dryChargeVisible ||
            schema.organicProductVisible ||
            schema.fumigatedVisible
        );
    }, [schema]);

    const showExpedientPivot = useMemo(() => {
        if (!schema) {
            return false;
        }
        return (
            schema.certificateNumberVisible ||
            schema.certificateTypeIdVisible ||
            schema.actuationTypeIdVisible ||
            schema.organismIdVisible ||
            schema.inspectorAssignedVisible ||
            schema.inspectorIdVisible ||
            schema.vuaVisible ||
            schema.summaryNumberVisible ||
            schema.terminalIdVisible ||
            schema.finalDestinationTypeIdVisible
        );
    }, [schema]);

    const showInspectionPivot = useMemo(() => {
        if (!schema) {
            return false;
        }
        return (
            schema.parkingAuthorizationVisible ||
            schema.summaryActiveVisible ||
            schema.turnIdVisible ||
            schema.stateIdVisible ||
            schema.dockIdVisible ||
            schema.documentTypeIdVisible ||
            schema.appVisible ||
            schema.appointmentTimeZoneVisible
        );
    }, [schema]);
    const popUpFilter = useCallback(() => {
        return (
            <RequestFilterPopUp
                showInspectionBubble={showInspectionBubble}
                showExpedientBubble={showExpedientBubble}
                showMerchandiseBubble={showMerchandiseBubble}
                showUtiBubble={showUtiBubble}
                showInspectionPivot={showInspectionPivot}
                showExpedientPivot={showExpedientPivot}
                showMerchandisePivot={showMerchandisePivot}
                showUtiPivot={showUtiPivot}
                schema={schema}
            />
        );
    }, [
        filter,
        showInspectionBubble,
        showExpedientBubble,
        showMerchandiseBubble,
        showUtiBubble,
        showInspectionPivot,
        showExpedientPivot,
        showMerchandisePivot,
        showUtiPivot,
        schema,
    ]);

    const actions = (
        <div className={style.actions}>
            {(showInspectionPivot || showExpedientPivot || showMerchandisePivot || showUtiPivot) && (
                <>
                    <div
                        onClick={() => {
                            dispatch(setShowPopUpFilter(PopUpCodes.FILTER));
                        }}
                        className={`${style.action} `}
                    >
                        <CustomIcons
                            size={20}
                            iconName={NameIcons.FILTER}
                            margin={"0px"}
                        />
                        {(showInspectionBubble || showExpedientBubble || showMerchandiseBubble || showUtiBubble) && (
                            <span className={style.applyFilterIcon}></span>
                        )}
                    </div>
                    <Separator size="auto" />
                </>
            )}

            <div
                onClick={() => {
                    dispatch(setShowAllRequest(!showAllRequest));
                }}
                className={`${style.action} `}
            >
                <CustomIcons
                    iconName={iconDrop}
                    margin={5}
                />
                {!showAllRequest ? t("COMMON.DROP_UP") : t("COMMON.DROP_DOWN")}
            </div>

            {showCreateRequest && (
                <>
                    <Separator size="auto"></Separator>
                    <div
                        onClick={() => {
                            setCreateRequestVisible(true);
                        }}
                        className={`${style.action} `}
                    >
                        <CustomIcons
                            margin={5}
                            iconName={"Add"}
                        />
                        <span> {t("LIST.BUTTON.ADD")}</span>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <div className={style.container}>
            <div className={style.date}>
                <RequestListDatePicker />
                {width - 60 > minUserWidt && (
                    <>
                        <span className={style.verticalWhiteSeparator}></span>
                        {actions}
                    </>
                )}
            </div>

            {width - 60 < minUserWidt && actions}

            <PopUpForm
                content={popUpFilter()}
                title={t("REQUEST_FILTER.TITLES.FILTERS")}
                isVisible={showPopUp === PopUpCodes.FILTER}
                onCancel={() => {
                    dispatch(setShowPopUpFilter(undefined as any));
                }}
                onSave={onApply}
                height={"calc(100vh - 12rem)"}
                width={"calc(100vw - 6rem)"}
                backGroundColor={colors.requestFilter.backgroundColor}
                hiddenClose={true}
                buttonSaveTitle={t("COMMON.APPLYFILTERS")}
                customActions={
                    <ButtonPrimaryInverseWithBorder
                        handleClick={() => {
                            onReset();
                        }}
                        title={t("COMMON.CLEANFILTERS")}
                        icon="ClearFilter"
                    />
                }
            ></PopUpForm>

            {showCreateRequest && (
                <PopUp
                    children={
                        <CreateRequestContainer
                            onAbandon={() => {
                                setCreateRequestVisible(false);
                            }}
                        />
                    }
                    state={isCreateRequestVisible}
                    isBlocking={true}
                    skipHeader={true}
                    skipPadding={true}
                />
            )}
        </div>
    );
};
