import { Resolver } from "react-hook-form";

export const myResolverConcept: Resolver<any> = async (values: any) => {
    let errors = {};
    Object.entries(values).forEach(([name, value]) => {
        if (!value) {
            errors = {
                ...errors,
                [name]: {
                    type: "required",
                    message: "FORM.VALIDATION.REQUIRE",
                },
            };
        }
    });
    return { values, errors };
};
