import { useTranslation } from "react-i18next";
import { DefaultValues } from "../../../common/enum/DefaultValues";

interface IBooleanValueProps {
    value?: boolean | string | number;
    allOption?: boolean;
}
export const BooleanValue: React.FC<IBooleanValueProps> = ({ value, allOption }) => {
    const { t } = useTranslation("common", { keyPrefix: "COMMON" });

    const mapValue = () => {
        if (value === undefined || (value === null && allOption)) {
            return t("ALL");
        } else if (value) {
            return t("YES");
        } else if (value === false) {
            return t("NO");
        } else {
            return DefaultValues.EMPTY_LIST;
        }
    };

    return <>{mapValue()}</>;
};
