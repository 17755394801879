import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { IBoardHistoryAppointmentCreate } from "../../../../models/masterAppointment/IAppointmentBoard";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";
export interface IAppointmentBoardFormState {
    form: IBoardHistoryAppointmentCreate;
    loading: boolean;
    showPopUp: PopUpCodes;
}

const initialState: IAppointmentBoardFormState = {
    form: {
        date: new Date(),
    },
    loading: false,
    showPopUp: PopUpCodes.NONE,
};

export const masterAppointmentBoardCreateForm = createSlice({
    name: "masterAppointmentBoardCreateForm",
    initialState,
    reducers: {
        resetMasterAppointmentBoardForm: () => initialState,
        setLoadingMasterAppointmentBoardForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterAppointmentBoardForm: (state, action: PayloadAction<IBoardHistoryAppointmentCreate>) => {
            state.form = action.payload;
        },
        updateMasterAppointmentBoardProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
        setShowPopUpAppointmentBoard: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetMasterAppointmentBoardForm,
    setLoadingMasterAppointmentBoardForm,
    setMasterAppointmentBoardForm,
    updateMasterAppointmentBoardProperty,
    setShowPopUpAppointmentBoard,
} = masterAppointmentBoardCreateForm.actions;

export default masterAppointmentBoardCreateForm.reducer;
