import { createUseStyles } from "react-jss";

export const StateChangerStyles = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: theme.paddings.default,
        paddingRight: theme.paddings.default,
        width: "200px",
        height: "80%",
        position: "relative",
        fontSize: theme.fonts.sizes.sm
    },
    selector: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 12,
        width: "100%",
        cursor: "pointer",
        " & i": {
            " & .st0": {
                fill: theme.colors.stateChanger.primary.color,
                stroke: theme.colors.stateChanger.primary.color,
                strokeWidth: 0
            }
        },
        " & .arrow": {
            marginLeft: "auto",
        },
        " & .arrowUp": {
            marginLeft: "auto",
            transform: "rotate(180deg)"
        }
    },
    currentState: {
        textTransform: "uppercase",
        color: theme.colors.stateChanger.primary.color,
        fontWeight: theme.fonts.weight.bold,
    },
    actionMenu: {
        position: "absolute",
        zIndex: "6000",
        top: 55,
        left: `-${theme.paddings.default}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 12,
        width: "223px",
        backgroundColor: theme.colors.headerNav.background,
        borderRadius: theme.borders.default.radius,
        padding: theme.paddings.default,
        WebkitBoxShadow: theme.borders.actionMenu.boxShadow,
        MozBoxShadow: theme.borders.actionMenu.boxShadow,
        boxShadow: theme.borders.actionMenu.boxShadow,

        " & .title": {
            fontSize: theme.fonts.sizes.sm
        },

        " & .button": {
            border: theme.borders.default.border,
            borderRadius: theme.borders.default.radius,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            gap: 10,
            padding: "8px 5px 5px 10px",
            cursor: "pointer",
            textTransform: "uppercase",
            fontWeight: theme.fonts.weight.bold,
            color: theme.colors.titles.primary.color,
            " & i": {
                width: 16,
            },
        },

        " & .primary": {
            borderColor: theme.colors.stateChanger.primary.color,
            color: theme.colors.stateChanger.primary.color,
            " &:hover": {
                backgroundColor: theme.colors.stateChanger.primary.selectedBackground,
            },
            " & .st0": {
                fill: theme.colors.stateChanger.primary.color,
                stroke: theme.colors.stateChanger.primary.color,
                strokeWidth: 0
            }
        },

        " & .secondary": {
            borderColor: theme.colors.stateChanger.secondary.color,
            color: theme.colors.stateChanger.secondary.color,
            " &:hover": {
                backgroundColor: theme.colors.stateChanger.secondary.selectedBackground,
            },
            " & .st0": {
                fill: theme.colors.stateChanger.secondary.color,
                stroke: theme.colors.stateChanger.secondary.color,
                strokeWidth: 0
            }
        },

        " & .separator": {
            borderBottom: theme.borders.default.borderDotted,
            width: "100%"
        },

        " & .otherStates": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 12,
            width: "100%",
            padding: "8px 10px 5px 10px",
            border: theme.borders.default.borderDotted,
            borderRadius: theme.borders.default.radius,

            " & .selector": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 12,
                width: "100%",
                cursor: "pointer",
                textTransform: "uppercase",
                fontWeight: theme.fonts.weight.bold,
                color: theme.colors.stateChanger.other.color,
                " & i": {
                    width: 16,
                    " & .st0": {
                        fill: theme.colors.stateChanger.other.color,
                        stroke: theme.colors.stateChanger.other.color,
                        strokeWidth: 0
                    }
                },
                " & .arrow": {
                    marginLeft: "auto",
                },
                " & .arrowUp": {
                    marginLeft: "auto",
                    transform: "rotate(180deg)"
                }
            },

            " & .actionMenu": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 5,
                width: "100%",
                maxHeight: "56vh",
                overflow: "auto",
                " & div:last-child": {
                    marginBottom: 5
                }
            }
        },
    }
}));
