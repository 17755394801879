import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../assets/icons";
import { IDockDisplayAssigned } from "../../../../models/management/dockDisplay/IDockDisplay";
import { CustomIcons } from "../../../common/customIcons/customIcons";
import { ManagementDockDisplayAssignedVehiclesStyle } from "./assigned.jss";

interface IManagementDockDisplayAssignedVehiclesProps {
    vehicle: IDockDisplayAssigned;
}

export const ManagementDockDisplayAssignedVehicles: React.FC<IManagementDockDisplayAssignedVehiclesProps> = ({ vehicle }) => {
    const { t } = useTranslation("common", { keyPrefix: "MANAGEMENT.DOCK_DISPLAY" });
    const theme = useTheme();
    const style = ManagementDockDisplayAssignedVehiclesStyle({ theme, newAssigned: vehicle.new });
    const assignedText = vehicle.new ? "NEW_ASSIGNED" : "ASSIGNED";
    const iconFlag = vehicle.new ? NameIcons.LIGHTFLAG : NameIcons.DARCKFLAG;
    const [showAnimated, setShowAnimated] = useState<boolean>(vehicle.new);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (vehicle.new) {
            timeoutId = setTimeout(() => setShowAnimated(false), 10000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [vehicle.new]);

    return (
        <div className={`${style.containerAssigned} ${vehicle.new && showAnimated ? style.animation : ""}`}>
            <div className={style.iconCell}>
                <CustomIcons
                    iconName={iconFlag}
                    className={style.flag}
                    position="start"
                    disabledSelect
                />
                <div className={style.textIcon}>{t(assignedText)}</div>
            </div>
            <div className={style.doubleCell}>
                <div className={style.header}>{t("DOOR")}</div>
                <div className={style.text}>{vehicle.dockCode}</div>
            </div>
            <div className={style.doubleCell}>
                <div className={style.header}>{t("PLATE")}</div>
                <div className={style.text}>{vehicle.transportUnitNumber}</div>
            </div>
        </div>
    );
};
