import { IRole } from "../../models";
import { IRoleManagement } from "../../models/common/IRole";

export const getRolesFiltered = (roles:IRole[], userRoleManagement?: IRoleManagement[]) => {
    let rolesManagement: IRole[] = [];

    if (userRoleManagement) {
        userRoleManagement?.forEach((x) => {
            const role = roles?.find((y) => x.id === y.id);
            if (role) {
                rolesManagement.push(role);
            }
        });
    }

    return rolesManagement;
};