import { createUseStyles } from "react-jss";

export const ManagementNotificationFilterStyle = createUseStyles((theme: any) => ({
    filterContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.gaps.filter.default,

    },
    filterItem: (props: any) => ({

        width: props.hasFiveColumns ? 161 : 205,
    }),
    filterItem2: (props: any) => ({

        width: props.hasFiveColumns ? 332 : 420,
    }),
}));
