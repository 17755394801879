import { mediaQueries } from "../common/constants";


export const getPivotStyles = (theme: any, selectedKey: any) => {
    return {
        linkIsSelected: {
            color: theme.colors.navLinks.primary.color,
            selectors: {
                ":before": {
                    height: 3,
                    backgroundColor: theme.colors.navLinks.primary.color,
                },
            },
        },

        link: {
            color: theme.colors.siteNav.section.color,
            fontFamily: theme.fonts.families.site,
            minWidth: 208,
            marginRight: 0,
            [mediaQueries.TABLET]: {
                minWidth: "auto",
            },
        },

        root: {
            display: "flex",
            height: "auto",
            background: theme.colors.palette.purpleLight,
        },
        itemContainer: {
            paddingLeft: selectedKey === "0" ? 0 : theme.paddings.header.paddingLeft,
        },
    };
};
export const getPivotGeneralInformationStyles = (theme: any) => {
    return {
        linkIsSelected: {
            color: theme.colors.navLinks.primary.color,
            backgroundColor: theme.colors.palette.purpleLighter,
            selectors: {
                ":before": {
                    height: 0,
                },
            },
        },
        link: {
            color: theme.colors.siteNav.section.color,
            fontFamily: theme.fonts.families.site,
            textAlign: "start",
            minWidth: 200,
            width: "100%",
            [mediaQueries.TABLET]: {
                minWidth: "auto",
            },
        },
        root: {
            display: "flex",
            flexFlow: "column nowrap",
            background: theme.colors.editRequest.containerBackGround,
            height: "auto",
        },
        itemContainer: {
            width: "100%",
            height: "auto",
            overflowY: "auto",
            maxHeight: theme.heights.requestEditContent,
            [mediaQueries.TABLET]: {
                maxHeight: theme.heights.requestEditMobileContent,
            },
            background: "white",
        },
    };
};

export const getColumnListStyle = (theme: any) => {
    return {
        header: {
            display: "flex",
        },
        sort: {
            marginTop: "5px",
        },
        iconSort: {
            fill: theme.colors.iconSort.disabled,
            width: "16px",
        },
        iconSorted: {
            fill: theme.colors.iconSort.enabled,
            width: "16px",
        },
        sortColumn: {
            width: 14,
            display: "flex",
            position: "absolute",
        },
        actions: {
            display: "flex",
        },
        arrow: {
            color: theme.colors.table.sortArrow.color,
        },
    };
};