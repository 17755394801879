import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";

export interface IAddInspectionData {
    typeId: number;
    instructions: string;
    organismId: number;
    onlyInspectionsWithoutActuation: boolean;
}
export interface IAddInspectionForm {
    form: IAddInspectionData;
}

const initialState: IAddInspectionForm = {
    form: {
        typeId: 0,
        instructions: "",
        organismId: 0,
        onlyInspectionsWithoutActuation: true,
    },
};

export const addInspectionSlice = createSlice({
    name: "addInspectionReducer",
    initialState,
    reducers: {
        updateAddInspectionFormProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },

        resetAddInspectionFormDataState: () => initialState,
    },
});

export const { updateAddInspectionFormProperty, resetAddInspectionFormDataState } = addInspectionSlice.actions;

export default addInspectionSlice.reducer;
