import { createUseStyles } from "react-jss";

export const RequestListChangeDatePickerStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
    },
    datePicker: {
        display: "flex",
        " & div": {
            width: 150,
            border: "none",
            borderTopRightRadius: 0,
            borderEndEndRadius: 0,
            borderTopLeftRadius: 2,
            borderEndStartRadius: 2,
            " & i": {
                left: 0,
            },
        },
        " & .ms-TextField-fieldGroup": {
            padding: "0 0 0 25px",
        },
    },
    datePickerBtn: {
        height: 32,
        border: "none",
        backgroundColor: theme.colors.palette.purple,
        color: theme.colors.palette.white,
        borderTopRightRadius: 2,
        borderEndEndRadius: 2,
        cursor: "pointer",
    },
}));
