import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models/common/IGenericPayload";
import { PopUpCodes } from "../../../../common/enum/PopUpCodes";

export interface IManagementRepresentationFormState {
    generalRepresentationData: IManagementRepresentationForm;
    loading: boolean;
    showPopUp:PopUpCodes
}

export interface IManagementRepresentationForm {
    id?: number;
    startDate?: Date;
    endDate?: Date;
    validated?: boolean;
}

const initialState: IManagementRepresentationFormState = {
    generalRepresentationData: {
        endDate: new Date(),
        startDate: new Date(),
        validated: false,
    },
    loading: false,
    showPopUp:PopUpCodes.NONE
};

export const managementRepresentationFormSlice = createSlice({
    name: "managementRepresentationForm",
    initialState,
    reducers: {
        resetManagementRepresentation: () => initialState,

        setLoadingManagementRepresentation: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        updateManagementRepresentationProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.generalRepresentationData = {
                ...state.generalRepresentationData,
                [action.payload.name]: action.payload.value,
            };
        },

        setManagementRepresentation: (state, action: PayloadAction<IManagementRepresentationForm>) => {
            state.generalRepresentationData = action.payload;
        },
        setManagementRepresentationPopUp: (state, action: PayloadAction<PopUpCodes>) => {
            state.showPopUp = action.payload;
        },
    },
});

export const {
    resetManagementRepresentation,
    setLoadingManagementRepresentation,
    updateManagementRepresentationProperty,
    setManagementRepresentation,
    setManagementRepresentationPopUp
} = managementRepresentationFormSlice.actions;

export default managementRepresentationFormSlice.reducer;
