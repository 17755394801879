import { DefaultValues } from "../../common/enum/DefaultValues";
import { FormatDate } from "../../common/enum/dateTime/FormatDate";
import { convertToLocal } from "../../utils";

export interface IStatisticsSearchFilter {
    filters: IStatisticsFilter;
}

export interface IStatisticsFilter {
    transportUnitIds: number[];
    transportUnitSizeIds: number[];
    customerIds: string[];
    merchandiseTypeIds: number[];
    merchandiseOriginIds: number[];
    customsStateIds: number[];
    finalDestinationTypeIds: number[];
    organismIds: number[];
    certificateTypeIds: number[];
    documentTypeIds: number[];
    inspectorIds: string[];
    startDate: string;
    endDate: string;
    halalCertified?: boolean;
    dryCharge?: boolean;
    fumigated?: boolean;
    organicProduct?: boolean;
}



export const emptyStatisticsFilter = (): IStatisticsFilter => {
    return {
        certificateTypeIds: [-1],
        customsStateIds: [-1],
        documentTypeIds: [-1],
        finalDestinationTypeIds: [-1],
        inspectorIds: [DefaultValues.DEFAULT_ALL_SELECTOR as string],
        merchandiseTypeIds: [-1],
        merchandiseOriginIds: [-1],
        organismIds: [-1],
        transportUnitIds: [-1],
        transportUnitSizeIds: [-1],
        customerIds: [DefaultValues.DEFAULT_ALL_SELECTOR as string],
        startDate: convertToLocal(new Date(), FormatDate.ISO),
        endDate: convertToLocal(new Date(), FormatDate.ISO),
    };
};
