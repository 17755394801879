import { IComboBoxOption, IDropdown, IDropdownOption } from "@fluentui/react";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { DocumentTypesWithId } from "../../../../../../../../common/enum";
import { ContentType } from "../../../../../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../../../../../common/enum/files/FileSize";
import { fetchDocumentTypes } from "../../../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch } from "../../../../../../../../redux/hooks";
import { documentTypesSelector } from "../../../../../../../../redux/selectors/documentTypes/documentTypesSelector";
import { Alert } from "../../../../../../../common/alert/alert";
import { Selector } from "../../../../../../../common/selectors/selector";
import { UploadFileDropzone } from "../../../../../../../common/uploadFileDropzone/uploadFileDropzone";
import { EditRequestDocumentFormStyle } from "./formStyle.jss";

interface IEditRequestDocumentFormProps {
    setDocumentType: (value: any) => any;
    selectedTypeDocument: any;
    setDocumentRequest: (value: any) => any;
}

export const EditRequestDocumentForm: React.FC<IEditRequestDocumentFormProps> = ({ setDocumentType, selectedTypeDocument, setDocumentRequest }) => {
    const theme = useTheme();

    const style = EditRequestDocumentFormStyle({ theme });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.FIELDS" });

    const comboBoxRef = React.useRef<IDropdown>(null);
    const documentTypes = useSelector(documentTypesSelector);

    useEffect(() => {
        if (!documentTypes || !documentTypes.data.length) {
            dispatch(fetchDocumentTypes());
        }
    }, [documentTypes, dispatch]);

    const documentTypesComboOptions: IComboBoxOption[] = documentTypes.data.map((item: any) => {
        return {
            key: item.id,
            text: item.description,
        };
    });

    const handleFileChange = (file: File) => {
        setDocumentRequest(file);
    };

    const handleDocumentTypeChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        setDocumentType(option.key);
    };

    return (
        <div className={style.container}>
            <div className={style.form}>
                <Selector
                    selectedKey={selectedTypeDocument}
                    comboBoxRef={comboBoxRef}
                    title={t("DOCUMENTTYPE")}
                    options={documentTypesComboOptions}
                    onChange={handleDocumentTypeChange}
                    name={""}
                    required={true}
                />

                <UploadFileDropzone
                    allowedExtensions={[ContentType.PDF, ContentType.WORD]}
                    maxSize={FileSize._5MB}
                    disabled={!selectedTypeDocument}
                    onFilesChange={handleFileChange}
                />
            </div>

            {selectedTypeDocument === DocumentTypesWithId.FUMIGATION_REPORT && (
                <Alert
                    title={t("INFO")}
                    icon={"Info"}
                    height={"23.1vh"}
                    data={
                        <div className={style.message}>
                            <Trans
                                t={t}
                                i18nKey={"FUMIGATIONWARNING"}
                            />
                        </div>
                    }
                />
            )}
        </div>
    );
};
