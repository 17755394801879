import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
    deleteMasterAppointmentDockTimeZoneConfiguration,
    postMasterAppointmentDockTimeZoneConfiguration,
    updateMasterAppointmentDockTimeZoneConfiguration,
} from "../../../../../../../../../../redux/actions/masterAppointment/appointmentBoard/appointmentDockTimeZoneConfiguration/action";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../redux/hooks";
import {
    resetAppointmentDockTimeZoneConfigurationForm,
    setShowPopUpAppointmentDockTimeZoneConfiguration,
} from "../../../../../../../../../../redux/reducers/masterAppointment/appointmentBoard/appointmentDockTimeZoneConfiguration/form";
import { PopUpConfirmation } from "../../../../../../../../../common/popup/popUpConfirmation/popUpConfirmation";
import { PopUpForm } from "../../../../../../../../../common/popup/popUpForm/popUpForm";
import { MasterBoardHistoryAppointmentFringeForm } from "./form/form";
import { PopUpCodes } from "../../../../../../../../../../common/enum/PopUpCodes";
import { PopUpConfirmationType } from "../../../../../../../../../../common/enum/PopUpConfirmationType";
import { RequiredFieldsMessage } from "../../../../../../../../../common/requiredMessage/requiredMessage";

export const MasterBoardHistoryAppointmentDockTimeZoneConfigurationControllerForm = () => {
    const dispatch = useAppDispatch();
    const { showPopUp, form, loading } = useAppSelector((store) => store.masterAppointmentDockTimeZoneConfigurationForm);
    const { id } = useAppSelector((store) => store.masterAppointmentBoardEdit.form);

    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT.APPOINTMENT_BOARD.APPOINTMENT_CONDITIONS" });
    const onSave = () => {
        if (showPopUp === PopUpCodes.CREATE) {
            dispatch(postMasterAppointmentDockTimeZoneConfiguration(id.toString(), form)).then((response: any) => {
                response && onClose();
            });
        } else if (showPopUp === PopUpCodes.UPDATE) {
            dispatch(updateMasterAppointmentDockTimeZoneConfiguration(id.toString(), form.id.toString(), form)).then((response: any) => {
                response && onClose();
            });
        } else if (showPopUp === PopUpCodes.DELETE) {
            dispatch(deleteMasterAppointmentDockTimeZoneConfiguration(id.toString(), form.id.toString()));
            onClose();
        }
    };

    const onClose = () => {
        dispatch(setShowPopUpAppointmentDockTimeZoneConfiguration(PopUpCodes.NONE));
    };

    const onDimissed = () => {
        dispatch(resetAppointmentDockTimeZoneConfigurationForm());
    };

    const titlePopUp = useMemo(() => {
        if (showPopUp === PopUpCodes.CREATE) {
            return t("CREATE");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            return t("UPDATE");
        }
        return "";
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return !form.appointmentTypeId || !form.merchandiseTypeId;
    }, [form]);
    return (
        <>
            <PopUpForm
                content={<MasterBoardHistoryAppointmentFringeForm />}
                title={titlePopUp}
                isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE}
                onCancel={onClose}
                onSave={onSave}
                onDimissed={onDimissed}
                height={"30vh"}
                width={"35vw"}
                isDisabledButton={isDisabledButton}
                isLoading={loading}
                leftButtonContent={<RequiredFieldsMessage />}
            />

            <PopUpConfirmation
                showPopUp={showPopUp === PopUpCodes.DELETE}
                onCancel={onClose}
                onAccept={onSave}
                onDimissed={onDimissed}
                type={PopUpConfirmationType.DELETE}
                disabledActions={loading}
                height={"auto"}
                width={"auto"}
            ></PopUpConfirmation>
        </>
    );
};
