import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../models";
import { emptyManagementUserSignupFilter, IManagementUserSignupFilter } from "../../../../models/management/userSignup/IManagementUserSignupFilter";

interface IManagementUserSignupFilterState {
    filter: IManagementUserSignupFilter;
}

const initialState: IManagementUserSignupFilterState = {
    filter: emptyManagementUserSignupFilter(),
};

export const managementUserFilterSlice = createSlice({
    name: "managementUserSignupFilter",
    initialState,
    reducers: {
        resetFilter: () => initialState,
        updateFilterProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.filter = {
                ...state.filter,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { resetFilter, updateFilterProperty } =  managementUserFilterSlice.actions;

export default managementUserFilterSlice.reducer;
