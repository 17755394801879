import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterOrganismItem } from "../../../../../models/resources/master/IOrganism";

export interface IMasterOrganismListState {
    list?: IMasterOrganismItem[];
    loading: boolean;
}

const initialState: IMasterOrganismListState = {
    loading: false,
};

export const masterOrganismList = createSlice({
    name: "masterOrganismList",
    initialState,
    reducers: {
        resetMasterOrganismList: () => initialState,
        setLoadingMasterOrganismList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterOrganismList: (state, action: PayloadAction<IMasterOrganismItem[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterOrganismList, setLoadingMasterOrganismList, setMasterOrganismList } = masterOrganismList.actions;

export default masterOrganismList.reducer;
