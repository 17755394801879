import { ManagementMenuSettingsApi } from "../../../../api/management/menuSettings";
import { throwErrorToastrAsync } from "../../../../utils";
import { AppThunk, store } from "../../../store";
import { setLoadingMenuSettingsByRoleForm, setMenuSettingsByRoleForm } from "../../../reducers/management/menuSettingsByRole/form";
import { IMenuSetting } from "../../../../models/management/menuSetting/IMenySetting";
import { fetchUserModules } from "../../user/module/module";

const menuSettingsApi = new ManagementMenuSettingsApi();

export const fetchManagementMenuSettingsListAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMenuSettingsByRoleForm(true));
        const form = store.getState().managementMenuSettingsForm;
        let response = await menuSettingsApi.getMenuSettingsListAsync(form);

        response = response.slice().sort((a: IMenuSetting, b: IMenuSetting) => {
            if (a.features.length > b.features.length) {
                return 1;
            } else if (a.features.length < b.features.length) {
                return -1;
            } else {
                return 0;
            }
        });

        dispatch(setMenuSettingsByRoleForm(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMenuSettingsByRoleForm(false));
    }
};

export const postAndPutManagementMenuSettingsAction = (): any => {
    return async (dispatch: any) => {
        try {
            const form = store.getState().managementMenuSettingsForm;

            dispatch(setLoadingMenuSettingsByRoleForm(true));
            if (form) {
                await menuSettingsApi.updateMenuSettingsAsync(form);
            }
            dispatch(fetchManagementMenuSettingsListAction());
            dispatch(fetchUserModules());

            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMenuSettingsByRoleForm(false));
        }
    };
};
