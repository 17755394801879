import { ReactChild } from "react";
import { useTheme } from "react-jss";
import { RoleType } from "../../../../../common/enum";
import { IRequestLastActuation } from "../../../../../models";
import { useAppSelector } from "../../../../../redux/hooks";
import { RequestListColumnsStyle } from "../requestListColumnsStyle.jss";
import { ActuationTypes } from "../../../../../common/enum/request/ActuationTypes";

interface IRequesLastActuationProps {
    lastActuation?: IRequestLastActuation;
}

export const RequestLastActuation = (props: IRequesLastActuationProps): JSX.Element => {
    const role = useAppSelector((state) => state.role.roleData);
    const theme = useTheme();
    const styles = RequestListColumnsStyle({ theme });

    const lastActuationTypeCode: ReactChild = (
        <>
            <div
                className={
                    (RoleType.ORGANISM_ADMIN === role.id || RoleType.ORGANISM_INSPECTOR === role.id || RoleType.ORGANISM_TECHNICIAN === role.id) &&
                    (ActuationTypes.HALLWAY === props.lastActuation?.actuationTypeCode ||
                        ActuationTypes.DOWNLOAD_COMPLETE === props.lastActuation?.actuationTypeCode)
                        ? styles.highlight
                        : ""
                }
            >
                ({props.lastActuation?.actuationTypeCode})
            </div>
        </>
    );

    return (
        <>
            <div className={styles.lastActuation}>
                {props.lastActuation ? (
                    <>
                        {props.lastActuation?.organismCode} {lastActuationTypeCode}
                    </>
                ) : null}
            </div>
        </>
    );
};
