import { useEffect } from "react";
import { useSelector } from "react-redux";
import { routeFeatures } from "../../common/constants/routeFeatures";
import { RoleType } from "../../common/enum";
import { fetchViewsToIdRole } from "../../redux/actions/catalog/catalog";
import { fetchViewsAction } from "../../redux/actions/user/role/view";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setLoadingViewPermission } from "../../redux/reducers/user/role/view";
import { getUserRole } from "../../redux/selectors/user/role/role";

export const RoleViewPermissions = () => {
    const dispatch = useAppDispatch();

    const { isClaimsLoaded } = useAppSelector((state) => state.userClaims);
    const { isViewLoaded } = useAppSelector((state) => state.userRoleViews);
    const roleData = useSelector(getUserRole);
 
    useEffect(() => {
        if (isViewLoaded || !isClaimsLoaded) return;
        const isMAPADirector = roleData.id === RoleType.MAPA_DIRECTOR;
        if (isMAPADirector) {
            dispatch(fetchViewsToIdRole(routeFeatures.REQUEST, RoleType.ORGANISM_ADMIN, isMAPADirector));
            dispatch(setLoadingViewPermission(true));
            return;
        }
        dispatch(fetchViewsAction());
    }, [dispatch, isClaimsLoaded, isViewLoaded]);

 
    return <></>;
};
