import { createUseStyles } from "react-jss";

export const LinkStyled = createUseStyles((theme: any) => ({
    link:(props:any)=>( {
        fontWeight: theme.fonts.weight.normal,
        textDecoration:props.border? "": "none",
        "&:hover": {
            filter: "contrast(0.5)",
        },
        "& i": {
            verticalAlign: "middle",
            paddingRight: theme.paddings.link.icon,
        },
    }),
    primary: {
        color: theme.colors.navLinks.primary.color,
        "&:visited": {
            color: theme.colors.navLinks.primary.visitedColor,
        },
    },
    secondary: {
        color: theme.colors.navLinks.secondary.color,
        "&:visited": {
            color: theme.colors.navLinks.secondary.visitedColor,
        },
    },
    success: {
        color: theme.colors.navLinks.success.color,
        "&:visited": {
            color: theme.colors.navLinks.success.visitedColor,
        },
    },
    danger: {
        color: theme.colors.navLinks.danger.color,
        "&:visited": {
            color: theme.colors.navLinks.danger.visitedColor,
        },
    },
    warning: {
        color: theme.colors.navLinks.warning.color,
        "&:visited": {
            color: theme.colors.navLinks.warning.visitedColor,
        },
    },
    info: {
        color: theme.colors.navLinks.info.color,
        "&:visited": {
            color: theme.colors.navLinks.info.visitedColor,
        },
    },
    bold: {
        fontWeight: theme.fonts.weight.bold,
    },
}));
