import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { generatePath, useNavigate } from "react-router";
import { NameIcons } from "../../../../../assets/icons";
import { routeUrls } from "../../../../../common/constants";
import { FormatDate } from "../../../../../common/enum/dateTime/FormatDate";

import { FinishedRequest } from "../../../../../models/vlcPort/IFinishedRequest";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
    setVlcportRequestFinishedDetail,
    setVlcportRequestFinishedPopUpShow,
    setVlcportRequestFinishedsendedValenciaportMessage,
    updateSortColumn,
} from "../../../../../redux/reducers/vlcPort/requestFinished/list";
import { store } from "../../../../../redux/store";
import { widths } from "../../../../../styles/romeu/widths";
import { compileNameOfProperty, convertToLocal } from "../../../../../utils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../common/customIcons/customIcons";
import { Separator } from "../../../../common/separator/separator";
import { VlcPortFinishedRequestColumnStyle } from "./columnStyle.jss";
import { PopUpCodes } from "../../../../../common/enum/PopUpCodes";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const VlcPortFinishedRequestColumn = (): IColumn[] => {
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.FINISHED_REQUESTS" });
    const theme = useTheme();
    const styles = VlcPortFinishedRequestColumnStyle({ theme });
    const dispatch = useAppDispatch();
    const sort = useAppSelector((state) => state.vlcPortFinishedRequestList.sort);

    const navigate = useNavigate();

    const searchRequest = (id: string) => {
        let path = generatePath(routeUrls.EDIT_FINISHED_REQUESTS, { id });
        navigate(path);
    };

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const actualSort = store.getState().vlcPortFinishedRequestList.sort;
            let payload = onUpdateSortedColumnUtil(column, actualSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<FinishedRequest>("date"),
            minWidth: 90,
            maxWidth: 100,
            name: t("DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<FinishedRequest>("date")}
                    title={t("DATE")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<FinishedRequest>("date"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: FinishedRequest) => <span>{convertToLocal(item.date, FormatDate.DATE)}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<FinishedRequest>("transportUnitNumber"),
            minWidth: 150,
            maxWidth: 160,
            name: t("UTI_TRANSPORT_NUMBER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<FinishedRequest>("transportUnitNumber")}
                    title={t("UTI_TRANSPORT_NUMBER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<FinishedRequest>("transportUnitNumber"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: FinishedRequest) => <span>{item.transportUnitNumber}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<FinishedRequest>("description"),
            minWidth: 200,
            maxWidth: 300,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<FinishedRequest>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<FinishedRequest>("description"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: FinishedRequest) => <span>{item.description ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<FinishedRequest>("customerName"),
            minWidth: 250,
            maxWidth: 350,
            name: t("CUSTOMER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<FinishedRequest>("customerName")}
                    title={t("CUSTOMER")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<FinishedRequest>("customerName"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: FinishedRequest) => <span>{item.customerName}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<FinishedRequest>("lastModifiedOn"),
            minWidth: 170,
            maxWidth: 180,
            name: t("LASTMODIFIEDON"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<FinishedRequest>("lastModifiedOn")}
                    title={t("LASTMODIFIEDON")}
                    styles={styles}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<FinishedRequest>("lastModifiedOn"))}
                    sort={sort}
                    isSortable={true}
                />
            ),
            onRender: (item: FinishedRequest) => <span>{convertToLocal(item.lastModifiedOn, FormatDate.DATE_HOUR)}</span>,
            isResizable: true,
        },

        {
            key: "",
            minWidth: 40,
            maxWidth: 70,
            name: "",
            onRender: (item: FinishedRequest) => (
                <span className={styles.actions}>
                    {(item.details || item.sendedValenciaportMessage) && (
                        <>
                            <CustomIcons
                                iconName={NameIcons.EYEICON}
                                onClick={() => {
                                    dispatch(setVlcportRequestFinishedDetail(item.details ?? ""));
                                    dispatch(setVlcportRequestFinishedsendedValenciaportMessage(item.sendedValenciaportMessage ?? ""));
                                    dispatch(setVlcportRequestFinishedPopUpShow(PopUpCodes.CREATE));
                                }}
                                size={widths.management.icons}
                                tooltip={t("DETAILS")}
                            />
                            <Separator size="auto" />
                        </>
                    )}

                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            searchRequest(String(item.id) ?? "");
                        }}
                        size={widths.management.icons}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
