import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConfigOrganismCertificateType } from "../../../../models/resources/organismCertificateType/IConfigOrganismCertificateType";
import { emptySortState, ISortState } from "../../../../models/common/ISortState";

export interface IOrganismCertificateTypeList {
    list?: IConfigOrganismCertificateType[];
    loading: boolean;
    sort: ISortState;
}

const initialState: IOrganismCertificateTypeList = {
    loading: false,
    sort: emptySortState(),
};

export const organismCertificateTypeListReducer = createSlice({
    name: "organismCertificateTypeListReducer",
    initialState,
    reducers: {
        resetOrganismCertificateTypeList: () => initialState,
        setLoadingOrganismCertificateTypeList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setOrganismCertificateTypeList: (state, action: PayloadAction<IConfigOrganismCertificateType[]>) => {
            state.list = action.payload;
        },
        updateSortColumn: (state, action: PayloadAction<ISortState>) => {
            state.sort = action.payload;
        },
    },
});

export const { resetOrganismCertificateTypeList,updateSortColumn, setLoadingOrganismCertificateTypeList, setOrganismCertificateTypeList } =
    organismCertificateTypeListReducer.actions;

export default organismCertificateTypeListReducer.reducer;
