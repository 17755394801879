import { useCallback, useMemo } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IGenericPayload, IUserProfile } from "../../../../../models";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateUserProfileProperty } from "../../../../../redux/reducers/user/profile/userProfile";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { Avatar } from "../../../../common/avatar/avatar";
import { FormField } from "../../../../common/fields/fields";
import { UserProfileUserFormStyle } from "./formStyle.jss";
import { RoleType } from "../../../../../common/enum";

interface IUserProfileDataFormProps {
    userRegister: UseFormRegister<IUserProfile>;
    userErrors: FieldErrors<IUserProfile>;
    userData: IUserProfile;
}

export const UserProfileDataForm: React.FC<IUserProfileDataFormProps> = ({ userRegister, userErrors, userData }) => {
    const styles = UserProfileUserFormStyle();
    const { t } = useTranslation("common");
    const dispatch = useAppDispatch();
    const role = useAppSelector((state) => state.role.roleData);

    const handleAvatarClick = (file: File | undefined) => {
        const isFile = file instanceof File;
        const payload: IGenericPayload = {
            name: compileNameOfProperty<IUserProfile>("image"),
            value: isFile ? URL.createObjectURL(file) : "",
        };

        dispatch(updateUserProfileProperty(payload));
        setImageHasChanged();
    };

    const setImageHasChanged = () => {
        if (userData.hasImageChanged) {
            return;
        }

        const payload: IGenericPayload = {
            name: compileNameOfProperty<IUserProfile>("hasImageChanged"),
            value: true,
        };

        dispatch(updateUserProfileProperty(payload));
    };

    const onInputChange = useCallback(
        (event) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateUserProfileProperty(payload));
        },
        [dispatch],
    );
    const surnameRequired = useMemo(() => {
        return role.id === RoleType.CUSTOMER_USER ||
            role.id === RoleType.CUSTOMER_ADMIN ||
            role.id === RoleType.CARRIER_ADMIN ||
            role.id === RoleType.CARRIER_USER ||
            role.id === RoleType.ORGANISM_TECHNICIAN ||
            role.id === RoleType.ORGANISM_INSPECTOR ||
            role.id === RoleType.ORGANISM_ADMIN ||
            role.id === RoleType.SUPER_ADMIN
            ? true
            : false;
    }, [role.id]);
    return (
        <>
            <div className={styles.content}>
                <div className={styles.infoContainer}>
                    <div className={styles.section}>
                        <div className={styles.item}>
                            <div className={styles.avatar}>
                                <Avatar
                                    src={userData.image}
                                    size="145px"
                                    onChangeAvatar={handleAvatarClick}
                                    edit={true}
                                />
                            </div>
                        </div>
                        <div className={styles.form}>
                            <FormField
                                type="text"
                                value={userData.name}
                                error={userErrors.name}
                                isRequired={true}
                                label={t("USER.PROFILE.NAME")}
                                {...userRegister(compileNameOfProperty<IUserProfile>("name"), {
                                    onChange: onInputChange,
                                    value: userData.name,
                                })}
                            />

                            {role.id !== RoleType.ORGANISM_ADMIN && (
                                <FormField
                                    type="text"
                                    value={userData.surName}
                                    error={userErrors.surName}
                                    label={t("USER.PROFILE.SURNAME")}
                                    isRequired={surnameRequired}
                                    {...userRegister(compileNameOfProperty<IUserProfile>("surName"), {
                                        onChange: onInputChange,
                                        value: userData.surName,
                                        required: surnameRequired,
                                    })}
                                />
                            )}
                            {role.id !== RoleType.ORGANISM_ADMIN && (
                                <FormField
                                    type="text"
                                    value={userData.lastName}
                                    error={userErrors.lastName}
                                    label={t("USER.PROFILE.LASTNAME")}
                                    {...userRegister(compileNameOfProperty<IUserProfile>("lastName"), {
                                        onChange: onInputChange,
                                        value: userData.lastName,
                                    })}
                                />
                            )}
                            <FormField
                                type="email"
                                value={userData.email}
                                disabled={true}
                                error={userErrors.email}
                                label={t("USER.PROFILE.EMAIL")}
                                {...userRegister(compileNameOfProperty<IUserProfile>("email"), {
                                    onChange: onInputChange,
                                    value: userData.email,
                                })}
                            />
                            <FormField
                                type="text"
                                value={userData.phoneNumber}
                                error={userErrors.phoneNumber}
                                isRequired={
                                    role.id === RoleType.CUSTOMER_USER ||
                                    role.id === RoleType.CUSTOMER_ADMIN ||
                                    role.id === RoleType.CARRIER_ADMIN ||
                                    role.id === RoleType.CARRIER_USER
                                }
                                label={t("USER.PROFILE.PHONE_NUMBER")}
                                {...userRegister(compileNameOfProperty<IUserProfile>("phoneNumber"), {
                                    onChange: onInputChange,
                                    value: userData.phoneNumber,
                                    required: role.id === RoleType.CUSTOMER_ADMIN || role.id === RoleType.CARRIER_ADMIN,
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
