import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterActuationGroup } from "../../../../../models/resources/master/IActuationGroup";

export interface IMasterActuationGroupListState {
    list?: IMasterActuationGroup[];
    loading: boolean;
}

const initialState: IMasterActuationGroupListState = {
   
    loading: false,
};

export const masterActuationGroupList = createSlice({
    name: "masterActuationGroupList",
    initialState,
    reducers: {
        resetMasterActuationGroupList: () => initialState,
        setLoadingMasterActuationGroupList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterActuationGroupList: (state, action: PayloadAction<IMasterActuationGroup[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterActuationGroupList, setLoadingMasterActuationGroupList, setMasterActuationGroupList } = masterActuationGroupList.actions;

export default masterActuationGroupList.reducer;
