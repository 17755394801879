import { createUseStyles } from "react-jss";

export const UserProfileUserFormStyle = createUseStyles((theme: any) => ({
    content: {
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
        gap:15
    },
    row: {
        display: "flex",
        rowGap: "30px",
        flexFlow: "row wrap",
        columnGap: "24px",
        flexDirection: "row",
        width:"100%"
    },

    upload: {
        display: "flex",
        rowGap: "30px",
        flexFlow: "row wrap",
        columnGap: "24px",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        marginTop: 25,
    },
    input: {
        width: "25vh",
    },
}));
