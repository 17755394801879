import { stringDefaultValue, throwErrorToastrAsync } from "../../../../utils";
import { AppThunk, store } from "../../../store";
import { IManagementRepresentationFilter } from "../../../../models/management/representation/IManagementRepresentationFilter";
import { fetchManagementRepresentationList, setLoadingManagementRepresentationList, setPage } from "../../../reducers/management/representation/list";
import {
    IManagementRepresentationForm,
    setLoadingManagementRepresentation,
    setManagementRepresentation,
} from "../../../reducers/management/representation/form";
import { ManagementRepresentationApi } from "../../../../api/management/representation";
import { convertToLocal } from "../../../../utils/dateUtils";
import { FormatDate } from "../../../../common/enum/dateTime/FormatDate";

const managementRepresentationApi = new ManagementRepresentationApi();

const getManagementRepresentationFilter = (): IManagementRepresentationFilter => {
    const { filter } = store.getState().managementRepresentationFilter;

    return {
        ...filter,
        userId: filter.userId !== stringDefaultValue.key ? filter.userId : "",
        representativeUserId: filter.representativeUserId !== stringDefaultValue.key ? filter.representativeUserId : "",
    };
};

export const fetchManagementRepresentationListAction =
    (resetPagination: boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementRepresentationList(true));
            const filter = getManagementRepresentationFilter();
            const response = await managementRepresentationApi.getRepresentationListAsync(filter);
            dispatch(fetchManagementRepresentationList(response));
            if (resetPagination) {
                dispatch(setPage(1));
            }
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementRepresentationList(false));
        }
    };

export const setPageAction =
    (page: number = 1): AppThunk =>
    async (dispatch) => {
        dispatch(setPage(page));
    };

export const updateManagementRepresentationAction = (managementProps: IManagementRepresentationForm): any => {
    return async (dispatch: any) => {
        try {
            dispatch(setLoadingManagementRepresentation(true));
            const payload = {
                ...managementProps,
                endDate: new Date(convertToLocal(managementProps.endDate, FormatDate.FULL)),
                startDate: new Date(convertToLocal(managementProps.startDate, FormatDate.FULL)),
            };
            await managementRepresentationApi.updateRepresentationAsync(payload);
            dispatch(fetchManagementRepresentationListAction(false));
            return true;
        } catch (error) {
            throwErrorToastrAsync(error);
            dispatch(setLoadingManagementRepresentation(false));
            return false;
        }
    };
};

export const getMananagementRepresentationAction =
    (id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingManagementRepresentation(true));
            const response = await managementRepresentationApi.getRepresentationAsync(id);
            response.startDate = response.startDate ? new Date(convertToLocal(response.startDate, FormatDate.FULL)) : undefined;
            response.endDate = response.endDate ? new Date(convertToLocal(response.endDate, FormatDate.FULL)) : undefined;
            dispatch(setManagementRepresentation(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingManagementRepresentation(false));
        }
    };
