import { IContextualMenuItem } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ColorType } from "../../../common/enum/ColorType";
import { PopUpCodes } from "../../../common/enum/PopUpCodes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppointmentImportPopUp } from "../../../redux/reducers/appointment/appoitmentImport/form";
import { fonts } from "../../../styles/romeu/fonts";
import { Alert } from "../../common/alert/alert";
import { ButtonContextualMenu } from "../../common/contextualMenu/contextualMenu";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { AppointmentImportContainerStyle } from "./containerStyle.jss";
import { AppointmentImportFormController } from "./controller/controller";
import { AppointmentImportList } from "./list/list";

export enum ImportRequest {
    DOCUMENT = "importDocument",
    CODE = "importText",
}

export const AppointmentImportContainer = () => {
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT.IMPORT" });
    const theme = useTheme();
    const style = AppointmentImportContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const [codeComponent, setCodeComponent] = useState<string>("");
    const { data } = useAppSelector((state) => state.appointmentImportList);

    const menuItems: IContextualMenuItem[] = [
        {
            key: ImportRequest.DOCUMENT,
            onClick: () => {
                dispatch(setAppointmentImportPopUp(PopUpCodes.CREATE));
                setCodeComponent(ImportRequest.DOCUMENT);
            },
            text: t("IMPORT_FILE"),
        },
        {
            key: ImportRequest.CODE,
            onClick: () => {
                dispatch(setAppointmentImportPopUp(PopUpCodes.CREATE));
                setCodeComponent(ImportRequest.CODE);
            },
            text: t("IMPORT_TXT"),
        },
    ];
    return (
        <div className={style.container}>
            <NavHeaderComponent
                title={t("TITLE")}
                action={
                    <ButtonContextualMenu
                        items={menuItems}
                        buttonTitle={t("IMPORT")}
                    />
                }
            />
            <AppointmentImportFormController
                codeComponent={codeComponent}
                setCodeComponent={setCodeComponent}
            />

            {data && data?.resultsError > 0 && <AppointmentImportList />}

            {data && data?.resultsError === 0 && (
                <Alert
                    colorType={ColorType.success}
                    fontSize={fonts.sizes.xl}
                    data={t("MESSAGE")}
                />
            )}
        </div>
    );
};
