import { createUseStyles } from "react-jss";

export const CheckboxSelectorStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.gaps.sizes.sm,
        marginTop: 8
    },
}));
