import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IRequestFilter } from "../../../../../../../models";
import { IConfigFilterRequest } from "../../../../../../../models/requests/IConfigFilterRequest";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { BooleaMultiSelector } from "../../../../../../common/selectors/multiSelectors/booleanMultiSelector/booleanMultiSelector";
import { MultipleDockSelector } from "../../../../../common/selectors/docks/multipledocks";
import { MultipleDocumentTypesSelector } from "../../../../../common/selectors/documentTypes/multipleDocumentTypes";
import { MultipleRequestStateSelector } from "../../../../../../common/selectors/controllerSelectors/requestStates/multipleRequestState";
import { MultipleTurnSelector } from "../../../../../common/selectors/turn/multipleTurn";
import { RequestFilterPivotStyle } from "../filterStyle.jss";
import { MultipleAppointmentTimeZoneSelector } from "../../../../../common/selectors/appointmentTimeZones/multipleAppointmentTimeZones";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    schema: IConfigFilterRequest;
}

export const RequestInspectionPivotFilter: React.FC<IProps> = ({ onMultipleNumberKeysChange, onNullableBooleanChange, schema }) => {
    const theme = useTheme();
    const styles = RequestFilterPivotStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });
    return (
        <div className={`${styles.containerPivot}`}>
            {schema.turnIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleTurnSelector
                        selectedKeys={filter.turnId}
                        propertyName={compileNameOfProperty<IRequestFilter>("turnId")}
                        title={t("TURN")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.stateIdVisible && (
                <div className={styles.filterItem2}>
                    <MultipleRequestStateSelector
                        selectedKeys={filter.stateId}
                        propertyName={compileNameOfProperty<IRequestFilter>("stateId")}
                        title={t("REQUEST_STATE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.dockIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleDockSelector
                        selectedKeys={filter.dockId}
                        propertyName={compileNameOfProperty<IRequestFilter>("dockId")}
                        title={t("DOCK")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.documentTypeIdVisible && (
                <div className={styles.filterItem}>
                    <MultipleDocumentTypesSelector
                        selectedKeys={filter.documentTypeId}
                        propertyName={compileNameOfProperty<IRequestFilter>("documentTypeId")}
                        title={t("DOCUMENT_TYPE")}
                        onChange={onMultipleNumberKeysChange}
                        autocomplete={false}
                    />
                </div>
            )}
            {schema.summaryActiveVisible && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.summaryActive}
                        propertyName={compileNameOfProperty<IRequestFilter>("summaryActive")}
                        title={t("SUMMARY_ACTIVE")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.parkingAuthorizationVisible && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.parkingAuthorization}
                        propertyName={compileNameOfProperty<IRequestFilter>("parkingAuthorization")}
                        title={t("PARKINGAUTHORIZATION")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}
            {schema.appVisible && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.app}
                        propertyName={compileNameOfProperty<IRequestFilter>("app")}
                        title={t("APP")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}

            {schema.appointmentTimeZoneVisible && (
                <div className={styles.filterItem}>
                    <MultipleAppointmentTimeZoneSelector
                        onChange={onMultipleNumberKeysChange}
                        selectedKeys={filter.appointmentTimeZoneId}
                        autocomplete={false}
                        propertyName={compileNameOfProperty<IRequestFilter>("appointmentTimeZoneId")}
                        title={t("APPOINTMENT_TIME_ZONE")}
                    />
                </div>
            )}

                {schema.appointmentAssigned && (
                <div className={styles.filterItem}>
                    <BooleaMultiSelector
                        selectedKey={filter.appointmentAssigned}
                        propertyName={compileNameOfProperty<IRequestFilter>("appointmentAssigned")}
                        title={t("APPOINMENT")}
                        onChange={onNullableBooleanChange}
                    />
                </div>
            )}

        </div>
    );
};
