import React from "react";
import { useTheme } from "react-jss";
import { IRequestPendingAssignmentListItem } from "../../../models/assignment/IAssignment";
import { CustomDetailsList } from "../../common/customDetailsList/customDetailsList";
import { RequestPendingAssignmentsColumns } from "./columns/columns";
import { AssignmentListStyle } from "./listStyle.jss";

interface IAssinmentListProps {
    code: string;
    list: IRequestPendingAssignmentListItem[];
}

export const RequestpendingAssinmentList: React.FC<IAssinmentListProps> = ({ code, list }) => {
    const theme = useTheme();
    const style = AssignmentListStyle({ theme });
    const onRenderColumns = () => {
        return RequestPendingAssignmentsColumns();
    };
    return (
        <div className={style.containerList}>
            <div className={style.headerTable}>
                <span className="code">{code}</span>

            </div>

            <CustomDetailsList
                data={list ?? []}
                renderColumns={onRenderColumns}
                height={"calc(100vh - 19.60rem)"}
            />
        </div>
    );
};
