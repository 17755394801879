import { CatalogAPI } from "../../../../../api";
import { throwErrorToastrAsync } from "../../../../../utils";
import { setLoadingMasterActuationGroupForm, setMasterActuationGroupForm } from "../../../../reducers/resources/master/actuationGroup/form";
import { setLoadingMasterActuationGroupList, setMasterActuationGroupList } from "../../../../reducers/resources/master/actuationGroup/list";
import { AppThunk, store } from "../../../../store";

const catalogApi = new CatalogAPI();

export const fetchMasterActuationGroupList = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingMasterActuationGroupList(true));
        const response = await catalogApi.getActuationGroupList();
        dispatch(setMasterActuationGroupList(response));
    } catch (error) {
        throwErrorToastrAsync(error);
    } finally {
        dispatch(setLoadingMasterActuationGroupList(false));
    }
};

export const fetchMasterActuationGroup =
    (id: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterActuationGroupForm(true));
            const response = await catalogApi.getActuationGroup(id);
            dispatch(setMasterActuationGroupForm(response));
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterActuationGroupForm(false));
        }
    };

export const postAndPutMasterActuationgroup = (): any => {
    return async (dispatch: any) => {
        try {
            const { form } = store.getState().masterActuationGroupForm;
            dispatch(setLoadingMasterActuationGroupForm(true));
            if (form.id) {
                await catalogApi.putActuationGroup(form);
            } else {
                await catalogApi.postActuationGroup(form);
            }
            dispatch(fetchMasterActuationGroupList());

            return true;
        } catch (error) {
            dispatch(setLoadingMasterActuationGroupForm(false));
            throwErrorToastrAsync(error);
            return false;
        } 
    };
};

export const patchSortActuationGroupsList =
    (tableName: string, id: number, sortType: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoadingMasterActuationGroupList(true));
            await catalogApi.managementSort(tableName, id, sortType);
            dispatch(fetchMasterActuationGroupList());
        } catch (error) {
            throwErrorToastrAsync(error);
        } finally {
            dispatch(setLoadingMasterActuationGroupList(false));
        }
    };
