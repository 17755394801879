import { createUseStyles } from "react-jss";

export const SpinnerFullStyle = createUseStyles((theme: any) => ({
    preloader: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0.3,
        backgroundColor: theme.colors.spinner.background,
        zIndex: 9999999,
    },
    message: {
        color: theme.colors.spinner.title,
        display: "block",
        position: "relative",
        top: "50%",
        transform: "translate(0, 50%)",
        textAlign: "center",
    },
    loader: {
        display: "block",
        position: "relative",
        left: "50%",
        top: "50%",
        width: 150,
        height: 150,
        margin: "-75px 0 0 -75px",
        borderRadius: "50%",
        border: "3px solid transparent",
        borderTopColor: theme.colors.spinner.main,
        animation: "$rotation 2s linear infinite",
        WebkitAnimation: "$rotation 2s linear infinite",
        "&:before": {
            content: '""',
            position: "absolute",
            top: 5,
            left: 5,
            right: 5,
            bottom: 5,
            borderRadius: "50%",
            border: "3px solid transparent",
            borderTopColor: theme.colors.spinner.before,
            animation: "$rotation 2.5s linear infinite",
            WebkitAnimation: "$rotation 2.5s linear infinite",
        },
        "&:after": {
            content: '""',
            position: "absolute",
            top: 15,
            left: 15,
            right: 15,
            bottom: 15,
            borderRadius: "50%",
            border: "3px solid transparent",
            borderTopColor: theme.colors.spinner.after,
            animation: "$rotation 1.5s linear infinite",
            WebkitAnimation: "$rotation 1.5s linear infinite",
        },
    },
    "@keyframes rotation": {
        from: { WebkitTransform: "rotate(0deg)", msTransform: "rotate(0deg)", animation: "rotate(0deg)" },
        to: { WebkitTransform: "rotate(360deg)", msTransform: "rotate(360deg)", animation: "rotate(360deg)" },
    },
}));
