import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatisticsInspectionDetailedState } from "../../../../models/statistics/inspection/IDetailed";

interface IInitialState {
    data: IStatisticsInspectionDetailedState;
}

const initialSateData = (): IStatisticsInspectionDetailedState => {
    return {
        organisms: [],
        inspectionsStatistics: {
            inspectionStatistics: [],
            organismRequests: [],
            totalAverageEntryTime: "",
            totalAverageExitTime: "",
            totalAverageInspectionTime: "",
            totalAverageTotalTime: "",
            totalIncidences: 0,
            totalRequests: 0,
            totalRequestsPositionedWithInvoiceConcepts: 0,
        },
        loading: false,
    };
};

const initialState: IInitialState = {
    data: initialSateData(),
};

export const statiticsInspectionDetailedListSlice = createSlice({
    name: "statiticsInspectionDetailedList",
    initialState,
    reducers: {
        resetStatisticsInspectionDetailedData: () => initialState,
        setStatisticsInspectionDetailedData: (state, action: PayloadAction<IStatisticsInspectionDetailedState>) => {
            state.data = action.payload;
        },
        setStatisticsInspectionDetailedLoading: (state, action: PayloadAction<boolean>) => {
            state.data.loading = action.payload;
        },
    },
});

export const { resetStatisticsInspectionDetailedData, setStatisticsInspectionDetailedData, setStatisticsInspectionDetailedLoading } =
    statiticsInspectionDetailedListSlice.actions;

export default statiticsInspectionDetailedListSlice.reducer;
