import { useTheme } from "react-jss";
import { SpinnerStyle } from "./spinnerStyle.jss";

interface ISpinner {
    size?: number;
}

export const Spinner: React.FC<ISpinner> = ({ size }) => {
    const theme = useTheme();
    const styles = SpinnerStyle({ theme, size });

    return (
        <>
            <div className={styles.spinner}></div>
        </>
    );
};
