export const gaps = {
    sizes: {
        xs: 5,
        sm: 10,
        base: 15,
        md: 20,
        lg: 25,
        xl: 30,
        xxl: 40,
        xxxl: 50,
    },
    file: {
        columnGap: 8,
        rowGap: 8,
    },
    section: {
        rowGap: 15,
    },
    form: {
        default: 40,
        column:30, 
        row:30
    },
    buttons: {
        default: 25,
    },
    table: {
        buttonsItem: 15,
    },
    filter :{
        default:15, 
        container:24
    },
    actionButtonsFilter: 20,
};
