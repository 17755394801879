export enum RequestHistoryTimeLineActions {
    // Requests
    CREATE_REQUEST = "CreateRequest",
    UPDATE_REQUEST = "UpdateRequest",
    UPDATE_REQUEST_DATE = "UpdateRequestDate",
    UPDATE_STATE = "UpdateState",
    UPDATE_REQUEST_STATE_HISTORY = "UpdateRequestStateHistory",
    UPDATE_DOCK = "UpdateDock",
    UPDATE_REQUEST_CUSTOMER_NOTIFIED_ON = "UpdateRequestCustomerNotifiedOn",
    UPDATE_REQUEST_CUSTOMER = "UpdateRequestCustomer",
    PREPARED_CONFIRMED_REQUEST = "PreparedConfirmedRequest",
    PREPARED_CONFIRMED_REQUEST_ERROR = "PreparedConfirmedRequestError",
    DELETE_PREPARED_CONFIRMED_REQUEST = "DeletePreparedConfirmedRequest",
    DELETE_PREPARED_CONFIRMED_REQUEST_ERROR = "DeletePreparedConfirmedRequestError",

    // Inspections
    CREATE_INSPECTION = "CreateInspection",
    UPDATE_INSPECTION = "UpdateInspection",
    DELETE_INSPECTION = "DeleteInspection",
    ASSIGN_INSPECTOR = "AssignInspector",
    UNASSIGN_INSPECTOR = "UnassignInspector",
    CREATE_INSPECTION_CARD = "CreateInspectionCard",
    UPDATE_INSPECTION_CARD = "UpdateInspectionCard",
    VALIDATE_INSPECTION_CARD = "ValidateInspectionCard",
    UNVALIDATE_INSPECTION_CARD = "UnvalidateInspectionCard",

    // Actuations
    CREATE_ACTUATION = "CreateActuation",
    UPDATE_ACTUATION = "UpdateActuation",
    DELETE_ACTUATION = "DeleteActuation",
    CREATE_MULTIPLE_ACTUATION = "CreateMultipleActuation",

    // Documents & Photos
    CREATE_DOCUMENT = "CreateDocument",
    DELETE_DOCUMENT = "DeleteDocument",
    CREATE_PHOTO = "CreatePhoto",
    DELETE_PHOTO = "DeletePhoto",

    // Incidences
    CREATE_ORGANISM_INCIDENCE = "CreateOrganismIncidence",
    UPDATE_ORGANISM_INCIDENCE = "UpdateOrganismIncidence",
    DELETE_ORGANISM_INCIDENCE = "DeleteOrganismIncidence",
    CREATE_OPERATIONAL_INCIDENCE = "CreateOperationalIncidence",
    UPDATE_OPERATIONAL_INCIDENCE = "UpdateOperationalIncidence",
    DELETE_OPERATIONAL_INCIDENCE = "DeleteOperationalIncidence",

    // Invoicing
    CREATE_INVOICE_CONCEPT = "CreateInvoiceConcept",
    UPDATE_INVOICE_CONCEPT = "UpdateInvoiceConcept",
    DELETE_INVOICE_CONCEPT = "DeleteInvoiceConcept",
    INVOICE_CONCEPT_CREATED_SYNCHRONIZED = "InvoiceConceptCreatedSynchronized",
    INVOICE_CONCEPT_UPDATED_SYNCHRONIZED = "InvoiceConceptUpdatedSynchronized",
    INVOICE_CONCEPT_DELETED_SYNCHRONIZED = "InvoiceConceptDeletedSynchronized",
    INVOICE_CONCEPT_INVOICED_SYNCHRONIZED = "InvoiceConceptInvoicedSynchronized",

    // Emails
    NEW_REQUEST_EMAIL = "NewRequestEmail",
    NEW_REQUEST_WITH_PARKING_RESTRICTIONS_EMAIL = "NewRequestWithParkingRestrictionsEmail",
    NEW_REQUEST_VALENCIAPORT_EMAIL = "NewRequestValenciaportEmail",
    NEW_REQUEST_VUA_EMAIL = "NewRequestVuaEmail",
    NEW_REQUEST_FUMIGATED_CONTAINER_EMAIL = "NewRequestFumigatedContainerEmail",
    ENTRY_EMAIL = "EntryEmail",
    ENTRY_WITH_SAMPLING_EMAIL = "EntryWithSamplingEmail",
    DOCK_ASSIGNMENT_EMAIL = "DockAssignmentEmail",
    DOCK_START_EMAIL = "DockStartEmail",
    DOCK_END_EMAIL = "DockEndEmail",
    NEW_INCIDENCE_EMAIL = "NewIncidenceEmail",
    NEW_ACTUATION_EMAIL = "NewActuationEmail",
    NEW_ACTUATION_WITH_INSTRUCTIONS_EMAIL = "NewActuationWithInstructionsEmail",
    NEW_DOCUMENT_FUMIGATED_CONTAINER_EMAIL = "NewDocumentFumigatedContainerEmail",
    NEW_DOCUMENT_ORGANISM_ACTUATION_EMAIL = "NewDocumentOrganismActuationEmail",
    APPOINTMENT_CONFIRMATION_EMAIL = "AppointmentConfirmationEmail",
    APPOINTMENT_CONFIRMATION_SORT_EMAIL = "AppointmentConfirmationSortEmail",

    // Integrations
    VALENCIAPORT_INSPECTION_APPOINTMENT_INTEGRATION = "ValenciaportInspectionAppointmentIntegration",
    VALENCIAPORT_ACK_FROM_INSPECTION_APPOINTMENT_INTEGRATION = "ValenciaportAckFromInspectionAppointmentIntegration",
    VALENCIAPORT_INSPECTION_RESULT_INTEGRATION = "ValenciaportInspectionResultIntegration",
    VALENCIAPORT_ACK_FROM_INSPECTION_RESULT_INTEGRATION = "ValenciaportAckFromInspectionResultIntegration",

    // Appointments
    CREATE_APPOINTMENT = "CreateAppointment",
    UPDATE_APPOINTMENT = "UpdateAppointment",
    DELETE_APPOINTMENT = "DeleteAppointment",
    APPOINTMENT_INSPECTION_RECEIVED = "AppointmentInspectionReceived",

    // Parking Validation
    VALIDATION_PARKING_ACCEPTED = "ValidationParkingAccepted",
    VALIDATION_PARKING_REJECTED = "ValidationParkingRejected",
    PARKING_BARRIER_ALARM_EMAIL = "ParkingBarrierAlarmEmail",

    //APBA
    APBA_INTEGRATION_KO = "ApbaIntegrationKO",
    APBA_INTEGRATION_OK = "ApbaIntegrationOK",

    //SCSI
    SCSI_STATE_CHANGED_NOTIFICATION = "RequestStateChangedNotification",
    SCSI_STATE_CHANGED_NOTIFICATION_ERROR = "RequestStateChangedNotificationError",
}
