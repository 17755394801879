import { IDropdownOption } from "@fluentui/react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { Seal } from "./seal/seal";
import { OriginSealsStyle } from "./formStyle.jss";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { SealTypes } from "../../../../../../common/enum/SealType";
import { IGenericPayload } from "../../../../../../models";
import { ISeal } from "../../../../../../models/requests/IEditRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { addSealItem, resetSealProperty, updateSealProperty } from "../../../../../../redux/reducers/request/edit/editRequest";
import { getGenericPayloadFromEvent, compileNameOfProperty } from "../../../../../../utils";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { FormField } from "../../../../../common/fields/fields";
import { TitleH5 } from "../../../../../common/titles/titles";
import { SealTypeSelector } from "../../../../common/selectors/sealType/sealType";

interface IProps {
    isOriginalSeal?: boolean;
}

export const OriginSeals: React.FC<IProps> = ({ isOriginalSeal }) => {
    const theme = useTheme();
    const style = OriginSealsStyle({ theme, isOriginalSeal });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const list = useAppSelector((store) => store.editRequest.seals);
    const form = useAppSelector((store) => store.editRequest.seal); 
    
    
    const {
        register,
        control,
        formState: { isValid },
    } = useForm<ISeal>({ mode: "onChange" });
    
    
    const onAddItem = () => {
        if (form.code.length > 0) {
            let copySeal = form.typeId === SealTypes.NONE && isOriginalSeal ? { ...form, typeId: SealTypes.ORIGIN } : form;
            dispatch(addSealItem(copySeal));
            dispatch(resetSealProperty());
        }
    };

    const onSelectorChange = (option: IDropdownOption, name: string) => {
        const payload: IGenericPayload = {
            name,
            value: option.key,
        };
        dispatch(updateSealProperty(payload));
    };

    const onInputChange = useCallback(
        (event: any, _newValue?: any) => {
            const payload = getGenericPayloadFromEvent(event);
            dispatch(updateSealProperty(payload));
        },
        [dispatch],
    );

  

    return (
        <>
            <div className={style.upsertSealsContainer}>
                <div className={style.sealDataContainer}>
                    {!isOriginalSeal && (
                        <SealTypeSelector
                            onChange={onSelectorChange}
                            selectedKey={form.typeId}
                            propertyName={compileNameOfProperty<ISeal>("typeId")}
                            title={t("FIELDS.SEAL_TYPE")}
                            isRequired={true}
                            control={control}
                        />
                    )}

                    <FormField
                        type="text"
                        value={form.code}
                        label={t("FIELDS.NUMBER")}
                        isRequired={true}
                        {...register("code", {
                            onChange: onInputChange,
                            validate: () => !!form.code,
                        })}
                    />
                    <div className={style.buttonContainer}>
                        <ButtonPrimaryInverseWithBorder
                            disabled={!isValid || (form.code && !form.code.length) || (!form.typeId && !isOriginalSeal)}
                            handleClick={onAddItem}
                            title={t("BUTTONS.ADD")}
                        />
                    </div>
                </div>

                <div className={style.upsertSealData}>
                    {!isOriginalSeal && (
                        <div className={style.titleContainer}>
                            <TitleH5
                                title={t("TITLES.SEALS_SECTION")}
                                color={ColorType.primary}
                                bold={true}
                            />
                        </div>
                    )}

                    {list.length >= 1 ? (
                        list.map((sealInfo, index) => (
                            <div
                                className={style.column}
                                key={`${OriginSeals.name}-${index}`}
                            >
                                <Seal
                                    seal={sealInfo}
                                    key={`${OriginSeals.name}-${index}`}
                                    index={index}
                                    newSeal={true}
                                    isOriginalSeal={isOriginalSeal}
                                />
                            </div>
                        ))
                    ) : (
                        <div className={style.column}>{t("FIELDS.NOT_DATA")}</div>
                    )}
                </div>
            </div>
        </>
    );
};
