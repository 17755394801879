import { IDropdownOption } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fetchMerchandiseTypes } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapAllOptionIDescriptionToDropDownOptions, mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface IMerchandiseTypeProps extends IBaseSelectorProps {
    humanConsumption?: boolean;
    merchandiseOriginId?: number;
    filterOptions: boolean;
    resetSelectorAtRedux?: (propertyName: string) => void;
    allOption?: boolean;
}

export const MerchandiseTypeSelector: React.FC<IMerchandiseTypeProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    filterOptions,
    humanConsumption,
    merchandiseOriginId,
    resetSelectorAtRedux,
    disabled,
    isRequired,
    control,
    rules,
    allOption,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.merchandiseTypes);
    const dispatch = useAppDispatch();
    const [selectorkey, setSelectorKey] = useState<number>(0);
    const firstUpdate = useRef(true);

    useEffect(() => {
        data.length === 0 && dispatch(fetchMerchandiseTypes());
    }, [dispatch, data]);

    const filteredOptions = useMemo(() => {
        return filterOptions
            ? data.filter(
                  (opt) =>
                      (opt.merchandiseOriginId === merchandiseOriginId || !opt.merchandiseOriginId) &&
                      (opt.humanConsumption === humanConsumption || opt.humanConsumption === null),
              )
            : data;
    }, [data, merchandiseOriginId, humanConsumption, filterOptions]);

    const cleanStoreIfProceed = useCallback(() => {
        if (resetSelectorAtRedux) {
            resetSelectorAtRedux(propertyName);
            setSelectorKey((value) => value + 1);
        }
    }, [dispatch]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            if (!mapIDescriptionToDropDownOptions(filteredOptions).some((opt) => opt.key === selectedKey)) {
                cleanStoreIfProceed();
            }
        }
    }, [dispatch, filteredOptions]);

    const onSelectorChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            options={allOption ? mapAllOptionIDescriptionToDropDownOptions(filteredOptions) : mapIDescriptionToDropDownOptions(filteredOptions)}
            disabled={loading || disabled}
            label={title}
            name={propertyName}
            required={isRequired}
            control={control}
            rules={rules}
            key={selectorkey}
        />
    );
};
