import React, { useEffect } from "react";
import { EditRequestContainer } from "./container";
import { Toastr } from "../../../common/toast/toast";
import { routeUrls } from "../../../../common/constants";
import { store } from "../../../../redux/store";
import { setInvoiceCustomersOptions } from "../../../../redux/reducers/user/users/usersByRole";
import { useParams } from "react-router";
import { OrganismTypes } from "../../../../common/enum";
import { OrganismRequestRoute } from "../../../../services/organisms/organismRoute";

const resetEditRequestUtils = () => {
    Toastr().clear(`EditRequestContainer`);
    store.dispatch(setInvoiceCustomersOptions([]));
};

export class EditRequestController extends React.Component {
    componentWillUnmount(): void {
        resetEditRequestUtils();
    }

    render(): React.ReactNode {
        return <EditRequestContainer />;
    }
}

interface MatchParams {
    impersonatedOrganismId: string;
    id?: string;
}



export const EditRequestBackToOrganismController: React.FC = () => {
    const { impersonatedOrganismId, id } = useParams<{ impersonatedOrganismId: string; id?: string }>();
    useEffect(() => {
        return () => {
            resetEditRequestUtils();
        };
    }, []);

    const organismRoute = OrganismRequestRoute(impersonatedOrganismId as OrganismTypes);
    
    return <EditRequestContainer backRoute={organismRoute} />;
};

export class EditRequestBackToFinishedRequestController extends React.Component {
    componentWillUnmount(): void {
        resetEditRequestUtils();
    }

    render(): React.ReactNode {
        return <EditRequestContainer backRoute={routeUrls.FINISHED_REQUESTS} />;
    }
}
