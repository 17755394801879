import { createUseStyles } from "react-jss";

export const ResumeContainerStyle = createUseStyles((theme: any) => ({
    resumeContainer: {
        display: "flex",
        paddingTop: 36,
        height: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        fontFamily: theme.fonts.families.site,
    },
}));
