import { IDropdownOption } from "@fluentui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { ContentType } from "../../../../../common/enum/files/ContentType";
import { FileSize } from "../../../../../common/enum/files/FileSize";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { IAppointmentImportForm, updateFormProperty } from "../../../../../redux/reducers/appointment/appoitmentImport/form";
import { compileNameOfProperty, getGenericPayloadFromEvent } from "../../../../../utils";
import { validateXml } from "../../../../../utils/xmlUtils";
import { Selector } from "../../../../common/selectors/selector";
import { Textarea } from "../../../../common/textarea/textarea";
import { UploadFileDropzone } from "../../../../common/uploadFileDropzone/uploadFileDropzone";
import { ImportRequest } from "../../container";
import { AppointmentImportFormStyle } from "./formStyle.jss";

interface IVlcPortImportRequestFormProps {
    setDocument: (value: any) => any;
    codeComponent: string;
}

export enum ImportTypeCodeAppointment {
    INSPECTOR = "Inspector",
    INCIDENCE = "Incidence",
    APPOINTMENT = "Appointment",
}

export enum ImportTypeCodeAppointmentDescription {
    INSPECTOR = "ENVIO_INSP",
    INCIDENCE = "ENVIO_INC",
    APPOINTMENT = "ENVIO_PRE",
}

export const AppointmentImportForm: React.FC<IVlcPortImportRequestFormProps> = ({ setDocument, codeComponent }) => {
    const theme = useTheme();
    const style = AppointmentImportFormStyle({ theme });
    const dispatch = useAppDispatch();
    const [selectedKey, setSelectedKey] = useState<ImportTypeCodeAppointment>(ImportTypeCodeAppointment.APPOINTMENT);
    const onInputChange = (event: any) => {
        const payload = getGenericPayloadFromEvent(event);
        dispatch(updateFormProperty(payload));
    };
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT.IMPORT" });

    const options: IDropdownOption<any>[] = [
        { key: ImportTypeCodeAppointment.INSPECTOR, text: ImportTypeCodeAppointmentDescription.INSPECTOR },
        { key: ImportTypeCodeAppointment.INCIDENCE, text: ImportTypeCodeAppointmentDescription.INCIDENCE },
        { key: ImportTypeCodeAppointment.APPOINTMENT, text: ImportTypeCodeAppointmentDescription.APPOINTMENT },
    ];

    const onChange = (_event: React.FormEvent<HTMLDivElement>, option: any, name: string) => {
        setSelectedKey(option.key);
        dispatch(updateFormProperty({ name, value: option.key }));
    };

    const { form } = useAppSelector((state) => state.appointmentImportForm);

    return (
        <div className={style.container}>
            <div>
                <Selector
                    title={t("DOCUMENT_TYPE")}
                    options={options}
                    onChange={onChange}
                    selectedKey={selectedKey}
                    name={compileNameOfProperty<IAppointmentImportForm>("typeCode")}
                />
            </div>
            {codeComponent === ImportRequest.DOCUMENT && (
                <div className={style.file}>
                    <UploadFileDropzone
                        allowedExtensions={[ContentType.TXT]}
                        onFilesChange={setDocument}
                        disabled={false}
                        maxSize={FileSize._5MB}
                    />
                </div>
            )}

            {codeComponent === ImportRequest.CODE && (
                <div className={style.code}>
                    <Textarea
                        name={compileNameOfProperty<IAppointmentImportForm>("document")}
                        onChange={onInputChange}
                        value={form.document}
                        label={t("CODE_TXT")}
                    />
                </div>
            )}
        </div>
    );
};
