import { useCallback, useMemo, useState } from "react";
import { IFormOption } from "../../../../../../../models/common/IFormOptions";
import { IDockQueueRulePriority, IDockQueueRulePriorityValues } from "../../../../../../../models/management/dockQueueRule/IDockQueueRule";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { deleteRulePriorityValue, updateQueueRulePrioritiesValueForm } from "../../../../../../../redux/reducers/management/dockQueueRule/form";
import { CheckboxSelector } from "../../../../../../common/checkboxSelector/checkboxSelector";
import { RadioButtonGroup } from "../../../../../../common/radioButton/radioButtonGroup/radioButtonGroup";
import { Section } from "../section/section";

export const DockQueueRulePrioritiesContainer = () => {
    const { view } = useAppSelector((state) => state.managementDockQueueRuleConfiguration);
    const {
        form: { dockQueueRulePriorityValues },
    } = useAppSelector((state) => state.managementDockQueueRuleForm);
    const dispatch = useAppDispatch();

    const [active, setActive] = useState<{ [key: number]: boolean }>({});

    const isActive = (dockQueueRulePriorityId: number, value: number, ruleComponents: IDockQueueRulePriorityValues[]): boolean =>
        ruleComponents.some(
            (ruleComponent) =>
                ruleComponent.dockQueueRulePriorityId === dockQueueRulePriorityId &&
                ruleComponent.value === value &&
                (ruleComponent.deleted === false || ruleComponent.deleted === null),
        );

    const onRadioButtonChange = (option: any, dockQueueRulePriorityId: number) => {
        let payload = {
            dockQueueRulePriorityId,
            value: +option,
            isBoolValue: true,
        };
        dispatch(updateQueueRulePrioritiesValueForm(payload));
    };

    const onCheckboxChange = (dockQueueRulePriorityId: number, value: number) => {
        let payload = {
            dockQueueRulePriorityId,
            value: +value,
            isBoolValue: false,
        };
        dispatch(updateQueueRulePrioritiesValueForm(payload));
    };

    const getValueFromActive = useCallback((key: number): boolean | undefined => active[key], [active]);

    const printComponent = (item: IDockQueueRulePriority, index: number, options: IFormOption[]) => {
        let itemSelected = false;

        if (item.boolValue) return;

        if (item.boolValue) {
            itemSelected = dockQueueRulePriorityValues.find(
                (r) => r.dockQueueRulePriorityId === item.id && (r.deleted === false || r.deleted === null),
            )?.value;

            if (itemSelected === null) {
                onRadioButtonChange(false, item.id);
            }
        }

        return item.boolValue ? (
            <RadioButtonGroup
                name={item.description}
                onChange={(option: any, name?: any) => {
                    onRadioButtonChange(!!+option, item.id);
                }}
                selectedKey={itemSelected ? itemSelected.toString() : "0"}
            />
        ) : (
            <CheckboxSelector
                options={options}
                name={item.description}
                onChange={(name: string, value: any, indexOption, id) => {
                    if (id) {
                        onCheckboxChange(item.id, +id);
                    }
                }}
            />
        );
    };

    const onCheckboxChangeSection = useCallback(
        (componentValue: IDockQueueRulePriority, existContent: boolean, event?: any, checked = false) => {
            setActive((prevState) => ({
                ...prevState,
                [componentValue.id]: checked ?? existContent,
            }));

            if (checked && componentValue.boolValue) {
                onRadioButtonChange(true, componentValue.id);
            }

            if (!checked) {
                dispatch(deleteRulePriorityValue(componentValue.id));
            }
        },
        [active, dockQueueRulePriorityValues],
    );

    const form = useMemo(() => {
        return view?.dockQueueRulePriorities.map((rulePriorities: IDockQueueRulePriority, index) => {
            const options = rulePriorities?.dockQueueRulePriorityValues.map((value): IFormOption => {
                return {
                    key: value.id,
                    text: value.description,
                    active: isActive(rulePriorities.id, value.id, dockQueueRulePriorityValues),
                    disabled: false,
                };
            });

            const existContent = () => {
                return dockQueueRulePriorityValues.some(
                    (componentValues) =>
                        componentValues.dockQueueRulePriorityId === rulePriorities.id &&
                        (componentValues.deleted === false || componentValues.deleted === null),
                );
            };

            return (
                <Section
                    title={rulePriorities.description}
                    value={printComponent(rulePriorities, index, options)}
                    showBubble={existContent()}
                    onChangeDisabledController={(e: any, checked?: boolean) => {
                        onCheckboxChangeSection(rulePriorities, existContent(), e, checked);
                    }}
                    sectionActive={getValueFromActive(rulePriorities.id) ?? existContent()}
                    showDisabledController={true}
                />
            );
        });
    }, [view, active, dockQueueRulePriorityValues]);

    return <>{form}</>;
};
