import { Icon, IPivotItemProps, Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IGenericPayload } from "../../../../models";
import { IStatisticsFilter } from "../../../../models/statistics/IStatisticsFilters";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { StatisticsExpedientInfoPivotFilter } from "./expedient/expedient";
import { StatisticsContainerStyle } from "./pivot.jss";
import { StatisticsInspectionPivotFilter } from "./inspection/inspection";
import { RequestMerchandisePivotFilter } from "./merchandise/merchandise";
import { StatisticsUtiPivotFilter } from "./uti/statisticsUti";
import { ButtonPrimary, ButtonPrimaryInverseWithBorder } from "../../../common/buttons/buttons";
import { Separator } from "../../../common/separator/separator";
import { DefaultValues } from "../../../../common/enum/DefaultValues";
interface IStatisticsFiltersContainerProps {
    updateRequestFilterProperty: (value: IGenericPayload) => any;
    filter: IStatisticsFilter;
    onApplyFilters: () => any;
    onResetFilters: () => any;
}
export const StatisticsFiltersPivot: React.FC<IStatisticsFiltersContainerProps> = ({
    updateRequestFilterProperty,
    filter,
    onApplyFilters,
    onResetFilters,
}) => {
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.TITLES" });
    const { organismId } = useAppSelector((state) => state.userData.profile);
    const [selectedKey, setSelectedKey] = useState("0");
    const theme = useTheme();
    const style = StatisticsContainerStyle({ theme });
    const dispatch = useAppDispatch();
    const onMultipleNumberKeysChange = useCallback(
        (name: string, numberOptions: number[]) => {
            const payload: IGenericPayload = {
                name,
                value: numberOptions,
            };
            updateRequestFilterProperty(payload);
        },
        [dispatch],
    );

    const onMultipleStringKeysChange = useCallback(
        (name: string, stringOptions: string[]) => {
            const payload: IGenericPayload = {
                name,
                value: stringOptions,
            };
            updateRequestFilterProperty(payload);
        },
        [dispatch],
    );

    const onNullableBooleanChange = useCallback(
        (name: string, nullablebooleanValue: any) => {
            const payload: IGenericPayload = {
                name,
                value: nullablebooleanValue,
            };
            updateRequestFilterProperty(payload);
        },
        [dispatch],
    );

    const onInputChange = useCallback(
        (event: any, name: string) => {
            const payload: IGenericPayload = {
                name,
                value: event.target.value,
            };
            updateRequestFilterProperty(payload);
        },
        [dispatch],
    );

    const onInputNumberChange = useCallback(
        (value: number | undefined, name: string) => {
            const payload: IGenericPayload = {
                name,
                value,
            };
            updateRequestFilterProperty(payload);
        },
        [dispatch],
    );
    const showUtiBubble: boolean = useMemo(() => {
        return (
            !(filter.transportUnitIds.length === 1 && filter.transportUnitIds[0] === -1) ||
            !(filter.transportUnitSizeIds.length === 1 && filter.transportUnitSizeIds[0] === -1) ||
            !(filter.customerIds.length === 1 && filter.customerIds[0] === DefaultValues.DEFAULT_ALL_SELECTOR)
        );
    }, [filter]);

    const showMerchandiseBubble: boolean = useMemo(() => {
        return (
            !(filter.merchandiseTypeIds.length === 1 && filter.merchandiseTypeIds[0] === -1) ||
            !(filter.merchandiseOriginIds.length === 1 && filter.merchandiseOriginIds[0] === -1) ||
            !(filter.customsStateIds.length === 1 && filter.customsStateIds[0] === -1) ||
            filter.halalCertified !== undefined ||
            filter.dryCharge !== undefined ||
            filter.organicProduct !== undefined ||
            filter.fumigated !== undefined
        );
    }, [filter]);

    const showExpedientBubble: boolean = useMemo(() => {
        return (
            !(filter.certificateTypeIds.length === 1 && filter.certificateTypeIds[0] === -1) ||
            !(filter.inspectorIds.length === 1 && filter.inspectorIds[0] === DefaultValues.DEFAULT_ALL_SELECTOR) ||
            !(filter.finalDestinationTypeIds.length === 1 && filter.finalDestinationTypeIds[0] === -1) ||
            (filter.organismIds[0] !== -1 && !organismId)
        );
    }, [filter]);

    const showInspectionBubble: boolean = useMemo(() => {
        return !(filter.documentTypeIds.length === 1 && filter.documentTypeIds[0] === -1);
    }, [filter]);

    const bubbleRender = (link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        let showBubble: boolean = false;

        switch (link.itemKey) {
            case "0":
                showBubble = showUtiBubble;
                break;
            case "1":
                showBubble = showMerchandiseBubble;
                break;
            case "2":
                showBubble = showExpedientBubble;
                break;
            case "3":
                showBubble = showInspectionBubble;
                break;
        }

        return (
            <span style={{ flex: "0 1 100%" }}>
                {defaultRenderer({ ...link, itemIcon: undefined })}
                {showBubble && (
                    <Icon
                        iconName={link.itemIcon}
                        style={{ color: "orange" }}
                    />
                )}
            </span>
        );
    };

    return (
        <>
            <Pivot
                className={style.pivot}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("REQUEST_DATA")}
                    itemKey="0"
                    itemIcon="LocationDot"
                    onRenderItemLink={bubbleRender}
                >
                    <StatisticsUtiPivotFilter
                        onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                        onNullableBooleanChange={onNullableBooleanChange}
                        onInputChange={onInputChange}
                        onMultipleStringKeysChange={onMultipleStringKeysChange}
                        onInputNumberChange={onInputNumberChange}
                        filter={filter}
                    />
                </PivotItem>

                <PivotItem
                    headerText={t("MERCHANDISE")}
                    itemKey="1"
                    itemIcon="LocationDot"
                    onRenderItemLink={bubbleRender}
                >
                    <RequestMerchandisePivotFilter
                        onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                        onNullableBooleanChange={onNullableBooleanChange}
                        filter={filter}
                    ></RequestMerchandisePivotFilter>
                </PivotItem>

                <PivotItem
                    headerText={t("EXPEDIENT_INFO")}
                    itemKey="2"
                    itemIcon="LocationDot"
                    onRenderItemLink={bubbleRender}
                >
                    <StatisticsExpedientInfoPivotFilter
                        onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                        onMultipleStringKeysChange={onMultipleStringKeysChange}
                        filter={filter}
                    />
                </PivotItem>

                <PivotItem
                    headerText={t("INSPECTION")}
                    itemKey="3"
                    itemIcon="LocationDot"
                    onRenderItemLink={bubbleRender}
                >
                    <StatisticsInspectionPivotFilter
                        onMultipleNumberKeysChange={onMultipleNumberKeysChange}
                        onNullableBooleanChange={onNullableBooleanChange}
                        filter={filter}
                    />
                </PivotItem>
            </Pivot>
            <Separator size="85%"></Separator>
            <div className={style.buttons}>
                <div>
                    <ButtonPrimaryInverseWithBorder
                        handleClick={onResetFilters}
                        icon="ClearFilter"
                    />

                    <ButtonPrimary
                        handleClick={onApplyFilters}
                        icon="Search"
                    />
                </div>
            </div>
        </>
    );
};
