import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from "@fluentui/react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IAppointment, IAppointmentLocks } from "../../../../models/appointment/IBoard";
import { IBoardHistoryAppointmentDock, IBoardHistoryAppointmentTimeZone } from "../../../../models/masterAppointment/IAppointmentBoard";
import {
    deleteAppointmentsBoardTimeZoneLockAction,
    fetchAppointmentsBoardAction,
    postAppointmentsBoardTimeZoneLockAction,
    removeAppointmentRequest,
} from "../../../../redux/actions/appointments/appointments";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { AppointmentBoardContainerStyle } from "../containerStyle.jss";
import { RoleType } from "../../../../common/enum";
import { generatePath } from "react-router";
import { routeUrls } from "../../../../common/constants";
import { routeFeatures } from "../../../../common/constants/routeFeatures";
import { IUserRoleView, IView } from "../../../../models/requests/IUserRoleView";
import { ViewType } from "../../../../models";
import { generateFilterRequestSchema } from "../../../request/common/schema/generateFilterRequestSchema";
import { store } from "../../../../redux/store";

interface AppointmentBoardProps {
    appointment?: IAppointment;
    lock?: IAppointmentLocks;
    dock: IBoardHistoryAppointmentDock;
    timeZone: IBoardHistoryAppointmentTimeZone;
}
export const AppointmentGridComponent = ({ appointment, dock, timeZone, lock }: AppointmentBoardProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT_BOARD" });
    const linkRef = useRef(null);
    const style = AppointmentBoardContainerStyle({ theme });
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const onHideContextualMenu = useCallback(() => setShowContextualMenu(false), []);
    const { viewPermission } = store.getState().userRoleViews;
    const { date } = useAppSelector((store) => store.appointmentBoardGrid);
    const { roles } = useAppSelector((store) => store.userData.profile);

    const requestClassStyle: string = `state-${appointment?.requestStateSort}`;

    const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature: any) => feature.id === routeFeatures.REQUEST);

    const viewConfig = useMemo(() => {
        return viewRequestPermission?.views.find((view) => view.type === ViewType.COMMANDS) as IView;
    }, [viewRequestPermission?.views]);

    const schema = useMemo(() => {
        if (!viewConfig) {
            return undefined;
        }
        return generateFilterRequestSchema(viewConfig.fields);
    }, [viewConfig?.fields]);

    const handleContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setShowContextualMenu(true);
    }, []);

    const onClickLock = useCallback(() => {
        dispatch(postAppointmentsBoardTimeZoneLockAction(date, dock.id, timeZone.id));
    }, [date]);

    const onClickUnLock = useCallback(() => {
        lock && dispatch(deleteAppointmentsBoardTimeZoneLockAction(lock.id, date));
    }, [date]);

    const userContextualmenu = useMemo(() => {
        return roles.some(
            (item) =>
                item.id === RoleType.DIRECTOR ||
                item.id === RoleType.TEAM_LEADER ||
                item.id === RoleType.AREA_DIRECTOR ||
                item.id === RoleType.ADMINISTRATIVE ||
                item.id === RoleType.SUPER_ADMIN,
        );
    }, [roles]);

    const menuItems: IContextualMenuItem[] = [
        {
            key: "section1",
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                topDivider: true,
                bottomDivider: true,
                title: `${timeZone.description}/${dock.description}`,
                items: [
                    { key: "lock", text: t("LOCK"), disabled: lock ? true : false, onClick: () => onClickLock() },
                    { key: "unlock", text: t("UNLOCK"), disabled: !lock ? true : false, onClick: () => onClickUnLock() },
                ],
            },
        },
    ];
    let path = (id: string) => {
        return generatePath(routeUrls.EDIT_REQUEST, { id });
    };

    const requestContextualMenu = useMemo(() => {
        let items = [];

        if (viewConfig.fields.some((field) => field.name === "edit")) {
            items.push({
                key: "section1",
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                    topDivider: true,
                    bottomDivider: true,
                    title: `${timeZone.description}/${dock.description}`,
                    items: [
                        {
                            key: "lock",
                            text: t("REQUEST_SEARCH"),
                            onClick: () => {
                                window.open(path(String(appointment?.requestId ?? 0)), "_blank");
                            },
                        },
                    ],
                },
            });
        }

        if (userContextualmenu) {
            items[0].sectionProps.items.push({
                key: "remove",
                text: t("REMOVE_APPOINTMENT"),
                onClick: () => {
                    if (appointment?.requestId) {
                        dispatch(removeAppointmentRequest(String(appointment?.requestId), true)).then((response: any) => {
                            if (response) {
                                dispatch(fetchAppointmentsBoardAction(date));
                            }
                        });
                    }
                },
            });
        }

        return items;
    }, [userContextualmenu, schema]);
    return (
        <>
            <div
                onContextMenu={handleContextMenu}
                ref={linkRef}
                className={`${style.appointment} ${style[requestClassStyle as keyof typeof style]}`}
            >
                {appointment && (
                    <div className={`${style.appointmentContent}`}>
                        <div>{appointment?.transportUnitNumber}</div>
                        <div>{appointment?.requestStateDescription}</div>
                    </div>
                )}
                {appointment?.requestId && (
                    <ContextualMenu
                        items={requestContextualMenu}
                        hidden={!showContextualMenu}
                        target={linkRef}
                        onItemClick={onHideContextualMenu}
                        onDismiss={onHideContextualMenu}
                    />
                )}

                {!appointment && userContextualmenu && (
                    <>
                        <div className={`${style.appointment} ${lock ? style.appointmentLock : style.appointmentAvailable}`}>
                            <ContextualMenu
                                items={menuItems}
                                hidden={!showContextualMenu}
                                target={linkRef}
                                onItemClick={onHideContextualMenu}
                                onDismiss={onHideContextualMenu}
                            />
                        </div>
                    </>
                )}

                {!appointment && !userContextualmenu && (
                    <>
                        <div className={`${style.appointment} ${lock ? style.appointmentLock : style.appointmentAvailable}`}></div>
                    </>
                )}
            </div>
        </>
    );
};
