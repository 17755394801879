import { useCallback, useEffect, useRef } from "react";
import { FieldErrors, Resolver, useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "../../../../../../../../../common/validations/validationsForm";
import { IEmailItem, IEmailItemWithIndex } from "../../../../../../../../../models";
import { useAppDispatch } from "../../../../../../../../../redux/hooks";
import {
    addRegisterEmailItem,
    removeRegisterEmailItem,
    updateRegisterEmailItem,
    updateRegisterEmailItemIsValid,
} from "../../../../../../../../../redux/reducers/user/profile/registerUsers";
import { compileNameOfProperty } from "../../../../../../../../../utils";
import { CustomIcons } from "../../../../../../../../common/customIcons/customIcons";
import { FormField } from "../../../../../../../../common/fields/fields";
import { UserProfileEntityUsersSendSignupEmailsItemStyle } from "./itemStyle.jss";

interface IUserProfileEntityUsersSendSignupEmailsItemProps {
    email: IEmailItem;
    isLastIndex: boolean;
    isUniqueIndex: boolean;
    index: number;
}

export const UserProfileEntityUsersSendSignupEmailsItem: React.FC<IUserProfileEntityUsersSendSignupEmailsItemProps> = ({
    email,
    isLastIndex,
    isUniqueIndex,
    index,
}) => {
    const isFirstRender = useRef(true);
    const style = UserProfileEntityUsersSendSignupEmailsItemStyle();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "USER.PROFILE.ENTITY" });

    const resolver: Resolver<IEmailItem> = async (values: IEmailItem) => {
        let errors = {};
        Object.entries(values).forEach(([name, value]) => {
            if (name === compileNameOfProperty<IEmailItem>("email")) {
                if (!isValidEmail(value)) {
                    errors = {
                        ...errors,
                        [name]: {
                            type: "required",
                            message: "FORM.VALIDATION.EMAIL",
                        },
                    };
                }
            }
        });
        return { values, errors };
    };
    const {
        register,
        formState: { errors: emailErrors, isValid },
    } = useForm<any>({ mode: "onChange", resolver });

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            const payload: IEmailItemWithIndex = {
                index: index,
                email: email.email,
                isValid,
            };
            dispatch(updateRegisterEmailItemIsValid(payload));
        }
    }, [isValid]);

    const onInputChange = useCallback(
        (event) => {
            const payload: IEmailItemWithIndex = {
                index: index,
                email: event?.target?.value,
                isValid,
            };
            dispatch(updateRegisterEmailItem(payload));
        },
        [dispatch],
    );

    const onRemoveEmail = () => {
        dispatch(removeRegisterEmailItem(index));
    };

    const onAddEmail = () => {
        dispatch(addRegisterEmailItem());
    };

    const emailsRegister: UseFormRegister<IEmailItem> = register;
    const validationErrors: FieldErrors<IEmailItem> = emailErrors;

    return (
        <>
            <div className={style.emailItem}>
                <div className={style.emailContent}>
                    <FormField
                        type="text"
                        label={`${t("EMAIL")} ${index + 1}`}
                        value={email.email}
                        error={validationErrors.email}
                        {...emailsRegister(compileNameOfProperty<IEmailItem>("email"), {
                            onChange: onInputChange,
                            value: email.email,
                        })}
                    />
                    {!isUniqueIndex && (
                        <CustomIcons
                            iconName="Cancel"
                            onClick={onRemoveEmail}
                            title={t("REMOVE_EMAIL")}
                            size={14}
                        ></CustomIcons>
                    )}
                </div>
                {isLastIndex && (
                    <div className={style.addEmail}>
                        <CustomIcons
                            iconName="Add"
                            onClick={onAddEmail}
                            title={t("ADD_EMAIL")}
                            size={14}
                        ></CustomIcons>
                    </div>
                )}
            </div>
        </>
    );
};
