import { Icon, IconButton, IIconProps, TooltipHost } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NameIcons } from "../../../assets/icons";
import { OrderColumn } from "../../../common/enum/OrderColumn";
import { ViewType } from "../../../models";
import { ISortState } from "../../../models/common/ISortState";
import { IUserRoleView } from "../../../models/requests/IUserRoleView";
import { store } from "../../../redux/store";

interface IHeaderStyles {
    header: string;
    sort: string;
    iconSort: string;
    iconSorted: string;
    sortColumn: string;
    iconHeader?: string;
}

interface IHeaderColumn {
    iconTitle?: any;
    title?: string;
    name: string;
    handleClick?: () => any;
    styles: IHeaderStyles;
    routeFeature?: string;
    isSortable?: boolean;
    sort?: ISortState;
}

export const CustomHeaderColumn = (props: IHeaderColumn): JSX.Element => {
    const { viewPermission } = store.getState().userRoleViews;
    const { t } = useTranslation();
    const isSortable = useMemo(() => {
        if (props.routeFeature) {
            const viewRequestPermission: IUserRoleView | undefined = viewPermission.find((feature) => feature.id === props.routeFeature);
            const view = viewRequestPermission?.views.find((view) => view.type === ViewType.LIST);

            return view?.fields.find((item) => item.name === props.name)?.sortable;
        } else {
            return props.isSortable;
        }
    }, [viewPermission]);
    let iconSort = NameIcons.DOWN;
    let iconStyle = props.styles.iconSort;

    if (props.sort && (props.sort.column === props.name || props.sort.column[0] === props.name)) {
        iconSort = props.sort.orderColumn === OrderColumn.DESC ? NameIcons.DOWN : NameIcons.UP;
        iconStyle = props.styles.iconSorted;
    }

    const icon: IIconProps = { iconName: iconSort, className: iconStyle };

    const sortable =
        isSortable && props.handleClick ? (
            <TooltipHost content={t("LIST.TOOLTIP_DESCRIPTION.SORT")}>
                <IconButton
                    iconProps={icon}
                    className={props.styles.sort}
                    onClick={props.handleClick}
                />
            </TooltipHost>
        ) : (
            <></>
        );

    return (
        <div className={props.styles.header}>
            {props.sort && (props.sort.column === props.name || props.sort.column[0] === props.name) ? (
                <Icon
                    iconName={NameIcons.ARROW}
                    className={props.styles.sortColumn}
                />
            ) : null}
            {props.title} {props.iconTitle} {sortable}
        </div>
    );
};
