import { useTheme } from "react-jss";
import { IConfigEditRequest } from "../../../../../../../../../models/requests/IConfigEditRequest";
import { EditRequestAppointmentFormStyle } from "./formStyle.jss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../redux/hooks";
import { AppointmentTypesSelector } from "../../../../../../../../resources/masterAppointment/list/appointmentBoard/controller/edit/tabs/form/appointmentSelector/appointmentSelector";
import { FormFieldCustom } from "../../../../../../../../common/fields/fieldsCustom";

import { useMemo } from "react";
import { CustomIcons } from "../../../../../../../../common/customIcons/customIcons";
import { Separator } from "../../../../../../../../common/separator/separator";
import { NameIcons } from "../../../../../../../../../assets/icons";
import { IGenericPayload } from "../../../../../../../../../models";
import {
    IEditRequestAppointmentCreateForm,
    setEditRequestAppointmentCreateForm,
    setEditRequestAppointmentShowForm,
} from "../../../../../../../../../redux/reducers/request/edit/form/appointment";
import { fetchEditRequestData, updateRequestFormData } from "../../../../../../../../../redux/actions/request/edit/action/requestEdit";
import { PopUpCodes } from "../../../../../../../../../common/enum/PopUpCodes";
import { compileNameOfProperty } from "../../../../../../../../../utils";
import { AppointmentTimeZoneSelector } from "../../../../../../../../common/selectors/formSelectors/timeZoneSelector";
import { resetAppointmentAvailable, setAppointmentSelectionToAssign } from "../../../../../../../../../redux/reducers/appointment/zoneAvailable";
import { mapAppointmentAssign } from "../../utils";
import { removeAppointmentRequest } from "../../../../../../../../../redux/actions/appointments/appointments";
import { updateAppoitmentsZones } from "../../../../../../../../../redux/reducers/request/edit/editRequest";
import { Alert } from "../../../../../../../../common/alert/alert";
import { useAppointment } from "../../../../../../../../../hooks/request/useAppointment";
import { RoleType } from "../../../../../../../../../common/enum";
interface IEditRequestAppointmentFormProps {
    schema: IConfigEditRequest;
    disabledAppointmentData: boolean;
    hiddenActionsAppointment: boolean;
    invalidRequestForm: boolean;
}

export const EditRequestAppointmentForm: React.FC<IEditRequestAppointmentFormProps> = ({
    schema,
    disabledAppointmentData,
    hiddenActionsAppointment,
    invalidRequestForm,
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const appointmentWidth = useMemo(() => {
        if (!schema.appointmentCreate.visible) {
            return "66%";
        } else {
            return hiddenActionsAppointment ? "70%" : "60%";
        }
    }, [schema]);
    const style = EditRequestAppointmentFormStyle({ theme, hiddenActionsAppointment, appointmentWidth: appointmentWidth });
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST.APPOINTMENT" });
    const {
        editRequest: { headerRequest, copyHeaderRequest },
        editRequestAppointment: { showPopUp, form },
        appointmentAvailableZone: {
            available: { timeZones },
            selectedToAssign,
        },
        userData: {
            profile: { roles },
        },
    } = useAppSelector((store) => store);

    const { isNotAvailableTimeZones, forceAppointmentType, isChangeDate } = useAppointment();

    const isCustomer = useMemo(() => {
        return roles.find((item) => item.id === RoleType.CUSTOMER_ADMIN || item.id === RoleType.CUSTOMER_USER) ? true : false;
    }, [roles]);

    const disableAppointmentTimeZoneSelector = useMemo(() => {
        if (isCustomer && headerRequest.date !== copyHeaderRequest.date) {
            return false;
        }
        if (hiddenActionsAppointment) {
            return !form.appointmentTypeId;
        }

        return schema.appointmentTimeZones.readonly || !headerRequest.appointmentTypeId || disabledAppointmentData ? true : false;
    }, [schema, headerRequest.appointmentTypeId, headerRequest.date, disabledAppointmentData, form]);

    const onAppoitmentTypeSelector = (keysSelected: any, name: string) => {
        const payload: IGenericPayload = {
            name: name,
            value: keysSelected.key,
        };
        const resetAppointmentTimeZoneId: IGenericPayload = {
            name: "timeZoneId",
            value: 0,
        };

        if (hiddenActionsAppointment) {
            dispatch(resetAppointmentAvailable());
            dispatch(setEditRequestAppointmentCreateForm(payload));
            dispatch(setEditRequestAppointmentCreateForm(resetAppointmentTimeZoneId));
        } else {
            dispatch(updateRequestFormData(payload));
            onRestoreAppointment();
        }
    };

    const onSelectorChangeTimeZone = (option: any, name: string) => {
        if (!option) {
            dispatch(setAppointmentSelectionToAssign(null));
            return;
        }

        const payload: IGenericPayload = {
            name: name,
            value: option.key,
        };

        if (hiddenActionsAppointment) {
            dispatch(setAppointmentSelectionToAssign(mapAppointmentAssign(option.key, timeZones ?? [], headerRequest, form)));
            dispatch(setEditRequestAppointmentCreateForm(payload));
        } else {
            dispatch(updateRequestFormData(payload));
            dispatch(setAppointmentSelectionToAssign(mapAppointmentAssign(option.key, timeZones ?? [], headerRequest, form)));
            dispatch(setEditRequestAppointmentShowForm(PopUpCodes.WARNING));
        }
    };

    const onRestoreAppointment = () => {
        dispatch(updateAppoitmentsZones(null));
        dispatch(setAppointmentSelectionToAssign(null));
    };

    const onRemoveAppointment = () => {
        dispatch(removeAppointmentRequest(String(headerRequest.id))).then((response: any) => {
            if (response) {
                dispatch(fetchEditRequestData(String(headerRequest.id)));
            }
        });
    };

    return (
        <div className={style.appointmentContent}>
            <div className={`${style.form}  ${hiddenActionsAppointment && style.popForm}`}>
                {schema.appointmentTypeId.visible && (
                    <div className={style.appointmentType}>
                        <AppointmentTypesSelector
                            title={t("TYPE")}
                            onChange={onAppoitmentTypeSelector}
                            selectedKey={hiddenActionsAppointment ? form.appointmentTypeId : headerRequest.appointmentTypeId}
                            name={compileNameOfProperty<IEditRequestAppointmentCreateForm>("appointmentTypeId")}
                            isRequired={showPopUp === PopUpCodes.CREATE || schema.appointmentTypeId.required || headerRequest.appointmentAssigned}
                            disabled={schema.appointmentTypeId.readonly || disabledAppointmentData || invalidRequestForm}
                        />
                    </div>
                )}

                {schema.appointmentTimeZones.visible && (
                    <div className={style.appointment}>
                        {headerRequest.appointmentAssigned && headerRequest.appointment ? (
                            <FormFieldCustom
                                type="text"
                                value={`${headerRequest.appointment?.timeZoneDescription} (${headerRequest.appointment?.startTime} - ${headerRequest.appointment?.endTime})`}
                                label={t("TIMEZONE")}
                                disabled={true}
                                isRequired={true}
                                name="timezone"
                                onChange={() => null}
                            />
                        ) : (
                            <AppointmentTimeZoneSelector
                                title={t("TIMEZONE")}
                                onChange={onSelectorChangeTimeZone}
                                selectedKey={hiddenActionsAppointment ? form.timeZoneId : selectedToAssign?.timeZoneId}
                                name={"timeZoneId"}
                                isRequired={schema.appointmentTimeZones.required || headerRequest.appointment === null}
                                disabled={disableAppointmentTimeZoneSelector || invalidRequestForm}
                                autoSelect={hiddenActionsAppointment}
                            />
                        )}
                    </div>
                )}

                {schema.appointmentCreate.visible &&
                    ((!hiddenActionsAppointment && headerRequest.appointment !== null) ||
                        (!hiddenActionsAppointment && headerRequest.appointment === null)) && (
                        <div className={`${style.actionContainer}`}>
                            {!hiddenActionsAppointment && headerRequest.appointment !== null && (
                                <div className={style.actionContainer}>
                                    {!disabledAppointmentData && (
                                        <>
                                            <div className={style.reloadButton}>
                                                <CustomIcons
                                                    iconName={NameIcons.EDIT}
                                                    size={"1.2rem"}
                                                    isButton={true}
                                                    margin={"0px"}
                                                    tooltip={t("UPDATE_APPOINTMENT")}
                                                    onClick={onRestoreAppointment}
                                                    isDisabled={invalidRequestForm}
                                                />
                                            </div>
                                            <Separator size="auto" />
                                        </>
                                    )}

                                    <CustomIcons
                                        iconName={NameIcons.REMOVE}
                                        size={"1.2rem"}
                                        isButton={true}
                                        margin={"0px"}
                                        tooltip={t("REMOVE_APPOINTMENT")}
                                        onClick={onRemoveAppointment}
                                        isDisabled={invalidRequestForm}
                                    />
                                </div>
                            )}

                            {!hiddenActionsAppointment && headerRequest.appointment === null && (
                                <CustomIcons
                                    iconName="InfoSolid"
                                    size={"1.2rem"}
                                    isButton={false}
                                    margin={"0px"}
                                    tooltip={t("WARNING_MESSAGE")}
                                    className={style.infoIcon}
                                    onClick={onRestoreAppointment}
                                    position="start"
                                    isDisabled={invalidRequestForm}
                                />
                            )}
                        </div>
                    )}
            </div>

            {isNotAvailableTimeZones && (
                <div className={style.alertInfo}>
                    <Alert
                        title={t("INFO")}
                        icon={"Info"}
                        height={"auto"}
                        data={t("NOT_ZONES")}
                    />
                </div>
            )}

            {forceAppointmentType && (
                <div className={style.alertInfo}>
                    <Alert
                        title={t("INFO")}
                        icon={"Info"}
                        height={"auto"}
                        data={t("CHANGE_APPOINTMENT_TYPE")}
                    />
                </div>
            )}
        </div>
    );
};
