import { createUseStyles } from "react-jss";

export const PerfilEntityDataContainerStyle = createUseStyles((_theme: any) => ({
    entityDataContainer: {
        minHeight: 500,
        padding: 8,
    },
    section: {
        padding: "15px",
        display: "flex",
        flexDirection: "column",
        rowGap: 20,
        paddingRight: "3rem",
    },
    sectionTitle: {
        marginLeft: "0.5rem",
        marginBottom: "-20px",
    },
    sectionEmails: {
        padding: "15px",
        display: "flex",
        flexDirection: "row",
        rowGap: 20,
    },
    sectionEmailsData: {
        display: "flex",
        flexDirection: "column",
        minWidth: "47%",
        maxWidth: "47%",
        rowGap: "5px",
    },
}));
