import { createUseStyles } from "react-jss";

export const UserProfileEntityContainerStyle = createUseStyles((_theme: any) => ({
    representativeContainer: {
        padding: 24,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height:"calc(100vh - 17rem)"
    },
    addRepresentative: {
        padding: "15px 0",
    },
}));
