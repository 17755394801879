import { Method } from "../../../common/enum";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management/sort`;

export class ManagementSortTableApi {
    async managementSort(tableName: string, id: number, sortType: string): Promise<boolean> {
        const url = `${baseURL}/${tableName}/${id}/${sortType}`;
        return apiFetchOAuthWithClaims(Method.PATCH, url).execute<boolean>();
    }
}
