import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { NameIcons } from "../../../../../../../assets/icons";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterTransportUnitColumnsStyle } from "./columnStyle.jss";

import { PopUpCodes } from "../../../../../../../common/enum/PopUpCodes";
import { BooleanValue } from "../../../../../../common/booleanValue/booleanValue";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";
import { IMasterTransportUnit } from "../../../../../../../models/resources/master/ITransportUnit";
import { fetchMasterUnitTransport } from "../../../../../../../redux/actions/resources/master/transportUnit/transportUnit";
import { setShowPopUpMaster } from "../../../../../../../redux/reducers/resources/master/master";

export const MasterTransportUnitColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterTransportUnitColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const dispatch = useAppDispatch();
    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IMasterTransportUnit>("code"),
            minWidth: 120,
            maxWidth: 200,
            name: t("CODE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterTransportUnit>("code")}
                    title={t("CODE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterTransportUnit) => <span>{item.code ??DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterTransportUnit>("description"),
            minWidth: 100,
            maxWidth: 200,
            name: t("DESCRIPTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterTransportUnit>("description")}
                    title={t("DESCRIPTION")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterTransportUnit) => <span>{item.description??DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMasterTransportUnit>("container"),
            minWidth: 100,
            maxWidth: 200,
            name: t("CONTAINER"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterTransportUnit>("container")}
                    title={t("CONTAINER")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterTransportUnit) => <span> <BooleanValue value={item.container}></BooleanValue></span>,
            isResizable: true,
        },

        {
            key: compileNameOfProperty<IMasterTransportUnit>("active"),
            minWidth: 100,
            maxWidth: 200,
            name: t("ACTIVE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMasterTransportUnit>("active")}
                    title={t("ACTIVE")}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterTransportUnit) => <span><BooleanValue value={item.active}></BooleanValue></span>,
            isResizable: true,
        },

        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IMasterTransportUnit) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            dispatch(setShowPopUpMaster(PopUpCodes.UPDATE));
                            dispatch(fetchMasterUnitTransport(item));
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
