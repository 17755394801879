import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { fetchCustomsStatesRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterCustomsStatesForm } from "./form/form";
import { postAndPutMasterCustomsStates } from "../../../../../../redux/actions/resources/master/customsStates/customsStates";
import { resetMasterCustomsStatesForm } from "../../../../../../redux/reducers/resources/master/customsStates/form";
import { setShowPopUpMaster } from "../../../../../../redux/reducers/resources/master/master";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";

interface IMasterCustomsStatesFormController {
    widthPopUp: any;
    heigthPopUp: any;
}

export const MasterCustomsStatesFormController: React.FC<IMasterCustomsStatesFormController> = ({ widthPopUp, heigthPopUp }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { form, loading } = useAppSelector((state) => state.masterCustomsStatesForm);
    const { data } = useAppSelector((state) => state.catalog.customsStatesRemaining);

    const onAcceptPopUp = () => {
        dispatch(postAndPutMasterCustomsStates()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(setShowPopUpMaster(PopUpCodes.NONE));
    };
    const onDimissed = () => {
        dispatch(resetMasterCustomsStatesForm());
    };
    const showNotData = useMemo(() => {
        return showPopUp === PopUpCodes.CREATE && !data.length ? true : false;
    }, [data, showPopUp]);

    const titlePopUp = useMemo(() => {
        let title = "";
        if (showPopUp === PopUpCodes.CREATE) {
            title = t("CREATE") + " " + t("BUTTON.CUSTOMS_STATES");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            title = t("UPDATE") + " " + t("BUTTON.CUSTOMS_STATES");
        }
        return title;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form, showPopUp]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchCustomsStatesRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterCustomsStatesForm
                    showNotData={showNotData}
                    selectorData={data}
                />
            }
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            onDimissed={onDimissed}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            leftButtonContent={<RequiredFieldsMessage />}
            isDisabledButton={isDisabledButton}
            onlyHidden={showPopUp === PopUpCodes.CREATE ? showNotData : false}
        />
    );
};
