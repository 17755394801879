import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMasterMerchandiseTypeRemaining } from "../../../../../models/resources/master/IMerchandiseTypes";

export interface IMasterTypeWareListState {
    list?: IMasterMerchandiseTypeRemaining[];
    loading: boolean;
}

const initialState: IMasterTypeWareListState = {
    loading: false,
};

export const masterTypeWareList = createSlice({
    name: "masterTypeWareList",
    initialState,
    reducers: {
        resetMasterMerchandiseTypesList: () => initialState,
        setLoadingMasterMerchandiseTypesList: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterMerchandiseTypeList: (state, action: PayloadAction<IMasterMerchandiseTypeRemaining[]>) => {
            state.list = action.payload;
        },
    },
});

export const { resetMasterMerchandiseTypesList, setLoadingMasterMerchandiseTypesList, setMasterMerchandiseTypeList } = masterTypeWareList.actions;

export default masterTypeWareList.reducer;
