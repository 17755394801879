import { createUseStyles } from "react-jss";
import { mediaQueries } from "../../../../../../common/constants";

export const EditRequestTabsVUAContainerStyle = createUseStyles((theme: any) => ({
    generalContainerVua: {
        height: theme.heights.requestEditContent,
        display: "flex",
        flexDirection: "column",
        gap: 30,
        overflowY: "scroll",
        padding: theme.paddings.default,
        paddingLeft: 0,
        [mediaQueries.MOBILE]: {
            flexFlow: "column",
            gap: 15,
            "& > div ": {
                marginTop: 10,
            },
        },
    },
    field: {
        [mediaQueries.MOBILE]: {
            marginTop: 10,
        },
    },
}));
