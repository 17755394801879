import { FormatDate } from "../../../../../../../common/enum/dateTime/FormatDate";
import { IAppointmentAssign } from "../../../../../../../models/appointment/IAppointmentAssign";
import { ITimeZone } from "../../../../../../../models/appointment/IDashboardZonesAvaiable";
import { IEditRequest } from "../../../../../../../models/requests/IEditRequest";
import { ICanAssignAppointment } from "../../../../../../../models/requests/editRequest/IAppointment";
import { IEditRequestAppointmentCreateForm } from "../../../../../../../redux/reducers/request/edit/form/appointment";
import { convertToLocal } from "../../../../../../../utils";

export const mapEditRequestCanAssignAppointment = (headerRequest: IEditRequest): ICanAssignAppointment => {
    return {
        transportUnitId: headerRequest.transportUnitId,
        transportUnitSizeId: headerRequest.transportUnitSizeId,
        merchandiseCategoryId: headerRequest.merchandiseCategoryId,
        date: convertToLocal(headerRequest.date, FormatDate.ISO),
    };
};

export const mapAppointmentAssign = (
    selectedTimeZoneId: number,
    timeZones: ITimeZone[],
    headerRequest: IEditRequest,
    createApointmentForm: IEditRequestAppointmentCreateForm,
) => {
    const timeZone = timeZones?.find((t) => t.id === selectedTimeZoneId);
    if (
        timeZone &&
        headerRequest.date &&
        (headerRequest.appointmentTypeId || createApointmentForm.appointmentTypeId) &&
        headerRequest.transportUnitId
    ) {
        const selectedTimeZone: IAppointmentAssign = {
            dashboardId: timeZone.appointmentDashboardId,
            date: headerRequest.date,
            appointmentTypeId: createApointmentForm.appointmentTypeId || headerRequest.appointmentTypeId || 0,
            merchandiseCategoryId: headerRequest.merchandiseCategoryId,
            merchandiseTypeId: headerRequest.merchandiseTypeId,
            requestId: headerRequest.id,
            timeZoneId: timeZone.id,
            timeZoneSort: timeZone.sort,
            transportUnitId: headerRequest.transportUnitId,
            transportUnitSizeId: headerRequest.transportUnitSizeId ?? 0,
            dryCharge: headerRequest.dryCharge,
            halalCertified: headerRequest.halalCertified,
            humanConsumption: headerRequest.humanConsumption,
            fumigated: headerRequest.fumigated,
            organicProduct: headerRequest.organicProduct,
        };
        return selectedTimeZone;
    }
    return null;
};

export const mapCompareRequestValues = (headerRequest: IEditRequest) => {
    return {
        date: headerRequest.date,
        fumigated: headerRequest.fumigated,
        halalCertified: headerRequest.halalCertified,
        humanConsumption: headerRequest.humanConsumption,
        merchandiseTypeId: headerRequest.merchandiseTypeId,
        organicProduct: headerRequest.organicProduct,
        transportUnitId: headerRequest.transportUnitId,
        appointmentTypeId: headerRequest.appointmentTypeId,
        turnId: headerRequest.turnId,
        transportUnitSizeId: headerRequest.transportUnitSizeId,
    };
};
