import { useTheme } from "react-jss";
import { IFormOption } from "../../../models/common/IFormOptions";
import { Checkboxs } from "../checkbox/checkbox";
import { CheckboxSelectorStyle } from "./checkboxSelectorStyle.jss";

interface ICheckboxSelectorProps {
    options: IFormOption[];
    name: string;
    onChange: (name: string, value: any, indexOption: number, id?: string | number) => void;
}

export const CheckboxSelector: React.FC<ICheckboxSelectorProps> = ({ options, onChange, name }) => {
    const theme = useTheme();
    const style = CheckboxSelectorStyle({ theme });
    return (
        <div className={style.container}>
            {options.map((option, index) => {
                return (
                    <Checkboxs
                        key={`${option.key}-${option.text}`}
                        handleChange={(e: any, checked = false) => {
                            onChange(e.target.name, checked, index, option.key);
                        }}
                        name={name}
                        isChecked={option.active}
                        label={option.text}
                        disabled={option.disabled}
                    />
                );
            })}
        </div>
    );
};
