import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { fetchOrganismRemaining } from "../../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PopUpForm } from "../../../../../common/popup/popUpForm/popUpForm";
import { validateDefaultForm } from "../../../utils/utils";
import { MasterOrganismForm } from "./form/form";
import { setShowPopUpMaster } from "../../../../../../redux/reducers/resources/master/master";
import { setResetmasterOrganismForm } from "../../../../../../redux/reducers/resources/master/organism/form";
import { postToMasterOrganismForm } from "../../../../../../redux/actions/resources/master/organism/organism";
import { RequiredFieldsMessage } from "../../../../../common/requiredMessage/requiredMessage";
interface IMasterOrganismFormController {
    widthPopUp: any;
    heigthPopUp: any;
}
export const MasterOrganismFormController: React.FC<IMasterOrganismFormController> = ({ heigthPopUp, widthPopUp }) => {
    const { form, loading } = useAppSelector((state) => state.masterOrganismForm);
    const { showPopUp } = useAppSelector((state) => state.masterPopUp);
    const { data } = useAppSelector((state) => state.catalog.organismsRemaining);
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "MASTERS" });

    const onAcceptPopUp = () => {
        dispatch(postToMasterOrganismForm()).then((response: any) => {
            if (response) {
                onCancelPopUp();
            }
        });
    };

    const onCancelPopUp = () => {
        dispatch(setShowPopUpMaster(PopUpCodes.NONE));
    };
    const onDimissedPopUp = () => {
        dispatch(setResetmasterOrganismForm());
    };

    const showNotData = useMemo(() => {
        return !data.length ? true : false;
    }, [data]);

    const titlePopUp = useMemo(() => {
        let title = "";
        if (showPopUp === PopUpCodes.CREATE) {
            title = t("CREATE") + " " + t("BUTTON.ORGANISM");
        } else if (showPopUp === PopUpCodes.UPDATE) {
            title = t("UPDATE") + " " + t("BUTTON.ORGANISM");
        }
        return title;
    }, [showPopUp]);

    const isDisabledButton = useMemo(() => {
        return validateDefaultForm(form, showPopUp === PopUpCodes.CREATE);
    }, [form]);

    useEffect(() => {
        showPopUp === PopUpCodes.CREATE && dispatch(fetchOrganismRemaining());
    }, [showPopUp]);

    return (
        <PopUpForm
            content={
                <MasterOrganismForm
                    showNotData={showNotData}
                    data={data}
                />
            }
            onDimissed={onDimissedPopUp}
            title={titlePopUp}
            isVisible={showPopUp === PopUpCodes.CREATE || showPopUp === PopUpCodes.UPDATE ? true : false}
            onCancel={onCancelPopUp}
            onSave={onAcceptPopUp}
            height={heigthPopUp}
            width={widthPopUp}
            isLoading={loading}
            isDisabledButton={isDisabledButton}
            leftButtonContent={<RequiredFieldsMessage />}
            onlyHidden={showPopUp === PopUpCodes.CREATE ? showNotData : false}
        />
    );
};
