import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { useMemo } from "react";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";

import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface IYearSelectorProps extends IBaseSelectorProps {
    yearsAfter: number;
    starterYear?: number;
}

export const YearSelector: React.FC<IYearSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    yearsAfter,
    starterYear,
    disabled,
    isRequired,
    control,
    rules,
}) => {
    const firstYear = useMemo(() => {
        return starterYear || new Date().getFullYear();
    }, [starterYear]);

    const options = useMemo(() => {
        const comboBoxoptions: IComboBoxOption[] = [];
        for (let i = 0; i < yearsAfter; i++) {
            comboBoxoptions.push({
                key: firstYear + i,
                text: `${firstYear + i}`,
                title: `${firstYear + i}`,
            });
        }
        return comboBoxoptions;
    }, [firstYear, yearsAfter]);

    const onyearChange = (_e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        onChange(option, propertyName);
    };

    return (
        <ControlledDropdown
            required={isRequired}
            options={options}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={disabled}
            onChange={onyearChange}
            selectedKey={selectedKey}
        />
    );
};
