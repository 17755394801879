import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { RequestFilterPopUpStyle } from "../filterStyle.jss";
import { IRequestFilter } from "../../../../../../../models";
import { useAppSelector } from "../../../../../../../redux/hooks";
import { compileNameOfProperty } from "../../../../../../../utils";
import { TextInput } from "../../../../../../common/inputs/textInput";
import { BooleaMultiSelector } from "../../../../../../common/selectors";
import { MultipleActuationTypesSelector } from "../../../../../common/selectors/actuationTypes/multipleActuationTypes";
import { MultipleCertificateSelector } from "../../../../../common/selectors/certificate/multipleCertificate";
import { MultipleFinalDestinationSelector } from "../../../../../common/selectors/finalDestination/multipleFinalDestination";
import { MultipleInspectorRoleSelector } from "../../../../../common/selectors/inspector/multipleInspectors";
import { MultipleOrganismSelector } from "../../../../../common/selectors/organism/multipleOrganism";
import { MultipleTerminalSelector } from "../../../../../common/selectors/terminal/multipleTerminal";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { RoleType, OrganismTypes } from "../../../../../../../common/enum";
import { fetchOrganisms } from "../../../../../../../redux/actions/catalog/catalog";

interface IProps {
    onMultipleNumberKeysChange: (name: string, options: number[]) => void;
    onNullableBooleanChange: (name: string, booleanValue: boolean | undefined) => void;
    onInputChange: (event: any, name: string) => void;
    onMultipleStringKeysChange: (name: string, options: string[]) => void;
    schema: any;
    onEnter: () => void;
}

export const RequestExpedientInfoFilter: React.FC<IProps> = ({
    onMultipleNumberKeysChange,
    onNullableBooleanChange,
    onInputChange,
    onMultipleStringKeysChange,
    schema,
    onEnter,
}) => {
    const theme = useTheme();
    const styles = RequestFilterPopUpStyle({ theme });
    const { filter } = useAppSelector((state) => state.requestListFilter);
    const { t } = useTranslation("common", { keyPrefix: "REQUEST_FILTER.FILTER" });
    const { profile } = useAppSelector((state) => state.userData);
    const { data } = useAppSelector((store) => store.catalog.organisms);
    const dispatch = useDispatch();
    const { id, impersonatedOrganismId } = useParams();

    const isShowSelector = useMemo(() => {
        const isOrganismAdmin = profile.roles.find((item) => item.id === RoleType.ORGANISM_ADMIN);

        if ((data && isOrganismAdmin) || (profile.organismId && !impersonatedOrganismId)) {
            let isTaxCanari = data.find((item) => item.code === OrganismTypes.TAX_CANARY);
            let isCustoms = data.find((item) => item.code === OrganismTypes.CUSTOMS);
            if (isTaxCanari?.id === profile.organismId || isCustoms?.id === profile.organismId) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }, [data, id]);

    useEffect(() => {
        data.length === 0 && dispatch(fetchOrganisms());
    }, [dispatch]);

    useEffect(() => {
        if (id || impersonatedOrganismId) {
            let organismSelected = data.find((item) => item.code === id || item.code === impersonatedOrganismId);
            let idSelected = organismSelected?.id ?? 0;
            onMultipleNumberKeysChange(compileNameOfProperty<IRequestFilter>("organismId"), [idSelected]);
        }
    }, [id, impersonatedOrganismId]);

    return (
        <div className={styles.section}>
            {schema.certificateNumberVisible && (
                <TextInput
                    value={filter.certificateNumber}
                    propertyName={compileNameOfProperty<IRequestFilter>("certificateNumber")}
                    label={t("CERTIFICATE_NUMBER")}
                    onChange={onInputChange}
                    onEnter={onEnter}
                />
            )}
            {schema.certificateTypeIdVisible && (
                <MultipleCertificateSelector
                    selectedKeys={filter.certificateTypeId}
                    propertyName={compileNameOfProperty<IRequestFilter>("certificateTypeId")}
                    title={t("CERTIFICATE_TYPE")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.actuationTypeIdVisible && (
                <MultipleActuationTypesSelector
                    selectedKeys={filter.actuationTypeId}
                    propertyName={compileNameOfProperty<IRequestFilter>("actuationTypeId")}
                    title={t("ACTUATION")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.organismIdVisible && isShowSelector && (
                <MultipleOrganismSelector
                    selectedKeys={filter.organismId}
                    propertyName={compileNameOfProperty<IRequestFilter>("organismId")}
                    title={t("ORGANISM")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.inspectorAssignedVisible && (
                <BooleaMultiSelector
                    selectedKey={filter.inspectorAssigned}
                    propertyName={compileNameOfProperty<IRequestFilter>("inspectorAssigned")}
                    title={t("INSPECTOR_ASSIGNED")}
                    onChange={onNullableBooleanChange}
                />
            )}
            {schema.inspectorIdVisible && (
                <MultipleInspectorRoleSelector
                    selectedKeys={filter.inspectorId}
                    propertyName={compileNameOfProperty<IRequestFilter>("inspectorId")}
                    title={t("INSPECTOR")}
                    onChange={onMultipleStringKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.vuaVisible && (
                <BooleaMultiSelector
                    selectedKey={filter.vua}
                    propertyName={compileNameOfProperty<IRequestFilter>("vua")}
                    title={t("VUA")}
                    onChange={onNullableBooleanChange}
                />
            )}
            {schema.summaryNumberVisible && (
                <TextInput
                    value={filter.summaryNumber}
                    propertyName={compileNameOfProperty<IRequestFilter>("summaryNumber")}
                    label={t("SUMMARY_NUMBER")}
                    onChange={onInputChange}
                    onEnter={onEnter}
                />
            )}

            {schema.terminalIdVisible && (
                <MultipleTerminalSelector
                    selectedKeys={filter.terminalId}
                    propertyName={compileNameOfProperty<IRequestFilter>("terminalId")}
                    title={t("TERMINAL")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
            {schema.finalDestinationTypeIdVisible && (
                <MultipleFinalDestinationSelector
                    selectedKeys={filter.finalDestinationTypeId}
                    propertyName={compileNameOfProperty<IRequestFilter>("finalDestinationTypeId")}
                    title={t("FINALDESTINATION")}
                    onChange={onMultipleNumberKeysChange}
                    autocomplete={false}
                />
            )}
        </div>
    );
};
