import { IColumn } from "@fluentui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IMonthlySummaryMonthlySummaryItem } from "../../../../../models/statistics/IMonthlySummary";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateSortColumn } from "../../../../../redux/reducers/statistics/customer/list";
import { store } from "../../../../../redux/store";
import { compileNameOfProperty } from "../../../../../utils/formUtils";
import { onUpdateSortedColumnUtil } from "../../../../../utils/sortUtils";
import { CustomHeaderColumn } from "../../../../common/customDetailsList/customHeader";
import { MonthlySummaryColumnStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../common/enum/DefaultValues";

export const StatisticsMonthlySummaryListColumn = (): IColumn[] => {
    const theme = useTheme();
    const styles = MonthlySummaryColumnStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "STATISTICS.MONTHLY_SUMMARY.TABLE" });
    const dispatch = useAppDispatch();

    const { sort } = useAppSelector((store) => store.statisticsMonthlySummaryList.data);

    const onUpdateSortedColumn = useCallback(
        (column: string) => () => {
            const currentSort = store.getState().statisticsMonthlySummaryList.data.sort;
            let payload = onUpdateSortedColumnUtil(column, currentSort);
            dispatch(updateSortColumn(payload));
        },
        [dispatch],
    );

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("monthSummary"),
            minWidth: 100,
            maxWidth: 150,
            name: t("YEAR"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("monthSummary")}
                    title={t("YEAR")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("monthSummary"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => (
                <span className={styles.dataCell}>
                    <MonthDescription
                        idMonth={Number(item.monthSummary)}
                        text={item.yearSummary}
                    />
                </span>
            ),
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspection"),
            minWidth: 85,
            maxWidth: 150,
            name: t("NUMBER_INSPECTIONS"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspection")}
                    title={t("NUMBER_INSPECTIONS")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspection"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.inspection ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime"),
            minWidth: 95,
            maxWidth: 150,
            name: t("TIME_STAY_FACILITIES"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime")}
                    title={t("TIME_STAY_FACILITIES")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.stayTime ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime"),
            minWidth: 100,
            maxWidth: 150,
            name: t("TIME_TOTAL_INSPECTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime")}
                    title={t("TIME_TOTAL_INSPECTION")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.inspectionTime?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime"),
            minWidth: 95,
            maxWidth: 100,
            name: t("TIME_TOTAL_INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime")}
                    title={t("TIME_TOTAL_INCIDENCE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidencesTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.incidencesTime?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences"),
            minWidth: 100,
            maxWidth: 110,
            name: t("NUMBER_INCIDENCE_VEHICLES"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences")}
                    title={t("NUMBER_INCIDENCE_VEHICLES")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("incidences"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.incidences?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime"),
            minWidth: 100,
            maxWidth: 150,
            name: t("AVERAGE_STAY_FACILITIES"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime")}
                    title={t("AVERAGE_STAY_FACILITIES")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("stayAverageTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.stayAverageTime?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime"),
            minWidth: 100,
            maxWidth: 150,
            name: t("AVERAGE_INSPECTION"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime")}
                    title={t("AVERAGE_INSPECTION")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("inspectionAverageTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.inspectionAverageTime?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime"),
            minWidth: 100,
            maxWidth: 150,
            name: t("AVERAGE_INCIDENCE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime")}
                    title={t("AVERAGE_INCIDENCE")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("averageIncidencesTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),
            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.averageIncidencesTime?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime"),
            minWidth: 100,
            maxWidth: 150,
            name: t("AVERAGE_NET"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime")}
                    title={t("AVERAGE_NET")}
                    handleClick={onUpdateSortedColumn(compileNameOfProperty<IMonthlySummaryMonthlySummaryItem>("netTime"))}
                    styles={styles}
                    isSortable={false}
                    sort={sort}
                />
            ),

            onRender: (item: IMonthlySummaryMonthlySummaryItem) => <span className={styles.dataCell}>{item.netTime?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
    ];

    return columns;
};
interface IMonthDescriptionProps {
    idMonth: number;
    text?: string;
}

export const MonthDescription: React.FC<IMonthDescriptionProps> = ({ idMonth, text }) => {
    const { t } = useTranslation("common", { keyPrefix: "MONTH" });
    const getMonthDescription = (idMonth: number) => {
        switch (idMonth) {
            case 1:
                return t("JANUARY");
            case 2:
                return t("FEBRUARY");
            case 3:
                return t("MARCH");
            case 4:
                return t("APRIL");
            case 5:
                return t("MAY");
            case 6:
                return t("JUNE");
            case 7:
                return t("JULY");
            case 8:
                return t("AUGUST");
            case 9:
                return t("SEPTEMBER");
            case 10:
                return t("OCTUBER");
            case 11:
                return t("NOVEMBER");
            case 12:
                return t("DECEMBER");
        }
    };

    return (
        <>
          
          {text ? text + " - ":"" }{getMonthDescription(idMonth)}
        </>
    );
};
