import { IDropdownOption } from "@fluentui/react";
import { useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { fetchTurns } from "../../../../../redux/actions/catalog/catalog";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { mapIDescriptionToDropDownOptions } from "../../../../../utils";
import { ControlledDropdown } from "../../../../common/selectors/controller/controlledSelector";
import { IBaseSelectorProps } from "../IBaseSelectorProps";

interface ITurnSelectorProps extends IBaseSelectorProps {
    setValue: UseFormSetValue<any>;
}

export const TurnSelector: React.FC<ITurnSelectorProps> = ({
    onChange,
    selectedKey,
    propertyName,
    title,
    disabled,
    isRequired,
    control,
    rules,
    setValue,
}) => {
    const { data, loading } = useAppSelector((store) => store.catalog.turns);
    const dispatch = useAppDispatch();
    const [selectorkey, setSelectorKey] = useState<number>(0);

    useEffect(() => {
        data.length === 0 && dispatch(fetchTurns());
        data.length === 1 && setOptionIfNotYetSelected();
    }, [dispatch, data]);

    const setOptionIfNotYetSelected = async () => {
        if (!selectedKey) {
            const options = mapIDescriptionToDropDownOptions(data);
            onChange(options[0], propertyName);
            setSelectorKey((value) => value + 1);
            setValue(propertyName, Number(options[0].key));
        }
    };

    const onSelectorChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
        onChange(option, propertyName);
    };
    return (
        <ControlledDropdown
            required={isRequired}
            options={mapIDescriptionToDropDownOptions(data)}
            label={title}
            control={control}
            name={propertyName}
            rules={rules}
            disabled={loading || disabled}
            onChange={onSelectorChange}
            selectedKey={selectedKey}
            key={`${TurnSelector.name}-${selectorkey}`}
        />
    );
};
