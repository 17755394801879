import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterMerchandiseType } from "../../../../../models/resources/master/IMerchandiseTypes";


export interface IMasterTypesWareFormState {
    form: IMasterMerchandiseType;
    loading: boolean;
}

const initialState: IMasterTypesWareFormState = {
    form: {
        active: true,
        code: "",
        description: "",
        humanConsumption: false,
        merchandiseOriginId: -1,
        parkingAuthorization: true,
    },
    loading: false,
};

export const masterTypesWareForm = createSlice({
    name: "masterOrganismForm",
    initialState,
    reducers: {
        resetMasterMerchandiseTypesForm: () => initialState,
        setLoadingMasterMerchandiseTypesForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterMerchandiseTypesForm: (state, action: PayloadAction<IMasterMerchandiseType>) => {
            state.form = action.payload;
        },
        updateMasterMerchandiseTypesProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterMerchandiseTypesForm,
    setLoadingMasterMerchandiseTypesForm,
    setMasterMerchandiseTypesForm,
    updateMasterMerchandiseTypesProperty,
} = masterTypesWareForm.actions;

export default masterTypesWareForm.reducer;
