import { RootState } from "../../../store";

export const userSignupConfirmationFormSelector = (state: RootState) => {
    return state.userSignupConfirmation.form;
};
export const userSignupConfirmationSuccessSelector = (state: RootState) => {
    return state.userSignupConfirmation.userSignupConfirmationSuccess;
};
export const userSignupConfirmationFormLoadingSelector = (state: RootState) => {
    return state.userSignupConfirmation.loadingSingupConfirmation;
};
