import { useEffect } from "react";
import { initSessionAction } from "../../redux/actions/session/initSessionAction";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export const InitDataFromLocalStorage = () => {
    const dispatch = useAppDispatch();
    const { loaded } = useAppSelector((store) => store.initSession);

    useEffect(() => {
        !loaded && dispatch(initSessionAction());
    }, []);

    return <></>;
};
