import React, { useMemo } from "react";
import { ThemeProvider, useTheme } from "react-jss";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getUserClaimsLoaded } from "../../../redux/selectors/user/claims/claims";
import { romeuTheme } from "../../../styles/romeu";
import { ClaimsToken } from "../../auth/claimsToken";
import { ErrorHandler } from "../../common/errorHandler/errorHandler";
import { SpinnerFull } from "../../common/spinner/spinnerFull/spinnerFull";
import { RoleViewPermissions } from "../../role/views";
import { InitDataFromLocalStorage } from "../../session/InitDataFromLocalStorage";
import { SiteNav } from "../../siteNav/siteNav";
import { TemplateHeader } from "./header/header";
import { EnvironmentStyles } from "./template.jss";
import { Cookies } from "../../common/cookies/cookiesContent";
import { OrganizationSelector } from "../../common/selectors";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../common/constants";
import { Environment } from "../../../common/enum/environment/Environment";

interface TemplateProps {
    title?: string;
    featureId?: string;
    component: React.ReactNode | React.Component | React.FunctionComponent;
}

export const Template = (props: TemplateProps & any) => {
    const [currentTheme, setCurrentTheme] = React.useState(romeuTheme);
    const isUserClaimsLoaded = useSelector(getUserClaimsLoaded);

    function changeTheme(themeValue: string) {
        if (themeValue === romeuTheme.id) {
            setCurrentTheme(romeuTheme);
        }
    }

    return (
        <>
            <ErrorHandler />
            <ClaimsToken />
            <RoleViewPermissions />
            <InitDataFromLocalStorage />
            <ThemeProvider theme={currentTheme}>
                {isUserClaimsLoaded ? (
                    <>
                        <BodyContainer
                            component={props.component}
                            title={props.title}
                            featureId={props.featureId}
                            changeTheme={changeTheme}
                            props={props}
                        />
                    </>
                ) : (
                    <></>
                )}
                <SpinnerFull />
                <Cookies />
            </ThemeProvider>
        </>
    );
};

const BodyContainer = (props: any) => {
    const theme = useTheme() as any;
    const { siteNavFixed } = useAppSelector((store) => store.globalConfig);
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const background = useMemo(() => {
        if (environment === Environment.DEV) {
            return theme.colors.siteHeader.devBackground;
        } else if (environment === Environment.PRE) {
            return theme.colors.siteHeader.preBackground;
        }
        return null;
    }, [environment]);

    const styles = EnvironmentStyles({ theme, background });

    const body = React.useMemo(
        () => (props.component ? <props.component {...props.props} /> : <p>Template referenced without body component</p>),
        [],
    );
    const siteNav = React.useMemo(() => <SiteNav />, []);
    const isMobile = useMediaQuery({ query: componentsMediaQueries.MOBILE });
    return (
        <>
            <div className={styles.siteContainer}>
                {siteNav}
                <div className={`${styles.siteBody} ${siteNavFixed ? styles.fixedBar : ""}`}>
                    <TemplateHeader
                        title={props.title}
                        featureId={props.featureId}
                        background={background}
                    />
                    {isMobile && (
                        <>
                            <div className={styles.selectorContainer}>
                                <OrganizationSelector />
                            </div>
                        </>
                    )}

                    <div className={styles.siteContent}>{body}</div>
                </div>
            </div>
        </>
    );
};
