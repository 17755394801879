import { createUseStyles } from "react-jss";

export const InvoicingSummaryByConceptListStyle = createUseStyles((theme: any) => ({
    root: {
        width: "100%",
        backgroundColor: theme.colors.footerTable.background,
    },
    cell: {
        display: "flex",
        width: "100%",
        overflow: "hidden",
    },
    cellAnimation: {
        width: "100%",
    },
    cellUnpadded: {
        width: "100%",
    },
    cellPadded: {
        width: "100%",
    },
    checkCell: {
        width: "100%",
    },
    isRowHeader: {
        width: "100%",
    },
    isMultiline: {
        width: "100%",
    },
    fields: {
        width: "100%",
    },
    cellMeasurer: {
        width: "100%",
    },
    checkCover: {
        width: "100%",
    },
    check: {
        width: "100%",
    },
    footerCell: {
        fontWeight: theme.fonts.weight.bold,
    },
}));
