import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { IConfigEditRequest } from "../../../../../../models/requests/IConfigEditRequest";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { ButtonPrimaryInverseWithBorder } from "../../../../../common/buttons/buttons";
import { TitleH5 } from "../../../../../common/titles/titles";
import { TableInspectionStyles } from "./containerStyle.jss";
import { RequestInspectionFormController } from "./controller/controller";
import { ActuationFormController } from "../../../../common/columns/components/controllers/actuation";
import { editRequestId } from "../../../../../../redux/selectors/request/request";
import { EditRequestGeneralInformationEditRequestInspectionsList } from "./list/inspection/list";
import { useMediaQuery } from "react-responsive";
import { componentsMediaQueries } from "../../../../../../common/constants";
import { CustomIcons } from "../../../../../common/customIcons/customIcons";
import { NameIcons } from "../../../../../../assets/icons";
import { isShowAddMultipleIcon } from "../../../../common/validation/inspections";
import { setEditRequestInspectionShowPopUp } from "../../../../../../redux/reducers/request/edit/form/inspection";
import { ColorType } from "../../../../../../common/enum/ColorType";
import { PopUpCodes } from "../../../../../../common/enum/PopUpCodes";
import { setEditRequestActuationPopUp, updateActuationFormProperty } from "../../../../../../redux/reducers/request/edit/form/actuation";

interface IPropsInspectionData {
    schema: IConfigEditRequest;
    invalidRequest: boolean;
}

export const EditRequestGeneralInformationEditRequestInspections: React.FC<IPropsInspectionData> = ({ schema, invalidRequest }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "EDIT_REQUEST" });
    const theme = useTheme();
    const style = TableInspectionStyles({ theme });

    const isMobile = useMediaQuery({ query: componentsMediaQueries.TABLETPORTRAIT });
    const requestId = useAppSelector(editRequestId);

    const [showFormActuationModal, setShowFormActuationModal] = useState<boolean>(false);
    const [showDeleteActuationModal, setShowDeleteActuationModal] = useState<boolean>(false);

    const { inspections } = useAppSelector((store) => store.editRequest.requestInspection);
    const { organismId } = useAppSelector((state) => state.userData.profile);

    const buttonAddMultipleActuation = useMemo(() => {
        if (isMobile && schema.actuation_create.visible && isShowAddMultipleIcon([], inspections)) {
            return (
                <CustomIcons
                    margin={"0px"}
                    isButton={true}
                    iconName={NameIcons.ADDRED}
                    onClick={() => {
                        dispatch(setEditRequestActuationPopUp(PopUpCodes.CREATE));
                        dispatch(updateActuationFormProperty({ name: "isMultiple", value: true }));
                        setShowFormActuationModal(true);
                    }}
                    isDisabled={true}
                    tooltip={t("TOOLTIP.CREATE_MULTIPLE_ACTUATIONS")}
                />
            );
        }
        return null;
    }, [invalidRequest, schema, inspections, isMobile]);

    const buttonAddInspections = useMemo(() => {
        if (schema.inspection_create.visible) {
            return (
                <ButtonPrimaryInverseWithBorder
                    handleClick={() => {
                        dispatch(setEditRequestInspectionShowPopUp(PopUpCodes.CREATE));
                    }}
                    title={`+ ${t("BUTTONS.ADD_INSPECTION_REQUEST")}`}
                />
            );
        }

        return null;
    }, [schema]);

    return (
        <div className={style.generalcontainer}>
            <div className={style.inspectionHeader}>
                <TitleH5
                    title={t("TITLES.INSPECTION_SECTION", { inspectionsLength: inspections ? inspections.length : 0 })}
                    color={ColorType.primary}
                    bold={true}
                />
                {buttonAddInspections}
                {buttonAddMultipleActuation}
            </div>

            <EditRequestGeneralInformationEditRequestInspectionsList
                schema={schema}
                requestId={requestId}
                invalidRequest={invalidRequest}
                showFormActuationModal={setShowFormActuationModal}
                showDeleteActuationModal={setShowDeleteActuationModal}
            />

            <RequestInspectionFormController
                requestId={requestId}
                schema={schema}
            />

            <ActuationFormController
                isEditRequest={true}
                requestId={requestId}
                disabledOrganismSelector={organismId ? true : false}
                showFormActuationModal={showFormActuationModal}
                onDismissFormActuationModal={setShowFormActuationModal}
                showDeleteActuationModal={showDeleteActuationModal}
                onDismissDeleteActuationModal={setShowDeleteActuationModal}
            />
        </div>
    );
};
