import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGenericPayload } from "../../../../../models";
import { IMasterNotificationType } from "../../../../../models/resources/master/INotificationType";
export interface IMasterTypesWareFormState {
    form: IMasterNotificationType;
    loading: boolean;
}

const initialState: IMasterTypesWareFormState = {
    form: {
        active: true,
        code: "",
        description: "",
        id: 0,
    },
    loading: false,
};

export const masterNotificationTypesForm = createSlice({
    name: "masterNotificationTypesForm",
    initialState,
    reducers: {
        resetMasterNotificationTypesForm: () => initialState,
        setLoadingMasterNotificationTypesForm: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setMasterNotificationTypesForm: (state, action: PayloadAction<IMasterNotificationType>) => {
            state.form = action.payload;
        },
        updateMasterNotificationTypesProperty: (state, action: PayloadAction<IGenericPayload>) => {
            state.form = {
                ...state.form,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const {
    resetMasterNotificationTypesForm,
    setLoadingMasterNotificationTypesForm,
    setMasterNotificationTypesForm,
    updateMasterNotificationTypesProperty,
} = masterNotificationTypesForm.actions;

export default masterNotificationTypesForm.reducer;
