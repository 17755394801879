import { useTheme } from "react-jss";
import { UpcomingAppointmentsFilterStyle } from "./filterStyle.jss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IGenericPayload } from "../../../models/common/IGenericPayload";
import { FilterHeader } from "../../common/filterHeader/filterHeader";
import { DaySelector } from "../../../models/requests/IDaySelector";
import { CustomDatePicker } from "../../common/customDatePicker/customDatePicker";
import { CustomOrganismSelector } from "../../request/common/selectors/organism/customOrganismSelector";
import { IUpcomingAppointmentFilter } from "../../../models/upcomingAppointment/IUpcomingAppointments";
import { compileNameOfProperty } from "../../../utils";
import { CustomInspectorRoleSelector } from "../../request/common/selectors/inspector/customInspectorSelector";
import { CustomMerchandiseCategoryIdSelector } from "../../common/selectors/controllerSelectors/merchandiseCategory/customMerchandiseCategory";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { CustomMerchandiseTypeSelector } from "../../request/common/selectors/merchandiseType/customMerchandiseTypeSelector";
import { RoleType } from "../../../common/enum";
import { DefaultValues } from "../../../common/enum/DefaultValues";
import { CustomerFilterSelector } from "../../common/selectors/controllerSelectors/customerSelector/customerFilterSelector";

interface IAppointmentUpcomingFilterProps {
    schema: any;
    filter: any;
    onApplyFilter: () => any;
    onResetFilter: () => any;
    updateProperty: (payload: IGenericPayload) => any;
}

export const AppointmentUpcomingFilter: React.FC<IAppointmentUpcomingFilterProps> = ({
    filter,
    onApplyFilter,
    onResetFilter,
    schema,
    updateProperty,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("common", { keyPrefix: "APPOINTMENT.FILTER" });
    const dobleRow = schema.merchandiseCategoryId.visible || schema.merchandiseTypeId.visible ? true : false;
    const theme = useTheme();
    const style = UpcomingAppointmentsFilterStyle({ theme, dobleRow });

    const { roles, email, organismId } = useAppSelector((store) => store.userData.profile);
    const userOrganismId = useAppSelector((state) => state.userData.profile.organismId);

    const organismIdInspectorFilter = useMemo(() => {
        if (!email) {
            return DefaultValues.DEFAULT_ALL_SELECTOR;
        }

        if (userOrganismId) {
            return userOrganismId;
        }

        return filter.organismId !== DefaultValues.DEFAULT_ALL_SELECTOR ? filter.organismId : undefined;
    }, [email, filter.organismId, userOrganismId]);

    const searchRole = (roleSearch: string) => {
        let list = roles.filter((role) => role.id === roleSearch);
        return list && list.length >= 1 ? true : false;
    };

    const disabledOrganismSelector = useMemo(() => {
        return searchRole(RoleType.ORGANISM_ADMIN);
    }, [roles]);

    const disabledCustomerSelector = useMemo(() => {
        return searchRole(RoleType.CUSTOMER_ADMIN) || searchRole(RoleType.CUSTOMER_USER);
    }, [roles]);

    const onSelectorChange = useCallback(
        (name: string, event: any) => {
            const payload: IGenericPayload = {
                name,
                value: event.key,
            };
            updateProperty(payload);
        },
        [dispatch],
    );

    useEffect(() => {
        if (filter.inspectorId !== DefaultValues.DEFAULT_ALL_SELECTOR) {
            const payload: IGenericPayload = {
                name: compileNameOfProperty<IUpcomingAppointmentFilter>("inspectorId"),
                value: DefaultValues.DEFAULT_ALL_SELECTOR,
            };
            updateProperty(payload);
        }
    }, [filter.organismId]);

    useLayoutEffect(() => {
        let name = "";
        let value: any;
        if (disabledCustomerSelector) {
            name = compileNameOfProperty<IUpcomingAppointmentFilter>("customerId");
            value = email;
        } else if (disabledOrganismSelector) {
            name = compileNameOfProperty<IUpcomingAppointmentFilter>("organismId");
            value = organismId ?? 0;
        }

        const payload: IGenericPayload = {
            name,
            value,
        };
        updateProperty(payload);
    }, [disabledCustomerSelector, disabledOrganismSelector, filter.customerId, filter.organismId]);

    return (
        <FilterHeader
            content={
                <div className={style.container}>
                    {(schema.startDate.visible || schema.endDate.visible) && (
                        <div className={style.column}>
                            <CustomDatePicker
                                onAppliFilters={onApplyFilter}
                                startDate={filter.startDate}
                                endDate={filter.endDate}
                                maxDaySelected={DaySelector.MAX_YEAR_SELECTED}
                                minDaySelected={DaySelector.MIN_DAYS_SELECTED}
                                setFilterProperty={(name: any, value: any) => {
                                    updateProperty({ name, value });
                                }}
                                showLeftArrowController={false}
                            />
                        </div>
                    )}
                    {(schema.organismId.visible ||
                        schema.inspectorId.visible ||
                        schema.customerId.visible ||
                        schema.merchandiseCategoryId.visible) && <div className={style.verticalWhiteSeparator}></div>}
                    {(schema.organismId.visible ||
                        schema.inspectorId.visible ||
                        schema.customerId.visible ||
                        schema.merchandiseCategoryId.visible) && (
                        <div className={`${style.column} `}>
                            <div className={style.row}>
                                {schema.organismId.visible && (
                                    <div className={style.item}>
                                        <CustomOrganismSelector
                                            title={t("ORGANISM")}
                                            onChange={onSelectorChange}
                                            selectedKey={filter.organismId}
                                            name={compileNameOfProperty<IUpcomingAppointmentFilter>("organismId")}
                                            disabled={disabledOrganismSelector}
                                        ></CustomOrganismSelector>
                                    </div>
                                )}
                                {schema.inspectorId.visible && (
                                    <div className={style.longItem}>
                                        <CustomInspectorRoleSelector
                                            title={t("INSPECTOR")}
                                            onChange={onSelectorChange}
                                            selectedKey={filter.inspectorId}
                                            name={compileNameOfProperty<IUpcomingAppointmentFilter>("inspectorId")}
                                            organismId={organismIdInspectorFilter}
                                        />
                                    </div>
                                )}

                                {schema.customerId.visible && (
                                    <div className={style.longItem}>
                                        <CustomerFilterSelector
                                            title={t("CUSTOMER")}
                                            onChange={onSelectorChange}
                                            selectedKey={filter.customerId}
                                            name={compileNameOfProperty<IUpcomingAppointmentFilter>("customerId")}
                                            disabled={disabledCustomerSelector}
                                            filteredRegisteredCustomer={true}
                                        />
                                    </div>
                                )}
                            </div>
                            {(schema.merchandiseCategoryId.visible || schema.merchandiseTypeId.visible) && (
                                <div className={style.row}>
                                    {schema.merchandiseCategoryId.visible && (
                                        <div className={style.item}>
                                            <CustomMerchandiseCategoryIdSelector
                                                title={t("MERCHANDISE")}
                                                onChange={onSelectorChange}
                                                selectedKey={filter.merchandiseCategoryId}
                                                name={compileNameOfProperty<IUpcomingAppointmentFilter>("merchandiseCategoryId")}
                                            />
                                        </div>
                                    )}
                                    {schema.merchandiseTypeId.visible && (
                                        <div className={style.item}>
                                            <CustomMerchandiseTypeSelector
                                                title={t("MERCHANDISE_TYPE")}
                                                onChange={onSelectorChange}
                                                selectedKey={filter.merchandiseTypeId}
                                                name={compileNameOfProperty<IUpcomingAppointmentFilter>("merchandiseTypeId")}
                                            ></CustomMerchandiseTypeSelector>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            }
            hideActionButtons={
                !schema.organismId.visible && !schema.inspectorId.visible && !schema.customerId.visible && !schema.merchandiseCategoryId.visible
                    ? true
                    : false
            }
            buttonColumn={schema.merchandiseCategoryId.visible || schema.merchandiseTypeId.visible ? true : false}
            height={schema.merchandiseCategoryId.visible || schema.merchandiseTypeId.visible ? 120 : undefined}
            onApply={onApplyFilter}
            onReset={onResetFilter}
        />
    );
};
