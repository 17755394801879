import { Method } from "../../../common/enum";
import { IInvoiceConcept, ITariffCondition } from "../../../models/management/tariff/ITariffData";
import { apiFetchOAuthWithClaims } from "../../../services/apiClient";

const baseURL = `${process.env.REACT_APP_API_URL}/management`;

export class ManagementTariffApi {
    async getTariffConcepts(): Promise<ITariffCondition[]> {
        const url = `${baseURL}/tariffs`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute();
    }
    async deleteTariffConcepts(tariff: number): Promise<boolean> {
        const url = `${baseURL}/tariffs/${tariff}`;
        return apiFetchOAuthWithClaims(Method.DELETE, url).execute();
    }
    async getInvoiceConcepts(): Promise<IInvoiceConcept[]> {
        const url = `${baseURL}/tariffs/invoiceconcepts`;
        return apiFetchOAuthWithClaims(Method.GET, url).execute();
    }
    async postInvoiceConcept(concept: ITariffCondition): Promise<boolean> {
        const url = `${baseURL}/tariffs`;
        return apiFetchOAuthWithClaims(Method.POST, url).withBody(concept).execute();
    }
    async putInvoiceConcept(concept: ITariffCondition): Promise<boolean> {
        const url = `${baseURL}/tariffs/${concept?.id}`;
        return apiFetchOAuthWithClaims(Method.PUT, url).withBody(concept).execute();
    }
    async getTariffItem(id: number): Promise<ITariffCondition> {
        const url = `${baseURL}/tariffs/${id}`;
        return apiFetchOAuthWithClaims(Method.GET, url).withBody({}).execute();
    }
}
