import { IColumn } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { generatePath, useNavigate } from "react-router";
import { NameIcons } from "../../../../../../../assets/icons";
import { routeUrls } from "../../../../../../../common/constants";
import { FormatDate } from "../../../../../../../common/enum/dateTime/FormatDate";
import { IBoardHistoryAppointment, IBoardHistoryAppointmentTable } from "../../../../../../../models/masterAppointment/IAppointmentBoard";
import { compileNameOfProperty, formatDate } from "../../../../../../../utils";
import { CustomHeaderColumn } from "../../../../../../common/customDetailsList/customHeader";
import { CustomIcons } from "../../../../../../common/customIcons/customIcons";
import { MasterBlocksColumnsStyle } from "./columnStyle.jss";
import { DefaultValues } from "../../../../../../../common/enum/DefaultValues";

export const MasterAppointmentBoardColumns = (): IColumn[] => {
    const theme = useTheme();
    const styles = MasterBlocksColumnsStyle({ theme });
    const { t } = useTranslation("common", { keyPrefix: "MASTER_APPOINTMENT" });
    const navigate = useNavigate();

    const columns: IColumn[] = [
        {
            key: compileNameOfProperty<IBoardHistoryAppointmentTable>("date"),
            minWidth: 100,
            maxWidth: 150,
            name: t("APPOINTMENT_BOARD.DATE"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IBoardHistoryAppointmentTable>("date")}
                    title={t("APPOINTMENT_BOARD.DATE")}
                    styles={styles}
                />
            ),
            onRender: (item: IBoardHistoryAppointmentTable) => <span>{formatDate(item.date, FormatDate.DATE)}</span>,
            isResizable: true,
        },
        {
            key: compileNameOfProperty<IBoardHistoryAppointmentTable>("merchandiseCategoryGroupDescription"),
            minWidth: 120,
            maxWidth: 200,
            name: t("APPOINTMENT_BOARD.MERCHANDISE_CATEGORY"),
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={compileNameOfProperty<IBoardHistoryAppointmentTable>("merchandiseCategoryGroupDescription")}
                    title={t("APPOINTMENT_BOARD.MERCHANDISE_CATEGORY")}
                    styles={styles}
                />
            ),
            onRender: (item: IBoardHistoryAppointment) => <span>{item.merchandiseCategoryGroupDescription ?? DefaultValues.EMPTY_LIST}</span>,
            isResizable: true,
        },
        {
            key: "actions",
            minWidth: 100,
            maxWidth: 1000,
            name: "",
            onRenderHeader: () => (
                <CustomHeaderColumn
                    name={""}
                    title={""}
                    styles={styles}
                />
            ),
            onRender: (item: IBoardHistoryAppointment) => (
                <span className={styles.actions}>
                    <CustomIcons
                        iconName={NameIcons.EDIT}
                        onClick={() => {
                            const path = generatePath(routeUrls.MASTER_APPOINTMENT_BOARD, { id: item.id.toString() });
                            navigate(path);
                        }}
                    />
                </span>
            ),
            isResizable: true,
        },
    ];

    return columns;
};
