import { createUseStyles } from "react-jss";

export const ControlExpedientsFormStyle = createUseStyles((theme: any) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.paddings.sizes.base,
        gap: theme.gaps.sizes.xl,
        width: "100%",
        alignItems: "center",
    },
    row: {
        display: "flex",
        width: "100%",
        gap: theme.gaps.sizes.xl,
    },
}));
