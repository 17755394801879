import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { mediaQueries } from "../../../common/constants";
import {
    fetchVlcPortHistoricPositioningRequest,
    fetchVlcportHistoricInspectionAppoiment,
    fetchVlcportHistoricInspectionResult,
} from "../../../redux/actions/vlcPort/historic/historic";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { NavHeaderComponent } from "../../common/navHeader/navHeader";
import { VlcportHistoryController } from "./controller/controller";
import { VlcportHistoricFilter } from "./filter/filter";
import { VlcportHistoricList } from "./list/list";

export enum VlcportHistoricListPivotId {
    INSPECTIONAPPOINTMENT = "0",
    INSPECTIONRESULT = "1",
    POSITIONINGREQUEST = "2",
}

export const VlcportHistoricContainer = () => {
    const [selectedKey, setSelectedKey] = useState<string>(VlcportHistoricListPivotId.POSITIONINGREQUEST);
    const { t } = useTranslation("common", { keyPrefix: "VLC_PORT.HISTORIC" });
    const dispatch = useAppDispatch();
    const theme: any = useTheme();

    const InspectionAppoiment = useAppSelector((state) => state.vlcportHistoricInspectionAppoimentList);
    const InspectionResult = useAppSelector((state) => state.vlcportHistoricInspectionResultList);
    const PositioningRequest = useAppSelector((state) => state.vlcportHistoricPositioningRequestList);

    const pivotStyles: Partial<IPivotStyles> = useMemo(() => {
        return {
            linkIsSelected: {
                color: theme.colors.navLinks.primary.color,
                selectors: {
                    ":before": {
                        height: 3,
                        backgroundColor: theme.colors.navLinks.primary.color,
                    },
                },
            },
            link: {
                color: theme.colors.siteNav.section.color,
                fontFamily: theme.fonts.families.site,
                minWidth: 208,
                marginRight: 0,
                [mediaQueries.TABLET]: {
                    minWidth: "auto",
                },
            },
        };
    }, [theme.colors, theme.fonts]);

    useEffect(() => {
        selectedKey === VlcportHistoricListPivotId.INSPECTIONAPPOINTMENT &&
            !InspectionAppoiment.loading &&
            dispatch(fetchVlcportHistoricInspectionAppoiment());

        selectedKey === VlcportHistoricListPivotId.INSPECTIONRESULT && !InspectionResult.loading && dispatch(fetchVlcportHistoricInspectionResult());

        selectedKey === VlcportHistoricListPivotId.POSITIONINGREQUEST &&
            !PositioningRequest.loading &&
            dispatch(fetchVlcPortHistoricPositioningRequest());
    }, [selectedKey]);

    return (
        <div>
            <NavHeaderComponent title={t("TITLE")} />
            <VlcportHistoricFilter selectedPivot={selectedKey} />
            <Pivot
                styles={pivotStyles}
                selectedKey={String(selectedKey)}
                onLinkClick={(event) => {
                    setSelectedKey(event?.props.itemKey ? event?.props.itemKey : "0");
                }}
            >
                <PivotItem
                    headerText={t("POSITIONINGREQUEST")}
                    itemKey={VlcportHistoricListPivotId.POSITIONINGREQUEST}
                >
                    {
                        <VlcportHistoricList
                            list={PositioningRequest.list}
                            loading={PositioningRequest.loading}
                            selectedKey={VlcportHistoricListPivotId.POSITIONINGREQUEST}
                        />
                    }
                </PivotItem>

                <PivotItem
                    headerText={t("INSPECTIONAPPOINTMENT")}
                    itemKey={VlcportHistoricListPivotId.INSPECTIONAPPOINTMENT}
                >
                    {
                        <VlcportHistoricList
                            list={InspectionAppoiment.list}
                            loading={InspectionAppoiment.loading}
                            selectedKey={VlcportHistoricListPivotId.INSPECTIONAPPOINTMENT}
                        />
                    }
                </PivotItem>
                <PivotItem
                    headerText={t("INSPECTIONRESULT")}
                    itemKey={VlcportHistoricListPivotId.INSPECTIONRESULT}
                >
                    {
                        <VlcportHistoricList
                            list={InspectionResult.list}
                            loading={InspectionResult.loading}
                            selectedKey={VlcportHistoricListPivotId.INSPECTIONRESULT}
                        />
                    }
                </PivotItem>
            </Pivot>
            <VlcportHistoryController />
        </div>
    );
};
